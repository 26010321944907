(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.fO.hy === region.gZ.hy)
	{
		return 'on line ' + region.fO.hy;
	}
	return 'on lines ' + region.fO.hy + ' through ' + region.gZ.hy;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.l2,
		impl.px,
		impl.oH,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS
//
// For some reason, tabs can appear in href protocols and it still works.
// So '\tjava\tSCRIPT:alert("!!!")' and 'javascript:alert("!!!")' are the same
// in practice. That is why _VirtualDom_RE_js and _VirtualDom_RE_js_html look
// so freaky.
//
// Pulling the regular expressions out to the top level gives a slight speed
// boost in small benchmarks (4-10%) but hoisting values to reduce allocation
// can be unpredictable in large programs where JIT may have a harder time with
// functions are not fully self-contained. The benefit is more that the js and
// js_html ones are so weird that I prefer to see them near each other.


var _VirtualDom_RE_script = /^script$/i;
var _VirtualDom_RE_on_formAction = /^(on|formAction$)/i;
var _VirtualDom_RE_js = /^\s*j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:/i;
var _VirtualDom_RE_js_html = /^\s*(j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:|d\s*a\s*t\s*a\s*:\s*t\s*e\s*x\s*t\s*\/\s*h\s*t\s*m\s*l\s*(,|;))/i;


function _VirtualDom_noScript(tag)
{
	return _VirtualDom_RE_script.test(tag) ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return _VirtualDom_RE_on_formAction.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return _VirtualDom_RE_js.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return _VirtualDom_RE_js_html.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlJson(value)
{
	return (typeof _Json_unwrap(value) === 'string' && _VirtualDom_RE_js_html.test(_Json_unwrap(value)))
		? _Json_wrap(
			/**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		) : value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		hG: func(record.hG),
		fP: record.fP,
		ft: record.ft
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.hG;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.fP;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.ft) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.l2,
		impl.px,
		impl.oH,
		function(sendToApp, initialModel) {
			var view = impl.pM;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.l2,
		impl.px,
		impl.oH,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.fG && impl.fG(sendToApp)
			var view = impl.pM;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.kd);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.ja) && (_VirtualDom_doc.title = title = doc.ja);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.ne;
	var onUrlRequest = impl.nf;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		fG: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.iq === next.iq
							&& curr.hj === next.hj
							&& curr.ij.a === next.ij.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		l2: function(flags)
		{
			return A3(impl.l2, flags, _Browser_getUrl(), key);
		},
		pM: impl.pM,
		px: impl.px,
		oH: impl.oH
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { lP: 'hidden', kt: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { lP: 'mozHidden', kt: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { lP: 'msHidden', kt: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { lP: 'webkitHidden', kt: 'webkitvisibilitychange' }
		: { lP: 'hidden', kt: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		iK: _Browser_getScene(),
		jo: {
			jv: _Browser_window.pageXOffset,
			pS: _Browser_window.pageYOffset,
			ec: _Browser_doc.documentElement.clientWidth,
			he: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		ec: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		he: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			iK: {
				ec: node.scrollWidth,
				he: node.scrollHeight
			},
			jo: {
				jv: node.scrollLeft,
				pS: node.scrollTop,
				ec: node.clientWidth,
				he: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			iK: _Browser_getScene(),
			jo: {
				jv: x,
				pS: y,
				ec: _Browser_doc.documentElement.clientWidth,
				he: _Browser_doc.documentElement.clientHeight
			},
			lh: {
				jv: x + rect.left,
				pS: y + rect.top,
				ec: rect.width,
				he: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}



// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.lp.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.lp.b, xhr)); });
		$elm$core$Maybe$isJust(request.pq) && _Http_track(router, xhr, request.pq.a);

		try {
			xhr.open(request.mE, request.py, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.py));
		}

		_Http_configureRequest(xhr, request);

		request.kd.a && xhr.setRequestHeader('Content-Type', request.kd.a);
		xhr.send(request.kd.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.lM; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.pd.a || 0;
	xhr.responseType = request.lp.d;
	xhr.withCredentials = request.jV;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		py: xhr.responseURL,
		oy: xhr.status,
		oz: xhr.statusText,
		lM: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			oc: event.loaded,
			iT: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			nL: event.loaded,
			iT: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}

// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.hO) { flags += 'm'; }
	if (options.gp) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;



// DECODER

var _File_decoder = _Json_decodePrim(function(value) {
	// NOTE: checks if `File` exists in case this is run on node
	return (typeof File !== 'undefined' && value instanceof File)
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FILE', value);
});


// METADATA

function _File_name(file) { return file.name; }
function _File_mime(file) { return file.type; }
function _File_size(file) { return file.size; }

function _File_lastModified(file)
{
	return $elm$time$Time$millisToPosix(file.lastModified);
}


// DOWNLOAD

var _File_downloadNode;

function _File_getDownloadNode()
{
	return _File_downloadNode || (_File_downloadNode = document.createElement('a'));
}

var _File_download = F3(function(name, mime, content)
{
	return _Scheduler_binding(function(callback)
	{
		var blob = new Blob([content], {type: mime});

		// for IE10+
		if (navigator.msSaveOrOpenBlob)
		{
			navigator.msSaveOrOpenBlob(blob, name);
			return;
		}

		// for HTML5
		var node = _File_getDownloadNode();
		var objectUrl = URL.createObjectURL(blob);
		node.href = objectUrl;
		node.download = name;
		_File_click(node);
		URL.revokeObjectURL(objectUrl);
	});
});

function _File_downloadUrl(href)
{
	return _Scheduler_binding(function(callback)
	{
		var node = _File_getDownloadNode();
		node.href = href;
		node.download = '';
		node.origin === location.origin || (node.target = '_blank');
		_File_click(node);
	});
}


// IE COMPATIBILITY

function _File_makeBytesSafeForInternetExplorer(bytes)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/10
	// all other browsers can just run `new Blob([bytes])` directly with no problem
	//
	return new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength);
}

function _File_click(node)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/11
	// all other browsers have MouseEvent and do not need this conditional stuff
	//
	if (typeof MouseEvent === 'function')
	{
		node.dispatchEvent(new MouseEvent('click'));
	}
	else
	{
		var event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		document.body.appendChild(node);
		node.dispatchEvent(event);
		document.body.removeChild(node);
	}
}


// UPLOAD

var _File_node;

function _File_uploadOne(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			callback(_Scheduler_succeed(event.target.files[0]));
		});
		_File_click(_File_node);
	});
}

function _File_uploadOneOrMore(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.multiple = true;
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			var elmFiles = _List_fromArray(event.target.files);
			callback(_Scheduler_succeed(_Utils_Tuple2(elmFiles.a, elmFiles.b)));
		});
		_File_click(_File_node);
	});
}


// CONTENT

function _File_toString(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsText(blob);
		return function() { reader.abort(); };
	});
}

function _File_toBytes(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(new DataView(reader.result)));
		});
		reader.readAsArrayBuffer(blob);
		return function() { reader.abort(); };
	});
}

function _File_toUrl(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsDataURL(blob);
		return function() { reader.abort(); };
	});
}

var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.A) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.G),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.G);
		} else {
			var treeLen = builder.A * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.I) : builder.I;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.A);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.G) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.G);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{I: nodeList, A: (len / $elm$core$Array$branchFactor) | 0, G: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {g8: fragment, hj: host, ig: path, ij: port_, iq: protocol, t: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$element = _Browser_element;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$Routing$FatalErrorRoute = {$: 34};
var $author$project$Routing$HomeRoute = {$: 0};
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Models$AnalysisByQuestionState = F4(
	function (tableState, qGroupIdsSelected, modalResultTallyByQIdToShow, questionBandsToHide) {
		return {mK: modalResultTallyByQIdToShow, nG: qGroupIdsSelected, nJ: questionBandsToHide, fT: tableState};
	});
var $author$project$Models$ExamNotStarted = 0;
var $author$project$Type$AppCfg$NAPLANStyle = 1;
var $krisajenkins$remotedata$RemoteData$NotAsked = {$: 0};
var $author$project$Type$Configuration$StudentMode = {$: 2};
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $author$project$Type$ChartData$Absolute = 1;
var $author$project$Page$AdaptiveAnalytics$ByNbrQuestions = 2;
var $author$project$Type$AnalyticsResult$PreAssessment = 0;
var $author$project$Page$AdaptiveAnalytics$Single = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$AdaptiveAnalytics$ViewCharts = 0;
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Model$Rgba = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $mdgriffith$elm_ui$Element$rgb255 = F3(
	function (red, green, blue) {
		return A4($mdgriffith$elm_ui$Internal$Model$Rgba, red / 255, green / 255, blue / 255, 1);
	});
var $author$project$Theme$Style$black = A3($mdgriffith$elm_ui$Element$rgb255, 0, 0, 0);
var $mdgriffith$elm_ui$Element$rgb = F3(
	function (r, g, b) {
		return A4($mdgriffith$elm_ui$Internal$Model$Rgba, r, g, b, 1);
	});
var $mdgriffith$elm_ui$Element$toRgb = function (_v0) {
	var r = _v0.a;
	var g = _v0.b;
	var b = _v0.c;
	var a = _v0.d;
	return {ek: a, eo: b, eT: g, fx: r};
};
var $author$project$Theme$Style$adj = F2(
	function (adjFactor, color) {
		return function (el) {
			return A3($mdgriffith$elm_ui$Element$rgb, el.fx + adjFactor, el.eT + adjFactor, el.eo + adjFactor);
		}(
			$mdgriffith$elm_ui$Element$toRgb(color));
	});
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $author$project$Theme$Style$darker = $author$project$Theme$Style$adj(-0.2);
var $author$project$Theme$Style$lighter = $author$project$Theme$Style$adj(0.2);
var $author$project$Theme$Style$smred = A3($mdgriffith$elm_ui$Element$rgb255, 158, 0, 37);
var $author$project$Theme$Style$white = A3($mdgriffith$elm_ui$Element$rgb255, 255, 255, 255);
var $author$project$Theme$Style$makeTheme = F2(
	function (successColor, _v0) {
		var c1 = _v0.et;
		var c2 = _v0.eu;
		var c3 = _v0.ev;
		var c4 = _v0.ew;
		var c5 = _v0.ex;
		return {
			f6: c3,
			jG: $author$project$Theme$Style$darker(c1),
			jH: $author$project$Theme$Style$white,
			jI: $author$project$Theme$Style$darker(c3),
			ki: c5,
			kj: $author$project$Theme$Style$white,
			kG: $author$project$Theme$Style$white,
			eB: c2,
			kH: successColor,
			kI: c4,
			gy: c4,
			gz: successColor,
			eC: $author$project$Theme$Style$lighter(c4),
			eD: successColor,
			gA: c1,
			gC: c4,
			kJ: $author$project$Theme$Style$darker(c2),
			kX: c4,
			kY: $author$project$Theme$Style$darker(c1),
			kZ: $author$project$Theme$Style$white,
			lN: c5,
			lO: $author$project$Theme$Style$lighter(c5),
			mC: c5,
			mD: c4,
			fd: $author$project$Theme$Style$lighter(c5),
			hF: $author$project$Theme$Style$white,
			nl: c2,
			fr: c1,
			nm: c1,
			nn: $author$project$Theme$Style$white,
			op: $author$project$Theme$Style$smred,
			oK: $author$project$Theme$Style$white,
			oL: $author$project$Theme$Style$black,
			oM: $author$project$Theme$Style$lighter(c5),
			oN: c5,
			oO: $author$project$Theme$Style$white
		};
	});
var $author$project$Theme$Style$successGreen = A3($mdgriffith$elm_ui$Element$rgb255, 127, 201, 89);
var $author$project$Theme$Style$theme2Colors = {
	et: A3($mdgriffith$elm_ui$Element$rgb255, 246, 248, 245),
	eu: A3($mdgriffith$elm_ui$Element$rgb255, 130, 165, 180),
	ev: A3($mdgriffith$elm_ui$Element$rgb255, 228, 167, 93),
	ew: A3($mdgriffith$elm_ui$Element$rgb255, 196, 55, 68),
	ex: A3($mdgriffith$elm_ui$Element$rgb255, 74, 75, 97)
};
var $author$project$Theme$Style$t = A2($author$project$Theme$Style$makeTheme, $author$project$Theme$Style$successGreen, $author$project$Theme$Style$theme2Colors);
var $author$project$Page$AdaptiveAnalytics$init = {
	bl: $elm$core$Maybe$Nothing,
	a_: _List_Nil,
	cG: $elm$core$Maybe$Nothing,
	bq: _Utils_Tuple2(
		$elm$time$Time$millisToPosix(0),
		$elm$time$Time$millisToPosix(0)),
	bH: $elm$time$Time$millisToPosix(0),
	dQ: _Utils_Tuple2($author$project$Theme$Style$t.eC, $elm$core$Maybe$Nothing),
	aV: $elm$core$Maybe$Nothing,
	bM: $author$project$Page$AdaptiveAnalytics$Single(0),
	aE: $elm$core$Maybe$Nothing,
	d1: 2,
	cX: _List_Nil,
	bS: 0,
	c2: _List_Nil,
	eb: $elm$core$Maybe$Nothing,
	cw: 1,
	bX: $elm$core$Maybe$Nothing
};
var $author$project$Page$AdminPage$init = {b4: '', aS: _List_Nil, b8: _List_Nil, fE: '', fF: $elm$core$Maybe$Nothing, bT: $elm$core$Maybe$Nothing, c3: _List_Nil, aM: $elm$core$Dict$empty, aN: $krisajenkins$remotedata$RemoteData$NotAsked, bi: _List_Nil, bj: $elm$core$Dict$empty, bU: $elm$core$Dict$empty};
var $author$project$Page$CreateUsers$InputNotVerified = 0;
var $mdgriffith$elm_ui$Internal$Model$Unkeyed = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$AsColumn = 1;
var $mdgriffith$elm_ui$Internal$Model$asColumn = 1;
var $mdgriffith$elm_ui$Internal$Style$classes = {jE: 'a', eh: 'atv', jN: 'ab', jO: 'cx', jP: 'cy', jQ: 'acb', jR: 'accx', jS: 'accy', jT: 'acr', gd: 'al', ge: 'ar', jU: 'at', ei: 'ah', ej: 'av', j_: 's', j8: 'bh', j9: 'b', ke: 'w7', kf: 'bd', kg: 'bdt', dd: 'bn', kh: 'bs', dg: 'cpe', kx: 'cp', ky: 'cpx', kz: 'cpy', gv: 'c', dk: 'ctr', dl: 'cb', dm: 'ccx', a1: 'ccy', cE: 'cl', dn: 'cr', kK: 'ct', kV: 'cptr', kW: 'ctxt', lA: 'fcs', g5: 'focus-within', lC: 'fs', lH: 'g', eW: 'hbh', eX: 'hc', hf: 'he', eY: 'hf', hg: 'hfp', lT: 'hv', lZ: 'ic', l$: 'fr', dD: 'lbl', l4: 'iml', l5: 'imlf', l6: 'imlp', l7: 'implw', l8: 'it', mn: 'i', hA: 'lnk', ce: 'nb', hT: 'notxt', nb: 'ol', nd: 'or', bI: 'oq', nk: 'oh', h_: 'pg', $8: 'p', nr: 'ppe', nX: 'ui', n$: 'r', n7: 'sb', n8: 'sbx', n9: 'sby', oa: 'sbt', on: 'e', oo: 'cap', os: 'sev', oC: 'sk', cY: 't', oX: 'tc', oY: 'w8', oZ: 'w2', o_: 'w9', o$: 'tj', d5: 'tja', o0: 'tl', o1: 'w3', o2: 'w5', o3: 'w4', o4: 'tr', o5: 'w6', o6: 'w1', o7: 'tun', jg: 'ts', bQ: 'clr', pv: 'u', f0: 'wc', js: 'we', f1: 'wf', jt: 'wfp', f3: 'wrp'};
var $mdgriffith$elm_ui$Internal$Model$Generic = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$div = $mdgriffith$elm_ui$Internal$Model$Generic;
var $mdgriffith$elm_ui$Internal$Model$NoNearbyChildren = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$columnClass = $mdgriffith$elm_ui$Internal$Style$classes.j_ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.gv);
var $mdgriffith$elm_ui$Internal$Model$gridClass = $mdgriffith$elm_ui$Internal$Style$classes.j_ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.lH);
var $mdgriffith$elm_ui$Internal$Model$pageClass = $mdgriffith$elm_ui$Internal$Style$classes.j_ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.h_);
var $mdgriffith$elm_ui$Internal$Model$paragraphClass = $mdgriffith$elm_ui$Internal$Style$classes.j_ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.$8);
var $mdgriffith$elm_ui$Internal$Model$rowClass = $mdgriffith$elm_ui$Internal$Style$classes.j_ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.n$);
var $mdgriffith$elm_ui$Internal$Model$singleClass = $mdgriffith$elm_ui$Internal$Style$classes.j_ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.on);
var $mdgriffith$elm_ui$Internal$Model$contextClasses = function (context) {
	switch (context) {
		case 0:
			return $mdgriffith$elm_ui$Internal$Model$rowClass;
		case 1:
			return $mdgriffith$elm_ui$Internal$Model$columnClass;
		case 2:
			return $mdgriffith$elm_ui$Internal$Model$singleClass;
		case 3:
			return $mdgriffith$elm_ui$Internal$Model$gridClass;
		case 4:
			return $mdgriffith$elm_ui$Internal$Model$paragraphClass;
		default:
			return $mdgriffith$elm_ui$Internal$Model$pageClass;
	}
};
var $mdgriffith$elm_ui$Internal$Model$Keyed = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$NoStyleSheet = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$Styled = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Unstyled = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$addChildren = F2(
	function (existing, nearbyChildren) {
		switch (nearbyChildren.$) {
			case 0:
				return existing;
			case 1:
				var behind = nearbyChildren.a;
				return _Utils_ap(behind, existing);
			case 2:
				var inFront = nearbyChildren.a;
				return _Utils_ap(existing, inFront);
			default:
				var behind = nearbyChildren.a;
				var inFront = nearbyChildren.b;
				return _Utils_ap(
					behind,
					_Utils_ap(existing, inFront));
		}
	});
var $mdgriffith$elm_ui$Internal$Model$addKeyedChildren = F3(
	function (key, existing, nearbyChildren) {
		switch (nearbyChildren.$) {
			case 0:
				return existing;
			case 1:
				var behind = nearbyChildren.a;
				return _Utils_ap(
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						behind),
					existing);
			case 2:
				var inFront = nearbyChildren.a;
				return _Utils_ap(
					existing,
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						inFront));
			default:
				var behind = nearbyChildren.a;
				var inFront = nearbyChildren.b;
				return _Utils_ap(
					A2(
						$elm$core$List$map,
						function (x) {
							return _Utils_Tuple2(key, x);
						},
						behind),
					_Utils_ap(
						existing,
						A2(
							$elm$core$List$map,
							function (x) {
								return _Utils_Tuple2(key, x);
							},
							inFront)));
		}
	});
var $mdgriffith$elm_ui$Internal$Model$AsEl = 2;
var $mdgriffith$elm_ui$Internal$Model$asEl = 2;
var $mdgriffith$elm_ui$Internal$Model$AsParagraph = 4;
var $mdgriffith$elm_ui$Internal$Model$asParagraph = 4;
var $mdgriffith$elm_ui$Internal$Flag$Flag = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$Second = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $mdgriffith$elm_ui$Internal$Flag$flag = function (i) {
	return (i > 31) ? $mdgriffith$elm_ui$Internal$Flag$Second(1 << (i - 32)) : $mdgriffith$elm_ui$Internal$Flag$Flag(1 << i);
};
var $mdgriffith$elm_ui$Internal$Flag$alignBottom = $mdgriffith$elm_ui$Internal$Flag$flag(41);
var $mdgriffith$elm_ui$Internal$Flag$alignRight = $mdgriffith$elm_ui$Internal$Flag$flag(40);
var $mdgriffith$elm_ui$Internal$Flag$centerX = $mdgriffith$elm_ui$Internal$Flag$flag(42);
var $mdgriffith$elm_ui$Internal$Flag$centerY = $mdgriffith$elm_ui$Internal$Flag$flag(43);
var $elm$json$Json$Encode$string = _Json_wrap;
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $mdgriffith$elm_ui$Internal$Model$lengthClassName = function (x) {
	switch (x.$) {
		case 0:
			var px = x.a;
			return $elm$core$String$fromInt(px) + 'px';
		case 1:
			return 'auto';
		case 2:
			var i = x.a;
			return $elm$core$String$fromInt(i) + 'fr';
		case 3:
			var min = x.a;
			var len = x.b;
			return 'min' + ($elm$core$String$fromInt(min) + $mdgriffith$elm_ui$Internal$Model$lengthClassName(len));
		default:
			var max = x.a;
			var len = x.b;
			return 'max' + ($elm$core$String$fromInt(max) + $mdgriffith$elm_ui$Internal$Model$lengthClassName(len));
	}
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$core$Basics$round = _Basics_round;
var $mdgriffith$elm_ui$Internal$Model$floatClass = function (x) {
	return $elm$core$String$fromInt(
		$elm$core$Basics$round(x * 255));
};
var $mdgriffith$elm_ui$Internal$Model$transformClass = function (transform) {
	switch (transform.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var _v1 = transform.a;
			var x = _v1.a;
			var y = _v1.b;
			var z = _v1.c;
			return $elm$core$Maybe$Just(
				'mv-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(x) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(y) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(z))))));
		default:
			var _v2 = transform.a;
			var tx = _v2.a;
			var ty = _v2.b;
			var tz = _v2.c;
			var _v3 = transform.b;
			var sx = _v3.a;
			var sy = _v3.b;
			var sz = _v3.c;
			var _v4 = transform.c;
			var ox = _v4.a;
			var oy = _v4.b;
			var oz = _v4.c;
			var angle = transform.d;
			return $elm$core$Maybe$Just(
				'tfrm-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(tx) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(ty) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(tz) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sx) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sy) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(sz) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(ox) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(oy) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(oz) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(angle))))))))))))))))))));
	}
};
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $mdgriffith$elm_ui$Internal$Model$getStyleName = function (style) {
	switch (style.$) {
		case 13:
			var name = style.a;
			return name;
		case 12:
			var name = style.a;
			var o = style.b;
			return name;
		case 0:
			var _class = style.a;
			return _class;
		case 1:
			var name = style.a;
			return name;
		case 2:
			var i = style.a;
			return 'font-size-' + $elm$core$String$fromInt(i);
		case 3:
			var _class = style.a;
			return _class;
		case 4:
			var _class = style.a;
			return _class;
		case 5:
			var cls = style.a;
			var x = style.b;
			var y = style.c;
			return cls;
		case 7:
			var cls = style.a;
			var top = style.b;
			var right = style.c;
			var bottom = style.d;
			var left = style.e;
			return cls;
		case 6:
			var cls = style.a;
			var top = style.b;
			var right = style.c;
			var bottom = style.d;
			var left = style.e;
			return cls;
		case 8:
			var template = style.a;
			return 'grid-rows-' + (A2(
				$elm$core$String$join,
				'-',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.n0)) + ('-cols-' + (A2(
				$elm$core$String$join,
				'-',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.kC)) + ('-space-x-' + ($mdgriffith$elm_ui$Internal$Model$lengthClassName(template.ou.a) + ('-space-y-' + $mdgriffith$elm_ui$Internal$Model$lengthClassName(template.ou.b)))))));
		case 9:
			var pos = style.a;
			return 'gp grid-pos-' + ($elm$core$String$fromInt(pos.n$) + ('-' + ($elm$core$String$fromInt(pos.kB) + ('-' + ($elm$core$String$fromInt(pos.ec) + ('-' + $elm$core$String$fromInt(pos.he)))))));
		case 11:
			var selector = style.a;
			var subStyle = style.b;
			var name = function () {
				switch (selector) {
					case 0:
						return 'fs';
					case 1:
						return 'hv';
					default:
						return 'act';
				}
			}();
			return A2(
				$elm$core$String$join,
				' ',
				A2(
					$elm$core$List$map,
					function (sty) {
						var _v1 = $mdgriffith$elm_ui$Internal$Model$getStyleName(sty);
						if (_v1 === '') {
							return '';
						} else {
							var styleName = _v1;
							return styleName + ('-' + name);
						}
					},
					subStyle));
		default:
			var x = style.a;
			return A2(
				$elm$core$Maybe$withDefault,
				'',
				$mdgriffith$elm_ui$Internal$Model$transformClass(x));
	}
};
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $mdgriffith$elm_ui$Internal$Model$reduceStyles = F2(
	function (style, nevermind) {
		var cache = nevermind.a;
		var existing = nevermind.b;
		var styleName = $mdgriffith$elm_ui$Internal$Model$getStyleName(style);
		return A2($elm$core$Set$member, styleName, cache) ? nevermind : _Utils_Tuple2(
			A2($elm$core$Set$insert, styleName, cache),
			A2($elm$core$List$cons, style, existing));
	});
var $mdgriffith$elm_ui$Internal$Model$Property = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$Style = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$dot = function (c) {
	return '.' + c;
};
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$core$String$fromFloat = _String_fromNumber;
var $mdgriffith$elm_ui$Internal$Model$formatColor = function (_v0) {
	var red = _v0.a;
	var green = _v0.b;
	var blue = _v0.c;
	var alpha = _v0.d;
	return 'rgba(' + ($elm$core$String$fromInt(
		$elm$core$Basics$round(red * 255)) + ((',' + $elm$core$String$fromInt(
		$elm$core$Basics$round(green * 255))) + ((',' + $elm$core$String$fromInt(
		$elm$core$Basics$round(blue * 255))) + (',' + ($elm$core$String$fromFloat(alpha) + ')')))));
};
var $mdgriffith$elm_ui$Internal$Model$formatBoxShadow = function (shadow) {
	return A2(
		$elm$core$String$join,
		' ',
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					shadow.hp ? $elm$core$Maybe$Just('inset') : $elm$core$Maybe$Nothing,
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.b.a) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.b.b) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.b_) + 'px'),
					$elm$core$Maybe$Just(
					$elm$core$String$fromFloat(shadow.iT) + 'px'),
					$elm$core$Maybe$Just(
					$mdgriffith$elm_ui$Internal$Model$formatColor(shadow.b0))
				])));
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $mdgriffith$elm_ui$Internal$Model$renderFocusStyle = function (focus) {
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Model$Style,
			$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.g5) + ':focus-within',
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'border-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.eq),
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'background-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.j3),
						A2(
						$elm$core$Maybe$map,
						function (shadow) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'box-shadow',
								$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(
									{
										b_: shadow.b_,
										b0: shadow.b0,
										hp: false,
										b: A2(
											$elm$core$Tuple$mapSecond,
											$elm$core$Basics$toFloat,
											A2($elm$core$Tuple$mapFirst, $elm$core$Basics$toFloat, shadow.b)),
										iT: shadow.iT
									}));
						},
						focus.oi),
						$elm$core$Maybe$Just(
						A2($mdgriffith$elm_ui$Internal$Model$Property, 'outline', 'none'))
					]))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$Style,
			($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j_) + ':focus .focusable, ') + (($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j_) + '.focusable:focus, ') + ('.ui-slide-bar:focus + ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j_) + ' .focusable-thumb'))),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'border-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.eq),
						A2(
						$elm$core$Maybe$map,
						function (color) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'background-color',
								$mdgriffith$elm_ui$Internal$Model$formatColor(color));
						},
						focus.j3),
						A2(
						$elm$core$Maybe$map,
						function (shadow) {
							return A2(
								$mdgriffith$elm_ui$Internal$Model$Property,
								'box-shadow',
								$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(
									{
										b_: shadow.b_,
										b0: shadow.b0,
										hp: false,
										b: A2(
											$elm$core$Tuple$mapSecond,
											$elm$core$Basics$toFloat,
											A2($elm$core$Tuple$mapFirst, $elm$core$Basics$toFloat, shadow.b)),
										iT: shadow.iT
									}));
						},
						focus.oi),
						$elm$core$Maybe$Just(
						A2($mdgriffith$elm_ui$Internal$Model$Property, 'outline', 'none'))
					])))
		]);
};
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlJson(value));
	});
var $mdgriffith$elm_ui$Internal$Style$AllChildren = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Batch = function (a) {
	return {$: 6, a: a};
};
var $mdgriffith$elm_ui$Internal$Style$Child = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Class = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Descriptor = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Left = 3;
var $mdgriffith$elm_ui$Internal$Style$Prop = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Right = 2;
var $mdgriffith$elm_ui$Internal$Style$Self = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Style$Supports = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Style$Content = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Style$Bottom = 1;
var $mdgriffith$elm_ui$Internal$Style$CenterX = 4;
var $mdgriffith$elm_ui$Internal$Style$CenterY = 5;
var $mdgriffith$elm_ui$Internal$Style$Top = 0;
var $mdgriffith$elm_ui$Internal$Style$alignments = _List_fromArray(
	[0, 1, 2, 3, 4, 5]);
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $mdgriffith$elm_ui$Internal$Style$contentName = function (desc) {
	switch (desc) {
		case 0:
			var _v1 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kK);
		case 1:
			var _v2 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dl);
		case 2:
			var _v3 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dn);
		case 3:
			var _v4 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cE);
		case 4:
			var _v5 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dm);
		default:
			var _v6 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.a1);
	}
};
var $mdgriffith$elm_ui$Internal$Style$selfName = function (desc) {
	switch (desc) {
		case 0:
			var _v1 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jU);
		case 1:
			var _v2 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jN);
		case 2:
			var _v3 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ge);
		case 3:
			var _v4 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gd);
		case 4:
			var _v5 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jO);
		default:
			var _v6 = desc;
			return $mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jP);
	}
};
var $mdgriffith$elm_ui$Internal$Style$describeAlignment = function (values) {
	var createDescription = function (alignment) {
		var _v0 = values(alignment);
		var content = _v0.a;
		var indiv = _v0.b;
		return _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$contentName(alignment),
				content),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j_),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$selfName(alignment),
						indiv)
					]))
			]);
	};
	return $mdgriffith$elm_ui$Internal$Style$Batch(
		A2($elm$core$List$concatMap, createDescription, $mdgriffith$elm_ui$Internal$Style$alignments));
};
var $mdgriffith$elm_ui$Internal$Style$elDescription = _List_fromArray(
	[
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'column'),
		A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre'),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Descriptor,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eW),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j8),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '-1')
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Descriptor,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.oa),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cY),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eY),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f1),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'auto !important')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eX),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eY),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f1),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jt),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Child,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f0),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
			])),
		$mdgriffith$elm_ui$Internal$Style$describeAlignment(
		function (alignment) {
			switch (alignment) {
				case 0:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', '0 !important')
							]));
				case 1:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', '0 !important')
							]));
				case 2:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
							]));
				case 3:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
							]));
				case 4:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
							]));
				default:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j_),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto')
									]))
							]),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
							]));
			}
		})
	]);
var $mdgriffith$elm_ui$Internal$Style$gridAlignments = function (values) {
	var createDescription = function (alignment) {
		return _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j_),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$selfName(alignment),
						values(alignment))
					]))
			]);
	};
	return $mdgriffith$elm_ui$Internal$Style$Batch(
		A2($elm$core$List$concatMap, createDescription, $mdgriffith$elm_ui$Internal$Style$alignments));
};
var $mdgriffith$elm_ui$Internal$Style$Above = 0;
var $mdgriffith$elm_ui$Internal$Style$Behind = 5;
var $mdgriffith$elm_ui$Internal$Style$Below = 1;
var $mdgriffith$elm_ui$Internal$Style$OnLeft = 3;
var $mdgriffith$elm_ui$Internal$Style$OnRight = 2;
var $mdgriffith$elm_ui$Internal$Style$Within = 4;
var $mdgriffith$elm_ui$Internal$Style$locations = function () {
	var loc = 0;
	var _v0 = function () {
		switch (loc) {
			case 0:
				return 0;
			case 1:
				return 0;
			case 2:
				return 0;
			case 3:
				return 0;
			case 4:
				return 0;
			default:
				return 0;
		}
	}();
	return _List_fromArray(
		[0, 1, 2, 3, 4, 5]);
}();
var $mdgriffith$elm_ui$Internal$Style$baseSheet = _List_fromArray(
	[
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		'html,body',
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'padding', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		_Utils_ap(
			$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j_),
			_Utils_ap(
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.on),
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.lZ))),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eY),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'img',
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'max-height', '100%'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'object-fit', 'cover')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f1),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'img',
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'max-width', '100%'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'object-fit', 'cover')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j_) + ':focus',
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'outline', 'none')
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nX),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'min-height', '100%'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				_Utils_ap(
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j_),
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eY)),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eY),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Child,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.l$),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ce),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'fixed'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20')
							]))
					]))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ce),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'relative'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.on),
				$mdgriffith$elm_ui$Internal$Style$elDescription),
				$mdgriffith$elm_ui$Internal$Style$Batch(
				function (fn) {
					return A2($elm$core$List$map, fn, $mdgriffith$elm_ui$Internal$Style$locations);
				}(
					function (loc) {
						switch (loc) {
							case 0:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jE),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'bottom', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eY),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
												])),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f1),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
												])),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 1:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j9),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'bottom', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												])),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eY),
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', 'auto')
												]))
										]));
							case 2:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nd),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 3:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nb),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'right', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '20'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							case 4:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.l$),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
							default:
								return A2(
									$mdgriffith$elm_ui$Internal$Style$Descriptor,
									$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j8),
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'absolute'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none'),
											A2(
											$mdgriffith$elm_ui$Internal$Style$Child,
											'*',
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto')
												]))
										]));
						}
					}))
			])),
		A2(
		$mdgriffith$elm_ui$Internal$Style$Class,
		$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j_),
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'relative'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'resize', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'box-sizing', 'border-box'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'padding', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-width', '0'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'solid'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-size', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'color', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-family', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'line-height', '1'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', 'inherit'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'none'),
				A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'inherit'),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f3),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-wrap', 'wrap')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hT),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-moz-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-webkit-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, '-ms-user-select', 'none'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'user-select', 'none')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kV),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'pointer')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kW),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'text')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nr),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none !important')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dg),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'auto !important')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bQ),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.bI),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.lT, $mdgriffith$elm_ui$Internal$Style$classes.bQ)) + ':hover',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.lT, $mdgriffith$elm_ui$Internal$Style$classes.bI)) + ':hover',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.lA, $mdgriffith$elm_ui$Internal$Style$classes.bQ)) + ':focus',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.lA, $mdgriffith$elm_ui$Internal$Style$classes.bI)) + ':focus',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.eh, $mdgriffith$elm_ui$Internal$Style$classes.bQ)) + ':active',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot(
					_Utils_ap($mdgriffith$elm_ui$Internal$Style$classes.eh, $mdgriffith$elm_ui$Internal$Style$classes.bI)) + ':active',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'opacity', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jg),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Prop,
						'transition',
						A2(
							$elm$core$String$join,
							', ',
							A2(
								$elm$core$List$map,
								function (x) {
									return x + ' 160ms';
								},
								_List_fromArray(
									['transform', 'opacity', 'filter', 'background-color', 'color', 'font-size']))))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.n7),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.n8),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-x', 'auto'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.n$),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.n9),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-y', 'auto'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gv),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.on),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-shrink', '1')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kx),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ky),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-x', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kz),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-y', 'hidden')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f0),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', 'auto')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dd),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-width', '0')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kf),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'dashed')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kg),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'dotted')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.kh),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'border-style', 'solid')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cY),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-block')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.l8),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'line-height', '1.05'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'background', 'transparent'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'inherit')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.on),
				$mdgriffith$elm_ui$Internal$Style$elDescription),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.n$),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'row'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j_),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', '0%'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.js),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hA),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eY),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hg),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f1),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dk),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jT,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jR,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jO),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-left', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jR,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jO),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-right', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:only-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jR,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jP),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.jR + ' ~ u'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.jT + (' ~ s.' + $mdgriffith$elm_ui$Internal$Style$classes.jR)),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
											]));
								case 1:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
											]));
								case 2:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
											]),
										_List_Nil);
								case 3:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
											]),
										_List_Nil);
								case 4:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
											]),
										_List_Nil);
								default:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
											]));
							}
						}),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.os),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'space-between')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dD),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'baseline')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gv),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-direction', 'column'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j_),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', '0px'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'min-height', 'min-content'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.hf),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eY),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '100000')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f1),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jt),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.f0),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jQ,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:first-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jS,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jP),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', '0 !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jS,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jP),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', '0 !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:only-of-type.' + $mdgriffith$elm_ui$Internal$Style$classes.jS,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '1'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jP),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto !important'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto !important')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						's:last-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.jS + ' ~ u'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'u:first-of-type.' + ($mdgriffith$elm_ui$Internal$Style$classes.jQ + (' ~ s.' + $mdgriffith$elm_ui$Internal$Style$classes.jS)),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-bottom', 'auto')
											]));
								case 1:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin-top', 'auto')
											]));
								case 2:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-end')
											]));
								case 3:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'flex-start')
											]));
								case 4:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'center')
											]));
								default:
									return _Utils_Tuple2(
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
											]),
										_List_Nil);
							}
						}),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dk),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-grow', '0'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-self', 'stretch !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.os),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'space-between')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.lH),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', '-ms-grid'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						'.gp',
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j_),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Supports,
						_Utils_Tuple2('display', 'grid'),
						_List_fromArray(
							[
								_Utils_Tuple2('display', 'grid')
							])),
						$mdgriffith$elm_ui$Internal$Style$gridAlignments(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-start')
										]);
								case 1:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'flex-end')
										]);
								case 2:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-end')
										]);
								case 3:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'flex-start')
										]);
								case 4:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'align-items', 'center')
										]);
								default:
									return _List_fromArray(
										[
											A2($mdgriffith$elm_ui$Internal$Style$Prop, 'justify-content', 'center')
										]);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.h_),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j_ + ':first-child'),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot(
							$mdgriffith$elm_ui$Internal$Style$classes.j_ + ($mdgriffith$elm_ui$Internal$Style$selfName(3) + (':first-child + .' + $mdgriffith$elm_ui$Internal$Style$classes.j_))),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot(
							$mdgriffith$elm_ui$Internal$Style$classes.j_ + ($mdgriffith$elm_ui$Internal$Style$selfName(2) + (':first-child + .' + $mdgriffith$elm_ui$Internal$Style$classes.j_))),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'margin', '0 !important')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 1:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 2:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'right'),
												A2(
												$mdgriffith$elm_ui$Internal$Style$Descriptor,
												'::after',
												_List_fromArray(
													[
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', '\"\"'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'table'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'clear', 'both')
													]))
											]));
								case 3:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'left'),
												A2(
												$mdgriffith$elm_ui$Internal$Style$Descriptor,
												'::after',
												_List_fromArray(
													[
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', '\"\"'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'table'),
														A2($mdgriffith$elm_ui$Internal$Style$Prop, 'clear', 'both')
													]))
											]));
								case 4:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								default:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.l4),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap !important'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'background-color', 'transparent')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.l7),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.on),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'flex-basis', 'auto')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.l6),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap !important'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'cursor', 'text'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.l5),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'pre-wrap !important'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'color', 'transparent')
							]))
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.$8),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'block'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'overflow-wrap', 'break-word'),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Descriptor,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.eW),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '0'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j8),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'z-index', '-1')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$AllChildren,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cY),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$AllChildren,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.$8),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								'::after',
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', 'none')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								'::before',
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'content', 'none')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$AllChildren,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.on),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal'),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.js),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-block')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.l$),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j8),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.jE),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j9),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nd),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Descriptor,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.nb),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'flex')
									])),
								A2(
								$mdgriffith$elm_ui$Internal$Style$Child,
								$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.cY),
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline'),
										A2($mdgriffith$elm_ui$Internal$Style$Prop, 'white-space', 'normal')
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.n$),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.gv),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-flex')
							])),
						A2(
						$mdgriffith$elm_ui$Internal$Style$Child,
						$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.lH),
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'inline-grid')
							])),
						$mdgriffith$elm_ui$Internal$Style$describeAlignment(
						function (alignment) {
							switch (alignment) {
								case 0:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 1:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								case 2:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'right')
											]));
								case 3:
									return _Utils_Tuple2(
										_List_Nil,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Internal$Style$Prop, 'float', 'left')
											]));
								case 4:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
								default:
									return _Utils_Tuple2(_List_Nil, _List_Nil);
							}
						})
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				'.hidden',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'display', 'none')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.o6),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '100')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.oZ),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '200')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.o1),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '300')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.o3),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '400')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.o2),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '500')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.o5),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '600')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.ke),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '700')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.oY),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '800')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.o_),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-weight', '900')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.mn),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'italic')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.oC),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'line-through')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.pv),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'underline'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip-ink', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip', 'ink')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				_Utils_ap(
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.pv),
					$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.oC)),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration', 'line-through underline'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip-ink', 'auto'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-decoration-skip', 'ink')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.o7),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-style', 'normal')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.o$),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'justify')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.d5),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'justify-all')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.oX),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'center')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.o4),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'right')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				$mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.o0),
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'text-align', 'left')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Descriptor,
				'.modal',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'position', 'fixed'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'left', '0'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'top', '0'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'width', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'height', '100%'),
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'pointer-events', 'none')
					]))
			]))
	]);
var $mdgriffith$elm_ui$Internal$Style$fontVariant = function (_var) {
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Style$Class,
			'.v-' + _var,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', '\"' + (_var + '\"'))
				])),
			A2(
			$mdgriffith$elm_ui$Internal$Style$Class,
			'.v-' + (_var + '-off'),
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-feature-settings', '\"' + (_var + '\" 0'))
				]))
		]);
};
var $mdgriffith$elm_ui$Internal$Style$commonValues = $elm$core$List$concat(
	_List_fromArray(
		[
			A2(
			$elm$core$List$map,
			function (x) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.border-' + $elm$core$String$fromInt(x),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'border-width',
							$elm$core$String$fromInt(x) + 'px')
						]));
			},
			A2($elm$core$List$range, 0, 6)),
			A2(
			$elm$core$List$map,
			function (i) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.font-size-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'font-size',
							$elm$core$String$fromInt(i) + 'px')
						]));
			},
			A2($elm$core$List$range, 8, 32)),
			A2(
			$elm$core$List$map,
			function (i) {
				return A2(
					$mdgriffith$elm_ui$Internal$Style$Class,
					'.p-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Style$Prop,
							'padding',
							$elm$core$String$fromInt(i) + 'px')
						]));
			},
			A2($elm$core$List$range, 0, 24)),
			_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Internal$Style$Class,
				'.v-smcp',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-variant', 'small-caps')
					])),
				A2(
				$mdgriffith$elm_ui$Internal$Style$Class,
				'.v-smcp-off',
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Internal$Style$Prop, 'font-variant', 'normal')
					]))
			]),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('zero'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('onum'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('liga'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('dlig'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('ordn'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('tnum'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('afrc'),
			$mdgriffith$elm_ui$Internal$Style$fontVariant('frac')
		]));
var $mdgriffith$elm_ui$Internal$Style$explainer = '\n.explain {\n    border: 6px solid rgb(174, 121, 15) !important;\n}\n.explain > .' + ($mdgriffith$elm_ui$Internal$Style$classes.j_ + (' {\n    border: 4px dashed rgb(0, 151, 167) !important;\n}\n\n.ctr {\n    border: none !important;\n}\n.explain > .ctr > .' + ($mdgriffith$elm_ui$Internal$Style$classes.j_ + ' {\n    border: 4px dashed rgb(0, 151, 167) !important;\n}\n\n')));
var $mdgriffith$elm_ui$Internal$Style$inputTextReset = '\ninput[type="search"],\ninput[type="search"]::-webkit-search-decoration,\ninput[type="search"]::-webkit-search-cancel-button,\ninput[type="search"]::-webkit-search-results-button,\ninput[type="search"]::-webkit-search-results-decoration {\n  -webkit-appearance:none;\n}\n';
var $mdgriffith$elm_ui$Internal$Style$sliderReset = '\ninput[type=range] {\n  -webkit-appearance: none; \n  background: transparent;\n  position:absolute;\n  left:0;\n  top:0;\n  z-index:10;\n  width: 100%;\n  outline: dashed 1px;\n  height: 100%;\n  opacity: 0;\n}\n';
var $mdgriffith$elm_ui$Internal$Style$thumbReset = '\ninput[type=range]::-webkit-slider-thumb {\n    -webkit-appearance: none;\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range]::-moz-range-thumb {\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range]::-ms-thumb {\n    opacity: 0.5;\n    width: 80px;\n    height: 80px;\n    background-color: black;\n    border:none;\n    border-radius: 5px;\n}\ninput[type=range][orient=vertical]{\n    writing-mode: bt-lr; /* IE */\n    -webkit-appearance: slider-vertical;  /* WebKit */\n}\n';
var $mdgriffith$elm_ui$Internal$Style$trackReset = '\ninput[type=range]::-moz-range-track {\n    background: transparent;\n    cursor: pointer;\n}\ninput[type=range]::-ms-track {\n    background: transparent;\n    cursor: pointer;\n}\ninput[type=range]::-webkit-slider-runnable-track {\n    background: transparent;\n    cursor: pointer;\n}\n';
var $mdgriffith$elm_ui$Internal$Style$overrides = '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j_) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.n$) + (' > ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j_) + (' { flex-basis: auto !important; } ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j_) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.n$) + (' > ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j_) + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.dk) + (' { flex-basis: auto !important; }}' + ($mdgriffith$elm_ui$Internal$Style$inputTextReset + ($mdgriffith$elm_ui$Internal$Style$sliderReset + ($mdgriffith$elm_ui$Internal$Style$trackReset + ($mdgriffith$elm_ui$Internal$Style$thumbReset + $mdgriffith$elm_ui$Internal$Style$explainer)))))))))))))));
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $mdgriffith$elm_ui$Internal$Style$Intermediate = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Internal$Style$emptyIntermediate = F2(
	function (selector, closing) {
		return {di: closing, K: _List_Nil, bc: _List_Nil, aF: selector};
	});
var $mdgriffith$elm_ui$Internal$Style$renderRules = F2(
	function (_v0, rulesToRender) {
		var parent = _v0;
		var generateIntermediates = F2(
			function (rule, rendered) {
				switch (rule.$) {
					case 0:
						var name = rule.a;
						var val = rule.b;
						return _Utils_update(
							rendered,
							{
								bc: A2(
									$elm$core$List$cons,
									_Utils_Tuple2(name, val),
									rendered.bc)
							});
					case 3:
						var _v2 = rule.a;
						var prop = _v2.a;
						var value = _v2.b;
						var props = rule.b;
						return _Utils_update(
							rendered,
							{
								K: A2(
									$elm$core$List$cons,
									{di: '\n}', K: _List_Nil, bc: props, aF: '@supports (' + (prop + (':' + (value + (') {' + parent.aF))))},
									rendered.K)
							});
					case 5:
						var selector = rule.a;
						var adjRules = rule.b;
						return _Utils_update(
							rendered,
							{
								K: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.aF + (' + ' + selector), ''),
										adjRules),
									rendered.K)
							});
					case 1:
						var child = rule.a;
						var childRules = rule.b;
						return _Utils_update(
							rendered,
							{
								K: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.aF + (' > ' + child), ''),
										childRules),
									rendered.K)
							});
					case 2:
						var child = rule.a;
						var childRules = rule.b;
						return _Utils_update(
							rendered,
							{
								K: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.aF + (' ' + child), ''),
										childRules),
									rendered.K)
							});
					case 4:
						var descriptor = rule.a;
						var descriptorRules = rule.b;
						return _Utils_update(
							rendered,
							{
								K: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2(
											$mdgriffith$elm_ui$Internal$Style$emptyIntermediate,
											_Utils_ap(parent.aF, descriptor),
											''),
										descriptorRules),
									rendered.K)
							});
					default:
						var batched = rule.a;
						return _Utils_update(
							rendered,
							{
								K: A2(
									$elm$core$List$cons,
									A2(
										$mdgriffith$elm_ui$Internal$Style$renderRules,
										A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, parent.aF, ''),
										batched),
									rendered.K)
							});
				}
			});
		return A3($elm$core$List$foldr, generateIntermediates, parent, rulesToRender);
	});
var $mdgriffith$elm_ui$Internal$Style$renderCompact = function (styleClasses) {
	var renderValues = function (values) {
		return $elm$core$String$concat(
			A2(
				$elm$core$List$map,
				function (_v3) {
					var x = _v3.a;
					var y = _v3.b;
					return x + (':' + (y + ';'));
				},
				values));
	};
	var renderClass = function (rule) {
		var _v2 = rule.bc;
		if (!_v2.b) {
			return '';
		} else {
			return rule.aF + ('{' + (renderValues(rule.bc) + (rule.di + '}')));
		}
	};
	var renderIntermediate = function (_v0) {
		var rule = _v0;
		return _Utils_ap(
			renderClass(rule),
			$elm$core$String$concat(
				A2($elm$core$List$map, renderIntermediate, rule.K)));
	};
	return $elm$core$String$concat(
		A2(
			$elm$core$List$map,
			renderIntermediate,
			A3(
				$elm$core$List$foldr,
				F2(
					function (_v1, existing) {
						var name = _v1.a;
						var styleRules = _v1.b;
						return A2(
							$elm$core$List$cons,
							A2(
								$mdgriffith$elm_ui$Internal$Style$renderRules,
								A2($mdgriffith$elm_ui$Internal$Style$emptyIntermediate, name, ''),
								styleRules),
							existing);
					}),
				_List_Nil,
				styleClasses)));
};
var $mdgriffith$elm_ui$Internal$Style$rules = _Utils_ap(
	$mdgriffith$elm_ui$Internal$Style$overrides,
	$mdgriffith$elm_ui$Internal$Style$renderCompact(
		_Utils_ap($mdgriffith$elm_ui$Internal$Style$baseSheet, $mdgriffith$elm_ui$Internal$Style$commonValues)));
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $mdgriffith$elm_ui$Internal$Model$staticRoot = function (opts) {
	var _v0 = opts.bF;
	switch (_v0) {
		case 0:
			return A3(
				$elm$virtual_dom$VirtualDom$node,
				'div',
				_List_Nil,
				_List_fromArray(
					[
						A3(
						$elm$virtual_dom$VirtualDom$node,
						'style',
						_List_Nil,
						_List_fromArray(
							[
								$elm$virtual_dom$VirtualDom$text($mdgriffith$elm_ui$Internal$Style$rules)
							]))
					]));
		case 1:
			return $elm$virtual_dom$VirtualDom$text('');
		default:
			return A3(
				$elm$virtual_dom$VirtualDom$node,
				'elm-ui-static-rules',
				_List_fromArray(
					[
						A2(
						$elm$virtual_dom$VirtualDom$property,
						'rules',
						$elm$json$Json$Encode$string($mdgriffith$elm_ui$Internal$Style$rules))
					]),
				_List_Nil);
	}
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$fontName = function (font) {
	switch (font.$) {
		case 0:
			return 'serif';
		case 1:
			return 'sans-serif';
		case 2:
			return 'monospace';
		case 3:
			var name = font.a;
			return '\"' + (name + '\"');
		case 4:
			var name = font.a;
			var url = font.b;
			return '\"' + (name + '\"');
		default:
			var name = font.a.mO;
			return '\"' + (name + '\"');
	}
};
var $mdgriffith$elm_ui$Internal$Model$isSmallCaps = function (_var) {
	switch (_var.$) {
		case 0:
			var name = _var.a;
			return name === 'smcp';
		case 1:
			var name = _var.a;
			return false;
		default:
			var name = _var.a;
			var index = _var.b;
			return (name === 'smcp') && (index === 1);
	}
};
var $mdgriffith$elm_ui$Internal$Model$hasSmallCaps = function (typeface) {
	if (typeface.$ === 5) {
		var font = typeface.a;
		return A2($elm$core$List$any, $mdgriffith$elm_ui$Internal$Model$isSmallCaps, font.jm);
	} else {
		return false;
	}
};
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $mdgriffith$elm_ui$Internal$Model$renderProps = F3(
	function (force, _v0, existing) {
		var key = _v0.a;
		var val = _v0.b;
		return force ? (existing + ('\n  ' + (key + (': ' + (val + ' !important;'))))) : (existing + ('\n  ' + (key + (': ' + (val + ';')))));
	});
var $mdgriffith$elm_ui$Internal$Model$renderStyle = F4(
	function (options, maybePseudo, selector, props) {
		if (maybePseudo.$ === 1) {
			return _List_fromArray(
				[
					selector + ('{' + (A3(
					$elm$core$List$foldl,
					$mdgriffith$elm_ui$Internal$Model$renderProps(false),
					'',
					props) + '\n}'))
				]);
		} else {
			var pseudo = maybePseudo.a;
			switch (pseudo) {
				case 1:
					var _v2 = options.lT;
					switch (_v2) {
						case 0:
							return _List_Nil;
						case 2:
							return _List_fromArray(
								[
									selector + ('-hv {' + (A3(
									$elm$core$List$foldl,
									$mdgriffith$elm_ui$Internal$Model$renderProps(true),
									'',
									props) + '\n}'))
								]);
						default:
							return _List_fromArray(
								[
									selector + ('-hv:hover {' + (A3(
									$elm$core$List$foldl,
									$mdgriffith$elm_ui$Internal$Model$renderProps(false),
									'',
									props) + '\n}'))
								]);
					}
				case 0:
					var renderedProps = A3(
						$elm$core$List$foldl,
						$mdgriffith$elm_ui$Internal$Model$renderProps(false),
						'',
						props);
					return _List_fromArray(
						[
							selector + ('-fs:focus {' + (renderedProps + '\n}')),
							('.' + ($mdgriffith$elm_ui$Internal$Style$classes.j_ + (':focus ' + (selector + '-fs  {')))) + (renderedProps + '\n}'),
							(selector + '-fs:focus-within {') + (renderedProps + '\n}'),
							('.ui-slide-bar:focus + ' + ($mdgriffith$elm_ui$Internal$Style$dot($mdgriffith$elm_ui$Internal$Style$classes.j_) + (' .focusable-thumb' + (selector + '-fs {')))) + (renderedProps + '\n}')
						]);
				default:
					return _List_fromArray(
						[
							selector + ('-act:active {' + (A3(
							$elm$core$List$foldl,
							$mdgriffith$elm_ui$Internal$Model$renderProps(false),
							'',
							props) + '\n}'))
						]);
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$renderVariant = function (_var) {
	switch (_var.$) {
		case 0:
			var name = _var.a;
			return '\"' + (name + '\"');
		case 1:
			var name = _var.a;
			return '\"' + (name + '\" 0');
		default:
			var name = _var.a;
			var index = _var.b;
			return '\"' + (name + ('\" ' + $elm$core$String$fromInt(index)));
	}
};
var $mdgriffith$elm_ui$Internal$Model$renderVariants = function (typeface) {
	if (typeface.$ === 5) {
		var font = typeface.a;
		return $elm$core$Maybe$Just(
			A2(
				$elm$core$String$join,
				', ',
				A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$renderVariant, font.jm)));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$transformValue = function (transform) {
	switch (transform.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var _v1 = transform.a;
			var x = _v1.a;
			var y = _v1.b;
			var z = _v1.c;
			return $elm$core$Maybe$Just(
				'translate3d(' + ($elm$core$String$fromFloat(x) + ('px, ' + ($elm$core$String$fromFloat(y) + ('px, ' + ($elm$core$String$fromFloat(z) + 'px)'))))));
		default:
			var _v2 = transform.a;
			var tx = _v2.a;
			var ty = _v2.b;
			var tz = _v2.c;
			var _v3 = transform.b;
			var sx = _v3.a;
			var sy = _v3.b;
			var sz = _v3.c;
			var _v4 = transform.c;
			var ox = _v4.a;
			var oy = _v4.b;
			var oz = _v4.c;
			var angle = transform.d;
			var translate = 'translate3d(' + ($elm$core$String$fromFloat(tx) + ('px, ' + ($elm$core$String$fromFloat(ty) + ('px, ' + ($elm$core$String$fromFloat(tz) + 'px)')))));
			var scale = 'scale3d(' + ($elm$core$String$fromFloat(sx) + (', ' + ($elm$core$String$fromFloat(sy) + (', ' + ($elm$core$String$fromFloat(sz) + ')')))));
			var rotate = 'rotate3d(' + ($elm$core$String$fromFloat(ox) + (', ' + ($elm$core$String$fromFloat(oy) + (', ' + ($elm$core$String$fromFloat(oz) + (', ' + ($elm$core$String$fromFloat(angle) + 'rad)')))))));
			return $elm$core$Maybe$Just(translate + (' ' + (scale + (' ' + rotate))));
	}
};
var $mdgriffith$elm_ui$Internal$Model$renderStyleRule = F3(
	function (options, rule, maybePseudo) {
		switch (rule.$) {
			case 0:
				var selector = rule.a;
				var props = rule.b;
				return A4($mdgriffith$elm_ui$Internal$Model$renderStyle, options, maybePseudo, selector, props);
			case 13:
				var name = rule.a;
				var prop = rule.b;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + name,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Internal$Model$Property, 'box-shadow', prop)
						]));
			case 12:
				var name = rule.a;
				var transparency = rule.b;
				var opacity = A2(
					$elm$core$Basics$max,
					0,
					A2($elm$core$Basics$min, 1, 1 - transparency));
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + name,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'opacity',
							$elm$core$String$fromFloat(opacity))
						]));
			case 2:
				var i = rule.a;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.font-size-' + $elm$core$String$fromInt(i),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'font-size',
							$elm$core$String$fromInt(i) + 'px')
						]));
			case 1:
				var name = rule.a;
				var typefaces = rule.b;
				var features = A2(
					$elm$core$String$join,
					', ',
					A2($elm$core$List$filterMap, $mdgriffith$elm_ui$Internal$Model$renderVariants, typefaces));
				var families = _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Internal$Model$Property,
						'font-family',
						A2(
							$elm$core$String$join,
							', ',
							A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$fontName, typefaces))),
						A2($mdgriffith$elm_ui$Internal$Model$Property, 'font-feature-settings', features),
						A2(
						$mdgriffith$elm_ui$Internal$Model$Property,
						'font-variant',
						A2($elm$core$List$any, $mdgriffith$elm_ui$Internal$Model$hasSmallCaps, typefaces) ? 'small-caps' : 'normal')
					]);
				return A4($mdgriffith$elm_ui$Internal$Model$renderStyle, options, maybePseudo, '.' + name, families);
			case 3:
				var _class = rule.a;
				var prop = rule.b;
				var val = rule.c;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + _class,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Internal$Model$Property, prop, val)
						]));
			case 4:
				var _class = rule.a;
				var prop = rule.b;
				var color = rule.c;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					'.' + _class,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							prop,
							$mdgriffith$elm_ui$Internal$Model$formatColor(color))
						]));
			case 5:
				var cls = rule.a;
				var x = rule.b;
				var y = rule.c;
				var yPx = $elm$core$String$fromInt(y) + 'px';
				var xPx = $elm$core$String$fromInt(x) + 'px';
				var single = '.' + $mdgriffith$elm_ui$Internal$Style$classes.on;
				var row = '.' + $mdgriffith$elm_ui$Internal$Style$classes.n$;
				var wrappedRow = '.' + ($mdgriffith$elm_ui$Internal$Style$classes.f3 + row);
				var right = '.' + $mdgriffith$elm_ui$Internal$Style$classes.ge;
				var paragraph = '.' + $mdgriffith$elm_ui$Internal$Style$classes.$8;
				var page = '.' + $mdgriffith$elm_ui$Internal$Style$classes.h_;
				var left = '.' + $mdgriffith$elm_ui$Internal$Style$classes.gd;
				var halfY = $elm$core$String$fromFloat(y / 2) + 'px';
				var halfX = $elm$core$String$fromFloat(x / 2) + 'px';
				var column = '.' + $mdgriffith$elm_ui$Internal$Style$classes.gv;
				var _class = '.' + cls;
				var any = '.' + $mdgriffith$elm_ui$Internal$Style$classes.j_;
				return $elm$core$List$concat(
					_List_fromArray(
						[
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (row + (' > ' + (any + (' + ' + any)))),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (wrappedRow + (' > ' + any)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin', halfY + (' ' + halfX))
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (column + (' > ' + (any + (' + ' + any)))),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-top', yPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (page + (' > ' + (any + (' + ' + any)))),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-top', yPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (page + (' > ' + left)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-right', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (page + (' > ' + right)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_Utils_ap(_class, paragraph),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'line-height',
									'calc(1em + ' + ($elm$core$String$fromInt(y) + 'px)'))
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							'textarea' + (any + _class),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'line-height',
									'calc(1em + ' + ($elm$core$String$fromInt(y) + 'px)')),
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'height',
									'calc(100% + ' + ($elm$core$String$fromInt(y) + 'px)'))
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + (' > ' + left)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-right', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + (' > ' + right)),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'margin-left', xPx)
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + '::after'),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'content', '\'\''),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'display', 'block'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'height', '0'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'width', '0'),
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'margin-top',
									$elm$core$String$fromInt((-1) * ((y / 2) | 0)) + 'px')
								])),
							A4(
							$mdgriffith$elm_ui$Internal$Model$renderStyle,
							options,
							maybePseudo,
							_class + (paragraph + '::before'),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'content', '\'\''),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'display', 'block'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'height', '0'),
									A2($mdgriffith$elm_ui$Internal$Model$Property, 'width', '0'),
									A2(
									$mdgriffith$elm_ui$Internal$Model$Property,
									'margin-bottom',
									$elm$core$String$fromInt((-1) * ((y / 2) | 0)) + 'px')
								]))
						]));
			case 7:
				var cls = rule.a;
				var top = rule.b;
				var right = rule.c;
				var bottom = rule.d;
				var left = rule.e;
				var _class = '.' + cls;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					_class,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'padding',
							$elm$core$String$fromFloat(top) + ('px ' + ($elm$core$String$fromFloat(right) + ('px ' + ($elm$core$String$fromFloat(bottom) + ('px ' + ($elm$core$String$fromFloat(left) + 'px')))))))
						]));
			case 6:
				var cls = rule.a;
				var top = rule.b;
				var right = rule.c;
				var bottom = rule.d;
				var left = rule.e;
				var _class = '.' + cls;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$renderStyle,
					options,
					maybePseudo,
					_class,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$Property,
							'border-width',
							$elm$core$String$fromInt(top) + ('px ' + ($elm$core$String$fromInt(right) + ('px ' + ($elm$core$String$fromInt(bottom) + ('px ' + ($elm$core$String$fromInt(left) + 'px')))))))
						]));
			case 8:
				var template = rule.a;
				var toGridLengthHelper = F3(
					function (minimum, maximum, x) {
						toGridLengthHelper:
						while (true) {
							switch (x.$) {
								case 0:
									var px = x.a;
									return $elm$core$String$fromInt(px) + 'px';
								case 1:
									var _v2 = _Utils_Tuple2(minimum, maximum);
									if (_v2.a.$ === 1) {
										if (_v2.b.$ === 1) {
											var _v3 = _v2.a;
											var _v4 = _v2.b;
											return 'max-content';
										} else {
											var _v6 = _v2.a;
											var maxSize = _v2.b.a;
											return 'minmax(max-content, ' + ($elm$core$String$fromInt(maxSize) + 'px)');
										}
									} else {
										if (_v2.b.$ === 1) {
											var minSize = _v2.a.a;
											var _v5 = _v2.b;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + 'max-content)'));
										} else {
											var minSize = _v2.a.a;
											var maxSize = _v2.b.a;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(maxSize) + 'px)')));
										}
									}
								case 2:
									var i = x.a;
									var _v7 = _Utils_Tuple2(minimum, maximum);
									if (_v7.a.$ === 1) {
										if (_v7.b.$ === 1) {
											var _v8 = _v7.a;
											var _v9 = _v7.b;
											return $elm$core$String$fromInt(i) + 'fr';
										} else {
											var _v11 = _v7.a;
											var maxSize = _v7.b.a;
											return 'minmax(max-content, ' + ($elm$core$String$fromInt(maxSize) + 'px)');
										}
									} else {
										if (_v7.b.$ === 1) {
											var minSize = _v7.a.a;
											var _v10 = _v7.b;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(i) + ('fr' + 'fr)'))));
										} else {
											var minSize = _v7.a.a;
											var maxSize = _v7.b.a;
											return 'minmax(' + ($elm$core$String$fromInt(minSize) + ('px, ' + ($elm$core$String$fromInt(maxSize) + 'px)')));
										}
									}
								case 3:
									var m = x.a;
									var len = x.b;
									var $temp$minimum = $elm$core$Maybe$Just(m),
										$temp$maximum = maximum,
										$temp$x = len;
									minimum = $temp$minimum;
									maximum = $temp$maximum;
									x = $temp$x;
									continue toGridLengthHelper;
								default:
									var m = x.a;
									var len = x.b;
									var $temp$minimum = minimum,
										$temp$maximum = $elm$core$Maybe$Just(m),
										$temp$x = len;
									minimum = $temp$minimum;
									maximum = $temp$maximum;
									x = $temp$x;
									continue toGridLengthHelper;
							}
						}
					});
				var toGridLength = function (x) {
					return A3(toGridLengthHelper, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, x);
				};
				var xSpacing = toGridLength(template.ou.a);
				var ySpacing = toGridLength(template.ou.b);
				var rows = function (x) {
					return 'grid-template-rows: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						' ',
						A2($elm$core$List$map, toGridLength, template.n0)));
				var msRows = function (x) {
					return '-ms-grid-rows: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						ySpacing,
						A2($elm$core$List$map, toGridLength, template.kC)));
				var msColumns = function (x) {
					return '-ms-grid-columns: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						ySpacing,
						A2($elm$core$List$map, toGridLength, template.kC)));
				var gapY = 'grid-row-gap:' + (toGridLength(template.ou.b) + ';');
				var gapX = 'grid-column-gap:' + (toGridLength(template.ou.a) + ';');
				var columns = function (x) {
					return 'grid-template-columns: ' + (x + ';');
				}(
					A2(
						$elm$core$String$join,
						' ',
						A2($elm$core$List$map, toGridLength, template.kC)));
				var _class = '.grid-rows-' + (A2(
					$elm$core$String$join,
					'-',
					A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.n0)) + ('-cols-' + (A2(
					$elm$core$String$join,
					'-',
					A2($elm$core$List$map, $mdgriffith$elm_ui$Internal$Model$lengthClassName, template.kC)) + ('-space-x-' + ($mdgriffith$elm_ui$Internal$Model$lengthClassName(template.ou.a) + ('-space-y-' + $mdgriffith$elm_ui$Internal$Model$lengthClassName(template.ou.b)))))));
				var modernGrid = _class + ('{' + (columns + (rows + (gapX + (gapY + '}')))));
				var supports = '@supports (display:grid) {' + (modernGrid + '}');
				var base = _class + ('{' + (msColumns + (msRows + '}')));
				return _List_fromArray(
					[base, supports]);
			case 9:
				var position = rule.a;
				var msPosition = A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[
							'-ms-grid-row: ' + ($elm$core$String$fromInt(position.n$) + ';'),
							'-ms-grid-row-span: ' + ($elm$core$String$fromInt(position.he) + ';'),
							'-ms-grid-column: ' + ($elm$core$String$fromInt(position.kB) + ';'),
							'-ms-grid-column-span: ' + ($elm$core$String$fromInt(position.ec) + ';')
						]));
				var modernPosition = A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[
							'grid-row: ' + ($elm$core$String$fromInt(position.n$) + (' / ' + ($elm$core$String$fromInt(position.n$ + position.he) + ';'))),
							'grid-column: ' + ($elm$core$String$fromInt(position.kB) + (' / ' + ($elm$core$String$fromInt(position.kB + position.ec) + ';')))
						]));
				var _class = '.grid-pos-' + ($elm$core$String$fromInt(position.n$) + ('-' + ($elm$core$String$fromInt(position.kB) + ('-' + ($elm$core$String$fromInt(position.ec) + ('-' + $elm$core$String$fromInt(position.he)))))));
				var modernGrid = _class + ('{' + (modernPosition + '}'));
				var supports = '@supports (display:grid) {' + (modernGrid + '}');
				var base = _class + ('{' + (msPosition + '}'));
				return _List_fromArray(
					[base, supports]);
			case 11:
				var _class = rule.a;
				var styles = rule.b;
				var renderPseudoRule = function (style) {
					return A3(
						$mdgriffith$elm_ui$Internal$Model$renderStyleRule,
						options,
						style,
						$elm$core$Maybe$Just(_class));
				};
				return A2($elm$core$List$concatMap, renderPseudoRule, styles);
			default:
				var transform = rule.a;
				var val = $mdgriffith$elm_ui$Internal$Model$transformValue(transform);
				var _class = $mdgriffith$elm_ui$Internal$Model$transformClass(transform);
				var _v12 = _Utils_Tuple2(_class, val);
				if ((!_v12.a.$) && (!_v12.b.$)) {
					var cls = _v12.a.a;
					var v = _v12.b.a;
					return A4(
						$mdgriffith$elm_ui$Internal$Model$renderStyle,
						options,
						maybePseudo,
						'.' + cls,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Internal$Model$Property, 'transform', v)
							]));
				} else {
					return _List_Nil;
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$encodeStyles = F2(
	function (options, stylesheet) {
		return $elm$json$Json$Encode$object(
			A2(
				$elm$core$List$map,
				function (style) {
					var styled = A3($mdgriffith$elm_ui$Internal$Model$renderStyleRule, options, style, $elm$core$Maybe$Nothing);
					return _Utils_Tuple2(
						$mdgriffith$elm_ui$Internal$Model$getStyleName(style),
						A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, styled));
				},
				stylesheet));
	});
var $mdgriffith$elm_ui$Internal$Model$bracket = F2(
	function (selector, rules) {
		var renderPair = function (_v0) {
			var name = _v0.a;
			var val = _v0.b;
			return name + (': ' + (val + ';'));
		};
		return selector + (' {' + (A2(
			$elm$core$String$join,
			'',
			A2($elm$core$List$map, renderPair, rules)) + '}'));
	});
var $mdgriffith$elm_ui$Internal$Model$fontRule = F3(
	function (name, modifier, _v0) {
		var parentAdj = _v0.a;
		var textAdjustment = _v0.b;
		return _List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Model$bracket, '.' + (name + ('.' + (modifier + (', ' + ('.' + (name + (' .' + modifier))))))), parentAdj),
				A2($mdgriffith$elm_ui$Internal$Model$bracket, '.' + (name + ('.' + (modifier + ('> .' + ($mdgriffith$elm_ui$Internal$Style$classes.cY + (', .' + (name + (' .' + (modifier + (' > .' + $mdgriffith$elm_ui$Internal$Style$classes.cY)))))))))), textAdjustment)
			]);
	});
var $mdgriffith$elm_ui$Internal$Model$renderFontAdjustmentRule = F3(
	function (fontToAdjust, _v0, otherFontName) {
		var full = _v0.a;
		var capital = _v0.b;
		var name = _Utils_eq(fontToAdjust, otherFontName) ? fontToAdjust : (otherFontName + (' .' + fontToAdjust));
		return A2(
			$elm$core$String$join,
			' ',
			_Utils_ap(
				A3($mdgriffith$elm_ui$Internal$Model$fontRule, name, $mdgriffith$elm_ui$Internal$Style$classes.oo, capital),
				A3($mdgriffith$elm_ui$Internal$Model$fontRule, name, $mdgriffith$elm_ui$Internal$Style$classes.lC, full)));
	});
var $mdgriffith$elm_ui$Internal$Model$renderNullAdjustmentRule = F2(
	function (fontToAdjust, otherFontName) {
		var name = _Utils_eq(fontToAdjust, otherFontName) ? fontToAdjust : (otherFontName + (' .' + fontToAdjust));
		return A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Internal$Model$bracket,
					'.' + (name + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.oo + (', ' + ('.' + (name + (' .' + $mdgriffith$elm_ui$Internal$Style$classes.oo))))))),
					_List_fromArray(
						[
							_Utils_Tuple2('line-height', '1')
						])),
					A2(
					$mdgriffith$elm_ui$Internal$Model$bracket,
					'.' + (name + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.oo + ('> .' + ($mdgriffith$elm_ui$Internal$Style$classes.cY + (', .' + (name + (' .' + ($mdgriffith$elm_ui$Internal$Style$classes.oo + (' > .' + $mdgriffith$elm_ui$Internal$Style$classes.cY)))))))))),
					_List_fromArray(
						[
							_Utils_Tuple2('vertical-align', '0'),
							_Utils_Tuple2('line-height', '1')
						]))
				]));
	});
var $mdgriffith$elm_ui$Internal$Model$adjust = F3(
	function (size, height, vertical) {
		return {he: height / size, iT: size, jn: vertical};
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$max, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$List$minimum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$min, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Basics$neq = _Utils_notEqual;
var $mdgriffith$elm_ui$Internal$Model$convertAdjustment = function (adjustment) {
	var lines = _List_fromArray(
		[adjustment.kp, adjustment.j7, adjustment.k9, adjustment.mw]);
	var lineHeight = 1.5;
	var normalDescender = (lineHeight - 1) / 2;
	var oldMiddle = lineHeight / 2;
	var descender = A2(
		$elm$core$Maybe$withDefault,
		adjustment.k9,
		$elm$core$List$minimum(lines));
	var newBaseline = A2(
		$elm$core$Maybe$withDefault,
		adjustment.j7,
		$elm$core$List$minimum(
			A2(
				$elm$core$List$filter,
				function (x) {
					return !_Utils_eq(x, descender);
				},
				lines)));
	var base = lineHeight;
	var ascender = A2(
		$elm$core$Maybe$withDefault,
		adjustment.kp,
		$elm$core$List$maximum(lines));
	var capitalSize = 1 / (ascender - newBaseline);
	var capitalVertical = 1 - ascender;
	var fullSize = 1 / (ascender - descender);
	var fullVertical = 1 - ascender;
	var newCapitalMiddle = ((ascender - newBaseline) / 2) + newBaseline;
	var newFullMiddle = ((ascender - descender) / 2) + descender;
	return {
		kp: A3($mdgriffith$elm_ui$Internal$Model$adjust, capitalSize, ascender - newBaseline, capitalVertical),
		g9: A3($mdgriffith$elm_ui$Internal$Model$adjust, fullSize, ascender - descender, fullVertical)
	};
};
var $mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules = function (converted) {
	return _Utils_Tuple2(
		_List_fromArray(
			[
				_Utils_Tuple2('display', 'block')
			]),
		_List_fromArray(
			[
				_Utils_Tuple2('display', 'inline-block'),
				_Utils_Tuple2(
				'line-height',
				$elm$core$String$fromFloat(converted.he)),
				_Utils_Tuple2(
				'vertical-align',
				$elm$core$String$fromFloat(converted.jn) + 'em'),
				_Utils_Tuple2(
				'font-size',
				$elm$core$String$fromFloat(converted.iT) + 'em')
			]));
};
var $mdgriffith$elm_ui$Internal$Model$typefaceAdjustment = function (typefaces) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (face, found) {
				if (found.$ === 1) {
					if (face.$ === 5) {
						var _with = face.a;
						var _v2 = _with.jL;
						if (_v2.$ === 1) {
							return found;
						} else {
							var adjustment = _v2.a;
							return $elm$core$Maybe$Just(
								_Utils_Tuple2(
									$mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules(
										function ($) {
											return $.g9;
										}(
											$mdgriffith$elm_ui$Internal$Model$convertAdjustment(adjustment))),
									$mdgriffith$elm_ui$Internal$Model$fontAdjustmentRules(
										function ($) {
											return $.kp;
										}(
											$mdgriffith$elm_ui$Internal$Model$convertAdjustment(adjustment)))));
						}
					} else {
						return found;
					}
				} else {
					return found;
				}
			}),
		$elm$core$Maybe$Nothing,
		typefaces);
};
var $mdgriffith$elm_ui$Internal$Model$renderTopLevelValues = function (rules) {
	var withImport = function (font) {
		if (font.$ === 4) {
			var url = font.b;
			return $elm$core$Maybe$Just('@import url(\'' + (url + '\');'));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	var fontImports = function (_v2) {
		var name = _v2.a;
		var typefaces = _v2.b;
		var imports = A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$filterMap, withImport, typefaces));
		return imports;
	};
	var allNames = A2($elm$core$List$map, $elm$core$Tuple$first, rules);
	var fontAdjustments = function (_v1) {
		var name = _v1.a;
		var typefaces = _v1.b;
		var _v0 = $mdgriffith$elm_ui$Internal$Model$typefaceAdjustment(typefaces);
		if (_v0.$ === 1) {
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$map,
					$mdgriffith$elm_ui$Internal$Model$renderNullAdjustmentRule(name),
					allNames));
		} else {
			var adjustment = _v0.a;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$map,
					A2($mdgriffith$elm_ui$Internal$Model$renderFontAdjustmentRule, name, adjustment),
					allNames));
		}
	};
	return _Utils_ap(
		A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$map, fontImports, rules)),
		A2(
			$elm$core$String$join,
			'\n',
			A2($elm$core$List$map, fontAdjustments, rules)));
};
var $mdgriffith$elm_ui$Internal$Model$topLevelValue = function (rule) {
	if (rule.$ === 1) {
		var name = rule.a;
		var typefaces = rule.b;
		return $elm$core$Maybe$Just(
			_Utils_Tuple2(name, typefaces));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Internal$Model$toStyleSheetString = F2(
	function (options, stylesheet) {
		var combine = F2(
			function (style, rendered) {
				return {
					dX: _Utils_ap(
						rendered.dX,
						A3($mdgriffith$elm_ui$Internal$Model$renderStyleRule, options, style, $elm$core$Maybe$Nothing)),
					c_: function () {
						var _v1 = $mdgriffith$elm_ui$Internal$Model$topLevelValue(style);
						if (_v1.$ === 1) {
							return rendered.c_;
						} else {
							var topLevel = _v1.a;
							return A2($elm$core$List$cons, topLevel, rendered.c_);
						}
					}()
				};
			});
		var _v0 = A3(
			$elm$core$List$foldl,
			combine,
			{dX: _List_Nil, c_: _List_Nil},
			stylesheet);
		var topLevel = _v0.c_;
		var rules = _v0.dX;
		return _Utils_ap(
			$mdgriffith$elm_ui$Internal$Model$renderTopLevelValues(topLevel),
			$elm$core$String$concat(rules));
	});
var $mdgriffith$elm_ui$Internal$Model$toStyleSheet = F2(
	function (options, styleSheet) {
		var _v0 = options.bF;
		switch (_v0) {
			case 0:
				return A3(
					$elm$virtual_dom$VirtualDom$node,
					'div',
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$elm$virtual_dom$VirtualDom$node,
							'style',
							_List_Nil,
							_List_fromArray(
								[
									$elm$virtual_dom$VirtualDom$text(
									A2($mdgriffith$elm_ui$Internal$Model$toStyleSheetString, options, styleSheet))
								]))
						]));
			case 1:
				return A3(
					$elm$virtual_dom$VirtualDom$node,
					'div',
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$elm$virtual_dom$VirtualDom$node,
							'style',
							_List_Nil,
							_List_fromArray(
								[
									$elm$virtual_dom$VirtualDom$text(
									A2($mdgriffith$elm_ui$Internal$Model$toStyleSheetString, options, styleSheet))
								]))
						]));
			default:
				return A3(
					$elm$virtual_dom$VirtualDom$node,
					'elm-ui-rules',
					_List_fromArray(
						[
							A2(
							$elm$virtual_dom$VirtualDom$property,
							'rules',
							A2($mdgriffith$elm_ui$Internal$Model$encodeStyles, options, styleSheet))
						]),
					_List_Nil);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$embedKeyed = F4(
	function (_static, opts, styles, children) {
		var dynamicStyleSheet = A2(
			$mdgriffith$elm_ui$Internal$Model$toStyleSheet,
			opts,
			A3(
				$elm$core$List$foldl,
				$mdgriffith$elm_ui$Internal$Model$reduceStyles,
				_Utils_Tuple2(
					$elm$core$Set$empty,
					$mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.lA)),
				styles).b);
		return _static ? A2(
			$elm$core$List$cons,
			_Utils_Tuple2(
				'static-stylesheet',
				$mdgriffith$elm_ui$Internal$Model$staticRoot(opts)),
			A2(
				$elm$core$List$cons,
				_Utils_Tuple2('dynamic-stylesheet', dynamicStyleSheet),
				children)) : A2(
			$elm$core$List$cons,
			_Utils_Tuple2('dynamic-stylesheet', dynamicStyleSheet),
			children);
	});
var $mdgriffith$elm_ui$Internal$Model$embedWith = F4(
	function (_static, opts, styles, children) {
		var dynamicStyleSheet = A2(
			$mdgriffith$elm_ui$Internal$Model$toStyleSheet,
			opts,
			A3(
				$elm$core$List$foldl,
				$mdgriffith$elm_ui$Internal$Model$reduceStyles,
				_Utils_Tuple2(
					$elm$core$Set$empty,
					$mdgriffith$elm_ui$Internal$Model$renderFocusStyle(opts.lA)),
				styles).b);
		return _static ? A2(
			$elm$core$List$cons,
			$mdgriffith$elm_ui$Internal$Model$staticRoot(opts),
			A2($elm$core$List$cons, dynamicStyleSheet, children)) : A2($elm$core$List$cons, dynamicStyleSheet, children);
	});
var $mdgriffith$elm_ui$Internal$Flag$heightBetween = $mdgriffith$elm_ui$Internal$Flag$flag(45);
var $mdgriffith$elm_ui$Internal$Flag$heightFill = $mdgriffith$elm_ui$Internal$Flag$flag(37);
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$core$Basics$not = _Basics_not;
var $elm$html$Html$p = _VirtualDom_node('p');
var $elm$core$Bitwise$and = _Bitwise_and;
var $mdgriffith$elm_ui$Internal$Flag$present = F2(
	function (myFlag, _v0) {
		var fieldOne = _v0.a;
		var fieldTwo = _v0.b;
		if (!myFlag.$) {
			var first = myFlag.a;
			return _Utils_eq(first & fieldOne, first);
		} else {
			var second = myFlag.a;
			return _Utils_eq(second & fieldTwo, second);
		}
	});
var $elm$html$Html$s = _VirtualDom_node('s');
var $elm$html$Html$u = _VirtualDom_node('u');
var $mdgriffith$elm_ui$Internal$Flag$widthBetween = $mdgriffith$elm_ui$Internal$Flag$flag(44);
var $mdgriffith$elm_ui$Internal$Flag$widthFill = $mdgriffith$elm_ui$Internal$Flag$flag(39);
var $mdgriffith$elm_ui$Internal$Model$finalizeNode = F6(
	function (has, node, attributes, children, embedMode, parentContext) {
		var createNode = F2(
			function (nodeName, attrs) {
				if (children.$ === 1) {
					var keyed = children.a;
					return A3(
						$elm$virtual_dom$VirtualDom$keyedNode,
						nodeName,
						attrs,
						function () {
							switch (embedMode.$) {
								case 0:
									return keyed;
								case 2:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedKeyed, false, opts, styles, keyed);
								default:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedKeyed, true, opts, styles, keyed);
							}
						}());
				} else {
					var unkeyed = children.a;
					return A2(
						function () {
							switch (nodeName) {
								case 'div':
									return $elm$html$Html$div;
								case 'p':
									return $elm$html$Html$p;
								default:
									return $elm$virtual_dom$VirtualDom$node(nodeName);
							}
						}(),
						attrs,
						function () {
							switch (embedMode.$) {
								case 0:
									return unkeyed;
								case 2:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedWith, false, opts, styles, unkeyed);
								default:
									var opts = embedMode.a;
									var styles = embedMode.b;
									return A4($mdgriffith$elm_ui$Internal$Model$embedWith, true, opts, styles, unkeyed);
							}
						}());
				}
			});
		var html = function () {
			switch (node.$) {
				case 0:
					return A2(createNode, 'div', attributes);
				case 1:
					var nodeName = node.a;
					return A2(createNode, nodeName, attributes);
				default:
					var nodeName = node.a;
					var internal = node.b;
					return A3(
						$elm$virtual_dom$VirtualDom$node,
						nodeName,
						attributes,
						_List_fromArray(
							[
								A2(
								createNode,
								internal,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Style$classes.j_ + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.on))
									]))
							]));
			}
		}();
		switch (parentContext) {
			case 0:
				return (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$widthFill, has) && (!A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$widthBetween, has))) ? html : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$alignRight, has) ? A2(
					$elm$html$Html$u,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.j_, $mdgriffith$elm_ui$Internal$Style$classes.on, $mdgriffith$elm_ui$Internal$Style$classes.dk, $mdgriffith$elm_ui$Internal$Style$classes.a1, $mdgriffith$elm_ui$Internal$Style$classes.jT])))
						]),
					_List_fromArray(
						[html])) : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$centerX, has) ? A2(
					$elm$html$Html$s,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.j_, $mdgriffith$elm_ui$Internal$Style$classes.on, $mdgriffith$elm_ui$Internal$Style$classes.dk, $mdgriffith$elm_ui$Internal$Style$classes.a1, $mdgriffith$elm_ui$Internal$Style$classes.jR])))
						]),
					_List_fromArray(
						[html])) : html));
			case 1:
				return (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$heightFill, has) && (!A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$heightBetween, has))) ? html : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$centerY, has) ? A2(
					$elm$html$Html$s,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.j_, $mdgriffith$elm_ui$Internal$Style$classes.on, $mdgriffith$elm_ui$Internal$Style$classes.dk, $mdgriffith$elm_ui$Internal$Style$classes.jS])))
						]),
					_List_fromArray(
						[html])) : (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$alignBottom, has) ? A2(
					$elm$html$Html$u,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									[$mdgriffith$elm_ui$Internal$Style$classes.j_, $mdgriffith$elm_ui$Internal$Style$classes.on, $mdgriffith$elm_ui$Internal$Style$classes.dk, $mdgriffith$elm_ui$Internal$Style$classes.jQ])))
						]),
					_List_fromArray(
						[html])) : html));
			default:
				return html;
		}
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $mdgriffith$elm_ui$Internal$Model$textElementClasses = $mdgriffith$elm_ui$Internal$Style$classes.j_ + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.cY + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.f0 + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.eX)))));
var $mdgriffith$elm_ui$Internal$Model$textElement = function (str) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Model$textElementClasses)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $mdgriffith$elm_ui$Internal$Model$textElementFillClasses = $mdgriffith$elm_ui$Internal$Style$classes.j_ + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.cY + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.f1 + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.eY)))));
var $mdgriffith$elm_ui$Internal$Model$textElementFill = function (str) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Model$textElementFillClasses)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $mdgriffith$elm_ui$Internal$Model$createElement = F3(
	function (context, children, rendered) {
		var gatherKeyed = F2(
			function (_v8, _v9) {
				var key = _v8.a;
				var child = _v8.b;
				var htmls = _v9.a;
				var existingStyles = _v9.b;
				switch (child.$) {
					case 0:
						var html = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									html(context)),
								htmls),
							existingStyles) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									html(context)),
								htmls),
							existingStyles);
					case 1:
						var styled = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									A2(styled.lV, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context)),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.i1 : _Utils_ap(styled.i1, existingStyles)) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									A2(styled.lV, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context)),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.i1 : _Utils_ap(styled.i1, existingStyles));
					case 2:
						var str = child.a;
						return _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									key,
									_Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asEl) ? $mdgriffith$elm_ui$Internal$Model$textElementFill(str) : $mdgriffith$elm_ui$Internal$Model$textElement(str)),
								htmls),
							existingStyles);
					default:
						return _Utils_Tuple2(htmls, existingStyles);
				}
			});
		var gather = F2(
			function (child, _v6) {
				var htmls = _v6.a;
				var existingStyles = _v6.b;
				switch (child.$) {
					case 0:
						var html = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								html(context),
								htmls),
							existingStyles) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								html(context),
								htmls),
							existingStyles);
					case 1:
						var styled = child.a;
						return _Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asParagraph) ? _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								A2(styled.lV, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.i1 : _Utils_ap(styled.i1, existingStyles)) : _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								A2(styled.lV, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, context),
								htmls),
							$elm$core$List$isEmpty(existingStyles) ? styled.i1 : _Utils_ap(styled.i1, existingStyles));
					case 2:
						var str = child.a;
						return _Utils_Tuple2(
							A2(
								$elm$core$List$cons,
								_Utils_eq(context, $mdgriffith$elm_ui$Internal$Model$asEl) ? $mdgriffith$elm_ui$Internal$Model$textElementFill(str) : $mdgriffith$elm_ui$Internal$Model$textElement(str),
								htmls),
							existingStyles);
					default:
						return _Utils_Tuple2(htmls, existingStyles);
				}
			});
		if (children.$ === 1) {
			var keyedChildren = children.a;
			var _v1 = A3(
				$elm$core$List$foldr,
				gatherKeyed,
				_Utils_Tuple2(_List_Nil, _List_Nil),
				keyedChildren);
			var keyed = _v1.a;
			var styles = _v1.b;
			var newStyles = $elm$core$List$isEmpty(styles) ? rendered.i1 : _Utils_ap(rendered.i1, styles);
			if (!newStyles.b) {
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A5(
						$mdgriffith$elm_ui$Internal$Model$finalizeNode,
						rendered.bu,
						rendered.mW,
						rendered.em,
						$mdgriffith$elm_ui$Internal$Model$Keyed(
							A3($mdgriffith$elm_ui$Internal$Model$addKeyedChildren, 'nearby-element-pls', keyed, rendered.eA)),
						$mdgriffith$elm_ui$Internal$Model$NoStyleSheet));
			} else {
				var allStyles = newStyles;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						lV: A4(
							$mdgriffith$elm_ui$Internal$Model$finalizeNode,
							rendered.bu,
							rendered.mW,
							rendered.em,
							$mdgriffith$elm_ui$Internal$Model$Keyed(
								A3($mdgriffith$elm_ui$Internal$Model$addKeyedChildren, 'nearby-element-pls', keyed, rendered.eA))),
						i1: allStyles
					});
			}
		} else {
			var unkeyedChildren = children.a;
			var _v3 = A3(
				$elm$core$List$foldr,
				gather,
				_Utils_Tuple2(_List_Nil, _List_Nil),
				unkeyedChildren);
			var unkeyed = _v3.a;
			var styles = _v3.b;
			var newStyles = $elm$core$List$isEmpty(styles) ? rendered.i1 : _Utils_ap(rendered.i1, styles);
			if (!newStyles.b) {
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A5(
						$mdgriffith$elm_ui$Internal$Model$finalizeNode,
						rendered.bu,
						rendered.mW,
						rendered.em,
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							A2($mdgriffith$elm_ui$Internal$Model$addChildren, unkeyed, rendered.eA)),
						$mdgriffith$elm_ui$Internal$Model$NoStyleSheet));
			} else {
				var allStyles = newStyles;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						lV: A4(
							$mdgriffith$elm_ui$Internal$Model$finalizeNode,
							rendered.bu,
							rendered.mW,
							rendered.em,
							$mdgriffith$elm_ui$Internal$Model$Unkeyed(
								A2($mdgriffith$elm_ui$Internal$Model$addChildren, unkeyed, rendered.eA))),
						i1: allStyles
					});
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Single = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$Transform = function (a) {
	return {$: 10, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Bitwise$or = _Bitwise_or;
var $mdgriffith$elm_ui$Internal$Flag$add = F2(
	function (myFlag, _v0) {
		var one = _v0.a;
		var two = _v0.b;
		if (!myFlag.$) {
			var first = myFlag.a;
			return A2($mdgriffith$elm_ui$Internal$Flag$Field, first | one, two);
		} else {
			var second = myFlag.a;
			return A2($mdgriffith$elm_ui$Internal$Flag$Field, one, second | two);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$ChildrenBehind = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$ChildrenInFront = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$nearbyElement = F2(
	function (location, elem) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class(
					function () {
						switch (location) {
							case 0:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.ce, $mdgriffith$elm_ui$Internal$Style$classes.on, $mdgriffith$elm_ui$Internal$Style$classes.jE]));
							case 1:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.ce, $mdgriffith$elm_ui$Internal$Style$classes.on, $mdgriffith$elm_ui$Internal$Style$classes.j9]));
							case 2:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.ce, $mdgriffith$elm_ui$Internal$Style$classes.on, $mdgriffith$elm_ui$Internal$Style$classes.nd]));
							case 3:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.ce, $mdgriffith$elm_ui$Internal$Style$classes.on, $mdgriffith$elm_ui$Internal$Style$classes.nb]));
							case 4:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.ce, $mdgriffith$elm_ui$Internal$Style$classes.on, $mdgriffith$elm_ui$Internal$Style$classes.l$]));
							default:
								return A2(
									$elm$core$String$join,
									' ',
									_List_fromArray(
										[$mdgriffith$elm_ui$Internal$Style$classes.ce, $mdgriffith$elm_ui$Internal$Style$classes.on, $mdgriffith$elm_ui$Internal$Style$classes.j8]));
						}
					}())
				]),
			_List_fromArray(
				[
					function () {
					switch (elem.$) {
						case 3:
							return $elm$virtual_dom$VirtualDom$text('');
						case 2:
							var str = elem.a;
							return $mdgriffith$elm_ui$Internal$Model$textElement(str);
						case 0:
							var html = elem.a;
							return html($mdgriffith$elm_ui$Internal$Model$asEl);
						default:
							var styled = elem.a;
							return A2(styled.lV, $mdgriffith$elm_ui$Internal$Model$NoStyleSheet, $mdgriffith$elm_ui$Internal$Model$asEl);
					}
				}()
				]));
	});
var $mdgriffith$elm_ui$Internal$Model$addNearbyElement = F3(
	function (location, elem, existing) {
		var nearby = A2($mdgriffith$elm_ui$Internal$Model$nearbyElement, location, elem);
		switch (existing.$) {
			case 0:
				if (location === 5) {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenBehind(
						_List_fromArray(
							[nearby]));
				} else {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenInFront(
						_List_fromArray(
							[nearby]));
				}
			case 1:
				var existingBehind = existing.a;
				if (location === 5) {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenBehind(
						A2($elm$core$List$cons, nearby, existingBehind));
				} else {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						existingBehind,
						_List_fromArray(
							[nearby]));
				}
			case 2:
				var existingInFront = existing.a;
				if (location === 5) {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						_List_fromArray(
							[nearby]),
						existingInFront);
				} else {
					return $mdgriffith$elm_ui$Internal$Model$ChildrenInFront(
						A2($elm$core$List$cons, nearby, existingInFront));
				}
			default:
				var existingBehind = existing.a;
				var existingInFront = existing.b;
				if (location === 5) {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						A2($elm$core$List$cons, nearby, existingBehind),
						existingInFront);
				} else {
					return A2(
						$mdgriffith$elm_ui$Internal$Model$ChildrenBehindAndInFront,
						existingBehind,
						A2($elm$core$List$cons, nearby, existingInFront));
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Embedded = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$NodeName = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$addNodeName = F2(
	function (newNode, old) {
		switch (old.$) {
			case 0:
				return $mdgriffith$elm_ui$Internal$Model$NodeName(newNode);
			case 1:
				var name = old.a;
				return A2($mdgriffith$elm_ui$Internal$Model$Embedded, name, newNode);
			default:
				var x = old.a;
				var y = old.b;
				return A2($mdgriffith$elm_ui$Internal$Model$Embedded, x, y);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$alignXName = function (align) {
	switch (align) {
		case 0:
			return $mdgriffith$elm_ui$Internal$Style$classes.ei + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.gd);
		case 2:
			return $mdgriffith$elm_ui$Internal$Style$classes.ei + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.ge);
		default:
			return $mdgriffith$elm_ui$Internal$Style$classes.ei + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.jO);
	}
};
var $mdgriffith$elm_ui$Internal$Model$alignYName = function (align) {
	switch (align) {
		case 0:
			return $mdgriffith$elm_ui$Internal$Style$classes.ej + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.jU);
		case 2:
			return $mdgriffith$elm_ui$Internal$Style$classes.ej + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.jN);
		default:
			return $mdgriffith$elm_ui$Internal$Style$classes.ej + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.jP);
	}
};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $mdgriffith$elm_ui$Internal$Model$FullTransform = F4(
	function (a, b, c, d) {
		return {$: 2, a: a, b: b, c: c, d: d};
	});
var $mdgriffith$elm_ui$Internal$Model$Moved = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$composeTransformation = F2(
	function (transform, component) {
		switch (transform.$) {
			case 0:
				switch (component.$) {
					case 0:
						var x = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, 0, 0));
					case 1:
						var y = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(0, y, 0));
					case 2:
						var z = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(0, 0, z));
					case 3:
						var xyz = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(xyz);
					case 4:
						var xyz = component.a;
						var angle = component.b;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(0, 0, 0),
							_Utils_Tuple3(1, 1, 1),
							xyz,
							angle);
					default:
						var xyz = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(0, 0, 0),
							xyz,
							_Utils_Tuple3(0, 0, 1),
							0);
				}
			case 1:
				var moved = transform.a;
				var x = moved.a;
				var y = moved.b;
				var z = moved.c;
				switch (component.$) {
					case 0:
						var newX = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(newX, y, z));
					case 1:
						var newY = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, newY, z));
					case 2:
						var newZ = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(
							_Utils_Tuple3(x, y, newZ));
					case 3:
						var xyz = component.a;
						return $mdgriffith$elm_ui$Internal$Model$Moved(xyz);
					case 4:
						var xyz = component.a;
						var angle = component.b;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							moved,
							_Utils_Tuple3(1, 1, 1),
							xyz,
							angle);
					default:
						var scale = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							moved,
							scale,
							_Utils_Tuple3(0, 0, 1),
							0);
				}
			default:
				var moved = transform.a;
				var x = moved.a;
				var y = moved.b;
				var z = moved.c;
				var scaled = transform.b;
				var origin = transform.c;
				var angle = transform.d;
				switch (component.$) {
					case 0:
						var newX = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(newX, y, z),
							scaled,
							origin,
							angle);
					case 1:
						var newY = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(x, newY, z),
							scaled,
							origin,
							angle);
					case 2:
						var newZ = component.a;
						return A4(
							$mdgriffith$elm_ui$Internal$Model$FullTransform,
							_Utils_Tuple3(x, y, newZ),
							scaled,
							origin,
							angle);
					case 3:
						var newMove = component.a;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, newMove, scaled, origin, angle);
					case 4:
						var newOrigin = component.a;
						var newAngle = component.b;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, moved, scaled, newOrigin, newAngle);
					default:
						var newScale = component.a;
						return A4($mdgriffith$elm_ui$Internal$Model$FullTransform, moved, newScale, origin, angle);
				}
		}
	});
var $mdgriffith$elm_ui$Internal$Flag$height = $mdgriffith$elm_ui$Internal$Flag$flag(7);
var $mdgriffith$elm_ui$Internal$Flag$heightContent = $mdgriffith$elm_ui$Internal$Flag$flag(36);
var $mdgriffith$elm_ui$Internal$Flag$merge = F2(
	function (_v0, _v1) {
		var one = _v0.a;
		var two = _v0.b;
		var three = _v1.a;
		var four = _v1.b;
		return A2($mdgriffith$elm_ui$Internal$Flag$Field, one | three, two | four);
	});
var $mdgriffith$elm_ui$Internal$Flag$none = A2($mdgriffith$elm_ui$Internal$Flag$Field, 0, 0);
var $mdgriffith$elm_ui$Internal$Model$renderHeight = function (h) {
	switch (h.$) {
		case 0:
			var px = h.a;
			var val = $elm$core$String$fromInt(px);
			var name = 'height-px-' + val;
			return _Utils_Tuple3(
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Style$classes.hf + (' ' + name),
				_List_fromArray(
					[
						A3($mdgriffith$elm_ui$Internal$Model$Single, name, 'height', val + 'px')
					]));
		case 1:
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightContent, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.eX,
				_List_Nil);
		case 2:
			var portion = h.a;
			return (portion === 1) ? _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.eY,
				_List_Nil) : _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.hg + (' height-fill-' + $elm$core$String$fromInt(portion)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						$mdgriffith$elm_ui$Internal$Style$classes.j_ + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.gv + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
							'height-fill-' + $elm$core$String$fromInt(portion))))),
						'flex-grow',
						$elm$core$String$fromInt(portion * 100000))
					]));
		case 3:
			var minSize = h.a;
			var len = h.b;
			var cls = 'min-height-' + $elm$core$String$fromInt(minSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'min-height',
				$elm$core$String$fromInt(minSize) + 'px !important');
			var _v1 = $mdgriffith$elm_ui$Internal$Model$renderHeight(len);
			var newFlag = _v1.a;
			var newAttrs = _v1.b;
			var newStyle = _v1.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
		default:
			var maxSize = h.a;
			var len = h.b;
			var cls = 'max-height-' + $elm$core$String$fromInt(maxSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'max-height',
				$elm$core$String$fromInt(maxSize) + 'px');
			var _v2 = $mdgriffith$elm_ui$Internal$Model$renderHeight(len);
			var newFlag = _v2.a;
			var newAttrs = _v2.b;
			var newStyle = _v2.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$heightBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
	}
};
var $mdgriffith$elm_ui$Internal$Flag$widthContent = $mdgriffith$elm_ui$Internal$Flag$flag(38);
var $mdgriffith$elm_ui$Internal$Model$renderWidth = function (w) {
	switch (w.$) {
		case 0:
			var px = w.a;
			return _Utils_Tuple3(
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Style$classes.js + (' width-px-' + $elm$core$String$fromInt(px)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						'width-px-' + $elm$core$String$fromInt(px),
						'width',
						$elm$core$String$fromInt(px) + 'px')
					]));
		case 1:
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthContent, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.f0,
				_List_Nil);
		case 2:
			var portion = w.a;
			return (portion === 1) ? _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.f1,
				_List_Nil) : _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthFill, $mdgriffith$elm_ui$Internal$Flag$none),
				$mdgriffith$elm_ui$Internal$Style$classes.jt + (' width-fill-' + $elm$core$String$fromInt(portion)),
				_List_fromArray(
					[
						A3(
						$mdgriffith$elm_ui$Internal$Model$Single,
						$mdgriffith$elm_ui$Internal$Style$classes.j_ + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.n$ + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
							'width-fill-' + $elm$core$String$fromInt(portion))))),
						'flex-grow',
						$elm$core$String$fromInt(portion * 100000))
					]));
		case 3:
			var minSize = w.a;
			var len = w.b;
			var cls = 'min-width-' + $elm$core$String$fromInt(minSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'min-width',
				$elm$core$String$fromInt(minSize) + 'px');
			var _v1 = $mdgriffith$elm_ui$Internal$Model$renderWidth(len);
			var newFlag = _v1.a;
			var newAttrs = _v1.b;
			var newStyle = _v1.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
		default:
			var maxSize = w.a;
			var len = w.b;
			var cls = 'max-width-' + $elm$core$String$fromInt(maxSize);
			var style = A3(
				$mdgriffith$elm_ui$Internal$Model$Single,
				cls,
				'max-width',
				$elm$core$String$fromInt(maxSize) + 'px');
			var _v2 = $mdgriffith$elm_ui$Internal$Model$renderWidth(len);
			var newFlag = _v2.a;
			var newAttrs = _v2.b;
			var newStyle = _v2.c;
			return _Utils_Tuple3(
				A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$widthBetween, newFlag),
				cls + (' ' + newAttrs),
				A2($elm$core$List$cons, style, newStyle));
	}
};
var $mdgriffith$elm_ui$Internal$Flag$borderWidth = $mdgriffith$elm_ui$Internal$Flag$flag(27);
var $elm$core$Basics$ge = _Utils_ge;
var $mdgriffith$elm_ui$Internal$Model$skippable = F2(
	function (flag, style) {
		if (_Utils_eq(flag, $mdgriffith$elm_ui$Internal$Flag$borderWidth)) {
			if (style.$ === 3) {
				var val = style.c;
				switch (val) {
					case '0px':
						return true;
					case '1px':
						return true;
					case '2px':
						return true;
					case '3px':
						return true;
					case '4px':
						return true;
					case '5px':
						return true;
					case '6px':
						return true;
					default:
						return false;
				}
			} else {
				return false;
			}
		} else {
			switch (style.$) {
				case 2:
					var i = style.a;
					return (i >= 8) && (i <= 32);
				case 7:
					var name = style.a;
					var t = style.b;
					var r = style.c;
					var b = style.d;
					var l = style.e;
					return _Utils_eq(t, b) && (_Utils_eq(t, r) && (_Utils_eq(t, l) && ((t >= 0) && (t <= 24))));
				default:
					return false;
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Flag$width = $mdgriffith$elm_ui$Internal$Flag$flag(6);
var $mdgriffith$elm_ui$Internal$Flag$xAlign = $mdgriffith$elm_ui$Internal$Flag$flag(30);
var $mdgriffith$elm_ui$Internal$Flag$yAlign = $mdgriffith$elm_ui$Internal$Flag$flag(29);
var $mdgriffith$elm_ui$Internal$Model$gatherAttrRecursive = F8(
	function (classes, node, has, transform, styles, attrs, children, elementAttrs) {
		gatherAttrRecursive:
		while (true) {
			if (!elementAttrs.b) {
				var _v1 = $mdgriffith$elm_ui$Internal$Model$transformClass(transform);
				if (_v1.$ === 1) {
					return {
						em: A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class(classes),
							attrs),
						eA: children,
						bu: has,
						mW: node,
						i1: styles
					};
				} else {
					var _class = _v1.a;
					return {
						em: A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class(classes + (' ' + _class)),
							attrs),
						eA: children,
						bu: has,
						mW: node,
						i1: A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$Transform(transform),
							styles)
					};
				}
			} else {
				var attribute = elementAttrs.a;
				var remaining = elementAttrs.b;
				switch (attribute.$) {
					case 0:
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = styles,
							$temp$attrs = attrs,
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 3:
						var flag = attribute.a;
						var exactClassName = attribute.b;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, flag, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = exactClassName + (' ' + classes),
								$temp$node = node,
								$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
					case 1:
						var actualAttribute = attribute.a;
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = styles,
							$temp$attrs = A2($elm$core$List$cons, actualAttribute, attrs),
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 4:
						var flag = attribute.a;
						var style = attribute.b;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, flag, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							if (A2($mdgriffith$elm_ui$Internal$Model$skippable, flag, style)) {
								var $temp$classes = $mdgriffith$elm_ui$Internal$Model$getStyleName(style) + (' ' + classes),
									$temp$node = node,
									$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							} else {
								var $temp$classes = $mdgriffith$elm_ui$Internal$Model$getStyleName(style) + (' ' + classes),
									$temp$node = node,
									$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
									$temp$transform = transform,
									$temp$styles = A2($elm$core$List$cons, style, styles),
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							}
						}
					case 10:
						var flag = attribute.a;
						var component = attribute.b;
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, flag, has),
							$temp$transform = A2($mdgriffith$elm_ui$Internal$Model$composeTransformation, transform, component),
							$temp$styles = styles,
							$temp$attrs = attrs,
							$temp$children = children,
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 7:
						var width = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$width, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							switch (width.$) {
								case 0:
									var px = width.a;
									var $temp$classes = ($mdgriffith$elm_ui$Internal$Style$classes.js + (' width-px-' + $elm$core$String$fromInt(px))) + (' ' + classes),
										$temp$node = node,
										$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has),
										$temp$transform = transform,
										$temp$styles = A2(
										$elm$core$List$cons,
										A3(
											$mdgriffith$elm_ui$Internal$Model$Single,
											'width-px-' + $elm$core$String$fromInt(px),
											'width',
											$elm$core$String$fromInt(px) + 'px'),
										styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 1:
									var $temp$classes = classes + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.f0),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$add,
										$mdgriffith$elm_ui$Internal$Flag$widthContent,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 2:
									var portion = width.a;
									if (portion === 1) {
										var $temp$classes = classes + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.f1),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$widthFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.jt + (' width-fill-' + $elm$core$String$fromInt(portion)))),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$widthFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
											$temp$transform = transform,
											$temp$styles = A2(
											$elm$core$List$cons,
											A3(
												$mdgriffith$elm_ui$Internal$Model$Single,
												$mdgriffith$elm_ui$Internal$Style$classes.j_ + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.n$ + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
													'width-fill-' + $elm$core$String$fromInt(portion))))),
												'flex-grow',
												$elm$core$String$fromInt(portion * 100000)),
											styles),
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								default:
									var _v4 = $mdgriffith$elm_ui$Internal$Model$renderWidth(width);
									var addToFlags = _v4.a;
									var newClass = _v4.b;
									var newStyles = _v4.c;
									var $temp$classes = classes + (' ' + newClass),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$merge,
										addToFlags,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$width, has)),
										$temp$transform = transform,
										$temp$styles = _Utils_ap(newStyles, styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
							}
						}
					case 8:
						var height = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$height, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							switch (height.$) {
								case 0:
									var px = height.a;
									var val = $elm$core$String$fromInt(px) + 'px';
									var name = 'height-px-' + val;
									var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.hf + (' ' + (name + (' ' + classes))),
										$temp$node = node,
										$temp$has = A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has),
										$temp$transform = transform,
										$temp$styles = A2(
										$elm$core$List$cons,
										A3($mdgriffith$elm_ui$Internal$Model$Single, name, 'height ', val),
										styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 1:
									var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.eX + (' ' + classes),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$add,
										$mdgriffith$elm_ui$Internal$Flag$heightContent,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								case 2:
									var portion = height.a;
									if (portion === 1) {
										var $temp$classes = $mdgriffith$elm_ui$Internal$Style$classes.eY + (' ' + classes),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$heightFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.hg + (' height-fill-' + $elm$core$String$fromInt(portion)))),
											$temp$node = node,
											$temp$has = A2(
											$mdgriffith$elm_ui$Internal$Flag$add,
											$mdgriffith$elm_ui$Internal$Flag$heightFill,
											A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
											$temp$transform = transform,
											$temp$styles = A2(
											$elm$core$List$cons,
											A3(
												$mdgriffith$elm_ui$Internal$Model$Single,
												$mdgriffith$elm_ui$Internal$Style$classes.j_ + ('.' + ($mdgriffith$elm_ui$Internal$Style$classes.gv + (' > ' + $mdgriffith$elm_ui$Internal$Style$dot(
													'height-fill-' + $elm$core$String$fromInt(portion))))),
												'flex-grow',
												$elm$core$String$fromInt(portion * 100000)),
											styles),
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								default:
									var _v6 = $mdgriffith$elm_ui$Internal$Model$renderHeight(height);
									var addToFlags = _v6.a;
									var newClass = _v6.b;
									var newStyles = _v6.c;
									var $temp$classes = classes + (' ' + newClass),
										$temp$node = node,
										$temp$has = A2(
										$mdgriffith$elm_ui$Internal$Flag$merge,
										addToFlags,
										A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$height, has)),
										$temp$transform = transform,
										$temp$styles = _Utils_ap(newStyles, styles),
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
							}
						}
					case 2:
						var description = attribute.a;
						switch (description.$) {
							case 0:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'main', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 1:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'nav', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 2:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'footer', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 3:
								var $temp$classes = classes,
									$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'aside', node),
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 4:
								var i = description.a;
								if (i <= 1) {
									var $temp$classes = classes,
										$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'h1', node),
										$temp$has = has,
										$temp$transform = transform,
										$temp$styles = styles,
										$temp$attrs = attrs,
										$temp$children = children,
										$temp$elementAttrs = remaining;
									classes = $temp$classes;
									node = $temp$node;
									has = $temp$has;
									transform = $temp$transform;
									styles = $temp$styles;
									attrs = $temp$attrs;
									children = $temp$children;
									elementAttrs = $temp$elementAttrs;
									continue gatherAttrRecursive;
								} else {
									if (i < 7) {
										var $temp$classes = classes,
											$temp$node = A2(
											$mdgriffith$elm_ui$Internal$Model$addNodeName,
											'h' + $elm$core$String$fromInt(i),
											node),
											$temp$has = has,
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									} else {
										var $temp$classes = classes,
											$temp$node = A2($mdgriffith$elm_ui$Internal$Model$addNodeName, 'h6', node),
											$temp$has = has,
											$temp$transform = transform,
											$temp$styles = styles,
											$temp$attrs = attrs,
											$temp$children = children,
											$temp$elementAttrs = remaining;
										classes = $temp$classes;
										node = $temp$node;
										has = $temp$has;
										transform = $temp$transform;
										styles = $temp$styles;
										attrs = $temp$attrs;
										children = $temp$children;
										elementAttrs = $temp$elementAttrs;
										continue gatherAttrRecursive;
									}
								}
							case 9:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = attrs,
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 8:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'role', 'button'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 5:
								var label = description.a;
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-label', label),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							case 6:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-live', 'polite'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
							default:
								var $temp$classes = classes,
									$temp$node = node,
									$temp$has = has,
									$temp$transform = transform,
									$temp$styles = styles,
									$temp$attrs = A2(
									$elm$core$List$cons,
									A2($elm$virtual_dom$VirtualDom$attribute, 'aria-live', 'assertive'),
									attrs),
									$temp$children = children,
									$temp$elementAttrs = remaining;
								classes = $temp$classes;
								node = $temp$node;
								has = $temp$has;
								transform = $temp$transform;
								styles = $temp$styles;
								attrs = $temp$attrs;
								children = $temp$children;
								elementAttrs = $temp$elementAttrs;
								continue gatherAttrRecursive;
						}
					case 9:
						var location = attribute.a;
						var elem = attribute.b;
						var newStyles = function () {
							switch (elem.$) {
								case 3:
									return styles;
								case 2:
									var str = elem.a;
									return styles;
								case 0:
									var html = elem.a;
									return styles;
								default:
									var styled = elem.a;
									return _Utils_ap(styles, styled.i1);
							}
						}();
						var $temp$classes = classes,
							$temp$node = node,
							$temp$has = has,
							$temp$transform = transform,
							$temp$styles = newStyles,
							$temp$attrs = attrs,
							$temp$children = A3($mdgriffith$elm_ui$Internal$Model$addNearbyElement, location, elem, children),
							$temp$elementAttrs = remaining;
						classes = $temp$classes;
						node = $temp$node;
						has = $temp$has;
						transform = $temp$transform;
						styles = $temp$styles;
						attrs = $temp$attrs;
						children = $temp$children;
						elementAttrs = $temp$elementAttrs;
						continue gatherAttrRecursive;
					case 6:
						var x = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$xAlign, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = $mdgriffith$elm_ui$Internal$Model$alignXName(x) + (' ' + classes),
								$temp$node = node,
								$temp$has = function (flags) {
								switch (x) {
									case 1:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$centerX, flags);
									case 2:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$alignRight, flags);
									default:
										return flags;
								}
							}(
								A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$xAlign, has)),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
					default:
						var y = attribute.a;
						if (A2($mdgriffith$elm_ui$Internal$Flag$present, $mdgriffith$elm_ui$Internal$Flag$yAlign, has)) {
							var $temp$classes = classes,
								$temp$node = node,
								$temp$has = has,
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						} else {
							var $temp$classes = $mdgriffith$elm_ui$Internal$Model$alignYName(y) + (' ' + classes),
								$temp$node = node,
								$temp$has = function (flags) {
								switch (y) {
									case 1:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$centerY, flags);
									case 2:
										return A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$alignBottom, flags);
									default:
										return flags;
								}
							}(
								A2($mdgriffith$elm_ui$Internal$Flag$add, $mdgriffith$elm_ui$Internal$Flag$yAlign, has)),
								$temp$transform = transform,
								$temp$styles = styles,
								$temp$attrs = attrs,
								$temp$children = children,
								$temp$elementAttrs = remaining;
							classes = $temp$classes;
							node = $temp$node;
							has = $temp$has;
							transform = $temp$transform;
							styles = $temp$styles;
							attrs = $temp$attrs;
							children = $temp$children;
							elementAttrs = $temp$elementAttrs;
							continue gatherAttrRecursive;
						}
				}
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$Untransformed = {$: 0};
var $mdgriffith$elm_ui$Internal$Model$untransformed = $mdgriffith$elm_ui$Internal$Model$Untransformed;
var $mdgriffith$elm_ui$Internal$Model$element = F4(
	function (context, node, attributes, children) {
		return A3(
			$mdgriffith$elm_ui$Internal$Model$createElement,
			context,
			children,
			A8(
				$mdgriffith$elm_ui$Internal$Model$gatherAttrRecursive,
				$mdgriffith$elm_ui$Internal$Model$contextClasses(context),
				node,
				$mdgriffith$elm_ui$Internal$Flag$none,
				$mdgriffith$elm_ui$Internal$Model$untransformed,
				_List_Nil,
				_List_Nil,
				$mdgriffith$elm_ui$Internal$Model$NoNearbyChildren,
				$elm$core$List$reverse(attributes)));
	});
var $mdgriffith$elm_ui$Internal$Model$Height = function (a) {
	return {$: 8, a: a};
};
var $mdgriffith$elm_ui$Element$height = $mdgriffith$elm_ui$Internal$Model$Height;
var $mdgriffith$elm_ui$Internal$Model$Attr = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$htmlClass = function (cls) {
	return $mdgriffith$elm_ui$Internal$Model$Attr(
		$elm$html$Html$Attributes$class(cls));
};
var $mdgriffith$elm_ui$Internal$Model$Content = {$: 1};
var $mdgriffith$elm_ui$Element$shrink = $mdgriffith$elm_ui$Internal$Model$Content;
var $mdgriffith$elm_ui$Internal$Model$Width = function (a) {
	return {$: 7, a: a};
};
var $mdgriffith$elm_ui$Element$width = $mdgriffith$elm_ui$Internal$Model$Width;
var $mdgriffith$elm_ui$Element$column = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asColumn,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.kK + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.cE)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Element$Input$Placeholder = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$Input$placeholder = $mdgriffith$elm_ui$Element$Input$Placeholder;
var $mdgriffith$elm_ui$Internal$Model$Text = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Element$text = function (content) {
	return $mdgriffith$elm_ui$Internal$Model$Text(content);
};
var $author$project$Page$CreateUsers$emailsPlaceholderInit = $elm$core$Maybe$Just(
	A2(
		$mdgriffith$elm_ui$Element$Input$placeholder,
		_List_Nil,
		A2(
			$mdgriffith$elm_ui$Element$column,
			_List_Nil,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$text('ann.struthers@school.state.edu.au'),
					$mdgriffith$elm_ui$Element$text('john.melton@school.state.edu.au'),
					$mdgriffith$elm_ui$Element$text('sally.campbell@school.state.edu.au')
				]))));
var $author$project$Page$CreateUsers$fNamesPlaceholderInit = $elm$core$Maybe$Just(
	A2(
		$mdgriffith$elm_ui$Element$Input$placeholder,
		_List_Nil,
		A2(
			$mdgriffith$elm_ui$Element$column,
			_List_Nil,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$text('Ann'),
					$mdgriffith$elm_ui$Element$text('John'),
					$mdgriffith$elm_ui$Element$text('Sally')
				]))));
var $author$project$Page$CreateUsers$lNamesPlaceholderInit = $elm$core$Maybe$Just(
	A2(
		$mdgriffith$elm_ui$Element$Input$placeholder,
		_List_Nil,
		A2(
			$mdgriffith$elm_ui$Element$column,
			_List_Nil,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$text('Struthers'),
					$mdgriffith$elm_ui$Element$text('Melton'),
					$mdgriffith$elm_ui$Element$text('Campbell')
				]))));
var $author$project$Page$PasswordChangeCommon$newPasswordPlaceholderInit = $elm$core$Maybe$Just(
	A2(
		$mdgriffith$elm_ui$Element$Input$placeholder,
		_List_Nil,
		$mdgriffith$elm_ui$Element$text('8 or more mixed incl number(s) e.g. Abcdefg1')));
var $author$project$Page$PasswordChangeCommon$repeatPasswordPlaceholderInit = $elm$core$Maybe$Just(
	A2(
		$mdgriffith$elm_ui$Element$Input$placeholder,
		_List_Nil,
		$mdgriffith$elm_ui$Element$text('Please enter your new password again')));
var $author$project$Page$CreateUsers$init = {
	a0: $elm$core$Maybe$Nothing,
	kA: $elm$core$Maybe$Nothing,
	cD: $elm$core$Maybe$Nothing,
	ak: _List_Nil,
	at: '',
	dt: $elm$core$Maybe$Nothing,
	du: $author$project$Page$CreateUsers$emailsPlaceholderInit,
	cL: _List_Nil,
	au: '',
	dy: $elm$core$Maybe$Nothing,
	dz: $author$project$Page$CreateUsers$fNamesPlaceholderInit,
	cN: false,
	ax: '',
	dH: $elm$core$Maybe$Nothing,
	dI: $author$project$Page$CreateUsers$lNamesPlaceholderInit,
	cf: '',
	cQ: $elm$core$Maybe$Nothing,
	fk: $author$project$Page$PasswordChangeCommon$newPasswordPlaceholderInit,
	cU: $krisajenkins$remotedata$RemoteData$NotAsked,
	dQ: _Utils_Tuple2($author$project$Theme$Style$t.eC, $elm$core$Maybe$Nothing),
	o: 0,
	dU: '',
	cW: $elm$core$Maybe$Nothing,
	fy: $author$project$Page$PasswordChangeCommon$repeatPasswordPlaceholderInit
};
var $author$project$Page$ForgotPassword$emailPlaceholderInit = $elm$core$Maybe$Just(
	A2(
		$mdgriffith$elm_ui$Element$Input$placeholder,
		_List_Nil,
		$mdgriffith$elm_ui$Element$text('some@email.edu.au')));
var $author$project$Page$ForgotPassword$init = {li: '', b3: $elm$core$Maybe$Nothing, ds: $author$project$Page$ForgotPassword$emailPlaceholderInit, fF: $elm$core$Maybe$Nothing};
var $author$project$Page$ForgotPasswordSubmit$codePlaceholderInit = $elm$core$Maybe$Just(
	A2(
		$mdgriffith$elm_ui$Element$Input$placeholder,
		_List_Nil,
		$mdgriffith$elm_ui$Element$text('The verification code sent to your email')));
var $author$project$Page$ForgotPasswordSubmit$init = {gt: '', b$: $elm$core$Maybe$Nothing, dj: $author$project$Page$ForgotPasswordSubmit$codePlaceholderInit, cN: false, cf: '', cQ: $elm$core$Maybe$Nothing, fk: $author$project$Page$PasswordChangeCommon$newPasswordPlaceholderInit, dU: '', cW: $elm$core$Maybe$Nothing, fy: $author$project$Page$PasswordChangeCommon$repeatPasswordPlaceholderInit, fF: $elm$core$Maybe$Nothing};
var $author$project$Page$ManageSchools$schoolCodePlaceholderInit = $elm$core$Maybe$Just(
	A2(
		$mdgriffith$elm_ui$Element$Input$placeholder,
		_List_Nil,
		$mdgriffith$elm_ui$Element$text('e.g. nsw-school1-h')));
var $author$project$Page$ManageSchools$schoolNamePlaceholderInit = $elm$core$Maybe$Just(
	A2(
		$mdgriffith$elm_ui$Element$Input$placeholder,
		_List_Nil,
		$mdgriffith$elm_ui$Element$text('The school name to create')));
var $author$project$Page$ManageSchools$init = {
	b7: $elm$core$Maybe$Nothing,
	cd: $elm$core$Dict$empty,
	dQ: _Utils_Tuple2($author$project$Theme$Style$t.eC, $elm$core$Maybe$Nothing),
	bd: '',
	fB: $author$project$Page$ManageSchools$schoolCodePlaceholderInit,
	n5: '',
	fC: $author$project$Page$ManageSchools$schoolNamePlaceholderInit,
	L: $krisajenkins$remotedata$RemoteData$NotAsked
};
var $author$project$Page$ManageUsers$ViewMode = 0;
var $author$project$Page$ManageUsers$groupFilterPlaceholderInit = $elm$core$Maybe$Just(
	A2(
		$mdgriffith$elm_ui$Element$Input$placeholder,
		_List_Nil,
		$mdgriffith$elm_ui$Element$text('Type to filter')));
var $author$project$Page$ManageUsers$init = {
	cK: '',
	b6: '',
	dA: $author$project$Page$ManageUsers$groupFilterPlaceholderInit,
	bJ: 0,
	dQ: _Utils_Tuple2($author$project$Theme$Style$t.eC, $elm$core$Maybe$Nothing),
	aa: $krisajenkins$remotedata$RemoteData$NotAsked,
	S: $krisajenkins$remotedata$RemoteData$NotAsked,
	bX: $elm$core$Maybe$Nothing
};
var $zaboco$elm_draggable$Internal$NotDragging = {$: 0};
var $zaboco$elm_draggable$Draggable$State = $elm$core$Basics$identity;
var $zaboco$elm_draggable$Draggable$init = $zaboco$elm_draggable$Internal$NotDragging;
var $author$project$UITool$Calculator$init = {
	b1: false,
	r: '',
	gX: $zaboco$elm_draggable$Draggable$init,
	br: 0,
	a6: false,
	bB: true,
	bb: _Utils_Tuple2(-80, 30)
};
var $author$project$Page$Mjd5$init = {
	fq: _List_fromArray(
		['']),
	fF: $elm$core$Maybe$Nothing,
	aI: $elm$core$Maybe$Nothing,
	je: $author$project$UITool$Calculator$init
};
var $author$project$Page$MutateUsers$MutationNotStarted = 0;
var $author$project$Page$MutateUsers$UpdateMode = 1;
var $author$project$Page$MutateUsers$groupTagPlaceholderInit = $elm$core$Maybe$Just(
	A2(
		$mdgriffith$elm_ui$Element$Input$placeholder,
		_List_Nil,
		$mdgriffith$elm_ui$Element$text('Tag for filtering, or blank')));
var $author$project$Type$Cohort$Aus = function (a) {
	return {$: 0, a: a};
};
var $author$project$Type$Cohort$Stage2 = function (a) {
	return {$: 0, a: a};
};
var $author$project$Type$Cohort$Y3 = 0;
var $author$project$Type$Cohort$placeholder = $author$project$Type$Cohort$Aus(
	$author$project$Type$Cohort$Stage2(0));
var $author$project$Page$MutateUsers$init = {
	a0: $elm$core$Maybe$Nothing,
	kA: $author$project$Type$Cohort$placeholder,
	cM: $elm$core$Maybe$Nothing,
	eU: $author$project$Page$MutateUsers$groupTagPlaceholderInit,
	a3: false,
	aT: false,
	a4: false,
	cU: $krisajenkins$remotedata$RemoteData$NotAsked,
	bJ: 1,
	dQ: _Utils_Tuple2($author$project$Theme$Style$t.eC, $elm$core$Maybe$Nothing),
	o: 0,
	F: _List_Nil
};
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $author$project$Page$Profile$emailPlaceholderInit = $elm$core$Maybe$Just(
	A2(
		$mdgriffith$elm_ui$Element$Input$placeholder,
		_List_Nil,
		$mdgriffith$elm_ui$Element$text('your_email@school.edu.au')));
var $author$project$Type$User$PersonEDetails = F5(
	function (id, sId, fNam, lNam, email) {
		return {li: email, lt: fNam, lW: id, mq: lNam, d$: sId};
	});
var $author$project$Type$User$extractPersonEDetails = function (person) {
	var extractPersonEDetailsFromStudentEDetails = function (_v1) {
		var id = _v1.lW;
		var sId = _v1.d$;
		var fNam = _v1.lt;
		var lNam = _v1.mq;
		var email = _v1.li;
		return A5($author$project$Type$User$PersonEDetails, id, sId, fNam, lNam, email);
	};
	if (!person.$) {
		var studentDetails = person.a;
		return extractPersonEDetailsFromStudentEDetails(studentDetails);
	} else {
		if (!person.a.$) {
			var personEDetails = person.a.a;
			return personEDetails;
		} else {
			var personEDetails = person.a.a;
			return personEDetails;
		}
	}
};
var $author$project$Type$User$extractStudentEFromPersonE = function (personE) {
	if (!personE.$) {
		var studentEType = personE.a;
		return $elm$core$Maybe$Just(studentEType);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$Profile$fNamPlaceholderInit = $elm$core$Maybe$Just(
	A2(
		$mdgriffith$elm_ui$Element$Input$placeholder,
		_List_Nil,
		$mdgriffith$elm_ui$Element$text('Your first name')));
var $author$project$Type$User$isTeacherEAdminE = function (person) {
	if ((person.$ === 1) && (!person.a.$)) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Page$Profile$lNamPlaceholderInit = $elm$core$Maybe$Just(
	A2(
		$mdgriffith$elm_ui$Element$Input$placeholder,
		_List_Nil,
		$mdgriffith$elm_ui$Element$text('Your last name')));
var $krisajenkins$remotedata$RemoteData$unwrap = F3(
	function (_default, _function, remoteData) {
		if (remoteData.$ === 3) {
			var data = remoteData.a;
			return _function(data);
		} else {
			return _default;
		}
	});
var $author$project$Page$Profile$init = function (user) {
	var isAdmin = A3($krisajenkins$remotedata$RemoteData$unwrap, false, $author$project$Type$User$isTeacherEAdminE, user);
	var assignedTeacherId = A3(
		$krisajenkins$remotedata$RemoteData$unwrap,
		'',
		A2(
			$elm$core$Basics$composeR,
			$author$project$Type$User$extractStudentEFromPersonE,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map(
					function ($) {
						return $.d$;
					}),
				$elm$core$Maybe$withDefault(''))),
		user);
	var _v0 = A3(
		$krisajenkins$remotedata$RemoteData$unwrap,
		_Utils_Tuple3('', '', ''),
		A2(
			$elm$core$Basics$composeR,
			$author$project$Type$User$extractPersonEDetails,
			function (el2) {
				return _Utils_Tuple3(el2.lt, el2.mq, el2.li);
			}),
		user);
	var fNam = _v0.a;
	var lNam = _v0.b;
	var email = _v0.c;
	return {
		da: $krisajenkins$remotedata$RemoteData$NotAsked,
		db: assignedTeacherId,
		bm: $elm$core$Maybe$Nothing,
		li: email,
		b3: $elm$core$Maybe$Nothing,
		aR: $elm$core$Maybe$Nothing,
		ds: $author$project$Page$Profile$emailPlaceholderInit,
		lt: fNam,
		a2: $elm$core$Maybe$Nothing,
		bs: $elm$core$Maybe$Nothing,
		eO: $author$project$Page$Profile$fNamPlaceholderInit,
		dE: isAdmin,
		bx: $elm$core$Maybe$Nothing,
		cN: false,
		bA: false,
		mq: lNam,
		a7: $elm$core$Maybe$Nothing,
		bC: $elm$core$Maybe$Nothing,
		e7: $author$project$Page$Profile$lNamPlaceholderInit,
		ay: $krisajenkins$remotedata$RemoteData$NotAsked,
		cf: '',
		cQ: $elm$core$Maybe$Nothing,
		fk: $author$project$Page$PasswordChangeCommon$newPasswordPlaceholderInit,
		dQ: _Utils_Tuple2($author$project$Theme$Style$t.eC, $elm$core$Maybe$Nothing),
		dU: '',
		cW: $elm$core$Maybe$Nothing,
		fy: $author$project$Page$PasswordChangeCommon$repeatPasswordPlaceholderInit,
		d2: $krisajenkins$remotedata$RemoteData$NotAsked,
		H: user,
		aK: $krisajenkins$remotedata$RemoteData$NotAsked
	};
};
var $author$project$Page$ReportCard$init = {fR: $elm$core$Maybe$Nothing};
var $author$project$Page$Sample$init = 0;
var $author$project$Page$ShowVariant$init = {f8: $elm$core$Maybe$Nothing, aq: $elm$core$Maybe$Nothing, dv: $krisajenkins$remotedata$RemoteData$NotAsked, ca: false, by: false, X: false, bz: false, fF: $elm$core$Maybe$Nothing, oe: _List_Nil, je: $author$project$UITool$Calculator$init, ao: $elm$core$Maybe$Nothing, aL: $krisajenkins$remotedata$RemoteData$NotAsked, cr: $elm$core$Maybe$Nothing, ab: $elm$core$Maybe$Nothing};
var $author$project$Page$ShowWorksheet$init = {c9: $elm$core$Maybe$Nothing, av: _List_Nil, dG: false, aM: _List_Nil, ed: $elm$core$Maybe$Nothing};
var $author$project$Page$ShowWorksheets$init = {aZ: $elm$core$Maybe$Nothing, b4: '', cP: false, cj: _List_Nil, fF: $elm$core$Maybe$Nothing, aY: $elm$core$Maybe$Nothing, aO: _List_Nil};
var $author$project$Page$SignIn$emailPlaceholderInit = $elm$core$Maybe$Just(
	A2(
		$mdgriffith$elm_ui$Element$Input$placeholder,
		_List_Nil,
		$mdgriffith$elm_ui$Element$text('some@email.edu.au')));
var $author$project$Page$SignIn$passwordPlaceholderInit = $elm$core$Maybe$Just(
	A2(
		$mdgriffith$elm_ui$Element$Input$placeholder,
		_List_Nil,
		$mdgriffith$elm_ui$Element$text('Your password')));
var $author$project$Page$SignIn$init = {li: '', b3: $elm$core$Maybe$Nothing, ds: $author$project$Page$SignIn$emailPlaceholderInit, cN: false, cO: true, bK: '', cg: $elm$core$Maybe$Nothing, dR: $author$project$Page$SignIn$passwordPlaceholderInit, fF: $elm$core$Maybe$Nothing};
var $author$project$Page$StudentHome$init = {
	cb: $elm$core$Dict$empty,
	bH: $elm$time$Time$millisToPosix(0),
	fF: $elm$core$Maybe$Nothing,
	d3: $elm$core$Maybe$Nothing,
	bg: 0.625,
	bO: 32,
	c4: $elm$core$Dict$empty
};
var $author$project$Type$NodeCfg$initNodeConfig = {jJ: $elm$core$Maybe$Nothing, mW: $krisajenkins$remotedata$RemoteData$NotAsked, fs: $krisajenkins$remotedata$RemoteData$NotAsked, oJ: $krisajenkins$remotedata$RemoteData$NotAsked};
var $NoRedInk$elm_sortable_table$Table$State = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $NoRedInk$elm_sortable_table$Table$initialSort = function (header) {
	return A2($NoRedInk$elm_sortable_table$Table$State, header, false);
};
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Models$blankModel = {
	f7: $author$project$Type$Configuration$StudentMode,
	gi: A4(
		$author$project$Models$AnalysisByQuestionState,
		$NoRedInk$elm_sortable_table$Table$initialSort('% Correct'),
		$elm$core$Set$empty,
		$elm$core$Maybe$Nothing,
		$elm$core$Set$empty),
	gj: 1,
	j$: $krisajenkins$remotedata$RemoteData$NotAsked,
	el: $krisajenkins$remotedata$RemoteData$NotAsked,
	gL: $krisajenkins$remotedata$RemoteData$NotAsked,
	kR: $elm$core$Array$empty,
	kS: $elm$core$Maybe$Nothing,
	kT: $elm$core$Maybe$Nothing,
	kU: $elm$core$Maybe$Nothing,
	ll: $elm$core$Maybe$Nothing,
	ln: 0,
	lo: $elm$core$Maybe$Nothing,
	lx: {
		lF: {go: '', gG: '', gH: '', gI: '', gJ: '', gQ: '', gR: '', gS: '', hE: '', hP: '', iZ: '', i_: '', i$: '', i0: '', i8: '', ji: '', jj: '', jk: ''},
		hm: '',
		hr: $elm$json$Json$Encode$null,
		md: false,
		od: '',
		pz: '',
		pA: '',
		pB: '',
		pL: ''
	},
	hi: $krisajenkins$remotedata$RemoteData$NotAsked,
	hs: false,
	fc: $krisajenkins$remotedata$RemoteData$NotAsked,
	mX: $author$project$Type$NodeCfg$initNodeConfig,
	hU: $elm$core$Maybe$Nothing,
	h$: $author$project$Page$AdaptiveAnalytics$init,
	h0: $author$project$Page$AdminPage$init,
	h1: $author$project$Page$CreateUsers$init,
	h2: $author$project$Page$ForgotPassword$init,
	h3: $author$project$Page$ForgotPasswordSubmit$init,
	h4: $author$project$Page$ManageSchools$init,
	h5: $author$project$Page$ManageUsers$init,
	h6: $author$project$Page$Mjd5$init,
	h7: $author$project$Page$MutateUsers$init,
	h8: $author$project$Page$Profile$init($krisajenkins$remotedata$RemoteData$NotAsked),
	h9: $author$project$Page$ReportCard$init,
	ia: $author$project$Page$Sample$init,
	no: $author$project$Page$ShowVariant$init,
	ib: $author$project$Page$ShowWorksheet$init,
	ic: $author$project$Page$ShowWorksheets$init,
	id: $author$project$Page$SignIn$init,
	ie: $author$project$Page$StudentHome$init,
	il: $krisajenkins$remotedata$RemoteData$NotAsked,
	nz: $author$project$Routing$HomeRoute,
	is: $elm$core$Maybe$Nothing,
	nI: $elm$core$Array$empty,
	t: '',
	n_: $author$project$Routing$HomeRoute,
	ox: $elm$core$Maybe$Nothing,
	fQ: $krisajenkins$remotedata$RemoteData$NotAsked,
	fT: $NoRedInk$elm_sortable_table$Table$initialSort('Action'),
	je: $author$project$UITool$Calculator$init,
	pC: $elm$core$Maybe$Nothing
};
var $author$project$Main$initModel = F3(
	function (flags, route, errorMaybe) {
		return _Utils_update(
			$author$project$Models$blankModel,
			{ll: errorMaybe, lx: flags, nz: route, n_: route});
	});
var $author$project$JsInterop$Types$ConstructNodeForSyllabusETJ = 33;
var $author$project$JsInterop$Types$ConstructNodeForSyllabusJTE = 10;
var $author$project$JsInterop$Types$CreateQuestionGroupJTE = 38;
var $author$project$JsInterop$Types$CreateSchoolJTE = 37;
var $author$project$JsInterop$Types$CreateStudentJTE = 24;
var $author$project$JsInterop$Types$CreateTeacherJTE = 23;
var $author$project$JsInterop$Types$DeleteLoginUserJTE = 21;
var $author$project$JsInterop$Types$DeleteSchoolJTE = 16;
var $author$project$JsInterop$Types$DeleteStudentHistoryJTE = 22;
var $author$project$JsInterop$Types$ForgotPasswordETJ = 26;
var $author$project$JsInterop$Types$ForgotPasswordJTE = 2;
var $author$project$JsInterop$Types$ForgotPasswordSubmitETJ = 27;
var $author$project$JsInterop$Types$ForgotPasswordSubmitJTE = 3;
var $author$project$JsInterop$Types$GetSyllabusesETJ = 34;
var $author$project$JsInterop$Types$GetSyllabusesJTE = 11;
var $author$project$JsInterop$Types$GetVariantIdsETJ = 32;
var $author$project$JsInterop$Types$GetVariantIdsJTE = 9;
var $author$project$JsInterop$Types$GetVariantSelection_Mjd5_ETJ = 29;
var $author$project$JsInterop$Types$GetVariantSelection_Mjd5_JTE = 6;
var $author$project$JsInterop$Types$GetVariantSelection_ShowVariant_ETJ = 30;
var $author$project$JsInterop$Types$GetVariantSelection_ShowVariant_JTE = 7;
var $author$project$JsInterop$Types$GetVariantSelection_ShowWorksheets_ETJ = 31;
var $author$project$JsInterop$Types$GetVariantSelection_ShowWorksheets_JTE = 8;
var $author$project$JsInterop$Types$InteropFailureJTE = 0;
var $author$project$JsInterop$Types$LogErrorETJ = 36;
var $author$project$JsInterop$Types$MeETJ = 35;
var $author$project$JsInterop$Types$MeJTE = 5;
var $author$project$JsInterop$Types$NbrUsersForSchoolIdJTE = 15;
var $author$project$JsInterop$Types$SchoolsJTE = 14;
var $author$project$JsInterop$Types$SignInETJ = 25;
var $author$project$JsInterop$Types$SignInJTE = 1;
var $author$project$JsInterop$Types$SignOutETJ = 28;
var $author$project$JsInterop$Types$SignOutJTE = 4;
var $author$project$JsInterop$Types$StudentsByTchIdJTE = 17;
var $author$project$JsInterop$Types$StudentsJTE = 13;
var $author$project$JsInterop$Types$TeachersJTE = 12;
var $author$project$JsInterop$Types$UpdateLoginUserJTE = 20;
var $author$project$JsInterop$Types$UpdateStudentJTE = 19;
var $author$project$JsInterop$Types$UpdateTeacherJTE = 18;
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$JsInterop$Types$interopTagDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (el) {
		switch (el) {
			case 'InteropFailureJTE':
				return $elm$json$Json$Decode$succeed(0);
			case 'SignInJTE':
				return $elm$json$Json$Decode$succeed(1);
			case 'ForgotPasswordJTE':
				return $elm$json$Json$Decode$succeed(2);
			case 'ForgotPasswordSubmitJTE':
				return $elm$json$Json$Decode$succeed(3);
			case 'SignOutJTE':
				return $elm$json$Json$Decode$succeed(4);
			case 'MeJTE':
				return $elm$json$Json$Decode$succeed(5);
			case 'GetVariantSelection_Mjd5_JTE':
				return $elm$json$Json$Decode$succeed(6);
			case 'GetVariantSelection_ShowVariant_JTE':
				return $elm$json$Json$Decode$succeed(7);
			case 'GetVariantSelection_ShowWorksheets_JTE':
				return $elm$json$Json$Decode$succeed(8);
			case 'GetVariantIdsJTE':
				return $elm$json$Json$Decode$succeed(9);
			case 'ConstructNodeForSyllabusJTE':
				return $elm$json$Json$Decode$succeed(10);
			case 'GetSyllabusesJTE':
				return $elm$json$Json$Decode$succeed(11);
			case 'TeachersJTE':
				return $elm$json$Json$Decode$succeed(12);
			case 'StudentsJTE':
				return $elm$json$Json$Decode$succeed(13);
			case 'SchoolsJTE':
				return $elm$json$Json$Decode$succeed(14);
			case 'NbrUsersForSchoolIdJTE':
				return $elm$json$Json$Decode$succeed(15);
			case 'DeleteSchoolJTE':
				return $elm$json$Json$Decode$succeed(16);
			case 'StudentsByTchIdJTE':
				return $elm$json$Json$Decode$succeed(17);
			case 'UpdateTeacherJTE':
				return $elm$json$Json$Decode$succeed(18);
			case 'UpdateStudentJTE':
				return $elm$json$Json$Decode$succeed(19);
			case 'UpdateLoginUserJTE':
				return $elm$json$Json$Decode$succeed(20);
			case 'DeleteLoginUserJTE':
				return $elm$json$Json$Decode$succeed(21);
			case 'DeleteStudentHistoryJTE':
				return $elm$json$Json$Decode$succeed(22);
			case 'CreateTeacherJTE':
				return $elm$json$Json$Decode$succeed(23);
			case 'CreateStudentJTE':
				return $elm$json$Json$Decode$succeed(24);
			case 'CreateSchoolJTE':
				return $elm$json$Json$Decode$succeed(37);
			case 'CreateQuestionGroupJTE':
				return $elm$json$Json$Decode$succeed(38);
			case 'LogErrorETJ':
				return $elm$json$Json$Decode$succeed(36);
			case 'SignInETJ':
				return $elm$json$Json$Decode$succeed(25);
			case 'ForgotPasswordETJ':
				return $elm$json$Json$Decode$succeed(26);
			case 'ForgotPasswordSubmitETJ':
				return $elm$json$Json$Decode$succeed(27);
			case 'SignOutETJ':
				return $elm$json$Json$Decode$succeed(28);
			case 'MeETJ':
				return $elm$json$Json$Decode$succeed(35);
			case 'GetVariantSelection_Mjd5_ETJ':
				return $elm$json$Json$Decode$succeed(29);
			case 'GetVariantSelection_ShowVariant_ETJ':
				return $elm$json$Json$Decode$succeed(30);
			case 'GetVariantSelection_ShowWorksheets_ETJ':
				return $elm$json$Json$Decode$succeed(31);
			case 'GetVariantIdsETJ':
				return $elm$json$Json$Decode$succeed(32);
			case 'ConstructNodeForSyllabusETJ':
				return $elm$json$Json$Decode$succeed(33);
			case 'GetSyllabusesETJ':
				return $elm$json$Json$Decode$succeed(34);
			default:
				return $elm$json$Json$Decode$fail('A misconfiguration has occurred since there is no associated InteropTag for [' + (el + '].'));
		}
	},
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$JsInterop$Types$interopTagsDecoder = $elm$json$Json$Decode$list($author$project$JsInterop$Types$interopTagDecoder);
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Routing$AdaptiveAnalyticsRoute = {$: 27};
var $author$project$Routing$AdminPageRoute = {$: 15};
var $author$project$Routing$AnalyticsItemRoute = function (a) {
	return {$: 10, a: a};
};
var $author$project$Routing$AnalyticsListRoute = {$: 9};
var $author$project$Routing$AnswerBoxAnswerRoute = function (a) {
	return {$: 2, a: a};
};
var $author$project$SharedTypes$ByBand = 0;
var $author$project$SharedTypes$ByQuestion = 1;
var $author$project$Routing$ConfigureHiddenExamsRoute = {$: 8};
var $author$project$Routing$ConfirmSectionRoute = {$: 5};
var $author$project$Routing$CreateUsersRoute = function (a) {
	return {$: 21, a: a};
};
var $author$project$Routing$CurrentExamStatsRoute = {$: 4};
var $author$project$Routing$CurrentQuestionRoute = function (a) {
	return {$: 1, a: a};
};
var $author$project$Routing$DeleteUsersRoute = function (a) {
	return {$: 24, a: a};
};
var $author$project$Routing$EditUsersRoute = function (a) {
	return {$: 22, a: a};
};
var $author$project$Routing$ForgotPasswordRoute = {$: 19};
var $author$project$Routing$ForgotPasswordSubmitRoute = function (a) {
	return {$: 20, a: a};
};
var $author$project$Routing$ManageSchoolsRoute = {$: 26};
var $author$project$Routing$Mjd5Route = {$: 13};
var $author$project$Routing$MultiChoiceMultipleAnswerRoute = function (a) {
	return {$: 1, a: a};
};
var $author$project$Routing$MultiChoiceSingleAnswerRoute = function (a) {
	return {$: 0, a: a};
};
var $author$project$Routing$NoDetailSubRoute = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Routing$ProfileRoute = function (a) {
	return {$: 17, a: a};
};
var $author$project$Routing$QuestionAndAnswerRoute = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $author$project$Routing$QuestionGroupInstructionsRoute = function (a) {
	return {$: 3, a: a};
};
var $author$project$Routing$RawDataDownloadRoute = {$: 11};
var $author$project$Routing$RawDataRoute = {$: 7};
var $author$project$Routing$ReportCardRoute = function (a) {
	return {$: 12, a: a};
};
var $author$project$Routing$SampleRoute = {$: 28};
var $author$project$Routing$SampleTestsRoute = function (a) {
	return {$: 6, a: a};
};
var $author$project$Routing$SessionsResultsRoute = F2(
	function (a, b) {
		return {$: 30, a: a, b: b};
	});
var $author$project$Routing$ShowVariantRoute = {$: 16};
var $author$project$Routing$ShowWorksheetRoute = {$: 32};
var $author$project$Routing$ShowWorksheetsRoute = {$: 33};
var $author$project$Routing$SignInRoute = {$: 18};
var $author$project$Routing$SingleDataBoxRouteV1 = function (a) {
	return {$: 3, a: a};
};
var $author$project$Routing$SingleSessionResultsRoute = F3(
	function (a, b, c) {
		return {$: 31, a: a, b: b, c: c};
	});
var $author$project$Routing$StudentResultsRoute = F2(
	function (a, b) {
		return {$: 29, a: a, b: b};
	});
var $author$project$Routing$Students = 0;
var $author$project$Routing$Teachers = 1;
var $author$project$Routing$UpdateUsersRoute = function (a) {
	return {$: 25, a: a};
};
var $author$project$Routing$ViewStudentsRoute = {$: 23};
var $author$project$SharedTypes$chartTypeToString = function (chartType) {
	if (!chartType) {
		return 'ByBand';
	} else {
		return 'ByQuestion';
	}
};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {bt: frag, s: params, bh: unvisited, pI: value, bV: visited};
	});
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.bV;
			var unvisited = _v0.bh;
			var params = _v0.s;
			var frag = _v0.bt;
			var value = _v0.pI;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$int = A2($elm$url$Url$Parser$custom, 'NUMBER', $elm$core$String$toInt);
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $author$project$Routing$jsonStringToInts = function (str) {
	return A2(
		$elm$core$Result$withDefault,
		_List_Nil,
		A2(
			$elm$json$Json$Decode$decodeString,
			$elm$json$Json$Decode$list($elm$json$Json$Decode$int),
			str));
};
var $elm$json$Json$Decode$array = _Json_decodeArray;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $author$project$Routing$jsonStringToStrings = function (strs) {
	return A2(
		$elm$core$Result$withDefault,
		$elm$core$Array$empty,
		A2(
			$elm$json$Json$Decode$decodeString,
			$elm$json$Json$Decode$array(
				$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
			A2(
				$elm$core$Maybe$withDefault,
				'',
				$elm$url$Url$percentDecode(strs))));
};
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.bV;
		var unvisited = _v0.bh;
		var params = _v0.s;
		var frag = _v0.bt;
		var value = _v0.pI;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.bV;
			var unvisited = _v1.bh;
			var params = _v1.s;
			var frag = _v1.bt;
			var value = _v1.pI;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0;
	return function (_v1) {
		var visited = _v1.bV;
		var unvisited = _v1.bh;
		var params = _v1.s;
		var frag = _v1.bt;
		var value = _v1.pI;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					queryParser(params)))
			]);
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$questionMark = F2(
	function (parser, queryParser) {
		return A2(
			$elm$url$Url$Parser$slash,
			parser,
			$elm$url$Url$Parser$query(queryParser));
	});
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.bV;
		var unvisited = _v0.bh;
		var params = _v0.s;
		var frag = _v0.bt;
		var value = _v0.pI;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $author$project$Routing$smPaths = {ga: 'sm_adaptiveAnalytics', jM: 'sm_adminPage', cC: 'sm_analytics', gw: 'sm_configureHiddenExams', gx: 'sm_confSection', kP: 'sm_createUsers', eG: 'sm_deleteUsers', gY: 'sm_editUsers', ll: 'sm_error', g$: 'sm_exam-instructions', g0: 'sm_estats', lB: 'sm_forgotPassword', g6: 'sm_forgotPasswordSubmit', mx: 'sm_manageSchools', mJ: 'sm_mjd5', nC: 'sm_profile', dS: 'sm_qid', it: 'sm_qidx', nK: 'sm_rawData', iz: 'sm_rawDataDownload', nN: 'sm_registration', nQ: 'sm_reportCard', iI: 'sm_sample', n3: 'sm_sampleTests', of: 'sm_session-results', og: 'sm_sessionsResults', iS: 'sm_showVariant', ok: 'sm_worksheet', ol: 'sm_worksheets', om: 'sm_signin', oF: 'sm_studentResults', jl: 'sm_updateUsers', pN: 'sm_viewStudents'};
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $elm$url$Url$Parser$Internal$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return function (dict) {
			return func(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, key, dict)));
		};
	});
var $elm$url$Url$Parser$Query$string = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$Maybe$Just(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Routing$userRouteTypeToString = function (userRouteType) {
	if (!userRouteType) {
		return 'Student';
	} else {
		return 'Teacher';
	}
};
var $author$project$Routing$matchers = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, $author$project$Routing$HomeRoute, $elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$CurrentQuestionRoute,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s($author$project$Routing$smPaths.it),
				$elm$url$Url$Parser$int)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$QuestionAndAnswerRoute,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s($author$project$Routing$smPaths.dS),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$string,
					A2(
						$elm$url$Url$Parser$slash,
						$elm$url$Url$Parser$string,
						$elm$url$Url$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$url$Url$Parser$map,
									$author$project$Routing$MultiChoiceSingleAnswerRoute,
									A2(
										$elm$url$Url$Parser$slash,
										$elm$url$Url$Parser$s('mc'),
										$elm$url$Url$Parser$int)),
									A2(
									$elm$url$Url$Parser$map,
									A2($elm$core$Basics$composeL, $author$project$Routing$MultiChoiceMultipleAnswerRoute, $author$project$Routing$jsonStringToInts),
									A2(
										$elm$url$Url$Parser$slash,
										$elm$url$Url$Parser$s('mcma'),
										$elm$url$Url$Parser$string)),
									A2(
									$elm$url$Url$Parser$map,
									A2($elm$core$Basics$composeL, $author$project$Routing$AnswerBoxAnswerRoute, $author$project$Routing$jsonStringToStrings),
									A2(
										$elm$url$Url$Parser$slash,
										$elm$url$Url$Parser$s('abox'),
										$elm$url$Url$Parser$string)),
									A2(
									$elm$url$Url$Parser$map,
									$author$project$Routing$SingleDataBoxRouteV1,
									A2(
										$elm$url$Url$Parser$slash,
										$elm$url$Url$Parser$s('sa'),
										$elm$url$Url$Parser$string)),
									A2(
									$elm$url$Url$Parser$map,
									$author$project$Routing$NoDetailSubRoute,
									A2($elm$url$Url$Parser$slash, $elm$url$Url$Parser$string, $elm$url$Url$Parser$string))
								])))))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$QuestionGroupInstructionsRoute,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s($author$project$Routing$smPaths.g$),
				$elm$url$Url$Parser$int)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$CurrentExamStatsRoute,
			$elm$url$Url$Parser$s($author$project$Routing$smPaths.g0)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$ConfirmSectionRoute,
			$elm$url$Url$Parser$s($author$project$Routing$smPaths.gx)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$SampleTestsRoute,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s($author$project$Routing$smPaths.n3),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$RawDataRoute,
			$elm$url$Url$Parser$s($author$project$Routing$smPaths.nK)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$ConfigureHiddenExamsRoute,
			$elm$url$Url$Parser$s($author$project$Routing$smPaths.gw)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$AnalyticsListRoute,
			$elm$url$Url$Parser$s($author$project$Routing$smPaths.cC)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$AnalyticsItemRoute,
			$elm$url$Url$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$url$Url$Parser$map,
						1,
						A2(
							$elm$url$Url$Parser$slash,
							$elm$url$Url$Parser$s($author$project$Routing$smPaths.cC),
							$elm$url$Url$Parser$s(
								$author$project$SharedTypes$chartTypeToString(1)))),
						A2(
						$elm$url$Url$Parser$map,
						0,
						A2(
							$elm$url$Url$Parser$slash,
							$elm$url$Url$Parser$s($author$project$Routing$smPaths.cC),
							$elm$url$Url$Parser$s(
								$author$project$SharedTypes$chartTypeToString(0))))
					]))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$RawDataDownloadRoute,
			$elm$url$Url$Parser$s($author$project$Routing$smPaths.iz)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$StudentResultsRoute,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s($author$project$Routing$smPaths.oF),
				A2(
					$elm$url$Url$Parser$questionMark,
					$elm$url$Url$Parser$string,
					$elm$url$Url$Parser$Query$string('show')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$ReportCardRoute,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s($author$project$Routing$smPaths.nQ),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$SessionsResultsRoute,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s($author$project$Routing$smPaths.og),
				A2($elm$url$Url$Parser$slash, $elm$url$Url$Parser$string, $elm$url$Url$Parser$string))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$Mjd5Route,
			$elm$url$Url$Parser$s($author$project$Routing$smPaths.mJ)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$AdminPageRoute,
			$elm$url$Url$Parser$s($author$project$Routing$smPaths.jM)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$ShowVariantRoute,
			$elm$url$Url$Parser$s($author$project$Routing$smPaths.iS)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$ProfileRoute,
			$elm$url$Url$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$url$Url$Parser$map,
						$elm$core$Maybe$Nothing,
						$elm$url$Url$Parser$s($author$project$Routing$smPaths.nC)),
						A2(
						$elm$url$Url$Parser$map,
						$elm$core$Maybe$Just,
						A2(
							$elm$url$Url$Parser$slash,
							$elm$url$Url$Parser$s($author$project$Routing$smPaths.nC),
							$elm$url$Url$Parser$string))
					]))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$SignInRoute,
			$elm$url$Url$Parser$s($author$project$Routing$smPaths.om)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$ForgotPasswordRoute,
			$elm$url$Url$Parser$s($author$project$Routing$smPaths.lB)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$ForgotPasswordSubmitRoute,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s($author$project$Routing$smPaths.g6),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$CreateUsersRoute,
			$elm$url$Url$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$url$Url$Parser$map,
						1,
						A2(
							$elm$url$Url$Parser$slash,
							$elm$url$Url$Parser$s($author$project$Routing$smPaths.kP),
							$elm$url$Url$Parser$s(
								$author$project$Routing$userRouteTypeToString(1)))),
						A2(
						$elm$url$Url$Parser$map,
						0,
						A2(
							$elm$url$Url$Parser$slash,
							$elm$url$Url$Parser$s($author$project$Routing$smPaths.kP),
							$elm$url$Url$Parser$s(
								$author$project$Routing$userRouteTypeToString(0))))
					]))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$EditUsersRoute,
			$elm$url$Url$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$url$Url$Parser$map,
						1,
						A2(
							$elm$url$Url$Parser$slash,
							$elm$url$Url$Parser$s($author$project$Routing$smPaths.gY),
							$elm$url$Url$Parser$s(
								$author$project$Routing$userRouteTypeToString(1)))),
						A2(
						$elm$url$Url$Parser$map,
						0,
						A2(
							$elm$url$Url$Parser$slash,
							$elm$url$Url$Parser$s($author$project$Routing$smPaths.gY),
							$elm$url$Url$Parser$s(
								$author$project$Routing$userRouteTypeToString(0))))
					]))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$ViewStudentsRoute,
			$elm$url$Url$Parser$s($author$project$Routing$smPaths.pN)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$DeleteUsersRoute,
			$elm$url$Url$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$url$Url$Parser$map,
						1,
						A2(
							$elm$url$Url$Parser$slash,
							$elm$url$Url$Parser$s($author$project$Routing$smPaths.eG),
							$elm$url$Url$Parser$s(
								$author$project$Routing$userRouteTypeToString(1)))),
						A2(
						$elm$url$Url$Parser$map,
						0,
						A2(
							$elm$url$Url$Parser$slash,
							$elm$url$Url$Parser$s($author$project$Routing$smPaths.eG),
							$elm$url$Url$Parser$s(
								$author$project$Routing$userRouteTypeToString(0))))
					]))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$UpdateUsersRoute,
			$elm$url$Url$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$url$Url$Parser$map,
						0,
						A2(
							$elm$url$Url$Parser$slash,
							$elm$url$Url$Parser$s($author$project$Routing$smPaths.jl),
							$elm$url$Url$Parser$s(
								$author$project$Routing$userRouteTypeToString(0))))
					]))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$SampleRoute,
			$elm$url$Url$Parser$s($author$project$Routing$smPaths.iI)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$ManageSchoolsRoute,
			$elm$url$Url$Parser$s($author$project$Routing$smPaths.mx)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$AdaptiveAnalyticsRoute,
			$elm$url$Url$Parser$s($author$project$Routing$smPaths.ga)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$SingleSessionResultsRoute,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s($author$project$Routing$smPaths.of),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$string,
					A2($elm$url$Url$Parser$slash, $elm$url$Url$Parser$string, $elm$url$Url$Parser$string)))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$ShowWorksheetRoute,
			$elm$url$Url$Parser$s($author$project$Routing$smPaths.ok)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$ShowWorksheetsRoute,
			$elm$url$Url$Parser$s($author$project$Routing$smPaths.ol)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routing$FatalErrorRoute,
			$elm$url$Url$Parser$s($author$project$Routing$smPaths.ll))
		]));
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.bh;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.pI);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.pI);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.ig),
					$elm$url$Url$Parser$prepareQuery(url.t),
					url.g8,
					$elm$core$Basics$identity)));
	});
var $author$project$Routing$locationHrefToRoute = function (locationHref) {
	return A2(
		$elm$core$Maybe$andThen,
		$elm$url$Url$Parser$parse($author$project$Routing$matchers),
		$elm$url$Url$fromString(locationHref));
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Main$init = function (flags) {
	var decodedInteropTags = A2($elm$json$Json$Decode$decodeValue, $author$project$JsInterop$Types$interopTagsDecoder, flags.hr);
	var _v0 = function () {
		if (decodedInteropTags.$ === 1) {
			var e = decodedInteropTags.a;
			return _Utils_Tuple2(
				$author$project$Routing$FatalErrorRoute,
				$elm$core$Maybe$Just(
					{
						lj: 'Apologies ... we can\'t continue due to an application configuration error.  Please notify SmarterMaths (support@smartermaths.com.au) and we\'ll get it fixed asap!',
						lk: $elm$core$Maybe$Just(
							'Could not successfully decode the flags.interopTags to a list of InteropTags. Message from the decoder is: ' + $elm$json$Json$Decode$errorToString(e))
					}));
		} else {
			return _Utils_Tuple2(
				A2(
					$elm$core$Maybe$withDefault,
					$author$project$Routing$HomeRoute,
					$author$project$Routing$locationHrefToRoute(flags.hm)),
				$elm$core$Maybe$Nothing);
		}
	}();
	var currentRoute = _v0.a;
	var errorMaybe = _v0.b;
	return _Utils_Tuple2(
		A3($author$project$Main$initModel, flags, currentRoute, errorMaybe),
		$elm$core$Platform$Cmd$none);
};
var $author$project$UITool$Calculator$DragMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Models$ExamInProgress = 1;
var $author$project$Msgs$HandleFailure = function (a) {
	return {$: 50, a: a};
};
var $author$project$Msgs$MsgPageShowVariant = function (a) {
	return {$: 55, a: a};
};
var $author$project$Msgs$MsgToolCalc = function (a) {
	return {$: 68, a: a};
};
var $author$project$Page$ShowVariant$MsgToolCalc = function (a) {
	return {$: 15, a: a};
};
var $author$project$Msgs$OnAnswerMadeMC = function (a) {
	return {$: 36, a: a};
};
var $author$project$Msgs$OnAnswerMadeStr = function (a) {
	return {$: 37, a: a};
};
var $author$project$Msgs$OnDecodeContent = function (a) {
	return {$: 25, a: a};
};
var $author$project$Msgs$OnInfoForElm = function (a) {
	return {$: 49, a: a};
};
var $author$project$Msgs$Tick = function (a) {
	return {$: 10, a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $author$project$Msgs$ShowFatalError = F2(
	function (a, b) {
		return {$: 13, a: a, b: b};
	});
var $author$project$Msgs$ShowNextQuestion = {$: 12};
var $author$project$Models$ShowNextQuestionRequest = 0;
var $author$project$DataService$actionTriggerRequestDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		if (str === 'ShowNextQuestion') {
			return $elm$json$Json$Decode$succeed(0);
		} else {
			var somethingElse = str;
			return $elm$json$Json$Decode$fail('Unknown action: ' + somethingElse);
		}
	},
	A2($elm$json$Json$Decode$field, 'action', $elm$json$Json$Decode$string));
var $author$project$Subscriptions$decodeActionTriggerRequest = function (req) {
	var result = A2($elm$json$Json$Decode$decodeValue, $author$project$DataService$actionTriggerRequestDecoder, req);
	if (!result.$) {
		var actionRequest = result.a;
		return $author$project$Msgs$ShowNextQuestion;
	} else {
		var err = result.a;
		return A2(
			$author$project$Msgs$ShowFatalError,
			'Sorry - we are unable to understand the requested action.',
			$elm$json$Json$Decode$errorToString(err));
	}
};
var $krisajenkins$remotedata$RemoteData$Failure = function (a) {
	return {$: 2, a: a};
};
var $author$project$Msgs$MsgPageAdminPage = function (a) {
	return {$: 54, a: a};
};
var $author$project$Msgs$MsgPageShowWorksheet = function (a) {
	return {$: 56, a: a};
};
var $author$project$Page$AdminPage$OnDecryptItem = function (a) {
	return {$: 15, a: a};
};
var $author$project$Page$ShowVariant$OnDecryptItem = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$ShowWorksheet$OnDecryptItem = function (a) {
	return {$: 3, a: a};
};
var $krisajenkins$remotedata$RemoteData$Success = function (a) {
	return {$: 3, a: a};
};
var $author$project$Type$Variant$VariantAnswerSet_AnsBox = function (a) {
	return {$: 2, a: a};
};
var $author$project$Type$Variant$VariantAnswerSet_MCMA = function (a) {
	return {$: 1, a: a};
};
var $author$project$Type$Variant$VariantAnswerSet_MCSA = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $author$project$Type$Error$deadEndsToString = function (deadEnds) {
	var problemToString = function (problem) {
		switch (problem.$) {
			case 0:
				var str1 = problem.a;
				return 'Was expecting: ' + str1;
			case 1:
				return 'Was expecting an Int value';
			case 2:
				return 'Was expecting a Hex value';
			case 3:
				return 'Was expecting an Octal value';
			case 4:
				return 'Was expecting a Binary value';
			case 5:
				return 'Was expecting a Float value';
			case 6:
				return 'Was expecting a Number value';
			case 7:
				return 'Was expecting a Variable value';
			case 8:
				var str2 = problem.a;
				return 'Was expecting a Symbol of: ' + str2;
			case 9:
				var str3 = problem.a;
				return 'Was expecting a Keyword of: ' + str3;
			case 10:
				return 'Was expecting an End value';
			case 11:
				return 'Encountered an UnexpectedChar';
			case 12:
				var str4 = problem.a;
				return 'Encountered the following Problem: ' + str4;
			default:
				return 'Encountered a BadRepeat';
		}
	};
	return A3(
		$elm$core$List$foldl,
		$elm$core$Basics$append,
		'',
		A2(
			$elm$core$List$map,
			function (deadEnd) {
				return problemToString(deadEnd.nA) + (' at row ' + ($elm$core$String$fromInt(deadEnd.n$) + (' at column ' + $elm$core$String$fromInt(deadEnd.kB))));
			},
			deadEnds));
};
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$Result$map3 = F4(
	function (func, ra, rb, rc) {
		if (ra.$ === 1) {
			var x = ra.a;
			return $elm$core$Result$Err(x);
		} else {
			var a = ra.a;
			if (rb.$ === 1) {
				var x = rb.a;
				return $elm$core$Result$Err(x);
			} else {
				var b = rb.a;
				if (rc.$ === 1) {
					var x = rc.a;
					return $elm$core$Result$Err(x);
				} else {
					var c = rc.a;
					return $elm$core$Result$Ok(
						A3(func, a, b, c));
				}
			}
		}
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$core$List$partition = F2(
	function (pred, list) {
		var step = F2(
			function (x, _v0) {
				var trues = _v0.a;
				var falses = _v0.b;
				return pred(x) ? _Utils_Tuple2(
					A2($elm$core$List$cons, x, trues),
					falses) : _Utils_Tuple2(
					trues,
					A2($elm$core$List$cons, x, falses));
			});
		return A3(
			$elm$core$List$foldr,
			step,
			_Utils_Tuple2(_List_Nil, _List_Nil),
			list);
	});
var $author$project$Html$Parser$Element = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {kB: col, kM: contextStack, nA: problem, n$: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.n$, s.kB, x, s.kL));
	});
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.b, s.ov);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{kB: 1, kL: s.kL, h: s.h, b: s.b + 1, n$: s.n$ + 1, ov: s.ov}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{kB: s.kB + 1, kL: s.kL, h: s.h, b: newOffset, n$: s.n$, ov: s.ov}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.ov);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.b, offset) < 0,
					0,
					{kB: col, kL: s0.kL, h: s0.h, b: offset, n$: row, ov: s0.ov});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.b, s.n$, s.kB, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $author$project$Html$Parser$chompOneOrMore = function (fn) {
	return A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$chompIf(fn),
		$elm$parser$Parser$chompWhile(fn));
};
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.b, s1.b, s0.ov),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $author$project$Html$Parser$isSpaceCharacter = function (c) {
	return (c === ' ') || ((c === '\t') || ((c === '\n') || ((c === '\u000D') || ((c === '\u000C') || (c === '\u00A0')))));
};
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$core$String$toLower = _String_toLower;
var $author$project$Html$Parser$closingTag = function (name) {
	var chompName = A2(
		$elm$parser$Parser$andThen,
		function (closingName) {
			return _Utils_eq(
				$elm$core$String$toLower(closingName),
				name) ? $elm$parser$Parser$succeed(0) : $elm$parser$Parser$problem('closing tag does not match opening tag: ' + name);
		},
		$elm$parser$Parser$getChompedString(
			$author$project$Html$Parser$chompOneOrMore(
				function (c) {
					return (!$author$project$Html$Parser$isSpaceCharacter(c)) && (c !== '>');
				})));
	return A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('<')),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('/'))),
				chompName),
			$elm$parser$Parser$chompWhile($author$project$Html$Parser$isSpaceCharacter)),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('>')));
};
var $author$project$Html$Parser$Comment = function (a) {
	return {$: 2, a: a};
};
var $elm$parser$Parser$Advanced$findSubString = _Parser_findSubString;
var $elm$parser$Parser$Advanced$fromInfo = F4(
	function (row, col, x, context) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, row, col, x, context));
	});
var $elm$parser$Parser$Advanced$chompUntil = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$findSubString, str, s.b, s.n$, s.kB, s.ov);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A4($elm$parser$Parser$Advanced$fromInfo, newRow, newCol, expecting, s.kL)) : A3(
			$elm$parser$Parser$Advanced$Good,
			_Utils_cmp(s.b, newOffset) < 0,
			0,
			{kB: newCol, kL: s.kL, h: s.h, b: newOffset, n$: newRow, ov: s.ov});
	};
};
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$chompUntil = function (str) {
	return $elm$parser$Parser$Advanced$chompUntil(
		$elm$parser$Parser$toToken(str));
};
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.b, s.n$, s.kB, s.ov);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{kB: newCol, kL: s.kL, h: s.h, b: newOffset, n$: newRow, ov: s.ov});
	};
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $author$project$Html$Parser$commentString = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$token('<!')),
		$elm$parser$Parser$token('--')),
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$getChompedString(
			$elm$parser$Parser$chompUntil('-->')),
		$elm$parser$Parser$token('-->')));
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $author$project$Html$Parser$comment = A2($elm$parser$Parser$map, $author$project$Html$Parser$Comment, $author$project$Html$Parser$commentString);
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$Html$Parser$voidElements = _List_fromArray(
	['area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 'link', 'meta', 'param', 'source', 'track', 'wbr']);
var $author$project$Html$Parser$isVoidElement = function (name) {
	return A2($elm$core$List$member, name, $author$project$Html$Parser$voidElements);
};
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $author$project$Html$Parser$many = function (parser_) {
	return A2(
		$elm$parser$Parser$loop,
		_List_Nil,
		function (list) {
			return $elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$map,
						function (_new) {
							return $elm$parser$Parser$Loop(
								A2($elm$core$List$cons, _new, list));
						},
						parser_),
						$elm$parser$Parser$succeed(
						$elm$parser$Parser$Done(
							$elm$core$List$reverse(list)))
					]));
		});
};
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $author$project$Html$Parser$isTagAttributeCharacter = function (c) {
	return (!$author$project$Html$Parser$isSpaceCharacter(c)) && ((c !== '\"') && ((c !== '\'') && ((c !== '>') && ((c !== '/') && (c !== '=')))));
};
var $author$project$Html$Parser$tagAttributeName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		$author$project$Html$Parser$chompOneOrMore($author$project$Html$Parser$isTagAttributeCharacter)));
var $author$project$Html$Parser$chompSemicolon = $elm$parser$Parser$chompIf(
	$elm$core$Basics$eq(';'));
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $author$project$Html$Parser$NamedCharacterReferences$dict = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('Aacute', 'Á'),
			_Utils_Tuple2('aacute', 'á'),
			_Utils_Tuple2('Abreve', 'Ă'),
			_Utils_Tuple2('abreve', 'ă'),
			_Utils_Tuple2('ac', '∾'),
			_Utils_Tuple2('acd', '∿'),
			_Utils_Tuple2('acE', '∾̳'),
			_Utils_Tuple2('Acirc', 'Â'),
			_Utils_Tuple2('acirc', 'â'),
			_Utils_Tuple2('acute', '´'),
			_Utils_Tuple2('Acy', 'А'),
			_Utils_Tuple2('acy', 'а'),
			_Utils_Tuple2('AElig', 'Æ'),
			_Utils_Tuple2('aelig', 'æ'),
			_Utils_Tuple2('af', '\u2061'),
			_Utils_Tuple2('Afr', '\uD835\uDD04'),
			_Utils_Tuple2('afr', '\uD835\uDD1E'),
			_Utils_Tuple2('Agrave', 'À'),
			_Utils_Tuple2('agrave', 'à'),
			_Utils_Tuple2('alefsym', 'ℵ'),
			_Utils_Tuple2('aleph', 'ℵ'),
			_Utils_Tuple2('Alpha', 'Α'),
			_Utils_Tuple2('alpha', 'α'),
			_Utils_Tuple2('Amacr', 'Ā'),
			_Utils_Tuple2('amacr', 'ā'),
			_Utils_Tuple2('amalg', '⨿'),
			_Utils_Tuple2('amp', '&'),
			_Utils_Tuple2('AMP', '&'),
			_Utils_Tuple2('andand', '⩕'),
			_Utils_Tuple2('And', '⩓'),
			_Utils_Tuple2('and', '∧'),
			_Utils_Tuple2('andd', '⩜'),
			_Utils_Tuple2('andslope', '⩘'),
			_Utils_Tuple2('andv', '⩚'),
			_Utils_Tuple2('ang', '∠'),
			_Utils_Tuple2('ange', '⦤'),
			_Utils_Tuple2('angle', '∠'),
			_Utils_Tuple2('angmsdaa', '⦨'),
			_Utils_Tuple2('angmsdab', '⦩'),
			_Utils_Tuple2('angmsdac', '⦪'),
			_Utils_Tuple2('angmsdad', '⦫'),
			_Utils_Tuple2('angmsdae', '⦬'),
			_Utils_Tuple2('angmsdaf', '⦭'),
			_Utils_Tuple2('angmsdag', '⦮'),
			_Utils_Tuple2('angmsdah', '⦯'),
			_Utils_Tuple2('angmsd', '∡'),
			_Utils_Tuple2('angrt', '∟'),
			_Utils_Tuple2('angrtvb', '⊾'),
			_Utils_Tuple2('angrtvbd', '⦝'),
			_Utils_Tuple2('angsph', '∢'),
			_Utils_Tuple2('angst', 'Å'),
			_Utils_Tuple2('angzarr', '⍼'),
			_Utils_Tuple2('Aogon', 'Ą'),
			_Utils_Tuple2('aogon', 'ą'),
			_Utils_Tuple2('Aopf', '\uD835\uDD38'),
			_Utils_Tuple2('aopf', '\uD835\uDD52'),
			_Utils_Tuple2('apacir', '⩯'),
			_Utils_Tuple2('ap', '≈'),
			_Utils_Tuple2('apE', '⩰'),
			_Utils_Tuple2('ape', '≊'),
			_Utils_Tuple2('apid', '≋'),
			_Utils_Tuple2('apos', '\''),
			_Utils_Tuple2('ApplyFunction', '\u2061'),
			_Utils_Tuple2('approx', '≈'),
			_Utils_Tuple2('approxeq', '≊'),
			_Utils_Tuple2('Aring', 'Å'),
			_Utils_Tuple2('aring', 'å'),
			_Utils_Tuple2('Ascr', '\uD835\uDC9C'),
			_Utils_Tuple2('ascr', '\uD835\uDCB6'),
			_Utils_Tuple2('Assign', '≔'),
			_Utils_Tuple2('ast', '*'),
			_Utils_Tuple2('asymp', '≈'),
			_Utils_Tuple2('asympeq', '≍'),
			_Utils_Tuple2('Atilde', 'Ã'),
			_Utils_Tuple2('atilde', 'ã'),
			_Utils_Tuple2('Auml', 'Ä'),
			_Utils_Tuple2('auml', 'ä'),
			_Utils_Tuple2('awconint', '∳'),
			_Utils_Tuple2('awint', '⨑'),
			_Utils_Tuple2('backcong', '≌'),
			_Utils_Tuple2('backepsilon', '϶'),
			_Utils_Tuple2('backprime', '‵'),
			_Utils_Tuple2('backsim', '∽'),
			_Utils_Tuple2('backsimeq', '⋍'),
			_Utils_Tuple2('Backslash', '∖'),
			_Utils_Tuple2('Barv', '⫧'),
			_Utils_Tuple2('barvee', '⊽'),
			_Utils_Tuple2('barwed', '⌅'),
			_Utils_Tuple2('Barwed', '⌆'),
			_Utils_Tuple2('barwedge', '⌅'),
			_Utils_Tuple2('bbrk', '⎵'),
			_Utils_Tuple2('bbrktbrk', '⎶'),
			_Utils_Tuple2('bcong', '≌'),
			_Utils_Tuple2('Bcy', 'Б'),
			_Utils_Tuple2('bcy', 'б'),
			_Utils_Tuple2('bdquo', '„'),
			_Utils_Tuple2('becaus', '∵'),
			_Utils_Tuple2('because', '∵'),
			_Utils_Tuple2('Because', '∵'),
			_Utils_Tuple2('bemptyv', '⦰'),
			_Utils_Tuple2('bepsi', '϶'),
			_Utils_Tuple2('bernou', 'ℬ'),
			_Utils_Tuple2('Bernoullis', 'ℬ'),
			_Utils_Tuple2('Beta', 'Β'),
			_Utils_Tuple2('beta', 'β'),
			_Utils_Tuple2('beth', 'ℶ'),
			_Utils_Tuple2('between', '≬'),
			_Utils_Tuple2('Bfr', '\uD835\uDD05'),
			_Utils_Tuple2('bfr', '\uD835\uDD1F'),
			_Utils_Tuple2('bigcap', '⋂'),
			_Utils_Tuple2('bigcirc', '◯'),
			_Utils_Tuple2('bigcup', '⋃'),
			_Utils_Tuple2('bigodot', '⨀'),
			_Utils_Tuple2('bigoplus', '⨁'),
			_Utils_Tuple2('bigotimes', '⨂'),
			_Utils_Tuple2('bigsqcup', '⨆'),
			_Utils_Tuple2('bigstar', '★'),
			_Utils_Tuple2('bigtriangledown', '▽'),
			_Utils_Tuple2('bigtriangleup', '△'),
			_Utils_Tuple2('biguplus', '⨄'),
			_Utils_Tuple2('bigvee', '⋁'),
			_Utils_Tuple2('bigwedge', '⋀'),
			_Utils_Tuple2('bkarow', '⤍'),
			_Utils_Tuple2('blacklozenge', '⧫'),
			_Utils_Tuple2('blacksquare', '▪'),
			_Utils_Tuple2('blacktriangle', '▴'),
			_Utils_Tuple2('blacktriangledown', '▾'),
			_Utils_Tuple2('blacktriangleleft', '◂'),
			_Utils_Tuple2('blacktriangleright', '▸'),
			_Utils_Tuple2('blank', '␣'),
			_Utils_Tuple2('blk12', '▒'),
			_Utils_Tuple2('blk14', '░'),
			_Utils_Tuple2('blk34', '▓'),
			_Utils_Tuple2('block', '█'),
			_Utils_Tuple2('bne', '=⃥'),
			_Utils_Tuple2('bnequiv', '≡⃥'),
			_Utils_Tuple2('bNot', '⫭'),
			_Utils_Tuple2('bnot', '⌐'),
			_Utils_Tuple2('Bopf', '\uD835\uDD39'),
			_Utils_Tuple2('bopf', '\uD835\uDD53'),
			_Utils_Tuple2('bot', '⊥'),
			_Utils_Tuple2('bottom', '⊥'),
			_Utils_Tuple2('bowtie', '⋈'),
			_Utils_Tuple2('boxbox', '⧉'),
			_Utils_Tuple2('boxdl', '┐'),
			_Utils_Tuple2('boxdL', '╕'),
			_Utils_Tuple2('boxDl', '╖'),
			_Utils_Tuple2('boxDL', '╗'),
			_Utils_Tuple2('boxdr', '┌'),
			_Utils_Tuple2('boxdR', '╒'),
			_Utils_Tuple2('boxDr', '╓'),
			_Utils_Tuple2('boxDR', '╔'),
			_Utils_Tuple2('boxh', '─'),
			_Utils_Tuple2('boxH', '═'),
			_Utils_Tuple2('boxhd', '┬'),
			_Utils_Tuple2('boxHd', '╤'),
			_Utils_Tuple2('boxhD', '╥'),
			_Utils_Tuple2('boxHD', '╦'),
			_Utils_Tuple2('boxhu', '┴'),
			_Utils_Tuple2('boxHu', '╧'),
			_Utils_Tuple2('boxhU', '╨'),
			_Utils_Tuple2('boxHU', '╩'),
			_Utils_Tuple2('boxminus', '⊟'),
			_Utils_Tuple2('boxplus', '⊞'),
			_Utils_Tuple2('boxtimes', '⊠'),
			_Utils_Tuple2('boxul', '┘'),
			_Utils_Tuple2('boxuL', '╛'),
			_Utils_Tuple2('boxUl', '╜'),
			_Utils_Tuple2('boxUL', '╝'),
			_Utils_Tuple2('boxur', '└'),
			_Utils_Tuple2('boxuR', '╘'),
			_Utils_Tuple2('boxUr', '╙'),
			_Utils_Tuple2('boxUR', '╚'),
			_Utils_Tuple2('boxv', '│'),
			_Utils_Tuple2('boxV', '║'),
			_Utils_Tuple2('boxvh', '┼'),
			_Utils_Tuple2('boxvH', '╪'),
			_Utils_Tuple2('boxVh', '╫'),
			_Utils_Tuple2('boxVH', '╬'),
			_Utils_Tuple2('boxvl', '┤'),
			_Utils_Tuple2('boxvL', '╡'),
			_Utils_Tuple2('boxVl', '╢'),
			_Utils_Tuple2('boxVL', '╣'),
			_Utils_Tuple2('boxvr', '├'),
			_Utils_Tuple2('boxvR', '╞'),
			_Utils_Tuple2('boxVr', '╟'),
			_Utils_Tuple2('boxVR', '╠'),
			_Utils_Tuple2('bprime', '‵'),
			_Utils_Tuple2('breve', '˘'),
			_Utils_Tuple2('Breve', '˘'),
			_Utils_Tuple2('brvbar', '¦'),
			_Utils_Tuple2('bscr', '\uD835\uDCB7'),
			_Utils_Tuple2('Bscr', 'ℬ'),
			_Utils_Tuple2('bsemi', '⁏'),
			_Utils_Tuple2('bsim', '∽'),
			_Utils_Tuple2('bsime', '⋍'),
			_Utils_Tuple2('bsolb', '⧅'),
			_Utils_Tuple2('bsol', '\\'),
			_Utils_Tuple2('bsolhsub', '⟈'),
			_Utils_Tuple2('bull', '•'),
			_Utils_Tuple2('bullet', '•'),
			_Utils_Tuple2('bump', '≎'),
			_Utils_Tuple2('bumpE', '⪮'),
			_Utils_Tuple2('bumpe', '≏'),
			_Utils_Tuple2('Bumpeq', '≎'),
			_Utils_Tuple2('bumpeq', '≏'),
			_Utils_Tuple2('Cacute', 'Ć'),
			_Utils_Tuple2('cacute', 'ć'),
			_Utils_Tuple2('capand', '⩄'),
			_Utils_Tuple2('capbrcup', '⩉'),
			_Utils_Tuple2('capcap', '⩋'),
			_Utils_Tuple2('cap', '∩'),
			_Utils_Tuple2('Cap', '⋒'),
			_Utils_Tuple2('capcup', '⩇'),
			_Utils_Tuple2('capdot', '⩀'),
			_Utils_Tuple2('CapitalDifferentialD', 'ⅅ'),
			_Utils_Tuple2('caps', '∩︀'),
			_Utils_Tuple2('caret', '⁁'),
			_Utils_Tuple2('caron', 'ˇ'),
			_Utils_Tuple2('Cayleys', 'ℭ'),
			_Utils_Tuple2('ccaps', '⩍'),
			_Utils_Tuple2('Ccaron', 'Č'),
			_Utils_Tuple2('ccaron', 'č'),
			_Utils_Tuple2('Ccedil', 'Ç'),
			_Utils_Tuple2('ccedil', 'ç'),
			_Utils_Tuple2('Ccirc', 'Ĉ'),
			_Utils_Tuple2('ccirc', 'ĉ'),
			_Utils_Tuple2('Cconint', '∰'),
			_Utils_Tuple2('ccups', '⩌'),
			_Utils_Tuple2('ccupssm', '⩐'),
			_Utils_Tuple2('Cdot', 'Ċ'),
			_Utils_Tuple2('cdot', 'ċ'),
			_Utils_Tuple2('cedil', '¸'),
			_Utils_Tuple2('Cedilla', '¸'),
			_Utils_Tuple2('cemptyv', '⦲'),
			_Utils_Tuple2('cent', '¢'),
			_Utils_Tuple2('centerdot', '·'),
			_Utils_Tuple2('CenterDot', '·'),
			_Utils_Tuple2('cfr', '\uD835\uDD20'),
			_Utils_Tuple2('Cfr', 'ℭ'),
			_Utils_Tuple2('CHcy', 'Ч'),
			_Utils_Tuple2('chcy', 'ч'),
			_Utils_Tuple2('check', '✓'),
			_Utils_Tuple2('checkmark', '✓'),
			_Utils_Tuple2('Chi', 'Χ'),
			_Utils_Tuple2('chi', 'χ'),
			_Utils_Tuple2('circ', 'ˆ'),
			_Utils_Tuple2('circeq', '≗'),
			_Utils_Tuple2('circlearrowleft', '↺'),
			_Utils_Tuple2('circlearrowright', '↻'),
			_Utils_Tuple2('circledast', '⊛'),
			_Utils_Tuple2('circledcirc', '⊚'),
			_Utils_Tuple2('circleddash', '⊝'),
			_Utils_Tuple2('CircleDot', '⊙'),
			_Utils_Tuple2('circledR', '®'),
			_Utils_Tuple2('circledS', 'Ⓢ'),
			_Utils_Tuple2('CircleMinus', '⊖'),
			_Utils_Tuple2('CirclePlus', '⊕'),
			_Utils_Tuple2('CircleTimes', '⊗'),
			_Utils_Tuple2('cir', '○'),
			_Utils_Tuple2('cirE', '⧃'),
			_Utils_Tuple2('cire', '≗'),
			_Utils_Tuple2('cirfnint', '⨐'),
			_Utils_Tuple2('cirmid', '⫯'),
			_Utils_Tuple2('cirscir', '⧂'),
			_Utils_Tuple2('ClockwiseContourIntegral', '∲'),
			_Utils_Tuple2('CloseCurlyDoubleQuote', '”'),
			_Utils_Tuple2('CloseCurlyQuote', '’'),
			_Utils_Tuple2('clubs', '♣'),
			_Utils_Tuple2('clubsuit', '♣'),
			_Utils_Tuple2('colon', ':'),
			_Utils_Tuple2('Colon', '∷'),
			_Utils_Tuple2('Colone', '⩴'),
			_Utils_Tuple2('colone', '≔'),
			_Utils_Tuple2('coloneq', '≔'),
			_Utils_Tuple2('comma', ','),
			_Utils_Tuple2('commat', '@'),
			_Utils_Tuple2('comp', '∁'),
			_Utils_Tuple2('compfn', '∘'),
			_Utils_Tuple2('complement', '∁'),
			_Utils_Tuple2('complexes', 'ℂ'),
			_Utils_Tuple2('cong', '≅'),
			_Utils_Tuple2('congdot', '⩭'),
			_Utils_Tuple2('Congruent', '≡'),
			_Utils_Tuple2('conint', '∮'),
			_Utils_Tuple2('Conint', '∯'),
			_Utils_Tuple2('ContourIntegral', '∮'),
			_Utils_Tuple2('copf', '\uD835\uDD54'),
			_Utils_Tuple2('Copf', 'ℂ'),
			_Utils_Tuple2('coprod', '∐'),
			_Utils_Tuple2('Coproduct', '∐'),
			_Utils_Tuple2('copy', '©'),
			_Utils_Tuple2('COPY', '©'),
			_Utils_Tuple2('copysr', '℗'),
			_Utils_Tuple2('CounterClockwiseContourIntegral', '∳'),
			_Utils_Tuple2('crarr', '↵'),
			_Utils_Tuple2('cross', '✗'),
			_Utils_Tuple2('Cross', '⨯'),
			_Utils_Tuple2('Cscr', '\uD835\uDC9E'),
			_Utils_Tuple2('cscr', '\uD835\uDCB8'),
			_Utils_Tuple2('csub', '⫏'),
			_Utils_Tuple2('csube', '⫑'),
			_Utils_Tuple2('csup', '⫐'),
			_Utils_Tuple2('csupe', '⫒'),
			_Utils_Tuple2('ctdot', '⋯'),
			_Utils_Tuple2('cudarrl', '⤸'),
			_Utils_Tuple2('cudarrr', '⤵'),
			_Utils_Tuple2('cuepr', '⋞'),
			_Utils_Tuple2('cuesc', '⋟'),
			_Utils_Tuple2('cularr', '↶'),
			_Utils_Tuple2('cularrp', '⤽'),
			_Utils_Tuple2('cupbrcap', '⩈'),
			_Utils_Tuple2('cupcap', '⩆'),
			_Utils_Tuple2('CupCap', '≍'),
			_Utils_Tuple2('cup', '∪'),
			_Utils_Tuple2('Cup', '⋓'),
			_Utils_Tuple2('cupcup', '⩊'),
			_Utils_Tuple2('cupdot', '⊍'),
			_Utils_Tuple2('cupor', '⩅'),
			_Utils_Tuple2('cups', '∪︀'),
			_Utils_Tuple2('curarr', '↷'),
			_Utils_Tuple2('curarrm', '⤼'),
			_Utils_Tuple2('curlyeqprec', '⋞'),
			_Utils_Tuple2('curlyeqsucc', '⋟'),
			_Utils_Tuple2('curlyvee', '⋎'),
			_Utils_Tuple2('curlywedge', '⋏'),
			_Utils_Tuple2('curren', '¤'),
			_Utils_Tuple2('curvearrowleft', '↶'),
			_Utils_Tuple2('curvearrowright', '↷'),
			_Utils_Tuple2('cuvee', '⋎'),
			_Utils_Tuple2('cuwed', '⋏'),
			_Utils_Tuple2('cwconint', '∲'),
			_Utils_Tuple2('cwint', '∱'),
			_Utils_Tuple2('cylcty', '⌭'),
			_Utils_Tuple2('dagger', '†'),
			_Utils_Tuple2('Dagger', '‡'),
			_Utils_Tuple2('daleth', 'ℸ'),
			_Utils_Tuple2('darr', '↓'),
			_Utils_Tuple2('Darr', '↡'),
			_Utils_Tuple2('dArr', '⇓'),
			_Utils_Tuple2('dash', '‐'),
			_Utils_Tuple2('Dashv', '⫤'),
			_Utils_Tuple2('dashv', '⊣'),
			_Utils_Tuple2('dbkarow', '⤏'),
			_Utils_Tuple2('dblac', '˝'),
			_Utils_Tuple2('Dcaron', 'Ď'),
			_Utils_Tuple2('dcaron', 'ď'),
			_Utils_Tuple2('Dcy', 'Д'),
			_Utils_Tuple2('dcy', 'д'),
			_Utils_Tuple2('ddagger', '‡'),
			_Utils_Tuple2('ddarr', '⇊'),
			_Utils_Tuple2('DD', 'ⅅ'),
			_Utils_Tuple2('dd', 'ⅆ'),
			_Utils_Tuple2('DDotrahd', '⤑'),
			_Utils_Tuple2('ddotseq', '⩷'),
			_Utils_Tuple2('deg', '°'),
			_Utils_Tuple2('Del', '∇'),
			_Utils_Tuple2('Delta', 'Δ'),
			_Utils_Tuple2('delta', 'δ'),
			_Utils_Tuple2('demptyv', '⦱'),
			_Utils_Tuple2('dfisht', '⥿'),
			_Utils_Tuple2('Dfr', '\uD835\uDD07'),
			_Utils_Tuple2('dfr', '\uD835\uDD21'),
			_Utils_Tuple2('dHar', '⥥'),
			_Utils_Tuple2('dharl', '⇃'),
			_Utils_Tuple2('dharr', '⇂'),
			_Utils_Tuple2('DiacriticalAcute', '´'),
			_Utils_Tuple2('DiacriticalDot', '˙'),
			_Utils_Tuple2('DiacriticalDoubleAcute', '˝'),
			_Utils_Tuple2('DiacriticalGrave', '`'),
			_Utils_Tuple2('DiacriticalTilde', '˜'),
			_Utils_Tuple2('diam', '⋄'),
			_Utils_Tuple2('diamond', '⋄'),
			_Utils_Tuple2('Diamond', '⋄'),
			_Utils_Tuple2('diamondsuit', '♦'),
			_Utils_Tuple2('diams', '♦'),
			_Utils_Tuple2('die', '¨'),
			_Utils_Tuple2('DifferentialD', 'ⅆ'),
			_Utils_Tuple2('digamma', 'ϝ'),
			_Utils_Tuple2('disin', '⋲'),
			_Utils_Tuple2('div', '÷'),
			_Utils_Tuple2('divide', '÷'),
			_Utils_Tuple2('divideontimes', '⋇'),
			_Utils_Tuple2('divonx', '⋇'),
			_Utils_Tuple2('DJcy', 'Ђ'),
			_Utils_Tuple2('djcy', 'ђ'),
			_Utils_Tuple2('dlcorn', '⌞'),
			_Utils_Tuple2('dlcrop', '⌍'),
			_Utils_Tuple2('dollar', '$'),
			_Utils_Tuple2('Dopf', '\uD835\uDD3B'),
			_Utils_Tuple2('dopf', '\uD835\uDD55'),
			_Utils_Tuple2('Dot', '¨'),
			_Utils_Tuple2('dot', '˙'),
			_Utils_Tuple2('DotDot', '⃜'),
			_Utils_Tuple2('doteq', '≐'),
			_Utils_Tuple2('doteqdot', '≑'),
			_Utils_Tuple2('DotEqual', '≐'),
			_Utils_Tuple2('dotminus', '∸'),
			_Utils_Tuple2('dotplus', '∔'),
			_Utils_Tuple2('dotsquare', '⊡'),
			_Utils_Tuple2('doublebarwedge', '⌆'),
			_Utils_Tuple2('DoubleContourIntegral', '∯'),
			_Utils_Tuple2('DoubleDot', '¨'),
			_Utils_Tuple2('DoubleDownArrow', '⇓'),
			_Utils_Tuple2('DoubleLeftArrow', '⇐'),
			_Utils_Tuple2('DoubleLeftRightArrow', '⇔'),
			_Utils_Tuple2('DoubleLeftTee', '⫤'),
			_Utils_Tuple2('DoubleLongLeftArrow', '⟸'),
			_Utils_Tuple2('DoubleLongLeftRightArrow', '⟺'),
			_Utils_Tuple2('DoubleLongRightArrow', '⟹'),
			_Utils_Tuple2('DoubleRightArrow', '⇒'),
			_Utils_Tuple2('DoubleRightTee', '⊨'),
			_Utils_Tuple2('DoubleUpArrow', '⇑'),
			_Utils_Tuple2('DoubleUpDownArrow', '⇕'),
			_Utils_Tuple2('DoubleVerticalBar', '∥'),
			_Utils_Tuple2('DownArrowBar', '⤓'),
			_Utils_Tuple2('downarrow', '↓'),
			_Utils_Tuple2('DownArrow', '↓'),
			_Utils_Tuple2('Downarrow', '⇓'),
			_Utils_Tuple2('DownArrowUpArrow', '⇵'),
			_Utils_Tuple2('DownBreve', '̑'),
			_Utils_Tuple2('downdownarrows', '⇊'),
			_Utils_Tuple2('downharpoonleft', '⇃'),
			_Utils_Tuple2('downharpoonright', '⇂'),
			_Utils_Tuple2('DownLeftRightVector', '⥐'),
			_Utils_Tuple2('DownLeftTeeVector', '⥞'),
			_Utils_Tuple2('DownLeftVectorBar', '⥖'),
			_Utils_Tuple2('DownLeftVector', '↽'),
			_Utils_Tuple2('DownRightTeeVector', '⥟'),
			_Utils_Tuple2('DownRightVectorBar', '⥗'),
			_Utils_Tuple2('DownRightVector', '⇁'),
			_Utils_Tuple2('DownTeeArrow', '↧'),
			_Utils_Tuple2('DownTee', '⊤'),
			_Utils_Tuple2('drbkarow', '⤐'),
			_Utils_Tuple2('drcorn', '⌟'),
			_Utils_Tuple2('drcrop', '⌌'),
			_Utils_Tuple2('Dscr', '\uD835\uDC9F'),
			_Utils_Tuple2('dscr', '\uD835\uDCB9'),
			_Utils_Tuple2('DScy', 'Ѕ'),
			_Utils_Tuple2('dscy', 'ѕ'),
			_Utils_Tuple2('dsol', '⧶'),
			_Utils_Tuple2('Dstrok', 'Đ'),
			_Utils_Tuple2('dstrok', 'đ'),
			_Utils_Tuple2('dtdot', '⋱'),
			_Utils_Tuple2('dtri', '▿'),
			_Utils_Tuple2('dtrif', '▾'),
			_Utils_Tuple2('duarr', '⇵'),
			_Utils_Tuple2('duhar', '⥯'),
			_Utils_Tuple2('dwangle', '⦦'),
			_Utils_Tuple2('DZcy', 'Џ'),
			_Utils_Tuple2('dzcy', 'џ'),
			_Utils_Tuple2('dzigrarr', '⟿'),
			_Utils_Tuple2('Eacute', 'É'),
			_Utils_Tuple2('eacute', 'é'),
			_Utils_Tuple2('easter', '⩮'),
			_Utils_Tuple2('Ecaron', 'Ě'),
			_Utils_Tuple2('ecaron', 'ě'),
			_Utils_Tuple2('Ecirc', 'Ê'),
			_Utils_Tuple2('ecirc', 'ê'),
			_Utils_Tuple2('ecir', '≖'),
			_Utils_Tuple2('ecolon', '≕'),
			_Utils_Tuple2('Ecy', 'Э'),
			_Utils_Tuple2('ecy', 'э'),
			_Utils_Tuple2('eDDot', '⩷'),
			_Utils_Tuple2('Edot', 'Ė'),
			_Utils_Tuple2('edot', 'ė'),
			_Utils_Tuple2('eDot', '≑'),
			_Utils_Tuple2('ee', 'ⅇ'),
			_Utils_Tuple2('efDot', '≒'),
			_Utils_Tuple2('Efr', '\uD835\uDD08'),
			_Utils_Tuple2('efr', '\uD835\uDD22'),
			_Utils_Tuple2('eg', '⪚'),
			_Utils_Tuple2('Egrave', 'È'),
			_Utils_Tuple2('egrave', 'è'),
			_Utils_Tuple2('egs', '⪖'),
			_Utils_Tuple2('egsdot', '⪘'),
			_Utils_Tuple2('el', '⪙'),
			_Utils_Tuple2('Element', '∈'),
			_Utils_Tuple2('elinters', '⏧'),
			_Utils_Tuple2('ell', 'ℓ'),
			_Utils_Tuple2('els', '⪕'),
			_Utils_Tuple2('elsdot', '⪗'),
			_Utils_Tuple2('Emacr', 'Ē'),
			_Utils_Tuple2('emacr', 'ē'),
			_Utils_Tuple2('empty', '∅'),
			_Utils_Tuple2('emptyset', '∅'),
			_Utils_Tuple2('EmptySmallSquare', '◻'),
			_Utils_Tuple2('emptyv', '∅'),
			_Utils_Tuple2('EmptyVerySmallSquare', '▫'),
			_Utils_Tuple2('emsp13', '\u2004'),
			_Utils_Tuple2('emsp14', '\u2005'),
			_Utils_Tuple2('emsp', '\u2003'),
			_Utils_Tuple2('ENG', 'Ŋ'),
			_Utils_Tuple2('eng', 'ŋ'),
			_Utils_Tuple2('ensp', '\u2002'),
			_Utils_Tuple2('Eogon', 'Ę'),
			_Utils_Tuple2('eogon', 'ę'),
			_Utils_Tuple2('Eopf', '\uD835\uDD3C'),
			_Utils_Tuple2('eopf', '\uD835\uDD56'),
			_Utils_Tuple2('epar', '⋕'),
			_Utils_Tuple2('eparsl', '⧣'),
			_Utils_Tuple2('eplus', '⩱'),
			_Utils_Tuple2('epsi', 'ε'),
			_Utils_Tuple2('Epsilon', 'Ε'),
			_Utils_Tuple2('epsilon', 'ε'),
			_Utils_Tuple2('epsiv', 'ϵ'),
			_Utils_Tuple2('eqcirc', '≖'),
			_Utils_Tuple2('eqcolon', '≕'),
			_Utils_Tuple2('eqsim', '≂'),
			_Utils_Tuple2('eqslantgtr', '⪖'),
			_Utils_Tuple2('eqslantless', '⪕'),
			_Utils_Tuple2('Equal', '⩵'),
			_Utils_Tuple2('equals', '='),
			_Utils_Tuple2('EqualTilde', '≂'),
			_Utils_Tuple2('equest', '≟'),
			_Utils_Tuple2('Equilibrium', '⇌'),
			_Utils_Tuple2('equiv', '≡'),
			_Utils_Tuple2('equivDD', '⩸'),
			_Utils_Tuple2('eqvparsl', '⧥'),
			_Utils_Tuple2('erarr', '⥱'),
			_Utils_Tuple2('erDot', '≓'),
			_Utils_Tuple2('escr', 'ℯ'),
			_Utils_Tuple2('Escr', 'ℰ'),
			_Utils_Tuple2('esdot', '≐'),
			_Utils_Tuple2('Esim', '⩳'),
			_Utils_Tuple2('esim', '≂'),
			_Utils_Tuple2('Eta', 'Η'),
			_Utils_Tuple2('eta', 'η'),
			_Utils_Tuple2('ETH', 'Ð'),
			_Utils_Tuple2('eth', 'ð'),
			_Utils_Tuple2('Euml', 'Ë'),
			_Utils_Tuple2('euml', 'ë'),
			_Utils_Tuple2('euro', '€'),
			_Utils_Tuple2('excl', '!'),
			_Utils_Tuple2('exist', '∃'),
			_Utils_Tuple2('Exists', '∃'),
			_Utils_Tuple2('expectation', 'ℰ'),
			_Utils_Tuple2('exponentiale', 'ⅇ'),
			_Utils_Tuple2('ExponentialE', 'ⅇ'),
			_Utils_Tuple2('fallingdotseq', '≒'),
			_Utils_Tuple2('Fcy', 'Ф'),
			_Utils_Tuple2('fcy', 'ф'),
			_Utils_Tuple2('female', '♀'),
			_Utils_Tuple2('ffilig', 'ﬃ'),
			_Utils_Tuple2('fflig', 'ﬀ'),
			_Utils_Tuple2('ffllig', 'ﬄ'),
			_Utils_Tuple2('Ffr', '\uD835\uDD09'),
			_Utils_Tuple2('ffr', '\uD835\uDD23'),
			_Utils_Tuple2('filig', 'ﬁ'),
			_Utils_Tuple2('FilledSmallSquare', '◼'),
			_Utils_Tuple2('FilledVerySmallSquare', '▪'),
			_Utils_Tuple2('fjlig', 'fj'),
			_Utils_Tuple2('flat', '♭'),
			_Utils_Tuple2('fllig', 'ﬂ'),
			_Utils_Tuple2('fltns', '▱'),
			_Utils_Tuple2('fnof', 'ƒ'),
			_Utils_Tuple2('Fopf', '\uD835\uDD3D'),
			_Utils_Tuple2('fopf', '\uD835\uDD57'),
			_Utils_Tuple2('forall', '∀'),
			_Utils_Tuple2('ForAll', '∀'),
			_Utils_Tuple2('fork', '⋔'),
			_Utils_Tuple2('forkv', '⫙'),
			_Utils_Tuple2('Fouriertrf', 'ℱ'),
			_Utils_Tuple2('fpartint', '⨍'),
			_Utils_Tuple2('frac12', '½'),
			_Utils_Tuple2('frac13', '⅓'),
			_Utils_Tuple2('frac14', '¼'),
			_Utils_Tuple2('frac15', '⅕'),
			_Utils_Tuple2('frac16', '⅙'),
			_Utils_Tuple2('frac18', '⅛'),
			_Utils_Tuple2('frac23', '⅔'),
			_Utils_Tuple2('frac25', '⅖'),
			_Utils_Tuple2('frac34', '¾'),
			_Utils_Tuple2('frac35', '⅗'),
			_Utils_Tuple2('frac38', '⅜'),
			_Utils_Tuple2('frac45', '⅘'),
			_Utils_Tuple2('frac56', '⅚'),
			_Utils_Tuple2('frac58', '⅝'),
			_Utils_Tuple2('frac78', '⅞'),
			_Utils_Tuple2('frasl', '⁄'),
			_Utils_Tuple2('frown', '⌢'),
			_Utils_Tuple2('fscr', '\uD835\uDCBB'),
			_Utils_Tuple2('Fscr', 'ℱ'),
			_Utils_Tuple2('gacute', 'ǵ'),
			_Utils_Tuple2('Gamma', 'Γ'),
			_Utils_Tuple2('gamma', 'γ'),
			_Utils_Tuple2('Gammad', 'Ϝ'),
			_Utils_Tuple2('gammad', 'ϝ'),
			_Utils_Tuple2('gap', '⪆'),
			_Utils_Tuple2('Gbreve', 'Ğ'),
			_Utils_Tuple2('gbreve', 'ğ'),
			_Utils_Tuple2('Gcedil', 'Ģ'),
			_Utils_Tuple2('Gcirc', 'Ĝ'),
			_Utils_Tuple2('gcirc', 'ĝ'),
			_Utils_Tuple2('Gcy', 'Г'),
			_Utils_Tuple2('gcy', 'г'),
			_Utils_Tuple2('Gdot', 'Ġ'),
			_Utils_Tuple2('gdot', 'ġ'),
			_Utils_Tuple2('ge', '≥'),
			_Utils_Tuple2('gE', '≧'),
			_Utils_Tuple2('gEl', '⪌'),
			_Utils_Tuple2('gel', '⋛'),
			_Utils_Tuple2('geq', '≥'),
			_Utils_Tuple2('geqq', '≧'),
			_Utils_Tuple2('geqslant', '⩾'),
			_Utils_Tuple2('gescc', '⪩'),
			_Utils_Tuple2('ges', '⩾'),
			_Utils_Tuple2('gesdot', '⪀'),
			_Utils_Tuple2('gesdoto', '⪂'),
			_Utils_Tuple2('gesdotol', '⪄'),
			_Utils_Tuple2('gesl', '⋛︀'),
			_Utils_Tuple2('gesles', '⪔'),
			_Utils_Tuple2('Gfr', '\uD835\uDD0A'),
			_Utils_Tuple2('gfr', '\uD835\uDD24'),
			_Utils_Tuple2('gg', '≫'),
			_Utils_Tuple2('Gg', '⋙'),
			_Utils_Tuple2('ggg', '⋙'),
			_Utils_Tuple2('gimel', 'ℷ'),
			_Utils_Tuple2('GJcy', 'Ѓ'),
			_Utils_Tuple2('gjcy', 'ѓ'),
			_Utils_Tuple2('gla', '⪥'),
			_Utils_Tuple2('gl', '≷'),
			_Utils_Tuple2('glE', '⪒'),
			_Utils_Tuple2('glj', '⪤'),
			_Utils_Tuple2('gnap', '⪊'),
			_Utils_Tuple2('gnapprox', '⪊'),
			_Utils_Tuple2('gne', '⪈'),
			_Utils_Tuple2('gnE', '≩'),
			_Utils_Tuple2('gneq', '⪈'),
			_Utils_Tuple2('gneqq', '≩'),
			_Utils_Tuple2('gnsim', '⋧'),
			_Utils_Tuple2('Gopf', '\uD835\uDD3E'),
			_Utils_Tuple2('gopf', '\uD835\uDD58'),
			_Utils_Tuple2('grave', '`'),
			_Utils_Tuple2('GreaterEqual', '≥'),
			_Utils_Tuple2('GreaterEqualLess', '⋛'),
			_Utils_Tuple2('GreaterFullEqual', '≧'),
			_Utils_Tuple2('GreaterGreater', '⪢'),
			_Utils_Tuple2('GreaterLess', '≷'),
			_Utils_Tuple2('GreaterSlantEqual', '⩾'),
			_Utils_Tuple2('GreaterTilde', '≳'),
			_Utils_Tuple2('Gscr', '\uD835\uDCA2'),
			_Utils_Tuple2('gscr', 'ℊ'),
			_Utils_Tuple2('gsim', '≳'),
			_Utils_Tuple2('gsime', '⪎'),
			_Utils_Tuple2('gsiml', '⪐'),
			_Utils_Tuple2('gtcc', '⪧'),
			_Utils_Tuple2('gtcir', '⩺'),
			_Utils_Tuple2('gt', '>'),
			_Utils_Tuple2('GT', '>'),
			_Utils_Tuple2('Gt', '≫'),
			_Utils_Tuple2('gtdot', '⋗'),
			_Utils_Tuple2('gtlPar', '⦕'),
			_Utils_Tuple2('gtquest', '⩼'),
			_Utils_Tuple2('gtrapprox', '⪆'),
			_Utils_Tuple2('gtrarr', '⥸'),
			_Utils_Tuple2('gtrdot', '⋗'),
			_Utils_Tuple2('gtreqless', '⋛'),
			_Utils_Tuple2('gtreqqless', '⪌'),
			_Utils_Tuple2('gtrless', '≷'),
			_Utils_Tuple2('gtrsim', '≳'),
			_Utils_Tuple2('gvertneqq', '≩︀'),
			_Utils_Tuple2('gvnE', '≩︀'),
			_Utils_Tuple2('Hacek', 'ˇ'),
			_Utils_Tuple2('hairsp', '\u200A'),
			_Utils_Tuple2('half', '½'),
			_Utils_Tuple2('hamilt', 'ℋ'),
			_Utils_Tuple2('HARDcy', 'Ъ'),
			_Utils_Tuple2('hardcy', 'ъ'),
			_Utils_Tuple2('harrcir', '⥈'),
			_Utils_Tuple2('harr', '↔'),
			_Utils_Tuple2('hArr', '⇔'),
			_Utils_Tuple2('harrw', '↭'),
			_Utils_Tuple2('Hat', '^'),
			_Utils_Tuple2('hbar', 'ℏ'),
			_Utils_Tuple2('Hcirc', 'Ĥ'),
			_Utils_Tuple2('hcirc', 'ĥ'),
			_Utils_Tuple2('hearts', '♥'),
			_Utils_Tuple2('heartsuit', '♥'),
			_Utils_Tuple2('hellip', '…'),
			_Utils_Tuple2('hercon', '⊹'),
			_Utils_Tuple2('hfr', '\uD835\uDD25'),
			_Utils_Tuple2('Hfr', 'ℌ'),
			_Utils_Tuple2('HilbertSpace', 'ℋ'),
			_Utils_Tuple2('hksearow', '⤥'),
			_Utils_Tuple2('hkswarow', '⤦'),
			_Utils_Tuple2('hoarr', '⇿'),
			_Utils_Tuple2('homtht', '∻'),
			_Utils_Tuple2('hookleftarrow', '↩'),
			_Utils_Tuple2('hookrightarrow', '↪'),
			_Utils_Tuple2('hopf', '\uD835\uDD59'),
			_Utils_Tuple2('Hopf', 'ℍ'),
			_Utils_Tuple2('horbar', '―'),
			_Utils_Tuple2('HorizontalLine', '─'),
			_Utils_Tuple2('hscr', '\uD835\uDCBD'),
			_Utils_Tuple2('Hscr', 'ℋ'),
			_Utils_Tuple2('hslash', 'ℏ'),
			_Utils_Tuple2('Hstrok', 'Ħ'),
			_Utils_Tuple2('hstrok', 'ħ'),
			_Utils_Tuple2('HumpDownHump', '≎'),
			_Utils_Tuple2('HumpEqual', '≏'),
			_Utils_Tuple2('hybull', '⁃'),
			_Utils_Tuple2('hyphen', '‐'),
			_Utils_Tuple2('Iacute', 'Í'),
			_Utils_Tuple2('iacute', 'í'),
			_Utils_Tuple2('ic', '\u2063'),
			_Utils_Tuple2('Icirc', 'Î'),
			_Utils_Tuple2('icirc', 'î'),
			_Utils_Tuple2('Icy', 'И'),
			_Utils_Tuple2('icy', 'и'),
			_Utils_Tuple2('Idot', 'İ'),
			_Utils_Tuple2('IEcy', 'Е'),
			_Utils_Tuple2('iecy', 'е'),
			_Utils_Tuple2('iexcl', '¡'),
			_Utils_Tuple2('iff', '⇔'),
			_Utils_Tuple2('ifr', '\uD835\uDD26'),
			_Utils_Tuple2('Ifr', 'ℑ'),
			_Utils_Tuple2('Igrave', 'Ì'),
			_Utils_Tuple2('igrave', 'ì'),
			_Utils_Tuple2('ii', 'ⅈ'),
			_Utils_Tuple2('iiiint', '⨌'),
			_Utils_Tuple2('iiint', '∭'),
			_Utils_Tuple2('iinfin', '⧜'),
			_Utils_Tuple2('iiota', '℩'),
			_Utils_Tuple2('IJlig', 'Ĳ'),
			_Utils_Tuple2('ijlig', 'ĳ'),
			_Utils_Tuple2('Imacr', 'Ī'),
			_Utils_Tuple2('imacr', 'ī'),
			_Utils_Tuple2('image', 'ℑ'),
			_Utils_Tuple2('ImaginaryI', 'ⅈ'),
			_Utils_Tuple2('imagline', 'ℐ'),
			_Utils_Tuple2('imagpart', 'ℑ'),
			_Utils_Tuple2('imath', 'ı'),
			_Utils_Tuple2('Im', 'ℑ'),
			_Utils_Tuple2('imof', '⊷'),
			_Utils_Tuple2('imped', 'Ƶ'),
			_Utils_Tuple2('Implies', '⇒'),
			_Utils_Tuple2('incare', '℅'),
			_Utils_Tuple2('in', '∈'),
			_Utils_Tuple2('infin', '∞'),
			_Utils_Tuple2('infintie', '⧝'),
			_Utils_Tuple2('inodot', 'ı'),
			_Utils_Tuple2('intcal', '⊺'),
			_Utils_Tuple2('int', '∫'),
			_Utils_Tuple2('Int', '∬'),
			_Utils_Tuple2('integers', 'ℤ'),
			_Utils_Tuple2('Integral', '∫'),
			_Utils_Tuple2('intercal', '⊺'),
			_Utils_Tuple2('Intersection', '⋂'),
			_Utils_Tuple2('intlarhk', '⨗'),
			_Utils_Tuple2('intprod', '⨼'),
			_Utils_Tuple2('InvisibleComma', '\u2063'),
			_Utils_Tuple2('InvisibleTimes', '\u2062'),
			_Utils_Tuple2('IOcy', 'Ё'),
			_Utils_Tuple2('iocy', 'ё'),
			_Utils_Tuple2('Iogon', 'Į'),
			_Utils_Tuple2('iogon', 'į'),
			_Utils_Tuple2('Iopf', '\uD835\uDD40'),
			_Utils_Tuple2('iopf', '\uD835\uDD5A'),
			_Utils_Tuple2('Iota', 'Ι'),
			_Utils_Tuple2('iota', 'ι'),
			_Utils_Tuple2('iprod', '⨼'),
			_Utils_Tuple2('iquest', '¿'),
			_Utils_Tuple2('iscr', '\uD835\uDCBE'),
			_Utils_Tuple2('Iscr', 'ℐ'),
			_Utils_Tuple2('isin', '∈'),
			_Utils_Tuple2('isindot', '⋵'),
			_Utils_Tuple2('isinE', '⋹'),
			_Utils_Tuple2('isins', '⋴'),
			_Utils_Tuple2('isinsv', '⋳'),
			_Utils_Tuple2('isinv', '∈'),
			_Utils_Tuple2('it', '\u2062'),
			_Utils_Tuple2('Itilde', 'Ĩ'),
			_Utils_Tuple2('itilde', 'ĩ'),
			_Utils_Tuple2('Iukcy', 'І'),
			_Utils_Tuple2('iukcy', 'і'),
			_Utils_Tuple2('Iuml', 'Ï'),
			_Utils_Tuple2('iuml', 'ï'),
			_Utils_Tuple2('Jcirc', 'Ĵ'),
			_Utils_Tuple2('jcirc', 'ĵ'),
			_Utils_Tuple2('Jcy', 'Й'),
			_Utils_Tuple2('jcy', 'й'),
			_Utils_Tuple2('Jfr', '\uD835\uDD0D'),
			_Utils_Tuple2('jfr', '\uD835\uDD27'),
			_Utils_Tuple2('jmath', 'ȷ'),
			_Utils_Tuple2('Jopf', '\uD835\uDD41'),
			_Utils_Tuple2('jopf', '\uD835\uDD5B'),
			_Utils_Tuple2('Jscr', '\uD835\uDCA5'),
			_Utils_Tuple2('jscr', '\uD835\uDCBF'),
			_Utils_Tuple2('Jsercy', 'Ј'),
			_Utils_Tuple2('jsercy', 'ј'),
			_Utils_Tuple2('Jukcy', 'Є'),
			_Utils_Tuple2('jukcy', 'є'),
			_Utils_Tuple2('Kappa', 'Κ'),
			_Utils_Tuple2('kappa', 'κ'),
			_Utils_Tuple2('kappav', 'ϰ'),
			_Utils_Tuple2('Kcedil', 'Ķ'),
			_Utils_Tuple2('kcedil', 'ķ'),
			_Utils_Tuple2('Kcy', 'К'),
			_Utils_Tuple2('kcy', 'к'),
			_Utils_Tuple2('Kfr', '\uD835\uDD0E'),
			_Utils_Tuple2('kfr', '\uD835\uDD28'),
			_Utils_Tuple2('kgreen', 'ĸ'),
			_Utils_Tuple2('KHcy', 'Х'),
			_Utils_Tuple2('khcy', 'х'),
			_Utils_Tuple2('KJcy', 'Ќ'),
			_Utils_Tuple2('kjcy', 'ќ'),
			_Utils_Tuple2('Kopf', '\uD835\uDD42'),
			_Utils_Tuple2('kopf', '\uD835\uDD5C'),
			_Utils_Tuple2('Kscr', '\uD835\uDCA6'),
			_Utils_Tuple2('kscr', '\uD835\uDCC0'),
			_Utils_Tuple2('lAarr', '⇚'),
			_Utils_Tuple2('Lacute', 'Ĺ'),
			_Utils_Tuple2('lacute', 'ĺ'),
			_Utils_Tuple2('laemptyv', '⦴'),
			_Utils_Tuple2('lagran', 'ℒ'),
			_Utils_Tuple2('Lambda', 'Λ'),
			_Utils_Tuple2('lambda', 'λ'),
			_Utils_Tuple2('lang', '⟨'),
			_Utils_Tuple2('Lang', '⟪'),
			_Utils_Tuple2('langd', '⦑'),
			_Utils_Tuple2('langle', '⟨'),
			_Utils_Tuple2('lap', '⪅'),
			_Utils_Tuple2('Laplacetrf', 'ℒ'),
			_Utils_Tuple2('laquo', '«'),
			_Utils_Tuple2('larrb', '⇤'),
			_Utils_Tuple2('larrbfs', '⤟'),
			_Utils_Tuple2('larr', '←'),
			_Utils_Tuple2('Larr', '↞'),
			_Utils_Tuple2('lArr', '⇐'),
			_Utils_Tuple2('larrfs', '⤝'),
			_Utils_Tuple2('larrhk', '↩'),
			_Utils_Tuple2('larrlp', '↫'),
			_Utils_Tuple2('larrpl', '⤹'),
			_Utils_Tuple2('larrsim', '⥳'),
			_Utils_Tuple2('larrtl', '↢'),
			_Utils_Tuple2('latail', '⤙'),
			_Utils_Tuple2('lAtail', '⤛'),
			_Utils_Tuple2('lat', '⪫'),
			_Utils_Tuple2('late', '⪭'),
			_Utils_Tuple2('lates', '⪭︀'),
			_Utils_Tuple2('lbarr', '⤌'),
			_Utils_Tuple2('lBarr', '⤎'),
			_Utils_Tuple2('lbbrk', '❲'),
			_Utils_Tuple2('lbrace', '{'),
			_Utils_Tuple2('lbrack', '['),
			_Utils_Tuple2('lbrke', '⦋'),
			_Utils_Tuple2('lbrksld', '⦏'),
			_Utils_Tuple2('lbrkslu', '⦍'),
			_Utils_Tuple2('Lcaron', 'Ľ'),
			_Utils_Tuple2('lcaron', 'ľ'),
			_Utils_Tuple2('Lcedil', 'Ļ'),
			_Utils_Tuple2('lcedil', 'ļ'),
			_Utils_Tuple2('lceil', '⌈'),
			_Utils_Tuple2('lcub', '{'),
			_Utils_Tuple2('Lcy', 'Л'),
			_Utils_Tuple2('lcy', 'л'),
			_Utils_Tuple2('ldca', '⤶'),
			_Utils_Tuple2('ldquo', '“'),
			_Utils_Tuple2('ldquor', '„'),
			_Utils_Tuple2('ldrdhar', '⥧'),
			_Utils_Tuple2('ldrushar', '⥋'),
			_Utils_Tuple2('ldsh', '↲'),
			_Utils_Tuple2('le', '≤'),
			_Utils_Tuple2('lE', '≦'),
			_Utils_Tuple2('LeftAngleBracket', '⟨'),
			_Utils_Tuple2('LeftArrowBar', '⇤'),
			_Utils_Tuple2('leftarrow', '←'),
			_Utils_Tuple2('LeftArrow', '←'),
			_Utils_Tuple2('Leftarrow', '⇐'),
			_Utils_Tuple2('LeftArrowRightArrow', '⇆'),
			_Utils_Tuple2('leftarrowtail', '↢'),
			_Utils_Tuple2('LeftCeiling', '⌈'),
			_Utils_Tuple2('LeftDoubleBracket', '⟦'),
			_Utils_Tuple2('LeftDownTeeVector', '⥡'),
			_Utils_Tuple2('LeftDownVectorBar', '⥙'),
			_Utils_Tuple2('LeftDownVector', '⇃'),
			_Utils_Tuple2('LeftFloor', '⌊'),
			_Utils_Tuple2('leftharpoondown', '↽'),
			_Utils_Tuple2('leftharpoonup', '↼'),
			_Utils_Tuple2('leftleftarrows', '⇇'),
			_Utils_Tuple2('leftrightarrow', '↔'),
			_Utils_Tuple2('LeftRightArrow', '↔'),
			_Utils_Tuple2('Leftrightarrow', '⇔'),
			_Utils_Tuple2('leftrightarrows', '⇆'),
			_Utils_Tuple2('leftrightharpoons', '⇋'),
			_Utils_Tuple2('leftrightsquigarrow', '↭'),
			_Utils_Tuple2('LeftRightVector', '⥎'),
			_Utils_Tuple2('LeftTeeArrow', '↤'),
			_Utils_Tuple2('LeftTee', '⊣'),
			_Utils_Tuple2('LeftTeeVector', '⥚'),
			_Utils_Tuple2('leftthreetimes', '⋋'),
			_Utils_Tuple2('LeftTriangleBar', '⧏'),
			_Utils_Tuple2('LeftTriangle', '⊲'),
			_Utils_Tuple2('LeftTriangleEqual', '⊴'),
			_Utils_Tuple2('LeftUpDownVector', '⥑'),
			_Utils_Tuple2('LeftUpTeeVector', '⥠'),
			_Utils_Tuple2('LeftUpVectorBar', '⥘'),
			_Utils_Tuple2('LeftUpVector', '↿'),
			_Utils_Tuple2('LeftVectorBar', '⥒'),
			_Utils_Tuple2('LeftVector', '↼'),
			_Utils_Tuple2('lEg', '⪋'),
			_Utils_Tuple2('leg', '⋚'),
			_Utils_Tuple2('leq', '≤'),
			_Utils_Tuple2('leqq', '≦'),
			_Utils_Tuple2('leqslant', '⩽'),
			_Utils_Tuple2('lescc', '⪨'),
			_Utils_Tuple2('les', '⩽'),
			_Utils_Tuple2('lesdot', '⩿'),
			_Utils_Tuple2('lesdoto', '⪁'),
			_Utils_Tuple2('lesdotor', '⪃'),
			_Utils_Tuple2('lesg', '⋚︀'),
			_Utils_Tuple2('lesges', '⪓'),
			_Utils_Tuple2('lessapprox', '⪅'),
			_Utils_Tuple2('lessdot', '⋖'),
			_Utils_Tuple2('lesseqgtr', '⋚'),
			_Utils_Tuple2('lesseqqgtr', '⪋'),
			_Utils_Tuple2('LessEqualGreater', '⋚'),
			_Utils_Tuple2('LessFullEqual', '≦'),
			_Utils_Tuple2('LessGreater', '≶'),
			_Utils_Tuple2('lessgtr', '≶'),
			_Utils_Tuple2('LessLess', '⪡'),
			_Utils_Tuple2('lesssim', '≲'),
			_Utils_Tuple2('LessSlantEqual', '⩽'),
			_Utils_Tuple2('LessTilde', '≲'),
			_Utils_Tuple2('lfisht', '⥼'),
			_Utils_Tuple2('lfloor', '⌊'),
			_Utils_Tuple2('Lfr', '\uD835\uDD0F'),
			_Utils_Tuple2('lfr', '\uD835\uDD29'),
			_Utils_Tuple2('lg', '≶'),
			_Utils_Tuple2('lgE', '⪑'),
			_Utils_Tuple2('lHar', '⥢'),
			_Utils_Tuple2('lhard', '↽'),
			_Utils_Tuple2('lharu', '↼'),
			_Utils_Tuple2('lharul', '⥪'),
			_Utils_Tuple2('lhblk', '▄'),
			_Utils_Tuple2('LJcy', 'Љ'),
			_Utils_Tuple2('ljcy', 'љ'),
			_Utils_Tuple2('llarr', '⇇'),
			_Utils_Tuple2('ll', '≪'),
			_Utils_Tuple2('Ll', '⋘'),
			_Utils_Tuple2('llcorner', '⌞'),
			_Utils_Tuple2('Lleftarrow', '⇚'),
			_Utils_Tuple2('llhard', '⥫'),
			_Utils_Tuple2('lltri', '◺'),
			_Utils_Tuple2('Lmidot', 'Ŀ'),
			_Utils_Tuple2('lmidot', 'ŀ'),
			_Utils_Tuple2('lmoustache', '⎰'),
			_Utils_Tuple2('lmoust', '⎰'),
			_Utils_Tuple2('lnap', '⪉'),
			_Utils_Tuple2('lnapprox', '⪉'),
			_Utils_Tuple2('lne', '⪇'),
			_Utils_Tuple2('lnE', '≨'),
			_Utils_Tuple2('lneq', '⪇'),
			_Utils_Tuple2('lneqq', '≨'),
			_Utils_Tuple2('lnsim', '⋦'),
			_Utils_Tuple2('loang', '⟬'),
			_Utils_Tuple2('loarr', '⇽'),
			_Utils_Tuple2('lobrk', '⟦'),
			_Utils_Tuple2('longleftarrow', '⟵'),
			_Utils_Tuple2('LongLeftArrow', '⟵'),
			_Utils_Tuple2('Longleftarrow', '⟸'),
			_Utils_Tuple2('longleftrightarrow', '⟷'),
			_Utils_Tuple2('LongLeftRightArrow', '⟷'),
			_Utils_Tuple2('Longleftrightarrow', '⟺'),
			_Utils_Tuple2('longmapsto', '⟼'),
			_Utils_Tuple2('longrightarrow', '⟶'),
			_Utils_Tuple2('LongRightArrow', '⟶'),
			_Utils_Tuple2('Longrightarrow', '⟹'),
			_Utils_Tuple2('looparrowleft', '↫'),
			_Utils_Tuple2('looparrowright', '↬'),
			_Utils_Tuple2('lopar', '⦅'),
			_Utils_Tuple2('Lopf', '\uD835\uDD43'),
			_Utils_Tuple2('lopf', '\uD835\uDD5D'),
			_Utils_Tuple2('loplus', '⨭'),
			_Utils_Tuple2('lotimes', '⨴'),
			_Utils_Tuple2('lowast', '∗'),
			_Utils_Tuple2('lowbar', '_'),
			_Utils_Tuple2('LowerLeftArrow', '↙'),
			_Utils_Tuple2('LowerRightArrow', '↘'),
			_Utils_Tuple2('loz', '◊'),
			_Utils_Tuple2('lozenge', '◊'),
			_Utils_Tuple2('lozf', '⧫'),
			_Utils_Tuple2('lpar', '('),
			_Utils_Tuple2('lparlt', '⦓'),
			_Utils_Tuple2('lrarr', '⇆'),
			_Utils_Tuple2('lrcorner', '⌟'),
			_Utils_Tuple2('lrhar', '⇋'),
			_Utils_Tuple2('lrhard', '⥭'),
			_Utils_Tuple2('lrm', '\u200E'),
			_Utils_Tuple2('lrtri', '⊿'),
			_Utils_Tuple2('lsaquo', '‹'),
			_Utils_Tuple2('lscr', '\uD835\uDCC1'),
			_Utils_Tuple2('Lscr', 'ℒ'),
			_Utils_Tuple2('lsh', '↰'),
			_Utils_Tuple2('Lsh', '↰'),
			_Utils_Tuple2('lsim', '≲'),
			_Utils_Tuple2('lsime', '⪍'),
			_Utils_Tuple2('lsimg', '⪏'),
			_Utils_Tuple2('lsqb', '['),
			_Utils_Tuple2('lsquo', '‘'),
			_Utils_Tuple2('lsquor', '‚'),
			_Utils_Tuple2('Lstrok', 'Ł'),
			_Utils_Tuple2('lstrok', 'ł'),
			_Utils_Tuple2('ltcc', '⪦'),
			_Utils_Tuple2('ltcir', '⩹'),
			_Utils_Tuple2('lt', '<'),
			_Utils_Tuple2('LT', '<'),
			_Utils_Tuple2('Lt', '≪'),
			_Utils_Tuple2('ltdot', '⋖'),
			_Utils_Tuple2('lthree', '⋋'),
			_Utils_Tuple2('ltimes', '⋉'),
			_Utils_Tuple2('ltlarr', '⥶'),
			_Utils_Tuple2('ltquest', '⩻'),
			_Utils_Tuple2('ltri', '◃'),
			_Utils_Tuple2('ltrie', '⊴'),
			_Utils_Tuple2('ltrif', '◂'),
			_Utils_Tuple2('ltrPar', '⦖'),
			_Utils_Tuple2('lurdshar', '⥊'),
			_Utils_Tuple2('luruhar', '⥦'),
			_Utils_Tuple2('lvertneqq', '≨︀'),
			_Utils_Tuple2('lvnE', '≨︀'),
			_Utils_Tuple2('macr', '¯'),
			_Utils_Tuple2('male', '♂'),
			_Utils_Tuple2('malt', '✠'),
			_Utils_Tuple2('maltese', '✠'),
			_Utils_Tuple2('Map', '⤅'),
			_Utils_Tuple2('map', '↦'),
			_Utils_Tuple2('mapsto', '↦'),
			_Utils_Tuple2('mapstodown', '↧'),
			_Utils_Tuple2('mapstoleft', '↤'),
			_Utils_Tuple2('mapstoup', '↥'),
			_Utils_Tuple2('marker', '▮'),
			_Utils_Tuple2('mcomma', '⨩'),
			_Utils_Tuple2('Mcy', 'М'),
			_Utils_Tuple2('mcy', 'м'),
			_Utils_Tuple2('mdash', '—'),
			_Utils_Tuple2('mDDot', '∺'),
			_Utils_Tuple2('measuredangle', '∡'),
			_Utils_Tuple2('MediumSpace', '\u205F'),
			_Utils_Tuple2('Mellintrf', 'ℳ'),
			_Utils_Tuple2('Mfr', '\uD835\uDD10'),
			_Utils_Tuple2('mfr', '\uD835\uDD2A'),
			_Utils_Tuple2('mho', '℧'),
			_Utils_Tuple2('micro', 'µ'),
			_Utils_Tuple2('midast', '*'),
			_Utils_Tuple2('midcir', '⫰'),
			_Utils_Tuple2('mid', '∣'),
			_Utils_Tuple2('middot', '·'),
			_Utils_Tuple2('minusb', '⊟'),
			_Utils_Tuple2('minus', '−'),
			_Utils_Tuple2('minusd', '∸'),
			_Utils_Tuple2('minusdu', '⨪'),
			_Utils_Tuple2('MinusPlus', '∓'),
			_Utils_Tuple2('mlcp', '⫛'),
			_Utils_Tuple2('mldr', '…'),
			_Utils_Tuple2('mnplus', '∓'),
			_Utils_Tuple2('models', '⊧'),
			_Utils_Tuple2('Mopf', '\uD835\uDD44'),
			_Utils_Tuple2('mopf', '\uD835\uDD5E'),
			_Utils_Tuple2('mp', '∓'),
			_Utils_Tuple2('mscr', '\uD835\uDCC2'),
			_Utils_Tuple2('Mscr', 'ℳ'),
			_Utils_Tuple2('mstpos', '∾'),
			_Utils_Tuple2('Mu', 'Μ'),
			_Utils_Tuple2('mu', 'μ'),
			_Utils_Tuple2('multimap', '⊸'),
			_Utils_Tuple2('mumap', '⊸'),
			_Utils_Tuple2('nabla', '∇'),
			_Utils_Tuple2('Nacute', 'Ń'),
			_Utils_Tuple2('nacute', 'ń'),
			_Utils_Tuple2('nang', '∠⃒'),
			_Utils_Tuple2('nap', '≉'),
			_Utils_Tuple2('napE', '⩰̸'),
			_Utils_Tuple2('napid', '≋̸'),
			_Utils_Tuple2('napos', 'ŉ'),
			_Utils_Tuple2('napprox', '≉'),
			_Utils_Tuple2('natural', '♮'),
			_Utils_Tuple2('naturals', 'ℕ'),
			_Utils_Tuple2('natur', '♮'),
			_Utils_Tuple2('nbsp', '\u00A0'),
			_Utils_Tuple2('nbump', '≎̸'),
			_Utils_Tuple2('nbumpe', '≏̸'),
			_Utils_Tuple2('ncap', '⩃'),
			_Utils_Tuple2('Ncaron', 'Ň'),
			_Utils_Tuple2('ncaron', 'ň'),
			_Utils_Tuple2('Ncedil', 'Ņ'),
			_Utils_Tuple2('ncedil', 'ņ'),
			_Utils_Tuple2('ncong', '≇'),
			_Utils_Tuple2('ncongdot', '⩭̸'),
			_Utils_Tuple2('ncup', '⩂'),
			_Utils_Tuple2('Ncy', 'Н'),
			_Utils_Tuple2('ncy', 'н'),
			_Utils_Tuple2('ndash', '–'),
			_Utils_Tuple2('nearhk', '⤤'),
			_Utils_Tuple2('nearr', '↗'),
			_Utils_Tuple2('neArr', '⇗'),
			_Utils_Tuple2('nearrow', '↗'),
			_Utils_Tuple2('ne', '≠'),
			_Utils_Tuple2('nedot', '≐̸'),
			_Utils_Tuple2('NegativeMediumSpace', '\u200B'),
			_Utils_Tuple2('NegativeThickSpace', '\u200B'),
			_Utils_Tuple2('NegativeThinSpace', '\u200B'),
			_Utils_Tuple2('NegativeVeryThinSpace', '\u200B'),
			_Utils_Tuple2('nequiv', '≢'),
			_Utils_Tuple2('nesear', '⤨'),
			_Utils_Tuple2('nesim', '≂̸'),
			_Utils_Tuple2('NestedGreaterGreater', '≫'),
			_Utils_Tuple2('NestedLessLess', '≪'),
			_Utils_Tuple2('NewLine', '\n'),
			_Utils_Tuple2('nexist', '∄'),
			_Utils_Tuple2('nexists', '∄'),
			_Utils_Tuple2('Nfr', '\uD835\uDD11'),
			_Utils_Tuple2('nfr', '\uD835\uDD2B'),
			_Utils_Tuple2('ngE', '≧̸'),
			_Utils_Tuple2('nge', '≱'),
			_Utils_Tuple2('ngeq', '≱'),
			_Utils_Tuple2('ngeqq', '≧̸'),
			_Utils_Tuple2('ngeqslant', '⩾̸'),
			_Utils_Tuple2('nges', '⩾̸'),
			_Utils_Tuple2('nGg', '⋙̸'),
			_Utils_Tuple2('ngsim', '≵'),
			_Utils_Tuple2('nGt', '≫⃒'),
			_Utils_Tuple2('ngt', '≯'),
			_Utils_Tuple2('ngtr', '≯'),
			_Utils_Tuple2('nGtv', '≫̸'),
			_Utils_Tuple2('nharr', '↮'),
			_Utils_Tuple2('nhArr', '⇎'),
			_Utils_Tuple2('nhpar', '⫲'),
			_Utils_Tuple2('ni', '∋'),
			_Utils_Tuple2('nis', '⋼'),
			_Utils_Tuple2('nisd', '⋺'),
			_Utils_Tuple2('niv', '∋'),
			_Utils_Tuple2('NJcy', 'Њ'),
			_Utils_Tuple2('njcy', 'њ'),
			_Utils_Tuple2('nlarr', '↚'),
			_Utils_Tuple2('nlArr', '⇍'),
			_Utils_Tuple2('nldr', '‥'),
			_Utils_Tuple2('nlE', '≦̸'),
			_Utils_Tuple2('nle', '≰'),
			_Utils_Tuple2('nleftarrow', '↚'),
			_Utils_Tuple2('nLeftarrow', '⇍'),
			_Utils_Tuple2('nleftrightarrow', '↮'),
			_Utils_Tuple2('nLeftrightarrow', '⇎'),
			_Utils_Tuple2('nleq', '≰'),
			_Utils_Tuple2('nleqq', '≦̸'),
			_Utils_Tuple2('nleqslant', '⩽̸'),
			_Utils_Tuple2('nles', '⩽̸'),
			_Utils_Tuple2('nless', '≮'),
			_Utils_Tuple2('nLl', '⋘̸'),
			_Utils_Tuple2('nlsim', '≴'),
			_Utils_Tuple2('nLt', '≪⃒'),
			_Utils_Tuple2('nlt', '≮'),
			_Utils_Tuple2('nltri', '⋪'),
			_Utils_Tuple2('nltrie', '⋬'),
			_Utils_Tuple2('nLtv', '≪̸'),
			_Utils_Tuple2('nmid', '∤'),
			_Utils_Tuple2('NoBreak', '\u2060'),
			_Utils_Tuple2('NonBreakingSpace', '\u00A0'),
			_Utils_Tuple2('nopf', '\uD835\uDD5F'),
			_Utils_Tuple2('Nopf', 'ℕ'),
			_Utils_Tuple2('Not', '⫬'),
			_Utils_Tuple2('not', '¬'),
			_Utils_Tuple2('NotCongruent', '≢'),
			_Utils_Tuple2('NotCupCap', '≭'),
			_Utils_Tuple2('NotDoubleVerticalBar', '∦'),
			_Utils_Tuple2('NotElement', '∉'),
			_Utils_Tuple2('NotEqual', '≠'),
			_Utils_Tuple2('NotEqualTilde', '≂̸'),
			_Utils_Tuple2('NotExists', '∄'),
			_Utils_Tuple2('NotGreater', '≯'),
			_Utils_Tuple2('NotGreaterEqual', '≱'),
			_Utils_Tuple2('NotGreaterFullEqual', '≧̸'),
			_Utils_Tuple2('NotGreaterGreater', '≫̸'),
			_Utils_Tuple2('NotGreaterLess', '≹'),
			_Utils_Tuple2('NotGreaterSlantEqual', '⩾̸'),
			_Utils_Tuple2('NotGreaterTilde', '≵'),
			_Utils_Tuple2('NotHumpDownHump', '≎̸'),
			_Utils_Tuple2('NotHumpEqual', '≏̸'),
			_Utils_Tuple2('notin', '∉'),
			_Utils_Tuple2('notindot', '⋵̸'),
			_Utils_Tuple2('notinE', '⋹̸'),
			_Utils_Tuple2('notinva', '∉'),
			_Utils_Tuple2('notinvb', '⋷'),
			_Utils_Tuple2('notinvc', '⋶'),
			_Utils_Tuple2('NotLeftTriangleBar', '⧏̸'),
			_Utils_Tuple2('NotLeftTriangle', '⋪'),
			_Utils_Tuple2('NotLeftTriangleEqual', '⋬'),
			_Utils_Tuple2('NotLess', '≮'),
			_Utils_Tuple2('NotLessEqual', '≰'),
			_Utils_Tuple2('NotLessGreater', '≸'),
			_Utils_Tuple2('NotLessLess', '≪̸'),
			_Utils_Tuple2('NotLessSlantEqual', '⩽̸'),
			_Utils_Tuple2('NotLessTilde', '≴'),
			_Utils_Tuple2('NotNestedGreaterGreater', '⪢̸'),
			_Utils_Tuple2('NotNestedLessLess', '⪡̸'),
			_Utils_Tuple2('notni', '∌'),
			_Utils_Tuple2('notniva', '∌'),
			_Utils_Tuple2('notnivb', '⋾'),
			_Utils_Tuple2('notnivc', '⋽'),
			_Utils_Tuple2('NotPrecedes', '⊀'),
			_Utils_Tuple2('NotPrecedesEqual', '⪯̸'),
			_Utils_Tuple2('NotPrecedesSlantEqual', '⋠'),
			_Utils_Tuple2('NotReverseElement', '∌'),
			_Utils_Tuple2('NotRightTriangleBar', '⧐̸'),
			_Utils_Tuple2('NotRightTriangle', '⋫'),
			_Utils_Tuple2('NotRightTriangleEqual', '⋭'),
			_Utils_Tuple2('NotSquareSubset', '⊏̸'),
			_Utils_Tuple2('NotSquareSubsetEqual', '⋢'),
			_Utils_Tuple2('NotSquareSuperset', '⊐̸'),
			_Utils_Tuple2('NotSquareSupersetEqual', '⋣'),
			_Utils_Tuple2('NotSubset', '⊂⃒'),
			_Utils_Tuple2('NotSubsetEqual', '⊈'),
			_Utils_Tuple2('NotSucceeds', '⊁'),
			_Utils_Tuple2('NotSucceedsEqual', '⪰̸'),
			_Utils_Tuple2('NotSucceedsSlantEqual', '⋡'),
			_Utils_Tuple2('NotSucceedsTilde', '≿̸'),
			_Utils_Tuple2('NotSuperset', '⊃⃒'),
			_Utils_Tuple2('NotSupersetEqual', '⊉'),
			_Utils_Tuple2('NotTilde', '≁'),
			_Utils_Tuple2('NotTildeEqual', '≄'),
			_Utils_Tuple2('NotTildeFullEqual', '≇'),
			_Utils_Tuple2('NotTildeTilde', '≉'),
			_Utils_Tuple2('NotVerticalBar', '∤'),
			_Utils_Tuple2('nparallel', '∦'),
			_Utils_Tuple2('npar', '∦'),
			_Utils_Tuple2('nparsl', '⫽⃥'),
			_Utils_Tuple2('npart', '∂̸'),
			_Utils_Tuple2('npolint', '⨔'),
			_Utils_Tuple2('npr', '⊀'),
			_Utils_Tuple2('nprcue', '⋠'),
			_Utils_Tuple2('nprec', '⊀'),
			_Utils_Tuple2('npreceq', '⪯̸'),
			_Utils_Tuple2('npre', '⪯̸'),
			_Utils_Tuple2('nrarrc', '⤳̸'),
			_Utils_Tuple2('nrarr', '↛'),
			_Utils_Tuple2('nrArr', '⇏'),
			_Utils_Tuple2('nrarrw', '↝̸'),
			_Utils_Tuple2('nrightarrow', '↛'),
			_Utils_Tuple2('nRightarrow', '⇏'),
			_Utils_Tuple2('nrtri', '⋫'),
			_Utils_Tuple2('nrtrie', '⋭'),
			_Utils_Tuple2('nsc', '⊁'),
			_Utils_Tuple2('nsccue', '⋡'),
			_Utils_Tuple2('nsce', '⪰̸'),
			_Utils_Tuple2('Nscr', '\uD835\uDCA9'),
			_Utils_Tuple2('nscr', '\uD835\uDCC3'),
			_Utils_Tuple2('nshortmid', '∤'),
			_Utils_Tuple2('nshortparallel', '∦'),
			_Utils_Tuple2('nsim', '≁'),
			_Utils_Tuple2('nsime', '≄'),
			_Utils_Tuple2('nsimeq', '≄'),
			_Utils_Tuple2('nsmid', '∤'),
			_Utils_Tuple2('nspar', '∦'),
			_Utils_Tuple2('nsqsube', '⋢'),
			_Utils_Tuple2('nsqsupe', '⋣'),
			_Utils_Tuple2('nsub', '⊄'),
			_Utils_Tuple2('nsubE', '⫅̸'),
			_Utils_Tuple2('nsube', '⊈'),
			_Utils_Tuple2('nsubset', '⊂⃒'),
			_Utils_Tuple2('nsubseteq', '⊈'),
			_Utils_Tuple2('nsubseteqq', '⫅̸'),
			_Utils_Tuple2('nsucc', '⊁'),
			_Utils_Tuple2('nsucceq', '⪰̸'),
			_Utils_Tuple2('nsup', '⊅'),
			_Utils_Tuple2('nsupE', '⫆̸'),
			_Utils_Tuple2('nsupe', '⊉'),
			_Utils_Tuple2('nsupset', '⊃⃒'),
			_Utils_Tuple2('nsupseteq', '⊉'),
			_Utils_Tuple2('nsupseteqq', '⫆̸'),
			_Utils_Tuple2('ntgl', '≹'),
			_Utils_Tuple2('Ntilde', 'Ñ'),
			_Utils_Tuple2('ntilde', 'ñ'),
			_Utils_Tuple2('ntlg', '≸'),
			_Utils_Tuple2('ntriangleleft', '⋪'),
			_Utils_Tuple2('ntrianglelefteq', '⋬'),
			_Utils_Tuple2('ntriangleright', '⋫'),
			_Utils_Tuple2('ntrianglerighteq', '⋭'),
			_Utils_Tuple2('Nu', 'Ν'),
			_Utils_Tuple2('nu', 'ν'),
			_Utils_Tuple2('num', '#'),
			_Utils_Tuple2('numero', '№'),
			_Utils_Tuple2('numsp', '\u2007'),
			_Utils_Tuple2('nvap', '≍⃒'),
			_Utils_Tuple2('nvdash', '⊬'),
			_Utils_Tuple2('nvDash', '⊭'),
			_Utils_Tuple2('nVdash', '⊮'),
			_Utils_Tuple2('nVDash', '⊯'),
			_Utils_Tuple2('nvge', '≥⃒'),
			_Utils_Tuple2('nvgt', '>⃒'),
			_Utils_Tuple2('nvHarr', '⤄'),
			_Utils_Tuple2('nvinfin', '⧞'),
			_Utils_Tuple2('nvlArr', '⤂'),
			_Utils_Tuple2('nvle', '≤⃒'),
			_Utils_Tuple2('nvlt', '<⃒'),
			_Utils_Tuple2('nvltrie', '⊴⃒'),
			_Utils_Tuple2('nvrArr', '⤃'),
			_Utils_Tuple2('nvrtrie', '⊵⃒'),
			_Utils_Tuple2('nvsim', '∼⃒'),
			_Utils_Tuple2('nwarhk', '⤣'),
			_Utils_Tuple2('nwarr', '↖'),
			_Utils_Tuple2('nwArr', '⇖'),
			_Utils_Tuple2('nwarrow', '↖'),
			_Utils_Tuple2('nwnear', '⤧'),
			_Utils_Tuple2('Oacute', 'Ó'),
			_Utils_Tuple2('oacute', 'ó'),
			_Utils_Tuple2('oast', '⊛'),
			_Utils_Tuple2('Ocirc', 'Ô'),
			_Utils_Tuple2('ocirc', 'ô'),
			_Utils_Tuple2('ocir', '⊚'),
			_Utils_Tuple2('Ocy', 'О'),
			_Utils_Tuple2('ocy', 'о'),
			_Utils_Tuple2('odash', '⊝'),
			_Utils_Tuple2('Odblac', 'Ő'),
			_Utils_Tuple2('odblac', 'ő'),
			_Utils_Tuple2('odiv', '⨸'),
			_Utils_Tuple2('odot', '⊙'),
			_Utils_Tuple2('odsold', '⦼'),
			_Utils_Tuple2('OElig', 'Œ'),
			_Utils_Tuple2('oelig', 'œ'),
			_Utils_Tuple2('ofcir', '⦿'),
			_Utils_Tuple2('Ofr', '\uD835\uDD12'),
			_Utils_Tuple2('ofr', '\uD835\uDD2C'),
			_Utils_Tuple2('ogon', '˛'),
			_Utils_Tuple2('Ograve', 'Ò'),
			_Utils_Tuple2('ograve', 'ò'),
			_Utils_Tuple2('ogt', '⧁'),
			_Utils_Tuple2('ohbar', '⦵'),
			_Utils_Tuple2('ohm', 'Ω'),
			_Utils_Tuple2('oint', '∮'),
			_Utils_Tuple2('olarr', '↺'),
			_Utils_Tuple2('olcir', '⦾'),
			_Utils_Tuple2('olcross', '⦻'),
			_Utils_Tuple2('oline', '‾'),
			_Utils_Tuple2('olt', '⧀'),
			_Utils_Tuple2('Omacr', 'Ō'),
			_Utils_Tuple2('omacr', 'ō'),
			_Utils_Tuple2('Omega', 'Ω'),
			_Utils_Tuple2('omega', 'ω'),
			_Utils_Tuple2('Omicron', 'Ο'),
			_Utils_Tuple2('omicron', 'ο'),
			_Utils_Tuple2('omid', '⦶'),
			_Utils_Tuple2('ominus', '⊖'),
			_Utils_Tuple2('Oopf', '\uD835\uDD46'),
			_Utils_Tuple2('oopf', '\uD835\uDD60'),
			_Utils_Tuple2('opar', '⦷'),
			_Utils_Tuple2('OpenCurlyDoubleQuote', '“'),
			_Utils_Tuple2('OpenCurlyQuote', '‘'),
			_Utils_Tuple2('operp', '⦹'),
			_Utils_Tuple2('oplus', '⊕'),
			_Utils_Tuple2('orarr', '↻'),
			_Utils_Tuple2('Or', '⩔'),
			_Utils_Tuple2('or', '∨'),
			_Utils_Tuple2('ord', '⩝'),
			_Utils_Tuple2('order', 'ℴ'),
			_Utils_Tuple2('orderof', 'ℴ'),
			_Utils_Tuple2('ordf', 'ª'),
			_Utils_Tuple2('ordm', 'º'),
			_Utils_Tuple2('origof', '⊶'),
			_Utils_Tuple2('oror', '⩖'),
			_Utils_Tuple2('orslope', '⩗'),
			_Utils_Tuple2('orv', '⩛'),
			_Utils_Tuple2('oS', 'Ⓢ'),
			_Utils_Tuple2('Oscr', '\uD835\uDCAA'),
			_Utils_Tuple2('oscr', 'ℴ'),
			_Utils_Tuple2('Oslash', 'Ø'),
			_Utils_Tuple2('oslash', 'ø'),
			_Utils_Tuple2('osol', '⊘'),
			_Utils_Tuple2('Otilde', 'Õ'),
			_Utils_Tuple2('otilde', 'õ'),
			_Utils_Tuple2('otimesas', '⨶'),
			_Utils_Tuple2('Otimes', '⨷'),
			_Utils_Tuple2('otimes', '⊗'),
			_Utils_Tuple2('Ouml', 'Ö'),
			_Utils_Tuple2('ouml', 'ö'),
			_Utils_Tuple2('ovbar', '⌽'),
			_Utils_Tuple2('OverBar', '‾'),
			_Utils_Tuple2('OverBrace', '⏞'),
			_Utils_Tuple2('OverBracket', '⎴'),
			_Utils_Tuple2('OverParenthesis', '⏜'),
			_Utils_Tuple2('para', '¶'),
			_Utils_Tuple2('parallel', '∥'),
			_Utils_Tuple2('par', '∥'),
			_Utils_Tuple2('parsim', '⫳'),
			_Utils_Tuple2('parsl', '⫽'),
			_Utils_Tuple2('part', '∂'),
			_Utils_Tuple2('PartialD', '∂'),
			_Utils_Tuple2('Pcy', 'П'),
			_Utils_Tuple2('pcy', 'п'),
			_Utils_Tuple2('percnt', '%'),
			_Utils_Tuple2('period', '.'),
			_Utils_Tuple2('permil', '‰'),
			_Utils_Tuple2('perp', '⊥'),
			_Utils_Tuple2('pertenk', '‱'),
			_Utils_Tuple2('Pfr', '\uD835\uDD13'),
			_Utils_Tuple2('pfr', '\uD835\uDD2D'),
			_Utils_Tuple2('Phi', 'Φ'),
			_Utils_Tuple2('phi', 'φ'),
			_Utils_Tuple2('phiv', 'ϕ'),
			_Utils_Tuple2('phmmat', 'ℳ'),
			_Utils_Tuple2('phone', '☎'),
			_Utils_Tuple2('Pi', 'Π'),
			_Utils_Tuple2('pi', 'π'),
			_Utils_Tuple2('pitchfork', '⋔'),
			_Utils_Tuple2('piv', 'ϖ'),
			_Utils_Tuple2('planck', 'ℏ'),
			_Utils_Tuple2('planckh', 'ℎ'),
			_Utils_Tuple2('plankv', 'ℏ'),
			_Utils_Tuple2('plusacir', '⨣'),
			_Utils_Tuple2('plusb', '⊞'),
			_Utils_Tuple2('pluscir', '⨢'),
			_Utils_Tuple2('plus', '+'),
			_Utils_Tuple2('plusdo', '∔'),
			_Utils_Tuple2('plusdu', '⨥'),
			_Utils_Tuple2('pluse', '⩲'),
			_Utils_Tuple2('PlusMinus', '±'),
			_Utils_Tuple2('plusmn', '±'),
			_Utils_Tuple2('plussim', '⨦'),
			_Utils_Tuple2('plustwo', '⨧'),
			_Utils_Tuple2('pm', '±'),
			_Utils_Tuple2('Poincareplane', 'ℌ'),
			_Utils_Tuple2('pointint', '⨕'),
			_Utils_Tuple2('popf', '\uD835\uDD61'),
			_Utils_Tuple2('Popf', 'ℙ'),
			_Utils_Tuple2('pound', '£'),
			_Utils_Tuple2('prap', '⪷'),
			_Utils_Tuple2('Pr', '⪻'),
			_Utils_Tuple2('pr', '≺'),
			_Utils_Tuple2('prcue', '≼'),
			_Utils_Tuple2('precapprox', '⪷'),
			_Utils_Tuple2('prec', '≺'),
			_Utils_Tuple2('preccurlyeq', '≼'),
			_Utils_Tuple2('Precedes', '≺'),
			_Utils_Tuple2('PrecedesEqual', '⪯'),
			_Utils_Tuple2('PrecedesSlantEqual', '≼'),
			_Utils_Tuple2('PrecedesTilde', '≾'),
			_Utils_Tuple2('preceq', '⪯'),
			_Utils_Tuple2('precnapprox', '⪹'),
			_Utils_Tuple2('precneqq', '⪵'),
			_Utils_Tuple2('precnsim', '⋨'),
			_Utils_Tuple2('pre', '⪯'),
			_Utils_Tuple2('prE', '⪳'),
			_Utils_Tuple2('precsim', '≾'),
			_Utils_Tuple2('prime', '′'),
			_Utils_Tuple2('Prime', '″'),
			_Utils_Tuple2('primes', 'ℙ'),
			_Utils_Tuple2('prnap', '⪹'),
			_Utils_Tuple2('prnE', '⪵'),
			_Utils_Tuple2('prnsim', '⋨'),
			_Utils_Tuple2('prod', '∏'),
			_Utils_Tuple2('Product', '∏'),
			_Utils_Tuple2('profalar', '⌮'),
			_Utils_Tuple2('profline', '⌒'),
			_Utils_Tuple2('profsurf', '⌓'),
			_Utils_Tuple2('prop', '∝'),
			_Utils_Tuple2('Proportional', '∝'),
			_Utils_Tuple2('Proportion', '∷'),
			_Utils_Tuple2('propto', '∝'),
			_Utils_Tuple2('prsim', '≾'),
			_Utils_Tuple2('prurel', '⊰'),
			_Utils_Tuple2('Pscr', '\uD835\uDCAB'),
			_Utils_Tuple2('pscr', '\uD835\uDCC5'),
			_Utils_Tuple2('Psi', 'Ψ'),
			_Utils_Tuple2('psi', 'ψ'),
			_Utils_Tuple2('puncsp', '\u2008'),
			_Utils_Tuple2('Qfr', '\uD835\uDD14'),
			_Utils_Tuple2('qfr', '\uD835\uDD2E'),
			_Utils_Tuple2('qint', '⨌'),
			_Utils_Tuple2('qopf', '\uD835\uDD62'),
			_Utils_Tuple2('Qopf', 'ℚ'),
			_Utils_Tuple2('qprime', '⁗'),
			_Utils_Tuple2('Qscr', '\uD835\uDCAC'),
			_Utils_Tuple2('qscr', '\uD835\uDCC6'),
			_Utils_Tuple2('quaternions', 'ℍ'),
			_Utils_Tuple2('quatint', '⨖'),
			_Utils_Tuple2('quest', '?'),
			_Utils_Tuple2('questeq', '≟'),
			_Utils_Tuple2('quot', '\"'),
			_Utils_Tuple2('QUOT', '\"'),
			_Utils_Tuple2('rAarr', '⇛'),
			_Utils_Tuple2('race', '∽̱'),
			_Utils_Tuple2('Racute', 'Ŕ'),
			_Utils_Tuple2('racute', 'ŕ'),
			_Utils_Tuple2('radic', '√'),
			_Utils_Tuple2('raemptyv', '⦳'),
			_Utils_Tuple2('rang', '⟩'),
			_Utils_Tuple2('Rang', '⟫'),
			_Utils_Tuple2('rangd', '⦒'),
			_Utils_Tuple2('range', '⦥'),
			_Utils_Tuple2('rangle', '⟩'),
			_Utils_Tuple2('raquo', '»'),
			_Utils_Tuple2('rarrap', '⥵'),
			_Utils_Tuple2('rarrb', '⇥'),
			_Utils_Tuple2('rarrbfs', '⤠'),
			_Utils_Tuple2('rarrc', '⤳'),
			_Utils_Tuple2('rarr', '→'),
			_Utils_Tuple2('Rarr', '↠'),
			_Utils_Tuple2('rArr', '⇒'),
			_Utils_Tuple2('rarrfs', '⤞'),
			_Utils_Tuple2('rarrhk', '↪'),
			_Utils_Tuple2('rarrlp', '↬'),
			_Utils_Tuple2('rarrpl', '⥅'),
			_Utils_Tuple2('rarrsim', '⥴'),
			_Utils_Tuple2('Rarrtl', '⤖'),
			_Utils_Tuple2('rarrtl', '↣'),
			_Utils_Tuple2('rarrw', '↝'),
			_Utils_Tuple2('ratail', '⤚'),
			_Utils_Tuple2('rAtail', '⤜'),
			_Utils_Tuple2('ratio', '∶'),
			_Utils_Tuple2('rationals', 'ℚ'),
			_Utils_Tuple2('rbarr', '⤍'),
			_Utils_Tuple2('rBarr', '⤏'),
			_Utils_Tuple2('RBarr', '⤐'),
			_Utils_Tuple2('rbbrk', '❳'),
			_Utils_Tuple2('rbrace', '}'),
			_Utils_Tuple2('rbrack', ']'),
			_Utils_Tuple2('rbrke', '⦌'),
			_Utils_Tuple2('rbrksld', '⦎'),
			_Utils_Tuple2('rbrkslu', '⦐'),
			_Utils_Tuple2('Rcaron', 'Ř'),
			_Utils_Tuple2('rcaron', 'ř'),
			_Utils_Tuple2('Rcedil', 'Ŗ'),
			_Utils_Tuple2('rcedil', 'ŗ'),
			_Utils_Tuple2('rceil', '⌉'),
			_Utils_Tuple2('rcub', '}'),
			_Utils_Tuple2('Rcy', 'Р'),
			_Utils_Tuple2('rcy', 'р'),
			_Utils_Tuple2('rdca', '⤷'),
			_Utils_Tuple2('rdldhar', '⥩'),
			_Utils_Tuple2('rdquo', '”'),
			_Utils_Tuple2('rdquor', '”'),
			_Utils_Tuple2('rdsh', '↳'),
			_Utils_Tuple2('real', 'ℜ'),
			_Utils_Tuple2('realine', 'ℛ'),
			_Utils_Tuple2('realpart', 'ℜ'),
			_Utils_Tuple2('reals', 'ℝ'),
			_Utils_Tuple2('Re', 'ℜ'),
			_Utils_Tuple2('rect', '▭'),
			_Utils_Tuple2('reg', '®'),
			_Utils_Tuple2('REG', '®'),
			_Utils_Tuple2('ReverseElement', '∋'),
			_Utils_Tuple2('ReverseEquilibrium', '⇋'),
			_Utils_Tuple2('ReverseUpEquilibrium', '⥯'),
			_Utils_Tuple2('rfisht', '⥽'),
			_Utils_Tuple2('rfloor', '⌋'),
			_Utils_Tuple2('rfr', '\uD835\uDD2F'),
			_Utils_Tuple2('Rfr', 'ℜ'),
			_Utils_Tuple2('rHar', '⥤'),
			_Utils_Tuple2('rhard', '⇁'),
			_Utils_Tuple2('rharu', '⇀'),
			_Utils_Tuple2('rharul', '⥬'),
			_Utils_Tuple2('Rho', 'Ρ'),
			_Utils_Tuple2('rho', 'ρ'),
			_Utils_Tuple2('rhov', 'ϱ'),
			_Utils_Tuple2('RightAngleBracket', '⟩'),
			_Utils_Tuple2('RightArrowBar', '⇥'),
			_Utils_Tuple2('rightarrow', '→'),
			_Utils_Tuple2('RightArrow', '→'),
			_Utils_Tuple2('Rightarrow', '⇒'),
			_Utils_Tuple2('RightArrowLeftArrow', '⇄'),
			_Utils_Tuple2('rightarrowtail', '↣'),
			_Utils_Tuple2('RightCeiling', '⌉'),
			_Utils_Tuple2('RightDoubleBracket', '⟧'),
			_Utils_Tuple2('RightDownTeeVector', '⥝'),
			_Utils_Tuple2('RightDownVectorBar', '⥕'),
			_Utils_Tuple2('RightDownVector', '⇂'),
			_Utils_Tuple2('RightFloor', '⌋'),
			_Utils_Tuple2('rightharpoondown', '⇁'),
			_Utils_Tuple2('rightharpoonup', '⇀'),
			_Utils_Tuple2('rightleftarrows', '⇄'),
			_Utils_Tuple2('rightleftharpoons', '⇌'),
			_Utils_Tuple2('rightrightarrows', '⇉'),
			_Utils_Tuple2('rightsquigarrow', '↝'),
			_Utils_Tuple2('RightTeeArrow', '↦'),
			_Utils_Tuple2('RightTee', '⊢'),
			_Utils_Tuple2('RightTeeVector', '⥛'),
			_Utils_Tuple2('rightthreetimes', '⋌'),
			_Utils_Tuple2('RightTriangleBar', '⧐'),
			_Utils_Tuple2('RightTriangle', '⊳'),
			_Utils_Tuple2('RightTriangleEqual', '⊵'),
			_Utils_Tuple2('RightUpDownVector', '⥏'),
			_Utils_Tuple2('RightUpTeeVector', '⥜'),
			_Utils_Tuple2('RightUpVectorBar', '⥔'),
			_Utils_Tuple2('RightUpVector', '↾'),
			_Utils_Tuple2('RightVectorBar', '⥓'),
			_Utils_Tuple2('RightVector', '⇀'),
			_Utils_Tuple2('ring', '˚'),
			_Utils_Tuple2('risingdotseq', '≓'),
			_Utils_Tuple2('rlarr', '⇄'),
			_Utils_Tuple2('rlhar', '⇌'),
			_Utils_Tuple2('rlm', '\u200F'),
			_Utils_Tuple2('rmoustache', '⎱'),
			_Utils_Tuple2('rmoust', '⎱'),
			_Utils_Tuple2('rnmid', '⫮'),
			_Utils_Tuple2('roang', '⟭'),
			_Utils_Tuple2('roarr', '⇾'),
			_Utils_Tuple2('robrk', '⟧'),
			_Utils_Tuple2('ropar', '⦆'),
			_Utils_Tuple2('ropf', '\uD835\uDD63'),
			_Utils_Tuple2('Ropf', 'ℝ'),
			_Utils_Tuple2('roplus', '⨮'),
			_Utils_Tuple2('rotimes', '⨵'),
			_Utils_Tuple2('RoundImplies', '⥰'),
			_Utils_Tuple2('rpar', ')'),
			_Utils_Tuple2('rpargt', '⦔'),
			_Utils_Tuple2('rppolint', '⨒'),
			_Utils_Tuple2('rrarr', '⇉'),
			_Utils_Tuple2('Rrightarrow', '⇛'),
			_Utils_Tuple2('rsaquo', '›'),
			_Utils_Tuple2('rscr', '\uD835\uDCC7'),
			_Utils_Tuple2('Rscr', 'ℛ'),
			_Utils_Tuple2('rsh', '↱'),
			_Utils_Tuple2('Rsh', '↱'),
			_Utils_Tuple2('rsqb', ']'),
			_Utils_Tuple2('rsquo', '’'),
			_Utils_Tuple2('rsquor', '’'),
			_Utils_Tuple2('rthree', '⋌'),
			_Utils_Tuple2('rtimes', '⋊'),
			_Utils_Tuple2('rtri', '▹'),
			_Utils_Tuple2('rtrie', '⊵'),
			_Utils_Tuple2('rtrif', '▸'),
			_Utils_Tuple2('rtriltri', '⧎'),
			_Utils_Tuple2('RuleDelayed', '⧴'),
			_Utils_Tuple2('ruluhar', '⥨'),
			_Utils_Tuple2('rx', '℞'),
			_Utils_Tuple2('Sacute', 'Ś'),
			_Utils_Tuple2('sacute', 'ś'),
			_Utils_Tuple2('sbquo', '‚'),
			_Utils_Tuple2('scap', '⪸'),
			_Utils_Tuple2('Scaron', 'Š'),
			_Utils_Tuple2('scaron', 'š'),
			_Utils_Tuple2('Sc', '⪼'),
			_Utils_Tuple2('sc', '≻'),
			_Utils_Tuple2('sccue', '≽'),
			_Utils_Tuple2('sce', '⪰'),
			_Utils_Tuple2('scE', '⪴'),
			_Utils_Tuple2('Scedil', 'Ş'),
			_Utils_Tuple2('scedil', 'ş'),
			_Utils_Tuple2('Scirc', 'Ŝ'),
			_Utils_Tuple2('scirc', 'ŝ'),
			_Utils_Tuple2('scnap', '⪺'),
			_Utils_Tuple2('scnE', '⪶'),
			_Utils_Tuple2('scnsim', '⋩'),
			_Utils_Tuple2('scpolint', '⨓'),
			_Utils_Tuple2('scsim', '≿'),
			_Utils_Tuple2('Scy', 'С'),
			_Utils_Tuple2('scy', 'с'),
			_Utils_Tuple2('sdotb', '⊡'),
			_Utils_Tuple2('sdot', '⋅'),
			_Utils_Tuple2('sdote', '⩦'),
			_Utils_Tuple2('searhk', '⤥'),
			_Utils_Tuple2('searr', '↘'),
			_Utils_Tuple2('seArr', '⇘'),
			_Utils_Tuple2('searrow', '↘'),
			_Utils_Tuple2('sect', '§'),
			_Utils_Tuple2('semi', ';'),
			_Utils_Tuple2('seswar', '⤩'),
			_Utils_Tuple2('setminus', '∖'),
			_Utils_Tuple2('setmn', '∖'),
			_Utils_Tuple2('sext', '✶'),
			_Utils_Tuple2('Sfr', '\uD835\uDD16'),
			_Utils_Tuple2('sfr', '\uD835\uDD30'),
			_Utils_Tuple2('sfrown', '⌢'),
			_Utils_Tuple2('sharp', '♯'),
			_Utils_Tuple2('SHCHcy', 'Щ'),
			_Utils_Tuple2('shchcy', 'щ'),
			_Utils_Tuple2('SHcy', 'Ш'),
			_Utils_Tuple2('shcy', 'ш'),
			_Utils_Tuple2('ShortDownArrow', '↓'),
			_Utils_Tuple2('ShortLeftArrow', '←'),
			_Utils_Tuple2('shortmid', '∣'),
			_Utils_Tuple2('shortparallel', '∥'),
			_Utils_Tuple2('ShortRightArrow', '→'),
			_Utils_Tuple2('ShortUpArrow', '↑'),
			_Utils_Tuple2('shy', '\u00AD'),
			_Utils_Tuple2('Sigma', 'Σ'),
			_Utils_Tuple2('sigma', 'σ'),
			_Utils_Tuple2('sigmaf', 'ς'),
			_Utils_Tuple2('sigmav', 'ς'),
			_Utils_Tuple2('sim', '∼'),
			_Utils_Tuple2('simdot', '⩪'),
			_Utils_Tuple2('sime', '≃'),
			_Utils_Tuple2('simeq', '≃'),
			_Utils_Tuple2('simg', '⪞'),
			_Utils_Tuple2('simgE', '⪠'),
			_Utils_Tuple2('siml', '⪝'),
			_Utils_Tuple2('simlE', '⪟'),
			_Utils_Tuple2('simne', '≆'),
			_Utils_Tuple2('simplus', '⨤'),
			_Utils_Tuple2('simrarr', '⥲'),
			_Utils_Tuple2('slarr', '←'),
			_Utils_Tuple2('SmallCircle', '∘'),
			_Utils_Tuple2('smallsetminus', '∖'),
			_Utils_Tuple2('smashp', '⨳'),
			_Utils_Tuple2('smeparsl', '⧤'),
			_Utils_Tuple2('smid', '∣'),
			_Utils_Tuple2('smile', '⌣'),
			_Utils_Tuple2('smt', '⪪'),
			_Utils_Tuple2('smte', '⪬'),
			_Utils_Tuple2('smtes', '⪬︀'),
			_Utils_Tuple2('SOFTcy', 'Ь'),
			_Utils_Tuple2('softcy', 'ь'),
			_Utils_Tuple2('solbar', '⌿'),
			_Utils_Tuple2('solb', '⧄'),
			_Utils_Tuple2('sol', '/'),
			_Utils_Tuple2('Sopf', '\uD835\uDD4A'),
			_Utils_Tuple2('sopf', '\uD835\uDD64'),
			_Utils_Tuple2('spades', '♠'),
			_Utils_Tuple2('spadesuit', '♠'),
			_Utils_Tuple2('spar', '∥'),
			_Utils_Tuple2('sqcap', '⊓'),
			_Utils_Tuple2('sqcaps', '⊓︀'),
			_Utils_Tuple2('sqcup', '⊔'),
			_Utils_Tuple2('sqcups', '⊔︀'),
			_Utils_Tuple2('Sqrt', '√'),
			_Utils_Tuple2('sqsub', '⊏'),
			_Utils_Tuple2('sqsube', '⊑'),
			_Utils_Tuple2('sqsubset', '⊏'),
			_Utils_Tuple2('sqsubseteq', '⊑'),
			_Utils_Tuple2('sqsup', '⊐'),
			_Utils_Tuple2('sqsupe', '⊒'),
			_Utils_Tuple2('sqsupset', '⊐'),
			_Utils_Tuple2('sqsupseteq', '⊒'),
			_Utils_Tuple2('square', '□'),
			_Utils_Tuple2('Square', '□'),
			_Utils_Tuple2('SquareIntersection', '⊓'),
			_Utils_Tuple2('SquareSubset', '⊏'),
			_Utils_Tuple2('SquareSubsetEqual', '⊑'),
			_Utils_Tuple2('SquareSuperset', '⊐'),
			_Utils_Tuple2('SquareSupersetEqual', '⊒'),
			_Utils_Tuple2('SquareUnion', '⊔'),
			_Utils_Tuple2('squarf', '▪'),
			_Utils_Tuple2('squ', '□'),
			_Utils_Tuple2('squf', '▪'),
			_Utils_Tuple2('srarr', '→'),
			_Utils_Tuple2('Sscr', '\uD835\uDCAE'),
			_Utils_Tuple2('sscr', '\uD835\uDCC8'),
			_Utils_Tuple2('ssetmn', '∖'),
			_Utils_Tuple2('ssmile', '⌣'),
			_Utils_Tuple2('sstarf', '⋆'),
			_Utils_Tuple2('Star', '⋆'),
			_Utils_Tuple2('star', '☆'),
			_Utils_Tuple2('starf', '★'),
			_Utils_Tuple2('straightepsilon', 'ϵ'),
			_Utils_Tuple2('straightphi', 'ϕ'),
			_Utils_Tuple2('strns', '¯'),
			_Utils_Tuple2('sub', '⊂'),
			_Utils_Tuple2('Sub', '⋐'),
			_Utils_Tuple2('subdot', '⪽'),
			_Utils_Tuple2('subE', '⫅'),
			_Utils_Tuple2('sube', '⊆'),
			_Utils_Tuple2('subedot', '⫃'),
			_Utils_Tuple2('submult', '⫁'),
			_Utils_Tuple2('subnE', '⫋'),
			_Utils_Tuple2('subne', '⊊'),
			_Utils_Tuple2('subplus', '⪿'),
			_Utils_Tuple2('subrarr', '⥹'),
			_Utils_Tuple2('subset', '⊂'),
			_Utils_Tuple2('Subset', '⋐'),
			_Utils_Tuple2('subseteq', '⊆'),
			_Utils_Tuple2('subseteqq', '⫅'),
			_Utils_Tuple2('SubsetEqual', '⊆'),
			_Utils_Tuple2('subsetneq', '⊊'),
			_Utils_Tuple2('subsetneqq', '⫋'),
			_Utils_Tuple2('subsim', '⫇'),
			_Utils_Tuple2('subsub', '⫕'),
			_Utils_Tuple2('subsup', '⫓'),
			_Utils_Tuple2('succapprox', '⪸'),
			_Utils_Tuple2('succ', '≻'),
			_Utils_Tuple2('succcurlyeq', '≽'),
			_Utils_Tuple2('Succeeds', '≻'),
			_Utils_Tuple2('SucceedsEqual', '⪰'),
			_Utils_Tuple2('SucceedsSlantEqual', '≽'),
			_Utils_Tuple2('SucceedsTilde', '≿'),
			_Utils_Tuple2('succeq', '⪰'),
			_Utils_Tuple2('succnapprox', '⪺'),
			_Utils_Tuple2('succneqq', '⪶'),
			_Utils_Tuple2('succnsim', '⋩'),
			_Utils_Tuple2('succsim', '≿'),
			_Utils_Tuple2('SuchThat', '∋'),
			_Utils_Tuple2('sum', '∑'),
			_Utils_Tuple2('Sum', '∑'),
			_Utils_Tuple2('sung', '♪'),
			_Utils_Tuple2('sup1', '¹'),
			_Utils_Tuple2('sup2', '²'),
			_Utils_Tuple2('sup3', '³'),
			_Utils_Tuple2('sup', '⊃'),
			_Utils_Tuple2('Sup', '⋑'),
			_Utils_Tuple2('supdot', '⪾'),
			_Utils_Tuple2('supdsub', '⫘'),
			_Utils_Tuple2('supE', '⫆'),
			_Utils_Tuple2('supe', '⊇'),
			_Utils_Tuple2('supedot', '⫄'),
			_Utils_Tuple2('Superset', '⊃'),
			_Utils_Tuple2('SupersetEqual', '⊇'),
			_Utils_Tuple2('suphsol', '⟉'),
			_Utils_Tuple2('suphsub', '⫗'),
			_Utils_Tuple2('suplarr', '⥻'),
			_Utils_Tuple2('supmult', '⫂'),
			_Utils_Tuple2('supnE', '⫌'),
			_Utils_Tuple2('supne', '⊋'),
			_Utils_Tuple2('supplus', '⫀'),
			_Utils_Tuple2('supset', '⊃'),
			_Utils_Tuple2('Supset', '⋑'),
			_Utils_Tuple2('supseteq', '⊇'),
			_Utils_Tuple2('supseteqq', '⫆'),
			_Utils_Tuple2('supsetneq', '⊋'),
			_Utils_Tuple2('supsetneqq', '⫌'),
			_Utils_Tuple2('supsim', '⫈'),
			_Utils_Tuple2('supsub', '⫔'),
			_Utils_Tuple2('supsup', '⫖'),
			_Utils_Tuple2('swarhk', '⤦'),
			_Utils_Tuple2('swarr', '↙'),
			_Utils_Tuple2('swArr', '⇙'),
			_Utils_Tuple2('swarrow', '↙'),
			_Utils_Tuple2('swnwar', '⤪'),
			_Utils_Tuple2('szlig', 'ß'),
			_Utils_Tuple2('Tab', '\t'),
			_Utils_Tuple2('target', '⌖'),
			_Utils_Tuple2('Tau', 'Τ'),
			_Utils_Tuple2('tau', 'τ'),
			_Utils_Tuple2('tbrk', '⎴'),
			_Utils_Tuple2('Tcaron', 'Ť'),
			_Utils_Tuple2('tcaron', 'ť'),
			_Utils_Tuple2('Tcedil', 'Ţ'),
			_Utils_Tuple2('tcedil', 'ţ'),
			_Utils_Tuple2('Tcy', 'Т'),
			_Utils_Tuple2('tcy', 'т'),
			_Utils_Tuple2('tdot', '⃛'),
			_Utils_Tuple2('telrec', '⌕'),
			_Utils_Tuple2('Tfr', '\uD835\uDD17'),
			_Utils_Tuple2('tfr', '\uD835\uDD31'),
			_Utils_Tuple2('there4', '∴'),
			_Utils_Tuple2('therefore', '∴'),
			_Utils_Tuple2('Therefore', '∴'),
			_Utils_Tuple2('Theta', 'Θ'),
			_Utils_Tuple2('theta', 'θ'),
			_Utils_Tuple2('thetasym', 'ϑ'),
			_Utils_Tuple2('thetav', 'ϑ'),
			_Utils_Tuple2('thickapprox', '≈'),
			_Utils_Tuple2('thicksim', '∼'),
			_Utils_Tuple2('ThickSpace', '\u205F\u200A'),
			_Utils_Tuple2('ThinSpace', '\u2009'),
			_Utils_Tuple2('thinsp', '\u2009'),
			_Utils_Tuple2('thkap', '≈'),
			_Utils_Tuple2('thksim', '∼'),
			_Utils_Tuple2('THORN', 'Þ'),
			_Utils_Tuple2('thorn', 'þ'),
			_Utils_Tuple2('tilde', '˜'),
			_Utils_Tuple2('Tilde', '∼'),
			_Utils_Tuple2('TildeEqual', '≃'),
			_Utils_Tuple2('TildeFullEqual', '≅'),
			_Utils_Tuple2('TildeTilde', '≈'),
			_Utils_Tuple2('timesbar', '⨱'),
			_Utils_Tuple2('timesb', '⊠'),
			_Utils_Tuple2('times', '×'),
			_Utils_Tuple2('timesd', '⨰'),
			_Utils_Tuple2('tint', '∭'),
			_Utils_Tuple2('toea', '⤨'),
			_Utils_Tuple2('topbot', '⌶'),
			_Utils_Tuple2('topcir', '⫱'),
			_Utils_Tuple2('top', '⊤'),
			_Utils_Tuple2('Topf', '\uD835\uDD4B'),
			_Utils_Tuple2('topf', '\uD835\uDD65'),
			_Utils_Tuple2('topfork', '⫚'),
			_Utils_Tuple2('tosa', '⤩'),
			_Utils_Tuple2('tprime', '‴'),
			_Utils_Tuple2('trade', '™'),
			_Utils_Tuple2('TRADE', '™'),
			_Utils_Tuple2('triangle', '▵'),
			_Utils_Tuple2('triangledown', '▿'),
			_Utils_Tuple2('triangleleft', '◃'),
			_Utils_Tuple2('trianglelefteq', '⊴'),
			_Utils_Tuple2('triangleq', '≜'),
			_Utils_Tuple2('triangleright', '▹'),
			_Utils_Tuple2('trianglerighteq', '⊵'),
			_Utils_Tuple2('tridot', '◬'),
			_Utils_Tuple2('trie', '≜'),
			_Utils_Tuple2('triminus', '⨺'),
			_Utils_Tuple2('TripleDot', '⃛'),
			_Utils_Tuple2('triplus', '⨹'),
			_Utils_Tuple2('trisb', '⧍'),
			_Utils_Tuple2('tritime', '⨻'),
			_Utils_Tuple2('trpezium', '⏢'),
			_Utils_Tuple2('Tscr', '\uD835\uDCAF'),
			_Utils_Tuple2('tscr', '\uD835\uDCC9'),
			_Utils_Tuple2('TScy', 'Ц'),
			_Utils_Tuple2('tscy', 'ц'),
			_Utils_Tuple2('TSHcy', 'Ћ'),
			_Utils_Tuple2('tshcy', 'ћ'),
			_Utils_Tuple2('Tstrok', 'Ŧ'),
			_Utils_Tuple2('tstrok', 'ŧ'),
			_Utils_Tuple2('twixt', '≬'),
			_Utils_Tuple2('twoheadleftarrow', '↞'),
			_Utils_Tuple2('twoheadrightarrow', '↠'),
			_Utils_Tuple2('Uacute', 'Ú'),
			_Utils_Tuple2('uacute', 'ú'),
			_Utils_Tuple2('uarr', '↑'),
			_Utils_Tuple2('Uarr', '↟'),
			_Utils_Tuple2('uArr', '⇑'),
			_Utils_Tuple2('Uarrocir', '⥉'),
			_Utils_Tuple2('Ubrcy', 'Ў'),
			_Utils_Tuple2('ubrcy', 'ў'),
			_Utils_Tuple2('Ubreve', 'Ŭ'),
			_Utils_Tuple2('ubreve', 'ŭ'),
			_Utils_Tuple2('Ucirc', 'Û'),
			_Utils_Tuple2('ucirc', 'û'),
			_Utils_Tuple2('Ucy', 'У'),
			_Utils_Tuple2('ucy', 'у'),
			_Utils_Tuple2('udarr', '⇅'),
			_Utils_Tuple2('Udblac', 'Ű'),
			_Utils_Tuple2('udblac', 'ű'),
			_Utils_Tuple2('udhar', '⥮'),
			_Utils_Tuple2('ufisht', '⥾'),
			_Utils_Tuple2('Ufr', '\uD835\uDD18'),
			_Utils_Tuple2('ufr', '\uD835\uDD32'),
			_Utils_Tuple2('Ugrave', 'Ù'),
			_Utils_Tuple2('ugrave', 'ù'),
			_Utils_Tuple2('uHar', '⥣'),
			_Utils_Tuple2('uharl', '↿'),
			_Utils_Tuple2('uharr', '↾'),
			_Utils_Tuple2('uhblk', '▀'),
			_Utils_Tuple2('ulcorn', '⌜'),
			_Utils_Tuple2('ulcorner', '⌜'),
			_Utils_Tuple2('ulcrop', '⌏'),
			_Utils_Tuple2('ultri', '◸'),
			_Utils_Tuple2('Umacr', 'Ū'),
			_Utils_Tuple2('umacr', 'ū'),
			_Utils_Tuple2('uml', '¨'),
			_Utils_Tuple2('UnderBar', '_'),
			_Utils_Tuple2('UnderBrace', '⏟'),
			_Utils_Tuple2('UnderBracket', '⎵'),
			_Utils_Tuple2('UnderParenthesis', '⏝'),
			_Utils_Tuple2('Union', '⋃'),
			_Utils_Tuple2('UnionPlus', '⊎'),
			_Utils_Tuple2('Uogon', 'Ų'),
			_Utils_Tuple2('uogon', 'ų'),
			_Utils_Tuple2('Uopf', '\uD835\uDD4C'),
			_Utils_Tuple2('uopf', '\uD835\uDD66'),
			_Utils_Tuple2('UpArrowBar', '⤒'),
			_Utils_Tuple2('uparrow', '↑'),
			_Utils_Tuple2('UpArrow', '↑'),
			_Utils_Tuple2('Uparrow', '⇑'),
			_Utils_Tuple2('UpArrowDownArrow', '⇅'),
			_Utils_Tuple2('updownarrow', '↕'),
			_Utils_Tuple2('UpDownArrow', '↕'),
			_Utils_Tuple2('Updownarrow', '⇕'),
			_Utils_Tuple2('UpEquilibrium', '⥮'),
			_Utils_Tuple2('upharpoonleft', '↿'),
			_Utils_Tuple2('upharpoonright', '↾'),
			_Utils_Tuple2('uplus', '⊎'),
			_Utils_Tuple2('UpperLeftArrow', '↖'),
			_Utils_Tuple2('UpperRightArrow', '↗'),
			_Utils_Tuple2('upsi', 'υ'),
			_Utils_Tuple2('Upsi', 'ϒ'),
			_Utils_Tuple2('upsih', 'ϒ'),
			_Utils_Tuple2('Upsilon', 'Υ'),
			_Utils_Tuple2('upsilon', 'υ'),
			_Utils_Tuple2('UpTeeArrow', '↥'),
			_Utils_Tuple2('UpTee', '⊥'),
			_Utils_Tuple2('upuparrows', '⇈'),
			_Utils_Tuple2('urcorn', '⌝'),
			_Utils_Tuple2('urcorner', '⌝'),
			_Utils_Tuple2('urcrop', '⌎'),
			_Utils_Tuple2('Uring', 'Ů'),
			_Utils_Tuple2('uring', 'ů'),
			_Utils_Tuple2('urtri', '◹'),
			_Utils_Tuple2('Uscr', '\uD835\uDCB0'),
			_Utils_Tuple2('uscr', '\uD835\uDCCA'),
			_Utils_Tuple2('utdot', '⋰'),
			_Utils_Tuple2('Utilde', 'Ũ'),
			_Utils_Tuple2('utilde', 'ũ'),
			_Utils_Tuple2('utri', '▵'),
			_Utils_Tuple2('utrif', '▴'),
			_Utils_Tuple2('uuarr', '⇈'),
			_Utils_Tuple2('Uuml', 'Ü'),
			_Utils_Tuple2('uuml', 'ü'),
			_Utils_Tuple2('uwangle', '⦧'),
			_Utils_Tuple2('vangrt', '⦜'),
			_Utils_Tuple2('varepsilon', 'ϵ'),
			_Utils_Tuple2('varkappa', 'ϰ'),
			_Utils_Tuple2('varnothing', '∅'),
			_Utils_Tuple2('varphi', 'ϕ'),
			_Utils_Tuple2('varpi', 'ϖ'),
			_Utils_Tuple2('varpropto', '∝'),
			_Utils_Tuple2('varr', '↕'),
			_Utils_Tuple2('vArr', '⇕'),
			_Utils_Tuple2('varrho', 'ϱ'),
			_Utils_Tuple2('varsigma', 'ς'),
			_Utils_Tuple2('varsubsetneq', '⊊︀'),
			_Utils_Tuple2('varsubsetneqq', '⫋︀'),
			_Utils_Tuple2('varsupsetneq', '⊋︀'),
			_Utils_Tuple2('varsupsetneqq', '⫌︀'),
			_Utils_Tuple2('vartheta', 'ϑ'),
			_Utils_Tuple2('vartriangleleft', '⊲'),
			_Utils_Tuple2('vartriangleright', '⊳'),
			_Utils_Tuple2('vBar', '⫨'),
			_Utils_Tuple2('Vbar', '⫫'),
			_Utils_Tuple2('vBarv', '⫩'),
			_Utils_Tuple2('Vcy', 'В'),
			_Utils_Tuple2('vcy', 'в'),
			_Utils_Tuple2('vdash', '⊢'),
			_Utils_Tuple2('vDash', '⊨'),
			_Utils_Tuple2('Vdash', '⊩'),
			_Utils_Tuple2('VDash', '⊫'),
			_Utils_Tuple2('Vdashl', '⫦'),
			_Utils_Tuple2('veebar', '⊻'),
			_Utils_Tuple2('vee', '∨'),
			_Utils_Tuple2('Vee', '⋁'),
			_Utils_Tuple2('veeeq', '≚'),
			_Utils_Tuple2('vellip', '⋮'),
			_Utils_Tuple2('verbar', '|'),
			_Utils_Tuple2('Verbar', '‖'),
			_Utils_Tuple2('vert', '|'),
			_Utils_Tuple2('Vert', '‖'),
			_Utils_Tuple2('VerticalBar', '∣'),
			_Utils_Tuple2('VerticalLine', '|'),
			_Utils_Tuple2('VerticalSeparator', '❘'),
			_Utils_Tuple2('VerticalTilde', '≀'),
			_Utils_Tuple2('VeryThinSpace', '\u200A'),
			_Utils_Tuple2('Vfr', '\uD835\uDD19'),
			_Utils_Tuple2('vfr', '\uD835\uDD33'),
			_Utils_Tuple2('vltri', '⊲'),
			_Utils_Tuple2('vnsub', '⊂⃒'),
			_Utils_Tuple2('vnsup', '⊃⃒'),
			_Utils_Tuple2('Vopf', '\uD835\uDD4D'),
			_Utils_Tuple2('vopf', '\uD835\uDD67'),
			_Utils_Tuple2('vprop', '∝'),
			_Utils_Tuple2('vrtri', '⊳'),
			_Utils_Tuple2('Vscr', '\uD835\uDCB1'),
			_Utils_Tuple2('vscr', '\uD835\uDCCB'),
			_Utils_Tuple2('vsubnE', '⫋︀'),
			_Utils_Tuple2('vsubne', '⊊︀'),
			_Utils_Tuple2('vsupnE', '⫌︀'),
			_Utils_Tuple2('vsupne', '⊋︀'),
			_Utils_Tuple2('Vvdash', '⊪'),
			_Utils_Tuple2('vzigzag', '⦚'),
			_Utils_Tuple2('Wcirc', 'Ŵ'),
			_Utils_Tuple2('wcirc', 'ŵ'),
			_Utils_Tuple2('wedbar', '⩟'),
			_Utils_Tuple2('wedge', '∧'),
			_Utils_Tuple2('Wedge', '⋀'),
			_Utils_Tuple2('wedgeq', '≙'),
			_Utils_Tuple2('weierp', '℘'),
			_Utils_Tuple2('Wfr', '\uD835\uDD1A'),
			_Utils_Tuple2('wfr', '\uD835\uDD34'),
			_Utils_Tuple2('Wopf', '\uD835\uDD4E'),
			_Utils_Tuple2('wopf', '\uD835\uDD68'),
			_Utils_Tuple2('wp', '℘'),
			_Utils_Tuple2('wr', '≀'),
			_Utils_Tuple2('wreath', '≀'),
			_Utils_Tuple2('Wscr', '\uD835\uDCB2'),
			_Utils_Tuple2('wscr', '\uD835\uDCCC'),
			_Utils_Tuple2('xcap', '⋂'),
			_Utils_Tuple2('xcirc', '◯'),
			_Utils_Tuple2('xcup', '⋃'),
			_Utils_Tuple2('xdtri', '▽'),
			_Utils_Tuple2('Xfr', '\uD835\uDD1B'),
			_Utils_Tuple2('xfr', '\uD835\uDD35'),
			_Utils_Tuple2('xharr', '⟷'),
			_Utils_Tuple2('xhArr', '⟺'),
			_Utils_Tuple2('Xi', 'Ξ'),
			_Utils_Tuple2('xi', 'ξ'),
			_Utils_Tuple2('xlarr', '⟵'),
			_Utils_Tuple2('xlArr', '⟸'),
			_Utils_Tuple2('xmap', '⟼'),
			_Utils_Tuple2('xnis', '⋻'),
			_Utils_Tuple2('xodot', '⨀'),
			_Utils_Tuple2('Xopf', '\uD835\uDD4F'),
			_Utils_Tuple2('xopf', '\uD835\uDD69'),
			_Utils_Tuple2('xoplus', '⨁'),
			_Utils_Tuple2('xotime', '⨂'),
			_Utils_Tuple2('xrarr', '⟶'),
			_Utils_Tuple2('xrArr', '⟹'),
			_Utils_Tuple2('Xscr', '\uD835\uDCB3'),
			_Utils_Tuple2('xscr', '\uD835\uDCCD'),
			_Utils_Tuple2('xsqcup', '⨆'),
			_Utils_Tuple2('xuplus', '⨄'),
			_Utils_Tuple2('xutri', '△'),
			_Utils_Tuple2('xvee', '⋁'),
			_Utils_Tuple2('xwedge', '⋀'),
			_Utils_Tuple2('Yacute', 'Ý'),
			_Utils_Tuple2('yacute', 'ý'),
			_Utils_Tuple2('YAcy', 'Я'),
			_Utils_Tuple2('yacy', 'я'),
			_Utils_Tuple2('Ycirc', 'Ŷ'),
			_Utils_Tuple2('ycirc', 'ŷ'),
			_Utils_Tuple2('Ycy', 'Ы'),
			_Utils_Tuple2('ycy', 'ы'),
			_Utils_Tuple2('yen', '¥'),
			_Utils_Tuple2('Yfr', '\uD835\uDD1C'),
			_Utils_Tuple2('yfr', '\uD835\uDD36'),
			_Utils_Tuple2('YIcy', 'Ї'),
			_Utils_Tuple2('yicy', 'ї'),
			_Utils_Tuple2('Yopf', '\uD835\uDD50'),
			_Utils_Tuple2('yopf', '\uD835\uDD6A'),
			_Utils_Tuple2('Yscr', '\uD835\uDCB4'),
			_Utils_Tuple2('yscr', '\uD835\uDCCE'),
			_Utils_Tuple2('YUcy', 'Ю'),
			_Utils_Tuple2('yucy', 'ю'),
			_Utils_Tuple2('yuml', 'ÿ'),
			_Utils_Tuple2('Yuml', 'Ÿ'),
			_Utils_Tuple2('Zacute', 'Ź'),
			_Utils_Tuple2('zacute', 'ź'),
			_Utils_Tuple2('Zcaron', 'Ž'),
			_Utils_Tuple2('zcaron', 'ž'),
			_Utils_Tuple2('Zcy', 'З'),
			_Utils_Tuple2('zcy', 'з'),
			_Utils_Tuple2('Zdot', 'Ż'),
			_Utils_Tuple2('zdot', 'ż'),
			_Utils_Tuple2('zeetrf', 'ℨ'),
			_Utils_Tuple2('ZeroWidthSpace', '\u200B'),
			_Utils_Tuple2('Zeta', 'Ζ'),
			_Utils_Tuple2('zeta', 'ζ'),
			_Utils_Tuple2('zfr', '\uD835\uDD37'),
			_Utils_Tuple2('Zfr', 'ℨ'),
			_Utils_Tuple2('ZHcy', 'Ж'),
			_Utils_Tuple2('zhcy', 'ж'),
			_Utils_Tuple2('zigrarr', '⇝'),
			_Utils_Tuple2('zopf', '\uD835\uDD6B'),
			_Utils_Tuple2('Zopf', 'ℤ'),
			_Utils_Tuple2('Zscr', '\uD835\uDCB5'),
			_Utils_Tuple2('zscr', '\uD835\uDCCF'),
			_Utils_Tuple2('zwj', '\u200D'),
			_Utils_Tuple2('zwnj', '\u200C')
		]));
var $author$project$Html$Parser$namedCharacterReference = A2(
	$elm$parser$Parser$map,
	function (reference) {
		return A2(
			$elm$core$Maybe$withDefault,
			'&' + (reference + ';'),
			A2($elm$core$Dict$get, reference, $author$project$Html$Parser$NamedCharacterReferences$dict));
	},
	$elm$parser$Parser$getChompedString(
		$author$project$Html$Parser$chompOneOrMore($elm$core$Char$isAlpha)));
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Char$fromCode = _Char_fromCode;
var $elm$core$Basics$pow = _Basics_pow;
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $elm$core$Char$isHexDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return ((48 <= code) && (code <= 57)) || (((65 <= code) && (code <= 70)) || ((97 <= code) && (code <= 102)));
};
var $author$project$Html$Parser$hexadecimal = A2(
	$elm$parser$Parser$andThen,
	function (hex) {
		var _v0 = $rtfeldman$elm_hex$Hex$fromString(
			$elm$core$String$toLower(hex));
		if (!_v0.$) {
			var value = _v0.a;
			return $elm$parser$Parser$succeed(value);
		} else {
			var error = _v0.a;
			return $elm$parser$Parser$problem(error);
		}
	},
	$elm$parser$Parser$getChompedString(
		$author$project$Html$Parser$chompOneOrMore($elm$core$Char$isHexDigit)));
var $elm$parser$Parser$ExpectingInt = {$: 1};
var $elm$parser$Parser$Advanced$consumeBase = _Parser_consumeBase;
var $elm$parser$Parser$Advanced$consumeBase16 = _Parser_consumeBase16;
var $elm$parser$Parser$Advanced$bumpOffset = F2(
	function (newOffset, s) {
		return {kB: s.kB + (newOffset - s.b), kL: s.kL, h: s.h, b: newOffset, n$: s.n$, ov: s.ov};
	});
var $elm$parser$Parser$Advanced$chompBase10 = _Parser_chompBase10;
var $elm$parser$Parser$Advanced$isAsciiCode = _Parser_isAsciiCode;
var $elm$parser$Parser$Advanced$consumeExp = F2(
	function (offset, src) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 101, offset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 69, offset, src)) {
			var eOffset = offset + 1;
			var expOffset = (A3($elm$parser$Parser$Advanced$isAsciiCode, 43, eOffset, src) || A3($elm$parser$Parser$Advanced$isAsciiCode, 45, eOffset, src)) ? (eOffset + 1) : eOffset;
			var newOffset = A2($elm$parser$Parser$Advanced$chompBase10, expOffset, src);
			return _Utils_eq(expOffset, newOffset) ? (-newOffset) : newOffset;
		} else {
			return offset;
		}
	});
var $elm$parser$Parser$Advanced$consumeDotAndExp = F2(
	function (offset, src) {
		return A3($elm$parser$Parser$Advanced$isAsciiCode, 46, offset, src) ? A2(
			$elm$parser$Parser$Advanced$consumeExp,
			A2($elm$parser$Parser$Advanced$chompBase10, offset + 1, src),
			src) : A2($elm$parser$Parser$Advanced$consumeExp, offset, src);
	});
var $elm$parser$Parser$Advanced$finalizeInt = F5(
	function (invalid, handler, startOffset, _v0, s) {
		var endOffset = _v0.a;
		var n = _v0.b;
		if (handler.$ === 1) {
			var x = handler.a;
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A2($elm$parser$Parser$Advanced$fromState, s, x));
		} else {
			var toValue = handler.a;
			return _Utils_eq(startOffset, endOffset) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				_Utils_cmp(s.b, startOffset) < 0,
				A2($elm$parser$Parser$Advanced$fromState, s, invalid)) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				toValue(n),
				A2($elm$parser$Parser$Advanced$bumpOffset, endOffset, s));
		}
	});
var $elm$core$String$toFloat = _String_toFloat;
var $elm$parser$Parser$Advanced$finalizeFloat = F6(
	function (invalid, expecting, intSettings, floatSettings, intPair, s) {
		var intOffset = intPair.a;
		var floatOffset = A2($elm$parser$Parser$Advanced$consumeDotAndExp, intOffset, s.ov);
		if (floatOffset < 0) {
			return A2(
				$elm$parser$Parser$Advanced$Bad,
				true,
				A4($elm$parser$Parser$Advanced$fromInfo, s.n$, s.kB - (floatOffset + s.b), invalid, s.kL));
		} else {
			if (_Utils_eq(s.b, floatOffset)) {
				return A2(
					$elm$parser$Parser$Advanced$Bad,
					false,
					A2($elm$parser$Parser$Advanced$fromState, s, expecting));
			} else {
				if (_Utils_eq(intOffset, floatOffset)) {
					return A5($elm$parser$Parser$Advanced$finalizeInt, invalid, intSettings, s.b, intPair, s);
				} else {
					if (floatSettings.$ === 1) {
						var x = floatSettings.a;
						return A2(
							$elm$parser$Parser$Advanced$Bad,
							true,
							A2($elm$parser$Parser$Advanced$fromState, s, invalid));
					} else {
						var toValue = floatSettings.a;
						var _v1 = $elm$core$String$toFloat(
							A3($elm$core$String$slice, s.b, floatOffset, s.ov));
						if (_v1.$ === 1) {
							return A2(
								$elm$parser$Parser$Advanced$Bad,
								true,
								A2($elm$parser$Parser$Advanced$fromState, s, invalid));
						} else {
							var n = _v1.a;
							return A3(
								$elm$parser$Parser$Advanced$Good,
								true,
								toValue(n),
								A2($elm$parser$Parser$Advanced$bumpOffset, floatOffset, s));
						}
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$number = function (c) {
	return function (s) {
		if (A3($elm$parser$Parser$Advanced$isAsciiCode, 48, s.b, s.ov)) {
			var zeroOffset = s.b + 1;
			var baseOffset = zeroOffset + 1;
			return A3($elm$parser$Parser$Advanced$isAsciiCode, 120, zeroOffset, s.ov) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.mc,
				c.hh,
				baseOffset,
				A2($elm$parser$Parser$Advanced$consumeBase16, baseOffset, s.ov),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 111, zeroOffset, s.ov) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.mc,
				c.hV,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 8, baseOffset, s.ov),
				s) : (A3($elm$parser$Parser$Advanced$isAsciiCode, 98, zeroOffset, s.ov) ? A5(
				$elm$parser$Parser$Advanced$finalizeInt,
				c.mc,
				c.gm,
				baseOffset,
				A3($elm$parser$Parser$Advanced$consumeBase, 2, baseOffset, s.ov),
				s) : A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.mc,
				c.g1,
				c.hq,
				c.g4,
				_Utils_Tuple2(zeroOffset, 0),
				s)));
		} else {
			return A6(
				$elm$parser$Parser$Advanced$finalizeFloat,
				c.mc,
				c.g1,
				c.hq,
				c.g4,
				A3($elm$parser$Parser$Advanced$consumeBase, 10, s.b, s.ov),
				s);
		}
	};
};
var $elm$parser$Parser$Advanced$int = F2(
	function (expecting, invalid) {
		return $elm$parser$Parser$Advanced$number(
			{
				gm: $elm$core$Result$Err(invalid),
				g1: expecting,
				g4: $elm$core$Result$Err(invalid),
				hh: $elm$core$Result$Err(invalid),
				hq: $elm$core$Result$Ok($elm$core$Basics$identity),
				mc: invalid,
				hV: $elm$core$Result$Err(invalid)
			});
	});
var $elm$parser$Parser$int = A2($elm$parser$Parser$Advanced$int, $elm$parser$Parser$ExpectingInt, $elm$parser$Parser$ExpectingInt);
var $author$project$Html$Parser$numericCharacterReference = function () {
	var codepoint = $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						function (c) {
							return (c === 'x') || (c === 'X');
						})),
				$author$project$Html$Parser$hexadecimal),
				A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq('0'))),
				$elm$parser$Parser$int)
			]));
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq('#'))),
		A2(
			$elm$parser$Parser$map,
			A2($elm$core$Basics$composeR, $elm$core$Char$fromCode, $elm$core$String$fromChar),
			codepoint));
}();
var $author$project$Html$Parser$characterReference = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('&'))),
	$elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($author$project$Html$Parser$namedCharacterReference),
				$author$project$Html$Parser$chompSemicolon),
				A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$backtrackable($author$project$Html$Parser$numericCharacterReference),
				$author$project$Html$Parser$chompSemicolon),
				$elm$parser$Parser$succeed('&')
			])));
var $author$project$Html$Parser$tagAttributeQuotedValue = function (quote) {
	var isQuotedValueChar = function (c) {
		return (!_Utils_eq(c, quote)) && (c !== '&');
	};
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))),
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$map,
				$elm$core$String$join(''),
				$author$project$Html$Parser$many(
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$getChompedString(
								$author$project$Html$Parser$chompOneOrMore(isQuotedValueChar)),
								$author$project$Html$Parser$characterReference
							])))),
			$elm$parser$Parser$chompIf(
				$elm$core$Basics$eq(quote))));
};
var $author$project$Html$Parser$oneOrMore = F2(
	function (type_, parser_) {
		return A2(
			$elm$parser$Parser$loop,
			_List_Nil,
			function (list) {
				return $elm$parser$Parser$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$parser$Parser$map,
							function (_new) {
								return $elm$parser$Parser$Loop(
									A2($elm$core$List$cons, _new, list));
							},
							parser_),
							$elm$core$List$isEmpty(list) ? $elm$parser$Parser$problem('expecting at least one ' + type_) : $elm$parser$Parser$succeed(
							$elm$parser$Parser$Done(
								$elm$core$List$reverse(list)))
						]));
			});
	});
var $author$project$Html$Parser$tagAttributeUnquotedValue = function () {
	var isUnquotedValueChar = function (c) {
		return (!$author$project$Html$Parser$isSpaceCharacter(c)) && ((c !== '\"') && ((c !== '\'') && ((c !== '=') && ((c !== '<') && ((c !== '>') && ((c !== '`') && (c !== '&')))))));
	};
	return A2(
		$elm$parser$Parser$map,
		$elm$core$String$join(''),
		A2(
			$author$project$Html$Parser$oneOrMore,
			'attribute value',
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$getChompedString(
						$author$project$Html$Parser$chompOneOrMore(isUnquotedValueChar)),
						$author$project$Html$Parser$characterReference
					]))));
}();
var $author$project$Html$Parser$tagAttributeValue = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Basics$identity),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('='))),
				$elm$parser$Parser$chompWhile($author$project$Html$Parser$isSpaceCharacter)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$author$project$Html$Parser$tagAttributeUnquotedValue,
						$author$project$Html$Parser$tagAttributeQuotedValue('\"'),
						$author$project$Html$Parser$tagAttributeQuotedValue('\'')
					]))),
			$elm$parser$Parser$succeed('')
		]));
var $author$project$Html$Parser$tagAttribute = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Tuple$pair),
		A2(
			$elm$parser$Parser$ignorer,
			$author$project$Html$Parser$tagAttributeName,
			$elm$parser$Parser$chompWhile($author$project$Html$Parser$isSpaceCharacter))),
	A2(
		$elm$parser$Parser$ignorer,
		$author$project$Html$Parser$tagAttributeValue,
		$elm$parser$Parser$chompWhile($author$project$Html$Parser$isSpaceCharacter)));
var $author$project$Html$Parser$tagAttributes = $author$project$Html$Parser$many($author$project$Html$Parser$tagAttribute);
var $author$project$Html$Parser$tagName = A2(
	$elm$parser$Parser$map,
	$elm$core$String$toLower,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$chompIf($elm$core$Char$isAlphaNum),
			$elm$parser$Parser$chompWhile(
				function (c) {
					return $elm$core$Char$isAlphaNum(c) || (c === '-');
				}))));
var $author$project$Html$Parser$Text = function (a) {
	return {$: 0, a: a};
};
var $author$project$Html$Parser$text = A2(
	$elm$parser$Parser$map,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$join(''),
		$author$project$Html$Parser$Text),
	A2(
		$author$project$Html$Parser$oneOrMore,
		'text element',
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$getChompedString(
					$author$project$Html$Parser$chompOneOrMore(
						function (c) {
							return (c !== '<') && (c !== '&');
						})),
					$author$project$Html$Parser$characterReference
				]))));
function $author$project$Html$Parser$cyclic$node() {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				$author$project$Html$Parser$text,
				$author$project$Html$Parser$comment,
				$author$project$Html$Parser$cyclic$element()
			]));
}
function $author$project$Html$Parser$cyclic$element() {
	return A2(
		$elm$parser$Parser$andThen,
		function (_v0) {
			var name = _v0.a;
			var attributes = _v0.b;
			return $author$project$Html$Parser$isVoidElement(name) ? A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A3($author$project$Html$Parser$Element, name, attributes, _List_Nil)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$elm$parser$Parser$chompIf(
								$elm$core$Basics$eq('/')),
								$elm$parser$Parser$succeed(0)
							]))),
				$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('>'))) : A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A2($author$project$Html$Parser$Element, name, attributes)),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('>'))),
				A2(
					$elm$parser$Parser$ignorer,
					$author$project$Html$Parser$many(
						$elm$parser$Parser$backtrackable(
							$author$project$Html$Parser$cyclic$node())),
					$author$project$Html$Parser$closingTag(name)));
		},
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($elm$core$Tuple$pair),
					$elm$parser$Parser$chompIf(
						$elm$core$Basics$eq('<'))),
				A2(
					$elm$parser$Parser$ignorer,
					$author$project$Html$Parser$tagName,
					$elm$parser$Parser$chompWhile($author$project$Html$Parser$isSpaceCharacter))),
			$author$project$Html$Parser$tagAttributes));
}
var $author$project$Html$Parser$node = $author$project$Html$Parser$cyclic$node();
$author$project$Html$Parser$cyclic$node = function () {
	return $author$project$Html$Parser$node;
};
var $author$project$Html$Parser$element = $author$project$Html$Parser$cyclic$element();
$author$project$Html$Parser$cyclic$element = function () {
	return $author$project$Html$Parser$element;
};
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {kB: col, nA: problem, n$: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.n$, p.kB, p.nA);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{kB: 1, kL: _List_Nil, h: 1, b: 0, n$: 1, ov: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $author$project$Html$Parser$run = function (str) {
	return $elm$core$String$isEmpty(str) ? $elm$core$Result$Ok(_List_Nil) : A2(
		$elm$parser$Parser$run,
		A2($author$project$Html$Parser$oneOrMore, 'node', $author$project$Html$Parser$node),
		str);
};
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Type$Variant$HtmlStringedVariantContent = F4(
	function (variantId, qContent, wsContent, aSet) {
		return {jB: aSet, nE: qContent, pK: variantId, pQ: wsContent};
	});
var $author$project$Type$Variant$HtmlStringedVariantAnswerSet_ANSBOX = function (a) {
	return {$: 2, a: a};
};
var $author$project$Type$Variant$HtmlStringedVariantAnswerSet_MCMA = function (a) {
	return {$: 1, a: a};
};
var $author$project$Type$Variant$HtmlStringedVariantAnswerSet_MCSA = function (a) {
	return {$: 0, a: a};
};
var $author$project$Type$Variant$HtmlStringedVariantAnswerSet_NOT_IMPLEMENTED = {$: 3};
var $author$project$Type$Variant$VariantAnswer_AnsBoxRec = F4(
	function (correctAnswer, prefix, suffix, width) {
		return {kO: correctAnswer, nx: prefix, oI: suffix, ec: width};
	});
var $elm$json$Json$Decode$map4 = _Json_map4;
var $author$project$Type$Variant$answerBoxDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Type$Variant$VariantAnswer_AnsBoxRec,
	A2($elm$json$Json$Decode$field, 'correctAnswer', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'prefix', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'suffix', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'width', $elm$json$Json$Decode$int));
var $author$project$Type$Variant$HtmlStringedVariantAnswer_MCRec = F2(
	function (answer, isCorrect) {
		return {bY: answer, dF: isCorrect};
	});
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $author$project$Type$Variant$answerMultipleChoiceDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Type$Variant$HtmlStringedVariantAnswer_MCRec,
	A2($elm$json$Json$Decode$field, 'answer', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'iscorrect',
		$elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					$elm$json$Json$Decode$bool,
					$elm$json$Json$Decode$null(false)
				]))));
var $author$project$Type$Variant$answerSetDecoder = function (questionType) {
	switch (questionType) {
		case 0:
			return A2(
				$elm$json$Json$Decode$map,
				$author$project$Type$Variant$HtmlStringedVariantAnswerSet_MCSA,
				$elm$json$Json$Decode$list($author$project$Type$Variant$answerMultipleChoiceDecoder));
		case 1:
			return A2(
				$elm$json$Json$Decode$map,
				$author$project$Type$Variant$HtmlStringedVariantAnswerSet_MCMA,
				$elm$json$Json$Decode$list($author$project$Type$Variant$answerMultipleChoiceDecoder));
		case 2:
			return A2(
				$elm$json$Json$Decode$map,
				$author$project$Type$Variant$HtmlStringedVariantAnswerSet_ANSBOX,
				$elm$json$Json$Decode$list($author$project$Type$Variant$answerBoxDecoder));
		default:
			return $elm$json$Json$Decode$succeed($author$project$Type$Variant$HtmlStringedVariantAnswerSet_NOT_IMPLEMENTED);
	}
};
var $author$project$Type$Variant$ANSBOX = 2;
var $author$project$Type$Variant$MCMA = 1;
var $author$project$Type$Variant$MCSA = 0;
var $author$project$Type$Variant$NOT_IMPLEMENTED = 3;
var $author$project$Type$Variant$questionTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (nType) {
		switch (nType) {
			case 'Multiple Choice (One Answer)':
				return $elm$json$Json$Decode$succeed(0);
			case 'Multiple Choice (Multiple Answer)':
				return $elm$json$Json$Decode$succeed(1);
			case 'Answer Box':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$succeed(3);
		}
	},
	A2($elm$json$Json$Decode$field, 'qType', $elm$json$Json$Decode$string));
var $author$project$Type$Variant$VariantId = F2(
	function (smuid, vId) {
		return {oq: smuid, c1: vId};
	});
var $author$project$Type$Variant$variantIdDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Type$Variant$VariantId,
	A2($elm$json$Json$Decode$field, 'smuid', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'vId', $elm$json$Json$Decode$int));
var $author$project$Type$Variant$variantContentDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (questionType) {
		return A5(
			$elm$json$Json$Decode$map4,
			$author$project$Type$Variant$HtmlStringedVariantContent,
			$author$project$Type$Variant$variantIdDecoder,
			A2($elm$json$Json$Decode$field, 'qContent', $elm$json$Json$Decode$string),
			A2($elm$json$Json$Decode$field, 'wsContent', $elm$json$Json$Decode$string),
			A2(
				$elm$json$Json$Decode$field,
				'aSet',
				$author$project$Type$Variant$answerSetDecoder(questionType)));
	},
	$author$project$Type$Variant$questionTypeDecoder);
var $author$project$Subscriptions$decodeDecryptItemResponse = function (resp) {
	var partialResult = A2(
		$elm$core$Result$mapError,
		$elm$json$Json$Decode$errorToString,
		A2($elm$json$Json$Decode$decodeValue, $author$project$Type$Variant$variantContentDecoder, resp.k4));
	var parseToNodes = function (htmlStringedVariantContent) {
		var parseToResult = function (inStr) {
			return A2(
				$elm$core$Result$mapError,
				$author$project$Type$Error$deadEndsToString,
				$author$project$Html$Parser$run(inStr));
		};
		var qContentResult = parseToResult(htmlStringedVariantContent.nE);
		var wsContentResult = parseToResult(htmlStringedVariantContent.pQ);
		var makeMappedASetMc = function (aSetMc) {
			return A2(
				$elm$core$List$map,
				function (el) {
					return {
						bY: parseToResult(el.bY),
						dF: el.dF
					};
				},
				aSetMc);
		};
		var isSuccess = function (resVal) {
			if (!resVal.$) {
				return true;
			} else {
				return false;
			}
		};
		var aSetResult = function () {
			var handleAnswerSetMultipleChoice = F2(
				function (typeConstructor, aSetMc) {
					var _v4 = A2(
						$elm$core$List$partition,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.bY;
							},
							isSuccess),
						makeMappedASetMc(aSetMc));
					var successes = _v4.a;
					var errors = _v4.b;
					if (!errors.b) {
						return $elm$core$Result$Ok(
							typeConstructor(
								A2(
									$elm$core$List$map,
									function (success) {
										return {
											bY: A2($elm$core$Result$withDefault, _List_Nil, success.bY),
											dF: success.dF
										};
									},
									successes)));
					} else {
						var err = errors.a;
						return A2(
							$elm$core$Result$map,
							function (el) {
								return $author$project$Type$Variant$VariantAnswerSet_MCSA(_List_Nil);
							},
							err.bY);
					}
				});
			var _v3 = htmlStringedVariantContent.jB;
			switch (_v3.$) {
				case 0:
					var aSetMc = _v3.a;
					return A2(handleAnswerSetMultipleChoice, $author$project$Type$Variant$VariantAnswerSet_MCSA, aSetMc);
				case 1:
					var aSetMcma = _v3.a;
					return A2(handleAnswerSetMultipleChoice, $author$project$Type$Variant$VariantAnswerSet_MCMA, aSetMcma);
				case 2:
					var aSetAnsBox = _v3.a;
					return $elm$core$Result$Ok(
						$author$project$Type$Variant$VariantAnswerSet_AnsBox(aSetAnsBox));
				default:
					return $elm$core$Result$Err('This question type is not yet implemented');
			}
		}();
		return A4(
			$elm$core$Result$map3,
			F3(
				function (qNodes, wsNodes, aSetWithNodes) {
					return {jB: aSetWithNodes, nE: qNodes, pK: htmlStringedVariantContent.pK, pQ: wsNodes};
				}),
			qContentResult,
			wsContentResult,
			aSetResult);
	};
	var result = A2($elm$core$Result$andThen, parseToNodes, partialResult);
	if (!result.$) {
		var response = result.a;
		var _v1 = resp.iG;
		switch (_v1) {
			case 'ShowVariant':
				return $author$project$Msgs$MsgPageShowVariant(
					$author$project$Page$ShowVariant$OnDecryptItem(
						$krisajenkins$remotedata$RemoteData$Success(response)));
			case 'ShowWorksheet':
				return $author$project$Msgs$MsgPageShowWorksheet(
					$author$project$Page$ShowWorksheet$OnDecryptItem(
						$krisajenkins$remotedata$RemoteData$Success(response)));
			case 'AdminPage':
				return $author$project$Msgs$MsgPageAdminPage(
					$author$project$Page$AdminPage$OnDecryptItem(
						$krisajenkins$remotedata$RemoteData$Success(response)));
			default:
				return A2($author$project$Msgs$ShowFatalError, 'Sorry but a handler for your request could not be found', 'Ok response, but missing requested handler is: ' + resp.iG);
		}
	} else {
		var err = result.a;
		var _v2 = resp.iG;
		switch (_v2) {
			case 'ShowVariant':
				return $author$project$Msgs$MsgPageShowVariant(
					$author$project$Page$ShowVariant$OnDecryptItem(
						$krisajenkins$remotedata$RemoteData$Failure(
							{
								b4: err,
								fZ: $elm$core$Result$toMaybe(
									A2(
										$elm$core$Result$map,
										function ($) {
											return $.pK;
										},
										partialResult))
							})));
			case 'ShowWorksheet':
				return $author$project$Msgs$MsgPageShowWorksheet(
					$author$project$Page$ShowWorksheet$OnDecryptItem(
						$krisajenkins$remotedata$RemoteData$Failure(
							{
								b4: err,
								fZ: $elm$core$Result$toMaybe(
									A2(
										$elm$core$Result$map,
										function ($) {
											return $.pK;
										},
										partialResult))
							})));
			case 'AdminPage':
				return $author$project$Msgs$MsgPageAdminPage(
					$author$project$Page$AdminPage$OnDecryptItem(
						$krisajenkins$remotedata$RemoteData$Failure(
							{
								b4: err,
								fZ: $elm$core$Result$toMaybe(
									A2(
										$elm$core$Result$map,
										function ($) {
											return $.pK;
										},
										partialResult))
							})));
			default:
				return A2($author$project$Msgs$ShowFatalError, 'Sorry but a handler for your request could not be found', 'Err response: ' + (err + (' and requested handlers is: ' + resp.iG)));
		}
	}
};
var $author$project$Msgs$OnDeleteHiddenExamGroupsResponse = function (a) {
	return {$: 32, a: a};
};
var $author$project$Models$GenericAPIGResponse = F3(
	function (isSuccess, developerMessage, userMessage) {
		return {lb: developerMessage, mm: isSuccess, pE: userMessage};
	});
var $elm$json$Json$Decode$map3 = _Json_map3;
var $author$project$DataService$apigResponseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Models$GenericAPIGResponse,
	A2(
		$elm$json$Json$Decode$field,
		'data',
		A2($elm$json$Json$Decode$field, 'isSuccess', $elm$json$Json$Decode$bool)),
	A2(
		$elm$json$Json$Decode$field,
		'data',
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'developerMessage', $elm$json$Json$Decode$string))),
	A2(
		$elm$json$Json$Decode$field,
		'data',
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'userMessage', $elm$json$Json$Decode$string))));
var $author$project$Subscriptions$decodeDeleteHiddenExamGroupsSuccessFailResponse = function (resp) {
	var result = A2($elm$json$Json$Decode$decodeValue, $author$project$DataService$apigResponseDecoder, resp);
	if (!result.$) {
		var genericAPIGSuccessResponse = result.a;
		return $author$project$Msgs$OnDeleteHiddenExamGroupsResponse(
			$krisajenkins$remotedata$RemoteData$Success(genericAPIGSuccessResponse));
	} else {
		var err = result.a;
		return $author$project$Msgs$OnDeleteHiddenExamGroupsResponse(
			$krisajenkins$remotedata$RemoteData$Failure(
				'We apologise.  We were unable to understand the translated result of the apigResponseDecoder. ' + $elm$json$Json$Decode$errorToString(err)));
	}
};
var $author$project$Msgs$OnGetHiddenExamGroupsResponse = function (a) {
	return {$: 30, a: a};
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $author$project$DataService$hiddenExamGroupsDecoder = $elm$json$Json$Decode$maybe(
	A2(
		$elm$json$Json$Decode$field,
		'hids',
		$elm$json$Json$Decode$dict(
			$elm$json$Json$Decode$dict(
				$elm$json$Json$Decode$list($elm$json$Json$Decode$string)))));
var $author$project$Subscriptions$decodeGetHiddenExamGroups = function (resp) {
	var result = A2($elm$json$Json$Decode$decodeValue, $author$project$DataService$hiddenExamGroupsDecoder, resp);
	if (!result.$) {
		var hiddenExamGroups = result.a;
		return $author$project$Msgs$OnGetHiddenExamGroupsResponse(
			$krisajenkins$remotedata$RemoteData$Success(hiddenExamGroups));
	} else {
		var err = result.a;
		return $author$project$Msgs$OnGetHiddenExamGroupsResponse(
			$krisajenkins$remotedata$RemoteData$Failure(
				'Sorry - we are unable to retrieve the list of suppressed exams.  ' + $elm$json$Json$Decode$errorToString(err)));
	}
};
var $author$project$Msgs$OnGetSchoolNameResponse = function (a) {
	return {$: 26, a: a};
};
var $author$project$DataService$schoolNameDecoder = A2($elm$json$Json$Decode$field, 'schoolName', $elm$json$Json$Decode$string);
var $author$project$Subscriptions$decodeGetSchoolNameResponse = function (resp) {
	var result = A2($elm$json$Json$Decode$decodeValue, $author$project$DataService$schoolNameDecoder, resp);
	if (!result.$) {
		var getSchoolNameResponse = result.a;
		return $author$project$Msgs$OnGetSchoolNameResponse(getSchoolNameResponse);
	} else {
		var err = result.a;
		return $author$project$Msgs$OnGetSchoolNameResponse(
			'Unknown School: ' + $elm$json$Json$Decode$errorToString(err));
	}
};
var $author$project$Msgs$OnRetrievePartialModelState = function (a) {
	return {$: 35, a: a};
};
var $author$project$Models$ExamProgressPoints = F3(
	function (currentQstnNbr, currentTestletsGroupId, currentTestletId) {
		return {kS: currentQstnNbr, kT: currentTestletId, kU: currentTestletsGroupId};
	});
var $author$project$Models$PartialModelState = F9(
	function (examStatus, examProgressPoints, startTimeRaw, examTimeSecondsRemaining, qIds, currentQGroupAnswerHistory, userDetails, pageModel_ShowVariant, activeSyllabusTitle) {
		return {jJ: activeSyllabusTitle, kR: currentQGroupAnswerHistory, eN: examProgressPoints, ln: examStatus, lo: examTimeSecondsRemaining, no: pageModel_ShowVariant, nI: qIds, ox: startTimeRaw, pC: userDetails};
	});
var $author$project$Helper$Common$blankUserDetails = {li: 'placeholder_email', lL: _List_Nil, pG: 'placeholder_username'};
var $author$project$Helper$Common$blankPartialModelState = A9(
	$author$project$Models$PartialModelState,
	0,
	A3($author$project$Models$ExamProgressPoints, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing),
	$elm$core$Maybe$Nothing,
	$elm$core$Maybe$Nothing,
	$elm$core$Array$empty,
	$elm$core$Array$empty,
	$author$project$Helper$Common$blankUserDetails,
	$author$project$Page$ShowVariant$init,
	$elm$core$Maybe$Nothing);
var $elm_community$json_extra$Json$Decode$Extra$andMap = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm_community$json_extra$Json$Decode$Extra$fromResult = function (result) {
	if (!result.$) {
		var successValue = result.a;
		return $elm$json$Json$Decode$succeed(successValue);
	} else {
		var errorMessage = result.a;
		return $elm$json$Json$Decode$fail(errorMessage);
	}
};
var $author$project$Models$ExamCompleted = 2;
var $author$project$DataService$fromStringExamStatus = function (examStatus) {
	switch (examStatus) {
		case 'ExamNotStarted':
			return $elm$core$Result$Ok(0);
		case 'ExamInProgress':
			return $elm$core$Result$Ok(1);
		case 'ExamCompleted':
			return $elm$core$Result$Ok(2);
		default:
			return $elm$core$Result$Err('Invalid ExamStatus: ' + examStatus);
	}
};
var $author$project$DataService$examStatusDecoder = A2(
	$elm$json$Json$Decode$andThen,
	A2($elm$core$Basics$composeR, $author$project$DataService$fromStringExamStatus, $elm_community$json_extra$Json$Decode$Extra$fromResult),
	$elm$json$Json$Decode$string);
var $author$project$Type$Activity$FromCMS = 0;
var $author$project$Page$ShowVariant$Model = function (serverErrorMsg) {
	return function (variantSelection) {
		return function (encodedVariantContent) {
			return function (variantContent) {
				return function (variantAnswer) {
					return function (sessionHistory) {
						return function (activity) {
							return function (activityMetric) {
								return function (isShowInstructions) {
									return function (isExtraTime) {
										return function (isAnswerChecked) {
											return function (toolModel_Calc) {
												return function (variantSeenHistory) {
													return function (isShowQuestionBand) {
														return {f8: activity, aq: activityMetric, dv: encodedVariantContent, ca: isAnswerChecked, by: isExtraTime, X: isShowInstructions, bz: isShowQuestionBand, fF: serverErrorMsg, oe: sessionHistory, je: toolModel_Calc, ao: variantAnswer, aL: variantContent, cr: variantSeenHistory, ab: variantSelection};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Type$Activity$ActivityData = F9(
	function (id, syllabusId, sortField, title, activityMetrics, isRepresentativeActivity, activityMeta, description, instructions) {
		return {cx: activityMeta, f9: activityMetrics, gT: description, lW: id, ma: instructions, hu: isRepresentativeActivity, or: sortField, bf: syllabusId, ja: title};
	});
var $author$project$Type$Activity$ActivityMeta = F5(
	function (targetNodeId, initialDifficulty, nodePercentages, context, focus) {
		return {kL: context, lA: focus, l3: initialDifficulty, mZ: nodePercentages, oR: targetNodeId};
	});
var $author$project$Type$Activity$Assess = 0;
var $author$project$Type$Activity$Practice = 1;
var $author$project$Type$Activity$contextDecoder = function (ctxString) {
	switch (ctxString) {
		case 'assess':
			return $elm$json$Json$Decode$succeed(0);
		case 'practice':
			return $elm$json$Json$Decode$succeed(1);
		default:
			return $elm$json$Json$Decode$succeed(1);
	}
};
var $author$project$Type$Activity$Challenge = 0;
var $author$project$Type$Activity$Weakness = 1;
var $author$project$Type$Activity$focusDecoder = function (focusString) {
	switch (focusString) {
		case 'challenge':
			return $elm$json$Json$Decode$succeed(0);
		case 'weakness':
			return $elm$json$Json$Decode$succeed(1);
		default:
			return $elm$json$Json$Decode$succeed(0);
	}
};
var $elm$json$Json$Decode$map5 = _Json_map5;
var $author$project$Type$Activity$NodePercentage = F2(
	function (nodeId, pc) {
		return {mY: nodeId, ih: pc};
	});
var $author$project$Type$Activity$nodePercentagesDecoder = function (jsonSourceFormat) {
	if (jsonSourceFormat === 1) {
		return A2(
			$elm$json$Json$Decode$field,
			'nodePercentages',
			$elm$json$Json$Decode$list(
				A3(
					$elm$json$Json$Decode$map2,
					$author$project$Type$Activity$NodePercentage,
					A2($elm$json$Json$Decode$field, 'nodeId', $elm$json$Json$Decode$string),
					A2($elm$json$Json$Decode$field, 'pc', $elm$json$Json$Decode$int))));
	} else {
		return A2(
			$elm$json$Json$Decode$field,
			'categorypercentages',
			$elm$json$Json$Decode$list(
				A3(
					$elm$json$Json$Decode$map2,
					$author$project$Type$Activity$NodePercentage,
					A2($elm$json$Json$Decode$field, 'category', $elm$json$Json$Decode$string),
					A2($elm$json$Json$Decode$field, 'categorypercentage', $elm$json$Json$Decode$int))));
	}
};
var $author$project$Type$Activity$activityMetaDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Type$Activity$ActivityMeta,
	A2($elm$json$Json$Decode$field, 'targetnode', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'initialdifficulty', $elm$json$Json$Decode$int),
	$author$project$Type$Activity$nodePercentagesDecoder(0),
	A2(
		$elm$json$Json$Decode$andThen,
		$author$project$Type$Activity$contextDecoder,
		A2($elm$json$Json$Decode$field, 'context', $elm$json$Json$Decode$string)),
	A2(
		$elm$json$Json$Decode$andThen,
		$author$project$Type$Activity$focusDecoder,
		A2($elm$json$Json$Decode$field, 'focus', $elm$json$Json$Decode$string)));
var $author$project$Type$Activity$ActivityMetric = F2(
	function (minutes, nbrQuestions) {
		return {fh: minutes, mV: nbrQuestions};
	});
var $author$project$Type$Activity$activityMetricDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Type$Activity$ActivityMetric,
	A2(
		$elm$json$Json$Decode$andThen,
		function (el) {
			return (!el) ? $elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing) : $elm$json$Json$Decode$succeed(
				$elm$core$Maybe$Just(el));
		},
		A2($elm$json$Json$Decode$field, 'minutes', $elm$json$Json$Decode$int)),
	A2($elm$json$Json$Decode$field, 'nbrquestions', $elm$json$Json$Decode$int));
var $author$project$Type$Activity$activityDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'instructions', $elm$json$Json$Decode$string),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string),
		A2(
			$elm$json$Json$Decode$andThen,
			function (f) {
				return A2($elm$json$Json$Decode$map, f, $author$project$Type$Activity$activityMetaDecoder);
			},
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'isrepresentativeactivity', $elm$json$Json$Decode$bool),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2(
						$elm$json$Json$Decode$field,
						'activitymetrics',
						$elm$json$Json$Decode$list($author$project$Type$Activity$activityMetricDecoder)),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'title', $elm$json$Json$Decode$string),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A2($elm$json$Json$Decode$field, 'sortfield', $elm$json$Json$Decode$string),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2($elm$json$Json$Decode$field, 'syllabus', $elm$json$Json$Decode$string),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
									$elm$json$Json$Decode$succeed($author$project$Type$Activity$ActivityData))))))))));
var $author$project$Type$History$ANSBOX = function (a) {
	return {$: 2, a: a};
};
var $author$project$Type$ExamResult$AnswerCorrect = 2;
var $author$project$Type$History$HistoryItem = F2(
	function (variantId, result) {
		return {dW: result, pK: variantId};
	});
var $author$project$Type$History$MCMA = function (a) {
	return {$: 1, a: a};
};
var $author$project$Type$History$MCSA = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $author$project$Type$ExamResult$AnswerIncorrect = 1;
var $author$project$Type$ExamResult$AnswerNotAttempted = 0;
var $author$project$Type$ExamResult$questionResultDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'c':
				return $elm$json$Json$Decode$succeed(2);
			case 'i':
				return $elm$json$Json$Decode$succeed(1);
			case 'n':
				return $elm$json$Json$Decode$succeed(0);
			default:
				var somethingElse = str;
				return $elm$json$Json$Decode$fail('Unknown result: ' + somethingElse);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Type$ExamResult$historyItemDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Type$History$HistoryItem,
	A3(
		$elm$json$Json$Decode$map2,
		$author$project$Type$Variant$VariantId,
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['it', 'ttl']),
			$elm$json$Json$Decode$string),
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['it', 'vId']),
			$elm$json$Json$Decode$int)),
	A2(
		$elm$json$Json$Decode$andThen,
		function (el) {
			if (!el) {
				return $elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing);
			} else {
				return $elm$json$Json$Decode$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$json$Json$Decode$andThen,
							function (idx) {
								return $elm$json$Json$Decode$succeed(
									$elm$core$Maybe$Just(
										$author$project$Type$History$MCSA(
											_Utils_Tuple2(idx, el === 2))));
							},
							A2(
								$elm$json$Json$Decode$at,
								_List_fromArray(
									['a', 'mc']),
								$elm$json$Json$Decode$int)),
							A2(
							$elm$json$Json$Decode$andThen,
							function (idxs) {
								return $elm$json$Json$Decode$succeed(
									$elm$core$Maybe$Just(
										$author$project$Type$History$MCMA(
											_Utils_Tuple2(idxs, el === 2))));
							},
							A2(
								$elm$json$Json$Decode$at,
								_List_fromArray(
									['a', 'mcma']),
								$elm$json$Json$Decode$list($elm$json$Json$Decode$int))),
							A2(
							$elm$json$Json$Decode$andThen,
							function (strs) {
								return $elm$json$Json$Decode$succeed(
									$elm$core$Maybe$Just(
										$author$project$Type$History$ANSBOX(
											_Utils_Tuple2(strs, el === 2))));
							},
							A2(
								$elm$json$Json$Decode$at,
								_List_fromArray(
									['a', 'abox']),
								$elm$json$Json$Decode$array(
									$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string))))
						]));
			}
		},
		A2($elm$json$Json$Decode$field, 'r', $author$project$Type$ExamResult$questionResultDecoder)));
var $author$project$Type$History$vAnswerDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'MCSA',
			A3(
				$elm$json$Json$Decode$map2,
				F2(
					function (a, b) {
						return $author$project$Type$History$MCSA(
							_Utils_Tuple2(a, b));
					}),
				A2($elm$json$Json$Decode$field, 'i', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'b', $elm$json$Json$Decode$bool))),
			A2(
			$elm$json$Json$Decode$field,
			'MCMA',
			A3(
				$elm$json$Json$Decode$map2,
				F2(
					function (a, b) {
						return $author$project$Type$History$MCMA(
							_Utils_Tuple2(a, b));
					}),
				A2(
					$elm$json$Json$Decode$field,
					'l',
					$elm$json$Json$Decode$list($elm$json$Json$Decode$int)),
				A2($elm$json$Json$Decode$field, 'b', $elm$json$Json$Decode$bool))),
			A2(
			$elm$json$Json$Decode$field,
			'ANSBOX',
			A3(
				$elm$json$Json$Decode$map2,
				F2(
					function (a, b) {
						return $author$project$Type$History$ANSBOX(
							_Utils_Tuple2(a, b));
					}),
				A2(
					$elm$json$Json$Decode$field,
					'a',
					$elm$json$Json$Decode$array(
						$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string))),
				A2($elm$json$Json$Decode$field, 'b', $elm$json$Json$Decode$bool)))
		]));
var $author$project$Type$History$vAnswerMaybeDecoder = $elm$json$Json$Decode$maybe(
	A2($elm$json$Json$Decode$field, 'variantAnswer', $author$project$Type$History$vAnswerDecoder));
var $author$project$Type$VariantSeenHistory$VariantMetaExtendedFlatForEncodeDecode = F5(
	function (variantId, difficulty, categoryIds, tags, usageTag) {
		return {ks: categoryIds, dp: difficulty, i7: tags, aX: usageTag, pK: variantId};
	});
var $author$project$Type$Variant$A_SmuidNeverSeen = 3;
var $author$project$Type$Variant$AlternateVariant = 1;
var $author$project$Type$Variant$B_HasASibSeenPrev = 4;
var $author$project$Type$Variant$BeenSeen = 2;
var $author$project$Type$Variant$C_HasASibSeenCurrSess = 5;
var $author$project$Type$Variant$D_VariantSeenPrev = 6;
var $author$project$Type$Variant$E_VariantSeenCurrSess = 7;
var $author$project$Type$Variant$Novel = 0;
var $author$project$Type$Variant$selectionTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (el) {
		return $elm$json$Json$Decode$succeed(
			function () {
				switch (el) {
					case 'Novel':
						return 0;
					case 'AlternateVariant':
						return 1;
					case 'BeenSeen':
						return 2;
					case 'A_SmuidNeverSeen':
						return 3;
					case 'B_HasASibSeenPrev':
						return 4;
					case 'C_HasASibSeenCurrSess':
						return 5;
					case 'D_VariantSeenPrev':
						return 6;
					case 'E_VariantSeenCurrSess':
						return 7;
					default:
						return 0;
				}
			}());
	},
	$elm$json$Json$Decode$string);
var $author$project$Type$VariantSeenHistory$variantMetaExtendedDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'usageTag', $author$project$Type$Variant$selectionTypeDecoder),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2(
			$elm$json$Json$Decode$field,
			'tags',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string)),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2(
				$elm$json$Json$Decode$field,
				'categoryIds',
				$elm$json$Json$Decode$list($elm$json$Json$Decode$string)),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'difficulty', $elm$json$Json$Decode$int),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'variantId', $author$project$Type$Variant$variantIdDecoder),
					$elm$json$Json$Decode$succeed($author$project$Type$VariantSeenHistory$VariantMetaExtendedFlatForEncodeDecode))))));
var $author$project$Type$VariantSeenHistory$variantSeenHistoryDecoder = $elm$json$Json$Decode$list($author$project$Type$VariantSeenHistory$variantMetaExtendedDecoder);
var $author$project$Type$Activity$FromPursEngine = 1;
var $author$project$Type$Variant$SelectionRationale = F4(
	function (targetStrike, selectionStrike, nodePercentages, selectionType) {
		return {mZ: nodePercentages, iO: selectionStrike, iP: selectionType, oS: targetStrike};
	});
var $author$project$Type$Variant$selectionRationaleDecoder = function (jsonSourceFormat) {
	if (!jsonSourceFormat) {
		return A5(
			$elm$json$Json$Decode$map4,
			$author$project$Type$Variant$SelectionRationale,
			A2($elm$json$Json$Decode$field, 'targetstrike', $elm$json$Json$Decode$int),
			A2($elm$json$Json$Decode$field, 'selectionstrike', $elm$json$Json$Decode$int),
			$author$project$Type$Activity$nodePercentagesDecoder(0),
			A2($elm$json$Json$Decode$field, 'selectiontype', $author$project$Type$Variant$selectionTypeDecoder));
	} else {
		return A5(
			$elm$json$Json$Decode$map4,
			$author$project$Type$Variant$SelectionRationale,
			A2($elm$json$Json$Decode$field, 'targetStrike', $elm$json$Json$Decode$int),
			A2($elm$json$Json$Decode$field, 'selectionStrike', $elm$json$Json$Decode$int),
			$author$project$Type$Activity$nodePercentagesDecoder(1),
			A2($elm$json$Json$Decode$field, 'selectionType', $author$project$Type$Variant$selectionTypeDecoder));
	}
};
var $author$project$Type$Variant$variantSelectionDecoder = function (jsonSourceFormat) {
	if (!jsonSourceFormat) {
		return A3(
			$elm$json$Json$Decode$map2,
			$elm$core$Tuple$pair,
			A2(
				$elm$json$Json$Decode$field,
				'selectionrationale',
				$author$project$Type$Variant$selectionRationaleDecoder(0)),
			$elm$json$Json$Decode$maybe(
				A2($elm$json$Json$Decode$field, 'variantid', $author$project$Type$Variant$variantIdDecoder)));
	} else {
		return A2(
			$elm$json$Json$Decode$andThen,
			function (variantId) {
				return A2(
					$elm$json$Json$Decode$andThen,
					function (selectionRationale) {
						return $elm$json$Json$Decode$succeed(
							_Utils_Tuple2(selectionRationale, variantId));
					},
					A2(
						$elm$json$Json$Decode$field,
						'value0',
						$author$project$Type$Variant$selectionRationaleDecoder(1)));
			},
			A2(
				$elm$json$Json$Decode$field,
				'value1',
				$elm$json$Json$Decode$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$json$Json$Decode$map,
							$elm$core$Maybe$Just,
							A2($elm$json$Json$Decode$field, 'value0', $author$project$Type$Variant$variantIdDecoder)),
							$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
						]))));
	}
};
var $author$project$Page$ShowVariant$modelDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2($elm$json$Json$Decode$field, 'isShowQuestionBand', $elm$json$Json$Decode$bool),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'variantSeenHistory', $author$project$Type$VariantSeenHistory$variantSeenHistoryDecoder)),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2(
				$elm$json$Json$Decode$field,
				'toolModel_Calc',
				$elm$json$Json$Decode$succeed($author$project$UITool$Calculator$init)),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2($elm$json$Json$Decode$field, 'isAnswerChecked', $elm$json$Json$Decode$bool),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2($elm$json$Json$Decode$field, 'isExtraTime', $elm$json$Json$Decode$bool),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A2($elm$json$Json$Decode$field, 'isShowInstructions', $elm$json$Json$Decode$bool),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							$elm$json$Json$Decode$maybe(
								A2($elm$json$Json$Decode$field, 'activityMetric', $author$project$Type$Activity$activityMetricDecoder)),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								$elm$json$Json$Decode$maybe(
									A2($elm$json$Json$Decode$field, 'activity', $author$project$Type$Activity$activityDecoder)),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2(
										$elm$json$Json$Decode$field,
										'sessionHistory',
										$elm$json$Json$Decode$list($author$project$Type$ExamResult$historyItemDecoder)),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										$author$project$Type$History$vAnswerMaybeDecoder,
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A2(
												$elm$json$Json$Decode$field,
												'variantContent',
												$elm$json$Json$Decode$succeed($krisajenkins$remotedata$RemoteData$NotAsked)),
											A2(
												$elm_community$json_extra$Json$Decode$Extra$andMap,
												A2(
													$elm$json$Json$Decode$field,
													'encodedVariantContent',
													$elm$json$Json$Decode$succeed($krisajenkins$remotedata$RemoteData$NotAsked)),
												A2(
													$elm_community$json_extra$Json$Decode$Extra$andMap,
													$elm$json$Json$Decode$maybe(
														A2(
															$elm$json$Json$Decode$field,
															'variantSelection',
															$author$project$Type$Variant$variantSelectionDecoder(0))),
													A2(
														$elm_community$json_extra$Json$Decode$Extra$andMap,
														A2(
															$elm$json$Json$Decode$field,
															'serverErrorMsg',
															$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)),
														$elm$json$Json$Decode$succeed($author$project$Page$ShowVariant$Model)))))))))))))));
var $author$project$Models$QAnswer = F6(
	function (aValue, aStatus, isQstnFlagged, isQstnLocked, isMkd, testletId) {
		return {jC: aStatus, jD: aValue, mg: isMkd, mi: isQstnFlagged, mj: isQstnLocked, oW: testletId};
	});
var $author$project$Type$ExamResult$MultiChoiceIndex = function (a) {
	return {$: 0, a: a};
};
var $author$project$Type$ExamResult$SingleDataBox = function (a) {
	return {$: 1, a: a};
};
var $author$project$Type$ExamResult$answerValueDecoderv1 = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$andThen,
			function (i) {
				return $elm$json$Json$Decode$succeed(
					$author$project$Type$ExamResult$MultiChoiceIndex(i));
			},
			A2($elm$json$Json$Decode$field, 'mc', $elm$json$Json$Decode$int)),
			A2(
			$elm$json$Json$Decode$andThen,
			function (str) {
				return $elm$json$Json$Decode$succeed(
					$author$project$Type$ExamResult$SingleDataBox(str));
			},
			A2($elm$json$Json$Decode$field, 'sa', $elm$json$Json$Decode$string))
		]));
var $elm$json$Json$Decode$map6 = _Json_map6;
var $author$project$DataService$qAnswerDecoder = A7(
	$elm$json$Json$Decode$map6,
	$author$project$Models$QAnswer,
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'aValue', $author$project$Type$ExamResult$answerValueDecoderv1)),
	A2($elm$json$Json$Decode$field, 'aStatus', $author$project$Type$ExamResult$questionResultDecoder),
	A2($elm$json$Json$Decode$field, 'isQstnFlagged', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'isQstnLocked', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'isMkd', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'testletId', $elm$json$Json$Decode$int));
var $author$project$JsInterop$Types$UserDetails = F3(
	function (email, username, groups) {
		return {li: email, lL: groups, pG: username};
	});
var $author$project$DataService$userDetailsDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$JsInterop$Types$UserDetails,
	A2($elm$json$Json$Decode$field, 'email', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'username', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'groups',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string)));
var $author$project$DataService$partialModelStateDecoder = A2(
	$elm_community$json_extra$Json$Decode$Extra$andMap,
	A2(
		$elm$json$Json$Decode$field,
		'activeSyllabusTitle',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$string)),
	A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A2($elm$json$Json$Decode$field, 'pageModel_ShowVariant', $author$project$Page$ShowVariant$modelDecoder),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A2($elm$json$Json$Decode$field, 'userDetails', $author$project$DataService$userDetailsDecoder),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A2(
					$elm$json$Json$Decode$field,
					'currentQGroupAnswerHistory',
					$elm$json$Json$Decode$array($author$project$DataService$qAnswerDecoder)),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A2(
						$elm$json$Json$Decode$field,
						'qIds',
						$elm$json$Json$Decode$array($elm$json$Json$Decode$int)),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						$elm$json$Json$Decode$maybe(
							A2($elm$json$Json$Decode$field, 'examTimeSecondsRemaining', $elm$json$Json$Decode$int)),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							$elm$json$Json$Decode$maybe(
								A2(
									$elm$json$Json$Decode$field,
									'startTimeRaw',
									A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int))),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A2(
									$elm$json$Json$Decode$field,
									'examProgressPoints',
									A4(
										$elm$json$Json$Decode$map3,
										$author$project$Models$ExamProgressPoints,
										$elm$json$Json$Decode$maybe(
											A2($elm$json$Json$Decode$field, 'currentQstnNbr', $elm$json$Json$Decode$int)),
										$elm$json$Json$Decode$maybe(
											A2($elm$json$Json$Decode$field, 'currentTestletsGroupId', $elm$json$Json$Decode$int)),
										$elm$json$Json$Decode$maybe(
											A2($elm$json$Json$Decode$field, 'currentTestletId', $elm$json$Json$Decode$int)))),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A2($elm$json$Json$Decode$field, 'examStatus', $author$project$DataService$examStatusDecoder),
									$elm$json$Json$Decode$succeed($author$project$Models$PartialModelState))))))))));
var $author$project$Subscriptions$decodePartialModelState = function (resp) {
	var result = A2($elm$json$Json$Decode$decodeValue, $author$project$DataService$partialModelStateDecoder, resp);
	if (!result.$) {
		var response = result.a;
		return $author$project$Msgs$OnRetrievePartialModelState(response);
	} else {
		var err = result.a;
		return $author$project$Msgs$OnRetrievePartialModelState($author$project$Helper$Common$blankPartialModelState);
	}
};
var $author$project$Msgs$OnPutHiddenExamGroupsResponse = function (a) {
	return {$: 31, a: a};
};
var $author$project$Subscriptions$decodePutHiddenExamGroupsSuccessFailResponse = function (resp) {
	var result = A2($elm$json$Json$Decode$decodeValue, $author$project$DataService$hiddenExamGroupsDecoder, resp);
	if (!result.$) {
		var hiddenExamGroups = result.a;
		return $author$project$Msgs$OnPutHiddenExamGroupsResponse(
			$krisajenkins$remotedata$RemoteData$Success(hiddenExamGroups));
	} else {
		var err = result.a;
		return $author$project$Msgs$OnPutHiddenExamGroupsResponse(
			$krisajenkins$remotedata$RemoteData$Failure(
				'We apologise.  We were unable to understand the translated result of the apigResponseDecoder. ' + $elm$json$Json$Decode$errorToString(err)));
	}
};
var $author$project$Msgs$UrlChanged = function (a) {
	return {$: 48, a: a};
};
var $author$project$Subscriptions$decodeUrlChange = function (urlChange) {
	return $author$project$Msgs$UrlChanged(
		A2(
			$elm$core$Maybe$withDefault,
			$author$project$Routing$HomeRoute,
			$author$project$Routing$locationHrefToRoute(urlChange)));
};
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {ip: processes, i6: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.ip;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.i6);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $author$project$Ports$onActionTriggerRequest = _Platform_incomingPort('onActionTriggerRequest', $elm$json$Json$Decode$value);
var $author$project$Ports$onDecodeContent = _Platform_incomingPort('onDecodeContent', $elm$json$Json$Decode$string);
var $author$project$Ports$onDecryptItemResponse = _Platform_incomingPort(
	'onDecryptItemResponse',
	A2(
		$elm$json$Json$Decode$andThen,
		function (responseHandler) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (decrypted) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (contentTag) {
							return $elm$json$Json$Decode$succeed(
								{gB: contentTag, k4: decrypted, iG: responseHandler});
						},
						A2($elm$json$Json$Decode$field, 'contentTag', $elm$json$Json$Decode$string));
				},
				A2($elm$json$Json$Decode$field, 'decrypted', $elm$json$Json$Decode$value));
		},
		A2($elm$json$Json$Decode$field, 'responseHandler', $elm$json$Json$Decode$string)));
var $author$project$Ports$onDeleteHiddenExamGroupsResponse = _Platform_incomingPort('onDeleteHiddenExamGroupsResponse', $elm$json$Json$Decode$value);
var $author$project$Ports$onGetHiddenExamGroupsResponse = _Platform_incomingPort('onGetHiddenExamGroupsResponse', $elm$json$Json$Decode$value);
var $author$project$Ports$onGetSchoolNameResponse = _Platform_incomingPort('onGetSchoolNameResponse', $elm$json$Json$Decode$value);
var $author$project$Ports$onPutHiddenExamGroupsResponse = _Platform_incomingPort('onPutHiddenExamGroupsResponse', $elm$json$Json$Decode$value);
var $author$project$Ports$onRetrievePartialModelState = _Platform_incomingPort('onRetrievePartialModelState', $elm$json$Json$Decode$value);
var $author$project$Ports$onUrlChange = _Platform_incomingPort('onUrlChange', $elm$json$Json$Decode$string);
var $elm$json$Json$Decode$index = _Json_decodeIndex;
var $author$project$Ports$receiveMCIsAnswerCorrect = _Platform_incomingPort(
	'receiveMCIsAnswerCorrect',
	A2(
		$elm$json$Json$Decode$andThen,
		function (_v0) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (_v1) {
					return $elm$json$Json$Decode$succeed(
						_Utils_Tuple2(_v0, _v1));
				},
				A2($elm$json$Json$Decode$index, 1, $elm$json$Json$Decode$bool));
		},
		A2($elm$json$Json$Decode$index, 0, $elm$json$Json$Decode$int)));
var $author$project$JsInterop$Plumbing$ConstructNodeForSyllabusResponse = function (a) {
	return {$: 9, a: a};
};
var $author$project$JsInterop$Plumbing$CreateQuestionGroupResponse = function (a) {
	return {$: 21, a: a};
};
var $author$project$JsInterop$Plumbing$CreateUserResponse = function (a) {
	return {$: 20, a: a};
};
var $author$project$JsInterop$Plumbing$DeleteLoginUserResponse = function (a) {
	return {$: 18, a: a};
};
var $author$project$JsInterop$Plumbing$DeleteSchoolResponse = function (a) {
	return {$: 15, a: a};
};
var $author$project$JsInterop$Plumbing$DeleteStudentHistoryResponse = function (a) {
	return {$: 19, a: a};
};
var $author$project$JsInterop$Types$Error = 0;
var $author$project$JsInterop$Types$FatalInteropFailure = function (a) {
	return {$: 1, a: a};
};
var $author$project$JsInterop$Types$FatalInteropFailureDetails = F3(
	function (code, message, details) {
		return {gt: code, gU: details, hG: message};
	});
var $author$project$JsInterop$Plumbing$ForgotPasswordCode = function (a) {
	return {$: 1, a: a};
};
var $author$project$JsInterop$Plumbing$ForgotPasswordSubmitResponse = function (a) {
	return {$: 2, a: a};
};
var $author$project$JsInterop$Plumbing$GetSyllabusesResponse = function (a) {
	return {$: 10, a: a};
};
var $author$project$JsInterop$Plumbing$GetVariantIdsResponse = function (a) {
	return {$: 8, a: a};
};
var $author$project$JsInterop$Plumbing$GetVariantSelectionResponse_Mjd5 = function (a) {
	return {$: 5, a: a};
};
var $author$project$JsInterop$Plumbing$GetVariantSelectionResponse_ShowVariant = function (a) {
	return {$: 6, a: a};
};
var $author$project$JsInterop$Plumbing$GetVariantSelectionResponse_ShowWorksheets = function (a) {
	return {$: 7, a: a};
};
var $author$project$JsInterop$Types$InteropFailureDetails = F4(
	function (code, severity, message, details) {
		return {gt: code, gU: details, hG: message, oh: severity};
	});
var $author$project$JsInterop$Plumbing$MeResponse = function (a) {
	return {$: 4, a: a};
};
var $author$project$JsInterop$Plumbing$NbrUsersForSchoolIdResponse = function (a) {
	return {$: 14, a: a};
};
var $author$project$JsInterop$Plumbing$PersonResponse = function (a) {
	return {$: 11, a: a};
};
var $author$project$JsInterop$Plumbing$PersonsResponse = function (a) {
	return {$: 12, a: a};
};
var $author$project$JsInterop$Types$RecoverableInteropFailure = function (a) {
	return {$: 0, a: a};
};
var $author$project$JsInterop$Plumbing$SchoolResponse = function (a) {
	return {$: 16, a: a};
};
var $author$project$JsInterop$Plumbing$SchoolsResponse = function (a) {
	return {$: 13, a: a};
};
var $author$project$JsInterop$Plumbing$SignOutResponse = function (a) {
	return {$: 3, a: a};
};
var $author$project$JsInterop$Plumbing$UpdateLoginUserResponse = function (a) {
	return {$: 17, a: a};
};
var $author$project$JsInterop$Plumbing$UserSignedIn = function (a) {
	return {$: 0, a: a};
};
var $author$project$JsInterop$Types$constructNodeForSyllabusResponseDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (el) {
		switch (el) {
			case 'Right':
				return A2(
					$elm$json$Json$Decode$map,
					$elm$core$Result$Ok,
					A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$value));
			case 'Left':
				return A2(
					$elm$json$Json$Decode$map,
					$elm$core$Result$Err,
					A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string));
			default:
				return $elm$json$Json$Decode$fail('Expecting a Right or Left valence, but instead received [' + (el + ']'));
		}
	},
	A2($elm$json$Json$Decode$field, 'valence', $elm$json$Json$Decode$string));
var $author$project$JsInterop$Types$CreateUserFailure = F4(
	function (a, b, c, d) {
		return {$: 1, a: a, b: b, c: c, d: d};
	});
var $author$project$JsInterop$Types$CreateUserSuccess = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$JsInterop$Types$createUserResponseDecoder = function (reqVariant) {
	var decodeSuccessStem = $elm$json$Json$Decode$at(
		_List_fromArray(
			['data', reqVariant, 'success']));
	var decodeFailureStem = $elm$json$Json$Decode$at(
		_List_fromArray(
			['data', reqVariant, 'failure']));
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A3(
				$elm$json$Json$Decode$map2,
				$author$project$JsInterop$Types$CreateUserSuccess,
				decodeSuccessStem(
					A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string)),
				decodeSuccessStem(
					A2($elm$json$Json$Decode$field, 'email', $elm$json$Json$Decode$string))),
				A5(
				$elm$json$Json$Decode$map4,
				$author$project$JsInterop$Types$CreateUserFailure,
				decodeSuccessStem(
					A2($elm$json$Json$Decode$field, 'email', $elm$json$Json$Decode$string)),
				decodeFailureStem(
					A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string)),
				decodeFailureStem(
					A2($elm$json$Json$Decode$field, 'message', $elm$json$Json$Decode$string)),
				decodeFailureStem(
					A2($elm$json$Json$Decode$field, 'technicals', $elm$json$Json$Decode$string)))
			]));
};
var $author$project$JsInterop$Types$DeleteSchoolFailure = function (a) {
	return {$: 1, a: a};
};
var $author$project$JsInterop$Types$DeleteSchoolResponseSuccess = function (a) {
	return {$: 0, a: a};
};
var $author$project$JsInterop$Types$Debug = 3;
var $author$project$JsInterop$Types$Info = 2;
var $author$project$JsInterop$Types$MsgForUser = $elm$core$Basics$identity;
var $author$project$JsInterop$Types$Warn = 1;
var $author$project$JsInterop$Types$failureSentFromJsToElmDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$andThen,
			function (el) {
				if (el === 'Fatal') {
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$JsInterop$Types$FatalInteropFailure,
						A4(
							$elm$json$Json$Decode$map3,
							$author$project$JsInterop$Types$FatalInteropFailureDetails,
							A2(
								$elm$json$Json$Decode$at,
								_List_fromArray(
									['code']),
								$elm$json$Json$Decode$string),
							A2(
								$elm$json$Json$Decode$andThen,
								function (el2) {
									return $elm$json$Json$Decode$succeed(el2);
								},
								A2(
									$elm$json$Json$Decode$at,
									_List_fromArray(
										['message']),
									$elm$json$Json$Decode$string)),
							A2(
								$elm$json$Json$Decode$at,
								_List_fromArray(
									['details']),
								$elm$json$Json$Decode$string)));
				} else {
					return $elm$json$Json$Decode$fail('The error severity [' + (el + '] sent from interop is misconfigured.'));
				}
			},
			A2(
				$elm$json$Json$Decode$at,
				_List_fromArray(
					['severity']),
				$elm$json$Json$Decode$string)),
			A2(
			$elm$json$Json$Decode$map,
			$author$project$JsInterop$Types$RecoverableInteropFailure,
			A5(
				$elm$json$Json$Decode$map4,
				$author$project$JsInterop$Types$InteropFailureDetails,
				A2(
					$elm$json$Json$Decode$at,
					_List_fromArray(
						['code']),
					$elm$json$Json$Decode$string),
				A2(
					$elm$json$Json$Decode$andThen,
					function (el) {
						switch (el) {
							case 'Error':
								return $elm$json$Json$Decode$succeed(0);
							case 'Warn':
								return $elm$json$Json$Decode$succeed(1);
							case 'Info':
								return $elm$json$Json$Decode$succeed(2);
							case 'Debug':
								return $elm$json$Json$Decode$succeed(3);
							default:
								return $elm$json$Json$Decode$fail('The error severity [' + (el + '] sent from interop is misconfigured.'));
						}
					},
					A2(
						$elm$json$Json$Decode$at,
						_List_fromArray(
							['severity']),
						$elm$json$Json$Decode$string)),
				A2(
					$elm$json$Json$Decode$andThen,
					function (el) {
						return $elm$json$Json$Decode$succeed(el);
					},
					A2(
						$elm$json$Json$Decode$at,
						_List_fromArray(
							['message']),
						$elm$json$Json$Decode$string)),
				A2(
					$elm$json$Json$Decode$at,
					_List_fromArray(
						['details']),
					$elm$json$Json$Decode$string)))
		]));
var $author$project$JsInterop$Types$SchoolDetails = F4(
	function (id, sId, name, isCurrentSubscriber) {
		return {lW: id, ht: isCurrentSubscriber, mO: name, d$: sId};
	});
var $author$project$JsInterop$Types$schoolDecoder = function (rootItems) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (id) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (sId) {
					return $elm$json$Json$Decode$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$json$Json$Decode$at,
								A2(
									$elm$core$List$append,
									rootItems,
									_List_fromArray(
										['eData', 'sch'])),
								A5(
									$elm$json$Json$Decode$map4,
									$author$project$JsInterop$Types$SchoolDetails,
									$elm$json$Json$Decode$succeed(id),
									$elm$json$Json$Decode$succeed(sId),
									A2(
										$elm$json$Json$Decode$at,
										_List_fromArray(
											['name']),
										$elm$json$Json$Decode$string),
									A2(
										$elm$json$Json$Decode$at,
										_List_fromArray(
											['isCurrentSubscriber']),
										$elm$json$Json$Decode$bool)))
							]));
				},
				A2(
					$elm$json$Json$Decode$at,
					A2(
						$elm$core$List$append,
						rootItems,
						_List_fromArray(
							['sId'])),
					$elm$json$Json$Decode$string));
		},
		A2(
			$elm$json$Json$Decode$at,
			A2(
				$elm$core$List$append,
				rootItems,
				_List_fromArray(
					['id'])),
			$elm$json$Json$Decode$string));
};
var $author$project$JsInterop$Types$deleteSchoolResponseDecoder = function (rootItems) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$map,
				$author$project$JsInterop$Types$DeleteSchoolResponseSuccess,
				$author$project$JsInterop$Types$schoolDecoder(rootItems)),
				A2(
				$elm$json$Json$Decode$map,
				$author$project$JsInterop$Types$DeleteSchoolFailure,
				A2($elm$json$Json$Decode$field, 'error', $author$project$JsInterop$Types$failureSentFromJsToElmDecoder))
			]));
};
var $author$project$JsInterop$Types$errMsgForUser = {g: 'There is a problem with the application configuration.  Please notify SmarterMaths (support@smartermaths.com.au) and we\'ll fix it asap!'};
var $author$project$JsInterop$Types$ForgotPasswordFailure = function (a) {
	return {$: 1, a: a};
};
var $author$project$JsInterop$Types$ForgotPasswordResponseDetails = F3(
	function (username, deliveryMedium, destination) {
		return {k5: deliveryMedium, la: destination, pG: username};
	});
var $author$project$JsInterop$Types$ForgotPasswordSuccess = function (a) {
	return {$: 0, a: a};
};
var $author$project$JsInterop$Types$forgotPasswordResponseDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$map,
			$author$project$JsInterop$Types$ForgotPasswordSuccess,
			A4(
				$elm$json$Json$Decode$map3,
				$author$project$JsInterop$Types$ForgotPasswordResponseDetails,
				A2(
					$elm$json$Json$Decode$at,
					_List_fromArray(
						['username']),
					$elm$json$Json$Decode$string),
				A2(
					$elm$json$Json$Decode$at,
					_List_fromArray(
						['deliveryMedium']),
					$elm$json$Json$Decode$string),
				A2(
					$elm$json$Json$Decode$at,
					_List_fromArray(
						['destination']),
					$elm$json$Json$Decode$string))),
			A2($elm$json$Json$Decode$map, $author$project$JsInterop$Types$ForgotPasswordFailure, $author$project$JsInterop$Types$failureSentFromJsToElmDecoder)
		]));
var $author$project$JsInterop$Types$ForgotPasswordSubmitFailure = function (a) {
	return {$: 1, a: a};
};
var $author$project$JsInterop$Types$ForgotPasswordSubmitSuccess = function (a) {
	return {$: 0, a: a};
};
var $author$project$JsInterop$Types$forgotPasswordSubmitResponseDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2($elm$json$Json$Decode$map, $author$project$JsInterop$Types$ForgotPasswordSubmitSuccess, $elm$json$Json$Decode$string),
			A2($elm$json$Json$Decode$map, $author$project$JsInterop$Types$ForgotPasswordSubmitFailure, $author$project$JsInterop$Types$failureSentFromJsToElmDecoder)
		]));
var $author$project$JsInterop$Types$GenericIdSuccess = function (a) {
	return {$: 0, a: a};
};
var $author$project$JsInterop$Types$genericIdResponseDecoder = function (rootItems) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$map,
				$author$project$JsInterop$Types$GenericIdSuccess,
				A2(
					$elm$json$Json$Decode$at,
					A2(
						$elm$core$List$append,
						rootItems,
						_List_fromArray(
							['id'])),
					$elm$json$Json$Decode$string))
			]));
};
var $author$project$JsInterop$Types$GenericLoginUserFailure = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$JsInterop$Types$GenericLoginUserSuccess = function (a) {
	return {$: 0, a: a};
};
var $author$project$JsInterop$Types$genericLoginUserResponseDecoder = function (reqVariant) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$map,
				$author$project$JsInterop$Types$GenericLoginUserSuccess,
				A2(
					$elm$json$Json$Decode$andThen,
					function (_v0) {
						return A2(
							$elm$json$Json$Decode$at,
							_List_fromArray(
								['data', reqVariant, 'success']),
							$elm$json$Json$Decode$string);
					},
					A2(
						$elm$json$Json$Decode$at,
						_List_fromArray(
							['data', reqVariant, 'failure']),
						$elm$json$Json$Decode$null(true)))),
				A3(
				$elm$json$Json$Decode$map2,
				$author$project$JsInterop$Types$GenericLoginUserFailure,
				A2(
					$elm$json$Json$Decode$at,
					_List_fromArray(
						['id']),
					$elm$json$Json$Decode$string),
				A2(
					$elm$json$Json$Decode$at,
					_List_fromArray(
						['error']),
					$author$project$JsInterop$Types$failureSentFromJsToElmDecoder))
			]));
};
var $author$project$Type$Syllabus$SyllabusWithCohortMaybe = F3(
	function (id, cohortMaybe, desc) {
		return {gu: cohortMaybe, k8: desc, lW: id};
	});
var $author$project$Type$Cohort$MS = {$: 1};
var $author$project$Type$Cohort$Stage3 = function (a) {
	return {$: 1, a: a};
};
var $author$project$Type$Cohort$Stage4 = function (a) {
	return {$: 2, a: a};
};
var $author$project$Type$Cohort$Stage5 = function (a) {
	return {$: 3, a: a};
};
var $author$project$Type$Cohort$Y10 = 1;
var $author$project$Type$Cohort$Y4 = 1;
var $author$project$Type$Cohort$Y5 = 0;
var $author$project$Type$Cohort$Y6 = 1;
var $author$project$Type$Cohort$Y7 = 0;
var $author$project$Type$Cohort$Y8 = 1;
var $author$project$Type$Cohort$Y9 = 0;
var $author$project$Type$Cohort$fromInt = function (i) {
	switch (i) {
		case 3:
			return $elm$core$Maybe$Just(
				$author$project$Type$Cohort$Aus(
					$author$project$Type$Cohort$Stage2(0)));
		case 4:
			return $elm$core$Maybe$Just(
				$author$project$Type$Cohort$Aus(
					$author$project$Type$Cohort$Stage2(1)));
		case 5:
			return $elm$core$Maybe$Just(
				$author$project$Type$Cohort$Aus(
					$author$project$Type$Cohort$Stage3(0)));
		case 6:
			return $elm$core$Maybe$Just(
				$author$project$Type$Cohort$Aus(
					$author$project$Type$Cohort$Stage3(1)));
		case 7:
			return $elm$core$Maybe$Just(
				$author$project$Type$Cohort$Aus(
					$author$project$Type$Cohort$Stage4(0)));
		case 8:
			return $elm$core$Maybe$Just(
				$author$project$Type$Cohort$Aus(
					$author$project$Type$Cohort$Stage4(1)));
		case 9:
			return $elm$core$Maybe$Just(
				$author$project$Type$Cohort$Aus(
					$author$project$Type$Cohort$Stage5(0)));
		case 10:
			return $elm$core$Maybe$Just(
				$author$project$Type$Cohort$Aus(
					$author$project$Type$Cohort$Stage5(1)));
		case 20:
			return $elm$core$Maybe$Just($author$project$Type$Cohort$MS);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Type$Cohort$fromAssigneeString = function (str) {
	return A2(
		$elm$core$Maybe$andThen,
		$author$project$Type$Cohort$fromInt,
		$elm$core$String$toInt(str));
};
var $author$project$Type$Cohort$fromSyllabusTitleToCohortMaybe = function (s) {
	return (s === 'minstd') ? $elm$core$Maybe$Just($author$project$Type$Cohort$MS) : ((A2($elm$core$String$startsWith, 'nap', s) || A2($elm$core$String$startsWith, 'aus', s)) ? $author$project$Type$Cohort$fromAssigneeString(
		A2($elm$core$String$dropLeft, 3, s)) : $elm$core$Maybe$Nothing);
};
var $author$project$Type$Syllabus$Syllabus = F3(
	function (id, cohort, desc) {
		return {kA: cohort, k8: desc, lW: id};
	});
var $author$project$Type$Syllabus$justsSyllabusWithCohortMaybes = $elm$core$List$filterMap(
	function (s) {
		return A2(
			$elm$core$Maybe$map,
			function (cohort) {
				return A3($author$project$Type$Syllabus$Syllabus, s.lW, cohort, s.k8);
			},
			s.gu);
	});
var $author$project$JsInterop$Types$getSyllabusesResponseDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (el) {
		switch (el) {
			case 'Right':
				return A2(
					$elm$json$Json$Decode$map,
					$elm$core$Result$Ok,
					A2(
						$elm$json$Json$Decode$field,
						'value',
						A2(
							$elm$json$Json$Decode$map,
							$author$project$Type$Syllabus$justsSyllabusWithCohortMaybes,
							$elm$json$Json$Decode$list(
								A4(
									$elm$json$Json$Decode$map3,
									$author$project$Type$Syllabus$SyllabusWithCohortMaybe,
									A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
									A2(
										$elm$json$Json$Decode$andThen,
										function (syllabusTitle) {
											return $elm$json$Json$Decode$succeed(
												$author$project$Type$Cohort$fromSyllabusTitleToCohortMaybe(syllabusTitle));
										},
										A2($elm$json$Json$Decode$field, 'title', $elm$json$Json$Decode$string)),
									A2($elm$json$Json$Decode$field, 'desc', $elm$json$Json$Decode$string))))));
			case 'Left':
				return A2(
					$elm$json$Json$Decode$map,
					$elm$core$Result$Err,
					A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string));
			default:
				return $elm$json$Json$Decode$fail('Expecting a Right or Left valence, but instead received [' + (el + ']'));
		}
	},
	A2($elm$json$Json$Decode$field, 'valence', $elm$json$Json$Decode$string));
var $author$project$JsInterop$Types$getVariantIdsResponseDecoder = $elm$json$Json$Decode$list($author$project$Type$Variant$variantIdDecoder);
var $author$project$JsInterop$Types$getVariantSelectionResponseDecoder = $author$project$Type$Variant$variantSelectionDecoder(1);
var $author$project$JsInterop$Plumbing$infoForElm = _Platform_incomingPort(
	'infoForElm',
	A2(
		$elm$json$Json$Decode$andThen,
		function (tag) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (payload) {
					return $elm$json$Json$Decode$succeed(
						{c: payload, M: tag});
				},
				A2($elm$json$Json$Decode$field, 'payload', $elm$json$Json$Decode$value));
		},
		A2($elm$json$Json$Decode$field, 'tag', $elm$json$Json$Decode$value)));
var $author$project$JsInterop$Types$NbrUsersForSchoolIdFailure = function (a) {
	return {$: 1, a: a};
};
var $author$project$JsInterop$Types$NbrUsersForSchoolIdSuccess = function (a) {
	return {$: 0, a: a};
};
var $author$project$JsInterop$Types$NbrUsersForSchoolIdDetails = F2(
	function (id, sId) {
		return {lW: id, d$: sId};
	});
var $author$project$JsInterop$Types$nbrUsersForSchoolIdDecoder = function (rootItems) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (id) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (sId) {
					return A3(
						$elm$json$Json$Decode$map2,
						$author$project$JsInterop$Types$NbrUsersForSchoolIdDetails,
						$elm$json$Json$Decode$succeed(id),
						$elm$json$Json$Decode$succeed(sId));
				},
				A2(
					$elm$json$Json$Decode$at,
					A2(
						$elm$core$List$append,
						rootItems,
						_List_fromArray(
							['sId'])),
					$elm$json$Json$Decode$string));
		},
		A2(
			$elm$json$Json$Decode$at,
			A2(
				$elm$core$List$append,
				rootItems,
				_List_fromArray(
					['id'])),
			$elm$json$Json$Decode$string));
};
var $author$project$JsInterop$Types$nbrUsersForSchoolIdResponseDecoder = function (rootItems) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$map,
				$author$project$JsInterop$Types$NbrUsersForSchoolIdSuccess,
				A2(
					$elm$json$Json$Decode$at,
					rootItems,
					$elm$json$Json$Decode$list(
						$author$project$JsInterop$Types$nbrUsersForSchoolIdDecoder(_List_Nil)))),
				A2(
				$elm$json$Json$Decode$map,
				$author$project$JsInterop$Types$NbrUsersForSchoolIdFailure,
				A2($elm$json$Json$Decode$at, rootItems, $author$project$JsInterop$Types$failureSentFromJsToElmDecoder))
			]));
};
var $author$project$Type$User$Admin = 0;
var $author$project$Type$User$Assignee = F4(
	function (base, sSub, schId, hstry) {
		return {dc: base, lU: hstry, n2: sSub, n4: schId};
	});
var $author$project$Type$User$AssigneeBase = F7(
	function (usrSub, typ, lvl, fNam, lNam, email, grp) {
		return {li: email, lt: fNam, eV: grp, mq: lNam, hB: lvl, pt: typ, pH: usrSub};
	});
var $author$project$Type$User$NonAdmin = 1;
var $author$project$JsInterop$Types$Person = $elm$core$Basics$identity;
var $author$project$JsInterop$Types$PersonFailure = function (a) {
	return {$: 1, a: a};
};
var $author$project$JsInterop$Types$PersonSuccess = function (a) {
	return {$: 0, a: a};
};
var $author$project$Type$User$Student = {$: 0};
var $author$project$Type$User$Teacher = function (a) {
	return {$: 1, a: a};
};
var $author$project$Type$Cohort$fromStringToMaybeCohort = function (s) {
	return (s === 'MS') ? $elm$core$Maybe$Just($author$project$Type$Cohort$MS) : (A2($elm$core$String$startsWith, 'Y', s) ? $author$project$Type$Cohort$fromAssigneeString(
		A2($elm$core$String$dropLeft, 1, s)) : $elm$core$Maybe$Nothing);
};
var $elm$json$Json$Decode$map7 = _Json_map7;
var $author$project$Type$ExamResult$ActivityResultRecord = F4(
	function (aId, aMode, tsFinish, qs) {
		return {jz: aId, jA: aMode, ci: qs, pr: tsFinish};
	});
var $author$project$Type$ExamResult$ActivityRunResult = function (a) {
	return {$: 1, a: a};
};
var $author$project$Type$Activity$Offline = function (a) {
	return {$: 1, a: a};
};
var $author$project$Type$Activity$Online = function (a) {
	return {$: 0, a: a};
};
var $author$project$Type$ExamResult$TestletGroupResultRecord = F5(
	function (qGroupId, finishTestletId, tsStart, tsFinish, qs) {
		return {g3: finishTestletId, nF: qGroupId, ci: qs, pr: tsFinish, jh: tsStart};
	});
var $author$project$Type$ExamResult$TestletGroupRunResult = function (a) {
	return {$: 0, a: a};
};
var $author$project$Type$Activity$WorksheetA = 0;
var $author$project$Type$Activity$WorksheetB = 1;
var $author$project$Type$ExamResult$QuestionResultItem = F4(
	function (qId, result, isMkd, qType) {
		return {mg: isMkd, dS: qId, iu: qType, dW: result};
	});
var $author$project$Type$ExamResult$questionResultItemDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Type$ExamResult$QuestionResultItem,
	A2($elm$json$Json$Decode$field, 'qId', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'r', $author$project$Type$ExamResult$questionResultDecoder),
	A2($elm$json$Json$Decode$field, 'isMkd', $elm$json$Json$Decode$bool),
	$elm$json$Json$Decode$maybe(
		A2($elm$json$Json$Decode$field, 'a', $author$project$Type$ExamResult$answerValueDecoderv1)));
var $author$project$Type$ExamResult$singleExamRunResultDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$map,
			$author$project$Type$ExamResult$TestletGroupRunResult,
			A6(
				$elm$json$Json$Decode$map5,
				$author$project$Type$ExamResult$TestletGroupResultRecord,
				A2($elm$json$Json$Decode$field, 'qGId', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'fTId', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'tsS', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'tsF', $elm$json$Json$Decode$string),
				A2(
					$elm$json$Json$Decode$field,
					'qs',
					$elm$json$Json$Decode$list($author$project$Type$ExamResult$questionResultItemDecoder)))),
			A2(
			$elm$json$Json$Decode$map,
			$author$project$Type$ExamResult$ActivityRunResult,
			A5(
				$elm$json$Json$Decode$map4,
				$author$project$Type$ExamResult$ActivityResultRecord,
				A2($elm$json$Json$Decode$field, 'aId', $elm$json$Json$Decode$string),
				$elm$json$Json$Decode$oneOf(
					_List_fromArray(
						[
							A2(
							$elm$json$Json$Decode$andThen,
							function (el) {
								return $elm$json$Json$Decode$succeed(
									$author$project$Type$Activity$Online(el));
							},
							A2($elm$json$Json$Decode$field, 'tsS', $elm$json$Json$Decode$string)),
							A2(
							$elm$json$Json$Decode$andThen,
							function (el) {
								return (el === 'wsA') ? $elm$json$Json$Decode$succeed(
									$author$project$Type$Activity$Offline(0)) : $elm$json$Json$Decode$succeed(
									$author$project$Type$Activity$Offline(1));
							},
							A2($elm$json$Json$Decode$field, 'wsT', $elm$json$Json$Decode$string))
						])),
				A2($elm$json$Json$Decode$field, 'tsF', $elm$json$Json$Decode$string),
				A2(
					$elm$json$Json$Decode$field,
					'qs',
					$elm$json$Json$Decode$list($author$project$Type$ExamResult$historyItemDecoder))))
		]));
var $author$project$JsInterop$Types$personResponseDecoder = function (rootItems) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$map,
				$author$project$JsInterop$Types$PersonSuccess,
				A2(
					$elm$json$Json$Decode$andThen,
					function (id) {
						return A2(
							$elm$json$Json$Decode$andThen,
							function (sId) {
								return A2(
									$elm$json$Json$Decode$map,
									$elm$core$Basics$identity,
									A5(
										$elm$json$Json$Decode$map4,
										$author$project$Type$User$Assignee,
										$elm$json$Json$Decode$oneOf(
											_List_fromArray(
												[
													A2(
													$elm$json$Json$Decode$at,
													A2(
														$elm$core$List$append,
														rootItems,
														_List_fromArray(
															['eData', 'tch'])),
													A8(
														$elm$json$Json$Decode$map7,
														$author$project$Type$User$AssigneeBase,
														$elm$json$Json$Decode$succeed(id),
														A2(
															$elm$json$Json$Decode$andThen,
															function (el) {
																return $elm$json$Json$Decode$succeed(
																	$author$project$Type$User$Teacher(
																		el ? 0 : 1));
															},
															A2($elm$json$Json$Decode$field, 'isAdmin', $elm$json$Json$Decode$bool)),
														$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing),
														A2($elm$json$Json$Decode$field, 'firstName', $elm$json$Json$Decode$string),
														A2($elm$json$Json$Decode$field, 'lastName', $elm$json$Json$Decode$string),
														A2($elm$json$Json$Decode$field, 'email', $elm$json$Json$Decode$string),
														$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing))),
													A2(
													$elm$json$Json$Decode$at,
													A2(
														$elm$core$List$append,
														rootItems,
														_List_fromArray(
															['eData', 'stu'])),
													A8(
														$elm$json$Json$Decode$map7,
														$author$project$Type$User$AssigneeBase,
														$elm$json$Json$Decode$succeed(id),
														$elm$json$Json$Decode$succeed($author$project$Type$User$Student),
														A2(
															$elm$json$Json$Decode$andThen,
															function (el) {
																var _v0 = $author$project$Type$Cohort$fromStringToMaybeCohort(el);
																if (!_v0.$) {
																	var cohort = _v0.a;
																	return $elm$json$Json$Decode$maybe(
																		$elm$json$Json$Decode$succeed(cohort));
																} else {
																	return $elm$json$Json$Decode$fail('The cohort (yearLvl) [' + (el + ('] sent from the graphql interop request for the student [' + (id + ']is misconfigured.'))));
																}
															},
															A2($elm$json$Json$Decode$field, 'cohort', $elm$json$Json$Decode$string)),
														A2($elm$json$Json$Decode$field, 'firstName', $elm$json$Json$Decode$string),
														A2($elm$json$Json$Decode$field, 'lastName', $elm$json$Json$Decode$string),
														A2($elm$json$Json$Decode$field, 'email', $elm$json$Json$Decode$string),
														$elm$json$Json$Decode$maybe(
															A2($elm$json$Json$Decode$field, 'group', $elm$json$Json$Decode$string))))
												])),
										$elm$json$Json$Decode$succeed(sId),
										$elm$json$Json$Decode$oneOf(
											_List_fromArray(
												[
													A2(
													$elm$json$Json$Decode$at,
													A2(
														$elm$core$List$append,
														rootItems,
														_List_fromArray(
															['eData', 'stu', 'schId'])),
													$elm$json$Json$Decode$string),
													$elm$json$Json$Decode$succeed('')
												])),
										A2(
											$elm$json$Json$Decode$at,
											A2(
												$elm$core$List$append,
												rootItems,
												_List_fromArray(
													['hstry', 'items'])),
											$elm$json$Json$Decode$oneOf(
												_List_fromArray(
													[
														$elm$json$Json$Decode$list($author$project$Type$ExamResult$singleExamRunResultDecoder),
														$elm$json$Json$Decode$succeed(_List_Nil)
													])))));
							},
							A2(
								$elm$json$Json$Decode$at,
								A2(
									$elm$core$List$append,
									rootItems,
									_List_fromArray(
										['sId'])),
								$elm$json$Json$Decode$string));
					},
					A2(
						$elm$json$Json$Decode$at,
						A2(
							$elm$core$List$append,
							rootItems,
							_List_fromArray(
								['id'])),
						$elm$json$Json$Decode$string))),
				A2($elm$json$Json$Decode$map, $author$project$JsInterop$Types$PersonFailure, $author$project$JsInterop$Types$failureSentFromJsToElmDecoder)
			]));
};
var $author$project$JsInterop$Types$PersonsFailure = function (a) {
	return {$: 1, a: a};
};
var $author$project$JsInterop$Types$PersonsSuccess = function (a) {
	return {$: 0, a: a};
};
var $author$project$JsInterop$Types$personsResponseDecoder = function (rootItems) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$map,
				$author$project$JsInterop$Types$PersonsSuccess,
				A2(
					$elm$json$Json$Decode$at,
					rootItems,
					$elm$json$Json$Decode$list(
						$author$project$JsInterop$Types$personResponseDecoder(_List_Nil)))),
				A2(
				$elm$json$Json$Decode$map,
				$author$project$JsInterop$Types$PersonsFailure,
				A2($elm$json$Json$Decode$at, rootItems, $author$project$JsInterop$Types$failureSentFromJsToElmDecoder))
			]));
};
var $author$project$JsInterop$Types$SchoolFailure = function (a) {
	return {$: 1, a: a};
};
var $author$project$JsInterop$Types$SchoolSuccess = function (a) {
	return {$: 0, a: a};
};
var $author$project$JsInterop$Types$schoolResponseDecoder = function (rootItems) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$map,
				$author$project$JsInterop$Types$SchoolSuccess,
				$author$project$JsInterop$Types$schoolDecoder(rootItems)),
				A2(
				$elm$json$Json$Decode$map,
				$author$project$JsInterop$Types$SchoolFailure,
				A2($elm$json$Json$Decode$field, 'error', $author$project$JsInterop$Types$failureSentFromJsToElmDecoder))
			]));
};
var $author$project$JsInterop$Types$SchoolsFailure = function (a) {
	return {$: 1, a: a};
};
var $author$project$JsInterop$Types$SchoolsSuccess = function (a) {
	return {$: 0, a: a};
};
var $author$project$JsInterop$Types$schoolsResponseDecoder = function (rootItems) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$map,
				$author$project$JsInterop$Types$SchoolsSuccess,
				A2(
					$elm$json$Json$Decode$at,
					rootItems,
					$elm$json$Json$Decode$list(
						$author$project$JsInterop$Types$schoolDecoder(_List_Nil)))),
				A2(
				$elm$json$Json$Decode$map,
				$author$project$JsInterop$Types$SchoolsFailure,
				A2($elm$json$Json$Decode$at, rootItems, $author$project$JsInterop$Types$failureSentFromJsToElmDecoder))
			]));
};
var $author$project$JsInterop$Types$SignInFailure = function (a) {
	return {$: 1, a: a};
};
var $author$project$JsInterop$Types$SignInSuccess = function (a) {
	return {$: 0, a: a};
};
var $author$project$JsInterop$Types$signInResponseDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$map,
			$author$project$JsInterop$Types$SignInSuccess,
			A4(
				$elm$json$Json$Decode$map3,
				$author$project$JsInterop$Types$UserDetails,
				A2(
					$elm$json$Json$Decode$at,
					_List_fromArray(
						['email']),
					$elm$json$Json$Decode$string),
				A2(
					$elm$json$Json$Decode$at,
					_List_fromArray(
						['username']),
					$elm$json$Json$Decode$string),
				A2(
					$elm$json$Json$Decode$at,
					_List_fromArray(
						['groups']),
					$elm$json$Json$Decode$list($elm$json$Json$Decode$string)))),
			A2($elm$json$Json$Decode$map, $author$project$JsInterop$Types$SignInFailure, $author$project$JsInterop$Types$failureSentFromJsToElmDecoder)
		]));
var $author$project$JsInterop$Types$SignOutFailure = function (a) {
	return {$: 1, a: a};
};
var $author$project$JsInterop$Types$SignOutSuccess = function (a) {
	return {$: 0, a: a};
};
var $author$project$JsInterop$Types$signOutResponseDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2($elm$json$Json$Decode$map, $author$project$JsInterop$Types$SignOutSuccess, $elm$json$Json$Decode$string),
			A2($elm$json$Json$Decode$map, $author$project$JsInterop$Types$SignOutFailure, $author$project$JsInterop$Types$failureSentFromJsToElmDecoder)
		]));
var $author$project$JsInterop$Plumbing$receiveMessageForElm = F2(
	function (tagger, onError) {
		return $author$project$JsInterop$Plumbing$infoForElm(
			function (outsideInfo) {
				var tagResult = A2($elm$json$Json$Decode$decodeValue, $author$project$JsInterop$Types$interopTagDecoder, outsideInfo.M);
				if (!tagResult.$) {
					var tag = tagResult.a;
					switch (tag) {
						case 1:
							var _v2 = A2($elm$json$Json$Decode$decodeValue, $author$project$JsInterop$Types$signInResponseDecoder, outsideInfo.c);
							if (!_v2.$) {
								var signInResponse = _v2.a;
								return tagger(
									$author$project$JsInterop$Plumbing$UserSignedIn(signInResponse));
							} else {
								var e = _v2.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'SignInResponseDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 2:
							var _v3 = A2($elm$json$Json$Decode$decodeValue, $author$project$JsInterop$Types$forgotPasswordResponseDecoder, outsideInfo.c);
							if (!_v3.$) {
								var forgotPasswordResponse = _v3.a;
								return tagger(
									$author$project$JsInterop$Plumbing$ForgotPasswordCode(forgotPasswordResponse));
							} else {
								var e = _v3.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'ForgotPasswordResponseDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 3:
							var _v4 = A2($elm$json$Json$Decode$decodeValue, $author$project$JsInterop$Types$forgotPasswordSubmitResponseDecoder, outsideInfo.c);
							if (!_v4.$) {
								var forgotPasswordSubmitResponse = _v4.a;
								return tagger(
									$author$project$JsInterop$Plumbing$ForgotPasswordSubmitResponse(forgotPasswordSubmitResponse));
							} else {
								var e = _v4.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'ForgotPasswordSubmitResponseDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 4:
							var _v5 = A2($elm$json$Json$Decode$decodeValue, $author$project$JsInterop$Types$signOutResponseDecoder, outsideInfo.c);
							if (!_v5.$) {
								var signOutResponse = _v5.a;
								return tagger(
									$author$project$JsInterop$Plumbing$SignOutResponse(signOutResponse));
							} else {
								var e = _v5.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'SignOutResponseDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 6:
							var _v6 = A2($elm$json$Json$Decode$decodeValue, $author$project$JsInterop$Types$getVariantSelectionResponseDecoder, outsideInfo.c);
							if (!_v6.$) {
								var getVariantSelectionResponse = _v6.a;
								return tagger(
									$author$project$JsInterop$Plumbing$GetVariantSelectionResponse_Mjd5(getVariantSelectionResponse));
							} else {
								var e = _v6.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'GetVariantSelectionResponse_Mjd5DecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 7:
							var _v7 = A2($elm$json$Json$Decode$decodeValue, $author$project$JsInterop$Types$getVariantSelectionResponseDecoder, outsideInfo.c);
							if (!_v7.$) {
								var getVariantSelectionResponse = _v7.a;
								return tagger(
									$author$project$JsInterop$Plumbing$GetVariantSelectionResponse_ShowVariant(getVariantSelectionResponse));
							} else {
								var e = _v7.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'GetVariantSelectionResponse_ShowVariantDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 8:
							var _v8 = A2($elm$json$Json$Decode$decodeValue, $author$project$JsInterop$Types$getVariantSelectionResponseDecoder, outsideInfo.c);
							if (!_v8.$) {
								var getVariantSelectionResponse = _v8.a;
								return tagger(
									$author$project$JsInterop$Plumbing$GetVariantSelectionResponse_ShowWorksheets(getVariantSelectionResponse));
							} else {
								var e = _v8.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'GetVariantSelectionResponse_ShowWorksheetsDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 9:
							var _v9 = A2($elm$json$Json$Decode$decodeValue, $author$project$JsInterop$Types$getVariantIdsResponseDecoder, outsideInfo.c);
							if (!_v9.$) {
								var getVariantIdsResponse = _v9.a;
								return tagger(
									$author$project$JsInterop$Plumbing$GetVariantIdsResponse(getVariantIdsResponse));
							} else {
								var e = _v9.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'GetVariantIdsResponseDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 10:
							var _v10 = A2($elm$json$Json$Decode$decodeValue, $author$project$JsInterop$Types$constructNodeForSyllabusResponseDecoder, outsideInfo.c);
							if (!_v10.$) {
								var constructNodeForSyllabusResponse = _v10.a;
								return tagger(
									$author$project$JsInterop$Plumbing$ConstructNodeForSyllabusResponse(constructNodeForSyllabusResponse));
							} else {
								var e = _v10.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'ConstructNodeForSyllabusResponseDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 11:
							var _v11 = A2($elm$json$Json$Decode$decodeValue, $author$project$JsInterop$Types$getSyllabusesResponseDecoder, outsideInfo.c);
							if (!_v11.$) {
								var getSyllabusResponse = _v11.a;
								return tagger(
									$author$project$JsInterop$Plumbing$GetSyllabusesResponse(getSyllabusResponse));
							} else {
								var e = _v11.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'GetSyllabusesResponseDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 5:
							var _v12 = A2(
								$elm$json$Json$Decode$decodeValue,
								$author$project$JsInterop$Types$personResponseDecoder(
									_List_fromArray(
										['data', 'me'])),
								outsideInfo.c);
							if (!_v12.$) {
								var meResponse = _v12.a;
								return tagger(
									$author$project$JsInterop$Plumbing$MeResponse(meResponse));
							} else {
								var e = _v12.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'PersonResponseDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 12:
							var _v13 = A2(
								$elm$json$Json$Decode$decodeValue,
								$author$project$JsInterop$Types$personsResponseDecoder(
									_List_fromArray(
										['data', 'teachers'])),
								outsideInfo.c);
							if (!_v13.$) {
								var personsResponse = _v13.a;
								return tagger(
									$author$project$JsInterop$Plumbing$PersonsResponse(personsResponse));
							} else {
								var e = _v13.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'PersonResponsesDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 13:
							var _v14 = A2(
								$elm$json$Json$Decode$decodeValue,
								$author$project$JsInterop$Types$personsResponseDecoder(
									_List_fromArray(
										['data', 'students'])),
								outsideInfo.c);
							if (!_v14.$) {
								var personsResponse = _v14.a;
								return tagger(
									$author$project$JsInterop$Plumbing$PersonsResponse(personsResponse));
							} else {
								var e = _v14.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'PersonResponsesDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 14:
							var _v15 = A2(
								$elm$json$Json$Decode$decodeValue,
								$author$project$JsInterop$Types$schoolsResponseDecoder(
									_List_fromArray(
										['data', 'bySId', 'items'])),
								outsideInfo.c);
							if (!_v15.$) {
								var schoolsResponse = _v15.a;
								return tagger(
									$author$project$JsInterop$Plumbing$SchoolsResponse(schoolsResponse));
							} else {
								var e = _v15.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'SchoolResponsesDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 15:
							var _v16 = A2(
								$elm$json$Json$Decode$decodeValue,
								$author$project$JsInterop$Types$nbrUsersForSchoolIdResponseDecoder(
									_List_fromArray(
										['data', 'bySId', 'items'])),
								outsideInfo.c);
							if (!_v16.$) {
								var nbrUsersForSchoolIdResponse = _v16.a;
								return tagger(
									$author$project$JsInterop$Plumbing$NbrUsersForSchoolIdResponse(nbrUsersForSchoolIdResponse));
							} else {
								var e = _v16.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'NbrUsersForSchoolIdDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 16:
							var _v17 = A2(
								$elm$json$Json$Decode$decodeValue,
								$author$project$JsInterop$Types$deleteSchoolResponseDecoder(
									_List_fromArray(
										['data', 'deleteEntity'])),
								outsideInfo.c);
							if (!_v17.$) {
								var deleteSchoolResponse = _v17.a;
								return tagger(
									$author$project$JsInterop$Plumbing$DeleteSchoolResponse(deleteSchoolResponse));
							} else {
								var e = _v17.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'deleteSchoolResponsDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 17:
							var _v18 = A2(
								$elm$json$Json$Decode$decodeValue,
								$author$project$JsInterop$Types$personsResponseDecoder(
									_List_fromArray(
										['data', 'studentsByTchId'])),
								outsideInfo.c);
							if (!_v18.$) {
								var personsResponse = _v18.a;
								return tagger(
									$author$project$JsInterop$Plumbing$PersonsResponse(personsResponse));
							} else {
								var e = _v18.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'PersonResponsesDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 18:
							var _v19 = A2(
								$elm$json$Json$Decode$decodeValue,
								$author$project$JsInterop$Types$personResponseDecoder(
									_List_fromArray(
										['data', 'updateTeacher'])),
								outsideInfo.c);
							if (!_v19.$) {
								var meResponse = _v19.a;
								return tagger(
									$author$project$JsInterop$Plumbing$PersonResponse(meResponse));
							} else {
								var e = _v19.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'PersonResponseDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 19:
							var _v20 = A2(
								$elm$json$Json$Decode$decodeValue,
								$author$project$JsInterop$Types$personResponseDecoder(
									_List_fromArray(
										['data', 'updateStudent'])),
								outsideInfo.c);
							if (!_v20.$) {
								var meResponse = _v20.a;
								return tagger(
									$author$project$JsInterop$Plumbing$PersonResponse(meResponse));
							} else {
								var e = _v20.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'PersonResponseDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 23:
							var _v21 = A2(
								$elm$json$Json$Decode$decodeValue,
								$author$project$JsInterop$Types$createUserResponseDecoder('createTeacher'),
								outsideInfo.c);
							if (!_v21.$) {
								var createUserResponse = _v21.a;
								return tagger(
									$author$project$JsInterop$Plumbing$CreateUserResponse(createUserResponse));
							} else {
								var e = _v21.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'CreateTeacherResponseDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 24:
							var _v22 = A2(
								$elm$json$Json$Decode$decodeValue,
								$author$project$JsInterop$Types$createUserResponseDecoder('createStudent'),
								outsideInfo.c);
							if (!_v22.$) {
								var createUserResponse = _v22.a;
								return tagger(
									$author$project$JsInterop$Plumbing$CreateUserResponse(createUserResponse));
							} else {
								var e = _v22.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'CreateStudentResponseDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 20:
							var _v23 = A2(
								$elm$json$Json$Decode$decodeValue,
								$author$project$JsInterop$Types$genericLoginUserResponseDecoder('updateLoginUser'),
								outsideInfo.c);
							if (!_v23.$) {
								var loginUserResponse = _v23.a;
								return tagger(
									$author$project$JsInterop$Plumbing$UpdateLoginUserResponse(loginUserResponse));
							} else {
								var e = _v23.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'UpdateLoginUserResponseDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 21:
							var _v24 = A2(
								$elm$json$Json$Decode$decodeValue,
								$author$project$JsInterop$Types$genericLoginUserResponseDecoder('deleteLoginUser'),
								outsideInfo.c);
							if (!_v24.$) {
								var loginUserResponse = _v24.a;
								return tagger(
									$author$project$JsInterop$Plumbing$DeleteLoginUserResponse(loginUserResponse));
							} else {
								var e = _v24.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'DeleteLoginUserResponseDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 22:
							var _v25 = A2(
								$elm$json$Json$Decode$decodeValue,
								$author$project$JsInterop$Types$genericLoginUserResponseDecoder('deleteStudentHistory'),
								outsideInfo.c);
							if (!_v25.$) {
								var loginUserResponse = _v25.a;
								return tagger(
									$author$project$JsInterop$Plumbing$DeleteStudentHistoryResponse(loginUserResponse));
							} else {
								var e = _v25.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'DeleteStudentHistoryResponseDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 37:
							var _v26 = A2(
								$elm$json$Json$Decode$decodeValue,
								$author$project$JsInterop$Types$schoolResponseDecoder(
									_List_fromArray(
										['data', 'createEntity'])),
								outsideInfo.c);
							if (!_v26.$) {
								var createSchoolResponse = _v26.a;
								return tagger(
									$author$project$JsInterop$Plumbing$SchoolResponse(createSchoolResponse));
							} else {
								var e = _v26.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'CreateSchoolResponseDecodeFailure',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 38:
							var _v27 = A2(
								$elm$json$Json$Decode$decodeValue,
								$author$project$JsInterop$Types$genericIdResponseDecoder(
									_List_fromArray(
										['data', 'createQuestionGroup'])),
								outsideInfo.c);
							if (!_v27.$) {
								var genericIdResponse = _v27.a;
								return tagger(
									$author$project$JsInterop$Plumbing$CreateQuestionGroupResponse(genericIdResponse));
							} else {
								var e = _v27.a;
								return onError(
									$author$project$JsInterop$Types$RecoverableInteropFailure(
										A4(
											$author$project$JsInterop$Types$InteropFailureDetails,
											'CreateQuestionGroupResponseDecodeFailure',
											0,
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						case 0:
							var _v28 = A2($elm$json$Json$Decode$decodeValue, $author$project$JsInterop$Types$failureSentFromJsToElmDecoder, outsideInfo.c);
							if (!_v28.$) {
								var e = _v28.a;
								return onError(e);
							} else {
								var e = _v28.a;
								return onError(
									$author$project$JsInterop$Types$FatalInteropFailure(
										A3(
											$author$project$JsInterop$Types$FatalInteropFailureDetails,
											'DecodeFailureInElm',
											$author$project$JsInterop$Types$errMsgForUser.g,
											$elm$json$Json$Decode$errorToString(e))));
							}
						default:
							return onError(
								$author$project$JsInterop$Types$FatalInteropFailure(
									A3($author$project$JsInterop$Types$FatalInteropFailureDetails, 'InteropTagNotImplementedInElm', $author$project$JsInterop$Types$errMsgForUser.g, 'InteropTag does not have an implementation in Elm')));
					}
				} else {
					var e = tagResult.a;
					return onError(
						$author$project$JsInterop$Types$FatalInteropFailure(
							A3(
								$author$project$JsInterop$Types$FatalInteropFailureDetails,
								'InteropTagDecodingFailed',
								$author$project$JsInterop$Types$errMsgForUser.g,
								$elm$json$Json$Decode$errorToString(e))));
				}
			});
	});
var $author$project$Ports$receiveStringIsAnswerCorrect = _Platform_incomingPort('receiveStringIsAnswerCorrect', $elm$json$Json$Decode$string);
var $zaboco$elm_draggable$Internal$DragAt = function (a) {
	return {$: 1, a: a};
};
var $zaboco$elm_draggable$Draggable$Msg = $elm$core$Basics$identity;
var $zaboco$elm_draggable$Internal$StopDragging = {$: 2};
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $elm$browser$Browser$Events$Document = 0;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {ii: pids, i3: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {g_: event, mo: key};
	});
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.ii,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.mo;
		var event = _v0.g_;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.i3);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onMouseMove = A2($elm$browser$Browser$Events$on, 0, 'mousemove');
var $elm$browser$Browser$Events$onMouseUp = A2($elm$browser$Browser$Events$on, 0, 'mouseup');
var $zaboco$elm_draggable$Internal$Position = F2(
	function (x, y) {
		return {jv: x, pS: y};
	});
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $elm$core$Basics$truncate = _Basics_truncate;
var $zaboco$elm_draggable$Draggable$positionDecoder = A3(
	$elm$json$Json$Decode$map2,
	$zaboco$elm_draggable$Internal$Position,
	A2(
		$elm$json$Json$Decode$map,
		$elm$core$Basics$truncate,
		A2($elm$json$Json$Decode$field, 'pageX', $elm$json$Json$Decode$float)),
	A2(
		$elm$json$Json$Decode$map,
		$elm$core$Basics$truncate,
		A2($elm$json$Json$Decode$field, 'pageY', $elm$json$Json$Decode$float)));
var $zaboco$elm_draggable$Draggable$subscriptions = F2(
	function (envelope, _v0) {
		var drag = _v0;
		if (!drag.$) {
			return $elm$core$Platform$Sub$none;
		} else {
			return A2(
				$elm$core$Platform$Sub$map,
				A2($elm$core$Basics$composeL, envelope, $elm$core$Basics$identity),
				$elm$core$Platform$Sub$batch(
					_List_fromArray(
						[
							$elm$browser$Browser$Events$onMouseMove(
							A2($elm$json$Json$Decode$map, $zaboco$elm_draggable$Internal$DragAt, $zaboco$elm_draggable$Draggable$positionDecoder)),
							$elm$browser$Browser$Events$onMouseUp(
							$elm$json$Json$Decode$succeed($zaboco$elm_draggable$Internal$StopDragging))
						])));
		}
	});
var $author$project$Subscriptions$subscriptions = function (model) {
	var base = _List_fromArray(
		[
			A2(
			$elm$core$Platform$Sub$map,
			$author$project$Msgs$MsgToolCalc,
			A2($zaboco$elm_draggable$Draggable$subscriptions, $author$project$UITool$Calculator$DragMsg, model.je.gX)),
			A2(
			$elm$core$Platform$Sub$map,
			$author$project$Msgs$MsgPageShowVariant,
			A2(
				$elm$core$Platform$Sub$map,
				$author$project$Page$ShowVariant$MsgToolCalc,
				A2($zaboco$elm_draggable$Draggable$subscriptions, $author$project$UITool$Calculator$DragMsg, model.no.je.gX))),
			$author$project$Ports$receiveMCIsAnswerCorrect($author$project$Msgs$OnAnswerMadeMC),
			$author$project$Ports$receiveStringIsAnswerCorrect($author$project$Msgs$OnAnswerMadeStr),
			$author$project$Ports$onActionTriggerRequest($author$project$Subscriptions$decodeActionTriggerRequest),
			$author$project$Ports$onDecodeContent($author$project$Msgs$OnDecodeContent),
			$author$project$Ports$onDecryptItemResponse($author$project$Subscriptions$decodeDecryptItemResponse),
			$author$project$Ports$onPutHiddenExamGroupsResponse($author$project$Subscriptions$decodePutHiddenExamGroupsSuccessFailResponse),
			$author$project$Ports$onDeleteHiddenExamGroupsResponse($author$project$Subscriptions$decodeDeleteHiddenExamGroupsSuccessFailResponse),
			$author$project$Ports$onGetHiddenExamGroupsResponse($author$project$Subscriptions$decodeGetHiddenExamGroups),
			$author$project$Ports$onGetSchoolNameResponse($author$project$Subscriptions$decodeGetSchoolNameResponse),
			$author$project$Ports$onUrlChange($author$project$Subscriptions$decodeUrlChange),
			$author$project$Ports$onRetrievePartialModelState($author$project$Subscriptions$decodePartialModelState),
			A2($author$project$JsInterop$Plumbing$receiveMessageForElm, $author$project$Msgs$OnInfoForElm, $author$project$Msgs$HandleFailure)
		]);
	var batch = (model.ln === 1) ? A2(
		$elm$core$List$cons,
		A2($elm$time$Time$every, 1000, $author$project$Msgs$Tick),
		base) : base;
	return $elm$core$Platform$Sub$batch(batch);
};
var $author$project$Type$ExamResult$ActivityRun = 1;
var $author$project$Page$CreateUsers$AssignableTeachersRetrieved = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$MutateUsers$AssignableTeachersRetrieved = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$Profile$AssignableTeachersRetrieved = function (a) {
	return {$: 13, a: a};
};
var $author$project$JsInterop$Plumbing$BySIdGQL = function (a) {
	return {$: 4, a: a};
};
var $author$project$Msgs$CreateWorksheet = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$MutateUsers$DeleteMode = 0;
var $author$project$Page$ManageSchools$DeleteSchoolResponse = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$ManageUsers$EditMode = 1;
var $author$project$Page$ForgotPassword$Failure = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$ForgotPasswordSubmit$Failure = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$SignIn$Failure = function (a) {
	return {$: 1, a: a};
};
var $author$project$Msgs$FinishExam = {$: 6};
var $author$project$Page$ShowVariant$GetNextVariantSelectionNEW = {$: 8};
var $author$project$Models$GoogleAnalyticsInitializationRecord = F4(
	function (userId, schoolName, userType, yearLvl) {
		return {n5: schoolName, pD: userId, pF: userType, pV: yearLvl};
	});
var $author$project$JsInterop$Plumbing$GraphQL = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Page$ShowWorksheet$InitializePage = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $author$project$Page$ShowWorksheets$InitializePage = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Msgs$LinkTo = F2(
	function (a, b) {
		return {$: 14, a: a, b: b};
	});
var $krisajenkins$remotedata$RemoteData$Loading = {$: 1};
var $author$project$JsInterop$Plumbing$LogError = function (a) {
	return {$: 0, a: a};
};
var $author$project$JsInterop$Plumbing$MeGQL = {$: 0};
var $author$project$Models$MrA = 1;
var $author$project$Msgs$MsgPageAdaptiveAnalytics = function (a) {
	return {$: 64, a: a};
};
var $author$project$Msgs$MsgPageCreateUsers = function (a) {
	return {$: 60, a: a};
};
var $author$project$Msgs$MsgPageForgotPassword = function (a) {
	return {$: 65, a: a};
};
var $author$project$Msgs$MsgPageForgotPasswordSubmit = function (a) {
	return {$: 66, a: a};
};
var $author$project$Msgs$MsgPageManageSchools = function (a) {
	return {$: 63, a: a};
};
var $author$project$Msgs$MsgPageManageUsers = function (a) {
	return {$: 61, a: a};
};
var $author$project$Msgs$MsgPageMjd5 = function (a) {
	return {$: 52, a: a};
};
var $author$project$Msgs$MsgPageMutateUsers = function (a) {
	return {$: 62, a: a};
};
var $author$project$Msgs$MsgPageProfile = function (a) {
	return {$: 58, a: a};
};
var $author$project$Msgs$MsgPageReportCard = function (a) {
	return {$: 59, a: a};
};
var $author$project$Msgs$MsgPageSample = function (a) {
	return {$: 67, a: a};
};
var $author$project$Msgs$MsgPageShowWorksheets = function (a) {
	return {$: 57, a: a};
};
var $author$project$Msgs$MsgPageSignIn = function (a) {
	return {$: 51, a: a};
};
var $author$project$Msgs$MsgPageStudentHome = function (a) {
	return {$: 53, a: a};
};
var $author$project$Page$MutateUsers$MutateUserResponse = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$ManageSchools$NbrUsersForSchoolIdResponse = function (a) {
	return {$: 8, a: a};
};
var $author$project$Type$AppCfg$None = 0;
var $author$project$Page$ShowVariant$OnGetEncodedVariantContent = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$AdminPage$OnGetVariantIdsResponse = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Mjd5$OnGetVariantSelectionResponse_Mjd5 = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$ShowVariant$OnGetVariantSelectionResponse_ShowVariant = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$ShowWorksheets$OnGetVariantSelectionResponse_ShowWorksheets = function (a) {
	return {$: 7, a: a};
};
var $author$project$JsInterop$Plumbing$OpGetSyllabuses = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Profile$PersonResponse = function (a) {
	return {$: 2, a: a};
};
var $author$project$JsInterop$Plumbing$PursBundleInterop = function (a) {
	return {$: 5, a: a};
};
var $author$project$Msgs$RecordStartTime = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$ManageSchools$SchoolResponse = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$ManageSchools$SchoolsResponse = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$ManageSchools$SetMySchoolIdResponse = function (a) {
	return {$: 11, a: a};
};
var $author$project$JsInterop$Plumbing$SignOut = {$: 4};
var $author$project$Page$ShowVariant$StartActivity = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Type$ExamResult$StudentData = function (history) {
	return {eZ: history};
};
var $author$project$JsInterop$Plumbing$StudentsByTchIdGQL = function (a) {
	return {$: 7, a: a};
};
var $author$project$JsInterop$Plumbing$StudentsByTchIdWithHstryGQL = function (a) {
	return {$: 8, a: a};
};
var $author$project$JsInterop$Plumbing$StudentsGQL = {$: 2};
var $author$project$Page$Profile$StudentsRetrieved = function (a) {
	return {$: 15, a: a};
};
var $author$project$JsInterop$Plumbing$StudentsWithHstryGQL = {$: 3};
var $author$project$Type$Configuration$TeacherPreviewMode = {$: 1};
var $author$project$JsInterop$Plumbing$TeachersGQL = {$: 1};
var $author$project$Page$ManageUsers$TeachersRetrieved = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Type$ExamResult$TestletGroupRun = 0;
var $author$project$Msgs$UpdateHiddenExamGroups = F4(
	function (a, b, c, d) {
		return {$: 46, a: a, b: b, c: c, d: d};
	});
var $author$project$Page$Profile$UpdateLoginUserResponse = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$CreateUsers$UserCreated = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Page$ManageUsers$UsersRetrieved = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $krisajenkins$remotedata$RemoteData$isNotAsked = function (data) {
	if (!data.$) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Update$log = F3(
	function (model, msg, el) {
		return model.lx.md ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Nothing;
	});
var $author$project$Msgs$OnGetPost = function (a) {
	return {$: 28, a: a};
};
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.oy));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {iC: reqs, i3: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.pq;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.iC));
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.i3)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					jV: r.jV,
					kd: r.kd,
					lp: A2(_Http_mapExpect, func, r.lp),
					lM: r.lM,
					mE: r.mE,
					pd: r.pd,
					pq: r.pq,
					py: r.py
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{jV: false, kd: r.kd, lp: r.lp, lM: r.lM, mE: r.mE, pd: r.pd, pq: r.pq, py: r.py}));
};
var $elm$http$Http$get = function (r) {
	return $elm$http$Http$request(
		{kd: $elm$http$Http$emptyBody, lp: r.lp, lM: _List_Nil, mE: 'GET', pd: $elm$core$Maybe$Nothing, pq: $elm$core$Maybe$Nothing, py: r.py});
};
var $author$project$Type$Post$Post = F3(
	function (id, title, content) {
		return {kF: content, lW: id, ja: title};
	});
var $author$project$Type$Post$postDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Type$Post$Post,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$field,
		'title',
		A2($elm$json$Json$Decode$field, 'rendered', $elm$json$Json$Decode$string)),
	A2(
		$elm$json$Json$Decode$field,
		'content',
		A2($elm$json$Json$Decode$field, 'rendered', $elm$json$Json$Decode$string)));
var $author$project$Update$sendGetPost = F2(
	function (urlPosts, postId) {
		return $elm$http$Http$get(
			{
				lp: A2($elm$http$Http$expectJson, $author$project$Msgs$OnGetPost, $author$project$Type$Post$postDecoder),
				py: urlPosts + ($elm$core$String$fromInt(postId) + '.json')
			});
	});
var $author$project$Update$addGetPostCmdIfAppropriate = function (_v0) {
	var model = _v0.a;
	var cmd = _v0.b;
	var postIdMaybe = A2(
		$elm$core$Maybe$andThen,
		function (el) {
			return A2($elm$core$Array$get, el, model.nI);
		},
		A2(
			$elm$core$Maybe$map,
			function (el) {
				return el - 1;
			},
			model.kS));
	var _v1 = A3($author$project$Update$log, model, 'model.currPost is ', model.gL);
	if (postIdMaybe.$ === 1) {
		return _Utils_Tuple2(model, cmd);
	} else {
		var postId = postIdMaybe.a;
		return $krisajenkins$remotedata$RemoteData$isNotAsked(model.gL) ? _Utils_Tuple2(
			_Utils_update(
				model,
				{gL: $krisajenkins$remotedata$RemoteData$Loading}),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						cmd,
						A2($author$project$Update$sendGetPost, model.lx.pz, postId)
					]))) : _Utils_Tuple2(model, cmd);
	}
};
var $author$project$Type$ActivityDataGroup$asActivityResultRecordsIn = F2(
	function (activityDataGroup, activityResultRecords) {
		return _Utils_update(
			activityDataGroup,
			{cy: activityResultRecords});
	});
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$DataService$qGroupForBBRREncoder = function (qGroup) {
	var attributes = _List_fromArray(
		[
			_Utils_Tuple2(
			'id',
			$elm$json$Json$Encode$int(qGroup.lW)),
			_Utils_Tuple2(
			'lvl',
			$elm$json$Json$Encode$int(qGroup.hB)),
			_Utils_Tuple2(
			'ord',
			$elm$json$Json$Encode$int(qGroup.nj)),
			_Utils_Tuple2(
			'typ',
			function () {
				var _v0 = qGroup.pt;
				if (!_v0) {
					return $elm$json$Json$Encode$string('exam');
				} else {
					return $elm$json$Json$Encode$string('strand');
				}
			}()),
			_Utils_Tuple2(
			'name',
			$elm$json$Json$Encode$string(qGroup.mO))
		]);
	return $elm$json$Json$Encode$object(attributes);
};
var $author$project$DataService$studentResultResForBBRREncoder = function (srRes) {
	var attributes = _List_fromArray(
		[
			_Utils_Tuple2(
			'qGroupId',
			$elm$json$Json$Encode$int(srRes.nF)),
			_Utils_Tuple2(
			'tsFinish',
			$elm$json$Json$Encode$string(srRes.pr)),
			_Utils_Tuple2(
			'bnd',
			$elm$json$Json$Encode$int(srRes.kb))
		]);
	return $elm$json$Json$Encode$object(attributes);
};
var $author$project$DataService$studentResultForBBRREncoder = function (studRes) {
	var attributes = _List_fromArray(
		[
			_Utils_Tuple2(
			'usrSub',
			$elm$json$Json$Encode$string(studRes.pH)),
			_Utils_Tuple2(
			'lvl',
			$elm$json$Json$Encode$int(studRes.hB)),
			_Utils_Tuple2(
			'fNam',
			$elm$json$Json$Encode$string(studRes.lt)),
			_Utils_Tuple2(
			'lNam',
			$elm$json$Json$Encode$string(studRes.mq)),
			_Utils_Tuple2(
			'res',
			A2($elm$json$Json$Encode$list, $author$project$DataService$studentResultResForBBRREncoder, studRes.nR))
		]);
	return $elm$json$Json$Encode$object(attributes);
};
var $author$project$DataService$bandByResultsRecordEncoder = function (bbrr) {
	var attributes = _List_fromArray(
		[
			_Utils_Tuple2(
			'qGroups',
			A2($elm$json$Json$Encode$list, $author$project$DataService$qGroupForBBRREncoder, bbrr.nH)),
			_Utils_Tuple2(
			'studentResults',
			A2($elm$json$Json$Encode$list, $author$project$DataService$studentResultForBBRREncoder, bbrr.oF))
		]);
	return $elm$json$Json$Encode$object(attributes);
};
var $author$project$Ports$browserBack = _Platform_outgoingPort(
	'browserBack',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Update$cleanAnyPreviousError = function (model) {
	return _Utils_update(
		model,
		{ll: $elm$core$Maybe$Nothing});
};
var $author$project$Type$Testlet$isLegacyQGroupId = function (qGroupId) {
	return !((3000 <= qGroupId) && (qGroupId <= 3999));
};
var $author$project$Helper$AnalysisByBand$createBBRQGroups = F3(
	function (qGroups, questionGroupVersion, yearLvl) {
		return A2(
			$elm$core$List$map,
			function (el) {
				return {lW: el.lW, hB: el.hB, mO: el.iv.mO, nj: el.nj, pt: el.iv.pt};
			},
			A2(
				$elm$core$List$filter,
				function () {
					if (!questionGroupVersion) {
						return A2(
							$elm$core$Basics$composeL,
							$author$project$Type$Testlet$isLegacyQGroupId,
							function ($) {
								return $.lW;
							});
					} else {
						return A2(
							$elm$core$Basics$composeL,
							A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Type$Testlet$isLegacyQGroupId),
							function ($) {
								return $.lW;
							});
					}
				}(),
				A2(
					$elm$core$List$filter,
					function (el) {
						return _Utils_eq(el.hB, yearLvl);
					},
					qGroups)));
	});
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Helper$Band$getBandForYearLvl = F2(
	function (qsMetaItem, yearLvl) {
		var itemForYearLvl = A2(
			$elm$core$List$filter,
			function (el) {
				return _Utils_eq(el.pS, yearLvl);
			},
			qsMetaItem.kb);
		return ($elm$core$List$length(itemForYearLvl) === 1) ? A2(
			$elm$core$Maybe$withDefault,
			-1,
			$elm$core$List$head(
				A2(
					$elm$core$List$map,
					function ($) {
						return $.gl;
					},
					itemForYearLvl))) : A2(
			$elm$core$Maybe$withDefault,
			-1,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.gl;
				},
				$elm$core$List$head(qsMetaItem.kb)));
	});
var $author$project$Helper$Band$getBandForQId = F3(
	function (qsMetas, yearLvlMaybe, qId) {
		var bndMetaMaybe = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (el) {
					return _Utils_eq(el.lW, qId);
				},
				qsMetas));
		if (bndMetaMaybe.$ === 1) {
			return -1;
		} else {
			var bndMeta = bndMetaMaybe.a;
			return A2(
				$author$project$Helper$Band$getBandForYearLvl,
				bndMeta,
				A2($elm$core$Maybe$withDefault, 9, yearLvlMaybe));
		}
	});
var $author$project$Type$AppCfg$getLvlForQGroupId = F2(
	function (questionGroups, testletGroupId) {
		return A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.hB;
			},
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (el) {
						return _Utils_eq(el.lW, testletGroupId);
					},
					questionGroups)));
	});
var $author$project$Type$AppCfg$getQGroupMaybe = F2(
	function (appCfg, qGroupId) {
		return $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.lW;
					},
					$elm$core$Basics$eq(qGroupId)),
				appCfg.nH));
	});
var $author$project$Type$AppCfg$getScoreThresholdsForTestlet = F3(
	function (appCfg, qGroupId, finishTestletId) {
		var getScoreThresholds = function (bandCalculationSet) {
			if (!bandCalculationSet.$) {
				return $elm$core$Maybe$Nothing;
			} else {
				var scoreThresholds = bandCalculationSet.a;
				return $elm$core$Maybe$Just(scoreThresholds);
			}
		};
		var bandCalculationSetMaybe = A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.j6;
			},
			A2(
				$elm$core$Maybe$andThen,
				function (els) {
					return $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (el) {
								return _Utils_eq(el.oW, finishTestletId);
							},
							els));
				},
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.lJ;
					},
					A2($author$project$Type$AppCfg$getQGroupMaybe, appCfg, qGroupId))));
		return A2($elm$core$Maybe$andThen, getScoreThresholds, bandCalculationSetMaybe);
	});
var $author$project$Helper$Band$getYearLvlMaybeForQGroupId = F2(
	function (qGroups, qGroupId) {
		return A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.hB;
			},
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (el) {
						return _Utils_eq(el.lW, qGroupId);
					},
					qGroups)));
	});
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $author$project$Helper$Band$singleExamRunBandString = F2(
	function (appCfg, singleExamRunResult) {
		var lvlMaybe = A2(
			$elm$core$Maybe$map,
			$elm$core$String$fromInt,
			A2($author$project$Type$AppCfg$getLvlForQGroupId, appCfg.nH, singleExamRunResult.nF));
		var getBandValue = function (qId) {
			return function (yearLvlMaybe) {
				return A3($author$project$Helper$Band$getBandForQId, appCfg.iw, yearLvlMaybe, qId);
			}(
				A2($author$project$Helper$Band$getYearLvlMaybeForQGroupId, appCfg.nH, singleExamRunResult.nF));
		};
		var getBandDecodes = function (testletGroupResultRecord) {
			return A3($author$project$Type$AppCfg$getScoreThresholdsForTestlet, appCfg, testletGroupResultRecord.nF, testletGroupResultRecord.g3);
		};
		var getBandLevel = F2(
			function (testletGroupResultRecord, score) {
				return function (bandStringMaybe) {
					return _Utils_Tuple2(lvlMaybe, bandStringMaybe);
				}(
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$second,
						A2(
							$elm$core$Maybe$andThen,
							A2($elm$core$Basics$composeL, $elm$core$List$head, $elm$core$List$reverse),
							A2(
								$elm$core$Maybe$map,
								$elm$core$List$filter(
									A2(
										$elm$core$Basics$composeL,
										$elm$core$Basics$ge(score),
										$elm$core$Tuple$first)),
								getBandDecodes(testletGroupResultRecord)))));
			});
		return A2(
			getBandLevel,
			singleExamRunResult,
			$elm$core$List$sum(
				A2(
					$elm$core$List$map,
					A2(
						$elm$core$Basics$composeL,
						getBandValue,
						function ($) {
							return $.dS;
						}),
					A2(
						$elm$core$List$filter,
						function (el) {
							return el.mg && (el.dW === 2);
						},
						singleExamRunResult.ci))));
	});
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $author$project$Helper$Band$getCorrectAnswersListForBandCalculations = function (historyItem) {
	return A2(
		$elm$core$List$filter,
		function (el) {
			return el.mg && (el.dW === 2);
		},
		historyItem.ci);
};
var $author$project$Helper$Band$singleExamRunLegacyBandResult = F2(
	function (appCfg, singleExamRunResult) {
		var yearLvlMaybe = A2($author$project$Helper$Band$getYearLvlMaybeForQGroupId, appCfg.nH, singleExamRunResult.nF);
		var getTestletRuleset = F2(
			function (testletId, groupRuleset) {
				return A2(
					$elm$core$Maybe$andThen,
					function (el) {
						if (!el.$) {
							var bandRuleset = el.a;
							return $elm$core$Maybe$Just(bandRuleset);
						} else {
							return $elm$core$Maybe$Nothing;
						}
					},
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.j6;
						},
						$elm$core$List$head(
							A2(
								$elm$core$List$filter,
								function (el) {
									return _Utils_eq(el.oW, testletId);
								},
								groupRuleset))));
			});
		var getGroupRuleset = F2(
			function (appCfg1, singleExamRunResult1) {
				return A2(
					$elm$core$Maybe$andThen,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.lJ;
						},
						$elm$core$Maybe$Just),
					$elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (el) {
								return _Utils_eq(el.lW, singleExamRunResult1.nF);
							},
							appCfg1.nH)));
			});
		var getCorrectNbrQsForBand = F2(
			function (singleExamRunResult1, bnd) {
				return $elm$core$List$length(
					A2(
						$elm$core$List$filter,
						function (el) {
							return _Utils_eq(
								bnd,
								A3($author$project$Helper$Band$getBandForQId, appCfg.iw, yearLvlMaybe, el.dS));
						},
						$author$project$Helper$Band$getCorrectAnswersListForBandCalculations(singleExamRunResult1)));
			});
		var isBndCndtnTrue = function (bndCndtn) {
			return _Utils_cmp(
				A2(getCorrectNbrQsForBand, singleExamRunResult, bndCndtn.kb),
				bndCndtn.mG) > -1;
		};
		var isBndCndtnsSatisfied = function (bndCndtns) {
			return A2($elm$core$List$all, isBndCndtnTrue, bndCndtns);
		};
		var isConditionsSatisfied = function (bandRuleset) {
			return isBndCndtnsSatisfied(bandRuleset.kc) ? $elm$core$Maybe$Just(bandRuleset.mF) : $elm$core$Maybe$Nothing;
		};
		var achievedBands = A2(
			$elm$core$Maybe$andThen,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$List$filterMap(isConditionsSatisfied),
				$elm$core$Maybe$Just),
			A2(
				$elm$core$Maybe$andThen,
				getTestletRuleset(singleExamRunResult.g3),
				A2(getGroupRuleset, appCfg, singleExamRunResult)));
		return A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$andThen, $elm$core$List$maximum, achievedBands));
	});
var $author$project$Helper$Band$singleExamRunBandResult = F2(
	function (appCfg, singleExamRunResult) {
		if ($author$project$Type$Testlet$isLegacyQGroupId(singleExamRunResult.nF)) {
			return A2($author$project$Helper$Band$singleExamRunLegacyBandResult, appCfg, singleExamRunResult);
		} else {
			var toBandFromBandString = A2(
				$elm$core$Basics$composeR,
				$elm$core$String$dropRight(1),
				$elm$core$String$toInt);
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				A2(
					$elm$core$Maybe$andThen,
					toBandFromBandString,
					A2($author$project$Helper$Band$singleExamRunBandString, appCfg, singleExamRunResult).b));
		}
	});
var $author$project$Helper$AnalysisByBand$createBBRStudentResultRes = F2(
	function (appCfg, hstryItem) {
		return {
			kb: A2($author$project$Helper$Band$singleExamRunBandResult, appCfg, hstryItem),
			nF: hstryItem.nF,
			pr: hstryItem.pr
		};
	});
var $author$project$Helper$AnalysisByBand$createBBRStudentResult = F2(
	function (appCfg, preStudentResult) {
		var res = A2(
			$elm$core$List$map,
			$author$project$Helper$AnalysisByBand$createBBRStudentResultRes(appCfg),
			preStudentResult.g2);
		return {lt: preStudentResult.lt, mq: preStudentResult.mq, hB: preStudentResult.hB, nR: res, pH: preStudentResult.pH};
	});
var $author$project$Type$ExamResult$filterTestletGroupResultRecords = function (serr) {
	if (!serr.$) {
		var testletGroupResultRecord = serr.a;
		return $elm$core$Maybe$Just(testletGroupResultRecord);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Type$Cohort$toInt = function (cohort) {
	if (!cohort.$) {
		switch (cohort.a.$) {
			case 0:
				if (!cohort.a.a) {
					var _v1 = cohort.a.a;
					return 3;
				} else {
					var _v2 = cohort.a.a;
					return 4;
				}
			case 1:
				if (!cohort.a.a) {
					var _v3 = cohort.a.a;
					return 5;
				} else {
					var _v4 = cohort.a.a;
					return 6;
				}
			case 2:
				if (!cohort.a.a) {
					var _v5 = cohort.a.a;
					return 7;
				} else {
					var _v6 = cohort.a.a;
					return 8;
				}
			default:
				if (!cohort.a.a) {
					var _v7 = cohort.a.a;
					return 9;
				} else {
					var _v8 = cohort.a.a;
					return 10;
				}
		}
	} else {
		return 20;
	}
};
var $author$project$Helper$AnalysisByBand$createPreBBRStudentResult = function (assignee) {
	return {
		lt: assignee.dc.lt,
		g2: A2($elm$core$List$filterMap, $author$project$Type$ExamResult$filterTestletGroupResultRecords, assignee.lU),
		mq: assignee.dc.mq,
		hB: A2(
			$elm$core$Maybe$withDefault,
			9,
			A2($elm$core$Maybe$map, $author$project$Type$Cohort$toInt, assignee.dc.hB)),
		pH: assignee.dc.pH
	};
};
var $author$project$Helper$AnalysisByBand$constructBandByResultsRecord = F4(
	function (appCfg, questionGroupVersion, yearLvl, assignees) {
		var studentResults = A2(
			$elm$core$List$map,
			$author$project$Helper$AnalysisByBand$createBBRStudentResult(appCfg),
			A2($elm$core$List$map, $author$project$Helper$AnalysisByBand$createPreBBRStudentResult, assignees));
		var qGroupsBBRR = A3($author$project$Helper$AnalysisByBand$createBBRQGroups, appCfg.nH, questionGroupVersion, yearLvl);
		return {nH: qGroupsBBRR, oF: studentResults};
	});
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {l1: index, mA: match, m5: number, i2: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$core$String$filter = _String_filter;
var $author$project$Routing$intsAsJsonString = function (ints) {
	return A2(
		$elm$json$Json$Encode$encode,
		0,
		A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, ints));
};
var $elm$core$Elm$JsArray$foldl = _JsArray_foldl;
var $elm$core$Array$foldl = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldl, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldl, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldl,
			func,
			A3($elm$core$Elm$JsArray$foldl, helper, baseCase, tree),
			tail);
	});
var $elm$json$Json$Encode$array = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$Array$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $author$project$Type$ExamResult$encodeStrMaybe = function (strMaybe) {
	if (strMaybe.$ === 1) {
		return $elm$json$Json$Encode$null;
	} else {
		var str = strMaybe.a;
		return $elm$core$String$isEmpty(str) ? $elm$json$Json$Encode$null : $elm$json$Json$Encode$string(
			$elm$url$Url$percentEncode(str));
	}
};
var $author$project$Routing$stringMaybesAsJsonString = function (strs) {
	return A2(
		$elm$json$Json$Encode$encode,
		0,
		A2($elm$json$Json$Encode$array, $author$project$Type$ExamResult$encodeStrMaybe, strs));
};
var $author$project$Routing$qASubRouteToString = function (qASubRoute) {
	switch (qASubRoute.$) {
		case 0:
			var _int = qASubRoute.a;
			return 'MultiChoiceSingleAnswerRoute' + $elm$core$String$fromInt(_int);
		case 1:
			var ints = qASubRoute.a;
			return 'MultiChoiceMultipleAnswerRoute' + $author$project$Routing$intsAsJsonString(ints);
		case 2:
			var strs = qASubRoute.a;
			return 'AnswerBoxAnswerRoute' + $author$project$Routing$stringMaybesAsJsonString(strs);
		case 3:
			var string = qASubRoute.a;
			return 'SingleDataBoxRouteV1' + string;
		default:
			var string1 = qASubRoute.a;
			var string2 = qASubRoute.b;
			return 'NoDetailSubRoute' + (string1 + string2);
	}
};
var $author$project$Routing$routeToString = function (route) {
	switch (route.$) {
		case 0:
			return 'HomeRoute';
		case 1:
			var i = route.a;
			return 'CurrentQuestionRoute/' + $elm$core$String$fromInt(i);
		case 2:
			var i = route.a;
			var string1 = route.b;
			var qASubRoute = route.c;
			return 'QuestionAndAnswerRoute/' + (i + ('/' + (string1 + ('/' + $author$project$Routing$qASubRouteToString(qASubRoute)))));
		case 3:
			var i = route.a;
			return 'QuestionGroupInstructionsRoute/' + $elm$core$String$fromInt(i);
		case 4:
			return 'CurrentExamStatsRoute';
		case 5:
			return 'ConfirmSectionRoute';
		case 6:
			var str = route.a;
			return 'SampleTestsRoute/' + str;
		case 7:
			return 'RawDataRoute';
		case 8:
			return 'ConfigureHiddenExamsRoute';
		case 9:
			return 'AnalyticsListRoute';
		case 10:
			var chartType = route.a;
			return 'AnalyticsItemRoute/' + $author$project$SharedTypes$chartTypeToString(chartType);
		case 11:
			return 'RawDataDownloadRoute';
		case 29:
			var string1 = route.a;
			var stringMaybe = route.b;
			return 'StudentResultsRoute/' + (string1 + ('/' + A2($elm$core$Maybe$withDefault, '-', stringMaybe)));
		case 12:
			var studentEId = route.a;
			return 'ReportCardRoute/' + studentEId;
		case 30:
			var string1 = route.a;
			var string2 = route.b;
			return 'SessionsResultsRoute/' + (string1 + ('/' + string2));
		case 13:
			return 'Mjd5Route';
		case 14:
			return 'StudentHomeRoute';
		case 15:
			return 'AdminPageRoute';
		case 16:
			return 'ShowVariantRoute';
		case 17:
			var stringMaybe = route.a;
			return 'ProfileRoute/' + A2($elm$core$Maybe$withDefault, '', stringMaybe);
		case 18:
			return 'SignInRoute';
		case 19:
			return 'ForgotPasswordRoute';
		case 20:
			var string1 = route.a;
			return 'ForgotPasswordSubmitRoute/' + string1;
		case 21:
			var routeType = route.a;
			return 'CreateUsersRoute/' + $author$project$Routing$userRouteTypeToString(routeType);
		case 22:
			var routeType = route.a;
			return 'EditUsersRoute/' + $author$project$Routing$userRouteTypeToString(routeType);
		case 23:
			return 'ViewStudentsRoute';
		case 24:
			var routeType = route.a;
			return 'DeleteUsersRoute/' + $author$project$Routing$userRouteTypeToString(routeType);
		case 25:
			var routeType = route.a;
			return 'UpdateUsersRoute/' + $author$project$Routing$userRouteTypeToString(routeType);
		case 26:
			return 'ManageSchoolsRoute';
		case 27:
			return 'AdaptiveAnalyticsRoute';
		case 28:
			return 'SampleRoute';
		case 31:
			var string1 = route.a;
			var string2 = route.b;
			var string3 = route.c;
			return 'SingleSessionResultsRoute/' + (string1 + ('/' + (string2 + ('/' + string3))));
		case 32:
			return 'ShowWorksheetRoute';
		case 33:
			return 'ShowWorksheetsRoute';
		case 34:
			return 'FatalErrorRoute';
		default:
			return 'NotFoundRoute';
	}
};
var $elm$core$String$words = _String_words;
var $author$project$Type$GoogleAnalytics$createGoogleAnalyticsRecord = function (route) {
	return $elm$core$String$words(
		A2(
			$elm$core$String$filter,
			$elm$core$Basics$neq('\"'),
			$author$project$Routing$routeToString(route)));
};
var $author$project$Routing$withHash = function (p) {
	return '/' + p;
};
var $author$project$Routing$createPath = function (chartType) {
	return $author$project$Routing$withHash($author$project$Routing$smPaths.cC) + ('/' + $author$project$SharedTypes$chartTypeToString(chartType));
};
var $elm$core$Maybe$map4 = F5(
	function (func, ma, mb, mc, md) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				if (mc.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var c = mc.a;
					if (md.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var d = md.a;
						return $elm$core$Maybe$Just(
							A4(func, a, b, c, d));
					}
				}
			}
		}
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $author$project$Type$DateTime$posixToDecSecsFloat = function (posix) {
	return function (el) {
		return el / 1000;
	}(
		$elm$time$Time$posixToMillis(posix));
};
var $author$project$Type$DateTime$posixToDecSecsString = function (posix) {
	return $elm$core$String$fromFloat(
		$author$project$Type$DateTime$posixToDecSecsFloat(posix));
};
var $author$project$Helper$Common$createActivityRunResult = F2(
	function (_v0, worksheetTypeMaybe) {
		var pageModel_ShowVariant = _v0.no;
		var startTimeRaw = _v0.ox;
		var nowTimeRaw = _v0.hU;
		var aMode = function () {
			if (startTimeRaw.$ === 1) {
				if ((!worksheetTypeMaybe.$) && (!worksheetTypeMaybe.a)) {
					var _v3 = worksheetTypeMaybe.a;
					return $author$project$Type$Activity$Offline(0);
				} else {
					return $author$project$Type$Activity$Offline(1);
				}
			} else {
				var startTimeRaw_ = startTimeRaw.a;
				return $author$project$Type$Activity$Online(
					$author$project$Type$DateTime$posixToDecSecsString(startTimeRaw_));
			}
		}();
		return A5(
			$elm$core$Maybe$map4,
			$author$project$Type$ExamResult$ActivityResultRecord,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.lW;
				},
				pageModel_ShowVariant.f8),
			$elm$core$Maybe$Just(aMode),
			A2($elm$core$Maybe$map, $author$project$Type$DateTime$posixToDecSecsString, nowTimeRaw),
			$elm$core$Maybe$Just(pageModel_ShowVariant.oe));
	});
var $elm$core$Maybe$map5 = F6(
	function (func, ma, mb, mc, md, me) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				if (mc.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var c = mc.a;
					if (md.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var d = md.a;
						if (me.$ === 1) {
							return $elm$core$Maybe$Nothing;
						} else {
							var e = me.a;
							return $elm$core$Maybe$Just(
								A5(func, a, b, c, d, e));
						}
					}
				}
			}
		}
	});
var $author$project$Helper$Common$createTesletGroupRunResult = function (_v0) {
	var currentTestletsGroupId = _v0.kU;
	var currentTestletId = _v0.kT;
	var startTimeRaw = _v0.ox;
	var nowTimeRaw = _v0.hU;
	var qIds = _v0.nI;
	var currentQGroupAnswerHistory = _v0.kR;
	var createHistoryItem = F2(
		function (qId, ansHist) {
			return {mg: ansHist.mg, dS: qId, iu: ansHist.jD, dW: ansHist.jC};
		});
	var answerHistory = A3(
		$elm$core$List$map2,
		createHistoryItem,
		$elm$core$Array$toList(qIds),
		$elm$core$Array$toList(currentQGroupAnswerHistory));
	return A6(
		$elm$core$Maybe$map5,
		$author$project$Type$ExamResult$TestletGroupResultRecord,
		currentTestletsGroupId,
		currentTestletId,
		A2($elm$core$Maybe$map, $author$project$Type$DateTime$posixToDecSecsString, startTimeRaw),
		A2($elm$core$Maybe$map, $author$project$Type$DateTime$posixToDecSecsString, nowTimeRaw),
		$elm$core$Maybe$Just(answerHistory));
};
var $author$project$Helper$Common$getExamRunType = function (mdl) {
	var _v0 = _Utils_Tuple2(mdl.kU, mdl.no.f8);
	_v0$2:
	while (true) {
		if (!_v0.a.$) {
			if (_v0.b.$ === 1) {
				var _v1 = _v0.b;
				return 0;
			} else {
				break _v0$2;
			}
		} else {
			if (!_v0.b.$) {
				var _v2 = _v0.a;
				return 1;
			} else {
				break _v0$2;
			}
		}
	}
	return 0;
};
var $author$project$Helper$Common$createSingleExamRunResultRecord = F2(
	function (model, worksheetTypeMaybe) {
		var examRunType = $author$project$Helper$Common$getExamRunType(model);
		if (!examRunType) {
			return A2(
				$elm$core$Maybe$map,
				$author$project$Type$ExamResult$TestletGroupRunResult,
				$author$project$Helper$Common$createTesletGroupRunResult(model));
		} else {
			return A2(
				$elm$core$Maybe$map,
				$author$project$Type$ExamResult$ActivityRunResult,
				A2($author$project$Helper$Common$createActivityRunResult, model, worksheetTypeMaybe));
		}
	});
var $author$project$Ports$decodeContent = _Platform_outgoingPort('decodeContent', $elm$json$Json$Encode$string);
var $author$project$Update$defaultQAnswerValues = F2(
	function (isMkd, currentTestletId) {
		return {jC: 0, jD: $elm$core$Maybe$Nothing, mg: isMkd, mi: false, mj: false, oW: currentTestletId};
	});
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $author$project$Type$User$extractCohortFromPersonE = function (personE) {
	return A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.kA;
		},
		$author$project$Type$User$extractStudentEFromPersonE(personE));
};
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $author$project$Helper$Common$escapeNode = function (node) {
	switch (node.$) {
		case 0:
			var s = node.a;
			return $author$project$Html$Parser$Text(
				A3($elm$core$String$replace, '<', '&lt;', s));
		case 1:
			var s = node.a;
			var attrs = node.b;
			var children = node.c;
			return A3(
				$author$project$Html$Parser$Element,
				s,
				attrs,
				A2($elm$core$List$map, $author$project$Helper$Common$escapeNode, children));
		default:
			return node;
	}
};
var $elm$core$Tuple$mapBoth = F3(
	function (funcA, funcB, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			funcA(x),
			funcB(y));
	});
var $author$project$Html$Parser$commentToString = function (comment_) {
	return '<!--' + (comment_ + '-->');
};
var $author$project$Html$Parser$elementToString = F3(
	function (name, attributes, children) {
		var attributeToString = function (_v2) {
			var attr = _v2.a;
			var value = _v2.b;
			return attr + ('=\"' + (value + '\"'));
		};
		var maybeAttributes = function () {
			if (!attributes.b) {
				return '';
			} else {
				return ' ' + A2(
					$elm$core$String$join,
					' ',
					A2($elm$core$List$map, attributeToString, attributes));
			}
		}();
		return $author$project$Html$Parser$isVoidElement(name) ? $elm$core$String$concat(
			_List_fromArray(
				['<', name, maybeAttributes, '>'])) : $elm$core$String$concat(
			_List_fromArray(
				[
					'<',
					name,
					maybeAttributes,
					'>',
					A2(
					$elm$core$String$join,
					'',
					A2($elm$core$List$map, $author$project$Html$Parser$nodeToString, children)),
					'</',
					name,
					'>'
				]));
	});
var $author$project$Html$Parser$nodeToString = function (node_) {
	switch (node_.$) {
		case 0:
			var text_ = node_.a;
			return text_;
		case 1:
			var name = node_.a;
			var attributes = node_.b;
			var children = node_.c;
			return A3($author$project$Html$Parser$elementToString, name, attributes, children);
		default:
			var comment_ = node_.a;
			return $author$project$Html$Parser$commentToString(comment_);
	}
};
var $author$project$Helper$Common$extractContentBetweenStrings = F3(
	function (from, to, htmlStr) {
		var updatePair = F2(
			function (str, pOfBoolList) {
				return A2($elm$core$String$contains, from, str) ? A3(
					$elm$core$Tuple$mapBoth,
					$elm$core$Basics$always(false),
					$elm$core$List$cons(str),
					pOfBoolList) : ((A2($elm$core$String$contains, from, str) || (A2($elm$core$String$contains, to, str) || pOfBoolList.a)) ? A3(
					$elm$core$Tuple$mapBoth,
					$elm$core$Basics$always(true),
					$elm$core$List$cons(str),
					pOfBoolList) : A2(
					$elm$core$Tuple$mapFirst,
					$elm$core$Basics$always(false),
					pOfBoolList));
			});
		var htmlStrResult = A2(
			$elm$core$Result$mapError,
			$author$project$Type$Error$deadEndsToString,
			A2(
				$elm$core$Result$map,
				A2($elm$core$List$foldr, $elm$core$Basics$append, ''),
				A2(
					$elm$core$Result$map,
					$elm$core$Tuple$second,
					A2(
						$elm$core$Result$map,
						A2(
							$elm$core$List$foldr,
							updatePair,
							A2($elm$core$Tuple$pair, false, _List_Nil)),
						A2(
							$elm$core$Result$map,
							$elm$core$List$map($author$project$Html$Parser$nodeToString),
							A2(
								$elm$core$Result$map,
								$elm$core$List$map($author$project$Helper$Common$escapeNode),
								$author$project$Html$Parser$run(htmlStr)))))));
		if (!htmlStrResult.$) {
			var content = htmlStrResult.a;
			return content;
		} else {
			var techDetailsString = htmlStrResult.a;
			return techDetailsString;
		}
	});
var $author$project$Helper$Common$extractContent = F2(
	function (post, contentSection) {
		var extractContentTag = F2(
			function (postString, contentSection1) {
				var tagInner = function () {
					switch (contentSection1) {
						case 0:
							return 'q';
						case 1:
							return 'a';
						default:
							return 'ws';
					}
				}();
				var tagOpen = '[mr_' + (tagInner + ']');
				var tagClose = '[/mr_' + (tagInner + ']');
				if (!contentSection) {
					return A3(
						$elm$core$String$replace,
						tagClose,
						'',
						A3(
							$elm$core$String$replace,
							tagOpen,
							'',
							A3($author$project$Helper$Common$extractContentBetweenStrings, tagOpen, tagClose, postString)));
				} else {
					return A2(
						$elm$core$Maybe$withDefault,
						'No content',
						A2(
							$elm$core$Maybe$andThen,
							$elm$core$List$head,
							A2(
								$elm$core$Maybe$map,
								$elm$core$String$split(tagClose),
								A2(
									$elm$core$Maybe$andThen,
									$elm$core$List$head,
									$elm$core$List$tail(
										A2($elm$core$String$split, tagOpen, postString))))));
				}
			});
		var appendMJaxTag = function (contentString) {
			return contentString + '<div class=\'sm-mjax\'></div>';
		};
		return appendMJaxTag(
			A2(extractContentTag, post.kF, contentSection));
	});
var $author$project$Type$User$extractPersonEDetailsFromTeacherE = function (teacher) {
	if (!teacher.$) {
		var personEDetails = teacher.a;
		return personEDetails;
	} else {
		var personEDetails = teacher.a;
		return personEDetails;
	}
};
var $author$project$Type$User$AdminE = function (a) {
	return {$: 0, a: a};
};
var $author$project$Type$User$NonAdminE = function (a) {
	return {$: 1, a: a};
};
var $author$project$Type$User$StudentE = function (a) {
	return {$: 0, a: a};
};
var $author$project$Type$User$TeacherE = function (a) {
	return {$: 1, a: a};
};
var $author$project$Type$User$extractPersonEFromAssignee = function (assignee) {
	var base = assignee.dc;
	var personDetails = {li: base.li, lt: base.lt, lW: base.pH, mq: base.mq, d$: assignee.n2};
	var _v0 = base.pt;
	if (!_v0.$) {
		var cohort = A2($elm$core$Maybe$withDefault, $author$project$Type$Cohort$placeholder, base.hB);
		return $author$project$Type$User$StudentE(
			{kA: cohort, li: base.li, lt: base.lt, lI: base.eV, lU: assignee.lU, lW: base.pH, mq: base.mq, d$: assignee.n2, n4: assignee.n4});
	} else {
		if (!_v0.a) {
			var _v1 = _v0.a;
			return $author$project$Type$User$TeacherE(
				$author$project$Type$User$AdminE(personDetails));
		} else {
			var _v2 = _v0.a;
			return $author$project$Type$User$TeacherE(
				$author$project$Type$User$NonAdminE(personDetails));
		}
	}
};
var $author$project$Page$ManageUsers$extractPersonEFromUser = function (user) {
	if (!user.$) {
		var personE = user.a;
		return personE;
	} else {
		var personE = user.a;
		return personE;
	}
};
var $author$project$Type$User$extractTeacherEFromPersonE = function (personE) {
	if (personE.$ === 1) {
		if (!personE.a.$) {
			var personEDetails = personE.a.a;
			return $elm$core$Maybe$Just(
				$author$project$Type$User$AdminE(personEDetails));
		} else {
			var personEDetails = personE.a.a;
			return $elm$core$Maybe$Just(
				$author$project$Type$User$NonAdminE(personEDetails));
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$regex$Regex$findAtMost = _Regex_findAtMost;
var $author$project$Type$User$findPersonE = F2(
	function (persons, id) {
		return $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (el) {
					return _Utils_eq(
						function ($) {
							return $.lW;
						}(
							$author$project$Type$User$extractPersonEDetails(el)),
						id);
				},
				persons));
	});
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{I: nodeList, A: nodeListSize, G: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $krisajenkins$remotedata$RemoteData$fromResult = function (result) {
	if (result.$ === 1) {
		var e = result.a;
		return $krisajenkins$remotedata$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return $krisajenkins$remotedata$RemoteData$Success(x);
	}
};
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{gp: false, hO: false},
		string);
};
var $Punie$elm_reader$Reader$Reader = $elm$core$Basics$identity;
var $Punie$elm_reader$Reader$asks = function (f) {
	return f;
};
var $author$project$Type$AnalyticsRecord$filterByHasResults = $elm$core$List$filter(
	A2(
		$elm$core$Basics$composeL,
		A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$List$isEmpty),
		function ($) {
			return $.aP;
		}));
var $author$project$Type$Cohort$toAssigneeString = function (cohort) {
	if (!cohort.$) {
		switch (cohort.a.$) {
			case 0:
				if (!cohort.a.a) {
					var _v1 = cohort.a.a;
					return '3';
				} else {
					var _v2 = cohort.a.a;
					return '4';
				}
			case 1:
				if (!cohort.a.a) {
					var _v3 = cohort.a.a;
					return '5';
				} else {
					var _v4 = cohort.a.a;
					return '6';
				}
			case 2:
				if (!cohort.a.a) {
					var _v5 = cohort.a.a;
					return '7';
				} else {
					var _v6 = cohort.a.a;
					return '8';
				}
			default:
				if (!cohort.a.a) {
					var _v7 = cohort.a.a;
					return '9';
				} else {
					var _v8 = cohort.a.a;
					return '10';
				}
		}
	} else {
		return 'MS';
	}
};
var $author$project$Helper$Util$toUniqueList = F2(
	function (toComparableFn, els) {
		return A2(
			$elm$core$List$map,
			function (_v0) {
				var v = _v0.b;
				return v;
			},
			$elm$core$Dict$toList(
				$elm$core$Dict$fromList(
					A2(
						$elm$core$List$map,
						function (el) {
							return _Utils_Tuple2(
								toComparableFn(el),
								el);
						},
						els))));
	});
var $author$project$Type$AnalyticsRecord$getValidCohorts = function (analyticsRecords) {
	return A2(
		$author$project$Helper$Util$toUniqueList,
		$author$project$Type$Cohort$toAssigneeString,
		A2(
			$elm$core$List$map,
			function ($) {
				return $.kA;
			},
			$author$project$Type$AnalyticsRecord$filterByHasResults(analyticsRecords)));
};
var $author$project$Type$NodeCfg$getItemDataMaybe = function (postMeta) {
	if (postMeta.$ === 1) {
		var itemData = postMeta.a;
		return $elm$core$Maybe$Just(itemData);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Type$NodeCfg$getItems = function (postMetas) {
	return A2($elm$core$List$filterMap, $author$project$Type$NodeCfg$getItemDataMaybe, postMetas);
};
var $author$project$Type$ExamResult$getActivityResultRecords = function (serrs) {
	return A2(
		$elm$core$List$filterMap,
		function (el) {
			if (el.$ === 1) {
				var actrr = el.a;
				return $elm$core$Maybe$Just(actrr);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		},
		serrs);
};
var $author$project$Type$ExamResult$isOnlineActivityResultRecord = function (actrr) {
	var _v0 = actrr.jA;
	if (!_v0.$) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Type$ExamResult$getOnlineResults = function (actrrs) {
	return A2($elm$core$List$filter, $author$project$Type$ExamResult$isOnlineActivityResultRecord, actrrs);
};
var $author$project$Type$ExamResult$getOnlineActivityResultRecords = F2(
	function (activityDatas, hstry) {
		var validActivityIds = A2(
			$elm$core$List$map,
			function ($) {
				return $.lW;
			},
			activityDatas);
		var onlineArrs = A2($elm$core$Basics$composeL, $author$project$Type$ExamResult$getOnlineResults, $author$project$Type$ExamResult$getActivityResultRecords)(hstry);
		return A2(
			$elm$core$List$filter,
			function (el) {
				return A2($elm$core$List$member, el.jz, validActivityIds);
			},
			onlineArrs);
	});
var $author$project$Type$NodeCfg$toActivityDatas = function (postMetas) {
	var getActivityDataMaybe = function (postMeta) {
		if (postMeta.$ === 2) {
			var activityData = postMeta.a;
			return $elm$core$Maybe$Just(activityData);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	return A2($elm$core$List$filterMap, getActivityDataMaybe, postMetas);
};
var $author$project$Type$NodeCfg$getItemData = F2(
	function (itemDatas, variantId) {
		return $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (el) {
					return _Utils_eq(el.oq, variantId.oq);
				},
				itemDatas));
	});
var $author$project$Type$ExamResult$getDifficultyForVariantId = F2(
	function (itemDatas, variantId) {
		var itemDataMaybe = A2($author$project$Type$NodeCfg$getItemData, itemDatas, variantId);
		var answerSetsMaybe = A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.jZ;
			},
			itemDataMaybe);
		return A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.dp;
			},
			A2(
				$elm$core$Maybe$andThen,
				function (els) {
					return A2(
						$elm$core$Array$get,
						variantId.c1,
						$elm$core$Array$fromList(els));
				},
				answerSetsMaybe));
	});
var $author$project$Type$History$isVAnswerCorrect = function (vAnswer) {
	switch (vAnswer.$) {
		case 0:
			if (vAnswer.a.b) {
				var _v1 = vAnswer.a;
				return true;
			} else {
				var _v2 = vAnswer.a;
				return false;
			}
		case 1:
			if (vAnswer.a.b) {
				var _v3 = vAnswer.a;
				return true;
			} else {
				var _v4 = vAnswer.a;
				return false;
			}
		default:
			if (vAnswer.a.b) {
				var _v5 = vAnswer.a;
				return true;
			} else {
				var _v6 = vAnswer.a;
				return false;
			}
	}
};
var $author$project$Type$History$isVAnswerMaybeCorrect = function (vAnswerMaybe) {
	if (!vAnswerMaybe.$) {
		var vAnswer = vAnswerMaybe.a;
		return $author$project$Type$History$isVAnswerCorrect(vAnswer);
	} else {
		return false;
	}
};
var $author$project$Type$ExamResult$calculateActivityResultDifficulty = F2(
	function (itemDatas, hItems) {
		var _v0 = $elm$core$List$reverse(hItems);
		if (!_v0.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var hItem = _v0.a;
			return A2(
				$elm$core$Maybe$map,
				function (el) {
					return $author$project$Type$History$isVAnswerMaybeCorrect(hItem.dW) ? (el + 2) : (el - 2);
				},
				A2($author$project$Type$ExamResult$getDifficultyForVariantId, itemDatas, hItem.pK));
		}
	});
var $author$project$Type$AnalyticsResult$toAnalyticsResultMaybe = F2(
	function (itemDatas, _v0) {
		var aId = _v0.jz;
		var tsFinish = _v0.pr;
		var qs = _v0.ci;
		return A2(
			$elm$core$Maybe$map,
			function (diffAchieved) {
				return {
					jz: aId,
					eJ: diffAchieved,
					mV: $elm$core$List$length(qs),
					pr: tsFinish
				};
			},
			A2($author$project$Type$ExamResult$calculateActivityResultDifficulty, itemDatas, qs));
	});
var $author$project$Type$AnalyticsRecord$toAnalyticsRecord = F2(
	function (postMetas, _v0) {
		var id = _v0.lW;
		var sId = _v0.d$;
		var fNam = _v0.lt;
		var lNam = _v0.mq;
		var email = _v0.li;
		var cohort = _v0.kA;
		var group = _v0.lI;
		var hstry = _v0.lU;
		return {
			aP: A2(
				$elm$core$List$filterMap,
				$author$project$Type$AnalyticsResult$toAnalyticsResultMaybe(
					$author$project$Type$NodeCfg$getItems(postMetas)),
				A2(
					$author$project$Type$ExamResult$getOnlineActivityResultRecords,
					$author$project$Type$NodeCfg$toActivityDatas(postMetas),
					hstry)),
			kA: cohort,
			li: email,
			lt: fNam,
			lI: group,
			lW: id,
			mq: lNam,
			d$: sId
		};
	});
var $author$project$Page$AdaptiveAnalytics$DisplayAsBand = 0;
var $author$project$Type$AnalyticsRecord$filterByCohort = F2(
	function (cohort, analyticsRecords) {
		return A2(
			$elm$core$List$filter,
			function (el) {
				return _Utils_eq(el.kA, cohort);
			},
			analyticsRecords);
	});
var $author$project$Type$AnalyticsRecord$toAnalyticsResults = function (analyticsRecords) {
	return A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.aP;
		},
		$author$project$Type$AnalyticsRecord$filterByHasResults(analyticsRecords));
};
var $elm$core$Set$fromList = function (list) {
	return A3($elm$core$List$foldl, $elm$core$Set$insert, $elm$core$Set$empty, list);
};
var $author$project$Helper$Util$unique = function (a) {
	return $elm$core$Set$toList(
		$elm$core$Set$fromList(a));
};
var $author$project$Page$AdaptiveAnalytics$updateModel = F3(
	function (model, postMetas, cohortMaybe) {
		var makeValidActivities = function (cohort) {
			var validActivityIds = $author$project$Helper$Util$unique(
				A2(
					$elm$core$List$map,
					function ($) {
						return $.jz;
					},
					$author$project$Type$AnalyticsRecord$toAnalyticsResults(
						A2($author$project$Type$AnalyticsRecord$filterByCohort, cohort, model.a_))));
			return A2(
				$elm$core$List$filter,
				function (el) {
					return A2($elm$core$List$member, el.lW, validActivityIds);
				},
				$author$project$Type$NodeCfg$toActivityDatas(postMetas));
		};
		if (cohortMaybe.$ === 1) {
			return model;
		} else {
			var cohort = cohortMaybe.a;
			return _Utils_update(
				model,
				{
					aV: _Utils_eq(cohort, $author$project$Type$Cohort$MS) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(0),
					c2: makeValidActivities(cohort),
					bX: $elm$core$Maybe$Just(cohort)
				});
		}
	});
var $author$project$Page$AdaptiveAnalytics$furnish = F3(
	function (model, studentETypes, teachersDetails) {
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				var analyticsRecordsAll = A2(
					$elm$core$List$map,
					$author$project$Type$AnalyticsRecord$toAnalyticsRecord(rState.mX.fs),
					studentETypes);
				var validCohorts = A2(
					$author$project$Helper$Util$toUniqueList,
					$author$project$Type$Cohort$toAssigneeString,
					$author$project$Type$AnalyticsRecord$getValidCohorts(analyticsRecordsAll));
				var cohortMaybe = function () {
					if (validCohorts.b && (!validCohorts.b.b)) {
						var cohort = validCohorts.a;
						return $elm$core$Maybe$Just(cohort);
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}();
				return A3(
					$author$project$Page$AdaptiveAnalytics$updateModel,
					_Utils_update(
						model,
						{
							a_: analyticsRecordsAll,
							cX: teachersDetails,
							eb: $elm$core$Maybe$Just(validCohorts)
						}),
					rState.mX.fs,
					cohortMaybe);
			});
	});
var $author$project$Msgs$GetAppCfgNonS3Response = function (a) {
	return {$: 17, a: a};
};
var $author$project$Type$AppCfg$AppCfg = F4(
	function (qGroups, testlets, categories, qsMeta) {
		return {kr: categories, nH: qGroups, iw: qsMeta, i9: testlets};
	});
var $author$project$Type$AppCfg$Category = F4(
	function (id, name, parent, isLeaf) {
		return {lW: id, mf: isLeaf, mO: name, nq: parent};
	});
var $author$project$DataService$categoryDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Type$AppCfg$Category,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'parent', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'isLeaf', $elm$json$Json$Decode$bool));
var $author$project$Type$AppCfg$QuestionGroup = F7(
	function (id, lvl, ord, qgDetails, strtTstltId, tstTime, groupRuleset) {
		return {lJ: groupRuleset, lW: id, hB: lvl, nj: ord, iv: qgDetails, oE: strtTstltId, ps: tstTime};
	});
var $author$project$Type$AppCfg$QuestionGroupDetails = F4(
	function (name, desc, typ, instrctns) {
		return {k8: desc, l9: instrctns, mO: name, pt: typ};
	});
var $author$project$Type$AppCfg$Exam = 0;
var $author$project$Type$AppCfg$Strand = 1;
var $author$project$DataService$qgTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'exam':
				return $elm$json$Json$Decode$succeed(0);
			case 'strand':
				return $elm$json$Json$Decode$succeed(1);
			default:
				var somethingElse = str;
				return $elm$json$Json$Decode$fail('Unknown result: ' + somethingElse);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$DataService$qgDetailsDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Type$AppCfg$QuestionGroupDetails,
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'desc', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'typ', $author$project$DataService$qgTypeDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'instrctns',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string)));
var $author$project$Type$AppCfg$BandRules = function (a) {
	return {$: 0, a: a};
};
var $author$project$Type$AppCfg$ScoreThresholds = function (a) {
	return {$: 1, a: a};
};
var $author$project$Type$AppCfg$TestletRuleset = F2(
	function (testletId, bandRuleset) {
		return {j6: bandRuleset, oW: testletId};
	});
var $author$project$Type$AppCfg$BandRuleset = F2(
	function (bndCndtns, minBndAch) {
		return {kc: bndCndtns, mF: minBndAch};
	});
var $author$project$Type$AppCfg$BandCondition = F2(
	function (bnd, minCrct) {
		return {kb: bnd, mG: minCrct};
	});
var $author$project$DataService$bandConditionDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Type$AppCfg$BandCondition,
	A2($elm$json$Json$Decode$field, 'bnd', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'minCrct', $elm$json$Json$Decode$int));
var $author$project$DataService$bandRulesetDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Type$AppCfg$BandRuleset,
	A2(
		$elm$json$Json$Decode$field,
		'bndCndtns',
		$elm$json$Json$Decode$list($author$project$DataService$bandConditionDecoder)),
	A2($elm$json$Json$Decode$field, 'minBndAch', $elm$json$Json$Decode$int));
var $author$project$DataService$scoreThresholdDecoder = A3(
	$elm$json$Json$Decode$map2,
	F2(
		function (score, value) {
			return _Utils_Tuple2(score, value);
		}),
	A2($elm$json$Json$Decode$field, 's', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'v', $elm$json$Json$Decode$string));
var $author$project$DataService$testletRulesetDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Type$AppCfg$TestletRuleset,
	A2($elm$json$Json$Decode$field, 'testletId', $elm$json$Json$Decode$int),
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$field,
				'bandRuleset',
				A2(
					$elm$json$Json$Decode$map,
					$author$project$Type$AppCfg$BandRules,
					$elm$json$Json$Decode$list($author$project$DataService$bandRulesetDecoder))),
				A2(
				$elm$json$Json$Decode$field,
				'scoreThresholds',
				A2(
					$elm$json$Json$Decode$map,
					$author$project$Type$AppCfg$ScoreThresholds,
					$elm$json$Json$Decode$list($author$project$DataService$scoreThresholdDecoder)))
			])));
var $author$project$DataService$questionGroupDecoder = A8(
	$elm$json$Json$Decode$map7,
	$author$project$Type$AppCfg$QuestionGroup,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'lvl', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'ord', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'qgDetails', $author$project$DataService$qgDetailsDecoder),
	A2($elm$json$Json$Decode$field, 'strtTstltId', $elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$field,
		'tstTime',
		$elm$json$Json$Decode$maybe($elm$json$Json$Decode$int)),
	A2(
		$elm$json$Json$Decode$field,
		'groupRuleset',
		$elm$json$Json$Decode$list($author$project$DataService$testletRulesetDecoder)));
var $author$project$DataService$qGroupsDecoder = $elm$json$Json$Decode$list($author$project$DataService$questionGroupDecoder);
var $author$project$Type$AppCfg$QuestionMetadata = F5(
	function (id, typ, fmt, cat, bnd) {
		return {kb: bnd, kq: cat, ly: fmt, lW: id, pt: typ};
	});
var $author$project$Type$AppCfg$BandMetadata = F3(
	function (y, b, s) {
		return {gl: b, n1: s, pS: y};
	});
var $author$project$DataService$bandMetadataDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Type$AppCfg$BandMetadata,
	A2($elm$json$Json$Decode$field, 'y', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'b', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 's', $elm$json$Json$Decode$int));
var $author$project$Type$AppCfg$MultiChoice = 0;
var $author$project$Type$AppCfg$SingleAnswer = 1;
var $author$project$DataService$fromStringQFmtType = function (fmt) {
	switch (fmt) {
		case 'm':
			return $elm$core$Result$Ok(0);
		case 's':
			return $elm$core$Result$Ok(1);
		default:
			return $elm$core$Result$Err('Invalid QsMeta Fmt of question (e.g. SingleAnswer, MultiChoice): ' + fmt);
	}
};
var $author$project$DataService$qFmtMetadataDecoder = A2(
	$elm$json$Json$Decode$andThen,
	A2($elm$core$Basics$composeR, $author$project$DataService$fromStringQFmtType, $elm_community$json_extra$Json$Decode$Extra$fromResult),
	$elm$json$Json$Decode$string);
var $author$project$Type$AppCfg$Calc = 0;
var $author$project$Type$AppCfg$NonCalc = 1;
var $author$project$DataService$fromStringQTypType = function (typ) {
	switch (typ) {
		case 'c':
			return $elm$core$Result$Ok(0);
		case 'n':
			return $elm$core$Result$Ok(1);
		default:
			return $elm$core$Result$Err('Invalid QsMeta Typ of question (e.g. Calc / NonCalc): ' + typ);
	}
};
var $author$project$DataService$qTypMetadataDecoder = A2(
	$elm$json$Json$Decode$andThen,
	A2($elm$core$Basics$composeR, $author$project$DataService$fromStringQTypType, $elm_community$json_extra$Json$Decode$Extra$fromResult),
	$elm$json$Json$Decode$string);
var $author$project$DataService$questionMetadataDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Type$AppCfg$QuestionMetadata,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'tp', $author$project$DataService$qTypMetadataDecoder),
	A2($elm$json$Json$Decode$field, 'ft', $author$project$DataService$qFmtMetadataDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'cs',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$int)),
	A2(
		$elm$json$Json$Decode$field,
		'bd',
		$elm$json$Json$Decode$list($author$project$DataService$bandMetadataDecoder)));
var $author$project$Type$AppCfg$TestletDetail = F5(
	function (id, qs, grd, nxt, isMkd) {
		return {lG: grd, lW: id, mg: isMkd, fn: nxt, ci: qs};
	});
var $author$project$Type$AppCfg$Locked = 1;
var $author$project$Type$AppCfg$LockedNoMoreCalculator = 2;
var $author$project$Type$AppCfg$Unlocked = 3;
var $author$project$DataService$fromStringGuardType = function (grd) {
	switch (grd) {
		case 'n':
			return $elm$core$Result$Ok(0);
		case 'l':
			return $elm$core$Result$Ok(1);
		case 'lnmc':
			return $elm$core$Result$Ok(2);
		case 'u':
			return $elm$core$Result$Ok(3);
		default:
			return $elm$core$Result$Err('Invalid Guard Type: ' + grd);
	}
};
var $author$project$DataService$guardDecoder = A2(
	$elm$json$Json$Decode$andThen,
	A2($elm$core$Basics$composeR, $author$project$DataService$fromStringGuardType, $elm_community$json_extra$Json$Decode$Extra$fromResult),
	$elm$json$Json$Decode$string);
var $author$project$DataService$qsDecoder = $elm$json$Json$Decode$array($elm$json$Json$Decode$int);
var $author$project$Type$AppCfg$TestletLevel = F2(
	function (pc, qsid) {
		return {ih: pc, ix: qsid};
	});
var $author$project$DataService$testletLevelDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Type$AppCfg$TestletLevel,
	A2($elm$json$Json$Decode$field, 'pc', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'qsid', $elm$json$Json$Decode$int));
var $author$project$DataService$testletDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Type$AppCfg$TestletDetail,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'qs', $author$project$DataService$qsDecoder),
	A2($elm$json$Json$Decode$field, 'grd', $author$project$DataService$guardDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'nxt',
		$elm$json$Json$Decode$list($author$project$DataService$testletLevelDecoder)),
	A2($elm$json$Json$Decode$field, 'isMkd', $elm$json$Json$Decode$bool));
var $author$project$DataService$appCfgDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Type$AppCfg$AppCfg,
	A2($elm$json$Json$Decode$field, 'qGroups', $author$project$DataService$qGroupsDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'testlets',
		$elm$json$Json$Decode$list($author$project$DataService$testletDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'categories',
		$elm$json$Json$Decode$list($author$project$DataService$categoryDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'qsMeta',
		$elm$json$Json$Decode$list($author$project$DataService$questionMetadataDecoder)));
var $author$project$DataService$getAppCfgNonAWSAPI = F2(
	function (prefixU, version) {
		var request = $elm$http$Http$get(
			{
				lp: A2(
					$elm$http$Http$expectJson,
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Msgs$GetAppCfgNonS3Response),
					$author$project$DataService$appCfgDecoder),
				py: prefixU + ('/appCfg.json?v=' + version)
			});
		return request;
	});
var $author$project$Type$NodeCfg$getCategoryDataMaybe = function (postMeta) {
	if (!postMeta.$) {
		var categoryData = postMeta.a;
		return $elm$core$Maybe$Just(categoryData);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Type$NodeCfg$getCategories = function (postMetas) {
	return A2($elm$core$List$filterMap, $author$project$Type$NodeCfg$getCategoryDataMaybe, postMetas);
};
var $author$project$Helper$Common$getTestletDetailForId = F2(
	function (model, testletId) {
		var _v0 = model.j$;
		if (_v0.$ === 3) {
			var appCfg = _v0.a;
			return $elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (el) {
						return _Utils_eq(el.lW, testletId);
					},
					appCfg.i9));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Helper$Common$getCurrentTestletDetail = function (model) {
	return A2(
		$elm$core$Maybe$andThen,
		$author$project$Helper$Common$getTestletDetailForId(model),
		model.kT);
};
var $author$project$JsInterop$Plumbing$getGraphQLQuery = F2(
	function (ops, msg) {
		switch (msg.$) {
			case 0:
				return ops.hE;
			case 1:
				return ops.i8;
			case 2:
				return ops.i$;
			case 3:
				return ops.i0;
			case 4:
				return ops.go;
			case 5:
				return ops.hP;
			case 6:
				return ops.gR;
			case 7:
				return ops.iZ;
			case 8:
				return ops.i_;
			case 9:
				return ops.jk;
			case 10:
				return ops.jj;
			case 14:
				return ops.gJ;
			case 15:
				return ops.gI;
			case 11:
				return ops.ji;
			case 12:
				return ops.gQ;
			case 13:
				return ops.gS;
			case 16:
				return ops.gG;
			default:
				return ops.gH;
		}
	});
var $author$project$Page$ManageUsers$getIsCheckedTeachers = function (augmentedTeacherERecords) {
	return A2(
		$elm$core$List$map,
		function ($) {
			return $.bN;
		},
		A2(
			$elm$core$List$filter,
			function ($) {
				return $.W;
			},
			augmentedTeacherERecords));
};
var $author$project$Type$User$getMeId = function (remoteMe) {
	return A3(
		$krisajenkins$remotedata$RemoteData$unwrap,
		$elm$core$Maybe$Nothing,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Type$User$extractPersonEDetails,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.lW;
				},
				$elm$core$Maybe$Just)),
		remoteMe);
};
var $author$project$Models$Next = 0;
var $elm$core$Array$length = function (_v0) {
	var len = _v0.a;
	return len;
};
var $author$project$Helper$Common$isQNbrNavigationValid = F2(
	function (model, direction) {
		var testlet_ = $author$project$Helper$Common$getCurrentTestletDetail(model);
		var qNbr = function () {
			var _v3 = model.n_;
			if (_v3.$ === 1) {
				var qNbr_ = _v3.a;
				return qNbr_;
			} else {
				return 0;
			}
		}();
		var prevQGroupAnswer = A2($elm$core$Array$get, (qNbr - 1) - 1, model.kR);
		if (!testlet_.$) {
			var testlet = testlet_.a;
			if (!direction) {
				return (!$elm$core$List$isEmpty(testlet.fn)) || (_Utils_cmp(
					qNbr,
					$elm$core$Array$length(model.nI)) < 0);
			} else {
				if (!prevQGroupAnswer.$) {
					var prevQGroupAnswer1 = prevQGroupAnswer.a;
					return (qNbr > 1) && (!prevQGroupAnswer1.mj);
				} else {
					return false;
				}
			}
		} else {
			return false;
		}
	});
var $author$project$Helper$Common$getNextQuestion = function (model) {
	return A2($author$project$Helper$Common$isQNbrNavigationValid, model, 0) ? A2(
		$elm$core$Maybe$map,
		$elm$core$Basics$add(1),
		model.kS) : $elm$core$Maybe$Nothing;
};
var $elm$core$Array$filter = F2(
	function (isGood, array) {
		return $elm$core$Array$fromList(
			A3(
				$elm$core$Array$foldr,
				F2(
					function (x, xs) {
						return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
					}),
				_List_Nil,
				array));
	});
var $author$project$Helper$Common$nbrCorrect = function (answerStatusList) {
	return $elm$core$List$length(
		A2(
			$elm$core$List$filter,
			function (aStatus) {
				return aStatus === 2;
			},
			answerStatusList));
};
var $author$project$Helper$Common$percentScore = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$round(100 * (numerator / denominator));
	});
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$Helper$Common$unwrap = function (mx) {
	return A2($elm$core$Maybe$andThen, $elm$core$Basics$identity, mx);
};
var $author$project$Helper$Common$getNextTestletDetail = function (model) {
	var scoreThreshold = function (l) {
		return A2(
			$author$project$Helper$Common$percentScore,
			$author$project$Helper$Common$nbrCorrect(l),
			$elm$core$List$length(l));
	}(
		A2(
			$elm$core$List$map,
			function ($) {
				return $.jC;
			},
			$elm$core$Array$toList(
				A2(
					$elm$core$Array$filter,
					function (el) {
						return _Utils_eq(
							el.oW,
							A2($elm$core$Maybe$withDefault, 0, model.kT));
					},
					model.kR))));
	var nxtTestletLevels = A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.fn;
		},
		$author$project$Helper$Common$getCurrentTestletDetail(model));
	return $author$project$Helper$Common$unwrap(
		A2(
			$elm$core$Maybe$map,
			$author$project$Helper$Common$getTestletDetailForId(model),
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.ix;
				},
				$author$project$Helper$Common$unwrap(
					A2(
						$elm$core$Maybe$map,
						function (nxt) {
							return $elm$core$List$head(
								A2(
									$elm$core$List$filter,
									function (el) {
										return _Utils_cmp(el.ih, scoreThreshold) < 1;
									},
									$elm$core$List$reverse(
										A2(
											$elm$core$List$sortBy,
											function ($) {
												return $.ih;
											},
											nxt))));
						},
						nxtTestletLevels)))));
};
var $author$project$Helper$Common$getSchoolName = function (_v0) {
	return $elm$core$Result$Err('Not Implemented');
};
var $author$project$Type$User$getStudentETypeMe = function (personE) {
	return $author$project$Type$User$extractStudentEFromPersonE(personE);
};
var $author$project$Update$getUserRouteType = function (route) {
	switch (route.$) {
		case 21:
			var userType_ = route.a;
			return userType_;
		case 25:
			var userType_ = route.a;
			return userType_;
		case 24:
			var userType_ = route.a;
			return userType_;
		default:
			return 0;
	}
};
var $author$project$DataService$genericMaybeStringEncoder = function (stringMaybe) {
	if (!stringMaybe.$) {
		var str = stringMaybe.a;
		return $elm$json$Json$Encode$string(str);
	} else {
		return $elm$json$Json$Encode$null;
	}
};
var $author$project$Type$Cohort$toString = function (cohort) {
	var prefix = function () {
		if (cohort.$ === 1) {
			return '';
		} else {
			return 'Y';
		}
	}();
	return _Utils_ap(
		prefix,
		$author$project$Type$Cohort$toAssigneeString(cohort));
};
var $author$project$DataService$userTypeForAnalyticsEncoder = function (userTypeMaybe) {
	return $elm$json$Json$Encode$string(
		function () {
			if (!userTypeMaybe.$) {
				if (!userTypeMaybe.a.$) {
					var _v1 = userTypeMaybe.a;
					return 'Student';
				} else {
					if (userTypeMaybe.a.a === 1) {
						var _v2 = userTypeMaybe.a.a;
						return 'NonAdminTeacher';
					} else {
						var _v3 = userTypeMaybe.a.a;
						return 'AdminTeacher';
					}
				}
			} else {
				return 'UnknownUserType';
			}
		}());
};
var $author$project$DataService$googleAnalyticsInitializationRecordEncoder = function (igar) {
	var attributes = _List_fromArray(
		[
			_Utils_Tuple2(
			'userId',
			$author$project$DataService$genericMaybeStringEncoder(igar.pD)),
			_Utils_Tuple2(
			'schoolName',
			$elm$json$Json$Encode$string(igar.n5)),
			_Utils_Tuple2(
			'userType',
			$author$project$DataService$userTypeForAnalyticsEncoder(igar.pF)),
			_Utils_Tuple2(
			'yearLvl',
			function () {
				var _v0 = igar.pV;
				if (!_v0.$) {
					var lvl = _v0.a;
					return $elm$json$Json$Encode$string(
						$author$project$Type$Cohort$toString(lvl));
				} else {
					return $elm$json$Json$Encode$null;
				}
			}())
		]);
	return $elm$json$Json$Encode$object(attributes);
};
var $author$project$JsInterop$Plumbing$CreateQuestionGroupGQL = F2(
	function (a, b) {
		return {$: 17, a: a, b: b};
	});
var $author$project$Type$User$isStudentE = function (person) {
	if (!person.$) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Type$User$isRemoteMeAStudentE = function (remoteMe) {
	return A3($krisajenkins$remotedata$RemoteData$unwrap, false, $author$project$Type$User$isStudentE, remoteMe);
};
var $author$project$Type$ExamResult$isSERRQuestionListEmpty = function (serr) {
	if (!serr.$) {
		var tgrr = serr.a;
		return $elm$core$List$isEmpty(tgrr.ci);
	} else {
		var actrr = serr.a;
		return $elm$core$List$isEmpty(actrr.ci);
	}
};
var $author$project$Helper$Common$extractPartialModelState = function (model) {
	return A9(
		$author$project$Models$PartialModelState,
		model.ln,
		A3($author$project$Models$ExamProgressPoints, model.kS, model.kU, model.kT),
		model.ox,
		model.lo,
		model.nI,
		model.kR,
		A2($elm$core$Maybe$withDefault, $author$project$Helper$Common$blankUserDetails, model.pC),
		model.no,
		model.mX.jJ);
};
var $author$project$DataService$examStatusEncoder = function (examStatus) {
	return $elm$json$Json$Encode$string(
		function () {
			switch (examStatus) {
				case 0:
					return 'ExamNotStarted';
				case 1:
					return 'ExamInProgress';
				default:
					return 'ExamCompleted';
			}
		}());
};
var $author$project$Type$Activity$activityMetricEncoder = function (activityMetric) {
	var attributes = _List_fromArray(
		[
			_Utils_Tuple2(
			'minutes',
			$elm$json$Json$Encode$int(
				A2($elm$core$Maybe$withDefault, 0, activityMetric.fh))),
			_Utils_Tuple2(
			'nbrquestions',
			$elm$json$Json$Encode$int(activityMetric.mV))
		]);
	return $elm$json$Json$Encode$object(attributes);
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$Type$Activity$categoryPercentagesEncoder = function (nodePercentages) {
	return A2(
		$elm$json$Json$Encode$list,
		function (el) {
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'category',
						$elm$json$Json$Encode$string(el.mY)),
						_Utils_Tuple2(
						'categorypercentage',
						$elm$json$Json$Encode$int(el.ih))
					]));
		},
		nodePercentages);
};
var $author$project$Type$Activity$ctxToString = function (ctx) {
	if (!ctx) {
		return 'Assess';
	} else {
		return 'Practice';
	}
};
var $author$project$Type$Activity$ctxToStringLower = A2($elm$core$Basics$composeL, $elm$core$String$toLower, $author$project$Type$Activity$ctxToString);
var $author$project$Type$Activity$focusToString = function (focus) {
	if (!focus) {
		return 'Challenge';
	} else {
		return 'Weakness';
	}
};
var $author$project$Type$Activity$activityEncoder = function (activity) {
	var attributes = _List_fromArray(
		[
			_Utils_Tuple2(
			'id',
			$elm$json$Json$Encode$string(activity.lW)),
			_Utils_Tuple2(
			'syllabus',
			$elm$json$Json$Encode$string(activity.bf)),
			_Utils_Tuple2(
			'sortfield',
			$elm$json$Json$Encode$string(activity.or)),
			_Utils_Tuple2(
			'title',
			$elm$json$Json$Encode$string(activity.ja)),
			_Utils_Tuple2(
			'activitymetrics',
			A2($elm$json$Json$Encode$list, $author$project$Type$Activity$activityMetricEncoder, activity.f9)),
			_Utils_Tuple2(
			'isrepresentativeactivity',
			$elm$json$Json$Encode$bool(activity.hu)),
			_Utils_Tuple2(
			'targetnode',
			$elm$json$Json$Encode$string(activity.cx.oR)),
			_Utils_Tuple2(
			'initialdifficulty',
			$elm$json$Json$Encode$int(activity.cx.l3)),
			_Utils_Tuple2(
			'categorypercentages',
			$author$project$Type$Activity$categoryPercentagesEncoder(activity.cx.mZ)),
			_Utils_Tuple2(
			'context',
			$elm$json$Json$Encode$string(
				$author$project$Type$Activity$ctxToStringLower(activity.cx.kL))),
			_Utils_Tuple2(
			'focus',
			$elm$json$Json$Encode$string(
				$author$project$Type$Activity$focusToString(activity.cx.lA))),
			_Utils_Tuple2(
			'description',
			$elm$json$Json$Encode$string(activity.gT)),
			_Utils_Tuple2(
			'instructions',
			$elm$json$Json$Encode$string(activity.ma))
		]);
	return $elm$json$Json$Encode$object(attributes);
};
var $author$project$Type$History$vAnswerEncodeToString = function (vAnswerMaybe) {
	if (vAnswerMaybe.$ === 1) {
		return 'n';
	} else {
		var vAnswer = vAnswerMaybe.a;
		return $author$project$Type$History$isVAnswerCorrect(vAnswer) ? 'c' : 'i';
	}
};
var $author$project$Type$ExamResult$historyItemEncoder = function (historyItem) {
	var attributes = _List_fromArray(
		[
			_Utils_Tuple2(
			'it',
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'ttl',
						$elm$json$Json$Encode$string(historyItem.pK.oq)),
						_Utils_Tuple2(
						'vId',
						$elm$json$Json$Encode$int(historyItem.pK.c1))
					]))),
			_Utils_Tuple2(
			'r',
			$elm$json$Json$Encode$string(
				$author$project$Type$History$vAnswerEncodeToString(historyItem.dW)))
		]);
	var attrsExtended = function () {
		var _v0 = historyItem.dW;
		if (_v0.$ === 1) {
			return attributes;
		} else {
			switch (_v0.a.$) {
				case 0:
					var _v1 = _v0.a.a;
					var ans = _v1.a;
					return A2(
						$elm$core$List$cons,
						_Utils_Tuple2(
							'a',
							$elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'mc',
										$elm$json$Json$Encode$int(ans))
									]))),
						attributes);
				case 1:
					var _v2 = _v0.a.a;
					var anss = _v2.a;
					return A2(
						$elm$core$List$cons,
						_Utils_Tuple2(
							'a',
							$elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'mcma',
										A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, anss))
									]))),
						attributes);
				default:
					var _v3 = _v0.a.a;
					var strs = _v3.a;
					return A2(
						$elm$core$List$cons,
						_Utils_Tuple2(
							'a',
							$elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'abox',
										A2($elm$json$Json$Encode$array, $author$project$Type$ExamResult$encodeStrMaybe, strs))
									]))),
						attributes);
			}
		}
	}();
	return $elm$json$Json$Encode$object(attrsExtended);
};
var $author$project$Type$History$vAnswerEncoder = function (vAnswer) {
	switch (vAnswer.$) {
		case 0:
			var _v1 = vAnswer.a;
			var i = _v1.a;
			var b = _v1.b;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'MCSA',
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'i',
									$elm$json$Json$Encode$int(i)),
									_Utils_Tuple2(
									'b',
									$elm$json$Json$Encode$bool(b))
								])))
					]));
		case 1:
			var _v2 = vAnswer.a;
			var l = _v2.a;
			var b = _v2.b;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'MCMA',
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'l',
									A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, l)),
									_Utils_Tuple2(
									'b',
									$elm$json$Json$Encode$bool(b))
								])))
					]));
		default:
			var _v3 = vAnswer.a;
			var a = _v3.a;
			var b = _v3.b;
			var encodeMaybeString = function (strMaybe) {
				if (strMaybe.$ === 1) {
					return $elm$json$Json$Encode$null;
				} else {
					var str = strMaybe.a;
					return $elm$json$Json$Encode$string(str);
				}
			};
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'ANSBOX',
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'a',
									A2($elm$json$Json$Encode$array, encodeMaybeString, a)),
									_Utils_Tuple2(
									'b',
									$elm$json$Json$Encode$bool(b))
								])))
					]));
	}
};
var $author$project$Type$History$vAnswerMaybeEncoder = function (vAnswerMaybe) {
	if (!vAnswerMaybe.$) {
		var vAnswer = vAnswerMaybe.a;
		return $author$project$Type$History$vAnswerEncoder(vAnswer);
	} else {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2('variantAnswer', $elm$json$Json$Encode$null)
				]));
	}
};
var $author$project$Type$Variant$toStringSelectionType = function (selectionType) {
	switch (selectionType) {
		case 0:
			return 'Novel';
		case 1:
			return 'AlternateVariant';
		case 2:
			return 'BeenSeen';
		case 3:
			return 'A_SmuidNeverSeen';
		case 4:
			return 'B_HasASibSeenPrev';
		case 5:
			return 'C_HasASibSeenCurrSess';
		case 6:
			return 'D_VariantSeenPrev';
		default:
			return 'E_VariantSeenCurrSess';
	}
};
var $author$project$Type$Variant$selectionTypeEncoder = function (selectionType) {
	return $elm$json$Json$Encode$string(
		$author$project$Type$Variant$toStringSelectionType(selectionType));
};
var $author$project$Type$Variant$variantIdEncoder = function (variantId) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'smuid',
				$elm$json$Json$Encode$string(variantId.oq)),
				_Utils_Tuple2(
				'vId',
				$elm$json$Json$Encode$int(variantId.c1))
			]));
};
var $author$project$Type$VariantSeenHistory$variantMetaExtendedEncoder = function (variantMetaExtended) {
	var attributes = _List_fromArray(
		[
			_Utils_Tuple2(
			'variantId',
			$author$project$Type$Variant$variantIdEncoder(variantMetaExtended.pK)),
			_Utils_Tuple2(
			'difficulty',
			$elm$json$Json$Encode$int(variantMetaExtended.dp)),
			_Utils_Tuple2(
			'categoryIds',
			A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, variantMetaExtended.ks)),
			_Utils_Tuple2(
			'tags',
			A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, variantMetaExtended.i7)),
			_Utils_Tuple2(
			'usageTag',
			$author$project$Type$Variant$selectionTypeEncoder(variantMetaExtended.aX))
		]);
	return $elm$json$Json$Encode$object(attributes);
};
var $author$project$Type$VariantSeenHistory$variantSeenHistoryEncoder = function (variantSeenHistory) {
	return A2($elm$json$Json$Encode$list, $author$project$Type$VariantSeenHistory$variantMetaExtendedEncoder, variantSeenHistory);
};
var $elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						$elm$core$List$cons,
						sep,
						A2($elm$core$List$cons, x, rest));
				});
			var spersed = A3($elm$core$List$foldr, step, _List_Nil, tl);
			return A2($elm$core$List$cons, hd, spersed);
		}
	});
var $author$project$Type$Variant$toStringSelectionRationale = function (sr) {
	var selectionTypeString = $author$project$Type$Variant$toStringSelectionType(sr.iP);
	var nodePercentagesString = function (el) {
		return '[' + (el + ']');
	}(
		A3(
			$elm$core$List$foldl,
			$elm$core$Basics$append,
			'',
			A2(
				$elm$core$List$intersperse,
				'\n     ',
				A2(
					$elm$core$List$map,
					function (el) {
						return el.mY + (' : ' + $elm$core$String$fromInt(el.ih));
					},
					sr.mZ))));
	return '\n SelectionRationale:' + ('\n  targetStrike is ' + ($elm$core$String$fromInt(sr.oS) + ('\n  selectionStrike is ' + ($elm$core$String$fromInt(sr.iO) + ('\n  nodePercentage(s):\n    ' + (nodePercentagesString + ('\n  selectionType is ' + selectionTypeString)))))));
};
var $author$project$Type$Variant$selectionRationaleEncoder = function (selectionRationale) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'targetstrike',
				$elm$json$Json$Encode$int(selectionRationale.oS)),
				_Utils_Tuple2(
				'selectionstrike',
				$elm$json$Json$Encode$int(selectionRationale.iO)),
				_Utils_Tuple2(
				'categorypercentages',
				$author$project$Type$Activity$categoryPercentagesEncoder(selectionRationale.mZ)),
				_Utils_Tuple2(
				'selectiontype',
				$elm$json$Json$Encode$string(
					$author$project$Type$Variant$toStringSelectionRationale(selectionRationale)))
			]));
};
var $author$project$Type$Variant$variantSelectionEncoder = function (variantSelection) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'selectionrationale',
				$author$project$Type$Variant$selectionRationaleEncoder(variantSelection.a)),
				_Utils_Tuple2(
				'variantid',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Type$Variant$variantIdEncoder, variantSelection.b)))
			]));
};
var $author$project$Page$ShowVariant$modelEncoder = function (model) {
	var attributes = _List_fromArray(
		[
			_Utils_Tuple2('serverErrorMsg', $elm$json$Json$Encode$null),
			_Utils_Tuple2(
			'variantSelection',
			A2(
				$elm$core$Maybe$withDefault,
				$elm$json$Json$Encode$null,
				A2($elm$core$Maybe$map, $author$project$Type$Variant$variantSelectionEncoder, model.ab))),
			_Utils_Tuple2('encodedVariantContent', $elm$json$Json$Encode$null),
			_Utils_Tuple2('variantContent', $elm$json$Json$Encode$null),
			_Utils_Tuple2(
			'variantAnswer',
			$author$project$Type$History$vAnswerMaybeEncoder(model.ao)),
			_Utils_Tuple2(
			'sessionHistory',
			A2($elm$json$Json$Encode$list, $author$project$Type$ExamResult$historyItemEncoder, model.oe)),
			_Utils_Tuple2(
			'activity',
			A2(
				$elm$core$Maybe$withDefault,
				$elm$json$Json$Encode$null,
				A2($elm$core$Maybe$map, $author$project$Type$Activity$activityEncoder, model.f8))),
			_Utils_Tuple2(
			'activityMetric',
			A2(
				$elm$core$Maybe$withDefault,
				$elm$json$Json$Encode$null,
				A2($elm$core$Maybe$map, $author$project$Type$Activity$activityMetricEncoder, model.aq))),
			_Utils_Tuple2(
			'isShowInstructions',
			$elm$json$Json$Encode$bool(model.X)),
			_Utils_Tuple2(
			'isExtraTime',
			$elm$json$Json$Encode$bool(model.by)),
			_Utils_Tuple2(
			'isAnswerChecked',
			$elm$json$Json$Encode$bool(model.ca)),
			_Utils_Tuple2('toolModel_Calc', $elm$json$Json$Encode$null),
			_Utils_Tuple2(
			'variantSeenHistory',
			A2(
				$elm$core$Maybe$withDefault,
				$elm$json$Json$Encode$null,
				A2($elm$core$Maybe$map, $author$project$Type$VariantSeenHistory$variantSeenHistoryEncoder, model.cr))),
			_Utils_Tuple2(
			'isShowQuestionBand',
			$elm$json$Json$Encode$bool(model.bz))
		]);
	return $elm$json$Json$Encode$object(attributes);
};
var $author$project$Type$ExamResult$answerValueEncoderv1 = function (answerValue) {
	if (!answerValue.$) {
		var i = answerValue.a;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'mc',
					$elm$json$Json$Encode$int(i))
				]));
	} else {
		var str = answerValue.a;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'sa',
					$elm$json$Json$Encode$string(str))
				]));
	}
};
var $author$project$Type$ExamResult$questionResultEncodeToString = function (qResult) {
	switch (qResult) {
		case 2:
			return 'c';
		case 1:
			return 'i';
		default:
			return 'n';
	}
};
var $author$project$Type$ExamResult$questionResultEncoder = function (qResult) {
	return $elm$json$Json$Encode$string(
		$author$project$Type$ExamResult$questionResultEncodeToString(qResult));
};
var $author$project$DataService$qAnswerEncoder = function (qAnswer) {
	var attributes = _List_fromArray(
		[
			_Utils_Tuple2(
			'aValue',
			function () {
				var _v0 = qAnswer.jD;
				if (!_v0.$) {
					var aValue = _v0.a;
					return $author$project$Type$ExamResult$answerValueEncoderv1(aValue);
				} else {
					return $elm$json$Json$Encode$null;
				}
			}()),
			_Utils_Tuple2(
			'aStatus',
			$author$project$Type$ExamResult$questionResultEncoder(qAnswer.jC)),
			_Utils_Tuple2(
			'isQstnFlagged',
			$elm$json$Json$Encode$bool(qAnswer.mi)),
			_Utils_Tuple2(
			'isQstnLocked',
			$elm$json$Json$Encode$bool(qAnswer.mj)),
			_Utils_Tuple2(
			'isMkd',
			$elm$json$Json$Encode$bool(qAnswer.mg)),
			_Utils_Tuple2(
			'testletId',
			$elm$json$Json$Encode$int(qAnswer.oW))
		]);
	return $elm$json$Json$Encode$object(attributes);
};
var $author$project$DataService$userDetailsEncoder = function (userDetails) {
	var attributes = _List_fromArray(
		[
			_Utils_Tuple2(
			'email',
			$elm$json$Json$Encode$string(userDetails.li)),
			_Utils_Tuple2(
			'username',
			$elm$json$Json$Encode$string(userDetails.pG)),
			_Utils_Tuple2(
			'groups',
			A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, userDetails.lL))
		]);
	return $elm$json$Json$Encode$object(attributes);
};
var $author$project$DataService$partialModelStateEncoder = function (partialModelState) {
	var attributes = _List_fromArray(
		[
			_Utils_Tuple2(
			'examStatus',
			$author$project$DataService$examStatusEncoder(partialModelState.ln)),
			_Utils_Tuple2(
			'examProgressPoints',
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'currentQstnNbr',
						function () {
							var _v0 = partialModelState.eN.kS;
							if (!_v0.$) {
								var currentQstnNbr = _v0.a;
								return $elm$json$Json$Encode$int(currentQstnNbr);
							} else {
								return $elm$json$Json$Encode$null;
							}
						}()),
						_Utils_Tuple2(
						'currentTestletsGroupId',
						function () {
							var _v1 = partialModelState.eN.kU;
							if (!_v1.$) {
								var currentTestletsGroupId = _v1.a;
								return $elm$json$Json$Encode$int(currentTestletsGroupId);
							} else {
								return $elm$json$Json$Encode$null;
							}
						}()),
						_Utils_Tuple2(
						'currentTestletId',
						function () {
							var _v2 = partialModelState.eN.kT;
							if (!_v2.$) {
								var currentTestletId = _v2.a;
								return $elm$json$Json$Encode$int(currentTestletId);
							} else {
								return $elm$json$Json$Encode$null;
							}
						}())
					]))),
			_Utils_Tuple2(
			'startTimeRaw',
			function () {
				var _v3 = partialModelState.ox;
				if (!_v3.$) {
					var startTimeRaw = _v3.a;
					return $elm$json$Json$Encode$int(
						$elm$time$Time$posixToMillis(startTimeRaw));
				} else {
					return $elm$json$Json$Encode$null;
				}
			}()),
			_Utils_Tuple2(
			'examTimeSecondsRemaining',
			function () {
				var _v4 = partialModelState.lo;
				if (!_v4.$) {
					var examTimeSecondsRemaining = _v4.a;
					return $elm$json$Json$Encode$int(examTimeSecondsRemaining);
				} else {
					return $elm$json$Json$Encode$null;
				}
			}()),
			_Utils_Tuple2(
			'qIds',
			A2($elm$json$Json$Encode$array, $elm$json$Json$Encode$int, partialModelState.nI)),
			_Utils_Tuple2(
			'currentQGroupAnswerHistory',
			A2($elm$json$Json$Encode$array, $author$project$DataService$qAnswerEncoder, partialModelState.kR)),
			_Utils_Tuple2(
			'userDetails',
			$author$project$DataService$userDetailsEncoder(partialModelState.pC)),
			_Utils_Tuple2(
			'pageModel_ShowVariant',
			$author$project$Page$ShowVariant$modelEncoder(partialModelState.no)),
			_Utils_Tuple2(
			'activeSyllabusTitle',
			function () {
				var _v5 = partialModelState.jJ;
				if (!_v5.$) {
					var activeSyllabusTitle = _v5.a;
					return $elm$json$Json$Encode$string(activeSyllabusTitle);
				} else {
					return $elm$json$Json$Encode$null;
				}
			}())
		]);
	return $elm$json$Json$Encode$object(attributes);
};
var $author$project$Ports$sendStorePartialModelState = _Platform_outgoingPort('sendStorePartialModelState', $elm$core$Basics$identity);
var $author$project$Update$makeStorePartialModelStateCmd = A2(
	$elm$core$Basics$composeL,
	A2($elm$core$Basics$composeL, $author$project$Ports$sendStorePartialModelState, $author$project$DataService$partialModelStateEncoder),
	$author$project$Helper$Common$extractPartialModelState);
var $krisajenkins$remotedata$RemoteData$map = F2(
	function (f, data) {
		switch (data.$) {
			case 3:
				var value = data.a;
				return $krisajenkins$remotedata$RemoteData$Success(
					f(value));
			case 1:
				return $krisajenkins$remotedata$RemoteData$Loading;
			case 0:
				return $krisajenkins$remotedata$RemoteData$NotAsked;
			default:
				var error = data.a;
				return $krisajenkins$remotedata$RemoteData$Failure(error);
		}
	});
var $author$project$JsInterop$Types$encodeByIdParams = function (id) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'input',
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'id',
							$elm$json$Json$Encode$string(id))
						])))
			]));
};
var $author$project$JsInterop$Types$encodeBySIdParams = function (sId) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'sId',
				$elm$json$Json$Encode$string(sId))
			]));
};
var $author$project$Type$NodeCfg$encodeCategories = function (categories) {
	return A2(
		$elm$json$Json$Encode$list,
		function (el) {
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'id',
						$elm$json$Json$Encode$string(el.lW)),
						_Utils_Tuple2(
						'title',
						$elm$json$Json$Encode$string(el.ja)),
						_Utils_Tuple2(
						'nodetype',
						$elm$json$Json$Encode$string(
							function () {
								var _v0 = el.fm;
								switch (_v0) {
									case 0:
										return 'root';
									case 1:
										return 'branch';
									default:
										return 'leaf';
								}
							}())),
						_Utils_Tuple2(
						'desc',
						$elm$json$Json$Encode$string(el.k8)),
						_Utils_Tuple2(
						'children',
						function () {
							var _v1 = el.eA;
							if (!_v1.$) {
								var children = _v1.a;
								return A2(
									$elm$json$Json$Encode$list,
									function (child) {
										return $elm$json$Json$Encode$string(child);
									},
									children);
							} else {
								return $elm$json$Json$Encode$null;
							}
						}())
					]));
		},
		categories);
};
var $author$project$Type$NodeCfg$encodeItems = function (items) {
	return A2(
		$elm$json$Json$Encode$list,
		function (el) {
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'title',
						$elm$json$Json$Encode$string(el.ja)),
						_Utils_Tuple2(
						'smuid',
						$elm$json$Json$Encode$string(el.oq)),
						_Utils_Tuple2(
						'qType',
						$elm$json$Json$Encode$string(
							function () {
								var _v0 = el.iy;
								switch (_v0) {
									case 0:
										return 'Multiple Choice (One Answer)';
									case 1:
										return 'Multiple Choice (Multiple Answer)';
									case 2:
										return 'Answer Box';
									default:
										return 'Not Implemented';
								}
							}())),
						_Utils_Tuple2(
						'slug',
						$elm$json$Json$Encode$string(el.iU)),
						_Utils_Tuple2(
						'answersets',
						A2(
							$elm$json$Json$Encode$list,
							function (answerset) {
								return $elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'difficulty',
											$elm$json$Json$Encode$int(answerset.dp)),
											_Utils_Tuple2(
											'categories',
											A2(
												$elm$json$Json$Encode$list,
												function (catId) {
													return $elm$json$Json$Encode$string(catId);
												},
												answerset.kr)),
											_Utils_Tuple2(
											'context',
											$elm$json$Json$Encode$string(
												$author$project$Type$Activity$ctxToStringLower(answerset.kL)))
										]));
							},
							el.jZ))
					]));
		},
		items);
};
var $author$project$JsInterop$Types$encodeConstructNodeForSyllabusParams = F2(
	function (syllabusId, postMetas) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'syllabus',
					$elm$json$Json$Encode$string(syllabusId)),
					_Utils_Tuple2(
					'categories',
					$author$project$Type$NodeCfg$encodeCategories(
						$author$project$Type$NodeCfg$getCategories(postMetas))),
					_Utils_Tuple2(
					'items',
					$author$project$Type$NodeCfg$encodeItems(
						$author$project$Type$NodeCfg$getItems(postMetas)))
				]));
	});
var $author$project$Type$ExamResult$getHistoryAttributesForActivityRunResult = function (_v0) {
	var aId = _v0.jz;
	var aMode = _v0.jA;
	var tsFinish = _v0.pr;
	var qs = _v0.ci;
	return _List_fromArray(
		[
			_Utils_Tuple2(
			'aId',
			$elm$json$Json$Encode$string(aId)),
			_Utils_Tuple2(
			'tsS',
			function () {
				if (!aMode.$) {
					var tsStart = aMode.a;
					return $elm$json$Json$Encode$string(tsStart);
				} else {
					return $elm$json$Json$Encode$null;
				}
			}()),
			_Utils_Tuple2(
			'wsT',
			function () {
				if (!aMode.$) {
					return $elm$json$Json$Encode$null;
				} else {
					if (!aMode.a) {
						var _v3 = aMode.a;
						return $elm$json$Json$Encode$string('wsA');
					} else {
						var _v4 = aMode.a;
						return $elm$json$Json$Encode$string('wsB');
					}
				}
			}()),
			_Utils_Tuple2(
			'tsF',
			$elm$json$Json$Encode$string(tsFinish)),
			_Utils_Tuple2(
			'qs',
			A2($elm$json$Json$Encode$list, $author$project$Type$ExamResult$historyItemEncoder, qs))
		]);
};
var $author$project$Type$ExamResult$questionResultItemEncoder = function (questionResultItem) {
	var attributes = _List_fromArray(
		[
			_Utils_Tuple2(
			'qId',
			$elm$json$Json$Encode$int(questionResultItem.dS)),
			_Utils_Tuple2(
			'r',
			$author$project$Type$ExamResult$questionResultEncoder(questionResultItem.dW)),
			_Utils_Tuple2(
			'isMkd',
			$elm$json$Json$Encode$bool(questionResultItem.mg))
		]);
	var attrsExtended = function () {
		var _v0 = questionResultItem.iu;
		if (_v0.$ === 1) {
			return attributes;
		} else {
			var qTypeVal = _v0.a;
			return A2(
				$elm$core$List$cons,
				_Utils_Tuple2(
					'a',
					$author$project$Type$ExamResult$answerValueEncoderv1(qTypeVal)),
				attributes);
		}
	}();
	return $elm$json$Json$Encode$object(attrsExtended);
};
var $author$project$Type$ExamResult$getStudentDataHistoryAttributes = function (studentDataHistory) {
	return _List_fromArray(
		[
			_Utils_Tuple2(
			'qGId',
			$elm$json$Json$Encode$int(studentDataHistory.nF)),
			_Utils_Tuple2(
			'fTId',
			$elm$json$Json$Encode$int(studentDataHistory.g3)),
			_Utils_Tuple2(
			'tsS',
			$elm$json$Json$Encode$string(studentDataHistory.jh)),
			_Utils_Tuple2(
			'tsF',
			$elm$json$Json$Encode$string(studentDataHistory.pr)),
			_Utils_Tuple2(
			'qs',
			A2($elm$json$Json$Encode$list, $author$project$Type$ExamResult$questionResultItemEncoder, studentDataHistory.ci))
		]);
};
var $author$project$JsInterop$Types$encodeCreateQuestionGroupParams = F2(
	function (id, serr) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'input',
					$elm$json$Json$Encode$object(
						A2(
							$elm$core$List$cons,
							_Utils_Tuple2(
								'id',
								$elm$json$Json$Encode$string(id)),
							function () {
								if (!serr.$) {
									var tgrr = serr.a;
									return $author$project$Type$ExamResult$getStudentDataHistoryAttributes(tgrr);
								} else {
									var actrr = serr.a;
									return $author$project$Type$ExamResult$getHistoryAttributesForActivityRunResult(actrr);
								}
							}())))
				]));
	});
var $author$project$JsInterop$Types$encodeCreateSchoolParams = function (schoolDetails) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'input',
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'id',
							$elm$json$Json$Encode$string(schoolDetails.lW)),
							_Utils_Tuple2(
							'sId',
							$elm$json$Json$Encode$string(schoolDetails.d$)),
							_Utils_Tuple2(
							'eData',
							$elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'sch',
										$elm$json$Json$Encode$object(
											_List_fromArray(
												[
													_Utils_Tuple2(
													'name',
													$elm$json$Json$Encode$string(schoolDetails.mO)),
													_Utils_Tuple2(
													'isCurrentSubscriber',
													$elm$json$Json$Encode$bool(schoolDetails.ht))
												])))
									])))
						])))
			]));
};
var $author$project$Type$Cohort$toGraphQLString = $author$project$Type$Cohort$toString;
var $author$project$JsInterop$Types$encodeEDataValue = function (person) {
	if (person.$ === 1) {
		var teacherType = person.a;
		var _v1 = function () {
			if (!teacherType.$) {
				var personEDetails_ = teacherType.a;
				return _Utils_Tuple2(personEDetails_, true);
			} else {
				var personEDetails_ = teacherType.a;
				return _Utils_Tuple2(personEDetails_, false);
			}
		}();
		var personEDetails = _v1.a;
		var isAdmin = _v1.b;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'tch',
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'firstName',
								$elm$json$Json$Encode$string(personEDetails.lt)),
								_Utils_Tuple2(
								'lastName',
								$elm$json$Json$Encode$string(personEDetails.mq)),
								_Utils_Tuple2(
								'email',
								$elm$json$Json$Encode$string(personEDetails.li)),
								_Utils_Tuple2(
								'isAdmin',
								$elm$json$Json$Encode$bool(isAdmin))
							])))
				]));
	} else {
		var studentEDetails = person.a;
		var groupEncoder = A2(
			$elm$core$Maybe$withDefault,
			$elm$json$Json$Encode$null,
			A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, studentEDetails.lI));
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'stu',
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'firstName',
								$elm$json$Json$Encode$string(studentEDetails.lt)),
								_Utils_Tuple2(
								'lastName',
								$elm$json$Json$Encode$string(studentEDetails.mq)),
								_Utils_Tuple2(
								'email',
								$elm$json$Json$Encode$string(studentEDetails.li)),
								_Utils_Tuple2(
								'cohort',
								$elm$json$Json$Encode$string(
									$author$project$Type$Cohort$toGraphQLString(studentEDetails.kA))),
								_Utils_Tuple2('group', groupEncoder),
								_Utils_Tuple2(
								'schId',
								$elm$json$Json$Encode$string(studentEDetails.n4))
							])))
				]));
	}
};
var $author$project$JsInterop$Types$encodeCreateUserParams = F3(
	function (person, password, sIdMaybe) {
		var sIdPair = function () {
			if (sIdMaybe.$ === 1) {
				return _List_Nil;
			} else {
				var sId = sIdMaybe.a;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						'sId',
						$elm$json$Json$Encode$string(sId))
					]);
			}
		}();
		return $elm$json$Json$Encode$object(
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						_Utils_Tuple2(
						'input',
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'eData',
									$author$project$JsInterop$Types$encodeEDataValue(person))
								]))),
						_Utils_Tuple2(
						'password',
						$elm$json$Json$Encode$string(password))
					]),
				sIdPair));
	});
var $author$project$JsInterop$Types$encodeDeleteLoginUserParams = function (id) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$string(id))
			]));
};
var $author$project$JsInterop$Types$encodeForgotPasswordParams = function (email) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'username',
				$elm$json$Json$Encode$string(email))
			]));
};
var $author$project$JsInterop$Types$encodeForgotPasswordSubmitParams = F3(
	function (email, code, newPassword) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'username',
					$elm$json$Json$Encode$string(email)),
					_Utils_Tuple2(
					'code',
					$elm$json$Json$Encode$string(code)),
					_Utils_Tuple2(
					'password',
					$elm$json$Json$Encode$string(newPassword))
				]));
	});
var $author$project$JsInterop$Types$encodeGetSyllabusesParams = function (categories) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'categories',
				$author$project$Type$NodeCfg$encodeCategories(categories))
			]));
};
var $author$project$JsInterop$Types$encodeGetVariantIdsParams = function (node) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2('node', node)
			]));
};
var $author$project$JsInterop$Types$encodeActivityMetaParams = function (activityMeta) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'targetNodeId',
				$elm$json$Json$Encode$string(activityMeta.oR)),
				_Utils_Tuple2(
				'context',
				$elm$json$Json$Encode$string(
					$author$project$Type$Activity$ctxToStringLower(activityMeta.kL))),
				_Utils_Tuple2(
				'initialDifficulty',
				$elm$json$Json$Encode$int(activityMeta.l3)),
				_Utils_Tuple2(
				'nodePercentages',
				A2(
					$elm$json$Json$Encode$list,
					function (el) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'nodeId',
									$elm$json$Json$Encode$string(el.mY)),
									_Utils_Tuple2(
									'pc',
									$elm$json$Json$Encode$int(el.ih))
								]));
					},
					activityMeta.mZ)),
				_Utils_Tuple2(
				'focus',
				$elm$json$Json$Encode$string(
					$author$project$Type$Activity$focusToString(activityMeta.lA)))
			]));
};
var $author$project$JsInterop$Types$encodeGetVariantSelectionParams = F5(
	function (node, activityMeta, sessionQNbr, history, targetStrikeMaybe) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2('node', node),
					_Utils_Tuple2(
					'activityMeta',
					$author$project$JsInterop$Types$encodeActivityMetaParams(activityMeta)),
					_Utils_Tuple2(
					'sessionQNbr',
					$elm$json$Json$Encode$int(sessionQNbr)),
					_Utils_Tuple2(
					'history',
					A2(
						$elm$json$Json$Encode$list,
						function (el) {
							return $elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'variantId',
										$author$project$Type$Variant$variantIdEncoder(el.pK)),
										_Utils_Tuple2(
										'result',
										$elm$json$Json$Encode$string(
											$author$project$Type$History$vAnswerEncodeToString(el.dW)))
									]));
						},
						history)),
					_Utils_Tuple2(
					'targetStrike',
					A2(
						$elm$core$Maybe$withDefault,
						$elm$json$Json$Encode$null,
						A2($elm$core$Maybe$map, $elm$json$Json$Encode$int, targetStrikeMaybe)))
				]));
	});
var $author$project$JsInterop$Types$encodeNoParams = $elm$json$Json$Encode$null;
var $author$project$JsInterop$Types$encodeSignInParams = F2(
	function (email, password) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'username',
					$elm$json$Json$Encode$string(email)),
					_Utils_Tuple2(
					'password',
					$elm$json$Json$Encode$string(password))
				]));
	});
var $author$project$JsInterop$Types$encodeSignOutParams = $elm$json$Json$Encode$null;
var $author$project$JsInterop$Types$encodeStudentsByTchIdParams = function (id) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$string(id))
			]));
};
var $author$project$JsInterop$Types$encodeUpdateLoginUserParams = F3(
	function (id, emailMaybe, passwordMaybe) {
		var obj = function (el) {
			if (!passwordMaybe.$) {
				var password = passwordMaybe.a;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(
						'password',
						$elm$json$Json$Encode$string(password)),
					el);
			} else {
				return el;
			}
		}(
			function (el) {
				if (!emailMaybe.$) {
					var email = emailMaybe.a;
					return A2(
						$elm$core$List$cons,
						_Utils_Tuple2(
							'email',
							$elm$json$Json$Encode$string(email)),
						el);
				} else {
					return el;
				}
			}(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'id',
						$elm$json$Json$Encode$string(id))
					])));
		return $elm$json$Json$Encode$object(obj);
	});
var $author$project$JsInterop$Types$encodeUpdateUserParams = function (personE) {
	return function (el) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'input',
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'id',
								$elm$json$Json$Encode$string(el.lW)),
								_Utils_Tuple2(
								'sId',
								$elm$json$Json$Encode$string(el.d$)),
								_Utils_Tuple2(
								'eData',
								$author$project$JsInterop$Types$encodeEDataValue(personE))
							])))
				]));
	}(
		$author$project$Type$User$extractPersonEDetails(personE));
};
var $author$project$JsInterop$Plumbing$infoForGraphQL = _Platform_outgoingPort(
	'infoForGraphQL',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'params',
					$elm$core$Basics$identity($.s)),
					_Utils_Tuple2(
					'query',
					$elm$json$Json$Encode$string($.t)),
					_Utils_Tuple2(
					'respTag',
					$elm$json$Json$Encode$string($.u))
				]));
	});
var $author$project$JsInterop$Plumbing$infoForOutside = _Platform_outgoingPort(
	'infoForOutside',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'payload',
					$elm$core$Basics$identity($.c)),
					_Utils_Tuple2(
					'tag',
					$elm$core$Basics$identity($.M))
				]));
	});
var $author$project$JsInterop$Plumbing$infoForPurescript = _Platform_outgoingPort(
	'infoForPurescript',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'payload',
					$elm$core$Basics$identity($.c)),
					_Utils_Tuple2(
					'tag',
					$elm$core$Basics$identity($.M))
				]));
	});
var $author$project$JsInterop$Types$interopTagEncoder = function (interopTag) {
	return $elm$json$Json$Encode$string(
		function () {
			switch (interopTag) {
				case 0:
					return 'InteropFailureJTE';
				case 1:
					return 'SignInJTE';
				case 2:
					return 'ForgotPasswordJTE';
				case 3:
					return 'ForgotPasswordSubmitJTE';
				case 4:
					return 'SignOutJTE';
				case 5:
					return 'MeJTE';
				case 6:
					return 'GetVariantSelection_Mjd5_JTE';
				case 7:
					return 'GetVariantSelection_ShowVariant_JTE';
				case 8:
					return 'GetVariantSelection_ShowWorksheets_JTE';
				case 9:
					return 'GetVariantIdsJTE';
				case 10:
					return 'ConstructNodeForSyllabusJTE';
				case 11:
					return 'GetSyllabusesJTE';
				case 12:
					return 'TeachersJTE';
				case 13:
					return 'StudentsJTE';
				case 14:
					return 'SchoolsJTE';
				case 15:
					return 'NbrUsersForSchoolIdJTE';
				case 16:
					return 'DeleteSchoolJTE';
				case 17:
					return 'StudentsByTchIdJTE';
				case 18:
					return 'UpdateTeacherJTE';
				case 19:
					return 'UpdateStudentJTE';
				case 20:
					return 'UpdateLoginUserJTE';
				case 21:
					return 'DeleteLoginUserJTE';
				case 22:
					return 'DeleteStudentHistoryJTE';
				case 23:
					return 'CreateTeacherJTE';
				case 24:
					return 'CreateStudentJTE';
				case 37:
					return 'CreateSchoolJTE';
				case 38:
					return 'CreateQuestionGroupJTE';
				case 36:
					return 'LogErrorETJ';
				case 25:
					return 'SignInETJ';
				case 26:
					return 'ForgotPasswordETJ';
				case 27:
					return 'ForgotPasswordSubmitETJ';
				case 28:
					return 'SignOutETJ';
				case 35:
					return 'MeETJ';
				case 29:
					return 'GetVariantSelection_Mjd5_ETJ';
				case 30:
					return 'GetVariantSelection_ShowVariant_ETJ';
				case 31:
					return 'GetVariantSelection_ShowWorksheets_ETJ';
				case 32:
					return 'GetVariantIdsETJ';
				case 33:
					return 'ConstructNodeForSyllabusETJ';
				default:
					return 'GetSyllabusesETJ';
			}
		}());
};
var $author$project$JsInterop$Plumbing$sendInfoOutside = function (info) {
	switch (info.$) {
		case 0:
			var err = info.a;
			return $author$project$JsInterop$Plumbing$infoForOutside(
				{
					c: $elm$json$Json$Encode$string(err),
					M: $author$project$JsInterop$Types$interopTagEncoder(36)
				});
		case 1:
			var email = info.a;
			var password = info.b;
			return $author$project$JsInterop$Plumbing$infoForOutside(
				{
					c: A2($author$project$JsInterop$Types$encodeSignInParams, email, password),
					M: $author$project$JsInterop$Types$interopTagEncoder(25)
				});
		case 2:
			var email = info.a;
			return $author$project$JsInterop$Plumbing$infoForOutside(
				{
					c: $author$project$JsInterop$Types$encodeForgotPasswordParams(email),
					M: $author$project$JsInterop$Types$interopTagEncoder(26)
				});
		case 3:
			var email = info.a;
			var code = info.b;
			var newPassword = info.c;
			return $author$project$JsInterop$Plumbing$infoForOutside(
				{
					c: A3($author$project$JsInterop$Types$encodeForgotPasswordSubmitParams, email, code, newPassword),
					M: $author$project$JsInterop$Types$interopTagEncoder(27)
				});
		case 4:
			return $author$project$JsInterop$Plumbing$infoForOutside(
				{
					c: $author$project$JsInterop$Types$encodeSignOutParams,
					M: $author$project$JsInterop$Types$interopTagEncoder(28)
				});
		case 5:
			switch (info.a.$) {
				case 1:
					var _v1 = info.a;
					var interopTagForResponse = _v1.a;
					var node = _v1.b;
					var activityMeta = _v1.c;
					var sessionQNbr = _v1.d;
					var history = _v1.e;
					var targetStrikeMaybe = _v1.f;
					return $author$project$JsInterop$Plumbing$infoForOutside(
						{
							c: A5($author$project$JsInterop$Types$encodeGetVariantSelectionParams, node, activityMeta, sessionQNbr, history, targetStrikeMaybe),
							M: $author$project$JsInterop$Types$interopTagEncoder(interopTagForResponse)
						});
				case 0:
					var node = info.a.a;
					return $author$project$JsInterop$Plumbing$infoForOutside(
						{
							c: $author$project$JsInterop$Types$encodeGetVariantIdsParams(node),
							M: $author$project$JsInterop$Types$interopTagEncoder(32)
						});
				case 2:
					var _v2 = info.a;
					var syllabusId = _v2.a;
					var postMetas = _v2.b;
					return $author$project$JsInterop$Plumbing$infoForOutside(
						{
							c: A2($author$project$JsInterop$Types$encodeConstructNodeForSyllabusParams, syllabusId, postMetas),
							M: $author$project$JsInterop$Types$interopTagEncoder(33)
						});
				default:
					var categoriesData = info.a.a;
					return $author$project$JsInterop$Plumbing$infoForOutside(
						{
							c: $author$project$JsInterop$Types$encodeGetSyllabusesParams(categoriesData),
							M: $author$project$JsInterop$Types$interopTagEncoder(34)
						});
			}
		case 6:
			return $author$project$JsInterop$Plumbing$infoForOutside(
				{
					c: $author$project$JsInterop$Types$encodeNoParams,
					M: $author$project$JsInterop$Types$interopTagEncoder(35)
				});
		case 7:
			return $author$project$JsInterop$Plumbing$infoForPurescript(
				{
					c: $author$project$JsInterop$Types$encodeNoParams,
					M: $author$project$JsInterop$Types$interopTagEncoder(35)
				});
		default:
			switch (info.a.$) {
				case 0:
					var _v3 = info.a;
					var query = info.b;
					return $author$project$JsInterop$Plumbing$infoForGraphQL(
						{s: $author$project$JsInterop$Types$encodeNoParams, t: query, u: 'MeJTE'});
				case 1:
					var _v4 = info.a;
					var query = info.b;
					return $author$project$JsInterop$Plumbing$infoForGraphQL(
						{s: $author$project$JsInterop$Types$encodeNoParams, t: query, u: 'TeachersJTE'});
				case 2:
					var _v5 = info.a;
					var query = info.b;
					return $author$project$JsInterop$Plumbing$infoForGraphQL(
						{s: $author$project$JsInterop$Types$encodeNoParams, t: query, u: 'StudentsJTE'});
				case 3:
					var _v6 = info.a;
					var query = info.b;
					return $author$project$JsInterop$Plumbing$infoForGraphQL(
						{s: $author$project$JsInterop$Types$encodeNoParams, t: query, u: 'StudentsJTE'});
				case 4:
					var sId = info.a.a;
					var query = info.b;
					return $author$project$JsInterop$Plumbing$infoForGraphQL(
						{
							s: $author$project$JsInterop$Types$encodeBySIdParams(sId),
							t: query,
							u: 'SchoolsJTE'
						});
				case 5:
					var sId = info.a.a;
					var query = info.b;
					return $author$project$JsInterop$Plumbing$infoForGraphQL(
						{
							s: $author$project$JsInterop$Types$encodeBySIdParams(sId),
							t: query,
							u: 'NbrUsersForSchoolIdJTE'
						});
				case 6:
					var id = info.a.a;
					var query = info.b;
					return $author$project$JsInterop$Plumbing$infoForGraphQL(
						{
							s: $author$project$JsInterop$Types$encodeByIdParams(id),
							t: query,
							u: 'DeleteSchoolJTE'
						});
				case 7:
					var id = info.a.a;
					var query = info.b;
					return $author$project$JsInterop$Plumbing$infoForGraphQL(
						{
							s: $author$project$JsInterop$Types$encodeStudentsByTchIdParams(id),
							t: query,
							u: 'StudentsByTchIdJTE'
						});
				case 8:
					var id = info.a.a;
					var query = info.b;
					return $author$project$JsInterop$Plumbing$infoForGraphQL(
						{
							s: $author$project$JsInterop$Types$encodeStudentsByTchIdParams(id),
							t: query,
							u: 'StudentsByTchIdJTE'
						});
				case 9:
					var user = info.a.a;
					var query = info.b;
					return $author$project$JsInterop$Plumbing$infoForGraphQL(
						{
							s: $author$project$JsInterop$Types$encodeUpdateUserParams(user),
							t: query,
							u: 'UpdateTeacherJTE'
						});
				case 10:
					var user = info.a.a;
					var query = info.b;
					return $author$project$JsInterop$Plumbing$infoForGraphQL(
						{
							s: $author$project$JsInterop$Types$encodeUpdateUserParams(user),
							t: query,
							u: 'UpdateStudentJTE'
						});
				case 14:
					var _v7 = info.a;
					var person = _v7.a;
					var password = _v7.b;
					var sIdMaybe = _v7.c;
					var query = info.b;
					return $author$project$JsInterop$Plumbing$infoForGraphQL(
						{
							s: A3($author$project$JsInterop$Types$encodeCreateUserParams, person, password, sIdMaybe),
							t: query,
							u: 'CreateTeacherJTE'
						});
				case 15:
					var _v8 = info.a;
					var person = _v8.a;
					var password = _v8.b;
					var sIdMaybe = _v8.c;
					var query = info.b;
					return $author$project$JsInterop$Plumbing$infoForGraphQL(
						{
							s: A3($author$project$JsInterop$Types$encodeCreateUserParams, person, password, sIdMaybe),
							t: query,
							u: 'CreateStudentJTE'
						});
				case 11:
					var _v9 = info.a;
					var id = _v9.a;
					var email = _v9.b;
					var password = _v9.c;
					var query = info.b;
					return $author$project$JsInterop$Plumbing$infoForGraphQL(
						{
							s: A3($author$project$JsInterop$Types$encodeUpdateLoginUserParams, id, email, password),
							t: query,
							u: 'UpdateLoginUserJTE'
						});
				case 12:
					var id = info.a.a;
					var query = info.b;
					return $author$project$JsInterop$Plumbing$infoForGraphQL(
						{
							s: $author$project$JsInterop$Types$encodeDeleteLoginUserParams(id),
							t: query,
							u: 'DeleteLoginUserJTE'
						});
				case 13:
					var id = info.a.a;
					var query = info.b;
					return $author$project$JsInterop$Plumbing$infoForGraphQL(
						{
							s: $author$project$JsInterop$Types$encodeDeleteLoginUserParams(id),
							t: query,
							u: 'DeleteStudentHistoryJTE'
						});
				case 16:
					var schoolDetails = info.a.a;
					var query = info.b;
					return $author$project$JsInterop$Plumbing$infoForGraphQL(
						{
							s: $author$project$JsInterop$Types$encodeCreateSchoolParams(schoolDetails),
							t: query,
							u: 'CreateSchoolJTE'
						});
				default:
					var _v10 = info.a;
					var id = _v10.a;
					var singleExamRunResult = _v10.b;
					var query = info.b;
					return $author$project$JsInterop$Plumbing$infoForGraphQL(
						{
							s: A2($author$project$JsInterop$Types$encodeCreateQuestionGroupParams, id, singleExamRunResult),
							t: query,
							u: 'CreateQuestionGroupJTE'
						});
			}
	}
};
var $author$project$Update$handleCommonSingleExamRunResultUpdates = F2(
	function (model, serr) {
		var cmdBase = $author$project$Update$makeStorePartialModelStateCmd(model);
		if ($author$project$Type$ExamResult$isSERRQuestionListEmpty(serr)) {
			return _Utils_Tuple2(model, cmdBase);
		} else {
			var updateSerrs = F2(
				function (serr_, serrs_) {
					return A2($elm$core$List$member, serr, serrs_) ? serrs_ : A2($elm$core$List$cons, serr_, serrs_);
				});
			var studentData = A2(
				$krisajenkins$remotedata$RemoteData$map,
				function (el) {
					return _Utils_update(
						el,
						{
							eZ: A2(updateSerrs, serr, el.eZ)
						});
				},
				model.fQ);
			var me = function () {
				var _v0 = model.fc;
				if ((_v0.$ === 3) && (!_v0.a.$)) {
					var studentEType = _v0.a.a;
					return $krisajenkins$remotedata$RemoteData$Success(
						$author$project$Type$User$StudentE(
							_Utils_update(
								studentEType,
								{
									lU: A2(updateSerrs, serr, studentEType.lU)
								})));
				} else {
					return model.fc;
				}
			}();
			var addCmdCreateQuestionGroup = function (cmd) {
				return $author$project$Type$User$isRemoteMeAStudentE(model.fc) ? A2(
					$elm$core$Maybe$withDefault,
					cmd,
					A2(
						$elm$core$Maybe$map,
						function (id) {
							return $elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										cmd,
										$author$project$JsInterop$Plumbing$sendInfoOutside(
										A2(
											$author$project$JsInterop$Plumbing$GraphQL,
											A2($author$project$JsInterop$Plumbing$CreateQuestionGroupGQL, id, serr),
											A2(
												$author$project$JsInterop$Plumbing$getGraphQLQuery,
												model.lx.lF,
												A2($author$project$JsInterop$Plumbing$CreateQuestionGroupGQL, id, serr))))
									]));
						},
						$author$project$Type$User$getMeId(model.fc))) : cmd;
			};
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{fc: me, fQ: studentData}),
				addCmdCreateQuestionGroup(cmdBase));
		}
	});
var $author$project$Type$Error$SMError = F2(
	function (errText, errTextTechDetails) {
		return {lj: errText, lk: errTextTechDetails};
	});
var $author$project$Type$Error$makeError = F2(
	function (eText, eTextTechDetails) {
		return $elm$core$Maybe$Just(
			A2($author$project$Type$Error$SMError, eText, eTextTechDetails));
	});
var $author$project$JsInterop$Types$msgForUserToString = function (msg) {
	var str = msg;
	return str;
};
var $author$project$Ports$pushUrl = _Platform_outgoingPort('pushUrl', $elm$json$Json$Encode$string);
var $author$project$Update$handleFatalInteropFailure = F2(
	function (model, ferr) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					ll: A2(
						$author$project$Type$Error$makeError,
						$author$project$JsInterop$Types$msgForUserToString(ferr.hG),
						$elm$core$Maybe$Just(ferr.gU))
				}),
			$author$project$Ports$pushUrl(
				$author$project$Routing$withHash($author$project$Routing$smPaths.ll)));
	});
var $elm$core$Elm$JsArray$appendN = _JsArray_appendN;
var $elm$core$Elm$JsArray$slice = _JsArray_slice;
var $elm$core$Array$appendHelpBuilder = F2(
	function (tail, builder) {
		var tailLen = $elm$core$Elm$JsArray$length(tail);
		var notAppended = ($elm$core$Array$branchFactor - $elm$core$Elm$JsArray$length(builder.G)) - tailLen;
		var appended = A3($elm$core$Elm$JsArray$appendN, $elm$core$Array$branchFactor, builder.G, tail);
		return (notAppended < 0) ? {
			I: A2(
				$elm$core$List$cons,
				$elm$core$Array$Leaf(appended),
				builder.I),
			A: builder.A + 1,
			G: A3($elm$core$Elm$JsArray$slice, notAppended, tailLen, tail)
		} : ((!notAppended) ? {
			I: A2(
				$elm$core$List$cons,
				$elm$core$Array$Leaf(appended),
				builder.I),
			A: builder.A + 1,
			G: $elm$core$Elm$JsArray$empty
		} : {I: builder.I, A: builder.A, G: appended});
	});
var $elm$core$Elm$JsArray$push = _JsArray_push;
var $elm$core$Elm$JsArray$singleton = _JsArray_singleton;
var $elm$core$Elm$JsArray$unsafeSet = _JsArray_unsafeSet;
var $elm$core$Array$insertTailInTree = F4(
	function (shift, index, tail, tree) {
		var pos = $elm$core$Array$bitMask & (index >>> shift);
		if (_Utils_cmp(
			pos,
			$elm$core$Elm$JsArray$length(tree)) > -1) {
			if (shift === 5) {
				return A2(
					$elm$core$Elm$JsArray$push,
					$elm$core$Array$Leaf(tail),
					tree);
			} else {
				var newSub = $elm$core$Array$SubTree(
					A4($elm$core$Array$insertTailInTree, shift - $elm$core$Array$shiftStep, index, tail, $elm$core$Elm$JsArray$empty));
				return A2($elm$core$Elm$JsArray$push, newSub, tree);
			}
		} else {
			var value = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!value.$) {
				var subTree = value.a;
				var newSub = $elm$core$Array$SubTree(
					A4($elm$core$Array$insertTailInTree, shift - $elm$core$Array$shiftStep, index, tail, subTree));
				return A3($elm$core$Elm$JsArray$unsafeSet, pos, newSub, tree);
			} else {
				var newSub = $elm$core$Array$SubTree(
					A4(
						$elm$core$Array$insertTailInTree,
						shift - $elm$core$Array$shiftStep,
						index,
						tail,
						$elm$core$Elm$JsArray$singleton(value)));
				return A3($elm$core$Elm$JsArray$unsafeSet, pos, newSub, tree);
			}
		}
	});
var $elm$core$Array$unsafeReplaceTail = F2(
	function (newTail, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		var originalTailLen = $elm$core$Elm$JsArray$length(tail);
		var newTailLen = $elm$core$Elm$JsArray$length(newTail);
		var newArrayLen = len + (newTailLen - originalTailLen);
		if (_Utils_eq(newTailLen, $elm$core$Array$branchFactor)) {
			var overflow = _Utils_cmp(newArrayLen >>> $elm$core$Array$shiftStep, 1 << startShift) > 0;
			if (overflow) {
				var newShift = startShift + $elm$core$Array$shiftStep;
				var newTree = A4(
					$elm$core$Array$insertTailInTree,
					newShift,
					len,
					newTail,
					$elm$core$Elm$JsArray$singleton(
						$elm$core$Array$SubTree(tree)));
				return A4($elm$core$Array$Array_elm_builtin, newArrayLen, newShift, newTree, $elm$core$Elm$JsArray$empty);
			} else {
				return A4(
					$elm$core$Array$Array_elm_builtin,
					newArrayLen,
					startShift,
					A4($elm$core$Array$insertTailInTree, startShift, len, newTail, tree),
					$elm$core$Elm$JsArray$empty);
			}
		} else {
			return A4($elm$core$Array$Array_elm_builtin, newArrayLen, startShift, tree, newTail);
		}
	});
var $elm$core$Array$appendHelpTree = F2(
	function (toAppend, array) {
		var len = array.a;
		var tree = array.c;
		var tail = array.d;
		var itemsToAppend = $elm$core$Elm$JsArray$length(toAppend);
		var notAppended = ($elm$core$Array$branchFactor - $elm$core$Elm$JsArray$length(tail)) - itemsToAppend;
		var appended = A3($elm$core$Elm$JsArray$appendN, $elm$core$Array$branchFactor, tail, toAppend);
		var newArray = A2($elm$core$Array$unsafeReplaceTail, appended, array);
		if (notAppended < 0) {
			var nextTail = A3($elm$core$Elm$JsArray$slice, notAppended, itemsToAppend, toAppend);
			return A2($elm$core$Array$unsafeReplaceTail, nextTail, newArray);
		} else {
			return newArray;
		}
	});
var $elm$core$Array$builderFromArray = function (_v0) {
	var len = _v0.a;
	var tree = _v0.c;
	var tail = _v0.d;
	var helper = F2(
		function (node, acc) {
			if (!node.$) {
				var subTree = node.a;
				return A3($elm$core$Elm$JsArray$foldl, helper, acc, subTree);
			} else {
				return A2($elm$core$List$cons, node, acc);
			}
		});
	return {
		I: A3($elm$core$Elm$JsArray$foldl, helper, _List_Nil, tree),
		A: (len / $elm$core$Array$branchFactor) | 0,
		G: tail
	};
};
var $elm$core$Array$append = F2(
	function (a, _v0) {
		var aTail = a.d;
		var bLen = _v0.a;
		var bTree = _v0.c;
		var bTail = _v0.d;
		if (_Utils_cmp(bLen, $elm$core$Array$branchFactor * 4) < 1) {
			var foldHelper = F2(
				function (node, array) {
					if (!node.$) {
						var tree = node.a;
						return A3($elm$core$Elm$JsArray$foldl, foldHelper, array, tree);
					} else {
						var leaf = node.a;
						return A2($elm$core$Array$appendHelpTree, leaf, array);
					}
				});
			return A2(
				$elm$core$Array$appendHelpTree,
				bTail,
				A3($elm$core$Elm$JsArray$foldl, foldHelper, a, bTree));
		} else {
			var foldHelper = F2(
				function (node, builder) {
					if (!node.$) {
						var tree = node.a;
						return A3($elm$core$Elm$JsArray$foldl, foldHelper, builder, tree);
					} else {
						var leaf = node.a;
						return A2($elm$core$Array$appendHelpBuilder, leaf, builder);
					}
				});
			return A2(
				$elm$core$Array$builderToArray,
				true,
				A2(
					$elm$core$Array$appendHelpBuilder,
					bTail,
					A3(
						$elm$core$Elm$JsArray$foldl,
						foldHelper,
						$elm$core$Array$builderFromArray(a),
						bTree)));
		}
	});
var $elm$core$Array$repeat = F2(
	function (n, e) {
		return A2(
			$elm$core$Array$initialize,
			n,
			function (_v0) {
				return e;
			});
	});
var $author$project$Update$resetModel = function (model) {
	var keepFlags = model.lx;
	return _Utils_update(
		$author$project$Models$blankModel,
		{lx: keepFlags});
};
var $author$project$Update$handleNextTestletDetail = F2(
	function (model, testlet) {
		var _v0 = model.kS;
		if (_v0.$ === 1) {
			var err = A2(
				$author$project$Type$Error$makeError,
				'There is no current question number set, so it is unable to be requested.',
				$elm$core$Maybe$Just(
					'The currentQstnNbr is \'Nothing\' and the testlet.id is ' + $elm$core$String$fromInt(testlet.lW)));
			return _Utils_Tuple2(
				$author$project$Update$resetModel(
					_Utils_update(
						model,
						{ll: err, n_: $author$project$Routing$FatalErrorRoute})),
				$author$project$Ports$pushUrl(
					$author$project$Routing$withHash($author$project$Routing$smPaths.ll)));
		} else {
			var qNbr = _v0.a;
			return _Utils_Tuple2(
				$author$project$Update$cleanAnyPreviousError(
					_Utils_update(
						model,
						{
							kR: A2(
								$elm$core$Array$append,
								model.kR,
								A2(
									$elm$core$Array$repeat,
									$elm$core$Array$length(testlet.ci),
									A2($author$project$Update$defaultQAnswerValues, testlet.mg, testlet.lW))),
							kT: $elm$core$Maybe$Just(testlet.lW),
							nI: A2($elm$core$Array$append, model.nI, testlet.ci)
						})),
				$elm$core$Platform$Cmd$batch(
					_List_fromArray(
						[
							$author$project$Ports$pushUrl(
							$author$project$Routing$withHash($author$project$Routing$smPaths.it) + ('/' + $elm$core$String$fromInt(qNbr)))
						])));
		}
	});
var $author$project$DataService$cohortExamGroupsEncoder = function (cohortExamGroups) {
	return $elm$json$Json$Encode$object(
		A2(
			$elm$core$List$map,
			function (_v0) {
				var groupTag = _v0.a;
				var activityIds = _v0.b;
				return _Utils_Tuple2(
					groupTag,
					A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, activityIds));
			},
			$elm$core$Dict$toList(cohortExamGroups)));
};
var $author$project$DataService$hiddenExamGroupsEncoder = function (hiddenExamGroups) {
	return $elm$json$Json$Encode$object(
		A2(
			$elm$core$List$map,
			function (_v0) {
				var cohortString = _v0.a;
				var cohortExamGroups = _v0.b;
				return _Utils_Tuple2(
					cohortString,
					$author$project$DataService$cohortExamGroupsEncoder(cohortExamGroups));
			},
			$elm$core$Dict$toList(hiddenExamGroups)));
};
var $author$project$Helper$Util$httpErrorToString = function (err) {
	switch (err.$) {
		case 0:
			var str = err.a;
			return 'BadUrl: ' + str;
		case 1:
			return 'Timeout: Please check your network connection and try again.';
		case 2:
			return 'Network Error: Please check your network connection and try again.';
		case 3:
			var i = err.a;
			return 'BadStatus: ' + $elm$core$String$fromInt(i);
		default:
			var str2 = err.a;
			return 'BadBody: ' + str2;
	}
};
var $author$project$Page$MutateUsers$MutateRequestNotYetSent = {$: 0};
var $author$project$Page$MutateUsers$initPersonEsToMutate = function (personEs) {
	return A2(
		$elm$core$List$map,
		function (p) {
			return {bG: $author$project$Page$MutateUsers$MutateRequestNotYetSent, a9: p};
		},
		personEs);
};
var $author$project$Type$User$isRemoteMeAnAdminE = function (remoteMe) {
	return A3($krisajenkins$remotedata$RemoteData$unwrap, false, $author$project$Type$User$isTeacherEAdminE, remoteMe);
};
var $krisajenkins$remotedata$RemoteData$isSuccess = function (data) {
	if (data.$ === 3) {
		var x = data.a;
		return true;
	} else {
		return false;
	}
};
var $author$project$Type$User$isTeacherENonAdminE = function (person) {
	if ((person.$ === 1) && (person.a.$ === 1)) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Type$User$isTeacherE = function (p) {
	return $author$project$Type$User$isTeacherENonAdminE(p) || $author$project$Type$User$isTeacherEAdminE(p);
};
var $author$project$Update$loadCurrQstnUpdateResponse = F3(
	function (model, urlHashPath, qNbr) {
		var _v0 = model.kT;
		if (!_v0.$) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						kS: $elm$core$Maybe$Just(qNbr)
					}),
				$author$project$Ports$pushUrl(
					urlHashPath + ('/' + $elm$core$String$fromInt(qNbr))));
		} else {
			var err = A2(
				$author$project$Type$Error$makeError,
				'We were unable to retrieve the question details.',
				$elm$core$Maybe$Just(
					'The currentTestletId is \'Nothing\' and the qNbr is ' + $elm$core$String$fromInt(qNbr)));
			return _Utils_Tuple2(
				$author$project$Update$resetModel(
					_Utils_update(
						model,
						{ll: err, n_: $author$project$Routing$FatalErrorRoute})),
				$author$project$Ports$pushUrl(
					$author$project$Routing$withHash($author$project$Routing$smPaths.ll)));
		}
	});
var $author$project$Type$AppCfg$lvlForNapStyleExams = function (rawLvl) {
	return A2(
		$elm$core$List$member,
		rawLvl,
		_List_fromArray(
			[4, 6, 8])) ? (rawLvl + 1) : rawLvl;
};
var $author$project$Page$ManageUsers$makeAugmentedTeacherERecord = function (personE) {
	return A2(
		$elm$core$Maybe$map,
		function (el) {
			return {W: false, bN: el};
		},
		$author$project$Type$User$extractTeacherEFromPersonE(personE));
};
var $author$project$Page$ManageUsers$AugmentedUser = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Page$ManageUsers$makeAugmentedUserFromPersonE = function (personE) {
	return A2($author$project$Page$ManageUsers$AugmentedUser, personE, false);
};
var $author$project$JsInterop$Plumbing$OpConstructNodeForSyllabus = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Type$Syllabus$fromCohort = F2(
	function (syllabuses, cohort) {
		return $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.kA;
					},
					$elm$core$Basics$eq(cohort)),
				syllabuses));
	});
var $author$project$Type$Syllabus$getSyllabus = F2(
	function (syllabuses, cohort) {
		return A2(
			$elm$core$Maybe$withDefault,
			A3($author$project$Type$Syllabus$Syllabus, 'NoSyllabusIdFound', $author$project$Type$Cohort$placeholder, 'No Syllabus Description Found'),
			A2($author$project$Type$Syllabus$fromCohort, syllabuses, cohort));
	});
var $author$project$Type$Syllabus$getSyllabusId = F2(
	function (cohort, syllabuses) {
		return A2($author$project$Type$Syllabus$getSyllabus, syllabuses, cohort).lW;
	});
var $krisajenkins$remotedata$RemoteData$withDefault = F2(
	function (_default, data) {
		if (data.$ === 3) {
			var x = data.a;
			return x;
		} else {
			return _default;
		}
	});
var $krisajenkins$remotedata$RemoteData$toMaybe = A2(
	$elm$core$Basics$composeR,
	$krisajenkins$remotedata$RemoteData$map($elm$core$Maybe$Just),
	$krisajenkins$remotedata$RemoteData$withDefault($elm$core$Maybe$Nothing));
var $author$project$Update$makeConstructNodeForSyllabusCmd = function (nodeCfg) {
	var syllabusId = A2(
		$elm$core$Maybe$withDefault,
		'none',
		A2(
			$elm$core$Maybe$andThen,
			function (syllabuses) {
				return A2(
					$elm$core$Maybe$map,
					function (el) {
						return A2($author$project$Type$Syllabus$getSyllabusId, el, syllabuses);
					},
					A2($elm$core$Maybe$andThen, $author$project$Type$Cohort$fromSyllabusTitleToCohortMaybe, nodeCfg.jJ));
			},
			$krisajenkins$remotedata$RemoteData$toMaybe(nodeCfg.oJ)));
	var postMetas = A2($krisajenkins$remotedata$RemoteData$withDefault, _List_Nil, nodeCfg.fs);
	return $author$project$JsInterop$Plumbing$sendInfoOutside(
		$author$project$JsInterop$Plumbing$PursBundleInterop(
			A2($author$project$JsInterop$Plumbing$OpConstructNodeForSyllabus, syllabusId, postMetas)));
};
var $author$project$Type$NodeCfg$NodeCfgResolved = F4(
	function (node, syllabuses, currentCohort, postMetas) {
		return {cF: currentCohort, mW: node, fs: postMetas, oJ: syllabuses};
	});
var $krisajenkins$remotedata$RemoteData$andMap = F2(
	function (wrappedValue, wrappedFunction) {
		var _v0 = _Utils_Tuple2(wrappedFunction, wrappedValue);
		_v0$2:
		while (true) {
			_v0$3:
			while (true) {
				_v0$4:
				while (true) {
					_v0$5:
					while (true) {
						switch (_v0.a.$) {
							case 3:
								switch (_v0.b.$) {
									case 3:
										var f = _v0.a.a;
										var value = _v0.b.a;
										return $krisajenkins$remotedata$RemoteData$Success(
											f(value));
									case 2:
										break _v0$2;
									case 1:
										break _v0$4;
									default:
										var _v4 = _v0.b;
										return $krisajenkins$remotedata$RemoteData$NotAsked;
								}
							case 2:
								var error = _v0.a.a;
								return $krisajenkins$remotedata$RemoteData$Failure(error);
							case 1:
								switch (_v0.b.$) {
									case 2:
										break _v0$2;
									case 1:
										break _v0$3;
									case 0:
										break _v0$3;
									default:
										break _v0$3;
								}
							default:
								switch (_v0.b.$) {
									case 2:
										break _v0$2;
									case 1:
										break _v0$4;
									case 0:
										break _v0$5;
									default:
										break _v0$5;
								}
						}
					}
					var _v3 = _v0.a;
					return $krisajenkins$remotedata$RemoteData$NotAsked;
				}
				var _v2 = _v0.b;
				return $krisajenkins$remotedata$RemoteData$Loading;
			}
			var _v1 = _v0.a;
			return $krisajenkins$remotedata$RemoteData$Loading;
		}
		var error = _v0.b.a;
		return $krisajenkins$remotedata$RemoteData$Failure(error);
	});
var $krisajenkins$remotedata$RemoteData$map3 = F4(
	function (f, a, b, c) {
		return A2(
			$krisajenkins$remotedata$RemoteData$andMap,
			c,
			A2(
				$krisajenkins$remotedata$RemoteData$andMap,
				b,
				A2($krisajenkins$remotedata$RemoteData$map, f, a)));
	});
var $krisajenkins$remotedata$RemoteData$mapError = F2(
	function (f, data) {
		switch (data.$) {
			case 3:
				var x = data.a;
				return $krisajenkins$remotedata$RemoteData$Success(x);
			case 2:
				var e = data.a;
				return $krisajenkins$remotedata$RemoteData$Failure(
					f(e));
			case 1:
				return $krisajenkins$remotedata$RemoteData$Loading;
			default:
				return $krisajenkins$remotedata$RemoteData$NotAsked;
		}
	});
var $author$project$Type$NodeCfg$makeNodeCfgResolved = function (nodeCfg) {
	var cohort = A2(
		$elm$core$Maybe$withDefault,
		$author$project$Type$Cohort$placeholder,
		A2($elm$core$Maybe$andThen, $author$project$Type$Cohort$fromSyllabusTitleToCohortMaybe, nodeCfg.jJ));
	return A4(
		$krisajenkins$remotedata$RemoteData$map3,
		F3(
			function (node, syllabuses, postMetas) {
				return A4($author$project$Type$NodeCfg$NodeCfgResolved, node, syllabuses, cohort, postMetas);
			}),
		nodeCfg.mW,
		nodeCfg.oJ,
		A2($krisajenkins$remotedata$RemoteData$mapError, $author$project$Helper$Util$httpErrorToString, nodeCfg.fs));
};
var $author$project$Type$ReaderConfig$ReaderState = function (flags) {
	return function (me) {
		return function (userDetails) {
			return function (appCfg) {
				return function (nodeCfg) {
					return function (isExamInProgress) {
						return function (history) {
							return function (examTimeSecondsRemaining) {
								return function (route) {
									return function (qstnEngineMaxDiffTolerance) {
										return {j$: appCfg, lo: examTimeSecondsRemaining, lx: flags, eZ: history, me: isExamInProgress, fc: me, mX: nodeCfg, fv: qstnEngineMaxDiffTolerance, n_: route, pC: userDetails};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Models$makeReaderStateWith = F4(
	function (model, appCfg, me, nodeCfgResolved) {
		var qstnEngineMaxDiffTolerance = 30;
		var history = A2(
			$krisajenkins$remotedata$RemoteData$withDefault,
			_List_Nil,
			A2(
				$krisajenkins$remotedata$RemoteData$map,
				function ($) {
					return $.eZ;
				},
				model.fQ));
		return $author$project$Type$ReaderConfig$ReaderState(model.lx)(me)(model.pC)(appCfg)(nodeCfgResolved)(model.ln === 1)(history)(model.lo)(model.n_)(qstnEngineMaxDiffTolerance);
	});
var $elm$core$Maybe$map3 = F4(
	function (func, ma, mb, mc) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				if (mc.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var c = mc.a;
					return $elm$core$Maybe$Just(
						A3(func, a, b, c));
				}
			}
		}
	});
var $author$project$Models$makeReaderState = function (model) {
	var nodeCfgResolvedMaybe = $krisajenkins$remotedata$RemoteData$toMaybe(
		$author$project$Type$NodeCfg$makeNodeCfgResolved(model.mX));
	var meMaybe = $krisajenkins$remotedata$RemoteData$toMaybe(model.fc);
	var appCfgMaybe = $krisajenkins$remotedata$RemoteData$toMaybe(model.j$);
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Maybe$Nothing,
		A4(
			$elm$core$Maybe$map3,
			F3(
				function (me, appCfg, nodeCfgResolved) {
					return $elm$core$Maybe$Just(
						A4($author$project$Models$makeReaderStateWith, model, appCfg, me, nodeCfgResolved));
				}),
			meMaybe,
			appCfgMaybe,
			nodeCfgResolvedMaybe));
};
var $author$project$Page$ManageUsers$User = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$ManageUsers$makeUserFromPersonE = function (personE) {
	return $author$project$Page$ManageUsers$User(personE);
};
var $elm$core$Elm$JsArray$map = _JsArray_map;
var $elm$core$Array$map = F2(
	function (func, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = function (node) {
			if (!node.$) {
				var subTree = node.a;
				return $elm$core$Array$SubTree(
					A2($elm$core$Elm$JsArray$map, helper, subTree));
			} else {
				var values = node.a;
				return $elm$core$Array$Leaf(
					A2($elm$core$Elm$JsArray$map, func, values));
			}
		};
		return A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A2($elm$core$Elm$JsArray$map, helper, tree),
			A2($elm$core$Elm$JsArray$map, func, tail));
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $Punie$elm_reader$Reader$map = F2(
	function (f, _v0) {
		var g = _v0;
		return A2($elm$core$Basics$composeL, f, g);
	});
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $author$project$Ports$mathJaxTypeset = _Platform_outgoingPort(
	'mathJaxTypeset',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $elm$regex$Regex$never = _Regex_never;
var $elm_community$maybe_extra$Maybe$Extra$or = F2(
	function (ma, mb) {
		if (ma.$ === 1) {
			return mb;
		} else {
			return ma;
		}
	});
var $author$project$Type$DateTime$posixToRoundedSecsInt = function (posix) {
	return $elm$core$Basics$round(
		$author$project$Type$DateTime$posixToDecSecsFloat(posix));
};
var $author$project$Helper$Common$FirstAttempt = 0;
var $author$project$Type$ExamResult$extractTsFinish = function (serr) {
	if (!serr.$) {
		var rec = serr.a;
		return rec.pr;
	} else {
		var rec = serr.a;
		return rec.pr;
	}
};
var $elm$core$Result$fromMaybe = F2(
	function (err, maybe) {
		if (!maybe.$) {
			var v = maybe.a;
			return $elm$core$Result$Ok(v);
		} else {
			return $elm$core$Result$Err(err);
		}
	});
var $author$project$Type$ExamResult$isRunResultIdsEqual = F2(
	function (serr1, serr2) {
		var _v0 = _Utils_Tuple2(serr1, serr2);
		_v0$2:
		while (true) {
			if (!_v0.a.$) {
				if (!_v0.b.$) {
					var rec1 = _v0.a.a;
					var rec2 = _v0.b.a;
					return _Utils_eq(rec1.nF, rec2.nF);
				} else {
					break _v0$2;
				}
			} else {
				if (_v0.b.$ === 1) {
					var rec1 = _v0.a.a;
					var rec2 = _v0.b.a;
					return _Utils_eq(rec1.jz, rec2.jz);
				} else {
					break _v0$2;
				}
			}
		}
		return false;
	});
var $elm$core$Result$map2 = F3(
	function (func, ra, rb) {
		if (ra.$ === 1) {
			var x = ra.a;
			return $elm$core$Result$Err(x);
		} else {
			var a = ra.a;
			if (rb.$ === 1) {
				var x = rb.a;
				return $elm$core$Result$Err(x);
			} else {
				var b = rb.a;
				return $elm$core$Result$Ok(
					A2(func, a, b));
			}
		}
	});
var $author$project$Helper$Common$updateResByAttemptType = F3(
	function (attemptType, serr, serrList) {
		var isNeedsReplaced = F3(
			function (attemptTyp, f, s) {
				var extractFinishTimeFromString = function (el) {
					return A2(
						$elm$core$Result$fromMaybe,
						'Unable to understand the exam finish time for: ' + el,
						$elm$core$String$toFloat(el));
				};
				var comparitor = function () {
					if (!attemptTyp) {
						return $elm$core$Basics$lt;
					} else {
						return $elm$core$Basics$gt;
					}
				}();
				return _Utils_eq(
					A3(
						$elm$core$Result$map2,
						comparitor,
						extractFinishTimeFromString(f),
						extractFinishTimeFromString(s)),
					$elm$core$Result$Ok(true));
			});
		var existingSerr = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (el) {
					return A2($author$project$Type$ExamResult$isRunResultIdsEqual, el, serr);
				},
				serrList));
		if (existingSerr.$ === 1) {
			return A2($elm$core$List$cons, serr, serrList);
		} else {
			var existingR = existingSerr.a;
			var replaceOrAdd = function (tsFinish) {
				if (A3(
					isNeedsReplaced,
					attemptType,
					tsFinish,
					$author$project$Type$ExamResult$extractTsFinish(existingR))) {
					var serrListWithoutExisting = A2(
						$elm$core$List$filter,
						function (el) {
							return !A2($author$project$Type$ExamResult$isRunResultIdsEqual, el, serr);
						},
						serrList);
					return A2($elm$core$List$cons, serr, serrListWithoutExisting);
				} else {
					return serrList;
				}
			};
			if (!serr.$) {
				var rec = serr.a;
				return replaceOrAdd(rec.pr);
			} else {
				var rec = serr.a;
				return replaceOrAdd(rec.pr);
			}
		}
	});
var $author$project$Helper$Common$reduceHistoryToFirstAttempts = function (assignee) {
	var filteredHstry = A3(
		$elm$core$List$foldl,
		$author$project$Helper$Common$updateResByAttemptType(0),
		_List_Nil,
		assignee.lU);
	return _Utils_update(
		assignee,
		{lU: filteredHstry});
};
var $elm$core$Set$remove = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$remove, key, dict);
	});
var $Punie$elm_reader$Reader$run = function (_v0) {
	var f = _v0;
	return f;
};
var $author$project$Type$User$sampleStudentUserSub = 'sampleStudentUserSub';
var $author$project$Ports$sendDeleteHiddenExamGroups = _Platform_outgoingPort('sendDeleteHiddenExamGroups', $elm$json$Json$Encode$string);
var $author$project$Type$Variant$EncodedVariantContent = function (payload) {
	return {c: payload};
};
var $author$project$Type$Variant$encodedVariantContentDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Type$Variant$EncodedVariantContent,
	A2($elm$json$Json$Decode$field, 'payload', $elm$json$Json$Decode$string));
var $author$project$PortImpl$sendGetEncodedVariantContent = F4(
	function (urlPrefixClientResources, resultToMsg, version, variantIdString) {
		return $elm$http$Http$get(
			{
				lp: A2($elm$http$Http$expectJson, resultToMsg, $author$project$Type$Variant$encodedVariantContentDecoder),
				py: urlPrefixClientResources + ('/variant/' + (variantIdString + ('.json?' + version)))
			});
	});
var $author$project$Ports$sendGetHiddenExamGroups = _Platform_outgoingPort('sendGetHiddenExamGroups', $elm$json$Json$Encode$string);
var $author$project$Msgs$OnGetPostMetas = function (a) {
	return {$: 29, a: a};
};
var $author$project$Type$NodeCfg$ActivityPost = function (a) {
	return {$: 2, a: a};
};
var $author$project$Type$NodeCfg$CategoryPost = function (a) {
	return {$: 0, a: a};
};
var $author$project$Type$NodeCfg$ItemPost = function (a) {
	return {$: 1, a: a};
};
var $author$project$Type$NodeCfg$ReportCardPost = function (a) {
	return {$: 3, a: a};
};
var $author$project$Type$NodeCfg$Branch = 1;
var $author$project$Type$NodeCfg$CategoryData = F5(
	function (id, title, nodetype, desc, children) {
		return {eA: children, k8: desc, lW: id, fm: nodetype, ja: title};
	});
var $author$project$Type$NodeCfg$Leaf = 2;
var $author$project$Type$NodeCfg$Root = 0;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $author$project$Type$NodeCfg$categoryDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Type$NodeCfg$CategoryData,
	A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'title', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$andThen,
		function (ntype) {
			switch (ntype) {
				case 'root':
					return $elm$json$Json$Decode$succeed(0);
				case 'branch':
					return $elm$json$Json$Decode$succeed(1);
				case 'leaf':
					return $elm$json$Json$Decode$succeed(2);
				default:
					return $elm$json$Json$Decode$succeed(2);
			}
		},
		A2($elm$json$Json$Decode$field, 'nodetype', $elm$json$Json$Decode$string)),
	A2($elm$json$Json$Decode$field, 'desc', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'children',
		$elm$json$Json$Decode$nullable(
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string))));
var $author$project$Type$NodeCfg$ItemData = F6(
	function (title, smuid, questiontype, slug, answersets, tags) {
		return {jZ: answersets, iy: questiontype, iU: slug, oq: smuid, i7: tags, ja: title};
	});
var $author$project$Type$NodeCfg$AnswerSet = F3(
	function (difficulty, categories, context) {
		return {kr: categories, kL: context, dp: difficulty};
	});
var $author$project$Type$NodeCfg$answerSetDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Type$NodeCfg$AnswerSet,
	A2($elm$json$Json$Decode$field, 'difficulty', $elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$field,
		'categories',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string)),
	A2(
		$elm$json$Json$Decode$andThen,
		function (nType) {
			return $author$project$Type$Activity$contextDecoder(nType);
		},
		A2($elm$json$Json$Decode$field, 'context', $elm$json$Json$Decode$string)));
var $author$project$Type$NodeCfg$itemDecoder = A7(
	$elm$json$Json$Decode$map6,
	$author$project$Type$NodeCfg$ItemData,
	A2($elm$json$Json$Decode$field, 'title', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'smuid', $elm$json$Json$Decode$string),
	$author$project$Type$Variant$questionTypeDecoder,
	A2($elm$json$Json$Decode$field, 'slug', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'answersets',
		$elm$json$Json$Decode$list($author$project$Type$NodeCfg$answerSetDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'tags',
		A2(
			$elm$json$Json$Decode$map,
			function (elMaybe) {
				return A2($elm$core$Maybe$withDefault, _List_Nil, elMaybe);
			},
			$elm$json$Json$Decode$nullable(
				$elm$json$Json$Decode$list($elm$json$Json$Decode$string)))));
var $author$project$Type$ReportCard$ReportCardData = F3(
	function (syllabusId, title, lineItems) {
		return {mv: lineItems, bf: syllabusId, ja: title};
	});
var $author$project$Type$ReportCard$ReportCardLineItem = F5(
	function (sortField, lineItemDescBlockA, lineItemDescBlockB, lineItemActivityIds, pcInOverall) {
		return {mt: lineItemActivityIds, hz: lineItemDescBlockA, mu: lineItemDescBlockB, ns: pcInOverall, or: sortField};
	});
var $author$project$Type$ReportCard$reportCardDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Type$ReportCard$ReportCardData,
	A2($elm$json$Json$Decode$field, 'syllabus', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'title', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'lineitems',
		$elm$json$Json$Decode$list(
			A6(
				$elm$json$Json$Decode$map5,
				$author$project$Type$ReportCard$ReportCardLineItem,
				A2($elm$json$Json$Decode$field, 'sortfield', $elm$json$Json$Decode$int),
				A2($elm$json$Json$Decode$field, 'lineitemdesca', $elm$json$Json$Decode$string),
				A2($elm$json$Json$Decode$field, 'lineitemdescb', $elm$json$Json$Decode$string),
				A2(
					$elm$json$Json$Decode$field,
					'lineitemactivities',
					$elm$json$Json$Decode$list($elm$json$Json$Decode$string)),
				A2($elm$json$Json$Decode$field, 'pcinoverall', $elm$json$Json$Decode$int)))));
var $author$project$Type$NodeCfg$postMetaDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (key) {
		switch (key) {
			case 'category-post':
				return A2($elm$json$Json$Decode$map, $author$project$Type$NodeCfg$CategoryPost, $author$project$Type$NodeCfg$categoryDecoder);
			case 'item-post':
				return A2($elm$json$Json$Decode$map, $author$project$Type$NodeCfg$ItemPost, $author$project$Type$NodeCfg$itemDecoder);
			case 'activity-post':
				return A2($elm$json$Json$Decode$map, $author$project$Type$NodeCfg$ActivityPost, $author$project$Type$Activity$activityDecoder);
			case 'reportcard-post':
				return A2($elm$json$Json$Decode$map, $author$project$Type$NodeCfg$ReportCardPost, $author$project$Type$ReportCard$reportCardDecoder);
			default:
				return $elm$json$Json$Decode$fail('The template key [' + (key + '] cannot be decoded.'));
		}
	},
	A2($elm$json$Json$Decode$field, 'templateKey', $elm$json$Json$Decode$string));
var $author$project$Type$NodeCfg$postMetasDecoder = A2(
	$elm$json$Json$Decode$field,
	'items',
	$elm$json$Json$Decode$list($author$project$Type$NodeCfg$postMetaDecoder));
var $author$project$Update$sendGetPostMetas = F2(
	function (urlPrefixClientResources, version) {
		return $elm$http$Http$get(
			{
				lp: A2(
					$elm$http$Http$expectJson,
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Msgs$OnGetPostMetas),
					$author$project$Type$NodeCfg$postMetasDecoder),
				py: urlPrefixClientResources + ('/feed-1.json' + ('?' + version))
			});
	});
var $author$project$Ports$sendGetSchoolName = _Platform_outgoingPort(
	'sendGetSchoolName',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Ports$sendGoogleAnalytics = _Platform_outgoingPort(
	'sendGoogleAnalytics',
	$elm$json$Json$Encode$list($elm$json$Json$Encode$string));
var $author$project$Ports$sendInitializeGoogleAnalytics = _Platform_outgoingPort('sendInitializeGoogleAnalytics', $elm$core$Basics$identity);
var $author$project$Ports$sendPutHiddenExamGroups = _Platform_outgoingPort(
	'sendPutHiddenExamGroups',
	function ($) {
		var a = $.a;
		var b = $.b;
		return A2(
			$elm$json$Json$Encode$list,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$json$Json$Encode$string(a),
					$elm$core$Basics$identity(b)
				]));
	});
var $author$project$Ports$sendRefreshCredentials = _Platform_outgoingPort(
	'sendRefreshCredentials',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Ports$sendRetrieveCredentials = _Platform_outgoingPort(
	'sendRetrieveCredentials',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Ports$setBelowElmDisplayNone = _Platform_outgoingPort('setBelowElmDisplayNone', $elm$json$Json$Encode$bool);
var $author$project$Update$setTeachersNotAsked = function (mdl) {
	return function (el) {
		return _Utils_update(
			mdl,
			{h5: el});
	}(
		function (el) {
			return _Utils_update(
				el,
				{aa: $krisajenkins$remotedata$RemoteData$NotAsked});
		}(mdl.h5));
};
var $author$project$Ports$showChartByBand = _Platform_outgoingPort('showChartByBand', $elm$core$Basics$identity);
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $krisajenkins$remotedata$RemoteData$succeed = $krisajenkins$remotedata$RemoteData$Success;
var $author$project$JsInterop$Types$toAssigneeMaybe = function (personResponse) {
	if (!personResponse.$) {
		var assignee = personResponse.a;
		return $elm$core$Maybe$Just(assignee);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Type$Cohort$toCurrentSyllabusTitle = function (cohort) {
	if (!cohort.$) {
		switch (cohort.a.$) {
			case 0:
				if (!cohort.a.a) {
					var _v1 = cohort.a.a;
					return 'nap3';
				} else {
					var _v2 = cohort.a.a;
					return 'aus4';
				}
			case 1:
				if (!cohort.a.a) {
					var _v3 = cohort.a.a;
					return 'nap5';
				} else {
					var _v4 = cohort.a.a;
					return 'aus6';
				}
			case 2:
				if (!cohort.a.a) {
					var _v5 = cohort.a.a;
					return 'nap7';
				} else {
					var _v6 = cohort.a.a;
					return 'aus8';
				}
			default:
				if (!cohort.a.a) {
					var _v7 = cohort.a.a;
					return 'nap9';
				} else {
					var _v8 = cohort.a.a;
					return 'aus10';
				}
		}
	} else {
		return 'minstd';
	}
};
var $elm$core$String$trim = _String_trim;
var $author$project$Page$ManageUsers$isPassesEmailsFilter = F2(
	function (emailsToFilter, filterable) {
		if (emailsToFilter === '') {
			return true;
		} else {
			var toEmails = A2(
				$elm$core$Basics$composeL,
				$elm$core$List$map($elm$core$String$trim),
				$elm$core$String$split('\n'));
			return A2(
				$elm$core$List$any,
				$elm$core$Basics$eq(filterable.li),
				toEmails(emailsToFilter));
		}
	});
var $author$project$Page$ManageUsers$isPassesGroupFilter = F2(
	function (groupFilter, filterable) {
		return (groupFilter === '-') ? _Utils_eq(filterable.lI, $elm$core$Maybe$Nothing) : ((groupFilter === '') || A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				$elm$core$String$contains(groupFilter),
				A2($elm$core$Maybe$map, $elm$core$String$toLower, filterable.lI))));
	});
var $author$project$Page$ManageUsers$isPassesYearLvlFilter = F2(
	function (yearLvlFilter, filterable) {
		return _Utils_eq(yearLvlFilter, $elm$core$Maybe$Nothing) || _Utils_eq(
			$elm$core$Maybe$Just(filterable.kA),
			yearLvlFilter);
	});
var $author$project$Page$ManageUsers$isPassesAllFilters = F2(
	function (filterModel, filterable) {
		return A2($author$project$Page$ManageUsers$isPassesYearLvlFilter, filterModel.bX, filterable) && (A2($author$project$Page$ManageUsers$isPassesGroupFilter, filterModel.b6, filterable) && A2($author$project$Page$ManageUsers$isPassesEmailsFilter, filterModel.cK, filterable));
	});
var $author$project$Page$ManageUsers$applyFilters = F2(
	function (filterModel, entries_) {
		return A2(
			$elm$core$List$filter,
			$author$project$Page$ManageUsers$isPassesAllFilters(filterModel),
			entries_);
	});
var $author$project$Type$User$transformStudentETypeToAssignee = function (p) {
	var assigneeBase = {
		li: p.li,
		lt: p.lt,
		eV: p.lI,
		mq: p.mq,
		hB: $elm$core$Maybe$Just(p.kA),
		pt: $author$project$Type$User$Student,
		pH: p.lW
	};
	return {dc: assigneeBase, lU: p.lU, n2: p.d$, n4: p.n4};
};
var $author$project$Page$ManageUsers$toFilteredAssignees = F2(
	function (filterModel, users) {
		return A2(
			$elm$core$List$map,
			$author$project$Type$User$transformStudentETypeToAssignee,
			A2(
				$author$project$Page$ManageUsers$applyFilters,
				filterModel,
				A2(
					$elm$core$List$filterMap,
					A2($elm$core$Basics$composeR, $author$project$Page$ManageUsers$extractPersonEFromUser, $author$project$Type$User$extractStudentEFromPersonE),
					users)));
	});
var $author$project$Type$Variant$toShortStringVariantId = function (variantId) {
	return variantId.oq + ('v' + $elm$core$String$fromInt(variantId.c1));
};
var $author$project$Type$DateTime$EndOfDay = 1;
var $author$project$Page$AdaptiveAnalytics$NowPosix = function (a) {
	return {$: 10, a: a};
};
var $author$project$Type$DateTime$StartOfDay = 0;
var $author$project$Type$User$findPersonEDetails = F2(
	function (persons, id) {
		return $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (el) {
					return _Utils_eq(el.lW, id);
				},
				persons));
	});
var $author$project$Page$AdaptiveAnalytics$getTeacherName = F3(
	function (teachersDetails, me, sId) {
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (el) {
					return el.lt + (' ' + el.mq);
				},
				$author$project$Type$User$isTeacherEAdminE(me) ? A2($author$project$Type$User$findPersonEDetails, teachersDetails, sId) : $elm$core$Maybe$Just(
					$author$project$Type$User$extractPersonEDetails(me))));
	});
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.fO, posixMinutes) < 0) {
					return posixMinutes + era.b;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		gN: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		hL: month,
		jw: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).gN;
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).hL;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $author$project$Type$DateTime$toMonthTuple = function (month) {
	switch (month) {
		case 0:
			return _Utils_Tuple2('Jan', '01');
		case 1:
			return _Utils_Tuple2('Feb', '02');
		case 2:
			return _Utils_Tuple2('Mar', '03');
		case 3:
			return _Utils_Tuple2('Apr', '04');
		case 4:
			return _Utils_Tuple2('May', '05');
		case 5:
			return _Utils_Tuple2('Jun', '06');
		case 6:
			return _Utils_Tuple2('Jul', '07');
		case 7:
			return _Utils_Tuple2('Aug', '08');
		case 8:
			return _Utils_Tuple2('Sep', '09');
		case 9:
			return _Utils_Tuple2('Oct', '10');
		case 10:
			return _Utils_Tuple2('Nov', '11');
		default:
			return _Utils_Tuple2('Dec', '12');
	}
};
var $author$project$Type$DateTime$toMonthShortName = function (month) {
	return $author$project$Type$DateTime$toMonthTuple(month).a;
};
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).jw;
	});
var $author$project$Type$DateTime$tz = A2($elm$time$Time$customZone, 10 * 60, _List_Nil);
var $author$project$Type$DateTime$posixToDateOnlyString = function (el) {
	return $elm$core$String$fromInt(
		A2($elm$time$Time$toDay, $author$project$Type$DateTime$tz, el)) + (' ' + ($author$project$Type$DateTime$toMonthShortName(
		A2($elm$time$Time$toMonth, $author$project$Type$DateTime$tz, el)) + (' ' + $elm$core$String$fromInt(
		A2($elm$time$Time$toYear, $author$project$Type$DateTime$tz, el)))));
};
var $elm$file$File$Download$string = F3(
	function (name, mime, content) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$never,
			A3(_File_download, name, mime, content));
	});
var $author$project$Page$AdaptiveAnalytics$downloadCSV = F5(
	function (teachersDetails, me, titleSuffix, aggregateResults, nowPosix) {
		var csvContent = 'Email,LastName,FirstName,TeacherName,Cohort,Group,ResultFirstPart,ResultSecondPart\n' + A2(
			$elm$core$String$join,
			'\n',
			A2(
				$elm$core$List$map,
				function (_v0) {
					var _v1 = _v0.a;
					var key1 = _v1.a;
					var key2 = _v1.b;
					var students = _v0.b;
					return A2(
						$elm$core$String$join,
						'\n',
						A2(
							$elm$core$List$map,
							function (student) {
								return A2(
									$elm$core$String$join,
									',',
									_List_fromArray(
										[
											student.li,
											student.mq,
											student.lt,
											A3($author$project$Page$AdaptiveAnalytics$getTeacherName, teachersDetails, me, student.d$),
											$author$project$Type$Cohort$toGraphQLString(student.kA),
											A2($elm$core$Maybe$withDefault, '', student.lI),
											key1,
											(key2 === '') ? '-' : key2
										]));
							},
							students));
				},
				$elm$core$Dict$toList(aggregateResults)));
		return A3(
			$elm$file$File$Download$string,
			'SmarterMaths_Results_' + (titleSuffix + ('_' + ($author$project$Type$DateTime$posixToDateOnlyString(nowPosix) + '.csv'))),
			'text/csv',
			csvContent);
	});
var $author$project$Type$DateTime$toMonthTwoDigitString = function (month) {
	return $author$project$Type$DateTime$toMonthTuple(month).b;
};
var $author$project$Type$DateTime$posixToYYYYMMDD = function (el) {
	var twoDigitDayString = function (el_) {
		return function (val) {
			return ($elm$core$String$length(val) === 1) ? ('0' + val) : val;
		}(
			$elm$core$String$fromInt(
				A2($elm$time$Time$toDay, $author$project$Type$DateTime$tz, el_)));
	};
	return $elm$core$String$fromInt(
		A2($elm$time$Time$toYear, $author$project$Type$DateTime$tz, el)) + ('-' + ($author$project$Type$DateTime$toMonthTwoDigitString(
		A2($elm$time$Time$toMonth, $author$project$Type$DateTime$tz, el)) + ('-' + twoDigitDayString(el))));
};
var $author$project$Type$DateTime$posixToYYYYMMDDHHMMSS = F2(
	function (roundingPoint, el) {
		var extensionStr = 'T' + (function () {
			if (!roundingPoint) {
				return '00:00:00';
			} else {
				return '23:59:59';
			}
		}() + '+10:00');
		return _Utils_ap(
			$author$project$Type$DateTime$posixToYYYYMMDD(el),
			extensionStr);
	});
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.ov),
			s.b) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	$elm$parser$Parser$andThen,
	function (str) {
		if ($elm$core$String$length(str) <= 9) {
			var _v0 = $elm$core$String$toFloat('0.' + str);
			if (!_v0.$) {
				var floatVal = _v0.a;
				return $elm$parser$Parser$succeed(
					$elm$core$Basics$round(floatVal * 1000));
			} else {
				return $elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return $elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + $elm$core$String$fromInt(
					$elm$core$String$length(str)));
		}
	},
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return $elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var $elm$core$String$append = _String_append;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	var helper = function (str) {
		if (_Utils_eq(
			$elm$core$String$length(str),
			quantity)) {
			var _v0 = $elm$core$String$toInt(str);
			if (!_v0.$) {
				var intVal = _v0.a;
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$Done,
					$elm$parser$Parser$succeed(intVal));
			} else {
				return $elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
			}
		} else {
			return A2(
				$elm$parser$Parser$map,
				function (nextChar) {
					return $elm$parser$Parser$Loop(
						A2($elm$core$String$append, str, nextChar));
				},
				$elm$parser$Parser$getChompedString(
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
		}
	};
	return A2($elm$parser$Parser$loop, '', helper);
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return $elm$parser$Parser$problem(
		'Invalid day: ' + $elm$core$String$fromInt(day));
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 4, year)) && ((!(!A2($elm$core$Basics$modBy, 100, year))) || (!A2($elm$core$Basics$modBy, 400, year)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var dayInMonth = _v0.c;
	if (dayInMonth < 0) {
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + ($rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore($rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return $elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return $elm$parser$Parser$problem(
					'Invalid month: \"' + ($elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	$elm$parser$Parser$andThen,
	$rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed($elm$core$Basics$identity),
							$elm$parser$Parser$symbol('-')),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
					]))),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed($elm$core$Basics$identity),
						$elm$parser$Parser$symbol('-')),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
				]))));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes = function () {
	var utcOffsetMinutesFromParts = F3(
		function (multiplier, hours, minutes) {
			return (multiplier * (hours * 60)) + minutes;
		});
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$map,
					function (_v0) {
						return 0;
					},
					$elm$parser$Parser$symbol('Z')),
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed(utcOffsetMinutesFromParts),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$map,
										function (_v1) {
											return 1;
										},
										$elm$parser$Parser$symbol('+')),
										A2(
										$elm$parser$Parser$map,
										function (_v2) {
											return -1;
										},
										$elm$parser$Parser$symbol('-'))
									]))),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed($elm$core$Basics$identity),
									$elm$parser$Parser$symbol(':')),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
								$elm$parser$Parser$succeed(0)
							]))),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(0),
					$elm$parser$Parser$end)
				])));
}();
var $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	$elm$parser$Parser$andThen,
	function (datePart) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed(
											$rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										$elm$parser$Parser$symbol('T')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$elm$parser$Parser$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$keeper,
											A2(
												$elm$parser$Parser$ignorer,
												$elm$parser$Parser$succeed($elm$core$Basics$identity),
												$elm$parser$Parser$symbol(':')),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
										]))),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$keeper,
										A2(
											$elm$parser$Parser$ignorer,
											$elm$parser$Parser$succeed($elm$core$Basics$identity),
											$elm$parser$Parser$symbol(':')),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
									]))),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$symbol('.')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									$elm$parser$Parser$succeed(0)
								]))),
					A2($elm$parser$Parser$ignorer, $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes, $elm$parser$Parser$end)),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A6($rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					$elm$parser$Parser$end)
				]));
	},
	$rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2($elm$parser$Parser$run, $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var $author$project$Type$DateTime$toPosixRounded = F2(
	function (roundingPoint, posix) {
		return A2(
			$elm$core$Result$withDefault,
			posix,
			$rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(
				A2($author$project$Type$DateTime$posixToYYYYMMDDHHMMSS, roundingPoint, posix)));
	});
var $author$project$Type$AnalyticsResult$MostRecent = 1;
var $author$project$Type$ResultBin$BandBin = function (a) {
	return {$: 1, a: a};
};
var $author$project$Type$ResultBin$MinStdBin = {$: 2};
var $author$project$Type$ResultBin$ReportCardBin = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$AdaptiveAnalytics$getResultBinType = F2(
	function (resultBinDisplayChoiceMaybe, cohort) {
		if (resultBinDisplayChoiceMaybe.$ === 1) {
			return $author$project$Type$ResultBin$MinStdBin;
		} else {
			if (!resultBinDisplayChoiceMaybe.a) {
				var _v1 = resultBinDisplayChoiceMaybe.a;
				return $author$project$Type$ResultBin$BandBin(cohort);
			} else {
				var _v2 = resultBinDisplayChoiceMaybe.a;
				return $author$project$Type$ResultBin$ReportCardBin(cohort);
			}
		}
	});
var $author$project$Type$AnalyticsRecord$filterByActivityId = F2(
	function (aId, analyticsRecords) {
		return A2(
			$elm$core$List$map,
			function (ar) {
				return _Utils_update(
					ar,
					{
						aP: A2(
							$elm$core$List$filter,
							function (el) {
								return _Utils_eq(el.jz, aId);
							},
							ar.aP)
					});
			},
			analyticsRecords);
	});
var $author$project$Type$AnalyticsRecord$filterByCohortByActivityId = F2(
	function (cohort, activityId) {
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Type$AnalyticsRecord$filterByCohort(cohort),
			$author$project$Type$AnalyticsRecord$filterByActivityId(activityId));
	});
var $author$project$Type$ResultBin$bandValueToString = function (bandValue) {
	switch (bandValue) {
		case 0:
			return '2';
		case 1:
			return '3';
		case 2:
			return '4';
		case 3:
			return '5';
		case 4:
			return '6';
		case 5:
			return '7';
		case 6:
			return '8';
		case 7:
			return '9';
		default:
			return '10';
	}
};
var $author$project$Type$ResultBin$msBandValueToString = function (msBandValue) {
	switch (msBandValue) {
		case 0:
			return 'e2l';
		case 1:
			return 'e2h';
		case 2:
			return 'e3l';
		case 3:
			return 'e3h';
		default:
			return 'e4';
	}
};
var $author$project$Type$ReportCard$toString = function (reportCardValue) {
	switch (reportCardValue) {
		case 0:
			return 'A';
		case 1:
			return 'B';
		case 2:
			return 'C';
		case 3:
			return 'D';
		default:
			return 'E';
	}
};
var $author$project$Type$ResultBin$resultBinToAggregateRecordKey = function (resultBin) {
	switch (resultBin.$) {
		case 2:
			var reportCardValue = resultBin.a;
			return _Utils_Tuple2(
				$author$project$Type$ReportCard$toString(reportCardValue),
				'');
		case 0:
			var band = resultBin.a;
			var subBand = resultBin.b;
			var letter = function () {
				switch (subBand) {
					case 0:
						return 'a';
					case 1:
						return 'b';
					default:
						return 'c';
				}
			}();
			return _Utils_Tuple2(
				$author$project$Type$ResultBin$bandValueToString(band),
				letter);
		default:
			var band = resultBin.a;
			var subBand = resultBin.b;
			var letter = function () {
				switch (subBand) {
					case 0:
						return 'a';
					case 1:
						return 'b';
					case 2:
						return 'c';
					default:
						return 'd';
				}
			}();
			return _Utils_Tuple2(
				$author$project$Type$ResultBin$msBandValueToString(band),
				letter);
	}
};
var $author$project$Type$ResultBin$B10 = 8;
var $author$project$Type$ResultBin$B2 = 0;
var $author$project$Type$ResultBin$B3 = 1;
var $author$project$Type$ResultBin$B4 = 2;
var $author$project$Type$ResultBin$B5 = 3;
var $author$project$Type$ResultBin$B6 = 4;
var $author$project$Type$ResultBin$B7 = 5;
var $author$project$Type$ResultBin$B8 = 6;
var $author$project$Type$ResultBin$B9 = 7;
var $author$project$Type$ResultBin$Band = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Type$ResultBin$E2H = 1;
var $author$project$Type$ResultBin$E2L = 0;
var $author$project$Type$ResultBin$E3H = 3;
var $author$project$Type$ResultBin$E3L = 2;
var $author$project$Type$ResultBin$E4 = 4;
var $author$project$Type$ResultBin$MSBand = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Type$ResultBin$MSSubA = 0;
var $author$project$Type$ResultBin$MSSubB = 1;
var $author$project$Type$ResultBin$MSSubC = 2;
var $author$project$Type$ResultBin$MSSubD = 3;
var $author$project$Type$ResultBin$ReportCard = function (a) {
	return {$: 2, a: a};
};
var $author$project$Type$ResultBin$SubA = 0;
var $author$project$Type$ResultBin$SubB = 1;
var $author$project$Type$ResultBin$SubC = 2;
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $author$project$Type$ReportCard$A = 0;
var $author$project$Type$ReportCard$B = 1;
var $author$project$Type$ReportCard$C = 2;
var $author$project$Type$ReportCard$D = 3;
var $author$project$Type$ReportCard$E = 4;
var $author$project$Type$ReportCard$toReportCardValue = F2(
	function (cohort, diff) {
		var calcReportCardValue = F4(
			function (a, b, c, d) {
				return (_Utils_cmp(diff, a) > -1) ? 0 : ((_Utils_cmp(diff, b) > -1) ? 1 : ((_Utils_cmp(diff, c) > -1) ? 2 : ((_Utils_cmp(diff, d) > -1) ? 3 : 4)));
			});
		if (!cohort.$) {
			switch (cohort.a.$) {
				case 0:
					if (!cohort.a.a) {
						var _v1 = cohort.a.a;
						return A4(calcReportCardValue, 516, 461, 355, 322);
					} else {
						var _v2 = cohort.a.a;
						return A4(calcReportCardValue, 568, 516, 401, 348);
					}
				case 1:
					if (!cohort.a.a) {
						var _v3 = cohort.a.a;
						return A4(calcReportCardValue, 620, 571, 447, 374);
					} else {
						var _v4 = cohort.a.a;
						return A4(calcReportCardValue, 633, 586, 461, 401);
					}
				case 2:
					if (!cohort.a.a) {
						var _v5 = cohort.a.a;
						return A4(calcReportCardValue, 645, 601, 489, 429);
					} else {
						var _v6 = cohort.a.a;
						return A4(calcReportCardValue, 663, 616, 506, 453);
					}
				default:
					if (!cohort.a.a) {
						var _v7 = cohort.a.a;
						return A4(calcReportCardValue, 681, 631, 536, 478);
					} else {
						var _v8 = cohort.a.a;
						return A4(calcReportCardValue, 696, 650, 561, 494);
					}
			}
		} else {
			return 0;
		}
	});
var $author$project$Type$ResultBin$toResultBinValue = F2(
	function (resultBinType, diff) {
		switch (resultBinType.$) {
			case 0:
				var cohort = resultBinType.a;
				return $author$project$Type$ResultBin$ReportCard(
					A2($author$project$Type$ReportCard$toReportCardValue, cohort, diff));
			case 1:
				if (!resultBinType.a.$) {
					var stage = resultBinType.a.a;
					var diffClamped = function () {
						switch (stage.$) {
							case 0:
								return A3($elm$core$Basics$clamp, 0, 580, diff);
							case 1:
								return A3($elm$core$Basics$clamp, 322, 636, diff);
							case 2:
								return A3($elm$core$Basics$clamp, 374, 685, diff);
							default:
								return A3($elm$core$Basics$clamp, 428, 999, diff);
						}
					}();
					return (diffClamped <= 287) ? A2($author$project$Type$ResultBin$Band, 0, 0) : ((diffClamped <= 304) ? A2($author$project$Type$ResultBin$Band, 0, 1) : ((diffClamped <= 321) ? A2($author$project$Type$ResultBin$Band, 0, 2) : ((diffClamped <= 337) ? A2($author$project$Type$ResultBin$Band, 1, 0) : ((diffClamped <= 352) ? A2($author$project$Type$ResultBin$Band, 1, 1) : ((diffClamped <= 373) ? A2($author$project$Type$ResultBin$Band, 1, 2) : ((diffClamped <= 393) ? A2($author$project$Type$ResultBin$Band, 2, 0) : ((diffClamped <= 410) ? A2($author$project$Type$ResultBin$Band, 2, 1) : ((diffClamped <= 427) ? A2($author$project$Type$ResultBin$Band, 2, 2) : ((diffClamped <= 445) ? A2($author$project$Type$ResultBin$Band, 3, 0) : ((diffClamped <= 461) ? A2($author$project$Type$ResultBin$Band, 3, 1) : ((diffClamped <= 477) ? A2($author$project$Type$ResultBin$Band, 3, 2) : ((diffClamped <= 497) ? A2($author$project$Type$ResultBin$Band, 4, 0) : ((diffClamped <= 515) ? A2($author$project$Type$ResultBin$Band, 4, 1) : ((diffClamped <= 533) ? A2($author$project$Type$ResultBin$Band, 4, 2) : ((diffClamped <= 550) ? A2($author$project$Type$ResultBin$Band, 5, 0) : ((diffClamped <= 565) ? A2($author$project$Type$ResultBin$Band, 5, 1) : ((diffClamped <= 580) ? A2($author$project$Type$ResultBin$Band, 5, 2) : ((diffClamped <= 599) ? A2($author$project$Type$ResultBin$Band, 6, 0) : ((diffClamped <= 618) ? A2($author$project$Type$ResultBin$Band, 6, 1) : ((diffClamped <= 636) ? A2($author$project$Type$ResultBin$Band, 6, 2) : ((diffClamped <= 652) ? A2($author$project$Type$ResultBin$Band, 7, 0) : ((diffClamped <= 668) ? A2($author$project$Type$ResultBin$Band, 7, 1) : ((diffClamped <= 685) ? A2($author$project$Type$ResultBin$Band, 7, 2) : ((diffClamped <= 710) ? A2($author$project$Type$ResultBin$Band, 8, 0) : ((diffClamped <= 730) ? A2($author$project$Type$ResultBin$Band, 8, 1) : A2($author$project$Type$ResultBin$Band, 8, 2))))))))))))))))))))))))));
				} else {
					var _v2 = resultBinType.a;
					return A2($author$project$Type$ResultBin$MSBand, 0, 0);
				}
			default:
				return (diff <= 452) ? A2($author$project$Type$ResultBin$MSBand, 0, 0) : ((diff <= 478) ? A2($author$project$Type$ResultBin$MSBand, 0, 1) : ((diff <= 495) ? A2($author$project$Type$ResultBin$MSBand, 0, 2) : ((diff <= 515) ? A2($author$project$Type$ResultBin$MSBand, 1, 0) : ((diff <= 534) ? A2($author$project$Type$ResultBin$MSBand, 1, 1) : ((diff <= 554) ? A2($author$project$Type$ResultBin$MSBand, 1, 2) : ((diff <= 563) ? A2($author$project$Type$ResultBin$MSBand, 2, 0) : ((diff <= 572) ? A2($author$project$Type$ResultBin$MSBand, 2, 1) : ((diff <= 580) ? A2($author$project$Type$ResultBin$MSBand, 2, 2) : ((diff <= 588) ? A2($author$project$Type$ResultBin$MSBand, 3, 0) : ((diff <= 597) ? A2($author$project$Type$ResultBin$MSBand, 3, 1) : ((diff <= 605) ? A2($author$project$Type$ResultBin$MSBand, 3, 2) : ((diff <= 633) ? A2($author$project$Type$ResultBin$MSBand, 4, 0) : ((diff <= 660) ? A2($author$project$Type$ResultBin$MSBand, 4, 1) : ((diff <= 685) ? A2($author$project$Type$ResultBin$MSBand, 4, 2) : A2($author$project$Type$ResultBin$MSBand, 4, 3)))))))))))))));
		}
	});
var $author$project$Type$ResultBin$toAggregateRecordKey = function (resultBinType) {
	return A2(
		$elm$core$Basics$composeL,
		$author$project$Type$ResultBin$resultBinToAggregateRecordKey,
		$author$project$Type$ResultBin$toResultBinValue(resultBinType));
};
var $author$project$Type$ResultBin$makeAggregateRecordKey = F2(
	function (resultBinType, diff) {
		return A2($author$project$Type$ResultBin$toAggregateRecordKey, resultBinType, diff);
	});
var $author$project$Type$AnalyticsRecord$toStudentIdentifyingDetails = function (_v0) {
	var id = _v0.lW;
	var sId = _v0.d$;
	var fNam = _v0.lt;
	var lNam = _v0.mq;
	var email = _v0.li;
	var cohort = _v0.kA;
	var group = _v0.lI;
	return {kA: cohort, li: email, lt: fNam, lI: group, lW: id, mq: lNam, d$: sId};
};
var $author$project$Type$AnalyticsRecord$toAggregateResultKV = F3(
	function (resultMoment, resultBinType, analyticsRecord) {
		var sortedResults = A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.pr;
			},
			analyticsRecord.aP);
		var analyticsResultMaybe = function () {
			if (!resultMoment) {
				return $elm$core$List$head(sortedResults);
			} else {
				return A2(
					$elm$core$Maybe$andThen,
					$elm$core$List$head,
					A2(
						$elm$core$Maybe$map,
						$elm$core$List$reverse,
						$elm$core$List$tail(sortedResults)));
			}
		}();
		return _Utils_Tuple2(
			A2(
				$elm$core$Maybe$withDefault,
				_Utils_Tuple2('N/A', ''),
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeL,
						$author$project$Type$ResultBin$makeAggregateRecordKey(resultBinType),
						function ($) {
							return $.eJ;
						}),
					analyticsResultMaybe)),
			$author$project$Type$AnalyticsRecord$toStudentIdentifyingDetails(analyticsRecord));
	});
var $author$project$Type$ResultBin$toCohort = function (resultBinType) {
	switch (resultBinType.$) {
		case 0:
			var cohort = resultBinType.a;
			return cohort;
		case 1:
			var cohort = resultBinType.a;
			return cohort;
		default:
			return $author$project$Type$Cohort$MS;
	}
};
var $author$project$Type$AnalyticsRecord$toAggregateResults = F4(
	function (activityId, resultMoment, resultBinType, analyticsRecords) {
		var addToAggregateResults = F2(
			function (analyticsRecord, aggregateResults) {
				var _v0 = A3($author$project$Type$AnalyticsRecord$toAggregateResultKV, resultMoment, resultBinType, analyticsRecord);
				var resultKey = _v0.a;
				var student = _v0.b;
				var addStudent = function (studentsMaybe) {
					return A2(
						$elm$core$Maybe$map,
						function (students) {
							return A2($elm$core$List$cons, student, students);
						},
						A2(
							$elm_community$maybe_extra$Maybe$Extra$or,
							studentsMaybe,
							$elm$core$Maybe$Just(_List_Nil)));
				};
				return A3($elm$core$Dict$update, resultKey, addStudent, aggregateResults);
			});
		return A3(
			$elm$core$List$foldl,
			addToAggregateResults,
			$elm$core$Dict$empty,
			A3(
				$author$project$Type$AnalyticsRecord$filterByCohortByActivityId,
				$author$project$Type$ResultBin$toCohort(resultBinType),
				activityId,
				analyticsRecords));
	});
var $author$project$Page$AdaptiveAnalytics$updateAggregateResults = function (model) {
	var aggregateResultsTupleMaybe = A3(
		$elm$core$Maybe$map2,
		F2(
			function (cohort, activity) {
				var resultBinType = A2($author$project$Page$AdaptiveAnalytics$getResultBinType, model.aV, cohort);
				return _Utils_Tuple2(
					A4($author$project$Type$AnalyticsRecord$toAggregateResults, activity.lW, 0, resultBinType, model.a_),
					A4($author$project$Type$AnalyticsRecord$toAggregateResults, activity.lW, 1, resultBinType, model.a_));
			}),
		model.bX,
		model.aE);
	return _Utils_update(
		model,
		{bl: aggregateResultsTupleMaybe});
};
var $author$project$Page$AdaptiveAnalytics$update = F2(
	function (msg, model) {
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				switch (msg.$) {
					case 13:
						var studentId = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									cG: $elm$core$Maybe$Just(studentId)
								}),
							$elm$core$Platform$Cmd$none);
					case 12:
						var dateRole = msg.a;
						var posix = msg.b;
						var _v1 = model.bq;
						var from_ = _v1.a;
						var to_ = _v1.b;
						var _v2 = function () {
							if (dateRole === 1) {
								return (_Utils_cmp(
									$elm$time$Time$posixToMillis(posix),
									$elm$time$Time$posixToMillis(to_)) > 0) ? _Utils_Tuple2(
									A2($author$project$Type$DateTime$toPosixRounded, 0, posix),
									A2($author$project$Type$DateTime$toPosixRounded, 1, posix)) : _Utils_Tuple2(
									A2($author$project$Type$DateTime$toPosixRounded, 0, posix),
									to_);
							} else {
								return (_Utils_cmp(
									$elm$time$Time$posixToMillis(posix),
									$elm$time$Time$posixToMillis(from_)) < 0) ? _Utils_Tuple2(
									A2($author$project$Type$DateTime$toPosixRounded, 0, posix),
									A2($author$project$Type$DateTime$toPosixRounded, 1, posix)) : _Utils_Tuple2(
									from_,
									A2($author$project$Type$DateTime$toPosixRounded, 1, posix));
							}
						}();
						var from = _v2.a;
						var to = _v2.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									bq: _Utils_Tuple2(from, to)
								}),
							$elm$core$Platform$Cmd$none);
					case 4:
						var resultsSelection = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{bM: resultsSelection}),
							$elm$core$Platform$Cmd$none);
					case 5:
						var yFormat = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{cw: yFormat}),
							$elm$core$Platform$Cmd$none);
					case 6:
						var resultBinDisplayChoiceMaybe = msg.a;
						return _Utils_Tuple2(
							$author$project$Page$AdaptiveAnalytics$updateAggregateResults(
								_Utils_update(
									model,
									{aV: resultBinDisplayChoiceMaybe})),
							$elm$core$Platform$Cmd$none);
					case 7:
						var userGoal = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{bS: userGoal}),
							$elm$core$Platform$Cmd$none);
					case 11:
						var studentUsageSort = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{d1: studentUsageSort}),
							$elm$core$Platform$Cmd$none);
					case 10:
						var nowPosix = msg.a;
						var from = $elm$time$Time$millisToPosix(
							$elm$time$Time$posixToMillis(
								A2($author$project$Type$DateTime$toPosixRounded, 0, nowPosix)) - ((((7 * 24) * 60) * 60) * 1000));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									bq: _Utils_Tuple2(
										from,
										A2($author$project$Type$DateTime$toPosixRounded, 1, nowPosix)),
									bH: nowPosix
								}),
							$elm$core$Platform$Cmd$none);
					case 2:
						var cohortMaybe = msg.a;
						return _Utils_Tuple2(
							A3(
								$author$project$Page$AdaptiveAnalytics$updateModel,
								_Utils_update(
									model,
									{bl: $elm$core$Maybe$Nothing, aE: $elm$core$Maybe$Nothing}),
								rState.mX.fs,
								cohortMaybe),
							$elm$core$Platform$Cmd$none);
					case 3:
						var activity = msg.a;
						var cmd = (!$elm$time$Time$posixToMillis(model.bH)) ? A2($elm$core$Task$perform, $author$project$Page$AdaptiveAnalytics$NowPosix, $elm$time$Time$now) : $elm$core$Platform$Cmd$none;
						return _Utils_Tuple2(
							$author$project$Page$AdaptiveAnalytics$updateAggregateResults(
								_Utils_update(
									model,
									{
										aE: $elm$core$Maybe$Just(activity)
									})),
							cmd);
					case 0:
						var err = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dQ: _Utils_Tuple2(
										$author$project$Theme$Style$t.eC,
										$elm$core$Maybe$Just(
											$author$project$JsInterop$Types$msgForUserToString(err.hG)))
								}),
							$elm$core$Platform$Cmd$none);
					case 8:
						var link = msg.a;
						return _Utils_Tuple2(
							model,
							$author$project$Ports$pushUrl(link));
					case 9:
						return _Utils_Tuple2(
							model,
							$author$project$JsInterop$Plumbing$sendInfoOutside($author$project$JsInterop$Plumbing$SignOut));
					case 1:
						return _Utils_Tuple2(
							$author$project$Page$AdaptiveAnalytics$init,
							$author$project$Ports$browserBack(0));
					default:
						var me = msg.a;
						var titleSuffix = msg.b;
						var aggregateResults = msg.c;
						var nowPosix = msg.d;
						return _Utils_Tuple2(
							model,
							A5($author$project$Page$AdaptiveAnalytics$downloadCSV, model.cX, me, titleSuffix, aggregateResults, nowPosix));
				}
			});
	});
var $author$project$Page$AdminPage$OnGetEncodedVariantContent = function (a) {
	return {$: 14, a: a};
};
var $author$project$JsInterop$Plumbing$OpGetVariantIds = function (a) {
	return {$: 0, a: a};
};
var $author$project$Type$Decrypt$encodeDecryptRequest = F3(
	function (contentTag, responseHandler, ciphertext) {
		return {kv: ciphertext, gB: contentTag, mo: '79245', iG: responseHandler};
	});
var $author$project$Type$NodeCfg$findGaps = F2(
	function (width, vals) {
		var findGaps_ = F2(
			function (vals_, acc) {
				findGaps_:
				while (true) {
					if (!vals_.b) {
						return acc;
					} else {
						if (!vals_.b.b) {
							return acc;
						} else {
							var x = vals_.a;
							var _v1 = vals_.b;
							var y = _v1.a;
							var rest = _v1.b;
							if (_Utils_cmp(y - x, width) > 0) {
								var $temp$vals_ = A2($elm$core$List$cons, y, rest),
									$temp$acc = A2(
									$elm$core$List$cons,
									_Utils_Tuple2(x, y),
									acc);
								vals_ = $temp$vals_;
								acc = $temp$acc;
								continue findGaps_;
							} else {
								var $temp$vals_ = A2($elm$core$List$cons, y, rest),
									$temp$acc = acc;
								vals_ = $temp$vals_;
								acc = $temp$acc;
								continue findGaps_;
							}
						}
					}
				}
			});
		return A2(findGaps_, vals, _List_Nil);
	});
var $elm$core$List$sort = function (xs) {
	return A2($elm$core$List$sortBy, $elm$core$Basics$identity, xs);
};
var $author$project$Type$NodeCfg$calculateGaps = F2(
	function (qstnEngineMaxDiffTolerance, vals) {
		return $elm$core$List$sort(
			A2(
				$author$project$Type$NodeCfg$findGaps,
				qstnEngineMaxDiffTolerance,
				A2(
					$elm$core$List$filter,
					$elm$core$Basics$lt(150),
					$elm$core$List$sort(vals))));
	});
var $author$project$Type$NodeCfg$getActivitiesForSyllabus = F2(
	function (postMetas, syllabus) {
		return A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.or;
			},
			A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.bf;
					},
					$elm$core$Basics$eq(syllabus.lW)),
				$author$project$Type$NodeCfg$toActivityDatas(postMetas)));
	});
var $author$project$Type$NodeCfg$getCategoryForId = F2(
	function (categories, id) {
		return $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.lW;
					},
					$elm$core$Basics$eq(id)),
				categories));
	});
var $author$project$Type$NodeCfg$getCategoryLeavesForNodeIdFromCats = F2(
	function (allCats, nodeId) {
		var nodeCategory = A2($author$project$Type$NodeCfg$getCategoryForId, allCats, nodeId);
		var getCategoryDatasFromNode = F2(
			function (allNodes, targetNode) {
				var getCategoryDatasFromNode_ = getCategoryDatasFromNode(allCats);
				var childCategories = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2(
						$elm$core$Maybe$map,
						$elm$core$List$filterMap(
							$author$project$Type$NodeCfg$getCategoryForId(allNodes)),
						targetNode.eA));
				return A2(
					$elm$core$List$cons,
					targetNode,
					A2($elm$core$List$concatMap, getCategoryDatasFromNode_, childCategories));
			});
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				$elm$core$List$filter(
					function (el) {
						return el.fm === 2;
					}),
				A2(
					$elm$core$Maybe$map,
					getCategoryDatasFromNode(allCats),
					nodeCategory)));
	});
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $elm$core$Dict$intersect = F2(
	function (t1, t2) {
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (k, _v0) {
					return A2($elm$core$Dict$member, k, t2);
				}),
			t1);
	});
var $elm$core$Set$intersect = F2(
	function (_v0, _v1) {
		var dict1 = _v0;
		var dict2 = _v1;
		return A2($elm$core$Dict$intersect, dict1, dict2);
	});
var $elm$core$Dict$sizeHelp = F2(
	function (n, dict) {
		sizeHelp:
		while (true) {
			if (dict.$ === -2) {
				return n;
			} else {
				var left = dict.d;
				var right = dict.e;
				var $temp$n = A2($elm$core$Dict$sizeHelp, n + 1, right),
					$temp$dict = left;
				n = $temp$n;
				dict = $temp$dict;
				continue sizeHelp;
			}
		}
	});
var $elm$core$Dict$size = function (dict) {
	return A2($elm$core$Dict$sizeHelp, 0, dict);
};
var $elm$core$Set$size = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$size(dict);
};
var $author$project$Type$NodeCfg$toVariantMetas = function (itemData) {
	var makeVariantMeta = F2(
		function (idx, answerset) {
			return {
				ks: answerset.kr,
				dp: answerset.dp,
				i7: itemData.i7,
				pK: {oq: itemData.oq, c1: idx}
			};
		});
	return A2($elm$core$List$indexedMap, makeVariantMeta, itemData.jZ);
};
var $author$project$Type$NodeCfg$makeActivitySpread = F2(
	function (qstnEngineMaxDiffTolerance, nodeCfgResolved) {
		var allVariantMetas = A2(
			$elm$core$List$concatMap,
			$author$project$Type$NodeCfg$toVariantMetas,
			$author$project$Type$NodeCfg$getItems(nodeCfgResolved.fs));
		var allCats = $author$project$Type$NodeCfg$getCategories(nodeCfgResolved.fs);
		var makeActivitySpreadStruct = function (act) {
			var variantMetas = function () {
				var actCatIds = $elm$core$Set$fromList(
					A2(
						$elm$core$List$map,
						function ($) {
							return $.lW;
						},
						A2(
							$elm$core$List$concatMap,
							$author$project$Type$NodeCfg$getCategoryLeavesForNodeIdFromCats(allCats),
							A2(
								$elm$core$List$map,
								function ($) {
									return $.mY;
								},
								act.cx.mZ))));
				return A2(
					$elm$core$List$filter,
					function (el) {
						return $elm$core$Set$size(
							A2(
								$elm$core$Set$intersect,
								actCatIds,
								$elm$core$Set$fromList(el.ks))) > 0;
					},
					allVariantMetas);
			}();
			return {
				ee: act,
				lD: A2(
					$author$project$Type$NodeCfg$calculateGaps,
					qstnEngineMaxDiffTolerance,
					A2(
						$elm$core$List$map,
						function ($) {
							return $.dp;
						},
						variantMetas)),
				f_: variantMetas
			};
		};
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				$elm$core$List$map(makeActivitySpreadStruct),
				A2(
					$elm$core$Maybe$map,
					$author$project$Type$NodeCfg$getActivitiesForSyllabus(nodeCfgResolved.fs),
					$elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (el) {
								return _Utils_eq(el.kA, nodeCfgResolved.cF);
							},
							nodeCfgResolved.oJ)))));
	});
var $author$project$Type$NodeCfg$getCategoryLeavesForNodeId = F2(
	function (postMetas, nodeId) {
		return A2(
			$author$project$Type$NodeCfg$getCategoryLeavesForNodeIdFromCats,
			$author$project$Type$NodeCfg$getCategories(postMetas),
			nodeId);
	});
var $author$project$Type$NodeCfg$getCategoryLeavesForCurrentSyllabus = function (nodeCfg) {
	return A2(
		$author$project$Type$NodeCfg$getCategoryLeavesForNodeId,
		nodeCfg.fs,
		A2($author$project$Type$Syllabus$getSyllabusId, nodeCfg.cF, nodeCfg.oJ));
};
var $author$project$Type$NodeCfg$makeCategoryStructsForCategories = F2(
	function (postMetas, leaves) {
		var variantMetas = A2(
			$elm$core$List$concatMap,
			$author$project$Type$NodeCfg$toVariantMetas,
			$author$project$Type$NodeCfg$getItems(postMetas));
		var makeCategoryStruct = function (leaf) {
			var catVariantMetas = A2(
				$elm$core$List$filter,
				function (el) {
					return A2($elm$core$List$member, leaf.lW, el.ks);
				},
				variantMetas);
			return {kq: leaf, f_: catVariantMetas};
		};
		return A2($elm$core$List$map, makeCategoryStruct, leaves);
	});
var $author$project$Type$NodeCfg$makeCategoryStructs = function (nodeCfg) {
	return A2(
		$author$project$Type$NodeCfg$makeCategoryStructsForCategories,
		nodeCfg.fs,
		$author$project$Type$NodeCfg$getCategoryLeavesForCurrentSyllabus(nodeCfg));
};
var $author$project$Type$NodeCfg$makeCategorySpread = F2(
	function (qstnEngineMaxDiffTolerance, nodeCfgResolved) {
		return A2(
			$elm$core$List$map,
			function (_v0) {
				var cat = _v0.kq;
				var variantMetas = _v0.f_;
				return {
					kq: cat,
					lD: A2(
						$author$project$Type$NodeCfg$calculateGaps,
						qstnEngineMaxDiffTolerance,
						A2(
							$elm$core$List$map,
							function ($) {
								return $.dp;
							},
							variantMetas)),
					f_: variantMetas
				};
			},
			$author$project$Type$NodeCfg$makeCategoryStructs(nodeCfgResolved));
	});
var $author$project$Ports$sendDecryptItem = _Platform_outgoingPort(
	'sendDecryptItem',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'ciphertext',
					$elm$json$Json$Encode$string($.kv)),
					_Utils_Tuple2(
					'contentTag',
					$elm$json$Json$Encode$string($.gB)),
					_Utils_Tuple2(
					'key',
					$elm$json$Json$Encode$string($.mo)),
					_Utils_Tuple2(
					'responseHandler',
					$elm$json$Json$Encode$string($.iG))
				]));
	});
var $author$project$Page$AdminPage$update = F2(
	function (msg, model) {
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				var neverUsedModelCmd = _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				var makeNextValidationTuple = function (cmds) {
					if (cmds.b) {
						var cmd_ = cmds.a;
						var remaining = cmds.b;
						return _Utils_Tuple2(cmd_, remaining);
					} else {
						return _Utils_Tuple2($elm$core$Platform$Cmd$none, _List_Nil);
					}
				};
				switch (msg.$) {
					case 16:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					case 4:
						var cohort = msg.a;
						var categoryData = msg.b;
						var vIdStringsMaybe = A2(
							$elm$core$Maybe$map,
							$elm$core$List$filter(
								function (el) {
									return !A2($elm$core$Dict$member, el, model.aM);
								}),
							A2(
								$elm$core$Maybe$map,
								$elm$core$List$map(
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.pK;
										},
										$author$project$Type$Variant$toShortStringVariantId)),
								A2(
									$elm$core$Maybe$map,
									function ($) {
										return $.f_;
									},
									A2(
										$elm$core$Maybe$andThen,
										$elm$core$List$head,
										A2(
											$elm$core$Maybe$map,
											function (els) {
												return A2(
													$elm$core$List$filter,
													function (el2) {
														return _Utils_eq(el2.kq.lW, categoryData.lW);
													},
													els);
											},
											A2(
												$elm$core$Dict$get,
												$author$project$Type$Cohort$toCurrentSyllabusTitle(cohort),
												model.bj))))));
						var expLeafIds = A2($elm$core$List$member, categoryData.lW, model.aS) ? A2(
							$elm$core$List$filter,
							function (el) {
								return !_Utils_eq(el, categoryData.lW);
							},
							model.aS) : A2($elm$core$List$cons, categoryData.lW, model.aS);
						var _v1 = function () {
							if (!vIdStringsMaybe.$) {
								var vIdStrings = vIdStringsMaybe.a;
								var vContents = $elm$core$Dict$fromList(
									A2(
										$elm$core$List$map,
										function (el) {
											return _Utils_Tuple2(el, $krisajenkins$remotedata$RemoteData$Loading);
										},
										vIdStrings));
								var cmds_ = A2(
									$elm$core$List$map,
									A3($author$project$PortImpl$sendGetEncodedVariantContent, rState.lx.pB, $author$project$Page$AdminPage$OnGetEncodedVariantContent, rState.lx.pL),
									vIdStrings);
								return _Utils_Tuple2(cmds_, vContents);
							} else {
								return _Utils_Tuple2(_List_Nil, $elm$core$Dict$empty);
							}
						}();
						var cmds = _v1.a;
						var newVariantContents = _v1.b;
						var variantContents_ = A6(
							$elm$core$Dict$merge,
							F2(
								function (vId, contentRemoteData) {
									return A2($elm$core$Dict$insert, vId, contentRemoteData);
								}),
							F3(
								function (vId, _v3, contentRemoteData2) {
									return A2($elm$core$Dict$insert, vId, contentRemoteData2);
								}),
							F2(
								function (vId, contentRemoteData) {
									return A2($elm$core$Dict$insert, vId, contentRemoteData);
								}),
							model.aM,
							newVariantContents,
							$elm$core$Dict$empty);
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{aS: expLeafIds, aM: variantContents_}),
							$elm$core$Platform$Cmd$batch(cmds));
					case 5:
						var newSyllabusTitle = $author$project$Type$Cohort$toCurrentSyllabusTitle(rState.mX.cF);
						return A2($elm$core$List$member, newSyllabusTitle, model.bi) ? _Utils_Tuple2(model, $elm$core$Platform$Cmd$none) : _Utils_Tuple2(
							_Utils_update(
								model,
								{
									bi: A2($elm$core$List$cons, newSyllabusTitle, model.bi)
								}),
							$author$project$JsInterop$Plumbing$sendInfoOutside(
								$author$project$JsInterop$Plumbing$PursBundleInterop(
									$author$project$JsInterop$Plumbing$OpGetVariantIds(rState.mX.mW))));
					case 6:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{aN: $krisajenkins$remotedata$RemoteData$NotAsked, bi: _List_Nil}),
							$elm$core$Platform$Cmd$none);
					case 7:
						var cohort = msg.a;
						var variantMetasDict_ = A3(
							$elm$core$Dict$insert,
							$author$project$Type$Cohort$toCurrentSyllabusTitle(cohort),
							A2(
								$elm$core$List$sortBy,
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.kq;
									},
									function ($) {
										return $.ja;
									}),
								A2($author$project$Type$NodeCfg$makeCategorySpread, rState.fv, rState.mX)),
							model.bj);
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{bT: $elm$core$Maybe$Nothing, aN: $krisajenkins$remotedata$RemoteData$NotAsked, bj: variantMetasDict_}),
							$elm$core$Platform$Cmd$none);
					case 8:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{aS: _List_Nil, bj: $elm$core$Dict$empty}),
							$elm$core$Platform$Cmd$none);
					case 9:
						var cohort = msg.a;
						var variantMetasForActivityDict_ = A3(
							$elm$core$Dict$insert,
							$author$project$Type$Cohort$toCurrentSyllabusTitle(cohort),
							A2(
								$elm$core$List$sortBy,
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.ee;
									},
									function ($) {
										return $.ja;
									}),
								A2($author$project$Type$NodeCfg$makeActivitySpread, rState.fv, rState.mX)),
							model.bU);
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{bU: variantMetasForActivityDict_}),
							$elm$core$Platform$Cmd$none);
					case 10:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{bU: $elm$core$Dict$empty}),
							$elm$core$Platform$Cmd$none);
					case 11:
						var getVariantIdsResponse = msg.a;
						var mergedVariantIds = function () {
							var _v4 = model.aN;
							if (_v4.$ === 3) {
								var existingVariantIds = _v4.a;
								var newVariantIds = A2(
									$elm$core$List$partition,
									function (el) {
										return !A2($elm$core$List$member, el, existingVariantIds);
									},
									getVariantIdsResponse).a;
								return $krisajenkins$remotedata$RemoteData$succeed(
									A2($elm$core$List$append, newVariantIds, existingVariantIds));
							} else {
								return $krisajenkins$remotedata$RemoteData$succeed(getVariantIdsResponse);
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{aN: mergedVariantIds}),
							$elm$core$Platform$Cmd$none);
					case 3:
						return neverUsedModelCmd;
					case 12:
						return neverUsedModelCmd;
					case 13:
						var _v5 = function () {
							var makeCmd = function (variantId) {
								return A4(
									$author$project$PortImpl$sendGetEncodedVariantContent,
									rState.lx.pB,
									$author$project$Page$AdminPage$OnGetEncodedVariantContent,
									rState.lx.pL,
									$author$project$Type$Variant$toShortStringVariantId(variantId));
							};
							return makeNextValidationTuple(
								A2(
									$krisajenkins$remotedata$RemoteData$withDefault,
									_List_Nil,
									A2(
										$krisajenkins$remotedata$RemoteData$map,
										$elm$core$List$map(makeCmd),
										model.aN)));
						}();
						var cmd = _v5.a;
						var remainingCmds = _v5.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{aS: _List_Nil, bT: $elm$core$Maybe$Nothing, c3: remainingCmds, bj: $elm$core$Dict$empty, bU: $elm$core$Dict$empty}),
							cmd);
					case 14:
						var getEncodedVariantContentResponse = msg.a;
						var encodedVariantContentResponse = A2($elm$core$Result$mapError, $author$project$Helper$Util$httpErrorToString, getEncodedVariantContentResponse);
						var _v6 = function () {
							if (!encodedVariantContentResponse.$) {
								var encodedVariantContent = encodedVariantContentResponse.a;
								return _Utils_Tuple2(
									model,
									$author$project$Ports$sendDecryptItem(
										A3($author$project$Type$Decrypt$encodeDecryptRequest, 'payload', 'AdminPage', encodedVariantContent.c)));
							} else {
								var errMsg = encodedVariantContentResponse.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{b4: errMsg}),
									$elm$core$Platform$Cmd$none);
							}
						}();
						var newModel = _v6.a;
						var cmd = _v6.b;
						return _Utils_Tuple2(newModel, cmd);
					case 15:
						var decryptItemResponse = msg.a;
						if ($krisajenkins$remotedata$RemoteData$isNotAsked(model.aN)) {
							var newModel = function () {
								switch (decryptItemResponse.$) {
									case 3:
										var variantContent = decryptItemResponse.a;
										var variantContents_ = A3(
											$elm$core$Dict$insert,
											$author$project$Type$Variant$toShortStringVariantId(variantContent.pK),
											$krisajenkins$remotedata$RemoteData$Success(variantContent),
											model.aM);
										return _Utils_update(
											model,
											{aM: variantContents_});
									case 2:
										var variantContentError = decryptItemResponse.a;
										var _v9 = variantContentError.fZ;
										if (!_v9.$) {
											var variantId = _v9.a;
											var variantContents_ = A3(
												$elm$core$Dict$insert,
												$author$project$Type$Variant$toShortStringVariantId(variantId),
												$krisajenkins$remotedata$RemoteData$Failure(variantContentError),
												model.aM);
											return _Utils_update(
												model,
												{aM: variantContents_});
										} else {
											return model;
										}
									default:
										return model;
								}
							}();
							return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
						} else {
							var validatedCountMaybe = function () {
								var _v13 = model.bT;
								if (_v13.$ === 1) {
									return $elm$core$Maybe$Just(1);
								} else {
									var count = _v13.a;
									return $elm$core$Maybe$Just(count + 1);
								}
							}();
							var newModel = function () {
								if (decryptItemResponse.$ === 2) {
									var variantContentError = decryptItemResponse.a;
									var _v12 = variantContentError.fZ;
									if (!_v12.$) {
										var variantId = _v12.a;
										return _Utils_update(
											model,
											{
												b8: A2($elm$core$List$cons, variantId, model.b8)
											});
									} else {
										return model;
									}
								} else {
									return model;
								}
							}();
							var _v10 = makeNextValidationTuple(model.c3);
							var cmd = _v10.a;
							var remainingCmds = _v10.b;
							return _Utils_Tuple2(
								_Utils_update(
									newModel,
									{bT: validatedCountMaybe, c3: remainingCmds}),
								cmd);
						}
					case 2:
						var err = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									fF: $elm$core$Maybe$Just(
										$author$project$JsInterop$Types$msgForUserToString(err.hG))
								}),
							$elm$core$Platform$Cmd$none);
					case 1:
						var link = msg.a;
						return _Utils_Tuple2(
							model,
							$author$project$Ports$pushUrl(link));
					default:
						return _Utils_Tuple2(
							model,
							$author$project$JsInterop$Plumbing$sendInfoOutside($author$project$JsInterop$Plumbing$SignOut));
				}
			});
	});
var $author$project$Page$CreateUsers$Cohort = 0;
var $author$project$Page$CreateUsers$CreateRequestNotYetSent = {$: 0};
var $author$project$Page$CreateUsers$CreateRequestSent = {$: 1};
var $author$project$JsInterop$Plumbing$CreateStudentGQL = F3(
	function (a, b, c) {
		return {$: 15, a: a, b: b, c: c};
	});
var $author$project$JsInterop$Plumbing$CreateTeacherGQL = F3(
	function (a, b, c) {
		return {$: 14, a: a, b: b, c: c};
	});
var $author$project$Page$CreateUsers$CreateUserResponse = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$CreateUsers$CreateUsers = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$CreateUsers$CreationCompleted = 4;
var $author$project$Page$CreateUsers$CreationInitiated = 3;
var $author$project$Page$CreateUsers$InputInconsistent = 1;
var $author$project$Page$CreateUsers$InputVerified = 2;
var $author$project$Page$CreateUsers$NewPassword = 1;
var $author$project$Page$CreateUsers$RepeatPassword = 2;
var $author$project$Page$CreateUsers$entriesToString = A2(
	$elm$core$Basics$composeR,
	$elm$core$List$intersperse('\n'),
	A2($elm$core$List$foldr, $elm$core$Basics$append, ''));
var $author$project$Page$SignInPagesCommon$errorMsgMaybe = F2(
	function (field, errs) {
		return $elm$core$List$head(
			A2(
				$elm$core$List$filterMap,
				function (_v0) {
					var f = _v0.a;
					var errMsg = _v0.b;
					return _Utils_eq(f, field) ? $elm$core$Maybe$Just(errMsg) : $elm$core$Maybe$Nothing;
				},
				errs));
	});
var $author$project$Page$CreateUsers$CreationStatus = F2(
	function (email, creationState) {
		return {bp: creationState, li: email};
	});
var $author$project$Page$CreateUsers$initAllCreationStates = F2(
	function (emails, initialCreationState) {
		return A2(
			$elm$core$List$map,
			function (el) {
				return A2($author$project$Page$CreateUsers$CreationStatus, el, initialCreationState);
			},
			emails);
	});
var $elm$core$String$lines = _String_lines;
var $elm$core$List$map3 = _List_map3;
var $author$project$Page$CreateUsers$makeUserInputRecords = F3(
	function (fNames, lNames, emails) {
		return A4(
			$elm$core$List$map3,
			F3(
				function (fNam, lNam, email) {
					return {li: email, lt: fNam, mq: lNam};
				}),
			fNames,
			lNames,
			emails);
	});
var $rtfeldman$elm_validate$Validate$Validator = $elm$core$Basics$identity;
var $rtfeldman$elm_validate$Validate$firstErrorHelp = F2(
	function (validators, subject) {
		firstErrorHelp:
		while (true) {
			if (!validators.b) {
				return _List_Nil;
			} else {
				var getErrors = validators.a;
				var rest = validators.b;
				var _v1 = getErrors(subject);
				if (!_v1.b) {
					var $temp$validators = rest,
						$temp$subject = subject;
					validators = $temp$validators;
					subject = $temp$subject;
					continue firstErrorHelp;
				} else {
					var errors = _v1;
					return errors;
				}
			}
		}
	});
var $rtfeldman$elm_validate$Validate$firstError = function (validators) {
	var getErrors = function (subject) {
		return A2($rtfeldman$elm_validate$Validate$firstErrorHelp, validators, subject);
	};
	return getErrors;
};
var $rtfeldman$elm_validate$Validate$ifTrue = F2(
	function (test, error) {
		var getErrors = function (subject) {
			return test(subject) ? _List_fromArray(
				[error]) : _List_Nil;
		};
		return getErrors;
	});
var $rtfeldman$elm_validate$Validate$ifNothing = F2(
	function (subjectToMaybe, error) {
		return A2(
			$rtfeldman$elm_validate$Validate$ifTrue,
			function (subject) {
				return _Utils_eq(
					subjectToMaybe(subject),
					$elm$core$Maybe$Nothing);
			},
			error);
	});
var $rtfeldman$elm_validate$Validate$isWhitespaceChar = function (_char) {
	return (_char === ' ') || ((_char === '\n') || ((_char === '\t') || (_char === '\u000D')));
};
var $rtfeldman$elm_validate$Validate$isBlank = function (str) {
	isBlank:
	while (true) {
		var _v0 = $elm$core$String$uncons(str);
		if (!_v0.$) {
			var _v1 = _v0.a;
			var _char = _v1.a;
			var rest = _v1.b;
			if ($rtfeldman$elm_validate$Validate$isWhitespaceChar(_char)) {
				var $temp$str = rest;
				str = $temp$str;
				continue isBlank;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}
};
var $rtfeldman$elm_validate$Validate$ifBlank = F2(
	function (subjectToString, error) {
		return A2(
			$rtfeldman$elm_validate$Validate$ifTrue,
			function (subject) {
				return $rtfeldman$elm_validate$Validate$isBlank(
					subjectToString(subject));
			},
			error);
	});
var $rtfeldman$elm_validate$Validate$ifFalse = F2(
	function (test, error) {
		var getErrors = function (subject) {
			return test(subject) ? _List_Nil : _List_fromArray(
				[error]);
		};
		return getErrors;
	});
var $author$project$Page$PasswordChangeCommon$newPasswordValidations = F2(
	function (newPasswordAccessorFn, newPasswordField) {
		var minLength = function (str) {
			return $elm$core$String$length(str) >= 8;
		};
		var hasNumber = function (str) {
			return A2(
				$elm$core$List$any,
				$elm$core$Char$isDigit,
				$elm$core$String$toList(str));
		};
		var hasMixedChars = function (str) {
			return function (el) {
				return A2($elm$core$List$any, $elm$core$Char$isUpper, el) && A2($elm$core$List$any, $elm$core$Char$isLower, el);
			}(
				$elm$core$String$toList(str));
		};
		return _List_fromArray(
			[
				A2(
				$rtfeldman$elm_validate$Validate$ifBlank,
				newPasswordAccessorFn,
				_Utils_Tuple2(newPasswordField, 'Please enter a password.')),
				A2(
				$rtfeldman$elm_validate$Validate$ifFalse,
				A2($elm$core$Basics$composeL, minLength, newPasswordAccessorFn),
				_Utils_Tuple2(newPasswordField, 'Password must be at least 8 characters.')),
				A2(
				$rtfeldman$elm_validate$Validate$ifFalse,
				A2($elm$core$Basics$composeL, hasMixedChars, newPasswordAccessorFn),
				_Utils_Tuple2(newPasswordField, 'Password must have mixed upper and lower case.')),
				A2(
				$rtfeldman$elm_validate$Validate$ifFalse,
				A2($elm$core$Basics$composeL, hasNumber, newPasswordAccessorFn),
				_Utils_Tuple2(newPasswordField, 'Password must have at least one number.'))
			]);
	});
var $author$project$Page$CreateUsers$modelValidator = function (userType) {
	return $rtfeldman$elm_validate$Validate$firstError(
		_Utils_ap(
			(userType === 1) ? _List_Nil : _List_fromArray(
				[
					A2(
					$rtfeldman$elm_validate$Validate$ifNothing,
					function ($) {
						return $.kA;
					},
					_Utils_Tuple2(0, 'Please select a year level to continue.'))
				]),
			_Utils_ap(
				A2(
					$author$project$Page$PasswordChangeCommon$newPasswordValidations,
					function ($) {
						return $.cf;
					},
					1),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_validate$Validate$ifTrue,
						function (model) {
							return !_Utils_eq(model.cf, model.dU);
						},
						_Utils_Tuple2(2, 'Passwords do not match.'))
					]))));
};
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $author$project$Page$CreateUsers$resetPageMsgArea = function (model) {
	return model;
};
var $author$project$Page$CreateUsers$setCreationState = F3(
	function (creationStatuses, emailMaybe, creationState) {
		return A2(
			$elm$core$List$map,
			function (el) {
				return _Utils_eq(
					$elm$core$Maybe$Just(el.li),
					emailMaybe) ? _Utils_update(
					el,
					{bp: creationState}) : el;
			},
			creationStatuses);
	});
var $author$project$Page$PasswordChangeCommon$updateSetNewPassword = F2(
	function (model, newPassword_) {
		return $elm$core$String$isEmpty(newPassword_) ? _Utils_Tuple2(
			_Utils_update(
				model,
				{cf: newPassword_, fk: $author$project$Page$PasswordChangeCommon$newPasswordPlaceholderInit}),
			$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
			_Utils_update(
				model,
				{cf: newPassword_, cQ: $elm$core$Maybe$Nothing}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$PasswordChangeCommon$updateSetRepeatPassword = F2(
	function (model, repeatPassword_) {
		return $elm$core$String$isEmpty(repeatPassword_) ? _Utils_Tuple2(
			_Utils_update(
				model,
				{dU: repeatPassword_, fy: $author$project$Page$PasswordChangeCommon$repeatPasswordPlaceholderInit}),
			$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
			_Utils_update(
				model,
				{dU: repeatPassword_, cW: $elm$core$Maybe$Nothing}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Page$PasswordChangeCommon$updateToggleIsShowPassword = function (model) {
	var isShow = !model.cN;
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{cN: isShow}),
		$elm$core$Platform$Cmd$none);
};
var $rtfeldman$elm_validate$Validate$all = function (validators) {
	var newGetErrors = function (subject) {
		var accumulateErrors = F2(
			function (_v0, totalErrors) {
				var getErrors = _v0;
				return _Utils_ap(
					totalErrors,
					getErrors(subject));
			});
		return A3($elm$core$List$foldl, accumulateErrors, _List_Nil, validators);
	};
	return newGetErrors;
};
var $rtfeldman$elm_validate$Validate$validEmail = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	A2(
		$elm$regex$Regex$fromStringWith,
		{gp: true, hO: false},
		'^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$'));
var $rtfeldman$elm_validate$Validate$isValidEmail = function (email) {
	return A2($elm$regex$Regex$contains, $rtfeldman$elm_validate$Validate$validEmail, email);
};
var $rtfeldman$elm_validate$Validate$ifInvalidEmail = F2(
	function (subjectToEmail, errorFromEmail) {
		var getErrors = function (subject) {
			var email = subjectToEmail(subject);
			return $rtfeldman$elm_validate$Validate$isValidEmail(email) ? _List_Nil : _List_fromArray(
				[
					errorFromEmail(email)
				]);
		};
		return getErrors;
	});
var $author$project$Page$CreateUsers$regexInvalidName = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('[^\\s-\'a-zA-Z0-9]+'));
var $author$project$Page$CreateUsers$isInvalidName = $elm$regex$Regex$contains($author$project$Page$CreateUsers$regexInvalidName);
var $author$project$Page$CreateUsers$userInputRecordValidator = function (cleanedEmails) {
	return $rtfeldman$elm_validate$Validate$all(
		_List_fromArray(
			[
				$rtfeldman$elm_validate$Validate$firstError(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_validate$Validate$ifBlank,
						function ($) {
							return $.lt;
						},
						'Missing first name'),
						A2(
						$rtfeldman$elm_validate$Validate$ifTrue,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.lt;
							},
							$author$project$Page$CreateUsers$isInvalidName),
						'Invalid characters in first name (Use only: a to z, -, \', integers and space)')
					])),
				$rtfeldman$elm_validate$Validate$firstError(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_validate$Validate$ifBlank,
						function ($) {
							return $.mq;
						},
						'Missing last name'),
						A2(
						$rtfeldman$elm_validate$Validate$ifTrue,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.mq;
							},
							$author$project$Page$CreateUsers$isInvalidName),
						'Invalid characters in last name (Use only: a to z, -, \', integers and space)')
					])),
				$rtfeldman$elm_validate$Validate$firstError(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_validate$Validate$ifBlank,
						function ($) {
							return $.li;
						},
						'Missing email'),
						A2(
						$rtfeldman$elm_validate$Validate$ifInvalidEmail,
						function ($) {
							return $.li;
						},
						function (el) {
							return 'The email ' + (el + ' is not a valid email format');
						}),
						A2(
						$rtfeldman$elm_validate$Validate$ifTrue,
						function (uir) {
							return $elm$core$List$length(
								A2(
									$elm$core$List$filter,
									$elm$core$Basics$eq(uir.li),
									cleanedEmails)) > 1;
						},
						'This email is used elsewhere in the emails column but must be unique')
					]))
			]));
};
var $rtfeldman$elm_validate$Validate$Valid = $elm$core$Basics$identity;
var $rtfeldman$elm_validate$Validate$validate = F2(
	function (_v0, subject) {
		var getErrors = _v0;
		var _v1 = getErrors(subject);
		if (!_v1.b) {
			return $elm$core$Result$Ok(subject);
		} else {
			var errors = _v1;
			return $elm$core$Result$Err(errors);
		}
	});
var $author$project$Page$CreateUsers$update = F2(
	function (msg, model) {
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				switch (msg.$) {
					case 2:
						var userType = msg.a;
						var createUserResponse = msg.b;
						var email = function () {
							if (!createUserResponse.$) {
								var email_ = createUserResponse.b;
								return email_;
							} else {
								var email_ = createUserResponse.a;
								return email_;
							}
						}();
						var newCreationStatuses = A3(
							$author$project$Page$CreateUsers$setCreationState,
							model.ak,
							$elm$core$Maybe$Just(email),
							$author$project$Page$CreateUsers$CreateUserResponse(createUserResponse));
						var pageStatus = A2(
							$elm$core$List$any,
							function (el) {
								return _Utils_eq(el.bp, $author$project$Page$CreateUsers$CreateRequestNotYetSent);
							},
							newCreationStatuses) ? 3 : 4;
						var newModel = _Utils_update(
							model,
							{ak: newCreationStatuses, o: pageStatus});
						return (pageStatus === 4) ? _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none) : A2(
							$Punie$elm_reader$Reader$run,
							A2(
								$author$project$Page$CreateUsers$update,
								$author$project$Page$CreateUsers$CreateUsers(userType),
								newModel),
							rState);
					case 13:
						var otherAssignableTeachers = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{cU: otherAssignableTeachers}),
							$elm$core$Platform$Cmd$none);
					case 0:
						var userType = msg.a;
						var sIdMaybe = $author$project$Type$User$isTeacherEAdminE(rState.fc) ? A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Type$User$extractPersonEDetailsFromTeacherE,
								function ($) {
									return $.lW;
								}),
							model.a0) : $elm$core$Maybe$Nothing;
						var password = model.cf;
						var nextEntryEmailToCreateMaybe = $elm$core$List$head(
							A2(
								$elm$core$List$map,
								function ($) {
									return $.li;
								},
								A2(
									$elm$core$List$filter,
									function (el) {
										return _Utils_eq(el.bp, $author$project$Page$CreateUsers$CreateRequestNotYetSent);
									},
									model.ak)));
						var emails = $elm$core$String$lines(model.at);
						var entriesToCreate = A3(
							$author$project$Page$CreateUsers$makeUserInputRecords,
							$elm$core$String$lines(model.au),
							$elm$core$String$lines(model.ax),
							emails);
						var creationStatuses = A3($author$project$Page$CreateUsers$setCreationState, model.ak, nextEntryEmailToCreateMaybe, $author$project$Page$CreateUsers$CreateRequestSent);
						var createGraphQLMsg = function (userInputRecord) {
							if (userType === 1) {
								var person = $author$project$Type$User$TeacherE(
									$author$project$Type$User$NonAdminE(
										A5($author$project$Type$User$PersonEDetails, 'NotUsedForCreation', 'NotUsedForCreation', userInputRecord.lt, userInputRecord.mq, userInputRecord.li)));
								return A3($author$project$JsInterop$Plumbing$CreateTeacherGQL, person, password, $elm$core$Maybe$Nothing);
							} else {
								var schId = A2(
									$elm$core$Basics$composeR,
									$author$project$Type$User$extractPersonEDetails,
									function ($) {
										return $.d$;
									})(rState.fc);
								var group = $elm$core$Maybe$Nothing;
								var cohort = A2($elm$core$Maybe$withDefault, $author$project$Type$Cohort$placeholder, model.kA);
								var person = $author$project$Type$User$StudentE(
									{kA: cohort, li: userInputRecord.li, lt: userInputRecord.lt, lI: group, lU: _List_Nil, lW: 'NotUsedForCreation', mq: userInputRecord.mq, d$: 'NotUsedForCreation', n4: schId});
								return A3($author$project$JsInterop$Plumbing$CreateStudentGQL, person, password, sIdMaybe);
							}
						};
						var createCmd = function (graphQLMsg) {
							return $author$project$JsInterop$Plumbing$sendInfoOutside(
								A2(
									$author$project$JsInterop$Plumbing$GraphQL,
									graphQLMsg,
									A2($author$project$JsInterop$Plumbing$getGraphQLQuery, rState.lx.lF, graphQLMsg)));
						};
						var nextCmd = A2(
							$elm$core$Maybe$withDefault,
							$elm$core$Platform$Cmd$none,
							A2(
								$elm$core$Maybe$map,
								createCmd,
								A2(
									$elm$core$Maybe$map,
									createGraphQLMsg,
									$elm$core$List$head(
										A2(
											$elm$core$List$filter,
											function (el) {
												return _Utils_eq(
													$elm$core$Maybe$Just(el.li),
													nextEntryEmailToCreateMaybe);
											},
											entriesToCreate)))));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									ak: creationStatuses,
									dQ: _Utils_Tuple2($author$project$Theme$Style$t.eC, $elm$core$Maybe$Nothing),
									o: 3
								}),
							nextCmd);
					case 1:
						var userType = msg.a;
						var trimTrailingEmptyStrings = A2(
							$elm$core$Basics$composeR,
							$elm$core$List$reverse,
							A2(
								$elm$core$Basics$composeR,
								A2(
									$elm$core$List$foldl,
									F2(
										function (el1, acc) {
											return ($elm$core$String$isEmpty(el1) && $elm$core$List$isEmpty(acc)) ? acc : A2(
												$elm$core$List$append,
												acc,
												_List_fromArray(
													[el1]));
										}),
									_List_Nil),
								$elm$core$List$reverse));
						var trimEntries = $elm$core$List$map($elm$core$String$trim);
						var toTrimmedLists = A2($elm$core$Basics$composeR, $elm$core$String$lines, trimTrailingEmptyStrings);
						var makeLength = F2(
							function (n, origList) {
								return A2(
									$elm$core$List$append,
									origList,
									A2(
										$elm$core$List$repeat,
										n - $elm$core$List$length(origList),
										''));
							});
						var isListAllValid = $elm$core$List$all(
							function (el) {
								if (!el.$) {
									return true;
								} else {
									return false;
								}
							});
						var assignedTeacher = _Utils_eq(model.a0, $elm$core$Maybe$Nothing) ? $author$project$Type$User$extractTeacherEFromPersonE(rState.fc) : model.a0;
						var allToLower = $elm$core$List$map($elm$core$String$toLower);
						var _v3 = _Utils_Tuple3(
							toTrimmedLists(model.au),
							toTrimmedLists(model.ax),
							toTrimmedLists(model.at));
						var fNamesList_ = _v3.a;
						var lNamesList_ = _v3.b;
						var emailsList_ = _v3.c;
						var maxLength = A2(
							$elm$core$Basics$max,
							A2(
								$elm$core$Basics$max,
								$elm$core$List$length(fNamesList_),
								$elm$core$List$length(lNamesList_)),
							$elm$core$List$length(emailsList_));
						var padList = makeLength(maxLength);
						var _v4 = _Utils_Tuple3(
							padList(
								trimEntries(fNamesList_)),
							padList(
								trimEntries(lNamesList_)),
							padList(
								A2($elm$core$Basics$composeR, trimEntries, allToLower)(emailsList_)));
						var cleanedFNames = _v4.a;
						var cleanedLNames = _v4.b;
						var cleanedEmails = _v4.c;
						var userInputRecords = A3($author$project$Page$CreateUsers$makeUserInputRecords, cleanedFNames, cleanedLNames, cleanedEmails);
						var entryStatuses = A2(
							$elm$core$List$map,
							$rtfeldman$elm_validate$Validate$validate(
								$author$project$Page$CreateUsers$userInputRecordValidator(cleanedEmails)),
							userInputRecords);
						var creationStatuses = isListAllValid(entryStatuses) ? A2($author$project$Page$CreateUsers$initAllCreationStates, cleanedEmails, $author$project$Page$CreateUsers$CreateRequestNotYetSent) : _List_Nil;
						var model_ = _Utils_update(
							model,
							{
								a0: assignedTeacher,
								ak: creationStatuses,
								at: $author$project$Page$CreateUsers$entriesToString(cleanedEmails),
								cL: entryStatuses,
								au: $author$project$Page$CreateUsers$entriesToString(cleanedFNames),
								ax: $author$project$Page$CreateUsers$entriesToString(cleanedLNames)
							});
						var _v5 = A2(
							$rtfeldman$elm_validate$Validate$validate,
							$author$project$Page$CreateUsers$modelValidator(userType),
							model);
						if (!_v5.$) {
							var _v6 = isListAllValid(entryStatuses) ? _Utils_Tuple2(
								2,
								_Utils_Tuple2(
									$author$project$Theme$Style$t.eC,
									$elm$core$Maybe$Just('Your entries appear valid, but the users are NOT yet created!  Please click below to create your users.'))) : _Utils_Tuple2(
								1,
								_Utils_Tuple2(
									$author$project$Theme$Style$t.eC,
									$elm$core$Maybe$Just('Please fix the errors above, then re-verify to continue.')));
							var pageStatus = _v6.a;
							var pageMsgArea = _v6.b;
							return _Utils_Tuple2(
								_Utils_update(
									model_,
									{dQ: pageMsgArea, o: pageStatus}),
								$elm$core$Platform$Cmd$none);
						} else {
							var errs = _v5.a;
							return _Utils_Tuple2(
								_Utils_update(
									model_,
									{
										cD: A2($author$project$Page$SignInPagesCommon$errorMsgMaybe, 0, errs),
										cQ: A2($author$project$Page$SignInPagesCommon$errorMsgMaybe, 1, errs),
										cW: A2($author$project$Page$SignInPagesCommon$errorMsgMaybe, 2, errs)
									}),
								$elm$core$Platform$Cmd$none);
						}
					case 11:
						var assignedTeacherId = msg.a;
						var assignedTeacher = function () {
							var _v8 = model.cU;
							if (_v8.$ === 3) {
								var assignableTeachers = _v8.a;
								return $elm$core$List$head(
									A2(
										$elm$core$List$filter,
										function (el) {
											return _Utils_eq(
												A2(
													$elm$core$Basics$composeR,
													$author$project$Type$User$extractPersonEDetailsFromTeacherE,
													function ($) {
														return $.lW;
													})(el),
												assignedTeacherId);
										},
										assignableTeachers));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{a0: assignedTeacher}),
							$elm$core$Platform$Cmd$none);
					case 12:
						var cohort = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									kA: $elm$core$Maybe$Just(cohort),
									cD: $elm$core$Maybe$Nothing
								}),
							$elm$core$Platform$Cmd$none);
					case 3:
						var fNames = msg.a;
						return $elm$core$String$isEmpty(fNames) ? _Utils_Tuple2(
							_Utils_update(
								model,
								{au: fNames, dz: $author$project$Page$CreateUsers$fNamesPlaceholderInit}),
							$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
							_Utils_update(
								model,
								{au: fNames, dy: $elm$core$Maybe$Nothing}),
							$elm$core$Platform$Cmd$none);
					case 4:
						var lNames = msg.a;
						return $elm$core$String$isEmpty(lNames) ? _Utils_Tuple2(
							_Utils_update(
								model,
								{ax: lNames, dI: $author$project$Page$CreateUsers$lNamesPlaceholderInit}),
							$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
							_Utils_update(
								model,
								{ax: lNames, dH: $elm$core$Maybe$Nothing}),
							$elm$core$Platform$Cmd$none);
					case 5:
						var emails = msg.a;
						return $elm$core$String$isEmpty(emails) ? _Utils_Tuple2(
							_Utils_update(
								model,
								{at: emails, du: $author$project$Page$CreateUsers$emailsPlaceholderInit}),
							$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
							_Utils_update(
								model,
								{at: emails, dt: $elm$core$Maybe$Nothing}),
							$elm$core$Platform$Cmd$none);
					case 6:
						var newPassword_ = msg.a;
						return A2(
							$author$project$Page$PasswordChangeCommon$updateSetNewPassword,
							$author$project$Page$CreateUsers$resetPageMsgArea(model),
							newPassword_);
					case 7:
						var repeatPassword_ = msg.a;
						return A2(
							$author$project$Page$PasswordChangeCommon$updateSetRepeatPassword,
							$author$project$Page$CreateUsers$resetPageMsgArea(model),
							repeatPassword_);
					case 8:
						return $author$project$Page$PasswordChangeCommon$updateToggleIsShowPassword(model);
					case 9:
						var err = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dQ: _Utils_Tuple2(
										$author$project$Theme$Style$t.eC,
										$elm$core$Maybe$Just(
											$author$project$JsInterop$Types$msgForUserToString(err.hG)))
								}),
							$elm$core$Platform$Cmd$none);
					case 10:
						return _Utils_Tuple2(
							$author$project$Page$CreateUsers$init,
							$author$project$Ports$browserBack(0));
					case 14:
						var link = msg.a;
						return _Utils_Tuple2(
							model,
							$author$project$Ports$pushUrl(link));
					default:
						return _Utils_Tuple2(
							model,
							$author$project$JsInterop$Plumbing$sendInfoOutside($author$project$JsInterop$Plumbing$SignOut));
				}
			});
	});
var $author$project$Page$ForgotPassword$Email = 0;
var $author$project$JsInterop$Plumbing$ForgotPassword = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$ForgotPassword$modelValidator = $rtfeldman$elm_validate$Validate$all(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.li;
			},
			_Utils_Tuple2(0, 'Please enter your email address.')),
			A2(
			$rtfeldman$elm_validate$Validate$ifInvalidEmail,
			function ($) {
				return $.li;
			},
			function (el) {
				return _Utils_Tuple2(0, 'Please enter a valid email address.');
			})
		]));
var $author$project$Page$ForgotPassword$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var email = msg.a;
				var model_ = _Utils_update(
					model,
					{fF: $elm$core$Maybe$Nothing});
				var _v1 = A2($rtfeldman$elm_validate$Validate$validate, $author$project$Page$ForgotPassword$modelValidator, model);
				if (!_v1.$) {
					return _Utils_Tuple2(
						model_,
						$author$project$JsInterop$Plumbing$sendInfoOutside(
							$author$project$JsInterop$Plumbing$ForgotPassword(email)));
				} else {
					var errs = _v1.a;
					return _Utils_Tuple2(
						_Utils_update(
							model_,
							{
								b3: A2($author$project$Page$SignInPagesCommon$errorMsgMaybe, 0, errs)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var email = msg.a;
				var email_ = $elm$core$String$toLower(email);
				return $elm$core$String$isEmpty(email_) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{li: email_, ds: $author$project$Page$ForgotPassword$emailPlaceholderInit}),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					_Utils_update(
						model,
						{li: email_, b3: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var err = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fF: $elm$core$Maybe$Just(
								$author$project$JsInterop$Types$msgForUserToString(err.hG))
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var link = msg.a;
				return _Utils_Tuple2(
					$author$project$Page$ForgotPassword$init,
					$author$project$Ports$pushUrl(link));
		}
	});
var $author$project$Page$ForgotPasswordSubmit$Code = 0;
var $author$project$JsInterop$Plumbing$ForgotPasswordSubmit = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $author$project$Page$ForgotPasswordSubmit$NewPassword = 1;
var $author$project$Page$ForgotPasswordSubmit$RepeatPassword = 2;
var $author$project$Page$ForgotPasswordSubmit$modelValidator = $rtfeldman$elm_validate$Validate$all(
	A2(
		$elm$core$List$append,
		A2(
			$author$project$Page$PasswordChangeCommon$newPasswordValidations,
			function ($) {
				return $.cf;
			},
			1),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_validate$Validate$ifBlank,
				function ($) {
					return $.gt;
				},
				_Utils_Tuple2(0, 'Please enter the verification code sent to your email.')),
				A2(
				$rtfeldman$elm_validate$Validate$ifBlank,
				function ($) {
					return $.dU;
				},
				_Utils_Tuple2(2, 'Please enter your new password again.')),
				A2(
				$rtfeldman$elm_validate$Validate$ifFalse,
				function (model) {
					return _Utils_eq(model.cf, model.dU);
				},
				_Utils_Tuple2(2, 'Passwords do not match.'))
			])));
var $author$project$Page$ForgotPasswordSubmit$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var email = msg.a;
				var code = msg.b;
				var newPassword = msg.c;
				var model_ = _Utils_update(
					model,
					{fF: $elm$core$Maybe$Nothing});
				var _v1 = A2($rtfeldman$elm_validate$Validate$validate, $author$project$Page$ForgotPasswordSubmit$modelValidator, model);
				if (!_v1.$) {
					return _Utils_Tuple2(
						model_,
						$author$project$JsInterop$Plumbing$sendInfoOutside(
							A3($author$project$JsInterop$Plumbing$ForgotPasswordSubmit, email, code, newPassword)));
				} else {
					var errs = _v1.a;
					return _Utils_Tuple2(
						_Utils_update(
							model_,
							{
								b$: A2($author$project$Page$SignInPagesCommon$errorMsgMaybe, 0, errs),
								cQ: A2($author$project$Page$SignInPagesCommon$errorMsgMaybe, 1, errs),
								cW: A2($author$project$Page$SignInPagesCommon$errorMsgMaybe, 2, errs)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var code_ = msg.a;
				return $elm$core$String$isEmpty(code_) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{gt: code_, dj: $author$project$Page$ForgotPasswordSubmit$codePlaceholderInit}),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					_Utils_update(
						model,
						{gt: code_, b$: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var newPassword_ = msg.a;
				return A2($author$project$Page$PasswordChangeCommon$updateSetNewPassword, model, newPassword_);
			case 4:
				var repeatPassword_ = msg.a;
				return A2($author$project$Page$PasswordChangeCommon$updateSetRepeatPassword, model, repeatPassword_);
			case 6:
				return $author$project$Page$PasswordChangeCommon$updateToggleIsShowPassword(model);
			case 1:
				var err = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fF: $elm$core$Maybe$Just(
								$author$project$JsInterop$Types$msgForUserToString(err.hG))
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var link = msg.a;
				return _Utils_Tuple2(
					$author$project$Page$ForgotPasswordSubmit$init,
					$author$project$Ports$pushUrl(link));
		}
	});
var $author$project$JsInterop$Plumbing$CreateSchoolGQL = function (a) {
	return {$: 16, a: a};
};
var $author$project$JsInterop$Plumbing$DeleteSchoolGQL = function (a) {
	return {$: 6, a: a};
};
var $author$project$JsInterop$Plumbing$NbrUsersForSchoolIdGQL = function (a) {
	return {$: 5, a: a};
};
var $author$project$JsInterop$Plumbing$UpdateTeacherGQL = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$ManageSchools$update = F2(
	function (msg, model) {
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				switch (msg.$) {
					case 12:
						var schoolId = msg.a;
						var personEDetails = $author$project$Type$User$extractPersonEDetails(rState.fc);
						var personE = $author$project$Type$User$TeacherE(
							$author$project$Type$User$AdminE(
								_Utils_update(
									personEDetails,
									{d$: schoolId})));
						var cmd = $author$project$JsInterop$Plumbing$sendInfoOutside(
							A2(
								$author$project$JsInterop$Plumbing$GraphQL,
								$author$project$JsInterop$Plumbing$UpdateTeacherGQL(personE),
								A2(
									$author$project$JsInterop$Plumbing$getGraphQLQuery,
									rState.lx.lF,
									$author$project$JsInterop$Plumbing$UpdateTeacherGQL(personE))));
						return _Utils_Tuple2(model, cmd);
					case 11:
						var personE = msg.a;
						var a = 1;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{cd: $elm$core$Dict$empty}),
							$elm$core$Platform$Cmd$none);
					case 5:
						var schoolName = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{n5: schoolName}),
							$elm$core$Platform$Cmd$none);
					case 6:
						var schoolCode = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{bd: schoolCode}),
							$elm$core$Platform$Cmd$none);
					case 3:
						var schoolResponse = msg.a;
						if (!schoolResponse.$) {
							var schoolDetails = schoolResponse.a;
							var schools = A2(
								$krisajenkins$remotedata$RemoteData$map,
								function (el) {
									return A2($elm$core$List$cons, schoolDetails, el);
								},
								model.L);
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{bd: '', n5: '', L: schools}),
								$elm$core$Platform$Cmd$none);
						} else {
							if (!schoolResponse.a.$) {
								var ferr = schoolResponse.a.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											dQ: _Utils_Tuple2(
												$author$project$Theme$Style$t.eC,
												$elm$core$Maybe$Just(
													'Failed to create new school (RIF):   ' + ($author$project$JsInterop$Types$msgForUserToString(ferr.hG) + ('. ' + ferr.gU))))
										}),
									$elm$core$Platform$Cmd$none);
							} else {
								var ferr = schoolResponse.a.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											dQ: _Utils_Tuple2(
												$author$project$Theme$Style$t.eC,
												$elm$core$Maybe$Just(
													'Failed to create new school (FIF):   ' + ($author$project$JsInterop$Types$msgForUserToString(ferr.hG) + ('. ' + ferr.gU))))
										}),
									$elm$core$Platform$Cmd$none);
							}
						}
					case 7:
						var schoolId = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									b7: $elm$core$Maybe$Just(schoolId)
								}),
							$author$project$JsInterop$Plumbing$sendInfoOutside(
								A2(
									$author$project$JsInterop$Plumbing$GraphQL,
									$author$project$JsInterop$Plumbing$NbrUsersForSchoolIdGQL(schoolId),
									A2(
										$author$project$JsInterop$Plumbing$getGraphQLQuery,
										rState.lx.lF,
										$author$project$JsInterop$Plumbing$NbrUsersForSchoolIdGQL(schoolId)))));
					case 8:
						var nbrUsersForSchoolIdResponse = msg.a;
						if (!nbrUsersForSchoolIdResponse.$) {
							var nbrUsersForSchoolIdSuccess = nbrUsersForSchoolIdResponse.a;
							var _v3 = model.b7;
							if (_v3.$ === 1) {
								return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
							} else {
								var schoolId = _v3.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											b7: $elm$core$Maybe$Nothing,
											cd: A3(
												$elm$core$Dict$insert,
												schoolId,
												$elm$core$List$length(nbrUsersForSchoolIdSuccess),
												model.cd)
										}),
									$elm$core$Platform$Cmd$none);
							}
						} else {
							if (!nbrUsersForSchoolIdResponse.a.$) {
								var ferr = nbrUsersForSchoolIdResponse.a.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											L: $krisajenkins$remotedata$RemoteData$Failure(
												$author$project$JsInterop$Types$msgForUserToString(ferr.hG) + ('. ' + ferr.gU))
										}),
									$elm$core$Platform$Cmd$none);
							} else {
								var ferr = nbrUsersForSchoolIdResponse.a.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											L: $krisajenkins$remotedata$RemoteData$Failure(
												$author$project$JsInterop$Types$msgForUserToString(ferr.hG) + ('. ' + ferr.gU))
										}),
									$elm$core$Platform$Cmd$none);
							}
						}
					case 9:
						var schoolId = msg.a;
						return _Utils_Tuple2(
							model,
							$author$project$JsInterop$Plumbing$sendInfoOutside(
								A2(
									$author$project$JsInterop$Plumbing$GraphQL,
									$author$project$JsInterop$Plumbing$DeleteSchoolGQL(schoolId),
									A2(
										$author$project$JsInterop$Plumbing$getGraphQLQuery,
										rState.lx.lF,
										$author$project$JsInterop$Plumbing$DeleteSchoolGQL(schoolId)))));
					case 10:
						var deleteSchoolResponse = msg.a;
						if (!deleteSchoolResponse.$) {
							var schoolDetails = deleteSchoolResponse.a;
							var schools = A2(
								$krisajenkins$remotedata$RemoteData$map,
								function (els) {
									return A2(
										$elm$core$List$filter,
										function (el) {
											return !_Utils_eq(el.lW, schoolDetails.lW);
										},
										els);
								},
								model.L);
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{L: schools}),
								$elm$core$Platform$Cmd$none);
						} else {
							if (!deleteSchoolResponse.a.$) {
								var ferr = deleteSchoolResponse.a.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											L: $krisajenkins$remotedata$RemoteData$Failure(
												$author$project$JsInterop$Types$msgForUserToString(ferr.hG) + ('. ' + ferr.gU))
										}),
									$elm$core$Platform$Cmd$none);
							} else {
								var ferr = deleteSchoolResponse.a.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											L: $krisajenkins$remotedata$RemoteData$Failure(
												$author$project$JsInterop$Types$msgForUserToString(ferr.hG) + ('. ' + ferr.gU))
										}),
									$elm$core$Platform$Cmd$none);
							}
						}
					case 4:
						var schoolsResponse = msg.a;
						if (!schoolsResponse.$) {
							var schoolsDetails = schoolsResponse.a;
							var mdl = _Utils_update(
								model,
								{
									dQ: _Utils_Tuple2($author$project$Theme$Style$t.eC, $elm$core$Maybe$Nothing),
									L: $krisajenkins$remotedata$RemoteData$Success(schoolsDetails)
								});
							return _Utils_Tuple2(mdl, $elm$core$Platform$Cmd$none);
						} else {
							if (!schoolsResponse.a.$) {
								var ferr = schoolsResponse.a.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											L: $krisajenkins$remotedata$RemoteData$Failure(
												$author$project$JsInterop$Types$msgForUserToString(ferr.hG) + ('. ' + ferr.gU))
										}),
									$elm$core$Platform$Cmd$none);
							} else {
								var ferr = schoolsResponse.a.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											L: $krisajenkins$remotedata$RemoteData$Failure(
												$author$project$JsInterop$Types$msgForUserToString(ferr.hG) + ('. ' + ferr.gU))
										}),
									$elm$core$Platform$Cmd$none);
							}
						}
					case 2:
						var id = msg.a;
						var name = msg.b;
						var school = A4($author$project$JsInterop$Types$SchoolDetails, id, 'school', name, true);
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dQ: _Utils_Tuple2($author$project$Theme$Style$t.eC, $elm$core$Maybe$Nothing)
								}),
							$author$project$JsInterop$Plumbing$sendInfoOutside(
								A2(
									$author$project$JsInterop$Plumbing$GraphQL,
									$author$project$JsInterop$Plumbing$CreateSchoolGQL(school),
									A2(
										$author$project$JsInterop$Plumbing$getGraphQLQuery,
										rState.lx.lF,
										$author$project$JsInterop$Plumbing$CreateSchoolGQL(school)))));
					case 0:
						var err = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									dQ: _Utils_Tuple2(
										$author$project$Theme$Style$t.eC,
										$elm$core$Maybe$Just(
											$author$project$JsInterop$Types$msgForUserToString(err.hG)))
								}),
							$elm$core$Platform$Cmd$none);
					case 13:
						var link = msg.a;
						return _Utils_Tuple2(
							model,
							$author$project$Ports$pushUrl(link));
					case 14:
						return _Utils_Tuple2(
							model,
							$author$project$JsInterop$Plumbing$sendInfoOutside($author$project$JsInterop$Plumbing$SignOut));
					default:
						return _Utils_Tuple2(
							$author$project$Page$ManageSchools$init,
							$author$project$Ports$browserBack(0));
				}
			});
	});
var $author$project$Page$ManageUsers$SetAllIsChecked = function (a) {
	return {$: 9, a: a};
};
var $krisajenkins$remotedata$RemoteData$map2 = F3(
	function (f, a, b) {
		return A2(
			$krisajenkins$remotedata$RemoteData$andMap,
			b,
			A2($krisajenkins$remotedata$RemoteData$map, f, a));
	});
var $author$project$Page$ManageUsers$setIsChecked = F2(
	function (user, isChecked) {
		if (user.$ === 1) {
			var personE = user.a;
			return A2($author$project$Page$ManageUsers$AugmentedUser, personE, isChecked);
		} else {
			return user;
		}
	});
var $author$project$Page$ManageUsers$getIdForUser = A2(
	$elm$core$Basics$composeR,
	$author$project$Page$ManageUsers$extractPersonEFromUser,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Type$User$extractPersonEDetails,
		function ($) {
			return $.lW;
		}));
var $author$project$Page$ManageUsers$setIsCheckedForId = F3(
	function (users, id, isChecked) {
		return A2(
			$elm$core$List$map,
			function (el) {
				return _Utils_eq(
					$author$project$Page$ManageUsers$getIdForUser(el),
					id) ? A2($author$project$Page$ManageUsers$setIsChecked, el, isChecked) : el;
			},
			users);
	});
var $elm$core$String$toUpper = _String_toUpper;
var $krisajenkins$remotedata$RemoteData$update = F2(
	function (f, remoteData) {
		switch (remoteData.$) {
			case 3:
				var data = remoteData.a;
				var _v1 = f(data);
				var first = _v1.a;
				var second = _v1.b;
				return _Utils_Tuple2(
					$krisajenkins$remotedata$RemoteData$Success(first),
					second);
			case 0:
				return _Utils_Tuple2($krisajenkins$remotedata$RemoteData$NotAsked, $elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2($krisajenkins$remotedata$RemoteData$Loading, $elm$core$Platform$Cmd$none);
			default:
				var error = remoteData.a;
				return _Utils_Tuple2(
					$krisajenkins$remotedata$RemoteData$Failure(error),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$ManageUsers$updateTeacherIsChecked = F3(
	function (id, isChecked, teachers) {
		return function (el) {
			return _Utils_Tuple2(el, $elm$core$Platform$Cmd$none);
		}(
			A2(
				$elm$core$List$map,
				function (el) {
					return _Utils_eq(
						$author$project$Type$User$extractPersonEDetailsFromTeacherE(el.bN).lW,
						id) ? _Utils_update(
						el,
						{W: isChecked}) : el;
				},
				teachers));
	});
var $author$project$Page$ManageUsers$update = F3(
	function (msg, model, graphQLOps) {
		update:
		while (true) {
			switch (msg.$) {
				case 13:
					var groupFilter = msg.a;
					var $temp$msg = $author$project$Page$ManageUsers$SetAllIsChecked(false),
						$temp$model = _Utils_update(
						model,
						{
							b6: $elm$core$String$toLower(groupFilter)
						}),
						$temp$graphQLOps = graphQLOps;
					msg = $temp$msg;
					model = $temp$model;
					graphQLOps = $temp$graphQLOps;
					continue update;
				case 14:
					var emailsToFilter = msg.a;
					var $temp$msg = $author$project$Page$ManageUsers$SetAllIsChecked(false),
						$temp$model = _Utils_update(
						model,
						{
							cK: $elm$core$String$toLower(emailsToFilter)
						}),
						$temp$graphQLOps = graphQLOps;
					msg = $temp$msg;
					model = $temp$model;
					graphQLOps = $temp$graphQLOps;
					continue update;
				case 15:
					var cohortMaybe = msg.a;
					var $temp$msg = $author$project$Page$ManageUsers$SetAllIsChecked(false),
						$temp$model = _Utils_update(
						model,
						{bX: cohortMaybe}),
						$temp$graphQLOps = graphQLOps;
					msg = $temp$msg;
					model = $temp$model;
					graphQLOps = $temp$graphQLOps;
					continue update;
				case 12:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 11:
					var teacherIds = msg.a;
					var makeCmd = function (id) {
						return (model.bJ === 1) ? $author$project$JsInterop$Plumbing$sendInfoOutside(
							A2(
								$author$project$JsInterop$Plumbing$GraphQL,
								$author$project$JsInterop$Plumbing$StudentsByTchIdGQL(id),
								A2(
									$author$project$JsInterop$Plumbing$getGraphQLQuery,
									graphQLOps,
									$author$project$JsInterop$Plumbing$StudentsByTchIdGQL(id)))) : $author$project$JsInterop$Plumbing$sendInfoOutside(
							A2(
								$author$project$JsInterop$Plumbing$GraphQL,
								$author$project$JsInterop$Plumbing$StudentsByTchIdWithHstryGQL(id),
								A2(
									$author$project$JsInterop$Plumbing$getGraphQLQuery,
									graphQLOps,
									$author$project$JsInterop$Plumbing$StudentsByTchIdWithHstryGQL(id))));
					};
					var cmds = A2($elm$core$List$map, makeCmd, teacherIds);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{b6: '', S: $krisajenkins$remotedata$RemoteData$Loading, bX: $elm$core$Maybe$Nothing}),
						$elm$core$Platform$Cmd$batch(cmds));
				case 17:
					var teachers = A2(
						$krisajenkins$remotedata$RemoteData$map,
						function (els) {
							return A2(
								$elm$core$List$map,
								function (el) {
									return _Utils_update(
										el,
										{W: true});
								},
								els);
						},
						model.aa);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aa: teachers}),
						$elm$core$Platform$Cmd$none);
				case 18:
					var teachers = A2(
						$krisajenkins$remotedata$RemoteData$map,
						function (els) {
							return A2(
								$elm$core$List$map,
								function (el) {
									return _Utils_update(
										el,
										{W: false});
								},
								els);
						},
						model.aa);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aa: teachers}),
						$elm$core$Platform$Cmd$none);
				case 10:
					var id = msg.a;
					var isChecked = msg.b;
					var _v1 = A2(
						$krisajenkins$remotedata$RemoteData$update,
						A2($author$project$Page$ManageUsers$updateTeacherIsChecked, id, isChecked),
						model.aa);
					var teachers = _v1.a;
					var cmd = _v1.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aa: teachers}),
						cmd);
				case 1:
					var users = msg.a;
					var newUsers = function () {
						var _v2 = model.S;
						if (_v2.$ === 3) {
							return A3($krisajenkins$remotedata$RemoteData$map2, $elm$core$List$append, model.S, users);
						} else {
							return users;
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{S: newUsers}),
						$elm$core$Platform$Cmd$none);
				case 2:
					var augmentedTeacherERecord = msg.a;
					var meRemote = msg.b;
					var sortTeachers = function (augTeachers) {
						return A2(
							$elm$core$List$sortBy,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.bN;
								},
								A2(
									$elm$core$Basics$composeR,
									$author$project$Type$User$extractPersonEDetailsFromTeacherE,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.mq;
										},
										$elm$core$String$toUpper))),
							augTeachers);
					};
					var _v3 = function () {
						if (meRemote.$ === 3) {
							var me = meRemote.a;
							return A2(
								$krisajenkins$remotedata$RemoteData$update,
								A2(
									$author$project$Page$ManageUsers$updateTeacherIsChecked,
									A2(
										$elm$core$Basics$composeR,
										$author$project$Type$User$extractPersonEDetails,
										function ($) {
											return $.lW;
										})(me),
									true),
								augmentedTeacherERecord);
						} else {
							return _Utils_Tuple2(augmentedTeacherERecord, $elm$core$Platform$Cmd$none);
						}
					}();
					var teachers = _v3.a;
					var cmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aa: A2($krisajenkins$remotedata$RemoteData$map, sortTeachers, teachers)
							}),
						cmd);
				case 3:
					var err = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dQ: _Utils_Tuple2(
									$author$project$Theme$Style$t.eC,
									$elm$core$Maybe$Just(
										$author$project$JsInterop$Types$msgForUserToString(err.hG)))
							}),
						$elm$core$Platform$Cmd$none);
				case 0:
					var link = msg.a;
					return _Utils_Tuple2(
						model,
						$author$project$Ports$pushUrl(link));
				case 16:
					return _Utils_Tuple2(
						model,
						$author$project$JsInterop$Plumbing$sendInfoOutside($author$project$JsInterop$Plumbing$SignOut));
				case 4:
					return _Utils_Tuple2(
						$author$project$Page$ManageUsers$init,
						$author$project$Ports$browserBack(0));
				case 5:
					var id = msg.a;
					var isChecked = msg.b;
					var users = A2(
						$krisajenkins$remotedata$RemoteData$map,
						function (users_) {
							return A3($author$project$Page$ManageUsers$setIsCheckedForId, users_, id, isChecked);
						},
						model.S);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{S: users}),
						$elm$core$Platform$Cmd$none);
				case 6:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 7:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 8:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 19:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 20:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				default:
					var isChecked = msg.a;
					var toFilterableMaybe = function (user) {
						return A3($elm$core$Basics$composeR, $author$project$Page$ManageUsers$extractPersonEFromUser, $author$project$Type$User$extractStudentEFromPersonE, user);
					};
					var isUserPassesAllFilters = function (user) {
						return A2(
							$elm$core$Maybe$withDefault,
							false,
							A2(
								$elm$core$Maybe$map,
								$author$project$Page$ManageUsers$isPassesAllFilters(model),
								toFilterableMaybe(user)));
					};
					var setIsCheckeds = function (users) {
						return A2(
							$elm$core$List$map,
							function (user) {
								return ((!isChecked) || isUserPassesAllFilters(user)) ? A2($author$project$Page$ManageUsers$setIsChecked, user, isChecked) : user;
							},
							users);
					};
					var newUsers = A2($krisajenkins$remotedata$RemoteData$map, setIsCheckeds, model.S);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{S: newUsers}),
						$elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$JsInterop$Plumbing$Me = {$: 6};
var $author$project$JsInterop$Plumbing$Me20 = {$: 7};
var $author$project$Page$Mjd5$MsgToolCalc = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$Mjd5$OnGetPost = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Mjd5$initializeTests = F2(
	function (baseStr, tGroup) {
		return A2(
			$elm$core$List$indexedMap,
			F2(
				function (idx, el) {
					var tId = baseStr + ($elm$core$String$fromInt(idx) + ':');
					return {
						i4: el.aW(tId),
						aj: el.aj,
						cm: tId,
						cn: $krisajenkins$remotedata$RemoteData$NotAsked
					};
				}),
			tGroup);
	});
var $author$project$Page$Mjd5$runNextTestIfApplicable = function (model) {
	var nextTestMaybe = A2(
		$elm$core$Maybe$andThen,
		$elm$core$List$head,
		A2(
			$elm$core$Maybe$map,
			$elm$core$List$filter(
				function (el) {
					return _Utils_eq(el.cn, $krisajenkins$remotedata$RemoteData$NotAsked);
				}),
			model.aI));
	var updatedTests = A2(
		$elm$core$Maybe$map,
		$elm$core$List$map(
			function (el) {
				return _Utils_eq(
					$elm$core$Maybe$Just(el.cm),
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.cm;
						},
						nextTestMaybe)) ? _Utils_update(
					el,
					{cn: $krisajenkins$remotedata$RemoteData$Loading}) : el;
			}),
		model.aI);
	if (!nextTestMaybe.$) {
		var nextTest = nextTestMaybe.a;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{aI: updatedTests}),
			nextTest.aj);
	} else {
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	}
};
var $author$project$JsInterop$Plumbing$OpGetVariantSelection = F6(
	function (a, b, c, d, e, f) {
		return {$: 1, a: a, b: b, c: c, d: d, e: e, f: f};
	});
var $author$project$Page$Mjd5$extractITitleMaybe = function (_v0) {
	var variantIdMaybe = _v0.b;
	return A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.oq;
		},
		variantIdMaybe);
};
var $author$project$Type$Variant$toStringVariantId = function (variantId) {
	return 'VariantId: ' + (' smuid is ' + (variantId.oq + (' vId is ' + $elm$core$String$fromInt(variantId.c1))));
};
var $author$project$Type$Variant$toStringVariantSelection = function (_v0) {
	var selectionRationale = _v0.a;
	var variantIdMaybe = _v0.b;
	if (!variantIdMaybe.$) {
		var variantId = variantIdMaybe.a;
		return $author$project$Type$Variant$toStringSelectionRationale(selectionRationale) + ('\n ' + $author$project$Type$Variant$toStringVariantId(variantId));
	} else {
		return $author$project$Type$Variant$toStringSelectionRationale(selectionRationale) + '\n VariantId: Nothing';
	}
};
var $author$project$Page$Mjd5$failOnExpectedMsg = function (vSel) {
	return 'Expected: ' + $author$project$Type$Variant$toStringVariantSelection(vSel);
};
var $author$project$Page$Mjd5$localLookupNodeIdForTitleMaybe = function (title) {
	switch (title) {
		case 'mjdtest':
			return $elm$core$Maybe$Just('dff148a2-c932-46bb-8bd9-17577c165fa7');
		case 'mjdtest:b1':
			return $elm$core$Maybe$Just('011317b8-5423-4719-be6f-232a0c1a32be');
		case 'mjdtest:b1:l1':
			return $elm$core$Maybe$Just('93f143db-bf8b-4cd7-90a4-7849a04dca8a');
		case 'mjdtest:b1:l2':
			return $elm$core$Maybe$Just('b54f31de-d191-4eff-b677-b926f98f4a3b');
		case 'mjdtest:b2':
			return $elm$core$Maybe$Just('c0b3134f-9d74-40b5-99fa-7f963373fcc0');
		case 'mjdtest:b2:l1':
			return $elm$core$Maybe$Just('d0aefe71-ed7d-4503-a34d-aa2cc8768f50');
		case 'mjdtest:b2:l2':
			return $elm$core$Maybe$Just('68e586ef-c86e-4597-82c6-c10b70bff331');
		case 'mjdtest:b3':
			return $elm$core$Maybe$Just('5f543f78-a8fd-4a31-87db-8227fd5531f9');
		case 'mjdtest:b3:l1':
			return $elm$core$Maybe$Just('bf3e33e9-b808-476e-b00f-63a7af37b131');
		case 'mjdtest:b3:l2':
			return $elm$core$Maybe$Just('e6a3e617-9aa9-4433-a849-b767e0c66727');
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$Mjd5$appendActualMsg = F2(
	function (inStr, variantSelection) {
		return inStr + ('\n\nBut the variantSelection was: ' + $author$project$Type$Variant$toStringVariantSelection(variantSelection));
	});
var $elm_explorations$test$Test$Runner$Failure$Custom = {$: 0};
var $elm_explorations$test$Test$Expectation$Fail = function (a) {
	return {$: 1, a: a};
};
var $elm_explorations$test$Test$Distribution$NoDistribution = {$: 0};
var $elm_explorations$test$Test$Expectation$fail = function (_v0) {
	var description = _v0.gT;
	var reason = _v0.an;
	return $elm_explorations$test$Test$Expectation$Fail(
		{gT: description, le: $elm_explorations$test$Test$Distribution$NoDistribution, hc: $elm$core$Maybe$Nothing, an: reason});
};
var $elm_explorations$test$Expect$fail = function (str) {
	return $elm_explorations$test$Test$Expectation$fail(
		{gT: str, an: $elm_explorations$test$Test$Runner$Failure$Custom});
};
var $elm_explorations$test$Test$Expectation$Pass = function (a) {
	return {$: 0, a: a};
};
var $elm_explorations$test$Expect$pass = $elm_explorations$test$Test$Expectation$Pass(
	{le: $elm_explorations$test$Test$Distribution$NoDistribution});
var $author$project$Page$Mjd5$prependUnsuccessMsg = function (inStr) {
	return 'Test failed to return a value: ' + inStr;
};
var $author$project$Page$Mjd5$makeExpectationBody = F4(
	function (_v0, failMsg, isSuccessFn, res) {
		if (res.$ === 3) {
			var variantSelection = res.a;
			return isSuccessFn(variantSelection) ? $elm_explorations$test$Expect$pass : $elm_explorations$test$Expect$fail(
				A2($author$project$Page$Mjd5$appendActualMsg, failMsg, variantSelection));
		} else {
			return $elm_explorations$test$Expect$fail(
				$author$project$Page$Mjd5$prependUnsuccessMsg(failMsg));
		}
	});
var $elm_explorations$test$Test$Internal$ElmTestVariant__Labeled = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm_explorations$test$Test$Internal$ElmTestVariant__UnitTest = function (a) {
	return {$: 0, a: a};
};
var $elm_explorations$test$Test$Runner$Failure$BadDescription = 3;
var $elm_explorations$test$Test$Runner$Failure$Invalid = function (a) {
	return {$: 6, a: a};
};
var $elm_explorations$test$Test$Internal$failNow = function (record) {
	return $elm_explorations$test$Test$Internal$ElmTestVariant__UnitTest(
		function (_v0) {
			return _List_fromArray(
				[
					$elm_explorations$test$Test$Expectation$fail(record)
				]);
		});
};
var $elm_explorations$test$Test$Internal$blankDescriptionFailure = $elm_explorations$test$Test$Internal$failNow(
	{
		gT: 'This test has a blank description. Let\'s give it a useful one!',
		an: $elm_explorations$test$Test$Runner$Failure$Invalid(3)
	});
var $elm_explorations$test$Test$test = F2(
	function (untrimmedDesc, thunk) {
		var desc = $elm$core$String$trim(untrimmedDesc);
		return $elm$core$String$isEmpty(desc) ? $elm_explorations$test$Test$Internal$blankDescriptionFailure : A2(
			$elm_explorations$test$Test$Internal$ElmTestVariant__Labeled,
			desc,
			$elm_explorations$test$Test$Internal$ElmTestVariant__UnitTest(
				function (_v0) {
					return _List_fromArray(
						[
							thunk(0)
						]);
				}));
	});
var $author$project$Page$Mjd5$testGroupA = function (node) {
	return _List_fromArray(
		[
			function () {
			var nodeIdMaybe = $author$project$Page$Mjd5$localLookupNodeIdForTitleMaybe('mjdtest:b1:l2');
			var aMetaMaybe = A2(
				$elm$core$Maybe$map,
				function (nodeId) {
					return {
						kL: 0,
						lA: 0,
						l3: 450,
						mZ: _List_fromArray(
							[
								{mY: nodeId, ih: 100}
							]),
						oR: nodeId
					};
				},
				nodeIdMaybe);
			return {
				aW: F2(
					function (preStr, res) {
						var successMsg = preStr + 'pick  variant in the b1l2 leaf node targeting 456 difficulty';
						var expVariantSelection = _Utils_Tuple2(
							{
								mZ: A2(
									$elm$core$Maybe$withDefault,
									_List_Nil,
									A2(
										$elm$core$Maybe$map,
										function (aMeta) {
											return aMeta.mZ;
										},
										aMetaMaybe)),
								iO: 456,
								iP: 0,
								oS: 456
							},
							$elm$core$Maybe$Just(
								{oq: '90121', c1: 0}));
						var failMsg = $author$project$Page$Mjd5$failOnExpectedMsg(expVariantSelection);
						var isSuccessFn = function (vSel) {
							return _Utils_eq(vSel, expVariantSelection);
						};
						return A2(
							$elm_explorations$test$Test$test,
							successMsg,
							function (_v0) {
								return A4($author$project$Page$Mjd5$makeExpectationBody, successMsg, failMsg, isSuccessFn, res);
							});
					}),
				aj: A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$Maybe$map,
						function (aMeta) {
							return $author$project$JsInterop$Plumbing$sendInfoOutside(
								$author$project$JsInterop$Plumbing$PursBundleInterop(
									A6(
										$author$project$JsInterop$Plumbing$OpGetVariantSelection,
										29,
										node,
										aMeta,
										1,
										_List_Nil,
										$elm$core$Maybe$Just(456))));
						},
						aMetaMaybe))
			};
		}(),
			function () {
			var nodeIdMaybe = $author$project$Page$Mjd5$localLookupNodeIdForTitleMaybe('mjdtest:b1:l2');
			var aMetaMaybe = A2(
				$elm$core$Maybe$map,
				function (nodeId) {
					return {
						kL: 1,
						lA: 0,
						l3: 450,
						mZ: _List_fromArray(
							[
								{mY: nodeId, ih: 100}
							]),
						oR: nodeId
					};
				},
				nodeIdMaybe);
			return {
				aW: F2(
					function (preStr, res) {
						var successMsg = preStr + 'pick variant with a Practice context in the b1l2 leaf node given no difficulty (so min diff)';
						var expVariantSelection = _Utils_Tuple2(
							{
								mZ: A2(
									$elm$core$Maybe$withDefault,
									_List_Nil,
									A2(
										$elm$core$Maybe$map,
										function (aMeta) {
											return aMeta.mZ;
										},
										aMetaMaybe)),
								iO: 456,
								iP: 0,
								oS: 456
							},
							$elm$core$Maybe$Just(
								{oq: '90121', c1: 0}));
						var failMsg = $author$project$Page$Mjd5$failOnExpectedMsg(expVariantSelection);
						var isSuccessFn = function (vSel) {
							return _Utils_eq(vSel, expVariantSelection);
						};
						return A2(
							$elm_explorations$test$Test$test,
							successMsg,
							function (_v1) {
								return A4($author$project$Page$Mjd5$makeExpectationBody, successMsg, failMsg, isSuccessFn, res);
							});
					}),
				aj: A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$Maybe$map,
						function (aMeta) {
							return $author$project$JsInterop$Plumbing$sendInfoOutside(
								$author$project$JsInterop$Plumbing$PursBundleInterop(
									A6($author$project$JsInterop$Plumbing$OpGetVariantSelection, 29, node, aMeta, 1, _List_Nil, $elm$core$Maybe$Nothing)));
						},
						aMetaMaybe))
			};
		}(),
			function () {
			var nodeIdMaybe = $author$project$Page$Mjd5$localLookupNodeIdForTitleMaybe('mjdtest');
			var nodeId2Maybe = $author$project$Page$Mjd5$localLookupNodeIdForTitleMaybe('mjdtest:b1:l1');
			var aMetaMaybe = A3(
				$elm$core$Maybe$map2,
				F2(
					function (nodeId, nodeId2) {
						return {
							kL: 1,
							lA: 0,
							l3: 450,
							mZ: _List_fromArray(
								[
									{mY: nodeId2, ih: 100}
								]),
							oR: nodeId
						};
					}),
				nodeIdMaybe,
				nodeId2Maybe);
			return {
				aW: F2(
					function (preStr, res) {
						var successMsg = preStr + 'pick closest variant with a Practice context in the NON-SPECIFIED \nleaf node when specify nodePercentages of another branch and the target node is root node';
						var expITitles = _List_fromArray(
							['90111', '90112', '90121', '90122']);
						var failMsg = 'Expected smuid to be one of ' + A2($elm$core$String$join, ', ', expITitles);
						var isSuccessFn = function (vSel) {
							return A2(
								$elm$core$List$member,
								$author$project$Page$Mjd5$extractITitleMaybe(vSel),
								A2($elm$core$List$map, $elm$core$Maybe$Just, expITitles));
						};
						return A2(
							$elm_explorations$test$Test$test,
							successMsg,
							function (_v2) {
								return A4($author$project$Page$Mjd5$makeExpectationBody, successMsg, failMsg, isSuccessFn, res);
							});
					}),
				aj: A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$Maybe$map,
						function (aMeta) {
							return $author$project$JsInterop$Plumbing$sendInfoOutside(
								$author$project$JsInterop$Plumbing$PursBundleInterop(
									A6(
										$author$project$JsInterop$Plumbing$OpGetVariantSelection,
										29,
										node,
										aMeta,
										1,
										_List_Nil,
										$elm$core$Maybe$Just(476))));
						},
						aMetaMaybe))
			};
		}()
		]);
};
var $author$project$Page$Mjd5$remoteVariantSelectionToVidString = function (rVariantSelection) {
	return '\n --> ' + A2(
		$krisajenkins$remotedata$RemoteData$withDefault,
		'VariantSelection was not a RemoteData.Success and therefore the VariantId could not be extracted.',
		A2(
			$krisajenkins$remotedata$RemoteData$map,
			$elm$core$Maybe$withDefault('VariantId was \'Nothing\''),
			A2(
				$krisajenkins$remotedata$RemoteData$map,
				$elm$core$Maybe$map($author$project$Type$Variant$toStringVariantId),
				A2($krisajenkins$remotedata$RemoteData$map, $elm$core$Tuple$second, rVariantSelection))));
};
var $author$project$Page$Mjd5$testGroupB = function (node) {
	var nodeIdMaybe = $author$project$Page$Mjd5$localLookupNodeIdForTitleMaybe('mjdtest');
	var nodeIdL2Maybe = $author$project$Page$Mjd5$localLookupNodeIdForTitleMaybe('mjdtest:b2');
	var nodeIdL1Maybe = $author$project$Page$Mjd5$localLookupNodeIdForTitleMaybe('mjdtest:b1');
	var hItem3 = {
		dW: $elm$core$Maybe$Nothing,
		pK: {oq: '90212', c1: 0}
	};
	var hItem2 = {
		dW: $elm$core$Maybe$Nothing,
		pK: {oq: '90112', c1: 0}
	};
	var hItem1 = {
		dW: $elm$core$Maybe$Nothing,
		pK: {oq: '90111', c1: 0}
	};
	var aMetaMaybe = A4(
		$elm$core$Maybe$map3,
		F3(
			function (nodeId, nodeIdL1, nodeIdL2) {
				return {
					kL: 0,
					lA: 0,
					l3: 450,
					mZ: _List_fromArray(
						[
							{mY: nodeIdL1, ih: 75},
							{mY: nodeIdL2, ih: 25}
						]),
					oR: nodeId
				};
			}),
		nodeIdMaybe,
		nodeIdL1Maybe,
		nodeIdL2Maybe);
	return _List_fromArray(
		[
			{
			aW: F2(
				function (preStr, res) {
					var successMsg = preStr + 'With no history, pick 90122 from mjdtest:b1';
					var expITitles = _List_fromArray(
						['90122']);
					var failMsg = 'Expected smuid to be one of ' + A2($elm$core$String$join, ', ', expITitles);
					var isSuccessFn = function (vSel) {
						return A2(
							$elm$core$List$member,
							$author$project$Page$Mjd5$extractITitleMaybe(vSel),
							A2($elm$core$List$map, $elm$core$Maybe$Just, expITitles));
					};
					return A2(
						$elm_explorations$test$Test$test,
						_Utils_ap(
							successMsg,
							$author$project$Page$Mjd5$remoteVariantSelectionToVidString(res)),
						function (_v0) {
							return A4($author$project$Page$Mjd5$makeExpectationBody, successMsg, failMsg, isSuccessFn, res);
						});
				}),
			aj: A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Platform$Cmd$none,
				A2(
					$elm$core$Maybe$map,
					function (aMeta) {
						return $author$project$JsInterop$Plumbing$sendInfoOutside(
							$author$project$JsInterop$Plumbing$PursBundleInterop(
								A6(
									$author$project$JsInterop$Plumbing$OpGetVariantSelection,
									29,
									node,
									aMeta,
									1,
									_List_Nil,
									$elm$core$Maybe$Just(476))));
					},
					aMetaMaybe))
		},
			{
			aW: F2(
				function (preStr, res) {
					var successMsg = preStr + 'With 90111 from mjdtest:b1 selected, pick from mjdtest:b1 again \n(as per ExmplReshuffleNodePcgsBasedOnHstry_Algo.xlsx) which should be 90112';
					var expITitles = _List_fromArray(
						['90112', '90121', '90122', '90221', '90222']);
					var failMsg = 'Expected smuid to be one of ' + A2($elm$core$String$join, ', ', expITitles);
					var isSuccessFn = function (vSel) {
						return A2(
							$elm$core$List$member,
							$author$project$Page$Mjd5$extractITitleMaybe(vSel),
							A2($elm$core$List$map, $elm$core$Maybe$Just, expITitles));
					};
					return A2(
						$elm_explorations$test$Test$test,
						_Utils_ap(
							successMsg,
							$author$project$Page$Mjd5$remoteVariantSelectionToVidString(res)),
						function (_v1) {
							return A4($author$project$Page$Mjd5$makeExpectationBody, successMsg, failMsg, isSuccessFn, res);
						});
				}),
			aj: A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Platform$Cmd$none,
				A2(
					$elm$core$Maybe$map,
					function (aMeta) {
						return $author$project$JsInterop$Plumbing$sendInfoOutside(
							$author$project$JsInterop$Plumbing$PursBundleInterop(
								A6(
									$author$project$JsInterop$Plumbing$OpGetVariantSelection,
									29,
									node,
									aMeta,
									2,
									_List_fromArray(
										[hItem1]),
									$elm$core$Maybe$Just(458))));
					},
					aMetaMaybe))
		},
			{
			aW: F2(
				function (preStr, res) {
					var successMsg = preStr + 'Now with history of [ 90111,  90211, 90212 ], with getting some wrong, should \npick again from mjdtest:b1 which should be one of the 90121.';
					var expITitles = _List_fromArray(
						['90311', '90321']);
					var failMsg = 'Expected smuid to be one of ' + A2($elm$core$String$join, ', ', expITitles);
					var isSuccessFn = function (vSel) {
						return A2(
							$elm$core$List$member,
							$author$project$Page$Mjd5$extractITitleMaybe(vSel),
							A2($elm$core$List$map, $elm$core$Maybe$Just, expITitles));
					};
					return A2(
						$elm_explorations$test$Test$test,
						_Utils_ap(
							successMsg,
							$author$project$Page$Mjd5$remoteVariantSelectionToVidString(res)),
						function (_v2) {
							return A4($author$project$Page$Mjd5$makeExpectationBody, successMsg, failMsg, isSuccessFn, res);
						});
				}),
			aj: A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Platform$Cmd$none,
				A2(
					$elm$core$Maybe$map,
					function (aMeta) {
						return $author$project$JsInterop$Plumbing$sendInfoOutside(
							$author$project$JsInterop$Plumbing$PursBundleInterop(
								A6(
									$author$project$JsInterop$Plumbing$OpGetVariantSelection,
									29,
									node,
									aMeta,
									2,
									_List_fromArray(
										[hItem1, hItem2, hItem3]),
									$elm$core$Maybe$Nothing)));
					},
					aMetaMaybe))
		}
		]);
};
var $author$project$Page$Mjd5$testGroupC = function (node) {
	var baseTest = function () {
		var nodeIdMaybe = $author$project$Page$Mjd5$localLookupNodeIdForTitleMaybe('mjdtest');
		var nodeIdL2Maybe = $author$project$Page$Mjd5$localLookupNodeIdForTitleMaybe('mjdtest:b3:l1');
		var nodeIdL1Maybe = $author$project$Page$Mjd5$localLookupNodeIdForTitleMaybe('mjdtest:b2:l1');
		var aMetaMaybe = A4(
			$elm$core$Maybe$map3,
			F3(
				function (nodeId, nodeIdL1, nodeIdL2) {
					return {
						kL: 1,
						lA: 0,
						l3: 450,
						mZ: _List_fromArray(
							[
								{mY: nodeIdL1, ih: 51},
								{mY: nodeIdL2, ih: 49}
							]),
						oR: nodeId
					};
				}),
			nodeIdMaybe,
			nodeIdL1Maybe,
			nodeIdL2Maybe);
		return {
			aW: F2(
				function (preStr, res) {
					var successMsg = preStr + 'pick one of two possible titles (10 variants each) with a Practice context \nin the b3l1 90% and b3l2 10% leaf nodes ';
					var expITitles = _List_fromArray(
						['90211', '90311']);
					var failMsg = 'Expected smuid to be one of ' + A2($elm$core$String$join, ', ', expITitles);
					var isSuccessFn = function (vSel) {
						return A2(
							$elm$core$List$member,
							$author$project$Page$Mjd5$extractITitleMaybe(vSel),
							A2($elm$core$List$map, $elm$core$Maybe$Just, expITitles));
					};
					return A2(
						$elm_explorations$test$Test$test,
						_Utils_ap(
							successMsg,
							$author$project$Page$Mjd5$remoteVariantSelectionToVidString(res)),
						function (_v0) {
							return A4($author$project$Page$Mjd5$makeExpectationBody, successMsg, failMsg, isSuccessFn, res);
						});
				}),
			aj: A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Platform$Cmd$none,
				A2(
					$elm$core$Maybe$map,
					function (aMeta) {
						return $author$project$JsInterop$Plumbing$sendInfoOutside(
							$author$project$JsInterop$Plumbing$PursBundleInterop(
								A6($author$project$JsInterop$Plumbing$OpGetVariantSelection, 29, node, aMeta, 1, _List_Nil, $elm$core$Maybe$Nothing)));
					},
					aMetaMaybe))
		};
	}();
	return A2($elm$core$List$repeat, 100, baseTest);
};
var $author$project$UITool$Calculator$appendDecimal = F2(
	function (model, string) {
		return model.b1 ? string : (string + '.');
	});
var $author$project$UITool$Calculator$dot = function (model) {
	var displayStr = (!$elm$core$String$isEmpty(model.r)) ? A2($author$project$UITool$Calculator$appendDecimal, model, model.r) : '0.';
	return _Utils_update(
		model,
		{b1: true, r: displayStr, bB: true});
};
var $author$project$UITool$Calculator$OnDragBy = function (a) {
	return {$: 6, a: a};
};
var $zaboco$elm_draggable$Draggable$Config = $elm$core$Basics$identity;
var $zaboco$elm_draggable$Internal$defaultConfig = {
	m7: function (_v0) {
		return $elm$core$Maybe$Nothing;
	},
	m8: function (_v1) {
		return $elm$core$Maybe$Nothing;
	},
	m9: $elm$core$Maybe$Nothing,
	na: function (_v2) {
		return $elm$core$Maybe$Nothing;
	},
	nc: function (_v3) {
		return $elm$core$Maybe$Nothing;
	}
};
var $zaboco$elm_draggable$Draggable$basicConfig = function (onDragByListener) {
	var defaultConfig = $zaboco$elm_draggable$Internal$defaultConfig;
	return _Utils_update(
		defaultConfig,
		{
			m8: A2($elm$core$Basics$composeL, $elm$core$Maybe$Just, onDragByListener)
		});
};
var $author$project$UITool$Calculator$dragConfig = $zaboco$elm_draggable$Draggable$basicConfig($author$project$UITool$Calculator$OnDragBy);
var $dmy$elm_pratt_parser$Pratt$Advanced$constant = F3(
	function (constantParser, e, _v0) {
		return A2(
			$elm$parser$Parser$Advanced$map,
			$elm$core$Basics$always(e),
			constantParser);
	});
var $dmy$elm_pratt_parser$Pratt$constant = $dmy$elm_pratt_parser$Pratt$Advanced$constant;
var $elm$core$Basics$cos = _Basics_cos;
var $elm$core$Basics$pi = _Basics_pi;
var $elm$core$Basics$degrees = function (angleInDegrees) {
	return (angleInDegrees * $elm$core$Basics$pi) / 180;
};
var $dmy$elm_pratt_parser$Pratt$Advanced$Config = $elm$core$Basics$identity;
var $dmy$elm_pratt_parser$Pratt$Advanced$filter = F3(
	function (_v0, currentPrecedence, leftExpression) {
		var precedence = _v0.a;
		var parser = _v0.b;
		return (_Utils_cmp(precedence, currentPrecedence) > 0) ? $elm$core$Maybe$Just(
			parser(leftExpression)) : $elm$core$Maybe$Nothing;
	});
var $dmy$elm_pratt_parser$Pratt$Advanced$operation = F3(
	function (config, precedence, leftExpression) {
		var conf = config;
		return $elm$parser$Parser$Advanced$oneOf(
			A2(
				$elm$core$List$filterMap,
				function (toOperation) {
					return A3(
						$dmy$elm_pratt_parser$Pratt$Advanced$filter,
						toOperation(config),
						precedence,
						leftExpression);
				},
				conf.jX));
	});
var $dmy$elm_pratt_parser$Pratt$Advanced$expressionHelp = function (_v0) {
	var config = _v0.a;
	var conf = config;
	var precedence = _v0.b;
	var leftExpression = _v0.c;
	return A2(
		$elm$parser$Parser$Advanced$keeper,
		A2(
			$elm$parser$Parser$Advanced$ignorer,
			$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
			conf.ot),
		$elm$parser$Parser$Advanced$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$Advanced$map,
					function (expr) {
						return $elm$parser$Parser$Advanced$Loop(
							_Utils_Tuple3(config, precedence, expr));
					},
					A3($dmy$elm_pratt_parser$Pratt$Advanced$operation, config, precedence, leftExpression)),
					$elm$parser$Parser$Advanced$succeed(
					$elm$parser$Parser$Advanced$Done(leftExpression))
				])));
};
var $elm$parser$Parser$Advanced$lazy = function (thunk) {
	return function (s) {
		var _v0 = thunk(0);
		var parse = _v0;
		return parse(s);
	};
};
var $dmy$elm_pratt_parser$Pratt$Advanced$subExpression = F2(
	function (precedence, config) {
		var conf = config;
		return A2(
			$elm$parser$Parser$Advanced$andThen,
			function (leftExpression) {
				return A2(
					$elm$parser$Parser$Advanced$loop,
					_Utils_Tuple3(config, precedence, leftExpression),
					$dmy$elm_pratt_parser$Pratt$Advanced$expressionHelp);
			},
			A2(
				$elm$parser$Parser$Advanced$keeper,
				A2(
					$elm$parser$Parser$Advanced$ignorer,
					$elm$parser$Parser$Advanced$succeed($elm$core$Basics$identity),
					conf.ot),
				$elm$parser$Parser$Advanced$lazy(
					function (_v0) {
						return $elm$parser$Parser$Advanced$oneOf(
							A2(
								$elm$core$List$map,
								$elm$core$Basics$apR(config),
								conf.ng));
					})));
	});
var $dmy$elm_pratt_parser$Pratt$Advanced$expression = function (config) {
	return A2(
		$dmy$elm_pratt_parser$Pratt$Advanced$subExpression,
		0,
		{jX: config.jX, ng: config.ng, ot: config.ot});
};
var $dmy$elm_pratt_parser$Pratt$expression = $dmy$elm_pratt_parser$Pratt$Advanced$expression;
var $elm$parser$Parser$ExpectingFloat = {$: 5};
var $elm$parser$Parser$Advanced$float = F2(
	function (expecting, invalid) {
		return $elm$parser$Parser$Advanced$number(
			{
				gm: $elm$core$Result$Err(invalid),
				g1: expecting,
				g4: $elm$core$Result$Ok($elm$core$Basics$identity),
				hh: $elm$core$Result$Err(invalid),
				hq: $elm$core$Result$Ok($elm$core$Basics$toFloat),
				mc: invalid,
				hV: $elm$core$Result$Err(invalid)
			});
	});
var $elm$parser$Parser$float = A2($elm$parser$Parser$Advanced$float, $elm$parser$Parser$ExpectingFloat, $elm$parser$Parser$ExpectingFloat);
var $dmy$elm_pratt_parser$Pratt$Advanced$infixHelp = F4(
	function (_v0, operator, apply, config) {
		var leftPrecedence = _v0.a;
		var rightPrecedence = _v0.b;
		return _Utils_Tuple2(
			leftPrecedence,
			function (left) {
				return A2(
					$elm$parser$Parser$Advanced$keeper,
					A2(
						$elm$parser$Parser$Advanced$ignorer,
						$elm$parser$Parser$Advanced$succeed(
							apply(left)),
						operator),
					A2($dmy$elm_pratt_parser$Pratt$Advanced$subExpression, rightPrecedence, config));
			});
	});
var $dmy$elm_pratt_parser$Pratt$Advanced$infixLeft = function (precedence) {
	return $dmy$elm_pratt_parser$Pratt$Advanced$infixHelp(
		_Utils_Tuple2(precedence, precedence));
};
var $dmy$elm_pratt_parser$Pratt$infixLeft = $dmy$elm_pratt_parser$Pratt$Advanced$infixLeft;
var $dmy$elm_pratt_parser$Pratt$Advanced$infixRight = function (precedence) {
	return $dmy$elm_pratt_parser$Pratt$Advanced$infixHelp(
		_Utils_Tuple2(precedence, precedence - 1));
};
var $dmy$elm_pratt_parser$Pratt$infixRight = $dmy$elm_pratt_parser$Pratt$Advanced$infixRight;
var $elm$parser$Parser$ExpectingKeyword = function (a) {
	return {$: 9, a: a};
};
var $elm$parser$Parser$Advanced$keyword = function (_v0) {
	var kwd = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(kwd);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, kwd, s.b, s.n$, s.kB, s.ov);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return (_Utils_eq(newOffset, -1) || (0 <= A3(
			$elm$parser$Parser$Advanced$isSubChar,
			function (c) {
				return $elm$core$Char$isAlphaNum(c) || (c === '_');
			},
			newOffset,
			s.ov))) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{kB: newCol, kL: s.kL, h: s.h, b: newOffset, n$: newRow, ov: s.ov});
	};
};
var $elm$parser$Parser$keyword = function (kwd) {
	return $elm$parser$Parser$Advanced$keyword(
		A2(
			$elm$parser$Parser$Advanced$Token,
			kwd,
			$elm$parser$Parser$ExpectingKeyword(kwd)));
};
var $dmy$elm_pratt_parser$Pratt$Advanced$literal = $elm$core$Basics$always;
var $dmy$elm_pratt_parser$Pratt$literal = $dmy$elm_pratt_parser$Pratt$Advanced$literal;
var $dmy$elm_pratt_parser$Pratt$subExpression = $dmy$elm_pratt_parser$Pratt$Advanced$subExpression;
var $author$project$Type$MathStringParser$parenthesizedExpression = function (config) {
	return A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			$elm$parser$Parser$symbol('(')),
		A2(
			$elm$parser$Parser$ignorer,
			A2($dmy$elm_pratt_parser$Pratt$subExpression, 0, config),
			$elm$parser$Parser$symbol(')')));
};
var $dmy$elm_pratt_parser$Pratt$Advanced$postfix = F4(
	function (precedence, operator, apply, _v0) {
		return _Utils_Tuple2(
			precedence,
			function (left) {
				return A2(
					$elm$parser$Parser$Advanced$map,
					function (_v1) {
						return apply(left);
					},
					operator);
			});
	});
var $dmy$elm_pratt_parser$Pratt$postfix = $dmy$elm_pratt_parser$Pratt$Advanced$postfix;
var $dmy$elm_pratt_parser$Pratt$Advanced$prefix = F4(
	function (precedence, operator, apply, config) {
		return A2(
			$elm$parser$Parser$Advanced$keeper,
			A2(
				$elm$parser$Parser$Advanced$ignorer,
				$elm$parser$Parser$Advanced$succeed(apply),
				operator),
			A2($dmy$elm_pratt_parser$Pratt$Advanced$subExpression, precedence, config));
	});
var $dmy$elm_pratt_parser$Pratt$prefix = $dmy$elm_pratt_parser$Pratt$Advanced$prefix;
var $elm$parser$Parser$Advanced$spaces = $elm$parser$Parser$Advanced$chompWhile(
	function (c) {
		return (c === ' ') || ((c === '\n') || (c === '\r'));
	});
var $elm$parser$Parser$spaces = $elm$parser$Parser$Advanced$spaces;
var $author$project$Type$MathStringParser$mathExpression = $dmy$elm_pratt_parser$Pratt$expression(
	{
		jX: _List_fromArray(
			[
				A3(
				$dmy$elm_pratt_parser$Pratt$infixLeft,
				1,
				$elm$parser$Parser$symbol('+'),
				$elm$core$Basics$add),
				A3(
				$dmy$elm_pratt_parser$Pratt$infixLeft,
				1,
				$elm$parser$Parser$symbol('-'),
				$elm$core$Basics$sub),
				A3(
				$dmy$elm_pratt_parser$Pratt$infixLeft,
				2,
				$elm$parser$Parser$symbol('*'),
				$elm$core$Basics$mul),
				A3(
				$dmy$elm_pratt_parser$Pratt$infixLeft,
				2,
				$elm$parser$Parser$symbol('/'),
				$elm$core$Basics$fdiv),
				A3(
				$dmy$elm_pratt_parser$Pratt$infixRight,
				4,
				$elm$parser$Parser$symbol('^'),
				$elm$core$Basics$pow),
				A3(
				$dmy$elm_pratt_parser$Pratt$postfix,
				6,
				$elm$parser$Parser$symbol('°'),
				$elm$core$Basics$degrees)
			]),
		ng: _List_fromArray(
			[
				$dmy$elm_pratt_parser$Pratt$literal($elm$parser$Parser$float),
				A2(
				$dmy$elm_pratt_parser$Pratt$constant,
				$elm$parser$Parser$keyword('pi'),
				$elm$core$Basics$pi),
				A3(
				$dmy$elm_pratt_parser$Pratt$prefix,
				3,
				$elm$parser$Parser$symbol('-'),
				$elm$core$Basics$negate),
				A3(
				$dmy$elm_pratt_parser$Pratt$prefix,
				5,
				$elm$parser$Parser$keyword('cos'),
				$elm$core$Basics$cos),
				$author$project$Type$MathStringParser$parenthesizedExpression
			]),
		ot: $elm$parser$Parser$spaces
	});
var $author$project$Type$MathStringParser$math = A2(
	$elm$parser$Parser$keeper,
	$elm$parser$Parser$succeed($elm$core$Basics$identity),
	A2($elm$parser$Parser$ignorer, $author$project$Type$MathStringParser$mathExpression, $elm$parser$Parser$end));
var $author$project$Type$MathStringParser$eval = function (mathStr) {
	return A2($elm$parser$Parser$run, $author$project$Type$MathStringParser$math, mathStr);
};
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $myrho$elm_round$Round$addSign = F2(
	function (signed, str) {
		var isNotZero = A2(
			$elm$core$List$any,
			function (c) {
				return (c !== '0') && (c !== '.');
			},
			$elm$core$String$toList(str));
		return _Utils_ap(
			(signed && isNotZero) ? '-' : '',
			str);
	});
var $myrho$elm_round$Round$increaseNum = function (_v0) {
	var head = _v0.a;
	var tail = _v0.b;
	if (head === '9') {
		var _v1 = $elm$core$String$uncons(tail);
		if (_v1.$ === 1) {
			return '01';
		} else {
			var headtail = _v1.a;
			return A2(
				$elm$core$String$cons,
				'0',
				$myrho$elm_round$Round$increaseNum(headtail));
		}
	} else {
		var c = $elm$core$Char$toCode(head);
		return ((c >= 48) && (c < 57)) ? A2(
			$elm$core$String$cons,
			$elm$core$Char$fromCode(c + 1),
			tail) : '0';
	}
};
var $elm$core$Basics$isInfinite = _Basics_isInfinite;
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elm$core$String$reverse = _String_reverse;
var $myrho$elm_round$Round$splitComma = function (str) {
	var _v0 = A2($elm$core$String$split, '.', str);
	if (_v0.b) {
		if (_v0.b.b) {
			var before = _v0.a;
			var _v1 = _v0.b;
			var after = _v1.a;
			return _Utils_Tuple2(before, after);
		} else {
			var before = _v0.a;
			return _Utils_Tuple2(before, '0');
		}
	} else {
		return _Utils_Tuple2('0', '0');
	}
};
var $myrho$elm_round$Round$toDecimal = function (fl) {
	var _v0 = A2(
		$elm$core$String$split,
		'e',
		$elm$core$String$fromFloat(
			$elm$core$Basics$abs(fl)));
	if (_v0.b) {
		if (_v0.b.b) {
			var num = _v0.a;
			var _v1 = _v0.b;
			var exp = _v1.a;
			var e = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A2($elm$core$String$startsWith, '+', exp) ? A2($elm$core$String$dropLeft, 1, exp) : exp));
			var _v2 = $myrho$elm_round$Round$splitComma(num);
			var before = _v2.a;
			var after = _v2.b;
			var total = _Utils_ap(before, after);
			var zeroed = (e < 0) ? A2(
				$elm$core$Maybe$withDefault,
				'0',
				A2(
					$elm$core$Maybe$map,
					function (_v3) {
						var a = _v3.a;
						var b = _v3.b;
						return a + ('.' + b);
					},
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$mapFirst($elm$core$String$fromChar),
						$elm$core$String$uncons(
							_Utils_ap(
								A2(
									$elm$core$String$repeat,
									$elm$core$Basics$abs(e),
									'0'),
								total))))) : A3($elm$core$String$padRight, e + 1, '0', total);
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				zeroed);
		} else {
			var num = _v0.a;
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				num);
		}
	} else {
		return '';
	}
};
var $myrho$elm_round$Round$roundFun = F3(
	function (functor, s, fl) {
		if ($elm$core$Basics$isInfinite(fl) || $elm$core$Basics$isNaN(fl)) {
			return $elm$core$String$fromFloat(fl);
		} else {
			var signed = fl < 0;
			var _v0 = $myrho$elm_round$Round$splitComma(
				$myrho$elm_round$Round$toDecimal(
					$elm$core$Basics$abs(fl)));
			var before = _v0.a;
			var after = _v0.b;
			var r = $elm$core$String$length(before) + s;
			var normalized = _Utils_ap(
				A2($elm$core$String$repeat, (-r) + 1, '0'),
				A3(
					$elm$core$String$padRight,
					r,
					'0',
					_Utils_ap(before, after)));
			var totalLen = $elm$core$String$length(normalized);
			var roundDigitIndex = A2($elm$core$Basics$max, 1, r);
			var increase = A2(
				functor,
				signed,
				A3($elm$core$String$slice, roundDigitIndex, totalLen, normalized));
			var remains = A3($elm$core$String$slice, 0, roundDigitIndex, normalized);
			var num = increase ? $elm$core$String$reverse(
				A2(
					$elm$core$Maybe$withDefault,
					'1',
					A2(
						$elm$core$Maybe$map,
						$myrho$elm_round$Round$increaseNum,
						$elm$core$String$uncons(
							$elm$core$String$reverse(remains))))) : remains;
			var numLen = $elm$core$String$length(num);
			var numZeroed = (num === '0') ? num : ((s <= 0) ? _Utils_ap(
				num,
				A2(
					$elm$core$String$repeat,
					$elm$core$Basics$abs(s),
					'0')) : ((_Utils_cmp(
				s,
				$elm$core$String$length(after)) < 0) ? (A3($elm$core$String$slice, 0, numLen - s, num) + ('.' + A3($elm$core$String$slice, numLen - s, numLen, num))) : _Utils_ap(
				before + '.',
				A3($elm$core$String$padRight, s, '0', after))));
			return A2($myrho$elm_round$Round$addSign, signed, numZeroed);
		}
	});
var $myrho$elm_round$Round$round = $myrho$elm_round$Round$roundFun(
	F2(
		function (signed, str) {
			var _v0 = $elm$core$String$uncons(str);
			if (_v0.$ === 1) {
				return false;
			} else {
				if ('5' === _v0.a.a) {
					if (_v0.a.b === '') {
						var _v1 = _v0.a;
						return !signed;
					} else {
						var _v2 = _v0.a;
						return true;
					}
				} else {
					var _v3 = _v0.a;
					var _int = _v3.a;
					return function (i) {
						return ((i > 53) && signed) || ((i >= 53) && (!signed));
					}(
						$elm$core$Char$toCode(_int));
				}
			}
		}));
var $author$project$UITool$Calculator$equal = function (model) {
	if ($elm$core$String$isEmpty(model.r)) {
		return model;
	} else {
		var resStringMaybe = A2(
			$elm$core$Maybe$map,
			$elm$core$String$fromFloat,
			A2(
				$elm$core$Maybe$andThen,
				$elm$core$String$toFloat,
				A2(
					$elm$core$Maybe$map,
					$myrho$elm_round$Round$round(12),
					$elm$core$Result$toMaybe(
						$author$project$Type$MathStringParser$eval(model.r)))));
		return _Utils_update(
			model,
			{
				r: A2($elm$core$Maybe$withDefault, 'Error', resStringMaybe)
			});
	}
};
var $zaboco$elm_draggable$Cmd$Extra$message = function (x) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$identity,
		$elm$core$Task$succeed(x));
};
var $zaboco$elm_draggable$Cmd$Extra$optionalMessage = function (msgMaybe) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Platform$Cmd$none,
		A2($elm$core$Maybe$map, $zaboco$elm_draggable$Cmd$Extra$message, msgMaybe));
};
var $zaboco$elm_draggable$Internal$Dragging = function (a) {
	return {$: 2, a: a};
};
var $zaboco$elm_draggable$Internal$DraggingTentative = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $zaboco$elm_draggable$Internal$distanceTo = F2(
	function (end, start) {
		return _Utils_Tuple2(end.jv - start.jv, end.pS - start.pS);
	});
var $zaboco$elm_draggable$Internal$updateAndEmit = F3(
	function (config, msg, drag) {
		var _v0 = _Utils_Tuple2(drag, msg);
		_v0$5:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					if (!_v0.b.$) {
						var _v1 = _v0.a;
						var _v2 = _v0.b;
						var key = _v2.a;
						var initialPosition = _v2.b;
						return _Utils_Tuple2(
							A2($zaboco$elm_draggable$Internal$DraggingTentative, key, initialPosition),
							config.nc(key));
					} else {
						break _v0$5;
					}
				case 1:
					switch (_v0.b.$) {
						case 1:
							var _v3 = _v0.a;
							var key = _v3.a;
							var oldPosition = _v3.b;
							return _Utils_Tuple2(
								$zaboco$elm_draggable$Internal$Dragging(oldPosition),
								config.na(key));
						case 2:
							var _v4 = _v0.a;
							var key = _v4.a;
							var _v5 = _v0.b;
							return _Utils_Tuple2(
								$zaboco$elm_draggable$Internal$NotDragging,
								config.m7(key));
						default:
							break _v0$5;
					}
				default:
					switch (_v0.b.$) {
						case 1:
							var oldPosition = _v0.a.a;
							var newPosition = _v0.b.a;
							return _Utils_Tuple2(
								$zaboco$elm_draggable$Internal$Dragging(newPosition),
								config.m8(
									A2($zaboco$elm_draggable$Internal$distanceTo, newPosition, oldPosition)));
						case 2:
							var _v6 = _v0.b;
							return _Utils_Tuple2($zaboco$elm_draggable$Internal$NotDragging, config.m9);
						default:
							break _v0$5;
					}
			}
		}
		return _Utils_Tuple2(drag, $elm$core$Maybe$Nothing);
	});
var $zaboco$elm_draggable$Draggable$updateDraggable = F3(
	function (_v0, _v1, _v2) {
		var config = _v0;
		var msg = _v1;
		var drag = _v2;
		var _v3 = A3($zaboco$elm_draggable$Internal$updateAndEmit, config, msg, drag);
		var newDrag = _v3.a;
		var newMsgMaybe = _v3.b;
		return _Utils_Tuple2(
			newDrag,
			$zaboco$elm_draggable$Cmd$Extra$optionalMessage(newMsgMaybe));
	});
var $zaboco$elm_draggable$Draggable$update = F3(
	function (config, msg, model) {
		var _v0 = A3($zaboco$elm_draggable$Draggable$updateDraggable, config, msg, model.gX);
		var dragState = _v0.a;
		var dragCmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{gX: dragState}),
			dragCmd);
	});
var $author$project$UITool$Calculator$updateDisplay = F2(
	function (model, _char) {
		var newDisplay = ((model.r === 'Infinity') || (model.r === 'Error')) ? _char : _Utils_ap(model.r, _char);
		return _Utils_update(
			model,
			{r: newDisplay});
	});
var $elm$core$String$endsWith = _String_endsWith;
var $author$project$UITool$Calculator$zero = function (model) {
	var isLastCharZero = $elm$core$String$endsWith('0');
	return $elm$core$String$isEmpty(model.r) ? _Utils_update(
		model,
		{r: '0', bB: false}) : (model.bB ? _Utils_update(
		model,
		{r: model.r + '0'}) : model);
};
var $author$project$UITool$Calculator$update = F2(
	function (msg, model) {
		var updateDisplay_ = F2(
			function (mdl, str) {
				return ((mdl.r === '') || ((mdl.r === 'Infinity') || (mdl.r === 'Error'))) ? mdl : A2($author$project$UITool$Calculator$updateDisplay, mdl, str);
			});
		switch (msg.$) {
			case 0:
				var charItem = msg.a;
				if (charItem.$ === 1) {
					var op = charItem.a;
					var newModel = function () {
						switch (op) {
							case 0:
								return A2(updateDisplay_, model, '/');
							case 1:
								return A2(updateDisplay_, model, '*');
							case 2:
								return A2($author$project$UITool$Calculator$updateDisplay, model, '-');
							case 3:
								return A2(updateDisplay_, model, '+');
							case 4:
								return A2(
									$author$project$UITool$Calculator$updateDisplay,
									_Utils_update(
										model,
										{br: model.br + 1}),
									'(');
							default:
								return (model.br > 0) ? A2(
									$author$project$UITool$Calculator$updateDisplay,
									_Utils_update(
										model,
										{br: model.br - 1}),
									')') : model;
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							newModel,
							{b1: false, bB: true}),
						$elm$core$Platform$Cmd$none);
				} else {
					var digitStr = charItem.a;
					return _Utils_Tuple2(
						A2($author$project$UITool$Calculator$updateDisplay, model, digitStr),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				return _Utils_Tuple2(
					$author$project$UITool$Calculator$equal(model),
					$elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(
					$author$project$UITool$Calculator$dot(model),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						$author$project$UITool$Calculator$init,
						{b1: false, a6: model.a6, bB: true, bb: model.bb}),
					$elm$core$Platform$Cmd$none);
			case 4:
				return _Utils_Tuple2(
					$author$project$UITool$Calculator$zero(model),
					$elm$core$Platform$Cmd$none);
			case 5:
				var mdl = model.a6 ? $author$project$UITool$Calculator$init : _Utils_update(
					model,
					{a6: true});
				return _Utils_Tuple2(mdl, $elm$core$Platform$Cmd$none);
			case 6:
				var _v3 = msg.a;
				var dx = _v3.a;
				var dy = _v3.b;
				var _v4 = model.bb;
				var x = _v4.a;
				var y = _v4.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bb: _Utils_Tuple2(
								$elm$core$Basics$round(x + dx),
								$elm$core$Basics$round(y + dy))
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var dragMsg = msg.a;
				return A3($zaboco$elm_draggable$Draggable$update, $author$project$UITool$Calculator$dragConfig, dragMsg, model);
		}
	});
var $author$project$Page$Mjd5$update = F2(
	function (msg, model) {
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				switch (msg.$) {
					case 11:
						var msgToolCalc = msg.a;
						return A3(
							$elm$core$Tuple$mapBoth,
							function (m2) {
								return _Utils_update(
									model,
									{je: m2});
							},
							$elm$core$Platform$Cmd$map($author$project$Page$Mjd5$MsgToolCalc),
							A2($author$project$UITool$Calculator$update, msgToolCalc, model.je));
					case 7:
						return _Utils_Tuple2(
							model,
							$elm$http$Http$get(
								{
									lp: A2($elm$http$Http$expectJson, $author$project$Page$Mjd5$OnGetPost, $author$project$Type$Post$postDecoder),
									py: 'https://sm-explore-apd2.s3-ap-southeast-2.amazonaws.com/posts/100504.json'
								}));
					case 10:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					case 8:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					case 9:
						var getVariantSelectionResponse = msg.a;
						var currTestMaybe = A2(
							$elm$core$Maybe$andThen,
							$elm$core$List$head,
							A2(
								$elm$core$Maybe$map,
								$elm$core$List$filter(
									function (el) {
										return _Utils_eq(el.cn, $krisajenkins$remotedata$RemoteData$Loading);
									}),
								model.aI));
						var updatedTests = A2(
							$elm$core$Maybe$map,
							$elm$core$List$map(
								function (el) {
									return _Utils_eq(
										$elm$core$Maybe$Just(el.cm),
										A2(
											$elm$core$Maybe$map,
											function ($) {
												return $.cm;
											},
											currTestMaybe)) ? _Utils_update(
										el,
										{
											cn: $krisajenkins$remotedata$RemoteData$Success(getVariantSelectionResponse)
										}) : el;
								}),
							model.aI);
						return $author$project$Page$Mjd5$runNextTestIfApplicable(
							_Utils_update(
								model,
								{aI: updatedTests}));
					case 1:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{aI: $elm$core$Maybe$Nothing}),
							$elm$core$Platform$Cmd$none);
					case 2:
						var modelWithTests = _Utils_update(
							model,
							{
								aI: $elm$core$Maybe$Just(
									$elm$core$List$concat(
										_List_fromArray(
											[
												A2(
												$author$project$Page$Mjd5$initializeTests,
												'A_',
												$author$project$Page$Mjd5$testGroupA(rState.mX.mW)),
												A2(
												$author$project$Page$Mjd5$initializeTests,
												'B_',
												$author$project$Page$Mjd5$testGroupB(rState.mX.mW)),
												A2(
												$author$project$Page$Mjd5$initializeTests,
												'C_',
												$author$project$Page$Mjd5$testGroupC(rState.mX.mW))
											])))
							});
						return $author$project$Page$Mjd5$runNextTestIfApplicable(modelWithTests);
					case 3:
						return _Utils_Tuple2(
							model,
							$author$project$JsInterop$Plumbing$sendInfoOutside($author$project$JsInterop$Plumbing$Me));
					case 4:
						return _Utils_Tuple2(
							model,
							$author$project$JsInterop$Plumbing$sendInfoOutside($author$project$JsInterop$Plumbing$Me20));
					case 6:
						var err = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									fF: $elm$core$Maybe$Just(
										$author$project$JsInterop$Types$msgForUserToString(err.hG))
								}),
							$elm$core$Platform$Cmd$none);
					case 5:
						var link = msg.a;
						return _Utils_Tuple2(
							model,
							$author$project$Ports$pushUrl(link));
					default:
						return _Utils_Tuple2(
							model,
							$author$project$JsInterop$Plumbing$sendInfoOutside($author$project$JsInterop$Plumbing$SignOut));
				}
			});
	});
var $author$project$JsInterop$Plumbing$DeleteLoginUserGQL = function (a) {
	return {$: 12, a: a};
};
var $author$project$JsInterop$Plumbing$DeleteStudentHistoryGQL = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$MutateUsers$MutateRequestSent = {$: 1};
var $author$project$Page$MutateUsers$MutateResponse = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$MutateUsers$MutateUsers = {$: 0};
var $author$project$Page$MutateUsers$MutationCompleted = 2;
var $author$project$Page$MutateUsers$MutationInitiated = 1;
var $author$project$JsInterop$Plumbing$UpdateStudentGQL = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$MutateUsers$setMutationState = F3(
	function (personEsWithMutationState, id, mutationState) {
		return A2(
			$elm$core$List$map,
			function (el) {
				return _Utils_eq(
					$author$project$Type$User$extractPersonEDetails(el.a9).lW,
					id) ? _Utils_update(
					el,
					{bG: mutationState}) : el;
			},
			personEsWithMutationState);
	});
var $author$project$Page$MutateUsers$update = F3(
	function (msg, model, graphQLOps) {
		update:
		while (true) {
			switch (msg.$) {
				case 10:
					var otherAssignableTeachers = msg.a;
					var assignedTeacher = A3($krisajenkins$remotedata$RemoteData$unwrap, $elm$core$Maybe$Nothing, $elm$core$List$head, otherAssignableTeachers);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{a0: assignedTeacher, cU: otherAssignableTeachers}),
						$elm$core$Platform$Cmd$none);
				case 11:
					var assignedTeacherId = msg.a;
					var assignedTeacher = function () {
						var _v1 = model.cU;
						if (_v1.$ === 3) {
							var assignableTeachers = _v1.a;
							return $elm$core$List$head(
								A2(
									$elm$core$List$filter,
									function (el) {
										return _Utils_eq(
											A2(
												$elm$core$Basics$composeR,
												$author$project$Type$User$extractPersonEDetailsFromTeacherE,
												function ($) {
													return $.lW;
												})(el),
											assignedTeacherId);
									},
									assignableTeachers));
						} else {
							return $elm$core$Maybe$Nothing;
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{a0: assignedTeacher}),
						$elm$core$Platform$Cmd$none);
				case 7:
					var isGroupTagToChange = !model.a4;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{a4: isGroupTagToChange}),
						$elm$core$Platform$Cmd$none);
				case 8:
					var isCohortToChange = !model.aT;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aT: isCohortToChange}),
						$elm$core$Platform$Cmd$none);
				case 9:
					var isAssignedTeacherToChange = !model.a3;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{a3: isAssignedTeacherToChange}),
						$elm$core$Platform$Cmd$none);
				case 5:
					var groupTag = msg.a;
					var tag = $elm$core$String$isEmpty(
						$elm$core$String$trim(groupTag)) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(groupTag);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{cM: tag}),
						$elm$core$Platform$Cmd$none);
				case 6:
					var cohort = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{kA: cohort}),
						$elm$core$Platform$Cmd$none);
				case 0:
					var nextPersonEToMutateMaybe = $elm$core$List$head(
						A2(
							$elm$core$List$map,
							function ($) {
								return $.a9;
							},
							A2(
								$elm$core$List$filter,
								function (el) {
									return _Utils_eq(el.bG, $author$project$Page$MutateUsers$MutateRequestNotYetSent);
								},
								model.F)));
					var newPersonEsToMutate = A2(
						$elm$core$Maybe$withDefault,
						model.F,
						A2(
							$elm$core$Maybe$map,
							function (el) {
								return A3(
									$author$project$Page$MutateUsers$setMutationState,
									model.F,
									$author$project$Type$User$extractPersonEDetails(el).lW,
									$author$project$Page$MutateUsers$MutateRequestSent);
							},
							nextPersonEToMutateMaybe));
					var createGraphQLMsgForMutation = function (personE) {
						var _v3 = model.bJ;
						if (!_v3) {
							return $author$project$JsInterop$Plumbing$DeleteLoginUserGQL(
								$author$project$Type$User$extractPersonEDetails(personE).lW);
						} else {
							var updatedPersonE = function () {
								if (!personE.$) {
									var s = personE.a;
									return $author$project$Type$User$StudentE(
										{
											kA: model.aT ? model.kA : s.kA,
											li: s.li,
											lt: s.lt,
											lI: model.a4 ? model.cM : s.lI,
											lU: s.lU,
											lW: s.lW,
											mq: s.mq,
											d$: model.a3 ? A2(
												$elm$core$Maybe$withDefault,
												'errTchId',
												A2(
													$elm$core$Maybe$map,
													A2(
														$elm$core$Basics$composeR,
														$author$project$Type$User$extractPersonEDetailsFromTeacherE,
														function ($) {
															return $.lW;
														}),
													model.a0)) : s.d$,
											n4: s.n4
										});
								} else {
									return personE;
								}
							}();
							return $author$project$JsInterop$Plumbing$UpdateStudentGQL(updatedPersonE);
						}
					};
					var createCmd = function (graphQLMsg) {
						var updateCmd = $author$project$JsInterop$Plumbing$sendInfoOutside(
							A2(
								$author$project$JsInterop$Plumbing$GraphQL,
								graphQLMsg,
								A2($author$project$JsInterop$Plumbing$getGraphQLQuery, graphQLOps, graphQLMsg)));
						if ((graphQLMsg.$ === 10) && (!graphQLMsg.a.$)) {
							var studentEType = graphQLMsg.a.a;
							var eraseResultsHistoryCmd = $author$project$JsInterop$Plumbing$sendInfoOutside(
								A2(
									$author$project$JsInterop$Plumbing$GraphQL,
									$author$project$JsInterop$Plumbing$DeleteStudentHistoryGQL(studentEType.lW),
									A2(
										$author$project$JsInterop$Plumbing$getGraphQLQuery,
										graphQLOps,
										$author$project$JsInterop$Plumbing$DeleteStudentHistoryGQL(studentEType.lW))));
							return model.aT ? $elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[eraseResultsHistoryCmd, updateCmd])) : $elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[updateCmd]));
						} else {
							return updateCmd;
						}
					};
					var nextCmd = A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$Maybe$map,
							createCmd,
							A2($elm$core$Maybe$map, createGraphQLMsgForMutation, nextPersonEToMutateMaybe)));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dQ: _Utils_Tuple2($author$project$Theme$Style$t.eC, $elm$core$Maybe$Nothing),
								o: 1,
								F: newPersonEsToMutate
							}),
						nextCmd);
				case 1:
					var genericLoginUserResponse = msg.a;
					var id = function () {
						if (!genericLoginUserResponse.$) {
							var id_ = genericLoginUserResponse.a;
							return id_;
						} else {
							var id_ = genericLoginUserResponse.a;
							return id_;
						}
					}();
					var newPersonEsToMutate = A3(
						$author$project$Page$MutateUsers$setMutationState,
						model.F,
						id,
						$author$project$Page$MutateUsers$MutateResponse(genericLoginUserResponse));
					var pageStatus = A2(
						$elm$core$List$any,
						function (el) {
							return _Utils_eq(el.bG, $author$project$Page$MutateUsers$MutateRequestNotYetSent);
						},
						newPersonEsToMutate) ? 1 : 2;
					var newModel = _Utils_update(
						model,
						{o: pageStatus, F: newPersonEsToMutate});
					if (pageStatus === 2) {
						return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
					} else {
						var $temp$msg = $author$project$Page$MutateUsers$MutateUsers,
							$temp$model = newModel,
							$temp$graphQLOps = graphQLOps;
						msg = $temp$msg;
						model = $temp$model;
						graphQLOps = $temp$graphQLOps;
						continue update;
					}
				case 2:
					var err = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dQ: _Utils_Tuple2(
									$author$project$Theme$Style$t.eC,
									$elm$core$Maybe$Just(
										$author$project$JsInterop$Types$msgForUserToString(err.hG)))
							}),
						$elm$core$Platform$Cmd$none);
				case 3:
					return _Utils_Tuple2(
						$author$project$Page$MutateUsers$init,
						$author$project$Ports$browserBack(0));
				case 4:
					return _Utils_Tuple2(
						$author$project$Page$MutateUsers$init,
						$author$project$Ports$pushUrl(
							$author$project$Routing$withHash($author$project$Routing$smPaths.pN)));
				case 12:
					var link = msg.a;
					return _Utils_Tuple2(
						model,
						$author$project$Ports$pushUrl(link));
				default:
					return _Utils_Tuple2(
						model,
						$author$project$JsInterop$Plumbing$sendInfoOutside($author$project$JsInterop$Plumbing$SignOut));
			}
		}
	});
var $author$project$Page$Profile$Email = 2;
var $author$project$Page$Profile$FNam = 0;
var $author$project$Page$Profile$LNam = 1;
var $author$project$Page$Profile$NewPassword = 3;
var $author$project$Page$Profile$RepeatPassword = 4;
var $author$project$JsInterop$Plumbing$UpdateLoginUserGQL = F3(
	function (a, b, c) {
		return {$: 11, a: a, b: b, c: c};
	});
var $author$project$Page$Profile$clearDisplayMsgs = function (model) {
	return _Utils_update(
		model,
		{bm: $elm$core$Maybe$Nothing, b3: $elm$core$Maybe$Nothing, aR: $elm$core$Maybe$Nothing, a2: $elm$core$Maybe$Nothing, bs: $elm$core$Maybe$Nothing, bx: $elm$core$Maybe$Nothing, a7: $elm$core$Maybe$Nothing, bC: $elm$core$Maybe$Nothing, cQ: $elm$core$Maybe$Nothing, cW: $elm$core$Maybe$Nothing});
};
var $author$project$Page$Profile$fieldAccessor = function (field) {
	switch (field) {
		case 0:
			return function ($) {
				return $.lt;
			};
		case 1:
			return function ($) {
				return $.mq;
			};
		case 2:
			return function ($) {
				return $.li;
			};
		default:
			return $elm$core$Basics$always('error');
	}
};
var $author$project$Page$Profile$willBeUpdatedString = '* Will be updated.';
var $author$project$Page$Profile$fieldValidator = function (field) {
	var accessor = $author$project$Page$Profile$fieldAccessor(field);
	return $rtfeldman$elm_validate$Validate$firstError(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_validate$Validate$ifFalse,
				function (mdl) {
					return A3(
						$krisajenkins$remotedata$RemoteData$unwrap,
						true,
						function (me_) {
							return _Utils_eq(
								A2($elm$core$Basics$composeR, $author$project$Type$User$extractPersonEDetails, accessor)(me_),
								accessor(mdl));
						},
						mdl.H);
				},
				_Utils_Tuple2(field, $author$project$Page$Profile$willBeUpdatedString))
			]));
};
var $author$project$Page$Profile$isUserProtected = function (user) {
	return A3(
		$krisajenkins$remotedata$RemoteData$unwrap,
		false,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Type$User$extractPersonEDetails,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.li;
				},
				function (el) {
					return (el === 'smadmin1@smartermaths.com.au') || (el === 'smadmin@smartermaths.com.au');
				})),
		user);
};
var $author$project$Page$Profile$modelValidator = function (isDoPasswordValidations) {
	return $rtfeldman$elm_validate$Validate$firstError(
		A2(
			$elm$core$List$append,
			isDoPasswordValidations ? A2(
				$author$project$Page$PasswordChangeCommon$newPasswordValidations,
				function ($) {
					return $.cf;
				},
				3) : _List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_validate$Validate$ifBlank,
					function ($) {
						return $.lt;
					},
					_Utils_Tuple2(0, 'Your first name cannot be blank.')),
					A2(
					$rtfeldman$elm_validate$Validate$ifBlank,
					function ($) {
						return $.mq;
					},
					_Utils_Tuple2(1, 'Your last name cannot be blank.')),
					A2(
					$rtfeldman$elm_validate$Validate$ifBlank,
					function ($) {
						return $.li;
					},
					_Utils_Tuple2(2, 'Your email cannot be blank.')),
					A2(
					$rtfeldman$elm_validate$Validate$ifInvalidEmail,
					function ($) {
						return $.li;
					},
					function (_v0) {
						return _Utils_Tuple2(2, 'Please enter a valid email address.');
					}),
					A2(
					$rtfeldman$elm_validate$Validate$ifTrue,
					function (model) {
						return ($elm$core$String$length(model.cf) > 0) && (!_Utils_eq(model.cf, model.dU));
					},
					_Utils_Tuple2(4, 'Passwords do not match.'))
				])));
};
var $author$project$Page$Profile$resetPageMsgArea = function (model) {
	return _Utils_update(
		model,
		{
			ay: $krisajenkins$remotedata$RemoteData$NotAsked,
			dQ: _Utils_Tuple2($author$project$Theme$Style$t.eC, $elm$core$Maybe$Nothing),
			aK: $krisajenkins$remotedata$RemoteData$NotAsked
		});
};
var $author$project$Page$Profile$update = F3(
	function (msg, model, graphQLOps) {
		switch (msg.$) {
			case 3:
				var loginUserResponse = msg.a;
				if (!loginUserResponse.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ay: $krisajenkins$remotedata$RemoteData$Success(0),
								cf: '',
								dU: ''
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					if (!loginUserResponse.b.$) {
						var ferr = loginUserResponse.b.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									ay: $krisajenkins$remotedata$RemoteData$Failure(
										'Failed to update: ' + ($author$project$JsInterop$Types$msgForUserToString(ferr.hG) + ('. ' + ferr.gU)))
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						var ferr = loginUserResponse.b.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									ay: $krisajenkins$remotedata$RemoteData$Failure(
										'Failed to update: ' + ($author$project$JsInterop$Types$msgForUserToString(ferr.hG) + ('. ' + ferr.gU)))
								}),
							$elm$core$Platform$Cmd$none);
					}
				}
			case 16:
				var nbrStudents = msg.a;
				var msgAreaText = 'There is/are ' + ($elm$core$String$fromInt(nbrStudents) + ' student(s) currently assigned to this teacher.  You must first delete or re-assign all students before the teacher can be deleted.  (You can delete or re-assign students for any teacher from the \'Manage Students\' tab on the home screen)');
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dQ: _Utils_Tuple2(
								$author$project$Theme$Style$t.eC,
								$elm$core$Maybe$Just(msgAreaText))
						}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var personEs = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{d2: personEs}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var teacherId = msg.a;
				var assignedTeacherIdInfoMsg = A3(
					$krisajenkins$remotedata$RemoteData$unwrap,
					false,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Type$User$extractPersonEDetails,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.d$;
							},
							$elm$core$Basics$neq(teacherId))),
					model.H) ? $elm$core$Maybe$Just($author$project$Page$Profile$willBeUpdatedString) : $elm$core$Maybe$Nothing;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{db: teacherId, bm: assignedTeacherIdInfoMsg}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var assignableTeachers = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{da: assignableTeachers}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var isAdmin = msg.a;
				var isAdminInfoMsg = _Utils_eq(
					$author$project$Type$User$isRemoteMeAnAdminE(model.H),
					isAdmin) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just($author$project$Page$Profile$willBeUpdatedString);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dE: isAdmin, bx: isAdminInfoMsg}),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 2:
				var personResponse = msg.a;
				var model_ = $author$project$Page$Profile$clearDisplayMsgs(model);
				if (!personResponse.$) {
					var assignee = personResponse.a;
					var person = $author$project$Type$User$extractPersonEFromAssignee(assignee);
					var mdl = _Utils_update(
						model_,
						{
							H: $krisajenkins$remotedata$RemoteData$Success(person),
							aK: $krisajenkins$remotedata$RemoteData$Success(0)
						});
					return _Utils_Tuple2(mdl, $elm$core$Platform$Cmd$none);
				} else {
					if (!personResponse.a.$) {
						var ferr = personResponse.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model_,
								{
									aK: $krisajenkins$remotedata$RemoteData$Failure(
										'Failed to update: ' + ($author$project$JsInterop$Types$msgForUserToString(ferr.hG) + ('. ' + ferr.gU)))
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						var ferr = personResponse.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model_,
								{
									aK: $krisajenkins$remotedata$RemoteData$Failure(
										'Failed to update: ' + ($author$project$JsInterop$Types$msgForUserToString(ferr.hG) + ('. ' + ferr.gU)))
								}),
							$elm$core$Platform$Cmd$none);
					}
				}
			case 0:
				var updateLoginUserCmd = function () {
					var isNewPasswordChanged = !$elm$core$String$isEmpty(model.cf);
					var isEmailChanged = !_Utils_eq(model.aR, $elm$core$Maybe$Nothing);
					if (!(isEmailChanged || isNewPasswordChanged)) {
						return $elm$core$Platform$Cmd$none;
					} else {
						var passwordMaybe = isNewPasswordChanged ? $elm$core$Maybe$Just(model.cf) : $elm$core$Maybe$Nothing;
						var id = A2(
							$krisajenkins$remotedata$RemoteData$withDefault,
							'error',
							A2(
								$krisajenkins$remotedata$RemoteData$map,
								A2(
									$elm$core$Basics$composeR,
									$author$project$Type$User$extractPersonEDetails,
									function ($) {
										return $.lW;
									}),
								model.H));
						var emailMaybe = isEmailChanged ? $elm$core$Maybe$Just(model.li) : $elm$core$Maybe$Nothing;
						return $author$project$JsInterop$Plumbing$sendInfoOutside(
							A2(
								$author$project$JsInterop$Plumbing$GraphQL,
								A3($author$project$JsInterop$Plumbing$UpdateLoginUserGQL, id, emailMaybe, passwordMaybe),
								A2(
									$author$project$JsInterop$Plumbing$getGraphQLQuery,
									graphQLOps,
									A3($author$project$JsInterop$Plumbing$UpdateLoginUserGQL, '', $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing))));
					}
				}();
				var entityCmd = function () {
					var _v5 = model.H;
					if (_v5.$ === 3) {
						var user_ = _v5.a;
						var user = function () {
							if (user_.$ === 1) {
								var teacherEType = user_.a;
								var personDetails = function (p) {
									return _Utils_update(
										p,
										{li: model.li, lt: model.lt, mq: model.mq});
								}(
									$author$project$Type$User$extractPersonEDetailsFromTeacherE(teacherEType));
								return model.dE ? $author$project$Type$User$TeacherE(
									$author$project$Type$User$AdminE(personDetails)) : $author$project$Type$User$TeacherE(
									$author$project$Type$User$NonAdminE(personDetails));
							} else {
								var p = user_.a;
								return $author$project$Type$User$StudentE(
									_Utils_update(
										p,
										{li: model.li, lt: model.lt, mq: model.mq, d$: model.db}));
							}
						}();
						return A2(
							$elm$core$List$all,
							$elm$core$Basics$eq($elm$core$Maybe$Nothing),
							_List_fromArray(
								[model.bs, model.bC, model.aR, model.bx, model.bm])) ? $elm$core$Platform$Cmd$none : ($author$project$Type$User$isStudentE(user) ? $author$project$JsInterop$Plumbing$sendInfoOutside(
							A2(
								$author$project$JsInterop$Plumbing$GraphQL,
								$author$project$JsInterop$Plumbing$UpdateStudentGQL(user),
								A2(
									$author$project$JsInterop$Plumbing$getGraphQLQuery,
									graphQLOps,
									$author$project$JsInterop$Plumbing$UpdateStudentGQL(user)))) : $author$project$JsInterop$Plumbing$sendInfoOutside(
							A2(
								$author$project$JsInterop$Plumbing$GraphQL,
								$author$project$JsInterop$Plumbing$UpdateTeacherGQL(user),
								A2(
									$author$project$JsInterop$Plumbing$getGraphQLQuery,
									graphQLOps,
									$author$project$JsInterop$Plumbing$UpdateTeacherGQL(user)))));
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}();
				var _v3 = A2(
					$rtfeldman$elm_validate$Validate$validate,
					$author$project$Page$Profile$modelValidator(
						$elm$core$String$length(model.cf) > 0),
					model);
				if (!_v3.$) {
					var loginUserRemoteDataStatus = _Utils_eq(updateLoginUserCmd, $elm$core$Platform$Cmd$none) ? model.ay : $krisajenkins$remotedata$RemoteData$Loading;
					var _v4 = _Utils_eq(entityCmd, $elm$core$Platform$Cmd$none) ? _Utils_Tuple2(model.H, model.aK) : _Utils_Tuple2($krisajenkins$remotedata$RemoteData$Loading, $krisajenkins$remotedata$RemoteData$Loading);
					var userRemoteDataStatus = _v4.a;
					var userDetailsUpdateFlagStatus = _v4.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ay: loginUserRemoteDataStatus,
								dQ: _Utils_Tuple2($author$project$Theme$Style$t.eC, $elm$core$Maybe$Nothing),
								H: userRemoteDataStatus,
								aK: userDetailsUpdateFlagStatus
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[entityCmd, updateLoginUserCmd])));
				} else {
					var errs = _v3.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								b3: A2($author$project$Page$SignInPagesCommon$errorMsgMaybe, 2, errs),
								a2: A2($author$project$Page$SignInPagesCommon$errorMsgMaybe, 0, errs),
								a7: A2($author$project$Page$SignInPagesCommon$errorMsgMaybe, 1, errs),
								cQ: A2($author$project$Page$SignInPagesCommon$errorMsgMaybe, 3, errs),
								cW: A2($author$project$Page$SignInPagesCommon$errorMsgMaybe, 4, errs)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 4:
				var fNam_ = msg.a;
				var newModel = function (el) {
					var _v7 = A2(
						$rtfeldman$elm_validate$Validate$validate,
						$author$project$Page$Profile$fieldValidator(0),
						el);
					if (!_v7.$) {
						return _Utils_update(
							el,
							{bs: $elm$core$Maybe$Nothing});
					} else {
						var errs = _v7.a;
						return _Utils_update(
							el,
							{
								bs: A2($author$project$Page$SignInPagesCommon$errorMsgMaybe, 0, errs)
							});
					}
				}(
					$author$project$Page$Profile$resetPageMsgArea(
						_Utils_update(
							model,
							{lt: fNam_, a2: $elm$core$Maybe$Nothing})));
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			case 5:
				var lNam_ = msg.a;
				var newModel = function (el) {
					var _v8 = A2(
						$rtfeldman$elm_validate$Validate$validate,
						$author$project$Page$Profile$fieldValidator(1),
						el);
					if (!_v8.$) {
						return _Utils_update(
							el,
							{bC: $elm$core$Maybe$Nothing});
					} else {
						var errs = _v8.a;
						return _Utils_update(
							el,
							{
								bC: A2($author$project$Page$SignInPagesCommon$errorMsgMaybe, 1, errs)
							});
					}
				}(
					$author$project$Page$Profile$resetPageMsgArea(
						_Utils_update(
							model,
							{mq: lNam_, a7: $elm$core$Maybe$Nothing})));
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			case 6:
				var email_ = msg.a;
				var newModel = $author$project$Page$Profile$isUserProtected(model.H) ? _Utils_update(
					model,
					{
						aR: $elm$core$Maybe$Just('Never change this email/password unless also change amplify server functions AND redeploy!')
					}) : function (el) {
					var _v9 = A2(
						$rtfeldman$elm_validate$Validate$validate,
						$author$project$Page$Profile$fieldValidator(2),
						el);
					if (!_v9.$) {
						return _Utils_update(
							el,
							{aR: $elm$core$Maybe$Nothing});
					} else {
						var errs = _v9.a;
						return _Utils_update(
							el,
							{
								aR: A2($author$project$Page$SignInPagesCommon$errorMsgMaybe, 2, errs)
							});
					}
				}(
					$author$project$Page$Profile$resetPageMsgArea(
						_Utils_update(
							model,
							{li: email_, b3: $elm$core$Maybe$Nothing})));
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			case 7:
				var newPassword_ = msg.a;
				return A2(
					$author$project$Page$PasswordChangeCommon$updateSetNewPassword,
					$author$project$Page$Profile$resetPageMsgArea(model),
					newPassword_);
			case 8:
				var repeatPassword_ = msg.a;
				return A2(
					$author$project$Page$PasswordChangeCommon$updateSetRepeatPassword,
					$author$project$Page$Profile$resetPageMsgArea(model),
					repeatPassword_);
			case 9:
				return $author$project$Page$PasswordChangeCommon$updateToggleIsShowPassword(model);
			case 10:
				var err = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							dQ: _Utils_Tuple2(
								$author$project$Theme$Style$t.eC,
								$elm$core$Maybe$Just(
									$author$project$JsInterop$Types$msgForUserToString(err.hG)))
						}),
					$elm$core$Platform$Cmd$none);
			case 11:
				return _Utils_Tuple2(
					model,
					$author$project$Ports$browserBack(0));
			case 17:
				var link = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Ports$pushUrl(link));
			default:
				return _Utils_Tuple2(
					model,
					$author$project$JsInterop$Plumbing$sendInfoOutside($author$project$JsInterop$Plumbing$SignOut));
		}
	});
var $author$project$Page$ReportCard$update = F3(
	function (msg, model, graphQLOps) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					$author$project$Ports$browserBack(0));
			case 1:
				var link = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Ports$pushUrl(link));
			default:
				return _Utils_Tuple2(
					model,
					$author$project$JsInterop$Plumbing$sendInfoOutside($author$project$JsInterop$Plumbing$SignOut));
		}
	});
var $author$project$Page$Sample$update = F2(
	function (msg, model) {
		if (!msg) {
			return _Utils_Tuple2(model + 1, $elm$core$Platform$Cmd$none);
		} else {
			return _Utils_Tuple2(model - 1, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$ShowVariant$OnRndForGetNextVariantSelection = function (a) {
	return {$: 16, a: a};
};
var $author$project$Page$ShowVariant$getQuestionNbr = function (mdl) {
	return $elm$core$List$length(mdl.oe) + 1;
};
var $author$project$Type$Syllabus$getSyllabusById = F2(
	function (syllabuses, id) {
		return $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.lW;
					},
					$elm$core$Basics$eq(id)),
				syllabuses));
	});
var $author$project$Page$ShowVariant$extractNextQuestionFailureDetails = F2(
	function (mdl, nodeCfg) {
		var activity = mdl.f8;
		var qNbrStr = $elm$core$String$fromInt(
			$author$project$Page$ShowVariant$getQuestionNbr(mdl));
		var actDetails = function () {
			if (!activity.$) {
				var description = activity.a.gT;
				var syllabusId = activity.a.bf;
				var activityMeta = activity.a.cx;
				return {k8: description, bf: syllabusId, oR: activityMeta.oR};
			} else {
				return {k8: 'n/a', bf: 'n/a', oR: 'n/a'};
			}
		}();
		var categoryDesc = A2(
			$elm$core$Maybe$withDefault,
			'n/a',
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.k8;
				},
				A2(
					$author$project$Type$NodeCfg$getCategoryForId,
					$author$project$Type$NodeCfg$getCategories(nodeCfg.fs),
					actDetails.oR)));
		var syllabusDesc = A2(
			$elm$core$Maybe$withDefault,
			'n/a',
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.k8;
				},
				A2($author$project$Type$Syllabus$getSyllabusById, nodeCfg.oJ, actDetails.bf)));
		var _v0 = function () {
			var _v1 = mdl.ab;
			if ((!_v1.$) && (_v1.a.b.$ === 1)) {
				var _v2 = _v1.a;
				var targetStrike = _v2.a.oS;
				var nodePercentages = _v2.a.mZ;
				var _v3 = _v2.b;
				return _Utils_Tuple2(
					$elm$core$String$fromInt(targetStrike),
					nodePercentages);
			} else {
				return _Utils_Tuple2('n/a', _List_Nil);
			}
		}();
		var targetStrikeStr = _v0.a;
		var nodePcs = _v0.b;
		return {
			ef: actDetails.k8,
			eg: actDetails.oR,
			ez: categoryDesc,
			fl: A2(
				$elm$core$String$join,
				', ',
				A2(
					$elm$core$List$map,
					function (_v4) {
						var nodeId = _v4.mY;
						var pc = _v4.ih;
						return nodeId + (': ' + ($elm$core$String$fromInt(pc) + '%'));
					},
					nodePcs)),
			fu: qNbrStr,
			fS: syllabusDesc,
			bf: actDetails.bf,
			fU: targetStrikeStr
		};
	});
var $elm$random$Random$Generator = $elm$core$Basics$identity;
var $elm$random$Random$Seed = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$random$Random$next = function (_v0) {
	var state0 = _v0.a;
	var incr = _v0.b;
	return A2($elm$random$Random$Seed, ((state0 * 1664525) + incr) >>> 0, incr);
};
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $elm$random$Random$peel = function (_v0) {
	var state = _v0.a;
	var word = (state ^ (state >>> ((state >>> 28) + 4))) * 277803737;
	return ((word >>> 22) ^ word) >>> 0;
};
var $elm$random$Random$float = F2(
	function (a, b) {
		return function (seed0) {
			var seed1 = $elm$random$Random$next(seed0);
			var range = $elm$core$Basics$abs(b - a);
			var n1 = $elm$random$Random$peel(seed1);
			var n0 = $elm$random$Random$peel(seed0);
			var lo = (134217727 & n1) * 1.0;
			var hi = (67108863 & n0) * 1.0;
			var val = ((hi * 134217728.0) + lo) / 9007199254740992.0;
			var scaled = (val * range) + a;
			return _Utils_Tuple2(
				scaled,
				$elm$random$Random$next(seed1));
		};
	});
var $elm$random$Random$Generate = $elm$core$Basics$identity;
var $elm$random$Random$initialSeed = function (x) {
	var _v0 = $elm$random$Random$next(
		A2($elm$random$Random$Seed, 0, 1013904223));
	var state1 = _v0.a;
	var incr = _v0.b;
	var state2 = (state1 + x) >>> 0;
	return $elm$random$Random$next(
		A2($elm$random$Random$Seed, state2, incr));
};
var $elm$random$Random$init = A2(
	$elm$core$Task$andThen,
	function (time) {
		return $elm$core$Task$succeed(
			$elm$random$Random$initialSeed(
				$elm$time$Time$posixToMillis(time)));
	},
	$elm$time$Time$now);
var $elm$random$Random$step = F2(
	function (_v0, seed) {
		var generator = _v0;
		return generator(seed);
	});
var $elm$random$Random$onEffects = F3(
	function (router, commands, seed) {
		if (!commands.b) {
			return $elm$core$Task$succeed(seed);
		} else {
			var generator = commands.a;
			var rest = commands.b;
			var _v1 = A2($elm$random$Random$step, generator, seed);
			var value = _v1.a;
			var newSeed = _v1.b;
			return A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$random$Random$onEffects, router, rest, newSeed);
				},
				A2($elm$core$Platform$sendToApp, router, value));
		}
	});
var $elm$random$Random$onSelfMsg = F3(
	function (_v0, _v1, seed) {
		return $elm$core$Task$succeed(seed);
	});
var $elm$random$Random$map = F2(
	function (func, _v0) {
		var genA = _v0;
		return function (seed0) {
			var _v1 = genA(seed0);
			var a = _v1.a;
			var seed1 = _v1.b;
			return _Utils_Tuple2(
				func(a),
				seed1);
		};
	});
var $elm$random$Random$cmdMap = F2(
	function (func, _v0) {
		var generator = _v0;
		return A2($elm$random$Random$map, func, generator);
	});
_Platform_effectManagers['Random'] = _Platform_createManager($elm$random$Random$init, $elm$random$Random$onEffects, $elm$random$Random$onSelfMsg, $elm$random$Random$cmdMap);
var $elm$random$Random$command = _Platform_leaf('Random');
var $elm$random$Random$generate = F2(
	function (tagger, generator) {
		return $elm$random$Random$command(
			A2($elm$random$Random$map, tagger, generator));
	});
var $author$project$Helper$TargetStrikeCalculator$calcNextTargetStrike = F6(
	function (isMoveQuickly, min, max, sessionQNbr, isCorrect, _v0) {
		var targetStrike = _v0.oS;
		var incrementModifier = ((max - min) / 50) | 0;
		var increment = incrementModifier + (isMoveQuickly ? ((sessionQNbr <= 1) ? 0 : ((sessionQNbr <= 4) ? 25 : ((sessionQNbr <= 8) ? 20 : ((sessionQNbr <= 12) ? 10 : 5)))) : 3);
		var baseStrike = targetStrike;
		return A3(
			$elm$core$Basics$clamp,
			min,
			max,
			isCorrect ? (baseStrike + $elm$core$Basics$round(0.5 * increment)) : (baseStrike - $elm$core$Basics$round(0.5 * increment)));
	});
var $author$project$Type$VariantSeenHistory$getDifficultyMax = function (variantSeenHistory) {
	return $elm$core$List$maximum(
		A2(
			$elm$core$List$map,
			function ($) {
				return $.dp;
			},
			variantSeenHistory));
};
var $author$project$Type$VariantSeenHistory$getDifficultyMin = function (variantSeenHistory) {
	return $elm$core$List$minimum(
		A2(
			$elm$core$List$filterMap,
			function (el) {
				return (el > 110) ? $elm$core$Maybe$Just(el) : $elm$core$Maybe$Nothing;
			},
			A2(
				$elm$core$List$map,
				function ($) {
					return $.dp;
				},
				variantSeenHistory)));
};
var $author$project$NextQuestionEngine$Buckets = F5(
	function (a, b, c, d, e) {
		return {$: 0, a: a, b: b, c: c, d: d, e: e};
	});
var $author$project$Type$VariantSeenHistory$filterByUsageTag = F2(
	function (usageTag, variantSeenHistory) {
		return A2(
			$elm$core$List$filter,
			function (v) {
				return _Utils_eq(v.aX, usageTag);
			},
			variantSeenHistory);
	});
var $elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $author$project$NextQuestionEngine$pickFromListWithRnd = F2(
	function (rnd_, items) {
		var len = $elm$core$List$length(items);
		var idx = $elm$core$Basics$round(rnd_ * (len - 1));
		return A2($elm_community$list_extra$List$Extra$getAt, idx, items);
	});
var $author$project$NextQuestionEngine$findNode = F2(
	function (scaledRnd, nodePercentages) {
		var cumulativeList = A3(
			$elm$core$List$foldl,
			F2(
				function (np, acc) {
					return _Utils_ap(
						acc,
						_List_fromArray(
							[
								$elm$core$List$sum(acc) + np.ih
							]));
				}),
			_List_Nil,
			nodePercentages);
		var combinedList = A3($elm$core$List$map2, $elm$core$Tuple$pair, cumulativeList, nodePercentages);
		return A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.mY;
			},
			A2(
				$elm$core$Maybe$map,
				$elm$core$Tuple$second,
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (_v0) {
							var cumulative = _v0.a;
							return _Utils_cmp(cumulative, scaledRnd) > 0;
						},
						combinedList))));
	});
var $author$project$NextQuestionEngine$toRandomizedNode = F3(
	function (nodePercentages_, rnd_, items) {
		var scaledRnd = $elm$core$Basics$round(rnd_ * 100);
		var pickedNode = A2($author$project$NextQuestionEngine$findNode, scaledRnd, nodePercentages_);
		var filterToInCategory = function (categoryId) {
			return A2(
				$elm$core$List$filter,
				function (item) {
					return A2($elm$core$List$member, categoryId, item.ks);
				},
				items);
		};
		return A2(
			$elm$core$Maybe$withDefault,
			items,
			A2($elm$core$Maybe$map, filterToInCategory, pickedNode));
	});
var $author$project$NextQuestionEngine$pickFromList = F3(
	function (nodePercentages, rnd, items) {
		var _v0 = A3($author$project$NextQuestionEngine$toRandomizedNode, nodePercentages, rnd, items);
		if (!_v0.b) {
			return A2($author$project$NextQuestionEngine$pickFromListWithRnd, rnd, items);
		} else {
			if (!_v0.b.b) {
				var el_ = _v0.a;
				return $elm$core$Maybe$Just(el_);
			} else {
				var allEls = _v0;
				return A2($author$project$NextQuestionEngine$pickFromListWithRnd, rnd, allEls);
			}
		}
	});
var $author$project$NextQuestionEngine$toDiffRange = F3(
	function (min, max, items) {
		return A2(
			$elm$core$List$filter,
			function (item) {
				return (_Utils_cmp(item.dp, min) > -1) && (_Utils_cmp(item.dp, max) < 1);
			},
			items);
	});
var $author$project$NextQuestionEngine$getNextVariantInternal = F6(
	function (maxDiffTolerance, diffTolerance, nodePercentages, variantSeenHistory, targetStrike, rnd) {
		getNextVariantInternal:
		while (true) {
			var toBuckets = function (variantSeenHistory_) {
				return A5(
					$author$project$NextQuestionEngine$Buckets,
					A2($author$project$Type$VariantSeenHistory$filterByUsageTag, 3, variantSeenHistory_),
					A2($author$project$Type$VariantSeenHistory$filterByUsageTag, 4, variantSeenHistory_),
					A2($author$project$Type$VariantSeenHistory$filterByUsageTag, 5, variantSeenHistory_),
					A2($author$project$Type$VariantSeenHistory$filterByUsageTag, 6, variantSeenHistory_),
					A2($author$project$Type$VariantSeenHistory$filterByUsageTag, 7, variantSeenHistory_));
			};
			var makeVariantSelection = F2(
				function (selectionType, vExtendedMaybe) {
					return _Utils_Tuple2(
						{
							mZ: nodePercentages,
							iO: A2(
								$elm$core$Maybe$withDefault,
								-1,
								A2(
									$elm$core$Maybe$map,
									function ($) {
										return $.dp;
									},
									vExtendedMaybe)),
							iP: selectionType,
							oS: targetStrike
						},
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.pK;
							},
							vExtendedMaybe));
				});
			var _v0 = toBuckets(
				A3($author$project$NextQuestionEngine$toDiffRange, targetStrike - diffTolerance, targetStrike + diffTolerance, variantSeenHistory));
			if (!_v0.a.b) {
				if (!_v0.b.b) {
					if (!_v0.c.b) {
						if (!_v0.d.b) {
							if (_Utils_cmp(diffTolerance, maxDiffTolerance) < 0) {
								var $temp$maxDiffTolerance = maxDiffTolerance,
									$temp$diffTolerance = diffTolerance + 3,
									$temp$nodePercentages = nodePercentages,
									$temp$variantSeenHistory = variantSeenHistory,
									$temp$targetStrike = targetStrike,
									$temp$rnd = rnd;
								maxDiffTolerance = $temp$maxDiffTolerance;
								diffTolerance = $temp$diffTolerance;
								nodePercentages = $temp$nodePercentages;
								variantSeenHistory = $temp$variantSeenHistory;
								targetStrike = $temp$targetStrike;
								rnd = $temp$rnd;
								continue getNextVariantInternal;
							} else {
								return A2(makeVariantSelection, 7, $elm$core$Maybe$Nothing);
							}
						} else {
							if (!_v0.d.b.b) {
								var _v1 = _v0.d;
								var el = _v1.a;
								return A2(
									makeVariantSelection,
									6,
									$elm$core$Maybe$Just(el));
							} else {
								var els = _v0.d;
								return A2(
									makeVariantSelection,
									6,
									A3($author$project$NextQuestionEngine$pickFromList, nodePercentages, rnd, els));
							}
						}
					} else {
						if (!_v0.c.b.b) {
							var _v2 = _v0.c;
							var el = _v2.a;
							return A2(
								makeVariantSelection,
								5,
								$elm$core$Maybe$Just(el));
						} else {
							var els = _v0.c;
							return A2(
								makeVariantSelection,
								5,
								A3($author$project$NextQuestionEngine$pickFromList, nodePercentages, rnd, els));
						}
					}
				} else {
					if (!_v0.b.b.b) {
						var _v3 = _v0.b;
						var el = _v3.a;
						return A2(
							makeVariantSelection,
							4,
							$elm$core$Maybe$Just(el));
					} else {
						var els = _v0.b;
						return A2(
							makeVariantSelection,
							4,
							A3($author$project$NextQuestionEngine$pickFromList, nodePercentages, rnd, els));
					}
				}
			} else {
				if (!_v0.a.b.b) {
					var _v4 = _v0.a;
					var el = _v4.a;
					return A2(
						makeVariantSelection,
						3,
						$elm$core$Maybe$Just(el));
				} else {
					var els = _v0.a;
					return A2(
						makeVariantSelection,
						3,
						A3($author$project$NextQuestionEngine$pickFromList, nodePercentages, rnd, els));
				}
			}
		}
	});
var $author$project$NextQuestionEngine$getNextVariant = function (qstnEngineMaxDiffTolerance) {
	return A2($author$project$NextQuestionEngine$getNextVariantInternal, qstnEngineMaxDiffTolerance, 3);
};
var $author$project$NextQuestionEngine$getMostRecentStrikeForActivity = F3(
	function (variantSeenHistory, history_, nodeId) {
		var getMostRecentHistoryItemMaybe = function (els) {
			return A2(
				$elm$core$Maybe$andThen,
				$elm$core$List$head,
				A2(
					$elm$core$Maybe$map,
					$elm$core$List$reverse,
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.ci;
						},
						$elm$core$List$head(
							$elm$core$List$reverse(
								A2(
									$elm$core$List$sortBy,
									function ($) {
										return $.pr;
									},
									els))))));
		};
		var getHistoryItemDifficultyMaybe = function (el) {
			var _v0 = A2(
				$elm$core$List$filter,
				function (el_) {
					return _Utils_eq(el_.pK, el.pK);
				},
				variantSeenHistory);
			if (!_v0.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var difficulty = _v0.a.dp;
				return $elm$core$Maybe$Just(difficulty);
			}
		};
		return A2(
			$elm$core$Maybe$andThen,
			getHistoryItemDifficultyMaybe,
			getMostRecentHistoryItemMaybe(
				A2(
					$elm$core$List$filter,
					function (r) {
						return _Utils_eq(r.jz, nodeId);
					},
					history_)));
	});
var $author$project$NextQuestionEngine$makeInitialSessionSelectionRationale = F4(
	function (act, variantSeenHistory, history, nbrQuestionsMaybe) {
		var mostRecentStrikeForActivityMaybe = A3(
			$author$project$NextQuestionEngine$getMostRecentStrikeForActivity,
			variantSeenHistory,
			$author$project$Type$ExamResult$getActivityResultRecords(history),
			act.lW);
		var initDiff = act.cx.l3;
		var strikeForFirstQuestion = function () {
			var _v0 = act.cx.kL;
			if (!_v0) {
				return A3(
					$elm$core$Basics$clamp,
					initDiff,
					initDiff + 100,
					A2($elm$core$Maybe$withDefault, 0, mostRecentStrikeForActivityMaybe) - 20);
			} else {
				var stepDown = A2(
					$elm$core$Maybe$withDefault,
					0,
					A2(
						$elm$core$Maybe$map,
						function (nbrQuestions) {
							return (nbrQuestions >= 15) ? 20 : 0;
						},
						nbrQuestionsMaybe));
				return A2(
					$elm$core$Maybe$withDefault,
					initDiff,
					A2(
						$elm$core$Maybe$map,
						function (el) {
							return el - stepDown;
						},
						mostRecentStrikeForActivityMaybe));
			}
		}();
		return {mZ: act.cx.mZ, iO: strikeForFirstQuestion, iP: 0, oS: strikeForFirstQuestion};
	});
var $author$project$NextQuestionEngine$getVariantSelection = F9(
	function (qstnEngineMaxDiffTolerance, act, variantSeenHistory, history, actMetricNbrQuestionsMaybe, selectionRationaleMaybe, isVariantAnswerCorrect, qstnNbr, rnd) {
		var targetStrike = function () {
			var priorVariantAnswer = function () {
				if (!selectionRationaleMaybe.$) {
					var selectionRationale = selectionRationaleMaybe.a;
					return selectionRationale;
				} else {
					return A4($author$project$NextQuestionEngine$makeInitialSessionSelectionRationale, act, variantSeenHistory, history, actMetricNbrQuestionsMaybe);
				}
			}();
			var _v0 = A3(
				$elm$core$Tuple$mapBoth,
				$elm$core$Maybe$withDefault(101),
				$elm$core$Maybe$withDefault(1000),
				_Utils_Tuple2(
					$author$project$Type$VariantSeenHistory$getDifficultyMin(variantSeenHistory),
					$author$project$Type$VariantSeenHistory$getDifficultyMax(variantSeenHistory)));
			var minDiff = _v0.a;
			var maxDiff = _v0.b;
			return A3(
				$elm$core$Basics$clamp,
				minDiff,
				maxDiff,
				A6(
					$author$project$Helper$TargetStrikeCalculator$calcNextTargetStrike,
					$elm$core$List$isEmpty(history) && (!act.cx.kL),
					minDiff,
					maxDiff,
					qstnNbr,
					isVariantAnswerCorrect,
					priorVariantAnswer));
		}();
		return A5($author$project$NextQuestionEngine$getNextVariant, qstnEngineMaxDiffTolerance, act.cx.mZ, variantSeenHistory, targetStrike, rnd);
	});
var $author$project$Type$History$makeHistoryItem = F2(
	function (variantId, vAnswerMaybe) {
		return {dW: vAnswerMaybe, pK: variantId};
	});
var $author$project$Type$NodeCfg$toUniqueVariantMetas = function (categoryStructs) {
	return A2(
		$author$project$Helper$Util$toUniqueList,
		A2(
			$elm$core$Basics$composeL,
			$author$project$Type$Variant$toShortStringVariantId,
			function ($) {
				return $.pK;
			}),
		A2(
			$elm$core$List$concatMap,
			function (el) {
				return el.f_;
			},
			categoryStructs));
};
var $author$project$Type$VariantSeenHistory$toVariantIdTuple = function (_v0) {
	var smuid = _v0.oq;
	var vId = _v0.c1;
	return _Utils_Tuple2(smuid, vId);
};
var $author$project$Type$VariantSeenHistory$toVariantMetaExtended = F2(
	function (usageTag, _v0) {
		var variantId = _v0.pK;
		var difficulty = _v0.dp;
		var categoryIds = _v0.ks;
		var tags = _v0.i7;
		return {ks: categoryIds, dp: difficulty, i7: tags, aX: usageTag, pK: variantId};
	});
var $author$project$Type$VariantSeenHistory$updateVariantSeenHistoryInternal = F3(
	function (isUpdateForCurrSess, currVariantId, variantSeenHistory) {
		var updateUsageTag = function (variantMetaExtended) {
			var variantId = variantMetaExtended.pK;
			var usageTag = function () {
				if (isUpdateForCurrSess) {
					var _v0 = _Utils_Tuple2(
						_Utils_eq(currVariantId.oq, variantId.oq),
						_Utils_eq(currVariantId.c1, variantId.c1));
					if (_v0.a) {
						if (_v0.b) {
							return 7;
						} else {
							return (variantMetaExtended.aX === 7) ? 7 : 5;
						}
					} else {
						return variantMetaExtended.aX;
					}
				} else {
					var _v1 = _Utils_Tuple2(
						_Utils_eq(currVariantId.oq, variantId.oq),
						_Utils_eq(currVariantId.c1, variantId.c1));
					if (_v1.a) {
						if (_v1.b) {
							return 6;
						} else {
							return (variantMetaExtended.aX === 6) ? 6 : 4;
						}
					} else {
						return variantMetaExtended.aX;
					}
				}
			}();
			return _Utils_update(
				variantMetaExtended,
				{aX: usageTag});
		};
		return A2($elm$core$List$map, updateUsageTag, variantSeenHistory);
	});
var $author$project$Type$VariantSeenHistory$makeVariantSeenHistoryForActivity = F3(
	function (postMetas, targetNodeId, historyItems) {
		var uniqueHistoryItemVariantIds = A2(
			$author$project$Helper$Util$toUniqueList,
			$author$project$Type$VariantSeenHistory$toVariantIdTuple,
			A2(
				$elm$core$List$map,
				function ($) {
					return $.pK;
				},
				historyItems));
		var initialVariantSeenHistory = A2(
			$elm$core$List$map,
			$author$project$Type$VariantSeenHistory$toVariantMetaExtended(3),
			$author$project$Type$NodeCfg$toUniqueVariantMetas(
				A2(
					$author$project$Type$NodeCfg$makeCategoryStructsForCategories,
					postMetas,
					A2($author$project$Type$NodeCfg$getCategoryLeavesForNodeId, postMetas, targetNodeId))));
		return A3(
			$elm$core$List$foldl,
			$author$project$Type$VariantSeenHistory$updateVariantSeenHistoryInternal(false),
			initialVariantSeenHistory,
			uniqueHistoryItemVariantIds);
	});
var $author$project$Type$VariantSeenHistory$makeVariantSeenHistory = F3(
	function (postMetas, targetNodeId, serrs) {
		var history = A2(
			$elm$core$List$concatMap,
			function ($) {
				return $.ci;
			},
			$author$project$Type$ExamResult$getActivityResultRecords(serrs));
		return A3($author$project$Type$VariantSeenHistory$makeVariantSeenHistoryForActivity, postMetas, targetNodeId, history);
	});
var $author$project$Page$ShowVariant$LogCompleted = function (a) {
	return {$: 17, a: a};
};
var $elm$http$Http$expectString = function (toMsg) {
	return A2(
		$elm$http$Http$expectStringResponse,
		toMsg,
		$elm$http$Http$resolve($elm$core$Result$Ok));
};
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $elm$http$Http$stringBody = _Http_pair;
var $author$project$Page$ShowVariant$sendNumeracyOnlineLog = function (bodyJson) {
	return $elm$http$Http$request(
		{
			kd: A2($elm$http$Http$stringBody, 'application/json', bodyJson),
			lp: $elm$http$Http$expectString($author$project$Page$ShowVariant$LogCompleted),
			lM: _List_fromArray(
				[
					A2($elm$http$Http$header, 'Content-Type', 'application/json'),
					A2($elm$http$Http$header, 'x-api-permission-key', 'a2c84de4-84ea-404e-9980-771ed8c5e5d8')
				]),
			mE: 'POST',
			pd: $elm$core$Maybe$Nothing,
			pq: $elm$core$Maybe$Nothing,
			py: 'https://cl12ndd8ra.execute-api.ap-southeast-2.amazonaws.com/numeracy-online-log'
		});
};
var $author$project$Type$VariantSeenHistory$updateVariantSeenHistory = $author$project$Type$VariantSeenHistory$updateVariantSeenHistoryInternal(true);
var $author$project$Page$ShowVariant$update = F2(
	function (msg, model) {
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				switch (msg.$) {
					case 17:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					case 15:
						var msgToolCalc = msg.a;
						return A3(
							$elm$core$Tuple$mapBoth,
							function (m2) {
								return _Utils_update(
									model,
									{je: m2});
							},
							$elm$core$Platform$Cmd$map($author$project$Page$ShowVariant$MsgToolCalc),
							A2($author$project$UITool$Calculator$update, msgToolCalc, model.je));
					case 6:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					case 13:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ca: true}),
							$elm$core$Platform$Cmd$none);
					case 10:
						var minChangeAmt = model.by ? (-5) : 5;
						var activityMetricMaybe = A2(
							$elm$core$Maybe$map,
							function (el) {
								return _Utils_update(
									el,
									{
										fh: A2(
											$elm$core$Maybe$map,
											$elm$core$Basics$add(minChangeAmt),
											el.fh)
									});
							},
							model.aq);
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{aq: activityMetricMaybe, by: !model.by}),
							$elm$core$Platform$Cmd$none);
					case 11:
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{bz: !model.bz}),
							$elm$core$Platform$Cmd$none);
					case 9:
						var newSessionHistory = function () {
							var _v1 = model.aL;
							if (_v1.$ === 3) {
								var variantContent = _v1.a;
								return _Utils_ap(
									model.oe,
									_List_fromArray(
										[
											A2($author$project$Type$History$makeHistoryItem, variantContent.pK, model.ao)
										]));
							} else {
								return model.oe;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{oe: newSessionHistory}),
							$elm$core$Platform$Cmd$none);
					case 8:
						var newSessionHistory = function () {
							var _v2 = model.aL;
							if (_v2.$ === 3) {
								var variantContent = _v2.a;
								return _Utils_ap(
									model.oe,
									_List_fromArray(
										[
											A2($author$project$Type$History$makeHistoryItem, variantContent.pK, model.ao)
										]));
							} else {
								return model.oe;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ca: false, X: false, oe: newSessionHistory, aL: $krisajenkins$remotedata$RemoteData$Loading}),
							A2(
								$elm$random$Random$generate,
								$author$project$Page$ShowVariant$OnRndForGetNextVariantSelection,
								A2($elm$random$Random$float, 0, 1)));
					case 16:
						var rnd = msg.a;
						var _v3 = _Utils_Tuple2(model.f8, model.cr);
						if ((!_v3.a.$) && (!_v3.b.$)) {
							var act = _v3.a.a;
							var variantSeenHistory = _v3.b.a;
							var variantSelection = A9(
								$author$project$NextQuestionEngine$getVariantSelection,
								rState.fv,
								act,
								variantSeenHistory,
								rState.eZ,
								A2(
									$elm$core$Maybe$map,
									function ($) {
										return $.mV;
									},
									model.aq),
								A2($elm$core$Maybe$map, $elm$core$Tuple$first, model.ab),
								$author$project$Type$History$isVAnswerMaybeCorrect(model.ao),
								$author$project$Page$ShowVariant$getQuestionNbr(model),
								rnd);
							var newModel_ = _Utils_update(
								model,
								{
									ao: $elm$core$Maybe$Nothing,
									ab: $elm$core$Maybe$Just(variantSelection)
								});
							var _v4 = variantSelection.b;
							if (!_v4.$) {
								var variantId = _v4.a;
								return _Utils_Tuple2(
									_Utils_update(
										newModel_,
										{
											cr: $elm$core$Maybe$Just(
												A2($author$project$Type$VariantSeenHistory$updateVariantSeenHistory, variantId, variantSeenHistory))
										}),
									$elm$core$Platform$Cmd$batch(
										_List_fromArray(
											[
												A4(
												$author$project$PortImpl$sendGetEncodedVariantContent,
												rState.lx.pB,
												$author$project$Page$ShowVariant$OnGetEncodedVariantContent,
												rState.lx.pL,
												$author$project$Type$Variant$toShortStringVariantId(variantId))
											])));
							} else {
								var nQF = A2($author$project$Page$ShowVariant$extractNextQuestionFailureDetails, newModel_, rState.mX);
								var _v5 = function () {
									var _v6 = rState.fc;
									if (_v6.$ === 1) {
										if (!_v6.a.$) {
											var personEDetails = _v6.a.a;
											return _Utils_Tuple2(personEDetails.li, personEDetails.d$);
										} else {
											var personEDetails = _v6.a.a;
											return _Utils_Tuple2(personEDetails.li, personEDetails.d$);
										}
									} else {
										var personEDetails = _v6.a;
										return _Utils_Tuple2(personEDetails.li, personEDetails.n4);
									}
								}();
								var userEmail = _v5.a;
								var schoolId = _v5.b;
								return _Utils_Tuple2(
									newModel_,
									$author$project$Page$ShowVariant$sendNumeracyOnlineLog(
										A2(
											$elm$json$Json$Encode$encode,
											0,
											$elm$json$Json$Encode$object(
												_List_fromArray(
													[
														_Utils_Tuple2(
														'messageType',
														$elm$json$Json$Encode$string('NumeracyOnlineQuestionGap')),
														_Utils_Tuple2(
														'syllabus_Description',
														$elm$json$Json$Encode$string(nQF.fS)),
														_Utils_Tuple2(
														'syllabus_Id',
														$elm$json$Json$Encode$string(nQF.bf)),
														_Utils_Tuple2(
														'activity_Description',
														$elm$json$Json$Encode$string(nQF.ef)),
														_Utils_Tuple2(
														'activity_Target_Node_Id',
														$elm$json$Json$Encode$string(nQF.eg)),
														_Utils_Tuple2(
														'category_Description',
														$elm$json$Json$Encode$string(nQF.ez)),
														_Utils_Tuple2(
														'question_Number_Failed_On',
														$elm$json$Json$Encode$string(nQF.fu)),
														_Utils_Tuple2(
														'target_Strike',
														$elm$json$Json$Encode$string(nQF.fU)),
														_Utils_Tuple2(
														'node_Percentages',
														$elm$json$Json$Encode$string(nQF.fl)),
														_Utils_Tuple2(
														'user_Email',
														$elm$json$Json$Encode$string(userEmail)),
														_Utils_Tuple2(
														'user_School_Id',
														$elm$json$Json$Encode$string(schoolId))
													])))));
							}
						} else {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										fF: $elm$core$Maybe$Just('Apologies, but no activity data has, and/or no questions for this activity have been specified (e.g. exam or revision), and we cannot continue.')
									}),
								$elm$core$Platform$Cmd$none);
						}
					case 7:
						var act = msg.a;
						var activityMetric = msg.b;
						var cmd = $elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Ports$pushUrl(
									$author$project$Routing$withHash($author$project$Routing$smPaths.iS)),
									$author$project$Ports$pushUrl(
									$author$project$Routing$withHash($author$project$Routing$smPaths.iS))
								]));
						return _Utils_Tuple2(
							_Utils_update(
								$author$project$Page$ShowVariant$init,
								{
									f8: $elm$core$Maybe$Just(act),
									aq: $elm$core$Maybe$Just(activityMetric),
									X: true,
									cr: $elm$core$Maybe$Just(
										A3($author$project$Type$VariantSeenHistory$makeVariantSeenHistory, rState.mX.fs, act.cx.oR, rState.eZ))
								}),
							cmd);
					case 12:
						var variantSelection = msg.a;
						var _v7 = function () {
							var _v8 = variantSelection.b;
							if (!_v8.$) {
								var variantId = _v8.a;
								var variantIdString = $author$project$Type$Variant$toShortStringVariantId(variantId);
								var cmds_ = $elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A4($author$project$PortImpl$sendGetEncodedVariantContent, rState.lx.pB, $author$project$Page$ShowVariant$OnGetEncodedVariantContent, rState.lx.pL, variantIdString)
										]));
								return _Utils_Tuple2(model, cmds_);
							} else {
								var activityId = A2(
									$elm$core$Maybe$withDefault,
									'Not Available',
									A2(
										$elm$core$Maybe$map,
										function ($) {
											return $.lW;
										},
										model.f8));
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											fF: $elm$core$Maybe$Just(
												'The question engine returned no next question with the following selection rationale: ' + ($author$project$Type$Variant$toStringVariantSelection(variantSelection) + (' ActivityId: ' + (activityId + (' SessionHistory length:' + $elm$core$String$fromInt(
													$elm$core$List$length(model.oe)))))))
										}),
									$elm$core$Platform$Cmd$none);
							}
						}();
						var newModel = _v7.a;
						var cmds = _v7.b;
						return _Utils_Tuple2(
							_Utils_update(
								newModel,
								{
									ab: $elm$core$Maybe$Just(variantSelection)
								}),
							cmds);
					case 3:
						var getEncodedVariantContentResponse = msg.a;
						var encodedVariantContentResponse = A2(
							$krisajenkins$remotedata$RemoteData$mapError,
							$author$project$Helper$Util$httpErrorToString,
							$krisajenkins$remotedata$RemoteData$fromResult(getEncodedVariantContentResponse));
						var serverErrorMsg = function () {
							if (encodedVariantContentResponse.$ === 2) {
								var errMsg = encodedVariantContentResponse.a;
								return $elm$core$Maybe$Just(errMsg);
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}();
						var cmd = function () {
							if (!getEncodedVariantContentResponse.$) {
								var encodedVariantContent = getEncodedVariantContentResponse.a;
								return $author$project$Ports$sendDecryptItem(
									A3($author$project$Type$Decrypt$encodeDecryptRequest, 'payload', 'ShowVariant', encodedVariantContent.c));
							} else {
								return $elm$core$Platform$Cmd$none;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{dv: encodedVariantContentResponse, fF: serverErrorMsg}),
							cmd);
					case 4:
						var decryptItemResponse = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{aL: decryptItemResponse}),
							$elm$core$Platform$Cmd$none);
					case 5:
						var selectedAnswer = msg.a;
						var variantAnswerMaybe = function () {
							switch (selectedAnswer.$) {
								case 0:
									return $elm$core$Maybe$Just(selectedAnswer);
								case 1:
									var _v12 = selectedAnswer.a;
									var idxs = _v12.a;
									return $elm$core$List$isEmpty(idxs) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(selectedAnswer);
								default:
									return $elm$core$Maybe$Just(selectedAnswer);
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ao: variantAnswerMaybe}),
							$elm$core$Platform$Cmd$none);
					case 2:
						var err = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									fF: $elm$core$Maybe$Just(
										$author$project$JsInterop$Types$msgForUserToString(err.hG))
								}),
							$elm$core$Platform$Cmd$none);
					case 1:
						var link = msg.a;
						return _Utils_Tuple2(
							model,
							$author$project$Ports$pushUrl(link));
					case 14:
						return _Utils_Tuple2(
							$author$project$Page$ShowVariant$init,
							$author$project$Ports$pushUrl('/'));
					default:
						return _Utils_Tuple2(
							model,
							$author$project$JsInterop$Plumbing$sendInfoOutside($author$project$JsInterop$Plumbing$SignOut));
				}
			});
	});
var $author$project$Page$ShowWorksheet$OnGetEncodedVariantContent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Type$Variant$variantContentErrorToString = function (_v0) {
	var variantIdMaybe = _v0.fZ;
	var errMsg = _v0.b4;
	return function (el) {
		return el + (' Error msg: ' + errMsg);
	}(
		A2(
			$elm$core$Maybe$withDefault,
			'Unknown variant',
			A2($elm$core$Maybe$map, $author$project$Type$Variant$toStringVariantId, variantIdMaybe)));
};
var $author$project$Page$ShowWorksheet$update = F2(
	function (msg, model) {
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				var neverUsedModelCmd = _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				switch (msg.$) {
					case 4:
						var activityData = msg.a;
						var activityResultRecord = msg.b;
						var isWorksheetBasedOnResults = msg.c;
						var shortVariantIds = A2(
							$elm$core$List$map,
							$author$project$Type$Variant$toShortStringVariantId,
							A2(
								$elm$core$List$map,
								function ($) {
									return $.pK;
								},
								activityResultRecord.ci));
						var cmds = $elm$core$Platform$Cmd$batch(
							A2(
								$elm$core$List$map,
								A3($author$project$PortImpl$sendGetEncodedVariantContent, rState.lx.pB, $author$project$Page$ShowWorksheet$OnGetEncodedVariantContent, rState.lx.pL),
								shortVariantIds));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									c9: $elm$core$Maybe$Just(activityData),
									dG: isWorksheetBasedOnResults,
									ed: $elm$core$Maybe$Just(activityResultRecord)
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										cmds,
										$author$project$Ports$pushUrl(
										$author$project$Routing$withHash($author$project$Routing$smPaths.ok))
									])));
					case 2:
						var getEncodedVariantContentResponse = msg.a;
						var encodedVariantContentResponse = A2(
							$krisajenkins$remotedata$RemoteData$mapError,
							$author$project$Helper$Util$httpErrorToString,
							$krisajenkins$remotedata$RemoteData$fromResult(getEncodedVariantContentResponse));
						var failureItems = function () {
							if (encodedVariantContentResponse.$ === 2) {
								var errMsg = encodedVariantContentResponse.a;
								return A2($elm$core$List$cons, errMsg, model.av);
							} else {
								return model.av;
							}
						}();
						var cmd = function () {
							if (!getEncodedVariantContentResponse.$) {
								var encodedVariantContent = getEncodedVariantContentResponse.a;
								return $author$project$Ports$sendDecryptItem(
									A3($author$project$Type$Decrypt$encodeDecryptRequest, 'payload', 'ShowWorksheet', encodedVariantContent.c));
							} else {
								return $elm$core$Platform$Cmd$none;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{av: failureItems}),
							cmd);
					case 3:
						var decryptItemResponse = msg.a;
						var newModel = function () {
							switch (decryptItemResponse.$) {
								case 3:
									var variantContent = decryptItemResponse.a;
									return _Utils_update(
										model,
										{
											aM: A2($elm$core$List$cons, variantContent, model.aM)
										});
								case 2:
									var variantContentError = decryptItemResponse.a;
									return _Utils_update(
										model,
										{
											av: A2(
												$elm$core$List$cons,
												$author$project$Type$Variant$variantContentErrorToString(variantContentError),
												model.av)
										});
								default:
									return model;
							}
						}();
						return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
					case 1:
						var link = msg.a;
						return _Utils_Tuple2(
							model,
							$author$project$Ports$pushUrl(link));
					case 5:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					default:
						return _Utils_Tuple2(
							model,
							$author$project$JsInterop$Plumbing$sendInfoOutside($author$project$JsInterop$Plumbing$SignOut));
				}
			});
	});
var $author$project$Page$ShowWorksheets$CreateWorksheetFinish = F4(
	function (a, b, c, d) {
		return {$: 5, a: a, b: b, c: c, d: d};
	});
var $author$project$Type$ExamResult$getMostRecentActivityResultRecordItem = function (actrr) {
	return $elm$core$List$head(
		$elm$core$List$reverse(
			A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.pr;
				},
				actrr)));
};
var $author$project$Type$History$isCorrect = function (_v0) {
	var result = _v0.dW;
	return $author$project$Type$History$isVAnswerMaybeCorrect(result);
};
var $author$project$Page$ShowWorksheets$makeTargetDifficulties = F2(
	function (scoreMaybe, nbrQsRequired) {
		var _v0 = function () {
			if (scoreMaybe.$ === 1) {
				return _Utils_Tuple2(460, 580);
			} else {
				var score = scoreMaybe.a;
				return (score < 500) ? _Utils_Tuple2(460, 580) : ((score < 561) ? _Utils_Tuple2(490, 590) : _Utils_Tuple2(530, 630));
			}
		}();
		var low = _v0.a;
		var high = _v0.b;
		var distance = (high - low) / (nbrQsRequired - 1);
		return A2(
			$elm$core$List$map,
			function (el) {
				return $elm$core$Basics$round(low + (el * distance));
			},
			A2(
				$elm$core$List$map,
				$elm$core$Basics$toFloat,
				A2($elm$core$List$range, 0, nbrQsRequired - 1)));
	});
var $author$project$Page$ShowWorksheets$worksheetQstnsLength = 22;
var $author$project$Page$ShowWorksheets$update = F2(
	function (msg, model) {
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				var neverUsedModelCmd = _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				var getNextVariantSelectionCmd = F2(
					function (worksheetItems, targetStrikeMaybe) {
						var sessionQNbr = $elm$core$List$length(worksheetItems) + 1;
						var allHistoryWithCurrSessionAtEnd = A2(
							$elm$core$List$append,
							A2(
								$elm$core$List$concatMap,
								function ($) {
									return $.ci;
								},
								$author$project$Type$ExamResult$getActivityResultRecords(rState.eZ)),
							worksheetItems);
						return A2(
							$elm$core$Maybe$withDefault,
							$elm$core$Platform$Cmd$none,
							A2(
								$elm$core$Maybe$map,
								function (aMeta) {
									return $author$project$JsInterop$Plumbing$sendInfoOutside(
										$author$project$JsInterop$Plumbing$PursBundleInterop(
											A6($author$project$JsInterop$Plumbing$OpGetVariantSelection, 31, rState.mX.mW, aMeta, sessionQNbr, allHistoryWithCurrSessionAtEnd, targetStrikeMaybe)));
								},
								A2(
									$elm$core$Maybe$map,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.f8;
										},
										function ($) {
											return $.cx;
										}),
									model.aZ)));
					});
				switch (msg.$) {
					case 3:
						var activityDataGroup = msg.a;
						var isStudentRequestor = msg.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aZ: $elm$core$Maybe$Just(activityDataGroup),
									cP: isStudentRequestor
								}),
							$author$project$Ports$pushUrl(
								$author$project$Routing$withHash($author$project$Routing$smPaths.ol)));
					case 6:
						var activityData = msg.a;
						var activityResultRecord = msg.b;
						var isWorksheetBasedOnResults = msg.c;
						return neverUsedModelCmd;
					case 4:
						var worksheetType = msg.a;
						var _v1 = model.aZ;
						if (_v1.$ === 1) {
							return neverUsedModelCmd;
						} else {
							var activityDataGroup = _v1.a;
							var newModel = _Utils_update(
								model,
								{aO: _List_Nil});
							var mostRecentOnlineActivityResultRecord = $author$project$Type$ExamResult$getMostRecentActivityResultRecordItem(
								A2($elm$core$List$filter, $author$project$Type$ExamResult$isOnlineActivityResultRecord, activityDataGroup.cy));
							var mostRecentScore = function () {
								var nodeItems = $author$project$Type$NodeCfg$getItems(rState.mX.fs);
								var hItems = A2(
									$elm$core$Maybe$withDefault,
									_List_Nil,
									A2(
										$elm$core$Maybe$map,
										function ($) {
											return $.ci;
										},
										mostRecentOnlineActivityResultRecord));
								return A2($author$project$Type$ExamResult$calculateActivityResultDifficulty, nodeItems, hItems);
							}();
							if (!worksheetType) {
								var mostRecentIncorrects = A2(
									$elm$core$Maybe$withDefault,
									_List_Nil,
									A2(
										$elm$core$Maybe$map,
										function (els) {
											return A2(
												$elm$core$List$filter,
												A2($elm$core$Basics$composeR, $author$project$Type$History$isCorrect, $elm$core$Basics$not),
												els);
										},
										A2(
											$elm$core$Maybe$map,
											function ($) {
												return $.ci;
											},
											mostRecentOnlineActivityResultRecord)));
								if (_Utils_cmp(
									$elm$core$List$length(mostRecentIncorrects),
									$author$project$Page$ShowWorksheets$worksheetQstnsLength) < 0) {
									var reqDiffs = A2(
										$author$project$Page$ShowWorksheets$makeTargetDifficulties,
										mostRecentScore,
										$author$project$Page$ShowWorksheets$worksheetQstnsLength - $elm$core$List$length(mostRecentIncorrects));
									return _Utils_Tuple2(
										_Utils_update(
											newModel,
											{
												cj: reqDiffs,
												aY: $elm$core$Maybe$Just(0),
												aO: mostRecentIncorrects
											}),
										A2(
											getNextVariantSelectionCmd,
											mostRecentIncorrects,
											$elm$core$List$head(reqDiffs)));
								} else {
									return _Utils_Tuple2(
										_Utils_update(
											newModel,
											{
												aY: $elm$core$Maybe$Just(0)
											}),
										A2(
											$elm$core$Task$perform,
											A3(
												$author$project$Page$ShowWorksheets$CreateWorksheetFinish,
												activityDataGroup.f8.lW,
												$author$project$Type$Activity$Offline(0),
												mostRecentIncorrects),
											$elm$time$Time$now));
								}
							} else {
								var reqDiffs = A2($author$project$Page$ShowWorksheets$makeTargetDifficulties, mostRecentScore, $author$project$Page$ShowWorksheets$worksheetQstnsLength);
								return _Utils_Tuple2(
									_Utils_update(
										newModel,
										{
											cj: reqDiffs,
											aY: $elm$core$Maybe$Just(1)
										}),
									A2(
										getNextVariantSelectionCmd,
										newModel.aO,
										$elm$core$List$head(reqDiffs)));
							}
						}
					case 7:
						var _v3 = msg.a;
						var variantIdMaybe = _v3.b;
						if (variantIdMaybe.$ === 1) {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						} else {
							var variantId = variantIdMaybe.a;
							var historyItem = {dW: $elm$core$Maybe$Nothing, pK: variantId};
							if (_Utils_cmp(
								$elm$core$List$length(model.aO),
								$author$project$Page$ShowWorksheets$worksheetQstnsLength - 1) < 0) {
								var worksheetItems = A2(
									$elm$core$List$append,
									model.aO,
									_List_fromArray(
										[historyItem]));
								var _v5 = function () {
									var _v6 = model.cj;
									if (!_v6.b) {
										return _Utils_Tuple2($elm$core$Maybe$Nothing, _List_Nil);
									} else {
										var reqDiff_ = _v6.a;
										var reqDiffs_ = _v6.b;
										return _Utils_Tuple2(
											$elm$core$Maybe$Just(reqDiff_),
											reqDiffs_);
									}
								}();
								var reqDiff = _v5.a;
								var reqDiffs = _v5.b;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{cj: reqDiffs, aO: worksheetItems}),
									A2(getNextVariantSelectionCmd, worksheetItems, reqDiff));
							} else {
								var dedupedHistoryItems = A3(
									$elm$core$List$foldl,
									F2(
										function (el, qs2) {
											return A2($elm$core$List$member, el, qs2) ? qs2 : A2($elm$core$List$cons, el, qs2);
										}),
									_List_Nil,
									A2($elm$core$List$cons, historyItem, model.aO));
								var cmd = A2(
									$elm$core$Maybe$withDefault,
									$elm$core$Platform$Cmd$none,
									A3(
										$elm$core$Maybe$map2,
										F2(
											function (worksheetInProgressType, activityDataGroup) {
												return A2(
													$elm$core$Task$perform,
													A3(
														$author$project$Page$ShowWorksheets$CreateWorksheetFinish,
														activityDataGroup.f8.lW,
														$author$project$Type$Activity$Offline(worksheetInProgressType),
														dedupedHistoryItems),
													$elm$time$Time$now);
											}),
										model.aY,
										model.aZ));
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{aY: $elm$core$Maybe$Nothing}),
									cmd);
							}
						}
					case 5:
						var activityId = msg.a;
						var activityMode = msg.b;
						var qs = msg.c;
						var posix = msg.d;
						return neverUsedModelCmd;
					case 2:
						var err = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									fF: $elm$core$Maybe$Just(
										$author$project$JsInterop$Types$msgForUserToString(err.hG))
								}),
							$elm$core$Platform$Cmd$none);
					case 1:
						var link = msg.a;
						return _Utils_Tuple2(
							model,
							$author$project$Ports$pushUrl(link));
					default:
						return _Utils_Tuple2(
							model,
							$author$project$JsInterop$Plumbing$sendInfoOutside($author$project$JsInterop$Plumbing$SignOut));
				}
			});
	});
var $author$project$Page$SignIn$Email = 0;
var $author$project$Page$SignIn$Password = 1;
var $author$project$JsInterop$Plumbing$SignIn = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Page$SignIn$modelValidator = $rtfeldman$elm_validate$Validate$all(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.li;
			},
			_Utils_Tuple2(0, 'Please enter your email address.')),
			A2(
			$rtfeldman$elm_validate$Validate$ifInvalidEmail,
			function ($) {
				return $.li;
			},
			function (el) {
				return _Utils_Tuple2(0, 'Please enter a valid email address.');
			}),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.bK;
			},
			_Utils_Tuple2(1, 'Please enter a password.'))
		]));
var $author$project$Page$SignIn$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var email = msg.a;
				var password = msg.b;
				var model_ = _Utils_update(
					model,
					{fF: $elm$core$Maybe$Nothing});
				var _v1 = A2($rtfeldman$elm_validate$Validate$validate, $author$project$Page$SignIn$modelValidator, model);
				if (!_v1.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model_,
							{cO: false}),
						$author$project$JsInterop$Plumbing$sendInfoOutside(
							A2($author$project$JsInterop$Plumbing$SignIn, email, password)));
				} else {
					var errs = _v1.a;
					return _Utils_Tuple2(
						_Utils_update(
							model_,
							{
								b3: A2($author$project$Page$SignInPagesCommon$errorMsgMaybe, 0, errs),
								cg: A2($author$project$Page$SignInPagesCommon$errorMsgMaybe, 1, errs)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var email = msg.a;
				var email_ = $elm$core$String$toLower(email);
				return $elm$core$String$isEmpty(email_) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{li: email_, ds: $author$project$Page$SignIn$emailPlaceholderInit}),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					_Utils_update(
						model,
						{li: email_, b3: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var pwd = msg.a;
				return $elm$core$String$isEmpty(pwd) ? _Utils_Tuple2(
					_Utils_update(
						model,
						{bK: pwd, dR: $author$project$Page$SignIn$passwordPlaceholderInit}),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					_Utils_update(
						model,
						{bK: pwd, cg: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var isShow = !model.cN;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cN: isShow}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var err = msg.a;
				var errMsg = function (el) {
					return (el === 'Only radix 2, 4, 8, 16, 32 are supported') ? 'Incorrect username or password.' : el;
				}(
					$author$project$JsInterop$Types$msgForUserToString(err.hG));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cO: true,
							fF: $elm$core$Maybe$Just(errMsg)
						}),
					$elm$core$Platform$Cmd$none);
			default:
				var link = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Ports$pushUrl(link));
		}
	});
var $author$project$Page$StudentHome$GlobalCapture_UpdateTestDataHistory = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$StudentHome$TestData_2_OnGenerateNowTime = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$StudentHome$TestData_3_OnGeneratedRandomValues = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$StudentHome$VariantAcc = F3(
	function (variantSeenHistory, historyItems, selectionRationaleMaybe) {
		return {e_: historyItems, iN: selectionRationaleMaybe, cr: variantSeenHistory};
	});
var $author$project$Type$ExamResult$toQAnswerStatus = function (vAnswerMaybe) {
	if (vAnswerMaybe.$ === 1) {
		return 0;
	} else {
		var vAnswer = vAnswerMaybe.a;
		return $author$project$Type$History$isVAnswerCorrect(vAnswer) ? 2 : 1;
	}
};
var $author$project$Page$StudentHome$makeTestDataSingleExamRunResult = F5(
	function (activityData, nbrQuestions, nowPosix, rnd_VariantSelector, rnd_isCorrects) {
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				var initialVariantSeenHistory = A3($author$project$Type$VariantSeenHistory$makeVariantSeenHistory, rState.mX.fs, activityData.cx.oR, rState.eZ);
				var getVariantSelection_ = A2($author$project$NextQuestionEngine$getVariantSelection, rState.fv, activityData);
				var makeHistoryItemsChain = F2(
					function (isCorrect, _v2) {
						var variantSeenHistory = _v2.cr;
						var historyItems = _v2.e_;
						var selectionRationaleMaybe = _v2.iN;
						var result = $elm$core$Maybe$Just(
							$author$project$Type$History$MCSA(
								_Utils_Tuple2(-1, isCorrect)));
						var qNbr = $elm$core$List$length(historyItems) + 1;
						var isCorrectPriorItem = A2(
							$elm$core$Maybe$withDefault,
							false,
							A2(
								$elm$core$Maybe$map,
								$elm$core$Basics$eq(2),
								A2(
									$elm$core$Maybe$map,
									A2(
										$elm$core$Basics$composeL,
										$author$project$Type$ExamResult$toQAnswerStatus,
										function ($) {
											return $.dW;
										}),
									$elm$core$List$head(
										$elm$core$List$reverse(historyItems)))));
						var _v0 = A7(
							getVariantSelection_,
							variantSeenHistory,
							rState.eZ,
							$elm$core$Maybe$Just(nbrQuestions),
							selectionRationaleMaybe,
							isCorrectPriorItem,
							qNbr,
							rnd_VariantSelector);
						var selectionRationale = _v0.a;
						var variantIdMaybe = _v0.b;
						if (!variantIdMaybe.$) {
							var variantId = variantIdMaybe.a;
							return A3(
								$author$project$Page$StudentHome$VariantAcc,
								A2($author$project$Type$VariantSeenHistory$updateVariantSeenHistory, variantId, variantSeenHistory),
								_Utils_ap(
									historyItems,
									_List_fromArray(
										[
											{dW: result, pK: variantId}
										])),
								$elm$core$Maybe$Just(selectionRationale));
						} else {
							return A3($author$project$Page$StudentHome$VariantAcc, variantSeenHistory, historyItems, $elm$core$Maybe$Nothing);
						}
					});
				var historyItemsGroup = A3(
					$elm$core$List$foldl,
					makeHistoryItemsChain,
					A3($author$project$Page$StudentHome$VariantAcc, initialVariantSeenHistory, _List_Nil, $elm$core$Maybe$Nothing),
					rnd_isCorrects);
				var activityResultRecord = {
					jz: activityData.lW,
					jA: $author$project$Type$Activity$Online(
						$author$project$Type$DateTime$posixToDecSecsString(
							$elm$time$Time$millisToPosix(
								$author$project$Type$DateTime$posixToRoundedSecsInt(nowPosix) - 500))),
					ci: historyItemsGroup.e_,
					pr: $author$project$Type$DateTime$posixToDecSecsString(nowPosix)
				};
				var singleExamRunResult = $author$project$Type$ExamResult$ActivityRunResult(activityResultRecord);
				return singleExamRunResult;
			});
	});
var $elm$random$Random$map2 = F3(
	function (func, _v0, _v1) {
		var genA = _v0;
		var genB = _v1;
		return function (seed0) {
			var _v2 = genA(seed0);
			var a = _v2.a;
			var seed1 = _v2.b;
			var _v3 = genB(seed1);
			var b = _v3.a;
			var seed2 = _v3.b;
			return _Utils_Tuple2(
				A2(func, a, b),
				seed2);
		};
	});
var $elm$random$Random$pair = F2(
	function (genA, genB) {
		return A3(
			$elm$random$Random$map2,
			F2(
				function (a, b) {
					return _Utils_Tuple2(a, b);
				}),
			genA,
			genB);
	});
var $Punie$elm_reader$Reader$reader = A2($elm$core$Basics$composeL, $elm$core$Basics$identity, $elm$core$Basics$always);
var $elm$random$Random$andThen = F2(
	function (callback, _v0) {
		var genA = _v0;
		return function (seed) {
			var _v1 = genA(seed);
			var result = _v1.a;
			var newSeed = _v1.b;
			var _v2 = callback(result);
			var genB = _v2;
			return genB(newSeed);
		};
	});
var $elm$random$Random$constant = function (value) {
	return function (seed) {
		return _Utils_Tuple2(value, seed);
	};
};
var $elm$random$Random$int = F2(
	function (a, b) {
		return function (seed0) {
			var _v0 = (_Utils_cmp(a, b) < 0) ? _Utils_Tuple2(a, b) : _Utils_Tuple2(b, a);
			var lo = _v0.a;
			var hi = _v0.b;
			var range = (hi - lo) + 1;
			if (!((range - 1) & range)) {
				return _Utils_Tuple2(
					(((range - 1) & $elm$random$Random$peel(seed0)) >>> 0) + lo,
					$elm$random$Random$next(seed0));
			} else {
				var threshhold = (((-range) >>> 0) % range) >>> 0;
				var accountForBias = function (seed) {
					accountForBias:
					while (true) {
						var x = $elm$random$Random$peel(seed);
						var seedN = $elm$random$Random$next(seed);
						if (_Utils_cmp(x, threshhold) < 0) {
							var $temp$seed = seedN;
							seed = $temp$seed;
							continue accountForBias;
						} else {
							return _Utils_Tuple2((x % range) + lo, seedN);
						}
					}
				};
				return accountForBias(seed0);
			}
		};
	});
var $elm$core$Array$setHelp = F4(
	function (shift, index, value, tree) {
		var pos = $elm$core$Array$bitMask & (index >>> shift);
		var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
		if (!_v0.$) {
			var subTree = _v0.a;
			var newSub = A4($elm$core$Array$setHelp, shift - $elm$core$Array$shiftStep, index, value, subTree);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$SubTree(newSub),
				tree);
		} else {
			var values = _v0.a;
			var newLeaf = A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, values);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$Leaf(newLeaf),
				tree);
		}
	});
var $elm$core$Array$set = F3(
	function (index, value, array) {
		var len = array.a;
		var startShift = array.b;
		var tree = array.c;
		var tail = array.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? array : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			tree,
			A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, tail)) : A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A4($elm$core$Array$setHelp, startShift, index, value, tree),
			tail));
	});
var $author$project$Page$StudentHome$shuffleValues = function (values) {
	var swapAt = F3(
		function (i, j, array) {
			var _v0 = A2($elm$core$Array$get, i, array);
			if (!_v0.$) {
				var valI = _v0.a;
				var _v1 = A2($elm$core$Array$get, j, array);
				if (!_v1.$) {
					var valJ = _v1.a;
					return A3(
						$elm$core$Array$set,
						j,
						valI,
						A3($elm$core$Array$set, i, valJ, array));
				} else {
					return array;
				}
			} else {
				return array;
			}
		});
	var helper = F2(
		function (currentIndex, array) {
			return (!currentIndex) ? $elm$random$Random$constant(array) : A2(
				$elm$random$Random$andThen,
				function (updatedArray) {
					return A2(helper, currentIndex - 1, updatedArray);
				},
				A2(
					$elm$random$Random$map,
					function (randomIndex) {
						return A3(swapAt, randomIndex, currentIndex, array);
					},
					A2($elm$random$Random$int, 0, currentIndex - 1)));
		});
	return A2(
		helper,
		$elm$core$Array$length(values) - 1,
		values);
};
var $author$project$Helper$Util$toCmdMsg = function (msg_) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$always(msg_),
		$elm$core$Task$succeed(0));
};
var $author$project$Page$StudentHome$update = F2(
	function (msg, model) {
		var neverUsedModelCmd = _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		switch (msg.$) {
			case 13:
				return $Punie$elm_reader$Reader$reader(
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none));
			case 3:
				return $Punie$elm_reader$Reader$reader(neverUsedModelCmd);
			case 5:
				return $Punie$elm_reader$Reader$reader(neverUsedModelCmd);
			case 2:
				var err = msg.a;
				return $Punie$elm_reader$Reader$reader(
					_Utils_Tuple2(
						_Utils_update(
							model,
							{
								fF: $elm$core$Maybe$Just(
									$author$project$JsInterop$Types$msgForUserToString(err.hG))
							}),
						$elm$core$Platform$Cmd$none));
			case 1:
				var link = msg.a;
				return $Punie$elm_reader$Reader$reader(
					_Utils_Tuple2(
						model,
						$author$project$Ports$pushUrl(link)));
			case 12:
				var bias = msg.a;
				return $Punie$elm_reader$Reader$reader(
					_Utils_Tuple2(
						_Utils_update(
							model,
							{bg: bias}),
						$elm$core$Platform$Cmd$none));
			case 4:
				return $Punie$elm_reader$Reader$reader(neverUsedModelCmd);
			case 6:
				var aId = msg.a;
				var nbr = msg.b;
				return $Punie$elm_reader$Reader$reader(
					_Utils_Tuple2(
						_Utils_update(
							model,
							{
								c4: A3($elm$core$Dict$insert, aId, nbr, model.c4)
							}),
						$elm$core$Platform$Cmd$none));
			case 7:
				var aId = msg.a;
				return $Punie$elm_reader$Reader$reader(
					function () {
						var isShow = _Utils_eq(
							A2($elm$core$Dict$get, aId, model.cb),
							$elm$core$Maybe$Just(true));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									cb: A3($elm$core$Dict$insert, aId, !isShow, model.cb)
								}),
							$elm$core$Platform$Cmd$none);
					}());
			case 8:
				var activityData = msg.a;
				var nbrQuestions = msg.b;
				var bias = msg.c;
				return $Punie$elm_reader$Reader$reader(
					_Utils_Tuple2(
						_Utils_update(
							model,
							{
								d3: $elm$core$Maybe$Just(activityData),
								bg: bias,
								bO: nbrQuestions
							}),
						A2($elm$core$Task$perform, $author$project$Page$StudentHome$TestData_2_OnGenerateNowTime, $elm$time$Time$now)));
			case 9:
				var nowPosix = msg.a;
				var initialValues = function () {
					var firstHalf = $elm$core$Basics$round(model.bO * model.bg);
					var lastHalf = model.bO - firstHalf;
					return A2(
						$elm$core$Array$append,
						A2($elm$core$Array$repeat, lastHalf, false),
						A2($elm$core$Array$repeat, firstHalf, true));
				}();
				var shuffledValues = $author$project$Page$StudentHome$shuffleValues(initialValues);
				return $Punie$elm_reader$Reader$reader(
					_Utils_Tuple2(
						_Utils_update(
							model,
							{bH: nowPosix}),
						A2(
							$elm$random$Random$generate,
							$author$project$Page$StudentHome$TestData_3_OnGeneratedRandomValues,
							A2(
								$elm$random$Random$pair,
								A2($elm$random$Random$float, 0, 1),
								shuffledValues))));
			case 10:
				var _v1 = msg.a;
				var rnd_VariantSelector = _v1.a;
				var rnd_isCorrects = _v1.b;
				var _v2 = model.d3;
				if (!_v2.$) {
					var activityData = _v2.a;
					return A2(
						$Punie$elm_reader$Reader$map,
						function (serr) {
							return _Utils_Tuple2(
								model,
								$author$project$Helper$Util$toCmdMsg(
									$author$project$Page$StudentHome$GlobalCapture_UpdateTestDataHistory(serr)));
						},
						A5(
							$author$project$Page$StudentHome$makeTestDataSingleExamRunResult,
							activityData,
							model.bO,
							model.bH,
							rnd_VariantSelector,
							$elm$core$Array$toList(rnd_isCorrects)));
				} else {
					return $Punie$elm_reader$Reader$reader(neverUsedModelCmd);
				}
			case 11:
				return $Punie$elm_reader$Reader$reader(neverUsedModelCmd);
			default:
				return $Punie$elm_reader$Reader$reader(
					_Utils_Tuple2(
						model,
						$author$project$JsInterop$Plumbing$sendInfoOutside($author$project$JsInterop$Plumbing$SignOut)));
		}
	});
var $author$project$Update$retrieveCurrentQuestionAnswerDetailsFromModel = function (model) {
	return A2(
		$elm$core$Maybe$andThen,
		function (el) {
			return A2(
				$elm$core$Maybe$map,
				function (el1) {
					return _Utils_Tuple2(el, el1);
				},
				A2($elm$core$Array$get, el, model.kR));
		},
		A2(
			$elm$core$Maybe$map,
			function (el) {
				return el - 1;
			},
			model.kS));
};
var $author$project$Update$updateCurrentAnswerElementDetails = F2(
	function (fn, model) {
		if (model.ln === 1) {
			var resp = function () {
				var _v0 = $author$project$Update$retrieveCurrentQuestionAnswerDetailsFromModel(model);
				if (_v0.$ === 1) {
					return _Utils_Tuple2(
						$author$project$Update$resetModel(
							_Utils_update(
								model,
								{
									ll: A2(
										$author$project$Type$Error$makeError,
										'We were unable to find the answer history for this question.',
										$elm$core$Maybe$Just('No answer history could be obtained.')),
									n_: $author$project$Routing$FatalErrorRoute
								})),
						$author$project$Ports$pushUrl(
							$author$project$Routing$withHash($author$project$Routing$smPaths.ll)));
				} else {
					var _v1 = _v0.a;
					var qIdx = _v1.a;
					var currentAnswerElementDetails = _v1.b;
					var newGroupAnswerHistory = A3(
						$elm$core$Array$set,
						qIdx,
						fn(currentAnswerElementDetails),
						model.kR);
					var newModel = _Utils_update(
						model,
						{kR: newGroupAnswerHistory});
					return _Utils_Tuple2(
						newModel,
						$author$project$Update$makeStorePartialModelStateCmd(newModel));
				}
			}();
			return resp;
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Type$HiddenExamGroup$getActivityIdsFromCohortExamGroups = F2(
	function (cohortExamGroups, group) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2($elm$core$Dict$get, group, cohortExamGroups));
	});
var $author$project$Type$HiddenExamGroup$getCohortExamGroupsMaybe = F2(
	function (hiddenExamGroups, cohort) {
		return A2(
			$elm$core$Dict$get,
			$author$project$Type$Cohort$toAssigneeString(cohort),
			hiddenExamGroups);
	});
var $author$project$Type$HiddenExamGroup$getActivityIdsForCohortAndGroupTag = F3(
	function (hiddenExamGroups, cohort, groupTag) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				function (el) {
					return A2($author$project$Type$HiddenExamGroup$getActivityIdsFromCohortExamGroups, el, groupTag);
				},
				A2($author$project$Type$HiddenExamGroup$getCohortExamGroupsMaybe, hiddenExamGroups, cohort)));
	});
var $author$project$Type$HiddenExamGroup$insert = F4(
	function (hiddenExamGroups, cohort, group, activityId) {
		var newHidsForCohortAndGroupTag = function (hids) {
			return A2($elm$core$List$cons, activityId, hids);
		}(
			A3($author$project$Type$HiddenExamGroup$getActivityIdsForCohortAndGroupTag, hiddenExamGroups, cohort, group));
		var hidsForCohort = A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Dict$empty,
			A2($author$project$Type$HiddenExamGroup$getCohortExamGroupsMaybe, hiddenExamGroups, cohort));
		var newHidsForCohort = A3($elm$core$Dict$insert, group, newHidsForCohortAndGroupTag, hidsForCohort);
		return A3(
			$elm$core$Dict$insert,
			$author$project$Type$Cohort$toAssigneeString(cohort),
			newHidsForCohort,
			hiddenExamGroups);
	});
var $author$project$Type$HiddenExamGroup$noGrp = 'nogrp';
var $elm$core$Dict$isEmpty = function (dict) {
	if (dict.$ === -2) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Type$HiddenExamGroup$remove = F4(
	function (hiddenExamGroups, cohort, group, activityId) {
		if ($elm$core$Dict$isEmpty(hiddenExamGroups)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($author$project$Type$HiddenExamGroup$getCohortExamGroupsMaybe, hiddenExamGroups, cohort);
			if (_v0.$ === 1) {
				return $elm$core$Maybe$Just(hiddenExamGroups);
			} else {
				var cohortExamGroups = _v0.a;
				var newActivityIds = A2(
					$elm$core$List$filter,
					function (el) {
						return !_Utils_eq(el, activityId);
					},
					A3($author$project$Type$HiddenExamGroup$getActivityIdsForCohortAndGroupTag, hiddenExamGroups, cohort, group));
				var cohortString = $author$project$Type$Cohort$toAssigneeString(cohort);
				var insertCohortExamGroup = function (newCohortExamGroups) {
					return $elm$core$Maybe$Just(
						A3($elm$core$Dict$insert, cohortString, newCohortExamGroups, hiddenExamGroups));
				};
				if ($elm$core$List$isEmpty(newActivityIds)) {
					var newCohortExamGroups = A2($elm$core$Dict$remove, group, cohortExamGroups);
					if ($elm$core$Dict$isEmpty(newCohortExamGroups)) {
						var newHids = A2($elm$core$Dict$remove, cohortString, hiddenExamGroups);
						return $elm$core$Dict$isEmpty(newHids) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(newHids);
					} else {
						return insertCohortExamGroup(newCohortExamGroups);
					}
				} else {
					return insertCohortExamGroup(
						A3($elm$core$Dict$insert, group, newActivityIds, cohortExamGroups));
				}
			}
		}
	});
var $author$project$Type$HiddenExamGroup$updateHiddenExamGroupsMaybe = F6(
	function (hiddenExamGroups, validQGroupIdsForYearLvl, questionGroupState, cohort, groupTagMaybe, activityId) {
		var group = A2($elm$core$Maybe$withDefault, $author$project$Type$HiddenExamGroup$noGrp, groupTagMaybe);
		if (questionGroupState === 1) {
			return A4($author$project$Type$HiddenExamGroup$remove, hiddenExamGroups, cohort, group, activityId);
		} else {
			return $elm$core$Maybe$Just(
				A4($author$project$Type$HiddenExamGroup$insert, hiddenExamGroups, cohort, group, activityId));
		}
	});
var $author$project$Update$update = F2(
	function (msg, model) {
		var _v91 = function () {
			var _v92 = A2($author$project$Update$update_, msg, model);
			var tModel = _v92.a;
			var tCmdMsg = _v92.b;
			var _v93 = tModel.n_;
			if ((_v93.$ === 10) && (!_v93.a)) {
				var _v94 = _v93.a;
				return _Utils_Tuple2(
					tModel,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Ports$setBelowElmDisplayNone(false),
								tCmdMsg
							])));
			} else {
				return _Utils_Tuple2(
					tModel,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Ports$setBelowElmDisplayNone(true),
								tCmdMsg
							])));
			}
		}();
		var interimModel = _v91.a;
		var interimCmdMsg = _v91.b;
		var _v95 = _Utils_Tuple2(interimModel, interimCmdMsg);
		var updatedModel = _v95.a;
		var updatedCmdMsg = _v95.b;
		if (!_Utils_eq(updatedModel.n_, updatedModel.nz)) {
			var augmentTupleForRoute = function (_v101) {
				var mdl = _v101.a;
				var cmds = _v101.b;
				var partialStateStorageCmd = $author$project$Update$makeStorePartialModelStateCmd(updatedModel);
				var graphQLOps = updatedModel.lx.lF;
				var runSharedEditViewUsersRouteChange = function (userRouteType) {
					var isTeachersRetrieved = $krisajenkins$remotedata$RemoteData$isSuccess(mdl.h5.aa);
					var newPageModel_ManageUsers = function (el) {
						return ($author$project$Type$User$isRemoteMeAnAdminE(updatedModel.fc) && (!isTeachersRetrieved)) ? _Utils_update(
							el,
							{aa: $krisajenkins$remotedata$RemoteData$Loading}) : el;
					}(
						function (el) {
							return _Utils_update(
								el,
								{S: $krisajenkins$remotedata$RemoteData$Loading});
						}(mdl.h5));
					var makeCmd = function (id) {
						return (newPageModel_ManageUsers.bJ === 1) ? $author$project$JsInterop$Plumbing$sendInfoOutside(
							A2(
								$author$project$JsInterop$Plumbing$GraphQL,
								$author$project$JsInterop$Plumbing$StudentsByTchIdGQL(id),
								A2(
									$author$project$JsInterop$Plumbing$getGraphQLQuery,
									graphQLOps,
									$author$project$JsInterop$Plumbing$StudentsByTchIdGQL(id)))) : $author$project$JsInterop$Plumbing$sendInfoOutside(
							A2(
								$author$project$JsInterop$Plumbing$GraphQL,
								$author$project$JsInterop$Plumbing$StudentsByTchIdWithHstryGQL(id),
								A2(
									$author$project$JsInterop$Plumbing$getGraphQLQuery,
									graphQLOps,
									$author$project$JsInterop$Plumbing$StudentsByTchIdWithHstryGQL(id))));
					};
					var cmds_ = function () {
						var getTeachersCmd = (isTeachersRetrieved && (!userRouteType)) ? $elm$core$Platform$Cmd$none : $author$project$JsInterop$Plumbing$sendInfoOutside(
							A2(
								$author$project$JsInterop$Plumbing$GraphQL,
								$author$project$JsInterop$Plumbing$TeachersGQL,
								A2($author$project$JsInterop$Plumbing$getGraphQLQuery, graphQLOps, $author$project$JsInterop$Plumbing$TeachersGQL)));
						var getStudentsCmd = function () {
							if (isTeachersRetrieved) {
								var _v100 = mdl.h5.aa;
								if (_v100.$ === 3) {
									var teachers = _v100.a;
									return $elm$core$Platform$Cmd$batch(
										A2(
											$elm$core$List$map,
											makeCmd,
											A2(
												$elm$core$List$map,
												function ($) {
													return $.lW;
												},
												A2(
													$elm$core$List$map,
													$author$project$Type$User$extractPersonEDetailsFromTeacherE,
													$author$project$Page$ManageUsers$getIsCheckedTeachers(teachers)))));
								} else {
									return $elm$core$Platform$Cmd$none;
								}
							} else {
								if (newPageModel_ManageUsers.bJ === 1) {
									return $author$project$JsInterop$Plumbing$sendInfoOutside(
										A2(
											$author$project$JsInterop$Plumbing$GraphQL,
											$author$project$JsInterop$Plumbing$StudentsGQL,
											A2($author$project$JsInterop$Plumbing$getGraphQLQuery, graphQLOps, $author$project$JsInterop$Plumbing$StudentsGQL)));
								} else {
									return $author$project$JsInterop$Plumbing$sendInfoOutside(
										A2(
											$author$project$JsInterop$Plumbing$GraphQL,
											$author$project$JsInterop$Plumbing$StudentsWithHstryGQL,
											A2($author$project$JsInterop$Plumbing$getGraphQLQuery, graphQLOps, $author$project$JsInterop$Plumbing$StudentsWithHstryGQL)));
								}
							}
						}();
						if (userRouteType === 1) {
							return A2($elm$core$List$cons, getTeachersCmd, cmds);
						} else {
							return $author$project$Type$User$isRemoteMeAnAdminE(updatedModel.fc) ? A2(
								$elm$core$List$cons,
								getTeachersCmd,
								A2($elm$core$List$cons, getStudentsCmd, cmds)) : A2($elm$core$List$cons, getStudentsCmd, cmds);
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							mdl,
							{h5: newPageModel_ManageUsers}),
						cmds_);
				};
				var _v97 = mdl.n_;
				switch (_v97.$) {
					case 1:
						return _Utils_Tuple2(
							mdl,
							A2($elm$core$List$cons, partialStateStorageCmd, cmds));
					case 4:
						return _Utils_Tuple2(
							mdl,
							A2($elm$core$List$cons, partialStateStorageCmd, cmds));
					case 5:
						return _Utils_Tuple2(
							mdl,
							A2($elm$core$List$cons, partialStateStorageCmd, cmds));
					case 22:
						var userRouteType = _v97.a;
						return runSharedEditViewUsersRouteChange(userRouteType);
					case 23:
						return runSharedEditViewUsersRouteChange(0);
					case 21:
						var userRouteType = _v97.a;
						if (userRouteType === 1) {
							return _Utils_Tuple2(mdl, cmds);
						} else {
							if ($author$project$Type$User$isRemoteMeAnAdminE(updatedModel.fc)) {
								var pageModel_CreateUsers = mdl.h1;
								var newPageModel_CreateUsers = _Utils_update(
									pageModel_CreateUsers,
									{cU: $krisajenkins$remotedata$RemoteData$Loading});
								var mdl_ = _Utils_update(
									mdl,
									{h1: newPageModel_CreateUsers});
								return _Utils_Tuple2(
									mdl_,
									A2(
										$elm$core$List$cons,
										$author$project$JsInterop$Plumbing$sendInfoOutside(
											A2(
												$author$project$JsInterop$Plumbing$GraphQL,
												$author$project$JsInterop$Plumbing$TeachersGQL,
												A2($author$project$JsInterop$Plumbing$getGraphQLQuery, graphQLOps, $author$project$JsInterop$Plumbing$TeachersGQL))),
										cmds));
							} else {
								return _Utils_Tuple2(mdl, cmds);
							}
						}
					case 25:
						return function (el) {
							return _Utils_Tuple2(
								el,
								A2(
									$elm$core$List$cons,
									$author$project$JsInterop$Plumbing$sendInfoOutside(
										A2(
											$author$project$JsInterop$Plumbing$GraphQL,
											$author$project$JsInterop$Plumbing$TeachersGQL,
											A2($author$project$JsInterop$Plumbing$getGraphQLQuery, graphQLOps, $author$project$JsInterop$Plumbing$TeachersGQL))),
									cmds));
						}(
							function (el) {
								return _Utils_update(
									mdl,
									{h7: el});
							}(
								function (el) {
									return _Utils_update(
										el,
										{cU: $krisajenkins$remotedata$RemoteData$Loading});
								}(mdl.h7)));
					case 26:
						var pageModel_ManageSchools = mdl.h4;
						var newPageModel_ManageSchools = _Utils_update(
							pageModel_ManageSchools,
							{L: $krisajenkins$remotedata$RemoteData$Loading});
						var mdl_ = _Utils_update(
							mdl,
							{h4: newPageModel_ManageSchools});
						return _Utils_Tuple2(
							mdl_,
							A2(
								$elm$core$List$cons,
								$author$project$JsInterop$Plumbing$sendInfoOutside(
									A2(
										$author$project$JsInterop$Plumbing$GraphQL,
										$author$project$JsInterop$Plumbing$BySIdGQL('school'),
										A2(
											$author$project$JsInterop$Plumbing$getGraphQLQuery,
											graphQLOps,
											$author$project$JsInterop$Plumbing$BySIdGQL('school')))),
								cmds));
					default:
						return _Utils_Tuple2(mdl, cmds);
				}
			};
			var mdlCmdsPair = A2(
				$elm$core$Tuple$mapSecond,
				$elm$core$Platform$Cmd$batch,
				function (_v96) {
					var mdl = _v96.a;
					var cmds = _v96.b;
					return _Utils_Tuple2(
						_Utils_update(
							mdl,
							{nz: updatedModel.n_}),
						cmds);
				}(
					augmentTupleForRoute(
						_Utils_Tuple2(
							updatedModel,
							_List_fromArray(
								[
									$author$project$Ports$sendGoogleAnalytics(
									$author$project$Type$GoogleAnalytics$createGoogleAnalyticsRecord(model.n_)),
									updatedCmdMsg
								])))));
			return mdlCmdsPair;
		} else {
			return _Utils_Tuple2(updatedModel, updatedCmdMsg);
		}
	});
var $author$project$Update$update_ = F2(
	function (msg, model) {
		update_:
		while (true) {
			switch (msg.$) {
				case 49:
					var infoForElm = msg.a;
					switch (infoForElm.$) {
						case 0:
							var signInResponse = infoForElm.a;
							if (!signInResponse.$) {
								var userDetails_ = signInResponse.a;
								var signedInModel = _Utils_update(
									model,
									{
										fc: $krisajenkins$remotedata$RemoteData$Loading,
										id: $author$project$Page$SignIn$init,
										pC: $elm$core$Maybe$Just(userDetails_)
									});
								return _Utils_Tuple2(
									signedInModel,
									$elm$core$Platform$Cmd$batch(
										_List_fromArray(
											[
												$author$project$JsInterop$Plumbing$sendInfoOutside(
												A2(
													$author$project$JsInterop$Plumbing$GraphQL,
													$author$project$JsInterop$Plumbing$MeGQL,
													A2($author$project$JsInterop$Plumbing$getGraphQLQuery, model.lx.lF, $author$project$JsInterop$Plumbing$MeGQL))),
												$author$project$Update$makeStorePartialModelStateCmd(signedInModel)
											])));
							} else {
								if (!signInResponse.a.$) {
									var err = signInResponse.a.a;
									return A3(
										$elm$core$Tuple$mapBoth,
										function (m2) {
											return _Utils_update(
												model,
												{id: m2});
										},
										$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageSignIn),
										A2(
											$author$project$Page$SignIn$update,
											$author$project$Page$SignIn$Failure(err),
											model.id));
								} else {
									var ferr = signInResponse.a.a;
									return A2($author$project$Update$handleFatalInteropFailure, model, ferr);
								}
							}
						case 1:
							var forgotPasswordResponse = infoForElm.a;
							if (!forgotPasswordResponse.$) {
								var codeDetails_ = forgotPasswordResponse.a;
								var _v4 = A3($author$project$Update$log, model, 'In OI.ForgotPasswordCode: codeDetails_ are', codeDetails_);
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{h2: $author$project$Page$ForgotPassword$init}),
									$author$project$Ports$pushUrl(
										$author$project$Routing$withHash($author$project$Routing$smPaths.g6 + ('/' + codeDetails_.pG))));
							} else {
								if (!forgotPasswordResponse.a.$) {
									var err = forgotPasswordResponse.a.a;
									return A3(
										$elm$core$Tuple$mapBoth,
										function (m2) {
											return _Utils_update(
												model,
												{h2: m2});
										},
										$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageForgotPassword),
										A2(
											$author$project$Page$ForgotPassword$update,
											$author$project$Page$ForgotPassword$Failure(err),
											model.h2));
								} else {
									var ferr = forgotPasswordResponse.a.a;
									return A2($author$project$Update$handleFatalInteropFailure, model, ferr);
								}
							}
						case 2:
							var forgotPasswordSubmitResponse = infoForElm.a;
							if (!forgotPasswordSubmitResponse.$) {
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{h3: $author$project$Page$ForgotPasswordSubmit$init}),
									$author$project$Ports$pushUrl(
										$author$project$Routing$withHash($author$project$Routing$smPaths.om)));
							} else {
								if (!forgotPasswordSubmitResponse.a.$) {
									var err = forgotPasswordSubmitResponse.a.a;
									return A3(
										$elm$core$Tuple$mapBoth,
										function (m2) {
											return _Utils_update(
												model,
												{h3: m2});
										},
										$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageForgotPasswordSubmit),
										A2(
											$author$project$Page$ForgotPasswordSubmit$update,
											$author$project$Page$ForgotPasswordSubmit$Failure(err),
											model.h3));
								} else {
									var ferr = forgotPasswordSubmitResponse.a.a;
									return A2($author$project$Update$handleFatalInteropFailure, model, ferr);
								}
							}
						case 3:
							var signOutResponse = infoForElm.a;
							if (!signOutResponse.$) {
								var _v7 = A3($author$project$Update$log, model, 'success response of signout is done here! ', '');
								return _Utils_Tuple2(
									$author$project$Update$resetModel(model),
									$elm$core$Platform$Cmd$batch(
										_List_fromArray(
											[
												$author$project$Ports$pushUrl(
												$author$project$Routing$withHash($author$project$Routing$smPaths.om)),
												$author$project$Ports$sendStorePartialModelState(
												$author$project$DataService$partialModelStateEncoder($author$project$Helper$Common$blankPartialModelState))
											])));
							} else {
								if (!signOutResponse.a.$) {
									var ferr = signOutResponse.a.a;
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												ll: A2(
													$author$project$Type$Error$makeError,
													$author$project$JsInterop$Types$msgForUserToString(ferr.hG),
													$elm$core$Maybe$Just(ferr.gU))
											}),
										$author$project$Ports$pushUrl(
											$author$project$Routing$withHash($author$project$Routing$smPaths.ll)));
								} else {
									var ferr = signOutResponse.a.a;
									return A2($author$project$Update$handleFatalInteropFailure, model, ferr);
								}
							}
						case 17:
							var loginUserResponse = infoForElm.a;
							return A3(
								$elm$core$Tuple$mapBoth,
								function (m2) {
									return _Utils_update(
										model,
										{h8: m2});
								},
								$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageProfile),
								A3(
									$author$project$Page$Profile$update,
									$author$project$Page$Profile$UpdateLoginUserResponse(loginUserResponse),
									model.h8,
									model.lx.lF));
						case 19:
							var loginUserResponse = infoForElm.a;
							if (!loginUserResponse.$) {
								return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
							} else {
								if (!loginUserResponse.b.$) {
									return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
								} else {
									var ferr = loginUserResponse.b.a;
									return A2($author$project$Update$handleFatalInteropFailure, model, ferr);
								}
							}
						case 18:
							var loginUserResponse = infoForElm.a;
							return A2(
								$elm$core$Tuple$mapFirst,
								function (mdl) {
									return ($author$project$Update$getUserRouteType(mdl.n_) === 1) ? $author$project$Update$setTeachersNotAsked(mdl) : mdl;
								},
								A3(
									$elm$core$Tuple$mapBoth,
									function (m2) {
										return _Utils_update(
											model,
											{h7: m2});
									},
									$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageMutateUsers),
									A3(
										$author$project$Page$MutateUsers$update,
										$author$project$Page$MutateUsers$MutateUserResponse(loginUserResponse),
										model.h7,
										model.lx.lF)));
						case 16:
							var createSchoolResponse = infoForElm.a;
							return A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
								A2(
									$elm$core$Maybe$map,
									A2(
										$elm$core$Basics$composeR,
										$Punie$elm_reader$Reader$run(
											A2(
												$author$project$Page$ManageSchools$update,
												$author$project$Page$ManageSchools$SchoolResponse(createSchoolResponse),
												model.h4)),
										A2(
											$elm$core$Tuple$mapBoth,
											function (m2) {
												return _Utils_update(
													model,
													{h4: m2});
											},
											$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageManageSchools))),
									$author$project$Models$makeReaderState(model)));
						case 21:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 20:
							var createUserResponse = infoForElm.a;
							var userType = function () {
								var _v10 = model.n_;
								if (_v10.$ === 21) {
									var userType_ = _v10.a;
									return userType_;
								} else {
									return 0;
								}
							}();
							return A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
								A2(
									$elm$core$Maybe$map,
									function (_v9) {
										var mdl = _v9.a;
										var cmd = _v9.b;
										return function (el) {
											return _Utils_Tuple2(el, cmd);
										}(
											($author$project$Update$getUserRouteType(mdl.n_) === 1) ? $author$project$Update$setTeachersNotAsked(mdl) : mdl);
									},
									A2(
										$elm$core$Maybe$map,
										A2(
											$elm$core$Basics$composeR,
											$Punie$elm_reader$Reader$run(
												A2(
													$author$project$Page$CreateUsers$update,
													A2($author$project$Page$CreateUsers$UserCreated, userType, createUserResponse),
													model.h1)),
											A2(
												$elm$core$Tuple$mapBoth,
												function (m2) {
													return _Utils_update(
														model,
														{h1: m2});
												},
												$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageCreateUsers))),
										$author$project$Models$makeReaderState(model))));
						case 11:
							var personResponse = infoForElm.a;
							var _v11 = model.n_;
							if (_v11.$ === 25) {
								var toGenericLoginUserResponse = function (_v13) {
									if (!personResponse.$) {
										var person = personResponse.a;
										return $author$project$JsInterop$Types$GenericLoginUserSuccess(person.dc.pH);
									} else {
										var err = personResponse.a;
										return A2($author$project$JsInterop$Types$GenericLoginUserFailure, 'id not available', err);
									}
								};
								return A3(
									$elm$core$Tuple$mapBoth,
									function (m2) {
										return _Utils_update(
											model,
											{h7: m2});
									},
									$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageMutateUsers),
									A3(
										$author$project$Page$MutateUsers$update,
										$author$project$Page$MutateUsers$MutateUserResponse(
											toGenericLoginUserResponse(personResponse)),
										model.h7,
										model.lx.lF));
							} else {
								if (!personResponse.$) {
									var assignee = personResponse.a;
									var model_ = A2(
										$krisajenkins$remotedata$RemoteData$withDefault,
										model,
										A2(
											$krisajenkins$remotedata$RemoteData$map,
											function (me) {
												return _Utils_eq(
													$author$project$Type$User$extractPersonEDetails(me).lW,
													assignee.dc.pH) ? _Utils_update(
													model,
													{
														fc: $krisajenkins$remotedata$RemoteData$Success(
															$author$project$Type$User$extractPersonEFromAssignee(assignee))
													}) : model;
											},
											model.fc));
									var _v15 = model_.n_;
									switch (_v15.$) {
										case 17:
											var $temp$msg = $author$project$Msgs$MsgPageProfile(
												$author$project$Page$Profile$PersonResponse(personResponse)),
												$temp$model = model_;
											msg = $temp$msg;
											model = $temp$model;
											continue update_;
										case 26:
											return A2(
												$elm$core$Maybe$withDefault,
												_Utils_Tuple2(model_, $elm$core$Platform$Cmd$none),
												A2(
													$elm$core$Maybe$map,
													function (rState) {
														return A3(
															$elm$core$Tuple$mapBoth,
															function (m2) {
																return _Utils_update(
																	model_,
																	{h4: m2});
															},
															$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageManageSchools),
															A2(
																$Punie$elm_reader$Reader$run,
																A2(
																	$author$project$Page$ManageSchools$update,
																	$author$project$Page$ManageSchools$SetMySchoolIdResponse(model_.fc),
																	model.h4),
																rState));
													},
													$author$project$Models$makeReaderState(model_)));
										default:
											return _Utils_Tuple2(model_, $elm$core$Platform$Cmd$none);
									}
								} else {
									if (!personResponse.a.$) {
										var ferr = personResponse.a.a;
										return _Utils_Tuple2(
											_Utils_update(
												model,
												{
													ll: A2(
														$author$project$Type$Error$makeError,
														$author$project$JsInterop$Types$msgForUserToString(ferr.hG),
														$elm$core$Maybe$Just(ferr.gU))
												}),
											$author$project$Ports$pushUrl(
												$author$project$Routing$withHash($author$project$Routing$smPaths.ll)));
									} else {
										var ferr = personResponse.a.a;
										return A2($author$project$Update$handleFatalInteropFailure, model, ferr);
									}
								}
							}
						case 9:
							var constructNodeForSyllabusResponse = infoForElm.a;
							if (!constructNodeForSyllabusResponse.$) {
								var dValue = constructNodeForSyllabusResponse.a;
								var nodeCfg = model.mX;
								var newNodeCfg = _Utils_update(
									nodeCfg,
									{
										mW: $krisajenkins$remotedata$RemoteData$Success(dValue)
									});
								var newModel = _Utils_update(
									model,
									{mX: newNodeCfg});
								var _v17 = A3($author$project$Update$log, model, 'In OI.ConstructNodeForSyllabusSuccess', dValue);
								var _v18 = model.n_;
								if (_v18.$ === 16) {
									var _v19 = newModel.no.ab;
									if (_v19.$ === 1) {
										return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
									} else {
										var variantSelection = _v19.a;
										var $temp$msg = $author$project$Msgs$OnInfoForElm(
											$author$project$JsInterop$Plumbing$GetVariantSelectionResponse_ShowVariant(variantSelection)),
											$temp$model = newModel;
										msg = $temp$msg;
										model = $temp$model;
										continue update_;
									}
								} else {
									return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
								}
							} else {
								var errString = constructNodeForSyllabusResponse.a;
								var _v20 = A3($author$project$Update$log, model, 'In OI.ConstructNodeForSyllabusFailure with ', errString);
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{
											ll: A2(
												$author$project$Type$Error$makeError,
												'Sorry but we were unable to create the correct question list for your syllabus.',
												$elm$core$Maybe$Just(errString))
										}),
									$author$project$Ports$pushUrl(
										$author$project$Routing$withHash($author$project$Routing$smPaths.ll)));
							}
						case 10:
							var getSyllabusesResponse = infoForElm.a;
							var nodeCfg = model.mX;
							var newNodeCfg = _Utils_update(
								nodeCfg,
								{
									oJ: $krisajenkins$remotedata$RemoteData$fromResult(getSyllabusesResponse)
								});
							var cmd = $author$project$Update$makeConstructNodeForSyllabusCmd(newNodeCfg);
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{mX: newNodeCfg}),
								cmd);
						case 5:
							var getVariantSelectionResponse = infoForElm.a;
							return A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
								A2(
									$elm$core$Maybe$map,
									A2(
										$elm$core$Basics$composeR,
										$Punie$elm_reader$Reader$run(
											A2(
												$author$project$Page$Mjd5$update,
												$author$project$Page$Mjd5$OnGetVariantSelectionResponse_Mjd5(getVariantSelectionResponse),
												model.h6)),
										A2(
											$elm$core$Tuple$mapBoth,
											function (m2) {
												return _Utils_update(
													model,
													{h6: m2});
											},
											$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageMjd5))),
									$author$project$Models$makeReaderState(model)));
						case 6:
							var variantSelection = infoForElm.a;
							return A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
								A2(
									$elm$core$Maybe$map,
									function (_v21) {
										var m = _v21.a;
										var cmd = _v21.b;
										return _Utils_Tuple2(
											m,
											$elm$core$Platform$Cmd$batch(
												_List_fromArray(
													[
														cmd,
														$author$project$Update$makeStorePartialModelStateCmd(m)
													])));
									},
									A2(
										$elm$core$Maybe$map,
										A2(
											$elm$core$Basics$composeR,
											$Punie$elm_reader$Reader$run(
												A2(
													$author$project$Page$ShowVariant$update,
													$author$project$Page$ShowVariant$OnGetVariantSelectionResponse_ShowVariant(variantSelection),
													model.no)),
											A2(
												$elm$core$Tuple$mapBoth,
												function (m) {
													return _Utils_update(
														model,
														{no: m});
												},
												$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageShowVariant))),
										$author$project$Models$makeReaderState(model))));
						case 7:
							var getVariantSelectionResponse = infoForElm.a;
							return A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
								A2(
									$elm$core$Maybe$map,
									function (rState) {
										return A3(
											$elm$core$Tuple$mapBoth,
											function (m2) {
												return _Utils_update(
													model,
													{ic: m2});
											},
											$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageShowWorksheets),
											A2(
												$Punie$elm_reader$Reader$run,
												A2(
													$author$project$Page$ShowWorksheets$update,
													$author$project$Page$ShowWorksheets$OnGetVariantSelectionResponse_ShowWorksheets(getVariantSelectionResponse),
													model.ic),
												rState));
									},
									$author$project$Models$makeReaderState(model)));
						case 8:
							var getVariantIdsResponse = infoForElm.a;
							return A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
								A2(
									$elm$core$Maybe$map,
									A2(
										$elm$core$Basics$composeR,
										$Punie$elm_reader$Reader$run(
											A2(
												$author$project$Page$AdminPage$update,
												$author$project$Page$AdminPage$OnGetVariantIdsResponse(getVariantIdsResponse),
												model.h0)),
										A2(
											$elm$core$Tuple$mapBoth,
											function (m2) {
												return _Utils_update(
													model,
													{h0: m2});
											},
											$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageAdminPage))),
									$author$project$Models$makeReaderState(model)));
						case 4:
							var meResponse = infoForElm.a;
							if (!meResponse.$) {
								var assignee = meResponse.a;
								var me = $author$project$Type$User$extractPersonEFromAssignee(assignee);
								var history = A2(
									$elm$core$Maybe$withDefault,
									_List_Nil,
									A2(
										$elm$core$Maybe$map,
										function ($) {
											return $.lU;
										},
										$author$project$Type$User$extractStudentEFromPersonE(me)));
								var studentData = $krisajenkins$remotedata$RemoteData$Success(
									$author$project$Type$ExamResult$StudentData(history));
								var mdl = function () {
									var activeSyllabusTitle = A2(
										$elm_community$maybe_extra$Maybe$Extra$or,
										model.mX.jJ,
										A2(
											$elm$core$Maybe$map,
											$author$project$Type$Cohort$toCurrentSyllabusTitle,
											$author$project$Type$User$extractCohortFromPersonE(me)));
									var newNodeCfg = function (el) {
										return _Utils_update(
											el,
											{jJ: activeSyllabusTitle, fs: $krisajenkins$remotedata$RemoteData$Loading});
									}(model.mX);
									return _Utils_update(
										model,
										{
											j$: $krisajenkins$remotedata$RemoteData$Loading,
											fc: $krisajenkins$remotedata$RemoteData$succeed(me),
											mX: newNodeCfg,
											fQ: studentData
										});
								}();
								var cmds = _List_fromArray(
									[
										A2($author$project$DataService$getAppCfgNonAWSAPI, model.lx.pA, model.lx.pL),
										$author$project$Type$User$isStudentE(me) ? $author$project$Ports$sendGetHiddenExamGroups(
										$author$project$Type$User$extractPersonEDetails(me).d$) : $author$project$Ports$sendGetHiddenExamGroups(
										$author$project$Type$User$extractPersonEDetails(me).lW),
										(model.ln === 1) ? $elm$core$Platform$Cmd$none : ($author$project$Type$User$isTeacherE(me) ? $author$project$Ports$pushUrl(
										$author$project$Routing$withHash($author$project$Routing$smPaths.pN)) : $author$project$Ports$pushUrl(
										$author$project$Routing$withHash('#'))),
										($author$project$Type$User$isTeacherE(me) && _Utils_eq(model.n_, $author$project$Routing$ViewStudentsRoute)) ? $author$project$JsInterop$Plumbing$sendInfoOutside(
										A2(
											$author$project$JsInterop$Plumbing$GraphQL,
											$author$project$JsInterop$Plumbing$StudentsWithHstryGQL,
											A2($author$project$JsInterop$Plumbing$getGraphQLQuery, model.lx.lF, $author$project$JsInterop$Plumbing$StudentsWithHstryGQL))) : $elm$core$Platform$Cmd$none
									]);
								return _Utils_Tuple2(
									mdl,
									$elm$core$Platform$Cmd$batch(cmds));
							} else {
								if (!meResponse.a.$) {
									var ferr = meResponse.a.a;
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												ll: A2(
													$author$project$Type$Error$makeError,
													$author$project$JsInterop$Types$msgForUserToString(ferr.hG),
													$elm$core$Maybe$Just(ferr.gU))
											}),
										$author$project$Ports$pushUrl(
											$author$project$Routing$withHash($author$project$Routing$smPaths.ll)));
								} else {
									var ferr = meResponse.a.a;
									return A2($author$project$Update$handleFatalInteropFailure, model, ferr);
								}
							}
						case 14:
							var nbrUsersForSchoolIdResponse = infoForElm.a;
							return A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
								A2(
									$elm$core$Maybe$map,
									A2(
										$elm$core$Basics$composeR,
										$Punie$elm_reader$Reader$run(
											A2(
												$author$project$Page$ManageSchools$update,
												$author$project$Page$ManageSchools$NbrUsersForSchoolIdResponse(nbrUsersForSchoolIdResponse),
												model.h4)),
										A2(
											$elm$core$Tuple$mapBoth,
											function (m2) {
												return _Utils_update(
													model,
													{h4: m2});
											},
											$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageManageSchools))),
									$author$project$Models$makeReaderState(model)));
						case 15:
							var deleteSchoolResponse = infoForElm.a;
							return A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
								A2(
									$elm$core$Maybe$map,
									A2(
										$elm$core$Basics$composeR,
										$Punie$elm_reader$Reader$run(
											A2(
												$author$project$Page$ManageSchools$update,
												$author$project$Page$ManageSchools$DeleteSchoolResponse(deleteSchoolResponse),
												model.h4)),
										A2(
											$elm$core$Tuple$mapBoth,
											function (m2) {
												return _Utils_update(
													model,
													{h4: m2});
											},
											$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageManageSchools))),
									$author$project$Models$makeReaderState(model)));
						case 13:
							var schoolsResponse = infoForElm.a;
							return A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
								A2(
									$elm$core$Maybe$map,
									A2(
										$elm$core$Basics$composeR,
										$Punie$elm_reader$Reader$run(
											A2(
												$author$project$Page$ManageSchools$update,
												$author$project$Page$ManageSchools$SchoolsResponse(schoolsResponse),
												model.h4)),
										A2(
											$elm$core$Tuple$mapBoth,
											function (m2) {
												return _Utils_update(
													model,
													{h4: m2});
											},
											$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageManageSchools))),
									$author$project$Models$makeReaderState(model)));
						default:
							var personsResponse = infoForElm.a;
							if (!personsResponse.$) {
								var personResponses = personsResponse.a;
								var personEs = A2(
									$elm$core$List$map,
									$author$project$Type$User$extractPersonEFromAssignee,
									A2($elm$core$List$filterMap, $author$project$JsInterop$Types$toAssigneeMaybe, personResponses));
								var handlePersonsResponseForEditAndViewUsersRoute = function (userRouteType) {
									var makeUserFromPersonE = function () {
										if (userRouteType === 1) {
											return $author$project$Page$ManageUsers$makeUserFromPersonE;
										} else {
											return $author$project$Page$ManageUsers$makeAugmentedUserFromPersonE;
										}
									}();
									var isManagingStudents = !userRouteType;
									var isFirstPersonEATeacher = _Utils_eq(
										$elm$core$Maybe$Just(true),
										A2(
											$elm$core$Maybe$map,
											$author$project$Type$User$isTeacherE,
											$elm$core$List$head(personEs)));
									var manageUsersUpdateFn = function () {
										if (isFirstPersonEATeacher && isManagingStudents) {
											var teachers = A2($elm$core$List$filterMap, $author$project$Page$ManageUsers$makeAugmentedTeacherERecord, personEs);
											return A3(
												$author$project$Page$ManageUsers$update,
												A2(
													$author$project$Page$ManageUsers$TeachersRetrieved,
													$krisajenkins$remotedata$RemoteData$succeed(teachers),
													model.fc),
												model.h5,
												model.lx.lF);
										} else {
											return A3(
												$author$project$Page$ManageUsers$update,
												$author$project$Page$ManageUsers$UsersRetrieved(
													$krisajenkins$remotedata$RemoteData$succeed(
														A2($elm$core$List$map, makeUserFromPersonE, personEs))),
												model.h5,
												model.lx.lF);
										}
									}();
									var assignees = function () {
										if (isManagingStudents && _Utils_eq(model.n_, $author$project$Routing$ViewStudentsRoute)) {
											var studentAssignees = A2($elm$core$List$filterMap, $author$project$JsInterop$Types$toAssigneeMaybe, personResponses);
											return A2(
												$krisajenkins$remotedata$RemoteData$map,
												function (els) {
													return A2($elm$core$List$append, studentAssignees, els);
												},
												model.el);
										} else {
											return $krisajenkins$remotedata$RemoteData$Success(_List_Nil);
										}
									}();
									return A3(
										$elm$core$Tuple$mapBoth,
										function (m2) {
											return _Utils_update(
												model,
												{el: assignees, h5: m2});
										},
										$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageManageUsers),
										manageUsersUpdateFn);
								};
								var _v24 = model.n_;
								switch (_v24.$) {
									case 22:
										var userRouteType = _v24.a;
										return handlePersonsResponseForEditAndViewUsersRoute(userRouteType);
									case 23:
										return handlePersonsResponseForEditAndViewUsersRoute(0);
									case 0:
										return handlePersonsResponseForEditAndViewUsersRoute(0);
									case 21:
										var teachers = A2($elm$core$List$filterMap, $author$project$Type$User$extractTeacherEFromPersonE, personEs);
										return A2(
											$elm$core$Maybe$withDefault,
											_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
											A2(
												$elm$core$Maybe$map,
												A2(
													$elm$core$Basics$composeR,
													$Punie$elm_reader$Reader$run(
														A2(
															$author$project$Page$CreateUsers$update,
															$author$project$Page$CreateUsers$AssignableTeachersRetrieved(
																$krisajenkins$remotedata$RemoteData$succeed(teachers)),
															model.h1)),
													A2(
														$elm$core$Tuple$mapBoth,
														function (m2) {
															return _Utils_update(
																model,
																{h1: m2});
														},
														$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageCreateUsers))),
												$author$project$Models$makeReaderState(model)));
									case 25:
										var teachers = A2($elm$core$List$filterMap, $author$project$Type$User$extractTeacherEFromPersonE, personEs);
										return A3(
											$elm$core$Tuple$mapBoth,
											function (m2) {
												return _Utils_update(
													model,
													{h7: m2});
											},
											$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageMutateUsers),
											A3(
												$author$project$Page$MutateUsers$update,
												$author$project$Page$MutateUsers$AssignableTeachersRetrieved(
													$krisajenkins$remotedata$RemoteData$succeed(teachers)),
												model.h7,
												model.lx.lF));
									case 17:
										if (A3($krisajenkins$remotedata$RemoteData$unwrap, false, $author$project$Type$User$isTeacherE, model.h8.H)) {
											return A3(
												$elm$core$Tuple$mapBoth,
												function (m2) {
													return _Utils_update(
														model,
														{h8: m2});
												},
												$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageProfile),
												A3(
													$author$project$Page$Profile$update,
													$author$project$Page$Profile$StudentsRetrieved(
														$krisajenkins$remotedata$RemoteData$succeed(personEs)),
													model.h8,
													model.lx.lF));
										} else {
											var teachers = A2($elm$core$List$filterMap, $author$project$Type$User$extractTeacherEFromPersonE, personEs);
											return A3(
												$elm$core$Tuple$mapBoth,
												function (m2) {
													return _Utils_update(
														model,
														{h8: m2});
												},
												$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageProfile),
												A3(
													$author$project$Page$Profile$update,
													$author$project$Page$Profile$AssignableTeachersRetrieved(
														$krisajenkins$remotedata$RemoteData$succeed(teachers)),
													model.h8,
													model.lx.lF));
										}
									default:
										return _Utils_eq(model.ll, $elm$core$Maybe$Nothing) ? _Utils_Tuple2(
											_Utils_update(
												model,
												{
													ll: A2(
														$author$project$Type$Error$makeError,
														'Sorry but a configuration error has occurred',
														$elm$core$Maybe$Just('The application is recieving OI.PersonsResponse but there is not a valid model.route to handle it.'))
												}),
											$author$project$Ports$pushUrl(
												$author$project$Routing$withHash($author$project$Routing$smPaths.ll))) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
								}
							} else {
								if (!personsResponse.a.$) {
									var ferr = personsResponse.a.a;
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												ll: A2(
													$author$project$Type$Error$makeError,
													$author$project$JsInterop$Types$msgForUserToString(ferr.hG),
													$elm$core$Maybe$Just(ferr.gU))
											}),
										$author$project$Ports$pushUrl(
											$author$project$Routing$withHash($author$project$Routing$smPaths.ll)));
								} else {
									var ferr = personsResponse.a.a;
									return A2($author$project$Update$handleFatalInteropFailure, model, ferr);
								}
							}
					}
				case 50:
					if (!msg.a.$) {
						var err = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									ll: A2(
										$author$project$Type$Error$makeError,
										'There was a problem with the application.',
										$elm$core$Maybe$Just(err.gU))
								}),
							$author$project$JsInterop$Plumbing$sendInfoOutside(
								$author$project$JsInterop$Plumbing$LogError('Non-fatal err: ' + err.gU)));
					} else {
						var ferr = msg.a.a;
						return A2($author$project$Update$handleFatalInteropFailure, model, ferr);
					}
				case 51:
					var msgPageSignIn = msg.a;
					return A3(
						$elm$core$Tuple$mapBoth,
						function (m2) {
							return _Utils_update(
								model,
								{id: m2});
						},
						$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageSignIn),
						A2($author$project$Page$SignIn$update, msgPageSignIn, model.id));
				case 52:
					var msgPageMjd5 = msg.a;
					return A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								$Punie$elm_reader$Reader$run(
									A2($author$project$Page$Mjd5$update, msgPageMjd5, model.h6)),
								A2(
									$elm$core$Tuple$mapBoth,
									function (m2) {
										return _Utils_update(
											model,
											{h6: m2});
									},
									$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageMjd5))),
							$author$project$Models$makeReaderState(model)));
				case 53:
					var msgPageStudentHome = msg.a;
					switch (msgPageStudentHome.$) {
						case 5:
							var questionGroupState = msgPageStudentHome.a;
							var cohort = msgPageStudentHome.b;
							var groupTagMaybe = msgPageStudentHome.c;
							var activityId = msgPageStudentHome.d;
							var $temp$msg = A4($author$project$Msgs$UpdateHiddenExamGroups, questionGroupState, cohort, groupTagMaybe, activityId),
								$temp$model = model;
							msg = $temp$msg;
							model = $temp$model;
							continue update_;
						case 11:
							var singleExamRunResult = msgPageStudentHome.a;
							var studentData = A2(
								$krisajenkins$remotedata$RemoteData$map,
								function (el) {
									return {
										eZ: A2($elm$core$List$cons, singleExamRunResult, el.eZ)
									};
								},
								model.fQ);
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{fQ: studentData}),
								$elm$core$Platform$Cmd$none);
						case 3:
							var act = msgPageStudentHome.a;
							var activityMetric = msgPageStudentHome.b;
							var $temp$msg = $author$project$Msgs$MsgPageShowVariant(
								A2($author$project$Page$ShowVariant$StartActivity, act, activityMetric)),
								$temp$model = _Utils_update(
								model,
								{gL: $krisajenkins$remotedata$RemoteData$NotAsked, kR: $elm$core$Array$empty, kS: $elm$core$Maybe$Nothing, kT: $elm$core$Maybe$Nothing, kU: $elm$core$Maybe$Nothing, ln: 0});
							msg = $temp$msg;
							model = $temp$model;
							continue update_;
						case 4:
							var activityDataGroup = msgPageStudentHome.a;
							var isStudentRequestor = msgPageStudentHome.b;
							var $temp$msg = $author$project$Msgs$MsgPageShowWorksheets(
								A2($author$project$Page$ShowWorksheets$InitializePage, activityDataGroup, isStudentRequestor)),
								$temp$model = model;
							msg = $temp$msg;
							model = $temp$model;
							continue update_;
						default:
							return A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
								A2(
									$elm$core$Maybe$map,
									A2(
										$elm$core$Basics$composeR,
										$Punie$elm_reader$Reader$run(
											A2($author$project$Page$StudentHome$update, msgPageStudentHome, model.ie)),
										A2(
											$elm$core$Tuple$mapBoth,
											function (m2) {
												return _Utils_update(
													model,
													{ie: m2});
											},
											$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageStudentHome))),
									$author$project$Models$makeReaderState(model)));
					}
				case 54:
					var msgPageAdminPage = msg.a;
					switch (msgPageAdminPage.$) {
						case 12:
							var variantIdString = msgPageAdminPage.a;
							var pageModel_ShowVariant = $author$project$Page$ShowVariant$init;
							var pageModel_AdminPage = model.h0;
							var newPageModel_ShowVariant = _Utils_update(
								pageModel_ShowVariant,
								{aL: $krisajenkins$remotedata$RemoteData$Loading});
							var newPageModel_AdminPage = _Utils_update(
								pageModel_AdminPage,
								{fE: variantIdString});
							var cmds = $elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										$author$project$Ports$pushUrl(
										$author$project$Routing$withHash($author$project$Routing$smPaths.iS)),
										A4(
										$author$project$PortImpl$sendGetEncodedVariantContent,
										model.lx.pB,
										A2($elm$core$Basics$composeR, $author$project$Page$ShowVariant$OnGetEncodedVariantContent, $author$project$Msgs$MsgPageShowVariant),
										model.lx.pL,
										variantIdString)
									]));
							var _v28 = A3($author$project$Update$log, model, 'ShowVariantId with variantIdString syllabusString', variantIdString);
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{h0: newPageModel_AdminPage, no: newPageModel_ShowVariant}),
								cmds);
						case 3:
							var syllabusTitle = msgPageAdminPage.a;
							var nodeCfg = model.mX;
							var newNodeCfg = _Utils_update(
								nodeCfg,
								{
									jJ: $elm$core$Maybe$Just(
										$author$project$Type$Cohort$toCurrentSyllabusTitle(syllabusTitle))
								});
							var cmd = $author$project$Update$makeConstructNodeForSyllabusCmd(newNodeCfg);
							var _v29 = A3($author$project$Update$log, model, 'SetSyllabus for testing purposes with syllabusString', syllabusTitle);
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{mX: newNodeCfg}),
								cmd);
						default:
							return A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
								A2(
									$elm$core$Maybe$map,
									A2(
										$elm$core$Basics$composeR,
										$Punie$elm_reader$Reader$run(
											A2($author$project$Page$AdminPage$update, msgPageAdminPage, model.h0)),
										A2(
											$elm$core$Tuple$mapBoth,
											function (m2) {
												return _Utils_update(
													model,
													{h0: m2});
											},
											$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageAdminPage))),
									$author$project$Models$makeReaderState(model)));
					}
				case 55:
					var msgPageShowVariant = msg.a;
					var processForFinishExam = function (_v33) {
						var model_ = _v33.a;
						var cmd_ = _v33.b;
						if (msgPageShowVariant.$ === 9) {
							return A2(
								$elm$core$Tuple$mapSecond,
								function (cmd2) {
									return $elm$core$Platform$Cmd$batch(
										_List_fromArray(
											[cmd_, cmd2]));
								},
								A2($author$project$Update$update_, $author$project$Msgs$FinishExam, model_));
						} else {
							return _Utils_Tuple2(model_, cmd_);
						}
					};
					var processForExamStart = function (_v31) {
						var model_ = _v31.a;
						var cmd_ = _v31.b;
						if ((!model_.ln) && _Utils_eq(msgPageShowVariant, $author$project$Page$ShowVariant$GetNextVariantSelectionNEW)) {
							var examTimeSecondsRemaining = A2(
								$elm$core$Maybe$map,
								$elm$core$Basics$mul(60),
								A2(
									$elm$core$Maybe$andThen,
									function ($) {
										return $.fh;
									},
									model_.no.aq));
							return _Utils_Tuple2(
								_Utils_update(
									model_,
									{lo: examTimeSecondsRemaining}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											cmd_,
											A2($elm$core$Task$perform, $author$project$Msgs$RecordStartTime, $elm$time$Time$now)
										])));
						} else {
							return _Utils_Tuple2(model_, cmd_);
						}
					};
					return A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
						A2(
							$elm$core$Maybe$map,
							function (_v30) {
								var m = _v30.a;
								var cmd = _v30.b;
								return _Utils_Tuple2(
									m,
									$elm$core$Platform$Cmd$batch(
										_List_fromArray(
											[
												cmd,
												model.no.ca ? $elm$core$Platform$Cmd$none : $author$project$Update$makeStorePartialModelStateCmd(m)
											])));
							},
							A2(
								$elm$core$Maybe$map,
								A2(
									$elm$core$Basics$composeR,
									$Punie$elm_reader$Reader$run(
										A2($author$project$Page$ShowVariant$update, msgPageShowVariant, model.no)),
									A2(
										$elm$core$Basics$composeR,
										A2(
											$elm$core$Tuple$mapBoth,
											function (m2) {
												return _Utils_update(
													model,
													{no: m2});
											},
											function (cmd) {
												return A2($elm$core$Platform$Cmd$map, $author$project$Msgs$MsgPageShowVariant, cmd);
											}),
										A2($elm$core$Basics$composeR, processForExamStart, processForFinishExam))),
								$author$project$Models$makeReaderState(model))));
				case 56:
					var msgPageShowWorksheet = msg.a;
					return A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								$Punie$elm_reader$Reader$run(
									A2($author$project$Page$ShowWorksheet$update, msgPageShowWorksheet, model.ib)),
								A2(
									$elm$core$Tuple$mapBoth,
									function (m2) {
										return _Utils_update(
											model,
											{ib: m2});
									},
									function (cmd) {
										return A2($elm$core$Platform$Cmd$map, $author$project$Msgs$MsgPageShowWorksheet, cmd);
									})),
							$author$project$Models$makeReaderState(model)));
				case 57:
					var msgPageShowWorksheets = msg.a;
					return A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
						A2(
							$elm$core$Maybe$map,
							function (rState) {
								return function (tup) {
									switch (msgPageShowWorksheets.$) {
										case 5:
											var activityId = msgPageShowWorksheets.a;
											var activityMode = msgPageShowWorksheets.b;
											var dedupedQs = msgPageShowWorksheets.c;
											var posix = msgPageShowWorksheets.d;
											var actRR = A4(
												$author$project$Type$ExamResult$ActivityResultRecord,
												activityId,
												activityMode,
												$author$project$Type$DateTime$posixToDecSecsString(posix),
												dedupedQs);
											var _v35 = A2(
												$author$project$Update$update_,
												$author$project$Msgs$CreateWorksheet(actRR),
												tup.a);
											var newModel = _v35.a;
											var newCmd = _v35.b;
											return A3(
												$elm$core$Tuple$mapBoth,
												function (_v36) {
													return newModel;
												},
												function (cmd2) {
													return $elm$core$Platform$Cmd$batch(
														_List_fromArray(
															[newCmd, cmd2]));
												},
												tup);
										case 6:
											var activityData = msgPageShowWorksheets.a;
											var activityResultRecord = msgPageShowWorksheets.b;
											var isWorksheetBasedOnResults = msgPageShowWorksheets.c;
											return A2(
												$author$project$Update$update_,
												$author$project$Msgs$MsgPageShowWorksheet(
													A3($author$project$Page$ShowWorksheet$InitializePage, activityData, activityResultRecord, isWorksheetBasedOnResults)),
												_Utils_update(
													model,
													{ib: $author$project$Page$ShowWorksheet$init}));
										default:
											return tup;
									}
								}(
									A3(
										$elm$core$Tuple$mapBoth,
										function (m2) {
											return _Utils_update(
												model,
												{ic: m2});
										},
										function (cmd) {
											return A2($elm$core$Platform$Cmd$map, $author$project$Msgs$MsgPageShowWorksheets, cmd);
										},
										A2(
											$Punie$elm_reader$Reader$run,
											A2($author$project$Page$ShowWorksheets$update, msgPageShowWorksheets, model.ic),
											rState)));
							},
							$author$project$Models$makeReaderState(model)));
				case 58:
					var msgPageProfile = msg.a;
					if (msgPageProfile.$ === 1) {
						var personE = msgPageProfile.a;
						var userRouteTypeString = $author$project$Type$User$isStudentE(personE) ? 'Student' : 'Teacher';
						var pageModel_MutateUsers_ = model.h7;
						var pageModel_MutateUsers = _Utils_update(
							pageModel_MutateUsers_,
							{
								F: $author$project$Page$MutateUsers$initPersonEsToMutate(
									$elm$core$List$singleton(personE))
							});
						var _v38 = A3($author$project$Update$log, model, 'in DeleteLoginUser', ' now');
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{h7: pageModel_MutateUsers}),
							$author$project$Ports$pushUrl(
								$author$project$Routing$withHash($author$project$Routing$smPaths.eG + ('/' + userRouteTypeString))));
					} else {
						return A3(
							$elm$core$Tuple$mapBoth,
							function (m2) {
								return _Utils_update(
									model,
									{h8: m2});
							},
							$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageProfile),
							A3($author$project$Page$Profile$update, msgPageProfile, model.h8, model.lx.lF));
					}
				case 60:
					var msgPage = msg.a;
					return A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								$Punie$elm_reader$Reader$run(
									A2($author$project$Page$CreateUsers$update, msgPage, model.h1)),
								A2(
									$elm$core$Tuple$mapBoth,
									function (m2) {
										return _Utils_update(
											model,
											{h1: m2});
									},
									$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageCreateUsers))),
							$author$project$Models$makeReaderState(model)));
				case 61:
					var msgPage = msg.a;
					switch (msgPage.$) {
						case 7:
							var personEs = msgPage.a;
							var userRouteTypeString = 'Student';
							var pageModel_MutateUsers_ = model.h7;
							var pageModel_MutateUsers = _Utils_update(
								pageModel_MutateUsers_,
								{
									F: $author$project$Page$MutateUsers$initPersonEsToMutate(personEs)
								});
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{h7: pageModel_MutateUsers}),
								$author$project$Ports$pushUrl(
									$author$project$Routing$withHash($author$project$Routing$smPaths.eG + ('/' + userRouteTypeString))));
						case 6:
							var personEs = msgPage.a;
							var userRouteTypeString = 'Student';
							var pageModel_MutateUsers_ = model.h7;
							var pageModel_MutateUsers = _Utils_update(
								pageModel_MutateUsers_,
								{
									F: $author$project$Page$MutateUsers$initPersonEsToMutate(personEs)
								});
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{h7: pageModel_MutateUsers}),
								$author$project$Ports$pushUrl(
									$author$project$Routing$withHash($author$project$Routing$smPaths.jl + ('/' + userRouteTypeString))));
						case 19:
							var studentETypes = msgPage.a;
							var teacherDetails = A2(
								$krisajenkins$remotedata$RemoteData$withDefault,
								_List_Nil,
								A2(
									$krisajenkins$remotedata$RemoteData$map,
									$elm$core$List$map(
										A2(
											$elm$core$Basics$composeL,
											$author$project$Type$User$extractPersonEDetailsFromTeacherE,
											function ($) {
												return $.bN;
											})),
									model.h5.aa));
							return function (mdl) {
								return _Utils_Tuple2(
									mdl,
									$author$project$Ports$pushUrl(
										$author$project$Routing$withHash($author$project$Routing$smPaths.ga)));
							}(
								A2(
									$elm$core$Maybe$withDefault,
									model,
									A2(
										$elm$core$Maybe$map,
										$Punie$elm_reader$Reader$run(
											A2(
												$Punie$elm_reader$Reader$map,
												function (el) {
													return _Utils_update(
														model,
														{h$: el});
												},
												A3($author$project$Page$AdaptiveAnalytics$furnish, $author$project$Page$AdaptiveAnalytics$init, studentETypes, teacherDetails))),
										$author$project$Models$makeReaderState(model))));
						case 20:
							var analyticsVersion = msgPage.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{gj: analyticsVersion}),
								$author$project$Ports$pushUrl(
									$author$project$Routing$withHash($author$project$Routing$smPaths.cC)));
						default:
							var assignees = function () {
								if (msgPage.$ === 11) {
									return $krisajenkins$remotedata$RemoteData$Success(_List_Nil);
								} else {
									return model.el;
								}
							}();
							return A3(
								$elm$core$Tuple$mapBoth,
								function (m2) {
									return _Utils_update(
										model,
										{el: assignees, h5: m2});
								},
								$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageManageUsers),
								A3($author$project$Page$ManageUsers$update, msgPage, model.h5, model.lx.lF));
					}
				case 62:
					var msgPage = msg.a;
					return A3(
						$elm$core$Tuple$mapBoth,
						function (m2) {
							return _Utils_update(
								model,
								{h7: m2});
						},
						$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageMutateUsers),
						A3($author$project$Page$MutateUsers$update, msgPage, model.h7, model.lx.lF));
				case 59:
					var msgPage = msg.a;
					return A3(
						$elm$core$Tuple$mapBoth,
						function (m2) {
							return _Utils_update(
								model,
								{h9: m2});
						},
						$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageReportCard),
						A3($author$project$Page$ReportCard$update, msgPage, model.h9, model.lx.lF));
				case 63:
					var msgPage = msg.a;
					return A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								$Punie$elm_reader$Reader$run(
									A2($author$project$Page$ManageSchools$update, msgPage, model.h4)),
								A2(
									$elm$core$Tuple$mapBoth,
									function (m2) {
										return _Utils_update(
											model,
											{h4: m2});
									},
									$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageManageSchools))),
							$author$project$Models$makeReaderState(model)));
				case 64:
					var msgPage = msg.a;
					return A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								$Punie$elm_reader$Reader$run(
									A2($author$project$Page$AdaptiveAnalytics$update, msgPage, model.h$)),
								A2(
									$elm$core$Tuple$mapBoth,
									function (m2) {
										return _Utils_update(
											model,
											{h$: m2});
									},
									$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageAdaptiveAnalytics))),
							$author$project$Models$makeReaderState(model)));
				case 65:
					var msgPageForgotPassword = msg.a;
					return A3(
						$elm$core$Tuple$mapBoth,
						function (m2) {
							return _Utils_update(
								model,
								{h2: m2});
						},
						$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageForgotPassword),
						A2($author$project$Page$ForgotPassword$update, msgPageForgotPassword, model.h2));
				case 66:
					var msgPageForgotPasswordSubmit = msg.a;
					return A3(
						$elm$core$Tuple$mapBoth,
						function (m2) {
							return _Utils_update(
								model,
								{h3: m2});
						},
						$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageForgotPasswordSubmit),
						A2($author$project$Page$ForgotPasswordSubmit$update, msgPageForgotPasswordSubmit, model.h3));
				case 67:
					var msgPageSample = msg.a;
					return A3(
						$elm$core$Tuple$mapBoth,
						function (m2) {
							return _Utils_update(
								model,
								{ia: m2});
						},
						$elm$core$Platform$Cmd$map($author$project$Msgs$MsgPageSample),
						A2($author$project$Page$Sample$update, msgPageSample, model.ia));
				case 45:
					var qGroupId = msg.a;
					var qGroupIdsSelected = model.gi.nG;
					var newQGroupIdsSelected = A2($elm$core$Set$member, qGroupId, qGroupIdsSelected) ? A2($elm$core$Set$remove, qGroupId, qGroupIdsSelected) : A2($elm$core$Set$insert, qGroupId, qGroupIdsSelected);
					var analysisByQuestionState = model.gi;
					var newAnalysisByQuestionState = _Utils_update(
						analysisByQuestionState,
						{nG: newQGroupIdsSelected});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{gi: newAnalysisByQuestionState}),
						$elm$core$Platform$Cmd$none);
				case 0:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 9:
					var qIdStrMaybe = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{is: qIdStrMaybe}),
						$elm$core$Platform$Cmd$none);
				case 46:
					var questionGroupState = msg.a;
					var cohort = msg.b;
					var groupTagMaybe = msg.c;
					var activityId = msg.d;
					var _v41 = model.j$;
					if (_v41.$ === 3) {
						var appCfg = _v41.a;
						var _v42 = model.fc;
						if (_v42.$ === 3) {
							var personE = _v42.a;
							var validQGroupIdsForYearLvl = $author$project$Helper$Util$unique(
								A2(
									$elm$core$List$map,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.lW;
										},
										$elm$core$String$fromInt),
									A2(
										$elm$core$List$filter,
										function (el) {
											return _Utils_eq(
												el.hB,
												$author$project$Type$Cohort$toInt(cohort));
										},
										appCfg.nH)));
							var meId = $author$project$Type$User$extractPersonEDetails(personE).lW;
							var hiddenExamGroups = A2(
								$elm$core$Maybe$withDefault,
								$elm$core$Dict$empty,
								A2($krisajenkins$remotedata$RemoteData$withDefault, $elm$core$Maybe$Nothing, model.hi));
							var updatedHiddenExamGroupsMaybe = A6($author$project$Type$HiddenExamGroup$updateHiddenExamGroupsMaybe, hiddenExamGroups, validQGroupIdsForYearLvl, questionGroupState, cohort, groupTagMaybe, activityId);
							if (updatedHiddenExamGroupsMaybe.$ === 1) {
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{hi: $krisajenkins$remotedata$RemoteData$Loading}),
									$author$project$Ports$sendDeleteHiddenExamGroups(meId));
							} else {
								var hids = updatedHiddenExamGroupsMaybe.a;
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{hi: $krisajenkins$remotedata$RemoteData$Loading}),
									$author$project$Ports$sendPutHiddenExamGroups(
										_Utils_Tuple2(
											meId,
											$author$project$DataService$hiddenExamGroupsEncoder(hids))));
							}
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					} else {
						return _Utils_Tuple2(
							$author$project$Update$resetModel(
								_Utils_update(
									model,
									{
										ll: A2(
											$author$project$Type$Error$makeError,
											'We were unable to access your application configureation data.',
											$elm$core$Maybe$Just('AppCfg was not \'Success _\''))
									})),
							$author$project$Ports$pushUrl(
								$author$project$Routing$withHash($author$project$Routing$smPaths.ll)));
					}
				case 18:
					var chartType = msg.a;
					if (!chartType) {
						var _v45 = _Utils_Tuple2(model.j$, model.h5.S);
						if ((_v45.a.$ === 3) && (_v45.b.$ === 3)) {
							var appCfg = _v45.a.a;
							var users = _v45.b.a;
							var filteredStudentCohort = A2(
								$elm$core$List$map,
								$author$project$Helper$Common$reduceHistoryToFirstAttempts,
								A2($author$project$Page$ManageUsers$toFilteredAssignees, model.h5, users));
							var yearLvl = A2(
								$elm$core$Maybe$withDefault,
								9,
								A2(
									$elm$core$Maybe$map,
									$author$project$Type$AppCfg$lvlForNapStyleExams,
									A2(
										$elm$core$Maybe$map,
										$author$project$Type$Cohort$toInt,
										A2(
											$elm$core$Maybe$andThen,
											function ($) {
												return $.hB;
											},
											A2(
												$elm$core$Maybe$map,
												function ($) {
													return $.dc;
												},
												$elm$core$List$head(filteredStudentCohort))))));
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										n_: $author$project$Routing$AnalyticsItemRoute(0)
									}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											$author$project$Ports$showChartByBand(
											$author$project$DataService$bandByResultsRecordEncoder(
												A4($author$project$Helper$AnalysisByBand$constructBandByResultsRecord, appCfg, model.gj, yearLvl, filteredStudentCohort))),
											$author$project$Ports$pushUrl(
											$author$project$Routing$createPath(0))
										])));
						} else {
							return _Utils_Tuple2(
								$author$project$Update$resetModel(
									_Utils_update(
										model,
										{
											ll: A2(
												$author$project$Type$Error$makeError,
												'We were unable to access your assignees and application data.',
												$elm$core$Maybe$Just('Either AppCfg or model.pageModel_ManageUsers.users was not \'Success _\''))
										})),
								$author$project$Ports$pushUrl(
									$author$project$Routing$withHash($author$project$Routing$smPaths.ll)));
						}
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 8:
					var sTime = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ln: 1,
								ox: $elm$core$Maybe$Just(sTime)
							}),
						$elm$core$Platform$Cmd$none);
				case 44:
					var resultTallyByQIdMaybe = msg.a;
					var analysisByQuestionState = model.gi;
					var newAnalysisByQuestionState = _Utils_update(
						analysisByQuestionState,
						{mK: resultTallyByQIdMaybe});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{gi: newAnalysisByQuestionState}),
						$elm$core$Platform$Cmd$none);
				case 16:
					var qGroupId = msg.a;
					var testletId = msg.b;
					var tstTime = msg.c;
					var _v46 = A2($author$project$Helper$Common$getTestletDetailForId, model, testletId);
					if (!_v46.$) {
						var testlet = _v46.a;
						var pageModel_ShowVariant = model.no;
						var newPageModel_ShowVariant = _Utils_update(
							pageModel_ShowVariant,
							{f8: $elm$core$Maybe$Nothing});
						var refreshedModel = _Utils_update(
							model,
							{
								gL: $krisajenkins$remotedata$RemoteData$NotAsked,
								kR: A2(
									$elm$core$Array$repeat,
									$elm$core$Array$length(testlet.ci),
									A2($author$project$Update$defaultQAnswerValues, testlet.mg, testlet.lW)),
								kS: $elm$core$Maybe$Nothing,
								kT: $elm$core$Maybe$Just(testlet.lW),
								kU: $elm$core$Maybe$Just(qGroupId),
								ln: 1,
								lo: tstTime,
								no: newPageModel_ShowVariant,
								nI: $elm$core$Array$fromList(
									$elm$core$Array$toList(testlet.ci)),
								ox: $elm$core$Maybe$Nothing
							});
						return _Utils_Tuple2(
							refreshedModel,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$core$Task$perform, $author$project$Msgs$RecordStartTime, $elm$time$Time$now),
										$author$project$Ports$pushUrl(
										$author$project$Routing$withHash($author$project$Routing$smPaths.it) + '/1')
									])));
					} else {
						var err = A2(
							$author$project$Type$Error$makeError,
							'There was a problem retrieving the question set for you.',
							$elm$core$Maybe$Just(
								'No question set was returned for testletId: ' + $elm$core$String$fromInt(testletId)));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ll: err}),
							$elm$core$Platform$Cmd$none);
					}
				case 7:
					var activityResultRecord = msg.a;
					var serr = $author$project$Type$ExamResult$ActivityRunResult(activityResultRecord);
					var pageModel_ShowWorksheets = model.ic;
					var newActivityDataGroup = A2(
						$elm$core$Maybe$map,
						function (el) {
							return A2(
								$author$project$Type$ActivityDataGroup$asActivityResultRecordsIn,
								el,
								A2($elm$core$List$cons, activityResultRecord, el.cy));
						},
						pageModel_ShowWorksheets.aZ);
					var newPageModel_ShowWorksheets = _Utils_update(
						pageModel_ShowWorksheets,
						{aZ: newActivityDataGroup});
					var _v47 = A3($author$project$Update$log, model, 'activityResultRecord', activityResultRecord);
					return A2(
						$elm$core$Tuple$mapFirst,
						function (mdl) {
							return _Utils_update(
								mdl,
								{ic: newPageModel_ShowWorksheets});
						},
						A2($author$project$Update$handleCommonSingleExamRunResultUpdates, model, serr));
				case 6:
					var serrMaybe = A2($author$project$Helper$Common$createSingleExamRunResultRecord, model, $elm$core$Maybe$Nothing);
					var resultsPath = function () {
						var maybeId = $author$project$Type$User$isRemoteMeAStudentE(model.fc) ? $author$project$Type$User$getMeId(model.fc) : $elm$core$Maybe$Just($author$project$Type$User$sampleStudentUserSub);
						var examQsRunIdMaybe = A2(
							$elm$core$Maybe$andThen,
							function (el) {
								if (!el.$) {
									return A2($elm$core$Maybe$map, $elm$core$String$fromInt, model.kU);
								} else {
									var actrr = el.a;
									return $elm$core$Maybe$Just(actrr.jz);
								}
							},
							serrMaybe);
						return A4(
							$elm$core$Maybe$map3,
							F3(
								function (uId, qGroupId, nowTime) {
									return '/' + (uId + ('/' + (qGroupId + ('/' + $author$project$Type$DateTime$posixToDecSecsString(nowTime)))));
								}),
							maybeId,
							examQsRunIdMaybe,
							model.hU);
					}();
					var _v48 = A3($author$project$Update$log, model, 'Update.elm: FinishExam: model is ', model);
					return A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Tuple$mapBoth,
								function (mdl) {
									return _Utils_update(
										mdl,
										{ll: $elm$core$Maybe$Nothing, ln: 2});
								},
								function (cmds) {
									return $elm$core$Platform$Cmd$batch(
										_List_fromArray(
											[
												$author$project$Ports$pushUrl(
												_Utils_ap(
													$author$project$Routing$withHash($author$project$Routing$smPaths.of),
													A2($elm$core$Maybe$withDefault, '///', resultsPath))),
												cmds
											]));
								}),
							A2(
								$elm$core$Maybe$map,
								$author$project$Update$handleCommonSingleExamRunResultUpdates(model),
								serrMaybe)));
				case 19:
					return _Utils_Tuple2(
						model,
						$author$project$Ports$pushUrl(
							$author$project$Routing$withHash($author$project$Routing$smPaths.om)));
				case 20:
					return _Utils_Tuple2(
						$author$project$Update$resetModel(model),
						$author$project$JsInterop$Plumbing$sendInfoOutside($author$project$JsInterop$Plumbing$SignOut));
				case 22:
					return _Utils_Tuple2(
						model,
						$author$project$Ports$sendRetrieveCredentials(0));
				case 23:
					return _Utils_Tuple2(
						model,
						$author$project$Ports$sendRefreshCredentials(0));
				case 21:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{gL: $krisajenkins$remotedata$RemoteData$Loading}),
						A2($author$project$Update$sendGetPost, model.lx.pz, 63510));
				case 5:
					var activitiesViewMode = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{f7: activitiesViewMode}),
						$elm$core$Platform$Cmd$none);
				case 3:
					var _v50 = A3($author$project$Update$log, model, 'about to', 'ScratchRequest');
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 4:
					var _v51 = A3($author$project$Update$log, model, 'about to', 'ScratchRequestResponse');
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 11:
					var nxtTestletMaybe = $author$project$Helper$Common$getNextTestletDetail(model);
					var currentTestlet = $author$project$Helper$Common$getCurrentTestletDetail(model);
					var currentTestletGrd = A2(
						$elm$core$Maybe$withDefault,
						0,
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.lG;
							},
							currentTestlet));
					if (nxtTestletMaybe.$ === 1) {
						var err = A2(
							$author$project$Type$Error$makeError,
							'There was a problem getting the next question set.',
							$elm$core$Maybe$Just('Trying to ProceedToNextSection, but the nxtTestlet was set to \'Nothing\''));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ll: err}),
							$elm$core$Platform$Cmd$none);
					} else {
						var nxtTestlet = nxtTestletMaybe.a;
						var handleLockedTypes = function () {
							var lockedAnsHistory = A2(
								$elm$core$Array$map,
								function (a) {
									return _Utils_update(
										a,
										{mj: true});
								},
								model.kR);
							return A2(
								$author$project$Update$handleNextTestletDetail,
								_Utils_update(
									model,
									{kR: lockedAnsHistory}),
								nxtTestlet);
						}();
						switch (currentTestletGrd) {
							case 0:
								var err = A2(
									$author$project$Type$Error$makeError,
									'There was a problem moving to the next question set.',
									$elm$core$Maybe$Just(
										'The current testlet should have a status of either Locked, LockedNoMoreCalculator, or Unlocked (since we\'re moving to the next testlet), but it has a status of None.  The nxtTestlet is: ' + $elm$core$String$fromInt(nxtTestlet.lW)));
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{ll: err}),
									$elm$core$Platform$Cmd$none);
							case 3:
								return A2($author$project$Update$handleNextTestletDetail, model, nxtTestlet);
							case 1:
								return handleLockedTypes;
							default:
								return handleLockedTypes;
						}
					}
				case 10:
					var newTime = msg.a;
					var tstTimeMaybe = function () {
						var examRunType = $author$project$Helper$Common$getExamRunType(model);
						return (!examRunType) ? A2(
							$elm$core$Maybe$andThen,
							function ($) {
								return $.ps;
							},
							A2(
								$elm$core$Maybe$andThen,
								A2(
									$elm$core$Basics$composeR,
									$elm$core$List$filter(
										function (el) {
											return _Utils_eq(
												$elm$core$Maybe$Just(el.lW),
												model.kU);
										}),
									$elm$core$List$head),
								A2(
									$elm$core$Maybe$map,
									function ($) {
										return $.nH;
									},
									$krisajenkins$remotedata$RemoteData$toMaybe(model.j$)))) : ((examRunType === 1) ? A2(
							$elm$core$Maybe$map,
							$elm$core$Basics$mul(60),
							A2(
								$elm$core$Maybe$andThen,
								function ($) {
									return $.fh;
								},
								model.no.aq)) : $elm$core$Maybe$Nothing);
					}();
					var start = model.ox;
					var nowTimeRaw_ = $elm$core$Maybe$Just(newTime);
					var now = nowTimeRaw_;
					var timeElapsed = A3(
						$elm$core$Maybe$map2,
						$elm$core$Basics$sub,
						A2($elm$core$Maybe$map, $author$project$Type$DateTime$posixToRoundedSecsInt, now),
						A2($elm$core$Maybe$map, $author$project$Type$DateTime$posixToRoundedSecsInt, start));
					var examTimeSecondsRemaining_ = A3($elm$core$Maybe$map2, $elm$core$Basics$sub, tstTimeMaybe, timeElapsed);
					var _v54 = function () {
						if (examTimeSecondsRemaining_.$ === 1) {
							return _Utils_Tuple2(1, false);
						} else {
							var examTimeSecsRemaining = examTimeSecondsRemaining_.a;
							return (examTimeSecsRemaining < 0) ? _Utils_Tuple2(2, true) : _Utils_Tuple2(1, false);
						}
					}();
					var examStatus_ = _v54.a;
					var isFinishExam = _v54.b;
					if (isFinishExam) {
						var _v56 = model.fQ;
						switch (_v56.$) {
							case 3:
								var $temp$msg = $author$project$Msgs$FinishExam,
									$temp$model = model;
								msg = $temp$msg;
								model = $temp$model;
								continue update_;
							case 2:
								var err = _v56.a;
								return _Utils_Tuple2(
									$author$project$Update$resetModel(
										_Utils_update(
											model,
											{
												ll: A2(
													$author$project$Type$Error$makeError,
													'We were unable to retrieve your user data. Specifically studentData',
													$elm$core$Maybe$Just(err))
											})),
									$author$project$Ports$pushUrl(
										$author$project$Routing$withHash($author$project$Routing$smPaths.ll)));
							default:
								if (A3($krisajenkins$remotedata$RemoteData$unwrap, false, $author$project$Type$User$isTeacherE, model.fc)) {
									var $temp$msg = $author$project$Msgs$FinishExam,
										$temp$model = model;
									msg = $temp$msg;
									model = $temp$model;
									continue update_;
								} else {
									return _Utils_Tuple2(
										$author$project$Update$resetModel(
											_Utils_update(
												model,
												{
													ll: A2(
														$author$project$Type$Error$makeError,
														'We were unable to retrieve your user data.',
														$elm$core$Maybe$Just('studentData was either \'NotAsked\' or \'Loading\''))
												})),
										$author$project$Ports$pushUrl(
											$author$project$Routing$withHash($author$project$Routing$smPaths.ll)));
								}
						}
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ln: examStatus_, lo: examTimeSecondsRemaining_, hU: nowTimeRaw_}),
							$elm$core$Platform$Cmd$none);
					}
				case 30:
					var response = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hi: response}),
						$elm$core$Platform$Cmd$none);
				case 39:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 33:
					var response = msg.a;
					if (response.$ === 2) {
						var f = response.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									ll: A2(
										$author$project$Type$Error$makeError,
										'We were unable to successfully save your user details.',
										$elm$core$Maybe$Just(f))
								}),
							$author$project$Ports$pushUrl(
								$author$project$Routing$withHash($author$project$Routing$smPaths.ll)));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 34:
					var response = msg.a;
					if (response.$ === 2) {
						var f = response.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									ll: A2(
										$author$project$Type$Error$makeError,
										'We were unable to successfully update your assignee details.',
										$elm$core$Maybe$Just(f))
								}),
							$author$project$Ports$pushUrl(
								$author$project$Routing$withHash($author$project$Routing$smPaths.ll)));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 31:
					var response = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hi: response}),
						$elm$core$Platform$Cmd$none);
				case 32:
					var response = msg.a;
					switch (response.$) {
						case 2:
							var f = response.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										ll: A2(
											$author$project$Type$Error$makeError,
											'We were unable to successfully update your list hidden exams (Attempting to delete)',
											$elm$core$Maybe$Just(f))
									}),
								$author$project$Ports$pushUrl(
									$author$project$Routing$withHash($author$project$Routing$smPaths.ll)));
						case 3:
							var resp = response.a;
							return resp.mm ? _Utils_Tuple2(
								_Utils_update(
									model,
									{
										hi: $krisajenkins$remotedata$RemoteData$Success($elm$core$Maybe$Nothing)
									}),
								$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
								_Utils_update(
									model,
									{
										ll: A2(
											$author$project$Type$Error$makeError,
											A2($elm$core$Maybe$withDefault, 'Unable to update your list of hidden exams.', resp.pE),
											resp.lb)
									}),
								$author$project$Ports$pushUrl(
									$author$project$Routing$withHash($author$project$Routing$smPaths.ll)));
						default:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 13:
					var userText = msg.a;
					var techText = msg.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ll: A2(
									$author$project$Type$Error$makeError,
									userText,
									$elm$core$Maybe$Just(techText)),
								n_: $author$project$Routing$FatalErrorRoute
							}),
						$author$project$Ports$pushUrl(
							$author$project$Routing$withHash($author$project$Routing$smPaths.ll)));
				case 17:
					var response = msg.a;
					var _v60 = function () {
						if (A3($krisajenkins$remotedata$RemoteData$unwrap, false, $author$project$Type$User$isTeacherE, model.fc)) {
							var schoolName = A2(
								$elm$core$Result$withDefault,
								'Unknown School',
								$author$project$Helper$Common$getSchoolName(model));
							return A2(
								$author$project$Update$update,
								$author$project$Msgs$OnGetSchoolNameResponse(schoolName),
								model);
						} else {
							return _Utils_Tuple2(
								model,
								$author$project$Ports$sendGetSchoolName(0));
						}
					}();
					var model1 = _v60.a;
					var cmd1 = _v60.b;
					var _v61 = (model.ln === 1) ? $author$project$Update$addGetPostCmdIfAppropriate(
						_Utils_Tuple2(model1, cmd1)) : _Utils_Tuple2(model1, cmd1);
					var augmentedModel = _v61.a;
					var augmentedCmds = _v61.b;
					var _v62 = A2($author$project$Update$log, model, 'In GetAppCfgNonS3Response (originates Main.elm)');
					return _Utils_Tuple2(
						_Utils_update(
							augmentedModel,
							{j$: response}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($author$project$Update$sendGetPostMetas, model.lx.pB, model.lx.pL),
									augmentedCmds
								])));
				case 26:
					var schoolName = msg.a;
					var userType_ = $elm$core$Maybe$Nothing;
					var googleAnalyticsInitializationRecord = $author$project$DataService$googleAnalyticsInitializationRecordEncoder(
						A4(
							$author$project$Models$GoogleAnalyticsInitializationRecord,
							$elm$core$Maybe$Nothing,
							schoolName,
							userType_,
							A3(
								$krisajenkins$remotedata$RemoteData$unwrap,
								$elm$core$Maybe$Nothing,
								A2(
									$elm$core$Basics$composeR,
									$author$project$Type$User$getStudentETypeMe,
									$elm$core$Maybe$map(
										function ($) {
											return $.kA;
										})),
								model.fc)));
					var _v63 = A3($author$project$Update$log, model, 'Update.elm: OnGetSchoolNameResponse: schoolName is ', schoolName);
					return _Utils_Tuple2(
						model,
						$author$project$Ports$sendInitializeGoogleAnalytics(googleAnalyticsInitializationRecord));
				case 38:
					return _Utils_Tuple2(
						$author$project$Update$cleanAnyPreviousError(model),
						$author$project$Ports$mathJaxTypeset(0));
				case 27:
					var response = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{gL: response}),
						$author$project$Ports$mathJaxTypeset(0));
				case 28:
					var response = msg.a;
					var postResp = A2(
						$krisajenkins$remotedata$RemoteData$mapError,
						$author$project$Helper$Util$httpErrorToString,
						$krisajenkins$remotedata$RemoteData$fromResult(response));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{gL: postResp}),
						$author$project$Ports$decodeContent(
							A3(
								$krisajenkins$remotedata$RemoteData$unwrap,
								'',
								function ($) {
									return $.kF;
								},
								postResp)));
				case 29:
					var postMetasRemoteData = msg.a;
					var nodeCfg = model.mX;
					var newNodeCfg = _Utils_update(
						nodeCfg,
						{fs: postMetasRemoteData});
					var cmd = function () {
						if (postMetasRemoteData.$ === 3) {
							var postMetas = postMetasRemoteData.a;
							return $author$project$JsInterop$Plumbing$sendInfoOutside(
								$author$project$JsInterop$Plumbing$PursBundleInterop(
									$author$project$JsInterop$Plumbing$OpGetSyllabuses(
										$author$project$Type$NodeCfg$getCategories(postMetas))));
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}();
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{mX: newNodeCfg}),
						cmd);
				case 25:
					var decodedContent = msg.a;
					var currPost = model.gL;
					var decodedPost = A2(
						$krisajenkins$remotedata$RemoteData$map,
						function (el) {
							return _Utils_update(
								el,
								{kF: decodedContent});
						},
						currPost);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{gL: decodedPost}),
						$author$project$Ports$mathJaxTypeset(0));
				case 35:
					var resp = msg.a;
					var nodeCfg = model.mX;
					var newNodeCfg = _Utils_update(
						nodeCfg,
						{jJ: resp.jJ});
					var newModel = _Utils_update(
						model,
						{
							kR: resp.kR,
							kS: resp.eN.kS,
							kT: resp.eN.kT,
							kU: resp.eN.kU,
							ln: (!_Utils_eq(model.n_, $author$project$Routing$HomeRoute)) ? resp.ln : model.ln,
							lo: resp.lo,
							mX: newNodeCfg,
							no: resp.no,
							nI: resp.nI,
							ox: resp.ox,
							pC: $elm$core$Maybe$Just(resp.pC)
						});
					var _v65 = A3($author$project$Update$log, model, 'OnRetrievePartialModelState', resp);
					return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
				case 2:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{hs: !model.hs}),
						$elm$core$Platform$Cmd$none);
				case 47:
					var internalUrlFragment = msg.a;
					return _Utils_Tuple2(
						model,
						$author$project$Ports$pushUrl(internalUrlFragment));
				case 14:
					var urlHashPath = msg.a;
					var qNbrMaybe = msg.b;
					if (qNbrMaybe.$ === 1) {
						return _Utils_Tuple2(
							model,
							$author$project$Ports$pushUrl(urlHashPath));
					} else {
						var qNbr = qNbrMaybe.a;
						if (_Utils_cmp(
							qNbr,
							$elm$core$Array$length(model.nI)) > 0) {
							var nxtTestlet = $author$project$Helper$Common$getNextTestletDetail(model);
							var currentTestlet = $author$project$Helper$Common$getCurrentTestletDetail(model);
							var currentTestletGrd = function () {
								if (currentTestlet.$ === 1) {
									return 0;
								} else {
									var currentTestlet_ = currentTestlet.a;
									return currentTestlet_.lG;
								}
							}();
							if (nxtTestlet.$ === 1) {
								var err = A2(
									$author$project$Type$Error$makeError,
									'There was a problem getting the next question set.',
									$elm$core$Maybe$Just(
										'Trying to \'LinkTo\', but the nxtTestlet was set to \'Nothing\' with qNbr: ' + $elm$core$String$fromInt(qNbr)));
								return _Utils_Tuple2(
									_Utils_update(
										model,
										{ll: err}),
									$elm$core$Platform$Cmd$none);
							} else {
								var nxtTestlet_ = nxtTestlet.a;
								if (!currentTestletGrd) {
									return A2(
										$author$project$Update$handleNextTestletDetail,
										_Utils_update(
											model,
											{
												kS: $elm$core$Maybe$Just(qNbr)
											}),
										nxtTestlet_);
								} else {
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{
												kS: $elm$core$Maybe$Just(qNbr)
											}),
										$elm$core$Platform$Cmd$batch(
											_List_fromArray(
												[
													$author$project$Ports$pushUrl(
													$author$project$Routing$withHash($author$project$Routing$smPaths.gx))
												])));
								}
							}
						} else {
							return A3($author$project$Update$loadCurrQstnUpdateResponse, model, urlHashPath, qNbr);
						}
					}
				case 48:
					var newRoute = msg.a;
					var defaultAction = _Utils_Tuple2(
						_Utils_update(
							model,
							{n_: newRoute}),
						$elm$core$Platform$Cmd$none);
					var _v70 = A3($author$project$Update$log, model, 'UrlChanged with: ', newRoute);
					var _v71 = model.j$;
					if (_v71.$ === 3) {
						var updateResponse = function () {
							switch (newRoute.$) {
								case 0:
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{ln: 0, n_: newRoute}),
										$elm$core$Platform$Cmd$none);
								case 29:
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{f7: $author$project$Type$Configuration$TeacherPreviewMode, n_: newRoute}),
										$elm$core$Platform$Cmd$none);
								case 1:
									var qNbr = newRoute.a;
									var postId = A2($elm$core$Array$get, qNbr - 1, model.nI);
									if (postId.$ === 1) {
										var err = A2(
											$author$project$Type$Error$makeError,
											'We were unable to find a valid question for you ... the question number in the URL seems wrong.',
											$elm$core$Maybe$Just(
												'No question could be obtained for qNbr: ' + $elm$core$String$fromInt(qNbr)));
										return _Utils_Tuple2(
											_Utils_update(
												model,
												{ll: err}),
											$elm$core$Platform$Cmd$none);
									} else {
										var postId_ = postId.a;
										return _Utils_Tuple2(
											_Utils_update(
												model,
												{
													gL: $krisajenkins$remotedata$RemoteData$Loading,
													kS: $elm$core$Maybe$Just(qNbr),
													n_: newRoute
												}),
											A2($author$project$Update$sendGetPost, model.lx.pz, postId_));
									}
								case 2:
									var postId = newRoute.a;
									var pageModel_ShowVariant = $author$project$Page$ShowVariant$init;
									var newPageModel_ShowVariant = _Utils_update(
										pageModel_ShowVariant,
										{aL: $krisajenkins$remotedata$RemoteData$Loading});
									var cmd = A2($elm$core$String$contains, 'v', postId) ? A4(
										$author$project$PortImpl$sendGetEncodedVariantContent,
										model.lx.pB,
										A2($elm$core$Basics$composeR, $author$project$Page$ShowVariant$OnGetEncodedVariantContent, $author$project$Msgs$MsgPageShowVariant),
										model.lx.pL,
										postId) : A2(
										$elm$core$Maybe$withDefault,
										$elm$core$Platform$Cmd$none,
										A2(
											$elm$core$Maybe$map,
											$author$project$Update$sendGetPost(model.lx.pz),
											$elm$core$String$toInt(postId)));
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{gL: $krisajenkins$remotedata$RemoteData$Loading, hs: false, no: newPageModel_ShowVariant, is: $elm$core$Maybe$Nothing, n_: newRoute}),
										cmd);
								case 22:
									return function (newPmdl) {
										return _Utils_Tuple2(
											_Utils_update(
												model,
												{h5: newPmdl, n_: newRoute}),
											$elm$core$Platform$Cmd$none);
									}(
										function (pmdl) {
											return _Utils_update(
												pmdl,
												{bJ: 1});
										}(model.h5));
								case 23:
									return function (newPmdl) {
										return _Utils_Tuple2(
											_Utils_update(
												model,
												{h5: newPmdl, n_: newRoute}),
											$elm$core$Platform$Cmd$none);
									}(
										function (pmdl) {
											return _Utils_update(
												pmdl,
												{bJ: 0});
										}(model.h5));
								case 24:
									var pageModel_MutateUsers = model.h7;
									var newPageModel_MutateUsers = _Utils_update(
										pageModel_MutateUsers,
										{bJ: 0});
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{h7: newPageModel_MutateUsers, n_: newRoute}),
										$elm$core$Platform$Cmd$none);
								case 25:
									return function (newPmdl) {
										return _Utils_Tuple2(
											_Utils_update(
												model,
												{h7: newPmdl, n_: newRoute}),
											$elm$core$Platform$Cmd$none);
									}(
										function (pmdl) {
											return _Utils_update(
												pmdl,
												{bJ: 1});
										}(model.h7));
								case 26:
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{n_: newRoute}),
										$elm$core$Platform$Cmd$none);
								case 12:
									var studentEId = newRoute.a;
									var studentEMaybe = $elm$core$List$head(
										A2(
											$elm$core$List$filter,
											function (stud) {
												return _Utils_eq(stud.lW, studentEId);
											},
											A2(
												$elm$core$List$filterMap,
												$author$project$Type$User$extractStudentEFromPersonE,
												A2(
													$elm$core$List$map,
													$author$project$Page$ManageUsers$extractPersonEFromUser,
													A2($krisajenkins$remotedata$RemoteData$withDefault, _List_Nil, model.h5.S)))));
									var pageModel_ReportCard = model.h9;
									var newPageModel_ReportCard = _Utils_update(
										pageModel_ReportCard,
										{fR: studentEMaybe});
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{h9: newPageModel_ReportCard, n_: newRoute}),
										$elm$core$Platform$Cmd$none);
								case 17:
									var idMaybe = newRoute.a;
									var user = function () {
										if (!idMaybe.$) {
											var id = idMaybe.a;
											var _v78 = model.h5.S;
											if (_v78.$ === 3) {
												var users = _v78.a;
												return A2(
													$elm$core$Maybe$withDefault,
													$krisajenkins$remotedata$RemoteData$Failure('Could not find the profile for the user you specified'),
													A2(
														$elm$core$Maybe$map,
														$krisajenkins$remotedata$RemoteData$Success,
														A2(
															$author$project$Type$User$findPersonE,
															A2($elm$core$List$map, $author$project$Page$ManageUsers$extractPersonEFromUser, users),
															id)));
											} else {
												return $krisajenkins$remotedata$RemoteData$Failure('Could not successfully access the list of users');
											}
										} else {
											return model.fc;
										}
									}();
									var teachersCmd = $author$project$JsInterop$Plumbing$sendInfoOutside(
										A2(
											$author$project$JsInterop$Plumbing$GraphQL,
											$author$project$JsInterop$Plumbing$TeachersGQL,
											A2($author$project$JsInterop$Plumbing$getGraphQLQuery, model.lx.lF, $author$project$JsInterop$Plumbing$TeachersGQL)));
									var studentsByTchIdCmd = A2(
										$elm$core$Maybe$withDefault,
										$elm$core$Platform$Cmd$none,
										A2(
											$elm$core$Maybe$map,
											function (id) {
												return $author$project$JsInterop$Plumbing$sendInfoOutside(
													A2(
														$author$project$JsInterop$Plumbing$GraphQL,
														$author$project$JsInterop$Plumbing$StudentsByTchIdGQL(id),
														A2(
															$author$project$JsInterop$Plumbing$getGraphQLQuery,
															model.lx.lF,
															$author$project$JsInterop$Plumbing$StudentsByTchIdGQL(id))));
											},
											idMaybe));
									var pageModel_Profile = $author$project$Page$Profile$init(user);
									var new_pageModel_Profile_ = _Utils_update(
										pageModel_Profile,
										{
											bA: _Utils_eq(user, model.fc)
										});
									var _v76 = A3(
										$krisajenkins$remotedata$RemoteData$unwrap,
										false,
										A2($elm$core$Basics$composeR, $author$project$Type$User$isStudentE, $elm$core$Basics$not),
										model.fc) ? (A3($krisajenkins$remotedata$RemoteData$unwrap, false, $author$project$Type$User$isStudentE, user) ? _Utils_Tuple2(
										_Utils_update(
											new_pageModel_Profile_,
											{da: $krisajenkins$remotedata$RemoteData$Loading}),
										teachersCmd) : _Utils_Tuple2(
										_Utils_update(
											new_pageModel_Profile_,
											{d2: $krisajenkins$remotedata$RemoteData$Loading}),
										studentsByTchIdCmd)) : _Utils_Tuple2(new_pageModel_Profile_, $elm$core$Platform$Cmd$none);
									var new_pageModel_Profile = _v76.a;
									var cmd = _v76.b;
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{h8: new_pageModel_Profile, n_: newRoute}),
										cmd);
								default:
									return defaultAction;
							}
						}();
						if (newRoute.$ === 6) {
							var syllabusStr = newRoute.a;
							var activeSyllabusTitle = A2(
								$elm$core$Maybe$map,
								$author$project$Type$Cohort$toCurrentSyllabusTitle,
								$author$project$Type$Cohort$fromStringToMaybeCohort(syllabusStr));
							var _v73 = updateResponse;
							var model_ = _v73.a;
							var cmd_ = _v73.b;
							var newNodeCfg = function (el) {
								return _Utils_update(
									el,
									{jJ: activeSyllabusTitle});
							}(model_.mX);
							var newCmd = $elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										cmd_,
										$author$project$Update$makeConstructNodeForSyllabusCmd(newNodeCfg)
									]));
							var newModel = _Utils_update(
								model_,
								{f7: $author$project$Type$Configuration$TeacherPreviewMode, mX: newNodeCfg});
							return _Utils_Tuple2(newModel, newCmd);
						} else {
							return updateResponse;
						}
					} else {
						return defaultAction;
					}
				case 15:
					var examResult = msg.a;
					var urlPathItems = function () {
						if (!examResult.$) {
							var questionResultItem = examResult.a;
							var qAnswerStatusString = $author$project$Type$ExamResult$questionResultEncodeToString(questionResultItem.dW);
							var _v80 = function () {
								var _v81 = questionResultItem.iu;
								if (!_v81.$) {
									if (!_v81.a.$) {
										var idx = _v81.a.a;
										return _Utils_Tuple2(
											'mc',
											$elm$core$String$fromInt(idx));
									} else {
										var resString = _v81.a.a;
										return _Utils_Tuple2('sa', resString);
									}
								} else {
									return _Utils_Tuple2('-', '-');
								}
							}();
							var qType = _v80.a;
							var val = _v80.b;
							return _List_fromArray(
								[
									$elm$core$String$fromInt(questionResultItem.dS),
									qAnswerStatusString,
									qType,
									val
								]);
						} else {
							var actrr = examResult.a;
							var qAnswerStatusString = $author$project$Type$History$vAnswerEncodeToString(actrr.dW);
							var _v82 = function () {
								var _v83 = actrr.dW;
								if (!_v83.$) {
									switch (_v83.a.$) {
										case 0:
											var _v84 = _v83.a.a;
											var idx = _v84.a;
											return _Utils_Tuple2(
												'mc',
												$elm$core$String$fromInt(idx));
										case 1:
											var _v85 = _v83.a.a;
											var idxs = _v85.a;
											return _Utils_Tuple2(
												'mcma',
												$author$project$Routing$intsAsJsonString(idxs));
										default:
											var _v86 = _v83.a.a;
											var strMaybes = _v86.a;
											return _Utils_Tuple2(
												'abox',
												$author$project$Routing$stringMaybesAsJsonString(strMaybes));
									}
								} else {
									return _Utils_Tuple2('-', '-');
								}
							}();
							var qType = _v82.a;
							var val = _v82.b;
							return _List_fromArray(
								[
									$author$project$Type$Variant$toShortStringVariantId(actrr.pK),
									qAnswerStatusString,
									qType,
									val
								]);
						}
					}();
					return _Utils_Tuple2(
						model,
						$author$project$Ports$pushUrl(
							$elm$core$String$concat(
								A2(
									$elm$core$List$intersperse,
									'/',
									A2(
										$elm$core$List$cons,
										$author$project$Routing$withHash($author$project$Routing$smPaths.dS),
										urlPathItems)))));
				case 1:
					return _Utils_Tuple2(
						model,
						$author$project$Ports$browserBack(0));
				case 12:
					var nextQuestion = $author$project$Helper$Common$getNextQuestion(model);
					if (!nextQuestion.$) {
						return A2(
							$author$project$Update$update,
							A2(
								$author$project$Msgs$LinkTo,
								$author$project$Routing$withHash($author$project$Routing$smPaths.it),
								nextQuestion),
							model);
					} else {
						var err = A2(
							$author$project$Type$Error$makeError,
							'There was a problem getting the next question to show.',
							$elm$core$Maybe$Just('Trying to \'ShowNextQuestion\', but the nextQuestion was set to \'Nothing\''));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ll: err}),
							$elm$core$Platform$Cmd$none);
					}
				case 24:
					var fn = function (currentAnswerElementDetails) {
						return _Utils_update(
							currentAnswerElementDetails,
							{mi: !currentAnswerElementDetails.mi});
					};
					return A2($author$project$Update$updateCurrentAnswerElementDetails, fn, model);
				case 36:
					var _v88 = msg.a;
					var aIdx = _v88.a;
					var isCorrect = _v88.b;
					var fn = function (currentAnswerElementDetails) {
						return _Utils_update(
							currentAnswerElementDetails,
							{
								jC: isCorrect ? 2 : 1,
								jD: $elm$core$Maybe$Just(
									$author$project$Type$ExamResult$MultiChoiceIndex(aIdx))
							});
					};
					return A2($author$project$Update$updateCurrentAnswerElementDetails, fn, model);
				case 37:
					var ansTxt = msg.a;
					var _v89 = model.gL;
					if (_v89.$ === 3) {
						var currPost = _v89.a;
						var expectedVal = A2(
							$elm$core$Maybe$andThen,
							$elm$core$String$toFloat,
							A2(
								$elm$core$Maybe$map,
								$elm$core$String$filter(
									function (a) {
										return A2(
											$elm$regex$Regex$contains,
											A2(
												$elm$core$Maybe$withDefault,
												$elm$regex$Regex$never,
												$elm$regex$Regex$fromString('[\\-.0-9]{1}')),
											$elm$core$String$fromChar(a));
									}),
								A2(
									$elm$core$Maybe$map,
									function ($) {
										return $.mA;
									},
									$author$project$Helper$Common$unwrap(
										A2(
											$elm$core$Maybe$map,
											$elm$core$List$head,
											A2(
												$elm$core$Maybe$map,
												A2(
													$elm$regex$Regex$findAtMost,
													1,
													A2(
														$elm$core$Maybe$withDefault,
														$elm$regex$Regex$never,
														$elm$regex$Regex$fromString('[-. ,0-9]+'))),
												A2(
													$elm$core$Maybe$map,
													$elm$core$String$filter(
														$elm$core$Basics$neq('\\')),
													$elm$core$List$head(
														A2(
															$elm$core$List$drop,
															1,
															A2(
																$elm$core$String$split,
																'`',
																A2($author$project$Helper$Common$extractContent, currPost, 1)))))))))));
						var actualVal = $elm$core$String$toFloat(ansTxt);
						var retVal = function () {
							var fn = function (currentAnswerElementDetails) {
								return _Utils_update(
									currentAnswerElementDetails,
									{
										jC: ($elm$core$String$isEmpty(ansTxt) || _Utils_eq(expectedVal, $elm$core$Maybe$Nothing)) ? 0 : (_Utils_eq(expectedVal, actualVal) ? 2 : 1),
										jD: $elm$core$String$isEmpty(ansTxt) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
											$author$project$Type$ExamResult$SingleDataBox(ansTxt))
									});
							};
							return A2($author$project$Update$updateCurrentAnswerElementDetails, fn, model);
						}();
						return retVal;
					} else {
						return _Utils_Tuple2(
							$author$project$Update$resetModel(
								_Utils_update(
									model,
									{
										ll: A2(
											$author$project$Type$Error$makeError,
											'We were unable to find the current post.',
											$elm$core$Maybe$Just('Trying to compare expected vs actual answers, but currPost is set to \'Nothing\'')),
										n_: $author$project$Routing$FatalErrorRoute
									})),
							$author$project$Ports$pushUrl(
								$author$project$Routing$withHash($author$project$Routing$smPaths.ll)));
					}
				case 40:
					var newQuery = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{t: newQuery}),
						$elm$core$Platform$Cmd$none);
				case 41:
					var bandToToggle = msg.a;
					var analysisByQuestionState = model.gi;
					var currBandsToHide = analysisByQuestionState.nJ;
					var newBandsToHide = A2($elm$core$Set$member, bandToToggle, currBandsToHide) ? A2($elm$core$Set$remove, bandToToggle, currBandsToHide) : A2($elm$core$Set$insert, bandToToggle, currBandsToHide);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								gi: _Utils_update(
									analysisByQuestionState,
									{nJ: newBandsToHide})
							}),
						$elm$core$Platform$Cmd$none);
				case 43:
					var newTableState = msg.a;
					var analysisByQuestionState = model.gi;
					var newAnalysisByQuestionState = _Utils_update(
						analysisByQuestionState,
						{fT: newTableState});
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{gi: newAnalysisByQuestionState}),
						$elm$core$Platform$Cmd$none);
				case 42:
					var newState = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{fT: newState}),
						$elm$core$Platform$Cmd$none);
				default:
					var msgToolCalc = msg.a;
					return A3(
						$elm$core$Tuple$mapBoth,
						function (m2) {
							return _Utils_update(
								model,
								{je: m2});
						},
						$elm$core$Platform$Cmd$map($author$project$Msgs$MsgToolCalc),
						A2($author$project$UITool$Calculator$update, msgToolCalc, model.je));
			}
		}
	});
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$View$View$getAssigneeMaybe = F2(
	function (model, studentSub) {
		return A2(
			$elm$core$Maybe$andThen,
			$elm$core$List$head,
			A2(
				$elm$core$Maybe$map,
				$elm$core$List$filter(
					function (el) {
						return _Utils_eq(el.dc.pH, studentSub);
					}),
				A2(
					$elm$core$Maybe$map,
					$author$project$Page$ManageUsers$toFilteredAssignees(model.h5),
					$krisajenkins$remotedata$RemoteData$toMaybe(model.h5.S))));
	});
var $mdgriffith$elm_ui$Internal$Model$AlignX = function (a) {
	return {$: 6, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Left = 0;
var $mdgriffith$elm_ui$Element$alignLeft = $mdgriffith$elm_ui$Internal$Model$AlignX(0);
var $mdgriffith$elm_ui$Internal$Model$Right = 2;
var $mdgriffith$elm_ui$Element$alignRight = $mdgriffith$elm_ui$Internal$Model$AlignX(2);
var $mdgriffith$elm_ui$Internal$Model$Button = {$: 8};
var $mdgriffith$elm_ui$Internal$Model$Describe = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $mdgriffith$elm_ui$Element$Input$enter = 'Enter';
var $mdgriffith$elm_ui$Internal$Model$NoAttribute = {$: 0};
var $mdgriffith$elm_ui$Element$Input$hasFocusStyle = function (attr) {
	if (((attr.$ === 4) && (attr.b.$ === 11)) && (!attr.b.a)) {
		var _v1 = attr.b;
		var _v2 = _v1.a;
		return true;
	} else {
		return false;
	}
};
var $mdgriffith$elm_ui$Element$Input$focusDefault = function (attrs) {
	return A2($elm$core$List$any, $mdgriffith$elm_ui$Element$Input$hasFocusStyle, attrs) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Internal$Model$htmlClass('focusable');
};
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $mdgriffith$elm_ui$Element$Events$onClick = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Events$onClick);
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $mdgriffith$elm_ui$Element$Input$onKeyLookup = function (lookup) {
	var decode = function (code) {
		var _v0 = lookup(code);
		if (_v0.$ === 1) {
			return $elm$json$Json$Decode$fail('No key matched');
		} else {
			var msg = _v0.a;
			return $elm$json$Json$Decode$succeed(msg);
		}
	};
	var isKey = A2(
		$elm$json$Json$Decode$andThen,
		decode,
		A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string));
	return $mdgriffith$elm_ui$Internal$Model$Attr(
		A2(
			$elm$html$Html$Events$preventDefaultOn,
			'keydown',
			A2(
				$elm$json$Json$Decode$map,
				function (fired) {
					return _Utils_Tuple2(fired, true);
				},
				isKey)));
};
var $mdgriffith$elm_ui$Internal$Model$Class = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$cursor = $mdgriffith$elm_ui$Internal$Flag$flag(21);
var $mdgriffith$elm_ui$Element$pointer = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$cursor, $mdgriffith$elm_ui$Internal$Style$classes.kV);
var $mdgriffith$elm_ui$Element$Input$space = ' ';
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $mdgriffith$elm_ui$Element$Input$button = F2(
	function (attrs, _v0) {
		var onPress = _v0.ai;
		var label = _v0.bD;
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.dm + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.a1 + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.oa + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.hT)))))),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$pointer,
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$Input$focusDefault(attrs),
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$Describe($mdgriffith$elm_ui$Internal$Model$Button),
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Internal$Model$Attr(
											$elm$html$Html$Attributes$tabindex(0)),
										function () {
											if (onPress.$ === 1) {
												return A2(
													$elm$core$List$cons,
													$mdgriffith$elm_ui$Internal$Model$Attr(
														$elm$html$Html$Attributes$disabled(true)),
													attrs);
											} else {
												var msg = onPress.a;
												return A2(
													$elm$core$List$cons,
													$mdgriffith$elm_ui$Element$Events$onClick(msg),
													A2(
														$elm$core$List$cons,
														$mdgriffith$elm_ui$Element$Input$onKeyLookup(
															function (code) {
																return _Utils_eq(code, $mdgriffith$elm_ui$Element$Input$enter) ? $elm$core$Maybe$Just(msg) : (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$space) ? $elm$core$Maybe$Just(msg) : $elm$core$Maybe$Nothing);
															}),
														attrs));
											}
										}()))))))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var $mdgriffith$elm_ui$Internal$Model$CenterX = 1;
var $mdgriffith$elm_ui$Element$centerX = $mdgriffith$elm_ui$Internal$Model$AlignX(1);
var $author$project$Type$Cohort$cohorts = _List_fromArray(
	[
		$author$project$Type$Cohort$Aus(
		$author$project$Type$Cohort$Stage2(0)),
		$author$project$Type$Cohort$Aus(
		$author$project$Type$Cohort$Stage2(1)),
		$author$project$Type$Cohort$Aus(
		$author$project$Type$Cohort$Stage3(0)),
		$author$project$Type$Cohort$Aus(
		$author$project$Type$Cohort$Stage3(1)),
		$author$project$Type$Cohort$Aus(
		$author$project$Type$Cohort$Stage4(0)),
		$author$project$Type$Cohort$Aus(
		$author$project$Type$Cohort$Stage4(1)),
		$author$project$Type$Cohort$Aus(
		$author$project$Type$Cohort$Stage5(0)),
		$author$project$Type$Cohort$Aus(
		$author$project$Type$Cohort$Stage5(1)),
		$author$project$Type$Cohort$MS
	]);
var $mdgriffith$elm_ui$Internal$Model$Colored = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$StyleClass = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$bgColor = $mdgriffith$elm_ui$Internal$Flag$flag(8);
var $mdgriffith$elm_ui$Internal$Model$formatColorClass = function (_v0) {
	var red = _v0.a;
	var green = _v0.b;
	var blue = _v0.c;
	var alpha = _v0.d;
	return $mdgriffith$elm_ui$Internal$Model$floatClass(red) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(green) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(blue) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(alpha))))));
};
var $mdgriffith$elm_ui$Element$Background$color = function (clr) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$bgColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'bg-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(clr),
			'background-color',
			clr));
};
var $mdgriffith$elm_ui$Internal$Flag$fontColor = $mdgriffith$elm_ui$Internal$Flag$flag(14);
var $mdgriffith$elm_ui$Element$Font$color = function (fontColor) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'fc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(fontColor),
			'color',
			fontColor));
};
var $mdgriffith$elm_ui$Element$el = F2(
	function (attrs, child) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
					attrs)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[child])));
	});
var $mdgriffith$elm_ui$Internal$Model$Fill = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Element$fill = $mdgriffith$elm_ui$Internal$Model$Fill(1);
var $mdgriffith$elm_ui$Element$htmlAttribute = $mdgriffith$elm_ui$Internal$Model$Attr;
var $author$project$JsInterop$Types$isNapAdminFromUserDetails = function (userDetails) {
	return A2($elm$core$List$member, 'NapAdmin', userDetails.lL);
};
var $author$project$Type$ReaderConfig$isNapAdmin = function (rState) {
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2($elm$core$Maybe$map, $author$project$JsInterop$Types$isNapAdminFromUserDetails, rState.pC));
};
var $mdgriffith$elm_ui$Element$Font$italic = $mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.mn);
var $mdgriffith$elm_ui$Internal$Model$Hover = 1;
var $mdgriffith$elm_ui$Internal$Model$PseudoSelector = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$hover = $mdgriffith$elm_ui$Internal$Flag$flag(33);
var $mdgriffith$elm_ui$Internal$Model$AlignY = function (a) {
	return {$: 5, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Nearby = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$TransformComponent = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$Empty = {$: 3};
var $mdgriffith$elm_ui$Internal$Model$map = F2(
	function (fn, el) {
		switch (el.$) {
			case 1:
				var styled = el.a;
				return $mdgriffith$elm_ui$Internal$Model$Styled(
					{
						lV: F2(
							function (add, context) {
								return A2(
									$elm$virtual_dom$VirtualDom$map,
									fn,
									A2(styled.lV, add, context));
							}),
						i1: styled.i1
					});
			case 0:
				var html = el.a;
				return $mdgriffith$elm_ui$Internal$Model$Unstyled(
					A2(
						$elm$core$Basics$composeL,
						$elm$virtual_dom$VirtualDom$map(fn),
						html));
			case 2:
				var str = el.a;
				return $mdgriffith$elm_ui$Internal$Model$Text(str);
			default:
				return $mdgriffith$elm_ui$Internal$Model$Empty;
		}
	});
var $elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var $mdgriffith$elm_ui$Internal$Model$mapAttrFromStyle = F2(
	function (fn, attr) {
		switch (attr.$) {
			case 0:
				return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
			case 2:
				var description = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Describe(description);
			case 6:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$AlignX(x);
			case 5:
				var y = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$AlignY(y);
			case 7:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Width(x);
			case 8:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Height(x);
			case 3:
				var x = attr.a;
				var y = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$Class, x, y);
			case 4:
				var flag = attr.a;
				var style = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$StyleClass, flag, style);
			case 9:
				var location = attr.a;
				var elem = attr.b;
				return A2(
					$mdgriffith$elm_ui$Internal$Model$Nearby,
					location,
					A2($mdgriffith$elm_ui$Internal$Model$map, fn, elem));
			case 1:
				var htmlAttr = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Attr(
					A2($elm$virtual_dom$VirtualDom$mapAttribute, fn, htmlAttr));
			default:
				var fl = attr.a;
				var trans = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$TransformComponent, fl, trans);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$removeNever = function (style) {
	return A2($mdgriffith$elm_ui$Internal$Model$mapAttrFromStyle, $elm$core$Basics$never, style);
};
var $mdgriffith$elm_ui$Internal$Model$unwrapDecsHelper = F2(
	function (attr, _v0) {
		var styles = _v0.a;
		var trans = _v0.b;
		var _v1 = $mdgriffith$elm_ui$Internal$Model$removeNever(attr);
		switch (_v1.$) {
			case 4:
				var style = _v1.b;
				return _Utils_Tuple2(
					A2($elm$core$List$cons, style, styles),
					trans);
			case 10:
				var flag = _v1.a;
				var component = _v1.b;
				return _Utils_Tuple2(
					styles,
					A2($mdgriffith$elm_ui$Internal$Model$composeTransformation, trans, component));
			default:
				return _Utils_Tuple2(styles, trans);
		}
	});
var $mdgriffith$elm_ui$Internal$Model$unwrapDecorations = function (attrs) {
	var _v0 = A3(
		$elm$core$List$foldl,
		$mdgriffith$elm_ui$Internal$Model$unwrapDecsHelper,
		_Utils_Tuple2(_List_Nil, $mdgriffith$elm_ui$Internal$Model$Untransformed),
		attrs);
	var styles = _v0.a;
	var transform = _v0.b;
	return A2(
		$elm$core$List$cons,
		$mdgriffith$elm_ui$Internal$Model$Transform(transform),
		styles);
};
var $mdgriffith$elm_ui$Element$mouseOver = function (decs) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$hover,
		A2(
			$mdgriffith$elm_ui$Internal$Model$PseudoSelector,
			1,
			$mdgriffith$elm_ui$Internal$Model$unwrapDecorations(decs)));
};
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$Attributes$rel = _VirtualDom_attribute('rel');
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $mdgriffith$elm_ui$Element$newTabLink = F2(
	function (attrs, _v0) {
		var url = _v0.py;
		var label = _v0.bD;
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$NodeName('a'),
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$Attr(
					$elm$html$Html$Attributes$href(url)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$Attr(
						$elm$html$Html$Attributes$rel('noopener noreferrer')),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Internal$Model$Attr(
							$elm$html$Html$Attributes$target('_blank')),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.dm + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.a1 + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.hA)))),
									attrs)))))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var $mdgriffith$elm_ui$Element$none = $mdgriffith$elm_ui$Internal$Model$Empty;
var $mdgriffith$elm_ui$Internal$Model$PaddingStyle = F5(
	function (a, b, c, d, e) {
		return {$: 7, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Internal$Flag$padding = $mdgriffith$elm_ui$Internal$Flag$flag(2);
var $mdgriffith$elm_ui$Element$paddingXY = F2(
	function (x, y) {
		if (_Utils_eq(x, y)) {
			var f = x;
			return A2(
				$mdgriffith$elm_ui$Internal$Model$StyleClass,
				$mdgriffith$elm_ui$Internal$Flag$padding,
				A5(
					$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
					'p-' + $elm$core$String$fromInt(x),
					f,
					f,
					f,
					f));
		} else {
			var yFloat = y;
			var xFloat = x;
			return A2(
				$mdgriffith$elm_ui$Internal$Model$StyleClass,
				$mdgriffith$elm_ui$Internal$Flag$padding,
				A5(
					$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
					'p-' + ($elm$core$String$fromInt(x) + ('-' + $elm$core$String$fromInt(y))),
					yFloat,
					xFloat,
					yFloat,
					xFloat));
		}
	});
var $mdgriffith$elm_ui$Internal$Model$AsRow = 0;
var $mdgriffith$elm_ui$Internal$Model$asRow = 0;
var $mdgriffith$elm_ui$Element$row = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asRow,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.cE + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.a1)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Internal$Model$FontSize = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$fontSize = $mdgriffith$elm_ui$Internal$Flag$flag(4);
var $mdgriffith$elm_ui$Element$Font$size = function (i) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontSize,
		$mdgriffith$elm_ui$Internal$Model$FontSize(i));
};
var $author$project$Type$Cohort$toShortExpandedString = function (cohort) {
	if (cohort.$ === 1) {
		return 'MinStd';
	} else {
		return 'Y' + $author$project$Type$Cohort$toAssigneeString(cohort);
	}
};
var $mdgriffith$elm_ui$Internal$Model$SpacingStyle = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$Padding = F5(
	function (a, b, c, d, e) {
		return {$: 0, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Internal$Model$Spaced = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Internal$Model$extractSpacingAndPadding = function (attrs) {
	return A3(
		$elm$core$List$foldr,
		F2(
			function (attr, _v0) {
				var pad = _v0.a;
				var spacing = _v0.b;
				return _Utils_Tuple2(
					function () {
						if (!pad.$) {
							var x = pad.a;
							return pad;
						} else {
							if ((attr.$ === 4) && (attr.b.$ === 7)) {
								var _v3 = attr.b;
								var name = _v3.a;
								var t = _v3.b;
								var r = _v3.c;
								var b = _v3.d;
								var l = _v3.e;
								return $elm$core$Maybe$Just(
									A5($mdgriffith$elm_ui$Internal$Model$Padding, name, t, r, b, l));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}
					}(),
					function () {
						if (!spacing.$) {
							var x = spacing.a;
							return spacing;
						} else {
							if ((attr.$ === 4) && (attr.b.$ === 5)) {
								var _v6 = attr.b;
								var name = _v6.a;
								var x = _v6.b;
								var y = _v6.c;
								return $elm$core$Maybe$Just(
									A3($mdgriffith$elm_ui$Internal$Model$Spaced, name, x, y));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}
					}());
			}),
		_Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing),
		attrs);
};
var $mdgriffith$elm_ui$Internal$Model$paddingNameFloat = F4(
	function (top, right, bottom, left) {
		return 'pad-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(top) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(right) + ('-' + ($mdgriffith$elm_ui$Internal$Model$floatClass(bottom) + ('-' + $mdgriffith$elm_ui$Internal$Model$floatClass(left)))))));
	});
var $mdgriffith$elm_ui$Internal$Flag$spacing = $mdgriffith$elm_ui$Internal$Flag$flag(3);
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $mdgriffith$elm_ui$Element$wrappedRow = F2(
	function (attrs, children) {
		var _v0 = $mdgriffith$elm_ui$Internal$Model$extractSpacingAndPadding(attrs);
		var padded = _v0.a;
		var spaced = _v0.b;
		if (spaced.$ === 1) {
			return A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asRow,
				$mdgriffith$elm_ui$Internal$Model$div,
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.cE + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.a1 + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.f3)))),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
							attrs))),
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
		} else {
			var _v2 = spaced.a;
			var spaceName = _v2.a;
			var x = _v2.b;
			var y = _v2.c;
			var newPadding = function () {
				if (!padded.$) {
					var _v5 = padded.a;
					var name = _v5.a;
					var t = _v5.b;
					var r = _v5.c;
					var b = _v5.d;
					var l = _v5.e;
					if ((_Utils_cmp(r, x / 2) > -1) && (_Utils_cmp(b, y / 2) > -1)) {
						var newTop = t - (y / 2);
						var newRight = r - (x / 2);
						var newLeft = l - (x / 2);
						var newBottom = b - (y / 2);
						return $elm$core$Maybe$Just(
							A2(
								$mdgriffith$elm_ui$Internal$Model$StyleClass,
								$mdgriffith$elm_ui$Internal$Flag$padding,
								A5(
									$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
									A4($mdgriffith$elm_ui$Internal$Model$paddingNameFloat, newTop, newRight, newBottom, newLeft),
									newTop,
									newRight,
									newBottom,
									newLeft)));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}();
			if (!newPadding.$) {
				var pad = newPadding.a;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asRow,
					$mdgriffith$elm_ui$Internal$Model$div,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.cE + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.a1 + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.f3)))),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
								_Utils_ap(
									attrs,
									_List_fromArray(
										[pad]))))),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
			} else {
				var halfY = -(y / 2);
				var halfX = -(x / 2);
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					attrs,
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[
								A4(
								$mdgriffith$elm_ui$Internal$Model$element,
								$mdgriffith$elm_ui$Internal$Model$asRow,
								$mdgriffith$elm_ui$Internal$Model$div,
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.cE + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.a1 + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.f3)))),
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Internal$Model$Attr(
											A2(
												$elm$html$Html$Attributes$style,
												'margin',
												$elm$core$String$fromFloat(halfY) + ('px' + (' ' + ($elm$core$String$fromFloat(halfX) + 'px'))))),
										A2(
											$elm$core$List$cons,
											$mdgriffith$elm_ui$Internal$Model$Attr(
												A2(
													$elm$html$Html$Attributes$style,
													'width',
													'calc(100% + ' + ($elm$core$String$fromInt(x) + 'px)'))),
											A2(
												$elm$core$List$cons,
												$mdgriffith$elm_ui$Internal$Model$Attr(
													A2(
														$elm$html$Html$Attributes$style,
														'height',
														'calc(100% + ' + ($elm$core$String$fromInt(y) + 'px)'))),
												A2(
													$elm$core$List$cons,
													A2(
														$mdgriffith$elm_ui$Internal$Model$StyleClass,
														$mdgriffith$elm_ui$Internal$Flag$spacing,
														A3($mdgriffith$elm_ui$Internal$Model$SpacingStyle, spaceName, x, y)),
													_List_Nil))))),
								$mdgriffith$elm_ui$Internal$Model$Unkeyed(children))
							])));
			}
		}
	});
var $author$project$Page$PagesCommon$topBar = F2(
	function (signOutMsg, linkClickedMsg) {
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				var topBarPreviewExamBtnWithMsg = F3(
					function (attrs, msg, txt) {
						return A2(
							$mdgriffith$elm_ui$Element$el,
							attrs,
							A2(
								$mdgriffith$elm_ui$Element$Input$button,
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Element$paddingXY, 8, 18),
										$mdgriffith$elm_ui$Element$mouseOver(
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.fd)
											]))
									]),
								{
									bD: A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$centerX]),
										$mdgriffith$elm_ui$Element$text(txt)),
									ai: $elm$core$Maybe$Just(msg)
								}));
					});
				var topBarBtnWithMsg = F3(
					function (attrs, msg, txt) {
						return A2(
							$mdgriffith$elm_ui$Element$el,
							attrs,
							A2(
								$mdgriffith$elm_ui$Element$Input$button,
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Element$paddingXY, 20, 18),
										$mdgriffith$elm_ui$Element$mouseOver(
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.fd)
											]))
									]),
								{
									bD: A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$centerX]),
										$mdgriffith$elm_ui$Element$text(txt)),
									ai: $elm$core$Maybe$Just(msg)
								}));
					});
				var topBarBtn = F3(
					function (attrs, lnk, txt) {
						return A3(
							topBarBtnWithMsg,
							attrs,
							linkClickedMsg(
								$author$project$Routing$withHash(lnk)),
							txt);
					});
				var profile = A3(
					topBarBtn,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignRight]),
					$author$project$Routing$smPaths.nC,
					'My Profile');
				var mjd5 = rState.lx.md ? A3(
					topBarBtn,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignRight]),
					$author$project$Routing$smPaths.mJ,
					'Mjd5') : $mdgriffith$elm_ui$Element$none;
				var makeManageSchools = function (isNapAdmin) {
					return isNapAdmin ? A3(
						topBarBtn,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignRight]),
						$author$project$Routing$smPaths.mx,
						'ManageSchools') : $mdgriffith$elm_ui$Element$none;
				};
				var makeExamButton = function (cohort) {
					return A3(
						topBarPreviewExamBtnWithMsg,
						_List_Nil,
						linkClickedMsg(
							$author$project$Routing$withHash(
								$author$project$Routing$smPaths.n3 + ('/' + $author$project$Type$Cohort$toString(cohort)))),
						$author$project$Type$Cohort$toShortExpandedString(cohort));
				};
				var previewExams = function (isStud) {
					return isStud ? $mdgriffith$elm_ui$Element$none : A2(
						$mdgriffith$elm_ui$Element$row,
						_List_Nil,
						A2(
							$elm$core$List$append,
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$Font$italic]),
									$mdgriffith$elm_ui$Element$text(' Preview/Configure Exams:'))
								]),
							A2($elm$core$List$map, makeExamButton, $author$project$Type$Cohort$cohorts)));
				};
				var makeBgColor = F2(
					function (isNapAdmin, me) {
						var isDefaultSId = $author$project$Type$User$extractPersonEDetails(me).d$ === 'nsw-smartermaths-h';
						return (isNapAdmin && (!isDefaultSId)) ? $author$project$Theme$Style$t.mD : $author$project$Theme$Style$t.mC;
					});
				var makeAdminPage = function (isNapAdmin) {
					return isNapAdmin ? A3(
						topBarBtn,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignRight]),
						$author$project$Routing$smPaths.jM,
						'QuestionAdmin') : $mdgriffith$elm_ui$Element$none;
				};
				var logout = A3(
					topBarBtnWithMsg,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignRight]),
					signOutMsg,
					'Logout');
				var isStudent = $author$project$Type$User$isStudentE(rState.fc);
				var home = function (isStud) {
					var lnk = isStud ? '' : $author$project$Routing$smPaths.pN;
					return A3(
						topBarBtn,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignLeft]),
						lnk,
						'Home');
				};
				var helpCenter = A2(
					$mdgriffith$elm_ui$Element$newTabLink,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$alignRight,
							A2($mdgriffith$elm_ui$Element$paddingXY, 20, 18),
							$mdgriffith$elm_ui$Element$mouseOver(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.fd),
									$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.hF)
								]))
						]),
					{
						bD: $mdgriffith$elm_ui$Element$text('Help Centre'),
						py: 'https://smartermaths.notion.site/SmarterMaths-Numeracy-Online-4011045ad0634757b3734741ef5c79aa'
					});
				return (rState.me && isStudent) ? $mdgriffith$elm_ui$Element$none : A2(
					$mdgriffith$elm_ui$Element$wrappedRow,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color(
							A2(
								makeBgColor,
								$author$project$Type$ReaderConfig$isNapAdmin(rState),
								rState.fc)),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.hF),
							$mdgriffith$elm_ui$Element$Font$size(16),
							$mdgriffith$elm_ui$Element$htmlAttribute(
							$elm$html$Html$Attributes$class('no-print'))
						]),
					_List_fromArray(
						[
							home(isStudent),
							mjd5,
							makeAdminPage(
							$author$project$Type$ReaderConfig$isNapAdmin(rState)),
							makeManageSchools(
							$author$project$Type$ReaderConfig$isNapAdmin(rState)),
							helpCenter,
							profile,
							previewExams(isStudent),
							logout
						]));
			});
	});
var $author$project$Page$AdaptiveAnalytics$LinkClicked = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$AdaptiveAnalytics$SignOut = {$: 9};
var $Punie$elm_reader$Reader$flip = F3(
	function (f, x, y) {
		return A2(f, y, x);
	});
var $Punie$elm_reader$Reader$bind = F2(
	function (x, f) {
		return function (r) {
			return A3(
				$Punie$elm_reader$Reader$flip,
				$Punie$elm_reader$Reader$run,
				r,
				f(
					A2($Punie$elm_reader$Reader$run, x, r)));
		};
	});
var $Punie$elm_reader$Reader$andThen = $Punie$elm_reader$Reader$flip($Punie$elm_reader$Reader$bind);
var $mdgriffith$elm_ui$Internal$Model$OnlyDynamic = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$StaticRootAndDynamic = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$AllowHover = 1;
var $mdgriffith$elm_ui$Internal$Model$Layout = 0;
var $mdgriffith$elm_ui$Internal$Model$focusDefaultStyle = {
	j3: $elm$core$Maybe$Nothing,
	eq: $elm$core$Maybe$Nothing,
	oi: $elm$core$Maybe$Just(
		{
			b_: 0,
			b0: A4($mdgriffith$elm_ui$Internal$Model$Rgba, 155 / 255, 203 / 255, 1, 1),
			b: _Utils_Tuple2(0, 0),
			iT: 3
		})
};
var $mdgriffith$elm_ui$Internal$Model$optionsToRecord = function (options) {
	var combine = F2(
		function (opt, record) {
			switch (opt.$) {
				case 0:
					var hoverable = opt.a;
					var _v4 = record.lT;
					if (_v4.$ === 1) {
						return _Utils_update(
							record,
							{
								lT: $elm$core$Maybe$Just(hoverable)
							});
					} else {
						return record;
					}
				case 1:
					var focusStyle = opt.a;
					var _v5 = record.lA;
					if (_v5.$ === 1) {
						return _Utils_update(
							record,
							{
								lA: $elm$core$Maybe$Just(focusStyle)
							});
					} else {
						return record;
					}
				default:
					var renderMode = opt.a;
					var _v6 = record.bF;
					if (_v6.$ === 1) {
						return _Utils_update(
							record,
							{
								bF: $elm$core$Maybe$Just(renderMode)
							});
					} else {
						return record;
					}
			}
		});
	var andFinally = function (record) {
		return {
			lA: function () {
				var _v0 = record.lA;
				if (_v0.$ === 1) {
					return $mdgriffith$elm_ui$Internal$Model$focusDefaultStyle;
				} else {
					var focusable = _v0.a;
					return focusable;
				}
			}(),
			lT: function () {
				var _v1 = record.lT;
				if (_v1.$ === 1) {
					return 1;
				} else {
					var hoverable = _v1.a;
					return hoverable;
				}
			}(),
			bF: function () {
				var _v2 = record.bF;
				if (_v2.$ === 1) {
					return 0;
				} else {
					var actualMode = _v2.a;
					return actualMode;
				}
			}()
		};
	};
	return andFinally(
		A3(
			$elm$core$List$foldr,
			combine,
			{lA: $elm$core$Maybe$Nothing, lT: $elm$core$Maybe$Nothing, bF: $elm$core$Maybe$Nothing},
			options));
};
var $mdgriffith$elm_ui$Internal$Model$toHtml = F2(
	function (mode, el) {
		switch (el.$) {
			case 0:
				var html = el.a;
				return html($mdgriffith$elm_ui$Internal$Model$asEl);
			case 1:
				var styles = el.a.i1;
				var html = el.a.lV;
				return A2(
					html,
					mode(styles),
					$mdgriffith$elm_ui$Internal$Model$asEl);
			case 2:
				var text = el.a;
				return $mdgriffith$elm_ui$Internal$Model$textElement(text);
			default:
				return $mdgriffith$elm_ui$Internal$Model$textElement('');
		}
	});
var $mdgriffith$elm_ui$Internal$Model$renderRoot = F3(
	function (optionList, attributes, child) {
		var options = $mdgriffith$elm_ui$Internal$Model$optionsToRecord(optionList);
		var embedStyle = function () {
			var _v0 = options.bF;
			if (_v0 === 1) {
				return $mdgriffith$elm_ui$Internal$Model$OnlyDynamic(options);
			} else {
				return $mdgriffith$elm_ui$Internal$Model$StaticRootAndDynamic(options);
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Internal$Model$toHtml,
			embedStyle,
			A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asEl,
				$mdgriffith$elm_ui$Internal$Model$div,
				attributes,
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[child]))));
	});
var $mdgriffith$elm_ui$Internal$Model$FontFamily = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Model$SansSerif = {$: 1};
var $mdgriffith$elm_ui$Internal$Model$Typeface = function (a) {
	return {$: 3, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$fontFamily = $mdgriffith$elm_ui$Internal$Flag$flag(5);
var $mdgriffith$elm_ui$Internal$Model$renderFontClassName = F2(
	function (font, current) {
		return _Utils_ap(
			current,
			function () {
				switch (font.$) {
					case 0:
						return 'serif';
					case 1:
						return 'sans-serif';
					case 2:
						return 'monospace';
					case 3:
						var name = font.a;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
					case 4:
						var name = font.a;
						var url = font.b;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
					default:
						var name = font.a.mO;
						return A2(
							$elm$core$String$join,
							'-',
							$elm$core$String$words(
								$elm$core$String$toLower(name)));
				}
			}());
	});
var $mdgriffith$elm_ui$Internal$Model$rootStyle = function () {
	var families = _List_fromArray(
		[
			$mdgriffith$elm_ui$Internal$Model$Typeface('Open Sans'),
			$mdgriffith$elm_ui$Internal$Model$Typeface('Helvetica'),
			$mdgriffith$elm_ui$Internal$Model$Typeface('Verdana'),
			$mdgriffith$elm_ui$Internal$Model$SansSerif
		]);
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$bgColor,
			A3(
				$mdgriffith$elm_ui$Internal$Model$Colored,
				'bg-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(
					A4($mdgriffith$elm_ui$Internal$Model$Rgba, 1, 1, 1, 0)),
				'background-color',
				A4($mdgriffith$elm_ui$Internal$Model$Rgba, 1, 1, 1, 0))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontColor,
			A3(
				$mdgriffith$elm_ui$Internal$Model$Colored,
				'fc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(
					A4($mdgriffith$elm_ui$Internal$Model$Rgba, 0, 0, 0, 1)),
				'color',
				A4($mdgriffith$elm_ui$Internal$Model$Rgba, 0, 0, 0, 1))),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontSize,
			$mdgriffith$elm_ui$Internal$Model$FontSize(20)),
			A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$fontFamily,
			A2(
				$mdgriffith$elm_ui$Internal$Model$FontFamily,
				A3($elm$core$List$foldl, $mdgriffith$elm_ui$Internal$Model$renderFontClassName, 'font-', families),
				families))
		]);
}();
var $mdgriffith$elm_ui$Element$layoutWith = F3(
	function (_v0, attrs, child) {
		var options = _v0.ni;
		return A3(
			$mdgriffith$elm_ui$Internal$Model$renderRoot,
			options,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass(
					A2(
						$elm$core$String$join,
						' ',
						_List_fromArray(
							[$mdgriffith$elm_ui$Internal$Style$classes.nX, $mdgriffith$elm_ui$Internal$Style$classes.j_, $mdgriffith$elm_ui$Internal$Style$classes.on]))),
				_Utils_ap($mdgriffith$elm_ui$Internal$Model$rootStyle, attrs)),
			child);
	});
var $mdgriffith$elm_ui$Element$layout = $mdgriffith$elm_ui$Element$layoutWith(
	{ni: _List_Nil});
var $author$project$Page$PagesCommon$makeBackgroundStyleRaw = function (serverEnv) {
	return (serverEnv === 'staging') ? $author$project$Theme$Style$lighter($author$project$Theme$Style$t.eC) : $author$project$Theme$Style$t.nl;
};
var $author$project$Page$PagesCommon$makeBackgroundStyle = $Punie$elm_reader$Reader$asks(
	function (rState) {
		return $author$project$Page$PagesCommon$makeBackgroundStyleRaw(rState.lx.od);
	});
var $Punie$elm_reader$Reader$apply = F2(
	function (_v0, _v1) {
		var f = _v0;
		var g = _v1;
		return function (env) {
			return A2(
				f,
				env,
				g(env));
		};
	});
var $Punie$elm_reader$Reader$andMap = $Punie$elm_reader$Reader$flip($Punie$elm_reader$Reader$apply);
var $Punie$elm_reader$Reader$map2 = F3(
	function (f, x, y) {
		return A2(
			$Punie$elm_reader$Reader$andMap,
			y,
			A2($Punie$elm_reader$Reader$map, f, x));
	});
var $author$project$Page$AdaptiveAnalytics$Cancel = {$: 1};
var $mdgriffith$elm_ui$Element$padding = function (x) {
	var f = x;
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$padding,
		A5(
			$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
			'p-' + $elm$core$String$fromInt(x),
			f,
			f,
			f,
			f));
};
var $mdgriffith$elm_ui$Internal$Flag$borderRound = $mdgriffith$elm_ui$Internal$Flag$flag(17);
var $mdgriffith$elm_ui$Element$Border$rounded = function (radius) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderRound,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Single,
			'br-' + $elm$core$String$fromInt(radius),
			'border-radius',
			$elm$core$String$fromInt(radius) + 'px'));
};
var $author$project$Page$PagesCommon$configurableButton = F6(
	function (isActive, activeColor, inactiveColor, elAttrs, btnAttrOverrides, btnConfig) {
		var btnConfig_ = isActive ? btnConfig : _Utils_update(
			btnConfig,
			{ai: $elm$core$Maybe$Nothing});
		var btnColor = isActive ? activeColor : inactiveColor;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			elAttrs,
			A2(
				$mdgriffith$elm_ui$Element$Input$button,
				A2(
					$elm$core$List$append,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color(btnColor),
							$mdgriffith$elm_ui$Element$Border$rounded(5),
							$mdgriffith$elm_ui$Element$padding(15),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					btnAttrOverrides),
				btnConfig_));
	});
var $mdgriffith$elm_ui$Internal$Model$paddingName = F4(
	function (top, right, bottom, left) {
		return 'pad-' + ($elm$core$String$fromInt(top) + ('-' + ($elm$core$String$fromInt(right) + ('-' + ($elm$core$String$fromInt(bottom) + ('-' + $elm$core$String$fromInt(left)))))));
	});
var $mdgriffith$elm_ui$Element$paddingEach = function (_v0) {
	var top = _v0.fY;
	var right = _v0.fz;
	var bottom = _v0.er;
	var left = _v0.e9;
	if (_Utils_eq(top, right) && (_Utils_eq(top, bottom) && _Utils_eq(top, left))) {
		var topFloat = top;
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$padding,
			A5(
				$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
				'p-' + $elm$core$String$fromInt(top),
				topFloat,
				topFloat,
				topFloat,
				topFloat));
	} else {
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$padding,
			A5(
				$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
				A4($mdgriffith$elm_ui$Internal$Model$paddingName, top, right, bottom, left),
				top,
				right,
				bottom,
				left));
	}
};
var $author$project$Page$PagesCommon$regButton = F2(
	function (isActive, btnConfig) {
		return A6(
			$author$project$Page$PagesCommon$configurableButton,
			isActive,
			$author$project$Theme$Style$t.f6,
			$author$project$Theme$Style$t.jG,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$paddingEach(
					{er: 5, e9: 0, fz: 0, fY: 15})
				]),
			_List_Nil,
			btnConfig);
	});
var $author$project$Page$AdaptiveAnalytics$cancelButton = function (_v0) {
	return A2(
		$author$project$Page$PagesCommon$regButton,
		true,
		{
			bD: A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				$mdgriffith$elm_ui$Element$text('Back')),
			ai: $elm$core$Maybe$Just($author$project$Page$AdaptiveAnalytics$Cancel)
		});
};
var $author$project$Page$AdaptiveAnalytics$actionBtns = function (mdl) {
	return $author$project$Page$AdaptiveAnalytics$cancelButton(mdl);
};
var $author$project$Page$AdaptiveAnalytics$SelectActivity = function (a) {
	return {$: 3, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$Top = 0;
var $mdgriffith$elm_ui$Element$alignTop = $mdgriffith$elm_ui$Internal$Model$AlignY(0);
var $mdgriffith$elm_ui$Internal$Model$unstyled = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Unstyled, $elm$core$Basics$always);
var $mdgriffith$elm_ui$Element$html = $mdgriffith$elm_ui$Internal$Model$unstyled;
var $elm$html$Html$span = _VirtualDom_node('span');
var $pablohirafuji$elm_markdown$Markdown$Block$BlockQuote = function (a) {
	return {$: 5, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$Block$List = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $pablohirafuji$elm_markdown$Markdown$Block$Paragraph = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $pablohirafuji$elm_markdown$Markdown$Block$formatParagraphLine = function (rawParagraph) {
	return (A2($elm$core$String$right, 2, rawParagraph) === '  ') ? ($elm$core$String$trim(rawParagraph) + '  ') : $elm$core$String$trim(rawParagraph);
};
var $pablohirafuji$elm_markdown$Markdown$Block$addToParagraph = F2(
	function (paragraph, rawLine) {
		return A2(
			$pablohirafuji$elm_markdown$Markdown$Block$Paragraph,
			paragraph + ('\n' + $pablohirafuji$elm_markdown$Markdown$Block$formatParagraphLine(rawLine)),
			_List_Nil);
	});
var $pablohirafuji$elm_markdown$Markdown$Block$blockQuoteLineRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^ {0,3}(?:>[ ]?)(.*)$'));
var $pablohirafuji$elm_markdown$Markdown$Block$blankLineRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^\\s*$'));
var $pablohirafuji$elm_markdown$Markdown$Block$calcListIndentLength = function (_v0) {
	var listBlock = _v0.a;
	var indentSpace = _v0.b;
	var rawLine = _v0.c;
	var indentSpaceLength = $elm$core$String$length(indentSpace);
	var isIndentedCode = indentSpaceLength >= 4;
	var updtRawLine = isIndentedCode ? _Utils_ap(indentSpace, rawLine) : rawLine;
	var indentLength = (isIndentedCode || A2($elm$regex$Regex$contains, $pablohirafuji$elm_markdown$Markdown$Block$blankLineRegex, rawLine)) ? (listBlock.O - indentSpaceLength) : listBlock.O;
	return _Utils_Tuple2(
		_Utils_update(
			listBlock,
			{O: indentLength}),
		updtRawLine);
};
var $pablohirafuji$elm_markdown$Markdown$Block$atxHeadingLineRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^ {0,3}(#{1,6})' + ('(?:[ \\t]+[ \\t#]+$|[ \\t]+|$)' + '(.*?)(?:\\s+[ \\t#]*)?$')));
var $pablohirafuji$elm_markdown$Markdown$Block$Heading = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $pablohirafuji$elm_markdown$Markdown$Block$extractATXHeadingRM = function (match) {
	var _v0 = match.i2;
	if ((_v0.b && (!_v0.a.$)) && _v0.b.b) {
		var lvl = _v0.a.a;
		var _v1 = _v0.b;
		var maybeHeading = _v1.a;
		return $elm$core$Maybe$Just(
			A3(
				$pablohirafuji$elm_markdown$Markdown$Block$Heading,
				A2($elm$core$Maybe$withDefault, '', maybeHeading),
				$elm$core$String$length(lvl),
				_List_Nil));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$Block$checkATXHeadingLine = function (_v0) {
	var rawLine = _v0.a;
	var ast = _v0.b;
	return A2(
		$elm$core$Result$fromMaybe,
		_Utils_Tuple2(rawLine, ast),
		A2(
			$elm$core$Maybe$map,
			function (a) {
				return A2($elm$core$List$cons, a, ast);
			},
			A2(
				$elm$core$Maybe$andThen,
				$pablohirafuji$elm_markdown$Markdown$Block$extractATXHeadingRM,
				$elm$core$List$head(
					A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$atxHeadingLineRegex, rawLine)))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$BlankLine = function (a) {
	return {$: 0, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$Block$CodeBlock = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $pablohirafuji$elm_markdown$Markdown$Block$Fenced = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $pablohirafuji$elm_markdown$Markdown$Block$addBlankLineToListBlock = F2(
	function (match, asts) {
		if (!asts.b) {
			return _List_fromArray(
				[
					_List_fromArray(
					[
						$pablohirafuji$elm_markdown$Markdown$Block$BlankLine(match.mA)
					])
				]);
		} else {
			var ast = asts.a;
			var astsTail = asts.b;
			return A2(
				$elm$core$List$cons,
				A2($pablohirafuji$elm_markdown$Markdown$Block$parseBlankLine, ast, match),
				astsTail);
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$parseBlankLine = F2(
	function (ast, match) {
		_v0$2:
		while (true) {
			if (ast.b) {
				switch (ast.a.$) {
					case 3:
						if ((ast.a.a.$ === 1) && ast.a.a.a) {
							var _v1 = ast.a;
							var _v2 = _v1.a;
							var fence = _v2.b;
							var code = _v1.b;
							var astTail = ast.b;
							return function (a) {
								return A2($elm$core$List$cons, a, astTail);
							}(
								A2(
									$pablohirafuji$elm_markdown$Markdown$Block$CodeBlock,
									A2($pablohirafuji$elm_markdown$Markdown$Block$Fenced, true, fence),
									code + '\n'));
						} else {
							break _v0$2;
						}
					case 6:
						var _v3 = ast.a;
						var model = _v3.a;
						var items = _v3.b;
						var astTail = ast.b;
						return A2(
							$elm$core$List$cons,
							A2(
								$pablohirafuji$elm_markdown$Markdown$Block$List,
								model,
								A2($pablohirafuji$elm_markdown$Markdown$Block$addBlankLineToListBlock, match, items)),
							astTail);
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return A2(
			$elm$core$List$cons,
			$pablohirafuji$elm_markdown$Markdown$Block$BlankLine(match.mA),
			ast);
	});
var $pablohirafuji$elm_markdown$Markdown$Block$checkBlankLine = function (_v0) {
	var rawLine = _v0.a;
	var ast = _v0.b;
	return A2(
		$elm$core$Result$fromMaybe,
		_Utils_Tuple2(rawLine, ast),
		A2(
			$elm$core$Maybe$map,
			$pablohirafuji$elm_markdown$Markdown$Block$parseBlankLine(ast),
			$elm$core$List$head(
				A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$blankLineRegex, rawLine))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$indentedCodeLineRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^(?: {4,4}| {0,3}\\t)(.*)$'));
var $pablohirafuji$elm_markdown$Markdown$Block$Indented = {$: 0};
var $pablohirafuji$elm_markdown$Markdown$Block$blocksAfterBlankLines = F2(
	function (ast, blankLines) {
		blocksAfterBlankLines:
		while (true) {
			if (ast.b && (!ast.a.$)) {
				var blankStr = ast.a.a;
				var astTail = ast.b;
				var $temp$ast = astTail,
					$temp$blankLines = A2($elm$core$List$cons, blankStr, blankLines);
				ast = $temp$ast;
				blankLines = $temp$blankLines;
				continue blocksAfterBlankLines;
			} else {
				return _Utils_Tuple2(ast, blankLines);
			}
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$maybeContinueParagraph = F2(
	function (rawLine, ast) {
		_v0$3:
		while (true) {
			if (ast.b) {
				switch (ast.a.$) {
					case 4:
						var _v1 = ast.a;
						var paragraph = _v1.a;
						var astTail = ast.b;
						return $elm$core$Maybe$Just(
							A2(
								$elm$core$List$cons,
								A2($pablohirafuji$elm_markdown$Markdown$Block$addToParagraph, paragraph, rawLine),
								astTail));
					case 5:
						var bqAST = ast.a.a;
						var astTail = ast.b;
						return A2(
							$elm$core$Maybe$map,
							function (updtBqAST) {
								return A2(
									$elm$core$List$cons,
									$pablohirafuji$elm_markdown$Markdown$Block$BlockQuote(updtBqAST),
									astTail);
							},
							A2($pablohirafuji$elm_markdown$Markdown$Block$maybeContinueParagraph, rawLine, bqAST));
					case 6:
						var _v2 = ast.a;
						var model = _v2.a;
						var items = _v2.b;
						var astTail = ast.b;
						if (items.b) {
							var itemAST = items.a;
							var itemASTTail = items.b;
							return A2(
								$elm$core$Maybe$map,
								A2(
									$elm$core$Basics$composeR,
									function (a) {
										return A2($elm$core$List$cons, a, itemASTTail);
									},
									A2(
										$elm$core$Basics$composeR,
										$pablohirafuji$elm_markdown$Markdown$Block$List(model),
										function (a) {
											return A2($elm$core$List$cons, a, astTail);
										})),
								A2($pablohirafuji$elm_markdown$Markdown$Block$maybeContinueParagraph, rawLine, itemAST));
						} else {
							return $elm$core$Maybe$Nothing;
						}
					default:
						break _v0$3;
				}
			} else {
				break _v0$3;
			}
		}
		return $elm$core$Maybe$Nothing;
	});
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $elm$regex$Regex$replaceAtMost = _Regex_replaceAtMost;
var $pablohirafuji$elm_markdown$Markdown$Helpers$tabRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('\\t'));
var $pablohirafuji$elm_markdown$Markdown$Helpers$indentLine = function (indentLength_) {
	return A2(
		$elm$core$Basics$composeR,
		A2(
			$elm$regex$Regex$replace,
			$pablohirafuji$elm_markdown$Markdown$Helpers$tabRegex,
			function (_v0) {
				return '    ';
			}),
		A3(
			$elm$regex$Regex$replaceAtMost,
			1,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$regex$Regex$never,
				$elm$regex$Regex$fromString(
					'^ {0,' + ($elm$core$String$fromInt(indentLength_) + '}'))),
			function (_v1) {
				return '';
			}));
};
var $pablohirafuji$elm_markdown$Markdown$Block$resumeIndentedCodeBlock = F2(
	function (codeLine, _v0) {
		var remainBlocks = _v0.a;
		var blankLines = _v0.b;
		if ((remainBlocks.b && (remainBlocks.a.$ === 3)) && (!remainBlocks.a.a.$)) {
			var _v2 = remainBlocks.a;
			var _v3 = _v2.a;
			var codeStr = _v2.b;
			var remainBlocksTail = remainBlocks.b;
			return $elm$core$Maybe$Just(
				function (a) {
					return A2($elm$core$List$cons, a, remainBlocksTail);
				}(
					A2(
						$pablohirafuji$elm_markdown$Markdown$Block$CodeBlock,
						$pablohirafuji$elm_markdown$Markdown$Block$Indented,
						function (a) {
							return a + (codeLine + '\n');
						}(
							_Utils_ap(
								codeStr,
								$elm$core$String$concat(
									A2(
										$elm$core$List$map,
										function (bl) {
											return A2($pablohirafuji$elm_markdown$Markdown$Helpers$indentLine, 4, bl) + '\n';
										},
										blankLines)))))));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$parseIndentedCodeLine = F2(
	function (ast, codeLine) {
		_v0$2:
		while (true) {
			if (ast.b) {
				switch (ast.a.$) {
					case 3:
						if (!ast.a.a.$) {
							var _v1 = ast.a;
							var _v2 = _v1.a;
							var codeStr = _v1.b;
							var astTail = ast.b;
							return function (a) {
								return A2($elm$core$List$cons, a, astTail);
							}(
								A2($pablohirafuji$elm_markdown$Markdown$Block$CodeBlock, $pablohirafuji$elm_markdown$Markdown$Block$Indented, codeStr + (codeLine + '\n')));
						} else {
							break _v0$2;
						}
					case 0:
						var blankStr = ast.a.a;
						var astTail = ast.b;
						return A2(
							$elm$core$Maybe$withDefault,
							function (a) {
								return A2($elm$core$List$cons, a, ast);
							}(
								A2($pablohirafuji$elm_markdown$Markdown$Block$CodeBlock, $pablohirafuji$elm_markdown$Markdown$Block$Indented, codeLine + '\n')),
							A2(
								$pablohirafuji$elm_markdown$Markdown$Block$resumeIndentedCodeBlock,
								codeLine,
								A2(
									$pablohirafuji$elm_markdown$Markdown$Block$blocksAfterBlankLines,
									astTail,
									_List_fromArray(
										[blankStr]))));
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return A2(
			$elm$core$Maybe$withDefault,
			function (a) {
				return A2($elm$core$List$cons, a, ast);
			}(
				A2($pablohirafuji$elm_markdown$Markdown$Block$CodeBlock, $pablohirafuji$elm_markdown$Markdown$Block$Indented, codeLine + '\n')),
			A2($pablohirafuji$elm_markdown$Markdown$Block$maybeContinueParagraph, codeLine, ast));
	});
var $pablohirafuji$elm_markdown$Markdown$Block$checkIndentedCode = function (_v0) {
	var rawLine = _v0.a;
	var ast = _v0.b;
	return A2(
		$elm$core$Result$fromMaybe,
		_Utils_Tuple2(rawLine, ast),
		A2(
			$elm$core$Maybe$map,
			$pablohirafuji$elm_markdown$Markdown$Block$parseIndentedCodeLine(ast),
			A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Maybe$Nothing,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Maybe$Nothing,
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.i2;
							},
							$elm$core$List$head),
						$elm$core$List$head(
							A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$indentedCodeLineRegex, rawLine)))))));
};
var $pablohirafuji$elm_markdown$Markdown$Entity$decimalRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('&#([0-9]{1,8});'));
var $pablohirafuji$elm_markdown$Markdown$Entity$isBadEndUnicode = function (_int) {
	var remain_ = A2($elm$core$Basics$modBy, 16, _int);
	var remain = A2($elm$core$Basics$modBy, 131070, _int);
	return (_int >= 131070) && ((((0 <= remain) && (remain <= 15)) || ((65536 <= remain) && (remain <= 65551))) && ((remain_ === 14) || (remain_ === 15)));
};
var $pablohirafuji$elm_markdown$Markdown$Entity$isValidUnicode = function (_int) {
	return (_int === 9) || ((_int === 10) || ((_int === 13) || ((_int === 133) || (((32 <= _int) && (_int <= 126)) || (((160 <= _int) && (_int <= 55295)) || (((57344 <= _int) && (_int <= 64975)) || (((65008 <= _int) && (_int <= 65533)) || ((65536 <= _int) && (_int <= 1114109)))))))));
};
var $pablohirafuji$elm_markdown$Markdown$Entity$validUnicode = function (_int) {
	return ($pablohirafuji$elm_markdown$Markdown$Entity$isValidUnicode(_int) && (!$pablohirafuji$elm_markdown$Markdown$Entity$isBadEndUnicode(_int))) ? $elm$core$String$fromChar(
		$elm$core$Char$fromCode(_int)) : $elm$core$String$fromChar(
		$elm$core$Char$fromCode(65533));
};
var $pablohirafuji$elm_markdown$Markdown$Entity$replaceDecimal = function (match) {
	return A2(
		$elm$core$Maybe$withDefault,
		match.mA,
		A2(
			$elm$core$Maybe$map,
			$pablohirafuji$elm_markdown$Markdown$Entity$validUnicode,
			A2(
				$elm$core$Maybe$andThen,
				$elm$core$String$toInt,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Maybe$Nothing,
					$elm$core$List$head(match.i2)))));
};
var $pablohirafuji$elm_markdown$Markdown$Entity$replaceDecimals = A2($elm$regex$Regex$replace, $pablohirafuji$elm_markdown$Markdown$Entity$decimalRegex, $pablohirafuji$elm_markdown$Markdown$Entity$replaceDecimal);
var $pablohirafuji$elm_markdown$Markdown$Entity$entitiesRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('&([0-9a-zA-Z]+);'));
var $pablohirafuji$elm_markdown$Markdown$Entity$entities = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('quot', 34),
			_Utils_Tuple2('amp', 38),
			_Utils_Tuple2('apos', 39),
			_Utils_Tuple2('lt', 60),
			_Utils_Tuple2('gt', 62),
			_Utils_Tuple2('nbsp', 160),
			_Utils_Tuple2('iexcl', 161),
			_Utils_Tuple2('cent', 162),
			_Utils_Tuple2('pound', 163),
			_Utils_Tuple2('curren', 164),
			_Utils_Tuple2('yen', 165),
			_Utils_Tuple2('brvbar', 166),
			_Utils_Tuple2('sect', 167),
			_Utils_Tuple2('uml', 168),
			_Utils_Tuple2('copy', 169),
			_Utils_Tuple2('ordf', 170),
			_Utils_Tuple2('laquo', 171),
			_Utils_Tuple2('not', 172),
			_Utils_Tuple2('shy', 173),
			_Utils_Tuple2('reg', 174),
			_Utils_Tuple2('macr', 175),
			_Utils_Tuple2('deg', 176),
			_Utils_Tuple2('plusmn', 177),
			_Utils_Tuple2('sup2', 178),
			_Utils_Tuple2('sup3', 179),
			_Utils_Tuple2('acute', 180),
			_Utils_Tuple2('micro', 181),
			_Utils_Tuple2('para', 182),
			_Utils_Tuple2('middot', 183),
			_Utils_Tuple2('cedil', 184),
			_Utils_Tuple2('sup1', 185),
			_Utils_Tuple2('ordm', 186),
			_Utils_Tuple2('raquo', 187),
			_Utils_Tuple2('frac14', 188),
			_Utils_Tuple2('frac12', 189),
			_Utils_Tuple2('frac34', 190),
			_Utils_Tuple2('iquest', 191),
			_Utils_Tuple2('Agrave', 192),
			_Utils_Tuple2('Aacute', 193),
			_Utils_Tuple2('Acirc', 194),
			_Utils_Tuple2('Atilde', 195),
			_Utils_Tuple2('Auml', 196),
			_Utils_Tuple2('Aring', 197),
			_Utils_Tuple2('AElig', 198),
			_Utils_Tuple2('Ccedil', 199),
			_Utils_Tuple2('Egrave', 200),
			_Utils_Tuple2('Eacute', 201),
			_Utils_Tuple2('Ecirc', 202),
			_Utils_Tuple2('Euml', 203),
			_Utils_Tuple2('Igrave', 204),
			_Utils_Tuple2('Iacute', 205),
			_Utils_Tuple2('Icirc', 206),
			_Utils_Tuple2('Iuml', 207),
			_Utils_Tuple2('ETH', 208),
			_Utils_Tuple2('Ntilde', 209),
			_Utils_Tuple2('Ograve', 210),
			_Utils_Tuple2('Oacute', 211),
			_Utils_Tuple2('Ocirc', 212),
			_Utils_Tuple2('Otilde', 213),
			_Utils_Tuple2('Ouml', 214),
			_Utils_Tuple2('times', 215),
			_Utils_Tuple2('Oslash', 216),
			_Utils_Tuple2('Ugrave', 217),
			_Utils_Tuple2('Uacute', 218),
			_Utils_Tuple2('Ucirc', 219),
			_Utils_Tuple2('Uuml', 220),
			_Utils_Tuple2('Yacute', 221),
			_Utils_Tuple2('THORN', 222),
			_Utils_Tuple2('szlig', 223),
			_Utils_Tuple2('agrave', 224),
			_Utils_Tuple2('aacute', 225),
			_Utils_Tuple2('acirc', 226),
			_Utils_Tuple2('atilde', 227),
			_Utils_Tuple2('auml', 228),
			_Utils_Tuple2('aring', 229),
			_Utils_Tuple2('aelig', 230),
			_Utils_Tuple2('ccedil', 231),
			_Utils_Tuple2('egrave', 232),
			_Utils_Tuple2('eacute', 233),
			_Utils_Tuple2('ecirc', 234),
			_Utils_Tuple2('euml', 235),
			_Utils_Tuple2('igrave', 236),
			_Utils_Tuple2('iacute', 237),
			_Utils_Tuple2('icirc', 238),
			_Utils_Tuple2('iuml', 239),
			_Utils_Tuple2('eth', 240),
			_Utils_Tuple2('ntilde', 241),
			_Utils_Tuple2('ograve', 242),
			_Utils_Tuple2('oacute', 243),
			_Utils_Tuple2('ocirc', 244),
			_Utils_Tuple2('otilde', 245),
			_Utils_Tuple2('ouml', 246),
			_Utils_Tuple2('divide', 247),
			_Utils_Tuple2('oslash', 248),
			_Utils_Tuple2('ugrave', 249),
			_Utils_Tuple2('uacute', 250),
			_Utils_Tuple2('ucirc', 251),
			_Utils_Tuple2('uuml', 252),
			_Utils_Tuple2('yacute', 253),
			_Utils_Tuple2('thorn', 254),
			_Utils_Tuple2('yuml', 255),
			_Utils_Tuple2('OElig', 338),
			_Utils_Tuple2('oelig', 339),
			_Utils_Tuple2('Scaron', 352),
			_Utils_Tuple2('scaron', 353),
			_Utils_Tuple2('Yuml', 376),
			_Utils_Tuple2('fnof', 402),
			_Utils_Tuple2('circ', 710),
			_Utils_Tuple2('tilde', 732),
			_Utils_Tuple2('Alpha', 913),
			_Utils_Tuple2('Beta', 914),
			_Utils_Tuple2('Gamma', 915),
			_Utils_Tuple2('Delta', 916),
			_Utils_Tuple2('Epsilon', 917),
			_Utils_Tuple2('Zeta', 918),
			_Utils_Tuple2('Eta', 919),
			_Utils_Tuple2('Theta', 920),
			_Utils_Tuple2('Iota', 921),
			_Utils_Tuple2('Kappa', 922),
			_Utils_Tuple2('Lambda', 923),
			_Utils_Tuple2('Mu', 924),
			_Utils_Tuple2('Nu', 925),
			_Utils_Tuple2('Xi', 926),
			_Utils_Tuple2('Omicron', 927),
			_Utils_Tuple2('Pi', 928),
			_Utils_Tuple2('Rho', 929),
			_Utils_Tuple2('Sigma', 931),
			_Utils_Tuple2('Tau', 932),
			_Utils_Tuple2('Upsilon', 933),
			_Utils_Tuple2('Phi', 934),
			_Utils_Tuple2('Chi', 935),
			_Utils_Tuple2('Psi', 936),
			_Utils_Tuple2('Omega', 937),
			_Utils_Tuple2('alpha', 945),
			_Utils_Tuple2('beta', 946),
			_Utils_Tuple2('gamma', 947),
			_Utils_Tuple2('delta', 948),
			_Utils_Tuple2('epsilon', 949),
			_Utils_Tuple2('zeta', 950),
			_Utils_Tuple2('eta', 951),
			_Utils_Tuple2('theta', 952),
			_Utils_Tuple2('iota', 953),
			_Utils_Tuple2('kappa', 954),
			_Utils_Tuple2('lambda', 955),
			_Utils_Tuple2('mu', 956),
			_Utils_Tuple2('nu', 957),
			_Utils_Tuple2('xi', 958),
			_Utils_Tuple2('omicron', 959),
			_Utils_Tuple2('pi', 960),
			_Utils_Tuple2('rho', 961),
			_Utils_Tuple2('sigmaf', 962),
			_Utils_Tuple2('sigma', 963),
			_Utils_Tuple2('tau', 964),
			_Utils_Tuple2('upsilon', 965),
			_Utils_Tuple2('phi', 966),
			_Utils_Tuple2('chi', 967),
			_Utils_Tuple2('psi', 968),
			_Utils_Tuple2('omega', 969),
			_Utils_Tuple2('thetasym', 977),
			_Utils_Tuple2('upsih', 978),
			_Utils_Tuple2('piv', 982),
			_Utils_Tuple2('ensp', 8194),
			_Utils_Tuple2('emsp', 8195),
			_Utils_Tuple2('thinsp', 8201),
			_Utils_Tuple2('zwnj', 8204),
			_Utils_Tuple2('zwj', 8205),
			_Utils_Tuple2('lrm', 8206),
			_Utils_Tuple2('rlm', 8207),
			_Utils_Tuple2('ndash', 8211),
			_Utils_Tuple2('mdash', 8212),
			_Utils_Tuple2('lsquo', 8216),
			_Utils_Tuple2('rsquo', 8217),
			_Utils_Tuple2('sbquo', 8218),
			_Utils_Tuple2('ldquo', 8220),
			_Utils_Tuple2('rdquo', 8221),
			_Utils_Tuple2('bdquo', 8222),
			_Utils_Tuple2('dagger', 8224),
			_Utils_Tuple2('Dagger', 8225),
			_Utils_Tuple2('bull', 8226),
			_Utils_Tuple2('hellip', 8230),
			_Utils_Tuple2('permil', 8240),
			_Utils_Tuple2('prime', 8242),
			_Utils_Tuple2('Prime', 8243),
			_Utils_Tuple2('lsaquo', 8249),
			_Utils_Tuple2('rsaquo', 8250),
			_Utils_Tuple2('oline', 8254),
			_Utils_Tuple2('frasl', 8260),
			_Utils_Tuple2('euro', 8364),
			_Utils_Tuple2('image', 8465),
			_Utils_Tuple2('weierp', 8472),
			_Utils_Tuple2('real', 8476),
			_Utils_Tuple2('trade', 8482),
			_Utils_Tuple2('alefsym', 8501),
			_Utils_Tuple2('larr', 8592),
			_Utils_Tuple2('uarr', 8593),
			_Utils_Tuple2('rarr', 8594),
			_Utils_Tuple2('darr', 8595),
			_Utils_Tuple2('harr', 8596),
			_Utils_Tuple2('crarr', 8629),
			_Utils_Tuple2('lArr', 8656),
			_Utils_Tuple2('uArr', 8657),
			_Utils_Tuple2('rArr', 8658),
			_Utils_Tuple2('dArr', 8659),
			_Utils_Tuple2('hArr', 8660),
			_Utils_Tuple2('forall', 8704),
			_Utils_Tuple2('part', 8706),
			_Utils_Tuple2('exist', 8707),
			_Utils_Tuple2('empty', 8709),
			_Utils_Tuple2('nabla', 8711),
			_Utils_Tuple2('isin', 8712),
			_Utils_Tuple2('notin', 8713),
			_Utils_Tuple2('ni', 8715),
			_Utils_Tuple2('prod', 8719),
			_Utils_Tuple2('sum', 8721),
			_Utils_Tuple2('minus', 8722),
			_Utils_Tuple2('lowast', 8727),
			_Utils_Tuple2('radic', 8730),
			_Utils_Tuple2('prop', 8733),
			_Utils_Tuple2('infin', 8734),
			_Utils_Tuple2('ang', 8736),
			_Utils_Tuple2('and', 8743),
			_Utils_Tuple2('or', 8744),
			_Utils_Tuple2('cap', 8745),
			_Utils_Tuple2('cup', 8746),
			_Utils_Tuple2('int', 8747),
			_Utils_Tuple2('there4', 8756),
			_Utils_Tuple2('sim', 8764),
			_Utils_Tuple2('cong', 8773),
			_Utils_Tuple2('asymp', 8776),
			_Utils_Tuple2('ne', 8800),
			_Utils_Tuple2('equiv', 8801),
			_Utils_Tuple2('le', 8804),
			_Utils_Tuple2('ge', 8805),
			_Utils_Tuple2('sub', 8834),
			_Utils_Tuple2('sup', 8835),
			_Utils_Tuple2('nsub', 8836),
			_Utils_Tuple2('sube', 8838),
			_Utils_Tuple2('supe', 8839),
			_Utils_Tuple2('oplus', 8853),
			_Utils_Tuple2('otimes', 8855),
			_Utils_Tuple2('perp', 8869),
			_Utils_Tuple2('sdot', 8901),
			_Utils_Tuple2('lceil', 8968),
			_Utils_Tuple2('rceil', 8969),
			_Utils_Tuple2('lfloor', 8970),
			_Utils_Tuple2('rfloor', 8971),
			_Utils_Tuple2('lang', 9001),
			_Utils_Tuple2('rang', 9002),
			_Utils_Tuple2('loz', 9674),
			_Utils_Tuple2('spades', 9824),
			_Utils_Tuple2('clubs', 9827),
			_Utils_Tuple2('hearts', 9829),
			_Utils_Tuple2('diams', 9830)
		]));
var $pablohirafuji$elm_markdown$Markdown$Entity$replaceEntity = function (match) {
	return A2(
		$elm$core$Maybe$withDefault,
		match.mA,
		A2(
			$elm$core$Maybe$map,
			A2($elm$core$Basics$composeR, $elm$core$Char$fromCode, $elm$core$String$fromChar),
			A2(
				$elm$core$Maybe$andThen,
				function (a) {
					return A2($elm$core$Dict$get, a, $pablohirafuji$elm_markdown$Markdown$Entity$entities);
				},
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Maybe$Nothing,
					$elm$core$List$head(match.i2)))));
};
var $pablohirafuji$elm_markdown$Markdown$Entity$replaceEntities = A2($elm$regex$Regex$replace, $pablohirafuji$elm_markdown$Markdown$Entity$entitiesRegex, $pablohirafuji$elm_markdown$Markdown$Entity$replaceEntity);
var $pablohirafuji$elm_markdown$Markdown$Helpers$escapableRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\+)([!\"#$%&\\\'()*+,./:;<=>?@[\\\\\\]^_`{|}~-])'));
var $pablohirafuji$elm_markdown$Markdown$Helpers$replaceEscapable = A2(
	$elm$regex$Regex$replace,
	$pablohirafuji$elm_markdown$Markdown$Helpers$escapableRegex,
	function (regexMatch) {
		var _v0 = regexMatch.i2;
		if (((_v0.b && (!_v0.a.$)) && _v0.b.b) && (!_v0.b.a.$)) {
			var backslashes = _v0.a.a;
			var _v1 = _v0.b;
			var escapedStr = _v1.a.a;
			return _Utils_ap(
				A2(
					$elm$core$String$repeat,
					($elm$core$String$length(backslashes) / 2) | 0,
					'\\'),
				escapedStr);
		} else {
			return regexMatch.mA;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Entity$hexadecimalRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('&#[Xx]([0-9a-fA-F]{1,8});'));
var $pablohirafuji$elm_markdown$Markdown$Entity$hexToInt = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$toLower,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$toList,
		A2(
			$elm$core$List$foldl,
			F2(
				function (hexDigit, _int) {
					return ((_int * 16) + A2(
						$elm$core$Basics$modBy,
						39,
						$elm$core$Char$toCode(hexDigit))) - 9;
				}),
			0)));
var $pablohirafuji$elm_markdown$Markdown$Entity$replaceHexadecimal = function (match) {
	return A2(
		$elm$core$Maybe$withDefault,
		match.mA,
		A2(
			$elm$core$Maybe$map,
			A2($elm$core$Basics$composeR, $pablohirafuji$elm_markdown$Markdown$Entity$hexToInt, $pablohirafuji$elm_markdown$Markdown$Entity$validUnicode),
			A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Maybe$Nothing,
				$elm$core$List$head(match.i2))));
};
var $pablohirafuji$elm_markdown$Markdown$Entity$replaceHexadecimals = A2($elm$regex$Regex$replace, $pablohirafuji$elm_markdown$Markdown$Entity$hexadecimalRegex, $pablohirafuji$elm_markdown$Markdown$Entity$replaceHexadecimal);
var $pablohirafuji$elm_markdown$Markdown$Helpers$formatStr = function (str) {
	return $pablohirafuji$elm_markdown$Markdown$Entity$replaceHexadecimals(
		$pablohirafuji$elm_markdown$Markdown$Entity$replaceDecimals(
			$pablohirafuji$elm_markdown$Markdown$Entity$replaceEntities(
				$pablohirafuji$elm_markdown$Markdown$Helpers$replaceEscapable(str))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$extractOpenCodeFenceRM = function (match) {
	var _v0 = match.i2;
	if (((_v0.b && _v0.b.b) && (!_v0.b.a.$)) && _v0.b.b.b) {
		var maybeIndent = _v0.a;
		var _v1 = _v0.b;
		var fence = _v1.a.a;
		var _v2 = _v1.b;
		var maybeLanguage = _v2.a;
		return $elm$core$Maybe$Just(
			A2(
				$pablohirafuji$elm_markdown$Markdown$Block$Fenced,
				true,
				{
					eP: A2($elm$core$String$left, 1, fence),
					eQ: $elm$core$String$length(fence),
					O: A2(
						$elm$core$Maybe$withDefault,
						0,
						A2($elm$core$Maybe$map, $elm$core$String$length, maybeIndent)),
					e8: A2(
						$elm$core$Maybe$map,
						$pablohirafuji$elm_markdown$Markdown$Helpers$formatStr,
						A2(
							$elm$core$Maybe$andThen,
							function (lang) {
								return (lang === '') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(lang);
							},
							$elm$core$List$head(
								A2(
									$elm$core$Maybe$withDefault,
									_List_Nil,
									A2($elm$core$Maybe$map, $elm$core$String$words, maybeLanguage)))))
				}));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$Block$openCodeFenceLineRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^( {0,3})(`{3,}(?!.*`)|~{3,}(?!.*~))(.*)$'));
var $pablohirafuji$elm_markdown$Markdown$Block$checkOpenCodeFenceLine = function (_v0) {
	var rawLine = _v0.a;
	var ast = _v0.b;
	return A2(
		$elm$core$Result$fromMaybe,
		_Utils_Tuple2(rawLine, ast),
		A2(
			$elm$core$Maybe$map,
			function (a) {
				return A2($elm$core$List$cons, a, ast);
			},
			A2(
				$elm$core$Maybe$map,
				function (f) {
					return A2($pablohirafuji$elm_markdown$Markdown$Block$CodeBlock, f, '');
				},
				A2(
					$elm$core$Maybe$andThen,
					$pablohirafuji$elm_markdown$Markdown$Block$extractOpenCodeFenceRM,
					$elm$core$List$head(
						A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$openCodeFenceLineRegex, rawLine))))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$Ordered = function (a) {
	return {$: 1, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$Block$Unordered = {$: 0};
var $pablohirafuji$elm_markdown$Markdown$Block$extractOrderedListRM = function (match) {
	var _v0 = match.i2;
	if (((((((_v0.b && (!_v0.a.$)) && _v0.b.b) && (!_v0.b.a.$)) && _v0.b.b.b) && (!_v0.b.b.a.$)) && _v0.b.b.b.b) && _v0.b.b.b.b.b) {
		var indentString = _v0.a.a;
		var _v1 = _v0.b;
		var start = _v1.a.a;
		var _v2 = _v1.b;
		var delimiter = _v2.a.a;
		var _v3 = _v2.b;
		var maybeIndentSpace = _v3.a;
		var _v4 = _v3.b;
		var maybeRawLine = _v4.a;
		return $elm$core$Maybe$Just(
			_Utils_Tuple3(
				{
					cI: delimiter,
					O: $elm$core$String$length(indentString) + 1,
					a5: false,
					pu: A2(
						$elm$core$Maybe$withDefault,
						$pablohirafuji$elm_markdown$Markdown$Block$Unordered,
						A2(
							$elm$core$Maybe$map,
							$pablohirafuji$elm_markdown$Markdown$Block$Ordered,
							$elm$core$String$toInt(start)))
				},
				A2($elm$core$Maybe$withDefault, '', maybeIndentSpace),
				A2($elm$core$Maybe$withDefault, '', maybeRawLine)));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$Block$orderedListLineRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^( *(\\d{1,9})([.)])( {0,4}))(?:[ \\t](.*))?$'));
var $pablohirafuji$elm_markdown$Markdown$Block$checkOrderedListLine = function (rawLine) {
	return A2(
		$elm$core$Result$fromMaybe,
		rawLine,
		A2(
			$elm$core$Maybe$andThen,
			$pablohirafuji$elm_markdown$Markdown$Block$extractOrderedListRM,
			$elm$core$List$head(
				A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$orderedListLineRegex, rawLine))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$extractSetextHeadingRM = function (match) {
	var _v0 = match.i2;
	if (_v0.b && (!_v0.a.$)) {
		var delimiter = _v0.a.a;
		return A2($elm$core$String$startsWith, '=', delimiter) ? $elm$core$Maybe$Just(
			_Utils_Tuple2(1, delimiter)) : $elm$core$Maybe$Just(
			_Utils_Tuple2(2, delimiter));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$Block$parseSetextHeadingLine = F3(
	function (rawLine, ast, _v0) {
		var lvl = _v0.a;
		var delimiter = _v0.b;
		if (ast.b && (ast.a.$ === 4)) {
			var _v2 = ast.a;
			var rawText = _v2.a;
			var astTail = ast.b;
			return $elm$core$Maybe$Just(
				A2(
					$elm$core$List$cons,
					A3($pablohirafuji$elm_markdown$Markdown$Block$Heading, rawText, lvl, _List_Nil),
					astTail));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$setextHeadingLineRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^ {0,3}(=+|-+)[ \\t]*$'));
var $pablohirafuji$elm_markdown$Markdown$Block$checkSetextHeadingLine = function (_v0) {
	var rawLine = _v0.a;
	var ast = _v0.b;
	return A2(
		$elm$core$Result$fromMaybe,
		_Utils_Tuple2(rawLine, ast),
		A2(
			$elm$core$Maybe$andThen,
			A2($pablohirafuji$elm_markdown$Markdown$Block$parseSetextHeadingLine, rawLine, ast),
			A2(
				$elm$core$Maybe$andThen,
				$pablohirafuji$elm_markdown$Markdown$Block$extractSetextHeadingRM,
				$elm$core$List$head(
					A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$setextHeadingLineRegex, rawLine)))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$ThematicBreak = {$: 1};
var $pablohirafuji$elm_markdown$Markdown$Block$thematicBreakLineRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^ {0,3}(?:' + ('(?:\\*[ \\t]*){3,}' + ('|(?:_[ \\t]*){3,}' + '|(?:-[ \\t]*){3,})[ \\t]*$'))));
var $pablohirafuji$elm_markdown$Markdown$Block$checkThematicBreakLine = function (_v0) {
	var rawLine = _v0.a;
	var ast = _v0.b;
	return A2(
		$elm$core$Result$fromMaybe,
		_Utils_Tuple2(rawLine, ast),
		A2(
			$elm$core$Maybe$map,
			function (_v1) {
				return A2($elm$core$List$cons, $pablohirafuji$elm_markdown$Markdown$Block$ThematicBreak, ast);
			},
			$elm$core$List$head(
				A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$thematicBreakLineRegex, rawLine))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$extractUnorderedListRM = function (match) {
	var _v0 = match.i2;
	if ((((((_v0.b && (!_v0.a.$)) && _v0.b.b) && (!_v0.b.a.$)) && _v0.b.b.b) && _v0.b.b.b.b) && (!_v0.b.b.b.b.b)) {
		var indentString = _v0.a.a;
		var _v1 = _v0.b;
		var delimiter = _v1.a.a;
		var _v2 = _v1.b;
		var maybeIndentSpace = _v2.a;
		var _v3 = _v2.b;
		var maybeRawLine = _v3.a;
		return $elm$core$Maybe$Just(
			_Utils_Tuple3(
				{
					cI: delimiter,
					O: $elm$core$String$length(indentString) + 1,
					a5: false,
					pu: $pablohirafuji$elm_markdown$Markdown$Block$Unordered
				},
				A2($elm$core$Maybe$withDefault, '', maybeIndentSpace),
				A2($elm$core$Maybe$withDefault, '', maybeRawLine)));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$Block$unorderedListLineRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^( *([\\*\\-\\+])( {0,4}))(?:[ \\t](.*))?$'));
var $pablohirafuji$elm_markdown$Markdown$Block$checkUnorderedListLine = function (rawLine) {
	return A2(
		$elm$core$Result$fromMaybe,
		rawLine,
		A2(
			$elm$core$Maybe$andThen,
			$pablohirafuji$elm_markdown$Markdown$Block$extractUnorderedListRM,
			$elm$core$List$head(
				A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$unorderedListLineRegex, rawLine))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$closeCodeFenceLineRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^ {0,3}(`{3,}|~{3,})\\s*$'));
var $pablohirafuji$elm_markdown$Markdown$Block$isCloseFenceLineHelp = F2(
	function (fence, match) {
		var _v0 = match.i2;
		if (_v0.b && (!_v0.a.$)) {
			var fenceStr = _v0.a.a;
			return (_Utils_cmp(
				$elm$core$String$length(fenceStr),
				fence.eQ) > -1) && _Utils_eq(
				A2($elm$core$String$left, 1, fenceStr),
				fence.eP);
		} else {
			return false;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$isCloseFenceLine = function (fence) {
	return A2(
		$elm$core$Basics$composeR,
		A2($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$closeCodeFenceLineRegex),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$head,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map(
					$pablohirafuji$elm_markdown$Markdown$Block$isCloseFenceLineHelp(fence)),
				$elm$core$Maybe$withDefault(false))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$continueOrCloseCodeFence = F3(
	function (fence, previousCode, rawLine) {
		return A2($pablohirafuji$elm_markdown$Markdown$Block$isCloseFenceLine, fence, rawLine) ? A2(
			$pablohirafuji$elm_markdown$Markdown$Block$CodeBlock,
			A2($pablohirafuji$elm_markdown$Markdown$Block$Fenced, false, fence),
			previousCode) : A2(
			$pablohirafuji$elm_markdown$Markdown$Block$CodeBlock,
			A2($pablohirafuji$elm_markdown$Markdown$Block$Fenced, true, fence),
			previousCode + (A2($pablohirafuji$elm_markdown$Markdown$Helpers$indentLine, fence.O, rawLine) + '\n'));
	});
var $pablohirafuji$elm_markdown$Markdown$Helpers$ifError = F2(
	function (_function, result) {
		if (!result.$) {
			return result;
		} else {
			var err = result.a;
			return _function(err);
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Helpers$initSpacesRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^ +'));
var $pablohirafuji$elm_markdown$Markdown$Helpers$indentLength = A2(
	$elm$core$Basics$composeR,
	A2(
		$elm$regex$Regex$replace,
		$pablohirafuji$elm_markdown$Markdown$Helpers$tabRegex,
		function (_v0) {
			return '    ';
		}),
	A2(
		$elm$core$Basics$composeR,
		A2($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Helpers$initSpacesRegex),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$head,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map(
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.mA;
						},
						$elm$core$String$length)),
				$elm$core$Maybe$withDefault(0)))));
var $pablohirafuji$elm_markdown$Markdown$Block$isBlankLineLast = function (items) {
	isBlankLineLast:
	while (true) {
		if (!items.b) {
			return false;
		} else {
			var item = items.a;
			var itemsTail = items.b;
			_v1$3:
			while (true) {
				if (item.b) {
					switch (item.a.$) {
						case 0:
							if (!item.b.b) {
								return false;
							} else {
								return true;
							}
						case 6:
							var _v2 = item.a;
							var items_ = _v2.b;
							var $temp$items = items_;
							items = $temp$items;
							continue isBlankLineLast;
						default:
							break _v1$3;
					}
				} else {
					break _v1$3;
				}
			}
			return false;
		}
	}
};
var $pablohirafuji$elm_markdown$Markdown$Block$parseTextLine = F2(
	function (rawLine, ast) {
		return A2(
			$elm$core$Maybe$withDefault,
			A2(
				$elm$core$List$cons,
				A2(
					$pablohirafuji$elm_markdown$Markdown$Block$Paragraph,
					$pablohirafuji$elm_markdown$Markdown$Block$formatParagraphLine(rawLine),
					_List_Nil),
				ast),
			A2($pablohirafuji$elm_markdown$Markdown$Block$maybeContinueParagraph, rawLine, ast));
	});
var $pablohirafuji$elm_markdown$Markdown$Block$checkBlockQuote = function (_v16) {
	var rawLine = _v16.a;
	var ast = _v16.b;
	return A2(
		$elm$core$Result$fromMaybe,
		_Utils_Tuple2(rawLine, ast),
		A2(
			$elm$core$Maybe$map,
			$pablohirafuji$elm_markdown$Markdown$Block$parseBlockQuoteLine(ast),
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.i2;
					},
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$head,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$withDefault($elm$core$Maybe$Nothing),
							$elm$core$Maybe$withDefault('')))),
				$elm$core$List$head(
					A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$blockQuoteLineRegex, rawLine)))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$checkListLine = function (_v15) {
	var rawLine = _v15.a;
	var ast = _v15.b;
	return A2(
		$elm$core$Result$mapError,
		function (e) {
			return _Utils_Tuple2(e, ast);
		},
		A2(
			$elm$core$Result$map,
			A2($pablohirafuji$elm_markdown$Markdown$Block$parseListLine, rawLine, ast),
			A2(
				$elm$core$Result$map,
				$pablohirafuji$elm_markdown$Markdown$Block$calcListIndentLength,
				A2(
					$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
					$pablohirafuji$elm_markdown$Markdown$Block$checkUnorderedListLine,
					$pablohirafuji$elm_markdown$Markdown$Block$checkOrderedListLine(rawLine)))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$incorporateLine = F2(
	function (rawLine, ast) {
		_v11$2:
		while (true) {
			if (ast.b) {
				switch (ast.a.$) {
					case 3:
						if ((ast.a.a.$ === 1) && ast.a.a.a) {
							var _v12 = ast.a;
							var _v13 = _v12.a;
							var fence = _v13.b;
							var code = _v12.b;
							var astTail = ast.b;
							return function (a) {
								return A2($elm$core$List$cons, a, astTail);
							}(
								A3($pablohirafuji$elm_markdown$Markdown$Block$continueOrCloseCodeFence, fence, code, rawLine));
						} else {
							break _v11$2;
						}
					case 6:
						var _v14 = ast.a;
						var model = _v14.a;
						var items = _v14.b;
						var astTail = ast.b;
						return (_Utils_cmp(
							$pablohirafuji$elm_markdown$Markdown$Helpers$indentLength(rawLine),
							model.O) > -1) ? A5($pablohirafuji$elm_markdown$Markdown$Block$parseIndentedListLine, rawLine, model, items, ast, astTail) : A2(
							$elm$core$Result$withDefault,
							A2($pablohirafuji$elm_markdown$Markdown$Block$parseTextLine, rawLine, ast),
							A2(
								$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
								$pablohirafuji$elm_markdown$Markdown$Block$checkBlockQuote,
								A2(
									$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
									$pablohirafuji$elm_markdown$Markdown$Block$checkATXHeadingLine,
									A2(
										$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
										$pablohirafuji$elm_markdown$Markdown$Block$checkSetextHeadingLine,
										A2(
											$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
											$pablohirafuji$elm_markdown$Markdown$Block$checkOpenCodeFenceLine,
											A2(
												$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
												$pablohirafuji$elm_markdown$Markdown$Block$checkIndentedCode,
												A2(
													$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
													$pablohirafuji$elm_markdown$Markdown$Block$checkBlankLine,
													A2(
														$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
														$pablohirafuji$elm_markdown$Markdown$Block$checkListLine,
														$pablohirafuji$elm_markdown$Markdown$Block$checkThematicBreakLine(
															_Utils_Tuple2(rawLine, ast))))))))));
					default:
						break _v11$2;
				}
			} else {
				break _v11$2;
			}
		}
		return A2($pablohirafuji$elm_markdown$Markdown$Block$parseRawLine, rawLine, ast);
	});
var $pablohirafuji$elm_markdown$Markdown$Block$parseBlockQuoteLine = F2(
	function (ast, rawLine) {
		if (ast.b && (ast.a.$ === 5)) {
			var bqAST = ast.a.a;
			var astTail = ast.b;
			return function (a) {
				return A2($elm$core$List$cons, a, astTail);
			}(
				$pablohirafuji$elm_markdown$Markdown$Block$BlockQuote(
					A2($pablohirafuji$elm_markdown$Markdown$Block$incorporateLine, rawLine, bqAST)));
		} else {
			return function (a) {
				return A2($elm$core$List$cons, a, ast);
			}(
				$pablohirafuji$elm_markdown$Markdown$Block$BlockQuote(
					A2($pablohirafuji$elm_markdown$Markdown$Block$incorporateLine, rawLine, _List_Nil)));
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$parseIndentedListLine = F5(
	function (rawLine, model, items, ast, astTail) {
		if (!items.b) {
			return function (a) {
				return A2($elm$core$List$cons, a, astTail);
			}(
				A2(
					$pablohirafuji$elm_markdown$Markdown$Block$List,
					model,
					function (a) {
						return A2($elm$core$List$cons, a, _List_Nil);
					}(
						function (a) {
							return A2($pablohirafuji$elm_markdown$Markdown$Block$incorporateLine, a, _List_Nil);
						}(
							A2($pablohirafuji$elm_markdown$Markdown$Helpers$indentLine, model.O, rawLine)))));
		} else {
			var item = items.a;
			var itemsTail = items.b;
			var indentedRawLine = A2($pablohirafuji$elm_markdown$Markdown$Helpers$indentLine, model.O, rawLine);
			var updateList = function (model_) {
				return function (a) {
					return A2($elm$core$List$cons, a, astTail);
				}(
					A2(
						$pablohirafuji$elm_markdown$Markdown$Block$List,
						model_,
						function (a) {
							return A2($elm$core$List$cons, a, itemsTail);
						}(
							A2($pablohirafuji$elm_markdown$Markdown$Block$incorporateLine, indentedRawLine, item))));
			};
			_v7$3:
			while (true) {
				if (item.b) {
					switch (item.a.$) {
						case 0:
							if (!item.b.b) {
								return updateList(model);
							} else {
								var itemTail = item.b;
								return A2(
									$elm$core$List$all,
									function (block) {
										if (!block.$) {
											return true;
										} else {
											return false;
										}
									},
									itemTail) ? A2($pablohirafuji$elm_markdown$Markdown$Block$parseRawLine, rawLine, ast) : updateList(
									_Utils_update(
										model,
										{a5: true}));
							}
						case 6:
							var _v9 = item.a;
							var model_ = _v9.a;
							var items_ = _v9.b;
							var itemTail = item.b;
							return (_Utils_cmp(
								$pablohirafuji$elm_markdown$Markdown$Helpers$indentLength(indentedRawLine),
								model_.O) > -1) ? updateList(model) : ($pablohirafuji$elm_markdown$Markdown$Block$isBlankLineLast(items_) ? updateList(
								_Utils_update(
									model,
									{a5: true})) : updateList(model));
						default:
							break _v7$3;
					}
				} else {
					break _v7$3;
				}
			}
			return updateList(model);
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$parseListLine = F3(
	function (rawLine, ast, _v0) {
		var listBlock = _v0.a;
		var listRawLine = _v0.b;
		var parsedRawLine = A2($pablohirafuji$elm_markdown$Markdown$Block$incorporateLine, listRawLine, _List_Nil);
		var newList = A2(
			$elm$core$List$cons,
			A2(
				$pablohirafuji$elm_markdown$Markdown$Block$List,
				listBlock,
				_List_fromArray(
					[parsedRawLine])),
			ast);
		_v1$2:
		while (true) {
			if (ast.b) {
				switch (ast.a.$) {
					case 6:
						var _v2 = ast.a;
						var model = _v2.a;
						var items = _v2.b;
						var astTail = ast.b;
						return _Utils_eq(listBlock.cI, model.cI) ? function (a) {
							return A2($elm$core$List$cons, a, astTail);
						}(
							A2(
								$pablohirafuji$elm_markdown$Markdown$Block$List,
								_Utils_update(
									model,
									{
										O: listBlock.O,
										a5: model.a5 || $pablohirafuji$elm_markdown$Markdown$Block$isBlankLineLast(items)
									}),
								A2($elm$core$List$cons, parsedRawLine, items))) : newList;
					case 4:
						var _v3 = ast.a;
						var rawText = _v3.a;
						var inlines = _v3.b;
						var astTail = ast.b;
						if ((parsedRawLine.b && (!parsedRawLine.a.$)) && (!parsedRawLine.b.b)) {
							return A2(
								$elm$core$List$cons,
								A2($pablohirafuji$elm_markdown$Markdown$Block$addToParagraph, rawText, rawLine),
								astTail);
						} else {
							var _v5 = listBlock.pu;
							if (_v5.$ === 1) {
								if (_v5.a === 1) {
									return newList;
								} else {
									var _int = _v5.a;
									return A2(
										$elm$core$List$cons,
										A2($pablohirafuji$elm_markdown$Markdown$Block$addToParagraph, rawText, rawLine),
										astTail);
								}
							} else {
								return newList;
							}
						}
					default:
						break _v1$2;
				}
			} else {
				break _v1$2;
			}
		}
		return newList;
	});
var $pablohirafuji$elm_markdown$Markdown$Block$parseRawLine = F2(
	function (rawLine, ast) {
		return A2(
			$elm$core$Result$withDefault,
			A2($pablohirafuji$elm_markdown$Markdown$Block$parseTextLine, rawLine, ast),
			A2(
				$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
				$pablohirafuji$elm_markdown$Markdown$Block$checkListLine,
				A2(
					$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
					$pablohirafuji$elm_markdown$Markdown$Block$checkThematicBreakLine,
					A2(
						$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
						$pablohirafuji$elm_markdown$Markdown$Block$checkBlockQuote,
						A2(
							$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
							$pablohirafuji$elm_markdown$Markdown$Block$checkATXHeadingLine,
							A2(
								$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
								$pablohirafuji$elm_markdown$Markdown$Block$checkSetextHeadingLine,
								A2(
									$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
									$pablohirafuji$elm_markdown$Markdown$Block$checkOpenCodeFenceLine,
									A2(
										$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
										$pablohirafuji$elm_markdown$Markdown$Block$checkIndentedCode,
										$pablohirafuji$elm_markdown$Markdown$Block$checkBlankLine(
											_Utils_Tuple2(rawLine, ast))))))))));
	});
var $pablohirafuji$elm_markdown$Markdown$Block$incorporateLines = F2(
	function (rawLines, ast) {
		if (!rawLines.b) {
			return ast;
		} else {
			var rawLine = rawLines.a;
			var rawLinesTail = rawLines.b;
			return A2(
				$pablohirafuji$elm_markdown$Markdown$Block$incorporateLines,
				rawLinesTail,
				A2($pablohirafuji$elm_markdown$Markdown$Block$incorporateLine, rawLine, ast));
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$Custom = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $pablohirafuji$elm_markdown$Markdown$Block$PlainInlines = function (a) {
	return {$: 7, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$Config$Sanitize = function (a) {
	return {$: 1, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$Config$defaultAllowedHtmlAttributes = _List_fromArray(
	['name', 'class']);
var $pablohirafuji$elm_markdown$Markdown$Config$defaultAllowedHtmlElements = _List_fromArray(
	['address', 'article', 'aside', 'b', 'blockquote', 'br', 'caption', 'center', 'cite', 'code', 'col', 'colgroup', 'dd', 'details', 'div', 'dl', 'dt', 'figcaption', 'figure', 'footer', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hr', 'i', 'legend', 'li', 'menu', 'menuitem', 'nav', 'ol', 'optgroup', 'option', 'p', 'pre', 'section', 'strike', 'summary', 'small', 'table', 'tbody', 'td', 'tfoot', 'th', 'thead', 'tr', 'ul']);
var $pablohirafuji$elm_markdown$Markdown$Config$defaultSanitizeOptions = {gg: $pablohirafuji$elm_markdown$Markdown$Config$defaultAllowedHtmlAttributes, gh: $pablohirafuji$elm_markdown$Markdown$Config$defaultAllowedHtmlElements};
var $pablohirafuji$elm_markdown$Markdown$Config$defaultOptions = {
	iA: $pablohirafuji$elm_markdown$Markdown$Config$Sanitize($pablohirafuji$elm_markdown$Markdown$Config$defaultSanitizeOptions),
	iW: false
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$initParser = F3(
	function (options, refs, rawText) {
		return {d: _List_Nil, ni: options, Z: rawText, nM: refs, i: _List_Nil};
	});
var $pablohirafuji$elm_markdown$Markdown$Inline$CodeInline = function (a) {
	return {$: 2, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$Inline$Emphasis = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $pablohirafuji$elm_markdown$Markdown$Inline$HardLineBreak = {$: 1};
var $pablohirafuji$elm_markdown$Markdown$Inline$HtmlInline = F3(
	function (a, b, c) {
		return {$: 5, a: a, b: b, c: c};
	});
var $pablohirafuji$elm_markdown$Markdown$Inline$Image = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $pablohirafuji$elm_markdown$Markdown$Inline$Link = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $pablohirafuji$elm_markdown$Markdown$Inline$Text = function (a) {
	return {$: 0, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$matchToInline = function (_v0) {
	var match = _v0;
	var _v1 = match.pu;
	switch (_v1.$) {
		case 0:
			return $pablohirafuji$elm_markdown$Markdown$Inline$Text(match.cY);
		case 1:
			return $pablohirafuji$elm_markdown$Markdown$Inline$HardLineBreak;
		case 2:
			return $pablohirafuji$elm_markdown$Markdown$Inline$CodeInline(match.cY);
		case 3:
			var _v2 = _v1.a;
			var text = _v2.a;
			var url = _v2.b;
			return A3(
				$pablohirafuji$elm_markdown$Markdown$Inline$Link,
				url,
				$elm$core$Maybe$Nothing,
				_List_fromArray(
					[
						$pablohirafuji$elm_markdown$Markdown$Inline$Text(text)
					]));
		case 4:
			var _v3 = _v1.a;
			var url = _v3.a;
			var maybeTitle = _v3.b;
			return A3(
				$pablohirafuji$elm_markdown$Markdown$Inline$Link,
				url,
				maybeTitle,
				$pablohirafuji$elm_markdown$Markdown$InlineParser$matchesToInlines(match.d));
		case 5:
			var _v4 = _v1.a;
			var url = _v4.a;
			var maybeTitle = _v4.b;
			return A3(
				$pablohirafuji$elm_markdown$Markdown$Inline$Image,
				url,
				maybeTitle,
				$pablohirafuji$elm_markdown$Markdown$InlineParser$matchesToInlines(match.d));
		case 6:
			var model = _v1.a;
			return A3(
				$pablohirafuji$elm_markdown$Markdown$Inline$HtmlInline,
				model.M,
				model.em,
				$pablohirafuji$elm_markdown$Markdown$InlineParser$matchesToInlines(match.d));
		default:
			var length = _v1.a;
			return A2(
				$pablohirafuji$elm_markdown$Markdown$Inline$Emphasis,
				length,
				$pablohirafuji$elm_markdown$Markdown$InlineParser$matchesToInlines(match.d));
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$matchesToInlines = function (matches) {
	return A2($elm$core$List$map, $pablohirafuji$elm_markdown$Markdown$InlineParser$matchToInline, matches);
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$Match = $elm$core$Basics$identity;
var $pablohirafuji$elm_markdown$Markdown$InlineParser$prepareChildMatch = F2(
	function (parentMatch, childMatch) {
		return _Utils_update(
			childMatch,
			{gZ: childMatch.gZ - parentMatch.aJ, fO: childMatch.fO - parentMatch.aJ, co: childMatch.co - parentMatch.aJ, aJ: childMatch.aJ - parentMatch.aJ});
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$addChild = F2(
	function (parentMatch, childMatch) {
		return _Utils_update(
			parentMatch,
			{
				d: A2(
					$elm$core$List$cons,
					A2($pablohirafuji$elm_markdown$Markdown$InlineParser$prepareChildMatch, parentMatch, childMatch),
					parentMatch.d)
			});
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$organizeMatch = F2(
	function (_v0, matches) {
		var match = _v0;
		if (!matches.b) {
			return _List_fromArray(
				[match]);
		} else {
			var prevMatch = matches.a;
			var matchesTail = matches.b;
			return (_Utils_cmp(prevMatch.gZ, match.fO) < 1) ? A2($elm$core$List$cons, match, matches) : (((_Utils_cmp(prevMatch.fO, match.fO) < 0) && (_Utils_cmp(prevMatch.gZ, match.gZ) > 0)) ? A2(
				$elm$core$List$cons,
				A2($pablohirafuji$elm_markdown$Markdown$InlineParser$addChild, prevMatch, match),
				matchesTail) : matches);
		}
	});
function $pablohirafuji$elm_markdown$Markdown$InlineParser$cyclic$organizeMatches() {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$List$sortBy(
			function (_v0) {
				var match = _v0;
				return match.fO;
			}),
		A2(
			$elm$core$Basics$composeR,
			A2($elm$core$List$foldl, $pablohirafuji$elm_markdown$Markdown$InlineParser$organizeMatch, _List_Nil),
			$elm$core$List$map(
				function (_v1) {
					var match = _v1;
					return _Utils_update(
						match,
						{
							d: $pablohirafuji$elm_markdown$Markdown$InlineParser$cyclic$organizeMatches()(match.d)
						});
				})));
}
var $pablohirafuji$elm_markdown$Markdown$InlineParser$organizeMatches = $pablohirafuji$elm_markdown$Markdown$InlineParser$cyclic$organizeMatches();
$pablohirafuji$elm_markdown$Markdown$InlineParser$cyclic$organizeMatches = function () {
	return $pablohirafuji$elm_markdown$Markdown$InlineParser$organizeMatches;
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$organizeParserMatches = function (model) {
	return _Utils_update(
		model,
		{
			d: $pablohirafuji$elm_markdown$Markdown$InlineParser$organizeMatches(model.d)
		});
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$NormalType = {$: 0};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$normalMatch = function (text) {
	return {
		gZ: 0,
		d: _List_Nil,
		fO: 0,
		cY: $pablohirafuji$elm_markdown$Markdown$Helpers$formatStr(text),
		co: 0,
		aJ: 0,
		pu: $pablohirafuji$elm_markdown$Markdown$InlineParser$NormalType
	};
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$parseTextMatch = F3(
	function (rawText, _v2, parsedMatches) {
		var matchModel = _v2;
		var updtMatch = _Utils_update(
			matchModel,
			{
				d: A3($pablohirafuji$elm_markdown$Markdown$InlineParser$parseTextMatches, matchModel.cY, _List_Nil, matchModel.d)
			});
		if (!parsedMatches.b) {
			var finalStr = A2($elm$core$String$dropLeft, matchModel.gZ, rawText);
			return $elm$core$String$isEmpty(finalStr) ? _List_fromArray(
				[updtMatch]) : _List_fromArray(
				[
					updtMatch,
					$pablohirafuji$elm_markdown$Markdown$InlineParser$normalMatch(finalStr)
				]);
		} else {
			var matchHead = parsedMatches.a;
			var matchesTail = parsedMatches.b;
			return _Utils_eq(matchHead.pu, $pablohirafuji$elm_markdown$Markdown$InlineParser$NormalType) ? A2($elm$core$List$cons, updtMatch, parsedMatches) : (_Utils_eq(matchModel.gZ, matchHead.fO) ? A2($elm$core$List$cons, updtMatch, parsedMatches) : ((_Utils_cmp(matchModel.gZ, matchHead.fO) < 0) ? A2(
				$elm$core$List$cons,
				updtMatch,
				A2(
					$elm$core$List$cons,
					$pablohirafuji$elm_markdown$Markdown$InlineParser$normalMatch(
						A3($elm$core$String$slice, matchModel.gZ, matchHead.fO, rawText)),
					parsedMatches)) : parsedMatches));
		}
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$parseTextMatches = F3(
	function (rawText, parsedMatches, matches) {
		parseTextMatches:
		while (true) {
			if (!matches.b) {
				if (!parsedMatches.b) {
					return $elm$core$String$isEmpty(rawText) ? _List_Nil : _List_fromArray(
						[
							$pablohirafuji$elm_markdown$Markdown$InlineParser$normalMatch(rawText)
						]);
				} else {
					var matchModel = parsedMatches.a;
					return (matchModel.fO > 0) ? A2(
						$elm$core$List$cons,
						$pablohirafuji$elm_markdown$Markdown$InlineParser$normalMatch(
							A2($elm$core$String$left, matchModel.fO, rawText)),
						parsedMatches) : parsedMatches;
				}
			} else {
				var match = matches.a;
				var matchesTail = matches.b;
				var $temp$rawText = rawText,
					$temp$parsedMatches = A3($pablohirafuji$elm_markdown$Markdown$InlineParser$parseTextMatch, rawText, match, parsedMatches),
					$temp$matches = matchesTail;
				rawText = $temp$rawText;
				parsedMatches = $temp$parsedMatches;
				matches = $temp$matches;
				continue parseTextMatches;
			}
		}
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$parseText = function (model) {
	return _Utils_update(
		model,
		{
			d: A3($pablohirafuji$elm_markdown$Markdown$InlineParser$parseTextMatches, model.Z, _List_Nil, model.d)
		});
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$angleBracketLTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\<)'));
var $elm$regex$Regex$find = _Regex_findAtMost(_Regex_infinity);
var $pablohirafuji$elm_markdown$Markdown$InlineParser$CharToken = function (a) {
	return {$: 3, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$Helpers$isEven = function (_int) {
	return !A2($elm$core$Basics$modBy, 2, _int);
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToAngleBracketLToken = function (regMatch) {
	var _v0 = regMatch.i2;
	if ((_v0.b && _v0.b.b) && (!_v0.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var delimiter = _v1.a.a;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		return $pablohirafuji$elm_markdown$Markdown$Helpers$isEven(backslashesLength) ? $elm$core$Maybe$Just(
			{
				l1: regMatch.l1 + backslashesLength,
				z: 1,
				f: $pablohirafuji$elm_markdown$Markdown$InlineParser$CharToken('<')
			}) : $elm$core$Maybe$Nothing;
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$findAngleBracketLTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToAngleBracketLToken,
		A2($elm$regex$Regex$find, $pablohirafuji$elm_markdown$Markdown$InlineParser$angleBracketLTokenRegex, str));
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$angleBracketRTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\>)'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$RightAngleBracket = function (a) {
	return {$: 4, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToAngleBracketRToken = function (regMatch) {
	var _v0 = regMatch.i2;
	if ((_v0.b && _v0.b.b) && (!_v0.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		return $elm$core$Maybe$Just(
			{
				l1: regMatch.l1 + backslashesLength,
				z: 1,
				f: $pablohirafuji$elm_markdown$Markdown$InlineParser$RightAngleBracket(
					!$pablohirafuji$elm_markdown$Markdown$Helpers$isEven(backslashesLength))
			});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$findAngleBracketRTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToAngleBracketRToken,
		A2($elm$regex$Regex$find, $pablohirafuji$elm_markdown$Markdown$InlineParser$angleBracketRTokenRegex, str));
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$asteriskEmphasisTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)([^*])?(\\*+)([^*])?'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$EmphasisToken = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$punctuationRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('[!-#%-\\*,-/:;\\?@\\[-\\]_\\{\\}]'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$containPunctuation = $elm$regex$Regex$contains($pablohirafuji$elm_markdown$Markdown$InlineParser$punctuationRegex);
var $pablohirafuji$elm_markdown$Markdown$InlineParser$spaceRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('\\s'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$containSpace = $elm$regex$Regex$contains($pablohirafuji$elm_markdown$Markdown$InlineParser$spaceRegex);
var $pablohirafuji$elm_markdown$Markdown$InlineParser$charFringeRank = function (_char) {
	var string = $elm$core$String$fromChar(_char);
	return $pablohirafuji$elm_markdown$Markdown$InlineParser$containSpace(string) ? 0 : ($pablohirafuji$elm_markdown$Markdown$InlineParser$containPunctuation(string) ? 1 : 2);
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$maybeCharFringeRank = function (maybeChar) {
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		A2($elm$core$Maybe$map, $pablohirafuji$elm_markdown$Markdown$InlineParser$charFringeRank, maybeChar));
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$getFringeRank = A2(
	$elm$core$Basics$composeR,
	$elm$core$Maybe$map(
		A2(
			$elm$core$Basics$composeR,
			$elm$core$String$uncons,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map($elm$core$Tuple$first),
				$pablohirafuji$elm_markdown$Markdown$InlineParser$maybeCharFringeRank))),
	$elm$core$Maybe$withDefault(0));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToEmphasisToken = F3(
	function (_char, rawText, regMatch) {
		var _v0 = regMatch.i2;
		if ((((_v0.b && _v0.b.b) && _v0.b.b.b) && (!_v0.b.b.a.$)) && _v0.b.b.b.b) {
			var maybeBackslashes = _v0.a;
			var _v1 = _v0.b;
			var maybeLeftFringe = _v1.a;
			var _v2 = _v1.b;
			var delimiter = _v2.a.a;
			var _v3 = _v2.b;
			var maybeRightFringe = _v3.a;
			var leftFringeLength = A2(
				$elm$core$Maybe$withDefault,
				0,
				A2($elm$core$Maybe$map, $elm$core$String$length, maybeLeftFringe));
			var mLeftFringe = ((!(!regMatch.l1)) && (!leftFringeLength)) ? $elm$core$Maybe$Just(
				A3($elm$core$String$slice, regMatch.l1 - 1, regMatch.l1, rawText)) : maybeLeftFringe;
			var backslashesLength = A2(
				$elm$core$Maybe$withDefault,
				0,
				A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
			var isEscaped = ((!$pablohirafuji$elm_markdown$Markdown$Helpers$isEven(backslashesLength)) && (!leftFringeLength)) || _Utils_eq(
				mLeftFringe,
				$elm$core$Maybe$Just('\\'));
			var delimiterLength = isEscaped ? ($elm$core$String$length(delimiter) - 1) : $elm$core$String$length(delimiter);
			var fringeRank = _Utils_Tuple2(
				isEscaped ? 1 : $pablohirafuji$elm_markdown$Markdown$InlineParser$getFringeRank(mLeftFringe),
				$pablohirafuji$elm_markdown$Markdown$InlineParser$getFringeRank(maybeRightFringe));
			var index = ((regMatch.l1 + backslashesLength) + leftFringeLength) + (isEscaped ? 1 : 0);
			return ((delimiterLength <= 0) || ((_char === '_') && _Utils_eq(
				fringeRank,
				_Utils_Tuple2(2, 2)))) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				{
					l1: index,
					z: delimiterLength,
					f: A2($pablohirafuji$elm_markdown$Markdown$InlineParser$EmphasisToken, _char, fringeRank)
				});
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$findAsteriskEmphasisTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		A2($pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToEmphasisToken, '*', str),
		A2($elm$regex$Regex$find, $pablohirafuji$elm_markdown$Markdown$InlineParser$asteriskEmphasisTokenRegex, str));
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$codeTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\`+)'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$CodeToken = function (a) {
	return {$: 0, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToCodeToken = function (regMatch) {
	var _v0 = regMatch.i2;
	if ((_v0.b && _v0.b.b) && (!_v0.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var backtick = _v1.a.a;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		return $elm$core$Maybe$Just(
			{
				l1: regMatch.l1 + backslashesLength,
				z: $elm$core$String$length(backtick),
				f: $pablohirafuji$elm_markdown$Markdown$InlineParser$CodeToken(
					!$pablohirafuji$elm_markdown$Markdown$Helpers$isEven(backslashesLength))
			});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$findCodeTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToCodeToken,
		A2($elm$regex$Regex$find, $pablohirafuji$elm_markdown$Markdown$InlineParser$codeTokenRegex, str));
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$hardBreakTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(?:(\\\\+)|( {2,}))\\n'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$HardLineBreakToken = {$: 8};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToHardBreakToken = function (regMatch) {
	var _v0 = regMatch.i2;
	_v0$2:
	while (true) {
		if (_v0.b) {
			if (!_v0.a.$) {
				var backslashes = _v0.a.a;
				var backslashesLength = $elm$core$String$length(backslashes);
				return (!$pablohirafuji$elm_markdown$Markdown$Helpers$isEven(backslashesLength)) ? $elm$core$Maybe$Just(
					{l1: (regMatch.l1 + backslashesLength) - 1, z: 2, f: $pablohirafuji$elm_markdown$Markdown$InlineParser$HardLineBreakToken}) : $elm$core$Maybe$Nothing;
			} else {
				if (_v0.b.b && (!_v0.b.a.$)) {
					var _v1 = _v0.b;
					return $elm$core$Maybe$Just(
						{
							l1: regMatch.l1,
							z: $elm$core$String$length(regMatch.mA),
							f: $pablohirafuji$elm_markdown$Markdown$InlineParser$HardLineBreakToken
						});
				} else {
					break _v0$2;
				}
			}
		} else {
			break _v0$2;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToSoftHardBreakToken = function (regMatch) {
	var _v0 = regMatch.i2;
	_v0$2:
	while (true) {
		if (_v0.b) {
			if (!_v0.a.$) {
				var backslashes = _v0.a.a;
				var backslashesLength = $elm$core$String$length(backslashes);
				return $pablohirafuji$elm_markdown$Markdown$Helpers$isEven(backslashesLength) ? $elm$core$Maybe$Just(
					{l1: regMatch.l1 + backslashesLength, z: 1, f: $pablohirafuji$elm_markdown$Markdown$InlineParser$HardLineBreakToken}) : $elm$core$Maybe$Just(
					{l1: (regMatch.l1 + backslashesLength) - 1, z: 2, f: $pablohirafuji$elm_markdown$Markdown$InlineParser$HardLineBreakToken});
			} else {
				if (_v0.b.b) {
					var _v1 = _v0.b;
					var maybeSpaces = _v1.a;
					return $elm$core$Maybe$Just(
						{
							l1: regMatch.l1,
							z: $elm$core$String$length(regMatch.mA),
							f: $pablohirafuji$elm_markdown$Markdown$InlineParser$HardLineBreakToken
						});
				} else {
					break _v0$2;
				}
			}
		} else {
			break _v0$2;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$softAsHardLineBreakTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(?:(\\\\+)|( *))\\n'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$findHardBreakTokens = F2(
	function (softAsHardLineBreak, str) {
		return softAsHardLineBreak ? A2(
			$elm$core$List$filterMap,
			$pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToSoftHardBreakToken,
			A2($elm$regex$Regex$find, $pablohirafuji$elm_markdown$Markdown$InlineParser$softAsHardLineBreakTokenRegex, str)) : A2(
			$elm$core$List$filterMap,
			$pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToHardBreakToken,
			A2($elm$regex$Regex$find, $pablohirafuji$elm_markdown$Markdown$InlineParser$hardBreakTokenRegex, str));
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$linkImageCloseTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\])'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToLinkImageCloseToken = function (regMatch) {
	var _v0 = regMatch.i2;
	if ((_v0.b && _v0.b.b) && (!_v0.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var delimiter = _v1.a.a;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		return $pablohirafuji$elm_markdown$Markdown$Helpers$isEven(backslashesLength) ? $elm$core$Maybe$Just(
			{
				l1: regMatch.l1 + backslashesLength,
				z: 1,
				f: $pablohirafuji$elm_markdown$Markdown$InlineParser$CharToken(']')
			}) : $elm$core$Maybe$Nothing;
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$findLinkImageCloseTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToLinkImageCloseToken,
		A2($elm$regex$Regex$find, $pablohirafuji$elm_markdown$Markdown$InlineParser$linkImageCloseTokenRegex, str));
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$linkImageOpenTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)(\\!)?(\\[)'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$ImageOpenToken = {$: 2};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$LinkOpenToken = function (a) {
	return {$: 1, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToLinkImageOpenToken = function (regMatch) {
	var _v0 = regMatch.i2;
	if (((_v0.b && _v0.b.b) && _v0.b.b.b) && (!_v0.b.b.a.$)) {
		var maybeBackslashes = _v0.a;
		var _v1 = _v0.b;
		var maybeImageOpen = _v1.a;
		var _v2 = _v1.b;
		var delimiter = _v2.a.a;
		var backslashesLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2($elm$core$Maybe$map, $elm$core$String$length, maybeBackslashes));
		var isEscaped = !$pablohirafuji$elm_markdown$Markdown$Helpers$isEven(backslashesLength);
		var index = (regMatch.l1 + backslashesLength) + ((isEscaped && _Utils_eq(
			maybeImageOpen,
			$elm$core$Maybe$Just('!'))) ? 1 : 0);
		var meaning = isEscaped ? A2(
			$elm$core$Maybe$map,
			function (_v3) {
				return $pablohirafuji$elm_markdown$Markdown$InlineParser$LinkOpenToken(true);
			},
			maybeImageOpen) : $elm$core$Maybe$Just(
			A2(
				$elm$core$Maybe$withDefault,
				$pablohirafuji$elm_markdown$Markdown$InlineParser$LinkOpenToken(true),
				A2(
					$elm$core$Maybe$map,
					function (_v4) {
						return $pablohirafuji$elm_markdown$Markdown$InlineParser$ImageOpenToken;
					},
					maybeImageOpen)));
		var length = _Utils_eq(
			meaning,
			$elm$core$Maybe$Just($pablohirafuji$elm_markdown$Markdown$InlineParser$ImageOpenToken)) ? 2 : 1;
		var toModel = function (m) {
			return {l1: index, z: length, f: m};
		};
		return A2($elm$core$Maybe$map, toModel, meaning);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$findLinkImageOpenTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		$pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToLinkImageOpenToken,
		A2($elm$regex$Regex$find, $pablohirafuji$elm_markdown$Markdown$InlineParser$linkImageOpenTokenRegex, str));
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$underlineEmphasisTokenRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('(\\\\*)([^_])?(\\_+)([^_])?'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$findUnderlineEmphasisTokens = function (str) {
	return A2(
		$elm$core$List$filterMap,
		A2($pablohirafuji$elm_markdown$Markdown$InlineParser$regMatchToEmphasisToken, '_', str),
		A2($elm$regex$Regex$find, $pablohirafuji$elm_markdown$Markdown$InlineParser$underlineEmphasisTokenRegex, str));
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$tokenize = function (model) {
	return _Utils_update(
		model,
		{
			i: A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.l1;
				},
				_Utils_ap(
					$pablohirafuji$elm_markdown$Markdown$InlineParser$findAngleBracketRTokens(model.Z),
					_Utils_ap(
						$pablohirafuji$elm_markdown$Markdown$InlineParser$findAngleBracketLTokens(model.Z),
						_Utils_ap(
							A2($pablohirafuji$elm_markdown$Markdown$InlineParser$findHardBreakTokens, model.ni.iW, model.Z),
							_Utils_ap(
								$pablohirafuji$elm_markdown$Markdown$InlineParser$findLinkImageCloseTokens(model.Z),
								_Utils_ap(
									$pablohirafuji$elm_markdown$Markdown$InlineParser$findLinkImageOpenTokens(model.Z),
									_Utils_ap(
										$pablohirafuji$elm_markdown$Markdown$InlineParser$findUnderlineEmphasisTokens(model.Z),
										_Utils_ap(
											$pablohirafuji$elm_markdown$Markdown$InlineParser$findAsteriskEmphasisTokens(model.Z),
											$pablohirafuji$elm_markdown$Markdown$InlineParser$findCodeTokens(model.Z)))))))))
		});
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$CodeType = {$: 2};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$EmphasisType = function (a) {
	return {$: 7, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$HtmlType = function (a) {
	return {$: 6, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$ImageType = function (a) {
	return {$: 5, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$LinkType = function (a) {
	return {$: 4, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$addMatch = F2(
	function (model, match) {
		return _Utils_update(
			model,
			{
				d: A2($elm$core$List$cons, match, model.d)
			});
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$addToken = F2(
	function (model, token) {
		return _Utils_update(
			model,
			{
				i: A2($elm$core$List$cons, token, model.i)
			});
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$applyTTM = F2(
	function (finderFunction, model) {
		return finderFunction(
			_Utils_Tuple2(
				model.i,
				_Utils_update(
					model,
					{i: _List_Nil})));
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$AutolinkType = function (a) {
	return {$: 3, a: a};
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$decodeUrlRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('%(?:3B|2C|2F|3F|3A|40|26|3D|2B|24|23|25)'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$encodeUrl = A2(
	$elm$core$Basics$composeR,
	$elm$url$Url$percentEncode,
	A2(
		$elm$regex$Regex$replace,
		$pablohirafuji$elm_markdown$Markdown$InlineParser$decodeUrlRegex,
		function (match) {
			return A2(
				$elm$core$Maybe$withDefault,
				match.mA,
				$elm$url$Url$percentDecode(match.mA));
		}));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$urlRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^([A-Za-z][A-Za-z0-9.+\\-]{1,31}:[^<>\\x00-\\x20]*)$'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$autolinkToMatch = function (_v0) {
	var match = _v0;
	return A2($elm$regex$Regex$contains, $pablohirafuji$elm_markdown$Markdown$InlineParser$urlRegex, match.cY) ? $elm$core$Result$Ok(
		_Utils_update(
			match,
			{
				pu: $pablohirafuji$elm_markdown$Markdown$InlineParser$AutolinkType(
					_Utils_Tuple2(
						match.cY,
						$pablohirafuji$elm_markdown$Markdown$InlineParser$encodeUrl(match.cY)))
			})) : $elm$core$Result$Err(match);
};
var $pablohirafuji$elm_markdown$Markdown$Helpers$whiteSpaceChars = ' \\t\\f\\v\\r\\n';
var $pablohirafuji$elm_markdown$Markdown$InlineParser$hrefRegex = '(?:<([^<>' + ($pablohirafuji$elm_markdown$Markdown$Helpers$whiteSpaceChars + (']*)>|([^' + ($pablohirafuji$elm_markdown$Markdown$Helpers$whiteSpaceChars + ('\\(\\)\\\\]*(?:\\\\.[^' + ($pablohirafuji$elm_markdown$Markdown$Helpers$whiteSpaceChars + '\\(\\)\\\\]*)*))')))));
var $pablohirafuji$elm_markdown$Markdown$Helpers$titleRegex = '(?:[' + ($pablohirafuji$elm_markdown$Markdown$Helpers$whiteSpaceChars + (']+' + ('(?:\'([^\'\\\\]*(?:\\\\.[^\'\\\\]*)*)\'|' + ('\"([^\"\\\\]*(?:\\\\.[^\"\\\\]*)*)\"|' + '\\(([^\\)\\\\]*(?:\\\\.[^\\)\\\\]*)*)\\)))?'))));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$inlineLinkTypeOrImageTypeRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^\\(\\s*' + ($pablohirafuji$elm_markdown$Markdown$InlineParser$hrefRegex + ($pablohirafuji$elm_markdown$Markdown$Helpers$titleRegex + '\\s*\\)'))));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$prepareUrlAndTitle = function (_v0) {
	var rawUrl = _v0.a;
	var maybeTitle = _v0.b;
	return _Utils_Tuple2(
		$pablohirafuji$elm_markdown$Markdown$InlineParser$encodeUrl(
			$pablohirafuji$elm_markdown$Markdown$Helpers$formatStr(rawUrl)),
		A2($elm$core$Maybe$map, $pablohirafuji$elm_markdown$Markdown$Helpers$formatStr, maybeTitle));
};
var $pablohirafuji$elm_markdown$Markdown$Helpers$returnFirstJust = function (maybes) {
	var process = F2(
		function (a, maybeFound) {
			if (!maybeFound.$) {
				var found = maybeFound.a;
				return $elm$core$Maybe$Just(found);
			} else {
				return a;
			}
		});
	return A3($elm$core$List$foldl, process, $elm$core$Maybe$Nothing, maybes);
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$inlineLinkTypeOrImageTypeRegexToMatch = F3(
	function (matchModel, model, regexMatch) {
		var _v0 = regexMatch.i2;
		if ((((_v0.b && _v0.b.b) && _v0.b.b.b) && _v0.b.b.b.b) && _v0.b.b.b.b.b) {
			var maybeRawUrlAngleBrackets = _v0.a;
			var _v1 = _v0.b;
			var maybeRawUrlWithoutBrackets = _v1.a;
			var _v2 = _v1.b;
			var maybeTitleSingleQuotes = _v2.a;
			var _v3 = _v2.b;
			var maybeTitleDoubleQuotes = _v3.a;
			var _v4 = _v3.b;
			var maybeTitleParenthesis = _v4.a;
			var maybeTitle = $pablohirafuji$elm_markdown$Markdown$Helpers$returnFirstJust(
				_List_fromArray(
					[maybeTitleSingleQuotes, maybeTitleDoubleQuotes, maybeTitleParenthesis]));
			var toMatch = function (rawUrl) {
				return _Utils_update(
					matchModel,
					{
						gZ: matchModel.gZ + $elm$core$String$length(regexMatch.mA),
						pu: function () {
							var _v5 = matchModel.pu;
							if (_v5.$ === 5) {
								return $pablohirafuji$elm_markdown$Markdown$InlineParser$ImageType;
							} else {
								return $pablohirafuji$elm_markdown$Markdown$InlineParser$LinkType;
							}
						}()(
							$pablohirafuji$elm_markdown$Markdown$InlineParser$prepareUrlAndTitle(
								_Utils_Tuple2(rawUrl, maybeTitle)))
					});
			};
			var maybeRawUrl = $pablohirafuji$elm_markdown$Markdown$Helpers$returnFirstJust(
				_List_fromArray(
					[maybeRawUrlAngleBrackets, maybeRawUrlWithoutBrackets]));
			return $elm$core$Maybe$Just(
				toMatch(
					A2($elm$core$Maybe$withDefault, '', maybeRawUrl)));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$checkForInlineLinkTypeOrImageType = function (_v0) {
	var remainText = _v0.a;
	var tempMatch = _v0.b;
	var model = _v0.c;
	return A2(
		$elm$core$Result$fromMaybe,
		_Utils_Tuple3(remainText, tempMatch, model),
		A2(
			$elm$core$Maybe$map,
			$pablohirafuji$elm_markdown$Markdown$InlineParser$addMatch(model),
			A2(
				$elm$core$Maybe$andThen,
				A2($pablohirafuji$elm_markdown$Markdown$InlineParser$inlineLinkTypeOrImageTypeRegexToMatch, tempMatch, model),
				$elm$core$List$head(
					A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$InlineParser$inlineLinkTypeOrImageTypeRegex, remainText)))));
};
var $pablohirafuji$elm_markdown$Markdown$Helpers$insideSquareBracketRegex = '[^\\[\\]\\\\]*(?:\\\\.[^\\[\\]\\\\]*)*';
var $pablohirafuji$elm_markdown$Markdown$InlineParser$refLabelRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^\\[\\s*(' + ($pablohirafuji$elm_markdown$Markdown$Helpers$insideSquareBracketRegex + ')\\s*\\]')));
var $pablohirafuji$elm_markdown$Markdown$Helpers$whitespacesRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('[' + ($pablohirafuji$elm_markdown$Markdown$Helpers$whiteSpaceChars + ']+')));
var $pablohirafuji$elm_markdown$Markdown$Helpers$cleanWhitespaces = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$trim,
	A2(
		$elm$regex$Regex$replace,
		$pablohirafuji$elm_markdown$Markdown$Helpers$whitespacesRegex,
		function (_v0) {
			return ' ';
		}));
var $pablohirafuji$elm_markdown$Markdown$Helpers$prepareRefLabel = A2($elm$core$Basics$composeR, $pablohirafuji$elm_markdown$Markdown$Helpers$cleanWhitespaces, $elm$core$String$toLower);
var $pablohirafuji$elm_markdown$Markdown$InlineParser$refRegexToMatch = F3(
	function (matchModel, model, maybeRegexMatch) {
		var regexMatchLength = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.mA;
					},
					$elm$core$String$length),
				maybeRegexMatch));
		var toMatch = function (urlTitle) {
			return _Utils_update(
				matchModel,
				{
					gZ: matchModel.gZ + regexMatchLength,
					pu: function () {
						var _v0 = matchModel.pu;
						if (_v0.$ === 5) {
							return $pablohirafuji$elm_markdown$Markdown$InlineParser$ImageType;
						} else {
							return $pablohirafuji$elm_markdown$Markdown$InlineParser$LinkType;
						}
					}()(
						$pablohirafuji$elm_markdown$Markdown$InlineParser$prepareUrlAndTitle(urlTitle))
				});
		};
		var refLabel = function (str) {
			return $elm$core$String$isEmpty(str) ? matchModel.cY : str;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				matchModel.cY,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Maybe$Nothing,
					A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Maybe$Nothing,
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.i2;
								},
								$elm$core$List$head),
							maybeRegexMatch)))));
		var maybeRefItem = A2(
			$elm$core$Dict$get,
			$pablohirafuji$elm_markdown$Markdown$Helpers$prepareRefLabel(refLabel),
			model.nM);
		return A2($elm$core$Maybe$map, toMatch, maybeRefItem);
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$checkForRefLinkTypeOrImageType = function (_v0) {
	var remainText = _v0.a;
	var tempMatch = _v0.b;
	var model = _v0.c;
	return A2(
		$elm$core$Result$fromMaybe,
		_Utils_Tuple3(remainText, tempMatch, model),
		A2(
			$elm$core$Maybe$map,
			$pablohirafuji$elm_markdown$Markdown$InlineParser$addMatch(model),
			A3(
				$pablohirafuji$elm_markdown$Markdown$InlineParser$refRegexToMatch,
				tempMatch,
				model,
				$elm$core$List$head(
					A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$InlineParser$refLabelRegex, remainText)))));
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$checkParsedAheadOverlapping = function (parser) {
	var _v0 = parser.d;
	if (!_v0.b) {
		return $elm$core$Result$Err(0);
	} else {
		var match = _v0.a;
		var remainMatches = _v0.b;
		var overlappingMatches = A2(
			$elm$core$List$filter,
			function (_v1) {
				var testMatch = _v1;
				return (_Utils_cmp(match.gZ, testMatch.fO) > 0) && (_Utils_cmp(match.gZ, testMatch.gZ) < 0);
			},
			remainMatches);
		return ($elm$core$List$isEmpty(remainMatches) || $elm$core$List$isEmpty(overlappingMatches)) ? $elm$core$Result$Ok(parser) : $elm$core$Result$Err(0);
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$emailRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^([a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~\\-]+@[a-zA-Z0-9](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?)*)$'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$emailAutolinkTypeToMatch = function (_v0) {
	var match = _v0;
	return A2($elm$regex$Regex$contains, $pablohirafuji$elm_markdown$Markdown$InlineParser$emailRegex, match.cY) ? $elm$core$Result$Ok(
		_Utils_update(
			match,
			{
				pu: $pablohirafuji$elm_markdown$Markdown$InlineParser$AutolinkType(
					_Utils_Tuple2(
						match.cY,
						'mailto:' + $pablohirafuji$elm_markdown$Markdown$InlineParser$encodeUrl(match.cY)))
			})) : $elm$core$Result$Err(match);
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$filterTokens = F2(
	function (filter, model) {
		return _Utils_update(
			model,
			{
				i: A2($elm$core$List$filter, filter, model.i)
			});
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$findToken = F2(
	function (isToken, tokens) {
		var search = F2(
			function (token, _v2) {
				var maybeToken = _v2.a;
				var innerTokens = _v2.b;
				var remainTokens = _v2.c;
				if (maybeToken.$ === 1) {
					return isToken(token) ? _Utils_Tuple3(
						$elm$core$Maybe$Just(token),
						innerTokens,
						_List_Nil) : _Utils_Tuple3(
						$elm$core$Maybe$Nothing,
						A2($elm$core$List$cons, token, innerTokens),
						_List_Nil);
				} else {
					return _Utils_Tuple3(
						maybeToken,
						innerTokens,
						A2($elm$core$List$cons, token, remainTokens));
				}
			});
		var _return = function (_v0) {
			var maybeToken = _v0.a;
			var innerTokens = _v0.b;
			var remainTokens = _v0.c;
			return A2(
				$elm$core$Maybe$map,
				function (token) {
					return _Utils_Tuple3(
						token,
						$elm$core$List$reverse(innerTokens),
						$elm$core$List$reverse(remainTokens));
				},
				maybeToken);
		};
		return _return(
			A3(
				$elm$core$List$foldl,
				search,
				_Utils_Tuple3($elm$core$Maybe$Nothing, _List_Nil, _List_Nil),
				tokens));
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$HtmlModel = F2(
	function (tag, attributes) {
		return {em: attributes, M: tag};
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$HtmlToken = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$attributesFromRegex = function (regexMatch) {
	var _v0 = regexMatch.i2;
	_v0$2:
	while (true) {
		if (_v0.b && (!_v0.a.$)) {
			if (_v0.a.a === '') {
				return $elm$core$Maybe$Nothing;
			} else {
				if ((_v0.b.b && _v0.b.b.b) && _v0.b.b.b.b) {
					var name = _v0.a.a;
					var _v1 = _v0.b;
					var maybeDoubleQuotes = _v1.a;
					var _v2 = _v1.b;
					var maybeSingleQuotes = _v2.a;
					var _v3 = _v2.b;
					var maybeUnquoted = _v3.a;
					var maybeValue = $pablohirafuji$elm_markdown$Markdown$Helpers$returnFirstJust(
						_List_fromArray(
							[maybeDoubleQuotes, maybeSingleQuotes, maybeUnquoted]));
					return $elm$core$Maybe$Just(
						_Utils_Tuple2(name, maybeValue));
				} else {
					break _v0$2;
				}
			}
		} else {
			break _v0$2;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$htmlAttributesRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('([a-zA-Z:_][a-zA-Z0-9\\-_.:]*)(?: ?= ?(?:\"([^\"]*)\"|\'([^\']*)\'|([^\\s\"\'=<>`]*)))?'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$applyAttributesRegex = A2(
	$elm$core$Basics$composeR,
	$elm$regex$Regex$find($pablohirafuji$elm_markdown$Markdown$InlineParser$htmlAttributesRegex),
	$elm$core$List$filterMap($pablohirafuji$elm_markdown$Markdown$InlineParser$attributesFromRegex));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$htmlFromRegex = F3(
	function (model, match, regexMatch) {
		var _v0 = regexMatch.i2;
		if ((((_v0.b && _v0.b.b) && (!_v0.b.a.$)) && _v0.b.b.b) && _v0.b.b.b.b) {
			var maybeClose = _v0.a;
			var _v1 = _v0.b;
			var tag = _v1.a.a;
			var _v2 = _v1.b;
			var maybeAttributes = _v2.a;
			var _v3 = _v2.b;
			var maybeSelfClosing = _v3.a;
			var updateModel = function (attrs) {
				return A2(
					$pablohirafuji$elm_markdown$Markdown$InlineParser$addToken,
					model,
					{
						l1: match.fO,
						z: match.gZ - match.fO,
						f: A2(
							$pablohirafuji$elm_markdown$Markdown$InlineParser$HtmlToken,
							_Utils_eq(maybeClose, $elm$core$Maybe$Nothing) && _Utils_eq(maybeSelfClosing, $elm$core$Maybe$Nothing),
							A2($pablohirafuji$elm_markdown$Markdown$InlineParser$HtmlModel, tag, attrs))
					});
			};
			var filterAttributes = F2(
				function (attrs, allowed) {
					return A2(
						$elm$core$List$filter,
						function (attr) {
							return A2($elm$core$List$member, attr.a, allowed);
						},
						attrs);
				});
			var attributes = A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2($elm$core$Maybe$map, $pablohirafuji$elm_markdown$Markdown$InlineParser$applyAttributesRegex, maybeAttributes));
			var noAttributesInCloseTag = _Utils_eq(maybeClose, $elm$core$Maybe$Nothing) || ((!_Utils_eq(maybeClose, $elm$core$Maybe$Nothing)) && _Utils_eq(attributes, _List_Nil));
			var _v4 = model.ni.iA;
			switch (_v4.$) {
				case 0:
					return noAttributesInCloseTag ? $elm$core$Maybe$Just(
						updateModel(attributes)) : $elm$core$Maybe$Nothing;
				case 1:
					var allowedHtmlElements = _v4.a.gh;
					var allowedHtmlAttributes = _v4.a.gg;
					return (A2($elm$core$List$member, tag, allowedHtmlElements) && noAttributesInCloseTag) ? $elm$core$Maybe$Just(
						updateModel(
							A2(filterAttributes, attributes, allowedHtmlAttributes))) : $elm$core$Maybe$Nothing;
				default:
					return $elm$core$Maybe$Nothing;
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$htmlRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^(\\/)?([a-zA-Z][a-zA-Z0-9\\-]*)(?:\\s+([^<>]*?))?(\\/)?$'));
var $pablohirafuji$elm_markdown$Markdown$InlineParser$htmlToToken = F2(
	function (model, _v0) {
		var match = _v0;
		var _v1 = model.ni.iA;
		if (_v1.$ === 2) {
			return $elm$core$Maybe$Nothing;
		} else {
			return A2(
				$elm$core$Maybe$andThen,
				A2($pablohirafuji$elm_markdown$Markdown$InlineParser$htmlFromRegex, model, match),
				$elm$core$List$head(
					A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$InlineParser$htmlRegex, match.cY)));
		}
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$isCloseToken = F2(
	function (htmlModel, token) {
		var _v0 = token.f;
		if ((_v0.$ === 5) && (!_v0.a)) {
			var htmlModel_ = _v0.b;
			return _Utils_eq(htmlModel.M, htmlModel_.M);
		} else {
			return false;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$isCodeTokenPair = F2(
	function (closeToken, openToken) {
		var _v0 = openToken.f;
		if (!_v0.$) {
			var isEscaped = _v0.a;
			return isEscaped ? _Utils_eq(openToken.z - 1, closeToken.z) : _Utils_eq(openToken.z, closeToken.z);
		} else {
			return false;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$isLinkTypeOrImageOpenToken = function (token) {
	var _v0 = token.f;
	switch (_v0.$) {
		case 1:
			return true;
		case 2:
			return true;
		default:
			return false;
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$isOpenEmphasisToken = F2(
	function (closeToken, openToken) {
		var _v0 = openToken.f;
		if (_v0.$ === 6) {
			var openChar = _v0.a;
			var _v1 = _v0.b;
			var openLR = _v1.a;
			var openRR = _v1.b;
			var _v2 = closeToken.f;
			if (_v2.$ === 6) {
				var closeChar = _v2.a;
				var _v3 = _v2.b;
				var closeLR = _v3.a;
				var closeRR = _v3.b;
				return _Utils_eq(openChar, closeChar) ? ((_Utils_eq(openLR, openRR) || _Utils_eq(closeLR, closeRR)) ? (!(!A2($elm$core$Basics$modBy, 3, closeToken.z + openToken.z))) : true) : false;
			} else {
				return false;
			}
		} else {
			return false;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$voidHtmlTags = _List_fromArray(
	['area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 'keygen', 'link', 'meta', 'param', 'source', 'track', 'wbr']);
var $pablohirafuji$elm_markdown$Markdown$InlineParser$isVoidTag = function (htmlModel) {
	return A2($elm$core$List$member, htmlModel.M, $pablohirafuji$elm_markdown$Markdown$InlineParser$voidHtmlTags);
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$HardLineBreakType = {$: 1};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$SoftLineBreakToken = {$: 7};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$reverseTokens = function (model) {
	return _Utils_update(
		model,
		{
			i: $elm$core$List$reverse(model.i)
		});
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$tokenToMatch = F2(
	function (token, type_) {
		return {gZ: token.l1 + token.z, d: _List_Nil, fO: token.l1, cY: '', co: 0, aJ: 0, pu: type_};
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$lineBreakTTM = function (_v0) {
	lineBreakTTM:
	while (true) {
		var tokens = _v0.a;
		var model = _v0.b;
		if (!tokens.b) {
			return $pablohirafuji$elm_markdown$Markdown$InlineParser$reverseTokens(model);
		} else {
			var token = tokens.a;
			var tokensTail = tokens.b;
			if (_Utils_eq(token.f, $pablohirafuji$elm_markdown$Markdown$InlineParser$HardLineBreakToken) || (_Utils_eq(token.f, $pablohirafuji$elm_markdown$Markdown$InlineParser$SoftLineBreakToken) && model.ni.iW)) {
				return $pablohirafuji$elm_markdown$Markdown$InlineParser$lineBreakTTM(
					function (b) {
						return _Utils_Tuple2(tokensTail, b);
					}(
						_Utils_update(
							model,
							{
								d: A2(
									$elm$core$List$cons,
									A2($pablohirafuji$elm_markdown$Markdown$InlineParser$tokenToMatch, token, $pablohirafuji$elm_markdown$Markdown$InlineParser$HardLineBreakType),
									model.d)
							})));
			} else {
				var $temp$_v0 = _Utils_Tuple2(
					tokensTail,
					A2($pablohirafuji$elm_markdown$Markdown$InlineParser$addToken, model, token));
				_v0 = $temp$_v0;
				continue lineBreakTTM;
			}
		}
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$removeParsedAheadTokens = F2(
	function (tokensTail, parser) {
		var _v0 = parser.d;
		if (!_v0.b) {
			return _Utils_Tuple2(tokensTail, parser);
		} else {
			var match = _v0.a;
			return _Utils_Tuple2(
				A2(
					$elm$core$List$filter,
					function (token) {
						return _Utils_cmp(token.l1, match.gZ) > -1;
					},
					tokensTail),
				parser);
		}
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$angleBracketsToMatch = F4(
	function (closeToken, isEscaped, model, _v24) {
		var openToken = _v24.a;
		var remainTokens = _v24.c;
		return function (result) {
			if (result.$ === 1) {
				var tempMatch = result.a;
				return (!isEscaped) ? A2(
					$pablohirafuji$elm_markdown$Markdown$InlineParser$htmlToToken,
					_Utils_update(
						model,
						{i: remainTokens}),
					tempMatch) : $elm$core$Result$toMaybe(result);
			} else {
				return $elm$core$Result$toMaybe(result);
			}
		}(
			A2(
				$elm$core$Result$map,
				function (newMatch) {
					return _Utils_update(
						model,
						{
							d: A2($elm$core$List$cons, newMatch, model.d),
							i: remainTokens
						});
				},
				A2(
					$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
					$pablohirafuji$elm_markdown$Markdown$InlineParser$emailAutolinkTypeToMatch,
					$pablohirafuji$elm_markdown$Markdown$InlineParser$autolinkToMatch(
						A6(
							$pablohirafuji$elm_markdown$Markdown$InlineParser$tokenPairToMatch,
							model,
							function (s) {
								return s;
							},
							$pablohirafuji$elm_markdown$Markdown$InlineParser$CodeType,
							openToken,
							closeToken,
							_List_Nil)))));
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$codeAutolinkTypeHtmlTagTTM = function (_v21) {
	codeAutolinkTypeHtmlTagTTM:
	while (true) {
		var tokens = _v21.a;
		var model = _v21.b;
		if (!tokens.b) {
			return $pablohirafuji$elm_markdown$Markdown$InlineParser$reverseTokens(model);
		} else {
			var token = tokens.a;
			var tokensTail = tokens.b;
			var _v23 = token.f;
			switch (_v23.$) {
				case 0:
					var isEscaped = _v23.a;
					return $pablohirafuji$elm_markdown$Markdown$InlineParser$codeAutolinkTypeHtmlTagTTM(
						function (b) {
							return _Utils_Tuple2(tokensTail, b);
						}(
							A2(
								$elm$core$Maybe$withDefault,
								A2($pablohirafuji$elm_markdown$Markdown$InlineParser$addToken, model, token),
								A2(
									$elm$core$Maybe$map,
									A2($pablohirafuji$elm_markdown$Markdown$InlineParser$codeToMatch, token, model),
									A2(
										$pablohirafuji$elm_markdown$Markdown$InlineParser$findToken,
										$pablohirafuji$elm_markdown$Markdown$InlineParser$isCodeTokenPair(token),
										model.i)))));
				case 4:
					var isEscaped = _v23.a;
					return $pablohirafuji$elm_markdown$Markdown$InlineParser$codeAutolinkTypeHtmlTagTTM(
						function (b) {
							return _Utils_Tuple2(tokensTail, b);
						}(
							A2(
								$pablohirafuji$elm_markdown$Markdown$InlineParser$filterTokens,
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.f;
									},
									$elm$core$Basics$neq(
										$pablohirafuji$elm_markdown$Markdown$InlineParser$CharToken('<'))),
								A2(
									$elm$core$Maybe$withDefault,
									model,
									A2(
										$elm$core$Maybe$andThen,
										A3($pablohirafuji$elm_markdown$Markdown$InlineParser$angleBracketsToMatch, token, isEscaped, model),
										A2(
											$pablohirafuji$elm_markdown$Markdown$InlineParser$findToken,
											A2(
												$elm$core$Basics$composeR,
												function ($) {
													return $.f;
												},
												$elm$core$Basics$eq(
													$pablohirafuji$elm_markdown$Markdown$InlineParser$CharToken('<'))),
											model.i))))));
				default:
					var $temp$_v21 = _Utils_Tuple2(
						tokensTail,
						A2($pablohirafuji$elm_markdown$Markdown$InlineParser$addToken, model, token));
					_v21 = $temp$_v21;
					continue codeAutolinkTypeHtmlTagTTM;
			}
		}
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$codeToMatch = F3(
	function (closeToken, model, _v20) {
		var openToken = _v20.a;
		var remainTokens = _v20.c;
		var updtOpenToken = _Utils_eq(
			openToken.f,
			$pablohirafuji$elm_markdown$Markdown$InlineParser$CodeToken(true)) ? _Utils_update(
			openToken,
			{l1: openToken.l1 + 1, z: openToken.z - 1}) : openToken;
		return _Utils_update(
			model,
			{
				d: A2(
					$elm$core$List$cons,
					A6($pablohirafuji$elm_markdown$Markdown$InlineParser$tokenPairToMatch, model, $pablohirafuji$elm_markdown$Markdown$Helpers$cleanWhitespaces, $pablohirafuji$elm_markdown$Markdown$InlineParser$CodeType, updtOpenToken, closeToken, _List_Nil),
					model.d),
				i: remainTokens
			});
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$emphasisTTM = function (_v16) {
	emphasisTTM:
	while (true) {
		var tokens = _v16.a;
		var model = _v16.b;
		if (!tokens.b) {
			return $pablohirafuji$elm_markdown$Markdown$InlineParser$reverseTokens(model);
		} else {
			var token = tokens.a;
			var tokensTail = tokens.b;
			var _v18 = token.f;
			if (_v18.$ === 6) {
				var _char = _v18.a;
				var _v19 = _v18.b;
				var leftRank = _v19.a;
				var rightRank = _v19.b;
				if (_Utils_eq(leftRank, rightRank)) {
					if ((!(!rightRank)) && ((_char !== '_') || (rightRank === 1))) {
						return $pablohirafuji$elm_markdown$Markdown$InlineParser$emphasisTTM(
							A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(
									tokensTail,
									A2($pablohirafuji$elm_markdown$Markdown$InlineParser$addToken, model, token)),
								A2(
									$elm$core$Maybe$map,
									A3($pablohirafuji$elm_markdown$Markdown$InlineParser$emphasisToMatch, token, tokensTail, model),
									A2(
										$pablohirafuji$elm_markdown$Markdown$InlineParser$findToken,
										$pablohirafuji$elm_markdown$Markdown$InlineParser$isOpenEmphasisToken(token),
										model.i))));
					} else {
						var $temp$_v16 = _Utils_Tuple2(tokensTail, model);
						_v16 = $temp$_v16;
						continue emphasisTTM;
					}
				} else {
					if (_Utils_cmp(leftRank, rightRank) < 0) {
						var $temp$_v16 = _Utils_Tuple2(
							tokensTail,
							A2($pablohirafuji$elm_markdown$Markdown$InlineParser$addToken, model, token));
						_v16 = $temp$_v16;
						continue emphasisTTM;
					} else {
						return $pablohirafuji$elm_markdown$Markdown$InlineParser$emphasisTTM(
							A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(tokensTail, model),
								A2(
									$elm$core$Maybe$map,
									A3($pablohirafuji$elm_markdown$Markdown$InlineParser$emphasisToMatch, token, tokensTail, model),
									A2(
										$pablohirafuji$elm_markdown$Markdown$InlineParser$findToken,
										$pablohirafuji$elm_markdown$Markdown$InlineParser$isOpenEmphasisToken(token),
										model.i))));
					}
				}
			} else {
				var $temp$_v16 = _Utils_Tuple2(
					tokensTail,
					A2($pablohirafuji$elm_markdown$Markdown$InlineParser$addToken, model, token));
				_v16 = $temp$_v16;
				continue emphasisTTM;
			}
		}
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$emphasisToMatch = F4(
	function (closeToken, tokensTail, model, _v15) {
		var openToken = _v15.a;
		var innerTokens = _v15.b;
		var remainTokens = _v15.c;
		var remainLength = openToken.z - closeToken.z;
		var updt = (!remainLength) ? {dh: closeToken, cT: openToken, dT: remainTokens, d6: tokensTail} : ((remainLength > 0) ? {
			dh: closeToken,
			cT: _Utils_update(
				openToken,
				{l1: openToken.l1 + remainLength, z: closeToken.z}),
			dT: A2(
				$elm$core$List$cons,
				_Utils_update(
					openToken,
					{z: remainLength}),
				remainTokens),
			d6: tokensTail
		} : {
			dh: _Utils_update(
				closeToken,
				{z: openToken.z}),
			cT: openToken,
			dT: remainTokens,
			d6: A2(
				$elm$core$List$cons,
				_Utils_update(
					closeToken,
					{l1: closeToken.l1 + openToken.z, z: -remainLength}),
				tokensTail)
		});
		var match = A6(
			$pablohirafuji$elm_markdown$Markdown$InlineParser$tokenPairToMatch,
			model,
			function (s) {
				return s;
			},
			$pablohirafuji$elm_markdown$Markdown$InlineParser$EmphasisType(updt.cT.z),
			updt.cT,
			updt.dh,
			$elm$core$List$reverse(innerTokens));
		return _Utils_Tuple2(
			updt.d6,
			_Utils_update(
				model,
				{
					d: A2($elm$core$List$cons, match, model.d),
					i: updt.dT
				}));
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$htmlElementTTM = function (_v12) {
	htmlElementTTM:
	while (true) {
		var tokens = _v12.a;
		var model = _v12.b;
		if (!tokens.b) {
			return $pablohirafuji$elm_markdown$Markdown$InlineParser$reverseTokens(model);
		} else {
			var token = tokens.a;
			var tokensTail = tokens.b;
			var _v14 = token.f;
			if (_v14.$ === 5) {
				var isOpen = _v14.a;
				var htmlModel = _v14.b;
				return ($pablohirafuji$elm_markdown$Markdown$InlineParser$isVoidTag(htmlModel) || (!isOpen)) ? $pablohirafuji$elm_markdown$Markdown$InlineParser$htmlElementTTM(
					function (b) {
						return _Utils_Tuple2(tokensTail, b);
					}(
						A2(
							$pablohirafuji$elm_markdown$Markdown$InlineParser$addMatch,
							model,
							A2(
								$pablohirafuji$elm_markdown$Markdown$InlineParser$tokenToMatch,
								token,
								$pablohirafuji$elm_markdown$Markdown$InlineParser$HtmlType(htmlModel))))) : $pablohirafuji$elm_markdown$Markdown$InlineParser$htmlElementTTM(
					A2(
						$elm$core$Maybe$withDefault,
						function (b) {
							return _Utils_Tuple2(tokensTail, b);
						}(
							A2(
								$pablohirafuji$elm_markdown$Markdown$InlineParser$addMatch,
								model,
								A2(
									$pablohirafuji$elm_markdown$Markdown$InlineParser$tokenToMatch,
									token,
									$pablohirafuji$elm_markdown$Markdown$InlineParser$HtmlType(htmlModel)))),
						A2(
							$elm$core$Maybe$map,
							A3($pablohirafuji$elm_markdown$Markdown$InlineParser$htmlElementToMatch, token, model, htmlModel),
							A2(
								$pablohirafuji$elm_markdown$Markdown$InlineParser$findToken,
								$pablohirafuji$elm_markdown$Markdown$InlineParser$isCloseToken(htmlModel),
								tokensTail))));
			} else {
				var $temp$_v12 = _Utils_Tuple2(
					tokensTail,
					A2($pablohirafuji$elm_markdown$Markdown$InlineParser$addToken, model, token));
				_v12 = $temp$_v12;
				continue htmlElementTTM;
			}
		}
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$htmlElementToMatch = F4(
	function (openToken, model, htmlModel, _v11) {
		var closeToken = _v11.a;
		var innerTokens = _v11.b;
		var remainTokens = _v11.c;
		return _Utils_Tuple2(
			remainTokens,
			_Utils_update(
				model,
				{
					d: A2(
						$elm$core$List$cons,
						A6(
							$pablohirafuji$elm_markdown$Markdown$InlineParser$tokenPairToMatch,
							model,
							function (s) {
								return s;
							},
							$pablohirafuji$elm_markdown$Markdown$InlineParser$HtmlType(htmlModel),
							openToken,
							closeToken,
							innerTokens),
						model.d)
				}));
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$linkImageTypeTTM = function (_v8) {
	linkImageTypeTTM:
	while (true) {
		var tokens = _v8.a;
		var model = _v8.b;
		if (!tokens.b) {
			return $pablohirafuji$elm_markdown$Markdown$InlineParser$reverseTokens(model);
		} else {
			var token = tokens.a;
			var tokensTail = tokens.b;
			var _v10 = token.f;
			if ((_v10.$ === 3) && (']' === _v10.a)) {
				return $pablohirafuji$elm_markdown$Markdown$InlineParser$linkImageTypeTTM(
					A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(tokensTail, model),
						A2(
							$elm$core$Maybe$andThen,
							A3($pablohirafuji$elm_markdown$Markdown$InlineParser$linkOrImageTypeToMatch, token, tokensTail, model),
							A2($pablohirafuji$elm_markdown$Markdown$InlineParser$findToken, $pablohirafuji$elm_markdown$Markdown$InlineParser$isLinkTypeOrImageOpenToken, model.i))));
			} else {
				var $temp$_v8 = _Utils_Tuple2(
					tokensTail,
					A2($pablohirafuji$elm_markdown$Markdown$InlineParser$addToken, model, token));
				_v8 = $temp$_v8;
				continue linkImageTypeTTM;
			}
		}
	}
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$linkOrImageTypeToMatch = F4(
	function (closeToken, tokensTail, model, _v1) {
		var openToken = _v1.a;
		var innerTokens = _v1.b;
		var remainTokens = _v1.c;
		var tempMatch = function (isLinkType) {
			return A6(
				$pablohirafuji$elm_markdown$Markdown$InlineParser$tokenPairToMatch,
				model,
				function (s) {
					return s;
				},
				isLinkType ? $pablohirafuji$elm_markdown$Markdown$InlineParser$LinkType(
					_Utils_Tuple2('', $elm$core$Maybe$Nothing)) : $pablohirafuji$elm_markdown$Markdown$InlineParser$ImageType(
					_Utils_Tuple2('', $elm$core$Maybe$Nothing)),
				openToken,
				closeToken,
				$elm$core$List$reverse(innerTokens));
		};
		var removeOpenToken = _Utils_Tuple2(
			tokensTail,
			_Utils_update(
				model,
				{
					i: _Utils_ap(innerTokens, remainTokens)
				}));
		var remainText = A2($elm$core$String$dropLeft, closeToken.l1 + 1, model.Z);
		var linkOpenTokenToInactive = function (model_) {
			var process = function (token) {
				var _v7 = token.f;
				if (_v7.$ === 1) {
					return _Utils_update(
						token,
						{
							f: $pablohirafuji$elm_markdown$Markdown$InlineParser$LinkOpenToken(false)
						});
				} else {
					return token;
				}
			};
			return _Utils_update(
				model_,
				{
					i: A2($elm$core$List$map, process, model_.i)
				});
		};
		var args = function (isLinkType) {
			return _Utils_Tuple3(
				remainText,
				tempMatch(isLinkType),
				_Utils_update(
					model,
					{i: remainTokens}));
		};
		var _v2 = openToken.f;
		switch (_v2.$) {
			case 2:
				return $elm$core$Result$toMaybe(
					A2(
						$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
						function (_v4) {
							return $elm$core$Result$Ok(removeOpenToken);
						},
						A2(
							$elm$core$Result$map,
							$pablohirafuji$elm_markdown$Markdown$InlineParser$removeParsedAheadTokens(tokensTail),
							A2(
								$elm$core$Result$andThen,
								$pablohirafuji$elm_markdown$Markdown$InlineParser$checkParsedAheadOverlapping,
								A2(
									$elm$core$Result$mapError,
									function (_v3) {
										return 0;
									},
									A2(
										$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
										$pablohirafuji$elm_markdown$Markdown$InlineParser$checkForRefLinkTypeOrImageType,
										$pablohirafuji$elm_markdown$Markdown$InlineParser$checkForInlineLinkTypeOrImageType(
											args(false))))))));
			case 1:
				if (_v2.a) {
					return $elm$core$Result$toMaybe(
						A2(
							$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
							function (_v6) {
								return $elm$core$Result$Ok(removeOpenToken);
							},
							A2(
								$elm$core$Result$map,
								$pablohirafuji$elm_markdown$Markdown$InlineParser$removeParsedAheadTokens(tokensTail),
								A2(
									$elm$core$Result$map,
									linkOpenTokenToInactive,
									A2(
										$elm$core$Result$andThen,
										$pablohirafuji$elm_markdown$Markdown$InlineParser$checkParsedAheadOverlapping,
										A2(
											$elm$core$Result$mapError,
											function (_v5) {
												return 0;
											},
											A2(
												$pablohirafuji$elm_markdown$Markdown$Helpers$ifError,
												$pablohirafuji$elm_markdown$Markdown$InlineParser$checkForRefLinkTypeOrImageType,
												$pablohirafuji$elm_markdown$Markdown$InlineParser$checkForInlineLinkTypeOrImageType(
													args(true)))))))));
				} else {
					return $elm$core$Maybe$Just(removeOpenToken);
				}
			default:
				return $elm$core$Maybe$Nothing;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$InlineParser$tokenPairToMatch = F6(
	function (model, processText, type_, openToken, closeToken, innerTokens) {
		var textStart = openToken.l1 + openToken.z;
		var textEnd = closeToken.l1;
		var start = openToken.l1;
		var end = closeToken.l1 + closeToken.z;
		var match = {
			gZ: end,
			d: _List_Nil,
			fO: start,
			cY: processText(
				A3($elm$core$String$slice, textStart, textEnd, model.Z)),
			co: textEnd,
			aJ: textStart,
			pu: type_
		};
		var matches = A2(
			$elm$core$List$map,
			function (_v0) {
				var matchModel = _v0;
				return A2($pablohirafuji$elm_markdown$Markdown$InlineParser$prepareChildMatch, match, matchModel);
			},
			$pablohirafuji$elm_markdown$Markdown$InlineParser$cyclic$tokensToMatches()(
				_Utils_update(
					model,
					{d: _List_Nil, i: innerTokens})).d);
		return _Utils_update(
			match,
			{d: matches});
	});
function $pablohirafuji$elm_markdown$Markdown$InlineParser$cyclic$tokensToMatches() {
	return A2(
		$elm$core$Basics$composeR,
		$pablohirafuji$elm_markdown$Markdown$InlineParser$applyTTM($pablohirafuji$elm_markdown$Markdown$InlineParser$codeAutolinkTypeHtmlTagTTM),
		A2(
			$elm$core$Basics$composeR,
			$pablohirafuji$elm_markdown$Markdown$InlineParser$applyTTM($pablohirafuji$elm_markdown$Markdown$InlineParser$htmlElementTTM),
			A2(
				$elm$core$Basics$composeR,
				$pablohirafuji$elm_markdown$Markdown$InlineParser$applyTTM($pablohirafuji$elm_markdown$Markdown$InlineParser$linkImageTypeTTM),
				A2(
					$elm$core$Basics$composeR,
					$pablohirafuji$elm_markdown$Markdown$InlineParser$applyTTM($pablohirafuji$elm_markdown$Markdown$InlineParser$emphasisTTM),
					$pablohirafuji$elm_markdown$Markdown$InlineParser$applyTTM($pablohirafuji$elm_markdown$Markdown$InlineParser$lineBreakTTM)))));
}
var $pablohirafuji$elm_markdown$Markdown$InlineParser$tokensToMatches = $pablohirafuji$elm_markdown$Markdown$InlineParser$cyclic$tokensToMatches();
$pablohirafuji$elm_markdown$Markdown$InlineParser$cyclic$tokensToMatches = function () {
	return $pablohirafuji$elm_markdown$Markdown$InlineParser$tokensToMatches;
};
var $pablohirafuji$elm_markdown$Markdown$InlineParser$parse = F3(
	function (options, refs, rawText) {
		return $pablohirafuji$elm_markdown$Markdown$InlineParser$matchesToInlines(
			$pablohirafuji$elm_markdown$Markdown$InlineParser$parseText(
				$pablohirafuji$elm_markdown$Markdown$InlineParser$organizeParserMatches(
					$pablohirafuji$elm_markdown$Markdown$InlineParser$tokensToMatches(
						$pablohirafuji$elm_markdown$Markdown$InlineParser$tokenize(
							A3(
								$pablohirafuji$elm_markdown$Markdown$InlineParser$initParser,
								options,
								refs,
								$elm$core$String$trim(rawText)))))).d);
	});
var $pablohirafuji$elm_markdown$Markdown$Block$parseInline = F4(
	function (maybeOptions, textAsParagraph, refs, block) {
		var options = A2($elm$core$Maybe$withDefault, $pablohirafuji$elm_markdown$Markdown$Config$defaultOptions, maybeOptions);
		switch (block.$) {
			case 2:
				var rawText = block.a;
				var lvl = block.b;
				return A3(
					$pablohirafuji$elm_markdown$Markdown$Block$Heading,
					rawText,
					lvl,
					A3($pablohirafuji$elm_markdown$Markdown$InlineParser$parse, options, refs, rawText));
			case 4:
				var rawText = block.a;
				var inlines = A3($pablohirafuji$elm_markdown$Markdown$InlineParser$parse, options, refs, rawText);
				if ((inlines.b && (inlines.a.$ === 5)) && (!inlines.b.b)) {
					var _v3 = inlines.a;
					return $pablohirafuji$elm_markdown$Markdown$Block$PlainInlines(inlines);
				} else {
					return textAsParagraph ? A2($pablohirafuji$elm_markdown$Markdown$Block$Paragraph, rawText, inlines) : $pablohirafuji$elm_markdown$Markdown$Block$PlainInlines(inlines);
				}
			case 5:
				var blocks = block.a;
				return $pablohirafuji$elm_markdown$Markdown$Block$BlockQuote(
					A3(
						$pablohirafuji$elm_markdown$Markdown$Block$parseInlines,
						maybeOptions,
						true,
						_Utils_Tuple2(refs, blocks)));
			case 6:
				var model = block.a;
				var items = block.b;
				return A2(
					$pablohirafuji$elm_markdown$Markdown$Block$List,
					model,
					function (a) {
						return A2($elm$core$List$map, a, items);
					}(
						A2(
							$elm$core$Basics$composeL,
							A2($pablohirafuji$elm_markdown$Markdown$Block$parseInlines, maybeOptions, model.a5),
							function (b) {
								return _Utils_Tuple2(refs, b);
							})));
			case 8:
				var customBlock = block.a;
				var blocks = block.b;
				return A2(
					$pablohirafuji$elm_markdown$Markdown$Block$Custom,
					customBlock,
					A3(
						$pablohirafuji$elm_markdown$Markdown$Block$parseInlines,
						maybeOptions,
						true,
						_Utils_Tuple2(refs, blocks)));
			default:
				return block;
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$parseInlines = F3(
	function (maybeOptions, textAsParagraph, _v0) {
		var refs = _v0.a;
		var blocks = _v0.b;
		return A2(
			$elm$core$List$map,
			A3($pablohirafuji$elm_markdown$Markdown$Block$parseInline, maybeOptions, textAsParagraph, refs),
			blocks);
	});
var $pablohirafuji$elm_markdown$Markdown$Block$dropRefString = F2(
	function (rawText, inlineMatch) {
		var strippedText = A2($elm$core$String$dropLeft, inlineMatch.fa, rawText);
		return A2($elm$regex$Regex$contains, $pablohirafuji$elm_markdown$Markdown$Block$blankLineRegex, strippedText) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(strippedText);
	});
var $pablohirafuji$elm_markdown$Markdown$Block$insertLinkMatch = F2(
	function (refs, linkMatch) {
		return A2($elm$core$Dict$member, linkMatch.bw, refs) ? refs : A3(
			$elm$core$Dict$insert,
			linkMatch.bw,
			_Utils_Tuple2(linkMatch.py, linkMatch.fb),
			refs);
	});
var $pablohirafuji$elm_markdown$Markdown$Block$extractUrlTitleRegex = function (regexMatch) {
	var _v0 = regexMatch.i2;
	if ((((((_v0.b && (!_v0.a.$)) && _v0.b.b) && _v0.b.b.b) && _v0.b.b.b.b) && _v0.b.b.b.b.b) && _v0.b.b.b.b.b.b) {
		var rawText = _v0.a.a;
		var _v1 = _v0.b;
		var maybeRawUrlAngleBrackets = _v1.a;
		var _v2 = _v1.b;
		var maybeRawUrlWithoutBrackets = _v2.a;
		var _v3 = _v2.b;
		var maybeTitleSingleQuotes = _v3.a;
		var _v4 = _v3.b;
		var maybeTitleDoubleQuotes = _v4.a;
		var _v5 = _v4.b;
		var maybeTitleParenthesis = _v5.a;
		var toReturn = function (rawUrl) {
			return {
				bw: rawText,
				fa: $elm$core$String$length(regexMatch.mA),
				fb: $pablohirafuji$elm_markdown$Markdown$Helpers$returnFirstJust(
					_List_fromArray(
						[maybeTitleSingleQuotes, maybeTitleDoubleQuotes, maybeTitleParenthesis])),
				py: rawUrl
			};
		};
		var maybeRawUrl = $pablohirafuji$elm_markdown$Markdown$Helpers$returnFirstJust(
			_List_fromArray(
				[maybeRawUrlAngleBrackets, maybeRawUrlWithoutBrackets]));
		return A2($elm$core$Maybe$map, toReturn, maybeRawUrl);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $pablohirafuji$elm_markdown$Markdown$Block$hrefRegex = '\\s*(?:<([^<>\\s]*)>|([^\\s]*))';
var $pablohirafuji$elm_markdown$Markdown$Block$refRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^\\s*\\[(' + ($pablohirafuji$elm_markdown$Markdown$Helpers$insideSquareBracketRegex + (')\\]:' + ($pablohirafuji$elm_markdown$Markdown$Block$hrefRegex + ($pablohirafuji$elm_markdown$Markdown$Helpers$titleRegex + '\\s*(?![^\\n])'))))));
var $pablohirafuji$elm_markdown$Markdown$Block$maybeLinkMatch = function (rawText) {
	return A2(
		$elm$core$Maybe$andThen,
		function (linkMatch) {
			return ((linkMatch.py === '') || (linkMatch.bw === '')) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(linkMatch);
		},
		A2(
			$elm$core$Maybe$map,
			function (linkMatch) {
				return _Utils_update(
					linkMatch,
					{
						bw: $pablohirafuji$elm_markdown$Markdown$Helpers$prepareRefLabel(linkMatch.bw)
					});
			},
			A2(
				$elm$core$Maybe$andThen,
				$pablohirafuji$elm_markdown$Markdown$Block$extractUrlTitleRegex,
				$elm$core$List$head(
					A3($elm$regex$Regex$findAtMost, 1, $pablohirafuji$elm_markdown$Markdown$Block$refRegex, rawText)))));
};
var $pablohirafuji$elm_markdown$Markdown$Block$parseReference = F2(
	function (refs, rawText) {
		parseReference:
		while (true) {
			var _v0 = $pablohirafuji$elm_markdown$Markdown$Block$maybeLinkMatch(rawText);
			if (!_v0.$) {
				var linkMatch = _v0.a;
				var updtRefs = A2($pablohirafuji$elm_markdown$Markdown$Block$insertLinkMatch, refs, linkMatch);
				var maybeStrippedText = A2($pablohirafuji$elm_markdown$Markdown$Block$dropRefString, rawText, linkMatch);
				if (!maybeStrippedText.$) {
					var strippedText = maybeStrippedText.a;
					var $temp$refs = updtRefs,
						$temp$rawText = strippedText;
					refs = $temp$refs;
					rawText = $temp$rawText;
					continue parseReference;
				} else {
					return _Utils_Tuple2(updtRefs, $elm$core$Maybe$Nothing);
				}
			} else {
				return _Utils_Tuple2(
					refs,
					$elm$core$Maybe$Just(rawText));
			}
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$parseReferences = function (refs) {
	return A2(
		$elm$core$List$foldl,
		$pablohirafuji$elm_markdown$Markdown$Block$parseReferencesHelp,
		_Utils_Tuple2(refs, _List_Nil));
};
var $pablohirafuji$elm_markdown$Markdown$Block$parseReferencesHelp = F2(
	function (block, _v0) {
		var refs = _v0.a;
		var parsedAST = _v0.b;
		switch (block.$) {
			case 4:
				var rawText = block.a;
				var _v2 = A2($pablohirafuji$elm_markdown$Markdown$Block$parseReference, $elm$core$Dict$empty, rawText);
				var paragraphRefs = _v2.a;
				var maybeUpdtText = _v2.b;
				var updtRefs = A2($elm$core$Dict$union, paragraphRefs, refs);
				if (!maybeUpdtText.$) {
					var updtText = maybeUpdtText.a;
					return _Utils_Tuple2(
						updtRefs,
						A2(
							$elm$core$List$cons,
							A2($pablohirafuji$elm_markdown$Markdown$Block$Paragraph, updtText, _List_Nil),
							parsedAST));
				} else {
					return _Utils_Tuple2(updtRefs, parsedAST);
				}
			case 6:
				var model = block.a;
				var items = block.b;
				var _v4 = A3(
					$elm$core$List$foldl,
					F2(
						function (item, _v5) {
							var refs__ = _v5.a;
							var parsedItems = _v5.b;
							return A2(
								$elm$core$Tuple$mapSecond,
								function (a) {
									return A2($elm$core$List$cons, a, parsedItems);
								},
								A2($pablohirafuji$elm_markdown$Markdown$Block$parseReferences, refs__, item));
						}),
					_Utils_Tuple2(refs, _List_Nil),
					items);
				var updtRefs = _v4.a;
				var updtItems = _v4.b;
				return _Utils_Tuple2(
					updtRefs,
					A2(
						$elm$core$List$cons,
						A2($pablohirafuji$elm_markdown$Markdown$Block$List, model, updtItems),
						parsedAST));
			case 5:
				var blocks = block.a;
				return A2(
					$elm$core$Tuple$mapSecond,
					function (a) {
						return A2($elm$core$List$cons, a, parsedAST);
					},
					A2(
						$elm$core$Tuple$mapSecond,
						$pablohirafuji$elm_markdown$Markdown$Block$BlockQuote,
						A2($pablohirafuji$elm_markdown$Markdown$Block$parseReferences, refs, blocks)));
			case 8:
				var customBlock = block.a;
				var blocks = block.b;
				return A2(
					$elm$core$Tuple$mapSecond,
					function (a) {
						return A2($elm$core$List$cons, a, parsedAST);
					},
					A2(
						$elm$core$Tuple$mapSecond,
						$pablohirafuji$elm_markdown$Markdown$Block$Custom(customBlock),
						A2($pablohirafuji$elm_markdown$Markdown$Block$parseReferences, refs, blocks)));
			default:
				return _Utils_Tuple2(
					refs,
					A2($elm$core$List$cons, block, parsedAST));
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$parse = function (maybeOptions) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$String$lines,
		A2(
			$elm$core$Basics$composeR,
			function (a) {
				return A2($pablohirafuji$elm_markdown$Markdown$Block$incorporateLines, a, _List_Nil);
			},
			A2(
				$elm$core$Basics$composeR,
				$pablohirafuji$elm_markdown$Markdown$Block$parseReferences($elm$core$Dict$empty),
				A2($pablohirafuji$elm_markdown$Markdown$Block$parseInlines, maybeOptions, true))));
};
var $elm$html$Html$blockquote = _VirtualDom_node('blockquote');
var $elm$html$Html$code = _VirtualDom_node('code');
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $elm$html$Html$h5 = _VirtualDom_node('h5');
var $elm$html$Html$h6 = _VirtualDom_node('h6');
var $elm$html$Html$hr = _VirtualDom_node('hr');
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$html$Html$ol = _VirtualDom_node('ol');
var $elm$html$Html$pre = _VirtualDom_node('pre');
var $elm$html$Html$Attributes$start = function (n) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'start',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $pablohirafuji$elm_markdown$Markdown$Inline$attributeToAttribute = function (_v0) {
	var name = _v0.a;
	var maybeValue = _v0.b;
	return A2(
		$elm$html$Html$Attributes$attribute,
		name,
		A2($elm$core$Maybe$withDefault, name, maybeValue));
};
var $pablohirafuji$elm_markdown$Markdown$Inline$attributesToHtmlAttributes = $elm$core$List$map($pablohirafuji$elm_markdown$Markdown$Inline$attributeToAttribute);
var $elm$html$Html$br = _VirtualDom_node('br');
var $elm$html$Html$em = _VirtualDom_node('em');
var $pablohirafuji$elm_markdown$Markdown$Inline$extractText = function (inlines) {
	return A3($elm$core$List$foldl, $pablohirafuji$elm_markdown$Markdown$Inline$extractTextHelp, '', inlines);
};
var $pablohirafuji$elm_markdown$Markdown$Inline$extractTextHelp = F2(
	function (inline, text) {
		switch (inline.$) {
			case 0:
				var str = inline.a;
				return _Utils_ap(text, str);
			case 1:
				return text + ' ';
			case 2:
				var str = inline.a;
				return _Utils_ap(text, str);
			case 3:
				var inlines = inline.c;
				return _Utils_ap(
					text,
					$pablohirafuji$elm_markdown$Markdown$Inline$extractText(inlines));
			case 4:
				var inlines = inline.c;
				return _Utils_ap(
					text,
					$pablohirafuji$elm_markdown$Markdown$Inline$extractText(inlines));
			case 5:
				var inlines = inline.c;
				return _Utils_ap(
					text,
					$pablohirafuji$elm_markdown$Markdown$Inline$extractText(inlines));
			case 6:
				var inlines = inline.b;
				return _Utils_ap(
					text,
					$pablohirafuji$elm_markdown$Markdown$Inline$extractText(inlines));
			default:
				var inlines = inline.b;
				return _Utils_ap(
					text,
					$pablohirafuji$elm_markdown$Markdown$Inline$extractText(inlines));
		}
	});
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $elm$html$Html$strong = _VirtualDom_node('strong');
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $pablohirafuji$elm_markdown$Markdown$Inline$defaultHtml = F2(
	function (customTransformer, inline) {
		var transformer = A2(
			$elm$core$Maybe$withDefault,
			$pablohirafuji$elm_markdown$Markdown$Inline$defaultHtml($elm$core$Maybe$Nothing),
			customTransformer);
		switch (inline.$) {
			case 0:
				var str = inline.a;
				return $elm$html$Html$text(str);
			case 1:
				return A2($elm$html$Html$br, _List_Nil, _List_Nil);
			case 2:
				var codeStr = inline.a;
				return A2(
					$elm$html$Html$code,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(codeStr)
						]));
			case 3:
				var url = inline.a;
				var maybeTitle = inline.b;
				var inlines = inline.c;
				if (!maybeTitle.$) {
					var title_ = maybeTitle.a;
					return A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href(url),
								$elm$html$Html$Attributes$title(title_)
							]),
						A2($elm$core$List$map, transformer, inlines));
				} else {
					return A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href(url)
							]),
						A2($elm$core$List$map, transformer, inlines));
				}
			case 4:
				var url = inline.a;
				var maybeTitle = inline.b;
				var inlines = inline.c;
				if (!maybeTitle.$) {
					var title_ = maybeTitle.a;
					return A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$alt(
								$pablohirafuji$elm_markdown$Markdown$Inline$extractText(inlines)),
								$elm$html$Html$Attributes$src(url),
								$elm$html$Html$Attributes$title(title_)
							]),
						_List_Nil);
				} else {
					return A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$alt(
								$pablohirafuji$elm_markdown$Markdown$Inline$extractText(inlines)),
								$elm$html$Html$Attributes$src(url)
							]),
						_List_Nil);
				}
			case 5:
				var tag = inline.a;
				var attrs = inline.b;
				var inlines = inline.c;
				return A3(
					$elm$html$Html$node,
					tag,
					$pablohirafuji$elm_markdown$Markdown$Inline$attributesToHtmlAttributes(attrs),
					A2($elm$core$List$map, transformer, inlines));
			case 6:
				var length = inline.a;
				var inlines = inline.b;
				switch (length) {
					case 1:
						return A2(
							$elm$html$Html$em,
							_List_Nil,
							A2($elm$core$List$map, transformer, inlines));
					case 2:
						return A2(
							$elm$html$Html$strong,
							_List_Nil,
							A2($elm$core$List$map, transformer, inlines));
					default:
						return ((length - 2) > 0) ? A2(
							$elm$html$Html$strong,
							_List_Nil,
							function (a) {
								return A2($elm$core$List$cons, a, _List_Nil);
							}(
								transformer(
									A2($pablohirafuji$elm_markdown$Markdown$Inline$Emphasis, length - 2, inlines)))) : A2(
							$elm$html$Html$em,
							_List_Nil,
							A2($elm$core$List$map, transformer, inlines));
				}
			default:
				var inlines = inline.b;
				return $elm$html$Html$text('');
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Inline$toHtml = $pablohirafuji$elm_markdown$Markdown$Inline$defaultHtml($elm$core$Maybe$Nothing);
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $pablohirafuji$elm_markdown$Markdown$Block$defaultHtml = F3(
	function (customHtml, customInlineHtml, block) {
		var inlineToHtml = A2($elm$core$Maybe$withDefault, $pablohirafuji$elm_markdown$Markdown$Inline$toHtml, customInlineHtml);
		var blockToHtml = A2(
			$elm$core$Maybe$withDefault,
			A2($pablohirafuji$elm_markdown$Markdown$Block$defaultHtml, $elm$core$Maybe$Nothing, customInlineHtml),
			customHtml);
		switch (block.$) {
			case 0:
				return _List_Nil;
			case 2:
				var level = block.b;
				var inlines = block.c;
				var hElement = function () {
					switch (level) {
						case 1:
							return $elm$html$Html$h1(_List_Nil);
						case 2:
							return $elm$html$Html$h2(_List_Nil);
						case 3:
							return $elm$html$Html$h3(_List_Nil);
						case 4:
							return $elm$html$Html$h4(_List_Nil);
						case 5:
							return $elm$html$Html$h5(_List_Nil);
						default:
							return $elm$html$Html$h6(_List_Nil);
					}
				}();
				return _List_fromArray(
					[
						hElement(
						A2($elm$core$List$map, inlineToHtml, inlines))
					]);
			case 1:
				return _List_fromArray(
					[
						A2($elm$html$Html$hr, _List_Nil, _List_Nil)
					]);
			case 4:
				var inlines = block.b;
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$p,
						_List_Nil,
						A2($elm$core$List$map, inlineToHtml, inlines))
					]);
			case 3:
				if (block.a.$ === 1) {
					var _v2 = block.a;
					var model = _v2.b;
					var codeStr = block.b;
					var basicView = function (attrs) {
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$pre,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$code,
										attrs,
										_List_fromArray(
											[
												$elm$html$Html$text(codeStr)
											]))
									]))
							]);
					};
					var _v3 = model.e8;
					if (!_v3.$) {
						var language = _v3.a;
						return basicView(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('language-' + language)
								]));
					} else {
						return basicView(_List_Nil);
					}
				} else {
					var _v4 = block.a;
					var codeStr = block.b;
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$pre,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$code,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(codeStr)
										]))
								]))
						]);
				}
			case 5:
				var blocks = block.a;
				return function (a) {
					return A2($elm$core$List$cons, a, _List_Nil);
				}(
					A2(
						$elm$html$Html$blockquote,
						_List_Nil,
						$elm$core$List$concat(
							A2($elm$core$List$map, blockToHtml, blocks))));
			case 6:
				var model = block.a;
				var items = block.b;
				return function (a) {
					return A2($elm$core$List$cons, a, _List_Nil);
				}(
					function () {
						var _v5 = model.pu;
						if (_v5.$ === 1) {
							var startInt = _v5.a;
							return (startInt === 1) ? $elm$html$Html$ol(_List_Nil) : $elm$html$Html$ol(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$start(startInt)
									]));
						} else {
							return $elm$html$Html$ul(_List_Nil);
						}
					}()(
						A2(
							$elm$core$List$map,
							A2(
								$elm$core$Basics$composeR,
								$elm$core$List$map(blockToHtml),
								A2(
									$elm$core$Basics$composeR,
									$elm$core$List$concat,
									$elm$html$Html$li(_List_Nil))),
							items)));
			case 7:
				var inlines = block.a;
				return A2($elm$core$List$map, inlineToHtml, inlines);
			default:
				var customBlock = block.a;
				var blocks = block.b;
				return function (a) {
					return A2($elm$core$List$cons, a, _List_Nil);
				}(
					A2(
						$elm$html$Html$div,
						_List_Nil,
						A2(
							$elm$core$List$cons,
							$elm$html$Html$text('Unhandled custom block.'),
							$elm$core$List$concat(
								A2($elm$core$List$map, blockToHtml, blocks)))));
		}
	});
var $pablohirafuji$elm_markdown$Markdown$Block$toHtml = A2($pablohirafuji$elm_markdown$Markdown$Block$defaultHtml, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
var $pablohirafuji$elm_markdown$Markdown$toHtml = F2(
	function (maybeOptions, rawText) {
		return $elm$core$List$concat(
			A2(
				$elm$core$List$map,
				$pablohirafuji$elm_markdown$Markdown$Block$toHtml,
				A2($pablohirafuji$elm_markdown$Markdown$Block$parse, maybeOptions, rawText)));
	});
var $author$project$UISnippet$Misc$fromMarkdown = function (txt) {
	return $mdgriffith$elm_ui$Element$html(
		A2(
			$elm$html$Html$span,
			_List_Nil,
			A2($pablohirafuji$elm_markdown$Markdown$toHtml, $elm$core$Maybe$Nothing, txt)));
};
var $mdgriffith$elm_ui$Element$modular = F3(
	function (normal, ratio, rescale) {
		return (!rescale) ? normal : ((rescale < 0) ? (normal * A2($elm$core$Basics$pow, ratio, rescale)) : (normal * A2($elm$core$Basics$pow, ratio, rescale - 1)));
	});
var $mdgriffith$elm_ui$Element$rgba = $mdgriffith$elm_ui$Internal$Model$Rgba;
var $author$project$Theme$Style$lightenBy = F2(
	function (amt, color) {
		return function (c) {
			return A4(
				$mdgriffith$elm_ui$Element$rgba,
				c.fx,
				c.eT,
				c.eo,
				A3($mdgriffith$elm_ui$Element$modular, 1, 0.8, amt));
		}(
			$mdgriffith$elm_ui$Element$toRgb(color));
	});
var $author$project$Page$AdaptiveAnalytics$lightBtnColor = A2($author$project$Theme$Style$lightenBy, 4, $author$project$Theme$Style$t.f6);
var $author$project$Page$AdaptiveAnalytics$makeSelectionArea = function (area) {
	return A2(
		$mdgriffith$elm_ui$Element$wrappedRow,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$padding(15),
				$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.gA),
				$mdgriffith$elm_ui$Element$Border$rounded(10),
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
			]),
		_List_fromArray(
			[area]));
};
var $mdgriffith$elm_ui$Internal$Model$Min = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$minimum = F2(
	function (i, l) {
		return A2($mdgriffith$elm_ui$Internal$Model$Min, i, l);
	});
var $mdgriffith$elm_ui$Internal$Model$Paragraph = {$: 9};
var $mdgriffith$elm_ui$Internal$Model$spacingName = F2(
	function (x, y) {
		return 'spacing-' + ($elm$core$String$fromInt(x) + ('-' + $elm$core$String$fromInt(y)));
	});
var $mdgriffith$elm_ui$Element$spacing = function (x) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$spacing,
		A3(
			$mdgriffith$elm_ui$Internal$Model$SpacingStyle,
			A2($mdgriffith$elm_ui$Internal$Model$spacingName, x, x),
			x,
			x));
};
var $mdgriffith$elm_ui$Element$paragraph = F2(
	function (attrs, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asParagraph,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$Describe($mdgriffith$elm_ui$Internal$Model$Paragraph),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$spacing(5),
						attrs))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $author$project$Page$AdaptiveAnalytics$zoneText = function (ctx) {
	if (!ctx) {
		return 'Test Zone';
	} else {
		return 'Practice Zone';
	}
};
var $author$project$Page$AdaptiveAnalytics$activitySection = function (model) {
	var makeItem = function (activityData) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Element$paddingXY, 1, 1),
					$mdgriffith$elm_ui$Element$width(
					A2($mdgriffith$elm_ui$Element$minimum, 330, $mdgriffith$elm_ui$Element$shrink)),
					$mdgriffith$elm_ui$Element$alignTop
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color(
							_Utils_eq(
								model.aE,
								$elm$core$Maybe$Just(activityData)) ? $author$project$Theme$Style$t.jI : $author$project$Page$AdaptiveAnalytics$lightBtnColor),
							$mdgriffith$elm_ui$Element$Border$rounded(5),
							$mdgriffith$elm_ui$Element$padding(15),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					{
						bD: A2(
							$mdgriffith$elm_ui$Element$paragraph,
							_List_Nil,
							_List_fromArray(
								[
									$author$project$UISnippet$Misc$fromMarkdown(activityData.ja)
								])),
						ai: $elm$core$Maybe$Just(
							$author$project$Page$AdaptiveAnalytics$SelectActivity(activityData))
					})
				]));
	};
	var makeActivitySection = F2(
		function (ctx, validActivities) {
			return $elm$core$List$isEmpty(validActivities) ? $mdgriffith$elm_ui$Element$none : A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(10)
					]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text(
						$author$project$Page$AdaptiveAnalytics$zoneText(ctx)),
						A2(
						$mdgriffith$elm_ui$Element$wrappedRow,
						_List_Nil,
						A2(
							$elm$core$List$map,
							makeItem,
							A2(
								$elm$core$List$sortBy,
								function ($) {
									return $.or;
								},
								validActivities)))
					]));
		});
	return $elm$core$List$isEmpty(model.c2) ? $mdgriffith$elm_ui$Element$none : $author$project$Page$AdaptiveAnalytics$makeSelectionArea(
		function (_v0) {
			var assessBlock = _v0.a;
			var practiceBlock = _v0.b;
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(20),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[assessBlock, practiceBlock]));
		}(
			A3(
				$elm$core$Tuple$mapBoth,
				makeActivitySection(0),
				makeActivitySection(1),
				A2(
					$elm$core$List$partition,
					function (el) {
						return !el.cx.kL;
					},
					model.c2))));
};
var $terezka$elm_charts$Internal$Property$Property = function (a) {
	return {$: 0, a: a};
};
var $terezka$elm_charts$Internal$Property$property = F3(
	function (value, inter, attrs) {
		return $terezka$elm_charts$Internal$Property$Property(
			{
				k: attrs,
				ls: F5(
					function (_v0, _v1, _v2, _v3, _v4) {
						return _List_Nil;
					}),
				ag: A2(
					$elm$core$Basics$composeR,
					value,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$map($elm$core$String$fromFloat),
						$elm$core$Maybe$withDefault('N/A'))),
				mb: inter,
				hH: $elm$core$Maybe$Nothing,
				pI: value,
				c5: value
			});
	});
var $terezka$elm_charts$Chart$bar = function (y) {
	return A2(
		$terezka$elm_charts$Internal$Property$property,
		A2($elm$core$Basics$composeR, y, $elm$core$Maybe$Just),
		_List_Nil);
};
var $terezka$elm_charts$Internal$Helpers$apply = F2(
	function (funcs, _default) {
		var apply_ = F2(
			function (f, a) {
				return f(a);
			});
		return A3($elm$core$List$foldl, apply_, _default, funcs);
	});
var $terezka$elm_charts$Internal$Svg$defaultLabel = {v: $elm$core$Maybe$Nothing, k: _List_Nil, ep: 'white', U: 0, b0: '#808BAB', w: $elm$core$Maybe$Nothing, x: $elm$core$Maybe$Nothing, y: false, C: 0, E: false, p: 0, q: 0};
var $terezka$elm_charts$Internal$Coordinates$bottom = function (pos) {
	return {jv: pos.cv + ((pos.c6 - pos.cv) / 2), pS: pos.pT};
};
var $terezka$elm_charts$Internal$Item$getPosition = F2(
	function (plane, _v0) {
		var item = _v0;
		return A2(item.pl, plane, item.kD);
	});
var $terezka$elm_charts$Chart$Item$getBottom = function (p) {
	return A2(
		$elm$core$Basics$composeR,
		$terezka$elm_charts$Internal$Item$getPosition(p),
		$terezka$elm_charts$Internal$Coordinates$bottom);
};
var $terezka$elm_charts$Chart$defaultLabel = {v: $terezka$elm_charts$Internal$Svg$defaultLabel.v, k: $terezka$elm_charts$Internal$Svg$defaultLabel.k, ep: $terezka$elm_charts$Internal$Svg$defaultLabel.ep, U: $terezka$elm_charts$Internal$Svg$defaultLabel.U, b0: $terezka$elm_charts$Internal$Svg$defaultLabel.b0, w: $terezka$elm_charts$Internal$Svg$defaultLabel.w, x: $terezka$elm_charts$Internal$Svg$defaultLabel.x, ag: $elm$core$Maybe$Nothing, y: $terezka$elm_charts$Internal$Svg$defaultLabel.y, bb: $terezka$elm_charts$Chart$Item$getBottom, C: $terezka$elm_charts$Internal$Svg$defaultLabel.C, E: $terezka$elm_charts$Internal$Svg$defaultLabel.E, p: $terezka$elm_charts$Internal$Svg$defaultLabel.p, q: $terezka$elm_charts$Internal$Svg$defaultLabel.q};
var $terezka$elm_charts$Chart$SubElements = function (a) {
	return {$: 10, a: a};
};
var $terezka$elm_charts$Internal$Many$Remodel = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $terezka$elm_charts$Internal$Many$andThen = F2(
	function (_v0, _v1) {
		var toPos2 = _v0.a;
		var func2 = _v0.b;
		var toPos1 = _v1.a;
		var func1 = _v1.b;
		return A2(
			$terezka$elm_charts$Internal$Many$Remodel,
			toPos2,
			function (items) {
				return func2(
					func1(items));
			});
	});
var $terezka$elm_charts$Chart$Item$andThen = $terezka$elm_charts$Internal$Many$andThen;
var $terezka$elm_charts$Internal$Many$apply = F2(
	function (_v0, items) {
		var func = _v0.b;
		return func(items);
	});
var $terezka$elm_charts$Chart$Item$apply = $terezka$elm_charts$Internal$Many$apply;
var $terezka$elm_charts$Internal$Item$Bar = function (a) {
	return {$: 1, a: a};
};
var $terezka$elm_charts$Internal$Item$Rendered = $elm$core$Basics$identity;
var $terezka$elm_charts$Internal$Commands$Arc = F7(
	function (a, b, c, d, e, f, g) {
		return {$: 6, a: a, b: b, c: c, d: d, e: e, f: f, g: g};
	});
var $terezka$elm_charts$Internal$Commands$Line = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $terezka$elm_charts$Internal$Commands$Move = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $terezka$elm_charts$Internal$Commands$joinCommands = function (commands) {
	return A2($elm$core$String$join, ' ', commands);
};
var $terezka$elm_charts$Internal$Commands$stringBoolInt = function (bool) {
	return bool ? '1' : '0';
};
var $terezka$elm_charts$Internal$Commands$stringPoint = function (_v0) {
	var x = _v0.a;
	var y = _v0.b;
	return $elm$core$String$fromFloat(x) + (' ' + $elm$core$String$fromFloat(y));
};
var $terezka$elm_charts$Internal$Commands$stringPoints = function (points) {
	return A2(
		$elm$core$String$join,
		',',
		A2($elm$core$List$map, $terezka$elm_charts$Internal$Commands$stringPoint, points));
};
var $terezka$elm_charts$Internal$Commands$stringCommand = function (command) {
	switch (command.$) {
		case 0:
			var x = command.a;
			var y = command.b;
			return 'M' + $terezka$elm_charts$Internal$Commands$stringPoint(
				_Utils_Tuple2(x, y));
		case 1:
			var x = command.a;
			var y = command.b;
			return 'L' + $terezka$elm_charts$Internal$Commands$stringPoint(
				_Utils_Tuple2(x, y));
		case 2:
			var cx1 = command.a;
			var cy1 = command.b;
			var cx2 = command.c;
			var cy2 = command.d;
			var x = command.e;
			var y = command.f;
			return 'C' + $terezka$elm_charts$Internal$Commands$stringPoints(
				_List_fromArray(
					[
						_Utils_Tuple2(cx1, cy1),
						_Utils_Tuple2(cx2, cy2),
						_Utils_Tuple2(x, y)
					]));
		case 3:
			var cx1 = command.a;
			var cy1 = command.b;
			var x = command.c;
			var y = command.d;
			return 'Q' + $terezka$elm_charts$Internal$Commands$stringPoints(
				_List_fromArray(
					[
						_Utils_Tuple2(cx1, cy1),
						_Utils_Tuple2(x, y)
					]));
		case 4:
			var cx1 = command.a;
			var cy1 = command.b;
			var x = command.c;
			var y = command.d;
			return 'Q' + $terezka$elm_charts$Internal$Commands$stringPoints(
				_List_fromArray(
					[
						_Utils_Tuple2(cx1, cy1),
						_Utils_Tuple2(x, y)
					]));
		case 5:
			var x = command.a;
			var y = command.b;
			return 'T' + $terezka$elm_charts$Internal$Commands$stringPoint(
				_Utils_Tuple2(x, y));
		case 6:
			var rx = command.a;
			var ry = command.b;
			var xAxisRotation = command.c;
			var largeArcFlag = command.d;
			var sweepFlag = command.e;
			var x = command.f;
			var y = command.g;
			return 'A ' + $terezka$elm_charts$Internal$Commands$joinCommands(
				_List_fromArray(
					[
						$terezka$elm_charts$Internal$Commands$stringPoint(
						_Utils_Tuple2(rx, ry)),
						$elm$core$String$fromInt(xAxisRotation),
						$terezka$elm_charts$Internal$Commands$stringBoolInt(largeArcFlag),
						$terezka$elm_charts$Internal$Commands$stringBoolInt(sweepFlag),
						$terezka$elm_charts$Internal$Commands$stringPoint(
						_Utils_Tuple2(x, y))
					]));
		default:
			return 'Z';
	}
};
var $terezka$elm_charts$Internal$Commands$Close = {$: 7};
var $terezka$elm_charts$Internal$Commands$CubicBeziers = F6(
	function (a, b, c, d, e, f) {
		return {$: 2, a: a, b: b, c: c, d: d, e: e, f: f};
	});
var $terezka$elm_charts$Internal$Commands$CubicBeziersShort = F4(
	function (a, b, c, d) {
		return {$: 3, a: a, b: b, c: c, d: d};
	});
var $terezka$elm_charts$Internal$Commands$QuadraticBeziers = F4(
	function (a, b, c, d) {
		return {$: 4, a: a, b: b, c: c, d: d};
	});
var $terezka$elm_charts$Internal$Commands$QuadraticBeziersShort = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $terezka$elm_charts$Internal$Coordinates$innerLength = function (axis) {
	return A2($elm$core$Basics$max, 1, (axis.z - axis.mz) - axis.my);
};
var $terezka$elm_charts$Internal$Coordinates$innerWidth = function (plane) {
	return $terezka$elm_charts$Internal$Coordinates$innerLength(plane.jv);
};
var $terezka$elm_charts$Internal$Coordinates$range = function (axis) {
	var diff = axis.mB - axis.hI;
	return (diff > 0) ? diff : 1;
};
var $terezka$elm_charts$Internal$Coordinates$scaleSVGX = F2(
	function (plane, value) {
		return (value * $terezka$elm_charts$Internal$Coordinates$innerWidth(plane)) / $terezka$elm_charts$Internal$Coordinates$range(plane.jv);
	});
var $terezka$elm_charts$Internal$Coordinates$toSVGX = F2(
	function (plane, value) {
		return A2($terezka$elm_charts$Internal$Coordinates$scaleSVGX, plane, value - plane.jv.hI) + plane.jv.mz;
	});
var $terezka$elm_charts$Internal$Coordinates$innerHeight = function (plane) {
	return $terezka$elm_charts$Internal$Coordinates$innerLength(plane.pS);
};
var $terezka$elm_charts$Internal$Coordinates$scaleSVGY = F2(
	function (plane, value) {
		return (value * $terezka$elm_charts$Internal$Coordinates$innerHeight(plane)) / $terezka$elm_charts$Internal$Coordinates$range(plane.pS);
	});
var $terezka$elm_charts$Internal$Coordinates$toSVGY = F2(
	function (plane, value) {
		return A2($terezka$elm_charts$Internal$Coordinates$scaleSVGY, plane, plane.pS.mB - value) + plane.pS.mz;
	});
var $terezka$elm_charts$Internal$Commands$translate = F2(
	function (plane, command) {
		switch (command.$) {
			case 0:
				var x = command.a;
				var y = command.b;
				return A2(
					$terezka$elm_charts$Internal$Commands$Move,
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y));
			case 1:
				var x = command.a;
				var y = command.b;
				return A2(
					$terezka$elm_charts$Internal$Commands$Line,
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y));
			case 2:
				var cx1 = command.a;
				var cy1 = command.b;
				var cx2 = command.c;
				var cy2 = command.d;
				var x = command.e;
				var y = command.f;
				return A6(
					$terezka$elm_charts$Internal$Commands$CubicBeziers,
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, cx1),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, cy1),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, cx2),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, cy2),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y));
			case 3:
				var cx1 = command.a;
				var cy1 = command.b;
				var x = command.c;
				var y = command.d;
				return A4(
					$terezka$elm_charts$Internal$Commands$CubicBeziersShort,
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, cx1),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, cy1),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y));
			case 4:
				var cx1 = command.a;
				var cy1 = command.b;
				var x = command.c;
				var y = command.d;
				return A4(
					$terezka$elm_charts$Internal$Commands$QuadraticBeziers,
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, cx1),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, cy1),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y));
			case 5:
				var x = command.a;
				var y = command.b;
				return A2(
					$terezka$elm_charts$Internal$Commands$QuadraticBeziersShort,
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y));
			case 6:
				var rx = command.a;
				var ry = command.b;
				var xAxisRotation = command.c;
				var largeArcFlag = command.d;
				var sweepFlag = command.e;
				var x = command.f;
				var y = command.g;
				return A7(
					$terezka$elm_charts$Internal$Commands$Arc,
					rx,
					ry,
					xAxisRotation,
					largeArcFlag,
					sweepFlag,
					A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x),
					A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y));
			default:
				return $terezka$elm_charts$Internal$Commands$Close;
		}
	});
var $terezka$elm_charts$Internal$Commands$description = F2(
	function (plane, commands) {
		return $terezka$elm_charts$Internal$Commands$joinCommands(
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					$terezka$elm_charts$Internal$Commands$translate(plane),
					$terezka$elm_charts$Internal$Commands$stringCommand),
				commands));
	});
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$Attributes$fillOpacity = _VirtualDom_attribute('fill-opacity');
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$g = $elm$svg$Svg$trustedNode('g');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $terezka$elm_charts$Internal$Coordinates$scaleCartesianX = F2(
	function (plane, value) {
		return (value * $terezka$elm_charts$Internal$Coordinates$range(plane.jv)) / $terezka$elm_charts$Internal$Coordinates$innerWidth(plane);
	});
var $terezka$elm_charts$Internal$Coordinates$scaleCartesianY = F2(
	function (plane, value) {
		return (value * $terezka$elm_charts$Internal$Coordinates$range(plane.pS)) / $terezka$elm_charts$Internal$Coordinates$innerHeight(plane);
	});
var $elm$svg$Svg$Attributes$stroke = _VirtualDom_attribute('stroke');
var $elm$svg$Svg$Attributes$strokeOpacity = _VirtualDom_attribute('stroke-opacity');
var $elm$svg$Svg$Attributes$strokeWidth = _VirtualDom_attribute('stroke-width');
var $terezka$elm_charts$Internal$Svg$apply = F2(
	function (funcs, _default) {
		var apply_ = F2(
			function (f, a) {
				return f(a);
			});
		return A3($elm$core$List$foldl, apply_, _default, funcs);
	});
var $elm$svg$Svg$circle = $elm$svg$Svg$trustedNode('circle');
var $elm$svg$Svg$Attributes$cx = _VirtualDom_attribute('cx');
var $elm$svg$Svg$Attributes$cy = _VirtualDom_attribute('cy');
var $elm$svg$Svg$defs = $elm$svg$Svg$trustedNode('defs');
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$svg$Svg$Attributes$id = _VirtualDom_attribute('id');
var $elm$svg$Svg$line = $elm$svg$Svg$trustedNode('line');
var $elm$svg$Svg$linearGradient = $elm$svg$Svg$trustedNode('linearGradient');
var $elm$svg$Svg$Attributes$offset = _VirtualDom_attribute('offset');
var $elm$svg$Svg$pattern = $elm$svg$Svg$trustedNode('pattern');
var $elm$svg$Svg$Attributes$patternTransform = _VirtualDom_attribute('patternTransform');
var $elm$svg$Svg$Attributes$patternUnits = _VirtualDom_attribute('patternUnits');
var $elm$svg$Svg$Attributes$r = _VirtualDom_attribute('r');
var $elm$svg$Svg$stop = $elm$svg$Svg$trustedNode('stop');
var $elm$svg$Svg$Attributes$stopColor = _VirtualDom_attribute('stop-color');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $elm$svg$Svg$Attributes$x1 = _VirtualDom_attribute('x1');
var $elm$svg$Svg$Attributes$x2 = _VirtualDom_attribute('x2');
var $elm$svg$Svg$Attributes$y = _VirtualDom_attribute('y');
var $elm$svg$Svg$Attributes$y1 = _VirtualDom_attribute('y1');
var $elm$svg$Svg$Attributes$y2 = _VirtualDom_attribute('y2');
var $terezka$elm_charts$Internal$Svg$toPattern = F2(
	function (defaultColor, design) {
		var toPatternId = function (props) {
			return A3(
				$elm$core$String$replace,
				'(',
				'-',
				A3(
					$elm$core$String$replace,
					')',
					'-',
					A3(
						$elm$core$String$replace,
						'.',
						'-',
						A3(
							$elm$core$String$replace,
							',',
							'-',
							A3(
								$elm$core$String$replace,
								' ',
								'-',
								A2(
									$elm$core$String$join,
									'-',
									_Utils_ap(
										_List_fromArray(
											[
												'elm-charts__pattern',
												function () {
												switch (design.$) {
													case 0:
														return 'striped';
													case 1:
														return 'dotted';
													default:
														return 'gradient';
												}
											}()
											]),
										props)))))));
		};
		var toPatternDefs = F4(
			function (id, spacing, rotate, inside) {
				return A2(
					$elm$svg$Svg$defs,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$svg$Svg$pattern,
							_List_fromArray(
								[
									$elm$svg$Svg$Attributes$id(id),
									$elm$svg$Svg$Attributes$patternUnits('userSpaceOnUse'),
									$elm$svg$Svg$Attributes$width(
									$elm$core$String$fromFloat(spacing)),
									$elm$svg$Svg$Attributes$height(
									$elm$core$String$fromFloat(spacing)),
									$elm$svg$Svg$Attributes$patternTransform(
									'rotate(' + ($elm$core$String$fromFloat(rotate) + ')'))
								]),
							_List_fromArray(
								[inside]))
						]));
			});
		var _v0 = function () {
			switch (design.$) {
				case 0:
					var edits = design.a;
					var config = A2(
						$terezka$elm_charts$Internal$Svg$apply,
						edits,
						{b0: defaultColor, C: 45, ou: 4, ec: 3});
					var theId = toPatternId(
						_List_fromArray(
							[
								config.b0,
								$elm$core$String$fromFloat(config.ec),
								$elm$core$String$fromFloat(config.ou),
								$elm$core$String$fromFloat(config.C)
							]));
					return _Utils_Tuple2(
						A4(
							toPatternDefs,
							theId,
							config.ou,
							config.C,
							A2(
								$elm$svg$Svg$line,
								_List_fromArray(
									[
										$elm$svg$Svg$Attributes$x1('0'),
										$elm$svg$Svg$Attributes$y('0'),
										$elm$svg$Svg$Attributes$x2('0'),
										$elm$svg$Svg$Attributes$y2(
										$elm$core$String$fromFloat(config.ou)),
										$elm$svg$Svg$Attributes$stroke(config.b0),
										$elm$svg$Svg$Attributes$strokeWidth(
										$elm$core$String$fromFloat(config.ec))
									]),
								_List_Nil)),
						theId);
				case 1:
					var edits = design.a;
					var config = A2(
						$terezka$elm_charts$Internal$Svg$apply,
						edits,
						{b0: defaultColor, C: 45, ou: 4, ec: 3});
					var theId = toPatternId(
						_List_fromArray(
							[
								config.b0,
								$elm$core$String$fromFloat(config.ec),
								$elm$core$String$fromFloat(config.ou),
								$elm$core$String$fromFloat(config.C)
							]));
					return _Utils_Tuple2(
						A4(
							toPatternDefs,
							theId,
							config.ou,
							config.C,
							A2(
								$elm$svg$Svg$circle,
								_List_fromArray(
									[
										$elm$svg$Svg$Attributes$fill(config.b0),
										$elm$svg$Svg$Attributes$cx(
										$elm$core$String$fromFloat(config.ec / 3)),
										$elm$svg$Svg$Attributes$cy(
										$elm$core$String$fromFloat(config.ec / 3)),
										$elm$svg$Svg$Attributes$r(
										$elm$core$String$fromFloat(config.ec / 3))
									]),
								_List_Nil)),
						theId);
				default:
					var edits = design.a;
					var colors = _Utils_eq(edits, _List_Nil) ? _List_fromArray(
						[defaultColor, 'white']) : edits;
					var theId = toPatternId(colors);
					var totalColors = $elm$core$List$length(colors);
					var toPercentage = function (i) {
						return (i * 100) / (totalColors - 1);
					};
					var toStop = F2(
						function (i, c) {
							return A2(
								$elm$svg$Svg$stop,
								_List_fromArray(
									[
										$elm$svg$Svg$Attributes$offset(
										$elm$core$String$fromFloat(
											toPercentage(i)) + '%'),
										$elm$svg$Svg$Attributes$stopColor(c)
									]),
								_List_Nil);
						});
					return _Utils_Tuple2(
						A2(
							$elm$svg$Svg$defs,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$svg$Svg$linearGradient,
									_List_fromArray(
										[
											$elm$svg$Svg$Attributes$id(theId),
											$elm$svg$Svg$Attributes$x1('0'),
											$elm$svg$Svg$Attributes$x2('0'),
											$elm$svg$Svg$Attributes$y1('0'),
											$elm$svg$Svg$Attributes$y2('1')
										]),
									A2($elm$core$List$indexedMap, toStop, colors))
								])),
						theId);
			}
		}();
		var patternDefs = _v0.a;
		var patternId = _v0.b;
		return _Utils_Tuple2(patternDefs, 'url(#' + (patternId + ')'));
	});
var $elm$html$Html$Attributes$map = $elm$virtual_dom$VirtualDom$mapAttribute;
var $terezka$elm_charts$Internal$Svg$withAttrs = F3(
	function (attrs, toEl, defaultAttrs) {
		return toEl(
			_Utils_ap(
				defaultAttrs,
				A2(
					$elm$core$List$map,
					$elm$html$Html$Attributes$map($elm$core$Basics$never),
					attrs)));
	});
var $elm$svg$Svg$Attributes$clipPath = _VirtualDom_attribute('clip-path');
var $terezka$elm_charts$Internal$Coordinates$toId = function (plane) {
	var numToStr = A2(
		$elm$core$Basics$composeR,
		$elm$core$String$fromFloat,
		A2($elm$core$String$replace, '.', '-'));
	return A2(
		$elm$core$String$join,
		'_',
		_List_fromArray(
			[
				'elm-charts__id',
				numToStr(plane.jv.z),
				numToStr(plane.jv.hI),
				numToStr(plane.jv.mB),
				numToStr(plane.jv.mz),
				numToStr(plane.jv.my),
				numToStr(plane.pS.z),
				numToStr(plane.pS.hI),
				numToStr(plane.pS.mB),
				numToStr(plane.pS.mz),
				numToStr(plane.pS.my)
			]));
};
var $terezka$elm_charts$Internal$Svg$withinChartArea = function (plane) {
	return $elm$svg$Svg$Attributes$clipPath(
		'url(#' + ($terezka$elm_charts$Internal$Coordinates$toId(plane) + ')'));
};
var $terezka$elm_charts$Internal$Svg$bar = F3(
	function (plane, config, point) {
		var viewBar = F6(
			function (fill, fillOpacity, border, borderWidth, strokeOpacity, cmds) {
				return A4(
					$terezka$elm_charts$Internal$Svg$withAttrs,
					config.k,
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('elm-charts__bar'),
							$elm$svg$Svg$Attributes$fill(fill),
							$elm$svg$Svg$Attributes$fillOpacity(
							$elm$core$String$fromFloat(fillOpacity)),
							$elm$svg$Svg$Attributes$stroke(border),
							$elm$svg$Svg$Attributes$strokeWidth(
							$elm$core$String$fromFloat(borderWidth)),
							$elm$svg$Svg$Attributes$strokeOpacity(
							$elm$core$String$fromFloat(strokeOpacity)),
							$elm$svg$Svg$Attributes$d(
							A2($terezka$elm_charts$Internal$Commands$description, plane, cmds)),
							$terezka$elm_charts$Internal$Svg$withinChartArea(plane)
						]),
					_List_Nil);
			});
		var highlightColor = (config.lR === '') ? config.b0 : config.lR;
		var borderWidthCarY = A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, config.U / 2);
		var highlightWidthCarY = borderWidthCarY + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, config.lS / 2);
		var borderWidthCarX = A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, config.U / 2);
		var highlightWidthCarX = borderWidthCarX + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, config.lS / 2);
		var pos = {
			cv: A2($elm$core$Basics$min, point.cv, point.c6) + borderWidthCarX,
			c6: A2($elm$core$Basics$max, point.cv, point.c6) - borderWidthCarX,
			pT: A2($elm$core$Basics$min, point.pT, point.f4) + borderWidthCarY,
			f4: A2($elm$core$Basics$max, point.pT, point.f4) - borderWidthCarY
		};
		var height = $elm$core$Basics$abs(pos.f4 - pos.pT);
		var highlightPos = {cv: pos.cv - highlightWidthCarX, c6: pos.c6 + highlightWidthCarX, pT: pos.pT - highlightWidthCarY, f4: pos.f4 + highlightWidthCarY};
		var width = $elm$core$Basics$abs(pos.c6 - pos.cv);
		var roundingBottom = (A2($terezka$elm_charts$Internal$Coordinates$scaleSVGX, plane, width) * 0.5) * A3($elm$core$Basics$clamp, 0, 1, config.nY);
		var radiusBottomX = A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, roundingBottom);
		var radiusBottomY = A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, roundingBottom);
		var roundingTop = (A2($terezka$elm_charts$Internal$Coordinates$scaleSVGX, plane, width) * 0.5) * A3($elm$core$Basics$clamp, 0, 1, config.nZ);
		var radiusTopX = A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, roundingTop);
		var radiusTopY = A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, roundingTop);
		var _v0 = ((((height - (radiusTopY * 0.8)) - (radiusBottomY * 0.8)) <= 0) || (((width - (radiusTopX * 0.8)) - (radiusBottomX * 0.8)) <= 0)) ? _Utils_Tuple2(0, 0) : _Utils_Tuple2(config.nZ, config.nY);
		var roundTop = _v0.a;
		var roundBottom = _v0.b;
		var _v1 = function () {
			if (_Utils_eq(pos.pT, pos.f4)) {
				return _Utils_Tuple2(_List_Nil, _List_Nil);
			} else {
				var _v2 = _Utils_Tuple2(roundTop > 0, roundBottom > 0);
				if (!_v2.a) {
					if (!_v2.b) {
						return _Utils_Tuple2(
							_List_fromArray(
								[
									A2($terezka$elm_charts$Internal$Commands$Move, pos.cv, pos.pT),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.cv, pos.f4),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.c6, pos.f4),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.c6, pos.pT),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.cv, pos.pT)
								]),
							_List_fromArray(
								[
									A2($terezka$elm_charts$Internal$Commands$Move, highlightPos.cv, pos.pT),
									A2($terezka$elm_charts$Internal$Commands$Line, highlightPos.cv, highlightPos.f4),
									A2($terezka$elm_charts$Internal$Commands$Line, highlightPos.c6, highlightPos.f4),
									A2($terezka$elm_charts$Internal$Commands$Line, highlightPos.c6, pos.pT),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.c6, pos.pT),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.c6, pos.f4),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.cv, pos.f4),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.cv, pos.pT)
								]));
					} else {
						return _Utils_Tuple2(
							_List_fromArray(
								[
									A2($terezka$elm_charts$Internal$Commands$Move, pos.cv + radiusBottomX, pos.pT),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingBottom, roundingBottom, -45, false, true, pos.cv, pos.pT + radiusBottomY),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.cv, pos.f4),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.c6, pos.f4),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.c6, pos.pT + radiusBottomY),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingBottom, roundingBottom, -45, false, true, pos.c6 - radiusBottomX, pos.pT),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.cv + radiusBottomX, pos.pT)
								]),
							_List_fromArray(
								[
									A2($terezka$elm_charts$Internal$Commands$Move, highlightPos.cv + radiusBottomX, highlightPos.pT),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingBottom, roundingBottom, -45, false, true, highlightPos.cv, highlightPos.pT + radiusBottomY),
									A2($terezka$elm_charts$Internal$Commands$Line, highlightPos.cv, highlightPos.f4),
									A2($terezka$elm_charts$Internal$Commands$Line, highlightPos.c6, highlightPos.f4),
									A2($terezka$elm_charts$Internal$Commands$Line, highlightPos.c6, highlightPos.pT + radiusBottomY),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingBottom, roundingBottom, -45, false, true, highlightPos.c6 - radiusBottomX, highlightPos.pT),
									A2($terezka$elm_charts$Internal$Commands$Line, highlightPos.cv + radiusBottomX, highlightPos.pT),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.c6 - radiusBottomX, pos.pT),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingBottom, roundingBottom, -45, false, false, pos.c6, pos.pT + radiusBottomY),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.c6, pos.f4),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.cv, pos.f4),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.cv, pos.pT + radiusBottomY),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.c6, pos.pT)
								]));
					}
				} else {
					if (!_v2.b) {
						return _Utils_Tuple2(
							_List_fromArray(
								[
									A2($terezka$elm_charts$Internal$Commands$Move, pos.cv, pos.pT),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.cv, pos.f4 - radiusTopY),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingTop, roundingTop, -45, false, true, pos.cv + radiusTopX, pos.f4),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.c6 - radiusTopX, pos.f4),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingTop, roundingTop, -45, false, true, pos.c6, pos.f4 - radiusTopY),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.c6, pos.pT),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.cv, pos.pT)
								]),
							_List_fromArray(
								[
									A2($terezka$elm_charts$Internal$Commands$Move, highlightPos.cv, pos.pT),
									A2($terezka$elm_charts$Internal$Commands$Line, highlightPos.cv, highlightPos.f4 - radiusTopY),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingTop, roundingTop, -45, false, true, highlightPos.cv + radiusTopX, highlightPos.f4),
									A2($terezka$elm_charts$Internal$Commands$Line, highlightPos.c6 - radiusTopX, highlightPos.f4),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingTop, roundingTop, -45, false, true, highlightPos.c6, highlightPos.f4 - radiusTopY),
									A2($terezka$elm_charts$Internal$Commands$Line, highlightPos.c6, pos.pT),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.c6, pos.pT),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.c6, pos.f4 - radiusTopY),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingTop, roundingTop, -45, false, false, pos.c6 - radiusTopX, pos.f4),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.cv + radiusTopX, pos.f4),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingTop, roundingTop, -45, false, false, pos.cv, pos.f4 - radiusTopY),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.cv, pos.pT)
								]));
					} else {
						return _Utils_Tuple2(
							_List_fromArray(
								[
									A2($terezka$elm_charts$Internal$Commands$Move, pos.cv + radiusBottomX, pos.pT),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingBottom, roundingBottom, -45, false, true, pos.cv, pos.pT + radiusBottomY),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.cv, pos.f4 - radiusTopY),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingTop, roundingTop, -45, false, true, pos.cv + radiusTopX, pos.f4),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.c6 - radiusTopX, pos.f4),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingTop, roundingTop, -45, false, true, pos.c6, pos.f4 - radiusTopY),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.c6, pos.pT + radiusBottomY),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingBottom, roundingBottom, -45, false, true, pos.c6 - radiusBottomX, pos.pT),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.cv + radiusBottomX, pos.pT)
								]),
							_List_fromArray(
								[
									A2($terezka$elm_charts$Internal$Commands$Move, highlightPos.cv + radiusBottomX, highlightPos.pT),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingBottom, roundingBottom, -45, false, true, highlightPos.cv, highlightPos.pT + radiusBottomY),
									A2($terezka$elm_charts$Internal$Commands$Line, highlightPos.cv, highlightPos.f4 - radiusTopY),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingTop, roundingTop, -45, false, true, highlightPos.cv + radiusTopX, highlightPos.f4),
									A2($terezka$elm_charts$Internal$Commands$Line, highlightPos.c6 - radiusTopX, highlightPos.f4),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingTop, roundingTop, -45, false, true, highlightPos.c6, highlightPos.f4 - radiusTopY),
									A2($terezka$elm_charts$Internal$Commands$Line, highlightPos.c6, highlightPos.pT + radiusBottomY),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingBottom, roundingBottom, -45, false, true, highlightPos.c6 - radiusBottomX, highlightPos.pT),
									A2($terezka$elm_charts$Internal$Commands$Line, highlightPos.cv + radiusBottomX, highlightPos.pT),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.c6 - radiusBottomX, pos.pT),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingBottom, roundingBottom, -45, false, false, pos.c6, pos.pT + radiusBottomY),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.c6, pos.f4 - radiusTopY),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingTop, roundingTop, -45, false, false, pos.c6 - radiusTopX, pos.f4),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.cv + radiusTopX, pos.f4),
									A7($terezka$elm_charts$Internal$Commands$Arc, roundingTop, roundingTop, -45, false, false, pos.cv, pos.f4 - radiusTopY),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.cv, pos.pT + radiusBottomY),
									A2($terezka$elm_charts$Internal$Commands$Line, pos.c6, pos.pT)
								]));
					}
				}
			}
		}();
		var commands = _v1.a;
		var highlightCommands = _v1.b;
		var viewAuraBar = function (fill) {
			return (!config.lQ) ? A6(viewBar, fill, config.aB, config.ep, config.U, 1, commands) : A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$class('elm-charts__bar-with-highlight')
					]),
				_List_fromArray(
					[
						A6(viewBar, highlightColor, config.lQ, 'transparent', 0, 0, highlightCommands),
						A6(viewBar, fill, config.aB, config.ep, config.U, 1, commands)
					]));
		};
		var _v3 = config.eI;
		if (_v3.$ === 1) {
			return viewAuraBar(config.b0);
		} else {
			var design = _v3.a;
			var _v4 = A2($terezka$elm_charts$Internal$Svg$toPattern, config.b0, design);
			var patternDefs = _v4.a;
			var fill = _v4.b;
			return A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$class('elm-charts__bar-with-pattern')
					]),
				_List_fromArray(
					[
						patternDefs,
						viewAuraBar(fill)
					]));
		}
	});
var $terezka$elm_charts$Internal$Item$isBar = function (_v0) {
	var item = _v0;
	var _v1 = item.kD.nB;
	if (_v1.$ === 1) {
		var bar = _v1.a;
		return $elm$core$Maybe$Just(
			{
				kD: {nB: bar, pg: $terezka$elm_charts$Internal$Item$Bar, d7: item.kD.d7, pJ: item.kD.pJ},
				ph: function (c) {
					return item.ph(item.kD);
				},
				pi: function (_v2) {
					return item.pi(item.kD);
				},
				pl: F2(
					function (plane, _v3) {
						return A2(item.pl, plane, item.kD);
					}),
				pm: F2(
					function (plane, config) {
						return A2($terezka$elm_charts$Internal$Svg$bar, plane, config.nB);
					})
			});
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $terezka$elm_charts$Internal$Many$bars = A2(
	$terezka$elm_charts$Internal$Many$Remodel,
	$terezka$elm_charts$Internal$Item$getPosition,
	$elm$core$List$filterMap($terezka$elm_charts$Internal$Item$isBar));
var $terezka$elm_charts$Chart$Item$bars = $terezka$elm_charts$Internal$Many$bars;
var $terezka$elm_charts$Internal$Item$isReal = function (_v0) {
	var item = _v0;
	return item.kD.pJ.mk;
};
var $terezka$elm_charts$Internal$Many$real = A2(
	$terezka$elm_charts$Internal$Many$Remodel,
	$terezka$elm_charts$Internal$Item$getPosition,
	$elm$core$List$filter($terezka$elm_charts$Internal$Item$isReal));
var $terezka$elm_charts$Chart$Item$real = $terezka$elm_charts$Internal$Many$real;
var $terezka$elm_charts$Chart$eachBar = function (func) {
	return $terezka$elm_charts$Chart$SubElements(
		F2(
			function (p, is) {
				return A2(
					$elm$core$List$concatMap,
					func(p),
					A2(
						$terezka$elm_charts$Chart$Item$apply,
						A2($terezka$elm_charts$Chart$Item$andThen, $terezka$elm_charts$Chart$Item$real, $terezka$elm_charts$Chart$Item$bars),
						is));
			}));
};
var $terezka$elm_charts$Internal$Coordinates$top = function (pos) {
	return {jv: pos.cv + ((pos.c6 - pos.cv) / 2), pS: pos.f4};
};
var $terezka$elm_charts$Chart$Item$getTop = function (p) {
	return A2(
		$elm$core$Basics$composeR,
		$terezka$elm_charts$Internal$Item$getPosition(p),
		$terezka$elm_charts$Internal$Coordinates$top);
};
var $terezka$elm_charts$Internal$Item$getY = function (_v0) {
	var item = _v0;
	return item.kD.pJ.pS;
};
var $terezka$elm_charts$Chart$Item$getY = $terezka$elm_charts$Internal$Item$getY;
var $elm$svg$Svg$foreignObject = $elm$svg$Svg$trustedNode('foreignObject');
var $elm$svg$Svg$Attributes$transform = _VirtualDom_attribute('transform');
var $terezka$elm_charts$Internal$Svg$position = F6(
	function (plane, rotation, x_, y_, xOff_, yOff_) {
		return $elm$svg$Svg$Attributes$transform(
			'translate(' + ($elm$core$String$fromFloat(
				A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, x_) + xOff_) + (',' + ($elm$core$String$fromFloat(
				A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, y_) + yOff_) + (') rotate(' + ($elm$core$String$fromFloat(rotation) + ')'))))));
	});
var $elm$svg$Svg$Attributes$style = _VirtualDom_attribute('style');
var $elm$svg$Svg$text_ = $elm$svg$Svg$trustedNode('text');
var $elm$svg$Svg$tspan = $elm$svg$Svg$trustedNode('tspan');
var $terezka$elm_charts$Internal$Svg$label = F4(
	function (plane, config, inner, point) {
		var _v0 = config.w;
		if (_v0.$ === 1) {
			var withOverflowWrap = function (el) {
				return config.y ? A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[
							$terezka$elm_charts$Internal$Svg$withinChartArea(plane)
						]),
					_List_fromArray(
						[el])) : el;
			};
			var uppercaseStyle = config.E ? 'text-transform: uppercase;' : '';
			var fontStyle = function () {
				var _v5 = config.x;
				if (!_v5.$) {
					var size_ = _v5.a;
					return 'font-size: ' + ($elm$core$String$fromInt(size_) + 'px;');
				} else {
					return '';
				}
			}();
			var anchorStyle = function () {
				var _v1 = config.v;
				if (_v1.$ === 1) {
					return 'text-anchor: middle;';
				} else {
					switch (_v1.a) {
						case 0:
							var _v2 = _v1.a;
							return 'text-anchor: end;';
						case 1:
							var _v3 = _v1.a;
							return 'text-anchor: start;';
						default:
							var _v4 = _v1.a;
							return 'text-anchor: middle;';
					}
				}
			}();
			return withOverflowWrap(
				A4(
					$terezka$elm_charts$Internal$Svg$withAttrs,
					config.k,
					$elm$svg$Svg$text_,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('elm-charts__label'),
							$elm$svg$Svg$Attributes$stroke(config.ep),
							$elm$svg$Svg$Attributes$strokeWidth(
							$elm$core$String$fromFloat(config.U)),
							$elm$svg$Svg$Attributes$fill(config.b0),
							A6($terezka$elm_charts$Internal$Svg$position, plane, -config.C, point.jv, point.pS, config.p, config.q),
							$elm$svg$Svg$Attributes$style(
							A2(
								$elm$core$String$join,
								' ',
								_List_fromArray(
									['pointer-events: none;', fontStyle, anchorStyle, uppercaseStyle])))
						]),
					_List_fromArray(
						[
							A2($elm$svg$Svg$tspan, _List_Nil, inner)
						])));
		} else {
			var ellipsis = _v0.a;
			var xOffWithAnchor = function () {
				var _v11 = config.v;
				if (_v11.$ === 1) {
					return config.p - (ellipsis.ec / 2);
				} else {
					switch (_v11.a) {
						case 0:
							var _v12 = _v11.a;
							return config.p - ellipsis.ec;
						case 1:
							var _v13 = _v11.a;
							return config.p;
						default:
							var _v14 = _v11.a;
							return config.p - (ellipsis.ec / 2);
					}
				}
			}();
			var withOverflowWrap = function (el) {
				return config.y ? A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[
							$terezka$elm_charts$Internal$Svg$withinChartArea(plane)
						]),
					_List_fromArray(
						[el])) : el;
			};
			var uppercaseStyle = config.E ? A2($elm$html$Html$Attributes$style, 'text-transform', 'uppercase') : A2($elm$html$Html$Attributes$style, '', '');
			var fontStyle = function () {
				var _v10 = config.x;
				if (!_v10.$) {
					var size_ = _v10.a;
					return A2(
						$elm$html$Html$Attributes$style,
						'font-size',
						$elm$core$String$fromInt(size_) + 'px');
				} else {
					return A2($elm$html$Html$Attributes$style, '', '');
				}
			}();
			var anchorStyle = function () {
				var _v6 = config.v;
				if (_v6.$ === 1) {
					return A2($elm$html$Html$Attributes$style, 'text-align', 'center');
				} else {
					switch (_v6.a) {
						case 0:
							var _v7 = _v6.a;
							return A2($elm$html$Html$Attributes$style, 'text-align', 'right');
						case 1:
							var _v8 = _v6.a;
							return A2($elm$html$Html$Attributes$style, 'text-align', 'left');
						default:
							var _v9 = _v6.a;
							return A2($elm$html$Html$Attributes$style, 'text-align', 'center');
					}
				}
			}();
			return withOverflowWrap(
				A4(
					$terezka$elm_charts$Internal$Svg$withAttrs,
					config.k,
					$elm$svg$Svg$foreignObject,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('elm-charts__label'),
							$elm$svg$Svg$Attributes$class('elm-charts__html-label'),
							$elm$svg$Svg$Attributes$width(
							$elm$core$String$fromFloat(ellipsis.ec)),
							$elm$svg$Svg$Attributes$height(
							$elm$core$String$fromFloat(ellipsis.he)),
							A6($terezka$elm_charts$Internal$Svg$position, plane, -config.C, point.jv, point.pS, xOffWithAnchor, config.q - 10)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$attribute, 'xmlns', 'http://www.w3.org/1999/xhtml'),
									A2($elm$html$Html$Attributes$style, 'white-space', 'nowrap'),
									A2($elm$html$Html$Attributes$style, 'overflow', 'hidden'),
									A2($elm$html$Html$Attributes$style, 'text-overflow', 'ellipsis'),
									A2($elm$html$Html$Attributes$style, 'height', '100%'),
									A2($elm$html$Html$Attributes$style, 'pointer-events', 'none'),
									A2($elm$html$Html$Attributes$style, 'color', config.b0),
									fontStyle,
									uppercaseStyle,
									anchorStyle
								]),
							inner)
						])));
		}
	});
var $terezka$elm_charts$Chart$SvgElement = function (a) {
	return {$: 12, a: a};
};
var $terezka$elm_charts$Chart$svg = function (func) {
	return $terezka$elm_charts$Chart$SvgElement(
		function (p) {
			return func(p);
		});
};
var $elm$svg$Svg$text = $elm$virtual_dom$VirtualDom$text;
var $terezka$elm_charts$Chart$toLabelFromItemLabel = function (config) {
	return {v: config.v, k: config.k, ep: config.ep, U: config.U, b0: config.b0, w: config.w, x: config.x, y: config.y, C: config.C, E: config.E, p: config.p, q: config.q};
};
var $terezka$elm_charts$Chart$barLabels = function (edits) {
	return $terezka$elm_charts$Chart$eachBar(
		F2(
			function (p, item) {
				var config = A2(
					$terezka$elm_charts$Internal$Helpers$apply,
					edits,
					_Utils_update(
						$terezka$elm_charts$Chart$defaultLabel,
						{bb: $terezka$elm_charts$Chart$Item$getTop}));
				var text = function () {
					var _v1 = config.ag;
					if (!_v1.$) {
						var formatting = _v1.a;
						return formatting(item);
					} else {
						return $elm$core$String$fromFloat(
							$terezka$elm_charts$Chart$Item$getY(item));
					}
				}();
				return _List_fromArray(
					[
						$terezka$elm_charts$Chart$svg(
						function (_v0) {
							return A4(
								$terezka$elm_charts$Internal$Svg$label,
								p,
								$terezka$elm_charts$Chart$toLabelFromItemLabel(config),
								_List_fromArray(
									[
										$elm$svg$Svg$text(text)
									]),
								A2(config.bb, p, item));
						})
					]);
			}));
};
var $terezka$elm_charts$Chart$BarsElement = F5(
	function (a, b, c, d, e) {
		return {$: 2, a: a, b: b, c: c, d: d, e: e};
	});
var $terezka$elm_charts$Chart$Indexed = function (a) {
	return {$: 0, a: a};
};
var $terezka$elm_charts$Internal$Many$editLimits = F2(
	function (edit, _v0) {
		var group_ = _v0;
		return _Utils_update(
			group_,
			{
				pi: function (c) {
					return function (_v1) {
						var x = _v1.a;
						var xs = _v1.b;
						return A2(
							edit,
							x,
							group_.pi(c));
					}(c.am);
				}
			});
	});
var $terezka$elm_charts$Internal$Item$getX1 = function (_v0) {
	var item = _v0;
	return item.kD.pJ.cv;
};
var $terezka$elm_charts$Internal$Item$getX2 = function (_v0) {
	var item = _v0;
	return item.kD.pJ.c6;
};
var $terezka$elm_charts$Internal$Helpers$gatherWith = F2(
	function (testFn, list) {
		var helper = F2(
			function (scattered, gathered) {
				if (!scattered.b) {
					return $elm$core$List$reverse(gathered);
				} else {
					var toGather = scattered.a;
					var population = scattered.b;
					var _v1 = A2(
						$elm$core$List$partition,
						testFn(toGather),
						population);
					var gathering = _v1.a;
					var remaining = _v1.b;
					return A2(
						helper,
						remaining,
						A2(
							$elm$core$List$cons,
							_Utils_Tuple2(toGather, gathering),
							gathered));
				}
			});
		return A2(helper, list, _List_Nil);
	});
var $terezka$elm_charts$Internal$Coordinates$Position = F4(
	function (x1, x2, y1, y2) {
		return {cv: x1, c6: x2, pT: y1, f4: y2};
	});
var $terezka$elm_charts$Internal$Coordinates$foldPosition = F2(
	function (func, data) {
		var fold = F2(
			function (datum, posM) {
				if (!posM.$) {
					var pos = posM.a;
					return $elm$core$Maybe$Just(
						{
							cv: A2(
								$elm$core$Basics$min,
								func(datum).cv,
								pos.cv),
							c6: A2(
								$elm$core$Basics$max,
								func(datum).c6,
								pos.c6),
							pT: A2(
								$elm$core$Basics$min,
								func(datum).pT,
								pos.pT),
							f4: A2(
								$elm$core$Basics$max,
								func(datum).f4,
								pos.f4)
						});
				} else {
					return $elm$core$Maybe$Just(
						func(datum));
				}
			});
		return A2(
			$elm$core$Maybe$withDefault,
			A4($terezka$elm_charts$Internal$Coordinates$Position, 0, 0, 0, 0),
			A3($elm$core$List$foldl, fold, $elm$core$Maybe$Nothing, data));
	});
var $terezka$elm_charts$Internal$Item$getLimits = function (_v0) {
	var item = _v0;
	return item.pi(item.kD);
};
var $elm$html$Html$table = _VirtualDom_node('table');
var $terezka$elm_charts$Internal$Item$toHtml = function (_v0) {
	var item = _v0;
	return item.ph(item.kD);
};
var $terezka$elm_charts$Internal$Item$toSvg = F2(
	function (plane, _v0) {
		var item = _v0;
		return A3(
			item.pm,
			plane,
			item.kD,
			A2(item.pl, plane, item.kD));
	});
var $terezka$elm_charts$Internal$Many$toGroup = F2(
	function (first, rest) {
		var concatTuple = function (_v1) {
			var x = _v1.a;
			var xs = _v1.b;
			return A2($elm$core$List$cons, x, xs);
		};
		return {
			kD: {
				am: _Utils_Tuple2(first, rest)
			},
			ph: function (c) {
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_Nil,
						A2(
							$elm$core$List$concatMap,
							$terezka$elm_charts$Internal$Item$toHtml,
							concatTuple(c.am)))
					]);
			},
			pi: function (c) {
				return A2(
					$terezka$elm_charts$Internal$Coordinates$foldPosition,
					$terezka$elm_charts$Internal$Item$getLimits,
					concatTuple(c.am));
			},
			pl: F2(
				function (p, c) {
					return A2(
						$terezka$elm_charts$Internal$Coordinates$foldPosition,
						$terezka$elm_charts$Internal$Item$getPosition(p),
						concatTuple(c.am));
				}),
			pm: F3(
				function (p, c, _v0) {
					return A2(
						$elm$svg$Svg$g,
						_List_fromArray(
							[
								$elm$svg$Svg$Attributes$class('elm-charts__group')
							]),
						A2(
							$elm$core$List$map,
							$terezka$elm_charts$Internal$Item$toSvg(p),
							concatTuple(c.am)));
				})
		};
	});
var $terezka$elm_charts$Internal$Many$groupingHelp = F2(
	function (_v0, items) {
		var shared = _v0.d0;
		var equality = _v0.dw;
		var edits = _v0.dr;
		var toShared = function (_v2) {
			var item = _v2;
			return shared(item.kD);
		};
		var toNewGroup = function (_v1) {
			var i = _v1.a;
			var is = _v1.b;
			return edits(
				A2($terezka$elm_charts$Internal$Many$toGroup, i, is));
		};
		var toEquality = F2(
			function (aO, bO) {
				return A2(
					equality,
					toShared(aO),
					toShared(bO));
			});
		return A2(
			$elm$core$List$map,
			toNewGroup,
			A2($terezka$elm_charts$Internal$Helpers$gatherWith, toEquality, items));
	});
var $terezka$elm_charts$Internal$Many$bins = A2(
	$terezka$elm_charts$Internal$Many$Remodel,
	$terezka$elm_charts$Internal$Item$getPosition,
	$terezka$elm_charts$Internal$Many$groupingHelp(
		{
			dr: $terezka$elm_charts$Internal$Many$editLimits(
				F2(
					function (item, pos) {
						return _Utils_update(
							pos,
							{
								cv: $terezka$elm_charts$Internal$Item$getX1(item),
								c6: $terezka$elm_charts$Internal$Item$getX2(item)
							});
					})),
			dw: F2(
				function (a, b) {
					return _Utils_eq(a.cv, b.cv) && (_Utils_eq(a.c6, b.c6) && (_Utils_eq(a.lg, b.lg) && _Utils_eq(a.eE, b.eE)));
				}),
			d0: function (config) {
				return {eE: config.d7.k_, lg: config.d7.lg, cv: config.pJ.cv, c6: config.pJ.c6};
			}
		}));
var $terezka$elm_charts$Chart$Item$bins = $terezka$elm_charts$Internal$Many$bins;
var $terezka$elm_charts$Internal$Produce$defaultBars = {lH: false, lK: true, bE: 0.1, nY: 0, nZ: 0, ou: 0.05, cv: $elm$core$Maybe$Nothing, c6: $elm$core$Maybe$Nothing};
var $terezka$elm_charts$Internal$Item$generalize = F2(
	function (toAny, _v0) {
		var item = _v0;
		return {
			kD: {
				nB: toAny(item.kD.nB),
				pg: $elm$core$Basics$identity,
				d7: item.kD.d7,
				pJ: item.kD.pJ
			},
			ph: function (c) {
				return $terezka$elm_charts$Internal$Item$toHtml(item);
			},
			pi: function (_v1) {
				return item.pi(item.kD);
			},
			pl: F2(
				function (plane, _v2) {
					return A2(item.pl, plane, item.kD);
				}),
			pm: F3(
				function (plane, _v3, _v4) {
					return A2($terezka$elm_charts$Internal$Item$toSvg, plane, item);
				})
		};
	});
var $terezka$elm_charts$Internal$Many$getMembers = function (_v0) {
	var group_ = _v0;
	return function (_v1) {
		var x = _v1.a;
		var xs = _v1.b;
		return A2($elm$core$List$cons, x, xs);
	}(group_.kD.am);
};
var $terezka$elm_charts$Internal$Many$getGenerals = function (group_) {
	var generalize = function (_v0) {
		var item = _v0;
		return A2($terezka$elm_charts$Internal$Item$generalize, item.kD.pg, item);
	};
	return A2(
		$elm$core$List$map,
		generalize,
		$terezka$elm_charts$Internal$Many$getMembers(group_));
};
var $terezka$elm_charts$Chart$Item$getLimits = $terezka$elm_charts$Internal$Item$getLimits;
var $terezka$elm_charts$Internal$Item$map = F2(
	function (func, _v0) {
		var item = _v0;
		return {
			kD: {
				nB: item.kD.nB,
				pg: item.kD.pg,
				d7: item.kD.d7,
				pJ: {
					k2: func(item.kD.pJ.k2),
					mk: item.kD.pJ.mk,
					cv: item.kD.pJ.cv,
					c6: item.kD.pJ.c6,
					pS: item.kD.pJ.pS
				}
			},
			ph: function (_v1) {
				return $terezka$elm_charts$Internal$Item$toHtml(item);
			},
			pi: function (_v2) {
				return item.pi(item.kD);
			},
			pl: F2(
				function (plane, _v3) {
					return A2(item.pl, plane, item.kD);
				}),
			pm: F3(
				function (plane, _v4, _v5) {
					return A2($terezka$elm_charts$Internal$Item$toSvg, plane, item);
				})
		};
	});
var $elm$svg$Svg$map = $elm$virtual_dom$VirtualDom$map;
var $terezka$elm_charts$Internal$Legend$BarLegend = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $terezka$elm_charts$Chart$Attributes$border = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{ep: v});
	});
var $terezka$elm_charts$Chart$Attributes$color = F2(
	function (v, config) {
		return (v === '') ? config : _Utils_update(
			config,
			{b0: v});
	});
var $terezka$elm_charts$Internal$Helpers$pink = '#ea60df';
var $terezka$elm_charts$Internal$Svg$defaultBar = {k: _List_Nil, ep: 'white', U: 0, b0: $terezka$elm_charts$Internal$Helpers$pink, eI: $elm$core$Maybe$Nothing, lQ: 0, lR: '', lS: 10, aB: 1, nY: 0, nZ: 0};
var $terezka$elm_charts$Chart$Attributes$roundBottom = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{nY: v});
	});
var $terezka$elm_charts$Chart$Attributes$roundTop = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{nZ: v});
	});
var $terezka$elm_charts$Internal$Property$toConfigs = function (prop) {
	if (!prop.$) {
		var config = prop.a;
		return _List_fromArray(
			[config]);
	} else {
		var configs = prop.a;
		return configs;
	}
};
var $terezka$elm_charts$Internal$Helpers$blue = '#12A5ED';
var $terezka$elm_charts$Internal$Helpers$brown = '#871c1c';
var $terezka$elm_charts$Internal$Helpers$green = '#71c614';
var $terezka$elm_charts$Internal$Helpers$moss = '#92b42c';
var $terezka$elm_charts$Internal$Helpers$orange = '#FF8400';
var $terezka$elm_charts$Internal$Helpers$purple = '#7b4dff';
var $terezka$elm_charts$Internal$Helpers$red = '#F5325B';
var $terezka$elm_charts$Internal$Helpers$toDefault = F3(
	function (_default, items, index) {
		var dict = $elm$core$Dict$fromList(
			A2($elm$core$List$indexedMap, $elm$core$Tuple$pair, items));
		var numOfItems = $elm$core$Dict$size(dict);
		var itemIndex = index % numOfItems;
		return A2(
			$elm$core$Maybe$withDefault,
			_default,
			A2($elm$core$Dict$get, itemIndex, dict));
	});
var $terezka$elm_charts$Internal$Helpers$turquoise = '#22d2ba';
var $terezka$elm_charts$Internal$Helpers$yellow = '#FFCA00';
var $terezka$elm_charts$Internal$Helpers$toDefaultColor = A2(
	$terezka$elm_charts$Internal$Helpers$toDefault,
	$terezka$elm_charts$Internal$Helpers$pink,
	_List_fromArray(
		[$terezka$elm_charts$Internal$Helpers$purple, $terezka$elm_charts$Internal$Helpers$pink, $terezka$elm_charts$Internal$Helpers$blue, $terezka$elm_charts$Internal$Helpers$green, $terezka$elm_charts$Internal$Helpers$red, $terezka$elm_charts$Internal$Helpers$yellow, $terezka$elm_charts$Internal$Helpers$turquoise, $terezka$elm_charts$Internal$Helpers$orange, $terezka$elm_charts$Internal$Helpers$moss, $terezka$elm_charts$Internal$Helpers$brown]));
var $terezka$elm_charts$Internal$Legend$toBarLegends = F3(
	function (elIndex, barsAttrs, properties) {
		var toBarConfig = function (attrs) {
			return A2($terezka$elm_charts$Internal$Helpers$apply, attrs, $terezka$elm_charts$Internal$Svg$defaultBar);
		};
		var barsConfig = A2($terezka$elm_charts$Internal$Helpers$apply, barsAttrs, $terezka$elm_charts$Internal$Produce$defaultBars);
		var toBarLegend = F2(
			function (colorIndex, prop) {
				var rounding = A2($elm$core$Basics$max, barsConfig.nZ, barsConfig.nY);
				var defaultName = 'Property #' + $elm$core$String$fromInt(colorIndex + 1);
				var defaultColor = $terezka$elm_charts$Internal$Helpers$toDefaultColor(colorIndex);
				var defaultAttrs = _List_fromArray(
					[
						$terezka$elm_charts$Chart$Attributes$roundTop(rounding),
						$terezka$elm_charts$Chart$Attributes$roundBottom(rounding),
						$terezka$elm_charts$Chart$Attributes$color(defaultColor),
						$terezka$elm_charts$Chart$Attributes$border(defaultColor)
					]);
				var attrsOrg = _Utils_ap(defaultAttrs, prop.k);
				var productOrg = toBarConfig(attrsOrg);
				var attrs = _Utils_eq(productOrg.ep, defaultColor) ? _Utils_ap(
					attrsOrg,
					_List_fromArray(
						[
							$terezka$elm_charts$Chart$Attributes$border(productOrg.b0)
						])) : attrsOrg;
				return A2(
					$terezka$elm_charts$Internal$Legend$BarLegend,
					A2($elm$core$Maybe$withDefault, defaultName, prop.hH),
					attrs);
			});
		return A2(
			$elm$core$List$indexedMap,
			function (propIndex) {
				return toBarLegend(elIndex + propIndex);
			},
			A2($elm$core$List$concatMap, $terezka$elm_charts$Internal$Property$toConfigs, properties));
	});
var $terezka$elm_charts$Internal$Produce$toDefaultName = F2(
	function (index, name) {
		return A2(
			$elm$core$Maybe$withDefault,
			'Property #' + $elm$core$String$fromInt(index + 1),
			name);
	});
var $elm$html$Html$td = _VirtualDom_node('td');
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $terezka$elm_charts$Internal$Produce$tooltipRow = F3(
	function (color, title, text) {
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'color', color),
							A2($elm$html$Html$Attributes$style, 'padding', '0'),
							A2($elm$html$Html$Attributes$style, 'padding-right', '3px')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title + ':')
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'text-align', 'right'),
							A2($elm$html$Html$Attributes$style, 'padding', '0')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(text)
						]))
				]));
	});
var $terezka$elm_charts$Internal$Helpers$withSurround = F2(
	function (all, func) {
		var fold = F4(
			function (index, prev, acc, list) {
				fold:
				while (true) {
					if (list.b) {
						if (list.b.b) {
							var a = list.a;
							var _v1 = list.b;
							var b = _v1.a;
							var rest = _v1.b;
							var $temp$index = index + 1,
								$temp$prev = $elm$core$Maybe$Just(a),
								$temp$acc = _Utils_ap(
								acc,
								_List_fromArray(
									[
										A4(
										func,
										index,
										prev,
										a,
										$elm$core$Maybe$Just(b))
									])),
								$temp$list = A2($elm$core$List$cons, b, rest);
							index = $temp$index;
							prev = $temp$prev;
							acc = $temp$acc;
							list = $temp$list;
							continue fold;
						} else {
							var a = list.a;
							return _Utils_ap(
								acc,
								_List_fromArray(
									[
										A4(func, index, prev, a, $elm$core$Maybe$Nothing)
									]));
						}
					} else {
						return acc;
					}
				}
			});
		return A4(fold, 0, $elm$core$Maybe$Nothing, _List_Nil, all);
	});
var $terezka$elm_charts$Internal$Produce$toBarSeries = F4(
	function (elIndex, barsAttrs, properties, data) {
		var toBarConfig = function (attrs) {
			return A2($terezka$elm_charts$Internal$Helpers$apply, attrs, $terezka$elm_charts$Internal$Svg$defaultBar);
		};
		var barsConfig = A2($terezka$elm_charts$Internal$Helpers$apply, barsAttrs, $terezka$elm_charts$Internal$Produce$defaultBars);
		var toBarItem = F7(
			function (sections, barIndex, sectionIndex, section, colorIndex, dataIndex, bin) {
				var visual = section.c5(bin.k2);
				var value = section.pI(bin.k2);
				var start = bin.fO;
				var numOfSections = $elm$core$List$length(sections);
				var numOfBars = barsConfig.lK ? $elm$core$List$length(properties) : 1;
				var minY = (numOfSections > 1) ? $elm$core$Basics$max(0) : $elm$core$Basics$identity;
				var y1 = minY(
					A2($elm$core$Maybe$withDefault, 0, visual) - A2($elm$core$Maybe$withDefault, 0, value));
				var y2 = minY(
					A2($elm$core$Maybe$withDefault, 0, visual));
				var isSingle = numOfSections === 1;
				var isLast = _Utils_eq(sectionIndex, numOfSections - 1);
				var roundTop = (isSingle || isLast) ? barsConfig.nZ : 0;
				var isFirst = !sectionIndex;
				var roundBottom = (isSingle || isFirst) ? barsConfig.nY : 0;
				var end = bin.gZ;
				var length = end - start;
				var margin = length * barsConfig.bE;
				var spacing = length * barsConfig.ou;
				var width = ((length - (margin * 2)) - ((numOfBars - 1) * spacing)) / numOfBars;
				var offset = barsConfig.lK ? ((barIndex * width) + (barIndex * spacing)) : 0;
				var x1 = (start + margin) + offset;
				var x2 = ((start + margin) + offset) + width;
				var defaultColor = $terezka$elm_charts$Internal$Helpers$toDefaultColor(colorIndex);
				var defaultAttrs = _List_fromArray(
					[
						$terezka$elm_charts$Chart$Attributes$roundTop(roundTop),
						$terezka$elm_charts$Chart$Attributes$roundBottom(roundBottom),
						$terezka$elm_charts$Chart$Attributes$color(defaultColor),
						$terezka$elm_charts$Chart$Attributes$border(defaultColor)
					]);
				var attrs = _Utils_ap(
					defaultAttrs,
					_Utils_ap(
						section.k,
						A5(section.ls, barIndex, sectionIndex, dataIndex, section.hH, bin.k2)));
				var productOrg = toBarConfig(attrs);
				var product = function (p) {
					return _Utils_eq(p.ep, defaultColor) ? _Utils_update(
						p,
						{ep: p.b0}) : p;
				}(
					function (p) {
						var _v21 = p.eI;
						if (((!_v21.$) && (_v21.a.$ === 2)) && _v21.a.a.b) {
							var _v22 = _v21.a.a;
							var color = _v22.a;
							return _Utils_eq(p.b0, defaultColor) ? _Utils_update(
								p,
								{b0: color}) : p;
						} else {
							return p;
						}
					}(productOrg));
				return {
					kD: {
						nB: product,
						pg: $terezka$elm_charts$Internal$Item$Bar,
						d7: {
							ep: product.ep,
							U: product.U,
							b0: product.b0,
							k_: dataIndex,
							lg: elIndex,
							g7: section.ag(bin.k2),
							l1: colorIndex,
							mO: section.hH,
							nD: barIndex,
							ow: sectionIndex
						},
						pJ: {
							k2: bin.k2,
							mk: function () {
								if (!value.$) {
									return true;
								} else {
									return false;
								}
							}(),
							cv: start,
							c6: end,
							pS: A2($elm$core$Maybe$withDefault, 0, value)
						}
					},
					ph: function (c) {
						return _List_fromArray(
							[
								A3(
								$terezka$elm_charts$Internal$Produce$tooltipRow,
								c.d7.b0,
								A2($terezka$elm_charts$Internal$Produce$toDefaultName, colorIndex, c.d7.mO),
								section.ag(bin.k2))
							]);
					},
					pi: function (config) {
						return {
							cv: x1,
							c6: x2,
							pT: A2($elm$core$Basics$min, y1, y2),
							f4: A2($elm$core$Basics$max, y1, y2)
						};
					},
					pl: F2(
						function (_v20, config) {
							return {cv: x1, c6: x2, pT: y1, f4: y2};
						}),
					pm: F3(
						function (plane, config, position) {
							return A3($terezka$elm_charts$Internal$Svg$bar, plane, product, position);
						})
				};
			});
		var toSeriesItem = F6(
			function (bins, sections, barIndex, sectionIndex, section, colorIndex) {
				var _v13 = A2(
					$elm$core$List$indexedMap,
					A5(toBarItem, sections, barIndex, sectionIndex, section, colorIndex),
					bins);
				if (!_v13.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					var first = _v13.a;
					var rest = _v13.b;
					return $elm$core$Maybe$Just(
						{
							kD: {
								am: _Utils_Tuple2(first, rest)
							},
							ph: function (c) {
								return _List_fromArray(
									[
										A2(
										$elm$html$Html$table,
										_List_fromArray(
											[
												A2($elm$html$Html$Attributes$style, 'margin', '0')
											]),
										A2(
											$elm$core$List$concatMap,
											$terezka$elm_charts$Internal$Item$toHtml,
											function (_v14) {
												var x = _v14.a;
												var xs = _v14.b;
												return A2($elm$core$List$cons, x, xs);
											}(c.am)))
									]);
							},
							pi: function (c) {
								return A2(
									$terezka$elm_charts$Internal$Coordinates$foldPosition,
									$terezka$elm_charts$Internal$Item$getLimits,
									function (_v15) {
										var x = _v15.a;
										var xs = _v15.b;
										return A2($elm$core$List$cons, x, xs);
									}(c.am));
							},
							pl: F2(
								function (plane, c) {
									return A2(
										$terezka$elm_charts$Internal$Coordinates$foldPosition,
										$terezka$elm_charts$Internal$Item$getPosition(plane),
										function (_v16) {
											var x = _v16.a;
											var xs = _v16.b;
											return A2($elm$core$List$cons, x, xs);
										}(c.am));
								}),
							pm: F3(
								function (plane, c, _v17) {
									return A2(
										$elm$svg$Svg$g,
										_List_fromArray(
											[
												$elm$svg$Svg$Attributes$class('elm-charts__bar-series')
											]),
										A2(
											$elm$core$List$map,
											$terezka$elm_charts$Internal$Item$toSvg(plane),
											function (_v18) {
												var x = _v18.a;
												var xs = _v18.b;
												return A2($elm$core$List$cons, x, xs);
											}(c.am)));
								})
						});
				}
			});
		var toBin = F4(
			function (index, prevM, curr, nextM) {
				var _v0 = _Utils_Tuple2(barsConfig.cv, barsConfig.c6);
				if (_v0.a.$ === 1) {
					if (_v0.b.$ === 1) {
						var _v1 = _v0.a;
						var _v2 = _v0.b;
						return {k2: curr, gZ: (index + 1) + 0.5, fO: (index + 1) - 0.5};
					} else {
						var _v8 = _v0.a;
						var toEnd = _v0.b.a;
						var _v9 = _Utils_Tuple2(prevM, nextM);
						if (!_v9.a.$) {
							var prev = _v9.a.a;
							return {
								k2: curr,
								gZ: toEnd(curr),
								fO: toEnd(prev)
							};
						} else {
							if (!_v9.b.$) {
								var _v10 = _v9.a;
								var next = _v9.b.a;
								return {
									k2: curr,
									gZ: toEnd(curr),
									fO: toEnd(curr) - (toEnd(next) - toEnd(curr))
								};
							} else {
								var _v11 = _v9.a;
								var _v12 = _v9.b;
								return {
									k2: curr,
									gZ: toEnd(curr),
									fO: toEnd(curr) - 1
								};
							}
						}
					}
				} else {
					if (_v0.b.$ === 1) {
						var toStart = _v0.a.a;
						var _v3 = _v0.b;
						var _v4 = _Utils_Tuple2(prevM, nextM);
						if (!_v4.b.$) {
							var next = _v4.b.a;
							return {
								k2: curr,
								gZ: toStart(next),
								fO: toStart(curr)
							};
						} else {
							if (!_v4.a.$) {
								var prev = _v4.a.a;
								var _v5 = _v4.b;
								return {
									k2: curr,
									gZ: toStart(curr) + (toStart(curr) - toStart(prev)),
									fO: toStart(curr)
								};
							} else {
								var _v6 = _v4.a;
								var _v7 = _v4.b;
								return {
									k2: curr,
									gZ: toStart(curr) + 1,
									fO: toStart(curr)
								};
							}
						}
					} else {
						var toStart = _v0.a.a;
						var toEnd = _v0.b.a;
						return {
							k2: curr,
							gZ: toEnd(curr),
							fO: toStart(curr)
						};
					}
				}
			});
		return function (bins) {
			return A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				A2(
					$elm$core$List$indexedMap,
					F2(
						function (propIndex, f) {
							return f(elIndex + propIndex);
						}),
					$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (barIndex, stacks) {
									return A2(
										$elm$core$List$indexedMap,
										A3(toSeriesItem, bins, stacks, barIndex),
										$elm$core$List$reverse(stacks));
								}),
							A2($elm$core$List$map, $terezka$elm_charts$Internal$Property$toConfigs, properties)))));
		}(
			A2($terezka$elm_charts$Internal$Helpers$withSurround, data, toBin));
	});
var $terezka$elm_charts$Chart$barsMap = F4(
	function (mapData, edits, properties, data) {
		return $terezka$elm_charts$Chart$Indexed(
			function (index) {
				var legends_ = A3($terezka$elm_charts$Internal$Legend$toBarLegends, index, edits, properties);
				var items = A4($terezka$elm_charts$Internal$Produce$toBarSeries, index, edits, properties, data);
				var generalized = A2(
					$elm$core$List$map,
					$terezka$elm_charts$Internal$Item$map(mapData),
					A2($elm$core$List$concatMap, $terezka$elm_charts$Internal$Many$getGenerals, items));
				var bins = A2($terezka$elm_charts$Chart$Item$apply, $terezka$elm_charts$Chart$Item$bins, generalized);
				var toLimits = A2($elm$core$List$map, $terezka$elm_charts$Internal$Item$getLimits, bins);
				var barsConfig = A2($terezka$elm_charts$Internal$Helpers$apply, edits, $terezka$elm_charts$Internal$Produce$defaultBars);
				var toTicks = F2(
					function (plane, acc) {
						return _Utils_update(
							acc,
							{
								ac: _Utils_ap(
									acc.ac,
									barsConfig.lH ? A2(
										$elm$core$List$concatMap,
										A2(
											$elm$core$Basics$composeR,
											$terezka$elm_charts$Chart$Item$getLimits,
											function (pos) {
												return _List_fromArray(
													[pos.cv, pos.c6]);
											}),
										bins) : _List_Nil)
							});
					});
				return _Utils_Tuple2(
					A5(
						$terezka$elm_charts$Chart$BarsElement,
						toLimits,
						generalized,
						legends_,
						toTicks,
						function (plane) {
							return A2(
								$elm$svg$Svg$map,
								$elm$core$Basics$never,
								A2(
									$elm$svg$Svg$g,
									_List_fromArray(
										[
											$elm$svg$Svg$Attributes$class('elm-charts__bar-series')
										]),
									A2(
										$elm$core$List$map,
										$terezka$elm_charts$Internal$Item$toSvg(plane),
										items)));
						}),
					index + $elm$core$List$length(
						A2($elm$core$List$concatMap, $terezka$elm_charts$Internal$Property$toConfigs, properties)));
			});
	});
var $terezka$elm_charts$Chart$bars = F3(
	function (edits, properties, data) {
		return A4($terezka$elm_charts$Chart$barsMap, $elm$core$Basics$identity, edits, properties, data);
	});
var $terezka$elm_charts$Chart$eachCustom = F2(
	function (grouping, func) {
		return $terezka$elm_charts$Chart$SubElements(
			F2(
				function (p, items) {
					var processed = A2($terezka$elm_charts$Chart$Item$apply, grouping, items);
					return A2(
						$elm$core$List$concatMap,
						func(p),
						processed);
				}));
	});
var $terezka$elm_charts$Internal$Item$getDatum = function (_v0) {
	var item = _v0;
	return item.kD.pJ.k2;
};
var $terezka$elm_charts$Internal$Many$getData = function (_v0) {
	var group_ = _v0;
	return function (_v1) {
		var x = _v1.a;
		var xs = _v1.b;
		return $terezka$elm_charts$Internal$Item$getDatum(x);
	}(group_.kD.am);
};
var $terezka$elm_charts$Chart$Item$getOneData = $terezka$elm_charts$Internal$Many$getData;
var $terezka$elm_charts$Chart$binLabels = F2(
	function (toLabel, edits) {
		return A2(
			$terezka$elm_charts$Chart$eachCustom,
			A2($terezka$elm_charts$Chart$Item$andThen, $terezka$elm_charts$Chart$Item$bins, $terezka$elm_charts$Chart$Item$bars),
			F2(
				function (p, item) {
					var config = A2($terezka$elm_charts$Internal$Helpers$apply, edits, $terezka$elm_charts$Chart$defaultLabel);
					var text = function () {
						var _v1 = config.ag;
						if (!_v1.$) {
							var formatting = _v1.a;
							return formatting(item);
						} else {
							return toLabel(
								$terezka$elm_charts$Chart$Item$getOneData(item));
						}
					}();
					return _List_fromArray(
						[
							$terezka$elm_charts$Chart$svg(
							function (_v0) {
								return A4(
									$terezka$elm_charts$Internal$Svg$label,
									p,
									$terezka$elm_charts$Chart$toLabelFromItemLabel(config),
									_List_fromArray(
										[
											$elm$svg$Svg$text(text)
										]),
									A2(config.bb, p, item));
							})
						]);
				}));
	});
var $terezka$elm_charts$Internal$Svg$Event = F2(
	function (name, handler) {
		return {hd: handler, mO: name};
	});
var $elm$svg$Svg$clipPath = $elm$svg$Svg$trustedNode('clipPath');
var $debois$elm_dom$DOM$offsetHeight = A2($elm$json$Json$Decode$field, 'offsetHeight', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$offsetWidth = A2($elm$json$Json$Decode$field, 'offsetWidth', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$offsetLeft = A2($elm$json$Json$Decode$field, 'offsetLeft', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$offsetParent = F2(
	function (x, decoder) {
		return $elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$json$Json$Decode$field,
					'offsetParent',
					$elm$json$Json$Decode$null(x)),
					A2($elm$json$Json$Decode$field, 'offsetParent', decoder)
				]));
	});
var $debois$elm_dom$DOM$offsetTop = A2($elm$json$Json$Decode$field, 'offsetTop', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$scrollLeft = A2($elm$json$Json$Decode$field, 'scrollLeft', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$scrollTop = A2($elm$json$Json$Decode$field, 'scrollTop', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$position = F2(
	function (x, y) {
		return A2(
			$elm$json$Json$Decode$andThen,
			function (_v0) {
				var x_ = _v0.a;
				var y_ = _v0.b;
				return A2(
					$debois$elm_dom$DOM$offsetParent,
					_Utils_Tuple2(x_, y_),
					A2($debois$elm_dom$DOM$position, x_, y_));
			},
			A5(
				$elm$json$Json$Decode$map4,
				F4(
					function (scrollLeftP, scrollTopP, offsetLeftP, offsetTopP) {
						return _Utils_Tuple2((x + offsetLeftP) - scrollLeftP, (y + offsetTopP) - scrollTopP);
					}),
				$debois$elm_dom$DOM$scrollLeft,
				$debois$elm_dom$DOM$scrollTop,
				$debois$elm_dom$DOM$offsetLeft,
				$debois$elm_dom$DOM$offsetTop));
	});
var $debois$elm_dom$DOM$boundingClientRect = A4(
	$elm$json$Json$Decode$map3,
	F3(
		function (_v0, width, height) {
			var x = _v0.a;
			var y = _v0.b;
			return {he: height, e9: x, fY: y, ec: width};
		}),
	A2($debois$elm_dom$DOM$position, 0, 0),
	$debois$elm_dom$DOM$offsetWidth,
	$debois$elm_dom$DOM$offsetHeight);
var $elm$json$Json$Decode$lazy = function (thunk) {
	return A2(
		$elm$json$Json$Decode$andThen,
		thunk,
		$elm$json$Json$Decode$succeed(0));
};
var $debois$elm_dom$DOM$parentElement = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'parentElement', decoder);
};
function $terezka$elm_charts$Internal$Svg$cyclic$decodePosition() {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$debois$elm_dom$DOM$boundingClientRect,
				$elm$json$Json$Decode$lazy(
				function (_v0) {
					return $debois$elm_dom$DOM$parentElement(
						$terezka$elm_charts$Internal$Svg$cyclic$decodePosition());
				})
			]));
}
var $terezka$elm_charts$Internal$Svg$decodePosition = $terezka$elm_charts$Internal$Svg$cyclic$decodePosition();
$terezka$elm_charts$Internal$Svg$cyclic$decodePosition = function () {
	return $terezka$elm_charts$Internal$Svg$decodePosition;
};
var $terezka$elm_charts$Internal$Coordinates$toCartesianX = F2(
	function (plane, value) {
		return A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, value - plane.jv.mz) + plane.jv.hI;
	});
var $terezka$elm_charts$Internal$Coordinates$toCartesianY = F2(
	function (plane, value) {
		return ($terezka$elm_charts$Internal$Coordinates$range(plane.pS) - A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, value - plane.pS.mz)) + plane.pS.hI;
	});
var $terezka$elm_charts$Internal$Svg$fromSvg = F2(
	function (plane, point) {
		return {
			jv: A2($terezka$elm_charts$Internal$Coordinates$toCartesianX, plane, point.jv),
			pS: A2($terezka$elm_charts$Internal$Coordinates$toCartesianY, plane, point.pS)
		};
	});
var $debois$elm_dom$DOM$target = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'target', decoder);
};
var $terezka$elm_charts$Internal$Svg$decoder = F2(
	function (plane, toMsg) {
		var handle = F3(
			function (mouseX, mouseY, box) {
				var yPrev = plane.pS;
				var xPrev = plane.jv;
				var widthPercent = box.ec / plane.jv.z;
				var heightPercent = box.he / plane.pS.z;
				var newPlane = _Utils_update(
					plane,
					{
						jv: _Utils_update(
							xPrev,
							{z: box.ec, my: plane.jv.my * widthPercent, mz: plane.jv.mz * widthPercent}),
						pS: _Utils_update(
							yPrev,
							{z: box.he, my: plane.pS.my * heightPercent, mz: plane.pS.mz * heightPercent})
					});
				var searched = A2(
					$terezka$elm_charts$Internal$Svg$fromSvg,
					newPlane,
					{jv: mouseX - box.e9, pS: mouseY - box.fY});
				return A2(toMsg, newPlane, searched);
			});
		return A4(
			$elm$json$Json$Decode$map3,
			handle,
			A2($elm$json$Json$Decode$field, 'pageX', $elm$json$Json$Decode$float),
			A2($elm$json$Json$Decode$field, 'pageY', $elm$json$Json$Decode$float),
			$debois$elm_dom$DOM$target($terezka$elm_charts$Internal$Svg$decodePosition));
	});
var $elm$svg$Svg$Events$on = $elm$html$Html$Events$on;
var $elm$svg$Svg$rect = $elm$svg$Svg$trustedNode('rect');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $elm$svg$Svg$Attributes$x = _VirtualDom_attribute('x');
var $terezka$elm_charts$Internal$Svg$container = F5(
	function (plane, config, below, chartEls, above) {
		var toEvent = function (event) {
			return A2(
				$elm$svg$Svg$Events$on,
				event.mO,
				A2($terezka$elm_charts$Internal$Svg$decoder, plane, event.hd));
		};
		var svgAttrsSize = config.dV ? _List_fromArray(
			[
				$elm$svg$Svg$Attributes$viewBox(
				'0 0 ' + ($elm$core$String$fromFloat(plane.jv.z) + (' ' + $elm$core$String$fromFloat(plane.pS.z)))),
				A2($elm$html$Html$Attributes$style, 'display', 'block')
			]) : _List_fromArray(
			[
				$elm$svg$Svg$Attributes$width(
				$elm$core$String$fromFloat(plane.jv.z)),
				$elm$svg$Svg$Attributes$height(
				$elm$core$String$fromFloat(plane.pS.z)),
				A2($elm$html$Html$Attributes$style, 'display', 'block')
			]);
		var htmlAttrsSize = config.dV ? _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'width', '100%'),
				A2($elm$html$Html$Attributes$style, 'height', '100%')
			]) : _List_fromArray(
			[
				A2(
				$elm$html$Html$Attributes$style,
				'width',
				$elm$core$String$fromFloat(plane.jv.z) + 'px'),
				A2(
				$elm$html$Html$Attributes$style,
				'height',
				$elm$core$String$fromFloat(plane.pS.z) + 'px')
			]);
		var htmlAttrsDef = _List_fromArray(
			[
				$elm$html$Html$Attributes$class('elm-charts__container-inner')
			]);
		var htmlAttrs = _Utils_ap(
			config.dC,
			_Utils_ap(htmlAttrsDef, htmlAttrsSize));
		var chartPosition = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$x(
				$elm$core$String$fromFloat(plane.jv.mz)),
				$elm$svg$Svg$Attributes$y(
				$elm$core$String$fromFloat(plane.pS.mz)),
				$elm$svg$Svg$Attributes$width(
				$elm$core$String$fromFloat(
					$terezka$elm_charts$Internal$Coordinates$innerWidth(plane))),
				$elm$svg$Svg$Attributes$height(
				$elm$core$String$fromFloat(
					$terezka$elm_charts$Internal$Coordinates$innerHeight(plane))),
				$elm$svg$Svg$Attributes$fill('transparent')
			]);
		var clipPathDefs = A2(
			$elm$svg$Svg$defs,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$clipPath,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$id(
							$terezka$elm_charts$Internal$Coordinates$toId(plane))
						]),
					_List_fromArray(
						[
							A2($elm$svg$Svg$rect, chartPosition, _List_Nil)
						]))
				]));
		var catcher = A2(
			$elm$svg$Svg$rect,
			_Utils_ap(
				chartPosition,
				A2($elm$core$List$map, toEvent, config.lm)),
			_List_Nil);
		var chart = A2(
			$elm$svg$Svg$svg,
			_Utils_ap(svgAttrsSize, config.k),
			_Utils_ap(
				_List_fromArray(
					[clipPathDefs]),
				_Utils_ap(
					chartEls,
					_List_fromArray(
						[catcher]))));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('elm-charts__container'),
					A2($elm$html$Html$Attributes$style, 'position', 'relative')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					htmlAttrs,
					_Utils_ap(
						below,
						_Utils_ap(
							_List_fromArray(
								[chart]),
							above)))
				]));
	});
var $terezka$elm_charts$Chart$Attributes$lowest = F3(
	function (v, edit, b) {
		return _Utils_update(
			b,
			{
				hI: A3(edit, v, b.hI, b.k0)
			});
	});
var $terezka$elm_charts$Chart$Attributes$orLower = F3(
	function (least, real, _v0) {
		return (_Utils_cmp(real, least) > 0) ? least : real;
	});
var $terezka$elm_charts$Chart$definePlane = F2(
	function (config, elements) {
		var width = A2($elm$core$Basics$max, 1, (config.ec - config.aD.e9) - config.aD.fz);
		var toLimit = F5(
			function (length, marginMin, marginMax, min, max) {
				return {k$: max, k0: min, z: length, my: marginMax, mz: marginMin, mB: max, hI: min};
			});
		var height = A2($elm$core$Basics$max, 1, (config.he - config.aD.er) - config.aD.fY);
		var fixSingles = function (bs) {
			return _Utils_eq(bs.hI, bs.mB) ? _Utils_update(
				bs,
				{mB: bs.hI + 10}) : bs;
		};
		var collectLimits = F2(
			function (el, acc) {
				switch (el.$) {
					case 0:
						return acc;
					case 1:
						var lims = el.a;
						return _Utils_ap(acc, lims);
					case 2:
						var lims = el.a;
						return _Utils_ap(acc, lims);
					case 3:
						return acc;
					case 4:
						return acc;
					case 5:
						return acc;
					case 6:
						return acc;
					case 7:
						return acc;
					case 8:
						return acc;
					case 9:
						return acc;
					case 10:
						return acc;
					case 11:
						var subs = el.a;
						return A3($elm$core$List$foldl, collectLimits, acc, subs);
					case 12:
						return acc;
					default:
						return acc;
				}
			});
		var limits_ = function (pos) {
			return function (_v3) {
				var x = _v3.jv;
				var y = _v3.pS;
				return {
					jv: fixSingles(x),
					pS: fixSingles(y)
				};
			}(
				{
					jv: A5(toLimit, width, config.bE.e9, config.bE.fz, pos.cv, pos.c6),
					pS: A5(toLimit, height, config.bE.fY, config.bE.er, pos.pT, pos.f4)
				});
		}(
			A2(
				$terezka$elm_charts$Internal$Coordinates$foldPosition,
				$elm$core$Basics$identity,
				A3($elm$core$List$foldl, collectLimits, _List_Nil, elements)));
		var calcRange = function () {
			var _v2 = config.fw;
			if (!_v2.b) {
				return limits_.jv;
			} else {
				var some = _v2;
				return A3(
					$elm$core$List$foldl,
					F2(
						function (f, b) {
							return f(b);
						}),
					limits_.jv,
					some);
			}
		}();
		var calcDomain = function () {
			var _v1 = config.eL;
			if (!_v1.b) {
				return A3($terezka$elm_charts$Chart$Attributes$lowest, 0, $terezka$elm_charts$Chart$Attributes$orLower, limits_.pS);
			} else {
				var some = _v1;
				return A3(
					$elm$core$List$foldl,
					F2(
						function (f, b) {
							return f(b);
						}),
					limits_.pS,
					some);
			}
		}();
		var unpadded = {jv: calcRange, pS: calcDomain};
		var scalePadX = $terezka$elm_charts$Internal$Coordinates$scaleCartesianX(unpadded);
		var xMax = calcRange.mB + scalePadX(config.aD.fz);
		var xMin = calcRange.hI - scalePadX(config.aD.e9);
		var scalePadY = $terezka$elm_charts$Internal$Coordinates$scaleCartesianY(unpadded);
		var yMax = calcDomain.mB + scalePadY(config.aD.fY);
		var yMin = calcDomain.hI - scalePadY(config.aD.er);
		return {
			jv: _Utils_update(
				calcRange,
				{
					z: config.ec,
					mB: A2($elm$core$Basics$max, xMin, xMax),
					hI: A2($elm$core$Basics$min, xMin, xMax)
				}),
			pS: _Utils_update(
				calcDomain,
				{
					z: config.he,
					mB: A2($elm$core$Basics$max, yMin, yMax),
					hI: A2($elm$core$Basics$min, yMin, yMax)
				})
		};
	});
var $terezka$elm_charts$Chart$getItems = F2(
	function (plane, elements) {
		var toItems = F2(
			function (el, acc) {
				switch (el.$) {
					case 0:
						return acc;
					case 1:
						var items = el.b;
						return _Utils_ap(acc, items);
					case 2:
						var items = el.b;
						return _Utils_ap(acc, items);
					case 3:
						var item = el.a;
						return _Utils_ap(
							acc,
							_List_fromArray(
								[item]));
					case 4:
						var func = el.a;
						return acc;
					case 5:
						return acc;
					case 6:
						return acc;
					case 7:
						return acc;
					case 8:
						return acc;
					case 9:
						return acc;
					case 10:
						return acc;
					case 11:
						var subs = el.a;
						return A3($elm$core$List$foldl, toItems, acc, subs);
					case 12:
						return acc;
					default:
						return acc;
				}
			});
		return A3($elm$core$List$foldl, toItems, _List_Nil, elements);
	});
var $terezka$elm_charts$Chart$getLegends = function (elements) {
	var toLegends = F2(
		function (el, acc) {
			switch (el.$) {
				case 0:
					return acc;
				case 1:
					var legends_ = el.c;
					return _Utils_ap(acc, legends_);
				case 2:
					var legends_ = el.c;
					return _Utils_ap(acc, legends_);
				case 3:
					return acc;
				case 4:
					return acc;
				case 5:
					return acc;
				case 6:
					return acc;
				case 7:
					return acc;
				case 8:
					return acc;
				case 9:
					return acc;
				case 10:
					return acc;
				case 11:
					var subs = el.a;
					return A3($elm$core$List$foldl, toLegends, acc, subs);
				case 12:
					return acc;
				default:
					return acc;
			}
		});
	return A3($elm$core$List$foldl, toLegends, _List_Nil, elements);
};
var $terezka$elm_charts$Chart$TickValues = F4(
	function (xAxis, yAxis, xs, ys) {
		return {c7: xAxis, ac: xs, c8: yAxis, ap: ys};
	});
var $terezka$elm_charts$Chart$getTickValues = F3(
	function (plane, items, elements) {
		var toValues = F2(
			function (el, acc) {
				switch (el.$) {
					case 0:
						return acc;
					case 1:
						return acc;
					case 2:
						var func = el.d;
						return A2(func, plane, acc);
					case 3:
						var func = el.b;
						return acc;
					case 4:
						var func = el.a;
						return A2(func, plane, acc);
					case 5:
						var func = el.a;
						return A2(func, plane, acc);
					case 6:
						var toC = el.a;
						var func = el.b;
						return A3(
							func,
							plane,
							toC(plane),
							acc);
					case 7:
						var toC = el.a;
						var func = el.b;
						return A3(
							func,
							plane,
							toC(plane),
							acc);
					case 8:
						var toC = el.a;
						var func = el.b;
						return A3(
							func,
							plane,
							toC(plane),
							acc);
					case 10:
						var func = el.a;
						return A3(
							$elm$core$List$foldl,
							toValues,
							acc,
							A2(func, plane, items));
					case 9:
						return acc;
					case 11:
						var subs = el.a;
						return A3($elm$core$List$foldl, toValues, acc, subs);
					case 12:
						return acc;
					default:
						return acc;
				}
			});
		return A3(
			$elm$core$List$foldl,
			toValues,
			A4($terezka$elm_charts$Chart$TickValues, _List_Nil, _List_Nil, _List_Nil, _List_Nil),
			elements);
	});
var $terezka$elm_charts$Chart$GridElement = function (a) {
	return {$: 9, a: a};
};
var $terezka$elm_charts$Internal$Svg$Circle = 0;
var $terezka$elm_charts$Chart$Attributes$circle = function (config) {
	return _Utils_update(
		config,
		{
			ck: $elm$core$Maybe$Just(0)
		});
};
var $terezka$elm_charts$Internal$Helpers$darkGray = 'rgb(200 200 200)';
var $terezka$elm_charts$Chart$Attributes$dashed = F2(
	function (value, config) {
		return _Utils_update(
			config,
			{$7: value});
	});
var $terezka$elm_charts$Internal$Svg$defaultDot = {ep: '', U: 0, b0: $terezka$elm_charts$Internal$Helpers$pink, y: false, lQ: 0, lR: '', lS: 5, aB: 1, ck: $elm$core$Maybe$Nothing, iT: 6};
var $terezka$elm_charts$Internal$Svg$isWithinPlane = F3(
	function (plane, x, y) {
		return _Utils_eq(
			A3($elm$core$Basics$clamp, plane.jv.hI, plane.jv.mB, x),
			x) && _Utils_eq(
			A3($elm$core$Basics$clamp, plane.pS.hI, plane.pS.mB, y),
			y);
	});
var $elm$core$Basics$sqrt = _Basics_sqrt;
var $terezka$elm_charts$Internal$Svg$plusPath = F4(
	function (area_, off, x_, y_) {
		var side = $elm$core$Basics$sqrt(area_ / 4) + off;
		var r6 = side / 2;
		var r3 = side;
		return A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[
					'M' + ($elm$core$String$fromFloat(x_ - r6) + (' ' + $elm$core$String$fromFloat(((y_ - r3) - r6) + off))),
					'v' + $elm$core$String$fromFloat(r3 - off),
					'h' + $elm$core$String$fromFloat((-r3) + off),
					'v' + $elm$core$String$fromFloat(r3),
					'h' + $elm$core$String$fromFloat(r3 - off),
					'v' + $elm$core$String$fromFloat(r3 - off),
					'h' + $elm$core$String$fromFloat(r3),
					'v' + $elm$core$String$fromFloat((-r3) + off),
					'h' + $elm$core$String$fromFloat(r3 - off),
					'v' + $elm$core$String$fromFloat(-r3),
					'h' + $elm$core$String$fromFloat((-r3) + off),
					'v' + $elm$core$String$fromFloat((-r3) + off),
					'h' + $elm$core$String$fromFloat(-r3),
					'v' + $elm$core$String$fromFloat(r3 - off)
				]));
	});
var $elm$core$Basics$tan = _Basics_tan;
var $terezka$elm_charts$Internal$Svg$trianglePath = F4(
	function (area_, off, x_, y_) {
		var side = $elm$core$Basics$sqrt(
			(area_ * 4) / $elm$core$Basics$sqrt(3)) + (off * $elm$core$Basics$sqrt(3));
		var height = ($elm$core$Basics$sqrt(3) * side) / 2;
		var fromMiddle = height - (($elm$core$Basics$tan(
			$elm$core$Basics$degrees(30)) * side) / 2);
		return A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[
					'M' + ($elm$core$String$fromFloat(x_) + (' ' + $elm$core$String$fromFloat(y_ - fromMiddle))),
					'l' + ($elm$core$String$fromFloat((-side) / 2) + (' ' + $elm$core$String$fromFloat(height))),
					'h' + $elm$core$String$fromFloat(side),
					'z'
				]));
	});
var $terezka$elm_charts$Internal$Svg$dot = F5(
	function (plane, toX, toY, config, datum_) {
		var yOrg = toY(datum_);
		var y_ = A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, yOrg);
		var xOrg = toX(datum_);
		var x_ = A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, xOrg);
		var styleAttrs = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$stroke(
				(config.ep === '') ? config.b0 : config.ep),
				$elm$svg$Svg$Attributes$strokeWidth(
				$elm$core$String$fromFloat(config.U)),
				$elm$svg$Svg$Attributes$fillOpacity(
				$elm$core$String$fromFloat(config.aB)),
				$elm$svg$Svg$Attributes$fill(config.b0),
				$elm$svg$Svg$Attributes$class('elm-charts__dot'),
				config.y ? $terezka$elm_charts$Internal$Svg$withinChartArea(plane) : $elm$svg$Svg$Attributes$class('')
			]);
		var showDot = A3($terezka$elm_charts$Internal$Svg$isWithinPlane, plane, xOrg, yOrg) || config.y;
		var highlightColor = (config.lR === '') ? config.b0 : config.lR;
		var highlightAttrs = _List_fromArray(
			[
				$elm$svg$Svg$Attributes$stroke(highlightColor),
				$elm$svg$Svg$Attributes$strokeWidth(
				$elm$core$String$fromFloat(config.lS)),
				$elm$svg$Svg$Attributes$strokeOpacity(
				$elm$core$String$fromFloat(config.lQ)),
				$elm$svg$Svg$Attributes$fill('transparent'),
				$elm$svg$Svg$Attributes$class('elm-charts__dot-highlight')
			]);
		var view = F3(
			function (toEl, highlightOff, toAttrs) {
				return (config.lQ > 0) ? A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('elm-charts__dot-container')
						]),
					_List_fromArray(
						[
							A2(
							toEl,
							_Utils_ap(
								toAttrs(highlightOff),
								highlightAttrs),
							_List_Nil),
							A2(
							toEl,
							_Utils_ap(
								toAttrs(0),
								styleAttrs),
							_List_Nil)
						])) : A2(
					toEl,
					_Utils_ap(
						toAttrs(0),
						styleAttrs),
					_List_Nil);
			});
		var area_ = (2 * $elm$core$Basics$pi) * config.iT;
		if (!showDot) {
			return $elm$svg$Svg$text('');
		} else {
			var _v0 = config.ck;
			if (_v0.$ === 1) {
				return $elm$svg$Svg$text('');
			} else {
				switch (_v0.a) {
					case 0:
						var _v1 = _v0.a;
						return A3(
							view,
							$elm$svg$Svg$circle,
							config.lS / 2,
							function (off) {
								var radius = $elm$core$Basics$sqrt(area_ / $elm$core$Basics$pi);
								return _List_fromArray(
									[
										$elm$svg$Svg$Attributes$cx(
										$elm$core$String$fromFloat(x_)),
										$elm$svg$Svg$Attributes$cy(
										$elm$core$String$fromFloat(y_)),
										$elm$svg$Svg$Attributes$r(
										$elm$core$String$fromFloat(radius + off))
									]);
							});
					case 1:
						var _v2 = _v0.a;
						return A3(
							view,
							$elm$svg$Svg$path,
							config.lS,
							function (off) {
								return _List_fromArray(
									[
										$elm$svg$Svg$Attributes$d(
										A4($terezka$elm_charts$Internal$Svg$trianglePath, area_, off, x_, y_))
									]);
							});
					case 2:
						var _v3 = _v0.a;
						return A3(
							view,
							$elm$svg$Svg$rect,
							config.lS,
							function (off) {
								var side = $elm$core$Basics$sqrt(area_);
								var sideOff = side + off;
								return _List_fromArray(
									[
										$elm$svg$Svg$Attributes$x(
										$elm$core$String$fromFloat(x_ - (sideOff / 2))),
										$elm$svg$Svg$Attributes$y(
										$elm$core$String$fromFloat(y_ - (sideOff / 2))),
										$elm$svg$Svg$Attributes$width(
										$elm$core$String$fromFloat(sideOff)),
										$elm$svg$Svg$Attributes$height(
										$elm$core$String$fromFloat(sideOff))
									]);
							});
					case 3:
						var _v4 = _v0.a;
						return A3(
							view,
							$elm$svg$Svg$rect,
							config.lS,
							function (off) {
								var side = $elm$core$Basics$sqrt(area_);
								var sideOff = side + off;
								return _List_fromArray(
									[
										$elm$svg$Svg$Attributes$x(
										$elm$core$String$fromFloat(x_ - (sideOff / 2))),
										$elm$svg$Svg$Attributes$y(
										$elm$core$String$fromFloat(y_ - (sideOff / 2))),
										$elm$svg$Svg$Attributes$width(
										$elm$core$String$fromFloat(sideOff)),
										$elm$svg$Svg$Attributes$height(
										$elm$core$String$fromFloat(sideOff)),
										$elm$svg$Svg$Attributes$transform(
										'rotate(45 ' + ($elm$core$String$fromFloat(x_) + (' ' + ($elm$core$String$fromFloat(y_) + ')'))))
									]);
							});
					case 4:
						var _v5 = _v0.a;
						return A3(
							view,
							$elm$svg$Svg$path,
							config.lS,
							function (off) {
								return _List_fromArray(
									[
										$elm$svg$Svg$Attributes$d(
										A4($terezka$elm_charts$Internal$Svg$plusPath, area_, off, x_, y_)),
										$elm$svg$Svg$Attributes$transform(
										'rotate(45 ' + ($elm$core$String$fromFloat(x_) + (' ' + ($elm$core$String$fromFloat(y_) + ')'))))
									]);
							});
					default:
						var _v6 = _v0.a;
						return A3(
							view,
							$elm$svg$Svg$path,
							config.lS,
							function (off) {
								return _List_fromArray(
									[
										$elm$svg$Svg$Attributes$d(
										A4($terezka$elm_charts$Internal$Svg$plusPath, area_, off, x_, y_))
									]);
							});
				}
			}
		}
	});
var $terezka$elm_charts$Chart$Svg$dot = F4(
	function (plane, toX, toY, edits) {
		return A4(
			$terezka$elm_charts$Internal$Svg$dot,
			plane,
			toX,
			toY,
			A2($terezka$elm_charts$Internal$Helpers$apply, edits, $terezka$elm_charts$Internal$Svg$defaultDot));
	});
var $terezka$elm_charts$Internal$Helpers$gray = '#EFF2FA';
var $terezka$elm_charts$Internal$Svg$defaultLine = {k: _List_Nil, kk: false, b0: 'rgb(210, 210, 210)', $7: _List_Nil, m: false, y: false, aB: 1, o9: -90, pa: 0, ec: 1, cv: $elm$core$Maybe$Nothing, c6: $elm$core$Maybe$Nothing, pR: $elm$core$Maybe$Nothing, p: 0, pT: $elm$core$Maybe$Nothing, f4: $elm$core$Maybe$Nothing, pU: $elm$core$Maybe$Nothing, q: 0};
var $terezka$elm_charts$Internal$Svg$lengthInCartesianX = $terezka$elm_charts$Internal$Coordinates$scaleCartesianX;
var $terezka$elm_charts$Internal$Svg$lengthInCartesianY = $terezka$elm_charts$Internal$Coordinates$scaleCartesianY;
var $elm$core$Basics$sin = _Basics_sin;
var $elm$svg$Svg$Attributes$strokeDasharray = _VirtualDom_attribute('stroke-dasharray');
var $terezka$elm_charts$Internal$Svg$line = F2(
	function (plane, config) {
		var angle = $elm$core$Basics$degrees(config.o9);
		var _v0 = function () {
			var _v3 = _Utils_Tuple3(
				_Utils_Tuple2(config.cv, config.c6),
				_Utils_Tuple2(config.pT, config.f4),
				_Utils_Tuple2(config.pR, config.pU));
			if (!_v3.a.a.$) {
				if (!_v3.a.b.$) {
					if (_v3.b.a.$ === 1) {
						if (_v3.b.b.$ === 1) {
							var _v4 = _v3.a;
							var a = _v4.a.a;
							var b = _v4.b.a;
							var _v5 = _v3.b;
							var _v6 = _v5.a;
							var _v7 = _v5.b;
							return _Utils_Tuple2(
								_Utils_Tuple2(a, b),
								_Utils_Tuple2(plane.pS.hI, plane.pS.hI));
						} else {
							var _v38 = _v3.a;
							var a = _v38.a.a;
							var b = _v38.b.a;
							var _v39 = _v3.b;
							var _v40 = _v39.a;
							var c = _v39.b.a;
							return _Utils_Tuple2(
								_Utils_Tuple2(a, b),
								_Utils_Tuple2(c, c));
						}
					} else {
						if (_v3.b.b.$ === 1) {
							var _v41 = _v3.a;
							var a = _v41.a.a;
							var b = _v41.b.a;
							var _v42 = _v3.b;
							var c = _v42.a.a;
							var _v43 = _v42.b;
							return _Utils_Tuple2(
								_Utils_Tuple2(a, b),
								_Utils_Tuple2(c, c));
						} else {
							return _Utils_Tuple2(
								_Utils_Tuple2(
									A2($elm$core$Maybe$withDefault, plane.jv.hI, config.cv),
									A2($elm$core$Maybe$withDefault, plane.jv.mB, config.c6)),
								_Utils_Tuple2(
									A2($elm$core$Maybe$withDefault, plane.pS.hI, config.pT),
									A2($elm$core$Maybe$withDefault, plane.pS.mB, config.f4)));
						}
					}
				} else {
					if (_v3.b.a.$ === 1) {
						if (_v3.b.b.$ === 1) {
							var _v8 = _v3.a;
							var a = _v8.a.a;
							var _v9 = _v8.b;
							var _v10 = _v3.b;
							var _v11 = _v10.a;
							var _v12 = _v10.b;
							return _Utils_Tuple2(
								_Utils_Tuple2(a, a),
								_Utils_Tuple2(plane.pS.hI, plane.pS.mB));
						} else {
							if (!_v3.c.a.$) {
								if (!_v3.c.b.$) {
									var _v51 = _v3.a;
									var a = _v51.a.a;
									var _v52 = _v51.b;
									var _v53 = _v3.b;
									var _v54 = _v53.a;
									var b = _v53.b.a;
									var _v55 = _v3.c;
									var xOff = _v55.a.a;
									var yOff = _v55.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								} else {
									var _v56 = _v3.a;
									var a = _v56.a.a;
									var _v57 = _v56.b;
									var _v58 = _v3.b;
									var _v59 = _v58.a;
									var b = _v58.b.a;
									var _v60 = _v3.c;
									var xOff = _v60.a.a;
									var _v61 = _v60.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(b, b));
								}
							} else {
								if (_v3.c.b.$ === 1) {
									var _v44 = _v3.a;
									var a = _v44.a.a;
									var _v45 = _v44.b;
									var _v46 = _v3.b;
									var _v47 = _v46.a;
									var b = _v46.b.a;
									var _v48 = _v3.c;
									var _v49 = _v48.a;
									var _v50 = _v48.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, plane.jv.mB),
										_Utils_Tuple2(b, b));
								} else {
									var _v62 = _v3.a;
									var a = _v62.a.a;
									var _v63 = _v62.b;
									var _v64 = _v3.b;
									var _v65 = _v64.a;
									var b = _v64.b.a;
									var _v66 = _v3.c;
									var _v67 = _v66.a;
									var yOff = _v66.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, a),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								}
							}
						}
					} else {
						if (!_v3.b.b.$) {
							var _v35 = _v3.a;
							var c = _v35.a.a;
							var _v36 = _v35.b;
							var _v37 = _v3.b;
							var a = _v37.a.a;
							var b = _v37.b.a;
							return _Utils_Tuple2(
								_Utils_Tuple2(c, c),
								_Utils_Tuple2(a, b));
						} else {
							if (!_v3.c.a.$) {
								if (!_v3.c.b.$) {
									var _v75 = _v3.a;
									var a = _v75.a.a;
									var _v76 = _v75.b;
									var _v77 = _v3.b;
									var b = _v77.a.a;
									var _v78 = _v77.b;
									var _v79 = _v3.c;
									var xOff = _v79.a.a;
									var yOff = _v79.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								} else {
									var _v80 = _v3.a;
									var a = _v80.a.a;
									var _v81 = _v80.b;
									var _v82 = _v3.b;
									var b = _v82.a.a;
									var _v83 = _v82.b;
									var _v84 = _v3.c;
									var xOff = _v84.a.a;
									var _v85 = _v84.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(b, b));
								}
							} else {
								if (_v3.c.b.$ === 1) {
									var _v68 = _v3.a;
									var a = _v68.a.a;
									var _v69 = _v68.b;
									var _v70 = _v3.b;
									var b = _v70.a.a;
									var _v71 = _v70.b;
									var _v72 = _v3.c;
									var _v73 = _v72.a;
									var _v74 = _v72.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, plane.jv.mB),
										_Utils_Tuple2(b, b));
								} else {
									var _v86 = _v3.a;
									var a = _v86.a.a;
									var _v87 = _v86.b;
									var _v88 = _v3.b;
									var b = _v88.a.a;
									var _v89 = _v88.b;
									var _v90 = _v3.c;
									var _v91 = _v90.a;
									var yOff = _v90.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, a),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								}
							}
						}
					}
				}
			} else {
				if (!_v3.a.b.$) {
					if (_v3.b.a.$ === 1) {
						if (_v3.b.b.$ === 1) {
							var _v13 = _v3.a;
							var _v14 = _v13.a;
							var b = _v13.b.a;
							var _v15 = _v3.b;
							var _v16 = _v15.a;
							var _v17 = _v15.b;
							return _Utils_Tuple2(
								_Utils_Tuple2(b, b),
								_Utils_Tuple2(plane.pS.hI, plane.pS.mB));
						} else {
							if (!_v3.c.a.$) {
								if (!_v3.c.b.$) {
									var _v99 = _v3.a;
									var _v100 = _v99.a;
									var a = _v99.b.a;
									var _v101 = _v3.b;
									var _v102 = _v101.a;
									var b = _v101.b.a;
									var _v103 = _v3.c;
									var xOff = _v103.a.a;
									var yOff = _v103.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								} else {
									var _v104 = _v3.a;
									var _v105 = _v104.a;
									var a = _v104.b.a;
									var _v106 = _v3.b;
									var _v107 = _v106.a;
									var b = _v106.b.a;
									var _v108 = _v3.c;
									var xOff = _v108.a.a;
									var _v109 = _v108.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(b, b));
								}
							} else {
								if (_v3.c.b.$ === 1) {
									var _v92 = _v3.a;
									var _v93 = _v92.a;
									var a = _v92.b.a;
									var _v94 = _v3.b;
									var _v95 = _v94.a;
									var b = _v94.b.a;
									var _v96 = _v3.c;
									var _v97 = _v96.a;
									var _v98 = _v96.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, plane.jv.mB),
										_Utils_Tuple2(b, b));
								} else {
									var _v110 = _v3.a;
									var _v111 = _v110.a;
									var a = _v110.b.a;
									var _v112 = _v3.b;
									var _v113 = _v112.a;
									var b = _v112.b.a;
									var _v114 = _v3.c;
									var _v115 = _v114.a;
									var yOff = _v114.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, a),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								}
							}
						}
					} else {
						if (!_v3.b.b.$) {
							var _v32 = _v3.a;
							var _v33 = _v32.a;
							var c = _v32.b.a;
							var _v34 = _v3.b;
							var a = _v34.a.a;
							var b = _v34.b.a;
							return _Utils_Tuple2(
								_Utils_Tuple2(c, c),
								_Utils_Tuple2(a, b));
						} else {
							if (!_v3.c.a.$) {
								if (!_v3.c.b.$) {
									var _v123 = _v3.a;
									var _v124 = _v123.a;
									var a = _v123.b.a;
									var _v125 = _v3.b;
									var b = _v125.a.a;
									var _v126 = _v125.b;
									var _v127 = _v3.c;
									var xOff = _v127.a.a;
									var yOff = _v127.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								} else {
									var _v128 = _v3.a;
									var _v129 = _v128.a;
									var a = _v128.b.a;
									var _v130 = _v3.b;
									var b = _v130.a.a;
									var _v131 = _v130.b;
									var _v132 = _v3.c;
									var xOff = _v132.a.a;
									var _v133 = _v132.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(
											a,
											a + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianX, plane, xOff)),
										_Utils_Tuple2(b, b));
								}
							} else {
								if (_v3.c.b.$ === 1) {
									var _v116 = _v3.a;
									var _v117 = _v116.a;
									var a = _v116.b.a;
									var _v118 = _v3.b;
									var b = _v118.a.a;
									var _v119 = _v118.b;
									var _v120 = _v3.c;
									var _v121 = _v120.a;
									var _v122 = _v120.b;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, plane.jv.mB),
										_Utils_Tuple2(b, b));
								} else {
									var _v134 = _v3.a;
									var _v135 = _v134.a;
									var a = _v134.b.a;
									var _v136 = _v3.b;
									var b = _v136.a.a;
									var _v137 = _v136.b;
									var _v138 = _v3.c;
									var _v139 = _v138.a;
									var yOff = _v138.b.a;
									return _Utils_Tuple2(
										_Utils_Tuple2(a, a),
										_Utils_Tuple2(
											b,
											b + A2($terezka$elm_charts$Internal$Coordinates$scaleCartesianY, plane, yOff)));
								}
							}
						}
					}
				} else {
					if (!_v3.b.a.$) {
						if (!_v3.b.b.$) {
							var _v18 = _v3.a;
							var _v19 = _v18.a;
							var _v20 = _v18.b;
							var _v21 = _v3.b;
							var a = _v21.a.a;
							var b = _v21.b.a;
							return _Utils_Tuple2(
								_Utils_Tuple2(plane.jv.hI, plane.jv.hI),
								_Utils_Tuple2(a, b));
						} else {
							var _v22 = _v3.a;
							var _v23 = _v22.a;
							var _v24 = _v22.b;
							var _v25 = _v3.b;
							var a = _v25.a.a;
							var _v26 = _v25.b;
							return _Utils_Tuple2(
								_Utils_Tuple2(plane.jv.hI, plane.jv.mB),
								_Utils_Tuple2(a, a));
						}
					} else {
						if (!_v3.b.b.$) {
							var _v27 = _v3.a;
							var _v28 = _v27.a;
							var _v29 = _v27.b;
							var _v30 = _v3.b;
							var _v31 = _v30.a;
							var b = _v30.b.a;
							return _Utils_Tuple2(
								_Utils_Tuple2(plane.jv.hI, plane.jv.mB),
								_Utils_Tuple2(b, b));
						} else {
							var _v140 = _v3.a;
							var _v141 = _v140.a;
							var _v142 = _v140.b;
							var _v143 = _v3.b;
							var _v144 = _v143.a;
							var _v145 = _v143.b;
							return _Utils_Tuple2(
								_Utils_Tuple2(plane.jv.hI, plane.jv.mB),
								_Utils_Tuple2(plane.pS.hI, plane.pS.mB));
						}
					}
				}
			}
		}();
		var _v1 = _v0.a;
		var x1 = _v1.a;
		var x2 = _v1.b;
		var _v2 = _v0.b;
		var y1 = _v2.a;
		var y2 = _v2.b;
		var x1_ = x1 + A2($terezka$elm_charts$Internal$Svg$lengthInCartesianX, plane, config.p);
		var x2_ = x2 + A2($terezka$elm_charts$Internal$Svg$lengthInCartesianX, plane, config.p);
		var y1_ = y1 - A2($terezka$elm_charts$Internal$Svg$lengthInCartesianY, plane, config.q);
		var y2_ = y2 - A2($terezka$elm_charts$Internal$Svg$lengthInCartesianY, plane, config.q);
		var _v146 = (config.pa > 0) ? _Utils_Tuple2(
			A2(
				$terezka$elm_charts$Internal$Svg$lengthInCartesianX,
				plane,
				$elm$core$Basics$cos(angle) * config.pa),
			A2(
				$terezka$elm_charts$Internal$Svg$lengthInCartesianY,
				plane,
				$elm$core$Basics$sin(angle) * config.pa)) : _Utils_Tuple2(0, 0);
		var tickOffsetX = _v146.a;
		var tickOffsetY = _v146.b;
		var cmds = config.m ? _Utils_ap(
			(config.pa > 0) ? _List_fromArray(
				[
					A2($terezka$elm_charts$Internal$Commands$Move, x2_ + tickOffsetX, y2_ + tickOffsetY),
					A2($terezka$elm_charts$Internal$Commands$Line, x2_, y2_)
				]) : _List_fromArray(
				[
					A2($terezka$elm_charts$Internal$Commands$Move, x2_, y2_)
				]),
			_Utils_ap(
				config.kk ? _List_fromArray(
					[
						A2($terezka$elm_charts$Internal$Commands$Line, x2_, y1_),
						A2($terezka$elm_charts$Internal$Commands$Line, x1_, y1_)
					]) : _List_fromArray(
					[
						A2($terezka$elm_charts$Internal$Commands$Line, x1_, y1_)
					]),
				(config.pa > 0) ? _List_fromArray(
					[
						A2($terezka$elm_charts$Internal$Commands$Line, x1_ + tickOffsetX, y1_ + tickOffsetY)
					]) : _List_Nil)) : _Utils_ap(
			(config.pa > 0) ? _List_fromArray(
				[
					A2($terezka$elm_charts$Internal$Commands$Move, x1_ + tickOffsetX, y1_ + tickOffsetY),
					A2($terezka$elm_charts$Internal$Commands$Line, x1_, y1_)
				]) : _List_fromArray(
				[
					A2($terezka$elm_charts$Internal$Commands$Move, x1_, y1_)
				]),
			_Utils_ap(
				config.kk ? _List_fromArray(
					[
						A2($terezka$elm_charts$Internal$Commands$Line, x1_, y2_),
						A2($terezka$elm_charts$Internal$Commands$Line, x2_, y2_)
					]) : _List_fromArray(
					[
						A2($terezka$elm_charts$Internal$Commands$Line, x2_, y2_)
					]),
				(config.pa > 0) ? _List_fromArray(
					[
						A2($terezka$elm_charts$Internal$Commands$Line, x2_ + tickOffsetX, y2_ + tickOffsetY)
					]) : _List_Nil));
		return A4(
			$terezka$elm_charts$Internal$Svg$withAttrs,
			config.k,
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('elm-charts__line'),
					$elm$svg$Svg$Attributes$fill('transparent'),
					$elm$svg$Svg$Attributes$stroke(config.b0),
					$elm$svg$Svg$Attributes$strokeWidth(
					$elm$core$String$fromFloat(config.ec)),
					$elm$svg$Svg$Attributes$strokeOpacity(
					$elm$core$String$fromFloat(config.aB)),
					$elm$svg$Svg$Attributes$strokeDasharray(
					A2(
						$elm$core$String$join,
						' ',
						A2($elm$core$List$map, $elm$core$String$fromFloat, config.$7))),
					$elm$svg$Svg$Attributes$d(
					A2($terezka$elm_charts$Internal$Commands$description, plane, cmds)),
					config.y ? $terezka$elm_charts$Internal$Svg$withinChartArea(plane) : $elm$svg$Svg$Attributes$class('')
				]),
			_List_Nil);
	});
var $terezka$elm_charts$Chart$Svg$line = F2(
	function (plane, edits) {
		return A2(
			$terezka$elm_charts$Internal$Svg$line,
			plane,
			A2($terezka$elm_charts$Internal$Helpers$apply, edits, $terezka$elm_charts$Internal$Svg$defaultLine));
	});
var $terezka$elm_charts$Chart$Attributes$size = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{iT: v});
	});
var $terezka$elm_charts$Chart$Attributes$width = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{ec: v});
	});
var $terezka$elm_charts$Chart$Attributes$x1 = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{
				cv: $elm$core$Maybe$Just(v)
			});
	});
var $terezka$elm_charts$Chart$Attributes$y1 = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{
				pT: $elm$core$Maybe$Just(v)
			});
	});
var $terezka$elm_charts$Chart$grid = function (edits) {
	var config = A2(
		$terezka$elm_charts$Internal$Helpers$apply,
		edits,
		{b0: '', $7: _List_Nil, cJ: false, ec: 0});
	var width = (!config.ec) ? (config.cJ ? 0.5 : 1) : config.ec;
	var color = $elm$core$String$isEmpty(config.b0) ? (config.cJ ? $terezka$elm_charts$Internal$Helpers$darkGray : $terezka$elm_charts$Internal$Helpers$gray) : config.b0;
	var toDot = F4(
		function (vs, p, x, y) {
			return (A2($elm$core$List$member, x, vs.c7) || A2($elm$core$List$member, y, vs.c8)) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				A5(
					$terezka$elm_charts$Chart$Svg$dot,
					p,
					function ($) {
						return $.jv;
					},
					function ($) {
						return $.pS;
					},
					_List_fromArray(
						[
							$terezka$elm_charts$Chart$Attributes$color(color),
							$terezka$elm_charts$Chart$Attributes$size(width),
							$terezka$elm_charts$Chart$Attributes$circle
						]),
					{jv: x, pS: y}));
		});
	var toXGrid = F3(
		function (vs, p, v) {
			return A2($elm$core$List$member, v, vs.c7) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				A2(
					$terezka$elm_charts$Chart$Svg$line,
					p,
					_List_fromArray(
						[
							$terezka$elm_charts$Chart$Attributes$color(color),
							$terezka$elm_charts$Chart$Attributes$width(width),
							$terezka$elm_charts$Chart$Attributes$x1(v),
							$terezka$elm_charts$Chart$Attributes$dashed(config.$7)
						])));
		});
	var toYGrid = F3(
		function (vs, p, v) {
			return A2($elm$core$List$member, v, vs.c8) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				A2(
					$terezka$elm_charts$Chart$Svg$line,
					p,
					_List_fromArray(
						[
							$terezka$elm_charts$Chart$Attributes$color(color),
							$terezka$elm_charts$Chart$Attributes$width(width),
							$terezka$elm_charts$Chart$Attributes$y1(v),
							$terezka$elm_charts$Chart$Attributes$dashed(config.$7)
						])));
		});
	return $terezka$elm_charts$Chart$GridElement(
		F2(
			function (p, vs) {
				return A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('elm-charts__grid')
						]),
					config.cJ ? A2(
						$elm$core$List$concatMap,
						function (x) {
							return A2(
								$elm$core$List$filterMap,
								A3(toDot, vs, p, x),
								vs.ap);
						},
						vs.ac) : _List_fromArray(
						[
							A2(
							$elm$svg$Svg$g,
							_List_fromArray(
								[
									$elm$svg$Svg$Attributes$class('elm-charts__x-grid')
								]),
							A2(
								$elm$core$List$filterMap,
								A2(toXGrid, vs, p),
								vs.ac)),
							A2(
							$elm$svg$Svg$g,
							_List_fromArray(
								[
									$elm$svg$Svg$Attributes$class('elm-charts__y-grid')
								]),
							A2(
								$elm$core$List$filterMap,
								A2(toYGrid, vs, p),
								vs.ap))
						]));
			}));
};
var $terezka$elm_charts$Chart$viewElements = F6(
	function (config, plane, tickValues, allItems, allLegends, elements) {
		var viewOne = F2(
			function (el, _v0) {
				var before = _v0.a;
				var chart_ = _v0.b;
				var after = _v0.c;
				switch (el.$) {
					case 0:
						return _Utils_Tuple3(before, chart_, after);
					case 1:
						var view = el.d;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								view(plane),
								chart_),
							after);
					case 2:
						var view = el.e;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								view(plane),
								chart_),
							after);
					case 3:
						var view = el.b;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								view(plane),
								chart_),
							after);
					case 4:
						var view = el.b;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								view(plane),
								chart_),
							after);
					case 5:
						var view = el.b;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								view(plane),
								chart_),
							after);
					case 6:
						var toC = el.a;
						var view = el.c;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								A2(
									view,
									plane,
									toC(plane)),
								chart_),
							after);
					case 7:
						var toC = el.a;
						var view = el.c;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								A2(
									view,
									plane,
									toC(plane)),
								chart_),
							after);
					case 8:
						var toC = el.a;
						var view = el.c;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								A2(
									view,
									plane,
									toC(plane)),
								chart_),
							after);
					case 9:
						var view = el.a;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								A2(view, plane, tickValues),
								chart_),
							after);
					case 10:
						var func = el.a;
						return A3(
							$elm$core$List$foldr,
							viewOne,
							_Utils_Tuple3(before, chart_, after),
							A2(func, plane, allItems));
					case 11:
						var els = el.a;
						return A3(
							$elm$core$List$foldr,
							viewOne,
							_Utils_Tuple3(before, chart_, after),
							els);
					case 12:
						var view = el.a;
						return _Utils_Tuple3(
							before,
							A2(
								$elm$core$List$cons,
								view(plane),
								chart_),
							after);
					default:
						var view = el.a;
						return _Utils_Tuple3(
							($elm$core$List$length(chart_) > 0) ? A2(
								$elm$core$List$cons,
								A2(view, plane, allLegends),
								before) : before,
							chart_,
							($elm$core$List$length(chart_) > 0) ? after : A2(
								$elm$core$List$cons,
								A2(view, plane, allLegends),
								after));
				}
			});
		return A3(
			$elm$core$List$foldr,
			viewOne,
			_Utils_Tuple3(_List_Nil, _List_Nil, _List_Nil),
			elements);
	});
var $terezka$elm_charts$Chart$chart = F2(
	function (edits, unindexedElements) {
		var indexedElements = function () {
			var toIndexedEl = F2(
				function (el, _v4) {
					var acc = _v4.a;
					var index = _v4.b;
					switch (el.$) {
						case 0:
							var toElAndIndex = el.a;
							var _v6 = toElAndIndex(index);
							var newEl = _v6.a;
							var newIndex = _v6.b;
							return _Utils_Tuple2(
								_Utils_ap(
									acc,
									_List_fromArray(
										[newEl])),
								newIndex);
						case 11:
							var els = el.a;
							return A3(
								$elm$core$List$foldl,
								toIndexedEl,
								_Utils_Tuple2(acc, index),
								els);
						default:
							return _Utils_Tuple2(
								_Utils_ap(
									acc,
									_List_fromArray(
										[el])),
								index);
					}
				});
			return A3(
				$elm$core$List$foldl,
				toIndexedEl,
				_Utils_Tuple2(_List_Nil, 0),
				unindexedElements).a;
		}();
		var elements = function () {
			var isGrid = function (el) {
				if (el.$ === 9) {
					return true;
				} else {
					return false;
				}
			};
			return A2($elm$core$List$any, isGrid, indexedElements) ? indexedElements : A2(
				$elm$core$List$cons,
				$terezka$elm_charts$Chart$grid(_List_Nil),
				indexedElements);
		}();
		var legends_ = $terezka$elm_charts$Chart$getLegends(elements);
		var config = A2(
			$terezka$elm_charts$Internal$Helpers$apply,
			edits,
			{
				k: _List_fromArray(
					[
						$elm$svg$Svg$Attributes$style('overflow: visible;')
					]),
				eL: _List_Nil,
				lm: _List_Nil,
				he: 300,
				dC: _List_Nil,
				bE: {er: 0, e9: 0, fz: 0, fY: 0},
				aD: {er: 0, e9: 0, fz: 0, fY: 0},
				fw: _List_Nil,
				dV: true,
				ec: 300
			});
		var plane = A2($terezka$elm_charts$Chart$definePlane, config, elements);
		var items = A2($terezka$elm_charts$Chart$getItems, plane, elements);
		var toEvent = function (_v2) {
			var event_ = _v2;
			var _v1 = event_.k3;
			var decoder = _v1;
			return A2(
				$terezka$elm_charts$Internal$Svg$Event,
				event_.mO,
				decoder(items));
		};
		var tickValues = A3($terezka$elm_charts$Chart$getTickValues, plane, items, elements);
		var _v0 = A6($terezka$elm_charts$Chart$viewElements, config, plane, tickValues, items, legends_, elements);
		var beforeEls = _v0.a;
		var chartEls = _v0.b;
		var afterEls = _v0.c;
		return A5(
			$terezka$elm_charts$Internal$Svg$container,
			plane,
			{
				k: config.k,
				lm: A2($elm$core$List$map, toEvent, config.lm),
				dC: config.dC,
				dV: config.dV
			},
			beforeEls,
			chartEls,
			afterEls);
	});
var $author$project$Type$DataVal$extractVal = F2(
	function (extractFnMaybe, dataVal) {
		switch (dataVal.$) {
			case 0:
				var f = dataVal.a;
				return f;
			case 1:
				var r = dataVal.a;
				if (extractFnMaybe.$ === 1) {
					return 0;
				} else {
					var extractFn = extractFnMaybe.a;
					return extractFn(r);
				}
			default:
				return 0;
		}
	});
var $terezka$elm_charts$Chart$Attributes$fontSize = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{
				x: $elm$core$Maybe$Just(v)
			});
	});
var $terezka$elm_charts$Chart$Attributes$format = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{
				ag: $elm$core$Maybe$Just(v)
			});
	});
var $terezka$elm_charts$Chart$Item$getData = $terezka$elm_charts$Internal$Item$getDatum;
var $terezka$elm_charts$Internal$Item$getName = function (_v0) {
	var item = _v0;
	var _v1 = item.kD.d7.mO;
	if (!_v1.$) {
		var name = _v1.a;
		return name;
	} else {
		return 'Property #' + $elm$core$String$fromInt(item.kD.d7.l1 + 1);
	}
};
var $terezka$elm_charts$Chart$Item$getName = $terezka$elm_charts$Internal$Item$getName;
var $terezka$elm_charts$Chart$Attributes$height = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{he: v});
	});
var $terezka$elm_charts$Internal$Svg$Ints = {$: 1};
var $terezka$elm_charts$Chart$Attributes$ints = function (config) {
	return _Utils_update(
		config,
		{aw: $terezka$elm_charts$Internal$Svg$Ints});
};
var $terezka$elm_charts$Chart$Svg$label = F2(
	function (plane, edits) {
		return A2(
			$terezka$elm_charts$Internal$Svg$label,
			plane,
			A2($terezka$elm_charts$Internal$Helpers$apply, edits, $terezka$elm_charts$Internal$Svg$defaultLabel));
	});
var $terezka$elm_charts$Chart$labelAt = F4(
	function (toX, toY, attrs, inner) {
		return $terezka$elm_charts$Chart$SvgElement(
			function (p) {
				return A4(
					$terezka$elm_charts$Chart$Svg$label,
					p,
					attrs,
					inner,
					{
						jv: toX(p.jv),
						pS: toY(p.pS)
					});
			});
	});
var $author$project$Type$ChartData$Relative = 0;
var $author$project$Type$ReportCard$reportCardValues = _List_fromArray(
	[0, 1, 2, 3, 4]);
var $author$project$Type$ReportCard$reportCardValueStrings = A2($elm$core$List$map, $author$project$Type$ReportCard$toString, $author$project$Type$ReportCard$reportCardValues);
var $author$project$Type$ResultBin$makeBinRange = function (resultBinType) {
	switch (resultBinType.$) {
		case 0:
			return $author$project$Type$ReportCard$reportCardValueStrings;
		case 1:
			var cohort = resultBinType.a;
			if (!cohort.$) {
				var stage = cohort.a;
				var lowestBand = function () {
					switch (stage.$) {
						case 0:
							return 2;
						case 1:
							return 3;
						case 2:
							return 4;
						default:
							return 5;
					}
				}();
				return A2(
					$elm$core$List$map,
					$elm$core$String$fromInt,
					A2($elm$core$List$range, lowestBand, lowestBand + 5));
			} else {
				return _List_Nil;
			}
		default:
			return A2(
				$elm$core$List$map,
				$author$project$Type$ResultBin$msBandValueToString,
				_List_fromArray(
					[0, 1, 2, 3, 4]));
	}
};
var $author$project$Type$DataVal$Empty = {$: 2};
var $author$project$Type$DataVal$mempty = $author$project$Type$DataVal$Empty;
var $author$project$Type$ChartData$makeInitChartData = function (resultBinType) {
	return $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (el) {
				return _Utils_Tuple2(
					el,
					{mL: $author$project$Type$DataVal$mempty, nw: $author$project$Type$DataVal$mempty});
			},
			$author$project$Type$ResultBin$makeBinRange(resultBinType)));
};
var $author$project$Type$DataVal$DataF = function (a) {
	return {$: 0, a: a};
};
var $author$project$Type$DataVal$DataR = function (a) {
	return {$: 1, a: a};
};
var $author$project$Type$DataVal$map2 = F3(
	function (fn, dv1, dv2) {
		var _v0 = _Utils_Tuple2(dv1, dv2);
		_v0$2:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					if (!_v0.b.$) {
						var f1 = _v0.a.a;
						var f2 = _v0.b.a;
						return $author$project$Type$DataVal$DataF(
							A2(fn, f1, f2));
					} else {
						break _v0$2;
					}
				case 1:
					if (_v0.b.$ === 1) {
						var r1 = _v0.a.a;
						var r2 = _v0.b.a;
						var totals = (r2.jy + r2.gl) + r2.ko;
						return $author$project$Type$DataVal$DataR(
							{
								jy: A2(fn, r1.jy, totals),
								gl: A2(fn, r1.gl, totals),
								ko: A2(fn, r1.ko, totals)
							});
					} else {
						break _v0$2;
					}
				default:
					break _v0$2;
			}
		}
		return $author$project$Type$DataVal$mempty;
	});
var $author$project$Type$DataVal$mappend = F2(
	function (d1, d2) {
		var _v0 = _Utils_Tuple2(d1, d2);
		_v0$2:
		while (true) {
			_v0$4:
			while (true) {
				switch (_v0.a.$) {
					case 1:
						switch (_v0.b.$) {
							case 1:
								var r1 = _v0.a.a;
								var r2 = _v0.b.a;
								return $author$project$Type$DataVal$DataR(
									{jy: r1.jy + r2.jy, gl: r1.gl + r2.gl, ko: r1.ko + r2.ko});
							case 2:
								break _v0$2;
							default:
								break _v0$4;
						}
					case 0:
						switch (_v0.b.$) {
							case 0:
								var f1 = _v0.a.a;
								var f2 = _v0.b.a;
								return $author$project$Type$DataVal$DataF(f1 + f2);
							case 2:
								break _v0$2;
							default:
								break _v0$4;
						}
					default:
						if (_v0.b.$ === 2) {
							break _v0$2;
						} else {
							var _v2 = _v0.a;
							var v = _v0.b;
							return v;
						}
				}
			}
			return $author$project$Type$DataVal$Empty;
		}
		var v = _v0.a;
		var _v1 = _v0.b;
		return v;
	});
var $author$project$Type$ResultBin$resultBinSorter = F3(
	function (resultBinType, tup1, tup2) {
		var el2 = tup2.a;
		var el1 = tup1.a;
		switch (resultBinType.$) {
			case 0:
				return A2($elm$core$Basics$compare, el1, el2);
			case 1:
				return A2(
					$elm$core$Basics$compare,
					A2(
						$elm$core$Maybe$withDefault,
						0,
						$elm$core$String$toInt(el1)),
					A2(
						$elm$core$Maybe$withDefault,
						0,
						$elm$core$String$toInt(el2)));
			default:
				var getOrder = function (minStdBin) {
					switch (minStdBin) {
						case 'e2l':
							return 1;
						case 'e2h':
							return 2;
						case 'e3l':
							return 3;
						case 'e3h':
							return 4;
						case 'e4':
							return 5;
						default:
							return 0;
					}
				};
				return A2(
					$elm$core$Basics$compare,
					getOrder(el1),
					getOrder(el2));
		}
	});
var $elm$core$List$sortWith = _List_sortWith;
var $author$project$Type$ChartData$appendCDV = F3(
	function (resultMoment, cdv, dv) {
		if (!resultMoment) {
			return _Utils_update(
				cdv,
				{
					nw: A2($author$project$Type$DataVal$mappend, cdv.nw, dv)
				});
		} else {
			return _Utils_update(
				cdv,
				{
					mL: A2($author$project$Type$DataVal$mappend, cdv.mL, dv)
				});
		}
	});
var $author$project$Type$DataVal$makeDataVal = F3(
	function (resultBinType, str2, val) {
		var makeDataR = function () {
			switch (str2) {
				case 'a':
					return $author$project$Type$DataVal$DataR(
						{jy: val, gl: 0, ko: 0});
				case 'b':
					return $author$project$Type$DataVal$DataR(
						{jy: 0, gl: val, ko: 0});
				case 'c':
					return $author$project$Type$DataVal$DataR(
						{jy: 0, gl: 0, ko: val});
				default:
					return $author$project$Type$DataVal$DataR(
						{jy: 0, gl: 0, ko: 0});
			}
		}();
		var makeDataF = $author$project$Type$DataVal$DataF(val);
		switch (resultBinType.$) {
			case 0:
				return makeDataF;
			case 1:
				return makeDataR;
			default:
				return makeDataR;
		}
	});
var $author$project$Type$ChartData$updateChartData = F4(
	function (resultBinType, resultMoment, aggregateResults, chartData) {
		var updateChartDataVal = F3(
			function (str2, val, chartDataVal) {
				return A3(
					$author$project$Type$ChartData$appendCDV,
					resultMoment,
					chartDataVal,
					A3($author$project$Type$DataVal$makeDataVal, resultBinType, str2, val));
			});
		var insertToChartData = F3(
			function (_v0, students, chartDataValDict) {
				var str1 = _v0.a;
				var str2 = _v0.b;
				return A3(
					$elm$core$Dict$update,
					str1,
					$elm$core$Maybe$map(
						A2(
							updateChartDataVal,
							str2,
							$elm$core$List$length(students))),
					chartDataValDict);
			});
		return A3($elm$core$Dict$foldl, insertToChartData, chartData, aggregateResults);
	});
var $author$project$Type$ChartData$makeChartData = F3(
	function (yFormat, _v0, resultBinType) {
		var aggResFirst = _v0.a;
		var aggResSecond = _v0.b;
		return function (els) {
			if (!yFormat) {
				var divFn = F2(
					function (el1, el2) {
						return (!el2) ? 0 : ((el1 / el2) * 100);
					});
				var _v1 = A3(
					$elm$core$List$foldl,
					F2(
						function (_v3, _v4) {
							var el1pre = _v3.a;
							var el1mostRec = _v3.b;
							var el2pre = _v4.a;
							var el2mostRec = _v4.b;
							return _Utils_Tuple2(
								A2($author$project$Type$DataVal$mappend, el1pre, el2pre),
								A2($author$project$Type$DataVal$mappend, el1mostRec, el2mostRec));
						}),
					_Utils_Tuple2($author$project$Type$DataVal$mempty, $author$project$Type$DataVal$mempty),
					A2(
						$elm$core$List$map,
						function (_v2) {
							var k = _v2.a;
							var v = _v2.b;
							return _Utils_Tuple2(v.nw, v.mL);
						},
						els));
				var preTotals = _v1.a;
				var mostRecTotals = _v1.b;
				return A2(
					$elm$core$List$map,
					function (_v5) {
						var k = _v5.a;
						var v = _v5.b;
						return _Utils_Tuple2(
							k,
							{
								mL: A3($author$project$Type$DataVal$map2, divFn, v.mL, mostRecTotals),
								nw: A3($author$project$Type$DataVal$map2, divFn, v.nw, preTotals)
							});
					},
					els);
			} else {
				return els;
			}
		}(
			A2(
				$elm$core$List$sortWith,
				$author$project$Type$ResultBin$resultBinSorter(resultBinType),
				$elm$core$Dict$toList(
					A4(
						$author$project$Type$ChartData$updateChartData,
						resultBinType,
						1,
						aggResSecond,
						A4(
							$author$project$Type$ChartData$updateChartData,
							resultBinType,
							0,
							aggResFirst,
							$author$project$Type$ChartData$makeInitChartData(resultBinType))))));
	});
var $terezka$elm_charts$Chart$Attributes$margin = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{bE: v});
	});
var $terezka$elm_charts$Chart$Attributes$middle = function (b) {
	return b.hI + ((b.mB - b.hI) / 2);
};
var $terezka$elm_charts$Chart$Attributes$moveDown = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{q: config.q + v});
	});
var $terezka$elm_charts$Chart$Attributes$moveLeft = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{p: config.p - v});
	});
var $terezka$elm_charts$Internal$Property$Stacked = function (a) {
	return {$: 1, a: a};
};
var $terezka$elm_charts$Internal$Property$meta = F2(
	function (value, prop) {
		if (!prop.$) {
			var con = prop.a;
			return $terezka$elm_charts$Internal$Property$Property(
				_Utils_update(
					con,
					{
						hH: $elm$core$Maybe$Just(value)
					}));
		} else {
			var cons = prop.a;
			return $terezka$elm_charts$Internal$Property$Stacked(
				A2(
					$elm$core$List$map,
					function (con) {
						return _Utils_update(
							con,
							{
								hH: $elm$core$Maybe$Just(value)
							});
					},
					cons));
		}
	});
var $terezka$elm_charts$Chart$named = function (name) {
	return $terezka$elm_charts$Internal$Property$meta(name);
};
var $terezka$elm_charts$Chart$Attributes$opacity = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{aB: v});
	});
var $terezka$elm_charts$Chart$Attributes$rotate = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{C: config.C + v});
	});
var $terezka$elm_charts$Internal$Property$stacked = function (properties) {
	var toVisual = F2(
		function (toYs_, datum) {
			var vs = A2(
				$elm$core$List$filterMap,
				function (toY) {
					return toY(datum);
				},
				toYs_);
			return (!_Utils_eq(
				$elm$core$List$length(vs),
				$elm$core$List$length(toYs_))) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				$elm$core$List$sum(vs));
		});
	var stack = F3(
		function (list, prev, result) {
			stack:
			while (true) {
				if (list.b) {
					var one = list.a;
					var rest = list.b;
					var toYs_ = A2($elm$core$List$cons, one.pI, prev);
					var $temp$list = rest,
						$temp$prev = toYs_,
						$temp$result = A2(
						$elm$core$List$cons,
						_Utils_update(
							one,
							{
								c5: toVisual(toYs_)
							}),
						result);
					list = $temp$list;
					prev = $temp$prev;
					result = $temp$result;
					continue stack;
				} else {
					return result;
				}
			}
		});
	var configs = A2(
		$elm$core$List$concatMap,
		$terezka$elm_charts$Internal$Property$toConfigs,
		$elm$core$List$reverse(properties));
	return $terezka$elm_charts$Internal$Property$Stacked(
		A3(stack, configs, _List_Nil, _List_Nil));
};
var $terezka$elm_charts$Chart$stacked = $terezka$elm_charts$Internal$Property$stacked;
var $terezka$elm_charts$Chart$Attributes$withGrid = function (config) {
	return _Utils_update(
		config,
		{lH: true});
};
var $terezka$elm_charts$Internal$Svg$Floats = {$: 0};
var $terezka$elm_charts$Chart$TicksElement = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $terezka$elm_charts$Internal$Svg$Generator = $elm$core$Basics$identity;
var $terezka$intervals$Intervals$Around = function (a) {
	return {$: 1, a: a};
};
var $terezka$intervals$Intervals$around = $terezka$intervals$Intervals$Around;
var $terezka$intervals$Intervals$ceilingTo = F2(
	function (prec, number) {
		return prec * $elm$core$Basics$ceiling(number / prec);
	});
var $terezka$intervals$Intervals$getBeginning = F2(
	function (min, interval) {
		var multiple = min / interval;
		return _Utils_eq(
			multiple,
			$elm$core$Basics$round(multiple)) ? min : A2($terezka$intervals$Intervals$ceilingTo, interval, min);
	});
var $terezka$intervals$Intervals$correctFloat = function (prec) {
	return A2(
		$elm$core$Basics$composeR,
		$myrho$elm_round$Round$round(prec),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$String$toFloat,
			$elm$core$Maybe$withDefault(0)));
};
var $terezka$intervals$Intervals$getMultiples = F3(
	function (magnitude, allowDecimals, hasTickAmount) {
		var defaults = hasTickAmount ? _List_fromArray(
			[1, 1.2, 1.5, 2, 2.5, 3, 4, 5, 6, 8, 10]) : _List_fromArray(
			[1, 2, 2.5, 5, 10]);
		return allowDecimals ? defaults : ((magnitude === 1) ? A2(
			$elm$core$List$filter,
			function (n) {
				return _Utils_eq(
					$elm$core$Basics$round(n),
					n);
			},
			defaults) : ((magnitude <= 0.1) ? _List_fromArray(
			[1 / magnitude]) : defaults));
	});
var $terezka$intervals$Intervals$getPrecision = function (number) {
	var _v0 = A2(
		$elm$core$String$split,
		'e',
		$elm$core$String$fromFloat(number));
	if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
		var before = _v0.a;
		var _v1 = _v0.b;
		var after = _v1.a;
		return $elm$core$Basics$abs(
			A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(after)));
	} else {
		var _v2 = A2(
			$elm$core$String$split,
			'.',
			$elm$core$String$fromFloat(number));
		if ((_v2.b && _v2.b.b) && (!_v2.b.b.b)) {
			var before = _v2.a;
			var _v3 = _v2.b;
			var after = _v3.a;
			return $elm$core$String$length(after);
		} else {
			return 0;
		}
	}
};
var $elm$core$Basics$e = _Basics_e;
var $terezka$intervals$Intervals$toMagnitude = function (num) {
	return A2(
		$elm$core$Basics$pow,
		10,
		$elm$core$Basics$floor(
			A2($elm$core$Basics$logBase, $elm$core$Basics$e, num) / A2($elm$core$Basics$logBase, $elm$core$Basics$e, 10)));
};
var $terezka$intervals$Intervals$getInterval = F3(
	function (intervalRaw, allowDecimals, hasTickAmount) {
		var magnitude = $terezka$intervals$Intervals$toMagnitude(intervalRaw);
		var multiples = A3($terezka$intervals$Intervals$getMultiples, magnitude, allowDecimals, hasTickAmount);
		var normalized = intervalRaw / magnitude;
		var findMultipleExact = function (multiples_) {
			findMultipleExact:
			while (true) {
				if (multiples_.b) {
					var m1 = multiples_.a;
					var rest = multiples_.b;
					if (_Utils_cmp(m1 * magnitude, intervalRaw) > -1) {
						return m1;
					} else {
						var $temp$multiples_ = rest;
						multiples_ = $temp$multiples_;
						continue findMultipleExact;
					}
				} else {
					return 1;
				}
			}
		};
		var findMultiple = function (multiples_) {
			findMultiple:
			while (true) {
				if (multiples_.b) {
					if (multiples_.b.b) {
						var m1 = multiples_.a;
						var _v2 = multiples_.b;
						var m2 = _v2.a;
						var rest = _v2.b;
						if (_Utils_cmp(normalized, (m1 + m2) / 2) < 1) {
							return m1;
						} else {
							var $temp$multiples_ = A2($elm$core$List$cons, m2, rest);
							multiples_ = $temp$multiples_;
							continue findMultiple;
						}
					} else {
						var m1 = multiples_.a;
						var rest = multiples_.b;
						if (_Utils_cmp(normalized, m1) < 1) {
							return m1;
						} else {
							var $temp$multiples_ = rest;
							multiples_ = $temp$multiples_;
							continue findMultiple;
						}
					}
				} else {
					return 1;
				}
			}
		};
		var multiple = hasTickAmount ? findMultipleExact(multiples) : findMultiple(multiples);
		var precision = $terezka$intervals$Intervals$getPrecision(magnitude) + $terezka$intervals$Intervals$getPrecision(multiple);
		return A2($terezka$intervals$Intervals$correctFloat, precision, multiple * magnitude);
	});
var $terezka$intervals$Intervals$positions = F5(
	function (range, beginning, interval, m, acc) {
		positions:
		while (true) {
			var nextPosition = A2(
				$terezka$intervals$Intervals$correctFloat,
				$terezka$intervals$Intervals$getPrecision(interval),
				beginning + (m * interval));
			if (_Utils_cmp(nextPosition, range.mB) > 0) {
				return acc;
			} else {
				var $temp$range = range,
					$temp$beginning = beginning,
					$temp$interval = interval,
					$temp$m = m + 1,
					$temp$acc = _Utils_ap(
					acc,
					_List_fromArray(
						[nextPosition]));
				range = $temp$range;
				beginning = $temp$beginning;
				interval = $temp$interval;
				m = $temp$m;
				acc = $temp$acc;
				continue positions;
			}
		}
	});
var $terezka$intervals$Intervals$values = F4(
	function (allowDecimals, exact, amountRough, range) {
		var intervalRough = (range.mB - range.hI) / amountRough;
		var interval = A3($terezka$intervals$Intervals$getInterval, intervalRough, allowDecimals, exact);
		var intervalSafe = (!interval) ? 1 : interval;
		var beginning = A2($terezka$intervals$Intervals$getBeginning, range.hI, intervalSafe);
		var amountRoughSafe = (!amountRough) ? 1 : amountRough;
		return A5($terezka$intervals$Intervals$positions, range, beginning, intervalSafe, 0, _List_Nil);
	});
var $terezka$intervals$Intervals$floats = function (amount) {
	if (!amount.$) {
		var number = amount.a;
		return A3($terezka$intervals$Intervals$values, true, true, number);
	} else {
		var number = amount.a;
		return A3($terezka$intervals$Intervals$values, true, false, number);
	}
};
var $terezka$elm_charts$Internal$Svg$floats = F2(
	function (i, b) {
		return A2(
			$terezka$intervals$Intervals$floats,
			$terezka$intervals$Intervals$around(i),
			{mB: b.mB, hI: b.hI});
	});
var $terezka$elm_charts$Chart$Svg$floats = $terezka$elm_charts$Internal$Svg$floats;
var $ryannhg$date_format$DateFormat$Language$Language = F6(
	function (toMonthName, toMonthAbbreviation, toWeekdayName, toWeekdayAbbreviation, toAmPm, toOrdinalSuffix) {
		return {pf: toAmPm, pj: toMonthAbbreviation, pk: toMonthName, cp: toOrdinalSuffix, pn: toWeekdayAbbreviation, po: toWeekdayName};
	});
var $ryannhg$date_format$DateFormat$Language$toEnglishAmPm = function (hour) {
	return (hour > 11) ? 'pm' : 'am';
};
var $ryannhg$date_format$DateFormat$Language$toEnglishMonthName = function (month) {
	switch (month) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $ryannhg$date_format$DateFormat$Language$toEnglishSuffix = function (num) {
	var _v0 = A2($elm$core$Basics$modBy, 100, num);
	switch (_v0) {
		case 11:
			return 'th';
		case 12:
			return 'th';
		case 13:
			return 'th';
		default:
			var _v1 = A2($elm$core$Basics$modBy, 10, num);
			switch (_v1) {
				case 1:
					return 'st';
				case 2:
					return 'nd';
				case 3:
					return 'rd';
				default:
					return 'th';
			}
	}
};
var $ryannhg$date_format$DateFormat$Language$toEnglishWeekdayName = function (weekday) {
	switch (weekday) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $ryannhg$date_format$DateFormat$Language$english = A6(
	$ryannhg$date_format$DateFormat$Language$Language,
	$ryannhg$date_format$DateFormat$Language$toEnglishMonthName,
	A2(
		$elm$core$Basics$composeR,
		$ryannhg$date_format$DateFormat$Language$toEnglishMonthName,
		$elm$core$String$left(3)),
	$ryannhg$date_format$DateFormat$Language$toEnglishWeekdayName,
	A2(
		$elm$core$Basics$composeR,
		$ryannhg$date_format$DateFormat$Language$toEnglishWeekdayName,
		$elm$core$String$left(3)),
	$ryannhg$date_format$DateFormat$Language$toEnglishAmPm,
	$ryannhg$date_format$DateFormat$Language$toEnglishSuffix);
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $ryannhg$date_format$DateFormat$amPm = F3(
	function (language, zone, posix) {
		return language.pf(
			A2($elm$time$Time$toHour, zone, posix));
	});
var $ryannhg$date_format$DateFormat$dayOfMonth = $elm$time$Time$toDay;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $ryannhg$date_format$DateFormat$days = _List_fromArray(
	[6, 0, 1, 2, 3, 4, 5]);
var $elm$time$Time$toWeekday = F2(
	function (zone, time) {
		var _v0 = A2(
			$elm$core$Basics$modBy,
			7,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60 * 24));
		switch (_v0) {
			case 0:
				return 3;
			case 1:
				return 4;
			case 2:
				return 5;
			case 3:
				return 6;
			case 4:
				return 0;
			case 5:
				return 1;
			default:
				return 2;
		}
	});
var $ryannhg$date_format$DateFormat$dayOfWeek = F2(
	function (zone, posix) {
		return function (_v1) {
			var i = _v1.a;
			return i;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				_Utils_Tuple2(0, 6),
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (_v0) {
							var day = _v0.b;
							return _Utils_eq(
								day,
								A2($elm$time$Time$toWeekday, zone, posix));
						},
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, day) {
									return _Utils_Tuple2(i, day);
								}),
							$ryannhg$date_format$DateFormat$days)))));
	});
var $ryannhg$date_format$DateFormat$isLeapYear = function (year_) {
	return (!(!A2($elm$core$Basics$modBy, 4, year_))) ? false : ((!(!A2($elm$core$Basics$modBy, 100, year_))) ? true : ((!(!A2($elm$core$Basics$modBy, 400, year_))) ? false : true));
};
var $ryannhg$date_format$DateFormat$daysInMonth = F2(
	function (year_, month) {
		switch (month) {
			case 0:
				return 31;
			case 1:
				return $ryannhg$date_format$DateFormat$isLeapYear(year_) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $ryannhg$date_format$DateFormat$months = _List_fromArray(
	[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
var $ryannhg$date_format$DateFormat$monthPair = F2(
	function (zone, posix) {
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(0, 0),
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (_v0) {
						var i = _v0.a;
						var m = _v0.b;
						return _Utils_eq(
							m,
							A2($elm$time$Time$toMonth, zone, posix));
					},
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (a, b) {
								return _Utils_Tuple2(a, b);
							}),
						$ryannhg$date_format$DateFormat$months))));
	});
var $ryannhg$date_format$DateFormat$monthNumber_ = F2(
	function (zone, posix) {
		return 1 + function (_v0) {
			var i = _v0.a;
			var m = _v0.b;
			return i;
		}(
			A2($ryannhg$date_format$DateFormat$monthPair, zone, posix));
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $ryannhg$date_format$DateFormat$dayOfYear = F2(
	function (zone, posix) {
		var monthsBeforeThisOne = A2(
			$elm$core$List$take,
			A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix) - 1,
			$ryannhg$date_format$DateFormat$months);
		var daysBeforeThisMonth = $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				$ryannhg$date_format$DateFormat$daysInMonth(
					A2($elm$time$Time$toYear, zone, posix)),
				monthsBeforeThisOne));
		return daysBeforeThisMonth + A2($ryannhg$date_format$DateFormat$dayOfMonth, zone, posix);
	});
var $ryannhg$date_format$DateFormat$quarter = F2(
	function (zone, posix) {
		return (A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix) / 4) | 0;
	});
var $ryannhg$date_format$DateFormat$toFixedLength = F2(
	function (totalChars, num) {
		var numStr = $elm$core$String$fromInt(num);
		var numZerosNeeded = totalChars - $elm$core$String$length(numStr);
		var zeros = A2(
			$elm$core$String$join,
			'',
			A2(
				$elm$core$List$map,
				function (_v0) {
					return '0';
				},
				A2($elm$core$List$range, 1, numZerosNeeded)));
		return _Utils_ap(zeros, numStr);
	});
var $elm$time$Time$toMillis = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			1000,
			$elm$time$Time$posixToMillis(time));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $ryannhg$date_format$DateFormat$toNonMilitary = function (num) {
	return (!num) ? 12 : ((num <= 12) ? num : (num - 12));
};
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $ryannhg$date_format$DateFormat$millisecondsPerYear = $elm$core$Basics$round((((1000 * 60) * 60) * 24) * 365.25);
var $ryannhg$date_format$DateFormat$firstDayOfYear = F2(
	function (zone, time) {
		return $elm$time$Time$millisToPosix(
			$ryannhg$date_format$DateFormat$millisecondsPerYear * A2($elm$time$Time$toYear, zone, time));
	});
var $ryannhg$date_format$DateFormat$weekOfYear = F2(
	function (zone, posix) {
		var firstDay = A2($ryannhg$date_format$DateFormat$firstDayOfYear, zone, posix);
		var firstDayOffset = A2($ryannhg$date_format$DateFormat$dayOfWeek, zone, firstDay);
		var daysSoFar = A2($ryannhg$date_format$DateFormat$dayOfYear, zone, posix);
		return (((daysSoFar + firstDayOffset) / 7) | 0) + 1;
	});
var $ryannhg$date_format$DateFormat$year = F2(
	function (zone, time) {
		return $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, zone, time));
	});
var $ryannhg$date_format$DateFormat$piece = F4(
	function (language, zone, posix, token) {
		switch (token.$) {
			case 0:
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix));
			case 1:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.cp(num));
				}(
					A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix));
			case 2:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix));
			case 3:
				return language.pj(
					A2($elm$time$Time$toMonth, zone, posix));
			case 4:
				return language.pk(
					A2($elm$time$Time$toMonth, zone, posix));
			case 17:
				return $elm$core$String$fromInt(
					1 + A2($ryannhg$date_format$DateFormat$quarter, zone, posix));
			case 18:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.cp(num));
				}(
					1 + A2($ryannhg$date_format$DateFormat$quarter, zone, posix));
			case 5:
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$dayOfMonth, zone, posix));
			case 6:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.cp(num));
				}(
					A2($ryannhg$date_format$DateFormat$dayOfMonth, zone, posix));
			case 7:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($ryannhg$date_format$DateFormat$dayOfMonth, zone, posix));
			case 8:
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$dayOfYear, zone, posix));
			case 9:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.cp(num));
				}(
					A2($ryannhg$date_format$DateFormat$dayOfYear, zone, posix));
			case 10:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					3,
					A2($ryannhg$date_format$DateFormat$dayOfYear, zone, posix));
			case 11:
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$dayOfWeek, zone, posix));
			case 12:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.cp(num));
				}(
					A2($ryannhg$date_format$DateFormat$dayOfWeek, zone, posix));
			case 13:
				return language.pn(
					A2($elm$time$Time$toWeekday, zone, posix));
			case 14:
				return language.po(
					A2($elm$time$Time$toWeekday, zone, posix));
			case 19:
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$weekOfYear, zone, posix));
			case 20:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.cp(num));
				}(
					A2($ryannhg$date_format$DateFormat$weekOfYear, zone, posix));
			case 21:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($ryannhg$date_format$DateFormat$weekOfYear, zone, posix));
			case 15:
				return A2(
					$elm$core$String$right,
					2,
					A2($ryannhg$date_format$DateFormat$year, zone, posix));
			case 16:
				return A2($ryannhg$date_format$DateFormat$year, zone, posix);
			case 22:
				return $elm$core$String$toUpper(
					A3($ryannhg$date_format$DateFormat$amPm, language, zone, posix));
			case 23:
				return $elm$core$String$toLower(
					A3($ryannhg$date_format$DateFormat$amPm, language, zone, posix));
			case 24:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toHour, zone, posix));
			case 25:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($elm$time$Time$toHour, zone, posix));
			case 26:
				return $elm$core$String$fromInt(
					$ryannhg$date_format$DateFormat$toNonMilitary(
						A2($elm$time$Time$toHour, zone, posix)));
			case 27:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					$ryannhg$date_format$DateFormat$toNonMilitary(
						A2($elm$time$Time$toHour, zone, posix)));
			case 28:
				return $elm$core$String$fromInt(
					1 + A2($elm$time$Time$toHour, zone, posix));
			case 29:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					1 + A2($elm$time$Time$toHour, zone, posix));
			case 30:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toMinute, zone, posix));
			case 31:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($elm$time$Time$toMinute, zone, posix));
			case 32:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toSecond, zone, posix));
			case 33:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($elm$time$Time$toSecond, zone, posix));
			case 34:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toMillis, zone, posix));
			case 35:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					3,
					A2($elm$time$Time$toMillis, zone, posix));
			default:
				var string = token.a;
				return string;
		}
	});
var $ryannhg$date_format$DateFormat$formatWithLanguage = F4(
	function (language, tokens, zone, time) {
		return A2(
			$elm$core$String$join,
			'',
			A2(
				$elm$core$List$map,
				A3($ryannhg$date_format$DateFormat$piece, language, zone, time),
				tokens));
	});
var $ryannhg$date_format$DateFormat$format = $ryannhg$date_format$DateFormat$formatWithLanguage($ryannhg$date_format$DateFormat$Language$english);
var $ryannhg$date_format$DateFormat$HourMilitaryFixed = {$: 25};
var $ryannhg$date_format$DateFormat$hourMilitaryFixed = $ryannhg$date_format$DateFormat$HourMilitaryFixed;
var $ryannhg$date_format$DateFormat$MinuteFixed = {$: 31};
var $ryannhg$date_format$DateFormat$minuteFixed = $ryannhg$date_format$DateFormat$MinuteFixed;
var $ryannhg$date_format$DateFormat$Text = function (a) {
	return {$: 36, a: a};
};
var $ryannhg$date_format$DateFormat$text = $ryannhg$date_format$DateFormat$Text;
var $terezka$elm_charts$Internal$Svg$formatClock = $ryannhg$date_format$DateFormat$format(
	_List_fromArray(
		[
			$ryannhg$date_format$DateFormat$hourMilitaryFixed,
			$ryannhg$date_format$DateFormat$text(':'),
			$ryannhg$date_format$DateFormat$minuteFixed
		]));
var $ryannhg$date_format$DateFormat$MillisecondFixed = {$: 35};
var $ryannhg$date_format$DateFormat$millisecondFixed = $ryannhg$date_format$DateFormat$MillisecondFixed;
var $ryannhg$date_format$DateFormat$SecondFixed = {$: 33};
var $ryannhg$date_format$DateFormat$secondFixed = $ryannhg$date_format$DateFormat$SecondFixed;
var $terezka$elm_charts$Internal$Svg$formatClockMillis = $ryannhg$date_format$DateFormat$format(
	_List_fromArray(
		[
			$ryannhg$date_format$DateFormat$hourMilitaryFixed,
			$ryannhg$date_format$DateFormat$text(':'),
			$ryannhg$date_format$DateFormat$minuteFixed,
			$ryannhg$date_format$DateFormat$text(':'),
			$ryannhg$date_format$DateFormat$secondFixed,
			$ryannhg$date_format$DateFormat$text(':'),
			$ryannhg$date_format$DateFormat$millisecondFixed
		]));
var $terezka$elm_charts$Internal$Svg$formatClockSecond = $ryannhg$date_format$DateFormat$format(
	_List_fromArray(
		[
			$ryannhg$date_format$DateFormat$hourMilitaryFixed,
			$ryannhg$date_format$DateFormat$text(':'),
			$ryannhg$date_format$DateFormat$minuteFixed,
			$ryannhg$date_format$DateFormat$text(':'),
			$ryannhg$date_format$DateFormat$secondFixed
		]));
var $ryannhg$date_format$DateFormat$DayOfMonthNumber = {$: 5};
var $ryannhg$date_format$DateFormat$dayOfMonthNumber = $ryannhg$date_format$DateFormat$DayOfMonthNumber;
var $ryannhg$date_format$DateFormat$MonthNumber = {$: 0};
var $ryannhg$date_format$DateFormat$monthNumber = $ryannhg$date_format$DateFormat$MonthNumber;
var $terezka$elm_charts$Internal$Svg$formatDate = $ryannhg$date_format$DateFormat$format(
	_List_fromArray(
		[
			$ryannhg$date_format$DateFormat$monthNumber,
			$ryannhg$date_format$DateFormat$text('/'),
			$ryannhg$date_format$DateFormat$dayOfMonthNumber
		]));
var $ryannhg$date_format$DateFormat$MonthNameAbbreviated = {$: 3};
var $ryannhg$date_format$DateFormat$monthNameAbbreviated = $ryannhg$date_format$DateFormat$MonthNameAbbreviated;
var $terezka$elm_charts$Internal$Svg$formatMonth = $ryannhg$date_format$DateFormat$format(
	_List_fromArray(
		[$ryannhg$date_format$DateFormat$monthNameAbbreviated]));
var $ryannhg$date_format$DateFormat$DayOfWeekNameFull = {$: 14};
var $ryannhg$date_format$DateFormat$dayOfWeekNameFull = $ryannhg$date_format$DateFormat$DayOfWeekNameFull;
var $terezka$elm_charts$Internal$Svg$formatWeekday = $ryannhg$date_format$DateFormat$format(
	_List_fromArray(
		[$ryannhg$date_format$DateFormat$dayOfWeekNameFull]));
var $ryannhg$date_format$DateFormat$YearNumber = {$: 16};
var $ryannhg$date_format$DateFormat$yearNumber = $ryannhg$date_format$DateFormat$YearNumber;
var $terezka$elm_charts$Internal$Svg$formatYear = $ryannhg$date_format$DateFormat$format(
	_List_fromArray(
		[$ryannhg$date_format$DateFormat$yearNumber]));
var $terezka$elm_charts$Internal$Svg$formatTime = F2(
	function (zone, time) {
		var _v0 = A2($elm$core$Maybe$withDefault, time.pw, time.kt);
		switch (_v0) {
			case 0:
				return A2($terezka$elm_charts$Internal$Svg$formatClockMillis, zone, time.pe);
			case 1:
				return A2($terezka$elm_charts$Internal$Svg$formatClockSecond, zone, time.pe);
			case 2:
				return A2($terezka$elm_charts$Internal$Svg$formatClock, zone, time.pe);
			case 3:
				return A2($terezka$elm_charts$Internal$Svg$formatClock, zone, time.pe);
			case 4:
				return (time.mN === 7) ? A2($terezka$elm_charts$Internal$Svg$formatWeekday, zone, time.pe) : A2($terezka$elm_charts$Internal$Svg$formatDate, zone, time.pe);
			case 5:
				return A2($terezka$elm_charts$Internal$Svg$formatMonth, zone, time.pe);
			default:
				return A2($terezka$elm_charts$Internal$Svg$formatYear, zone, time.pe);
		}
	});
var $terezka$elm_charts$Chart$Svg$formatTime = $terezka$elm_charts$Internal$Svg$formatTime;
var $terezka$elm_charts$Internal$Svg$generate = F3(
	function (amount, _v0, limits) {
		var func = _v0;
		return A2(func, amount, limits);
	});
var $terezka$elm_charts$Chart$Svg$generate = $terezka$elm_charts$Internal$Svg$generate;
var $terezka$intervals$Intervals$ints = F2(
	function (amount, range) {
		return A2(
			$elm$core$List$map,
			$elm$core$Basics$round,
			function () {
				if (!amount.$) {
					var number = amount.a;
					return A4($terezka$intervals$Intervals$values, false, true, number, range);
				} else {
					var number = amount.a;
					return A4($terezka$intervals$Intervals$values, false, false, number, range);
				}
			}());
	});
var $terezka$elm_charts$Internal$Svg$ints = F2(
	function (i, b) {
		return A2(
			$terezka$intervals$Intervals$ints,
			$terezka$intervals$Intervals$around(i),
			{mB: b.mB, hI: b.hI});
	});
var $terezka$elm_charts$Chart$Svg$ints = $terezka$elm_charts$Internal$Svg$ints;
var $terezka$intervals$Intervals$Day = 4;
var $terezka$intervals$Intervals$Hour = 3;
var $terezka$intervals$Intervals$Millisecond = 0;
var $terezka$intervals$Intervals$Minute = 2;
var $terezka$intervals$Intervals$Month = 5;
var $terezka$intervals$Intervals$Second = 1;
var $terezka$intervals$Intervals$Year = 6;
var $justinmimbs$time_extra$Time$Extra$Day = 11;
var $justinmimbs$date$Date$Days = 3;
var $justinmimbs$time_extra$Time$Extra$Millisecond = 15;
var $justinmimbs$time_extra$Time$Extra$Month = 2;
var $justinmimbs$date$Date$Months = 1;
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		default:
			return 11;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {gN: d, hL: m, jw: y};
			}
		}
	});
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0;
	var y = $justinmimbs$date$Date$year(rd);
	return {
		fp: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		jw: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0;
	var date = $justinmimbs$date$Date$toOrdinalDate(rd);
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.jw, 0, date.fp);
};
var $justinmimbs$date$Date$add = F3(
	function (unit, n, _v0) {
		var rd = _v0;
		switch (unit) {
			case 0:
				return A3($justinmimbs$date$Date$add, 1, 12 * n, rd);
			case 1:
				var date = $justinmimbs$date$Date$toCalendarDate(rd);
				var wholeMonths = ((12 * (date.jw - 1)) + ($justinmimbs$date$Date$monthToNumber(date.hL) - 1)) + n;
				var m = $justinmimbs$date$Date$numberToMonth(
					A2($elm$core$Basics$modBy, 12, wholeMonths) + 1);
				var y = A2($justinmimbs$date$Date$floorDiv, wholeMonths, 12) + 1;
				return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A2(
					$elm$core$Basics$min,
					date.gN,
					A2($justinmimbs$date$Date$daysInMonth, y, m));
			case 2:
				return rd + (7 * n);
			default:
				return rd + n;
		}
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
			$elm$core$Basics$clamp,
			1,
			A2($justinmimbs$date$Date$daysInMonth, y, m),
			d);
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $justinmimbs$date$Date$toRataDie = function (_v0) {
	var rd = _v0;
	return rd;
};
var $justinmimbs$time_extra$Time$Extra$dateToMillis = function (date) {
	var daysSinceEpoch = $justinmimbs$date$Date$toRataDie(date) - 719163;
	return daysSinceEpoch * 86400000;
};
var $justinmimbs$time_extra$Time$Extra$timeFromClock = F4(
	function (hour, minute, second, millisecond) {
		return (((hour * 3600000) + (minute * 60000)) + (second * 1000)) + millisecond;
	});
var $justinmimbs$time_extra$Time$Extra$timeFromPosix = F2(
	function (zone, posix) {
		return A4(
			$justinmimbs$time_extra$Time$Extra$timeFromClock,
			A2($elm$time$Time$toHour, zone, posix),
			A2($elm$time$Time$toMinute, zone, posix),
			A2($elm$time$Time$toSecond, zone, posix),
			A2($elm$time$Time$toMillis, zone, posix));
	});
var $justinmimbs$time_extra$Time$Extra$toOffset = F2(
	function (zone, posix) {
		var millis = $elm$time$Time$posixToMillis(posix);
		var localMillis = $justinmimbs$time_extra$Time$Extra$dateToMillis(
			A2($justinmimbs$date$Date$fromPosix, zone, posix)) + A2($justinmimbs$time_extra$Time$Extra$timeFromPosix, zone, posix);
		return ((localMillis - millis) / 60000) | 0;
	});
var $justinmimbs$time_extra$Time$Extra$posixFromDateTime = F3(
	function (zone, date, time) {
		var millis = $justinmimbs$time_extra$Time$Extra$dateToMillis(date) + time;
		var offset0 = A2(
			$justinmimbs$time_extra$Time$Extra$toOffset,
			zone,
			$elm$time$Time$millisToPosix(millis));
		var posix1 = $elm$time$Time$millisToPosix(millis - (offset0 * 60000));
		var offset1 = A2($justinmimbs$time_extra$Time$Extra$toOffset, zone, posix1);
		if (_Utils_eq(offset0, offset1)) {
			return posix1;
		} else {
			var posix2 = $elm$time$Time$millisToPosix(millis - (offset1 * 60000));
			var offset2 = A2($justinmimbs$time_extra$Time$Extra$toOffset, zone, posix2);
			return _Utils_eq(offset1, offset2) ? posix2 : posix1;
		}
	});
var $justinmimbs$time_extra$Time$Extra$add = F4(
	function (interval, n, zone, posix) {
		add:
		while (true) {
			switch (interval) {
				case 15:
					return $elm$time$Time$millisToPosix(
						$elm$time$Time$posixToMillis(posix) + n);
				case 14:
					var $temp$interval = 15,
						$temp$n = n * 1000,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 13:
					var $temp$interval = 15,
						$temp$n = n * 60000,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 12:
					var $temp$interval = 15,
						$temp$n = n * 3600000,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 11:
					return A3(
						$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
						zone,
						A3(
							$justinmimbs$date$Date$add,
							3,
							n,
							A2($justinmimbs$date$Date$fromPosix, zone, posix)),
						A2($justinmimbs$time_extra$Time$Extra$timeFromPosix, zone, posix));
				case 2:
					return A3(
						$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
						zone,
						A3(
							$justinmimbs$date$Date$add,
							1,
							n,
							A2($justinmimbs$date$Date$fromPosix, zone, posix)),
						A2($justinmimbs$time_extra$Time$Extra$timeFromPosix, zone, posix));
				case 0:
					var $temp$interval = 2,
						$temp$n = n * 12,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 1:
					var $temp$interval = 2,
						$temp$n = n * 3,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 3:
					var $temp$interval = 11,
						$temp$n = n * 7,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				default:
					var weekday = interval;
					var $temp$interval = 11,
						$temp$n = n * 7,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
			}
		}
	});
var $justinmimbs$time_extra$Time$Extra$Week = 3;
var $justinmimbs$date$Date$Day = 11;
var $justinmimbs$date$Date$Friday = 8;
var $justinmimbs$date$Date$Monday = 4;
var $justinmimbs$date$Date$Month = 2;
var $justinmimbs$date$Date$Quarter = 1;
var $justinmimbs$date$Date$Saturday = 9;
var $justinmimbs$date$Date$Sunday = 10;
var $justinmimbs$date$Date$Thursday = 7;
var $justinmimbs$date$Date$Tuesday = 5;
var $justinmimbs$date$Date$Wednesday = 6;
var $justinmimbs$date$Date$Week = 3;
var $justinmimbs$date$Date$Year = 0;
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$weekdayToNumber = function (wd) {
	switch (wd) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		default:
			return 7;
	}
};
var $justinmimbs$date$Date$daysSincePreviousWeekday = F2(
	function (wd, date) {
		return A2(
			$elm$core$Basics$modBy,
			7,
			($justinmimbs$date$Date$weekdayNumber(date) + 7) - $justinmimbs$date$Date$weekdayToNumber(wd));
	});
var $justinmimbs$date$Date$firstOfMonth = F2(
	function (y, m) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + 1;
	});
var $justinmimbs$date$Date$firstOfYear = function (y) {
	return $justinmimbs$date$Date$daysBeforeYear(y) + 1;
};
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.hL;
	});
var $justinmimbs$date$Date$monthToQuarter = function (m) {
	return (($justinmimbs$date$Date$monthToNumber(m) + 2) / 3) | 0;
};
var $justinmimbs$date$Date$quarter = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToQuarter);
var $justinmimbs$date$Date$quarterToMonth = function (q) {
	return $justinmimbs$date$Date$numberToMonth((q * 3) - 2);
};
var $justinmimbs$date$Date$floor = F2(
	function (interval, date) {
		var rd = date;
		switch (interval) {
			case 0:
				return $justinmimbs$date$Date$firstOfYear(
					$justinmimbs$date$Date$year(date));
			case 1:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$quarterToMonth(
						$justinmimbs$date$Date$quarter(date)));
			case 2:
				return A2(
					$justinmimbs$date$Date$firstOfMonth,
					$justinmimbs$date$Date$year(date),
					$justinmimbs$date$Date$month(date));
			case 3:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 4:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 0, date);
			case 5:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 1, date);
			case 6:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 2, date);
			case 7:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 3, date);
			case 8:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 4, date);
			case 9:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 5, date);
			case 10:
				return rd - A2($justinmimbs$date$Date$daysSincePreviousWeekday, 6, date);
			default:
				return date;
		}
	});
var $justinmimbs$time_extra$Time$Extra$floorDate = F3(
	function (dateInterval, zone, posix) {
		return A3(
			$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
			zone,
			A2(
				$justinmimbs$date$Date$floor,
				dateInterval,
				A2($justinmimbs$date$Date$fromPosix, zone, posix)),
			0);
	});
var $justinmimbs$time_extra$Time$Extra$floor = F3(
	function (interval, zone, posix) {
		switch (interval) {
			case 15:
				return posix;
			case 14:
				return A3(
					$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
					zone,
					A2($justinmimbs$date$Date$fromPosix, zone, posix),
					A4(
						$justinmimbs$time_extra$Time$Extra$timeFromClock,
						A2($elm$time$Time$toHour, zone, posix),
						A2($elm$time$Time$toMinute, zone, posix),
						A2($elm$time$Time$toSecond, zone, posix),
						0));
			case 13:
				return A3(
					$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
					zone,
					A2($justinmimbs$date$Date$fromPosix, zone, posix),
					A4(
						$justinmimbs$time_extra$Time$Extra$timeFromClock,
						A2($elm$time$Time$toHour, zone, posix),
						A2($elm$time$Time$toMinute, zone, posix),
						0,
						0));
			case 12:
				return A3(
					$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
					zone,
					A2($justinmimbs$date$Date$fromPosix, zone, posix),
					A4(
						$justinmimbs$time_extra$Time$Extra$timeFromClock,
						A2($elm$time$Time$toHour, zone, posix),
						0,
						0,
						0));
			case 11:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 11, zone, posix);
			case 2:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 2, zone, posix);
			case 0:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 0, zone, posix);
			case 1:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 1, zone, posix);
			case 3:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 3, zone, posix);
			case 4:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 4, zone, posix);
			case 5:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 5, zone, posix);
			case 6:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 6, zone, posix);
			case 7:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 7, zone, posix);
			case 8:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 8, zone, posix);
			case 9:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 9, zone, posix);
			default:
				return A3($justinmimbs$time_extra$Time$Extra$floorDate, 10, zone, posix);
		}
	});
var $justinmimbs$time_extra$Time$Extra$ceiling = F3(
	function (interval, zone, posix) {
		var floored = A3($justinmimbs$time_extra$Time$Extra$floor, interval, zone, posix);
		return _Utils_eq(floored, posix) ? posix : A4($justinmimbs$time_extra$Time$Extra$add, interval, 1, zone, floored);
	});
var $terezka$intervals$Intervals$Time$ceilingDay = F3(
	function (zone, mult, stamp) {
		return (mult === 7) ? A3($justinmimbs$time_extra$Time$Extra$ceiling, 3, zone, stamp) : A3($justinmimbs$time_extra$Time$Extra$ceiling, 11, zone, stamp);
	});
var $justinmimbs$time_extra$Time$Extra$Hour = 12;
var $justinmimbs$time_extra$Time$Extra$partsToPosix = F2(
	function (zone, _v0) {
		var year = _v0.jw;
		var month = _v0.hL;
		var day = _v0.gN;
		var hour = _v0.e$;
		var minute = _v0.fg;
		var second = _v0.fD;
		var millisecond = _v0.ff;
		return A3(
			$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
			zone,
			A3($justinmimbs$date$Date$fromCalendarDate, year, month, day),
			A4(
				$justinmimbs$time_extra$Time$Extra$timeFromClock,
				A3($elm$core$Basics$clamp, 0, 23, hour),
				A3($elm$core$Basics$clamp, 0, 59, minute),
				A3($elm$core$Basics$clamp, 0, 59, second),
				A3($elm$core$Basics$clamp, 0, 999, millisecond)));
	});
var $justinmimbs$time_extra$Time$Extra$posixToParts = F2(
	function (zone, posix) {
		return {
			gN: A2($elm$time$Time$toDay, zone, posix),
			e$: A2($elm$time$Time$toHour, zone, posix),
			ff: A2($elm$time$Time$toMillis, zone, posix),
			fg: A2($elm$time$Time$toMinute, zone, posix),
			hL: A2($elm$time$Time$toMonth, zone, posix),
			fD: A2($elm$time$Time$toSecond, zone, posix),
			jw: A2($elm$time$Time$toYear, zone, posix)
		};
	});
var $terezka$intervals$Intervals$Time$ceilingHour = F3(
	function (zone, mult, stamp) {
		var parts = A2(
			$justinmimbs$time_extra$Time$Extra$posixToParts,
			zone,
			A3($justinmimbs$time_extra$Time$Extra$ceiling, 12, zone, stamp));
		var rem = parts.e$ % mult;
		var _new = A2($justinmimbs$time_extra$Time$Extra$partsToPosix, zone, parts);
		return (!rem) ? _new : A4($justinmimbs$time_extra$Time$Extra$add, 12, mult - rem, zone, _new);
	});
var $justinmimbs$time_extra$Time$Extra$Minute = 13;
var $terezka$intervals$Intervals$Time$ceilingMinute = F3(
	function (zone, mult, stamp) {
		var parts = A2(
			$justinmimbs$time_extra$Time$Extra$posixToParts,
			zone,
			A3($justinmimbs$time_extra$Time$Extra$ceiling, 13, zone, stamp));
		var rem = parts.fg % mult;
		var _new = A2($justinmimbs$time_extra$Time$Extra$partsToPosix, zone, parts);
		return (!rem) ? _new : A4($justinmimbs$time_extra$Time$Extra$add, 13, mult - rem, zone, _new);
	});
var $terezka$intervals$Intervals$Time$intAsMonth = function (_int) {
	switch (_int) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		case 12:
			return 11;
		default:
			return 11;
	}
};
var $terezka$intervals$Intervals$Time$monthAsInt = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $terezka$intervals$Intervals$Time$ceilingMonth = F3(
	function (zone, mult, stamp) {
		var parts = A2(
			$justinmimbs$time_extra$Time$Extra$posixToParts,
			zone,
			A3($justinmimbs$time_extra$Time$Extra$ceiling, 2, zone, stamp));
		var monthInt = $terezka$intervals$Intervals$Time$monthAsInt(parts.hL);
		var rem = (monthInt - 1) % mult;
		var newMonth = (!rem) ? monthInt : ((monthInt - rem) + mult);
		return A2(
			$justinmimbs$time_extra$Time$Extra$partsToPosix,
			zone,
			(newMonth > 12) ? _Utils_update(
				parts,
				{
					hL: $terezka$intervals$Intervals$Time$intAsMonth(newMonth - 12),
					jw: parts.jw + 1
				}) : _Utils_update(
				parts,
				{
					hL: $terezka$intervals$Intervals$Time$intAsMonth(newMonth)
				}));
	});
var $terezka$intervals$Intervals$Time$ceilingMs = F3(
	function (zone, mult, stamp) {
		var parts = A2($justinmimbs$time_extra$Time$Extra$posixToParts, zone, stamp);
		var rem = parts.ff % mult;
		return (!rem) ? A2($justinmimbs$time_extra$Time$Extra$partsToPosix, zone, parts) : A4($justinmimbs$time_extra$Time$Extra$add, 15, mult - rem, zone, stamp);
	});
var $justinmimbs$time_extra$Time$Extra$Second = 14;
var $terezka$intervals$Intervals$Time$ceilingSecond = F3(
	function (zone, mult, stamp) {
		var parts = A2(
			$justinmimbs$time_extra$Time$Extra$posixToParts,
			zone,
			A3($justinmimbs$time_extra$Time$Extra$ceiling, 14, zone, stamp));
		var rem = parts.fD % mult;
		var _new = A2($justinmimbs$time_extra$Time$Extra$partsToPosix, zone, parts);
		return (!rem) ? _new : A4($justinmimbs$time_extra$Time$Extra$add, 14, mult - rem, zone, _new);
	});
var $justinmimbs$time_extra$Time$Extra$Year = 0;
var $terezka$intervals$Intervals$Time$ceilingYear = F3(
	function (zone, mult, stamp) {
		var parts = A2(
			$justinmimbs$time_extra$Time$Extra$posixToParts,
			zone,
			A3($justinmimbs$time_extra$Time$Extra$ceiling, 0, zone, stamp));
		var rem = parts.jw % mult;
		var newYear = (!rem) ? parts.jw : ((parts.jw - rem) + mult);
		return A2(
			$justinmimbs$time_extra$Time$Extra$partsToPosix,
			zone,
			_Utils_update(
				parts,
				{jw: newYear}));
	});
var $terezka$intervals$Intervals$Time$ceilingUnit = F3(
	function (zone, unit, mult) {
		switch (unit) {
			case 0:
				return A2($terezka$intervals$Intervals$Time$ceilingMs, zone, mult);
			case 1:
				return A2($terezka$intervals$Intervals$Time$ceilingSecond, zone, mult);
			case 2:
				return A2($terezka$intervals$Intervals$Time$ceilingMinute, zone, mult);
			case 3:
				return A2($terezka$intervals$Intervals$Time$ceilingHour, zone, mult);
			case 4:
				return A2($terezka$intervals$Intervals$Time$ceilingDay, zone, mult);
			case 5:
				return A2($terezka$intervals$Intervals$Time$ceilingMonth, zone, mult);
			default:
				return A2($terezka$intervals$Intervals$Time$ceilingYear, zone, mult);
		}
	});
var $terezka$intervals$Intervals$Time$Day = 4;
var $terezka$intervals$Intervals$Time$Hour = 3;
var $terezka$intervals$Intervals$Time$Millisecond = 0;
var $terezka$intervals$Intervals$Time$Minute = 2;
var $terezka$intervals$Intervals$Time$Month = 5;
var $terezka$intervals$Intervals$Time$Second = 1;
var $terezka$intervals$Intervals$Time$Year = 6;
var $terezka$intervals$Intervals$Time$getChange = F3(
	function (zone, a, b) {
		var bP = A2($justinmimbs$time_extra$Time$Extra$posixToParts, zone, b);
		var aP = A2($justinmimbs$time_extra$Time$Extra$posixToParts, zone, a);
		return (!_Utils_eq(aP.jw, bP.jw)) ? 6 : ((!_Utils_eq(aP.hL, bP.hL)) ? 5 : ((!_Utils_eq(aP.gN, bP.gN)) ? 4 : ((!_Utils_eq(aP.e$, bP.e$)) ? 3 : ((!_Utils_eq(aP.fg, bP.fg)) ? 2 : ((!_Utils_eq(aP.fD, bP.fD)) ? 1 : 0)))));
	});
var $danhandrea$elm_time_extra$Util$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 400, year)) || ((!(!A2($elm$core$Basics$modBy, 100, year))) && (!A2($elm$core$Basics$modBy, 4, year)));
};
var $danhandrea$elm_time_extra$Month$days = F2(
	function (year, month) {
		switch (month) {
			case 0:
				return 31;
			case 1:
				return $danhandrea$elm_time_extra$Util$isLeapYear(year) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $danhandrea$elm_time_extra$TimeExtra$daysInMonth = $danhandrea$elm_time_extra$Month$days;
var $terezka$intervals$Intervals$Time$toMs = $elm$time$Time$posixToMillis;
var $terezka$intervals$Intervals$Time$getDiff = F3(
	function (zone, a, b) {
		var _v0 = (_Utils_cmp(
			$terezka$intervals$Intervals$Time$toMs(a),
			$terezka$intervals$Intervals$Time$toMs(b)) < 0) ? _Utils_Tuple2(
			A2($justinmimbs$time_extra$Time$Extra$posixToParts, zone, a),
			A2($justinmimbs$time_extra$Time$Extra$posixToParts, zone, b)) : _Utils_Tuple2(
			A2($justinmimbs$time_extra$Time$Extra$posixToParts, zone, b),
			A2($justinmimbs$time_extra$Time$Extra$posixToParts, zone, a));
		var aP = _v0.a;
		var bP = _v0.b;
		var dMsX = bP.ff - aP.ff;
		var dMs = (dMsX < 0) ? (1000 + dMsX) : dMsX;
		var dSecondX = (bP.fD - aP.fD) + ((dMsX < 0) ? (-1) : 0);
		var dMinuteX = (bP.fg - aP.fg) + ((dSecondX < 0) ? (-1) : 0);
		var dHourX = (bP.e$ - aP.e$) + ((dMinuteX < 0) ? (-1) : 0);
		var dDayX = (bP.gN - aP.gN) + ((dHourX < 0) ? (-1) : 0);
		var dDay = (dDayX < 0) ? (A2($danhandrea$elm_time_extra$TimeExtra$daysInMonth, bP.jw, bP.hL) + dDayX) : dDayX;
		var dMonthX = ($terezka$intervals$Intervals$Time$monthAsInt(bP.hL) - $terezka$intervals$Intervals$Time$monthAsInt(aP.hL)) + ((dDayX < 0) ? (-1) : 0);
		var dMonth = (dMonthX < 0) ? (12 + dMonthX) : dMonthX;
		var dHour = (dHourX < 0) ? (24 + dHourX) : dHourX;
		var dMinute = (dMinuteX < 0) ? (60 + dMinuteX) : dMinuteX;
		var dSecond = (dSecondX < 0) ? (60 + dSecondX) : dSecondX;
		var dYearX = (bP.jw - aP.jw) + ((dMonthX < 0) ? (-1) : 0);
		var dYear = (dYearX < 0) ? ($terezka$intervals$Intervals$Time$monthAsInt(bP.hL) + dYearX) : dYearX;
		return {gN: dDay, e$: dHour, ff: dMs, fg: dMinute, hL: dMonth, fD: dSecond, jw: dYear};
	});
var $terezka$intervals$Intervals$Time$oneSecond = 1000;
var $terezka$intervals$Intervals$Time$oneMinute = $terezka$intervals$Intervals$Time$oneSecond * 60;
var $terezka$intervals$Intervals$Time$oneHour = $terezka$intervals$Intervals$Time$oneMinute * 60;
var $terezka$intervals$Intervals$Time$oneDay = $terezka$intervals$Intervals$Time$oneHour * 24;
var $terezka$intervals$Intervals$Time$oneMs = 1;
var $terezka$intervals$Intervals$Time$getNumOfTicks = F5(
	function (zone, unit, mult, a, b) {
		var div = F2(
			function (n1, n2) {
				return $elm$core$Basics$floor(n1 / n2);
			});
		var timeDiff = function (ms) {
			var ceiled = A4($terezka$intervals$Intervals$Time$ceilingUnit, zone, unit, mult, a);
			return (_Utils_cmp(
				$terezka$intervals$Intervals$Time$toMs(ceiled),
				$terezka$intervals$Intervals$Time$toMs(b)) > 0) ? (-1) : A2(
				div,
				A2(
					div,
					$terezka$intervals$Intervals$Time$toMs(b) - $terezka$intervals$Intervals$Time$toMs(ceiled),
					ms),
				mult);
		};
		var diff = function (property) {
			var ceiled = A4($terezka$intervals$Intervals$Time$ceilingUnit, zone, unit, mult, a);
			return (_Utils_cmp(
				$terezka$intervals$Intervals$Time$toMs(ceiled),
				$terezka$intervals$Intervals$Time$toMs(b)) > 0) ? (-1) : A2(
				div,
				property(
					A3($terezka$intervals$Intervals$Time$getDiff, zone, ceiled, b)),
				mult);
		};
		switch (unit) {
			case 0:
				return timeDiff($terezka$intervals$Intervals$Time$oneMs) + 1;
			case 1:
				return timeDiff($terezka$intervals$Intervals$Time$oneSecond) + 1;
			case 2:
				return timeDiff($terezka$intervals$Intervals$Time$oneMinute) + 1;
			case 3:
				return timeDiff($terezka$intervals$Intervals$Time$oneHour) + 1;
			case 4:
				return timeDiff($terezka$intervals$Intervals$Time$oneDay) + 1;
			case 5:
				return diff(
					function (d) {
						return d.hL + (d.jw * 12);
					}) + 1;
			default:
				return diff(
					function ($) {
						return $.jw;
					}) + 1;
		}
	});
var $terezka$intervals$Intervals$Time$largerUnit = function (unit) {
	switch (unit) {
		case 0:
			return $elm$core$Maybe$Just(1);
		case 1:
			return $elm$core$Maybe$Just(2);
		case 2:
			return $elm$core$Maybe$Just(3);
		case 3:
			return $elm$core$Maybe$Just(4);
		case 4:
			return $elm$core$Maybe$Just(5);
		case 5:
			return $elm$core$Maybe$Just(6);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $terezka$intervals$Intervals$Time$niceMultiples = function (unit) {
	switch (unit) {
		case 0:
			return _List_fromArray(
				[1, 2, 5, 10, 20, 25, 50, 100, 200, 500]);
		case 1:
			return _List_fromArray(
				[1, 2, 5, 10, 15, 30]);
		case 2:
			return _List_fromArray(
				[1, 2, 5, 10, 15, 30]);
		case 3:
			return _List_fromArray(
				[1, 2, 3, 4, 6, 8, 12]);
		case 4:
			return _List_fromArray(
				[1, 2, 3, 7, 14]);
		case 5:
			return _List_fromArray(
				[1, 2, 3, 4, 6]);
		default:
			return _List_fromArray(
				[1, 2, 5, 10, 20, 25, 50, 100, 200, 500, 1000, 10000, 1000000, 10000000]);
	}
};
var $terezka$intervals$Intervals$Time$toBestUnit = F4(
	function (zone, amount, min, max) {
		var toNice = function (unit) {
			toNice:
			while (true) {
				var niceNums = $terezka$intervals$Intervals$Time$niceMultiples(unit);
				var maybeNiceNum = A2(
					$elm$core$List$filter,
					function (n) {
						return _Utils_cmp(
							A5($terezka$intervals$Intervals$Time$getNumOfTicks, zone, unit, n, min, max),
							amount) < 1;
					},
					niceNums);
				var div = F2(
					function (n1, n2) {
						return $elm$core$Basics$ceiling(n1 / n2);
					});
				var _v0 = $elm$core$List$head(maybeNiceNum);
				if (!_v0.$) {
					var niceNum = _v0.a;
					return _Utils_Tuple2(unit, niceNum);
				} else {
					var _v1 = $terezka$intervals$Intervals$Time$largerUnit(unit);
					if (!_v1.$) {
						var larger = _v1.a;
						var $temp$unit = larger;
						unit = $temp$unit;
						continue toNice;
					} else {
						return _Utils_Tuple2(6, 100000000);
					}
				}
			}
		};
		return toNice(0);
	});
var $terezka$intervals$Intervals$Time$toExtraUnit = function (unit) {
	switch (unit) {
		case 0:
			return 15;
		case 1:
			return 14;
		case 2:
			return 13;
		case 3:
			return 12;
		case 4:
			return 11;
		case 5:
			return 2;
		default:
			return 0;
	}
};
var $terezka$intervals$Intervals$Time$unitToInt = function (unit) {
	switch (unit) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		case 4:
			return 4;
		case 5:
			return 5;
		default:
			return 6;
	}
};
var $terezka$intervals$Intervals$Time$values = F4(
	function (zone, maxMmount, min, max) {
		var _v0 = A4($terezka$intervals$Intervals$Time$toBestUnit, zone, maxMmount, min, max);
		var unit = _v0.a;
		var mult = _v0.b;
		var amount = A5($terezka$intervals$Intervals$Time$getNumOfTicks, zone, unit, mult, min, max);
		var initial = A4($terezka$intervals$Intervals$Time$ceilingUnit, zone, unit, mult, min);
		var tUnit = $terezka$intervals$Intervals$Time$toExtraUnit(unit);
		var toTick = F3(
			function (x, timestamp, change) {
				return {
					kt: (_Utils_cmp(
						$terezka$intervals$Intervals$Time$unitToInt(change),
						$terezka$intervals$Intervals$Time$unitToInt(unit)) > 0) ? $elm$core$Maybe$Just(change) : $elm$core$Maybe$Nothing,
					e6: !x,
					mN: mult,
					pe: timestamp,
					pw: unit,
					f5: zone
				};
			});
		var toTicks = F2(
			function (xs, acc) {
				toTicks:
				while (true) {
					if (xs.b) {
						var x = xs.a;
						var rest = xs.b;
						var prev = A4($justinmimbs$time_extra$Time$Extra$add, tUnit, (x - 1) * mult, zone, initial);
						var curr = A4($justinmimbs$time_extra$Time$Extra$add, tUnit, x * mult, zone, initial);
						var change = A3($terezka$intervals$Intervals$Time$getChange, zone, prev, curr);
						var $temp$xs = rest,
							$temp$acc = A2(
							$elm$core$List$cons,
							A3(toTick, x, curr, change),
							acc);
						xs = $temp$xs;
						acc = $temp$acc;
						continue toTicks;
					} else {
						return acc;
					}
				}
			});
		return $elm$core$List$reverse(
			A2(
				toTicks,
				A2($elm$core$List$range, 0, amount - 1),
				_List_Nil));
	});
var $terezka$intervals$Intervals$times = F3(
	function (zone, amount, range) {
		var toUnit = function (unit) {
			switch (unit) {
				case 0:
					return 0;
				case 1:
					return 1;
				case 2:
					return 2;
				case 3:
					return 3;
				case 4:
					return 4;
				case 5:
					return 5;
				default:
					return 6;
			}
		};
		var translateUnit = function (time) {
			return {
				kt: A2($elm$core$Maybe$map, toUnit, time.kt),
				e6: time.e6,
				mN: time.mN,
				pe: time.pe,
				pw: toUnit(time.pw),
				f5: time.f5
			};
		};
		var fromMs = function (ts) {
			return $elm$time$Time$millisToPosix(
				$elm$core$Basics$round(ts));
		};
		return A2(
			$elm$core$List$map,
			translateUnit,
			A4(
				$terezka$intervals$Intervals$Time$values,
				zone,
				amount,
				fromMs(range.hI),
				fromMs(range.mB)));
	});
var $terezka$elm_charts$Internal$Svg$times = function (zone) {
	return F2(
		function (i, b) {
			return A3(
				$terezka$intervals$Intervals$times,
				zone,
				i,
				{mB: b.mB, hI: b.hI});
		});
};
var $terezka$elm_charts$Chart$Svg$times = $terezka$elm_charts$Internal$Svg$times;
var $terezka$elm_charts$Chart$generateValues = F4(
	function (amount, tick, maybeFormat, axis) {
		var toTickValues = F2(
			function (toValue, toString) {
				return $elm$core$List$map(
					function (i) {
						return {
							bD: function () {
								if (!maybeFormat.$) {
									var formatter = maybeFormat.a;
									return formatter(
										toValue(i));
								} else {
									return toString(i);
								}
							}(),
							pI: toValue(i)
						};
					});
			});
		switch (tick.$) {
			case 0:
				return A3(
					toTickValues,
					$elm$core$Basics$identity,
					$elm$core$String$fromFloat,
					A3($terezka$elm_charts$Chart$Svg$generate, amount, $terezka$elm_charts$Chart$Svg$floats, axis));
			case 1:
				return A3(
					toTickValues,
					$elm$core$Basics$toFloat,
					$elm$core$String$fromInt,
					A3($terezka$elm_charts$Chart$Svg$generate, amount, $terezka$elm_charts$Chart$Svg$ints, axis));
			default:
				var zone = tick.a;
				return A3(
					toTickValues,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Basics$toFloat, $elm$time$Time$posixToMillis),
						function ($) {
							return $.pe;
						}),
					$terezka$elm_charts$Chart$Svg$formatTime(zone),
					A3(
						$terezka$elm_charts$Chart$Svg$generate,
						amount,
						$terezka$elm_charts$Chart$Svg$times(zone),
						axis));
		}
	});
var $terezka$elm_charts$Chart$Attributes$length = F2(
	function (v, config) {
		return _Utils_update(
			config,
			{z: v});
	});
var $terezka$elm_charts$Internal$Svg$defaultTick = {k: _List_Nil, b0: 'rgb(210, 210, 210)', z: 5, ec: 1};
var $terezka$elm_charts$Internal$Svg$tick = F4(
	function (plane, config, isX, point) {
		return A4(
			$terezka$elm_charts$Internal$Svg$withAttrs,
			config.k,
			$elm$svg$Svg$line,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('elm-charts__tick'),
					$elm$svg$Svg$Attributes$stroke(config.b0),
					$elm$svg$Svg$Attributes$strokeWidth(
					$elm$core$String$fromFloat(config.ec)),
					$elm$svg$Svg$Attributes$x1(
					$elm$core$String$fromFloat(
						A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, point.jv))),
					$elm$svg$Svg$Attributes$x2(
					$elm$core$String$fromFloat(
						A2($terezka$elm_charts$Internal$Coordinates$toSVGX, plane, point.jv) + (isX ? 0 : (-config.z)))),
					$elm$svg$Svg$Attributes$y1(
					$elm$core$String$fromFloat(
						A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, point.pS))),
					$elm$svg$Svg$Attributes$y2(
					$elm$core$String$fromFloat(
						A2($terezka$elm_charts$Internal$Coordinates$toSVGY, plane, point.pS) + (isX ? config.z : 0)))
				]),
			_List_Nil);
	});
var $terezka$elm_charts$Internal$Svg$xTick = F3(
	function (plane, config, point) {
		return A4($terezka$elm_charts$Internal$Svg$tick, plane, config, true, point);
	});
var $terezka$elm_charts$Chart$Svg$xTick = F2(
	function (plane, edits) {
		return A2(
			$terezka$elm_charts$Internal$Svg$xTick,
			plane,
			A2($terezka$elm_charts$Internal$Helpers$apply, edits, $terezka$elm_charts$Internal$Svg$defaultTick));
	});
var $terezka$elm_charts$Chart$Attributes$zero = function (b) {
	return A3($elm$core$Basics$clamp, b.hI, b.mB, 0);
};
var $terezka$elm_charts$Chart$xTicks = function (edits) {
	var config = A2(
		$terezka$elm_charts$Internal$Helpers$apply,
		edits,
		{ar: 5, b0: '', m: false, aw: $terezka$elm_charts$Internal$Svg$Floats, lH: true, he: 5, Q: _List_Nil, B: $terezka$elm_charts$Chart$Attributes$zero, ec: 1});
	var toTicks = function (p) {
		return A2(
			$elm$core$List$map,
			function ($) {
				return $.pI;
			},
			A4(
				$terezka$elm_charts$Chart$generateValues,
				config.ar,
				config.aw,
				$elm$core$Maybe$Nothing,
				A3(
					$elm$core$List$foldl,
					F2(
						function (f, x) {
							return f(x);
						}),
					p.jv,
					config.Q)));
	};
	var addTickValues = F2(
		function (p, ts) {
			return (!config.lH) ? ts : _Utils_update(
				ts,
				{
					ac: _Utils_ap(
						ts.ac,
						toTicks(p))
				});
		});
	return A2(
		$terezka$elm_charts$Chart$TicksElement,
		addTickValues,
		function (p) {
			var toTick = function (x) {
				return A3(
					$terezka$elm_charts$Chart$Svg$xTick,
					p,
					_List_fromArray(
						[
							$terezka$elm_charts$Chart$Attributes$color(config.b0),
							$terezka$elm_charts$Chart$Attributes$length(
							config.m ? (-config.he) : config.he),
							$terezka$elm_charts$Chart$Attributes$width(config.ec)
						]),
					{
						jv: x,
						pS: config.B(p.pS)
					});
			};
			return A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$class('elm-charts__x-ticks')
					]),
				A2(
					$elm$core$List$map,
					toTick,
					toTicks(p)));
		});
};
var $terezka$elm_charts$Internal$Svg$End = 0;
var $terezka$elm_charts$Chart$LabelsElement = F3(
	function (a, b, c) {
		return {$: 7, a: a, b: b, c: c};
	});
var $terezka$elm_charts$Internal$Svg$Start = 1;
var $terezka$elm_charts$Chart$yLabels = function (edits) {
	var toTicks = F2(
		function (p, config) {
			return A4(
				$terezka$elm_charts$Chart$generateValues,
				config.ar,
				config.aw,
				config.ag,
				A3(
					$elm$core$List$foldl,
					F2(
						function (f, y) {
							return f(y);
						}),
					p.pS,
					config.Q));
		});
	var toTickValues = F3(
		function (p, config, ts) {
			return (!config.lH) ? ts : _Utils_update(
				ts,
				{
					ap: _Utils_ap(
						ts.ap,
						A2(
							$elm$core$List$map,
							function ($) {
								return $.pI;
							},
							A2(toTicks, p, config)))
				});
		});
	var toConfig = function (p) {
		return A2(
			$terezka$elm_charts$Internal$Helpers$apply,
			edits,
			{ar: 5, v: $elm$core$Maybe$Nothing, k: _List_Nil, b0: '#808BAB', w: $elm$core$Maybe$Nothing, m: false, x: $elm$core$Maybe$Nothing, ag: $elm$core$Maybe$Nothing, aw: $terezka$elm_charts$Internal$Svg$Floats, lH: false, y: false, Q: _List_Nil, B: $terezka$elm_charts$Chart$Attributes$zero, C: 0, E: false, p: -10, q: 3});
	};
	return A3(
		$terezka$elm_charts$Chart$LabelsElement,
		toConfig,
		toTickValues,
		F2(
			function (p, config) {
				var _default = $terezka$elm_charts$Internal$Svg$defaultLabel;
				var toLabel = function (item) {
					return A4(
						$terezka$elm_charts$Internal$Svg$label,
						p,
						_Utils_update(
							_default,
							{
								v: function () {
									var _v0 = config.v;
									if (_v0.$ === 1) {
										return $elm$core$Maybe$Just(
											config.m ? 1 : 0);
									} else {
										var anchor = _v0.a;
										return $elm$core$Maybe$Just(anchor);
									}
								}(),
								k: config.k,
								b0: config.b0,
								w: config.w,
								x: config.x,
								y: config.y,
								C: config.C,
								E: config.E,
								p: config.m ? (-config.p) : config.p,
								q: config.q
							}),
						_List_fromArray(
							[
								$elm$svg$Svg$text(item.bD)
							]),
						{
							jv: config.B(p.jv),
							pS: item.pI
						});
				};
				return A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('elm-charts__y-labels')
						]),
					A2(
						$elm$core$List$map,
						toLabel,
						A2(toTicks, p, config)));
			}));
};
var $author$project$Page$AdaptiveAnalytics$chartArea = function (model) {
	var _v0 = model.bl;
	if (_v0.$ === 1) {
		return $mdgriffith$elm_ui$Element$none;
	} else {
		var aggregateResultsTuple = _v0.a;
		var resultBinTypeMaybe = A2(
			$elm$core$Maybe$map,
			$author$project$Page$AdaptiveAnalytics$getResultBinType(model.aV),
			model.bX);
		var isReportCardBin = function () {
			if ((!resultBinTypeMaybe.$) && (!resultBinTypeMaybe.a.$)) {
				return true;
			} else {
				return false;
			}
		}();
		var makeBars = function (resultMoment) {
			var _v5 = function () {
				if (!resultMoment) {
					return _Utils_Tuple2(
						function ($) {
							return $.nw;
						},
						'green');
				} else {
					return _Utils_Tuple2(
						function ($) {
							return $.mL;
						},
						'blue');
				}
			}();
			var accessorFn = _v5.a;
			var color = _v5.b;
			return _List_fromArray(
				[
					$terezka$elm_charts$Chart$stacked(
					isReportCardBin ? _List_fromArray(
						[
							A2(
							$terezka$elm_charts$Chart$named,
							'',
							A2(
								$terezka$elm_charts$Chart$bar,
								A2(
									$elm$core$Basics$composeL,
									A2(
										$elm$core$Basics$composeL,
										$author$project$Type$DataVal$extractVal($elm$core$Maybe$Nothing),
										accessorFn),
									$elm$core$Tuple$second),
								_List_fromArray(
									[
										$terezka$elm_charts$Chart$Attributes$color(color),
										$terezka$elm_charts$Chart$Attributes$opacity(1.0)
									])))
						]) : _List_fromArray(
						[
							A2(
							$terezka$elm_charts$Chart$named,
							'c',
							A2(
								$terezka$elm_charts$Chart$bar,
								A2(
									$elm$core$Basics$composeL,
									A2(
										$elm$core$Basics$composeL,
										$author$project$Type$DataVal$extractVal(
											$elm$core$Maybe$Just(
												function ($) {
													return $.ko;
												})),
										accessorFn),
									$elm$core$Tuple$second),
								_List_fromArray(
									[
										$terezka$elm_charts$Chart$Attributes$color(color),
										$terezka$elm_charts$Chart$Attributes$opacity(1.0)
									]))),
							A2(
							$terezka$elm_charts$Chart$named,
							'b',
							A2(
								$terezka$elm_charts$Chart$bar,
								A2(
									$elm$core$Basics$composeL,
									A2(
										$elm$core$Basics$composeL,
										$author$project$Type$DataVal$extractVal(
											$elm$core$Maybe$Just(
												function ($) {
													return $.gl;
												})),
										accessorFn),
									$elm$core$Tuple$second),
								_List_fromArray(
									[
										$terezka$elm_charts$Chart$Attributes$color(color),
										$terezka$elm_charts$Chart$Attributes$opacity(0.75)
									]))),
							A2(
							$terezka$elm_charts$Chart$named,
							'a',
							A2(
								$terezka$elm_charts$Chart$bar,
								A2(
									$elm$core$Basics$composeL,
									A2(
										$elm$core$Basics$composeL,
										$author$project$Type$DataVal$extractVal(
											$elm$core$Maybe$Just(
												function ($) {
													return $.jy;
												})),
										accessorFn),
									$elm$core$Tuple$second),
								_List_fromArray(
									[
										$terezka$elm_charts$Chart$Attributes$color(color),
										$terezka$elm_charts$Chart$Attributes$opacity(0.5)
									])))
						]))
				]);
		};
		var xAxisTitle = isReportCardBin ? 'Report Result' : 'Band Result (a=low b=mid c=high)';
		var chartData = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				A2($author$project$Type$ChartData$makeChartData, model.cw, aggregateResultsTuple),
				resultBinTypeMaybe));
		var _v1 = function () {
			var _v2 = model.bM;
			if (!_v2.$) {
				if (!_v2.a) {
					var _v3 = _v2.a;
					return _Utils_Tuple2(
						makeBars(0),
						_List_Nil);
				} else {
					var _v4 = _v2.a;
					return _Utils_Tuple2(
						_List_Nil,
						makeBars(1));
				}
			} else {
				return _Utils_Tuple2(
					makeBars(0),
					makeBars(1));
			}
		}();
		var preBars = _v1.a;
		var mostRecBars = _v1.b;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			$mdgriffith$elm_ui$Element$html(
				A2(
					$terezka$elm_charts$Chart$chart,
					_List_fromArray(
						[
							$terezka$elm_charts$Chart$Attributes$height(400),
							$terezka$elm_charts$Chart$Attributes$width(1200),
							$terezka$elm_charts$Chart$Attributes$margin(
							{er: 60, e9: 100, fz: 200, fY: 60})
						]),
					_List_fromArray(
						[
							A2(
							$terezka$elm_charts$Chart$binLabels,
							$elm$core$Tuple$first,
							_List_fromArray(
								[
									$terezka$elm_charts$Chart$Attributes$moveDown(25)
								])),
							$terezka$elm_charts$Chart$xTicks(
							_List_fromArray(
								[$terezka$elm_charts$Chart$Attributes$withGrid])),
							$terezka$elm_charts$Chart$yLabels(
							_List_fromArray(
								[$terezka$elm_charts$Chart$Attributes$withGrid, $terezka$elm_charts$Chart$Attributes$ints])),
							A4(
							$terezka$elm_charts$Chart$labelAt,
							function ($) {
								return $.hI;
							},
							$terezka$elm_charts$Chart$Attributes$middle,
							_List_fromArray(
								[
									$terezka$elm_charts$Chart$Attributes$moveLeft(40),
									$terezka$elm_charts$Chart$Attributes$rotate(90)
								]),
							_List_fromArray(
								[
									$elm$svg$Svg$text(
									(model.cw === 1) ? '# Students' : 'Relative %')
								])),
							A4(
							$terezka$elm_charts$Chart$labelAt,
							$terezka$elm_charts$Chart$Attributes$middle,
							function ($) {
								return $.hI;
							},
							_List_fromArray(
								[
									$terezka$elm_charts$Chart$Attributes$moveDown(50)
								]),
							_List_fromArray(
								[
									$elm$svg$Svg$text(xAxisTitle)
								])),
							A3(
							$terezka$elm_charts$Chart$bars,
							_List_Nil,
							A2($elm$core$List$append, preBars, mostRecBars),
							chartData),
							$terezka$elm_charts$Chart$barLabels(
							_List_fromArray(
								[
									$terezka$elm_charts$Chart$Attributes$moveDown(20),
									$terezka$elm_charts$Chart$Attributes$color('white'),
									$terezka$elm_charts$Chart$Attributes$fontSize(16),
									$terezka$elm_charts$Chart$Attributes$format(
									function (bar) {
										var nbr = $terezka$elm_charts$Chart$Item$getY(bar);
										return ((nbr < 1) || isReportCardBin) ? '' : _Utils_ap(
											A2($elm$core$Basics$composeL, $elm$core$Tuple$first, $terezka$elm_charts$Chart$Item$getData)(bar),
											$terezka$elm_charts$Chart$Item$getName(bar));
									})
								]))
						]))));
	}
};
var $author$project$Page$AdaptiveAnalytics$SetYearLvlFilter = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Element$Border$roundEach = function (_v0) {
	var topLeft = _v0.d8;
	var topRight = _v0.d9;
	var bottomLeft = _v0.de;
	var bottomRight = _v0.df;
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderRound,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Single,
			'br-' + ($elm$core$String$fromInt(topLeft) + ('-' + ($elm$core$String$fromInt(topRight) + ($elm$core$String$fromInt(bottomLeft) + ('-' + $elm$core$String$fromInt(bottomRight)))))),
			'border-radius',
			$elm$core$String$fromInt(topLeft) + ('px ' + ($elm$core$String$fromInt(topRight) + ('px ' + ($elm$core$String$fromInt(bottomRight) + ('px ' + ($elm$core$String$fromInt(bottomLeft) + 'px'))))))));
};
var $author$project$Page$PagesCommon$makeBorderAttrForRadioBtns = function (order) {
	switch (order) {
		case 0:
			return $mdgriffith$elm_ui$Element$Border$roundEach(
				{de: 5, df: 0, d8: 5, d9: 0});
		case 1:
			return $mdgriffith$elm_ui$Element$Border$roundEach(
				{de: 0, df: 0, d8: 0, d9: 0});
		default:
			return $mdgriffith$elm_ui$Element$Border$roundEach(
				{de: 0, df: 5, d8: 0, d9: 5});
	}
};
var $author$project$Page$PagesCommon$filterYearLvlButton = F3(
	function (msgConstructor, filterModel, _v0) {
		var order = _v0.a;
		var cohortMaybe = _v0.b;
		var isActive = _Utils_eq(cohortMaybe, filterModel.bX);
		var btnColor = isActive ? $author$project$Theme$Style$t.jI : $author$project$Theme$Style$t.f6;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_Nil,
			A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color(btnColor),
						$author$project$Page$PagesCommon$makeBorderAttrForRadioBtns(order),
						$mdgriffith$elm_ui$Element$padding(15),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				{
					bD: A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX]),
						$mdgriffith$elm_ui$Element$text(
							A2(
								$elm$core$Maybe$withDefault,
								'All',
								A2($elm$core$Maybe$map, $author$project$Type$Cohort$toGraphQLString, cohortMaybe)))),
					ai: $elm$core$Maybe$Just(
						msgConstructor(cohortMaybe))
				}));
	});
var $author$project$Page$PagesCommon$First = 0;
var $author$project$Page$PagesCommon$Last = 2;
var $author$project$Page$PagesCommon$Middle = 1;
var $author$project$Page$PagesCommon$makeOrderAndItemTuple = function (els) {
	return A2(
		$elm$core$List$indexedMap,
		F2(
			function (idx, el) {
				return _Utils_Tuple2(
					(!idx) ? 0 : (_Utils_eq(
						idx,
						$elm$core$List$length(els) - 1) ? 2 : 1),
					el);
			}),
		els);
};
var $author$project$Page$PagesCommon$filterYearLvlButtons = F4(
	function (isAddAllBtn, msgConstructor, filterModel, cohorts) {
		var btns = A2(
			$elm$core$List$map,
			A2($author$project$Page$PagesCommon$filterYearLvlButton, msgConstructor, filterModel),
			$author$project$Page$PagesCommon$makeOrderAndItemTuple(
				A2(
					$elm$core$List$append,
					isAddAllBtn ? _List_fromArray(
						[$elm$core$Maybe$Nothing]) : _List_Nil,
					A2(
						$elm$core$List$map,
						$elm$core$Maybe$Just,
						A2($elm$core$List$sortBy, $author$project$Type$Cohort$toInt, cohorts)))));
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$paddingEach(
							{er: 3, e9: 0, fz: 0, fY: 2})
						]),
					$mdgriffith$elm_ui$Element$text('Year Level')),
					A2($mdgriffith$elm_ui$Element$row, _List_Nil, btns)
				]));
	});
var $author$project$Page$AdaptiveAnalytics$cohortControl = function (model) {
	return $author$project$Page$AdaptiveAnalytics$makeSelectionArea(
		function () {
			var _v0 = model.eb;
			if (_v0.$ === 1) {
				return $mdgriffith$elm_ui$Element$text('Preparing the year levels...');
			} else {
				var cohorts = _v0.a;
				if (!cohorts.b) {
					return $mdgriffith$elm_ui$Element$text('No student data has yet been created, so no analytics are yet available.');
				} else {
					if (!cohorts.b.b) {
						var cohort = cohorts.a;
						return $mdgriffith$elm_ui$Element$text(
							'Year Level: ' + $author$project$Type$Cohort$toGraphQLString(cohort));
					} else {
						return A4($author$project$Page$PagesCommon$filterYearLvlButtons, false, $author$project$Page$AdaptiveAnalytics$SetYearLvlFilter, model, cohorts);
					}
				}
			}
		}());
};
var $mdgriffith$elm_ui$Internal$Flag$fontWeight = $mdgriffith$elm_ui$Internal$Flag$flag(13);
var $mdgriffith$elm_ui$Element$Font$bold = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontWeight, $mdgriffith$elm_ui$Internal$Style$classes.ke);
var $mdgriffith$elm_ui$Internal$Flag$borderStyle = $mdgriffith$elm_ui$Internal$Flag$flag(11);
var $mdgriffith$elm_ui$Element$Border$dotted = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$borderStyle, $mdgriffith$elm_ui$Internal$Style$classes.kg);
var $author$project$Type$AnalyticsRecord$filterByActivity = F2(
	function (activityData, analyticsRecords) {
		return A2($author$project$Type$AnalyticsRecord$filterByActivityId, activityData.lW, analyticsRecords);
	});
var $author$project$Theme$Style$info = A3($mdgriffith$elm_ui$Element$rgb255, 32, 156, 238);
var $author$project$Type$DateTime$toPosix = A2(
	$elm$core$Basics$composeR,
	$elm$core$Basics$round,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$Basics$mul(1000),
		$elm$time$Time$millisToPosix));
var $author$project$Type$DateTime$toDateOnlyString = function (ts) {
	return A2(
		$elm$core$Maybe$withDefault,
		'UnknownDate',
		A2(
			$elm$core$Maybe$map,
			$author$project$Type$DateTime$posixToDateOnlyString,
			A2(
				$elm$core$Maybe$map,
				$author$project$Type$DateTime$toPosix,
				$elm$core$String$toFloat(ts))));
};
var $author$project$Type$DateTime$tsToTimeString = F2(
	function (inclSecs, date) {
		var secsString = inclSecs ? function (el) {
			return ':' + el;
		}(
			$elm$core$String$fromInt(
				A2($elm$time$Time$toSecond, $author$project$Type$DateTime$tz, date))) : '';
		var dateMinute = A2($elm$time$Time$toMinute, $author$project$Type$DateTime$tz, date);
		var minuteStringRaw = $elm$core$String$fromInt(dateMinute);
		var minuteString = (dateMinute < 10) ? ('0' + minuteStringRaw) : minuteStringRaw;
		var dateHour = A2($elm$time$Time$toHour, $author$project$Type$DateTime$tz, date);
		var hourString = (dateHour === 12) ? '12' : $elm$core$String$fromInt(
			A2($elm$core$Basics$modBy, 12, dateHour));
		var amPm = (dateHour >= 12) ? 'PM' : 'AM';
		return hourString + (':' + (minuteString + (secsString + (' ' + amPm))));
	});
var $author$project$Type$DateTime$toDateString = function (ts) {
	return A2(
		$elm$core$Maybe$withDefault,
		'Unknown date/time',
		A2(
			$elm$core$Maybe$map,
			function (res) {
				return $author$project$Type$DateTime$posixToDateOnlyString(res) + (' (' + (A2($author$project$Type$DateTime$tsToTimeString, false, res) + ')'));
			},
			A2(
				$elm$core$Maybe$map,
				$author$project$Type$DateTime$toPosix,
				$elm$core$String$toFloat(ts))));
};
var $author$project$Type$Cohort$toExpandedString = function (cohort) {
	if (cohort.$ === 1) {
		return 'Minimum Standards';
	} else {
		return 'Year ' + $author$project$Type$Cohort$toAssigneeString(cohort);
	}
};
var $author$project$Type$DateTime$toTimeStringLong = function (ts) {
	return A2(
		$elm$core$Maybe$withDefault,
		'UnknownTime',
		A2(
			$elm$core$Maybe$map,
			$author$project$Type$DateTime$tsToTimeString(false),
			A2(
				$elm$core$Maybe$map,
				$author$project$Type$DateTime$toPosix,
				$elm$core$String$toFloat(ts))));
};
var $mdgriffith$elm_ui$Element$Font$underline = $mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.pv);
var $mdgriffith$elm_ui$Internal$Model$BorderWidth = F5(
	function (a, b, c, d, e) {
		return {$: 6, a: a, b: b, c: c, d: d, e: e};
	});
var $mdgriffith$elm_ui$Element$Border$width = function (v) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderWidth,
		A5(
			$mdgriffith$elm_ui$Internal$Model$BorderWidth,
			'b-' + $elm$core$String$fromInt(v),
			v,
			v,
			v,
			v));
};
var $mdgriffith$elm_ui$Element$Border$widthXY = F2(
	function (x, y) {
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$borderWidth,
			A5(
				$mdgriffith$elm_ui$Internal$Model$BorderWidth,
				'b-' + ($elm$core$String$fromInt(x) + ('-' + $elm$core$String$fromInt(y))),
				y,
				x,
				y,
				x));
	});
var $mdgriffith$elm_ui$Element$Border$widthEach = function (_v0) {
	var bottom = _v0.er;
	var top = _v0.fY;
	var left = _v0.e9;
	var right = _v0.fz;
	return (_Utils_eq(top, bottom) && _Utils_eq(left, right)) ? (_Utils_eq(top, right) ? $mdgriffith$elm_ui$Element$Border$width(top) : A2($mdgriffith$elm_ui$Element$Border$widthXY, left, top)) : A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderWidth,
		A5(
			$mdgriffith$elm_ui$Internal$Model$BorderWidth,
			'b-' + ($elm$core$String$fromInt(top) + ('-' + ($elm$core$String$fromInt(right) + ('-' + ($elm$core$String$fromInt(bottom) + ('-' + $elm$core$String$fromInt(left))))))),
			top,
			right,
			bottom,
			left));
};
var $author$project$Page$AdaptiveAnalytics$displayAllStudentHistory = function (model) {
	var makeSomeRows = function (rec) {
		return A2(
			$elm$core$List$map,
			function (actResult) {
				var _v3 = function () {
					var _v4 = rec.kA;
					if (_v4.$ === 1) {
						var aggRecKey = A2($author$project$Type$ResultBin$makeAggregateRecordKey, $author$project$Type$ResultBin$MinStdBin, actResult.eJ);
						return _Utils_Tuple3(aggRecKey.a, aggRecKey.b, '');
					} else {
						var aggRecKey = A2(
							$author$project$Type$ResultBin$makeAggregateRecordKey,
							$author$project$Type$ResultBin$BandBin(rec.kA),
							actResult.eJ);
						return _Utils_Tuple3(
							aggRecKey.a,
							aggRecKey.b,
							A2(
								$author$project$Type$ResultBin$makeAggregateRecordKey,
								$author$project$Type$ResultBin$ReportCardBin(rec.kA),
								actResult.eJ).a);
					}
				}();
				var majorBandStr = _v3.a;
				var minorBandStr = _v3.b;
				var reportCardStr = _v3.c;
				return $mdgriffith$elm_ui$Element$text(
					A3(
						$elm$core$List$foldr,
						$elm$core$Basics$append,
						'',
						A2(
							$elm$core$List$intersperse,
							',',
							_List_fromArray(
								[
									rec.li,
									rec.lt,
									rec.mq,
									$author$project$Type$DateTime$toDateOnlyString(actResult.pr),
									$author$project$Type$DateTime$toTimeStringLong(actResult.pr),
									majorBandStr,
									minorBandStr,
									reportCardStr,
									A2($elm$core$Maybe$withDefault, 'NoGroup', rec.lI)
								]))));
			},
			A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.pr;
				},
				rec.aP));
	};
	var analyticsRecords = function () {
		var _v2 = model.aE;
		if (_v2.$ === 1) {
			return _List_Nil;
		} else {
			var activityData = _v2.a;
			return A2($author$project$Type$AnalyticsRecord$filterByActivity, activityData, model.a_);
		}
	}();
	return $Punie$elm_reader$Reader$asks(
		function (_v0) {
			if (!_Utils_eq(model.aE, $elm$core$Maybe$Nothing)) {
				var hrElement = A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Border$dotted,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$Border$widthEach(
							{er: 0, e9: 0, fz: 0, fY: 1})
						]),
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$none]));
				return A2(
					$mdgriffith$elm_ui$Element$wrappedRow,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$padding(15),
							$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.gA),
							$mdgriffith$elm_ui$Element$Border$rounded(10),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(10),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$Font$italic, $mdgriffith$elm_ui$Element$Font$bold]),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('Selected history for download to MS Excel or equivalent.')
										])),
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$Font$italic]),
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$paragraph,
											_List_Nil,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$text('Instructions:')
												])),
											A2(
											$mdgriffith$elm_ui$Element$paragraph,
											_List_Nil,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$text('- Copy the data below to your clipboard. They are in a Comma Separated Value (csv) format.')
												])),
											A2(
											$mdgriffith$elm_ui$Element$paragraph,
											_List_Nil,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$text('- You can save them in a file, or paste into a tool like Microsoft Excel for further analysis.')
												])),
											A2(
											$mdgriffith$elm_ui$Element$paragraph,
											_List_Nil,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$text('- e.g. For Excel, paste in the text, then choose \'Data->Text to Columns...\' and specify that the data is comma delimited to align it in cells.')
												])),
											A2(
											$mdgriffith$elm_ui$Element$paragraph,
											_List_Nil,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$text('- Click for video walkthrough: '),
													A2(
													$mdgriffith$elm_ui$Element$newTabLink,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$Font$underline,
															$mdgriffith$elm_ui$Element$Font$bold,
															$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$info)
														]),
													{
														bD: $mdgriffith$elm_ui$Element$text('Student history analysis with pivot charts.'),
														py: 'https://s3.ap-southeast-2.amazonaws.com/support.smarterschool.com.au/wp-content/uploads/2022/12/21111126/SmarterMaths-Online-Data-Export-v7.mp4'
													})
												]))
										])),
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									$elm$core$List$concat(
										_List_fromArray(
											[
												_List_fromArray(
												[
													hrElement,
													$mdgriffith$elm_ui$Element$text(' '),
													$mdgriffith$elm_ui$Element$text('SmarterMaths Online Student Historical Results'),
													$mdgriffith$elm_ui$Element$text(
													'Date of extract: ' + A2($elm$core$Basics$composeL, $author$project$Type$DateTime$toDateString, $author$project$Type$DateTime$posixToDecSecsString)(model.bH)),
													$mdgriffith$elm_ui$Element$text(
													'Course: ' + A2(
														$elm$core$Maybe$withDefault,
														'Unknown',
														A2($elm$core$Maybe$map, $author$project$Type$Cohort$toExpandedString, model.bX))),
													$mdgriffith$elm_ui$Element$text(
													'Activity: ' + function () {
														var _v1 = model.aE;
														if (_v1.$ === 1) {
															return 'Unknown';
														} else {
															var selAct = _v1.a;
															return $author$project$Page$AdaptiveAnalytics$zoneText(selAct.cx.kL) + (' - ' + selAct.ja);
														}
													}()),
													$mdgriffith$elm_ui$Element$text(' '),
													$mdgriffith$elm_ui$Element$text('Email,Student First,Student Last,Date,Time,Major band,Minor band,Report Card,Group')
												]),
												A2($elm$core$List$concatMap, makeSomeRows, analyticsRecords),
												_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$text(' ')
												]),
												_List_fromArray(
												[hrElement])
											])))
								]))
						]));
			} else {
				return $mdgriffith$elm_ui$Element$none;
			}
		});
};
var $author$project$Page$AdaptiveAnalytics$From = 1;
var $author$project$Page$AdaptiveAnalytics$To = 0;
var $author$project$Page$AdaptiveAnalytics$SetDateRange = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $elm$html$Html$Attributes$max = $elm$html$Html$Attributes$stringProperty('max');
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $elm$html$Html$Keyed$node = $elm$virtual_dom$VirtualDom$keyedNode;
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Page$AdaptiveAnalytics$makeDateSelection = F3(
	function (now, dateRole, posix) {
		var roundingPoint = function () {
			if (dateRole === 1) {
				return 0;
			} else {
				return 1;
			}
		}();
		return $mdgriffith$elm_ui$Element$html(
			A3(
				$elm$html$Html$Keyed$node,
				'input',
				_List_fromArray(
					[
						$elm$html$Html$Attributes$type_('date'),
						A2($elm$html$Html$Attributes$style, 'font-size', '18px'),
						$elm$html$Html$Attributes$value(
						$author$project$Type$DateTime$posixToYYYYMMDD(posix)),
						$elm$html$Html$Attributes$name(
						function () {
							if (dateRole === 1) {
								return 'dateFrom';
							} else {
								return 'dateTo';
							}
						}()),
						$elm$html$Html$Attributes$max(
						$author$project$Type$DateTime$posixToYYYYMMDD(now)),
						$elm$html$Html$Events$onInput(
						function (yyyymmddStr) {
							return A2(
								$author$project$Page$AdaptiveAnalytics$SetDateRange,
								dateRole,
								A2(
									$elm$core$Result$withDefault,
									posix,
									A2(
										$elm$core$Result$andThen,
										$rtfeldman$elm_iso8601_date_strings$Iso8601$toTime,
										A2(
											$elm$core$Result$map,
											$author$project$Type$DateTime$posixToYYYYMMDDHHMMSS(roundingPoint),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(yyyymmddStr + 'T00:01:00+10:00')))));
						})
					]),
				_List_Nil));
	});
var $author$project$Page$AdaptiveAnalytics$displayDatesSection = F2(
	function (now, _v0) {
		var from = _v0.a;
		var to = _v0.b;
		return $author$project$Page$AdaptiveAnalytics$makeSelectionArea(
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(20)
					]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('Usage Date Range (click dates to change)'),
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(10),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$Font$italic
							]),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('View student usage from '),
								A3($author$project$Page$AdaptiveAnalytics$makeDateSelection, now, 1, from),
								$mdgriffith$elm_ui$Element$text(' to '),
								A3($author$project$Page$AdaptiveAnalytics$makeDateSelection, now, 0, to)
							]))
					])));
	});
var $author$project$Page$AdaptiveAnalytics$DisplayAsReportCard = 1;
var $author$project$Page$AdaptiveAnalytics$SpecifyDisplayChoice = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$PagesCommon$makeRadioBtnRow = function (items) {
	var makeBtn = function (_v0) {
		var order = _v0.a;
		var item = _v0.b;
		var btnColor = item.b9 ? $author$project$Theme$Style$t.jI : $author$project$Theme$Style$t.f6;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_Nil,
			A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color(btnColor),
						$author$project$Page$PagesCommon$makeBorderAttrForRadioBtns(order),
						$mdgriffith$elm_ui$Element$padding(15),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				{
					bD: A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX]),
						$mdgriffith$elm_ui$Element$text(item.cq)),
					ai: $elm$core$Maybe$Just(
						item.ai(item.cc))
				}));
	};
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_Nil,
		A2(
			$elm$core$List$map,
			makeBtn,
			$author$project$Page$PagesCommon$makeOrderAndItemTuple(items)));
};
var $author$project$Page$AdaptiveAnalytics$resultsFormatArea = function (model) {
	var items = A2(
		$elm$core$List$map,
		function (el) {
			return {
				b9: _Utils_eq(
					model.aV,
					$elm$core$Maybe$Just(el)),
				cc: $elm$core$Maybe$Just(el),
				ai: $author$project$Page$AdaptiveAnalytics$SpecifyDisplayChoice,
				cq: function () {
					if (!el) {
						return 'Band';
					} else {
						return 'Report Card';
					}
				}()
			};
		},
		_List_fromArray(
			[0, 1]));
	return _Utils_eq(model.aV, $elm$core$Maybe$Nothing) ? $mdgriffith$elm_ui$Element$none : A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(10)
			]),
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$text('Results Format'),
				$author$project$Page$PagesCommon$makeRadioBtnRow(items)
			]));
};
var $author$project$Page$AdaptiveAnalytics$Both = {$: 1};
var $author$project$Page$AdaptiveAnalytics$SpecifyResults = function (a) {
	return {$: 4, a: a};
};
var $author$project$Type$AnalyticsResult$resultMomentToString = function (resultMoment) {
	if (!resultMoment) {
		return 'Pre Assessment';
	} else {
		return 'Most Recent';
	}
};
var $author$project$Page$AdaptiveAnalytics$resultsSelectionToString = function (resultsSelection) {
	if (!resultsSelection.$) {
		var resultMoment = resultsSelection.a;
		return $author$project$Type$AnalyticsResult$resultMomentToString(resultMoment);
	} else {
		return 'Both';
	}
};
var $author$project$Page$AdaptiveAnalytics$resultsSelectionArea = function (model) {
	var items = A2(
		$elm$core$List$map,
		function (el) {
			return {
				b9: _Utils_eq(model.bM, el),
				cc: el,
				ai: $author$project$Page$AdaptiveAnalytics$SpecifyResults,
				cq: $author$project$Page$AdaptiveAnalytics$resultsSelectionToString(el)
			};
		},
		_List_fromArray(
			[
				$author$project$Page$AdaptiveAnalytics$Single(0),
				$author$project$Page$AdaptiveAnalytics$Single(1),
				$author$project$Page$AdaptiveAnalytics$Both
			]));
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(10)
			]),
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$text('Results Selection'),
				$author$project$Page$PagesCommon$makeRadioBtnRow(items)
			]));
};
var $author$project$Page$AdaptiveAnalytics$SpecifyYFormat = function (a) {
	return {$: 5, a: a};
};
var $author$project$Type$ChartData$yFormatToString = function (yFormat) {
	if (yFormat === 1) {
		return '# Students';
	} else {
		return 'Relative %';
	}
};
var $author$project$Page$AdaptiveAnalytics$yFormatArea = function (model) {
	var items = A2(
		$elm$core$List$map,
		function (el) {
			return {
				b9: _Utils_eq(model.cw, el),
				cc: el,
				ai: $author$project$Page$AdaptiveAnalytics$SpecifyYFormat,
				cq: $author$project$Type$ChartData$yFormatToString(el)
			};
		},
		_List_fromArray(
			[1, 0]));
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(10)
			]),
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$text('Chart Format'),
				$author$project$Page$PagesCommon$makeRadioBtnRow(items)
			]));
};
var $author$project$Page$AdaptiveAnalytics$displayOptionsSection = function (model) {
	return $author$project$Page$AdaptiveAnalytics$makeSelectionArea(
		A2(
			$mdgriffith$elm_ui$Element$wrappedRow,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(30),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					$author$project$Page$AdaptiveAnalytics$resultsSelectionArea(model),
					$author$project$Page$AdaptiveAnalytics$resultsFormatArea(model),
					$author$project$Page$AdaptiveAnalytics$yFormatArea(model)
				])));
};
var $author$project$Type$AnalyticsRecord$filterByCohortByActivity = F2(
	function (cohort, activity) {
		return A2($author$project$Type$AnalyticsRecord$filterByCohortByActivityId, cohort, activity.lW);
	});
var $author$project$Page$AdaptiveAnalytics$DownloadCSV = F4(
	function (a, b, c, d) {
		return {$: 14, a: a, b: b, c: c, d: d};
	});
var $author$project$Page$AdaptiveAnalytics$StudentDetailsShow = function (a) {
	return {$: 13, a: a};
};
var $author$project$Type$ResultBin$aggRecKeyToString = function (_v0) {
	var band = _v0.a;
	var subBand = _v0.b;
	return _Utils_ap(band, subBand);
};
var $author$project$Page$AdaptiveAnalytics$getAggegateResults = F2(
	function (aggregateResultsTupleMaybe, resultMoment) {
		if (!resultMoment) {
			return aggregateResultsTupleMaybe.a;
		} else {
			return aggregateResultsTupleMaybe.b;
		}
	});
var $author$project$Page$AdaptiveAnalytics$makeStudentExtraDetailOrNone = F3(
	function (model, me, sDetails) {
		var teacherName = $author$project$Type$User$isTeacherEAdminE(me) ? A2(
			$elm$core$Maybe$map,
			function (el) {
				return el.lt + (' ' + el.mq);
			},
			A2($author$project$Type$User$findPersonEDetails, model.cX, sDetails.d$)) : $elm$core$Maybe$Nothing;
		var currentStudentIsHovered = _Utils_eq(
			$elm$core$Maybe$Just(sDetails.lW),
			model.cG);
		if (currentStudentIsHovered) {
			var _v0 = _Utils_Tuple2(sDetails.lI, teacherName);
			if (!_v0.a.$) {
				if (!_v0.b.$) {
					var group = _v0.a.a;
					var teacher = _v0.b.a;
					return $mdgriffith$elm_ui$Element$text(' (Group: ' + (group + (', Teacher: ' + (teacher + ')'))));
				} else {
					var group = _v0.a.a;
					var _v1 = _v0.b;
					return $mdgriffith$elm_ui$Element$text(' (Group: ' + (group + ')'));
				}
			} else {
				if (!_v0.b.$) {
					var _v2 = _v0.a;
					var teacher = _v0.b.a;
					return $mdgriffith$elm_ui$Element$text(' (Teacher: ' + (teacher + ')'));
				} else {
					var _v3 = _v0.a;
					var _v4 = _v0.b;
					return $mdgriffith$elm_ui$Element$none;
				}
			}
		} else {
			return $mdgriffith$elm_ui$Element$none;
		}
	});
var $elm$html$Html$Events$onMouseEnter = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseenter',
		$elm$json$Json$Decode$succeed(msg));
};
var $mdgriffith$elm_ui$Element$Events$onMouseEnter = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Events$onMouseEnter);
var $author$project$Page$AdaptiveAnalytics$resultsListing = function (model) {
	return $Punie$elm_reader$Reader$asks(
		function (rState) {
			var _v0 = model.bl;
			if (_v0.$ === 1) {
				return $mdgriffith$elm_ui$Element$none;
			} else {
				var aggregateResultsTuple = _v0.a;
				var printStudent = function (sDetails) {
					var currentStudentIsHovered = _Utils_eq(
						$elm$core$Maybe$Just(sDetails.lW),
						model.cG);
					return A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 1, 1)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$Input$button,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Background$color(
										currentStudentIsHovered ? $author$project$Theme$Style$t.f6 : $author$project$Page$AdaptiveAnalytics$lightBtnColor),
										$mdgriffith$elm_ui$Element$Border$rounded(5),
										A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$Events$onMouseEnter(
										$author$project$Page$AdaptiveAnalytics$StudentDetailsShow(sDetails.lW))
									]),
								{
									bD: A2(
										$mdgriffith$elm_ui$Element$column,
										_List_Nil,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text(sDetails.lt + (' ' + sDetails.mq)),
												A3($author$project$Page$AdaptiveAnalytics$makeStudentExtraDetailOrNone, model, rState.fc, sDetails)
											])),
									ai: $elm$core$Maybe$Just(
										$author$project$Page$AdaptiveAnalytics$LinkClicked(
											$author$project$Routing$withHash($author$project$Routing$smPaths.oF + ('/' + sDetails.lW))))
								})
							]));
				};
				var makeResults = function (aggregateResults) {
					return A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						A2(
							$elm$core$List$map,
							function (_v3) {
								var binKey = _v3.a;
								var binValues = _v3.b;
								return A2(
									$mdgriffith$elm_ui$Element$row,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$spacing(20),
											A2($mdgriffith$elm_ui$Element$paddingXY, 10, 10),
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$Border$dotted,
											$mdgriffith$elm_ui$Element$Border$widthEach(
											{er: 0, e9: 0, fz: 0, fY: 1})
										]),
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Element$paddingXY, 10, 0),
													$mdgriffith$elm_ui$Element$width(
													A2($mdgriffith$elm_ui$Element$minimum, 60, $mdgriffith$elm_ui$Element$shrink))
												]),
											$mdgriffith$elm_ui$Element$text(
												$author$project$Type$ResultBin$aggRecKeyToString(binKey))),
											A2(
											$mdgriffith$elm_ui$Element$paragraph,
											_List_Nil,
											A2(
												$elm$core$List$map,
												printStudent,
												A2(
													$elm$core$List$sortBy,
													function ($) {
														return $.mq;
													},
													binValues)))
										]));
							},
							function (els) {
								var _v1 = model.aV;
								if (_v1.$ === 1) {
									return A2(
										$elm$core$List$sortWith,
										F2(
											function (el1, el2) {
												return A3($author$project$Type$ResultBin$resultBinSorter, $author$project$Type$ResultBin$MinStdBin, el1.a, el2.a);
											}),
										els);
								} else {
									if (!_v1.a) {
										var _v2 = _v1.a;
										return A2(
											$elm$core$List$sortBy,
											A2(
												$elm$core$Basics$composeL,
												$elm$core$Tuple$mapFirst(
													A2(
														$elm$core$Basics$composeL,
														$elm$core$Maybe$withDefault(99),
														$elm$core$String$toInt)),
												$elm$core$Tuple$first),
											els);
									} else {
										return A2($elm$core$List$sortBy, $elm$core$Tuple$first, els);
									}
								}
							}(
								$elm$core$Dict$toList(aggregateResults))));
				};
				var makeResultsMiniSection = F2(
					function (titleSuffix, aggregateResults) {
						return A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$spacing(10),
									$mdgriffith$elm_ui$Element$padding(10)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$Font$bold, $mdgriffith$elm_ui$Element$Font$italic]),
									$mdgriffith$elm_ui$Element$text('Results - ' + titleSuffix)),
									makeResults(aggregateResults),
									A2(
									$mdgriffith$elm_ui$Element$Input$button,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Background$color($author$project$Page$AdaptiveAnalytics$lightBtnColor),
											$mdgriffith$elm_ui$Element$Border$rounded(5),
											$mdgriffith$elm_ui$Element$padding(10),
											$mdgriffith$elm_ui$Element$alignRight
										]),
									{
										bD: $mdgriffith$elm_ui$Element$text('Download CSV'),
										ai: $elm$core$Maybe$Just(
											A4($author$project$Page$AdaptiveAnalytics$DownloadCSV, rState.fc, titleSuffix, aggregateResults, model.bH))
									})
								]));
					});
				var makeResultsOrNone = function (resultMoment) {
					return (_Utils_eq(model.bM, $author$project$Page$AdaptiveAnalytics$Both) || _Utils_eq(
						model.bM,
						$author$project$Page$AdaptiveAnalytics$Single(resultMoment))) ? A2(
						makeResultsMiniSection,
						$author$project$Type$AnalyticsResult$resultMomentToString(resultMoment),
						A2($author$project$Page$AdaptiveAnalytics$getAggegateResults, aggregateResultsTuple, resultMoment)) : $mdgriffith$elm_ui$Element$none;
				};
				return A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(20)
						]),
					_List_fromArray(
						[
							makeResultsOrNone(0),
							makeResultsOrNone(1)
						]));
			}
		});
};
var $author$project$Page$AdaptiveAnalytics$ByDifficulty = 3;
var $author$project$Page$AdaptiveAnalytics$ByName = 0;
var $author$project$Page$AdaptiveAnalytics$ByNbrSessions = 1;
var $author$project$Page$AdaptiveAnalytics$SortStudentUsageTable = function (a) {
	return {$: 11, a: a};
};
var $author$project$Type$ResultBin$resultBinToShortString = function (resultBin) {
	return $author$project$Type$ResultBin$aggRecKeyToString(
		$author$project$Type$ResultBin$resultBinToAggregateRecordKey(resultBin));
};
var $author$project$Type$ResultBin$toShortString = function (resultBinType) {
	return A2(
		$elm$core$Basics$composeL,
		$author$project$Type$ResultBin$resultBinToShortString,
		$author$project$Type$ResultBin$toResultBinValue(resultBinType));
};
var $author$project$Type$ExamResult$difficultyToImageUrlSpecifier = F2(
	function (cohort, diff_) {
		var makeNapImageUrlFragment = function (napString) {
			var napTag = A2(
				$author$project$Type$ResultBin$toShortString,
				$author$project$Type$ResultBin$BandBin(cohort),
				diff_);
			return '2021/06/nap' + (napString + ('_res_' + napTag));
		};
		if (cohort.$ === 1) {
			var resp = A2($author$project$Type$ResultBin$toShortString, $author$project$Type$ResultBin$MinStdBin, diff_);
			return '2020/11/minstd_res_' + resp;
		} else {
			var stage = cohort.a;
			var yrStr = function () {
				switch (stage.$) {
					case 0:
						return '3';
					case 1:
						return '5';
					case 2:
						return '7';
					default:
						return '9';
				}
			}();
			return makeNapImageUrlFragment(yrStr);
		}
	});
var $author$project$Type$ExamResult$difficultyToImageUrlBase = F2(
	function (cohort, diff) {
		return 'https://teacher.smartermaths.com.au/wp-content/uploads/' + A2($author$project$Type$ExamResult$difficultyToImageUrlSpecifier, cohort, diff);
	});
var $author$project$Type$ExamResult$difficultyToImageUrlSmall = F2(
	function (cohort, diff) {
		return A2($author$project$Type$ExamResult$difficultyToImageUrlBase, cohort, diff) + '.svg';
	});
var $author$project$Type$AnalyticsResult$getMostRecentResultDifficulty = function (results) {
	return A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.eJ;
		},
		$elm$core$List$head(
			$elm$core$List$reverse(
				A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.pr;
					},
					results))));
};
var $mdgriffith$elm_ui$Element$image = F2(
	function (attrs, _v0) {
		var src = _v0.ov;
		var description = _v0.gT;
		var imageAttributes = A2(
			$elm$core$List$filter,
			function (a) {
				switch (a.$) {
					case 7:
						return true;
					case 8:
						return true;
					default:
						return false;
				}
			},
			attrs);
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.lZ),
				attrs),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[
						A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asEl,
						$mdgriffith$elm_ui$Internal$Model$NodeName('img'),
						_Utils_ap(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Internal$Model$Attr(
									$elm$html$Html$Attributes$src(src)),
									$mdgriffith$elm_ui$Internal$Model$Attr(
									$elm$html$Html$Attributes$alt(description))
								]),
							imageAttributes),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(_List_Nil))
					])));
	});
var $mdgriffith$elm_ui$Internal$Model$Px = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Element$px = $mdgriffith$elm_ui$Internal$Model$Px;
var $author$project$UISnippet$Misc$makeResultElement = function (urlMaybe) {
	return A2(
		$elm$core$Maybe$withDefault,
		A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$paddingEach(
					{er: 0, e9: 0, fz: 0, fY: 5})
				]),
			$mdgriffith$elm_ui$Element$text('-')),
		A2(
			$elm$core$Maybe$map,
			function (img) {
				return A2(
					$mdgriffith$elm_ui$Element$image,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(30))
						]),
					{gT: img, ov: img});
			},
			urlMaybe));
};
var $author$project$Page$PagesCommon$headingButton = F2(
	function (isSelected, btnConfig) {
		return A6(
			$author$project$Page$PagesCommon$configurableButton,
			true,
			isSelected ? $author$project$Theme$Style$t.jI : $author$project$Theme$Style$t.f6,
			$author$project$Theme$Style$t.jG,
			_List_Nil,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Element$paddingXY, 10, 7)
				]),
			btnConfig);
	});
var $author$project$Page$AdaptiveAnalytics$makeStudentUsageHeaderButton = F4(
	function (isSelected, msg, attrs, title) {
		return A2(
			$author$project$Page$PagesCommon$headingButton,
			isSelected,
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$el,
					attrs,
					$mdgriffith$elm_ui$Element$text(title)),
				ai: $elm$core$Maybe$Just(msg)
			});
	});
var $author$project$Page$AdaptiveAnalytics$sumQuestions = function (actResults) {
	return A3(
		$elm$core$List$foldr,
		F2(
			function (actResult, acc) {
				return acc + actResult.mV;
			}),
		0,
		actResults);
};
var $author$project$Page$AdaptiveAnalytics$sortAnalyticsRecords = F2(
	function (studentUsageSort, analyticsRecords) {
		switch (studentUsageSort) {
			case 0:
				return A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.mq;
					},
					analyticsRecords);
			case 1:
				return $elm$core$List$reverse(
					A2(
						$elm$core$List$sortBy,
						function (aRec) {
							return $elm$core$List$length(aRec.aP);
						},
						analyticsRecords));
			case 2:
				return $elm$core$List$reverse(
					A2(
						$elm$core$List$sortBy,
						function (aRec) {
							return $author$project$Page$AdaptiveAnalytics$sumQuestions(aRec.aP);
						},
						analyticsRecords));
			default:
				return $elm$core$List$reverse(
					A2(
						$elm$core$List$sortBy,
						function (aRec) {
							return A2(
								$elm$core$Maybe$withDefault,
								0,
								$author$project$Type$AnalyticsResult$getMostRecentResultDifficulty(aRec.aP));
						},
						analyticsRecords));
		}
	});
var $mdgriffith$elm_ui$Element$InternalColumn = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$GridPosition = function (a) {
	return {$: 9, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$GridTemplateStyle = function (a) {
	return {$: 8, a: a};
};
var $mdgriffith$elm_ui$Internal$Model$AsGrid = 3;
var $mdgriffith$elm_ui$Internal$Model$asGrid = 3;
var $mdgriffith$elm_ui$Internal$Model$getSpacing = F2(
	function (attrs, _default) {
		return A2(
			$elm$core$Maybe$withDefault,
			_default,
			A3(
				$elm$core$List$foldr,
				F2(
					function (attr, acc) {
						if (!acc.$) {
							var x = acc.a;
							return $elm$core$Maybe$Just(x);
						} else {
							if ((attr.$ === 4) && (attr.b.$ === 5)) {
								var _v2 = attr.b;
								var x = _v2.b;
								var y = _v2.c;
								return $elm$core$Maybe$Just(
									_Utils_Tuple2(x, y));
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}
					}),
				$elm$core$Maybe$Nothing,
				attrs));
	});
var $mdgriffith$elm_ui$Internal$Flag$gridPosition = $mdgriffith$elm_ui$Internal$Flag$flag(35);
var $mdgriffith$elm_ui$Internal$Flag$gridTemplate = $mdgriffith$elm_ui$Internal$Flag$flag(34);
var $mdgriffith$elm_ui$Element$tableHelper = F2(
	function (attrs, config) {
		var onGrid = F3(
			function (rowLevel, columnLevel, elem) {
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Internal$Model$StyleClass,
							$mdgriffith$elm_ui$Internal$Flag$gridPosition,
							$mdgriffith$elm_ui$Internal$Model$GridPosition(
								{kB: columnLevel, he: 1, n$: rowLevel, ec: 1}))
						]),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[elem])));
			});
		var columnWidth = function (col) {
			if (!col.$) {
				var colConfig = col.a;
				return colConfig.ec;
			} else {
				var colConfig = col.a;
				return colConfig.ec;
			}
		};
		var columnHeader = function (col) {
			if (!col.$) {
				var colConfig = col.a;
				return colConfig.dB;
			} else {
				var colConfig = col.a;
				return colConfig.dB;
			}
		};
		var maybeHeaders = function (headers) {
			return A2(
				$elm$core$List$all,
				$elm$core$Basics$eq($mdgriffith$elm_ui$Internal$Model$Empty),
				headers) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
				A2(
					$elm$core$List$indexedMap,
					F2(
						function (col, header) {
							return A3(onGrid, 1, col + 1, header);
						}),
					headers));
		}(
			A2($elm$core$List$map, columnHeader, config.kC));
		var add = F3(
			function (cell, columnConfig, cursor) {
				if (!columnConfig.$) {
					var col = columnConfig.a;
					return _Utils_update(
						cursor,
						{
							gv: cursor.gv + 1,
							aQ: A2(
								$elm$core$List$cons,
								A3(
									onGrid,
									cursor.n$,
									cursor.gv,
									A2(
										col.pM,
										_Utils_eq(maybeHeaders, $elm$core$Maybe$Nothing) ? (cursor.n$ - 1) : (cursor.n$ - 2),
										cell)),
								cursor.aQ)
						});
				} else {
					var col = columnConfig.a;
					return {
						gv: cursor.gv + 1,
						aQ: A2(
							$elm$core$List$cons,
							A3(
								onGrid,
								cursor.n$,
								cursor.gv,
								col.pM(cell)),
							cursor.aQ),
						n$: cursor.n$
					};
				}
			});
		var build = F3(
			function (columns, rowData, cursor) {
				var newCursor = A3(
					$elm$core$List$foldl,
					add(rowData),
					cursor,
					columns);
				return {gv: 1, aQ: newCursor.aQ, n$: cursor.n$ + 1};
			});
		var children = A3(
			$elm$core$List$foldl,
			build(config.kC),
			{
				gv: 1,
				aQ: _List_Nil,
				n$: _Utils_eq(maybeHeaders, $elm$core$Maybe$Nothing) ? 1 : 2
			},
			config.k_);
		var _v0 = A2(
			$mdgriffith$elm_ui$Internal$Model$getSpacing,
			attrs,
			_Utils_Tuple2(0, 0));
		var sX = _v0.a;
		var sY = _v0.b;
		var template = A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$gridTemplate,
			$mdgriffith$elm_ui$Internal$Model$GridTemplateStyle(
				{
					kC: A2($elm$core$List$map, columnWidth, config.kC),
					n0: A2(
						$elm$core$List$repeat,
						$elm$core$List$length(config.k_),
						$mdgriffith$elm_ui$Internal$Model$Content),
					ou: _Utils_Tuple2(
						$mdgriffith$elm_ui$Element$px(sX),
						$mdgriffith$elm_ui$Element$px(sY))
				}));
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asGrid,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				A2($elm$core$List$cons, template, attrs)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				function () {
					if (maybeHeaders.$ === 1) {
						return children.aQ;
					} else {
						var renderedHeaders = maybeHeaders.a;
						return _Utils_ap(
							renderedHeaders,
							$elm$core$List$reverse(children.aQ));
					}
				}()));
	});
var $mdgriffith$elm_ui$Element$table = F2(
	function (attrs, config) {
		return A2(
			$mdgriffith$elm_ui$Element$tableHelper,
			attrs,
			{
				kC: A2($elm$core$List$map, $mdgriffith$elm_ui$Element$InternalColumn, config.kC),
				k_: config.k_
			});
	});
var $author$project$Page$AdaptiveAnalytics$studentUsageTable = F3(
	function (me, model, analyticsRecords) {
		var studentUsageSort = model.d1;
		return A2(
			$mdgriffith$elm_ui$Element$table,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(10),
					$mdgriffith$elm_ui$Element$padding(10)
				]),
			{
				kC: _List_fromArray(
					[
						{
						dB: A4(
							$author$project$Page$AdaptiveAnalytics$makeStudentUsageHeaderButton,
							!studentUsageSort,
							$author$project$Page$AdaptiveAnalytics$SortStudentUsageTable(0),
							_List_Nil,
							'Student name'),
						pM: function (aRec) {
							return A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Element$paddingXY, 10, 0)
									]),
								A2(
									$mdgriffith$elm_ui$Element$Input$button,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Background$color($author$project$Page$AdaptiveAnalytics$lightBtnColor),
											$mdgriffith$elm_ui$Element$Border$rounded(5),
											A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$Events$onMouseEnter(
											$author$project$Page$AdaptiveAnalytics$StudentDetailsShow(aRec.lW))
										]),
									{
										bD: A2(
											$mdgriffith$elm_ui$Element$column,
											_List_Nil,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$text(aRec.mq + (', ' + aRec.lt)),
													A3($author$project$Page$AdaptiveAnalytics$makeStudentExtraDetailOrNone, model, me, aRec)
												])),
										ai: $elm$core$Maybe$Just(
											$author$project$Page$AdaptiveAnalytics$LinkClicked(
												$author$project$Routing$withHash($author$project$Routing$smPaths.oF + ('/' + aRec.lW))))
									}));
						},
						ec: $mdgriffith$elm_ui$Element$fill
					},
						{
						dB: A4(
							$author$project$Page$AdaptiveAnalytics$makeStudentUsageHeaderButton,
							studentUsageSort === 1,
							$author$project$Page$AdaptiveAnalytics$SortStudentUsageTable(1),
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]),
							'# Sessions'),
						pM: function (aRec) {
							var nbrSessions = $elm$core$List$length(aRec.aP);
							return A2(
								$mdgriffith$elm_ui$Element$row,
								(nbrSessions > 0) ? _List_Nil : _List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.eC),
										$mdgriffith$elm_ui$Element$Border$rounded(5)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5),
												$mdgriffith$elm_ui$Element$centerX,
												$mdgriffith$elm_ui$Element$height(
												$mdgriffith$elm_ui$Element$px(30))
											]),
										$mdgriffith$elm_ui$Element$text(
											$elm$core$String$fromInt(nbrSessions)))
									]));
						},
						ec: $mdgriffith$elm_ui$Element$fill
					},
						{
						dB: A4(
							$author$project$Page$AdaptiveAnalytics$makeStudentUsageHeaderButton,
							studentUsageSort === 2,
							$author$project$Page$AdaptiveAnalytics$SortStudentUsageTable(2),
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]),
							'# Questions'),
						pM: function (aRec) {
							return A2(
								$mdgriffith$elm_ui$Element$row,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5),
												$mdgriffith$elm_ui$Element$centerX,
												$mdgriffith$elm_ui$Element$height(
												$mdgriffith$elm_ui$Element$px(30))
											]),
										$mdgriffith$elm_ui$Element$text(
											$elm$core$String$fromInt(
												$author$project$Page$AdaptiveAnalytics$sumQuestions(aRec.aP))))
									]));
						},
						ec: $mdgriffith$elm_ui$Element$fill
					},
						{
						dB: A4(
							$author$project$Page$AdaptiveAnalytics$makeStudentUsageHeaderButton,
							studentUsageSort === 3,
							$author$project$Page$AdaptiveAnalytics$SortStudentUsageTable(3),
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]),
							'            Band            '),
						pM: function (aRec) {
							var _v0 = $author$project$Type$AnalyticsResult$getMostRecentResultDifficulty(aRec.aP);
							if (_v0.$ === 1) {
								return A2(
									$mdgriffith$elm_ui$Element$row,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5),
													$mdgriffith$elm_ui$Element$centerX,
													$mdgriffith$elm_ui$Element$height(
													$mdgriffith$elm_ui$Element$px(30))
												]),
											$mdgriffith$elm_ui$Element$text('-'))
										]));
							} else {
								var diff = _v0.a;
								return A2(
									$mdgriffith$elm_ui$Element$row,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$centerX]),
											$author$project$UISnippet$Misc$makeResultElement(
												$elm$core$Maybe$Just(
													A2($author$project$Type$ExamResult$difficultyToImageUrlSmall, aRec.kA, diff))))
										]));
							}
						},
						ec: $mdgriffith$elm_ui$Element$fill
					}
					]),
				k_: A2($author$project$Page$AdaptiveAnalytics$sortAnalyticsRecords, studentUsageSort, analyticsRecords)
			});
	});
var $author$project$Page$AdaptiveAnalytics$ExportData = 2;
var $author$project$Page$AdaptiveAnalytics$SpecifyUserGoal = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$AdaptiveAnalytics$StudentUsage = 1;
var $author$project$Page$AdaptiveAnalytics$userGoalControl = function (model) {
	var items = _List_fromArray(
		[
			{b9: !model.bS, cc: 0, ai: $author$project$Page$AdaptiveAnalytics$SpecifyUserGoal, cq: 'Interactive charts'},
			{b9: model.bS === 1, cc: 1, ai: $author$project$Page$AdaptiveAnalytics$SpecifyUserGoal, cq: 'Student usage'},
			{b9: model.bS === 2, cc: 2, ai: $author$project$Page$AdaptiveAnalytics$SpecifyUserGoal, cq: 'History for export'}
		]);
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(10)
			]),
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$text('Show'),
				$author$project$Page$PagesCommon$makeRadioBtnRow(items)
			]));
};
var $author$project$Page$AdaptiveAnalytics$configArea = function (model) {
	return $Punie$elm_reader$Reader$asks(
		function (rState) {
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(20)
					]),
				$elm$core$List$concat(
					_List_fromArray(
						[
							_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$Font$italic]),
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text('Note: Options presented are those available for the students you retrieved and filtered on the previous page.')
									])),
								$author$project$Page$AdaptiveAnalytics$cohortControl(model),
								$author$project$Page$AdaptiveAnalytics$activitySection(model)
							]),
							_Utils_eq(model.bl, $elm$core$Maybe$Nothing) ? _List_Nil : $elm$core$List$concat(
							_List_fromArray(
								[
									_List_fromArray(
									[
										$author$project$Page$AdaptiveAnalytics$makeSelectionArea(
										$author$project$Page$AdaptiveAnalytics$userGoalControl(model))
									]),
									function () {
									var _v0 = model.bS;
									switch (_v0) {
										case 0:
											return _List_fromArray(
												[
													$author$project$Page$AdaptiveAnalytics$displayOptionsSection(model),
													$author$project$Page$AdaptiveAnalytics$chartArea(model),
													A2(
													$Punie$elm_reader$Reader$run,
													$author$project$Page$AdaptiveAnalytics$resultsListing(model),
													rState)
												]);
										case 1:
											var filterToDateRange = function (analyticsRecords) {
												var _v2 = A3($elm$core$Tuple$mapBoth, $elm$time$Time$posixToMillis, $elm$time$Time$posixToMillis, model.bq);
												var from = _v2.a;
												var to = _v2.b;
												var filterActivityResults = function (analyticRecord) {
													return _Utils_update(
														analyticRecord,
														{
															aP: A2(
																$elm$core$List$filter,
																function (actResult) {
																	var tsPosix = A2(
																		$elm$core$Maybe$withDefault,
																		0,
																		A2(
																			$elm$core$Maybe$map,
																			$elm$core$Basics$round,
																			A2(
																				$elm$core$Maybe$map,
																				$elm$core$Basics$mul(1000),
																				$elm$core$String$toFloat(actResult.pr))));
																	return (_Utils_cmp(tsPosix, from) > -1) && (_Utils_cmp(tsPosix, to) < 1);
																},
																analyticRecord.aP)
														});
												};
												return A2($elm$core$List$map, filterActivityResults, analyticsRecords);
											};
											var analyticsRecordsMaybe = A2(
												$elm$core$Maybe$map,
												function (fn) {
													return A2($elm$core$Basics$composeL, filterToDateRange, fn)(model.a_);
												},
												A3($elm$core$Maybe$map2, $author$project$Type$AnalyticsRecord$filterByCohortByActivity, model.bX, model.aE));
											return _List_fromArray(
												[
													A2($author$project$Page$AdaptiveAnalytics$displayDatesSection, model.bH, model.bq),
													$author$project$Page$AdaptiveAnalytics$makeSelectionArea(
													function () {
														if (!analyticsRecordsMaybe.$) {
															var analyticsRecords = analyticsRecordsMaybe.a;
															return A2(
																$mdgriffith$elm_ui$Element$column,
																_List_fromArray(
																	[
																		$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
																	]),
																_List_fromArray(
																	[
																		$mdgriffith$elm_ui$Element$text('Click column headings to sort'),
																		A3($author$project$Page$AdaptiveAnalytics$studentUsageTable, rState.fc, model, analyticsRecords)
																	]));
														} else {
															return $mdgriffith$elm_ui$Element$text('Did you specify the year and the activity for which you want the data?  We could not find anything.');
														}
													}())
												]);
										default:
											return _List_fromArray(
												[
													A2(
													$Punie$elm_reader$Reader$run,
													$author$project$Page$AdaptiveAnalytics$displayAllStudentHistory(model),
													rState)
												]);
									}
								}()
								]))
						])));
		});
};
var $mdgriffith$elm_ui$Element$fillPortion = $mdgriffith$elm_ui$Internal$Model$Fill;
var $author$project$Page$SignInPagesCommon$msgAreaWithColor = F2(
	function (color, serverErrorMsgMaybe) {
		if (!serverErrorMsgMaybe.$) {
			var errString = serverErrorMsgMaybe.a;
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10)
					]),
				A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$padding(20),
							$mdgriffith$elm_ui$Element$Border$rounded(10),
							$mdgriffith$elm_ui$Element$Background$color(color)
						]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text(errString)
						])));
		} else {
			return $mdgriffith$elm_ui$Element$none;
		}
	});
var $author$project$Page$AdaptiveAnalytics$msgRow = function (pageMsgArea) {
	var _v0 = pageMsgArea.b;
	if (_v0.$ === 1) {
		return $mdgriffith$elm_ui$Element$none;
	} else {
		return A2($author$project$Page$SignInPagesCommon$msgAreaWithColor, pageMsgArea.a, pageMsgArea.b);
	}
};
var $mdgriffith$elm_ui$Element$Font$family = function (families) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$fontFamily,
		A2(
			$mdgriffith$elm_ui$Internal$Model$FontFamily,
			A3($elm$core$List$foldl, $mdgriffith$elm_ui$Internal$Model$renderFontClassName, 'ff-', families),
			families));
};
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $mdgriffith$elm_ui$Element$Font$typeface = $mdgriffith$elm_ui$Internal$Model$Typeface;
var $author$project$Page$PagesCommon$helpAnchorWithColor = F2(
	function (id, color) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$htmlAttribute(
					$elm$html$Html$Attributes$id(id)),
					$mdgriffith$elm_ui$Element$Font$family(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$typeface('FontAwesome')
						])),
					$mdgriffith$elm_ui$Element$Font$color(color),
					$mdgriffith$elm_ui$Element$Font$size(21),
					A2($mdgriffith$elm_ui$Element$paddingXY, 5, 0)
				]),
			$mdgriffith$elm_ui$Element$text('\uF059'));
	});
var $author$project$Page$AdaptiveAnalytics$pageHeading = A2(
	$mdgriffith$elm_ui$Element$row,
	_List_Nil,
	_List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.nn),
					$mdgriffith$elm_ui$Element$Font$size(30),
					$mdgriffith$elm_ui$Element$Font$bold
				]),
			$mdgriffith$elm_ui$Element$text('Aggregate Analytics')),
			A2($author$project$Page$PagesCommon$helpAnchorWithColor, 'sma_title_adaptiveAnalytics', $author$project$Theme$Style$t.lN)
		]));
var $author$project$Page$AdaptiveAnalytics$workArea = function (mdl) {
	var makeWorkArea = function (currSetArea) {
		return $Punie$elm_reader$Reader$asks(
			function (_v0) {
				return A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 0, 60),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$fillPortion(1))
								]),
							_List_Nil),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$fillPortion(12)),
									$mdgriffith$elm_ui$Element$spacing(30)
								]),
							_List_fromArray(
								[
									$author$project$Page$AdaptiveAnalytics$pageHeading,
									$author$project$Page$AdaptiveAnalytics$msgRow(mdl.dQ),
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$spacing(10),
											$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
											A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
											$mdgriffith$elm_ui$Element$Border$rounded(10),
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									_List_fromArray(
										[
											currSetArea,
											$author$project$Page$AdaptiveAnalytics$actionBtns(mdl)
										]))
								])),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$fillPortion(1))
								]),
							_List_Nil)
						]));
			});
	};
	return A2(
		$Punie$elm_reader$Reader$andThen,
		makeWorkArea,
		$author$project$Page$AdaptiveAnalytics$configArea(mdl));
};
var $author$project$Page$AdaptiveAnalytics$view = F2(
	function (model, topBarFn) {
		var addTopBar = function (wArea) {
			return A2(
				$Punie$elm_reader$Reader$map,
				function (tBar) {
					return _List_fromArray(
						[tBar, wArea]);
				},
				A2(topBarFn, $author$project$Page$AdaptiveAnalytics$SignOut, $author$project$Page$AdaptiveAnalytics$LinkClicked));
		};
		return A3(
			$Punie$elm_reader$Reader$map2,
			F2(
				function (color, elements) {
					return A2(
						$mdgriffith$elm_ui$Element$layout,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Background$color(color)
							]),
						A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							elements));
				}),
			$author$project$Page$PagesCommon$makeBackgroundStyle,
			A2(
				$Punie$elm_reader$Reader$andThen,
				addTopBar,
				$author$project$Page$AdaptiveAnalytics$workArea(model)));
	});
var $author$project$Page$AdminPage$LinkClicked = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$AdminPage$SignOut = {$: 0};
var $author$project$Type$NodeCfg$ActivitySpread = function (a) {
	return {$: 1, a: a};
};
var $author$project$Type$NodeCfg$CategorySpread = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$AdminPage$DoNothing = {$: 16};
var $author$project$Page$AdminPage$SetSyllabus = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$AdminPage$ShowVariantId = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$AdminPage$ToggleLeaf = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Page$AdminPage$ClearActivities = {$: 10};
var $author$project$Page$AdminPage$ClearLeaves = {$: 8};
var $author$project$Page$AdminPage$ClearVariantIds = {$: 6};
var $author$project$Page$AdminPage$GetActivities = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$AdminPage$GetLeaves = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$AdminPage$GetVariantIds = {$: 5};
var $author$project$Page$AdminPage$ValidateVariantStructures = {$: 13};
var $author$project$Page$AdminPage$actionsSection = F2(
	function (mdl, isNapAdmin) {
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				return A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
							A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
							$mdgriffith$elm_ui$Element$Border$rounded(10),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$Font$italic]),
							$mdgriffith$elm_ui$Element$text('Actions:')),
							A2(
							$mdgriffith$elm_ui$Element$wrappedRow,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(10)
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Page$PagesCommon$regButton,
									true,
									{
										bD: A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$centerX]),
											$mdgriffith$elm_ui$Element$text('Get VariantIds')),
										ai: $elm$core$Maybe$Just($author$project$Page$AdminPage$GetVariantIds)
									}),
									A2(
									$author$project$Page$PagesCommon$regButton,
									true,
									{
										bD: A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$centerX]),
											$mdgriffith$elm_ui$Element$text('Clear VariantIds')),
										ai: $elm$core$Maybe$Just($author$project$Page$AdminPage$ClearVariantIds)
									}),
									A2(
									$author$project$Page$PagesCommon$regButton,
									true,
									{
										bD: A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$centerX]),
											$mdgriffith$elm_ui$Element$text('Validate Variant Structures')),
										ai: $elm$core$Maybe$Just($author$project$Page$AdminPage$ValidateVariantStructures)
									}),
									A2(
									$author$project$Page$PagesCommon$regButton,
									true,
									{
										bD: A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$centerX]),
											$mdgriffith$elm_ui$Element$text('Get Leaves')),
										ai: $elm$core$Maybe$Just(
											$author$project$Page$AdminPage$GetLeaves(rState.mX.cF))
									}),
									A2(
									$author$project$Page$PagesCommon$regButton,
									true,
									{
										bD: A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$centerX]),
											$mdgriffith$elm_ui$Element$text('Clear Leaves')),
										ai: $elm$core$Maybe$Just($author$project$Page$AdminPage$ClearLeaves)
									}),
									A2(
									$author$project$Page$PagesCommon$regButton,
									true,
									{
										bD: A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$centerX]),
											$mdgriffith$elm_ui$Element$text('Get Activities')),
										ai: $elm$core$Maybe$Just(
											$author$project$Page$AdminPage$GetActivities(rState.mX.cF))
									}),
									A2(
									$author$project$Page$PagesCommon$regButton,
									true,
									{
										bD: A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$centerX]),
											$mdgriffith$elm_ui$Element$text('Clear Activities')),
										ai: $elm$core$Maybe$Just($author$project$Page$AdminPage$ClearActivities)
									})
								])),
							function () {
							var syllabusesTxt = $elm$core$List$isEmpty(mdl.bi) ? 'none' : $elm$core$String$concat(
								A2($elm$core$List$intersperse, ', ', mdl.bi));
							return $mdgriffith$elm_ui$Element$text('VariantIds shown below from the following syllabuses: ' + syllabusesTxt);
						}()
						]));
			});
	});
var $elm$html$Html$i = _VirtualDom_node('i');
var $author$project$UISnippet$Answer$iconCross = $mdgriffith$elm_ui$Element$html(
	A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('icon'),
				A2($elm$html$Html$Attributes$style, 'height', '1rem')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fa fa-times')
					]),
				_List_Nil)
			])));
var $author$project$UISnippet$Answer$iconTick = $mdgriffith$elm_ui$Element$html(
	A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('icon'),
				A2($elm$html$Html$Attributes$style, 'height', '1rem')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$i,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('fa fa-check')
					]),
				_List_Nil)
			])));
var $author$project$UISnippet$Answer$answerDisplay = F2(
	function (isShowTickCross, isCorrect) {
		return isShowTickCross ? _List_fromArray(
			[
				isCorrect ? A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.gz)
					]),
				$author$project$UISnippet$Answer$iconTick) : A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.gy)
					]),
				$author$project$UISnippet$Answer$iconCross)
			]) : _List_Nil;
	});
var $mdgriffith$elm_ui$Internal$Model$LivePolite = {$: 6};
var $mdgriffith$elm_ui$Element$Region$announce = $mdgriffith$elm_ui$Internal$Model$Describe($mdgriffith$elm_ui$Internal$Model$LivePolite);
var $mdgriffith$elm_ui$Element$Input$applyLabel = F3(
	function (attrs, label, input) {
		if (label.$ === 1) {
			var labelText = label.a;
			return A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asColumn,
				$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
				attrs,
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[input])));
		} else {
			var position = label.a;
			var labelAttrs = label.b;
			var labelChild = label.c;
			var labelElement = A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asEl,
				$mdgriffith$elm_ui$Internal$Model$div,
				labelAttrs,
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[labelChild])));
			switch (position) {
				case 2:
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asColumn,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.dD),
							attrs),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[labelElement, input])));
				case 3:
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asColumn,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.dD),
							attrs),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[input, labelElement])));
				case 0:
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asRow,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.dD),
							attrs),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[input, labelElement])));
				default:
					return A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asRow,
						$mdgriffith$elm_ui$Internal$Model$NodeName('label'),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.dD),
							attrs),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(
							_List_fromArray(
								[labelElement, input])));
			}
		}
	});
var $mdgriffith$elm_ui$Internal$Model$CenterY = 1;
var $mdgriffith$elm_ui$Element$centerY = $mdgriffith$elm_ui$Internal$Model$AlignY(1);
var $mdgriffith$elm_ui$Internal$Model$Label = function (a) {
	return {$: 5, a: a};
};
var $mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute = function (label) {
	if (label.$ === 1) {
		var textLabel = label.a;
		return $mdgriffith$elm_ui$Internal$Model$Describe(
			$mdgriffith$elm_ui$Internal$Model$Label(textLabel));
	} else {
		return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
	}
};
var $mdgriffith$elm_ui$Element$Input$isHiddenLabel = function (label) {
	if (label.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $mdgriffith$elm_ui$Element$Input$tabindex = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Attributes$tabindex);
var $mdgriffith$elm_ui$Element$Input$checkbox = F2(
	function (attrs, _v0) {
		var label = _v0.bD;
		var icon = _v0.hl;
		var checked = _v0.gr;
		var onChange = _v0.cS;
		var attributes = _Utils_ap(
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Input$isHiddenLabel(label) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Element$spacing(6),
					$mdgriffith$elm_ui$Internal$Model$Attr(
					$elm$html$Html$Events$onClick(
						onChange(!checked))),
					$mdgriffith$elm_ui$Element$Region$announce,
					$mdgriffith$elm_ui$Element$Input$onKeyLookup(
					function (code) {
						return _Utils_eq(code, $mdgriffith$elm_ui$Element$Input$enter) ? $elm$core$Maybe$Just(
							onChange(!checked)) : (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$space) ? $elm$core$Maybe$Just(
							onChange(!checked)) : $elm$core$Maybe$Nothing);
					}),
					$mdgriffith$elm_ui$Element$Input$tabindex(0),
					$mdgriffith$elm_ui$Element$pointer,
					$mdgriffith$elm_ui$Element$alignLeft,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			attrs);
		return A3(
			$mdgriffith$elm_ui$Element$Input$applyLabel,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$Attr(
					A2($elm$html$Html$Attributes$attribute, 'role', 'checkbox')),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$Attr(
						A2(
							$elm$html$Html$Attributes$attribute,
							'aria-checked',
							checked ? 'true' : 'false')),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute(label),
						attributes))),
			label,
			A4(
				$mdgriffith$elm_ui$Internal$Model$element,
				$mdgriffith$elm_ui$Internal$Model$asEl,
				$mdgriffith$elm_ui$Internal$Model$div,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerY,
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink)
					]),
				$mdgriffith$elm_ui$Internal$Model$Unkeyed(
					_List_fromArray(
						[
							icon(checked)
						]))));
	});
var $mdgriffith$elm_ui$Internal$Flag$borderColor = $mdgriffith$elm_ui$Internal$Flag$flag(28);
var $mdgriffith$elm_ui$Element$Border$color = function (clr) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$borderColor,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Colored,
			'bc-' + $mdgriffith$elm_ui$Internal$Model$formatColorClass(clr),
			'border-color',
			clr));
};
var $truqu$elm_base64$Base64$Decode$pad = function (input) {
	var _v0 = $elm$core$String$length(input) % 4;
	switch (_v0) {
		case 3:
			return input + '=';
		case 2:
			return input + '==';
		default:
			return input;
	}
};
var $truqu$elm_base64$Base64$Decode$charToInt = function (_char) {
	switch (_char) {
		case 'A':
			return 0;
		case 'B':
			return 1;
		case 'C':
			return 2;
		case 'D':
			return 3;
		case 'E':
			return 4;
		case 'F':
			return 5;
		case 'G':
			return 6;
		case 'H':
			return 7;
		case 'I':
			return 8;
		case 'J':
			return 9;
		case 'K':
			return 10;
		case 'L':
			return 11;
		case 'M':
			return 12;
		case 'N':
			return 13;
		case 'O':
			return 14;
		case 'P':
			return 15;
		case 'Q':
			return 16;
		case 'R':
			return 17;
		case 'S':
			return 18;
		case 'T':
			return 19;
		case 'U':
			return 20;
		case 'V':
			return 21;
		case 'W':
			return 22;
		case 'X':
			return 23;
		case 'Y':
			return 24;
		case 'Z':
			return 25;
		case 'a':
			return 26;
		case 'b':
			return 27;
		case 'c':
			return 28;
		case 'd':
			return 29;
		case 'e':
			return 30;
		case 'f':
			return 31;
		case 'g':
			return 32;
		case 'h':
			return 33;
		case 'i':
			return 34;
		case 'j':
			return 35;
		case 'k':
			return 36;
		case 'l':
			return 37;
		case 'm':
			return 38;
		case 'n':
			return 39;
		case 'o':
			return 40;
		case 'p':
			return 41;
		case 'q':
			return 42;
		case 'r':
			return 43;
		case 's':
			return 44;
		case 't':
			return 45;
		case 'u':
			return 46;
		case 'v':
			return 47;
		case 'w':
			return 48;
		case 'x':
			return 49;
		case 'y':
			return 50;
		case 'z':
			return 51;
		case '0':
			return 52;
		case '1':
			return 53;
		case '2':
			return 54;
		case '3':
			return 55;
		case '4':
			return 56;
		case '5':
			return 57;
		case '6':
			return 58;
		case '7':
			return 59;
		case '8':
			return 60;
		case '9':
			return 61;
		case '+':
			return 62;
		case '/':
			return 63;
		default:
			return 0;
	}
};
var $truqu$elm_base64$Base64$Decode$intToString = A2($elm$core$Basics$composeR, $elm$core$Char$fromCode, $elm$core$String$fromChar);
var $truqu$elm_base64$Base64$Decode$add = F2(
	function (_char, _v0) {
		var curr = _v0.a;
		var need = _v0.b;
		var res = _v0.c;
		var shiftAndAdd = function (_int) {
			return (63 & _int) | (curr << 6);
		};
		return (!need) ? ((!(128 & _char)) ? _Utils_Tuple3(
			0,
			0,
			_Utils_ap(
				res,
				$truqu$elm_base64$Base64$Decode$intToString(_char))) : (((224 & _char) === 192) ? _Utils_Tuple3(31 & _char, 1, res) : (((240 & _char) === 224) ? _Utils_Tuple3(15 & _char, 2, res) : _Utils_Tuple3(7 & _char, 3, res)))) : ((need === 1) ? _Utils_Tuple3(
			0,
			0,
			_Utils_ap(
				res,
				$truqu$elm_base64$Base64$Decode$intToString(
					shiftAndAdd(_char)))) : _Utils_Tuple3(
			shiftAndAdd(_char),
			need - 1,
			res));
	});
var $truqu$elm_base64$Base64$Decode$toUTF16 = F2(
	function (_char, acc) {
		return _Utils_Tuple3(
			0,
			0,
			A2(
				$truqu$elm_base64$Base64$Decode$add,
				255 & (_char >>> 0),
				A2(
					$truqu$elm_base64$Base64$Decode$add,
					255 & (_char >>> 8),
					A2($truqu$elm_base64$Base64$Decode$add, 255 & (_char >>> 16), acc))));
	});
var $truqu$elm_base64$Base64$Decode$chomp = F2(
	function (char_, _v0) {
		var curr = _v0.a;
		var cnt = _v0.b;
		var utf8ToUtf16 = _v0.c;
		var _char = $truqu$elm_base64$Base64$Decode$charToInt(char_);
		if (cnt === 3) {
			return A2($truqu$elm_base64$Base64$Decode$toUTF16, curr | _char, utf8ToUtf16);
		} else {
			return _Utils_Tuple3((_char << ((3 - cnt) * 6)) | curr, cnt + 1, utf8ToUtf16);
		}
	});
var $elm$core$String$foldl = _String_foldl;
var $truqu$elm_base64$Base64$Decode$initial = _Utils_Tuple3(
	0,
	0,
	_Utils_Tuple3(0, 0, ''));
var $truqu$elm_base64$Base64$Decode$stripNulls = F2(
	function (input, output) {
		return A2($elm$core$String$endsWith, '==', input) ? A2($elm$core$String$dropRight, 2, output) : (A2($elm$core$String$endsWith, '=', input) ? A2($elm$core$String$dropRight, 1, output) : output);
	});
var $truqu$elm_base64$Base64$Decode$validBase64Regex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^([A-Za-z0-9\\/+]{4})*([A-Za-z0-9\\/+]{2}[A-Za-z0-9\\/+=]{2})?$'));
var $truqu$elm_base64$Base64$Decode$validate = function (input) {
	return A2($elm$regex$Regex$contains, $truqu$elm_base64$Base64$Decode$validBase64Regex, input) ? $elm$core$Result$Ok(input) : $elm$core$Result$Err('Invalid base64');
};
var $truqu$elm_base64$Base64$Decode$wrapUp = function (_v0) {
	var _v1 = _v0.c;
	var need = _v1.b;
	var res = _v1.c;
	return (need > 0) ? $elm$core$Result$Err('Invalid UTF-16') : $elm$core$Result$Ok(res);
};
var $truqu$elm_base64$Base64$Decode$validateAndDecode = function (input) {
	return A2(
		$elm$core$Result$map,
		$truqu$elm_base64$Base64$Decode$stripNulls(input),
		A2(
			$elm$core$Result$andThen,
			A2(
				$elm$core$Basics$composeR,
				A2($elm$core$String$foldl, $truqu$elm_base64$Base64$Decode$chomp, $truqu$elm_base64$Base64$Decode$initial),
				$truqu$elm_base64$Base64$Decode$wrapUp),
			$truqu$elm_base64$Base64$Decode$validate(input)));
};
var $truqu$elm_base64$Base64$Decode$decode = A2($elm$core$Basics$composeR, $truqu$elm_base64$Base64$Decode$pad, $truqu$elm_base64$Base64$Decode$validateAndDecode);
var $truqu$elm_base64$Base64$decode = $truqu$elm_base64$Base64$Decode$decode;
var $elm$virtual_dom$VirtualDom$nodeNS = F2(
	function (namespace, tag) {
		return A2(
			_VirtualDom_nodeNS,
			namespace,
			_VirtualDom_noScript(tag));
	});
var $elm$svg$Svg$node = $elm$virtual_dom$VirtualDom$nodeNS('http://www.w3.org/2000/svg');
var $Garados007$elm_svg_parser$SvgParser$toAttribute = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return A2($elm$virtual_dom$VirtualDom$attribute, name, value);
};
var $Garados007$elm_svg_parser$SvgParser$elementToSvg = function (element) {
	return A3(
		$elm$svg$Svg$node,
		element.mO,
		A2($elm$core$List$map, $Garados007$elm_svg_parser$SvgParser$toAttribute, element.em),
		A2($elm$core$List$map, $Garados007$elm_svg_parser$SvgParser$nodeToSvg, element.eA));
};
var $Garados007$elm_svg_parser$SvgParser$nodeToSvg = function (svgNode) {
	switch (svgNode.$) {
		case 0:
			var element = svgNode.a;
			return $Garados007$elm_svg_parser$SvgParser$elementToSvg(element);
		case 1:
			var content = svgNode.a;
			return $elm$svg$Svg$text(content);
		default:
			var content = svgNode.a;
			return $elm$svg$Svg$text('');
	}
};
var $andre_dietrich$parser_combinators$Combine$Parser = $elm$core$Basics$identity;
var $andre_dietrich$parser_combinators$Combine$app = function (_v0) {
	var inner = _v0;
	return inner;
};
var $andre_dietrich$parser_combinators$Combine$andThen = F2(
	function (f, p) {
		return F2(
			function (state, stream) {
				var _v0 = A3($andre_dietrich$parser_combinators$Combine$app, p, state, stream);
				if (!_v0.c.$) {
					var rstate = _v0.a;
					var rstream = _v0.b;
					var res = _v0.c.a;
					return A3(
						$andre_dietrich$parser_combinators$Combine$app,
						f(res),
						rstate,
						rstream);
				} else {
					var estate = _v0.a;
					var estream = _v0.b;
					var ms = _v0.c.a;
					return _Utils_Tuple3(
						estate,
						estream,
						$elm$core$Result$Err(ms));
				}
			});
	});
var $pilatch$flip$Flip$flip = F3(
	function (_function, argB, argA) {
		return A2(_function, argA, argB);
	});
var $andre_dietrich$parser_combinators$Combine$bimap = F3(
	function (fok, ferr, p) {
		return F2(
			function (state, stream) {
				var _v0 = A3($andre_dietrich$parser_combinators$Combine$app, p, state, stream);
				if (!_v0.c.$) {
					var rstate = _v0.a;
					var rstream = _v0.b;
					var res = _v0.c.a;
					return _Utils_Tuple3(
						rstate,
						rstream,
						$elm$core$Result$Ok(
							fok(res)));
				} else {
					var estate = _v0.a;
					var estream = _v0.b;
					var ms = _v0.c.a;
					return _Utils_Tuple3(
						estate,
						estream,
						$elm$core$Result$Err(
							ferr(ms)));
				}
			});
	});
var $andre_dietrich$parser_combinators$Combine$map = F2(
	function (f, p) {
		return A3($andre_dietrich$parser_combinators$Combine$bimap, f, $elm$core$Basics$identity, p);
	});
var $andre_dietrich$parser_combinators$Combine$andMap = F2(
	function (rp, lp) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$andThen,
			A2($pilatch$flip$Flip$flip, $andre_dietrich$parser_combinators$Combine$map, rp),
			lp);
	});
var $Garados007$elm_svg_parser$SvgParser$flip = F3(
	function (func, b, a) {
		return A2(func, a, b);
	});
var $Garados007$elm_svg_parser$SvgParser$andMapRight = F2(
	function (lp, rp) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$andMap,
			rp,
			A2(
				$andre_dietrich$parser_combinators$Combine$map,
				$Garados007$elm_svg_parser$SvgParser$flip($elm$core$Basics$always),
				lp));
	});
var $Garados007$elm_svg_parser$SvgParser$SvgElement = function (a) {
	return {$: 0, a: a};
};
var $Garados007$elm_svg_parser$SvgParser$andMapLeft = F2(
	function (lp, rp) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$andMap,
			rp,
			A2($andre_dietrich$parser_combinators$Combine$map, $elm$core$Basics$always, lp));
	});
var $andre_dietrich$parser_combinators$Combine$emptyErr = F2(
	function (state, stream) {
		return _Utils_Tuple3(
			state,
			stream,
			$elm$core$Result$Err(_List_Nil));
	});
var $andre_dietrich$parser_combinators$Combine$or = F2(
	function (lp, rp) {
		return F2(
			function (state, stream) {
				var _v0 = A3($andre_dietrich$parser_combinators$Combine$app, lp, state, stream);
				if (!_v0.c.$) {
					var res = _v0;
					return res;
				} else {
					var lms = _v0.c.a;
					var _v1 = A3($andre_dietrich$parser_combinators$Combine$app, rp, state, stream);
					if (!_v1.c.$) {
						var res = _v1;
						return res;
					} else {
						var rms = _v1.c.a;
						return _Utils_Tuple3(
							state,
							stream,
							$elm$core$Result$Err(
								_Utils_ap(lms, rms)));
					}
				}
			});
	});
var $andre_dietrich$parser_combinators$Combine$choice = function (xs) {
	return A3($elm$core$List$foldr, $andre_dietrich$parser_combinators$Combine$or, $andre_dietrich$parser_combinators$Combine$emptyErr, xs);
};
var $Garados007$elm_svg_parser$SvgParser$SvgComment = function (a) {
	return {$: 2, a: a};
};
var $andre_dietrich$parser_combinators$Combine$mapError = $andre_dietrich$parser_combinators$Combine$bimap($elm$core$Basics$identity);
var $andre_dietrich$parser_combinators$Combine$onerror = F2(
	function (m, p) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$mapError,
			$elm$core$Basics$always(
				_List_fromArray(
					[m])),
			p);
	});
var $andre_dietrich$parser_combinators$Combine$primitive = $elm$core$Basics$identity;
var $andre_dietrich$parser_combinators$Combine$Char$satisfy = function (pred) {
	return $andre_dietrich$parser_combinators$Combine$primitive(
		F2(
			function (state, stream) {
				var message = 'could not satisfy predicate';
				var _v0 = $elm$core$String$uncons(stream.ah);
				if (!_v0.$) {
					var _v1 = _v0.a;
					var h = _v1.a;
					var rest = _v1.b;
					return pred(h) ? _Utils_Tuple3(
						state,
						_Utils_update(
							stream,
							{ah: rest, bb: stream.bb + 1}),
						$elm$core$Result$Ok(h)) : _Utils_Tuple3(
						state,
						stream,
						$elm$core$Result$Err(
							_List_fromArray(
								[message])));
				} else {
					return _Utils_Tuple3(
						state,
						stream,
						$elm$core$Result$Err(
							_List_fromArray(
								[message])));
				}
			}));
};
var $andre_dietrich$parser_combinators$Combine$Char$anyChar = A2(
	$andre_dietrich$parser_combinators$Combine$onerror,
	'expected any character',
	$andre_dietrich$parser_combinators$Combine$Char$satisfy(
		$elm$core$Basics$always(true)));
var $elm$core$String$fromList = _String_fromList;
var $andre_dietrich$parser_combinators$Combine$succeed = function (res) {
	return F2(
		function (state, stream) {
			return _Utils_Tuple3(
				state,
				stream,
				$elm$core$Result$Ok(res));
		});
};
var $andre_dietrich$parser_combinators$Combine$lazy = function (t) {
	return A2(
		$andre_dietrich$parser_combinators$Combine$andThen,
		t,
		$andre_dietrich$parser_combinators$Combine$succeed(0));
};
var $andre_dietrich$parser_combinators$Combine$manyTill = F2(
	function (p, end_) {
		var accumulate = F3(
			function (acc, state, stream) {
				accumulate:
				while (true) {
					var _v0 = A3($andre_dietrich$parser_combinators$Combine$app, end_, state, stream);
					if (!_v0.c.$) {
						var rstate = _v0.a;
						var rstream = _v0.b;
						return _Utils_Tuple3(
							rstate,
							rstream,
							$elm$core$Result$Ok(
								$elm$core$List$reverse(acc)));
					} else {
						var estate = _v0.a;
						var estream = _v0.b;
						var ms = _v0.c.a;
						var _v1 = A3($andre_dietrich$parser_combinators$Combine$app, p, state, stream);
						if (!_v1.c.$) {
							var rstate = _v1.a;
							var rstream = _v1.b;
							var res = _v1.c.a;
							var $temp$acc = A2($elm$core$List$cons, res, acc),
								$temp$state = rstate,
								$temp$stream = rstream;
							acc = $temp$acc;
							state = $temp$state;
							stream = $temp$stream;
							continue accumulate;
						} else {
							return _Utils_Tuple3(
								estate,
								estream,
								$elm$core$Result$Err(ms));
						}
					}
				}
			});
		return accumulate(_List_Nil);
	});
var $andre_dietrich$parser_combinators$Combine$string = function (s) {
	return F2(
		function (state, stream) {
			if (A2($elm$core$String$startsWith, s, stream.ah)) {
				var len = $elm$core$String$length(s);
				var pos = stream.bb + len;
				var rem = A2($elm$core$String$dropLeft, len, stream.ah);
				return _Utils_Tuple3(
					state,
					_Utils_update(
						stream,
						{ah: rem, bb: pos}),
					$elm$core$Result$Ok(s));
			} else {
				return _Utils_Tuple3(
					state,
					stream,
					$elm$core$Result$Err(
						_List_fromArray(
							['expected \"' + (s + '\"')])));
			}
		});
};
var $andre_dietrich$parser_combinators$Combine$regexer = F5(
	function (input, output, pat, state, stream) {
		var pattern = A2($elm$core$String$startsWith, '^', pat) ? pat : ('^' + pat);
		var _v0 = A3(
			$elm$regex$Regex$findAtMost,
			1,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$regex$Regex$never,
				input(pattern)),
			stream.ah);
		if (_v0.b && (!_v0.b.b)) {
			var match = _v0.a;
			var len = $elm$core$String$length(match.mA);
			var pos = stream.bb + len;
			var rem = A2($elm$core$String$dropLeft, len, stream.ah);
			return _Utils_Tuple3(
				state,
				_Utils_update(
					stream,
					{ah: rem, bb: pos}),
				$elm$core$Result$Ok(
					output(match)));
		} else {
			return _Utils_Tuple3(
				state,
				stream,
				$elm$core$Result$Err(
					_List_fromArray(
						['expected input matching Regexp /' + (pattern + '/')])));
		}
	});
var $andre_dietrich$parser_combinators$Combine$regex = A2(
	$elm$core$Basics$composeR,
	A2(
		$andre_dietrich$parser_combinators$Combine$regexer,
		$elm$regex$Regex$fromString,
		function ($) {
			return $.mA;
		}),
	$elm$core$Basics$identity);
var $andre_dietrich$parser_combinators$Combine$whitespace = A2(
	$andre_dietrich$parser_combinators$Combine$onerror,
	'optional whitespace',
	$andre_dietrich$parser_combinators$Combine$regex('\\s*'));
var $Garados007$elm_svg_parser$SvgParser$commentParser = $andre_dietrich$parser_combinators$Combine$lazy(
	function (_v0) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$map,
			A2($elm$core$Basics$composeL, $Garados007$elm_svg_parser$SvgParser$SvgComment, $elm$core$String$fromList),
			A2(
				$Garados007$elm_svg_parser$SvgParser$andMapRight,
				A2(
					$Garados007$elm_svg_parser$SvgParser$andMapRight,
					$andre_dietrich$parser_combinators$Combine$whitespace,
					$andre_dietrich$parser_combinators$Combine$string('<!--')),
				A2(
					$andre_dietrich$parser_combinators$Combine$manyTill,
					$andre_dietrich$parser_combinators$Combine$Char$anyChar,
					$andre_dietrich$parser_combinators$Combine$string('-->'))));
	});
var $andre_dietrich$parser_combinators$Combine$many = function (p) {
	var accumulate = F3(
		function (acc, state, stream) {
			accumulate:
			while (true) {
				var _v0 = A3($andre_dietrich$parser_combinators$Combine$app, p, state, stream);
				if (!_v0.c.$) {
					var rstate = _v0.a;
					var rstream = _v0.b;
					var res = _v0.c.a;
					if (_Utils_eq(stream, rstream)) {
						return _Utils_Tuple3(
							rstate,
							rstream,
							$elm$core$List$reverse(acc));
					} else {
						var $temp$acc = A2($elm$core$List$cons, res, acc),
							$temp$state = rstate,
							$temp$stream = rstream;
						acc = $temp$acc;
						state = $temp$state;
						stream = $temp$stream;
						continue accumulate;
					}
				} else {
					return _Utils_Tuple3(
						state,
						stream,
						$elm$core$List$reverse(acc));
				}
			}
		});
	return F2(
		function (state, stream) {
			var _v1 = A3(accumulate, _List_Nil, state, stream);
			var rstate = _v1.a;
			var rstream = _v1.b;
			var res = _v1.c;
			return _Utils_Tuple3(
				rstate,
				rstream,
				$elm$core$Result$Ok(res));
		});
};
var $Garados007$elm_svg_parser$SvgParser$Element = F3(
	function (name, attributes, children) {
		return {em: attributes, eA: children, mO: name};
	});
var $andre_dietrich$parser_combinators$Combine$optional = F2(
	function (res, p) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$or,
			p,
			$andre_dietrich$parser_combinators$Combine$succeed(res));
	});
var $Garados007$elm_svg_parser$SvgParser$attributeParser = A2(
	$andre_dietrich$parser_combinators$Combine$andMap,
	A2(
		$andre_dietrich$parser_combinators$Combine$optional,
		'',
		A2(
			$Garados007$elm_svg_parser$SvgParser$andMapLeft,
			A2(
				$Garados007$elm_svg_parser$SvgParser$andMapRight,
				$andre_dietrich$parser_combinators$Combine$string('=\"'),
				$andre_dietrich$parser_combinators$Combine$regex('[^\"]*')),
			$andre_dietrich$parser_combinators$Combine$string('\"'))),
	A2(
		$andre_dietrich$parser_combinators$Combine$map,
		$elm$core$Tuple$pair,
		$andre_dietrich$parser_combinators$Combine$regex('[^=>/]+')));
var $andre_dietrich$parser_combinators$Combine$keep = F2(
	function (p1, p2) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$andMap,
			p1,
			A2(
				$andre_dietrich$parser_combinators$Combine$map,
				$pilatch$flip$Flip$flip($elm$core$Basics$always),
				p2));
	});
var $andre_dietrich$parser_combinators$Combine$sepBy1 = F2(
	function (sep, p) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$andMap,
			$andre_dietrich$parser_combinators$Combine$many(
				A2($andre_dietrich$parser_combinators$Combine$keep, p, sep)),
			A2($andre_dietrich$parser_combinators$Combine$map, $elm$core$List$cons, p));
	});
var $andre_dietrich$parser_combinators$Combine$sepBy = F2(
	function (sep, p) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$or,
			A2($andre_dietrich$parser_combinators$Combine$sepBy1, sep, p),
			$andre_dietrich$parser_combinators$Combine$succeed(_List_Nil));
	});
var $Garados007$elm_svg_parser$SvgParser$openingParser = A3(
	$Garados007$elm_svg_parser$SvgParser$flip,
	$andre_dietrich$parser_combinators$Combine$andMap,
	A2(
		$andre_dietrich$parser_combinators$Combine$andMap,
		$andre_dietrich$parser_combinators$Combine$regex('[^/>\\s]+'),
		A2(
			$andre_dietrich$parser_combinators$Combine$map,
			F3(
				function (_v0, tagName, attributes) {
					return A3($Garados007$elm_svg_parser$SvgParser$Element, tagName, attributes, _List_Nil);
				}),
			$andre_dietrich$parser_combinators$Combine$string('<'))),
	A2(
		$Garados007$elm_svg_parser$SvgParser$andMapLeft,
		A2(
			$Garados007$elm_svg_parser$SvgParser$andMapRight,
			$andre_dietrich$parser_combinators$Combine$whitespace,
			A2($andre_dietrich$parser_combinators$Combine$sepBy, $andre_dietrich$parser_combinators$Combine$whitespace, $Garados007$elm_svg_parser$SvgParser$attributeParser)),
		$andre_dietrich$parser_combinators$Combine$whitespace));
var $Garados007$elm_svg_parser$SvgParser$SvgText = function (a) {
	return {$: 1, a: a};
};
var $Garados007$elm_svg_parser$SvgParser$textParser = $andre_dietrich$parser_combinators$Combine$lazy(
	function (_v0) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$map,
			$Garados007$elm_svg_parser$SvgParser$SvgText,
			A2(
				$Garados007$elm_svg_parser$SvgParser$andMapRight,
				$andre_dietrich$parser_combinators$Combine$whitespace,
				$andre_dietrich$parser_combinators$Combine$regex('[^<]+')));
	});
var $Garados007$elm_svg_parser$SvgParser$closingOrChildrenParser = function (element) {
	var childrenParser = A2(
		$andre_dietrich$parser_combinators$Combine$map,
		function (children) {
			return _Utils_update(
				element,
				{eA: children});
		},
		A2(
			$Garados007$elm_svg_parser$SvgParser$andMapLeft,
			A2(
				$Garados007$elm_svg_parser$SvgParser$andMapLeft,
				A2(
					$Garados007$elm_svg_parser$SvgParser$andMapRight,
					A2(
						$Garados007$elm_svg_parser$SvgParser$andMapRight,
						$andre_dietrich$parser_combinators$Combine$whitespace,
						$andre_dietrich$parser_combinators$Combine$string('>')),
					$andre_dietrich$parser_combinators$Combine$many(
						$Garados007$elm_svg_parser$SvgParser$cyclic$nodeParser())),
				$andre_dietrich$parser_combinators$Combine$whitespace),
			$andre_dietrich$parser_combinators$Combine$string('</' + (element.mO + '>'))));
	return $andre_dietrich$parser_combinators$Combine$lazy(
		function (_v2) {
			return $andre_dietrich$parser_combinators$Combine$choice(
				_List_fromArray(
					[
						A2(
						$Garados007$elm_svg_parser$SvgParser$andMapRight,
						A2(
							$Garados007$elm_svg_parser$SvgParser$andMapRight,
							$andre_dietrich$parser_combinators$Combine$whitespace,
							$andre_dietrich$parser_combinators$Combine$string('/>')),
						$andre_dietrich$parser_combinators$Combine$succeed(element)),
						childrenParser
					]));
		});
};
function $Garados007$elm_svg_parser$SvgParser$cyclic$elementParser() {
	return $andre_dietrich$parser_combinators$Combine$lazy(
		function (_v1) {
			return A2(
				$andre_dietrich$parser_combinators$Combine$map,
				$Garados007$elm_svg_parser$SvgParser$SvgElement,
				A2(
					$andre_dietrich$parser_combinators$Combine$andThen,
					$Garados007$elm_svg_parser$SvgParser$closingOrChildrenParser,
					A2(
						$andre_dietrich$parser_combinators$Combine$andMap,
						$Garados007$elm_svg_parser$SvgParser$openingParser,
						A2(
							$andre_dietrich$parser_combinators$Combine$map,
							$Garados007$elm_svg_parser$SvgParser$flip($elm$core$Basics$always),
							$andre_dietrich$parser_combinators$Combine$whitespace))));
		});
}
function $Garados007$elm_svg_parser$SvgParser$cyclic$nodeParser() {
	return $andre_dietrich$parser_combinators$Combine$lazy(
		function (_v0) {
			return $andre_dietrich$parser_combinators$Combine$choice(
				_List_fromArray(
					[
						$Garados007$elm_svg_parser$SvgParser$textParser,
						$Garados007$elm_svg_parser$SvgParser$commentParser,
						$Garados007$elm_svg_parser$SvgParser$cyclic$elementParser()
					]));
		});
}
var $Garados007$elm_svg_parser$SvgParser$elementParser = $Garados007$elm_svg_parser$SvgParser$cyclic$elementParser();
$Garados007$elm_svg_parser$SvgParser$cyclic$elementParser = function () {
	return $Garados007$elm_svg_parser$SvgParser$elementParser;
};
var $Garados007$elm_svg_parser$SvgParser$nodeParser = $Garados007$elm_svg_parser$SvgParser$cyclic$nodeParser();
$Garados007$elm_svg_parser$SvgParser$cyclic$nodeParser = function () {
	return $Garados007$elm_svg_parser$SvgParser$nodeParser;
};
var $andre_dietrich$parser_combinators$Combine$InputStream = F3(
	function (data, input, position) {
		return {k_: data, ah: input, bb: position};
	});
var $andre_dietrich$parser_combinators$Combine$initStream = function (s) {
	return A3($andre_dietrich$parser_combinators$Combine$InputStream, s, s, 0);
};
var $andre_dietrich$parser_combinators$Combine$runParser = F3(
	function (p, st, s) {
		var _v0 = A3(
			$andre_dietrich$parser_combinators$Combine$app,
			p,
			st,
			$andre_dietrich$parser_combinators$Combine$initStream(s));
		if (!_v0.c.$) {
			var state = _v0.a;
			var stream = _v0.b;
			var res = _v0.c.a;
			return $elm$core$Result$Ok(
				_Utils_Tuple3(state, stream, res));
		} else {
			var state = _v0.a;
			var stream = _v0.b;
			var ms = _v0.c.a;
			return $elm$core$Result$Err(
				_Utils_Tuple3(state, stream, ms));
		}
	});
var $Garados007$elm_svg_parser$SvgParser$xmlDeclarationParser = A2(
	$andre_dietrich$parser_combinators$Combine$map,
	$elm$core$String$fromList,
	A2(
		$Garados007$elm_svg_parser$SvgParser$andMapRight,
		A2(
			$Garados007$elm_svg_parser$SvgParser$andMapRight,
			$andre_dietrich$parser_combinators$Combine$whitespace,
			$andre_dietrich$parser_combinators$Combine$string('<?xml')),
		A2(
			$andre_dietrich$parser_combinators$Combine$manyTill,
			$andre_dietrich$parser_combinators$Combine$Char$anyChar,
			$andre_dietrich$parser_combinators$Combine$string('?>'))));
var $Garados007$elm_svg_parser$SvgParser$parseToNode = function (input) {
	var _v0 = A3(
		$andre_dietrich$parser_combinators$Combine$runParser,
		A2(
			$Garados007$elm_svg_parser$SvgParser$andMapRight,
			A2($andre_dietrich$parser_combinators$Combine$optional, '', $Garados007$elm_svg_parser$SvgParser$xmlDeclarationParser),
			$Garados007$elm_svg_parser$SvgParser$nodeParser),
		_List_Nil,
		input);
	if (!_v0.$) {
		var _v1 = _v0.a;
		var svgNode = _v1.c;
		return $elm$core$Result$Ok(svgNode);
	} else {
		var _v2 = _v0.a;
		var stream = _v2.b;
		var errors = _v2.c;
		return $elm$core$Result$Err(
			A2($elm$core$String$join, ' or ', errors));
	}
};
var $Garados007$elm_svg_parser$SvgParser$parse = function (input) {
	var toHtml = function (svgNode) {
		if (!svgNode.$) {
			var element = svgNode.a;
			return (element.mO === 'svg') ? $elm$core$Result$Ok(
				A2(
					$elm$svg$Svg$svg,
					A2($elm$core$List$map, $Garados007$elm_svg_parser$SvgParser$toAttribute, element.em),
					A2($elm$core$List$map, $Garados007$elm_svg_parser$SvgParser$nodeToSvg, element.eA))) : $elm$core$Result$Err('Top element is not svg');
		} else {
			return $elm$core$Result$Err('Top element is not svg');
		}
	};
	return A2(
		$elm$core$Result$andThen,
		toHtml,
		$Garados007$elm_svg_parser$SvgParser$parseToNode(input));
};
var $author$project$Html$Parser$Util$toAttribute = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return A2($elm$html$Html$Attributes$attribute, name, value);
};
var $author$project$Html$Parser$Util$toVirtualDom = function (nodes) {
	return A2($elm$core$List$map, $author$project$Html$Parser$Util$toVirtualDomEach, nodes);
};
var $author$project$Html$Parser$Util$toVirtualDomEach = function (node) {
	switch (node.$) {
		case 1:
			var name = node.a;
			var attrs = node.b;
			var children = node.c;
			return ((name === 'p') && A2(
				$elm$core$List$member,
				_Utils_Tuple2('class', 'smsvg'),
				attrs)) ? A2(
				$elm$core$Result$withDefault,
				$elm$html$Html$text('error'),
				A2(
					$elm$core$Result$andThen,
					$Garados007$elm_svg_parser$SvgParser$parse,
					$truqu$elm_base64$Base64$decode(
						A3(
							$elm$core$List$foldl,
							$elm$core$Basics$append,
							'',
							A2($elm$core$List$map, $author$project$Html$Parser$nodeToString, children))))) : A3(
				$elm$html$Html$node,
				name,
				A2($elm$core$List$map, $author$project$Html$Parser$Util$toAttribute, attrs),
				$author$project$Html$Parser$Util$toVirtualDom(children));
		case 0:
			var s = node.a;
			return $elm$html$Html$text(s);
		default:
			return $elm$html$Html$text('');
	}
};
var $author$project$Helper$HtmlUtil$convertHtmlStringToResultListHtml = function (str) {
	return A2(
		$elm$core$Result$mapError,
		$author$project$Type$Error$deadEndsToString,
		A2(
			$elm$core$Result$map,
			$author$project$Html$Parser$Util$toVirtualDom,
			$author$project$Html$Parser$run(str)));
};
var $author$project$Helper$HtmlUtil$convertHtmlStringToElement = function (str) {
	var _v0 = $author$project$Helper$HtmlUtil$convertHtmlStringToResultListHtml(str);
	if (!_v0.$) {
		var content = _v0.a;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_Nil,
			A2($elm$core$List$map, $mdgriffith$elm_ui$Element$html, content));
	} else {
		var techDetailsString = _v0.a;
		return $mdgriffith$elm_ui$Element$text(techDetailsString);
	}
};
var $mdgriffith$elm_ui$Internal$Flag$fontAlignment = $mdgriffith$elm_ui$Internal$Flag$flag(12);
var $mdgriffith$elm_ui$Element$Font$center = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontAlignment, $mdgriffith$elm_ui$Internal$Style$classes.oX);
var $mdgriffith$elm_ui$Internal$Model$InFront = 4;
var $mdgriffith$elm_ui$Element$createNearby = F2(
	function (loc, element) {
		if (element.$ === 3) {
			return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
		} else {
			return A2($mdgriffith$elm_ui$Internal$Model$Nearby, loc, element);
		}
	});
var $mdgriffith$elm_ui$Element$inFront = function (element) {
	return A2($mdgriffith$elm_ui$Element$createNearby, 4, element);
};
var $mdgriffith$elm_ui$Internal$Model$MoveY = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Internal$Flag$moveY = $mdgriffith$elm_ui$Internal$Flag$flag(26);
var $mdgriffith$elm_ui$Element$moveUp = function (y) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$TransformComponent,
		$mdgriffith$elm_ui$Internal$Flag$moveY,
		$mdgriffith$elm_ui$Internal$Model$MoveY(-y));
};
var $mdgriffith$elm_ui$Internal$Model$Rotate = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$rotate = $mdgriffith$elm_ui$Internal$Flag$flag(24);
var $mdgriffith$elm_ui$Element$rotate = function (angle) {
	return A2(
		$mdgriffith$elm_ui$Internal$Model$TransformComponent,
		$mdgriffith$elm_ui$Internal$Flag$rotate,
		A2(
			$mdgriffith$elm_ui$Internal$Model$Rotate,
			_Utils_Tuple3(0, 0, 1),
			angle));
};
var $mdgriffith$elm_ui$Internal$Model$boxShadowClass = function (shadow) {
	return $elm$core$String$concat(
		_List_fromArray(
			[
				shadow.hp ? 'box-inset' : 'box-',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.b.a) + 'px',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.b.b) + 'px',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.b_) + 'px',
				$mdgriffith$elm_ui$Internal$Model$floatClass(shadow.iT) + 'px',
				$mdgriffith$elm_ui$Internal$Model$formatColorClass(shadow.b0)
			]));
};
var $mdgriffith$elm_ui$Internal$Flag$shadows = $mdgriffith$elm_ui$Internal$Flag$flag(19);
var $mdgriffith$elm_ui$Element$Border$shadow = function (almostShade) {
	var shade = {b_: almostShade.b_, b0: almostShade.b0, hp: false, b: almostShade.b, iT: almostShade.iT};
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$shadows,
		A3(
			$mdgriffith$elm_ui$Internal$Model$Single,
			$mdgriffith$elm_ui$Internal$Model$boxShadowClass(shade),
			'box-shadow',
			$mdgriffith$elm_ui$Internal$Model$formatBoxShadow(shade)));
};
var $mdgriffith$elm_ui$Internal$Model$Transparency = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $mdgriffith$elm_ui$Internal$Flag$transparency = $mdgriffith$elm_ui$Internal$Flag$flag(0);
var $mdgriffith$elm_ui$Element$transparent = function (on) {
	return on ? A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$transparency,
		A2($mdgriffith$elm_ui$Internal$Model$Transparency, 'transparent', 1.0)) : A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$transparency,
		A2($mdgriffith$elm_ui$Internal$Model$Transparency, 'visible', 0.0));
};
var $mdgriffith$elm_ui$Element$Input$white = A3($mdgriffith$elm_ui$Element$rgb, 1, 1, 1);
var $mdgriffith$elm_ui$Element$Input$defaultCheckbox = function (checked) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Internal$Model$htmlClass('focusable'),
				$mdgriffith$elm_ui$Element$width(
				$mdgriffith$elm_ui$Element$px(14)),
				$mdgriffith$elm_ui$Element$height(
				$mdgriffith$elm_ui$Element$px(14)),
				$mdgriffith$elm_ui$Element$Font$color($mdgriffith$elm_ui$Element$Input$white),
				$mdgriffith$elm_ui$Element$centerY,
				$mdgriffith$elm_ui$Element$Font$size(9),
				$mdgriffith$elm_ui$Element$Font$center,
				$mdgriffith$elm_ui$Element$Border$rounded(3),
				$mdgriffith$elm_ui$Element$Border$color(
				checked ? A3($mdgriffith$elm_ui$Element$rgb, 59 / 255, 153 / 255, 252 / 255) : A3($mdgriffith$elm_ui$Element$rgb, 211 / 255, 211 / 255, 211 / 255)),
				$mdgriffith$elm_ui$Element$Border$shadow(
				{
					b_: 1,
					b0: checked ? A4($mdgriffith$elm_ui$Element$rgba, 238 / 255, 238 / 255, 238 / 255, 0) : A3($mdgriffith$elm_ui$Element$rgb, 238 / 255, 238 / 255, 238 / 255),
					b: _Utils_Tuple2(0, 0),
					iT: 1
				}),
				$mdgriffith$elm_ui$Element$Background$color(
				checked ? A3($mdgriffith$elm_ui$Element$rgb, 59 / 255, 153 / 255, 252 / 255) : $mdgriffith$elm_ui$Element$Input$white),
				$mdgriffith$elm_ui$Element$Border$width(
				checked ? 0 : 1),
				$mdgriffith$elm_ui$Element$inFront(
				A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Border$color($mdgriffith$elm_ui$Element$Input$white),
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(6)),
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(9)),
							$mdgriffith$elm_ui$Element$rotate(
							$elm$core$Basics$degrees(-45)),
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$moveUp(1),
							$mdgriffith$elm_ui$Element$transparent(!checked),
							$mdgriffith$elm_ui$Element$Border$widthEach(
							{er: 2, e9: 2, fz: 0, fY: 0})
						]),
					$mdgriffith$elm_ui$Element$none))
			]),
		$mdgriffith$elm_ui$Element$none);
};
var $elm$html$Html$Attributes$autofocus = $elm$html$Html$Attributes$boolProperty('autofocus');
var $mdgriffith$elm_ui$Element$Input$focusedOnLoad = $mdgriffith$elm_ui$Internal$Model$Attr(
	$elm$html$Html$Attributes$autofocus(true));
var $mdgriffith$elm_ui$Element$Input$HiddenLabel = function (a) {
	return {$: 1, a: a};
};
var $mdgriffith$elm_ui$Element$Input$labelHidden = $mdgriffith$elm_ui$Element$Input$HiddenLabel;
var $mdgriffith$elm_ui$Element$Input$Label = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $mdgriffith$elm_ui$Element$Input$OnRight = 0;
var $mdgriffith$elm_ui$Element$Input$labelRight = $mdgriffith$elm_ui$Element$Input$Label(0);
var $author$project$Helper$HtmlUtil$nodesToHtmls = function (nodes) {
	return $author$project$Html$Parser$Util$toVirtualDom(nodes);
};
var $author$project$Helper$HtmlUtil$makeHtmlElements = A2(
	$elm$core$Basics$composeR,
	$author$project$Helper$HtmlUtil$nodesToHtmls,
	$elm$core$List$map($mdgriffith$elm_ui$Element$html));
var $mdgriffith$elm_ui$Element$Input$Option = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$Input$defaultRadioOption = F2(
	function (optionLabel, status) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(10),
					$mdgriffith$elm_ui$Element$alignLeft,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(14)),
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$px(14)),
							$mdgriffith$elm_ui$Element$Background$color($mdgriffith$elm_ui$Element$Input$white),
							$mdgriffith$elm_ui$Element$Border$rounded(7),
							function () {
							if (status === 2) {
								return $mdgriffith$elm_ui$Internal$Model$htmlClass('focusable');
							} else {
								return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
							}
						}(),
							$mdgriffith$elm_ui$Element$Border$width(
							function () {
								switch (status) {
									case 0:
										return 1;
									case 1:
										return 1;
									default:
										return 5;
								}
							}()),
							$mdgriffith$elm_ui$Element$Border$color(
							function () {
								switch (status) {
									case 0:
										return A3($mdgriffith$elm_ui$Element$rgb, 208 / 255, 208 / 255, 208 / 255);
									case 1:
										return A3($mdgriffith$elm_ui$Element$rgb, 208 / 255, 208 / 255, 208 / 255);
									default:
										return A3($mdgriffith$elm_ui$Element$rgb, 59 / 255, 153 / 255, 252 / 255);
								}
							}())
						]),
					$mdgriffith$elm_ui$Element$none),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Internal$Model$htmlClass('unfocusable')
						]),
					optionLabel)
				]));
	});
var $mdgriffith$elm_ui$Element$Input$option = F2(
	function (val, txt) {
		return A2(
			$mdgriffith$elm_ui$Element$Input$Option,
			val,
			$mdgriffith$elm_ui$Element$Input$defaultRadioOption(txt));
	});
var $mdgriffith$elm_ui$Element$Input$Column = 1;
var $mdgriffith$elm_ui$Element$Input$AfterFound = 2;
var $mdgriffith$elm_ui$Element$Input$BeforeFound = 1;
var $mdgriffith$elm_ui$Element$Input$Idle = 0;
var $mdgriffith$elm_ui$Element$Input$NotFound = 0;
var $mdgriffith$elm_ui$Element$Input$Selected = 2;
var $mdgriffith$elm_ui$Element$Input$column = F2(
	function (attributes, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asColumn,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					attributes)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Element$Input$downArrow = 'ArrowDown';
var $mdgriffith$elm_ui$Internal$Model$filter = function (attrs) {
	return A3(
		$elm$core$List$foldr,
		F2(
			function (x, _v0) {
				var found = _v0.a;
				var has = _v0.b;
				switch (x.$) {
					case 0:
						return _Utils_Tuple2(found, has);
					case 3:
						var key = x.a;
						return _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							has);
					case 1:
						var attr = x.a;
						return _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							has);
					case 4:
						var style = x.b;
						return _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							has);
					case 7:
						var width = x.a;
						return A2($elm$core$Set$member, 'width', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							A2($elm$core$Set$insert, 'width', has));
					case 8:
						var height = x.a;
						return A2($elm$core$Set$member, 'height', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							A2($elm$core$Set$insert, 'height', has));
					case 2:
						var description = x.a;
						return A2($elm$core$Set$member, 'described', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							A2($elm$core$Set$insert, 'described', has));
					case 9:
						var location = x.a;
						var elem = x.b;
						return _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							has);
					case 6:
						return A2($elm$core$Set$member, 'align-x', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							A2($elm$core$Set$insert, 'align-x', has));
					case 5:
						return A2($elm$core$Set$member, 'align-y', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							A2($elm$core$Set$insert, 'align-y', has));
					default:
						return A2($elm$core$Set$member, 'transform', has) ? _Utils_Tuple2(found, has) : _Utils_Tuple2(
							A2($elm$core$List$cons, x, found),
							A2($elm$core$Set$insert, 'transform', has));
				}
			}),
		_Utils_Tuple2(_List_Nil, $elm$core$Set$empty),
		attrs).a;
};
var $mdgriffith$elm_ui$Internal$Model$get = F2(
	function (attrs, isAttr) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, found) {
					return isAttr(x) ? A2($elm$core$List$cons, x, found) : found;
				}),
			_List_Nil,
			$mdgriffith$elm_ui$Internal$Model$filter(attrs));
	});
var $mdgriffith$elm_ui$Element$Input$leftArrow = 'ArrowLeft';
var $mdgriffith$elm_ui$Element$Input$rightArrow = 'ArrowRight';
var $mdgriffith$elm_ui$Element$Input$row = F2(
	function (attributes, children) {
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asRow,
			$mdgriffith$elm_ui$Internal$Model$div,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				attributes),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(children));
	});
var $mdgriffith$elm_ui$Element$Input$upArrow = 'ArrowUp';
var $mdgriffith$elm_ui$Element$Input$radioHelper = F3(
	function (orientation, attrs, input) {
		var track = F2(
			function (opt, _v14) {
				var found = _v14.a;
				var prev = _v14.b;
				var nxt = _v14.c;
				var val = opt.a;
				switch (found) {
					case 0:
						return _Utils_eq(
							$elm$core$Maybe$Just(val),
							input.ob) ? _Utils_Tuple3(1, prev, nxt) : _Utils_Tuple3(found, val, nxt);
					case 1:
						return _Utils_Tuple3(2, prev, val);
					default:
						return _Utils_Tuple3(found, prev, nxt);
				}
			});
		var renderOption = function (_v11) {
			var val = _v11.a;
			var view = _v11.b;
			var status = _Utils_eq(
				$elm$core$Maybe$Just(val),
				input.ob) ? 2 : 0;
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$pointer,
						function () {
						if (!orientation) {
							return $mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink);
						} else {
							return $mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill);
						}
					}(),
						$mdgriffith$elm_ui$Element$Events$onClick(
						input.cS(val)),
						function () {
						if (status === 2) {
							return $mdgriffith$elm_ui$Internal$Model$Attr(
								A2($elm$html$Html$Attributes$attribute, 'aria-checked', 'true'));
						} else {
							return $mdgriffith$elm_ui$Internal$Model$Attr(
								A2($elm$html$Html$Attributes$attribute, 'aria-checked', 'false'));
						}
					}(),
						$mdgriffith$elm_ui$Internal$Model$Attr(
						A2($elm$html$Html$Attributes$attribute, 'role', 'radio'))
					]),
				view(status));
		};
		var prevNext = function () {
			var _v5 = input.ni;
			if (!_v5.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var _v6 = _v5.a;
				var val = _v6.a;
				return function (_v7) {
					var found = _v7.a;
					var b = _v7.b;
					var a = _v7.c;
					switch (found) {
						case 0:
							return $elm$core$Maybe$Just(
								_Utils_Tuple2(b, val));
						case 1:
							return $elm$core$Maybe$Just(
								_Utils_Tuple2(b, val));
						default:
							return $elm$core$Maybe$Just(
								_Utils_Tuple2(b, a));
					}
				}(
					A3(
						$elm$core$List$foldl,
						track,
						_Utils_Tuple3(0, val, val),
						input.ni));
			}
		}();
		var optionArea = function () {
			if (!orientation) {
				return A2(
					$mdgriffith$elm_ui$Element$Input$row,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute(input.bD),
						attrs),
					A2($elm$core$List$map, renderOption, input.ni));
			} else {
				return A2(
					$mdgriffith$elm_ui$Element$Input$column,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute(input.bD),
						attrs),
					A2($elm$core$List$map, renderOption, input.ni));
			}
		}();
		var events = A2(
			$mdgriffith$elm_ui$Internal$Model$get,
			attrs,
			function (attr) {
				_v3$3:
				while (true) {
					switch (attr.$) {
						case 7:
							if (attr.a.$ === 2) {
								return true;
							} else {
								break _v3$3;
							}
						case 8:
							if (attr.a.$ === 2) {
								return true;
							} else {
								break _v3$3;
							}
						case 1:
							return true;
						default:
							break _v3$3;
					}
				}
				return false;
			});
		return A3(
			$mdgriffith$elm_ui$Element$Input$applyLabel,
			_Utils_ap(
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							$elm$core$Maybe$Just($mdgriffith$elm_ui$Element$alignLeft),
							$elm$core$Maybe$Just(
							$mdgriffith$elm_ui$Element$Input$tabindex(0)),
							$elm$core$Maybe$Just(
							$mdgriffith$elm_ui$Internal$Model$htmlClass('focus')),
							$elm$core$Maybe$Just($mdgriffith$elm_ui$Element$Region$announce),
							$elm$core$Maybe$Just(
							$mdgriffith$elm_ui$Internal$Model$Attr(
								A2($elm$html$Html$Attributes$attribute, 'role', 'radiogroup'))),
							function () {
							if (prevNext.$ === 1) {
								return $elm$core$Maybe$Nothing;
							} else {
								var _v1 = prevNext.a;
								var prev = _v1.a;
								var next = _v1.b;
								return $elm$core$Maybe$Just(
									$mdgriffith$elm_ui$Element$Input$onKeyLookup(
										function (code) {
											if (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$leftArrow)) {
												return $elm$core$Maybe$Just(
													input.cS(prev));
											} else {
												if (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$upArrow)) {
													return $elm$core$Maybe$Just(
														input.cS(prev));
												} else {
													if (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$rightArrow)) {
														return $elm$core$Maybe$Just(
															input.cS(next));
													} else {
														if (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$downArrow)) {
															return $elm$core$Maybe$Just(
																input.cS(next));
														} else {
															if (_Utils_eq(code, $mdgriffith$elm_ui$Element$Input$space)) {
																var _v2 = input.ob;
																if (_v2.$ === 1) {
																	return $elm$core$Maybe$Just(
																		input.cS(prev));
																} else {
																	return $elm$core$Maybe$Nothing;
																}
															} else {
																return $elm$core$Maybe$Nothing;
															}
														}
													}
												}
											}
										}));
							}
						}()
						])),
				events),
			input.bD,
			optionArea);
	});
var $mdgriffith$elm_ui$Element$Input$radio = $mdgriffith$elm_ui$Element$Input$radioHelper(1);
var $mdgriffith$elm_ui$Element$Input$TextInputNode = function (a) {
	return {$: 0, a: a};
};
var $mdgriffith$elm_ui$Element$Input$TextArea = {$: 1};
var $mdgriffith$elm_ui$Element$Input$autofill = A2(
	$elm$core$Basics$composeL,
	$mdgriffith$elm_ui$Internal$Model$Attr,
	$elm$html$Html$Attributes$attribute('autocomplete'));
var $mdgriffith$elm_ui$Internal$Model$Behind = 5;
var $mdgriffith$elm_ui$Element$behindContent = function (element) {
	return A2($mdgriffith$elm_ui$Element$createNearby, 5, element);
};
var $mdgriffith$elm_ui$Element$Input$calcMoveToCompensateForPadding = function (attrs) {
	var gatherSpacing = F2(
		function (attr, found) {
			if ((attr.$ === 4) && (attr.b.$ === 5)) {
				var _v2 = attr.b;
				var x = _v2.b;
				var y = _v2.c;
				if (found.$ === 1) {
					return $elm$core$Maybe$Just(y);
				} else {
					return found;
				}
			} else {
				return found;
			}
		});
	var _v0 = A3($elm$core$List$foldr, gatherSpacing, $elm$core$Maybe$Nothing, attrs);
	if (_v0.$ === 1) {
		return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
	} else {
		var vSpace = _v0.a;
		return $mdgriffith$elm_ui$Element$moveUp(
			$elm$core$Basics$floor(vSpace / 2));
	}
};
var $mdgriffith$elm_ui$Internal$Flag$overflow = $mdgriffith$elm_ui$Internal$Flag$flag(20);
var $mdgriffith$elm_ui$Element$clip = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.kx);
var $mdgriffith$elm_ui$Element$Input$darkGrey = A3($mdgriffith$elm_ui$Element$rgb, 186 / 255, 189 / 255, 182 / 255);
var $mdgriffith$elm_ui$Element$Input$defaultTextPadding = A2($mdgriffith$elm_ui$Element$paddingXY, 12, 12);
var $mdgriffith$elm_ui$Element$Input$defaultTextBoxStyle = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$Input$defaultTextPadding,
		$mdgriffith$elm_ui$Element$Border$rounded(3),
		$mdgriffith$elm_ui$Element$Border$color($mdgriffith$elm_ui$Element$Input$darkGrey),
		$mdgriffith$elm_ui$Element$Background$color($mdgriffith$elm_ui$Element$Input$white),
		$mdgriffith$elm_ui$Element$Border$width(1),
		$mdgriffith$elm_ui$Element$spacing(5),
		$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
		$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink)
	]);
var $mdgriffith$elm_ui$Element$Input$getHeight = function (attr) {
	if (attr.$ === 8) {
		var h = attr.a;
		return $elm$core$Maybe$Just(h);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $mdgriffith$elm_ui$Element$Input$isConstrained = function (len) {
	isConstrained:
	while (true) {
		switch (len.$) {
			case 1:
				return false;
			case 0:
				return true;
			case 2:
				return true;
			case 3:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isConstrained;
			default:
				var l = len.b;
				return true;
		}
	}
};
var $mdgriffith$elm_ui$Element$Input$isStacked = function (label) {
	if (!label.$) {
		var loc = label.a;
		switch (loc) {
			case 0:
				return false;
			case 1:
				return false;
			case 2:
				return true;
			default:
				return true;
		}
	} else {
		return true;
	}
};
var $mdgriffith$elm_ui$Element$Input$negateBox = function (box) {
	return {er: -box.er, e9: -box.e9, fz: -box.fz, fY: -box.fY};
};
var $mdgriffith$elm_ui$Element$Input$isFill = function (len) {
	isFill:
	while (true) {
		switch (len.$) {
			case 2:
				return true;
			case 1:
				return false;
			case 0:
				return false;
			case 3:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isFill;
			default:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isFill;
		}
	}
};
var $mdgriffith$elm_ui$Element$Input$isPixel = function (len) {
	isPixel:
	while (true) {
		switch (len.$) {
			case 1:
				return false;
			case 0:
				return true;
			case 2:
				return false;
			case 3:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isPixel;
			default:
				var l = len.b;
				var $temp$len = l;
				len = $temp$len;
				continue isPixel;
		}
	}
};
var $mdgriffith$elm_ui$Element$Input$redistributeOver = F4(
	function (isMultiline, stacked, attr, els) {
		switch (attr.$) {
			case 9:
				return _Utils_update(
					els,
					{
						nq: A2($elm$core$List$cons, attr, els.nq)
					});
			case 7:
				var width = attr.a;
				return $mdgriffith$elm_ui$Element$Input$isFill(width) ? _Utils_update(
					els,
					{
						n: A2($elm$core$List$cons, attr, els.n),
						ah: A2($elm$core$List$cons, attr, els.ah),
						nq: A2($elm$core$List$cons, attr, els.nq)
					}) : (stacked ? _Utils_update(
					els,
					{
						n: A2($elm$core$List$cons, attr, els.n)
					}) : _Utils_update(
					els,
					{
						nq: A2($elm$core$List$cons, attr, els.nq)
					}));
			case 8:
				var height = attr.a;
				return (!stacked) ? _Utils_update(
					els,
					{
						n: A2($elm$core$List$cons, attr, els.n),
						nq: A2($elm$core$List$cons, attr, els.nq)
					}) : ($mdgriffith$elm_ui$Element$Input$isFill(height) ? _Utils_update(
					els,
					{
						n: A2($elm$core$List$cons, attr, els.n),
						nq: A2($elm$core$List$cons, attr, els.nq)
					}) : ($mdgriffith$elm_ui$Element$Input$isPixel(height) ? _Utils_update(
					els,
					{
						nq: A2($elm$core$List$cons, attr, els.nq)
					}) : _Utils_update(
					els,
					{
						nq: A2($elm$core$List$cons, attr, els.nq)
					})));
			case 6:
				return _Utils_update(
					els,
					{
						n: A2($elm$core$List$cons, attr, els.n)
					});
			case 5:
				return _Utils_update(
					els,
					{
						n: A2($elm$core$List$cons, attr, els.n)
					});
			case 4:
				switch (attr.b.$) {
					case 5:
						var _v1 = attr.b;
						return _Utils_update(
							els,
							{
								n: A2($elm$core$List$cons, attr, els.n),
								ah: A2($elm$core$List$cons, attr, els.ah),
								nq: A2($elm$core$List$cons, attr, els.nq),
								cu: A2($elm$core$List$cons, attr, els.cu)
							});
					case 7:
						var cls = attr.a;
						var _v2 = attr.b;
						var pad = _v2.a;
						var t = _v2.b;
						var r = _v2.c;
						var b = _v2.d;
						var l = _v2.e;
						if (isMultiline) {
							return _Utils_update(
								els,
								{
									ad: A2($elm$core$List$cons, attr, els.ad),
									nq: A2($elm$core$List$cons, attr, els.nq)
								});
						} else {
							var newTop = t - A2($elm$core$Basics$min, t, b);
							var newLineHeight = $mdgriffith$elm_ui$Element$htmlAttribute(
								A2(
									$elm$html$Html$Attributes$style,
									'line-height',
									'calc(1.0em + ' + ($elm$core$String$fromFloat(
										2 * A2($elm$core$Basics$min, t, b)) + 'px)')));
							var newHeight = $mdgriffith$elm_ui$Element$htmlAttribute(
								A2(
									$elm$html$Html$Attributes$style,
									'height',
									'calc(1.0em + ' + ($elm$core$String$fromFloat(
										2 * A2($elm$core$Basics$min, t, b)) + 'px)')));
							var newBottom = b - A2($elm$core$Basics$min, t, b);
							var reducedVerticalPadding = A2(
								$mdgriffith$elm_ui$Internal$Model$StyleClass,
								$mdgriffith$elm_ui$Internal$Flag$padding,
								A5(
									$mdgriffith$elm_ui$Internal$Model$PaddingStyle,
									A4($mdgriffith$elm_ui$Internal$Model$paddingNameFloat, newTop, r, newBottom, l),
									newTop,
									r,
									newBottom,
									l));
							return _Utils_update(
								els,
								{
									ad: A2($elm$core$List$cons, attr, els.ad),
									ah: A2(
										$elm$core$List$cons,
										newHeight,
										A2($elm$core$List$cons, newLineHeight, els.ah)),
									nq: A2($elm$core$List$cons, reducedVerticalPadding, els.nq)
								});
						}
					case 6:
						var _v3 = attr.b;
						return _Utils_update(
							els,
							{
								ad: A2($elm$core$List$cons, attr, els.ad),
								nq: A2($elm$core$List$cons, attr, els.nq)
							});
					case 10:
						return _Utils_update(
							els,
							{
								ad: A2($elm$core$List$cons, attr, els.ad),
								nq: A2($elm$core$List$cons, attr, els.nq)
							});
					case 2:
						return _Utils_update(
							els,
							{
								n: A2($elm$core$List$cons, attr, els.n)
							});
					case 1:
						var _v4 = attr.b;
						return _Utils_update(
							els,
							{
								n: A2($elm$core$List$cons, attr, els.n)
							});
					default:
						var flag = attr.a;
						var cls = attr.b;
						return _Utils_update(
							els,
							{
								nq: A2($elm$core$List$cons, attr, els.nq)
							});
				}
			case 0:
				return els;
			case 1:
				var a = attr.a;
				return _Utils_update(
					els,
					{
						ah: A2($elm$core$List$cons, attr, els.ah)
					});
			case 2:
				return _Utils_update(
					els,
					{
						ah: A2($elm$core$List$cons, attr, els.ah)
					});
			case 3:
				return _Utils_update(
					els,
					{
						nq: A2($elm$core$List$cons, attr, els.nq)
					});
			default:
				return _Utils_update(
					els,
					{
						ah: A2($elm$core$List$cons, attr, els.ah)
					});
		}
	});
var $mdgriffith$elm_ui$Element$Input$redistribute = F3(
	function (isMultiline, stacked, attrs) {
		return function (redist) {
			return {
				ad: $elm$core$List$reverse(redist.ad),
				n: $elm$core$List$reverse(redist.n),
				ah: $elm$core$List$reverse(redist.ah),
				nq: $elm$core$List$reverse(redist.nq),
				cu: $elm$core$List$reverse(redist.cu)
			};
		}(
			A3(
				$elm$core$List$foldl,
				A2($mdgriffith$elm_ui$Element$Input$redistributeOver, isMultiline, stacked),
				{ad: _List_Nil, n: _List_Nil, ah: _List_Nil, nq: _List_Nil, cu: _List_Nil},
				attrs));
	});
var $mdgriffith$elm_ui$Element$Input$renderBox = function (_v0) {
	var top = _v0.fY;
	var right = _v0.fz;
	var bottom = _v0.er;
	var left = _v0.e9;
	return $elm$core$String$fromInt(top) + ('px ' + ($elm$core$String$fromInt(right) + ('px ' + ($elm$core$String$fromInt(bottom) + ('px ' + ($elm$core$String$fromInt(left) + 'px'))))));
};
var $mdgriffith$elm_ui$Element$alpha = function (o) {
	var transparency = function (x) {
		return 1 - x;
	}(
		A2(
			$elm$core$Basics$min,
			1.0,
			A2($elm$core$Basics$max, 0.0, o)));
	return A2(
		$mdgriffith$elm_ui$Internal$Model$StyleClass,
		$mdgriffith$elm_ui$Internal$Flag$transparency,
		A2(
			$mdgriffith$elm_ui$Internal$Model$Transparency,
			'transparency-' + $mdgriffith$elm_ui$Internal$Model$floatClass(transparency),
			transparency));
};
var $mdgriffith$elm_ui$Element$Input$charcoal = A3($mdgriffith$elm_ui$Element$rgb, 136 / 255, 138 / 255, 133 / 255);
var $mdgriffith$elm_ui$Element$Input$renderPlaceholder = F3(
	function (_v0, forPlaceholder, on) {
		var placeholderAttrs = _v0.a;
		var placeholderEl = _v0.b;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_Utils_ap(
				forPlaceholder,
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$color($mdgriffith$elm_ui$Element$Input$charcoal),
							$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.hT + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.nr)),
							$mdgriffith$elm_ui$Element$clip,
							$mdgriffith$elm_ui$Element$Border$color(
							A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0)),
							$mdgriffith$elm_ui$Element$Background$color(
							A4($mdgriffith$elm_ui$Element$rgba, 0, 0, 0, 0)),
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$alpha(
							on ? 1 : 0)
						]),
					placeholderAttrs)),
			placeholderEl);
	});
var $mdgriffith$elm_ui$Element$scrollbarY = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.n9);
var $elm$html$Html$Attributes$spellcheck = $elm$html$Html$Attributes$boolProperty('spellcheck');
var $mdgriffith$elm_ui$Element$Input$spellcheck = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Attributes$spellcheck);
var $mdgriffith$elm_ui$Element$Input$value = A2($elm$core$Basics$composeL, $mdgriffith$elm_ui$Internal$Model$Attr, $elm$html$Html$Attributes$value);
var $mdgriffith$elm_ui$Element$Input$textHelper = F3(
	function (textInput, attrs, textOptions) {
		var withDefaults = _Utils_ap($mdgriffith$elm_ui$Element$Input$defaultTextBoxStyle, attrs);
		var redistributed = A3(
			$mdgriffith$elm_ui$Element$Input$redistribute,
			_Utils_eq(textInput.pu, $mdgriffith$elm_ui$Element$Input$TextArea),
			$mdgriffith$elm_ui$Element$Input$isStacked(textOptions.bD),
			withDefaults);
		var onlySpacing = function (attr) {
			if ((attr.$ === 4) && (attr.b.$ === 5)) {
				var _v9 = attr.b;
				return true;
			} else {
				return false;
			}
		};
		var heightConstrained = function () {
			var _v7 = textInput.pu;
			if (!_v7.$) {
				var inputType = _v7.a;
				return false;
			} else {
				return A2(
					$elm$core$Maybe$withDefault,
					false,
					A2(
						$elm$core$Maybe$map,
						$mdgriffith$elm_ui$Element$Input$isConstrained,
						$elm$core$List$head(
							$elm$core$List$reverse(
								A2($elm$core$List$filterMap, $mdgriffith$elm_ui$Element$Input$getHeight, withDefaults)))));
			}
		}();
		var getPadding = function (attr) {
			if ((attr.$ === 4) && (attr.b.$ === 7)) {
				var cls = attr.a;
				var _v6 = attr.b;
				var pad = _v6.a;
				var t = _v6.b;
				var r = _v6.c;
				var b = _v6.d;
				var l = _v6.e;
				return $elm$core$Maybe$Just(
					{
						er: A2(
							$elm$core$Basics$max,
							0,
							$elm$core$Basics$floor(b - 3)),
						e9: A2(
							$elm$core$Basics$max,
							0,
							$elm$core$Basics$floor(l - 3)),
						fz: A2(
							$elm$core$Basics$max,
							0,
							$elm$core$Basics$floor(r - 3)),
						fY: A2(
							$elm$core$Basics$max,
							0,
							$elm$core$Basics$floor(t - 3))
					});
			} else {
				return $elm$core$Maybe$Nothing;
			}
		};
		var parentPadding = A2(
			$elm$core$Maybe$withDefault,
			{er: 0, e9: 0, fz: 0, fY: 0},
			$elm$core$List$head(
				$elm$core$List$reverse(
					A2($elm$core$List$filterMap, getPadding, withDefaults))));
		var inputElement = A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			function () {
				var _v3 = textInput.pu;
				if (!_v3.$) {
					var inputType = _v3.a;
					return $mdgriffith$elm_ui$Internal$Model$NodeName('input');
				} else {
					return $mdgriffith$elm_ui$Internal$Model$NodeName('textarea');
				}
			}(),
			_Utils_ap(
				function () {
					var _v4 = textInput.pu;
					if (!_v4.$) {
						var inputType = _v4.a;
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Internal$Model$Attr(
								$elm$html$Html$Attributes$type_(inputType)),
								$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.l8)
							]);
					} else {
						return _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$clip,
								$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.l4),
								$mdgriffith$elm_ui$Element$Input$calcMoveToCompensateForPadding(withDefaults),
								$mdgriffith$elm_ui$Element$paddingEach(parentPadding),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								A2(
									$elm$html$Html$Attributes$style,
									'margin',
									$mdgriffith$elm_ui$Element$Input$renderBox(
										$mdgriffith$elm_ui$Element$Input$negateBox(parentPadding)))),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								A2($elm$html$Html$Attributes$style, 'box-sizing', 'content-box'))
							]);
					}
				}(),
				_Utils_ap(
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Input$value(textOptions.cY),
							$mdgriffith$elm_ui$Internal$Model$Attr(
							$elm$html$Html$Events$onInput(textOptions.cS)),
							$mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute(textOptions.bD),
							$mdgriffith$elm_ui$Element$Input$spellcheck(textInput.aG),
							A2(
							$elm$core$Maybe$withDefault,
							$mdgriffith$elm_ui$Internal$Model$NoAttribute,
							A2($elm$core$Maybe$map, $mdgriffith$elm_ui$Element$Input$autofill, textInput.as))
						]),
					redistributed.ah)),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(_List_Nil));
		var wrappedInput = function () {
			var _v0 = textInput.pu;
			if (_v0.$ === 1) {
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					_Utils_ap(
						(heightConstrained ? $elm$core$List$cons($mdgriffith$elm_ui$Element$scrollbarY) : $elm$core$Basics$identity)(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									A2($elm$core$List$any, $mdgriffith$elm_ui$Element$Input$hasFocusStyle, withDefaults) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.g5),
									$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.l7)
								])),
						redistributed.nq),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[
								A4(
								$mdgriffith$elm_ui$Internal$Model$element,
								$mdgriffith$elm_ui$Internal$Model$asParagraph,
								$mdgriffith$elm_ui$Internal$Model$div,
								A2(
									$elm$core$List$cons,
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									A2(
										$elm$core$List$cons,
										$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
										A2(
											$elm$core$List$cons,
											$mdgriffith$elm_ui$Element$inFront(inputElement),
											A2(
												$elm$core$List$cons,
												$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.l6),
												redistributed.cu)))),
								$mdgriffith$elm_ui$Internal$Model$Unkeyed(
									function () {
										if (textOptions.cY === '') {
											var _v1 = textOptions.cV;
											if (_v1.$ === 1) {
												return _List_fromArray(
													[
														$mdgriffith$elm_ui$Element$text('\u00A0')
													]);
											} else {
												var place = _v1.a;
												return _List_fromArray(
													[
														A3($mdgriffith$elm_ui$Element$Input$renderPlaceholder, place, _List_Nil, textOptions.cY === '')
													]);
											}
										} else {
											return _List_fromArray(
												[
													$mdgriffith$elm_ui$Internal$Model$unstyled(
													A2(
														$elm$html$Html$span,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class($mdgriffith$elm_ui$Internal$Style$classes.l5)
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(textOptions.cY + '\u00A0')
															])))
												]);
										}
									}()))
							])));
			} else {
				var inputType = _v0.a;
				return A4(
					$mdgriffith$elm_ui$Internal$Model$element,
					$mdgriffith$elm_ui$Internal$Model$asEl,
					$mdgriffith$elm_ui$Internal$Model$div,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						A2(
							$elm$core$List$cons,
							A2($elm$core$List$any, $mdgriffith$elm_ui$Element$Input$hasFocusStyle, withDefaults) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.g5),
							$elm$core$List$concat(
								_List_fromArray(
									[
										redistributed.nq,
										function () {
										var _v2 = textOptions.cV;
										if (_v2.$ === 1) {
											return _List_Nil;
										} else {
											var place = _v2.a;
											return _List_fromArray(
												[
													$mdgriffith$elm_ui$Element$behindContent(
													A3($mdgriffith$elm_ui$Element$Input$renderPlaceholder, place, redistributed.ad, textOptions.cY === ''))
												]);
										}
									}()
									])))),
					$mdgriffith$elm_ui$Internal$Model$Unkeyed(
						_List_fromArray(
							[inputElement])));
			}
		}();
		return A3(
			$mdgriffith$elm_ui$Element$Input$applyLabel,
			A2(
				$elm$core$List$cons,
				A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$cursor, $mdgriffith$elm_ui$Internal$Style$classes.kW),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Element$Input$isHiddenLabel(textOptions.bD) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : $mdgriffith$elm_ui$Element$spacing(5),
					A2($elm$core$List$cons, $mdgriffith$elm_ui$Element$Region$announce, redistributed.n))),
			textOptions.bD,
			wrappedInput);
	});
var $mdgriffith$elm_ui$Element$Input$text = $mdgriffith$elm_ui$Element$Input$textHelper(
	{
		as: $elm$core$Maybe$Nothing,
		aG: false,
		pu: $mdgriffith$elm_ui$Element$Input$TextInputNode('text')
	});
var $elm$core$Array$toIndexedList = function (array) {
	var len = array.a;
	var helper = F2(
		function (entry, _v0) {
			var index = _v0.a;
			var list = _v0.b;
			return _Utils_Tuple2(
				index - 1,
				A2(
					$elm$core$List$cons,
					_Utils_Tuple2(index, entry),
					list));
		});
	return A3(
		$elm$core$Array$foldr,
		helper,
		_Utils_Tuple2(len - 1, _List_Nil),
		array).b;
};
var $author$project$UISnippet$Answer$makeAnswersElements = F4(
	function (isShowAnswerValue, msgFn, vaSet, vAnswerMaybe_) {
		var handleASet_MCSA = function (mcRecs) {
			var answerOptions = A2(
				$elm$core$List$indexedMap,
				F2(
					function (idx, el) {
						return A2(
							$mdgriffith$elm_ui$Element$Input$option,
							$author$project$Type$History$MCSA(
								_Utils_Tuple2(idx, el.dF)),
							A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$row,
										_List_Nil,
										$elm$core$List$concat(
											_List_fromArray(
												[
													A2($author$project$UISnippet$Answer$answerDisplay, isShowAnswerValue, el.dF),
													_List_fromArray(
													[
														A2(
														$mdgriffith$elm_ui$Element$paragraph,
														_List_Nil,
														$author$project$Helper$HtmlUtil$makeHtmlElements(el.bY))
													])
												])))
									])));
					}),
				mcRecs);
			return A2(
				$mdgriffith$elm_ui$Element$Input$radio,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(10),
						$mdgriffith$elm_ui$Element$spacing(20)
					]),
				{
					bD: $mdgriffith$elm_ui$Element$Input$labelHidden('Select your answer'),
					cS: msgFn,
					ni: answerOptions,
					ob: vAnswerMaybe_
				});
		};
		var handleASet_MCMA = function (mcRecs) {
			var isCurrentlySelected = F2(
				function (vAnswerMaybe, idx) {
					if ((!vAnswerMaybe.$) && (vAnswerMaybe.a.$ === 1)) {
						var _v12 = vAnswerMaybe.a.a;
						var idxs = _v12.a;
						return A2($elm$core$List$member, idx, idxs);
					} else {
						return false;
					}
				});
			var isAnswersCorrect = function (idxs) {
				var allCorrect = $elm$core$List$sort(
					A2(
						$elm$core$List$map,
						$elm$core$Tuple$first,
						A2(
							$elm$core$List$partition,
							A2(
								$elm$core$Basics$composeR,
								$elm$core$Tuple$second,
								function ($) {
									return $.dF;
								}),
							A2($elm$core$List$indexedMap, $elm$core$Tuple$pair, mcRecs)).a));
				return _Utils_eq(idxs, allCorrect);
			};
			var setCurrentlySelected = F3(
				function (vAnswersMaybe, idx, isChecked) {
					var updatedSelections = function () {
						var _v6 = _Utils_Tuple2(vAnswersMaybe, isChecked);
						_v6$3:
						while (true) {
							if (!_v6.a.$) {
								if (_v6.a.a.$ === 1) {
									if (_v6.b) {
										var _v7 = _v6.a.a.a;
										var ints = _v7.a;
										return $elm$core$List$sort(
											A2($elm$core$List$cons, idx, ints));
									} else {
										var _v8 = _v6.a.a.a;
										var ints = _v8.a;
										var _v9 = A2(
											$elm$core$List$partition,
											function (el) {
												return !_Utils_eq(el, idx);
											},
											ints);
										var intsWithoutIdx = _v9.a;
										return intsWithoutIdx;
									}
								} else {
									break _v6$3;
								}
							} else {
								if (_v6.b) {
									var _v10 = _v6.a;
									return _List_fromArray(
										[idx]);
								} else {
									break _v6$3;
								}
							}
						}
						return _List_Nil;
					}();
					var isCorrect = isAnswersCorrect(updatedSelections);
					return $author$project$Type$History$MCMA(
						_Utils_Tuple2(updatedSelections, isCorrect));
				});
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_Nil,
				A2(
					$elm$core$List$map,
					function (_v5) {
						var idx = _v5.a;
						var el = _v5.b;
						return A2(
							$mdgriffith$elm_ui$Element$row,
							_List_Nil,
							A2(
								$elm$core$List$append,
								A2($author$project$UISnippet$Answer$answerDisplay, isShowAnswerValue, el.dF),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$Input$checkbox,
										_List_Nil,
										{
											gr: A2(isCurrentlySelected, vAnswerMaybe_, idx),
											hl: $mdgriffith$elm_ui$Element$Input$defaultCheckbox,
											bD: A2(
												$mdgriffith$elm_ui$Element$Input$labelRight,
												_List_Nil,
												A2(
													$mdgriffith$elm_ui$Element$row,
													_List_Nil,
													$author$project$Helper$HtmlUtil$makeHtmlElements(el.bY))),
											cS: function (isChecked) {
												return msgFn(
													A3(setCurrentlySelected, vAnswerMaybe_, idx, isChecked));
											}
										})
									])));
					},
					A2($elm$core$List$indexedMap, $elm$core$Tuple$pair, mcRecs)));
		};
		var handleASet_ANSBOX = F2(
			function (aBoxRecs, currentAnswerMaybes) {
				return A2(
					$mdgriffith$elm_ui$Element$column,
					_List_Nil,
					A3(
						$elm$core$List$map2,
						F2(
							function (el, _v3) {
								var idx = _v3.a;
								var currentAnswerMaybe = _v3.b;
								return A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$paddingXY, 10, 15)
										]),
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$row,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$spacing(7)
												]),
											_List_fromArray(
												[
													$author$project$Helper$HtmlUtil$convertHtmlStringToElement(el.nx),
													A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$width(
															$mdgriffith$elm_ui$Element$px(el.ec * 19))
														]),
													A2(
														$mdgriffith$elm_ui$Element$Input$text,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$Border$width(2),
																$mdgriffith$elm_ui$Element$Border$color($author$project$Theme$Style$t.kI),
																$mdgriffith$elm_ui$Element$Input$focusedOnLoad
															]),
														{
															bD: $mdgriffith$elm_ui$Element$Input$labelHidden('Answer Box'),
															cS: function (str) {
																var isAlreadyContainsDecimal = function (lst) {
																	return $elm$core$String$length(lst) > 1;
																}(
																	A2(
																		$elm$core$String$filter,
																		function (ch) {
																			return ch === '.';
																		},
																		str));
																var newStr = isAlreadyContainsDecimal ? A2($elm$core$String$dropRight, 1, str) : str;
																var newAnswerMaybes = A3(
																	$elm$core$Array$set,
																	idx,
																	$elm$core$String$isEmpty(
																		$elm$core$String$trim(str)) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
																		A2(
																			$elm$core$String$filter,
																			function (ch) {
																				return $elm$core$Char$isDigit(ch) || (ch === '.');
																			},
																			$elm$core$String$trim(newStr))),
																	currentAnswerMaybes);
																var correctAnswers = $elm$core$Array$fromList(
																	A2(
																		$elm$core$List$map,
																		A2(
																			$elm$core$Basics$composeL,
																			$elm$core$String$toFloat,
																			function ($) {
																				return $.kO;
																			}),
																		aBoxRecs));
																var isAnsBoxAnswersCorrect = _Utils_eq(
																	correctAnswers,
																	A2(
																		$elm$core$Array$map,
																		$elm$core$Maybe$andThen($elm$core$String$toFloat),
																		newAnswerMaybes));
																return msgFn(
																	$author$project$Type$History$ANSBOX(
																		_Utils_Tuple2(newAnswerMaybes, isAnsBoxAnswersCorrect)));
															},
															cV: $elm$core$Maybe$Nothing,
															cY: A2($elm$core$Maybe$withDefault, '', currentAnswerMaybe)
														})),
													$author$project$Helper$HtmlUtil$convertHtmlStringToElement(el.oI),
													function () {
													if (isShowAnswerValue) {
														var answerDetailElement = A2(
															$mdgriffith$elm_ui$Element$row,
															_List_Nil,
															_List_fromArray(
																[
																	A2(
																	$mdgriffith$elm_ui$Element$el,
																	_List_fromArray(
																		[
																			$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.gy)
																		]),
																	$author$project$UISnippet$Answer$iconCross),
																	$mdgriffith$elm_ui$Element$text('(Correct Answer: ' + (el.kO + ')'))
																]));
														if (currentAnswerMaybe.$ === 1) {
															return answerDetailElement;
														} else {
															var answer = currentAnswerMaybe.a;
															return _Utils_eq(
																$elm$core$String$toFloat(answer),
																$elm$core$String$toFloat(el.kO)) ? A2(
																$mdgriffith$elm_ui$Element$el,
																_List_fromArray(
																	[
																		$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.gz)
																	]),
																$author$project$UISnippet$Answer$iconTick) : answerDetailElement;
														}
													} else {
														return $mdgriffith$elm_ui$Element$none;
													}
												}()
												]))
										]));
							}),
						aBoxRecs,
						$elm$core$Array$toIndexedList(currentAnswerMaybes)));
			});
		var extractAnsBoxAnswers = function (vAnswer) {
			if (vAnswer.$ === 2) {
				var _v2 = vAnswer.a;
				var strs = _v2.a;
				return strs;
			} else {
				return $elm$core$Array$empty;
			}
		};
		switch (vaSet.$) {
			case 0:
				var mcRecs = vaSet.a;
				return handleASet_MCSA(mcRecs);
			case 1:
				var mcRecs = vaSet.a;
				return handleASet_MCMA(mcRecs);
			default:
				var aBoxRecs = vaSet.a;
				return A2(
					handleASet_ANSBOX,
					aBoxRecs,
					extractAnsBoxAnswers(
						A2(
							$elm$core$Maybe$withDefault,
							$author$project$Type$History$ANSBOX(
								_Utils_Tuple2(
									A2(
										$elm$core$Array$repeat,
										$elm$core$List$length(aBoxRecs),
										$elm$core$Maybe$Nothing),
									false)),
							vAnswerMaybe_)));
		}
	});
var $author$project$Type$Variant$Metrics$maxDiff = function (metrics) {
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$List$maximum(
			$elm$core$Dict$keys(metrics)));
};
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Type$Variant$Metrics$maxFreq = function (metrics) {
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$List$maximum(
			$elm$core$Dict$values(metrics)));
};
var $author$project$Type$Variant$Metrics$minDiff = function (metrics) {
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$List$minimum(
			$elm$core$Dict$keys(metrics)));
};
var $author$project$Type$Variant$Metrics$minFreq = function (metrics) {
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$List$minimum(
			$elm$core$Dict$values(metrics)));
};
var $author$project$Type$Variant$Metrics$makeMetricsStats = function (metrics) {
	return {
		hC: $author$project$Type$Variant$Metrics$maxDiff(metrics),
		hD: $author$project$Type$Variant$Metrics$maxFreq(metrics),
		hJ: $author$project$Type$Variant$Metrics$minDiff(metrics),
		hK: $author$project$Type$Variant$Metrics$minFreq(metrics)
	};
};
var $author$project$Type$Variant$Metrics$append = F2(
	function (metricsA, metricsB) {
		return A6(
			$elm$core$Dict$merge,
			F2(
				function (diff, freq) {
					return A2($elm$core$Dict$insert, diff, freq);
				}),
			F3(
				function (diff, freq1, freq2) {
					return A2($elm$core$Dict$insert, diff, freq1 + freq2);
				}),
			F2(
				function (diff, freq) {
					return A2($elm$core$Dict$insert, diff, freq);
				}),
			metricsA,
			metricsB,
			$elm$core$Dict$empty);
	});
var $author$project$Type$Variant$Metrics$empty = $elm$core$Dict$empty;
var $elm$core$Dict$singleton = F2(
	function (key, value) {
		return A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
	});
var $author$project$Type$Variant$VariantMeta$toVariantMetrics = function (variantMeta) {
	return A2($elm$core$Dict$singleton, variantMeta.dp, 1);
};
var $author$project$Type$Variant$VariantMeta$makeVariantMetrics = function (variantMetas) {
	return A3(
		$elm$core$List$foldl,
		$author$project$Type$Variant$Metrics$append,
		$author$project$Type$Variant$Metrics$empty,
		A2($elm$core$List$map, $author$project$Type$Variant$VariantMeta$toVariantMetrics, variantMetas));
};
var $mdgriffith$elm_ui$Element$Border$dashed = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$borderStyle, $mdgriffith$elm_ui$Internal$Style$classes.kf);
var $mdgriffith$elm_ui$Element$link = F2(
	function (attrs, _v0) {
		var url = _v0.py;
		var label = _v0.bD;
		return A4(
			$mdgriffith$elm_ui$Internal$Model$element,
			$mdgriffith$elm_ui$Internal$Model$asEl,
			$mdgriffith$elm_ui$Internal$Model$NodeName('a'),
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Internal$Model$Attr(
					$elm$html$Html$Attributes$href(url)),
				A2(
					$elm$core$List$cons,
					$mdgriffith$elm_ui$Internal$Model$Attr(
						$elm$html$Html$Attributes$rel('noopener noreferrer')),
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$shrink),
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$shrink),
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Internal$Model$htmlClass($mdgriffith$elm_ui$Internal$Style$classes.dm + (' ' + ($mdgriffith$elm_ui$Internal$Style$classes.a1 + (' ' + $mdgriffith$elm_ui$Internal$Style$classes.hA)))),
								attrs))))),
			$mdgriffith$elm_ui$Internal$Model$Unkeyed(
				_List_fromArray(
					[label])));
	});
var $mdgriffith$elm_ui$Element$Border$solid = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$borderStyle, $mdgriffith$elm_ui$Internal$Style$classes.kh);
var $author$project$UISnippet$MetricsVisualisation$makeVisualisation = F2(
	function (_v0, catOnlyMetrics) {
		var minDiff = _v0.hJ;
		var maxDiff = _v0.hC;
		var getFreq = function (diff) {
			return A2(
				$elm$core$Maybe$withDefault,
				_Utils_Tuple2(diff, 0),
				A2(
					$elm$core$Maybe$map,
					function (freq_) {
						return _Utils_Tuple2(diff, freq_);
					},
					A2($elm$core$Dict$get, diff, catOnlyMetrics)));
		};
		var _v1 = $author$project$Type$Variant$Metrics$makeMetricsStats(catOnlyMetrics);
		var minFreq = _v1.hK;
		var maxFreq = _v1.hD;
		var makeBar = function (_v2) {
			var diff = _v2.a;
			var freq = _v2.b;
			var barColor = function () {
				if (freq > 0) {
					var minC = 95;
					var maxC = 255;
					var c = maxC - $elm$core$Basics$round((freq / maxFreq) * (maxC - minC));
					return A3($mdgriffith$elm_ui$Element$rgb255, c, c, 0);
				} else {
					return A3($mdgriffith$elm_ui$Element$rgb255, 255, 255, 255);
				}
			}();
			return A2(
				$mdgriffith$elm_ui$Element$link,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height(
						$mdgriffith$elm_ui$Element$px(28)),
						$mdgriffith$elm_ui$Element$Font$color(barColor),
						$mdgriffith$elm_ui$Element$Border$width(1),
						$mdgriffith$elm_ui$Element$Border$solid
					]),
				{
					bD: $mdgriffith$elm_ui$Element$text(''),
					py: ' difficulty: ' + ($elm$core$String$fromInt(diff) + (', frequency: ' + $elm$core$String$fromInt(freq)))
				});
		};
		var bars = A2(
			$elm$core$List$map,
			makeBar,
			A2(
				$elm$core$List$map,
				getFreq,
				A2($elm$core$List$range, minDiff, maxDiff)));
		var freqRange = maxFreq - minFreq;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 5, 0)
						]),
					$mdgriffith$elm_ui$Element$text(
						$elm$core$String$fromInt(minDiff))),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Border$widthEach(
							{er: 1, e9: 0, fz: 0, fY: 1}),
							$mdgriffith$elm_ui$Element$Border$dashed
						]),
					bars),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 5, 0)
						]),
					$mdgriffith$elm_ui$Element$text(
						$elm$core$String$fromInt(maxDiff)))
				]));
	});
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $author$project$Page$PagesCommon$thinButton = F2(
	function (isActive, btnConfig) {
		return A6(
			$author$project$Page$PagesCommon$configurableButton,
			isActive,
			$author$project$Theme$Style$t.f6,
			$author$project$Theme$Style$t.jG,
			_List_Nil,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Element$paddingXY, 10, 7)
				]),
			btnConfig);
	});
var $author$project$Page$AdminPage$workArea = function (mdl) {
	return $Punie$elm_reader$Reader$asks(
		function (rState) {
			var syllabusSelectorSection = A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
						A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
						$mdgriffith$elm_ui$Element$Border$rounded(10),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				function () {
					var syllabusTitles = A2(
						$elm$core$List$map,
						function ($) {
							return $.kA;
						},
						A2(
							$elm$core$List$sortBy,
							function ($) {
								return $.k8;
							},
							rState.mX.oJ));
					var cohort = rState.mX.cF;
					var currentSyllabusTitle = $author$project$Type$Cohort$toCurrentSyllabusTitle(cohort);
					var makeRegButton = function (syllabusTitle) {
						return A2(
							$author$project$Page$PagesCommon$thinButton,
							!_Utils_eq(syllabusTitle, cohort),
							{
								bD: A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX]),
									$mdgriffith$elm_ui$Element$text(
										$author$project$Type$Cohort$toCurrentSyllabusTitle(syllabusTitle))),
								ai: $elm$core$Maybe$Just(
									$author$project$Page$AdminPage$SetSyllabus(syllabusTitle))
							});
					};
					var syllabusButtons = A2($elm$core$List$map, makeRegButton, syllabusTitles);
					return _List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$italic,
											A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5)
										]),
									$mdgriffith$elm_ui$Element$text('Syllabus: ' + currentSyllabusTitle)),
									A2(
									$mdgriffith$elm_ui$Element$wrappedRow,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$spacing(10)
										]),
									syllabusButtons)
								]))
						]);
				}());
			var renderVariant = function (variantMeta) {
				var variantContentRemote = A2(
					$elm$core$Maybe$withDefault,
					$krisajenkins$remotedata$RemoteData$Failure(
						{
							b4: 'Could not find the variantId in the Dict model.variantContents.  Confirm that the parent syllabus for this category is selected above, and try again.',
							fZ: $elm$core$Maybe$Just(variantMeta.pK)
						}),
					A2(
						$elm$core$Dict$get,
						$author$project$Type$Variant$toShortStringVariantId(variantMeta.pK),
						mdl.aM));
				var variantContent = function () {
					switch (variantContentRemote.$) {
						case 1:
							return A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Element$paddingXY, 5, 5)
									]),
								$mdgriffith$elm_ui$Element$text('Loading...'));
						case 0:
							return $mdgriffith$elm_ui$Element$text('The contents of this post have not yet been requested.');
						case 2:
							var err = variantContentRemote.a;
							return A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text(
										$author$project$Type$Variant$variantContentErrorToString(err))
									]));
						default:
							var variantContent_ = variantContentRemote.a;
							var wsContent = $author$project$Helper$HtmlUtil$makeHtmlElements(variantContent_.pQ);
							var qContent = $author$project$Helper$HtmlUtil$makeHtmlElements(variantContent_.nE);
							var answers = A4(
								$author$project$UISnippet$Answer$makeAnswersElements,
								true,
								$elm$core$Basics$always($author$project$Page$AdminPage$DoNothing),
								variantContent_.jB,
								$elm$core$Maybe$Nothing);
							return A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Element$paddingXY, 0, 20),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$spacing(10),
										$mdgriffith$elm_ui$Element$htmlAttribute(
										$elm$html$Html$Attributes$class('content'))
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$column,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
											]),
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Element$paragraph, _List_Nil, qContent)
											])),
										answers,
										A2(
										$mdgriffith$elm_ui$Element$column,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$htmlAttribute(
												$elm$html$Html$Attributes$class('content'))
											]),
										$elm$core$List$concat(
											_List_fromArray(
												[
													_List_fromArray(
													[
														A2(
														$mdgriffith$elm_ui$Element$el,
														_List_fromArray(
															[$mdgriffith$elm_ui$Element$Font$italic, $mdgriffith$elm_ui$Element$Font$underline]),
														$mdgriffith$elm_ui$Element$text('Worked Solution')),
														$mdgriffith$elm_ui$Element$text(' ')
													]),
													wsContent
												])))
									]));
					}
				}();
				var tagsAsString = $elm$core$List$isEmpty(variantMeta.i7) ? '' : ('t:' + $elm$core$String$concat(
					A2($elm$core$List$intersperse, ',', variantMeta.i7)));
				return A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$bold,
									$mdgriffith$elm_ui$Element$Font$italic,
									$mdgriffith$elm_ui$Element$Font$size(18),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$spacing(10),
									A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10),
									$mdgriffith$elm_ui$Element$Border$widthEach(
									{er: 1, e9: 0, fz: 0, fY: 2})
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$newTabLink,
									_List_Nil,
									{
										bD: $mdgriffith$elm_ui$Element$text(
											$author$project$Type$Variant$toShortStringVariantId(variantMeta.pK)),
										py: 'https://cms.smarterschool.com.au/admin/#/collections/item/entries/' + variantMeta.pK.oq
									}),
									$mdgriffith$elm_ui$Element$text(
									'd.' + $elm$core$String$fromInt(variantMeta.dp)),
									$mdgriffith$elm_ui$Element$text(tagsAsString)
								])),
							variantContent
						]));
			};
			var makeGapsVisualization = function (gaps) {
				var gapsString = A3(
					$elm$core$List$foldr,
					$elm$core$Basics$append,
					'',
					A2(
						$elm$core$List$intersperse,
						', ',
						A2(
							$elm$core$List$map,
							function (_v8) {
								var start = _v8.a;
								var end = _v8.b;
								return $elm$core$String$fromInt(start) + ('-' + $elm$core$String$fromInt(end));
							},
							gaps)));
				return (!$elm$core$List$isEmpty(gaps)) ? A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 5, 8),
							$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.gC)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$Font$bold, $mdgriffith$elm_ui$Element$Font$italic]),
							$mdgriffith$elm_ui$Element$text(
								'Gaps (>' + ($elm$core$String$fromInt(rState.fv) + '): '))),
							$mdgriffith$elm_ui$Element$text(gapsString)
						])) : $mdgriffith$elm_ui$Element$none;
			};
			var makeSpreadTopLevelElement = F4(
				function (attrMsgFn, title, courseMetricsStats, _v7) {
					var variantMetas = _v7.f_;
					var gaps = _v7.lD;
					var gapsVisualisation = makeGapsVisualization(gaps);
					var catMetricsVisualisation = A2(
						$author$project$UISnippet$MetricsVisualisation$makeVisualisation,
						courseMetricsStats,
						$author$project$Type$Variant$VariantMeta$makeVariantMetrics(variantMetas));
					var categoryDescriptionRow = A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								attrMsgFn(
								A2(
									$mdgriffith$elm_ui$Element$row,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5),
											$mdgriffith$elm_ui$Element$Font$size(18)
										]),
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$Font$bold]),
											$mdgriffith$elm_ui$Element$text(title)),
											$mdgriffith$elm_ui$Element$text(' ('),
											$mdgriffith$elm_ui$Element$text(
											$elm$core$String$fromInt(
												$elm$core$List$length(variantMetas))),
											$mdgriffith$elm_ui$Element$text(')')
										]))),
								catMetricsVisualisation,
								gapsVisualisation
							]));
					return categoryDescriptionRow;
				});
			var invalidShortStringVariantIds = A2($elm$core$List$map, $author$project$Type$Variant$toShortStringVariantId, mdl.b8);
			var makeClickableVariantId = function (str) {
				var sep = '  ';
				var fontColor = A2($elm$core$List$member, str, invalidShortStringVariantIds) ? _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.gC)
					]) : _List_Nil;
				var elStyle = _Utils_eq(str, mdl.fE) ? _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color(
						$author$project$Theme$Style$lighter($author$project$Theme$Style$info)),
						A2($mdgriffith$elm_ui$Element$paddingXY, 4, 4),
						$mdgriffith$elm_ui$Element$Border$rounded(8)
					]) : _List_Nil;
				return A2(
					$mdgriffith$elm_ui$Element$row,
					_List_Nil,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text(sep),
							A2(
							$mdgriffith$elm_ui$Element$el,
							$elm$core$List$concat(
								_List_fromArray(
									[
										_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Events$onClick(
											$author$project$Page$AdminPage$ShowVariantId(str))
										]),
										elStyle,
										fontColor
									])),
							$mdgriffith$elm_ui$Element$text(str)),
							$mdgriffith$elm_ui$Element$text(sep)
						]));
			};
			var variantsContent = A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
						A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
						$mdgriffith$elm_ui$Element$Border$rounded(10),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				function () {
					var _v6 = mdl.aN;
					switch (_v6.$) {
						case 0:
							return _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Please click the \'Get VariantIds\' button to retrieve the list of variantIds for the specified Syllabus.')
								]);
						case 1:
							return _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Loading the current list of variantIds...')
								]);
						case 2:
							return _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('There was a problem retrieving the variantIds')
								]);
						default:
							var variantIds = _v6.a;
							var variantIdEls = A2(
								$elm$core$List$map,
								makeClickableVariantId,
								$elm$core$List$sort(
									A2($elm$core$List$map, $author$project$Type$Variant$toShortStringVariantId, variantIds)));
							return _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text(
									'Click on any of the ' + ($elm$core$String$fromInt(
										$elm$core$List$length(variantIdEls)) + ' variants to view')),
									$mdgriffith$elm_ui$Element$text(' '),
									A2($mdgriffith$elm_ui$Element$wrappedRow, _List_Nil, variantIdEls)
								]);
					}
				}());
			var validationMsgArea = function () {
				var _v5 = _Utils_Tuple2(mdl.aN, mdl.bT);
				if ((_v5.a.$ === 3) && (!_v5.b.$)) {
					var variantIds = _v5.a.a;
					var validatedCount = _v5.b.a;
					var variantIdEls = A2(
						$elm$core$List$map,
						makeClickableVariantId,
						$elm$core$List$sort(invalidShortStringVariantIds));
					var total = $elm$core$List$length(variantIds);
					var nbrInvalid = $elm$core$List$length(mdl.b8);
					return A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
								A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
								$mdgriffith$elm_ui$Element$Border$rounded(10),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_Utils_ap(
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$Font$underline]),
									$mdgriffith$elm_ui$Element$text('Validate Variant Structures')),
									$mdgriffith$elm_ui$Element$text(' '),
									$mdgriffith$elm_ui$Element$text(' - Checks html structure only.'),
									$mdgriffith$elm_ui$Element$text(' - Any errors are often a missing matching </div> or invalid tag used such as </br> instead of <br>'),
									$mdgriffith$elm_ui$Element$text(' '),
									$mdgriffith$elm_ui$Element$text(
									(_Utils_eq(validatedCount, total) ? 'Completed: Validated ' : 'In Progress:: Validating ') + ($elm$core$String$fromInt(validatedCount) + (' of ' + $elm$core$String$fromInt(total)))),
									$mdgriffith$elm_ui$Element$text(
									'# Successes: ' + $elm$core$String$fromInt(validatedCount - nbrInvalid)),
									$mdgriffith$elm_ui$Element$text(
									'# Failures: ' + $elm$core$String$fromInt(nbrInvalid))
								]),
							(nbrInvalid > 0) ? _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Failure variant ids:'),
									A2($mdgriffith$elm_ui$Element$paragraph, _List_Nil, variantIdEls)
								]) : _List_Nil));
				} else {
					return $mdgriffith$elm_ui$Element$none;
				}
			}();
			var extractVariantMetas = function (variantSpread) {
				if (!variantSpread.$) {
					var structs = variantSpread.a;
					return $author$project$Type$NodeCfg$toUniqueVariantMetas(structs);
				} else {
					var structs = variantSpread.a;
					return $author$project$Type$NodeCfg$toUniqueVariantMetas(structs);
				}
			};
			var renderVariantMetasDictElement = function (_v3) {
				var syllabusTitle = _v3.a;
				var variantSpread = _v3.b;
				var uniqueCourseVariantMetas = extractVariantMetas(variantSpread);
				var courseMetrics = $author$project$Type$Variant$VariantMeta$makeVariantMetrics(uniqueCourseVariantMetas);
				var courseMetricsStats = $author$project$Type$Variant$Metrics$makeMetricsStats(courseMetrics);
				var courseDescriptionColumn = A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5),
									$mdgriffith$elm_ui$Element$Font$size(18)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$Font$bold]),
									$mdgriffith$elm_ui$Element$text(syllabusTitle)),
									$mdgriffith$elm_ui$Element$text(' ('),
									$mdgriffith$elm_ui$Element$text(
									$elm$core$String$fromInt(
										$elm$core$List$length(uniqueCourseVariantMetas))),
									$mdgriffith$elm_ui$Element$text(' unique)')
								])),
							A2($author$project$UISnippet$MetricsVisualisation$makeVisualisation, courseMetricsStats, courseMetrics)
						]));
				return A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Border$widthEach(
							{er: 0, e9: 0, fz: 0, fY: 2}),
							$mdgriffith$elm_ui$Element$Border$solid,
							A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5)
						]),
					A2(
						$elm$core$List$cons,
						courseDescriptionColumn,
						function () {
							if (!variantSpread.$) {
								var categorySpreadStruct = variantSpread.a;
								return A2(
									$elm$core$List$map,
									function (el_) {
										var attrMsgFn = function (elmt) {
											return A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$Events$onClick(
														A2($author$project$Page$AdminPage$ToggleLeaf, rState.mX.cF, el_.kq))
													]),
												elmt);
										};
										return A2(
											$mdgriffith$elm_ui$Element$column,
											_List_Nil,
											_List_fromArray(
												[
													A4(makeSpreadTopLevelElement, attrMsgFn, el_.kq.ja, courseMetricsStats, el_),
													A2($elm$core$List$member, el_.kq.lW, mdl.aS) ? A2(
													$mdgriffith$elm_ui$Element$column,
													_List_fromArray(
														[
															A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5)
														]),
													A2(
														$elm$core$List$map,
														renderVariant,
														A2(
															$elm$core$List$sortBy,
															function ($) {
																return $.dp;
															},
															el_.f_))) : $mdgriffith$elm_ui$Element$none
												]));
									},
									categorySpreadStruct);
							} else {
								var activitySpreadStruct = variantSpread.a;
								var makePrefixedTitle = function (el_) {
									return $author$project$Type$Activity$ctxToString(el_.ee.cx.kL) + (' - ' + el_.ee.ja);
								};
								return A2(
									$elm$core$List$map,
									function (el_) {
										return A2(
											$mdgriffith$elm_ui$Element$column,
											_List_Nil,
											_List_fromArray(
												[
													A4(
													makeSpreadTopLevelElement,
													$elm$core$Basics$identity,
													makePrefixedTitle(el_),
													courseMetricsStats,
													el_)
												]));
									},
									A2($elm$core$List$sortBy, makePrefixedTitle, activitySpreadStruct));
							}
						}()));
			};
			var makeLeavesContent = function (leavesDict) {
				return A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
							A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
							$mdgriffith$elm_ui$Element$Border$rounded(10),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					$elm$core$Dict$isEmpty(leavesDict) ? _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text('Please click the \'Get Leaves/Activities\' button (AND WAIT A FEW SECONDS) to retrieve the list of leaves for the specified Syllabus.')
						]) : _List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_Nil,
							A2(
								$elm$core$List$cons,
								A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$paddingEach(
											{er: 10, e9: 0, fz: 0, fY: 0})
										]),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('* Minor technical note: you can only expand (previously un-expanded) items when that item\'s parent is selected above.')
										])),
								A2(
									$elm$core$List$map,
									renderVariantMetasDictElement,
									$elm$core$Dict$toList(leavesDict))))
						]));
			};
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 0, 60),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$fillPortion(1))
									]),
								_List_Nil),
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$fillPortion(6)),
										$mdgriffith$elm_ui$Element$spacing(30)
									]),
								$elm$core$List$concat(
									_List_fromArray(
										[
											_List_fromArray(
											[
												A2(
												$mdgriffith$elm_ui$Element$row,
												_List_Nil,
												_List_fromArray(
													[
														A2(
														$mdgriffith$elm_ui$Element$el,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.nn),
																$mdgriffith$elm_ui$Element$Font$size(30),
																$mdgriffith$elm_ui$Element$Font$bold
															]),
														$mdgriffith$elm_ui$Element$text('Question Administration'))
													]))
											]),
											(mdl.b4 === '') ? _List_Nil : _List_fromArray(
											[
												A2(
												$mdgriffith$elm_ui$Element$column,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.eC),
														A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
														$mdgriffith$elm_ui$Element$Border$rounded(10),
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
													]),
												_List_fromArray(
													[
														A2(
														$mdgriffith$elm_ui$Element$el,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.nn)
															]),
														$mdgriffith$elm_ui$Element$text(mdl.b4))
													]))
											]),
											_List_fromArray(
											[
												A2(
												$mdgriffith$elm_ui$Element$column,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$spacing(20)
													]),
												_List_fromArray(
													[
														syllabusSelectorSection,
														A2(
														$Punie$elm_reader$Reader$run,
														A2(
															$author$project$Page$AdminPage$actionsSection,
															mdl,
															$author$project$Type$ReaderConfig$isNapAdmin(rState)),
														rState),
														validationMsgArea,
														variantsContent,
														makeLeavesContent(
														A2(
															$elm$core$Dict$map,
															function (_v0) {
																return function (v) {
																	return $author$project$Type$NodeCfg$CategorySpread(v);
																};
															},
															mdl.bj)),
														makeLeavesContent(
														A2(
															$elm$core$Dict$map,
															function (_v1) {
																return function (v) {
																	return $author$project$Type$NodeCfg$ActivitySpread(v);
																};
															},
															mdl.bU))
													]))
											])
										]))),
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$fillPortion(1))
									]),
								_List_Nil)
							]))
					]));
		});
};
var $author$project$Page$AdminPage$view = F2(
	function (model, topBarFn) {
		var addToElements = F2(
			function (el, els) {
				return A2(
					$Punie$elm_reader$Reader$map,
					function (el2) {
						return A2($elm$core$List$cons, el2, els);
					},
					el);
			});
		return A3(
			$Punie$elm_reader$Reader$map2,
			F2(
				function (color, els) {
					return A2(
						$mdgriffith$elm_ui$Element$layout,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$size(16),
								$mdgriffith$elm_ui$Element$Background$color(color)
							]),
						A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
								]),
							els));
				}),
			$author$project$Page$PagesCommon$makeBackgroundStyle,
			A2(
				$Punie$elm_reader$Reader$andThen,
				addToElements(
					A2(topBarFn, $author$project$Page$AdminPage$SignOut, $author$project$Page$AdminPage$LinkClicked)),
				A2(
					$Punie$elm_reader$Reader$andThen,
					addToElements(
						$author$project$Page$AdminPage$workArea(model)),
					$Punie$elm_reader$Reader$reader(_List_Nil))));
	});
var $author$project$Page$CreateUsers$LinkClicked = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$CreateUsers$SignOut = {$: 15};
var $author$project$Routing$Lower = 1;
var $author$project$Page$CreateUsers$SetAssignedTeacher = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$CreateUsers$SetCohort = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$CreateUsers$Cancel = {$: 10};
var $author$project$Page$CreateUsers$VerifyInput = function (a) {
	return {$: 1, a: a};
};
var $author$project$Type$ReaderConfig$isUserRestricted = function (rState) {
	return A2(
		$elm$core$Maybe$withDefault,
		true,
		A2(
			$elm$core$Maybe$map,
			function (userDetails_) {
				return A2($elm$core$List$member, 'SMDemoUser', userDetails_.lL);
			},
			rState.pC));
};
var $author$project$Page$PagesCommon$restrictUsage = F3(
	function (btnFn, isActive, btnConfig) {
		var makeBtn = function (isRestricted) {
			return isRestricted ? A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(10)
					]),
				_List_fromArray(
					[
						A2(btnFn, false, btnConfig),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$paddingEach(
								{er: 5, e9: 0, fz: 0, fY: 15})
							]),
						$mdgriffith$elm_ui$Element$text('* Restricted for demo user'))
					])) : A2(btnFn, isActive, btnConfig);
		};
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				return makeBtn(
					$author$project$Type$ReaderConfig$isUserRestricted(rState));
			});
	});
var $author$project$Routing$userRouteTypeCapPlural = A2(
	$elm$core$Basics$composeR,
	$author$project$Routing$userRouteTypeToString,
	function (str) {
		return str + 's';
	});
var $author$project$Routing$userRouteTypeLowerPlural = A2($elm$core$Basics$composeR, $author$project$Routing$userRouteTypeCapPlural, $elm$core$String$toLower);
var $author$project$Page$CreateUsers$actionButton = F2(
	function (mdl, userType) {
		var _v0 = function () {
			var _v1 = mdl.o;
			switch (_v1) {
				case 0:
					return _Utils_Tuple2(
						'Verify entries',
						$elm$core$Maybe$Just(
							$author$project$Page$CreateUsers$VerifyInput(userType)));
				case 1:
					return _Utils_Tuple2(
						'Re-verify entries',
						$elm$core$Maybe$Just(
							$author$project$Page$CreateUsers$VerifyInput(userType)));
				case 2:
					return _Utils_Tuple2(
						'Create ' + $author$project$Routing$userRouteTypeLowerPlural(userType),
						$elm$core$Maybe$Just(
							$author$project$Page$CreateUsers$CreateUsers(userType)));
				case 3:
					return _Utils_Tuple2('N/A', $elm$core$Maybe$Nothing);
				default:
					return _Utils_Tuple2(
						'Close',
						$elm$core$Maybe$Just($author$project$Page$CreateUsers$Cancel));
			}
		}();
		var btnText = _v0.a;
		var btnAction = _v0.b;
		var _v2 = ((!$elm$core$String$isEmpty(mdl.au)) && ((!$elm$core$String$isEmpty(mdl.ax)) && (!$elm$core$String$isEmpty(mdl.at)))) ? _Utils_Tuple2(true, btnAction) : _Utils_Tuple2(false, $elm$core$Maybe$Nothing);
		var isActive = _v2.a;
		var action = _v2.b;
		return A3(
			$author$project$Page$PagesCommon$restrictUsage,
			$author$project$Page$PagesCommon$regButton,
			isActive,
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]),
					$mdgriffith$elm_ui$Element$text(btnText)),
				ai: action
			});
	});
var $author$project$Page$CreateUsers$cancelButton = function (mdl) {
	return A2(
		$author$project$Page$PagesCommon$regButton,
		true,
		{
			bD: A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				$mdgriffith$elm_ui$Element$text('Cancel')),
			ai: $elm$core$Maybe$Just($author$project$Page$CreateUsers$Cancel)
		});
};
var $author$project$Page$CreateUsers$SetEmails = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$SignInPagesCommon$decorateAttrsError = $elm$core$List$append(
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$Border$color($author$project$Theme$Style$t.kI)
		]));
var $author$project$Page$SignInPagesCommon$getDecorateAttrsFn = function (strMaybe) {
	return (!_Utils_eq(strMaybe, $elm$core$Maybe$Nothing)) ? $author$project$Page$SignInPagesCommon$decorateAttrsError : $elm$core$Basics$identity;
};
var $mdgriffith$elm_ui$Element$Input$Above = 2;
var $mdgriffith$elm_ui$Element$Input$labelAbove = $mdgriffith$elm_ui$Element$Input$Label(2);
var $mdgriffith$elm_ui$Element$Input$multiline = F2(
	function (attrs, multi) {
		return A3(
			$mdgriffith$elm_ui$Element$Input$textHelper,
			{as: $elm$core$Maybe$Nothing, aG: multi.fN, pu: $mdgriffith$elm_ui$Element$Input$TextArea},
			attrs,
			{bD: multi.bD, cS: multi.cS, cV: multi.cV, cY: multi.cY});
	});
var $author$project$Page$CreateUsers$emailsField = function (mdl) {
	var decorateAttrs = $author$project$Page$SignInPagesCommon$getDecorateAttrsFn(mdl.dt);
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10),
				$mdgriffith$elm_ui$Element$alignTop
			]),
		A2(
			$mdgriffith$elm_ui$Element$Input$multiline,
			decorateAttrs(_List_Nil),
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_Nil,
					A2(
						$mdgriffith$elm_ui$Element$el,
						decorateAttrs(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$size(20)
								])),
						$mdgriffith$elm_ui$Element$text('Email(s)'))),
				cS: $author$project$Page$CreateUsers$SetEmails,
				cV: mdl.du,
				fN: false,
				cY: mdl.at
			}));
};
var $author$project$Page$CreateUsers$SetFNames = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$CreateUsers$fNamesField = function (mdl) {
	var decorateAttrs = $author$project$Page$SignInPagesCommon$getDecorateAttrsFn(mdl.dy);
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10),
				$mdgriffith$elm_ui$Element$alignTop
			]),
		A2(
			$mdgriffith$elm_ui$Element$Input$multiline,
			decorateAttrs(_List_Nil),
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_Nil,
					A2(
						$mdgriffith$elm_ui$Element$el,
						decorateAttrs(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$size(20)
								])),
						$mdgriffith$elm_ui$Element$text('First Name(s)'))),
				cS: $author$project$Page$CreateUsers$SetFNames,
				cV: mdl.dz,
				fN: false,
				cY: mdl.au
			}));
};
var $author$project$Page$SignInPagesCommon$fieldErrorMsgElement = function (strMaybe) {
	if (!strMaybe.$) {
		var str = strMaybe.a;
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$paddingEach(
					{er: 10, e9: 0, fz: 0, fY: 0}),
					$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.gC)
				]),
			$mdgriffith$elm_ui$Element$text(str));
	} else {
		return $mdgriffith$elm_ui$Element$none;
	}
};
var $author$project$Page$SignInPagesCommon$fieldInfoMsgElementWithColor = F2(
	function (color, strMaybe) {
		if (!strMaybe.$) {
			var str = strMaybe.a;
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$paddingEach(
						{er: 0, e9: 0, fz: 0, fY: 20}),
						$mdgriffith$elm_ui$Element$Font$color(color)
					]),
				$mdgriffith$elm_ui$Element$text(str));
		} else {
			return $mdgriffith$elm_ui$Element$none;
		}
	});
var $author$project$Page$CreateUsers$isCreationStateSuccess = function (creationStatus) {
	var _v0 = creationStatus.bp;
	if ((_v0.$ === 2) && (!_v0.a.$)) {
		var _v1 = _v0.a;
		return true;
	} else {
		return false;
	}
};
var $author$project$Page$CreateUsers$SetLNames = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$CreateUsers$lNamesField = function (mdl) {
	var decorateAttrs = $author$project$Page$SignInPagesCommon$getDecorateAttrsFn(mdl.dH);
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10),
				$mdgriffith$elm_ui$Element$alignTop
			]),
		A2(
			$mdgriffith$elm_ui$Element$Input$multiline,
			decorateAttrs(_List_Nil),
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_Nil,
					A2(
						$mdgriffith$elm_ui$Element$el,
						decorateAttrs(
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$size(20)
								])),
						$mdgriffith$elm_ui$Element$text('Last Name(s)'))),
				cS: $author$project$Page$CreateUsers$SetLNames,
				cV: mdl.dI,
				fN: false,
				cY: mdl.ax
			}));
};
var $mdgriffith$elm_ui$Element$Input$Row = 0;
var $mdgriffith$elm_ui$Element$Input$radioRow = $mdgriffith$elm_ui$Element$Input$radioHelper(0);
var $author$project$Page$PagesCommon$makeCohortRadios = F4(
	function (attribs, headingMaybe, cohortMaybe, setCohortFn) {
		var labelItem = A2(
			$elm$core$Maybe$withDefault,
			$mdgriffith$elm_ui$Element$Input$labelHidden('Year Level Radios'),
			A2(
				$elm$core$Maybe$map,
				function (el) {
					return A2(
						$mdgriffith$elm_ui$Element$Input$labelAbove,
						_List_Nil,
						$mdgriffith$elm_ui$Element$text(el));
				},
				headingMaybe));
		return A2(
			$mdgriffith$elm_ui$Element$Input$radioRow,
			attribs,
			{
				bD: labelItem,
				cS: setCohortFn,
				ni: A2(
					$elm$core$List$map,
					function (el) {
						return A2(
							$mdgriffith$elm_ui$Element$Input$option,
							el,
							$mdgriffith$elm_ui$Element$text(
								$author$project$Type$Cohort$toShortExpandedString(el)));
					},
					$author$project$Type$Cohort$cohorts),
				ob: cohortMaybe
			});
	});
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $author$project$Page$PagesCommon$makeTeacherDropdownOption = F2(
	function (defaultTeacherId, personEDetails) {
		var htmlAttrs = function (el) {
			return _Utils_eq(personEDetails.lW, defaultTeacherId) ? A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$selected(true),
				el) : el;
		}(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(personEDetails.lW)
				]));
		return A2(
			$elm$html$Html$option,
			htmlAttrs,
			_List_fromArray(
				[
					$elm$html$Html$text(personEDetails.lt + (' ' + personEDetails.mq))
				]));
	});
var $elm$html$Html$select = _VirtualDom_node('select');
var $author$project$Page$PagesCommon$makeTeacherDropdownCustomizable = F4(
	function (heading, defaultTeacherId, setAssignedTeacherFn, otherAssignableTeachers) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_Nil,
			_List_fromArray(
				[
					heading,
					$mdgriffith$elm_ui$Element$html(
					A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('select control')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$select,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$Events$on,
										'change',
										A2($elm$json$Json$Decode$map, setAssignedTeacherFn, $elm$html$Html$Events$targetValue))
									]),
								function () {
									switch (otherAssignableTeachers.$) {
										case 3:
											var otherAssignableTeachers_ = otherAssignableTeachers.a;
											return A2(
												$elm$core$List$map,
												$author$project$Page$PagesCommon$makeTeacherDropdownOption(defaultTeacherId),
												A2(
													$elm$core$List$sortBy,
													function ($) {
														return $.mq;
													},
													A2($elm$core$List$map, $author$project$Type$User$extractPersonEDetailsFromTeacherE, otherAssignableTeachers_)));
										case 1:
											return _List_fromArray(
												[
													A2(
													$elm$html$Html$option,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$value('invalid')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Loading other teachers...')
														]))
												]);
										case 0:
											return _List_fromArray(
												[
													A2(
													$elm$html$Html$option,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$value('invalid')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Configuration Error: Please refresh page')
														]))
												]);
										default:
											return _List_fromArray(
												[
													A2(
													$elm$html$Html$option,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$value('invalid')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Error: Please refresh page')
														]))
												]);
									}
								}())
							])))
				]));
	});
var $author$project$Page$PagesCommon$makeTeacherDropdown = F3(
	function (defaultTeacherId, setAssignedTeacherFn, otherAssignableTeachers) {
		return A4(
			$author$project$Page$PagesCommon$makeTeacherDropdownCustomizable,
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$paddingEach(
						{er: 5, e9: 0, fz: 0, fY: 10})
					]),
				$mdgriffith$elm_ui$Element$text('Teacher')),
			defaultTeacherId,
			setAssignedTeacherFn,
			otherAssignableTeachers);
	});
var $elm$core$List$map4 = _List_map4;
var $author$project$Page$CreateUsers$makeUserInputWithCreationStatusRecords = F4(
	function (creationStatuses, fNames, lNames, emails) {
		return A5(
			$elm$core$List$map4,
			F4(
				function (creationStatus, fNam, lNam, email) {
					return {gK: creationStatus, li: email, lt: fNam, mq: lNam};
				}),
			creationStatuses,
			fNames,
			lNames,
			emails);
	});
var $author$project$Page$CreateUsers$SetNewPassword = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$CreateUsers$ToggleIsShowPassword = {$: 8};
var $mdgriffith$elm_ui$Internal$Model$Max = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $mdgriffith$elm_ui$Element$maximum = F2(
	function (i, l) {
		return A2($mdgriffith$elm_ui$Internal$Model$Max, i, l);
	});
var $mdgriffith$elm_ui$Element$Input$newPassword = F2(
	function (attrs, pass) {
		return A3(
			$mdgriffith$elm_ui$Element$Input$textHelper,
			{
				as: $elm$core$Maybe$Just('new-password'),
				aG: false,
				pu: $mdgriffith$elm_ui$Element$Input$TextInputNode(
					pass.iR ? 'text' : 'password')
			},
			attrs,
			{bD: pass.bD, cS: pass.cS, cV: pass.cV, cY: pass.cY});
	});
var $author$project$Routing$userRouteTypeLowerSingular = A2($elm$core$Basics$composeR, $author$project$Routing$userRouteTypeToString, $elm$core$String$toLower);
var $author$project$Page$CreateUsers$newPasswordField = F2(
	function (mdl, userType) {
		var showOrHideText = mdl.cN ? 'Hide' : 'Show';
		var decorateAttrs = $author$project$Page$SignInPagesCommon$getDecorateAttrsFn(mdl.cQ);
		return A2(
			$elm$core$List$member,
			mdl.o,
			_List_fromArray(
				[0, 1])) ? A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2($mdgriffith$elm_ui$Element$maximum, 500, $mdgriffith$elm_ui$Element$fill)),
					A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10)
				]),
			A2(
				$mdgriffith$elm_ui$Element$Input$newPassword,
				decorateAttrs(
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$Input$focusedOnLoad])),
				{
					bD: A2(
						$mdgriffith$elm_ui$Element$Input$labelAbove,
						_List_Nil,
						A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									decorateAttrs(_List_Nil),
									$mdgriffith$elm_ui$Element$text(
										'Initial Password for ' + ($author$project$Routing$userRouteTypeLowerSingular(userType) + '(s) below'))),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.eB),
											$mdgriffith$elm_ui$Element$Font$underline,
											$mdgriffith$elm_ui$Element$Font$size(16),
											$mdgriffith$elm_ui$Element$alignRight,
											$mdgriffith$elm_ui$Element$Events$onClick($author$project$Page$CreateUsers$ToggleIsShowPassword)
										]),
									$mdgriffith$elm_ui$Element$text(showOrHideText))
								]))),
					cS: $author$project$Page$CreateUsers$SetNewPassword,
					cV: mdl.fk,
					iR: mdl.cN,
					cY: mdl.cf
				})) : $mdgriffith$elm_ui$Element$none;
	});
var $author$project$Page$CreateUsers$SetRepeatPassword = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$CreateUsers$repeatPasswordField = function (mdl) {
	var decorateAttrs = $author$project$Page$SignInPagesCommon$getDecorateAttrsFn(mdl.cW);
	return A2(
		$elm$core$List$member,
		mdl.o,
		_List_fromArray(
			[0, 1])) ? A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				A2($mdgriffith$elm_ui$Element$maximum, 500, $mdgriffith$elm_ui$Element$fill)),
				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10)
			]),
		A2(
			$mdgriffith$elm_ui$Element$Input$newPassword,
			decorateAttrs(
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$Input$focusedOnLoad])),
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_Nil,
					A2(
						$mdgriffith$elm_ui$Element$el,
						decorateAttrs(_List_Nil),
						$mdgriffith$elm_ui$Element$text('Enter Initial Password Again'))),
				cS: $author$project$Page$CreateUsers$SetRepeatPassword,
				cV: mdl.fy,
				iR: mdl.cN,
				cY: mdl.dU
			})) : $mdgriffith$elm_ui$Element$none;
};
var $author$project$Routing$userRouteTypeCapSingular = $author$project$Routing$userRouteTypeToString;
var $author$project$Routing$userTypeString = F3(
	function (userTypeCase, entityList, userType) {
		return ($elm$core$List$length(entityList) > 1) ? ((userTypeCase === 1) ? $author$project$Routing$userRouteTypeLowerPlural(userType) : $author$project$Routing$userRouteTypeCapPlural(userType)) : ((userTypeCase === 1) ? $author$project$Routing$userRouteTypeLowerSingular(userType) : $author$project$Routing$userRouteTypeCapSingular(userType));
	});
var $author$project$Page$CreateUsers$workArea = F2(
	function (mdl, userType) {
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				var usersDisplayBlock = function () {
					var passwordFields = _List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$paddingEach(
									{er: 20, e9: 0, fz: 0, fY: 0}),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$row,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$spacing(10),
											$mdgriffith$elm_ui$Element$centerY,
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									_List_fromArray(
										[
											A2($author$project$Page$CreateUsers$newPasswordField, mdl, userType)
										])),
									$author$project$Page$SignInPagesCommon$fieldErrorMsgElement(mdl.cQ),
									A2(
									$mdgriffith$elm_ui$Element$row,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$spacing(10),
											$mdgriffith$elm_ui$Element$centerY,
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									_List_fromArray(
										[
											$author$project$Page$CreateUsers$repeatPasswordField(mdl)
										])),
									$author$project$Page$SignInPagesCommon$fieldErrorMsgElement(mdl.cW)
								]))
						]);
					var iconTick = $mdgriffith$elm_ui$Element$html(
						A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('icon')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fa fa-check')
										]),
									_List_Nil)
								])));
					var iconSpinner = $mdgriffith$elm_ui$Element$html(
						A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('icon')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fa fa-spinner fa-spin')
										]),
									_List_Nil)
								])));
					var iconInfo = function (txt) {
						return $mdgriffith$elm_ui$Element$html(
							A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('icon has-tooltip-danger'),
										A2($elm$html$Html$Attributes$attribute, 'data-tooltip', txt)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('fa fa-info-circle')
											]),
										_List_Nil)
									])));
					};
					var makeRow = function (status) {
						if (!status.$) {
							return A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$centerX,
										$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.gz)
									]),
								iconTick);
						} else {
							var errs = status.a;
							var multilineContent = $author$project$Page$CreateUsers$entriesToString(errs);
							return A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$centerX,
										$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.gy)
									]),
								iconInfo(multilineContent));
						}
					};
					var statusRows = A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignTop]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10)
									]),
								$mdgriffith$elm_ui$Element$text('Validity')),
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$spacing(1),
										$mdgriffith$elm_ui$Element$centerX,
										A2($mdgriffith$elm_ui$Element$paddingXY, 0, 8)
									]),
								A2($elm$core$List$map, makeRow, mdl.cL))
							]));
					var userInputRows = function () {
						if ((!mdl.o) || (mdl.o === 1)) {
							return A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$alignTop,
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$row,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$spacing(10),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$Font$size(18)
											]),
										_List_fromArray(
											[
												A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$alignTop,
														$mdgriffith$elm_ui$Element$width(
														$mdgriffith$elm_ui$Element$fillPortion(9))
													]),
												$author$project$Page$CreateUsers$fNamesField(mdl)),
												A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$alignTop,
														$mdgriffith$elm_ui$Element$width(
														$mdgriffith$elm_ui$Element$fillPortion(12))
													]),
												$author$project$Page$CreateUsers$lNamesField(mdl)),
												A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$alignTop,
														$mdgriffith$elm_ui$Element$width(
														$mdgriffith$elm_ui$Element$fillPortion(20))
													]),
												$author$project$Page$CreateUsers$emailsField(mdl))
											]))
									]));
						} else {
							var makeEntryRow = function (txt) {
								return A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5),
											$mdgriffith$elm_ui$Element$Border$dashed,
											$mdgriffith$elm_ui$Element$Border$widthEach(
											{er: 1, e9: 0, fz: 0, fY: 0})
										]),
									$mdgriffith$elm_ui$Element$text(txt));
							};
							var makeCreationStatusRow = function (creationStatus) {
								var _v3 = creationStatus.bp;
								switch (_v3.$) {
									case 0:
										return A2(
											$mdgriffith$elm_ui$Element$row,
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Element$paddingXY, 0, 3),
													$mdgriffith$elm_ui$Element$Border$dashed,
													$mdgriffith$elm_ui$Element$Border$widthEach(
													{er: 1, e9: 0, fz: 0, fY: 0})
												]),
											_List_fromArray(
												[
													A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$centerX,
															$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.eB)
														]),
													iconSpinner)
												]));
									case 1:
										return A2(
											$mdgriffith$elm_ui$Element$row,
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Element$paddingXY, 0, 3),
													$mdgriffith$elm_ui$Element$Border$dashed,
													$mdgriffith$elm_ui$Element$Border$widthEach(
													{er: 1, e9: 0, fz: 0, fY: 0})
												]),
											_List_fromArray(
												[
													A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$centerX,
															$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.eB)
														]),
													iconSpinner)
												]));
									default:
										if (!_v3.a.$) {
											var _v4 = _v3.a;
											return A2(
												$mdgriffith$elm_ui$Element$row,
												_List_fromArray(
													[
														A2($mdgriffith$elm_ui$Element$paddingXY, 0, 3),
														$mdgriffith$elm_ui$Element$Border$dashed,
														$mdgriffith$elm_ui$Element$Border$widthEach(
														{er: 1, e9: 0, fz: 0, fY: 0})
													]),
												_List_fromArray(
													[
														A2(
														$mdgriffith$elm_ui$Element$el,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$centerX,
																$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.gz)
															]),
														iconTick)
													]));
										} else {
											var _v5 = _v3.a;
											var email = _v5.a;
											var name = _v5.b;
											var message = _v5.c;
											var technicals = _v5.d;
											return A2(
												$mdgriffith$elm_ui$Element$row,
												_List_fromArray(
													[
														A2($mdgriffith$elm_ui$Element$paddingXY, 0, 3),
														$mdgriffith$elm_ui$Element$Border$dashed,
														$mdgriffith$elm_ui$Element$Border$widthEach(
														{er: 1, e9: 0, fz: 0, fY: 0})
													]),
												_List_fromArray(
													[
														A2(
														$mdgriffith$elm_ui$Element$el,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$centerX,
																$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.gy)
															]),
														iconInfo(message))
													]));
										}
								}
							};
							var entries = A4(
								$author$project$Page$CreateUsers$makeUserInputWithCreationStatusRecords,
								mdl.ak,
								$elm$core$String$lines(mdl.au),
								$elm$core$String$lines(mdl.ax),
								$elm$core$String$lines(mdl.at));
							var columns_ = _List_fromArray(
								[
									{
									dB: A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5),
												$mdgriffith$elm_ui$Element$Border$solid,
												$mdgriffith$elm_ui$Element$Border$widthEach(
												{er: 1, e9: 0, fz: 0, fY: 0})
											]),
										$mdgriffith$elm_ui$Element$text('First Name(s)')),
									pM: function (el_) {
										return makeEntryRow(el_.lt);
									},
									ec: $mdgriffith$elm_ui$Element$fill
								},
									{
									dB: A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5),
												$mdgriffith$elm_ui$Element$Border$solid,
												$mdgriffith$elm_ui$Element$Border$widthEach(
												{er: 1, e9: 0, fz: 0, fY: 0})
											]),
										$mdgriffith$elm_ui$Element$text('Last Name(s)')),
									pM: function (el_) {
										return makeEntryRow(el_.mq);
									},
									ec: $mdgriffith$elm_ui$Element$fill
								},
									{
									dB: A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5),
												$mdgriffith$elm_ui$Element$Border$solid,
												$mdgriffith$elm_ui$Element$Border$widthEach(
												{er: 1, e9: 0, fz: 0, fY: 0})
											]),
										$mdgriffith$elm_ui$Element$text('Email(s)')),
									pM: function (el_) {
										return makeEntryRow(el_.li);
									},
									ec: $mdgriffith$elm_ui$Element$fill
								}
								]);
							var columns = ((mdl.o === 3) || (mdl.o === 4)) ? A2(
								$elm$core$List$append,
								_List_fromArray(
									[
										{
										dB: A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Element$paddingXY, 5, 5),
													$mdgriffith$elm_ui$Element$Border$solid,
													$mdgriffith$elm_ui$Element$Border$widthEach(
													{er: 1, e9: 0, fz: 0, fY: 0})
												]),
											$mdgriffith$elm_ui$Element$text('Status')),
										pM: function (el_) {
											return makeCreationStatusRow(el_.gK);
										},
										ec: $mdgriffith$elm_ui$Element$shrink
									}
									]),
								columns_) : columns_;
							return A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$alignTop,
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$table,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Element$paddingXY, 0, 20)
											]),
										{kC: columns, k_: entries})
									]));
						}
					}();
					var userRows_ = (mdl.o === 1) ? _List_fromArray(
						[statusRows, userInputRows]) : _List_fromArray(
						[userInputRows]);
					var userRows = A2(
						$elm$core$List$append,
						passwordFields,
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$row,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$spacing(10)
									]),
								userRows_)
							]));
					var iconCross = $mdgriffith$elm_ui$Element$html(
						A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('icon')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fa fa-times')
										]),
									_List_Nil)
								])));
					if (userType === 1) {
						return userRows;
					} else {
						var defaultTeacherId_ = A2(
							$elm$core$Basics$composeR,
							$author$project$Type$User$extractPersonEDetails,
							function ($) {
								return $.lW;
							})(rState.fc);
						return A2(
							$elm$core$List$append,
							function () {
								if (!((mdl.o === 1) || (!mdl.o))) {
									var personEDetailsMaybe = A2($elm$core$Maybe$map, $author$project$Type$User$extractPersonEDetailsFromTeacherE, mdl.a0);
									var getField = F2(
										function (personEDetailsMaybe_, f) {
											return A2(
												$elm$core$Maybe$withDefault,
												'error',
												A2($elm$core$Maybe$map, f, personEDetailsMaybe_));
										});
									var getF = getField(personEDetailsMaybe);
									return _List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$row,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$paddingEach(
													{er: 10, e9: 0, fz: 0, fY: 0}),
													$mdgriffith$elm_ui$Element$Font$underline
												]),
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$text(
													'The ' + (A3($author$project$Routing$userTypeString, 1, mdl.cL, userType) + ' below will be created with settings of:'))
												])),
											A2(
											$mdgriffith$elm_ui$Element$row,
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Element$paddingXY, 10, 10),
													$mdgriffith$elm_ui$Element$spacing(10)
												]),
											_List_fromArray(
												[
													A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[$mdgriffith$elm_ui$Element$Font$bold, $mdgriffith$elm_ui$Element$Font$italic]),
													$mdgriffith$elm_ui$Element$text('Teacher:')),
													A2(
													$mdgriffith$elm_ui$Element$el,
													_List_Nil,
													$mdgriffith$elm_ui$Element$text(
														getF(
															function ($) {
																return $.lt;
															}) + (' ' + getF(
															function ($) {
																return $.mq;
															}))))
												])),
											A2(
											$mdgriffith$elm_ui$Element$row,
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Element$paddingXY, 10, 10),
													$mdgriffith$elm_ui$Element$spacing(10)
												]),
											_List_fromArray(
												[
													A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[$mdgriffith$elm_ui$Element$Font$bold, $mdgriffith$elm_ui$Element$Font$italic]),
													$mdgriffith$elm_ui$Element$text('Year:')),
													A2(
													$mdgriffith$elm_ui$Element$el,
													_List_Nil,
													$mdgriffith$elm_ui$Element$text(
														A2(
															$elm$core$Maybe$withDefault,
															'error',
															A2($elm$core$Maybe$map, $author$project$Type$Cohort$toGraphQLString, mdl.kA))))
												]))
										]);
								} else {
									return _List_fromArray(
										[
											$author$project$Type$User$isTeacherEAdminE(rState.fc) ? A2(
											$mdgriffith$elm_ui$Element$row,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$paddingEach(
													{er: 10, e9: 0, fz: 0, fY: 20})
												]),
											_List_fromArray(
												[
													A3($author$project$Page$PagesCommon$makeTeacherDropdown, defaultTeacherId_, $author$project$Page$CreateUsers$SetAssignedTeacher, mdl.cU)
												])) : $mdgriffith$elm_ui$Element$none,
											A2(
											$mdgriffith$elm_ui$Element$row,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$paddingEach(
													{er: 10, e9: 0, fz: 0, fY: 20})
												]),
											_List_fromArray(
												[
													A4(
													$author$project$Page$PagesCommon$makeCohortRadios,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$padding(10),
															$mdgriffith$elm_ui$Element$spacing(30)
														]),
													$elm$core$Maybe$Just('Year Level (for all students to be created below)'),
													mdl.kA,
													$author$project$Page$CreateUsers$SetCohort),
													A2($author$project$Page$SignInPagesCommon$fieldInfoMsgElementWithColor, $author$project$Theme$Style$t.gC, mdl.cD)
												]))
										]);
								}
							}(),
							userRows);
					}
				}();
				var msgRow = (mdl.o === 3) ? _List_fromArray(
					[
						A2(
						$author$project$Page$SignInPagesCommon$msgAreaWithColor,
						$author$project$Theme$Style$t.eC,
						$elm$core$Maybe$Just('Creating users ... please wait.'))
					]) : _List_fromArray(
					[
						A2($author$project$Page$SignInPagesCommon$msgAreaWithColor, mdl.dQ.a, mdl.dQ.b)
					]);
				var instructions = ((!mdl.o) || (mdl.o === 1)) ? _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$padding(20),
									$mdgriffith$elm_ui$Element$Border$rounded(10),
									$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.gA),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$spacing(8)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_Nil,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text(
												'In each column, type or paste up to 100 ' + ($author$project$Routing$userRouteTypeLowerPlural(userType) + ', one item per line.'))
											])),
										A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_Nil,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text(
												'To paste from a spreadsheet, simply copy the first names from the spreadsheet and paste into the first names column below, then repeat for the remaining columns.  Pasting from a spreadsheet is the safest and recommended way if you have many ' + ($author$project$Routing$userRouteTypeLowerPlural(userType) + ' to create.'))
											])),
										A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_Nil,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text(
												'Each row forms a ' + ($author$project$Routing$userRouteTypeLowerSingular(userType) + ' to be created, so the number of lines of first names, last names, and emails in each column must be the same.'))
											]))
									]))))
					]) : ((mdl.o === 3) ? _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$padding(20),
									$mdgriffith$elm_ui$Element$Border$rounded(10),
									$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.gA),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$spacing(8)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_Nil,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text('Please wait and do not navigate away from this page as your Users are created...')
											]))
									]))))
					]) : _List_Nil);
				var creationResults = function () {
					if (mdl.o === 4) {
						if (A2($elm$core$List$all, $author$project$Page$CreateUsers$isCreationStateSuccess, mdl.ak)) {
							var msgTxt = 'All users were created successfully.' + ((!userType) ? ' To add a group tag to these students, select them in the \'Manage Students\' tab of your home page, click \'Update Students\', then designate a group tag.' : '');
							return _List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_Nil,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text(msgTxt)
										]))
								]);
						} else {
							var errors = A2(
								$elm$core$List$map,
								function (el_) {
									var _v0 = el_.bp;
									if ((_v0.$ === 2) && (_v0.a.$ === 1)) {
										var _v1 = _v0.a;
										var email = _v1.a;
										var name = _v1.b;
										var message = _v1.c;
										var technicals = _v1.d;
										return A2(
											$mdgriffith$elm_ui$Element$column,
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Element$paddingXY, 0, 6)
												]),
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$html(
													A2(
														$elm$html$Html$ul,
														_List_Nil,
														_List_fromArray(
															[
																A2(
																$elm$html$Html$li,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$strong,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$elm$html$Html$text(name + (': ' + email))
																			]))
																	])),
																A2(
																$elm$html$Html$li,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$u,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$elm$html$Html$text(message)
																			]))
																	])),
																A2(
																$elm$html$Html$li,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$em,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$elm$html$Html$text(technicals)
																			]))
																	]))
															])))
												]));
									} else {
										return $mdgriffith$elm_ui$Element$text('Formatting error');
									}
								},
								A2(
									$elm$core$List$filter,
									A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Page$CreateUsers$isCreationStateSuccess),
									mdl.ak));
							return _List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10),
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$padding(20),
												$mdgriffith$elm_ui$Element$Border$rounded(10),
												$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.eC),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
											]),
										A2(
											$mdgriffith$elm_ui$Element$column,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$spacing(8),
													$mdgriffith$elm_ui$Element$Font$size(20)
												]),
											_List_fromArray(
												[
													A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$Font$italic,
															$mdgriffith$elm_ui$Element$Font$underline,
															$mdgriffith$elm_ui$Element$paddingEach(
															{er: 10, e9: 0, fz: 0, fY: 0})
														]),
													$mdgriffith$elm_ui$Element$text('Error details:')),
													A2(
													$mdgriffith$elm_ui$Element$paragraph,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$Font$size(18)
														]),
													errors)
												]))))
								]);
						}
					} else {
						return _List_Nil;
					}
				}();
				var actionBtns = (mdl.o === 3) ? _List_Nil : ((mdl.o === 4) ? _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(10),
								$mdgriffith$elm_ui$Element$centerY
							]),
						_List_fromArray(
							[
								A2(
								$Punie$elm_reader$Reader$run,
								A2($author$project$Page$CreateUsers$actionButton, mdl, userType),
								rState)
							]))
					]) : _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(10),
								$mdgriffith$elm_ui$Element$centerY
							]),
						_List_fromArray(
							[
								$author$project$Page$CreateUsers$cancelButton(mdl),
								A2(
								$Punie$elm_reader$Reader$run,
								A2($author$project$Page$CreateUsers$actionButton, mdl, userType),
								rState)
							]))
					]));
				return A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 0, 60),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$fillPortion(1))
								]),
							_List_Nil),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$fillPortion(6)),
									$mdgriffith$elm_ui$Element$spacing(30)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$row,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.nn),
													$mdgriffith$elm_ui$Element$Font$size(30),
													$mdgriffith$elm_ui$Element$Font$bold
												]),
											$mdgriffith$elm_ui$Element$text(
												'Create ' + $author$project$Routing$userRouteTypeLowerPlural(userType))),
											A2($author$project$Page$PagesCommon$helpAnchorWithColor, 'smHaPageHeading', $author$project$Theme$Style$t.lN)
										])),
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
											A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
											$mdgriffith$elm_ui$Element$Border$rounded(10),
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									$elm$core$List$concat(
										_List_fromArray(
											[instructions, usersDisplayBlock, msgRow, creationResults, actionBtns])))
								])),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$fillPortion(1))
								]),
							_List_Nil)
						]));
			});
	});
var $author$project$Page$CreateUsers$view = F3(
	function (model, topBarFn, userType) {
		var makeWorkArea = F2(
			function (color, elements) {
				return A2(
					$mdgriffith$elm_ui$Element$layout,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color(color)
						]),
					A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						elements));
			});
		var addTopBar = function (wArea2) {
			return A2(
				$Punie$elm_reader$Reader$map,
				function (tBar3) {
					return _List_fromArray(
						[tBar3, wArea2]);
				},
				A2(topBarFn, $author$project$Page$CreateUsers$SignOut, $author$project$Page$CreateUsers$LinkClicked));
		};
		return A3(
			$Punie$elm_reader$Reader$map2,
			makeWorkArea,
			$author$project$Page$PagesCommon$makeBackgroundStyle,
			A2(
				$Punie$elm_reader$Reader$andThen,
				addTopBar,
				A2($author$project$Page$CreateUsers$workArea, model, userType)));
	});
var $author$project$Page$ManageSchools$LinkClicked = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$ManageSchools$SignOut = {$: 14};
var $author$project$Page$ManageSchools$Cancel = {$: 1};
var $author$project$Page$ManageSchools$cancelButton = function (mdl) {
	return A2(
		$author$project$Page$PagesCommon$regButton,
		true,
		{
			bD: A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				$mdgriffith$elm_ui$Element$text('Back')),
			ai: $elm$core$Maybe$Just($author$project$Page$ManageSchools$Cancel)
		});
};
var $author$project$Page$ManageSchools$actionBtns = function (mdl) {
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(10),
				$mdgriffith$elm_ui$Element$centerY
			]),
		_List_fromArray(
			[
				$author$project$Page$ManageSchools$cancelButton(mdl)
			]));
};
var $author$project$Page$ManageSchools$CreateSchool = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Page$ManageSchools$createSchoolButton = function (mdl) {
	var codeNotUnique = function () {
		var _v1 = mdl.L;
		if (_v1.$ === 3) {
			var schoolDetails = _v1.a;
			return A2(
				$elm$core$List$member,
				mdl.bd,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.lW;
					},
					schoolDetails));
		} else {
			return true;
		}
	}();
	var _v0 = ($elm$core$String$isEmpty(mdl.n5) || ($elm$core$String$isEmpty(mdl.bd) || codeNotUnique)) ? _Utils_Tuple2(false, $elm$core$Maybe$Nothing) : _Utils_Tuple2(
		true,
		$elm$core$Maybe$Just(
			A2($author$project$Page$ManageSchools$CreateSchool, mdl.bd, mdl.n5)));
	var isActive = _v0.a;
	var action = _v0.b;
	return A2(
		$author$project$Page$PagesCommon$regButton,
		isActive,
		{
			bD: A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				$mdgriffith$elm_ui$Element$text('Create School')),
			ai: action
		});
};
var $author$project$Page$ManageSchools$SetSchoolCode = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$ManageSchools$schoolCodeField = function (mdl) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10)
			]),
		A2(
			$mdgriffith$elm_ui$Element$Input$text,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$Input$focusedOnLoad]),
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_Nil,
					A2(
						$mdgriffith$elm_ui$Element$el,
						_List_Nil,
						$mdgriffith$elm_ui$Element$text('New unique school code'))),
				cS: $author$project$Page$ManageSchools$SetSchoolCode,
				cV: mdl.fB,
				cY: mdl.bd
			}));
};
var $author$project$Page$ManageSchools$SetSchoolName = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$ManageSchools$schoolNameField = function (mdl) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10)
			]),
		A2(
			$mdgriffith$elm_ui$Element$Input$text,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$Input$focusedOnLoad]),
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_Nil,
					A2(
						$mdgriffith$elm_ui$Element$el,
						_List_Nil,
						$mdgriffith$elm_ui$Element$text('New school name to create'))),
				cS: $author$project$Page$ManageSchools$SetSchoolName,
				cV: mdl.fC,
				cY: mdl.n5
			}));
};
var $author$project$Page$ManageSchools$actionsArea = function (mdl) {
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$paddingEach(
						{er: 15, e9: 15, fz: 15, fY: 15}),
						$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.gA),
						$mdgriffith$elm_ui$Element$Border$rounded(10),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$Font$bold, $mdgriffith$elm_ui$Element$Font$italic]),
								$mdgriffith$elm_ui$Element$text('Create a new school')),
								A2(
								$mdgriffith$elm_ui$Element$row,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$spacing(10)
									]),
								_List_fromArray(
									[
										$author$project$Page$ManageSchools$schoolNameField(mdl),
										$author$project$Page$ManageSchools$schoolCodeField(mdl),
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$paddingEach(
												{er: 0, e9: 0, fz: 0, fY: 15})
											]),
										$author$project$Page$ManageSchools$createSchoolButton(mdl))
									]))
							]))
					]))
			]));
};
var $author$project$Page$ManageSchools$currentSettingsArea = function (mdl) {
	return $Punie$elm_reader$Reader$asks(
		function (rState) {
			var schCode = $author$project$Type$User$extractPersonEDetails(rState.fc).d$;
			var schName = A3(
				$krisajenkins$remotedata$RemoteData$unwrap,
				'error',
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$filter(
						function (el) {
							return _Utils_eq(el.lW, schCode);
						}),
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$head,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$map(
								function ($) {
									return $.mO;
								}),
							$elm$core$Maybe$withDefault('error')))),
				mdl.L);
			return A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$paddingEach(
								{er: 15, e9: 15, fz: 15, fY: 15}),
								$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.gA),
								$mdgriffith$elm_ui$Element$Border$rounded(10),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$Font$bold, $mdgriffith$elm_ui$Element$Font$italic]),
										$mdgriffith$elm_ui$Element$text('You are currently an administrator for: ' + (schCode + (' (' + (schName + ')')))))
									]))
							]))
					]));
		});
};
var $author$project$Page$ManageSchools$SetMySchoolId = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$ManageSchools$augmentAttrs = F2(
	function (isCurrentAdminSchool, attrs) {
		return isCurrentAdminSchool ? A2(
			$elm$core$List$cons,
			$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.jG),
			attrs) : attrs;
	});
var $author$project$Page$ManageSchools$makeEntryRow = F2(
	function (isCurrentAdminSchool, elem) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			A2(
				$author$project$Page$ManageSchools$augmentAttrs,
				isCurrentAdminSchool,
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Element$paddingXY, 10, 10),
						$mdgriffith$elm_ui$Element$Border$dashed,
						$mdgriffith$elm_ui$Element$Border$widthEach(
						{er: 1, e9: 0, fz: 0, fY: 0})
					])),
			elem);
	});
var $author$project$Page$ManageSchools$DeleteSchool = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$ManageSchools$GetNbrUsersForSchoolId = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$ManageSchools$viewSchoolActions = F3(
	function (currentAdminSchool, mdl, schoolId) {
		var nbrUsersMaybe = A2($elm$core$Dict$get, schoolId, mdl.cd);
		if (nbrUsersMaybe.$ === 1) {
			return A2(
				$mdgriffith$elm_ui$Element$el,
				A2(
					$author$project$Page$ManageSchools$augmentAttrs,
					_Utils_eq(currentAdminSchool, schoolId),
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
							$mdgriffith$elm_ui$Element$Border$dashed,
							$mdgriffith$elm_ui$Element$Border$widthEach(
							{er: 1, e9: 0, fz: 0, fY: 0})
						])),
				A2(
					$mdgriffith$elm_ui$Element$el,
					_List_Nil,
					A2(
						$mdgriffith$elm_ui$Element$Input$button,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.f6),
								$mdgriffith$elm_ui$Element$Border$rounded(5),
								A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						{
							bD: A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX]),
								$mdgriffith$elm_ui$Element$text('Get #teachers')),
							ai: function () {
								var _v1 = mdl.b7;
								if (_v1.$ === 1) {
									return $elm$core$Maybe$Just(
										$author$project$Page$ManageSchools$GetNbrUsersForSchoolId(schoolId));
								} else {
									return $elm$core$Maybe$Nothing;
								}
							}()
						})));
		} else {
			if (!nbrUsersMaybe.a) {
				return A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
							$mdgriffith$elm_ui$Element$Border$dashed,
							$mdgriffith$elm_ui$Element$Border$widthEach(
							{er: 1, e9: 0, fz: 0, fY: 0})
						]),
					A2(
						$mdgriffith$elm_ui$Element$el,
						_List_Nil,
						A2(
							$mdgriffith$elm_ui$Element$Input$button,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.f6),
									$mdgriffith$elm_ui$Element$Border$rounded(5),
									A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							{
								bD: A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX]),
									$mdgriffith$elm_ui$Element$text('Delete School')),
								ai: $elm$core$Maybe$Just(
									$author$project$Page$ManageSchools$DeleteSchool(schoolId))
							})));
			} else {
				var nbrUsers = nbrUsersMaybe.a;
				return A2(
					$author$project$Page$ManageSchools$makeEntryRow,
					_Utils_eq(currentAdminSchool, schoolId),
					$mdgriffith$elm_ui$Element$text(
						$elm$core$String$fromInt(nbrUsers) + ' teachers'));
			}
		}
	});
var $author$project$Page$ManageSchools$columnsForSchoolsTable = F2(
	function (mdl, currentAdminSchool) {
		return _List_fromArray(
			[
				{
				dB: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
							$mdgriffith$elm_ui$Element$Border$solid,
							$mdgriffith$elm_ui$Element$Border$widthEach(
							{er: 1, e9: 0, fz: 0, fY: 0})
						]),
					$mdgriffith$elm_ui$Element$text('School Name')),
				pM: function (el_) {
					return A2(
						$author$project$Page$ManageSchools$makeEntryRow,
						_Utils_eq(currentAdminSchool, el_.lW),
						$mdgriffith$elm_ui$Element$text(el_.mO));
				},
				ec: $mdgriffith$elm_ui$Element$fill
			},
				{
				dB: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
							$mdgriffith$elm_ui$Element$Border$solid,
							$mdgriffith$elm_ui$Element$Border$widthEach(
							{er: 1, e9: 0, fz: 0, fY: 0})
						]),
					$mdgriffith$elm_ui$Element$text('School Code')),
				pM: function (el_) {
					return A2(
						$author$project$Page$ManageSchools$makeEntryRow,
						_Utils_eq(currentAdminSchool, el_.lW),
						$mdgriffith$elm_ui$Element$text(el_.lW));
				},
				ec: $mdgriffith$elm_ui$Element$fill
			},
				{
				dB: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
							$mdgriffith$elm_ui$Element$Border$solid,
							$mdgriffith$elm_ui$Element$Border$widthEach(
							{er: 1, e9: 0, fz: 0, fY: 0})
						]),
					$mdgriffith$elm_ui$Element$text('Action')),
				pM: function (el_) {
					return A2(
						$mdgriffith$elm_ui$Element$el,
						A2(
							$author$project$Page$ManageSchools$augmentAttrs,
							_Utils_eq(currentAdminSchool, el_.lW),
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
									$mdgriffith$elm_ui$Element$Border$dashed,
									$mdgriffith$elm_ui$Element$Border$widthEach(
									{er: 1, e9: 0, fz: 0, fY: 0})
								])),
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_Nil,
							A2(
								$mdgriffith$elm_ui$Element$Input$button,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.f6),
										$mdgriffith$elm_ui$Element$Border$rounded(5),
										A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								{
									bD: A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$centerX]),
										$mdgriffith$elm_ui$Element$text('Become an admin')),
									ai: $elm$core$Maybe$Just(
										$author$project$Page$ManageSchools$SetMySchoolId(el_.lW))
								})));
				},
				ec: $mdgriffith$elm_ui$Element$fill
			},
				{
				dB: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
							$mdgriffith$elm_ui$Element$Border$solid,
							$mdgriffith$elm_ui$Element$Border$widthEach(
							{er: 1, e9: 0, fz: 0, fY: 0})
						]),
					$mdgriffith$elm_ui$Element$text('School actions')),
				pM: function (el_) {
					return A3($author$project$Page$ManageSchools$viewSchoolActions, currentAdminSchool, mdl, el_.lW);
				},
				ec: $mdgriffith$elm_ui$Element$fill
			}
			]);
	});
var $author$project$Page$ManageSchools$makeSchoolsTable = F3(
	function (currentAdminSchool, mdl, entries) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$alignTop,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$paddingEach(
							{er: 0, e9: 0, fz: 0, fY: 20})
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$Font$bold, $mdgriffith$elm_ui$Element$Font$italic]),
									$mdgriffith$elm_ui$Element$text('Schools'))
								]))
						])),
					A2(
					$mdgriffith$elm_ui$Element$table,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 0, 20)
						]),
					{
						kC: A2($author$project$Page$ManageSchools$columnsForSchoolsTable, mdl, currentAdminSchool),
						k_: entries
					})
				]));
	});
var $author$project$Page$ManageSchools$msgRow = function (pageMsgArea) {
	var _v0 = pageMsgArea.b;
	if (_v0.$ === 1) {
		return $mdgriffith$elm_ui$Element$none;
	} else {
		return A2($author$project$Page$SignInPagesCommon$msgAreaWithColor, pageMsgArea.a, pageMsgArea.b);
	}
};
var $author$project$Page$ManageSchools$pageHeading = A2(
	$mdgriffith$elm_ui$Element$row,
	_List_Nil,
	_List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.nn),
					$mdgriffith$elm_ui$Element$Font$size(30),
					$mdgriffith$elm_ui$Element$Font$bold
				]),
			$mdgriffith$elm_ui$Element$text('Manage Schools')),
			A2($author$project$Page$PagesCommon$helpAnchorWithColor, 'smHaPageHeading', $author$project$Theme$Style$t.lN)
		]));
var $author$project$Page$ManageSchools$workArea = function (mdl) {
	var schoolsArea = function (personE) {
		var makeTextRow = function (el_) {
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$padding(20)
					]),
				el_);
		};
		var currentAdminSchool = $author$project$Type$User$extractPersonEDetails(personE).d$;
		var _v0 = mdl.L;
		switch (_v0.$) {
			case 3:
				var schoolsDetails_ = _v0.a;
				return ($elm$core$List$length(schoolsDetails_) < 1) ? makeTextRow(
					$mdgriffith$elm_ui$Element$text('The retrieved list of schools is empty.')) : A3(
					$author$project$Page$ManageSchools$makeSchoolsTable,
					currentAdminSchool,
					mdl,
					A2(
						$elm$core$List$sortBy,
						function ($) {
							return $.mO;
						},
						schoolsDetails_));
			case 0:
				return makeTextRow(
					$mdgriffith$elm_ui$Element$text('No request has yet been made for the list of schools.'));
			case 1:
				return makeTextRow(
					$mdgriffith$elm_ui$Element$text('Loading schools...'));
			default:
				var err = _v0.a;
				return makeTextRow(
					$mdgriffith$elm_ui$Element$text('Failed to load schools.'));
		}
	};
	var makeWorkArea = function (currSetArea) {
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				return $author$project$Type$ReaderConfig$isNapAdmin(rState) ? A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 0, 60),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$fillPortion(1))
								]),
							_List_Nil),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$fillPortion(6)),
									$mdgriffith$elm_ui$Element$spacing(30)
								]),
							_List_fromArray(
								[
									$author$project$Page$ManageSchools$pageHeading,
									$author$project$Page$ManageSchools$msgRow(mdl.dQ),
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
											A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
											$mdgriffith$elm_ui$Element$Border$rounded(10),
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									_List_fromArray(
										[
											$author$project$Page$ManageSchools$actionsArea(mdl),
											currSetArea,
											schoolsArea(rState.fc),
											$author$project$Page$ManageSchools$actionBtns(mdl)
										]))
								])),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$fillPortion(1))
								]),
							_List_Nil)
						])) : $mdgriffith$elm_ui$Element$text('Could not validate your user status.  Please logout and login again.');
			});
	};
	return A2(
		$Punie$elm_reader$Reader$andThen,
		makeWorkArea,
		$author$project$Page$ManageSchools$currentSettingsArea(mdl));
};
var $author$project$Page$ManageSchools$view = F2(
	function (model, topBarFn) {
		var addTopBar = function (wArea) {
			return A2(
				$Punie$elm_reader$Reader$map,
				function (tBar) {
					return _List_fromArray(
						[tBar, wArea]);
				},
				A2(topBarFn, $author$project$Page$ManageSchools$SignOut, $author$project$Page$ManageSchools$LinkClicked));
		};
		return A3(
			$Punie$elm_reader$Reader$map2,
			F2(
				function (color, elements) {
					return A2(
						$mdgriffith$elm_ui$Element$layout,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Background$color(color)
							]),
						A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							elements));
				}),
			$author$project$Page$PagesCommon$makeBackgroundStyle,
			A2(
				$Punie$elm_reader$Reader$andThen,
				addTopBar,
				$author$project$Page$ManageSchools$workArea(model)));
	});
var $author$project$Page$ManageUsers$LinkClicked = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$ManageUsers$SignOut = {$: 16};
var $author$project$Page$ManageUsers$CheckAllTeachers = {$: 17};
var $author$project$Page$ManageUsers$CheckNoneTeachers = {$: 18};
var $author$project$Page$ManageUsers$Cancel = {$: 4};
var $author$project$Page$ManageUsers$cancelButton = A2(
	$author$project$Page$PagesCommon$regButton,
	true,
	{
		bD: A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$centerX]),
			$mdgriffith$elm_ui$Element$text('Back')),
		ai: $elm$core$Maybe$Just($author$project$Page$ManageUsers$Cancel)
	});
var $author$project$Page$ManageUsers$actionBtns = A2(
	$mdgriffith$elm_ui$Element$row,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$spacing(10),
			$mdgriffith$elm_ui$Element$centerY
		]),
	_List_fromArray(
		[$author$project$Page$ManageUsers$cancelButton]));
var $author$project$Page$ManageUsers$createUsersButton = function (userType) {
	return A2(
		$author$project$Page$PagesCommon$regButton,
		true,
		{
			bD: A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				$mdgriffith$elm_ui$Element$text(
					'Create ' + $author$project$Routing$userRouteTypeCapPlural(userType))),
			ai: $elm$core$Maybe$Just(
				$author$project$Page$ManageUsers$LinkClicked(
					$author$project$Routing$withHash(
						$author$project$Routing$smPaths.kP + ('/' + $author$project$Routing$userRouteTypeCapSingular(userType)))))
		});
};
var $author$project$Page$ManageUsers$DeleteLoginUsers = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$ManageUsers$isCheckedUser = function (user) {
	if (user.$ === 1) {
		var isChecked = user.b;
		return isChecked;
	} else {
		return false;
	}
};
var $author$project$Page$ManageUsers$toSelectedPersonEs = function (users) {
	return A2(
		$elm$core$List$map,
		$author$project$Page$ManageUsers$extractPersonEFromUser,
		A2($elm$core$List$filter, $author$project$Page$ManageUsers$isCheckedUser, users));
};
var $author$project$Page$ManageUsers$makeButtonCmd = F2(
	function (msg, users) {
		return function (list) {
			return ($elm$core$List$length(list) > 0) ? $elm$core$Maybe$Just(
				msg(list)) : $elm$core$Maybe$Nothing;
		}(
			$author$project$Page$ManageUsers$toSelectedPersonEs(users));
	});
var $author$project$Page$ManageUsers$deleteStudentsButton = function (mdl) {
	var cmdMaybe = A3(
		$elm$core$Basics$composeR,
		$krisajenkins$remotedata$RemoteData$toMaybe,
		$elm$core$Maybe$withDefault($elm$core$Maybe$Nothing),
		A2(
			$krisajenkins$remotedata$RemoteData$map,
			$author$project$Page$ManageUsers$makeButtonCmd($author$project$Page$ManageUsers$DeleteLoginUsers),
			mdl.S));
	var cmdIsActivePair = _Utils_eq(cmdMaybe, $elm$core$Maybe$Nothing) ? _Utils_Tuple2($elm$core$Maybe$Nothing, false) : _Utils_Tuple2(cmdMaybe, true);
	return A2(
		$author$project$Page$PagesCommon$regButton,
		cmdIsActivePair.b,
		{
			bD: A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				$mdgriffith$elm_ui$Element$text('Delete Students')),
			ai: cmdIsActivePair.a
		});
};
var $author$project$Page$ManageUsers$makeStudentsSelectedText = F2(
	function (userRouteType, students) {
		var nbrStudents = $elm$core$List$length(
			A2($elm$core$List$filter, $author$project$Page$ManageUsers$isCheckedUser, students));
		return (nbrStudents > 1) ? A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$Font$bold]),
			$mdgriffith$elm_ui$Element$text(
				$elm$core$String$fromInt(nbrStudents) + (' ' + $author$project$Routing$userRouteTypeLowerPlural(userRouteType)))) : ((nbrStudents === 1) ? A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$Font$bold]),
			$mdgriffith$elm_ui$Element$text(
				'single ' + $author$project$Routing$userRouteTypeLowerSingular(userRouteType))) : $mdgriffith$elm_ui$Element$text(
			$author$project$Routing$userRouteTypeLowerPlural(userRouteType)));
	});
var $author$project$Page$ManageUsers$UpdateUsers = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$ManageUsers$updateStudentsButton = function (mdl) {
	var cmdMaybe = A3(
		$elm$core$Basics$composeR,
		$krisajenkins$remotedata$RemoteData$toMaybe,
		$elm$core$Maybe$withDefault($elm$core$Maybe$Nothing),
		A2(
			$krisajenkins$remotedata$RemoteData$map,
			$author$project$Page$ManageUsers$makeButtonCmd($author$project$Page$ManageUsers$UpdateUsers),
			mdl.S));
	var cmdIsActivePair = _Utils_eq(cmdMaybe, $elm$core$Maybe$Nothing) ? _Utils_Tuple2($elm$core$Maybe$Nothing, false) : _Utils_Tuple2(cmdMaybe, true);
	return A2(
		$author$project$Page$PagesCommon$regButton,
		cmdIsActivePair.b,
		{
			bD: A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				$mdgriffith$elm_ui$Element$text('Update Students')),
			ai: cmdIsActivePair.a
		});
};
var $author$project$Page$ManageUsers$actionsArea = F2(
	function (mdl, userType) {
		if (!userType) {
			return A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$paddingEach(
						{er: 0, e9: 0, fz: 0, fY: 0})
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$padding(15),
								$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.gA),
								$mdgriffith$elm_ui$Element$Border$roundEach(
								{de: 10, df: 0, d8: 10, d9: 0}),
								$mdgriffith$elm_ui$Element$Border$dashed,
								$mdgriffith$elm_ui$Element$Border$widthEach(
								{er: 0, e9: 0, fz: 1, fY: 0})
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$row,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$Font$italic]),
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text('For the '),
												A2(
												$author$project$Page$ManageUsers$makeStudentsSelectedText,
												userType,
												A2($krisajenkins$remotedata$RemoteData$withDefault, _List_Nil, mdl.S)),
												$mdgriffith$elm_ui$Element$text(' selected below')
											])),
										A2(
										$mdgriffith$elm_ui$Element$row,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$spacing(10)
											]),
										_List_fromArray(
											[
												$author$project$Page$ManageUsers$updateStudentsButton(mdl),
												$author$project$Page$ManageUsers$deleteStudentsButton(mdl)
											]))
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$padding(15),
								$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.gA),
								$mdgriffith$elm_ui$Element$Border$roundEach(
								{de: 0, df: 10, d8: 0, d9: 10})
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_Nil,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text(' '),
										$author$project$Page$ManageUsers$createUsersButton(userType)
									]))
							]))
					]));
		} else {
			return $author$project$Page$ManageUsers$createUsersButton(userType);
		}
	});
var $author$project$Page$ManageUsers$makeStudentRecord = function (user) {
	if ((user.$ === 1) && (!user.a.$)) {
		var id = user.a.a.lW;
		var sId = user.a.a.d$;
		var fNam = user.a.a.lt;
		var lNam = user.a.a.mq;
		var email = user.a.a.li;
		var cohort = user.a.a.kA;
		var group = user.a.a.lI;
		var schId = user.a.a.n4;
		var hstry = user.a.a.lU;
		var isChecked = user.b;
		return {kA: cohort, li: email, lt: fNam, lI: group, lU: hstry, lW: id, W: isChecked, mq: lNam, dL: $elm$core$Maybe$Nothing, d$: sId, n4: schId};
	} else {
		return {kA: $author$project$Type$Cohort$placeholder, li: 'fail', lt: 'fail', lI: $elm$core$Maybe$Nothing, lU: _List_Nil, lW: 'fail', W: false, mq: 'fail', dL: $elm$core$Maybe$Nothing, d$: 'fail', n4: 'fail'};
	}
};
var $author$project$Type$NodeCfg$activitiesByLevel = function (nodeCfg) {
	var getActivitiesForSyllabus_ = $author$project$Type$NodeCfg$getActivitiesForSyllabus(nodeCfg.fs);
	return $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (syllabus) {
				var lvl = $author$project$Type$Cohort$toInt(syllabus.kA);
				return _Utils_Tuple2(
					lvl,
					getActivitiesForSyllabus_(syllabus));
			},
			nodeCfg.oJ));
};
var $author$project$Page$ManageUsers$NbrAssess = 0;
var $author$project$Page$ManageUsers$NbrNaplan = 2;
var $author$project$Page$ManageUsers$NbrPractice = 1;
var $author$project$Page$ManageUsers$columnsAction = _List_fromArray(
	[
		{
		dB: A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
					$mdgriffith$elm_ui$Element$Border$solid,
					$mdgriffith$elm_ui$Element$Border$widthEach(
					{er: 1, e9: 0, fz: 0, fY: 0})
				]),
			$mdgriffith$elm_ui$Element$text('Action')),
		pM: function (el_) {
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
						$mdgriffith$elm_ui$Element$Border$dashed,
						$mdgriffith$elm_ui$Element$Border$widthEach(
						{er: 1, e9: 0, fz: 0, fY: 0})
					]),
				A2(
					$mdgriffith$elm_ui$Element$el,
					_List_Nil,
					A2(
						$mdgriffith$elm_ui$Element$Input$button,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.f6),
								$mdgriffith$elm_ui$Element$Border$rounded(5),
								A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						{
							bD: A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX]),
								$mdgriffith$elm_ui$Element$text('Edit')),
							ai: $elm$core$Maybe$Just(
								$author$project$Page$ManageUsers$LinkClicked(
									$author$project$Routing$withHash($author$project$Routing$smPaths.nC + ('/' + el_.lW))))
						})));
		},
		ec: $mdgriffith$elm_ui$Element$fill
	}
	]);
var $author$project$Type$ExamResult$getActivityResult = F4(
	function (decoderFn, cohort, nodeItems, hItems) {
		return A2(
			$elm$core$Maybe$map,
			decoderFn(cohort),
			A2($author$project$Type$ExamResult$calculateActivityResultDifficulty, nodeItems, hItems));
	});
var $author$project$Type$ExamResult$getActivityResultUrlSmall = F3(
	function (cohort, nodeItems, hItems) {
		return A4($author$project$Type$ExamResult$getActivityResult, $author$project$Type$ExamResult$difficultyToImageUrlSmall, cohort, nodeItems, hItems);
	});
var $author$project$Type$ExamResult$getExamActivityResultRecords = F2(
	function (nodeCfg, actRRs) {
		var currentSyllabus = A2($author$project$Type$Syllabus$getSyllabus, nodeCfg.oJ, nodeCfg.cF);
		var examActivityIds = A2(
			$elm$core$List$map,
			function ($) {
				return $.lW;
			},
			A2(
				$elm$core$List$filter,
				function ($) {
					return $.hu;
				},
				A2($author$project$Type$NodeCfg$getActivitiesForSyllabus, nodeCfg.fs, currentSyllabus)));
		return A2(
			$elm$core$List$filter,
			function (el) {
				return A2($elm$core$List$member, el.jz, examActivityIds);
			},
			actRRs);
	});
var $author$project$Type$ExamResult$getMostRecentActivityResultRecord = F3(
	function (nodeCfg, isIncludeOnlyIfExamIsInTitle, serrs) {
		return $author$project$Type$ExamResult$getMostRecentActivityResultRecordItem(
			function (els) {
				return isIncludeOnlyIfExamIsInTitle ? A2($author$project$Type$ExamResult$getExamActivityResultRecords, nodeCfg, els) : els;
			}(
				A2($elm$core$List$filter, $author$project$Type$ExamResult$isOnlineActivityResultRecord, serrs)));
	});
var $author$project$Type$ExamResult$getMostRecentActivityResultUrlSmall = F3(
	function (nodeCfg, isIncludeOnlyIfExamIsInTitle, serrs) {
		return A2(
			$elm$core$Maybe$andThen,
			function (el) {
				return A3(
					$author$project$Type$ExamResult$getActivityResultUrlSmall,
					nodeCfg.cF,
					$author$project$Type$NodeCfg$getItems(nodeCfg.fs),
					el.ci);
			},
			A3($author$project$Type$ExamResult$getMostRecentActivityResultRecord, nodeCfg, isIncludeOnlyIfExamIsInTitle, serrs));
	});
var $author$project$Page$ManageUsers$columnsExamResult = F2(
	function (nodeCfg, _v0) {
		return _List_fromArray(
			[
				{
				dB: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
							$mdgriffith$elm_ui$Element$Border$solid,
							$mdgriffith$elm_ui$Element$Border$widthEach(
							{er: 1, e9: 0, fz: 0, fY: 0})
						]),
					$mdgriffith$elm_ui$Element$text('Test Zone Result')),
				pM: function (el_) {
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
								$mdgriffith$elm_ui$Element$Border$dashed,
								$mdgriffith$elm_ui$Element$Border$widthEach(
								{er: 1, e9: 0, fz: 0, fY: 0})
							]),
						function () {
							var recentExamResult = $author$project$UISnippet$Misc$makeResultElement(
								A3(
									$author$project$Type$ExamResult$getMostRecentActivityResultUrlSmall,
									_Utils_update(
										nodeCfg,
										{cF: el_.kA}),
									true,
									$author$project$Type$ExamResult$getActivityResultRecords(el_.lU)));
							return A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$px(168)),
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$px(30)),
										A2($mdgriffith$elm_ui$Element$paddingXY, 0, 0)
									]),
								recentExamResult);
						}());
				},
				ec: $mdgriffith$elm_ui$Element$fill
			}
			]);
	});
var $author$project$Page$ManageUsers$makeEntryRow = function (elem) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Element$paddingXY, 10, 10),
				$mdgriffith$elm_ui$Element$Border$dashed,
				$mdgriffith$elm_ui$Element$Border$widthEach(
				{er: 1, e9: 0, fz: 0, fY: 0})
			]),
		elem);
};
var $author$project$Page$ManageUsers$columnsFirstLastEmail = function (isStudentsList) {
	return A2(
		$elm$core$List$append,
		_List_fromArray(
			[
				{
				dB: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
							$mdgriffith$elm_ui$Element$Border$solid,
							$mdgriffith$elm_ui$Element$Border$widthEach(
							{er: 1, e9: 0, fz: 0, fY: 0})
						]),
					$mdgriffith$elm_ui$Element$text('First Name(s)')),
				pM: function (el_) {
					return $author$project$Page$ManageUsers$makeEntryRow(
						$mdgriffith$elm_ui$Element$text(el_.lt));
				},
				ec: $mdgriffith$elm_ui$Element$fill
			},
				{
				dB: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
							$mdgriffith$elm_ui$Element$Border$solid,
							$mdgriffith$elm_ui$Element$Border$widthEach(
							{er: 1, e9: 0, fz: 0, fY: 0})
						]),
					$mdgriffith$elm_ui$Element$text('Last Name(s)')),
				pM: function (el_) {
					return $author$project$Page$ManageUsers$makeEntryRow(
						$mdgriffith$elm_ui$Element$text(el_.mq));
				},
				ec: $mdgriffith$elm_ui$Element$fill
			}
			]),
		isStudentsList ? _List_Nil : _List_fromArray(
			[
				{
				dB: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
							$mdgriffith$elm_ui$Element$Border$solid,
							$mdgriffith$elm_ui$Element$Border$widthEach(
							{er: 1, e9: 0, fz: 0, fY: 0})
						]),
					$mdgriffith$elm_ui$Element$text('Email(s)')),
				pM: function (el_) {
					return $author$project$Page$ManageUsers$makeEntryRow(
						$mdgriffith$elm_ui$Element$text(el_.li));
				},
				ec: $mdgriffith$elm_ui$Element$fill
			}
			]));
};
var $author$project$Page$ManageUsers$SetUserIsChecked = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Page$ManageUsers$columnsIsChecked = _List_fromArray(
	[
		{
		dB: A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
					$mdgriffith$elm_ui$Element$Border$solid,
					$mdgriffith$elm_ui$Element$Border$widthEach(
					{er: 1, e9: 0, fz: 0, fY: 0})
				]),
			$mdgriffith$elm_ui$Element$text('Select')),
		pM: function (el_) {
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Element$paddingXY, 10, 13),
						$mdgriffith$elm_ui$Element$Border$dashed,
						$mdgriffith$elm_ui$Element$Border$widthEach(
						{er: 1, e9: 0, fz: 0, fY: 0})
					]),
				A2(
					$mdgriffith$elm_ui$Element$Input$checkbox,
					_List_Nil,
					{
						gr: el_.W,
						hl: $mdgriffith$elm_ui$Element$Input$defaultCheckbox,
						bD: $mdgriffith$elm_ui$Element$Input$labelHidden('Selected?'),
						cS: $author$project$Page$ManageUsers$SetUserIsChecked(el_.lW)
					}));
		},
		ec: $mdgriffith$elm_ui$Element$shrink
	}
	]);
var $author$project$Page$ManageUsers$columnsNbrAttempts = function (nbrAttemptsType) {
	var heading = function () {
		switch (nbrAttemptsType) {
			case 0:
				return '#Test';
			case 1:
				return '#Prac';
			default:
				return '#Napl';
		}
	}();
	return _List_fromArray(
		[
			{
			dB: A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$center,
						A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
						$mdgriffith$elm_ui$Element$Border$solid,
						$mdgriffith$elm_ui$Element$Border$widthEach(
						{er: 1, e9: 0, fz: 0, fY: 0})
					]),
				$mdgriffith$elm_ui$Element$text(heading)),
			pM: function (el_) {
				var nbrAttemptsStr = function () {
					var _v0 = el_.dL;
					if (!_v0.$) {
						var nbrAttempts = _v0.a;
						var nbrAttempts_ = function () {
							switch (nbrAttemptsType) {
								case 0:
									return nbrAttempts.mQ;
								case 1:
									return nbrAttempts.mS;
								default:
									return nbrAttempts.mR;
							}
						}();
						return (!nbrAttempts_) ? '-' : $elm$core$String$fromInt(nbrAttempts_);
					} else {
						return '-';
					}
				}();
				return A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
							$mdgriffith$elm_ui$Element$Border$dashed,
							$mdgriffith$elm_ui$Element$Border$widthEach(
							{er: 1, e9: 0, fz: 0, fY: 0})
						]),
					A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5)
							]),
						$mdgriffith$elm_ui$Element$text(nbrAttemptsStr)));
			},
			ec: $mdgriffith$elm_ui$Element$shrink
		}
		]);
};
var $author$project$Page$ManageUsers$columnsReportCard = _List_fromArray(
	[
		{
		dB: A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Font$center,
					A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
					$mdgriffith$elm_ui$Element$Border$solid,
					$mdgriffith$elm_ui$Element$Border$widthEach(
					{er: 1, e9: 0, fz: 0, fY: 0})
				]),
			$mdgriffith$elm_ui$Element$text('Report')),
		pM: function (el_) {
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
						$mdgriffith$elm_ui$Element$Border$dashed,
						$mdgriffith$elm_ui$Element$Border$widthEach(
						{er: 1, e9: 0, fz: 0, fY: 0})
					]),
				(($elm$core$List$length(el_.lU) > 0) && (!_Utils_eq(el_.kA, $author$project$Type$Cohort$MS))) ? A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]),
					A2(
						$mdgriffith$elm_ui$Element$Input$button,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.f6),
								$mdgriffith$elm_ui$Element$Border$rounded(5),
								A2($mdgriffith$elm_ui$Element$paddingXY, 3, 3),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						{
							bD: $mdgriffith$elm_ui$Element$html(
								A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('icon')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('fa fa-file-text-o')
												]),
											_List_Nil)
										]))),
							ai: $elm$core$Maybe$Just(
								$author$project$Page$ManageUsers$LinkClicked(
									$author$project$Routing$withHash($author$project$Routing$smPaths.nQ + ('/' + el_.lW))))
						})) : A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5)
						]),
					$mdgriffith$elm_ui$Element$text('-')));
		},
		ec: $mdgriffith$elm_ui$Element$fill
	}
	]);
var $author$project$Page$ManageUsers$columnsStudentResults = _List_fromArray(
	[
		{
		dB: A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
					$mdgriffith$elm_ui$Element$Border$solid,
					$mdgriffith$elm_ui$Element$Border$widthEach(
					{er: 1, e9: 0, fz: 0, fY: 0})
				]),
			$mdgriffith$elm_ui$Element$text('History')),
		pM: function (el_) {
			var nbrHstry = $elm$core$List$length(el_.lU);
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
						$mdgriffith$elm_ui$Element$Border$dashed,
						$mdgriffith$elm_ui$Element$Border$widthEach(
						{er: 1, e9: 0, fz: 0, fY: 0})
					]),
				(nbrHstry > 0) ? A2(
					$mdgriffith$elm_ui$Element$el,
					_List_Nil,
					A2(
						$mdgriffith$elm_ui$Element$Input$button,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.f6),
								$mdgriffith$elm_ui$Element$Border$rounded(5),
								A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						{
							bD: A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX]),
								$mdgriffith$elm_ui$Element$text('Results')),
							ai: $elm$core$Maybe$Just(
								$author$project$Page$ManageUsers$LinkClicked(
									$author$project$Routing$withHash($author$project$Routing$smPaths.oF + ('/' + el_.lW))))
						})) : A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5)
						]),
					$mdgriffith$elm_ui$Element$text('No attempts')));
		},
		ec: $mdgriffith$elm_ui$Element$fill
	}
	]);
var $author$project$Type$User$extractTeacherName = F2(
	function (teachers, id) {
		return A2(
			$elm$core$Maybe$withDefault,
			'Unknown',
			A2(
				$elm$core$Maybe$map,
				function (el) {
					return el.lt + (' ' + el.mq);
				},
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (el) {
							return _Utils_eq(el.lW, id);
						},
						A2($elm$core$List$map, $author$project$Type$User$extractPersonEDetailsFromTeacherE, teachers)))));
	});
var $author$project$Page$ManageUsers$columnsStudentSpecific = function (teachers) {
	return $elm$core$List$concat(
		_List_fromArray(
			[
				_List_fromArray(
				[
					{
					dB: A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
								$mdgriffith$elm_ui$Element$Border$solid,
								$mdgriffith$elm_ui$Element$Border$widthEach(
								{er: 1, e9: 0, fz: 0, fY: 0})
							]),
						$mdgriffith$elm_ui$Element$text('Year')),
					pM: function (el_) {
						return $author$project$Page$ManageUsers$makeEntryRow(
							$mdgriffith$elm_ui$Element$text(
								$author$project$Type$Cohort$toShortExpandedString(el_.kA)));
					},
					ec: $mdgriffith$elm_ui$Element$fill
				}
				]),
				function () {
				if (teachers.$ === 3) {
					var teachers_ = teachers.a;
					return _List_fromArray(
						[
							{
							dB: A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
										$mdgriffith$elm_ui$Element$Border$solid,
										$mdgriffith$elm_ui$Element$Border$widthEach(
										{er: 1, e9: 0, fz: 0, fY: 0})
									]),
								$mdgriffith$elm_ui$Element$text('Teacher')),
							pM: function (el_) {
								return $author$project$Page$ManageUsers$makeEntryRow(
									$mdgriffith$elm_ui$Element$text(
										A2(
											$author$project$Type$User$extractTeacherName,
											A2(
												$elm$core$List$map,
												function ($) {
													return $.bN;
												},
												teachers_),
											el_.d$)));
							},
							ec: $mdgriffith$elm_ui$Element$fill
						}
						]);
				} else {
					return _List_Nil;
				}
			}(),
				_List_fromArray(
				[
					{
					dB: A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
								$mdgriffith$elm_ui$Element$Border$solid,
								$mdgriffith$elm_ui$Element$Border$widthEach(
								{er: 1, e9: 0, fz: 0, fY: 0})
							]),
						$mdgriffith$elm_ui$Element$text('Group')),
					pM: function (el_) {
						var group = A2($elm$core$Maybe$withDefault, '-', el_.lI);
						return $author$project$Page$ManageUsers$makeEntryRow(
							$mdgriffith$elm_ui$Element$text(group));
					},
					ec: $mdgriffith$elm_ui$Element$fill
				}
				])
			]));
};
var $author$project$Page$ManageUsers$columnsForStudentTable = F5(
	function (isSelfAdmin, nodeCfg, appCfg, model, cohorts) {
		var teachers_ = isSelfAdmin ? model.aa : $krisajenkins$remotedata$RemoteData$NotAsked;
		var isShowNaplCol = A2(
			$elm$core$List$all,
			function (el) {
				return _Utils_eq(el, $author$project$Type$Cohort$MS) || _Utils_eq(
					el,
					$author$project$Type$Cohort$Aus(
						$author$project$Type$Cohort$Stage5(1)));
			},
			cohorts);
		return (model.bJ === 1) ? $elm$core$List$concat(
			_List_fromArray(
				[
					$author$project$Page$ManageUsers$columnsIsChecked,
					$author$project$Page$ManageUsers$columnsFirstLastEmail(true),
					$author$project$Page$ManageUsers$columnsStudentSpecific(teachers_),
					$author$project$Page$ManageUsers$columnsAction
				])) : $elm$core$List$concat(
			_List_fromArray(
				[
					$author$project$Page$ManageUsers$columnsFirstLastEmail(true),
					$author$project$Page$ManageUsers$columnsStudentSpecific(teachers_),
					$author$project$Page$ManageUsers$columnsStudentResults,
					$author$project$Page$ManageUsers$columnsNbrAttempts(0),
					$author$project$Page$ManageUsers$columnsNbrAttempts(1),
					isShowNaplCol ? _List_Nil : $author$project$Page$ManageUsers$columnsNbrAttempts(2),
					$author$project$Page$ManageUsers$columnsReportCard,
					A2($author$project$Page$ManageUsers$columnsExamResult, nodeCfg, appCfg)
				]));
	});
var $author$project$Page$ManageUsers$SetYearLvlFilter = function (a) {
	return {$: 15, a: a};
};
var $author$project$Page$ManageUsers$SetEmailsToFilter = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$ManageUsers$emailsFilterField = F2(
	function (emailsToFilter, groupFilterPlaceholder) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$mdgriffith$elm_ui$Element$Input$multiline,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$Input$focusedOnLoad]),
				{
					bD: A2(
						$mdgriffith$elm_ui$Element$Input$labelAbove,
						_List_Nil,
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_Nil,
							$mdgriffith$elm_ui$Element$text('Emails (one per line)'))),
					cS: $author$project$Page$ManageUsers$SetEmailsToFilter,
					cV: groupFilterPlaceholder,
					fN: false,
					cY: emailsToFilter
				}));
	});
var $author$project$Type$AppCfg$Legacy = 0;
var $author$project$Page$ManageUsers$toStudentEType = function (_v0) {
	var id = _v0.lW;
	var sId = _v0.d$;
	var fNam = _v0.lt;
	var lNam = _v0.mq;
	var email = _v0.li;
	var schId = _v0.n4;
	var hstry = _v0.lU;
	var cohort = _v0.kA;
	var group = _v0.lI;
	return {kA: cohort, li: email, lt: fNam, lI: group, lU: hstry, lW: id, mq: lNam, d$: sId, n4: schId};
};
var $author$project$Page$ManageUsers$AdaptiveAnalytics = function (a) {
	return {$: 19, a: a};
};
var $author$project$Page$PagesCommon$helpAnchor = function (id) {
	return A2($author$project$Page$PagesCommon$helpAnchorWithColor, id, $author$project$Theme$Style$t.lO);
};
var $author$project$Page$ManageUsers$viewAdaptiveAnalyticsButton = function (students) {
	var isActive = !$elm$core$List$isEmpty(
		A2(
			$elm$core$List$concatMap,
			$author$project$Type$ExamResult$getActivityResultRecords,
			A2(
				$elm$core$List$map,
				function ($) {
					return $.lU;
				},
				students)));
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$paddingEach(
				{er: 0, e9: 0, fz: 0, fY: 19})
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$paddingEach(
						{er: 3, e9: 0, fz: 0, fY: 2})
					]),
				$mdgriffith$elm_ui$Element$text(' ')),
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_Nil,
				_List_fromArray(
					[
						A6(
						$author$project$Page$PagesCommon$configurableButton,
						isActive,
						$author$project$Theme$Style$t.f6,
						$author$project$Theme$Style$t.jG,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$paddingEach(
								{er: 0, e9: 0, fz: 0, fY: 0})
							]),
						_List_Nil,
						{
							bD: A2(
								$mdgriffith$elm_ui$Element$el,
								_List_Nil,
								$mdgriffith$elm_ui$Element$text('Aggregate Analytics')),
							ai: $elm$core$Maybe$Just(
								$author$project$Page$ManageUsers$AdaptiveAnalytics(students))
						}),
						$author$project$Page$PagesCommon$helpAnchor('sma_btn_adaptiveAnalytics')
					])),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$paddingEach(
						{er: 0, e9: 0, fz: 22, fY: 3})
					]),
				$mdgriffith$elm_ui$Element$text('(for listed students)'))
			]));
};
var $author$project$Page$ManageUsers$ShowAnalytics = function (a) {
	return {$: 20, a: a};
};
var $author$project$Type$Cohort$fromShortString = A2(
	$elm$core$Basics$composeR,
	$author$project$Type$Cohort$fromStringToMaybeCohort,
	$elm$core$Maybe$withDefault($author$project$Type$Cohort$placeholder));
var $author$project$Page$ManageUsers$getUniqueCohorts = function (filterables) {
	return A2(
		$elm$core$List$map,
		$author$project$Type$Cohort$fromShortString,
		$elm$core$Set$toList(
			$elm$core$Set$fromList(
				A2(
					$elm$core$List$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.kA;
						},
						$author$project$Type$Cohort$toString),
					filterables))));
};
var $author$project$Page$ManageUsers$viewAnalyticsButton = F3(
	function (questionGroupVersion, filterModel, entries) {
		var toQuestionGroupVersions = function (_v4) {
			var hstry = _v4.lU;
			return A2(
				$elm$core$List$map,
				function (el) {
					return $author$project$Type$Testlet$isLegacyQGroupId(el) ? 0 : 1;
				},
				A2(
					$elm$core$List$map,
					function ($) {
						return $.nF;
					},
					A2($elm$core$List$filterMap, $author$project$Type$ExamResult$filterTestletGroupResultRecords, hstry)));
		};
		var questionGroupVersions = A2($elm$core$List$concatMap, toQuestionGroupVersions, entries);
		var cohorts = $author$project$Page$ManageUsers$getUniqueCohorts(
			A2($author$project$Page$ManageUsers$applyFilters, filterModel, entries));
		var isAllOneCohort = function (els) {
			return $elm$core$List$length(els) === 1;
		}(cohorts);
		var isShowAnalyticsBtn = function () {
			if (!questionGroupVersion) {
				return isAllOneCohort && ((!_Utils_eq(
					cohorts,
					_List_fromArray(
						[$author$project$Type$Cohort$MS]))) && A2(
					$elm$core$List$any,
					$elm$core$Basics$eq(0),
					questionGroupVersions));
			} else {
				return !_Utils_eq(
					cohorts,
					_List_fromArray(
						[$author$project$Type$Cohort$MS]));
			}
		}();
		var cmdMaybe = function () {
			if (!questionGroupVersion) {
				return isShowAnalyticsBtn ? $elm$core$Maybe$Just(
					$author$project$Page$ManageUsers$ShowAnalytics(0)) : $elm$core$Maybe$Nothing;
			} else {
				return (isAllOneCohort && A2(
					$elm$core$List$any,
					$elm$core$Basics$eq(1),
					questionGroupVersions)) ? $elm$core$Maybe$Just(
					$author$project$Page$ManageUsers$ShowAnalytics(1)) : $elm$core$Maybe$Nothing;
			}
		}();
		var isActive = !_Utils_eq(cmdMaybe, $elm$core$Maybe$Nothing);
		var _v0 = function () {
			if (!questionGroupVersion) {
				return _Utils_Tuple2('Legacy Analytics', 'sma_btn_legacyAnalytics');
			} else {
				return _Utils_Tuple2('NAPLAN Analytics', 'sma_btn_NAPLANStyleAnalytics');
			}
		}();
		var btnText = _v0.a;
		var btnHelpAnchor = _v0.b;
		return isShowAnalyticsBtn ? A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$paddingEach(
					{er: 0, e9: 0, fz: 0, fY: 19})
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$paddingEach(
							{er: 3, e9: 0, fz: 0, fY: 2})
						]),
					$mdgriffith$elm_ui$Element$text(' ')),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_Nil,
					_List_fromArray(
						[
							A6(
							$author$project$Page$PagesCommon$configurableButton,
							isActive,
							$author$project$Theme$Style$t.f6,
							$author$project$Theme$Style$t.jG,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$paddingEach(
									{er: 0, e9: 0, fz: 0, fY: 0})
								]),
							_List_Nil,
							{
								bD: A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX]),
									A2(
										$mdgriffith$elm_ui$Element$row,
										_List_Nil,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text(btnText)
											]))),
								ai: cmdMaybe
							}),
							$author$project$Page$PagesCommon$helpAnchor(btnHelpAnchor)
						])),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$paddingEach(
							{er: 0, e9: 0, fz: 22, fY: 3})
						]),
					$mdgriffith$elm_ui$Element$text('(for listed students)'))
				])) : $mdgriffith$elm_ui$Element$none;
	});
var $author$project$Page$ManageUsers$filteredListActionButtons = F2(
	function (entries, filterModel) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(10)
				]),
			_List_fromArray(
				[
					A3($author$project$Page$ManageUsers$viewAnalyticsButton, 1, filterModel, entries),
					A3($author$project$Page$ManageUsers$viewAnalyticsButton, 0, filterModel, entries),
					$author$project$Page$ManageUsers$viewAdaptiveAnalyticsButton(
					A2($elm$core$List$map, $author$project$Page$ManageUsers$toStudentEType, entries))
				]));
	});
var $author$project$Page$ManageUsers$SetGroupFilter = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$ManageUsers$groupFilterField = F2(
	function (groupFilter, groupFilterPlaceholder) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$mdgriffith$elm_ui$Element$Input$text,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$Input$focusedOnLoad]),
				{
					bD: A2(
						$mdgriffith$elm_ui$Element$Input$labelAbove,
						_List_Nil,
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_Nil,
							$mdgriffith$elm_ui$Element$text('Group contains (\'-\' for no group)'))),
					cS: $author$project$Page$ManageUsers$SetGroupFilter,
					cV: groupFilterPlaceholder,
					cY: groupFilter
				}));
	});
var $author$project$Page$ManageUsers$filterArea = F6(
	function (isNapAdmin, pageMode, entries, filterModel, cohorts, isEntriesHasGroupTags) {
		var isShowGroupFilter = isEntriesHasGroupTags;
		var isShowCohortFilter = $elm$core$List$length(cohorts) > 1;
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.gA),
					$mdgriffith$elm_ui$Element$Border$rounded(10),
					$mdgriffith$elm_ui$Element$padding(15)
				]),
			_List_fromArray(
				[
					(isShowCohortFilter || isShowGroupFilter) ? ((!pageMode) ? A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$paddingEach(
							{er: 5, e9: 0, fz: 0, fY: 0})
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$Font$bold, $mdgriffith$elm_ui$Element$Font$italic]),
							$mdgriffith$elm_ui$Element$text('Filter by:')),
							$author$project$Page$PagesCommon$helpAnchor('smHaViewStudentsFilterByText')
						])) : A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$paddingEach(
							{er: 5, e9: 0, fz: 0, fY: 0})
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$Font$bold, $mdgriffith$elm_ui$Element$Font$italic]),
							$mdgriffith$elm_ui$Element$text('Filter by:')),
							$author$project$Page$PagesCommon$helpAnchor('smHaEditStudentsFilterByText')
						]))) : $mdgriffith$elm_ui$Element$none,
					A2(
					$mdgriffith$elm_ui$Element$wrappedRow,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(
							A2($mdgriffith$elm_ui$Element$minimum, 1080, $mdgriffith$elm_ui$Element$fill)),
							$mdgriffith$elm_ui$Element$spacing(10)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$fillPortion(2))
								]),
							_List_fromArray(
								[
									isShowCohortFilter ? A4($author$project$Page$PagesCommon$filterYearLvlButtons, true, $author$project$Page$ManageUsers$SetYearLvlFilter, filterModel, cohorts) : $mdgriffith$elm_ui$Element$none
								])),
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$fillPortion(1))
								]),
							_List_fromArray(
								[
									isShowGroupFilter ? A2($author$project$Page$ManageUsers$groupFilterField, filterModel.b6, filterModel.dA) : $mdgriffith$elm_ui$Element$none
								])),
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$fillPortion(2))
								]),
							_List_fromArray(
								[
									(!pageMode) ? A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$alignRight]),
									A2($author$project$Page$ManageUsers$filteredListActionButtons, entries, filterModel)) : (isNapAdmin ? A2($author$project$Page$ManageUsers$emailsFilterField, filterModel.cK, filterModel.dA) : $mdgriffith$elm_ui$Element$none)
								]))
						]))
				]));
	});
var $author$project$Type$ExamResult$makeNbrAttempts = F2(
	function (activityDatas, serrs) {
		var totalNbrAttempts = $elm$core$List$length(serrs);
		var isAssess = function (activityResultRecord) {
			var assessIds = A2(
				$elm$core$List$map,
				function ($) {
					return $.lW;
				},
				A2(
					$elm$core$List$filter,
					function (el) {
						return !el.cx.kL;
					},
					activityDatas));
			return A2($elm$core$List$member, activityResultRecord.jz, assessIds);
		};
		var activities = $author$project$Type$ExamResult$getActivityResultRecords(serrs);
		var nbrActivities = $elm$core$List$length(activities);
		var nbrAssess = (!nbrActivities) ? 0 : $elm$core$List$length(
			A2($elm$core$List$filter, isAssess, activities));
		return {mQ: nbrAssess, mR: totalNbrAttempts - nbrActivities, mS: nbrActivities - nbrAssess};
	});
var $author$project$Type$AppCfg$naplanStyleExamsByLevel = function (appCfg) {
	var isNaplanStyleQGroup = function (qGroup) {
		return !$author$project$Type$Testlet$isLegacyQGroupId(qGroup.lW);
	};
	return $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (lvl) {
				return _Utils_Tuple2(
					lvl,
					A2(
						$elm$core$List$sortBy,
						function ($) {
							return $.nj;
						},
						A2(
							$elm$core$List$filter,
							isNaplanStyleQGroup,
							A2(
								$elm$core$List$filter,
								function (qGroup) {
									return _Utils_eq(
										qGroup.hB,
										$author$project$Type$AppCfg$lvlForNapStyleExams(lvl));
								},
								appCfg.nH))));
			},
			A2(
				$elm$core$List$map,
				$author$project$Type$Cohort$toInt,
				A2(
					$elm$core$List$filter,
					function (cohort) {
						return !_Utils_eq(cohort, $author$project$Type$Cohort$MS);
					},
					$author$project$Type$Cohort$cohorts))));
};
var $author$project$Page$ManageUsers$setAllIsCheckedBtn = function (isAllVisibleEntriesSelected) {
	var _v0 = isAllVisibleEntriesSelected ? _Utils_Tuple2('Deselect all', false) : _Utils_Tuple2('Select all visible', true);
	var btnText = _v0.a;
	var isChecked = _v0.b;
	return A2(
		$author$project$Page$PagesCommon$regButton,
		true,
		{
			bD: A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				$mdgriffith$elm_ui$Element$text(btnText)),
			ai: $elm$core$Maybe$Just(
				$author$project$Page$ManageUsers$SetAllIsChecked(isChecked))
		});
};
var $author$project$Page$ManageUsers$makeStudentTable = F4(
	function (model, userType, isAllVisibleEntriesSelected, entries_) {
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				var pageMode = model.bJ;
				var naplanStyleExamsIdsByLevel = A2(
					$elm$core$Dict$map,
					F2(
						function (_v3, exams) {
							return A2(
								$elm$core$List$map,
								function ($) {
									return $.lW;
								},
								exams);
						}),
					$author$project$Type$AppCfg$naplanStyleExamsByLevel(rState.j$));
				var isEntriesHasGroupTags = A2(
					$elm$core$List$any,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.lI;
						},
						$elm$core$Basics$neq($elm$core$Maybe$Nothing)),
					entries_);
				var cohorts = $author$project$Page$ManageUsers$getUniqueCohorts(entries_);
				var checkedBtnList = (pageMode === 1) ? _List_fromArray(
					[
						$author$project$Page$ManageUsers$setAllIsCheckedBtn(isAllVisibleEntriesSelected)
					]) : _List_Nil;
				var activitiesIdsByLevel = A2(
					$elm$core$Dict$map,
					F2(
						function (_v2, activities) {
							return A2(
								$elm$core$List$map,
								function ($) {
									return $.lW;
								},
								activities);
						}),
					$author$project$Type$NodeCfg$activitiesByLevel(rState.mX));
				var entries = A2(
					$elm$core$List$map,
					function (el) {
						var validQGroupsIdsForCohort = A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							A2(
								$elm$core$Dict$get,
								$author$project$Type$Cohort$toInt(el.kA),
								naplanStyleExamsIdsByLevel));
						var validActivitiesIdsForCohort = A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							A2(
								$elm$core$Dict$get,
								$author$project$Type$Cohort$toInt(el.kA),
								activitiesIdsByLevel));
						var isCurrentValidSerr = function (serr) {
							if (!serr.$) {
								var tgrr = serr.a;
								return A2($elm$core$List$member, tgrr.nF, validQGroupsIdsForCohort);
							} else {
								var arr = serr.a;
								var _v1 = arr.jA;
								if (!_v1.$) {
									return A2($elm$core$List$member, arr.jz, validActivitiesIdsForCohort);
								} else {
									return false;
								}
							}
						};
						var studentHstryFilteredToCurrentCohort = A2($elm$core$List$filter, isCurrentValidSerr, el.lU);
						return _Utils_update(
							el,
							{
								lU: studentHstryFilteredToCurrentCohort,
								dL: $elm$core$Maybe$Just(
									A2(
										$author$project$Type$ExamResult$makeNbrAttempts,
										$author$project$Type$NodeCfg$toActivityDatas(rState.mX.fs),
										studentHstryFilteredToCurrentCohort))
							});
					},
					A2($author$project$Page$ManageUsers$applyFilters, model, entries_));
				return A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$alignTop,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$paddingEach(
									{er: 10, e9: 0, fz: 0, fY: 20})
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$Font$bold, $mdgriffith$elm_ui$Element$Font$italic]),
											$mdgriffith$elm_ui$Element$text(
												$author$project$Routing$userRouteTypeCapPlural(userType)))
										]))
								])),
							A6(
							$author$project$Page$ManageUsers$filterArea,
							$author$project$Type$ReaderConfig$isNapAdmin(rState),
							pageMode,
							entries,
							model,
							cohorts,
							isEntriesHasGroupTags),
							A2($mdgriffith$elm_ui$Element$row, _List_Nil, checkedBtnList),
							$elm$core$List$isEmpty(entries) ? A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$paddingEach(
									{er: 0, e9: 0, fz: 0, fY: 20})
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('No students')
								])) : A2(
							$mdgriffith$elm_ui$Element$table,
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Element$paddingXY, 0, 20)
								]),
							{
								kC: A5(
									$author$project$Page$ManageUsers$columnsForStudentTable,
									$author$project$Type$User$isTeacherEAdminE(rState.fc),
									rState.mX,
									rState.j$,
									model,
									$author$project$Page$ManageUsers$getUniqueCohorts(entries)),
								k_: entries
							})
						]));
			});
	});
var $author$project$Page$ManageUsers$makeTeacherRecord = function (user) {
	if ((!user.$) && (user.a.$ === 1)) {
		if (!user.a.a.$) {
			var id = user.a.a.a.lW;
			var fNam = user.a.a.a.lt;
			var lNam = user.a.a.a.mq;
			var email = user.a.a.a.li;
			return {li: email, lt: fNam, lW: id, dE: true, mq: lNam, d$: 'n/a'};
		} else {
			var id = user.a.a.a.lW;
			var fNam = user.a.a.a.lt;
			var lNam = user.a.a.a.mq;
			var email = user.a.a.a.li;
			return {li: email, lt: fNam, lW: id, dE: false, mq: lNam, d$: 'n/a'};
		}
	} else {
		return {li: 'fail', lt: 'fail', lW: 'fail', dE: false, mq: 'fail', d$: 'fail'};
	}
};
var $author$project$Page$ManageUsers$columnsTeacherSpecific = _List_fromArray(
	[
		{
		dB: A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5),
					$mdgriffith$elm_ui$Element$Border$solid,
					$mdgriffith$elm_ui$Element$Border$widthEach(
					{er: 1, e9: 0, fz: 0, fY: 0})
				]),
			$mdgriffith$elm_ui$Element$text('Admin?')),
		pM: function (el_) {
			return $author$project$Page$ManageUsers$makeEntryRow(
				$mdgriffith$elm_ui$Element$text(
					el_.dE ? 'Y' : 'N'));
		},
		ec: $mdgriffith$elm_ui$Element$fill
	}
	]);
var $author$project$Page$ManageUsers$columnsForTeacherTable = $elm$core$List$concat(
	_List_fromArray(
		[
			$author$project$Page$ManageUsers$columnsFirstLastEmail(false),
			$author$project$Page$ManageUsers$columnsTeacherSpecific,
			$author$project$Page$ManageUsers$columnsAction
		]));
var $author$project$Page$ManageUsers$makeTeacherTable = F2(
	function (userType, entries) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$alignTop,
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$paddingEach(
							{er: 0, e9: 0, fz: 0, fY: 20}),
							$mdgriffith$elm_ui$Element$Font$bold,
							$mdgriffith$elm_ui$Element$Font$italic
						]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text(
							$author$project$Routing$userRouteTypeCapPlural(userType))
						])),
					A2(
					$mdgriffith$elm_ui$Element$table,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 0, 20)
						]),
					{kC: $author$project$Page$ManageUsers$columnsForTeacherTable, k_: entries})
				]));
	});
var $author$project$Page$ManageUsers$ManageStudents = 2;
var $author$project$Page$ManageUsers$ManageTeachers = 1;
var $author$project$Page$ManageUsers$ViewStudents = 0;
var $author$project$Page$ManageUsers$makeTabButton = F3(
	function (path, txt, isSelected) {
		var msgMaybe = isSelected ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
			$author$project$Page$ManageUsers$LinkClicked(
				$author$project$Routing$withHash(path)));
		return A2(
			$mdgriffith$elm_ui$Element$Input$button,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Element$paddingXY, 20, 18),
					$mdgriffith$elm_ui$Element$Border$roundEach(
					{de: 0, df: 0, d8: 10, d9: 10}),
					$mdgriffith$elm_ui$Element$Background$color(
					isSelected ? $author$project$Theme$Style$t.oK : $author$project$Theme$Style$t.oN),
					$mdgriffith$elm_ui$Element$mouseOver(
					isSelected ? _List_Nil : _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.oM)
						]))
				]),
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$Font$color(
							isSelected ? $author$project$Theme$Style$t.oL : $author$project$Theme$Style$t.oO)
						]),
					A2(
						$mdgriffith$elm_ui$Element$row,
						_List_Nil,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text(txt),
								isSelected ? $author$project$Page$PagesCommon$helpAnchor('smHaTab') : $mdgriffith$elm_ui$Element$none
							]))),
				ai: msgMaybe
			});
	});
var $author$project$Page$ManageUsers$pageHeading = F3(
	function (isShowManageTeachersTab, pageMode, userType) {
		var tabsNeeded = isShowManageTeachersTab ? _List_fromArray(
			[0, 1, 2]) : _List_fromArray(
			[0, 2]);
		var selectedTab = (!pageMode) ? 0 : ((userType === 1) ? 1 : 2);
		var isSelected = function (t) {
			return _Utils_eq(t, selectedTab);
		};
		var makeTabButtonFor = function (tab) {
			switch (tab) {
				case 0:
					return A3(
						$author$project$Page$ManageUsers$makeTabButton,
						$author$project$Routing$smPaths.pN,
						'View Students',
						isSelected(tab));
				case 1:
					return A3(
						$author$project$Page$ManageUsers$makeTabButton,
						$author$project$Routing$smPaths.gY + '/Teacher',
						'Manage Teachers',
						isSelected(tab));
				default:
					return A3(
						$author$project$Page$ManageUsers$makeTabButton,
						$author$project$Routing$smPaths.gY + '/Student',
						'Manage Students',
						isSelected(tab));
			}
		};
		var btns = A2($elm$core$List$map, makeTabButtonFor, tabsNeeded);
		return A2($mdgriffith$elm_ui$Element$row, _List_Nil, btns);
	});
var $author$project$Page$ManageUsers$GetStudentsByTchId = function (a) {
	return {$: 11, a: a};
};
var $krisajenkins$remotedata$RemoteData$isLoading = function (data) {
	if (data.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Page$ManageUsers$refreshUsersButton = F2(
	function (users, teachers) {
		var checkedTeacherIds = function () {
			if (teachers.$ === 3) {
				var teachers_ = teachers.a;
				return A2(
					$elm$core$List$map,
					function ($) {
						return $.lW;
					},
					A2(
						$elm$core$List$map,
						$author$project$Type$User$extractPersonEDetailsFromTeacherE,
						$author$project$Page$ManageUsers$getIsCheckedTeachers(teachers_)));
			} else {
				return _List_Nil;
			}
		}();
		var isActive = ($elm$core$List$length(checkedTeacherIds) > 0) && (!$krisajenkins$remotedata$RemoteData$isLoading(users));
		return A2(
			$author$project$Page$PagesCommon$regButton,
			isActive,
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]),
					$mdgriffith$elm_ui$Element$text('Retrieve students')),
				ai: isActive ? $elm$core$Maybe$Just(
					$author$project$Page$ManageUsers$GetStudentsByTchId(checkedTeacherIds)) : $elm$core$Maybe$Nothing
			});
	});
var $author$project$Page$ManageUsers$TeacherChecked = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$Page$ManageUsers$makeTeacherCheckbox = function (augmentedTeacherERecord) {
	var tDetail = $author$project$Type$User$extractPersonEDetailsFromTeacherE(augmentedTeacherERecord.bN);
	return A2(
		$mdgriffith$elm_ui$Element$Input$checkbox,
		_List_Nil,
		{
			gr: augmentedTeacherERecord.W,
			hl: $mdgriffith$elm_ui$Element$Input$defaultCheckbox,
			bD: A2(
				$mdgriffith$elm_ui$Element$Input$labelRight,
				_List_Nil,
				$mdgriffith$elm_ui$Element$text(tDetail.lt + (' ' + tDetail.mq))),
			cS: $author$project$Page$ManageUsers$TeacherChecked(tDetail.lW)
		});
};
var $author$project$Page$ManageUsers$teachersList = function (augmentedTeacherERecords) {
	return A2(
		$mdgriffith$elm_ui$Element$wrappedRow,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$spacing(20),
				$mdgriffith$elm_ui$Element$paddingEach(
				{er: 5, e9: 10, fz: 10, fY: 15})
			]),
		A2($elm$core$List$map, $author$project$Page$ManageUsers$makeTeacherCheckbox, augmentedTeacherERecords));
};
var $author$project$Page$PagesCommon$versionElement = function (version) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$alignRight,
				$mdgriffith$elm_ui$Element$Font$size(14),
				$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.nm)
			]),
		$mdgriffith$elm_ui$Element$text('v' + version));
};
var $author$project$Page$ManageUsers$workArea = F2(
	function (mdl, userType) {
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				var usersArea = function () {
					var makeTextRow = function (el_) {
						return A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$padding(20)
								]),
							el_);
					};
					var _v3 = mdl.S;
					switch (_v3.$) {
						case 3:
							var users_ = _v3.a;
							if ($elm$core$List$length(users_) < 1) {
								return makeTextRow(
									$mdgriffith$elm_ui$Element$text(
										'No ' + ($author$project$Routing$userRouteTypeLowerPlural(userType) + ' were found.')));
							} else {
								if (userType === 1) {
									return A2(
										$author$project$Page$ManageUsers$makeTeacherTable,
										userType,
										A2(
											$elm$core$List$sortBy,
											function ($) {
												return $.mq;
											},
											A2($elm$core$List$map, $author$project$Page$ManageUsers$makeTeacherRecord, users_)));
								} else {
									var students = A2(
										$elm$core$List$sortBy,
										function ($) {
											return $.mq;
										},
										A2($elm$core$List$map, $author$project$Page$ManageUsers$makeStudentRecord, users_));
									var isAllVisibleEntriesSelected = A2(
										$elm$core$List$all,
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.W;
											},
											$elm$core$Basics$eq(true)),
										A2($author$project$Page$ManageUsers$applyFilters, mdl, students));
									return A2(
										$Punie$elm_reader$Reader$run,
										A4($author$project$Page$ManageUsers$makeStudentTable, mdl, userType, isAllVisibleEntriesSelected, students),
										rState);
								}
							}
						case 0:
							return makeTextRow(
								A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_Nil,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text(
											'Please refresh this page, since no request has yet been made for the list of ' + $author$project$Routing$userRouteTypeLowerPlural(userType))
										])));
						case 1:
							return makeTextRow(
								$mdgriffith$elm_ui$Element$text(
									'Loading ' + $author$project$Routing$userRouteTypeLowerPlural(userType)));
						default:
							return makeTextRow(
								$mdgriffith$elm_ui$Element$text(
									'Failed to load ' + $author$project$Routing$userRouteTypeLowerPlural(userType)));
					}
				}();
				var teachersArea = A2(
					$mdgriffith$elm_ui$Element$column,
					_List_Nil,
					_List_fromArray(
						[
							function () {
							if ($author$project$Type$User$isTeacherEAdminE(rState.fc) && A3(
								$krisajenkins$remotedata$RemoteData$unwrap,
								false,
								A2(
									$elm$core$Basics$composeR,
									$elm$core$List$length,
									$elm$core$Basics$lt(1)),
								mdl.aa)) {
								var _v0 = mdl.aa;
								switch (_v0.$) {
									case 0:
										return $mdgriffith$elm_ui$Element$text('No request has yet been made to retrieve the list of teachers');
									case 1:
										return $mdgriffith$elm_ui$Element$text('Loading');
									case 2:
										return $mdgriffith$elm_ui$Element$text('Failure');
									default:
										var teachers = _v0.a;
										return A2(
											$mdgriffith$elm_ui$Element$row,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$paddingEach(
													{er: 15, e9: 0, fz: 0, fY: 0})
												]),
											_List_fromArray(
												[
													A2(
													$mdgriffith$elm_ui$Element$row,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
															$mdgriffith$elm_ui$Element$padding(15),
															$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.gA),
															$mdgriffith$elm_ui$Element$Border$rounded(10)
														]),
													_List_fromArray(
														[
															A2(
															$mdgriffith$elm_ui$Element$column,
															_List_fromArray(
																[
																	$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
																]),
															_List_fromArray(
																[
																	A2(
																	$mdgriffith$elm_ui$Element$row,
																	_List_fromArray(
																		[
																			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
																			$mdgriffith$elm_ui$Element$spacing(10),
																			$mdgriffith$elm_ui$Element$paddingEach(
																			{er: 5, e9: 0, fz: 0, fY: 0}),
																			$mdgriffith$elm_ui$Element$Border$dashed,
																			$mdgriffith$elm_ui$Element$Border$widthEach(
																			{er: 1, e9: 0, fz: 0, fY: 0})
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$mdgriffith$elm_ui$Element$el,
																			_List_fromArray(
																				[
																					$mdgriffith$elm_ui$Element$Font$italic,
																					$mdgriffith$elm_ui$Element$paddingEach(
																					{er: 0, e9: 0, fz: 10, fY: 0})
																				]),
																			$mdgriffith$elm_ui$Element$text('Teachers')),
																			function () {
																			var _v1 = (!A2(
																				$elm$core$List$all,
																				function (el) {
																					return el.W;
																				},
																				teachers)) ? _Utils_Tuple2(
																				true,
																				$elm$core$Maybe$Just($author$project$Page$ManageUsers$CheckAllTeachers)) : _Utils_Tuple2(false, $elm$core$Maybe$Nothing);
																			var isActive = _v1.a;
																			var action = _v1.b;
																			return A2(
																				$author$project$Page$PagesCommon$thinButton,
																				isActive,
																				{
																					bD: A2(
																						$mdgriffith$elm_ui$Element$el,
																						_List_fromArray(
																							[$mdgriffith$elm_ui$Element$centerX]),
																						$mdgriffith$elm_ui$Element$text('Check all')),
																					ai: action
																				});
																		}(),
																			function () {
																			var _v2 = A2(
																				$elm$core$List$any,
																				function (el) {
																					return el.W;
																				},
																				teachers) ? _Utils_Tuple2(
																				true,
																				$elm$core$Maybe$Just($author$project$Page$ManageUsers$CheckNoneTeachers)) : _Utils_Tuple2(false, $elm$core$Maybe$Nothing);
																			var isActive = _v2.a;
																			var action = _v2.b;
																			return A2(
																				$author$project$Page$PagesCommon$thinButton,
																				isActive,
																				{
																					bD: A2(
																						$mdgriffith$elm_ui$Element$el,
																						_List_fromArray(
																							[$mdgriffith$elm_ui$Element$centerX]),
																						$mdgriffith$elm_ui$Element$text('Check none')),
																					ai: action
																				});
																		}()
																		])),
																	A2(
																	$mdgriffith$elm_ui$Element$row,
																	_List_fromArray(
																		[
																			$mdgriffith$elm_ui$Element$spacing(10)
																		]),
																	_List_fromArray(
																		[
																			$author$project$Page$ManageUsers$teachersList(teachers)
																		])),
																	A2($author$project$Page$ManageUsers$refreshUsersButton, mdl.S, mdl.aa)
																]))
														]))
												]));
								}
							} else {
								return $mdgriffith$elm_ui$Element$none;
							}
						}()
						]));
				return A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 0, 60),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$fillPortion(1))
								]),
							_List_Nil),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$fillPortion(6))
								]),
							_List_fromArray(
								[
									A3(
									$author$project$Page$ManageUsers$pageHeading,
									$author$project$Type$User$isTeacherEAdminE(rState.fc),
									mdl.bJ,
									userType),
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
											A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
											$mdgriffith$elm_ui$Element$Border$roundEach(
											{de: 10, df: 10, d8: 0, d9: 10}),
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									_List_fromArray(
										[
											function () {
											if (!userType) {
												var actions = (!mdl.bJ) ? _List_Nil : _List_fromArray(
													[
														A2($author$project$Page$ManageUsers$actionsArea, mdl, userType)
													]);
												return A2(
													$mdgriffith$elm_ui$Element$column,
													_List_Nil,
													$elm$core$List$concat(
														_List_fromArray(
															[
																_List_fromArray(
																[teachersArea]),
																actions
															])));
											} else {
												return A2(
													$mdgriffith$elm_ui$Element$column,
													_List_Nil,
													_List_fromArray(
														[
															A2($author$project$Page$ManageUsers$actionsArea, mdl, userType)
														]));
											}
										}(),
											usersArea,
											$author$project$Page$ManageUsers$actionBtns
										])),
									$author$project$Page$PagesCommon$versionElement(rState.lx.pL)
								])),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$fillPortion(1))
								]),
							_List_Nil)
						]));
			});
	});
var $author$project$Page$ManageUsers$view = F4(
	function (_v0, model, topBarFn, userType) {
		var addTopBar = function (wArea) {
			return A2(
				$Punie$elm_reader$Reader$map,
				function (tBar) {
					return _List_fromArray(
						[tBar, wArea]);
				},
				A2(topBarFn, $author$project$Page$ManageUsers$SignOut, $author$project$Page$ManageUsers$LinkClicked));
		};
		return A3(
			$Punie$elm_reader$Reader$map2,
			F2(
				function (color, elements) {
					return A2(
						$mdgriffith$elm_ui$Element$layout,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Background$color(color)
							]),
						A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							elements));
				}),
			$author$project$Page$PagesCommon$makeBackgroundStyle,
			A2(
				$Punie$elm_reader$Reader$andThen,
				addTopBar,
				A2($author$project$Page$ManageUsers$workArea, model, userType)));
	});
var $author$project$Page$Mjd5$LinkClicked = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Mjd5$SignOut = {$: 0};
var $author$project$Page$SignInPagesCommon$hr = A2(
	$mdgriffith$elm_ui$Element$column,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
			A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10)
		]),
	_List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Border$widthEach(
					{er: 0, e9: 0, fz: 0, fY: 1})
				]),
			_List_Nil)
		]));
var $author$project$Page$Mjd5$GetPost = {$: 7};
var $author$project$Page$Mjd5$TestAction = {$: 10};
var $author$project$Page$Mjd5$linksSection = function (_v0) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 15),
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$spacing(5),
				$mdgriffith$elm_ui$Element$Border$dashed,
				$mdgriffith$elm_ui$Element$Border$widthEach(
				{er: 1, e9: 0, fz: 0, fY: 1})
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$Font$italic]),
				$mdgriffith$elm_ui$Element$text('Links Section')),
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(10)
					]),
				_List_fromArray(
					[
						A2(
						$author$project$Page$PagesCommon$regButton,
						true,
						{
							bD: A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX]),
								$mdgriffith$elm_ui$Element$text('Profile')),
							ai: $elm$core$Maybe$Just(
								$author$project$Page$Mjd5$LinkClicked(
									$author$project$Routing$withHash($author$project$Routing$smPaths.nC)))
						}),
						A2(
						$author$project$Page$PagesCommon$regButton,
						true,
						{
							bD: A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX]),
								$mdgriffith$elm_ui$Element$text('View Students')),
							ai: $elm$core$Maybe$Just(
								$author$project$Page$Mjd5$LinkClicked(
									$author$project$Routing$withHash($author$project$Routing$smPaths.pN)))
						}),
						A2(
						$author$project$Page$PagesCommon$regButton,
						true,
						{
							bD: A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX]),
								$mdgriffith$elm_ui$Element$text('Manage Schools')),
							ai: $elm$core$Maybe$Just(
								$author$project$Page$Mjd5$LinkClicked(
									$author$project$Routing$withHash($author$project$Routing$smPaths.mx)))
						}),
						A2(
						$author$project$Page$PagesCommon$regButton,
						true,
						{
							bD: A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX]),
								$mdgriffith$elm_ui$Element$text('Manage Teachers')),
							ai: $elm$core$Maybe$Just(
								$author$project$Page$Mjd5$LinkClicked(
									$author$project$Routing$withHash($author$project$Routing$smPaths.gY + '/Teacher')))
						}),
						A2(
						$author$project$Page$PagesCommon$regButton,
						true,
						{
							bD: A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX]),
								$mdgriffith$elm_ui$Element$text('Manage Students')),
							ai: $elm$core$Maybe$Just(
								$author$project$Page$Mjd5$LinkClicked(
									$author$project$Routing$withHash($author$project$Routing$smPaths.gY + '/Student')))
						}),
						A2(
						$author$project$Page$PagesCommon$regButton,
						true,
						{
							bD: A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX]),
								$mdgriffith$elm_ui$Element$text('Get Post')),
							ai: $elm$core$Maybe$Just($author$project$Page$Mjd5$GetPost)
						}),
						A2(
						$author$project$Page$PagesCommon$regButton,
						true,
						{
							bD: A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$centerX]),
								$mdgriffith$elm_ui$Element$text('Test Action')),
							ai: $elm$core$Maybe$Just($author$project$Page$Mjd5$TestAction)
						})
					]))
			]));
};
var $mdgriffith$elm_ui$Element$map = $mdgriffith$elm_ui$Internal$Model$map;
var $author$project$Page$Mjd5$Me = {$: 3};
var $author$project$Page$Mjd5$Me20 = {$: 4};
var $author$project$Page$Mjd5$ResetTests = {$: 1};
var $author$project$Page$Mjd5$RunTests = {$: 2};
var $author$project$Page$Mjd5$signInSection = F3(
	function (_v0, me, isNapAdmin) {
		var napAdminStatus = isNapAdmin ? '[*** NapAdmin user ***]: ' : '';
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					A2($mdgriffith$elm_ui$Element$paddingXY, 0, 15),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$spacing(5),
					$mdgriffith$elm_ui$Element$Border$dashed,
					$mdgriffith$elm_ui$Element$Border$widthEach(
					{er: 1, e9: 0, fz: 0, fY: 1})
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$Font$italic]),
					$mdgriffith$elm_ui$Element$text('SignIn Section')),
					A2(
					$mdgriffith$elm_ui$Element$wrappedRow,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(10)
						]),
					_List_fromArray(
						[
							A2(
							$author$project$Page$PagesCommon$regButton,
							true,
							{
								bD: A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX]),
									$mdgriffith$elm_ui$Element$text('Sign In')),
								ai: $elm$core$Maybe$Just(
									$author$project$Page$Mjd5$LinkClicked(
										$author$project$Routing$withHash($author$project$Routing$smPaths.om)))
							}),
							A2(
							$author$project$Page$PagesCommon$regButton,
							true,
							{
								bD: A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX]),
									$mdgriffith$elm_ui$Element$text('Sign Out')),
								ai: $elm$core$Maybe$Just($author$project$Page$Mjd5$SignOut)
							}),
							A2(
							$author$project$Page$PagesCommon$regButton,
							true,
							{
								bD: A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX]),
									$mdgriffith$elm_ui$Element$text('Manually call Me')),
								ai: $elm$core$Maybe$Just($author$project$Page$Mjd5$Me)
							}),
							A2(
							$author$project$Page$PagesCommon$regButton,
							true,
							{
								bD: A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX]),
									$mdgriffith$elm_ui$Element$text('Manually call Me20')),
								ai: $elm$core$Maybe$Just($author$project$Page$Mjd5$Me20)
							}),
							A2(
							$author$project$Page$PagesCommon$regButton,
							true,
							{
								bD: A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX]),
									$mdgriffith$elm_ui$Element$text('RawDataTBD')),
								ai: $elm$core$Maybe$Just(
									$author$project$Page$Mjd5$LinkClicked(
										$author$project$Routing$withHash($author$project$Routing$smPaths.nK)))
							}),
							A2(
							$author$project$Page$PagesCommon$regButton,
							true,
							{
								bD: A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX]),
									$mdgriffith$elm_ui$Element$text('ResetTests')),
								ai: $elm$core$Maybe$Just($author$project$Page$Mjd5$ResetTests)
							}),
							A2(
							$author$project$Page$PagesCommon$regButton,
							true,
							{
								bD: A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX]),
									$mdgriffith$elm_ui$Element$text('RunTests (SetSyllabusToMjdTestFirst)')),
								ai: $elm$core$Maybe$Just($author$project$Page$Mjd5$RunTests)
							})
						])),
					function () {
					var _v1 = function () {
						var personEDetails = $author$project$Type$User$extractPersonEDetails(me);
						if (!me.$) {
							return _Utils_Tuple2('Student: ', personEDetails);
						} else {
							if (!me.a.$) {
								return _Utils_Tuple2('Admin Teacher: ', personEDetails);
							} else {
								return _Utils_Tuple2('NonAdmin Teacher: ', personEDetails);
							}
						}
					}();
					var userType = _v1.a;
					var personDetails = _v1.b;
					return $mdgriffith$elm_ui$Element$text(
						_Utils_ap(
							napAdminStatus,
							_Utils_ap(
								userType,
								$elm$core$String$concat(
									A2(
										$elm$core$List$intersperse,
										', ',
										_List_fromArray(
											[personDetails.lt, personDetails.mq, personDetails.li]))))));
				}()
				]));
	});
var $author$project$UITool$Calculator$AddA = 3;
var $author$project$UITool$Calculator$Character = function (a) {
	return {$: 0, a: a};
};
var $author$project$UITool$Calculator$Clear = {$: 3};
var $author$project$UITool$Calculator$DigitA = function (a) {
	return {$: 0, a: a};
};
var $author$project$UITool$Calculator$DivideA = 0;
var $author$project$UITool$Calculator$Dot = {$: 2};
var $author$project$UITool$Calculator$Equal = {$: 1};
var $author$project$UITool$Calculator$MinusA = 2;
var $author$project$UITool$Calculator$Operation = function (a) {
	return {$: 1, a: a};
};
var $author$project$UITool$Calculator$ParenLeft = 4;
var $author$project$UITool$Calculator$ParenRight = 5;
var $author$project$UITool$Calculator$TimesA = 1;
var $author$project$UITool$Calculator$Toggle = {$: 5};
var $author$project$UITool$Calculator$Zero = {$: 4};
var $mdgriffith$elm_ui$Element$Font$alignRight = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$fontAlignment, $mdgriffith$elm_ui$Internal$Style$classes.o4);
var $author$project$UITool$Calculator$colorNearlyWhite = A3($mdgriffith$elm_ui$Element$rgb, 0.95, 0.95, 0.95);
var $author$project$UITool$Calculator$darkBlue = A3($mdgriffith$elm_ui$Element$rgb255, 0, 92, 145);
var $author$project$UITool$Calculator$lightBlue = A3($mdgriffith$elm_ui$Element$rgb255, 200, 230, 255);
var $author$project$UITool$Calculator$calculatorButtonConfigurable = F4(
	function (widthPx, msg, elAttrs, buttonText) {
		var btnColor = function () {
			_v0$2:
			while (true) {
				switch (msg.$) {
					case 0:
						if (!msg.a.$) {
							return $author$project$UITool$Calculator$lightBlue;
						} else {
							break _v0$2;
						}
					case 4:
						return $author$project$UITool$Calculator$lightBlue;
					default:
						break _v0$2;
				}
			}
			return $author$project$UITool$Calculator$colorNearlyWhite;
		}();
		return A2(
			$mdgriffith$elm_ui$Element$el,
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color($author$project$UITool$Calculator$darkBlue)
					]),
				elAttrs),
			A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color(btnColor),
						$mdgriffith$elm_ui$Element$Border$color(
						A3($mdgriffith$elm_ui$Element$rgb, 0.8, 0.8, 0.8)),
						$mdgriffith$elm_ui$Element$Border$solid,
						$mdgriffith$elm_ui$Element$Border$width(1),
						$mdgriffith$elm_ui$Element$width(
						$mdgriffith$elm_ui$Element$px(widthPx)),
						$mdgriffith$elm_ui$Element$height(
						$mdgriffith$elm_ui$Element$px(60)),
						$mdgriffith$elm_ui$Element$Border$rounded(5),
						$mdgriffith$elm_ui$Element$Font$size(22)
					]),
				{
					bD: A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$centerX]),
						$mdgriffith$elm_ui$Element$text(buttonText)),
					ai: $elm$core$Maybe$Just(msg)
				}));
	});
var $author$project$UITool$Calculator$calculatorButton = $author$project$UITool$Calculator$calculatorButtonConfigurable(60);
var $author$project$UITool$Calculator$calculatorButtonDoubleWide = $author$project$UITool$Calculator$calculatorButtonConfigurable(130);
var $author$project$UITool$Calculator$midBlue = A3($mdgriffith$elm_ui$Element$rgb255, 70, 140, 210);
var $zaboco$elm_draggable$Draggable$alwaysPreventDefaultAndStopPropagation = function (msg) {
	return {hG: msg, ft: true, fP: true};
};
var $zaboco$elm_draggable$Internal$StartDragging = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $zaboco$elm_draggable$Draggable$baseDecoder = function (key) {
	return A2(
		$elm$json$Json$Decode$map,
		A2(
			$elm$core$Basics$composeL,
			$elm$core$Basics$identity,
			$zaboco$elm_draggable$Internal$StartDragging(key)),
		$zaboco$elm_draggable$Draggable$positionDecoder);
};
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $zaboco$elm_draggable$Draggable$whenLeftMouseButtonPressed = function (decoder) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (button) {
			if (!button) {
				return decoder;
			} else {
				return $elm$json$Json$Decode$fail('Event is only relevant when the main mouse button was pressed.');
			}
		},
		A2($elm$json$Json$Decode$field, 'button', $elm$json$Json$Decode$int));
};
var $zaboco$elm_draggable$Draggable$mouseTrigger = F2(
	function (key, envelope) {
		return A2(
			$elm$html$Html$Events$custom,
			'mousedown',
			A2(
				$elm$json$Json$Decode$map,
				A2($elm$core$Basics$composeL, $zaboco$elm_draggable$Draggable$alwaysPreventDefaultAndStopPropagation, envelope),
				$zaboco$elm_draggable$Draggable$whenLeftMouseButtonPressed(
					$zaboco$elm_draggable$Draggable$baseDecoder(key))));
	});
var $mdgriffith$elm_ui$Element$scrollbars = A2($mdgriffith$elm_ui$Internal$Model$Class, $mdgriffith$elm_ui$Internal$Flag$overflow, $mdgriffith$elm_ui$Internal$Style$classes.n7);
var $author$project$UITool$Calculator$view = function (model) {
	var transformForCalculation = function (input) {
		return A3(
			$elm$core$String$replace,
			'-',
			'−',
			A3(
				$elm$core$String$replace,
				'*',
				'x',
				A3($elm$core$String$replace, '/', '÷', input)));
	};
	var padRight = $mdgriffith$elm_ui$Element$paddingEach(
		{er: 0, e9: 0, fz: 10, fY: 0});
	var closeButton = A2(
		$mdgriffith$elm_ui$Element$Input$button,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Background$color($author$project$UITool$Calculator$midBlue),
				$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$Border$roundEach(
				{de: 0, df: 5, d8: 0, d9: 5})
			]),
		{
			bD: A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Element$paddingXY, 2, 2),
						$mdgriffith$elm_ui$Element$Font$color(
						A3($mdgriffith$elm_ui$Element$rgb, 0.9, 0.9, 0.9))
					]),
				$mdgriffith$elm_ui$Element$text('X ')),
			ai: $elm$core$Maybe$Just($author$project$UITool$Calculator$Toggle)
		});
	var attrs = A2(
		$elm$core$List$map,
		$mdgriffith$elm_ui$Element$htmlAttribute,
		A2(
			$elm$core$List$append,
			model.a6 ? _List_Nil : _List_fromArray(
				[
					A2($elm$html$Html$Attributes$style, 'display', 'none')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$Attributes$style,
					'top',
					$elm$core$String$fromInt(model.bb.b) + 'px'),
					A2(
					$elm$html$Html$Attributes$style,
					'left',
					$elm$core$String$fromInt(model.bb.a) + 'px'),
					A2($elm$html$Html$Attributes$style, 'position', 'absolute'),
					A2($elm$html$Html$Attributes$style, 'z-index', '2000'),
					A2($zaboco$elm_draggable$Draggable$mouseTrigger, 'sm-calculator', $author$project$UITool$Calculator$DragMsg)
				])));
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$Page$PagesCommon$thinButton,
				true,
				{
					bD: $mdgriffith$elm_ui$Element$text(
						model.a6 ? 'Hide Calculator' : 'Show Calculator'),
					ai: $elm$core$Maybe$Just($author$project$UITool$Calculator$Toggle)
				}),
				A2(
				$mdgriffith$elm_ui$Element$column,
				A2(
					$elm$core$List$append,
					attrs,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Border$solid,
							$mdgriffith$elm_ui$Element$Border$width(15),
							$mdgriffith$elm_ui$Element$Border$rounded(15),
							$mdgriffith$elm_ui$Element$Border$color($author$project$UITool$Calculator$darkBlue),
							$mdgriffith$elm_ui$Element$Background$color($author$project$UITool$Calculator$darkBlue)
						])),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$spacing(10)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$row,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$px(25)),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$Border$roundEach(
												{de: 5, df: 0, d8: 5, d9: 0}),
												$mdgriffith$elm_ui$Element$Background$color($author$project$UITool$Calculator$midBlue)
											]),
										A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$centerY,
													$mdgriffith$elm_ui$Element$Font$color(
													A3($mdgriffith$elm_ui$Element$rgb, 0.9, 0.9, 0.9)),
													$mdgriffith$elm_ui$Element$Font$size(12),
													$mdgriffith$elm_ui$Element$Font$center,
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
												]),
											$mdgriffith$elm_ui$Element$text('--- Drag to move ---'))),
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$alignRight,
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
											]),
										closeButton)
									])),
								A2(
								$mdgriffith$elm_ui$Element$row,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$height(
										$mdgriffith$elm_ui$Element$px(60)),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width(
												A2($mdgriffith$elm_ui$Element$maximum, 270, $mdgriffith$elm_ui$Element$fill)),
												$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$Border$rounded(5),
												$mdgriffith$elm_ui$Element$Background$color(
												A3($mdgriffith$elm_ui$Element$rgb, 1, 1, 1)),
												$mdgriffith$elm_ui$Element$Border$color(
												A3($mdgriffith$elm_ui$Element$rgb, 0.8, 0.8, 0.8)),
												$mdgriffith$elm_ui$Element$Border$solid,
												$mdgriffith$elm_ui$Element$Border$width(1)
											]),
										A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$Font$size(22),
													$mdgriffith$elm_ui$Element$centerY,
													$mdgriffith$elm_ui$Element$Font$alignRight,
													$mdgriffith$elm_ui$Element$padding(5),
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
													$mdgriffith$elm_ui$Element$scrollbars
												]),
											$mdgriffith$elm_ui$Element$text(
												transformForCalculation(model.r))))
									]))
							])),
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$paddingEach(
								{er: 5, e9: 0, fz: 0, fY: 10})
							]),
						_List_fromArray(
							[
								A3(
								$author$project$UITool$Calculator$calculatorButton,
								$author$project$UITool$Calculator$Clear,
								_List_fromArray(
									[padRight]),
								'C'),
								A3(
								$author$project$UITool$Calculator$calculatorButton,
								$author$project$UITool$Calculator$Character(
									$author$project$UITool$Calculator$Operation(4)),
								_List_fromArray(
									[padRight]),
								'('),
								A3(
								$author$project$UITool$Calculator$calculatorButton,
								$author$project$UITool$Calculator$Character(
									$author$project$UITool$Calculator$Operation(5)),
								_List_fromArray(
									[padRight]),
								')'),
								A3(
								$author$project$UITool$Calculator$calculatorButton,
								$author$project$UITool$Calculator$Character(
									$author$project$UITool$Calculator$Operation(0)),
								_List_Nil,
								'÷')
							])),
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5)
							]),
						_List_fromArray(
							[
								A3(
								$author$project$UITool$Calculator$calculatorButton,
								$author$project$UITool$Calculator$Character(
									$author$project$UITool$Calculator$DigitA('7')),
								_List_fromArray(
									[padRight]),
								'7'),
								A3(
								$author$project$UITool$Calculator$calculatorButton,
								$author$project$UITool$Calculator$Character(
									$author$project$UITool$Calculator$DigitA('8')),
								_List_fromArray(
									[padRight]),
								'8'),
								A3(
								$author$project$UITool$Calculator$calculatorButton,
								$author$project$UITool$Calculator$Character(
									$author$project$UITool$Calculator$DigitA('9')),
								_List_fromArray(
									[padRight]),
								'9'),
								A3(
								$author$project$UITool$Calculator$calculatorButton,
								$author$project$UITool$Calculator$Character(
									$author$project$UITool$Calculator$Operation(1)),
								_List_Nil,
								'x')
							])),
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5)
							]),
						_List_fromArray(
							[
								A3(
								$author$project$UITool$Calculator$calculatorButton,
								$author$project$UITool$Calculator$Character(
									$author$project$UITool$Calculator$DigitA('4')),
								_List_fromArray(
									[padRight]),
								'4'),
								A3(
								$author$project$UITool$Calculator$calculatorButton,
								$author$project$UITool$Calculator$Character(
									$author$project$UITool$Calculator$DigitA('5')),
								_List_fromArray(
									[padRight]),
								'5'),
								A3(
								$author$project$UITool$Calculator$calculatorButton,
								$author$project$UITool$Calculator$Character(
									$author$project$UITool$Calculator$DigitA('6')),
								_List_fromArray(
									[padRight]),
								'6'),
								A3(
								$author$project$UITool$Calculator$calculatorButton,
								$author$project$UITool$Calculator$Character(
									$author$project$UITool$Calculator$Operation(2)),
								_List_Nil,
								'−')
							])),
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5)
							]),
						_List_fromArray(
							[
								A3(
								$author$project$UITool$Calculator$calculatorButton,
								$author$project$UITool$Calculator$Character(
									$author$project$UITool$Calculator$DigitA('1')),
								_List_fromArray(
									[padRight]),
								'1'),
								A3(
								$author$project$UITool$Calculator$calculatorButton,
								$author$project$UITool$Calculator$Character(
									$author$project$UITool$Calculator$DigitA('2')),
								_List_fromArray(
									[padRight]),
								'2'),
								A3(
								$author$project$UITool$Calculator$calculatorButton,
								$author$project$UITool$Calculator$Character(
									$author$project$UITool$Calculator$DigitA('3')),
								_List_fromArray(
									[padRight]),
								'3'),
								A3(
								$author$project$UITool$Calculator$calculatorButton,
								$author$project$UITool$Calculator$Character(
									$author$project$UITool$Calculator$Operation(3)),
								_List_Nil,
								'+')
							])),
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5)
							]),
						_List_fromArray(
							[
								A3(
								$author$project$UITool$Calculator$calculatorButtonDoubleWide,
								$author$project$UITool$Calculator$Zero,
								_List_fromArray(
									[padRight]),
								'0'),
								A3(
								$author$project$UITool$Calculator$calculatorButton,
								$author$project$UITool$Calculator$Dot,
								_List_fromArray(
									[padRight]),
								'.'),
								A3($author$project$UITool$Calculator$calculatorButton, $author$project$UITool$Calculator$Equal, _List_Nil, '=')
							]))
					]))
			]));
};
var $author$project$Page$Mjd5$workArea = function (mdl) {
	return $Punie$elm_reader$Reader$asks(
		function (rState) {
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 0, 60),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$fillPortion(1))
									]),
								_List_Nil),
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$fillPortion(6)),
										$mdgriffith$elm_ui$Element$spacing(30)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$row,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.nn),
														$mdgriffith$elm_ui$Element$Font$size(30),
														$mdgriffith$elm_ui$Element$Font$bold
													]),
												$mdgriffith$elm_ui$Element$text('Mjd5: Behind the scenes!')),
												A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$Font$family(
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$Font$typeface('FontAwesome')
															])),
														$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$white),
														$mdgriffith$elm_ui$Element$Font$size(30),
														A2($mdgriffith$elm_ui$Element$paddingXY, 5, 0)
													]),
												$mdgriffith$elm_ui$Element$text('\uF059'))
											])),
										A2(
										$mdgriffith$elm_ui$Element$column,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
												A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
												$mdgriffith$elm_ui$Element$Border$rounded(10),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
											]),
										_List_fromArray(
											[
												A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[$mdgriffith$elm_ui$Element$alignRight]),
												A2(
													$mdgriffith$elm_ui$Element$map,
													$author$project$Page$Mjd5$MsgToolCalc,
													$author$project$UITool$Calculator$view(mdl.je))),
												A3(
												$author$project$Page$Mjd5$signInSection,
												mdl,
												rState.fc,
												$author$project$Type$ReaderConfig$isNapAdmin(rState)),
												$author$project$Page$Mjd5$linksSection(mdl)
											])),
										A2(
										$mdgriffith$elm_ui$Element$column,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
												A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
												$mdgriffith$elm_ui$Element$Border$rounded(10),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
											]),
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text('Local output content area'),
												$mdgriffith$elm_ui$Element$text(
												A3(
													$elm$core$List$foldl,
													$elm$core$Basics$append,
													'',
													A2($elm$core$List$intersperse, '\n', mdl.fq)))
											])),
										A2(
										$mdgriffith$elm_ui$Element$column,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
												A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
												$mdgriffith$elm_ui$Element$Border$rounded(10),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
											]),
										_List_fromArray(
											[
												A2(
												$mdgriffith$elm_ui$Element$el,
												_List_Nil,
												$mdgriffith$elm_ui$Element$text('Test Suite (If many fails with \'Test failed to return a value\' then check localLookupNodeIdForTitleMaybe)')),
												$author$project$Page$SignInPagesCommon$hr
											]))
									])),
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$fillPortion(1))
									]),
								_List_Nil)
							]))
					]));
		});
};
var $author$project$Page$Mjd5$view = F2(
	function (model, topBarFn) {
		var addToElements = F2(
			function (el, els) {
				return A2(
					$Punie$elm_reader$Reader$map,
					function (el2) {
						return A2($elm$core$List$cons, el2, els);
					},
					el);
			});
		return A3(
			$Punie$elm_reader$Reader$map2,
			F2(
				function (color, els) {
					return A2(
						$mdgriffith$elm_ui$Element$layout,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$size(16),
								$mdgriffith$elm_ui$Element$Background$color(color)
							]),
						A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
								]),
							els));
				}),
			$author$project$Page$PagesCommon$makeBackgroundStyle,
			A2(
				$Punie$elm_reader$Reader$andThen,
				addToElements(
					A2(topBarFn, $author$project$Page$Mjd5$SignOut, $author$project$Page$Mjd5$LinkClicked)),
				A2(
					$Punie$elm_reader$Reader$andThen,
					addToElements(
						$author$project$Page$Mjd5$workArea(model)),
					$Punie$elm_reader$Reader$reader(_List_Nil))));
	});
var $author$project$Page$MutateUsers$LinkClicked = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$MutateUsers$SignOut = {$: 13};
var $author$project$Page$MutateUsers$AssignedTeacher = 1;
var $author$project$Routing$Capitalized = 0;
var $author$project$Page$MutateUsers$GroupTag = 0;
var $author$project$Page$MutateUsers$SetAssignedTeacher = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$MutateUsers$SetCohort = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$MutateUsers$YearLvl = 2;
var $author$project$Page$MutateUsers$Close = {$: 4};
var $author$project$Page$PagesCommon$dangerButton = F2(
	function (isActive, btnConfig) {
		return A6(
			$author$project$Page$PagesCommon$configurableButton,
			isActive,
			$author$project$Theme$Style$t.kX,
			$author$project$Theme$Style$t.kY,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$paddingEach(
					{er: 5, e9: 0, fz: 0, fY: 15})
				]),
			_List_Nil,
			btnConfig);
	});
var $author$project$Page$MutateUsers$actionButton = F2(
	function (mdl, userType) {
		var isUpdateReady = (!mdl.bJ) || ((mdl.bJ === 1) && A2(
			$elm$core$List$any,
			$elm$core$Basics$identity,
			_List_fromArray(
				[mdl.a4, mdl.aT, mdl.a3])));
		var btnAttrs = function () {
			var _v2 = mdl.o;
			if (!_v2) {
				return _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.jH)
					]);
			} else {
				return _List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]);
			}
		}();
		var _v0 = function () {
			var _v1 = mdl.o;
			switch (_v1) {
				case 0:
					return _Utils_Tuple3(
						$author$project$Page$PagesCommon$dangerButton,
						_Utils_ap(
							(!mdl.bJ) ? 'Delete ' : 'Update ',
							A3($author$project$Routing$userTypeString, 1, mdl.F, userType)),
						isUpdateReady ? $elm$core$Maybe$Just($author$project$Page$MutateUsers$MutateUsers) : $elm$core$Maybe$Nothing);
				case 1:
					return _Utils_Tuple3($author$project$Page$PagesCommon$regButton, 'N/A', $elm$core$Maybe$Nothing);
				default:
					return _Utils_Tuple3(
						$author$project$Page$PagesCommon$regButton,
						'Close',
						$elm$core$Maybe$Just($author$project$Page$MutateUsers$Close));
			}
		}();
		var btnType = _v0.a;
		var btnText = _v0.b;
		var btnAction = _v0.c;
		return A3(
			$author$project$Page$PagesCommon$restrictUsage,
			btnType,
			isUpdateReady,
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$el,
					btnAttrs,
					$mdgriffith$elm_ui$Element$text(btnText)),
				ai: btnAction
			});
	});
var $author$project$Page$MutateUsers$Cancel = {$: 3};
var $author$project$Page$MutateUsers$cancelButton = function (mdl) {
	return A2(
		$author$project$Page$PagesCommon$regButton,
		true,
		{
			bD: A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				$mdgriffith$elm_ui$Element$text('Cancel')),
			ai: $elm$core$Maybe$Just($author$project$Page$MutateUsers$Cancel)
		});
};
var $author$project$Page$MutateUsers$extractMutationStates = function (personEsWithMutationState) {
	return A2(
		$elm$core$List$map,
		function ($) {
			return $.bG;
		},
		personEsWithMutationState);
};
var $author$project$Page$MutateUsers$fieldAsText = function (field) {
	switch (field) {
		case 0:
			return 'Group';
		case 1:
			return 'Teacher';
		default:
			return 'Year Level';
	}
};
var $author$project$Page$MutateUsers$fieldCurrentState = F2(
	function (mdl, field) {
		switch (field) {
			case 0:
				return mdl.a4;
			case 1:
				return mdl.a3;
			default:
				return mdl.aT;
		}
	});
var $author$project$Page$MutateUsers$ToggleAssignedTeacherToChange = {$: 9};
var $author$project$Page$MutateUsers$ToggleCohortToChange = {$: 8};
var $author$project$Page$MutateUsers$ToggleGroupTagToChange = {$: 7};
var $author$project$Page$MutateUsers$fieldToggleMsg = function (field) {
	switch (field) {
		case 0:
			return $author$project$Page$MutateUsers$ToggleGroupTagToChange;
		case 1:
			return $author$project$Page$MutateUsers$ToggleAssignedTeacherToChange;
		default:
			return $author$project$Page$MutateUsers$ToggleCohortToChange;
	}
};
var $author$project$Page$MutateUsers$fieldChangeButton = F2(
	function (mdl, field) {
		var btnText = A2($author$project$Page$MutateUsers$fieldCurrentState, mdl, field) ? 'Undo' : ('Set ' + $author$project$Page$MutateUsers$fieldAsText(field));
		return A6(
			$author$project$Page$PagesCommon$configurableButton,
			true,
			$author$project$Theme$Style$t.f6,
			$author$project$Theme$Style$t.jG,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$paddingEach(
					{er: 0, e9: 0, fz: 0, fY: 5})
				]),
			_List_Nil,
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]),
					$mdgriffith$elm_ui$Element$text(btnText)),
				ai: $elm$core$Maybe$Just(
					$author$project$Page$MutateUsers$fieldToggleMsg(field))
			});
	});
var $author$project$Page$MutateUsers$SetGroupTag = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$MutateUsers$groupTagField = function (mdl) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				A2($mdgriffith$elm_ui$Element$maximum, 400, $mdgriffith$elm_ui$Element$fill)),
				$mdgriffith$elm_ui$Element$paddingEach(
				{er: 0, e9: 0, fz: 0, fY: 5})
			]),
		A2(
			$mdgriffith$elm_ui$Element$Input$text,
			_List_Nil,
			{
				bD: $mdgriffith$elm_ui$Element$Input$labelHidden('Group'),
				cS: $author$project$Page$MutateUsers$SetGroupTag,
				cV: mdl.eU,
				cY: A2($elm$core$Maybe$withDefault, '', mdl.cM)
			}));
};
var $author$project$Page$MutateUsers$isMutationStateSuccess = function (mutationState) {
	if ((mutationState.$ === 2) && (!mutationState.a.$)) {
		return true;
	} else {
		return false;
	}
};
var $author$project$JsInterop$Types$makeShortErrorMsg = function (orig) {
	var strLen = 31;
	return (_Utils_cmp(
		$elm$core$String$length(orig),
		strLen) < 0) ? orig : A2(
		$elm$core$String$append,
		A2($elm$core$String$left, strLen, orig),
		'...[full message below]');
};
var $author$project$JsInterop$Types$msgForUserToShortString = A2($elm$core$Basics$composeR, $author$project$JsInterop$Types$msgForUserToString, $author$project$JsInterop$Types$makeShortErrorMsg);
var $author$project$Page$MutateUsers$workArea = F2(
	function (mdl, userType) {
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				var updateSpecificFields = function () {
					var assignedTeacherId = A2(
						$elm$core$Maybe$withDefault,
						'none',
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Type$User$extractPersonEDetailsFromTeacherE,
								function ($) {
									return $.lW;
								}),
							mdl.a0));
					return ((mdl.bJ === 1) && (!mdl.o)) ? _List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(15),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$padding(15),
									$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.gA),
									$mdgriffith$elm_ui$Element$Border$rounded(10)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$Font$bold, $mdgriffith$elm_ui$Element$Font$italic]),
									$mdgriffith$elm_ui$Element$text('Update(s) to be applied:')),
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_Nil,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text(
											$author$project$Page$MutateUsers$fieldAsText(2)),
											A2(
											$mdgriffith$elm_ui$Element$row,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$spacing(15)
												]),
											_List_fromArray(
												[
													A2($author$project$Page$MutateUsers$fieldChangeButton, mdl, 2),
													mdl.aT ? A2(
													$mdgriffith$elm_ui$Element$row,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$paddingEach(
															{er: 0, e9: 0, fz: 0, fY: 5})
														]),
													_List_fromArray(
														[
															A4(
															$author$project$Page$PagesCommon$makeCohortRadios,
															_List_fromArray(
																[
																	$mdgriffith$elm_ui$Element$paddingEach(
																	{er: 0, e9: 0, fz: 0, fY: 0}),
																	$mdgriffith$elm_ui$Element$spacing(30)
																]),
															$elm$core$Maybe$Just('CAUTION: Results history for all students below will be ERASED if you set the year level.'),
															$elm$core$Maybe$Just(mdl.kA),
															$author$project$Page$MutateUsers$SetCohort)
														])) : $mdgriffith$elm_ui$Element$none
												]))
										])),
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_Nil,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text(
											$author$project$Page$MutateUsers$fieldAsText(1)),
											A2(
											$mdgriffith$elm_ui$Element$row,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$spacing(15)
												]),
											_List_fromArray(
												[
													A2($author$project$Page$MutateUsers$fieldChangeButton, mdl, 1),
													mdl.a3 ? A2(
													$mdgriffith$elm_ui$Element$row,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$paddingEach(
															{er: 0, e9: 0, fz: 0, fY: 5})
														]),
													_List_fromArray(
														[
															A4($author$project$Page$PagesCommon$makeTeacherDropdownCustomizable, $mdgriffith$elm_ui$Element$none, assignedTeacherId, $author$project$Page$MutateUsers$SetAssignedTeacher, mdl.cU)
														])) : $mdgriffith$elm_ui$Element$none
												]))
										])),
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_Nil,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text(
											$author$project$Page$MutateUsers$fieldAsText(0)),
											A2(
											$mdgriffith$elm_ui$Element$row,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$spacing(15)
												]),
											_List_fromArray(
												[
													A2($author$project$Page$MutateUsers$fieldChangeButton, mdl, 0),
													mdl.a4 ? $author$project$Page$MutateUsers$groupTagField(mdl) : $mdgriffith$elm_ui$Element$none
												]))
										]))
								]))
						]) : _List_Nil;
				}();
				var pageOpString = (!mdl.bJ) ? 'Delete' : 'Update';
				var usersDisplayBlock = function () {
					var iconTick = $mdgriffith$elm_ui$Element$html(
						A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('icon')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fa fa-check')
										]),
									_List_Nil)
								])));
					var iconSpinner = $mdgriffith$elm_ui$Element$html(
						A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('icon')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fa fa-spinner fa-spin')
										]),
									_List_Nil)
								])));
					var iconInfo = function (txt) {
						return $mdgriffith$elm_ui$Element$html(
							A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('icon has-tooltip-danger'),
										A2($elm$html$Html$Attributes$attribute, 'data-tooltip', txt)
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('fa fa-info-circle')
											]),
										_List_Nil)
									])));
					};
					var userInputRows = function () {
						var makeMutationStateRow = function (mutationState) {
							var makeErrRow = function (err) {
								return A2(
									$mdgriffith$elm_ui$Element$row,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$paddingXY, 0, 3),
											$mdgriffith$elm_ui$Element$Border$dashed,
											$mdgriffith$elm_ui$Element$Border$widthEach(
											{er: 1, e9: 0, fz: 0, fY: 0})
										]),
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$centerX,
													$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.gy)
												]),
											iconInfo(
												$author$project$JsInterop$Types$msgForUserToShortString(err.hG)))
										]));
							};
							switch (mutationState.$) {
								case 0:
									return A2(
										$mdgriffith$elm_ui$Element$row,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Element$paddingXY, 0, 3),
												$mdgriffith$elm_ui$Element$Border$dashed,
												$mdgriffith$elm_ui$Element$Border$widthEach(
												{er: 1, e9: 0, fz: 0, fY: 0})
											]),
										_List_fromArray(
											[
												A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$centerX,
														$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.eB)
													]),
												iconSpinner)
											]));
								case 1:
									return A2(
										$mdgriffith$elm_ui$Element$row,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Element$paddingXY, 0, 3),
												$mdgriffith$elm_ui$Element$Border$dashed,
												$mdgriffith$elm_ui$Element$Border$widthEach(
												{er: 1, e9: 0, fz: 0, fY: 0})
											]),
										_List_fromArray(
											[
												A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$centerX,
														$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.eB)
													]),
												iconSpinner)
											]));
								default:
									if (!mutationState.a.$) {
										return A2(
											$mdgriffith$elm_ui$Element$row,
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Element$paddingXY, 0, 3),
													$mdgriffith$elm_ui$Element$Border$dashed,
													$mdgriffith$elm_ui$Element$Border$widthEach(
													{er: 1, e9: 0, fz: 0, fY: 0})
												]),
											_List_fromArray(
												[
													A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$centerX,
															$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.gz)
														]),
													iconTick)
												]));
									} else {
										if (!mutationState.a.b.$) {
											var _v4 = mutationState.a;
											var err = _v4.b.a;
											return makeErrRow(err);
										} else {
											var _v5 = mutationState.a;
											var err = _v5.b.a;
											return makeErrRow(err);
										}
									}
							}
						};
						var makeEntryRow = function (txt) {
							return A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5),
										$mdgriffith$elm_ui$Element$Border$dashed,
										$mdgriffith$elm_ui$Element$Border$widthEach(
										{er: 1, e9: 0, fz: 0, fY: 0})
									]),
								$mdgriffith$elm_ui$Element$text(txt));
						};
						var entries = A2(
							$elm$core$List$sortBy,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.a9;
								},
								A2(
									$elm$core$Basics$composeR,
									$author$project$Type$User$extractPersonEDetails,
									function ($) {
										return $.mq;
									})),
							mdl.F);
						var columns_ = _List_fromArray(
							[
								{
								dB: A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5),
											$mdgriffith$elm_ui$Element$Border$solid,
											$mdgriffith$elm_ui$Element$Border$widthEach(
											{er: 1, e9: 0, fz: 0, fY: 0})
										]),
									$mdgriffith$elm_ui$Element$text('First Name(s)')),
								pM: function (el_) {
									return makeEntryRow(
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.a9;
											},
											A2(
												$elm$core$Basics$composeR,
												$author$project$Type$User$extractPersonEDetails,
												function ($) {
													return $.lt;
												}))(el_));
								},
								ec: $mdgriffith$elm_ui$Element$fill
							},
								{
								dB: A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5),
											$mdgriffith$elm_ui$Element$Border$solid,
											$mdgriffith$elm_ui$Element$Border$widthEach(
											{er: 1, e9: 0, fz: 0, fY: 0})
										]),
									$mdgriffith$elm_ui$Element$text('Last Name(s)')),
								pM: function (el_) {
									return makeEntryRow(
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.a9;
											},
											A2(
												$elm$core$Basics$composeR,
												$author$project$Type$User$extractPersonEDetails,
												function ($) {
													return $.mq;
												}))(el_));
								},
								ec: $mdgriffith$elm_ui$Element$fill
							},
								{
								dB: A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$paddingXY, 0, 5),
											$mdgriffith$elm_ui$Element$Border$solid,
											$mdgriffith$elm_ui$Element$Border$widthEach(
											{er: 1, e9: 0, fz: 0, fY: 0})
										]),
									$mdgriffith$elm_ui$Element$text('Email(s)')),
								pM: function (el_) {
									return makeEntryRow(
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.a9;
											},
											A2(
												$elm$core$Basics$composeR,
												$author$project$Type$User$extractPersonEDetails,
												function ($) {
													return $.li;
												}))(el_));
								},
								ec: $mdgriffith$elm_ui$Element$fill
							}
							]);
						var columns = ((mdl.o === 1) || (mdl.o === 2)) ? A2(
							$elm$core$List$append,
							_List_fromArray(
								[
									{
									dB: A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Element$paddingXY, 5, 5),
												$mdgriffith$elm_ui$Element$Border$solid,
												$mdgriffith$elm_ui$Element$Border$widthEach(
												{er: 1, e9: 0, fz: 0, fY: 0})
											]),
										$mdgriffith$elm_ui$Element$text('Status')),
									pM: function (el_) {
										return makeMutationStateRow(el_.bG);
									},
									ec: $mdgriffith$elm_ui$Element$shrink
								}
								]),
							columns_) : columns_;
						if ($elm$core$List$isEmpty(entries)) {
							return A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$padding(20)
									]),
								$mdgriffith$elm_ui$Element$text('No user(s) have been selected for deletion.  Please cancel and try again.'));
						} else {
							var uTypeString = A3($author$project$Routing$userTypeString, 0, mdl.F, userType);
							var tableHeading = (!mdl.o) ? (uTypeString + (' to be ' + ($elm$core$String$toLower(pageOpString) + 'd:'))) : (uTypeString + (' ' + ($elm$core$String$toLower(pageOpString) + ' status:')));
							return A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$alignTop,
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$row,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$paddingEach(
												{er: 0, e9: 0, fz: 0, fY: 20}),
												$mdgriffith$elm_ui$Element$Font$bold,
												$mdgriffith$elm_ui$Element$Font$italic
											]),
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text(tableHeading)
											])),
										A2(
										$mdgriffith$elm_ui$Element$table,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Element$paddingXY, 0, 20)
											]),
										{kC: columns, k_: entries})
									]));
						}
					}();
					var userRows_ = _List_fromArray(
						[userInputRows]);
					var userRows = _List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$spacing(10)
								]),
							userRows_)
						]);
					var iconCross = $mdgriffith$elm_ui$Element$html(
						A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('icon')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fa fa-times')
										]),
									_List_Nil)
								])));
					return userRows;
				}();
				var mutationResults = function () {
					if (mdl.o === 2) {
						if (A2(
							$elm$core$List$all,
							$author$project$Page$MutateUsers$isMutationStateSuccess,
							$author$project$Page$MutateUsers$extractMutationStates(mdl.F))) {
							return _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text(
									A3($author$project$Routing$userTypeString, 0, mdl.F, userType) + (' successfully ' + ($elm$core$String$toLower(pageOpString) + 'd.')))
								]);
						} else {
							var errors = A2(
								$elm$core$List$map,
								function (el) {
									if ((el.$ === 2) && (el.a.$ === 1)) {
										var _v1 = el.a;
										var id = _v1.a;
										var failure = _v1.b;
										var makeErrRow = function (err) {
											return A2(
												$mdgriffith$elm_ui$Element$column,
												_List_fromArray(
													[
														A2($mdgriffith$elm_ui$Element$paddingXY, 0, 6)
													]),
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$html(
														A2(
															$elm$html$Html$ul,
															_List_Nil,
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$li,
																	_List_Nil,
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$strong,
																			_List_Nil,
																			_List_fromArray(
																				[
																					$elm$html$Html$text(err.gt + ': ')
																				]))
																		])),
																	A2(
																	$elm$html$Html$li,
																	_List_Nil,
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$u,
																			_List_Nil,
																			_List_fromArray(
																				[
																					$elm$html$Html$text(
																					$author$project$JsInterop$Types$msgForUserToString(err.hG))
																				]))
																		])),
																	A2(
																	$elm$html$Html$li,
																	_List_Nil,
																	_List_fromArray(
																		[
																			A2(
																			$elm$html$Html$em,
																			_List_Nil,
																			_List_fromArray(
																				[
																					$elm$html$Html$text(err.gU)
																				]))
																		]))
																])))
													]));
										};
										if (!failure.$) {
											var err_ = failure.a;
											return makeErrRow(err_);
										} else {
											var err_ = failure.a;
											return makeErrRow(err_);
										}
									} else {
										return $mdgriffith$elm_ui$Element$text('Formatting error');
									}
								},
								A2(
									$elm$core$List$filter,
									A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Page$MutateUsers$isMutationStateSuccess),
									$author$project$Page$MutateUsers$extractMutationStates(mdl.F)));
							return _List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10),
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$padding(20),
												$mdgriffith$elm_ui$Element$Border$rounded(10),
												$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.eC),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
											]),
										A2(
											$mdgriffith$elm_ui$Element$column,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$spacing(8),
													$mdgriffith$elm_ui$Element$Font$size(20)
												]),
											_List_fromArray(
												[
													A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$Font$italic,
															$mdgriffith$elm_ui$Element$Font$underline,
															$mdgriffith$elm_ui$Element$paddingEach(
															{er: 10, e9: 0, fz: 0, fY: 0})
														]),
													$mdgriffith$elm_ui$Element$text('Error details:')),
													A2(
													$mdgriffith$elm_ui$Element$paragraph,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$Font$size(18)
														]),
													errors)
												]))))
								]);
						}
					} else {
						return _List_Nil;
					}
				}();
				var msgRow = (mdl.o === 1) ? _List_fromArray(
					[
						A2(
						$author$project$Page$SignInPagesCommon$msgAreaWithColor,
						$author$project$Theme$Style$t.eC,
						$elm$core$Maybe$Just(
							A2($elm$core$String$dropRight, 1, pageOpString) + 'ing users ... please wait.'))
					]) : _List_fromArray(
					[
						A2($author$project$Page$SignInPagesCommon$msgAreaWithColor, mdl.dQ.a, mdl.dQ.b)
					]);
				var instructions = ((!mdl.o) && (!mdl.bJ)) ? _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$padding(20),
									$mdgriffith$elm_ui$Element$Border$rounded(10),
									$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.gA),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$spacing(8)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_Nil,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text(
												'The ' + (A3($author$project$Routing$userTypeString, 1, mdl.F, userType) + (' listed below will be permanently and irrecoverably ' + ($elm$core$String$toLower(pageOpString) + ('d if you click the \'' + (pageOpString + (' ' + (A3($author$project$Routing$userTypeString, 1, mdl.F, userType) + '\' button below.'))))))))
											]))
									]))))
					]) : _List_Nil;
				var actionBtns = $elm$core$List$isEmpty(mdl.F) ? _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(10),
								$mdgriffith$elm_ui$Element$centerY
							]),
						_List_fromArray(
							[
								$author$project$Page$MutateUsers$cancelButton(mdl)
							]))
					]) : ((mdl.o === 1) ? _List_Nil : ((mdl.o === 2) ? _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(10),
								$mdgriffith$elm_ui$Element$centerY
							]),
						_List_fromArray(
							[
								A2(
								$Punie$elm_reader$Reader$run,
								A2($author$project$Page$MutateUsers$actionButton, mdl, userType),
								rState)
							]))
					]) : _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(10),
								$mdgriffith$elm_ui$Element$centerY
							]),
						_List_fromArray(
							[
								$author$project$Page$MutateUsers$cancelButton(mdl),
								A2(
								$Punie$elm_reader$Reader$run,
								A2($author$project$Page$MutateUsers$actionButton, mdl, userType),
								rState)
							]))
					])));
				return A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							A2($mdgriffith$elm_ui$Element$paddingXY, 0, 60),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$fillPortion(1))
								]),
							_List_Nil),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$fillPortion(6)),
									$mdgriffith$elm_ui$Element$spacing(30)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$row,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.nn),
													$mdgriffith$elm_ui$Element$Font$size(30),
													$mdgriffith$elm_ui$Element$Font$bold
												]),
											$mdgriffith$elm_ui$Element$text(
												pageOpString + (' ' + A3($author$project$Routing$userTypeString, 1, mdl.F, userType)))),
											A2($author$project$Page$PagesCommon$helpAnchorWithColor, 'smHaPageHeading', $author$project$Theme$Style$t.lN)
										])),
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
											A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
											$mdgriffith$elm_ui$Element$Border$rounded(10),
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									$elm$core$List$concat(
										_List_fromArray(
											[instructions, updateSpecificFields, usersDisplayBlock, msgRow, mutationResults, actionBtns])))
								])),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$fillPortion(1))
								]),
							_List_Nil)
						]));
			});
	});
var $author$project$Page$MutateUsers$view = F3(
	function (model, topBarFn, userType) {
		var makeWorkArea = F2(
			function (color, elements) {
				return A2(
					$mdgriffith$elm_ui$Element$layout,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color(color)
						]),
					A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						elements));
			});
		var addTopBar = function (wArea2) {
			return A2(
				$Punie$elm_reader$Reader$map,
				function (tBar3) {
					return _List_fromArray(
						[tBar3, wArea2]);
				},
				A2(topBarFn, $author$project$Page$MutateUsers$SignOut, $author$project$Page$MutateUsers$LinkClicked));
		};
		return A3(
			$Punie$elm_reader$Reader$map2,
			makeWorkArea,
			$author$project$Page$PagesCommon$makeBackgroundStyle,
			A2(
				$Punie$elm_reader$Reader$andThen,
				addTopBar,
				A2($author$project$Page$MutateUsers$workArea, model, userType)));
	});
var $author$project$Page$Profile$LinkClicked = function (a) {
	return {$: 17, a: a};
};
var $author$project$Page$Profile$SignOut = {$: 18};
var $author$project$Page$Profile$SetAdministrator = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$Profile$SetAssignedTeacher = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$Profile$assignableTeachersDropdown = F3(
	function (isViewOnly, studentEType, assignableTeachers) {
		return isViewOnly ? $mdgriffith$elm_ui$Element$none : A3($author$project$Page$PagesCommon$makeTeacherDropdown, studentEType.d$, $author$project$Page$Profile$SetAssignedTeacher, assignableTeachers);
	});
var $author$project$Page$Profile$Cancel = {$: 11};
var $author$project$Page$Profile$cancelButton = function (mdl) {
	var cancelText = ($krisajenkins$remotedata$RemoteData$isSuccess(mdl.aK) || $krisajenkins$remotedata$RemoteData$isSuccess(mdl.ay)) ? 'Close' : 'Cancel';
	return A2(
		$author$project$Page$PagesCommon$regButton,
		true,
		{
			bD: A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				$mdgriffith$elm_ui$Element$text(cancelText)),
			ai: $elm$core$Maybe$Just($author$project$Page$Profile$Cancel)
		});
};
var $author$project$Page$Profile$DeleteLoginUser = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Profile$DisplayCannotDeleteTeacher = function (a) {
	return {$: 16, a: a};
};
var $author$project$Page$Profile$Failure = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$Profile$deleteLoginUserButton = function (mdl) {
	if (mdl.bA) {
		return $Punie$elm_reader$Reader$reader($mdgriffith$elm_ui$Element$none);
	} else {
		var nbrStudentsMaybe = A2(
			$elm$core$Maybe$map,
			$elm$core$List$length,
			$krisajenkins$remotedata$RemoteData$toMaybe(mdl.d2));
		var isToDeleteTeacher = A3($krisajenkins$remotedata$RemoteData$unwrap, false, $author$project$Type$User$isTeacherE, mdl.H);
		var userStr = isToDeleteTeacher ? 'teacher' : 'student';
		var interopFailureDetails = A4($author$project$JsInterop$Types$InteropFailureDetails, 'UnableToDeleteUser', 0, 'Apologies but we cannot delete this user as there is a problem with getting this user\'s details', 'No further details');
		var cmd = A3(
			$krisajenkins$remotedata$RemoteData$unwrap,
			$elm$core$Maybe$Just(
				$author$project$Page$Profile$Failure(interopFailureDetails)),
			function (personE) {
				if (isToDeleteTeacher && (!_Utils_eq(
					nbrStudentsMaybe,
					$elm$core$Maybe$Just(0)))) {
					if (!nbrStudentsMaybe.$) {
						var nbrStudents = nbrStudentsMaybe.a;
						return $elm$core$Maybe$Just(
							$author$project$Page$Profile$DisplayCannotDeleteTeacher(nbrStudents));
					} else {
						return $elm$core$Maybe$Just(
							$author$project$Page$Profile$Failure(interopFailureDetails));
					}
				} else {
					return $elm$core$Maybe$Just(
						$author$project$Page$Profile$DeleteLoginUser(personE));
				}
			},
			mdl.H);
		return A3(
			$author$project$Page$PagesCommon$restrictUsage,
			$author$project$Page$PagesCommon$dangerButton,
			true,
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.kZ)
						]),
					$mdgriffith$elm_ui$Element$text('Delete ' + userStr)),
				ai: cmd
			});
	}
};
var $author$project$Page$Profile$SetEmail = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Profile$emailField = F2(
	function (mdl, isFieldEditable) {
		var decorateAttrs = $author$project$Page$SignInPagesCommon$getDecorateAttrsFn(mdl.b3);
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2($mdgriffith$elm_ui$Element$maximum, 500, $mdgriffith$elm_ui$Element$fill)),
					A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10)
				]),
			isFieldEditable ? A2(
				$mdgriffith$elm_ui$Element$Input$text,
				decorateAttrs(_List_Nil),
				{
					bD: A2(
						$mdgriffith$elm_ui$Element$Input$labelAbove,
						_List_Nil,
						A2(
							$mdgriffith$elm_ui$Element$el,
							decorateAttrs(_List_Nil),
							$mdgriffith$elm_ui$Element$text('Email Address'))),
					cS: $author$project$Page$Profile$SetEmail,
					cV: mdl.ds,
					cY: mdl.li
				}) : A2(
				$mdgriffith$elm_ui$Element$column,
				_List_Nil,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('Email: '),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$italic,
								$mdgriffith$elm_ui$Element$Font$bold,
								A2($mdgriffith$elm_ui$Element$paddingXY, 10, 10)
							]),
						$mdgriffith$elm_ui$Element$text(mdl.li))
					])));
	});
var $author$project$Page$Profile$SetFNam = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Profile$fNamField = F2(
	function (mdl, isFieldEditable) {
		var decorateAttrs = $author$project$Page$SignInPagesCommon$getDecorateAttrsFn(mdl.a2);
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2($mdgriffith$elm_ui$Element$maximum, 500, $mdgriffith$elm_ui$Element$fill)),
					A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10)
				]),
			isFieldEditable ? A2(
				$mdgriffith$elm_ui$Element$Input$text,
				decorateAttrs(
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$Input$focusedOnLoad])),
				{
					bD: A2(
						$mdgriffith$elm_ui$Element$Input$labelAbove,
						_List_Nil,
						A2(
							$mdgriffith$elm_ui$Element$el,
							decorateAttrs(_List_Nil),
							$mdgriffith$elm_ui$Element$text('First Name'))),
					cS: $author$project$Page$Profile$SetFNam,
					cV: mdl.eO,
					cY: mdl.lt
				}) : A2(
				$mdgriffith$elm_ui$Element$column,
				_List_Nil,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('First Name: '),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$italic,
								$mdgriffith$elm_ui$Element$Font$bold,
								A2($mdgriffith$elm_ui$Element$paddingXY, 10, 10)
							]),
						$mdgriffith$elm_ui$Element$text(mdl.lt))
					])));
	});
var $author$project$Page$SignInPagesCommon$fieldInfoMsgElement = function (strMaybe) {
	return A2($author$project$Page$SignInPagesCommon$fieldInfoMsgElementWithColor, $author$project$Theme$Style$t.kH, strMaybe);
};
var $author$project$Page$Profile$SetLNam = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Profile$lNamField = F2(
	function (mdl, isFieldEditable) {
		var decorateAttrs = $author$project$Page$SignInPagesCommon$getDecorateAttrsFn(mdl.a7);
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width(
					A2($mdgriffith$elm_ui$Element$maximum, 500, $mdgriffith$elm_ui$Element$fill)),
					A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10)
				]),
			isFieldEditable ? A2(
				$mdgriffith$elm_ui$Element$Input$text,
				decorateAttrs(_List_Nil),
				{
					bD: A2(
						$mdgriffith$elm_ui$Element$Input$labelAbove,
						_List_Nil,
						A2(
							$mdgriffith$elm_ui$Element$el,
							decorateAttrs(_List_Nil),
							$mdgriffith$elm_ui$Element$text('Last Name'))),
					cS: $author$project$Page$Profile$SetLNam,
					cV: mdl.e7,
					cY: mdl.mq
				}) : A2(
				$mdgriffith$elm_ui$Element$column,
				_List_Nil,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('Last Name: '),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$italic,
								$mdgriffith$elm_ui$Element$Font$bold,
								A2($mdgriffith$elm_ui$Element$paddingXY, 10, 10)
							]),
						$mdgriffith$elm_ui$Element$text(mdl.mq))
					])));
	});
var $author$project$Page$Profile$SetNewPassword = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Profile$ToggleIsShowPassword = {$: 9};
var $author$project$Page$Profile$newPasswordField = function (mdl) {
	var showOrHideText = mdl.cN ? 'Hide' : 'Show';
	var decorateAttrs = $author$project$Page$SignInPagesCommon$getDecorateAttrsFn(mdl.cQ);
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				A2($mdgriffith$elm_ui$Element$maximum, 500, $mdgriffith$elm_ui$Element$fill)),
				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10)
			]),
		A2(
			$mdgriffith$elm_ui$Element$Input$newPassword,
			decorateAttrs(
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$Input$focusedOnLoad])),
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_Nil,
					A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								decorateAttrs(_List_Nil),
								$mdgriffith$elm_ui$Element$text('New Password')),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.eB),
										$mdgriffith$elm_ui$Element$Font$underline,
										$mdgriffith$elm_ui$Element$Font$size(16),
										$mdgriffith$elm_ui$Element$alignRight,
										$mdgriffith$elm_ui$Element$Events$onClick($author$project$Page$Profile$ToggleIsShowPassword)
									]),
								$mdgriffith$elm_ui$Element$text(showOrHideText))
							]))),
				cS: $author$project$Page$Profile$SetNewPassword,
				cV: mdl.fk,
				iR: mdl.cN,
				cY: mdl.cf
			}));
};
var $author$project$Page$Profile$SetRepeatPassword = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Profile$repeatPasswordField = function (mdl) {
	var decorateAttrs = $author$project$Page$SignInPagesCommon$getDecorateAttrsFn(mdl.cW);
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width(
				A2($mdgriffith$elm_ui$Element$maximum, 500, $mdgriffith$elm_ui$Element$fill)),
				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10)
			]),
		A2(
			$mdgriffith$elm_ui$Element$Input$newPassword,
			decorateAttrs(
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$Input$focusedOnLoad])),
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_Nil,
					A2(
						$mdgriffith$elm_ui$Element$el,
						decorateAttrs(_List_Nil),
						$mdgriffith$elm_ui$Element$text('Enter New Password Again'))),
				cS: $author$project$Page$Profile$SetRepeatPassword,
				cV: mdl.fy,
				iR: mdl.cN,
				cY: mdl.dU
			}));
};
var $author$project$Page$Profile$successPageMsgArea = _Utils_Tuple2(
	$author$project$Theme$Style$t.eD,
	$elm$core$Maybe$Just('Update successful.'));
var $author$project$Page$Profile$UpdateProfile = {$: 0};
var $author$project$Page$Profile$updateProfileButton = function (mdl) {
	var isNothing = function (el) {
		return _Utils_eq(el, $elm$core$Maybe$Nothing);
	};
	var isFieldChanged = function (field) {
		return A2(
			$krisajenkins$remotedata$RemoteData$withDefault,
			false,
			A3(
				$krisajenkins$remotedata$RemoteData$map2,
				F2(
					function (el1, el2) {
						return !_Utils_eq(
							el1,
							A2(
								$author$project$Page$Profile$fieldAccessor,
								field,
								$author$project$Type$User$extractPersonEDetails(el2)));
					}),
				$krisajenkins$remotedata$RemoteData$succeed(
					A2($author$project$Page$Profile$fieldAccessor, field, mdl)),
				mdl.H));
	};
	var isAnyFieldHasChanged = A2(
		$elm$core$List$any,
		isFieldChanged,
		_List_fromArray(
			[0, 1, 2])) || (((!$elm$core$String$isEmpty(mdl.cf)) && (!$elm$core$String$isEmpty(mdl.dU))) || ((!_Utils_eq(mdl.bx, $elm$core$Maybe$Nothing)) || (!_Utils_eq(mdl.bm, $elm$core$Maybe$Nothing))));
	var _v0 = (isAnyFieldHasChanged && A2(
		$elm$core$List$all,
		isNothing,
		_List_fromArray(
			[mdl.a2, mdl.a7, mdl.b3]))) ? _Utils_Tuple2(
		true,
		$elm$core$Maybe$Just($author$project$Page$Profile$UpdateProfile)) : _Utils_Tuple2(false, $elm$core$Maybe$Nothing);
	var isActive = _v0.a;
	var action = _v0.b;
	return A3(
		$author$project$Page$PagesCommon$restrictUsage,
		$author$project$Page$PagesCommon$regButton,
		isActive,
		{
			bD: A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				$mdgriffith$elm_ui$Element$text('Update')),
			ai: action
		});
};
var $author$project$Page$Profile$workArea = function (mdl) {
	return $Punie$elm_reader$Reader$asks(
		function (rState) {
			var userSpecificFields = function () {
				var _v8 = mdl.H;
				if (_v8.$ === 3) {
					if (!_v8.a.$) {
						var studentEType = _v8.a.a;
						var isViewOnly = mdl.bA;
						return _List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$row,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$spacing(10),
										$mdgriffith$elm_ui$Element$centerY,
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										A3($author$project$Page$Profile$assignableTeachersDropdown, isViewOnly, studentEType, mdl.da),
										$author$project$Page$SignInPagesCommon$fieldInfoMsgElement(mdl.bm)
									]))
							]);
					} else {
						if (mdl.bA) {
							var modifierTxt = $author$project$Type$User$isRemoteMeAnAdminE(mdl.H) ? '' : 'do not ';
							return _List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10),
											$mdgriffith$elm_ui$Element$spacing(5)
										]),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('You ' + (modifierTxt + 'have administrative rights')),
											A2(
											$mdgriffith$elm_ui$Element$paragraph,
											_List_Nil,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$text('(Note: Administrative rights allow one to manage other teachers and other teachers\' students, not just one\'s own.)')
												]))
										]))
								]);
						} else {
							return _List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$row,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$paddingEach(
													{er: 0, e9: 0, fz: 0, fY: 20})
												]),
											_List_fromArray(
												[
													A2(
													$mdgriffith$elm_ui$Element$Input$radioRow,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$padding(10),
															$mdgriffith$elm_ui$Element$spacing(30)
														]),
													{
														bD: A2(
															$mdgriffith$elm_ui$Element$Input$labelAbove,
															_List_Nil,
															$mdgriffith$elm_ui$Element$text('Enable administrative rights for this teacher?')),
														cS: $author$project$Page$Profile$SetAdministrator,
														ni: _List_fromArray(
															[
																A2(
																$mdgriffith$elm_ui$Element$Input$option,
																false,
																$mdgriffith$elm_ui$Element$text('No')),
																A2(
																$mdgriffith$elm_ui$Element$Input$option,
																true,
																$mdgriffith$elm_ui$Element$text('Yes'))
															]),
														ob: $elm$core$Maybe$Just(mdl.dE)
													}),
													$author$project$Page$SignInPagesCommon$fieldInfoMsgElement(mdl.bx)
												])),
											A2(
											$mdgriffith$elm_ui$Element$column,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$paddingEach(
													{er: 10, e9: 0, fz: 0, fY: 0})
												]),
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$text('\'No\' means a teacher can only create/delete/edit their own students.'),
													$mdgriffith$elm_ui$Element$text('\'Yes\' means they can also create/edit/delete teachers and other teachers\' students.')
												]))
										]))
								]);
						}
					}
				} else {
					return _List_Nil;
				}
			}();
			var isUserStudentE = A3($krisajenkins$remotedata$RemoteData$unwrap, false, $author$project$Type$User$isStudentE, mdl.H);
			var isFieldEditable = !(isUserStudentE && mdl.bA);
			var deleteItem = _List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(10),
							$mdgriffith$elm_ui$Element$centerY
						]),
					A2(
						$Punie$elm_reader$Reader$run,
						$author$project$Page$Profile$deleteLoginUserButton(mdl),
						rState))
				]);
			var commonFields = _List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(10),
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							A2($author$project$Page$Profile$fNamField, mdl, isFieldEditable),
							$author$project$Page$SignInPagesCommon$fieldInfoMsgElement(mdl.bs)
						])),
					$author$project$Page$SignInPagesCommon$fieldErrorMsgElement(mdl.a2),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(10),
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							A2($author$project$Page$Profile$lNamField, mdl, isFieldEditable),
							$author$project$Page$SignInPagesCommon$fieldInfoMsgElement(mdl.bC)
						])),
					$author$project$Page$SignInPagesCommon$fieldErrorMsgElement(mdl.a7),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(10),
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							A2($author$project$Page$Profile$emailField, mdl, isFieldEditable),
							$author$project$Page$SignInPagesCommon$fieldInfoMsgElement(mdl.aR)
						])),
					$author$project$Page$SignInPagesCommon$fieldErrorMsgElement(mdl.b3),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(10),
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							$author$project$Page$Profile$newPasswordField(mdl)
						])),
					$author$project$Page$SignInPagesCommon$fieldErrorMsgElement(mdl.cQ),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(10),
							$mdgriffith$elm_ui$Element$centerY,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							$author$project$Page$Profile$repeatPasswordField(mdl)
						])),
					$author$project$Page$SignInPagesCommon$fieldErrorMsgElement(mdl.cW)
				]);
			var actionBtns = function () {
				var _v0 = function () {
					var _v1 = _Utils_Tuple2(mdl.aK, mdl.ay);
					_v1$2:
					while (true) {
						_v1$7:
						while (true) {
							switch (_v1.a.$) {
								case 1:
									var _v2 = _v1.a;
									return _Utils_Tuple2(
										$author$project$Theme$Style$t.eD,
										$elm$core$Maybe$Just('Updating...'));
								case 3:
									switch (_v1.b.$) {
										case 3:
											return $author$project$Page$Profile$successPageMsgArea;
										case 1:
											break _v1$2;
										case 0:
											var _v4 = _v1.b;
											return $author$project$Page$Profile$successPageMsgArea;
										default:
											break _v1$7;
									}
								case 2:
									switch (_v1.b.$) {
										case 1:
											break _v1$2;
										case 2:
											var err1 = _v1.a.a;
											var err2 = _v1.b.a;
											return _Utils_Tuple2(
												$author$project$Theme$Style$t.eC,
												$elm$core$Maybe$Just(err1 + ('  ' + err2)));
										default:
											var err = _v1.a.a;
											return _Utils_Tuple2(
												$author$project$Theme$Style$t.eC,
												$elm$core$Maybe$Just(err));
									}
								default:
									switch (_v1.b.$) {
										case 1:
											break _v1$2;
										case 3:
											var _v5 = _v1.a;
											return $author$project$Page$Profile$successPageMsgArea;
										case 2:
											break _v1$7;
										default:
											var _v6 = _v1.a;
											var _v7 = _v1.b;
											return mdl.dQ;
									}
							}
						}
						var err = _v1.b.a;
						return _Utils_Tuple2(
							$author$project$Theme$Style$t.eC,
							$elm$core$Maybe$Just(err));
					}
					var _v3 = _v1.b;
					return _Utils_Tuple2(
						$author$project$Theme$Style$t.eD,
						$elm$core$Maybe$Just('Updating...'));
				}();
				var color = _v0.a;
				var stringMaybe = _v0.b;
				return _List_fromArray(
					[
						A2($author$project$Page$SignInPagesCommon$msgAreaWithColor, color, stringMaybe),
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(10),
								$mdgriffith$elm_ui$Element$centerY
							]),
						_List_fromArray(
							[
								$author$project$Page$Profile$cancelButton(mdl),
								A2(
								$Punie$elm_reader$Reader$run,
								$author$project$Page$Profile$updateProfileButton(mdl),
								rState)
							]))
					]);
			}();
			return A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Element$paddingXY, 0, 60),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width(
								$mdgriffith$elm_ui$Element$fillPortion(1))
							]),
						_List_Nil),
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width(
								$mdgriffith$elm_ui$Element$fillPortion(6)),
								$mdgriffith$elm_ui$Element$spacing(30)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$row,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.nn),
												$mdgriffith$elm_ui$Element$Font$size(30),
												$mdgriffith$elm_ui$Element$Font$bold
											]),
										$mdgriffith$elm_ui$Element$text('Profile')),
										A2($author$project$Page$PagesCommon$helpAnchorWithColor, 'smHaPageHeading', $author$project$Theme$Style$t.lN)
									])),
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
										A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
										$mdgriffith$elm_ui$Element$Border$rounded(10),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								($author$project$Page$Profile$isUserProtected(mdl.H) && (!mdl.bA)) ? _List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10)
											]),
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text('This is the SmarterMaths administrator that has been temporarily associated with your account.')
											])),
										A2(
										$mdgriffith$elm_ui$Element$paragraph,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10)
											]),
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text('This normally occurs for diagnostic purposes, usually due to a previous request or problem raised directly by a teacher or student at your school, and will be unassigned from your account when the issue is resolved.')
											]))
									]) : $elm$core$List$concat(
									_List_fromArray(
										[commonFields, userSpecificFields, deleteItem, actionBtns])))
							])),
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width(
								$mdgriffith$elm_ui$Element$fillPortion(1))
							]),
						_List_Nil)
					]));
		});
};
var $author$project$Page$Profile$view = F2(
	function (model, topBarFn) {
		var makeWorkArea = F2(
			function (color, elements) {
				return A2(
					$mdgriffith$elm_ui$Element$layout,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color(color)
						]),
					A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						elements));
			});
		var addTopBar = function (wArea2) {
			return A2(
				$Punie$elm_reader$Reader$map,
				function (tBar3) {
					return _List_fromArray(
						[tBar3, wArea2]);
				},
				A2(topBarFn, $author$project$Page$Profile$SignOut, $author$project$Page$Profile$LinkClicked));
		};
		return A3(
			$Punie$elm_reader$Reader$map2,
			makeWorkArea,
			$author$project$Page$PagesCommon$makeBackgroundStyle,
			A2(
				$Punie$elm_reader$Reader$andThen,
				addTopBar,
				$author$project$Page$Profile$workArea(model)));
	});
var $author$project$Page$ReportCard$LinkClicked = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$ReportCard$SignOut = {$: 2};
var $author$project$Page$ReportCard$Cancel = {$: 0};
var $author$project$Page$ReportCard$cancelButton = A2(
	$author$project$Page$PagesCommon$regButton,
	true,
	{
		bD: A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$centerX]),
			$mdgriffith$elm_ui$Element$text('Back')),
		ai: $elm$core$Maybe$Just($author$project$Page$ReportCard$Cancel)
	});
var $author$project$Page$PagesCommon$contentTitleRowAttrs = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kJ),
		A2($mdgriffith$elm_ui$Element$paddingXY, 30, 15),
		$mdgriffith$elm_ui$Element$Border$rounded(10),
		$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
	]);
var $author$project$Page$ReportCard$formatErrorRawParagraphs = function (errStr) {
	return _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Element$paragraph,
			_List_Nil,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$text(errStr)
				])),
			A2(
			$mdgriffith$elm_ui$Element$paragraph,
			_List_Nil,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$text('Please logout/login to try again.')
				])),
			A2(
			$mdgriffith$elm_ui$Element$paragraph,
			_List_Nil,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$text('If you\'re still having problems, please let us know at support@smartermaths.com.au and we\'ll get it fixed ASAP!')
				]))
		]);
};
var $author$project$Page$ReportCard$formatErrorMsg = function (errStr) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(20)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.nn),
								$mdgriffith$elm_ui$Element$Font$size(30),
								$mdgriffith$elm_ui$Element$Font$bold
							]),
						$mdgriffith$elm_ui$Element$text('Student Report Card')),
						A2($author$project$Page$PagesCommon$helpAnchorWithColor, 'smHaPageHeading', $author$project$Theme$Style$t.lN)
					])),
				A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
						A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
						$mdgriffith$elm_ui$Element$Border$rounded(10),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$spacing(20)
					]),
				$author$project$Page$ReportCard$formatErrorRawParagraphs(errStr))
			]));
};
var $author$project$Page$ReportCard$getLineItemAvgDifficultyMaybe = F3(
	function (itemDatas, rcli, acrrs) {
		var toDiffAvgMaybe = function (ints) {
			if ($elm$core$List$isEmpty(ints)) {
				return $elm$core$Maybe$Nothing;
			} else {
				var length = $elm$core$List$length(ints);
				return $elm$core$Maybe$Just(
					($elm$core$List$sum(ints) / length) | 0);
			}
		};
		return toDiffAvgMaybe(
			A2(
				$elm$core$List$filterMap,
				function (arr) {
					return A2($author$project$Type$ExamResult$calculateActivityResultDifficulty, itemDatas, arr.ci);
				},
				A2(
					$elm$core$List$filterMap,
					$author$project$Type$ExamResult$getMostRecentActivityResultRecordItem,
					A2(
						$elm$core$List$map,
						function (actId) {
							return A2(
								$elm$core$List$filter,
								function (arr) {
									return _Utils_eq(arr.jz, actId);
								},
								acrrs);
						},
						rcli.mt))));
	});
var $author$project$Type$NodeCfg$toReportCardDataMaybe = function (postMeta) {
	if (postMeta.$ === 3) {
		var reportCardData = postMeta.a;
		return $elm$core$Maybe$Just(reportCardData);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Type$NodeCfg$getReportCardLineItemsMaybe = F3(
	function (syllabuses, postMetas, cohort) {
		var syllabusMaybe = A2($author$project$Type$Syllabus$fromCohort, syllabuses, cohort);
		var toReportCardDataForSyllabusMaybe = function (reportCardData) {
			return A2(
				$elm$core$Maybe$andThen,
				function (syllabus) {
					return _Utils_eq(reportCardData.bf, syllabus.lW) ? $elm$core$Maybe$Just(reportCardData) : $elm$core$Maybe$Nothing;
				},
				syllabusMaybe);
		};
		return A2(
			$elm$core$Maybe$map,
			$elm$core$List$sortBy(
				function ($) {
					return $.or;
				}),
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.mv;
				},
				$elm$core$List$head(
					A2(
						$elm$core$List$filterMap,
						toReportCardDataForSyllabusMaybe,
						A2($elm$core$List$filterMap, $author$project$Type$NodeCfg$toReportCardDataMaybe, postMetas)))));
	});
var $author$project$Page$ReportCard$calcOverallMaybe = function (lineDatas) {
	var lineDatasWithoutNothings = A2(
		$elm$core$List$filterMap,
		function (_v1) {
			var rcli = _v1.a;
			var valMaybe = _v1.b;
			if (valMaybe.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var val = valMaybe.a;
				return $elm$core$Maybe$Just(
					_Utils_Tuple2(rcli.ns, val));
			}
		},
		lineDatas);
	return (!_Utils_eq(
		$elm$core$List$length(lineDatasWithoutNothings),
		$elm$core$List$length(lineDatas))) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
		$elm$core$List$sum(
			A2(
				$elm$core$List$map,
				function (_v0) {
					var pc = _v0.a;
					var val = _v0.b;
					return ((pc * val) / 100) | 0;
				},
				lineDatasWithoutNothings)));
};
var $author$project$Page$ReportCard$colorDarkGrey = A2($elm$core$Basics$composeL, $author$project$Theme$Style$lighter, $author$project$Theme$Style$lighter)($author$project$Theme$Style$black);
var $author$project$Page$ReportCard$colorLightGrey = A2($elm$core$Basics$composeL, $author$project$Theme$Style$lighter, $author$project$Theme$Style$lighter)($author$project$Page$ReportCard$colorDarkGrey);
var $author$project$Page$ReportCard$insufficientDataString = 'Insufficient data to display a result.';
var $author$project$Page$ReportCard$reportCardValueOptions = _List_fromArray(
	[0, 1, 2, 3, 4]);
var $author$project$Page$ReportCard$makeContentBlockA = F2(
	function (cohort, tempLineDatas) {
		var paddingAttr = $mdgriffith$elm_ui$Element$paddingEach(
			{er: 0, e9: 10, fz: 0, fY: 0});
		var makeTableHeader = A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							paddingAttr,
							$mdgriffith$elm_ui$Element$Font$italic
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$fillPortion(3))
								]),
							_List_Nil),
						A2(
							$elm$core$List$map,
							function (el_) {
								return A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$fillPortion(1)),
											$mdgriffith$elm_ui$Element$Border$widthEach(
											{er: 0, e9: 1, fz: 1, fY: 1})
										]),
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$centerX,
													$mdgriffith$elm_ui$Element$Font$size(16),
													A2($mdgriffith$elm_ui$Element$paddingXY, 0, 6)
												]),
											$mdgriffith$elm_ui$Element$text(el_))
										]));
							},
							_List_fromArray(
								['Outstanding', 'High', 'Sound', 'Basic', 'Limited'])))),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							paddingAttr,
							$mdgriffith$elm_ui$Element$Border$dotted,
							$mdgriffith$elm_ui$Element$Border$widthEach(
							{er: 1, e9: 0, fz: 0, fY: 0})
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$fillPortion(3))
								]),
							_List_Nil),
						A2(
							$elm$core$List$map,
							function (el_) {
								return A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$fillPortion(1)),
											$mdgriffith$elm_ui$Element$Border$widthEach(
											{er: 1, e9: 1, fz: 1, fY: 1}),
											$mdgriffith$elm_ui$Element$Background$color($author$project$Page$ReportCard$colorDarkGrey)
										]),
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$centerX,
													$mdgriffith$elm_ui$Element$Font$bold,
													A2($mdgriffith$elm_ui$Element$paddingXY, 0, 4),
													$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$white)
												]),
											$mdgriffith$elm_ui$Element$text(el_))
										]));
							},
							A2($elm$core$List$map, $author$project$Type$ReportCard$toString, $author$project$Page$ReportCard$reportCardValueOptions))))
				]));
		var makeRow = function (_v6) {
			var rcli = _v6.a;
			var valMaybe = _v6.b;
			var makeCol = function (reportCardValueCol) {
				return A2(
					$elm$core$Maybe$withDefault,
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]),
							$mdgriffith$elm_ui$Element$text('-'))
						]),
					A2(
						$elm$core$Maybe$map,
						function (isEqual) {
							return _List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX]),
									isEqual ? $mdgriffith$elm_ui$Element$html(
										A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('icon'),
													A2($elm$html$Html$Attributes$style, 'height', '1.2rem')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$i,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('fa fa-check')
														]),
													_List_Nil)
												]))) : $mdgriffith$elm_ui$Element$text(' '))
								]);
						},
						A2(
							$elm$core$Maybe$map,
							$elm$core$Basics$eq(reportCardValueCol),
							A2(
								$elm$core$Maybe$map,
								$author$project$Type$ReportCard$toReportCardValue(cohort),
								valMaybe))));
			};
			var nonFirstCols = A2(
				$elm$core$List$map,
				function (el) {
					return A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 0, 6),
								$mdgriffith$elm_ui$Element$width(
								$mdgriffith$elm_ui$Element$fillPortion(1)),
								$mdgriffith$elm_ui$Element$Border$widthEach(
								{er: 0, e9: 1, fz: 1, fY: 0})
							]),
						makeCol(el));
				},
				$author$project$Page$ReportCard$reportCardValueOptions);
			return A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						paddingAttr,
						$mdgriffith$elm_ui$Element$Border$dotted,
						$mdgriffith$elm_ui$Element$Border$widthEach(
						{er: 1, e9: 0, fz: 0, fY: 0})
					]),
				A2(
					$elm$core$List$cons,
					A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width(
								$mdgriffith$elm_ui$Element$fillPortion(3))
							]),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text(rcli.hz)
							])),
					nonFirstCols));
		};
		var makeOverallGraphic = function (val) {
			var graphicCells = function () {
				var lastCellIdx = $elm$core$List$length($author$project$Page$ReportCard$reportCardValueOptions) - 1;
				var makeCell = F2(
					function (idx, _v5) {
						var cell = _v5.a;
						var isCardAchieved = _v5.b;
						var baseAttrs = A2(
							$elm$core$List$append,
							isCardAchieved ? _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Background$color($author$project$Page$ReportCard$colorDarkGrey)
								]) : _List_Nil,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$size(28),
									$mdgriffith$elm_ui$Element$Font$bold,
									A2($mdgriffith$elm_ui$Element$paddingXY, 12, 10)
								]));
						var attrs = (!idx) ? A2(
							$elm$core$List$append,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Border$roundEach(
									{de: 10, df: 0, d8: 10, d9: 0})
								]),
							baseAttrs) : (_Utils_eq(idx, lastCellIdx) ? A2(
							$elm$core$List$append,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Border$roundEach(
									{de: 0, df: 10, d8: 0, d9: 10})
								]),
							baseAttrs) : baseAttrs);
						return A2($mdgriffith$elm_ui$Element$el, attrs, cell);
					});
				return A2(
					$elm$core$List$indexedMap,
					makeCell,
					A2(
						$elm$core$List$map,
						function (el) {
							return _Utils_Tuple2(
								$mdgriffith$elm_ui$Element$text(
									$author$project$Type$ReportCard$toString(el)),
								_Utils_eq(el, val));
						},
						$author$project$Page$ReportCard$reportCardValueOptions));
			}();
			return A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Border$rounded(10),
						$mdgriffith$elm_ui$Element$Background$color($author$project$Page$ReportCard$colorLightGrey),
						$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$white)
					]),
				graphicCells);
		};
		var makeFirstRow = function (_v4) {
			var rcli = _v4.a;
			var valMaybe = _v4.b;
			return A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$paddingEach(
						{er: 30, e9: 0, fz: 0, fY: 0})
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width(
								$mdgriffith$elm_ui$Element$fillPortion(3))
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$bold,
										$mdgriffith$elm_ui$Element$Font$size(24)
									]),
								$mdgriffith$elm_ui$Element$text(rcli.hz))
							])),
						function () {
						if (valMaybe.$ === 1) {
							return A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$fillPortion(5))
									]),
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text($author$project$Page$ReportCard$insufficientDataString)
									]));
						} else {
							var val = valMaybe.a;
							return A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$fillPortion(5))
									]),
								_List_fromArray(
									[
										makeOverallGraphic(
										A2($author$project$Type$ReportCard$toReportCardValue, cohort, val))
									]));
						}
					}()
					]));
		};
		var isUsePercentCalcForOverall = !A2(
			$elm$core$List$member,
			$elm$core$Maybe$Nothing,
			A2($elm$core$List$map, $elm$core$Tuple$second, tempLineDatas));
		var lineDatas = function () {
			if (isUsePercentCalcForOverall) {
				if (tempLineDatas.b && (!tempLineDatas.a.b.$)) {
					var _v2 = tempLineDatas.a;
					var rcli = _v2.a;
					var val = _v2.b.a;
					var xs = tempLineDatas.b;
					var newPcCalcMaybe = $author$project$Page$ReportCard$calcOverallMaybe(tempLineDatas);
					return A2(
						$elm$core$List$cons,
						_Utils_Tuple2(
							rcli,
							_Utils_eq(newPcCalcMaybe, $elm$core$Maybe$Nothing) ? $elm$core$Maybe$Just(val) : newPcCalcMaybe),
						xs);
				} else {
					return tempLineDatas;
				}
			} else {
				return tempLineDatas;
			}
		}();
		if (lineDatas.b) {
			var firstData = lineDatas.a;
			var restData = lineDatas.b;
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				A2(
					$elm$core$List$cons,
					makeFirstRow(firstData),
					A2(
						$elm$core$List$cons,
						makeTableHeader,
						A2($elm$core$List$map, makeRow, restData))));
		} else {
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(10)
					]),
				$author$project$Page$ReportCard$formatErrorRawParagraphs('A problem occurred when lookin for the report card data. '));
		}
	});
var $author$project$Page$ReportCard$makeContentBlockB = F2(
	function (cohort, lineDatas) {
		var makeRow = function (_v0) {
			var rcli = _v0.a;
			var valMaybe = _v0.b;
			return A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						A2($mdgriffith$elm_ui$Element$paddingXY, 10, 6),
						$mdgriffith$elm_ui$Element$Border$dotted,
						$mdgriffith$elm_ui$Element$Border$widthEach(
						{er: 1, e9: 0, fz: 0, fY: 0})
					]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text(rcli.mu),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignRight]),
						$author$project$UISnippet$Misc$makeResultElement(
							A2(
								$elm$core$Maybe$map,
								$author$project$Type$ExamResult$difficultyToImageUrlSmall(cohort),
								valMaybe)))
					]));
		};
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$bold,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10),
							$mdgriffith$elm_ui$Element$Border$solid,
							$mdgriffith$elm_ui$Element$Border$widthEach(
							{er: 2, e9: 0, fz: 0, fY: 0})
						]),
					$mdgriffith$elm_ui$Element$text('TEST RESULTS')),
				A2($elm$core$List$map, makeRow, lineDatas)));
	});
var $author$project$Page$PagesCommon$makeContentTitleElement = function (titleString) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$white),
				$mdgriffith$elm_ui$Element$Font$italic,
				$mdgriffith$elm_ui$Element$Font$size(22)
			]),
		$mdgriffith$elm_ui$Element$text(titleString));
};
var $author$project$Page$ReportCard$makeReportHeader = function (studentE) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(12),
				$mdgriffith$elm_ui$Element$Font$bold
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(8)
					]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('Student:'),
						$mdgriffith$elm_ui$Element$text(studentE.lt),
						$mdgriffith$elm_ui$Element$text(studentE.mq)
					])),
				$mdgriffith$elm_ui$Element$text(
				$author$project$Type$Cohort$toExpandedString(studentE.kA))
			]));
};
var $author$project$Page$ReportCard$workArea = function (model) {
	return $Punie$elm_reader$Reader$asks(
		function (rState) {
			var pageBody = function () {
				var _v0 = model.fR;
				if (_v0.$ === 1) {
					return _List_fromArray(
						[
							$author$project$Page$ReportCard$formatErrorMsg('An error has occurred, and we could not obtain valid student data for your Report Card.')
						]);
				} else {
					var studentE = _v0.a;
					var lineItemsMaybe = A3($author$project$Type$NodeCfg$getReportCardLineItemsMaybe, rState.mX.oJ, rState.mX.fs, studentE.kA);
					var itemDatas = $author$project$Type$NodeCfg$getItems(rState.mX.fs);
					var acrrs = A2($elm$core$Basics$composeL, $author$project$Type$ExamResult$getOnlineResults, $author$project$Type$ExamResult$getActivityResultRecords)(studentE.lU);
					if (lineItemsMaybe.$ === 1) {
						return _List_fromArray(
							[
								$author$project$Page$ReportCard$formatErrorMsg(
								'A problem occurred when we were trying to get the report card configurations for ' + $author$project$Type$Cohort$toString(studentE.kA))
							]);
					} else {
						var lineItems = lineItemsMaybe.a;
						var makeLineData = function (rcli) {
							return _Utils_Tuple2(
								rcli,
								A3($author$project$Page$ReportCard$getLineItemAvgDifficultyMaybe, itemDatas, rcli, acrrs));
						};
						var lineDatas = A2($elm$core$List$map, makeLineData, lineItems);
						return _List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$row,
										$author$project$Page$PagesCommon$contentTitleRowAttrs,
										_List_fromArray(
											[
												$author$project$Page$PagesCommon$makeContentTitleElement('Student Report Card')
											])),
										A2(
										$mdgriffith$elm_ui$Element$column,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
												A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
												$mdgriffith$elm_ui$Element$Border$rounded(10),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
											]),
										_List_fromArray(
											[
												$author$project$Page$ReportCard$makeReportHeader(studentE)
											]))
									])),
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
										A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
										$mdgriffith$elm_ui$Element$Border$rounded(10),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										A2($author$project$Page$ReportCard$makeContentBlockA, studentE.kA, lineDatas)
									])),
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
										A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
										$mdgriffith$elm_ui$Element$Border$rounded(10),
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								_List_fromArray(
									[
										A2($author$project$Page$ReportCard$makeContentBlockB, studentE.kA, lineDatas)
									]))
							]);
					}
				}
			}();
			return A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						A2($mdgriffith$elm_ui$Element$paddingXY, 0, 60),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width(
								$mdgriffith$elm_ui$Element$fillPortion(1))
							]),
						_List_Nil),
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width(
								$mdgriffith$elm_ui$Element$fillPortion(6)),
								$mdgriffith$elm_ui$Element$spacing(12)
							]),
						A2(
							$elm$core$List$append,
							pageBody,
							_List_fromArray(
								[$author$project$Page$ReportCard$cancelButton]))),
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width(
								$mdgriffith$elm_ui$Element$fillPortion(1))
							]),
						_List_Nil)
					]));
		});
};
var $author$project$Page$ReportCard$view = F2(
	function (model, topBarFn) {
		var makeWorkArea = F2(
			function (color, elements) {
				return A2(
					$mdgriffith$elm_ui$Element$layout,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color(color)
						]),
					A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						elements));
			});
		var addTopBar = function (wArea2) {
			return A2(
				$Punie$elm_reader$Reader$map,
				function (tBar3) {
					return _List_fromArray(
						[tBar3, wArea2]);
				},
				A2(topBarFn, $author$project$Page$ReportCard$SignOut, $author$project$Page$ReportCard$LinkClicked));
		};
		return A3(
			$Punie$elm_reader$Reader$map2,
			makeWorkArea,
			$author$project$Page$PagesCommon$makeBackgroundStyle,
			A2(
				$Punie$elm_reader$Reader$andThen,
				addTopBar,
				$author$project$Page$ReportCard$workArea(model)));
	});
var $author$project$Page$Sample$Decrement = 1;
var $author$project$Page$Sample$Increment = 0;
var $elm$html$Html$button = _VirtualDom_node('button');
var $author$project$Page$Sample$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(1)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('-')
					])),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(model))
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(0)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('+')
					]))
			]));
};
var $author$project$Page$ShowVariant$LinkClicked = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$ShowVariant$SignOut = {$: 0};
var $author$project$Page$ShowVariant$AnswerChecked = 2;
var $author$project$Page$ShowVariant$OnVariantAnswer = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$ShowVariant$OnVariantAnswerDisabled = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$ShowVariant$Revision = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$ShowVariant$Abort = {$: 14};
var $author$project$Page$ShowVariant$CheckAnswer = {$: 13};
var $author$project$Page$ShowVariant$FinishActivity = {$: 9};
var $author$project$Page$ShowVariant$ToggleIsShowQuestionBand = {$: 11};
var $author$project$Page$ShowVariant$Answered = 1;
var $author$project$Page$ShowVariant$Exam = {$: 0};
var $author$project$Page$ShowVariant$NotAnswered = 0;
var $author$project$Page$ShowVariant$getActivityContextMaybe = function (model) {
	return A2(
		$elm$core$Maybe$map,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.cx;
			},
			function ($) {
				return $.kL;
			}),
		model.f8);
};
var $author$project$Page$ShowVariant$isAnswered = function (vAnswerMaybe) {
	if (vAnswerMaybe.$ === 1) {
		return false;
	} else {
		var vAnswer = vAnswerMaybe.a;
		switch (vAnswer.$) {
			case 0:
				return true;
			case 1:
				return true;
			default:
				var _v2 = vAnswer.a;
				var answers = _v2.a;
				return A2(
					$elm$core$List$all,
					$elm$core$Basics$eq(true),
					$elm$core$Array$toList(
						A2(
							$elm$core$Array$map,
							function (el) {
								return (!_Utils_eq(el, $elm$core$Maybe$Nothing)) ? true : false;
							},
							answers)));
		}
	}
};
var $author$project$Page$ShowVariant$getPageMode = function (model) {
	var getPageStatus = (!$author$project$Page$ShowVariant$isAnswered(model.ao)) ? 0 : (model.ca ? 2 : 1);
	var _v0 = $author$project$Page$ShowVariant$getActivityContextMaybe(model);
	if (!_v0.$) {
		if (!_v0.a) {
			var _v1 = _v0.a;
			return $author$project$Page$ShowVariant$Exam;
		} else {
			var _v2 = _v0.a;
			return $author$project$Page$ShowVariant$Revision(getPageStatus);
		}
	} else {
		return $author$project$Page$ShowVariant$Exam;
	}
};
var $author$project$Page$ShowVariant$isNoQuestionFound = function (variantSelection) {
	if ((!variantSelection.$) && (variantSelection.a.b.$ === 1)) {
		var _v1 = variantSelection.a;
		var _v2 = _v1.b;
		return true;
	} else {
		return false;
	}
};
var $author$project$Page$ShowVariant$isSessionFinished = function (mdl) {
	var _v0 = mdl.aq;
	if (!_v0.$) {
		var activityMetric = _v0.a;
		return _Utils_cmp(
			$elm$core$List$length(mdl.oe),
			activityMetric.mV - 1) > -1;
	} else {
		return true;
	}
};
var $author$project$Page$ShowVariant$buttonRow = function (mdl) {
	return $Punie$elm_reader$Reader$asks(
		function (rState) {
			var isShowingQuestion = function () {
				var _v5 = mdl.aL;
				if (_v5.$ === 3) {
					return true;
				} else {
					return false;
				}
			}();
			var isAnswerSelected = rState.lx.md ? true : $author$project$Page$ShowVariant$isAnswered(mdl.ao);
			var hideShowQuestionBand = A2(
				$mdgriffith$elm_ui$Element$Input$button,
				_List_Nil,
				mdl.bz ? {
					bD: A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$Font$underline]),
						$mdgriffith$elm_ui$Element$text('hide')),
					ai: $elm$core$Maybe$Just($author$project$Page$ShowVariant$ToggleIsShowQuestionBand)
				} : {
					bD: A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$Font$underline]),
						$mdgriffith$elm_ui$Element$text('click to show')),
					ai: $elm$core$Maybe$Just($author$project$Page$ShowVariant$ToggleIsShowQuestionBand)
				});
			var diffElement = A2(
				$elm$core$Maybe$withDefault,
				$mdgriffith$elm_ui$Element$text('n/a'),
				A2(
					$elm$core$Maybe$map,
					function (img) {
						return A2(
							$mdgriffith$elm_ui$Element$image,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width(
									$mdgriffith$elm_ui$Element$px(167)),
									$mdgriffith$elm_ui$Element$height(
									$mdgriffith$elm_ui$Element$px(30))
								]),
							{gT: img, ov: img});
					},
					A2(
						$elm$core$Maybe$map,
						$author$project$Type$ExamResult$difficultyToImageUrlSmall(rState.mX.cF),
						A2(
							$elm$core$Maybe$andThen,
							$author$project$Type$ExamResult$getDifficultyForVariantId(
								$author$project$Type$NodeCfg$getItems(rState.mX.fs)),
							A2(
								$elm$core$Maybe$map,
								function ($) {
									return $.pK;
								},
								$krisajenkins$remotedata$RemoteData$toMaybe(mdl.aL))))));
			var _v0 = function () {
				if (mdl.X) {
					return _Utils_Tuple3(
						true,
						$elm$core$Maybe$Just($author$project$Page$ShowVariant$GetNextVariantSelectionNEW),
						'Begin');
				} else {
					if ($author$project$Page$ShowVariant$isNoQuestionFound(mdl.ab)) {
						return $elm$core$List$isEmpty(mdl.oe) ? _Utils_Tuple3(
							true,
							$elm$core$Maybe$Just($author$project$Page$ShowVariant$Abort),
							'Close') : _Utils_Tuple3(
							true,
							$elm$core$Maybe$Just($author$project$Page$ShowVariant$FinishActivity),
							'Finish');
					} else {
						if ($krisajenkins$remotedata$RemoteData$isSuccess(mdl.aL)) {
							var finishBtnSettings = _Utils_Tuple3(
								isAnswerSelected,
								isAnswerSelected ? $elm$core$Maybe$Just($author$project$Page$ShowVariant$FinishActivity) : $elm$core$Maybe$Nothing,
								'Finish');
							var _v1 = $author$project$Page$ShowVariant$getPageMode(mdl);
							if (!_v1.$) {
								return $author$project$Page$ShowVariant$isSessionFinished(mdl) ? finishBtnSettings : _Utils_Tuple3(
									isAnswerSelected,
									isAnswerSelected ? $elm$core$Maybe$Just($author$project$Page$ShowVariant$GetNextVariantSelectionNEW) : $elm$core$Maybe$Nothing,
									'Next');
							} else {
								switch (_v1.a) {
									case 0:
										var _v2 = _v1.a;
										return _Utils_Tuple3(false, $elm$core$Maybe$Nothing, 'Check answer');
									case 1:
										var _v3 = _v1.a;
										return _Utils_Tuple3(
											true,
											$elm$core$Maybe$Just($author$project$Page$ShowVariant$CheckAnswer),
											'Check answer');
									default:
										var _v4 = _v1.a;
										return $author$project$Page$ShowVariant$isSessionFinished(mdl) ? finishBtnSettings : _Utils_Tuple3(
											true,
											$elm$core$Maybe$Just($author$project$Page$ShowVariant$GetNextVariantSelectionNEW),
											'Next');
								}
							}
						} else {
							return _Utils_Tuple3(false, $elm$core$Maybe$Nothing, 'Loading...');
						}
					}
				}
			}();
			var isActive = _v0.a;
			var action = _v0.b;
			var btnText = _v0.c;
			return A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$alignLeft,
								$mdgriffith$elm_ui$Element$spacing(10),
								$mdgriffith$elm_ui$Element$paddingEach(
								{er: 5, e9: 0, fz: 0, fY: 15}),
								$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$white),
								$mdgriffith$elm_ui$Element$Font$bold
							]),
						isShowingQuestion ? (mdl.bz ? _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('Question band:'),
								diffElement,
								hideShowQuestionBand
							]) : _List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('Question band:'),
								hideShowQuestionBand
							])) : _List_Nil),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignRight]),
						A2(
							$author$project$Page$PagesCommon$regButton,
							isActive,
							{
								bD: A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX]),
									$mdgriffith$elm_ui$Element$text(btnText)),
								ai: action
							}))
					]));
		});
};
var $author$project$Page$ShowVariant$instructionsArea = function (model) {
	return $Punie$elm_reader$Reader$asks(
		function (_v0) {
			var toMd = function (txt) {
				return A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_Nil,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$html(
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('sm-markdown')
									]),
								A2($pablohirafuji$elm_markdown$Markdown$toHtml, $elm$core$Maybe$Nothing, txt)))
						]));
			};
			return A2(
				$elm$core$Maybe$withDefault,
				$mdgriffith$elm_ui$Element$text('Click \'Begin\' to proceed'),
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.ma;
						},
						toMd),
					model.f8));
		});
};
var $author$project$Type$History$toStringHistoryItem = function (_v0) {
	var variantId = _v0.pK;
	var result = _v0.dW;
	return $author$project$Type$Variant$toShortStringVariantId(variantId) + ('(' + ($author$project$Type$History$vAnswerEncodeToString(result) + ')'));
};
var $author$project$Page$ShowVariant$instrumentationArea = F2(
	function (mdl, isNapAdmin) {
		if (isNapAdmin && ((!_Utils_eq(mdl.f8, $elm$core$Maybe$Nothing)) && (!mdl.X))) {
			var historyStr = function (el) {
				return $elm$core$List$isEmpty(el) ? 'None' : A3($elm$core$List$foldr, $elm$core$Basics$append, '', el);
			}(
				A2(
					$elm$core$List$intersperse,
					', ',
					A2($elm$core$List$map, $author$project$Type$History$toStringHistoryItem, mdl.oe)));
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
						A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
						$mdgriffith$elm_ui$Element$Border$rounded(10),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$spacing(10),
								$mdgriffith$elm_ui$Element$htmlAttribute(
								$elm$html$Html$Attributes$class('content'))
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$Font$italic, $mdgriffith$elm_ui$Element$Font$underline]),
								$mdgriffith$elm_ui$Element$text('Instrumentation')),
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_Nil,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text(' '),
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$Font$underline]),
										$mdgriffith$elm_ui$Element$text('Current question details:')),
										$mdgriffith$elm_ui$Element$text(
										A2(
											$elm$core$Maybe$withDefault,
											'No variantSelection details are yet available.',
											A2($elm$core$Maybe$map, $author$project$Type$Variant$toStringVariantSelection, mdl.ab)))
									])),
								$mdgriffith$elm_ui$Element$text(' '),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[$mdgriffith$elm_ui$Element$Font$underline]),
								$mdgriffith$elm_ui$Element$text('History (first to last, not incl current q) where c = correct, i = incorrect, and n = notanswered :')),
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text(historyStr)
									]))
							]))
					]));
		} else {
			return $mdgriffith$elm_ui$Element$text('');
		}
	});
var $author$project$Page$ShowVariant$makeErrorContent = F2(
	function (variantSelection, _v0) {
		var variantIdMaybe = _v0.fZ;
		var errMsg = _v0.b4;
		var blankLine = $mdgriffith$elm_ui$Element$text(' ');
		if (A2($elm$core$String$startsWith, 'Network Error', errMsg)) {
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$spacing(20)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$Font$bold]),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('Error:  No internet / Disconnected.')
							])),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_Nil,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('If you leave this page you will lose your progress.')
							])),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_Nil,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('To continue:')
							])),
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(4),
								A2($mdgriffith$elm_ui$Element$paddingXY, 20, 0)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text(' 1. Stay on this page.')
									])),
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text(' 2. Fix your internet.')
									])),
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text(' 3. Then \'refresh\' this page in the browser.')
									]))
							]))
					]));
		} else {
			var variantIdString = A2(
				$elm$core$Maybe$withDefault,
				'Unknown',
				A2(
					$elm$core$Maybe$map,
					$author$project$Type$Variant$toShortStringVariantId,
					A2(
						$elm_community$maybe_extra$Maybe$Extra$or,
						variantIdMaybe,
						A2($elm$core$Maybe$andThen, $elm$core$Tuple$second, variantSelection))));
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_Nil,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('There was a problem retrieving the question with the following error message:')
							])),
						blankLine,
						$mdgriffith$elm_ui$Element$text('*************************************************'),
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_Nil,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text(errMsg)
							])),
						$mdgriffith$elm_ui$Element$text('Variant Id: ' + variantIdString),
						$mdgriffith$elm_ui$Element$text('*************************************************'),
						blankLine,
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_Nil,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('Please let us know if the problem persists and we\'ll get it fixed ASAP! ... send us a screenshot if possible, or just copy/paste the message above and email to support@smartermaths.com.au.')
							])),
						blankLine,
						A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_Nil,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text('Many thanks from the SmarterMaths team!')
							]))
					]));
		}
	});
var $author$project$Type$NodeCfg$getItemTags = F2(
	function (postMetas, variantId) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.i7;
				},
				A2(
					$author$project$Type$NodeCfg$getItemData,
					$author$project$Type$NodeCfg$getItems(postMetas),
					variantId)));
	});
var $author$project$Type$Syllabus$getSyllabusShortCode = function (cohort) {
	if (cohort.$ === 1) {
		return 'ms';
	} else {
		return 'nap';
	}
};
var $author$project$Page$ShowVariant$makeQuestionTool = F3(
	function (nodeCfg, mdl, variantContent) {
		var isAddCalculator = function (cohort) {
			var isAddCalc = !A2(
				$elm$core$List$member,
				'nap_nc',
				A2($author$project$Type$NodeCfg$getItemTags, nodeCfg.fs, variantContent.pK));
			if (cohort.$ === 1) {
				return A2(
					$elm$core$List$member,
					$author$project$Type$Syllabus$getSyllabusShortCode(nodeCfg.cF) + '_ca',
					A2($author$project$Type$NodeCfg$getItemTags, nodeCfg.fs, variantContent.pK));
			} else {
				switch (cohort.a.$) {
					case 0:
						return false;
					case 1:
						return false;
					case 2:
						return isAddCalc;
					default:
						return isAddCalc;
				}
			}
		};
		var calcToolElement = A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$paddingEach(
					{er: 10, e9: 0, fz: 0, fY: 0}),
					$mdgriffith$elm_ui$Element$Border$widthEach(
					{er: 1, e9: 0, fz: 0, fY: 0}),
					$mdgriffith$elm_ui$Element$Border$dashed
				]),
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$alignRight, $mdgriffith$elm_ui$Element$Font$bold]),
				A2(
					$mdgriffith$elm_ui$Element$map,
					$author$project$Page$ShowVariant$MsgToolCalc,
					$author$project$UITool$Calculator$view(mdl.je))));
		var toolForCohort = function (cohort) {
			return isAddCalculator(cohort) ? calcToolElement : $mdgriffith$elm_ui$Element$none;
		};
		return toolForCohort(nodeCfg.cF);
	});
var $author$project$Page$ShowVariant$ToggleExtraTime = {$: 10};
var $author$project$Helper$Util$digitalClockTextFromSeconds = function (secs) {
	var orig_seconds = secs % 60;
	var orig_minutes = (secs / 60) | 0;
	var addLeadingZeroIfNeeded = function (val) {
		return (val < 10) ? ('0' + $elm$core$String$fromInt(val)) : $elm$core$String$fromInt(val);
	};
	var minutes = addLeadingZeroIfNeeded(orig_minutes);
	var seconds = addLeadingZeroIfNeeded(orig_seconds);
	return minutes + (':' + seconds);
};
var $author$project$Page$ShowVariant$makeTitleRow = F2(
	function (mdl, examTimeSecondsRemainingMaybe) {
		return A2(
			$elm$core$List$append,
			function () {
				var nbrQuestionsMaybe = A2(
					$elm$core$Maybe$map,
					$elm$core$String$fromInt,
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.mV;
						},
						mdl.aq));
				var qsDisplay = function () {
					if (!nbrQuestionsMaybe.$) {
						var nbrQuestions = nbrQuestionsMaybe.a;
						return mdl.X ? (nbrQuestions + ' questions') : ('Question ' + ($elm$core$String$fromInt(
							$author$project$Page$ShowVariant$getQuestionNbr(mdl)) + (' of ' + nbrQuestions)));
					} else {
						return '# Questions not specified.';
					}
				}();
				return _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[$mdgriffith$elm_ui$Element$alignLeft]),
						$mdgriffith$elm_ui$Element$text(qsDisplay))
					]);
			}(),
			function () {
				var timeRemaining = A2($elm$core$Maybe$map, $author$project$Helper$Util$digitalClockTextFromSeconds, examTimeSecondsRemainingMaybe);
				var extraTimeButton = function () {
					if (mdl.X) {
						var btnTxt = mdl.by ? '-5 mins' : '+5 mins';
						return A2(
							$author$project$Page$PagesCommon$thinButton,
							true,
							{
								bD: A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX]),
									$mdgriffith$elm_ui$Element$text(btnTxt)),
								ai: $elm$core$Maybe$Just($author$project$Page$ShowVariant$ToggleExtraTime)
							});
					} else {
						return $mdgriffith$elm_ui$Element$none;
					}
				}();
				var clockDisplayMaybe = mdl.X ? A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Basics$mul(60),
						A2(
							$elm$core$Basics$composeR,
							$author$project$Helper$Util$digitalClockTextFromSeconds,
							$elm$core$Basics$append('Time remaining: '))),
					A2(
						$elm$core$Maybe$andThen,
						function ($) {
							return $.fh;
						},
						mdl.aq)) : A2(
					$elm$core$Maybe$map,
					function (el) {
						return 'Time remaining: ' + el;
					},
					timeRemaining);
				if (!clockDisplayMaybe.$) {
					var clockDisplay = clockDisplayMaybe.a;
					return _List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$alignRight,
									$mdgriffith$elm_ui$Element$spacing(10)
								]),
							_List_fromArray(
								[
									extraTimeButton,
									$mdgriffith$elm_ui$Element$text(clockDisplay)
								]))
						]);
				} else {
					return _List_Nil;
				}
			}());
	});
var $author$project$Page$ShowVariant$workArea = function (mdl) {
	return $Punie$elm_reader$Reader$asks(
		function (rState) {
			var titleRow = A2($author$project$Page$ShowVariant$makeTitleRow, mdl, rState.lo);
			var isNapAdmin_ = $author$project$Type$ReaderConfig$isNapAdmin(rState);
			var _v0 = function () {
				var _v1 = mdl.fF;
				if (!_v1.$) {
					var serverErrorMsg = _v1.a;
					return _Utils_Tuple2(
						A2(
							$author$project$Page$ShowVariant$makeErrorContent,
							mdl.ab,
							{b4: serverErrorMsg, fZ: $elm$core$Maybe$Nothing}),
						$elm$core$Maybe$Nothing);
				} else {
					if ($author$project$Page$ShowVariant$isNoQuestionFound(mdl.ab)) {
						var buttonInstruction = $elm$core$List$isEmpty(mdl.oe) ? A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$Font$bold]),
							$mdgriffith$elm_ui$Element$text('Click the \'Close\' button below to go back to your home page')) : A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$Font$bold]),
							$mdgriffith$elm_ui$Element$text('Click the \'Finish\' button below to save your progress.'));
						var content = A2(
							$mdgriffith$elm_ui$Element$column,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$paddingXY, 10, 15)
										]),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('It seems we\'ve run out of questions in this particular topic for your specific level of difficulty.')
										])),
									A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$paddingXY, 10, 15)
										]),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('A notification of this has automatically been sent to us to add more questions for you, but it may take some time.  Since the exact question you get is adaptive (which means it depends on how you answer prior questions,) you may get additional questions and not see this message if you try this activity again.')
										])),
									A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$paddingXY, 10, 15)
										]),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('There\'s nothing else you need to do, but if something else doesn\'t look right or you want to contact us anyway, then please send an email to support@smartermaths.com.au, and we\'ll respond ASAP.')
										])),
									A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_fromArray(
										[
											A2($mdgriffith$elm_ui$Element$paddingXY, 10, 15)
										]),
									_List_fromArray(
										[buttonInstruction]))
								]));
						return _Utils_Tuple2(content, $elm$core$Maybe$Nothing);
					} else {
						var _v2 = mdl.aL;
						switch (_v2.$) {
							case 0:
								return _Utils_Tuple2(
									$mdgriffith$elm_ui$Element$text('No question has yet been requested.'),
									$elm$core$Maybe$Just(
										$mdgriffith$elm_ui$Element$text('No worked solution has yet been requested.')));
							case 1:
								var _v3 = mdl.dv;
								if (_v3.$ === 2) {
									var errMsg = _v3.a;
									return _Utils_Tuple2(
										A2(
											$author$project$Page$ShowVariant$makeErrorContent,
											mdl.ab,
											{b4: errMsg, fZ: $elm$core$Maybe$Nothing}),
										$elm$core$Maybe$Nothing);
								} else {
									return _Utils_Tuple2(
										$mdgriffith$elm_ui$Element$text('Loading the next question.'),
										$elm$core$Maybe$Just(
											$mdgriffith$elm_ui$Element$text('Loading the worked solution.')));
								}
							case 2:
								var err = _v2.a;
								return _Utils_Tuple2(
									A2($author$project$Page$ShowVariant$makeErrorContent, mdl.ab, err),
									$elm$core$Maybe$Nothing);
							default:
								var variantContent = _v2.a;
								var wsContent = $author$project$Helper$HtmlUtil$makeHtmlElements(variantContent.pQ);
								var questionTool = A3($author$project$Page$ShowVariant$makeQuestionTool, rState.mX, mdl, variantContent);
								var qContent = $author$project$Helper$HtmlUtil$makeHtmlElements(variantContent.nE);
								var isQuestionAdminMode = _Utils_eq(mdl.f8, $elm$core$Maybe$Nothing) && isNapAdmin_;
								var answers = function () {
									var _v4 = function () {
										var _v5 = $author$project$Page$ShowVariant$getPageMode(mdl);
										if (!_v5.$) {
											return _Utils_Tuple2(isNapAdmin_, $author$project$Page$ShowVariant$OnVariantAnswer);
										} else {
											switch (_v5.a) {
												case 0:
													var _v6 = _v5.a;
													return _Utils_Tuple2(isNapAdmin_, $author$project$Page$ShowVariant$OnVariantAnswer);
												case 1:
													var _v7 = _v5.a;
													return _Utils_Tuple2(isNapAdmin_, $author$project$Page$ShowVariant$OnVariantAnswer);
												default:
													var _v8 = _v5.a;
													return _Utils_Tuple2(true, $author$project$Page$ShowVariant$OnVariantAnswerDisabled);
											}
										}
									}();
									var isShowAnswerValue = _v4.a;
									var msgFn = _v4.b;
									return A4($author$project$UISnippet$Answer$makeAnswersElements, isShowAnswerValue, msgFn, variantContent.jB, mdl.ao);
								}();
								return _Utils_Tuple2(
									A2(
										$mdgriffith$elm_ui$Element$column,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$spacing(10),
												$mdgriffith$elm_ui$Element$htmlAttribute(
												$elm$html$Html$Attributes$class('content'))
											]),
										_List_fromArray(
											[
												questionTool,
												A2(
												$mdgriffith$elm_ui$Element$column,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
													]),
												_List_fromArray(
													[
														A2($mdgriffith$elm_ui$Element$paragraph, _List_Nil, qContent)
													])),
												answers
											])),
									(_Utils_eq(
										$author$project$Page$ShowVariant$getPageMode(mdl),
										$author$project$Page$ShowVariant$Revision(2)) || isQuestionAdminMode) ? $elm$core$Maybe$Just(
										A2(
											$mdgriffith$elm_ui$Element$column,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
													$mdgriffith$elm_ui$Element$htmlAttribute(
													$elm$html$Html$Attributes$class('content'))
												]),
											$elm$core$List$concat(
												_List_fromArray(
													[
														_List_fromArray(
														[
															A2(
															$mdgriffith$elm_ui$Element$el,
															_List_fromArray(
																[$mdgriffith$elm_ui$Element$Font$italic, $mdgriffith$elm_ui$Element$Font$underline]),
															$mdgriffith$elm_ui$Element$text('Worked Solution')),
															$mdgriffith$elm_ui$Element$text(' ')
														]),
														wsContent
													])))) : $elm$core$Maybe$Nothing);
						}
					}
				}
			}();
			var qArea = _v0.a;
			var wsAreaMaybe = _v0.b;
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 0, 60),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$fillPortion(1))
									]),
								_List_Nil),
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$fillPortion(6)),
										$mdgriffith$elm_ui$Element$spacing(30)
									]),
								(rState.me || (mdl.X || isNapAdmin_)) ? _List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$row,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$Font$bold,
												$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.nn)
											]),
										titleRow),
										A2(
										$mdgriffith$elm_ui$Element$column,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
												A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
												$mdgriffith$elm_ui$Element$Border$rounded(10),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
											]),
										_List_fromArray(
											[
												mdl.X ? A2(
												$Punie$elm_reader$Reader$run,
												$author$project$Page$ShowVariant$instructionsArea(mdl),
												rState) : A2(
												$mdgriffith$elm_ui$Element$column,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$spacing(15)
													]),
												_List_fromArray(
													[qArea]))
											])),
										function () {
										if (mdl.X) {
											return $mdgriffith$elm_ui$Element$none;
										} else {
											if (!wsAreaMaybe.$) {
												var wsArea = wsAreaMaybe.a;
												return A2(
													$mdgriffith$elm_ui$Element$column,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
															A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
															$mdgriffith$elm_ui$Element$Border$rounded(10),
															$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
														]),
													_List_fromArray(
														[wsArea]));
											} else {
												return $mdgriffith$elm_ui$Element$none;
											}
										}
									}(),
										A2(
										$Punie$elm_reader$Reader$run,
										$author$project$Page$ShowVariant$buttonRow(mdl),
										rState),
										A2($author$project$Page$ShowVariant$instrumentationArea, mdl, isNapAdmin_)
									]) : _List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$column,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
												A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
												$mdgriffith$elm_ui$Element$Border$rounded(10),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$spacing(10)
											]),
										_List_fromArray(
											[
												A2(
												$mdgriffith$elm_ui$Element$paragraph,
												_List_Nil,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$text('It looks like you may have pressed back in the browser, or somehow got to this page another way, whilst not in an exam.')
													])),
												A2(
												$mdgriffith$elm_ui$Element$paragraph,
												_List_Nil,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$text('Please click home and try an exam again!')
													]))
											]))
									])),
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$fillPortion(1))
									]),
								_List_Nil)
							]))
					]));
		});
};
var $author$project$Page$ShowVariant$view = F2(
	function (model, topBarFn) {
		var addToElements = F2(
			function (el, els) {
				return A2(
					$Punie$elm_reader$Reader$map,
					function (el2) {
						return A2($elm$core$List$cons, el2, els);
					},
					el);
			});
		return A3(
			$Punie$elm_reader$Reader$map2,
			F2(
				function (color, els) {
					return A2(
						$mdgriffith$elm_ui$Element$layout,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$size(16),
								$mdgriffith$elm_ui$Element$Background$color(color)
							]),
						A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
								]),
							els));
				}),
			$author$project$Page$PagesCommon$makeBackgroundStyle,
			A2(
				$Punie$elm_reader$Reader$andThen,
				addToElements(
					A2(topBarFn, $author$project$Page$ShowVariant$SignOut, $author$project$Page$ShowVariant$LinkClicked)),
				A2(
					$Punie$elm_reader$Reader$andThen,
					addToElements(
						$author$project$Page$ShowVariant$workArea(model)),
					$Punie$elm_reader$Reader$reader(_List_Nil))));
	});
var $author$project$Page$ShowWorksheet$LinkClicked = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$ShowWorksheet$SignOut = {$: 0};
var $author$project$Page$ShowWorksheet$buildingWorksheetContent = F2(
	function (doneCount, totalCount) {
		return $mdgriffith$elm_ui$Element$text(
			'Building question ' + ($elm$core$String$fromInt(doneCount) + (' of ' + ($elm$core$String$fromInt(totalCount) + ' for your worksheet...'))));
	});
var $author$project$Page$ShowWorksheet$failuresBlock = function (mdl) {
	return $elm$core$List$isEmpty(mdl.av) ? $mdgriffith$elm_ui$Element$none : A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
				A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
				$mdgriffith$elm_ui$Element$Border$rounded(10),
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$spacing(5)
			]),
		A2(
			$elm$core$List$cons,
			A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$bold,
						$mdgriffith$elm_ui$Element$Font$underline,
						A2($mdgriffith$elm_ui$Element$paddingXY, 0, 15)
					]),
				$mdgriffith$elm_ui$Element$text('The following errors have occurred')),
			A2(
				$elm$core$List$map,
				function (el) {
					return A2(
						$mdgriffith$elm_ui$Element$paragraph,
						_List_Nil,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$text(el)
							]));
				},
				mdl.av)));
};
var $author$project$Page$ShowWorksheet$DoNothing = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$ShowWorksheet$footer = A2(
	$mdgriffith$elm_ui$Element$row,
	_List_fromArray(
		[
			A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10),
			$mdgriffith$elm_ui$Element$Border$solid,
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
			$mdgriffith$elm_ui$Element$Border$widthEach(
			{er: 1, e9: 0, fz: 0, fY: 0})
		]),
	_List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$centerX,
					$mdgriffith$elm_ui$Element$Font$italic,
					$mdgriffith$elm_ui$Element$Font$size(11)
				]),
			$mdgriffith$elm_ui$Element$text('Copyright SmarterMaths (c) 2015-2020'))
		]));
var $author$project$Page$ShowWorksheet$header = F6(
	function (syllabuses, activityDataMaybe, personEDetails, nbrQuestions, activityResultRecord, isWorksheetBasedOnResults) {
		var topicDescElement = function (el) {
			return $mdgriffith$elm_ui$Element$html(
				A2($elm$html$Html$p, _List_Nil, el));
		}(
			A2(
				$pablohirafuji$elm_markdown$Markdown$toHtml,
				$elm$core$Maybe$Nothing,
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.ja;
						},
						activityDataMaybe))));
		var syllabusHeading = A2(
			$elm$core$Maybe$withDefault,
			'Worksheet',
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					A2($elm$core$String$replace, '<p>', ''),
					A2($elm$core$String$replace, '</p>', '')),
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.k8;
					},
					A2(
						$elm$core$Maybe$andThen,
						$author$project$Type$Syllabus$getSyllabusById(syllabuses),
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.bf;
							},
							activityDataMaybe)))));
		var smarterMathsLogo = A2(
			$mdgriffith$elm_ui$Element$image,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$alignRight,
					$mdgriffith$elm_ui$Element$alignTop,
					$mdgriffith$elm_ui$Element$width(
					$mdgriffith$elm_ui$Element$px(150))
				]),
			{gT: 'SmarterMaths Logo', ov: 'https://teacher.smartermaths.com.au/wp-content/uploads/2014/09/Generic-logo.png'});
		var questionsTxt = 'Questions: ' + $elm$core$String$fromInt(nbrQuestions);
		var preparedForTxt = $mdgriffith$elm_ui$Element$text(
			'TAILORED WORKSHEET: ' + (personEDetails.lt + (' ' + (personEDetails.mq + (' on ' + $author$project$Type$DateTime$toDateString(activityResultRecord.pr))))));
		var minutes = $elm$core$String$fromInt(
			A2($elm$core$Basics$min, 20, nbrQuestions));
		var durationTxt = 'Time: ' + (minutes + ' minutes');
		var _v0 = function () {
			var commentBase = 'Comment: Worksheet generated from recent online exam results';
			var _v1 = activityResultRecord.jA;
			if ((_v1.$ === 1) && (!_v1.a)) {
				var _v2 = _v1.a;
				return _Utils_Tuple2(
					'Worksheet A',
					isWorksheetBasedOnResults ? $mdgriffith$elm_ui$Element$text(commentBase + ', including all questions answered incorrectly.') : $mdgriffith$elm_ui$Element$none);
			} else {
				return _Utils_Tuple2(
					'Worksheet B',
					isWorksheetBasedOnResults ? $mdgriffith$elm_ui$Element$text(commentBase) : $mdgriffith$elm_ui$Element$none);
			}
		}();
		var worksheetTypeTxt = _v0.a;
		var comment = _v0.b;
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.gA),
					A2($mdgriffith$elm_ui$Element$paddingXY, 20, 20),
					$mdgriffith$elm_ui$Element$Border$rounded(5),
					$mdgriffith$elm_ui$Element$Border$solid,
					$mdgriffith$elm_ui$Element$Border$width(1)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(10),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Font$underline,
									$mdgriffith$elm_ui$Element$Font$size(22),
									$mdgriffith$elm_ui$Element$Font$italic
								]),
							$mdgriffith$elm_ui$Element$text(syllabusHeading)),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Element$paddingXY, 10, 10),
									$mdgriffith$elm_ui$Element$spacing(10)
								]),
							_List_fromArray(
								[
									topicDescElement,
									A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_Nil,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text(worksheetTypeTxt)
										])),
									$mdgriffith$elm_ui$Element$text(questionsTxt),
									$mdgriffith$elm_ui$Element$text(durationTxt),
									A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_Nil,
									_List_fromArray(
										[comment])),
									A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$italic,
											$mdgriffith$elm_ui$Element$paddingEach(
											{er: 0, e9: 0, fz: 0, fY: 5})
										]),
									_List_fromArray(
										[preparedForTxt]))
								]))
						])),
					smarterMathsLogo
				]));
	});
var $author$project$Page$ShowWorksheet$makeHeading = function (txt) {
	return A2(
		$mdgriffith$elm_ui$Element$row,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.gA),
				A2($mdgriffith$elm_ui$Element$paddingXY, 20, 20),
				$mdgriffith$elm_ui$Element$Border$rounded(5),
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Font$underline,
						$mdgriffith$elm_ui$Element$Font$bold,
						$mdgriffith$elm_ui$Element$Font$italic,
						$mdgriffith$elm_ui$Element$Font$size(22)
					]),
				$mdgriffith$elm_ui$Element$text(txt))
			]));
};
var $author$project$Page$ShowWorksheet$viewWorksheetContent = F2(
	function (model, activityResultRecord) {
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				var renderHtmlElements = F2(
					function (idx, elements) {
						return A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(10),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$htmlAttribute(
									A2($elm$html$Html$Attributes$style, 'overflow', 'hidden')),
									$mdgriffith$elm_ui$Element$htmlAttribute(
									A2($elm$html$Html$Attributes$style, 'pageBreakInside', 'avoid'))
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$alignTop,
											A2($mdgriffith$elm_ui$Element$paddingXY, 15, 5),
											$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$Border$dotted,
											$mdgriffith$elm_ui$Element$Border$widthEach(
											{er: 0, e9: 0, fz: 1, fY: 0})
										]),
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$Font$italic,
													$mdgriffith$elm_ui$Element$Font$size(20)
												]),
											$mdgriffith$elm_ui$Element$text(
												$elm$core$String$fromInt(idx + 1) + '.'))
										])),
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$paddingEach(
											{er: 10, e9: 0, fz: 0, fY: 5}),
											$mdgriffith$elm_ui$Element$Border$color($author$project$Theme$Style$t.jG),
											$mdgriffith$elm_ui$Element$Border$solid,
											$mdgriffith$elm_ui$Element$Border$widthEach(
											{er: 1, e9: 0, fz: 0, fY: 0})
										]),
									elements)
								]));
					});
				var nodeItems = $author$project$Type$NodeCfg$getItems(rState.mX.fs);
				var itemElements = A2(
					$elm$core$List$map,
					function (el1) {
						var questionTool = A2(
							$elm$core$List$member,
							$author$project$Type$Syllabus$getSyllabusShortCode(rState.mX.cF) + '_ca',
							A2($author$project$Type$NodeCfg$getItemTags, rState.mX.fs, el1.pK)) ? A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$paddingEach(
									{er: 10, e9: 0, fz: 0, fY: 0})
								]),
							A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
										$mdgriffith$elm_ui$Element$paddingEach(
										{er: 10, e9: 0, fz: 0, fY: 0}),
										$mdgriffith$elm_ui$Element$Border$widthEach(
										{er: 1, e9: 0, fz: 0, fY: 0}),
										$mdgriffith$elm_ui$Element$Border$dashed
									]),
								A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$alignRight, $mdgriffith$elm_ui$Element$Font$bold]),
									$mdgriffith$elm_ui$Element$text('CALCULATOR ALLOWED')))) : $mdgriffith$elm_ui$Element$none;
						return {
							jy: _List_fromArray(
								[
									A4($author$project$UISnippet$Answer$makeAnswersElements, false, $author$project$Page$ShowWorksheet$DoNothing, el1.jB, $elm$core$Maybe$Nothing)
								]),
							ir: A2(
								$elm$core$List$cons,
								questionTool,
								$author$project$Helper$HtmlUtil$makeHtmlElements(el1.nE)),
							ju: $author$project$Helper$HtmlUtil$makeHtmlElements(el1.pQ)
						};
					},
					A2(
						$elm$core$List$sortBy,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.pK;
							},
							A2(
								$elm$core$Basics$composeR,
								$author$project$Type$ExamResult$getDifficultyForVariantId(nodeItems),
								$elm$core$Maybe$withDefault(0))),
						model.aM));
				return A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$htmlAttribute(
							$elm$html$Html$Attributes$class('content')),
							$mdgriffith$elm_ui$Element$spacing(30),
							$mdgriffith$elm_ui$Element$htmlAttribute(
							A2($elm$html$Html$Attributes$style, 'display', 'block'))
						]),
					$elm$core$List$concat(
						_List_fromArray(
							[
								_List_fromArray(
								[
									A6(
									$author$project$Page$ShowWorksheet$header,
									rState.mX.oJ,
									model.c9,
									$author$project$Type$User$extractPersonEDetails(rState.fc),
									$elm$core$List$length(model.aM),
									activityResultRecord,
									model.dG)
								]),
								_List_fromArray(
								[
									$author$project$Page$ShowWorksheet$makeHeading('Questions')
								]),
								A2(
								$elm$core$List$indexedMap,
								renderHtmlElements,
								A3(
									$elm$core$List$map2,
									F2(
										function (els1, els2) {
											return A2($elm$core$List$append, els1, els2);
										}),
									A2(
										$elm$core$List$map,
										function ($) {
											return $.ir;
										},
										itemElements),
									A2(
										$elm$core$List$map,
										function ($) {
											return $.jy;
										},
										itemElements))),
								_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									$author$project$Page$ShowWorksheet$footer)
								]),
								_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$htmlAttribute(
											A2($elm$html$Html$Attributes$style, 'pageBreakBefore', 'always'))
										]),
									$author$project$Page$ShowWorksheet$makeHeading('Solutions'))
								]),
								A2(
								$elm$core$List$indexedMap,
								renderHtmlElements,
								A2(
									$elm$core$List$map,
									function ($) {
										return $.ju;
									},
									itemElements)),
								_List_fromArray(
								[$author$project$Page$ShowWorksheet$footer])
							])));
			});
	});
var $author$project$Page$ShowWorksheet$workArea = function (mdl) {
	return $Punie$elm_reader$Reader$asks(
		function (rState) {
			var doneCount = $elm$core$List$length(mdl.aM) + $elm$core$List$length(mdl.av);
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$fillPortion(1))
									]),
								_List_Nil),
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$px(1100))
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$row,
										_List_fromArray(
											[
												A2($mdgriffith$elm_ui$Element$paddingXY, 0, 30),
												$mdgriffith$elm_ui$Element$htmlAttribute(
												$elm$html$Html$Attributes$class('no-print'))
											]),
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$none])),
										$author$project$Page$ShowWorksheet$failuresBlock(mdl),
										A2(
										$mdgriffith$elm_ui$Element$column,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
												A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
												$mdgriffith$elm_ui$Element$Border$rounded(10),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
											]),
										_List_fromArray(
											[
												function () {
												var _v0 = mdl.ed;
												if (_v0.$ === 1) {
													return $mdgriffith$elm_ui$Element$text('Preparing to build your worksheet...');
												} else {
													var activityResultRecord = _v0.a;
													var totalCount = $elm$core$List$length(activityResultRecord.ci);
													return (_Utils_cmp(doneCount, totalCount) < 0) ? A2($author$project$Page$ShowWorksheet$buildingWorksheetContent, doneCount, totalCount) : A2(
														$Punie$elm_reader$Reader$run,
														A2($author$project$Page$ShowWorksheet$viewWorksheetContent, mdl, activityResultRecord),
														rState);
												}
											}()
											]))
									])),
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$fillPortion(1))
									]),
								_List_Nil)
							]))
					]));
		});
};
var $author$project$Page$ShowWorksheet$view = F2(
	function (model, topBarFn) {
		var addToElements = F2(
			function (el, els) {
				return A2(
					$Punie$elm_reader$Reader$map,
					function (el2) {
						return A2($elm$core$List$cons, el2, els);
					},
					el);
			});
		return A3(
			$Punie$elm_reader$Reader$map2,
			F2(
				function (color, els) {
					return A2(
						$mdgriffith$elm_ui$Element$layout,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$size(16),
								$mdgriffith$elm_ui$Element$Background$color(color)
							]),
						A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
								]),
							els));
				}),
			$author$project$Page$PagesCommon$makeBackgroundStyle,
			A2(
				$Punie$elm_reader$Reader$andThen,
				addToElements(
					A2(topBarFn, $author$project$Page$ShowWorksheet$SignOut, $author$project$Page$ShowWorksheet$LinkClicked)),
				A2(
					$Punie$elm_reader$Reader$andThen,
					addToElements(
						$author$project$Page$ShowWorksheet$workArea(model)),
					$Punie$elm_reader$Reader$reader(_List_Nil))));
	});
var $author$project$Page$ShowWorksheets$LinkClicked = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$ShowWorksheets$SignOut = {$: 0};
var $author$project$Page$ShowWorksheets$CreateWorksheetStart = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$ShowWorksheets$generationAreaAlreadyCreatedMsg = function (activityDataGroup) {
	return A2(
		$mdgriffith$elm_ui$Element$column,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$spacing(10),
				A2($mdgriffith$elm_ui$Element$paddingXY, 40, 0)
			]),
		_List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_Nil,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('Please find your worksheets in the list below.')
					])),
				A2(
				$mdgriffith$elm_ui$Element$paragraph,
				_List_Nil,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('To generate more worksheets for this exam [' + (activityDataGroup.f8.ja + '], please do the online exam, after which we can create customized worksheets for you based on your most recent results.'))
					]))
			]));
};
var $author$project$Page$ShowWorksheets$getCurrentGeneratedWorksheetMaybe = F2(
	function (activityResultRecords, worksheetType) {
		var getCurrentGeneratedWorksheetMaybe_ = function (actRRsDescending) {
			getCurrentGeneratedWorksheetMaybe_:
			while (true) {
				var actRRMaybe = $elm$core$List$head(actRRsDescending);
				if (actRRMaybe.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var actRR = actRRMaybe.a;
					var _v1 = actRR.jA;
					if (_v1.$ === 1) {
						var wsType = _v1.a;
						if (_Utils_eq(wsType, worksheetType)) {
							return actRRMaybe;
						} else {
							var $temp$actRRsDescending = A2($elm$core$List$drop, 1, actRRsDescending);
							actRRsDescending = $temp$actRRsDescending;
							continue getCurrentGeneratedWorksheetMaybe_;
						}
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}
			}
		};
		return getCurrentGeneratedWorksheetMaybe_(
			$elm$core$List$reverse(
				A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.pr;
					},
					activityResultRecords)));
	});
var $author$project$Type$ExamResult$isWorksheet = function (actRR) {
	var _v0 = actRR.jA;
	if (_v0.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Type$ExamResult$getWorksheets = function (activityResultRecords) {
	return A2($elm$core$List$filter, $author$project$Type$ExamResult$isWorksheet, activityResultRecords);
};
var $author$project$Page$ShowWorksheets$generationArea = F2(
	function (model, activityDataGroup) {
		var worksheets = $author$project$Type$ExamResult$getWorksheets(activityDataGroup.cy);
		var makeWorksheetButtonLinkRow = function (actrr) {
			var makeRow = function (wsTypeString) {
				return A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							A2($mdgriffith$elm_ui$Element$paddingXY, 15, 15),
							$mdgriffith$elm_ui$Element$Border$rounded(5),
							$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.gA)
						]),
					$mdgriffith$elm_ui$Element$text('Worksheet ' + (wsTypeString + ' was created and is listed below')));
			};
			var _v3 = actrr.jA;
			if (_v3.$ === 1) {
				if (!_v3.a) {
					var _v4 = _v3.a;
					return makeRow('A');
				} else {
					var _v5 = _v3.a;
					return makeRow('B');
				}
			} else {
				return $mdgriffith$elm_ui$Element$none;
			}
		};
		var makeButton = F3(
			function (isActive, txt, msgMaybe) {
				return A6(
					$author$project$Page$PagesCommon$configurableButton,
					isActive,
					$author$project$Theme$Style$t.f6,
					$author$project$Theme$Style$t.jG,
					_List_Nil,
					_List_Nil,
					{
						bD: A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]),
							$mdgriffith$elm_ui$Element$text(txt)),
						ai: msgMaybe
					});
			});
		var makeWorksheetButtonCreateRow = function (worksheetType) {
			var btnTxt = function () {
				var worksheetCountDesc = A2(
					$elm$core$Maybe$withDefault,
					false,
					A2(
						$elm$core$Maybe$map,
						$elm$core$Basics$eq(worksheetType),
						model.aY)) ? (': Creating Question ' + ($elm$core$String$fromInt(
					$elm$core$List$length(model.aO)) + (' of ' + $elm$core$String$fromInt($author$project$Page$ShowWorksheets$worksheetQstnsLength)))) : (model.cP ? '' : ' (Button enabled for student)');
				if (!worksheetType) {
					return 'Create Worksheet A' + worksheetCountDesc;
				} else {
					return 'Create Worksheet B' + worksheetCountDesc;
				}
			}();
			var _v1 = (_Utils_eq(model.aY, $elm$core$Maybe$Nothing) && model.cP) ? _Utils_Tuple2(
				true,
				$elm$core$Maybe$Just(
					$author$project$Page$ShowWorksheets$CreateWorksheetStart(worksheetType))) : _Utils_Tuple2(false, $elm$core$Maybe$Nothing);
			var isActive = _v1.a;
			var msgMaybe = _v1.b;
			return A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				A3(makeButton, isActive, btnTxt, msgMaybe));
		};
		var isActRRsContainsOnlineResults = A2(
			$elm$core$List$any,
			function (el) {
				var _v0 = el.jA;
				if (!_v0.$) {
					return true;
				} else {
					return false;
				}
			},
			activityDataGroup.cy);
		var getAlreadyGenWsMaybe = $author$project$Page$ShowWorksheets$getCurrentGeneratedWorksheetMaybe(activityDataGroup.cy);
		var wsAMaybe = getAlreadyGenWsMaybe(0);
		var wsBMaybe = getAlreadyGenWsMaybe(1);
		var bulletText = function (txt) {
			return $mdgriffith$elm_ui$Element$html(
				A2(
					$elm$html$Html$ul,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$li,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'list-style', 'disc')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(txt)
								]))
						])));
		};
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(20),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
					A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
					$mdgriffith$elm_ui$Element$Border$rounded(10)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$spacing(5),
							$mdgriffith$elm_ui$Element$paddingEach(
							{er: 10, e9: 0, fz: 0, fY: 0})
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$Font$italic]),
							$mdgriffith$elm_ui$Element$text(
								A2(
									$elm$core$Maybe$withDefault,
									'An error has occurred',
									A2(
										$elm$core$Maybe$map,
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.f8;
											},
											function ($) {
												return $.ja;
											}),
										model.aZ)))),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]),
							$mdgriffith$elm_ui$Element$text(
								isActRRsContainsOnlineResults ? 'Create customized worksheets based on your most recent exam results' : 'Create a worksheet'))
						])),
					function () {
					if ((!_Utils_eq(wsAMaybe, $elm$core$Maybe$Nothing)) && (!_Utils_eq(wsBMaybe, $elm$core$Maybe$Nothing))) {
						return $author$project$Page$ShowWorksheets$generationAreaAlreadyCreatedMsg(activityDataGroup);
					} else {
						var wsBBullets = isActRRsContainsOnlineResults ? _List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										bulletText('Further revision questions targeting the optimal difficulty band based on your latest exam results')
									]))
							]) : _List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										bulletText('No exam history currently exists')
									])),
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										bulletText('Further  questions that target difficulty levels from below, to above, the pass mark requirements')
									]))
							]);
						var wsABullets = isActRRsContainsOnlineResults ? _List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										bulletText('Includes all questions answered incorrectly in your most recent online exam')
									])),
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										bulletText('Includes new questions targeting the optimal difficulty band based on your latest exam results')
									]))
							]) : _List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										bulletText('No exam history currently exists')
									])),
								A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										bulletText('Questions target difficulty levels from below, to above, the pass mark requirements')
									]))
							]);
						return A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(20)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$alignTop,
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$fillPortion(1)),
											$mdgriffith$elm_ui$Element$Border$dashed,
											$mdgriffith$elm_ui$Element$Border$width(1),
											$mdgriffith$elm_ui$Element$Border$rounded(10),
											A2($mdgriffith$elm_ui$Element$paddingXY, 20, 20)
										]),
									_List_fromArray(
										[
											A2(
											$elm$core$Maybe$withDefault,
											makeWorksheetButtonCreateRow(0),
											A2($elm$core$Maybe$map, makeWorksheetButtonLinkRow, wsAMaybe)),
											A2(
											$mdgriffith$elm_ui$Element$column,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$centerX,
													$mdgriffith$elm_ui$Element$paddingEach(
													{er: 10, e9: 40, fz: 40, fY: 20}),
													$mdgriffith$elm_ui$Element$spacing(10)
												]),
											wsABullets)
										])),
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$alignTop,
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$fillPortion(1)),
											$mdgriffith$elm_ui$Element$Border$dashed,
											$mdgriffith$elm_ui$Element$Border$width(1),
											$mdgriffith$elm_ui$Element$Border$rounded(10),
											A2($mdgriffith$elm_ui$Element$paddingXY, 20, 20)
										]),
									_List_fromArray(
										[
											A2(
											$elm$core$Maybe$withDefault,
											makeWorksheetButtonCreateRow(1),
											A2($elm$core$Maybe$map, makeWorksheetButtonLinkRow, wsBMaybe)),
											A2(
											$mdgriffith$elm_ui$Element$column,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$centerX,
													$mdgriffith$elm_ui$Element$paddingEach(
													{er: 10, e9: 40, fz: 40, fY: 20}),
													$mdgriffith$elm_ui$Element$spacing(10)
												]),
											wsBBullets)
										]))
								]));
					}
				}()
				]));
	});
var $author$project$Type$ExamResult$getWorksheetsAndOnlinesTuple = function (activityResultRecords) {
	return A2($elm$core$List$partition, $author$project$Type$ExamResult$isWorksheet, activityResultRecords);
};
var $author$project$Page$ShowWorksheets$ViewWorksheet = F3(
	function (a, b, c) {
		return {$: 6, a: a, b: b, c: c};
	});
var $author$project$Page$ShowWorksheets$columnsForWorksheetLinksTable = F3(
	function (nodeCfg, activityData, earliestOnlineExamTimestampMaybe) {
		var makeWorksheetTypeText = function (activityMode) {
			if ((activityMode.$ === 1) && (!activityMode.a)) {
				var _v1 = activityMode.a;
				return 'Worksheet A';
			} else {
				return 'Worksheet B';
			}
		};
		var makeButton = F2(
			function (txt, msgMaybe) {
				return A2(
					$author$project$Page$PagesCommon$thinButton,
					true,
					{
						bD: A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerX]),
							$mdgriffith$elm_ui$Element$text(txt)),
						ai: msgMaybe
					});
			});
		var cellDefaults = _List_fromArray(
			[
				A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5)
			]);
		var borderDefaults = _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Border$widthEach(
				{er: 1, e9: 0, fz: 0, fY: 0})
			]);
		var dashedBorder = A2($elm$core$List$cons, $mdgriffith$elm_ui$Element$Border$dashed, borderDefaults);
		var rowCellDefaults = $elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$centerY
					]),
					cellDefaults,
					dashedBorder
				]));
		var makeRow = function (element) {
			return A2($mdgriffith$elm_ui$Element$el, rowCellDefaults, element);
		};
		var solidBorder = A2($elm$core$List$cons, $mdgriffith$elm_ui$Element$Border$solid, borderDefaults);
		var headerCellDefaults = $elm$core$List$concat(
			_List_fromArray(
				[cellDefaults, solidBorder]));
		var makeHeader = function (headerText) {
			return A2(
				$mdgriffith$elm_ui$Element$el,
				headerCellDefaults,
				$mdgriffith$elm_ui$Element$text(headerText));
		};
		return _List_fromArray(
			[
				{
				dB: makeHeader('Worksheet type'),
				pM: function (el_) {
					return makeRow(
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerY]),
							$mdgriffith$elm_ui$Element$text(
								makeWorksheetTypeText(el_.jA))));
				},
				ec: $mdgriffith$elm_ui$Element$fill
			},
				{
				dB: makeHeader('Date created'),
				pM: function (el_) {
					return makeRow(
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$centerY]),
							$mdgriffith$elm_ui$Element$text(
								$author$project$Type$DateTime$toDateString(el_.pr))));
				},
				ec: $mdgriffith$elm_ui$Element$fill
			},
				{
				dB: makeHeader('Action'),
				pM: function (el_) {
					return makeRow(
						A2(
							makeButton,
							'View',
							$elm$core$Maybe$Just(
								A3(
									$author$project$Page$ShowWorksheets$ViewWorksheet,
									activityData,
									el_,
									A2(
										$elm$core$Maybe$withDefault,
										false,
										A2(
											$elm$core$Maybe$map,
											function (earliestOnline) {
												return _Utils_cmp(el_.pr, earliestOnline) > 0;
											},
											earliestOnlineExamTimestampMaybe))))));
				},
				ec: $mdgriffith$elm_ui$Element$fill
			}
			]);
	});
var $author$project$Page$ShowWorksheets$worksheetLinksTable = F3(
	function (activityData, worksheets, earliestOnlineExamTimestampMaybe) {
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				return A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(10),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[$mdgriffith$elm_ui$Element$Font$italic]),
							$mdgriffith$elm_ui$Element$text('View Created Worksheets')),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$table,
								_List_Nil,
								{
									kC: A3($author$project$Page$ShowWorksheets$columnsForWorksheetLinksTable, rState.mX, activityData, earliestOnlineExamTimestampMaybe),
									k_: worksheets
								})
							])));
			});
	});
var $author$project$Page$ShowWorksheets$worksheetLinksArea = function (activityDataGroup) {
	return $Punie$elm_reader$Reader$asks(
		function (rState) {
			var worksheetsAndOnlinesTuple = $author$project$Type$ExamResult$getWorksheetsAndOnlinesTuple(activityDataGroup.cy);
			var _v0 = worksheetsAndOnlinesTuple;
			var worksheets = _v0.a;
			var onlines = _v0.b;
			var earliestOnlineExamTimestampMaybe = $elm$core$List$minimum(
				A2(
					$elm$core$List$map,
					function ($) {
						return $.pr;
					},
					onlines));
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
						A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
						$mdgriffith$elm_ui$Element$Border$rounded(10),
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						$elm$core$List$isEmpty(worksheets) ? $mdgriffith$elm_ui$Element$text('You have not yet generated any worksheets for: ' + activityDataGroup.f8.ja) : A2(
						$Punie$elm_reader$Reader$run,
						A3($author$project$Page$ShowWorksheets$worksheetLinksTable, activityDataGroup.f8, worksheets, earliestOnlineExamTimestampMaybe),
						rState)
					]));
		});
};
var $author$project$Page$ShowWorksheets$workArea = function (model) {
	return $Punie$elm_reader$Reader$asks(
		function (rState) {
			return A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 0, 60),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$fillPortion(1))
									]),
								_List_Nil),
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$fillPortion(6)),
										$mdgriffith$elm_ui$Element$spacing(30)
									]),
								_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$row,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.nn),
														$mdgriffith$elm_ui$Element$Font$size(30),
														$mdgriffith$elm_ui$Element$Font$bold
													]),
												$mdgriffith$elm_ui$Element$text('Worksheet Zone')),
												A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$Font$family(
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$Font$typeface('FontAwesome')
															])),
														$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$white),
														$mdgriffith$elm_ui$Element$Font$size(30),
														A2($mdgriffith$elm_ui$Element$paddingXY, 5, 0)
													]),
												$mdgriffith$elm_ui$Element$text('\uF059'))
											])),
										function () {
										var _v0 = model.aZ;
										if (_v0.$ === 1) {
											return A2(
												$mdgriffith$elm_ui$Element$column,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
														A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
														$mdgriffith$elm_ui$Element$Border$rounded(10),
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
													]),
												_List_fromArray(
													[
														A2(
														$mdgriffith$elm_ui$Element$paragraph,
														_List_Nil,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$text('Sorry, but there is a mis-configuration of the application and we are unable to display the worksheets page for you.  Please try logging out and back in, and if the problem persists, please notify SmarterMaths at support@smartermaths.com.au along with a screenshot of this page if possible, and we\'ll get it fixed ASAP!')
															]))
													]));
										} else {
											var activityDataGroup = _v0.a;
											return A2(
												$mdgriffith$elm_ui$Element$column,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
														$mdgriffith$elm_ui$Element$spacing(20)
													]),
												_List_fromArray(
													[
														A2($author$project$Page$ShowWorksheets$generationArea, model, activityDataGroup),
														A2(
														$Punie$elm_reader$Reader$run,
														$author$project$Page$ShowWorksheets$worksheetLinksArea(activityDataGroup),
														rState)
													]));
										}
									}()
									])),
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$fillPortion(1))
									]),
								_List_Nil)
							]))
					]));
		});
};
var $author$project$Page$ShowWorksheets$view = F2(
	function (model, topBarFn) {
		var addToElements = F2(
			function (el, els) {
				return A2(
					$Punie$elm_reader$Reader$map,
					function (el2) {
						return A2($elm$core$List$cons, el2, els);
					},
					el);
			});
		return A3(
			$Punie$elm_reader$Reader$map2,
			F2(
				function (color, els) {
					return A2(
						$mdgriffith$elm_ui$Element$layout,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$size(16),
								$mdgriffith$elm_ui$Element$Background$color(color)
							]),
						A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
								]),
							els));
				}),
			$author$project$Page$PagesCommon$makeBackgroundStyle,
			A2(
				$Punie$elm_reader$Reader$andThen,
				addToElements(
					A2(topBarFn, $author$project$Page$ShowWorksheets$SignOut, $author$project$Page$ShowWorksheets$LinkClicked)),
				A2(
					$Punie$elm_reader$Reader$andThen,
					addToElements(
						$author$project$Page$ShowWorksheets$workArea(model)),
					$Punie$elm_reader$Reader$reader(_List_Nil))));
	});
var $author$project$Page$SignIn$LinkClicked = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$SignInPagesCommon$linkTextAttrs = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.nn),
		$mdgriffith$elm_ui$Element$Font$size(16),
		$mdgriffith$elm_ui$Element$Font$underline,
		$mdgriffith$elm_ui$Element$padding(10),
		$mdgriffith$elm_ui$Element$centerX,
		$mdgriffith$elm_ui$Element$pointer
	]);
var $author$project$Page$SignInPagesCommon$makeLinkBase = F2(
	function (linkText, attrs) {
		return A2(
			$mdgriffith$elm_ui$Element$el,
			attrs,
			$mdgriffith$elm_ui$Element$text(linkText));
	});
var $author$project$Page$SignInPagesCommon$makeLink = F2(
	function (linkText, linkOnClick) {
		return A2(
			$author$project$Page$SignInPagesCommon$makeLinkBase,
			linkText,
			A2(
				$elm$core$List$cons,
				$mdgriffith$elm_ui$Element$Events$onClick(linkOnClick),
				$author$project$Page$SignInPagesCommon$linkTextAttrs));
	});
var $author$project$Page$SignInPagesCommon$makeLinkText = function (linkText) {
	return A2(
		$author$project$Page$SignInPagesCommon$makeLinkBase,
		linkText,
		A2(
			$elm$core$List$cons,
			$mdgriffith$elm_ui$Element$htmlAttribute(
				$elm$html$Html$Attributes$id('smHaPageLinktext')),
			$author$project$Page$SignInPagesCommon$linkTextAttrs));
};
var $author$project$Page$SignIn$forgotPassword = A2(
	$mdgriffith$elm_ui$Element$row,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
		]),
	_List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$alignLeft]),
			A2(
				$author$project$Page$SignInPagesCommon$makeLink,
				'Forgot your password?',
				$author$project$Page$SignIn$LinkClicked(
					$author$project$Routing$withHash($author$project$Routing$smPaths.lB)))),
			A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$alignRight]),
			$author$project$Page$SignInPagesCommon$makeLinkText('Don\'t have an account?'))
		]));
var $author$project$Page$SignInPagesCommon$signInArea = $mdgriffith$elm_ui$Element$column(
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$centerX,
			$mdgriffith$elm_ui$Element$centerY,
			$mdgriffith$elm_ui$Element$width(
			A2($mdgriffith$elm_ui$Element$maximum, 600, $mdgriffith$elm_ui$Element$fill))
		]));
var $author$project$Page$SignIn$SetEmail = function (a) {
	return {$: 2, a: a};
};
var $mdgriffith$elm_ui$Element$Input$username = $mdgriffith$elm_ui$Element$Input$textHelper(
	{
		as: $elm$core$Maybe$Just('username'),
		aG: false,
		pu: $mdgriffith$elm_ui$Element$Input$TextInputNode('text')
	});
var $author$project$Page$SignIn$emailField = function (mdl) {
	var decorateAttrs = $author$project$Page$SignInPagesCommon$getDecorateAttrsFn(mdl.b3);
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10)
			]),
		A2(
			$mdgriffith$elm_ui$Element$Input$username,
			decorateAttrs(
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$Input$focusedOnLoad])),
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_Nil,
					A2(
						$mdgriffith$elm_ui$Element$el,
						decorateAttrs(_List_Nil),
						$mdgriffith$elm_ui$Element$text('Email'))),
				cS: $author$project$Page$SignIn$SetEmail,
				cV: mdl.ds,
				cY: mdl.li
			}));
};
var $author$project$Page$SignInPagesCommon$msgArea = function (serverErrorMsgMaybe) {
	return A2($author$project$Page$SignInPagesCommon$msgAreaWithColor, $author$project$Theme$Style$t.eC, serverErrorMsgMaybe);
};
var $author$project$Page$SignIn$SetPassword = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$SignIn$ToggleIsShowPassword = {$: 5};
var $mdgriffith$elm_ui$Element$Input$currentPassword = F2(
	function (attrs, pass) {
		return A3(
			$mdgriffith$elm_ui$Element$Input$textHelper,
			{
				as: $elm$core$Maybe$Just('current-password'),
				aG: false,
				pu: $mdgriffith$elm_ui$Element$Input$TextInputNode(
					pass.iR ? 'text' : 'password')
			},
			attrs,
			{bD: pass.bD, cS: pass.cS, cV: pass.cV, cY: pass.cY});
	});
var $author$project$Page$SignIn$passwordField = function (mdl) {
	var showOrHideText = mdl.cN ? 'Hide' : 'Show';
	var decorateAttrs = $author$project$Page$SignInPagesCommon$getDecorateAttrsFn(mdl.cg);
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10)
			]),
		A2(
			$mdgriffith$elm_ui$Element$Input$currentPassword,
			_List_Nil,
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_Nil,
					A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								decorateAttrs(_List_Nil),
								$mdgriffith$elm_ui$Element$text('Password')),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.eB),
										$mdgriffith$elm_ui$Element$Font$underline,
										$mdgriffith$elm_ui$Element$Font$size(16),
										$mdgriffith$elm_ui$Element$alignRight,
										$mdgriffith$elm_ui$Element$Events$onClick($author$project$Page$SignIn$ToggleIsShowPassword)
									]),
								$mdgriffith$elm_ui$Element$text(showOrHideText))
							]))),
				cS: $author$project$Page$SignIn$SetPassword,
				cV: mdl.dR,
				iR: mdl.cN,
				cY: mdl.bK
			}));
};
var $author$project$Page$SignIn$SignIn = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$SignIn$signInButton = function (mdl) {
	var _v0 = mdl.cO ? _Utils_Tuple2(
		true,
		$elm$core$Maybe$Just(
			A2($author$project$Page$SignIn$SignIn, mdl.li, mdl.bK))) : _Utils_Tuple2(false, $elm$core$Maybe$Nothing);
	var isActive = _v0.a;
	var cmd = _v0.b;
	return A2(
		$author$project$Page$PagesCommon$regButton,
		isActive,
		{
			bD: A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				$mdgriffith$elm_ui$Element$text('Sign In')),
			ai: cmd
		});
};
var $author$project$Page$SignInPagesCommon$signInPagesBoxHeading = function (title) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.ki),
				$mdgriffith$elm_ui$Element$Border$roundEach(
				{de: 0, df: 0, d8: 10, d9: 10}),
				$mdgriffith$elm_ui$Element$padding(15),
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				$mdgriffith$elm_ui$Element$Font$size(30),
				$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.kj)
			]),
		$mdgriffith$elm_ui$Element$text(title));
};
var $author$project$Page$SignInPagesCommon$signInPagesBox = F2(
	function (title, contents) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			_List_fromArray(
				[
					$author$project$Page$SignInPagesCommon$signInPagesBoxHeading(title),
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$padding(30),
							$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
							$mdgriffith$elm_ui$Element$Border$roundEach(
							{de: 10, df: 10, d8: 0, d9: 0})
						]),
					contents)
				]));
	});
var $author$project$Page$SignIn$signInBox = function (mdl) {
	return A2(
		$author$project$Page$SignInPagesCommon$signInPagesBox,
		'Online Testing/Practice Zone',
		_List_fromArray(
			[
				$author$project$Page$SignInPagesCommon$msgArea(mdl.fF),
				$author$project$Page$SignIn$emailField(mdl),
				$author$project$Page$SignInPagesCommon$fieldErrorMsgElement(mdl.b3),
				$author$project$Page$SignIn$passwordField(mdl),
				$author$project$Page$SignInPagesCommon$fieldErrorMsgElement(mdl.cg),
				$author$project$Page$SignIn$signInButton(mdl)
			]));
};
var $author$project$Page$SignInPagesCommon$smarterMathsLogo = A2(
	$mdgriffith$elm_ui$Element$el,
	_List_fromArray(
		[
			A2($mdgriffith$elm_ui$Element$paddingXY, 60, 15)
		]),
	A2(
		$mdgriffith$elm_ui$Element$image,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$padding(20),
				$mdgriffith$elm_ui$Element$Border$rounded(10),
				$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.op)
			]),
		{gT: 'SmarterMaths Logo', ov: '/assets/SmarterMathsLogoWhite.png'}));
var $author$project$Page$SignIn$signInPagesArea = F2(
	function (version, mdl) {
		return $author$project$Page$SignInPagesCommon$signInArea(
			_List_fromArray(
				[
					$author$project$Page$SignInPagesCommon$smarterMathsLogo,
					$author$project$Page$SignIn$signInBox(mdl),
					$author$project$Page$SignIn$forgotPassword,
					$author$project$Page$PagesCommon$versionElement(version)
				]));
	});
var $author$project$Page$SignIn$view = F2(
	function (flags, model) {
		return A2(
			$mdgriffith$elm_ui$Element$layout,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color(
					$author$project$Page$PagesCommon$makeBackgroundStyleRaw(flags.od))
				]),
			A2($author$project$Page$SignIn$signInPagesArea, flags.pL, model));
	});
var $author$project$Page$StudentHome$LinkClicked = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$StudentHome$SignOut = {$: 0};
var $author$project$Page$StudentHome$DoNothing = {$: 13};
var $author$project$Type$HiddenExamGroup$Hide = 0;
var $author$project$Type$HiddenExamGroup$Show = 1;
var $author$project$Page$StudentHome$StartActivity = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Page$StudentHome$TriggerWorksheetsPage = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Page$StudentHome$UpdateHiddenExamGroups = F4(
	function (a, b, c, d) {
		return {$: 5, a: a, b: b, c: c, d: d};
	});
var $author$project$UISnippet$HideShow$Hide = 0;
var $author$project$UISnippet$HideShow$Show = 1;
var $author$project$UISnippet$HideShow$black = A3($mdgriffith$elm_ui$Element$rgb255, 0, 0, 0);
var $author$project$UISnippet$HideShow$green = A3($mdgriffith$elm_ui$Element$rgb255, 34, 208, 95);
var $author$project$UISnippet$HideShow$red = A3($mdgriffith$elm_ui$Element$rgb255, 255, 56, 96);
var $author$project$UISnippet$HideShow$white = A3($mdgriffith$elm_ui$Element$rgb255, 255, 255, 255);
var $author$project$UISnippet$HideShow$btnHideShow = F2(
	function (isHidden, msgMaybe) {
		var makeBtn = F2(
			function (hideShow, isActive) {
				var fontColor = isActive ? $author$project$UISnippet$HideShow$black : $author$project$UISnippet$HideShow$white;
				var cfg = function () {
					if (!hideShow) {
						var _v1 = isActive ? _Utils_Tuple2($author$project$UISnippet$HideShow$white, $author$project$UISnippet$HideShow$red) : _Utils_Tuple2($author$project$UISnippet$HideShow$red, $author$project$UISnippet$HideShow$red);
						var bgColor = _v1.a;
						var borderColor = _v1.b;
						return {
							en: bgColor,
							ep: $mdgriffith$elm_ui$Element$Border$roundEach(
								{de: 15, df: 0, d8: 15, d9: 0}),
							eq: borderColor,
							es: 'Hide'
						};
					} else {
						var _v2 = isActive ? _Utils_Tuple2($author$project$UISnippet$HideShow$white, $author$project$UISnippet$HideShow$green) : _Utils_Tuple2($author$project$UISnippet$HideShow$green, $author$project$UISnippet$HideShow$green);
						var bgColor = _v2.a;
						var borderColor = _v2.b;
						return {
							en: bgColor,
							ep: $mdgriffith$elm_ui$Element$Border$roundEach(
								{de: 0, df: 15, d8: 0, d9: 15}),
							eq: borderColor,
							es: 'Show'
						};
					}
				}();
				return A2(
					$mdgriffith$elm_ui$Element$Input$button,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Border$width(1),
							$mdgriffith$elm_ui$Element$Border$color(cfg.eq),
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$px(60)),
							A2($mdgriffith$elm_ui$Element$paddingXY, 10, 7),
							cfg.ep,
							$mdgriffith$elm_ui$Element$Background$color(cfg.en)
						]),
					{
						bD: A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$centerX,
									$mdgriffith$elm_ui$Element$Font$color(fontColor)
								]),
							$mdgriffith$elm_ui$Element$text(cfg.es)),
						ai: isActive ? msgMaybe : $elm$core$Maybe$Nothing
					});
			});
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_Nil,
			_List_fromArray(
				[
					A2(makeBtn, 0, !isHidden),
					A2(makeBtn, 1, isHidden)
				]));
	});
var $author$project$Type$HiddenExamGroup$isHiddenActivity = F4(
	function (hiddenExamGroupsMaybe, cohort, groupMaybe, activityId) {
		var group = A2($elm$core$Maybe$withDefault, $author$project$Type$HiddenExamGroup$noGrp, groupMaybe);
		return _Utils_eq(
			$elm$core$Maybe$Just(true),
			A2(
				$elm$core$Maybe$map,
				$elm$core$List$member(activityId),
				A2(
					$elm$core$Maybe$map,
					function (fn) {
						return A2(fn, cohort, group);
					},
					A2($elm$core$Maybe$map, $author$project$Type$HiddenExamGroup$getActivityIdsForCohortAndGroupTag, hiddenExamGroupsMaybe))));
	});
var $author$project$Page$StudentHome$makeButton = F3(
	function (txt, msgMaybe, isConfigPageMode) {
		return A2(
			$author$project$Page$PagesCommon$thinButton,
			!isConfigPageMode,
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]),
					$mdgriffith$elm_ui$Element$text(txt)),
				ai: msgMaybe
			});
	});
var $author$project$Page$StudentHome$AdjustBias = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$StudentHome$TestData_1_GenerateRandomValues = F3(
	function (a, b, c) {
		return {$: 8, a: a, b: b, c: c};
	});
var $author$project$Page$StudentHome$ToggleTestConfigBtn = function (a) {
	return {$: 7, a: a};
};
var $mdgriffith$elm_ui$Element$Input$Thumb = $elm$core$Basics$identity;
var $mdgriffith$elm_ui$Element$Input$defaultThumb = _List_fromArray(
	[
		$mdgriffith$elm_ui$Element$width(
		$mdgriffith$elm_ui$Element$px(16)),
		$mdgriffith$elm_ui$Element$height(
		$mdgriffith$elm_ui$Element$px(16)),
		$mdgriffith$elm_ui$Element$Border$rounded(8),
		$mdgriffith$elm_ui$Element$Border$width(1),
		$mdgriffith$elm_ui$Element$Border$color(
		A3($mdgriffith$elm_ui$Element$rgb, 0.5, 0.5, 0.5)),
		$mdgriffith$elm_ui$Element$Background$color(
		A3($mdgriffith$elm_ui$Element$rgb, 1, 1, 1))
	]);
var $mdgriffith$elm_ui$Internal$Flag$active = $mdgriffith$elm_ui$Internal$Flag$flag(32);
var $mdgriffith$elm_ui$Internal$Flag$focus = $mdgriffith$elm_ui$Internal$Flag$flag(31);
var $mdgriffith$elm_ui$Internal$Model$getHeight = function (attrs) {
	return A3(
		$elm$core$List$foldr,
		F2(
			function (attr, acc) {
				if (!acc.$) {
					var x = acc.a;
					return $elm$core$Maybe$Just(x);
				} else {
					if (attr.$ === 8) {
						var len = attr.a;
						return $elm$core$Maybe$Just(len);
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}
			}),
		$elm$core$Maybe$Nothing,
		attrs);
};
var $mdgriffith$elm_ui$Internal$Model$getWidth = function (attrs) {
	return A3(
		$elm$core$List$foldr,
		F2(
			function (attr, acc) {
				if (!acc.$) {
					var x = acc.a;
					return $elm$core$Maybe$Just(x);
				} else {
					if (attr.$ === 7) {
						var len = attr.a;
						return $elm$core$Maybe$Just(len);
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}
			}),
		$elm$core$Maybe$Nothing,
		attrs);
};
var $elm$html$Html$Attributes$min = $elm$html$Html$Attributes$stringProperty('min');
var $mdgriffith$elm_ui$Element$spacingXY = F2(
	function (x, y) {
		return A2(
			$mdgriffith$elm_ui$Internal$Model$StyleClass,
			$mdgriffith$elm_ui$Internal$Flag$spacing,
			A3(
				$mdgriffith$elm_ui$Internal$Model$SpacingStyle,
				A2($mdgriffith$elm_ui$Internal$Model$spacingName, x, y),
				x,
				y));
	});
var $elm$html$Html$Attributes$step = function (n) {
	return A2($elm$html$Html$Attributes$stringProperty, 'step', n);
};
var $mdgriffith$elm_ui$Internal$Model$mapAttr = F2(
	function (fn, attr) {
		switch (attr.$) {
			case 0:
				return $mdgriffith$elm_ui$Internal$Model$NoAttribute;
			case 2:
				var description = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Describe(description);
			case 6:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$AlignX(x);
			case 5:
				var y = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$AlignY(y);
			case 7:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Width(x);
			case 8:
				var x = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Height(x);
			case 3:
				var x = attr.a;
				var y = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$Class, x, y);
			case 4:
				var flag = attr.a;
				var style = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$StyleClass, flag, style);
			case 9:
				var location = attr.a;
				var elem = attr.b;
				return A2(
					$mdgriffith$elm_ui$Internal$Model$Nearby,
					location,
					A2($mdgriffith$elm_ui$Internal$Model$map, fn, elem));
			case 1:
				var htmlAttr = attr.a;
				return $mdgriffith$elm_ui$Internal$Model$Attr(
					A2($elm$virtual_dom$VirtualDom$mapAttribute, fn, htmlAttr));
			default:
				var fl = attr.a;
				var trans = attr.b;
				return A2($mdgriffith$elm_ui$Internal$Model$TransformComponent, fl, trans);
		}
	});
var $mdgriffith$elm_ui$Element$Input$viewHorizontalThumb = F3(
	function (factor, thumbAttributes, trackHeight) {
		return A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$height(
					A2($elm$core$Maybe$withDefault, $mdgriffith$elm_ui$Element$fill, trackHeight)),
					$mdgriffith$elm_ui$Element$centerY
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$fillPortion(
								$elm$core$Basics$round(factor * 10000)))
						]),
					$mdgriffith$elm_ui$Element$none),
					A2(
					$mdgriffith$elm_ui$Element$el,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$centerY,
						A2(
							$elm$core$List$map,
							$mdgriffith$elm_ui$Internal$Model$mapAttr($elm$core$Basics$never),
							thumbAttributes)),
					$mdgriffith$elm_ui$Element$none),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width(
							$mdgriffith$elm_ui$Element$fillPortion(
								$elm$core$Basics$round(
									$elm$core$Basics$abs(1 - factor) * 10000)))
						]),
					$mdgriffith$elm_ui$Element$none)
				]));
	});
var $mdgriffith$elm_ui$Element$Input$viewVerticalThumb = F3(
	function (factor, thumbAttributes, trackWidth) {
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$width(
					A2($elm$core$Maybe$withDefault, $mdgriffith$elm_ui$Element$fill, trackWidth)),
					$mdgriffith$elm_ui$Element$centerX
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$fillPortion(
								$elm$core$Basics$round(
									$elm$core$Basics$abs(1 - factor) * 10000)))
						]),
					$mdgriffith$elm_ui$Element$none),
					A2(
					$mdgriffith$elm_ui$Element$el,
					A2(
						$elm$core$List$cons,
						$mdgriffith$elm_ui$Element$centerX,
						A2(
							$elm$core$List$map,
							$mdgriffith$elm_ui$Internal$Model$mapAttr($elm$core$Basics$never),
							thumbAttributes)),
					$mdgriffith$elm_ui$Element$none),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$height(
							$mdgriffith$elm_ui$Element$fillPortion(
								$elm$core$Basics$round(factor * 10000)))
						]),
					$mdgriffith$elm_ui$Element$none)
				]));
	});
var $mdgriffith$elm_ui$Element$Input$slider = F2(
	function (attributes, input) {
		var trackWidth = $mdgriffith$elm_ui$Internal$Model$getWidth(attributes);
		var trackHeight = $mdgriffith$elm_ui$Internal$Model$getHeight(attributes);
		var vertical = function () {
			var _v8 = _Utils_Tuple2(trackWidth, trackHeight);
			_v8$3:
			while (true) {
				if (_v8.a.$ === 1) {
					if (_v8.b.$ === 1) {
						var _v9 = _v8.a;
						var _v10 = _v8.b;
						return false;
					} else {
						break _v8$3;
					}
				} else {
					if ((!_v8.a.a.$) && (!_v8.b.$)) {
						switch (_v8.b.a.$) {
							case 0:
								var w = _v8.a.a.a;
								var h = _v8.b.a.a;
								return _Utils_cmp(h, w) > 0;
							case 2:
								return true;
							default:
								break _v8$3;
						}
					} else {
						break _v8$3;
					}
				}
			}
			return false;
		}();
		var factor = (input.pI - input.hI) / (input.mB - input.hI);
		var _v0 = input.o8;
		var thumbAttributes = _v0;
		var height = $mdgriffith$elm_ui$Internal$Model$getHeight(thumbAttributes);
		var thumbHeightString = function () {
			if (height.$ === 1) {
				return '20px';
			} else {
				if (!height.a.$) {
					var px = height.a.a;
					return $elm$core$String$fromInt(px) + 'px';
				} else {
					return '100%';
				}
			}
		}();
		var width = $mdgriffith$elm_ui$Internal$Model$getWidth(thumbAttributes);
		var thumbWidthString = function () {
			if (width.$ === 1) {
				return '20px';
			} else {
				if (!width.a.$) {
					var px = width.a.a;
					return $elm$core$String$fromInt(px) + 'px';
				} else {
					return '100%';
				}
			}
		}();
		var className = 'thmb-' + (thumbWidthString + ('-' + thumbHeightString));
		var thumbShadowStyle = _List_fromArray(
			[
				A2($mdgriffith$elm_ui$Internal$Model$Property, 'width', thumbWidthString),
				A2($mdgriffith$elm_ui$Internal$Model$Property, 'height', thumbHeightString)
			]);
		var _v1 = A2(
			$mdgriffith$elm_ui$Internal$Model$getSpacing,
			attributes,
			_Utils_Tuple2(5, 5));
		var spacingX = _v1.a;
		var spacingY = _v1.b;
		return A3(
			$mdgriffith$elm_ui$Element$Input$applyLabel,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Input$isHiddenLabel(input.bD) ? $mdgriffith$elm_ui$Internal$Model$NoAttribute : A2($mdgriffith$elm_ui$Element$spacingXY, spacingX, spacingY),
					$mdgriffith$elm_ui$Element$Region$announce,
					$mdgriffith$elm_ui$Element$width(
					function () {
						if (trackWidth.$ === 1) {
							return $mdgriffith$elm_ui$Element$fill;
						} else {
							if (!trackWidth.a.$) {
								return $mdgriffith$elm_ui$Element$shrink;
							} else {
								var x = trackWidth.a;
								return x;
							}
						}
					}()),
					$mdgriffith$elm_ui$Element$height(
					function () {
						if (trackHeight.$ === 1) {
							return $mdgriffith$elm_ui$Element$shrink;
						} else {
							if (!trackHeight.a.$) {
								return $mdgriffith$elm_ui$Element$shrink;
							} else {
								var x = trackHeight.a;
								return x;
							}
						}
					}())
				]),
			input.bD,
			A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width(
						A2($elm$core$Maybe$withDefault, $mdgriffith$elm_ui$Element$fill, trackWidth)),
						$mdgriffith$elm_ui$Element$height(
						A2(
							$elm$core$Maybe$withDefault,
							$mdgriffith$elm_ui$Element$px(20),
							trackHeight))
					]),
				_List_fromArray(
					[
						A4(
						$mdgriffith$elm_ui$Internal$Model$element,
						$mdgriffith$elm_ui$Internal$Model$asEl,
						$mdgriffith$elm_ui$Internal$Model$NodeName('input'),
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Input$hiddenLabelAttribute(input.bD),
								A2(
								$mdgriffith$elm_ui$Internal$Model$StyleClass,
								$mdgriffith$elm_ui$Internal$Flag$active,
								A2($mdgriffith$elm_ui$Internal$Model$Style, 'input[type=\"range\"].' + (className + '::-moz-range-thumb'), thumbShadowStyle)),
								A2(
								$mdgriffith$elm_ui$Internal$Model$StyleClass,
								$mdgriffith$elm_ui$Internal$Flag$hover,
								A2($mdgriffith$elm_ui$Internal$Model$Style, 'input[type=\"range\"].' + (className + '::-webkit-slider-thumb'), thumbShadowStyle)),
								A2(
								$mdgriffith$elm_ui$Internal$Model$StyleClass,
								$mdgriffith$elm_ui$Internal$Flag$focus,
								A2($mdgriffith$elm_ui$Internal$Model$Style, 'input[type=\"range\"].' + (className + '::-ms-thumb'), thumbShadowStyle)),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								$elm$html$Html$Attributes$class(className + ' ui-slide-bar focusable-parent')),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								$elm$html$Html$Events$onInput(
									function (str) {
										var _v4 = $elm$core$String$toFloat(str);
										if (_v4.$ === 1) {
											return input.cS(0);
										} else {
											var val = _v4.a;
											return input.cS(val);
										}
									})),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								$elm$html$Html$Attributes$type_('range')),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								$elm$html$Html$Attributes$step(
									function () {
										var _v5 = input.oA;
										if (_v5.$ === 1) {
											return 'any';
										} else {
											var step = _v5.a;
											return $elm$core$String$fromFloat(step);
										}
									}())),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								$elm$html$Html$Attributes$min(
									$elm$core$String$fromFloat(input.hI))),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								$elm$html$Html$Attributes$max(
									$elm$core$String$fromFloat(input.mB))),
								$mdgriffith$elm_ui$Internal$Model$Attr(
								$elm$html$Html$Attributes$value(
									$elm$core$String$fromFloat(input.pI))),
								vertical ? $mdgriffith$elm_ui$Internal$Model$Attr(
								A2($elm$html$Html$Attributes$attribute, 'orient', 'vertical')) : $mdgriffith$elm_ui$Internal$Model$NoAttribute,
								$mdgriffith$elm_ui$Element$width(
								vertical ? A2(
									$elm$core$Maybe$withDefault,
									$mdgriffith$elm_ui$Element$px(20),
									trackHeight) : A2($elm$core$Maybe$withDefault, $mdgriffith$elm_ui$Element$fill, trackWidth)),
								$mdgriffith$elm_ui$Element$height(
								vertical ? A2($elm$core$Maybe$withDefault, $mdgriffith$elm_ui$Element$fill, trackWidth) : A2(
									$elm$core$Maybe$withDefault,
									$mdgriffith$elm_ui$Element$px(20),
									trackHeight))
							]),
						$mdgriffith$elm_ui$Internal$Model$Unkeyed(_List_Nil)),
						A2(
						$mdgriffith$elm_ui$Element$el,
						A2(
							$elm$core$List$cons,
							$mdgriffith$elm_ui$Element$width(
								A2($elm$core$Maybe$withDefault, $mdgriffith$elm_ui$Element$fill, trackWidth)),
							A2(
								$elm$core$List$cons,
								$mdgriffith$elm_ui$Element$height(
									A2(
										$elm$core$Maybe$withDefault,
										$mdgriffith$elm_ui$Element$px(20),
										trackHeight)),
								_Utils_ap(
									attributes,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$behindContent(
											vertical ? A3(
												$mdgriffith$elm_ui$Element$Input$viewVerticalThumb,
												factor,
												A2(
													$elm$core$List$cons,
													$mdgriffith$elm_ui$Internal$Model$htmlClass('focusable-thumb'),
													thumbAttributes),
												trackWidth) : A3(
												$mdgriffith$elm_ui$Element$Input$viewHorizontalThumb,
												factor,
												A2(
													$elm$core$List$cons,
													$mdgriffith$elm_ui$Internal$Model$htmlClass('focusable-thumb'),
													thumbAttributes),
												trackHeight))
										])))),
						$mdgriffith$elm_ui$Element$none)
					])));
	});
var $author$project$Page$StudentHome$makeTestDataComponent = F3(
	function (isConfigPageMode, model, activity) {
		var makeTestDataButton = function (isShown_) {
			return A3(
				$author$project$Page$StudentHome$makeButton,
				isShown_ ? 'Hide' : 'Show',
				$elm$core$Maybe$Just(
					$author$project$Page$StudentHome$ToggleTestConfigBtn(activity.lW)),
				isConfigPageMode);
		};
		var makeGenerateButton = A3(
			$author$project$Page$StudentHome$makeButton,
			'Generate',
			$elm$core$Maybe$Just(
				A3($author$project$Page$StudentHome$TestData_1_GenerateRandomValues, activity, model.bO, model.bg)),
			isConfigPageMode);
		var isShown = _Utils_eq(
			A2($elm$core$Dict$get, activity.lW, model.cb),
			$elm$core$Maybe$Just(true));
		var biasSlider = A2(
			$mdgriffith$elm_ui$Element$Input$slider,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$height(
					$mdgriffith$elm_ui$Element$px(30)),
					$mdgriffith$elm_ui$Element$behindContent(
					A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
								$mdgriffith$elm_ui$Element$height(
								$mdgriffith$elm_ui$Element$px(2)),
								$mdgriffith$elm_ui$Element$centerY,
								$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.f6),
								$mdgriffith$elm_ui$Element$Border$rounded(2)
							]),
						$mdgriffith$elm_ui$Element$none))
				]),
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_Nil,
					$mdgriffith$elm_ui$Element$text(
						'% correct: ' + $elm$core$String$fromFloat(model.bg))),
				mB: 1.0,
				hI: 0.0,
				cS: $author$project$Page$StudentHome$AdjustBias,
				oA: $elm$core$Maybe$Just(0.005),
				o8: $mdgriffith$elm_ui$Element$Input$defaultThumb,
				pI: model.bg
			});
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(10)
				]),
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						makeTestDataButton(isShown)
					]),
				isShown ? _List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('# Qstns: 32'),
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Border$dotted,
								$mdgriffith$elm_ui$Element$Border$rounded(5),
								$mdgriffith$elm_ui$Element$Border$width(1),
								$mdgriffith$elm_ui$Element$Border$color($author$project$Theme$Style$black),
								A2($mdgriffith$elm_ui$Element$paddingXY, 10, 10)
							]),
						_List_fromArray(
							[biasSlider])),
						makeGenerateButton
					]) : _List_Nil));
	});
var $author$project$Page$StudentHome$VariableQstnNbrChanged = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Page$StudentHome$minVariableQstnNbr = 3;
var $elm$html$Html$optgroup = _VirtualDom_node('optgroup');
var $author$project$Page$StudentHome$makeVariableQstnSelector = F2(
	function (nbr, activity) {
		var makeOption = function (_v0) {
			var isSelected = _v0.a;
			var x = _v0.b;
			return A2(
				$elm$html$Html$option,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$selected(isSelected),
						$elm$html$Html$Attributes$value(
						$elm$core$String$fromInt(x))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(x))
					]));
		};
		var convertToPair = function (el) {
			return A2(
				$elm$core$Tuple$pair,
				_Utils_eq(el, nbr) ? true : false,
				el);
		};
		var activityMetrics = activity.f9;
		var actNbrs = A2(
			$elm$core$List$map,
			function ($) {
				return $.mV;
			},
			activityMetrics);
		var commonSet = A2($elm$core$List$map, convertToPair, actNbrs);
		var max = A2(
			$elm$core$Maybe$withDefault,
			0,
			$elm$core$List$maximum(actNbrs));
		var otherChoices = A2(
			$elm$core$List$map,
			convertToPair,
			A2(
				$elm$core$List$filter,
				function (x) {
					return !A2(
						$elm$core$List$member,
						x,
						A2($elm$core$List$cons, $author$project$Page$StudentHome$minVariableQstnNbr, actNbrs));
				},
				A2($elm$core$List$range, $author$project$Page$StudentHome$minVariableQstnNbr, max)));
		var options = _List_fromArray(
			[
				A2(
				$elm$html$Html$optgroup,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'label', 'Common choices')
					]),
				A2(
					$elm$core$List$cons,
					makeOption(
						_Utils_Tuple2(false, $author$project$Page$StudentHome$minVariableQstnNbr)),
					A2($elm$core$List$map, makeOption, commonSet))),
				A2(
				$elm$html$Html$optgroup,
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'label', 'Other choices')
					]),
				A2($elm$core$List$map, makeOption, otherChoices))
			]);
		return _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$html(
				A2(
					$elm$html$Html$select,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'font-size', '20px'),
							$elm$html$Html$Events$onInput(
							A2(
								$elm$core$Basics$composeR,
								$elm$core$String$toInt,
								A2(
									$elm$core$Basics$composeR,
									$elm$core$Maybe$withDefault($author$project$Page$StudentHome$minVariableQstnNbr),
									A2(
										$elm$core$Basics$composeR,
										A2($elm$core$Basics$clamp, $author$project$Page$StudentHome$minVariableQstnNbr, max),
										$author$project$Page$StudentHome$VariableQstnNbrChanged(activity.lW)))))
						]),
					options))
			]);
	});
var $author$project$Page$PagesCommon$stripYearLevel = function (str) {
	return A2(
		$elm$core$Maybe$withDefault,
		str,
		A2(
			$elm$core$Maybe$map,
			function (regex) {
				return A3(
					$elm$regex$Regex$replace,
					regex,
					function (_v0) {
						return '';
					},
					str);
			},
			$elm$regex$Regex$fromString('Y[1-9][0-2]? ')));
};
var $author$project$Page$StudentHome$columnsForActivitiesTable = F9(
	function (rState, model, isUserRestricted, nodeCfg, hiddenExamGroupsMaybe, context, isStudentRequestor, selfOrAssignee, activitiesViewMode) {
		var makeAttempts = function (actHistory) {
			return $elm$core$List$isEmpty(actHistory) ? '-' : $elm$core$String$fromInt(
				$elm$core$List$length(actHistory));
		};
		var cellDefaults = _List_fromArray(
			[
				A2($mdgriffith$elm_ui$Element$paddingXY, 10, 5)
			]);
		var borderDefaults = _List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Border$widthEach(
				{er: 1, e9: 0, fz: 0, fY: 0})
			]);
		var dashedBorder = A2($elm$core$List$cons, $mdgriffith$elm_ui$Element$Border$dashed, borderDefaults);
		var rowCellDefaults = $elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$centerY
					]),
					cellDefaults,
					dashedBorder,
					_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$height(
						A2($mdgriffith$elm_ui$Element$minimum, 41, $mdgriffith$elm_ui$Element$fill))
					])
				]));
		var makeRow = function (element) {
			return A2($mdgriffith$elm_ui$Element$el, rowCellDefaults, element);
		};
		var solidBorder = A2($elm$core$List$cons, $mdgriffith$elm_ui$Element$Border$solid, borderDefaults);
		var headerCellDefaults = $elm$core$List$concat(
			_List_fromArray(
				[cellDefaults, solidBorder]));
		var makeHeader = function (headerText) {
			return A2(
				$mdgriffith$elm_ui$Element$el,
				headerCellDefaults,
				$mdgriffith$elm_ui$Element$text(headerText));
		};
		var _v0 = function () {
			if (!activitiesViewMode.$) {
				var groupMaybe_ = activitiesViewMode.a;
				return _Utils_Tuple2(true, groupMaybe_);
			} else {
				return _Utils_Tuple2(false, $elm$core$Maybe$Nothing);
			}
		}();
		var isConfigPageMode = _v0.a;
		var groupMaybe = _v0.b;
		var makeMetricButton = F3(
			function (prefix, activityMetric, msgMaybe) {
				var btnTxt = function () {
					if (!context) {
						return prefix + ($elm$core$String$fromInt(
							A2($elm$core$Maybe$withDefault, 0, activityMetric.fh)) + 'm');
					} else {
						return prefix;
					}
				}();
				return A3($author$project$Page$StudentHome$makeButton, btnTxt, msgMaybe, isConfigPageMode);
			});
		return $elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						{
						dB: makeHeader('Activity'),
						pM: function (el_) {
							return makeRow(
								A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerY]),
									$author$project$UISnippet$Misc$fromMarkdown(
										isStudentRequestor ? $author$project$Page$PagesCommon$stripYearLevel(el_.f8.ja) : el_.f8.ja)));
						},
						ec: $mdgriffith$elm_ui$Element$fill
					},
						{
						dB: makeHeader(
							function () {
								if (!context) {
									return 'Attempts';
								} else {
									return 'Sessions';
								}
							}()),
						pM: function (el_) {
							return makeRow(
								A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
									$mdgriffith$elm_ui$Element$text(
										makeAttempts(
											$author$project$Type$ExamResult$getOnlineResults(el_.cy)))));
						},
						ec: $mdgriffith$elm_ui$Element$shrink
					},
						{
						dB: makeHeader('Recent result'),
						pM: function (el_) {
							return function (el1) {
								return makeRow(
									A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[$mdgriffith$elm_ui$Element$centerX]),
										el1));
							}(
								$author$project$UISnippet$Misc$makeResultElement(
									A3($author$project$Type$ExamResult$getMostRecentActivityResultUrlSmall, nodeCfg, false, el_.cy)));
						},
						ec: $mdgriffith$elm_ui$Element$shrink
					},
						{
						dB: makeHeader('History'),
						pM: function (el_) {
							var makeLink = function (pathRoot) {
								return $author$project$Routing$withHash(pathRoot) + ('/' + (selfOrAssignee.dc.pH + ('/' + el_.f8.lW)));
							};
							var retVal = function () {
								var _v3 = $author$project$Type$ExamResult$getOnlineResults(el_.cy);
								if (!_v3.b) {
									return makeRow(
										A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
											$mdgriffith$elm_ui$Element$text('-')));
								} else {
									if (!_v3.b.b) {
										var el1 = _v3.a;
										return makeRow(
											A3(
												$author$project$Page$StudentHome$makeButton,
												'History',
												$elm$core$Maybe$Just(
													$author$project$Page$StudentHome$LinkClicked(
														makeLink($author$project$Routing$smPaths.of) + ('/' + el1.pr))),
												isConfigPageMode));
									} else {
										return makeRow(
											A3(
												$author$project$Page$StudentHome$makeButton,
												'History',
												$elm$core$Maybe$Just(
													$author$project$Page$StudentHome$LinkClicked(
														makeLink($author$project$Routing$smPaths.og))),
												isConfigPageMode));
									}
								}
							}();
							return retVal;
						},
						ec: $mdgriffith$elm_ui$Element$shrink
					}
					]),
					isStudentRequestor ? _List_fromArray(
					[
						{
						dB: makeHeader(
							function () {
								if (!context) {
									return 'Time (minutes)';
								} else {
									return 'Do Questions';
								}
							}()),
						pM: function (el_) {
							return makeRow(
								A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerY]),
									A2(
										$mdgriffith$elm_ui$Element$row,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$spacing(5)
											]),
										function () {
											var isFirstTimeForThisActivity = $elm$core$List$isEmpty(
												$author$project$Type$ExamResult$getOnlineResults(el_.cy));
											var activityMetrics = A2(
												$elm$core$List$sortBy,
												function ($) {
													return $.mV;
												},
												el_.f8.f9);
											if (!context) {
												return isFirstTimeForThisActivity ? A2(
													$elm$core$Maybe$withDefault,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$text('-')
														]),
													A2(
														$elm$core$Maybe$map,
														$elm$core$List$singleton,
														A2(
															$elm$core$Maybe$map,
															function (activityMetric) {
																return A3(
																	makeMetricButton,
																	'Pre-Assess ',
																	activityMetric,
																	$elm$core$Maybe$Just(
																		A2($author$project$Page$StudentHome$StartActivity, el_.f8, activityMetric)));
															},
															$elm$core$List$head(activityMetrics)))) : A2(
													$elm$core$List$map,
													function (activityMetric) {
														return A3(
															makeMetricButton,
															'',
															activityMetric,
															$elm$core$Maybe$Just(
																A2($author$project$Page$StudentHome$StartActivity, el_.f8, activityMetric)));
													},
													activityMetrics);
											} else {
												var nbr = function () {
													var _v6 = A2($elm$core$Dict$get, el_.f8.lW, model.c4);
													if (!_v6.$) {
														var n = _v6.a;
														return n;
													} else {
														if (activityMetrics.b) {
															var hd = activityMetrics.a;
															return hd.mV;
														} else {
															return $author$project$Page$StudentHome$minVariableQstnNbr;
														}
													}
												}();
												return function (els) {
													return A2(
														$elm$core$List$append,
														els,
														function () {
															var activityMetric = A2($author$project$Type$Activity$ActivityMetric, $elm$core$Maybe$Nothing, nbr);
															return _List_fromArray(
																[
																	$mdgriffith$elm_ui$Element$text(' '),
																	A3(
																	makeMetricButton,
																	'Start',
																	activityMetric,
																	$elm$core$Maybe$Just(
																		A2($author$project$Page$StudentHome$StartActivity, el_.f8, activityMetric)))
																]);
														}());
												}(
													A2($author$project$Page$StudentHome$makeVariableQstnSelector, nbr, el_.f8));
											}
										}())));
						},
						ec: $mdgriffith$elm_ui$Element$shrink
					}
					]) : _List_Nil,
					function () {
					if (!context) {
						return _List_fromArray(
							[
								{
								dB: makeHeader('View'),
								pM: function (el_) {
									return makeRow(
										A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
											A3(
												$author$project$Page$StudentHome$makeButton,
												'Worksheets',
												$elm$core$Maybe$Just(
													A2($author$project$Page$StudentHome$TriggerWorksheetsPage, el_, isStudentRequestor)),
												isConfigPageMode)));
								},
								ec: $mdgriffith$elm_ui$Element$shrink
							}
							]);
					} else {
						return _List_Nil;
					}
				}(),
					$author$project$Type$ReaderConfig$isNapAdmin(rState) ? _List_fromArray(
					[
						{
						dB: makeHeader('Test Data'),
						pM: function (el_) {
							return makeRow(
								A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
									A3($author$project$Page$StudentHome$makeTestDataComponent, isConfigPageMode, model, el_.f8)));
						},
						ec: $mdgriffith$elm_ui$Element$shrink
					}
					]) : _List_Nil,
					isConfigPageMode ? _List_fromArray(
					[
						{
						dB: makeHeader('Show to students?'),
						pM: function (el_) {
							var isHidden = A4($author$project$Type$HiddenExamGroup$isHiddenActivity, hiddenExamGroupsMaybe, nodeCfg.cF, groupMaybe, el_.f8.lW);
							var msg = function () {
								var questionGroupState = isHidden ? 1 : 0;
								return isUserRestricted ? $author$project$Page$StudentHome$DoNothing : A4($author$project$Page$StudentHome$UpdateHiddenExamGroups, questionGroupState, nodeCfg.cF, groupMaybe, el_.f8.lW);
							}();
							return makeRow(
								A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[$mdgriffith$elm_ui$Element$centerX, $mdgriffith$elm_ui$Element$centerY]),
									A2(
										$author$project$UISnippet$HideShow$btnHideShow,
										isHidden,
										$elm$core$Maybe$Just(msg))));
						},
						ec: $mdgriffith$elm_ui$Element$shrink
					}
					]) : _List_Nil
				]));
	});
var $author$project$Page$StudentHome$activitiesSection = F6(
	function (model, hiddenExamGroupsMaybe, context, isStudentRequestor, selfOrAssignee, activitiesViewMode) {
		var makeActivityTableRows = F2(
			function (activitiesForSyllabus, activityResultRecords) {
				return A2(
					$elm$core$List$map,
					function (el) {
						var activityResultRecords_ = A2(
							$elm$core$List$filter,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.jz;
								},
								$elm$core$Basics$eq(el.lW)),
							activityResultRecords);
						return {f8: el, cy: activityResultRecords_};
					},
					activitiesForSyllabus);
			});
		var activitiesTypeTitle = function () {
			if (!context) {
				return 'Test Zone (Timed)';
			} else {
				return 'Practice Zone (Untimed)';
			}
		}();
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				var tempNodeCfg = function () {
					var nodeCfg = rState.mX;
					return A2(
						$elm$core$Maybe$withDefault,
						nodeCfg,
						A2(
							$elm$core$Maybe$map,
							function (cohort) {
								return _Utils_update(
									nodeCfg,
									{cF: cohort});
							},
							selfOrAssignee.dc.hB));
				}();
				var isVisibleActivity = function (actId) {
					var groupMaybe = selfOrAssignee.dc.eV;
					if (activitiesViewMode.$ === 2) {
						return A2(
							$elm$core$Maybe$withDefault,
							true,
							A2(
								$elm$core$Maybe$map,
								function (cohort) {
									return !A4($author$project$Type$HiddenExamGroup$isHiddenActivity, hiddenExamGroupsMaybe, cohort, groupMaybe, actId);
								},
								selfOrAssignee.dc.hB));
					} else {
						return true;
					}
				};
				var activityResultRecords = $author$project$Type$ExamResult$getActivityResultRecords(
					function () {
						var _v0 = rState.fc;
						if (!_v0.$) {
							return rState.eZ;
						} else {
							return selfOrAssignee.lU;
						}
					}());
				var activitiesForSyllabus = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2(
						$elm$core$Maybe$map,
						$elm$core$List$filter(
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.lW;
								},
								isVisibleActivity)),
						A2(
							$elm$core$Maybe$map,
							$elm$core$List$filter(
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.cx;
									},
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.kL;
										},
										$elm$core$Basics$eq(context)))),
							A2(
								$elm$core$Maybe$map,
								$author$project$Type$NodeCfg$getActivitiesForSyllabus(rState.mX.fs),
								A2(
									$elm$core$Maybe$map,
									$author$project$Type$Syllabus$getSyllabus(rState.mX.oJ),
									selfOrAssignee.dc.hB)))));
				return A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							$author$project$Page$PagesCommon$contentTitleRowAttrs,
							_List_fromArray(
								[
									$author$project$Page$PagesCommon$makeContentTitleElement(activitiesTypeTitle)
								])),
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
									A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
									$mdgriffith$elm_ui$Element$Border$rounded(10),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$spacing(10),
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									function () {
										var dataRows = A2(makeActivityTableRows, activitiesForSyllabus, activityResultRecords);
										return $elm$core$List$isEmpty(dataRows) ? _List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text('Your teacher has not made available any activities in this area.')
											]) : _List_fromArray(
											[
												A2(
												$mdgriffith$elm_ui$Element$table,
												_List_Nil,
												{
													kC: A9(
														$author$project$Page$StudentHome$columnsForActivitiesTable,
														rState,
														model,
														$author$project$Type$ReaderConfig$isUserRestricted(rState),
														tempNodeCfg,
														hiddenExamGroupsMaybe,
														context,
														isStudentRequestor,
														selfOrAssignee,
														activitiesViewMode),
													k_: dataRows
												})
											]);
									}())
								]))
						]));
			});
	});
var $author$project$Page$StudentHome$workArea = F4(
	function (model, hiddenExamGroupsMaybe, isStudentRequestor, selfOrAssignee) {
		return $Punie$elm_reader$Reader$asks(
			function (rState) {
				var headerLineString = isStudentRequestor ? ('Hi ' + (selfOrAssignee.dc.lt + '!')) : ('Results for ' + (selfOrAssignee.dc.lt + (' ' + selfOrAssignee.dc.mq)));
				return A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$row,
							_List_fromArray(
								[
									A2($mdgriffith$elm_ui$Element$paddingXY, 0, 60),
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
								]),
							_List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$fillPortion(1))
										]),
									_List_Nil),
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$fillPortion(6)),
											$mdgriffith$elm_ui$Element$spacing(30)
										]),
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$row,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.nn),
															$mdgriffith$elm_ui$Element$Font$size(30),
															$mdgriffith$elm_ui$Element$Font$bold
														]),
													$mdgriffith$elm_ui$Element$text(headerLineString)),
													A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$Font$family(
															_List_fromArray(
																[
																	$mdgriffith$elm_ui$Element$Font$typeface('FontAwesome')
																])),
															$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$white),
															$mdgriffith$elm_ui$Element$Font$size(30),
															A2($mdgriffith$elm_ui$Element$paddingXY, 5, 0)
														]),
													$mdgriffith$elm_ui$Element$text('\uF059'))
												])),
											A2(
											$mdgriffith$elm_ui$Element$column,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$spacing(20),
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
												]),
											_List_fromArray(
												[
													A2(
													$Punie$elm_reader$Reader$run,
													A6($author$project$Page$StudentHome$activitiesSection, model, hiddenExamGroupsMaybe, 0, isStudentRequestor, selfOrAssignee, $author$project$Type$Configuration$StudentMode),
													rState),
													A2(
													$Punie$elm_reader$Reader$run,
													A6($author$project$Page$StudentHome$activitiesSection, model, hiddenExamGroupsMaybe, 1, isStudentRequestor, selfOrAssignee, $author$project$Type$Configuration$StudentMode),
													rState)
												]))
										])),
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width(
											$mdgriffith$elm_ui$Element$fillPortion(1))
										]),
									_List_Nil)
								]))
						]));
			});
	});
var $author$project$Page$StudentHome$view = F5(
	function (model, topBarFn, hiddenExamGroupsMaybe, isStudentRequestor, selfOrAssignee) {
		var addToElements = F2(
			function (el, els) {
				return A2(
					$Punie$elm_reader$Reader$map,
					function (el2) {
						return A2($elm$core$List$cons, el2, els);
					},
					el);
			});
		return A3(
			$Punie$elm_reader$Reader$map2,
			F2(
				function (color, els) {
					return A2(
						$mdgriffith$elm_ui$Element$layout,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$size(16),
								$mdgriffith$elm_ui$Element$Background$color(color)
							]),
						A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$height($mdgriffith$elm_ui$Element$fill)
								]),
							els));
				}),
			$author$project$Page$PagesCommon$makeBackgroundStyle,
			A2(
				$Punie$elm_reader$Reader$andThen,
				addToElements(
					A2(topBarFn, $author$project$Page$StudentHome$SignOut, $author$project$Page$StudentHome$LinkClicked)),
				A2(
					$Punie$elm_reader$Reader$andThen,
					addToElements(
						A4($author$project$Page$StudentHome$workArea, model, hiddenExamGroupsMaybe, isStudentRequestor, selfOrAssignee)),
					$Punie$elm_reader$Reader$reader(_List_Nil))));
	});
var $author$project$Msgs$LinkClicked = function (a) {
	return {$: 47, a: a};
};
var $author$project$Msgs$ProceedToNextSection = {$: 11};
var $author$project$Page$PagesCommon$makePage = F4(
	function (bgColorMaybe, tBar, headingMaybe, pageMeat) {
		return A2(
			$mdgriffith$elm_ui$Element$layout,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color(
					A2($elm$core$Maybe$withDefault, $author$project$Theme$Style$t.nl, bgColorMaybe))
				]),
			A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						tBar,
						A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								A2($mdgriffith$elm_ui$Element$paddingXY, 0, 60),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$fillPortion(1))
									]),
								_List_Nil),
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$fillPortion(6)),
										$mdgriffith$elm_ui$Element$spacing(30)
									]),
								function () {
									if (headingMaybe.$ === 1) {
										return pageMeat;
									} else {
										var heading = headingMaybe.a;
										return A2(
											$elm$core$List$cons,
											A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$white),
														$mdgriffith$elm_ui$Element$Font$size(30),
														$mdgriffith$elm_ui$Element$Font$bold
													]),
												heading),
											pageMeat);
									}
								}()),
								A2(
								$mdgriffith$elm_ui$Element$column,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width(
										$mdgriffith$elm_ui$Element$fillPortion(1))
									]),
								_List_Nil)
							]))
					])));
	});
var $author$project$Msgs$Logout = {$: 20};
var $author$project$View$ViewHelper$topBarInternal = function (model) {
	return A2(
		$elm$core$Maybe$map,
		function (cfg) {
			return A2(
				$Punie$elm_reader$Reader$run,
				A2($author$project$Page$PagesCommon$topBar, $author$project$Msgs$Logout, $author$project$Msgs$LinkClicked),
				cfg);
		},
		$author$project$Models$makeReaderState(model));
};
var $author$project$View$ViewHelper$topBarAsElement = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		$mdgriffith$elm_ui$Element$text('Could not create the menu bar'),
		$author$project$View$ViewHelper$topBarInternal(model));
};
var $author$project$Theme$Style$toRgbString = function (color) {
	var to255 = function (f) {
		return $elm$core$String$fromInt(
			$elm$core$Basics$round(f * 255));
	};
	return function (el) {
		return 'rgb(' + (to255(el.fx) + (', ' + (to255(el.eT) + (', ' + (to255(el.eo) + ')')))));
	}(
		$mdgriffith$elm_ui$Element$toRgb(color));
};
var $author$project$View$View$toggleQstnEstats = function (model) {
	return _Utils_eq(model.n_, $author$project$Routing$CurrentExamStatsRoute) ? A2(
		$author$project$Msgs$LinkTo,
		$author$project$Routing$withHash($author$project$Routing$smPaths.it),
		model.kS) : A2(
		$author$project$Msgs$LinkTo,
		$author$project$Routing$withHash($author$project$Routing$smPaths.g0),
		$elm$core$Maybe$Nothing);
};
var $author$project$View$View$viewPartHeroHead = function (model) {
	var qHeader = function () {
		var _v2 = model.kS;
		if (!_v2.$) {
			var qNbr = _v2.a;
			return A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						' Question ' + $elm$core$String$fromInt(qNbr))
					]));
		} else {
			return A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(' No current question ')
					]));
		}
	}();
	var isViewingQuestion = function () {
		var _v1 = model.n_;
		if (_v1.$ === 1) {
			return true;
		} else {
			return false;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('columns')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('column')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('field'),
								$elm$html$Html$Attributes$class('is-grouped')
							]),
						_List_fromArray(
							[
								function () {
								var _v0 = model.lo;
								if (_v0.$ === 1) {
									return $elm$html$Html$text('');
								} else {
									var examTimeSecondsRemaining = _v0.a;
									return (examTimeSecondsRemaining <= 180) ? A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('tag is-danger  is-rounded is-medium')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$Helper$Util$digitalClockTextFromSeconds(examTimeSecondsRemaining))
											])) : A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('tag is-dark is-rounded is-medium')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$Helper$Util$digitalClockTextFromSeconds(examTimeSecondsRemaining))
											]));
								}
							}()
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('column has-text-centered'),
						$elm$html$Html$Events$onClick(
						$author$project$View$View$toggleQstnEstats(model))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('button'),
								A2(
								$elm$html$Html$Attributes$style,
								'background-color',
								$author$project$Theme$Style$toRgbString($author$project$Theme$Style$t.fr))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('icon')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('fa fa-list')
											]),
										_List_Nil)
									])),
								qHeader,
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$style, 'margin-left', '.5rem')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										isViewingQuestion ? '(Click for list)' : '(Click to go back)')
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('column')
					]),
				_List_fromArray(
					[
						model.lx.md ? A2($elm$html$Html$div, _List_Nil, _List_Nil) : $elm$html$Html$text('')
					]))
			]));
};
var $author$project$View$View$viewConfirmSection = function (model) {
	var nxtTestlet = $author$project$Helper$Common$getNextTestletDetail(model);
	var isShowNextButton = function () {
		if (nxtTestlet.$ === 1) {
			return false;
		} else {
			return true;
		}
	}();
	var dispContent = function () {
		var scaled = A2(
			$elm$core$Basics$composeR,
			A2($mdgriffith$elm_ui$Element$modular, 16, 1.25),
			$elm$core$Basics$round);
		var currentTestlet = $author$project$Helper$Common$getCurrentTestletDetail(model);
		var currentTestletGrd = function () {
			if (currentTestlet.$ === 1) {
				return 0;
			} else {
				var currentTestlet_ = currentTestlet.a;
				return currentTestlet_.lG;
			}
		}();
		return A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(
					scaled(1)),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			function () {
				if (nxtTestlet.$ === 1) {
					return _List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text('You cannot navigate to that set of questions.  If you clicked the browser back button, but may be because those questions are now locked.  Please click the browser forward button to proceed.')
						]);
				} else {
					var continueLink = A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Events$onClick(
								$author$project$Msgs$LinkClicked(
									$author$project$Routing$withHash($author$project$Routing$smPaths.g0))),
								$mdgriffith$elm_ui$Element$pointer,
								$mdgriffith$elm_ui$Element$Font$underline,
								$mdgriffith$elm_ui$Element$Font$bold
							]),
						$mdgriffith$elm_ui$Element$text('Click here to check your answers now before continuing.'));
					switch (currentTestletGrd) {
						case 0:
							return _List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('There seems to be a configuration problem since this screen should only be shown with a testlet guard, and it is \'None\'')
								]);
						case 1:
							return _List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$size(
											scaled(3))
										]),
									$mdgriffith$elm_ui$Element$text('You have finished this section of the test.')),
									$mdgriffith$elm_ui$Element$text('You will not be able to return to this section.'),
									continueLink,
									A2(
									$mdgriffith$elm_ui$Element$row,
									_List_Nil,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('After you click '),
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$Font$bold]),
											$mdgriffith$elm_ui$Element$text('Next')),
											$mdgriffith$elm_ui$Element$text(' you will NOT be able to view or change your answers.')
										])),
									A2(
									$mdgriffith$elm_ui$Element$row,
									_List_Nil,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('To start the next section, click the '),
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$Font$bold]),
											$mdgriffith$elm_ui$Element$text('\'Next\' button.'))
										]))
								]);
						case 2:
							return _List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$spacing(
											scaled(1)),
											$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.kG),
											A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
											$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.ki),
											$mdgriffith$elm_ui$Element$Border$rounded(6),
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
										]),
									_List_fromArray(
										[
											A2(
											$mdgriffith$elm_ui$Element$row,
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
													$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.eC),
													$mdgriffith$elm_ui$Element$Border$rounded(6),
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
													$mdgriffith$elm_ui$Element$Font$size(
													scaled(3))
												]),
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$text('You have FINISHED THE CALCULATOR section of the test.')
												])),
											$mdgriffith$elm_ui$Element$text('You will not be able to return to this section, and will NOT be able to use a calculator in the next section.'),
											continueLink,
											A2(
											$mdgriffith$elm_ui$Element$row,
											_List_Nil,
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$text('After you click '),
													A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[$mdgriffith$elm_ui$Element$Font$bold]),
													$mdgriffith$elm_ui$Element$text('Next')),
													$mdgriffith$elm_ui$Element$text(' you will NOT be able to view or change your answers.')
												])),
											A2(
											$mdgriffith$elm_ui$Element$row,
											_List_fromArray(
												[
													A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
													$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.eC),
													$mdgriffith$elm_ui$Element$Border$rounded(6),
													$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
												]),
											_List_fromArray(
												[
													$mdgriffith$elm_ui$Element$text('To start the next section with NO CALCULATOR, click the '),
													A2(
													$mdgriffith$elm_ui$Element$el,
													_List_fromArray(
														[$mdgriffith$elm_ui$Element$Font$bold]),
													$mdgriffith$elm_ui$Element$text('\'Next\' button.'))
												]))
										]))
								]);
						default:
							return _List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$size(
											scaled(3))
										]),
									$mdgriffith$elm_ui$Element$text('You have finished this section of the test.')),
									A2(
									$mdgriffith$elm_ui$Element$row,
									_List_Nil,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('When you click '),
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$Font$bold]),
											$mdgriffith$elm_ui$Element$text('Next')),
											$mdgriffith$elm_ui$Element$text(', your answers for this section will be scored and you will move to the next section.')
										])),
									continueLink,
									A2(
									$mdgriffith$elm_ui$Element$row,
									_List_Nil,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('To start the next section, click the '),
											A2(
											$mdgriffith$elm_ui$Element$el,
											_List_fromArray(
												[$mdgriffith$elm_ui$Element$Font$bold]),
											$mdgriffith$elm_ui$Element$text('\'Next\' button.'))
										]))
								]);
					}
				}
			}());
	}();
	var pageMeat = _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			$mdgriffith$elm_ui$Element$html(
				$author$project$View$View$viewPartHeroHead(model))),
			A2(
			$mdgriffith$elm_ui$Element$row,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Font$size(16)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.kG),
							A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
							$mdgriffith$elm_ui$Element$Border$rounded(6),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[dispContent]))
				])),
			A2(
			$mdgriffith$elm_ui$Element$el,
			_List_Nil,
			$mdgriffith$elm_ui$Element$html(
				isShowNextButton ? A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('button'),
							$elm$html$Html$Events$onClick($author$project$Msgs$ProceedToNextSection)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Next')
						])) : $elm$html$Html$text('')))
		]);
	return A4(
		$author$project$Page$PagesCommon$makePage,
		$elm$core$Maybe$Nothing,
		$author$project$View$ViewHelper$topBarAsElement(model),
		$elm$core$Maybe$Nothing,
		pageMeat);
};
var $elm$html$Html$section = _VirtualDom_node('section');
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $elm$html$Html$th = _VirtualDom_node('th');
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $author$project$View$View$toCurrentSessionStatsTableRow = function (_v0) {
	var qIdx = _v0.a;
	var qAnswerDetails = _v0.b;
	var qNbr = qIdx + 1;
	var qNbrText = $elm$core$String$fromInt(qNbr);
	var aStatusText = (!qAnswerDetails.jC) ? _List_fromArray(
		[
			A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('icon'),
					A2($elm$html$Html$Attributes$style, 'color', 'red')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fa fa-square-o')
						]),
					_List_Nil)
				]))
		]) : _List_fromArray(
		[
			A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('icon')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fa fa-check-square-o')
						]),
					_List_Nil)
				]))
		]);
	var aFlaggedDisplay = qAnswerDetails.mi ? _List_fromArray(
		[
			A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('icon'),
					A2($elm$html$Html$Attributes$style, 'color', 'red')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fa fa-flag')
						]),
					_List_Nil)
				]))
		]) : _List_Nil;
	var _v1 = qAnswerDetails.mj ? _Utils_Tuple2(
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('icon'),
						A2($elm$html$Html$Attributes$style, 'color', 'red')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fa fa-lock')
							]),
						_List_Nil)
					]))
			]),
		_List_Nil) : _Utils_Tuple2(
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$Events$onClick(
				A2(
					$author$project$Msgs$LinkTo,
					$author$project$Routing$withHash($author$project$Routing$smPaths.it),
					$elm$core$Maybe$Just(qNbr)))
			]));
	var aLockedDisplay = _v1.a;
	var attributesList = _v1.b;
	return A2(
		$elm$html$Html$tr,
		attributesList,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$td,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Question ' + qNbrText)
					])),
				A2($elm$html$Html$td, _List_Nil, aStatusText),
				A2($elm$html$Html$td, _List_Nil, aFlaggedDisplay),
				A2($elm$html$Html$td, _List_Nil, aLockedDisplay)
			]));
};
var $author$project$View$View$currentSessionStatsTable = function (model) {
	var sessionStats = $elm$core$Array$toIndexedList(model.kR);
	return A2(
		$elm$html$Html$section,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('box')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('table is-striped is-hoverable is-fullwidth')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Question #')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Answered?')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Flagged?')
													])),
												A2(
												$elm$html$Html$th,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text('Locked?')
													]))
											]))
									])),
								A2(
								$elm$html$Html$tbody,
								_List_Nil,
								A2($elm$core$List$map, $author$project$View$View$toCurrentSessionStatsTableRow, sessionStats))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('notification is-info has-text-centered')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Click on any unlocked question in the table to jump directly to it.')
							]))
					]))
			]));
};
var $author$project$View$View$viewExamStats = function (model) {
	var pageMeat = _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
				]),
			$mdgriffith$elm_ui$Element$html(
				$author$project$View$View$viewPartHeroHead(model))),
			A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Font$size(16)
				]),
			$mdgriffith$elm_ui$Element$html(
				$author$project$View$View$currentSessionStatsTable(model)))
		]);
	return A4(
		$author$project$Page$PagesCommon$makePage,
		$elm$core$Maybe$Nothing,
		$author$project$View$ViewHelper$topBarAsElement(model),
		$elm$core$Maybe$Nothing,
		pageMeat);
};
var $author$project$View$ViewHelper$decoratePage = F3(
	function (model, _v0, isAllElm) {
		var title = _v0.a;
		var innerContent = _v0.b;
		var pageMeat = _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$Font$size(16)
					]),
				$mdgriffith$elm_ui$Element$html(innerContent)),
				A2(
				$mdgriffith$elm_ui$Element$row,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$size(12),
								$mdgriffith$elm_ui$Element$alignRight,
								$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$white)
							]),
						$mdgriffith$elm_ui$Element$text('v' + model.lx.pL))
					]))
			]);
		return A4(
			$author$project$Page$PagesCommon$makePage,
			$elm$core$Maybe$Nothing,
			$author$project$View$ViewHelper$topBarAsElement(model),
			$elm$core$Maybe$Just(title),
			pageMeat);
	});
var $author$project$View$ViewHelper$decoratePageAllElm = F2(
	function (model, _v0) {
		var title = _v0.a;
		var innerContent = _v0.b;
		return A3(
			$author$project$View$ViewHelper$decoratePage,
			model,
			_Utils_Tuple2(title, innerContent),
			true);
	});
var $author$project$View$ViewHelper$errorPageHeading = 'Oh no!  We\'re sorry but we can\'t continue due to an error...';
var $elm$html$Html$article = _VirtualDom_node('article');
var $author$project$View$ViewHelper$viewFatalErrorPageInnerContent = F2(
	function (errHumanText, errTechnicalDetailsText) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('box')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('notification is-danger')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h1,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('title')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Problem')
								])),
							A2($elm$html$Html$p, _List_Nil, _List_Nil),
							A2(
							$elm$html$Html$h2,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('subtitle')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(errHumanText)
								]))
						])),
					A2(
					$elm$html$Html$article,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('message')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('message-header')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Technical error details for the eggheads at SmarterMaths!')
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('message-body'),
									A2($elm$html$Html$Attributes$style, 'white-space', 'pre-wrap')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(errTechnicalDetailsText)
								]))
						]))
				]));
	});
var $author$project$View$ViewHelper$viewFatalErrorPage = F3(
	function (model, errHumanText, errTechnicalDetailsText) {
		return A2(
			$author$project$View$ViewHelper$decoratePageAllElm,
			model,
			_Utils_Tuple2(
				$mdgriffith$elm_ui$Element$text($author$project$View$ViewHelper$errorPageHeading),
				A2($author$project$View$ViewHelper$viewFatalErrorPageInnerContent, errHumanText, errTechnicalDetailsText)));
	});
var $author$project$View$ViewHelper$viewFatalError = function (model) {
	var _v0 = function () {
		var _v1 = model.ll;
		if (_v1.$ === 1) {
			return _Utils_Tuple2('No further details are available.  To recover you can try to click back, or logout and login again.', 'No technical details available.');
		} else {
			var error = _v1.a;
			return _Utils_Tuple2(
				error.lj,
				function () {
					var _v2 = error.lk;
					if (_v2.$ === 1) {
						return 'No technical details available.';
					} else {
						var s = _v2.a;
						return s;
					}
				}());
		}
	}();
	var errText = _v0.a;
	var errTextTechDetails = _v0.b;
	return A3($author$project$View$ViewHelper$viewFatalErrorPage, model, errText, errTextTechDetails);
};
var $author$project$Models$MrQ = 0;
var $author$project$View$View$insertMultiChoiceIndexValue = F2(
	function (qBeforeStateAdded, aIdx) {
		if (aIdx >= 0) {
			var htmlListSplitBySmAOption = A2($elm$core$String$split, 'sm-a-option', qBeforeStateAdded);
			var reconstitutedHtml = A3(
				$elm$core$List$foldl,
				F2(
					function (htmlStringFragment, _v0) {
						var htmlString = _v0.a;
						var accCounter = _v0.b;
						var intersperedString = _Utils_eq(accCounter, aIdx) ? 'sm-a-option sm-a-selected' : (_Utils_eq(
							accCounter,
							$elm$core$List$length(htmlListSplitBySmAOption) - 1) ? '' : 'sm-a-option');
						return _Utils_Tuple2(
							_Utils_ap(
								htmlString,
								_Utils_ap(htmlStringFragment, intersperedString)),
							accCounter + 1);
					}),
				_Utils_Tuple2('', 0),
				htmlListSplitBySmAOption);
			return reconstitutedHtml.a;
		} else {
			return qBeforeStateAdded;
		}
	});
var $author$project$View$View$insertSingleDataBoxValue = F2(
	function (qBeforeStateAdded, aTxt) {
		return A4(
			$elm$regex$Regex$replaceAtMost,
			1,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$regex$Regex$never,
				$elm$regex$Regex$fromString('sm-red-box-a\"')),
			function (_v0) {
				return 'sm-red-box-a\" value=\"' + (aTxt + '\"');
			},
			qBeforeStateAdded);
	});
var $author$project$View$View$filterQuestionHtmlToAddSelectionAnswer = F3(
	function (model, qIdx, qBeforeStateAdded) {
		var currentQGroupAnswer = A2($elm$core$Array$get, qIdx, model.kR);
		var qAfterStateAdded = function () {
			if (currentQGroupAnswer.$ === 1) {
				return qBeforeStateAdded;
			} else {
				var qGroupAnswer = currentQGroupAnswer.a;
				var _v1 = qGroupAnswer.jD;
				if (_v1.$ === 1) {
					return qBeforeStateAdded;
				} else {
					if (!_v1.a.$) {
						var aIdx = _v1.a.a;
						return A2($author$project$View$View$insertMultiChoiceIndexValue, qBeforeStateAdded, aIdx);
					} else {
						var aTxt = _v1.a.a;
						return A2($author$project$View$View$insertSingleDataBoxValue, qBeforeStateAdded, aTxt);
					}
				}
			}
		}();
		return qAfterStateAdded;
	});
var $author$project$View$View$questionContentFailedParsing = F2(
	function (postId, techDetailsString) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Apologies!  We had a problem showing this question.')
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						'Please notify SmarterMaths (support@smartermaths.com.au) that the solution to question id ' + ($elm$core$String$fromInt(postId) + ' does not display properly, and we\'ll get it fixed right away!'))
					])),
				A2(
				$elm$html$Html$p,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Technical details for the eggheads at SmarterMaths: ' + techDetailsString)
					]))
			]);
	});
var $author$project$View$View$parseContent = F2(
	function (postId, input) {
		var parsedContent = A2(
			$elm$core$Result$mapError,
			$author$project$Type$Error$deadEndsToString,
			A2(
				$elm$core$Result$map,
				$author$project$Html$Parser$Util$toVirtualDom,
				$author$project$Html$Parser$run(input)));
		if (!parsedContent.$) {
			var content = parsedContent.a;
			return content;
		} else {
			var techDetailsString = parsedContent.a;
			return A2($author$project$View$View$questionContentFailedParsing, postId, techDetailsString);
		}
	});
var $author$project$Type$Cohort$syllabusTitleToCohort = function (s) {
	switch (s) {
		case 'minstd':
			return $author$project$Type$Cohort$MS;
		case 'nap3':
			return $author$project$Type$Cohort$Aus(
				$author$project$Type$Cohort$Stage2(0));
		case 'aus4':
			return $author$project$Type$Cohort$Aus(
				$author$project$Type$Cohort$Stage2(1));
		case 'nap5':
			return $author$project$Type$Cohort$Aus(
				$author$project$Type$Cohort$Stage3(0));
		case 'aus6':
			return $author$project$Type$Cohort$Aus(
				$author$project$Type$Cohort$Stage3(1));
		case 'nap7':
			return $author$project$Type$Cohort$Aus(
				$author$project$Type$Cohort$Stage4(0));
		case 'aus8':
			return $author$project$Type$Cohort$Aus(
				$author$project$Type$Cohort$Stage4(1));
		case 'nap9':
			return $author$project$Type$Cohort$Aus(
				$author$project$Type$Cohort$Stage5(0));
		case 'aus10':
			return $author$project$Type$Cohort$Aus(
				$author$project$Type$Cohort$Stage5(1));
		default:
			return $author$project$Type$Cohort$placeholder;
	}
};
var $author$project$Type$Cohort$syllabusTitleToInt = A2($elm$core$Basics$composeR, $author$project$Type$Cohort$syllabusTitleToCohort, $author$project$Type$Cohort$toInt);
var $author$project$Models$Back = 1;
var $author$project$Msgs$ToggleQuestionFlag = function (a) {
	return {$: 24, a: a};
};
var $author$project$View$View$getQuestion = F2(
	function (qidx, direction) {
		if (!direction) {
			return qidx + 1;
		} else {
			return qidx - 1;
		}
	});
var $author$project$View$View$viewPartHeroFoot = F2(
	function (model, qNbr) {
		var isQFlagged = function () {
			var _v0 = A2($elm$core$Array$get, qNbr - 1, model.kR);
			if (_v0.$ === 1) {
				return false;
			} else {
				var questionAnswerDetails = _v0.a;
				return questionAnswerDetails.mi;
			}
		}();
		var isLoadingString = _Utils_eq(model.gL, $krisajenkins$remotedata$RemoteData$Loading) ? ' is-loading' : '';
		return A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.nl)
				]),
			$mdgriffith$elm_ui$Element$html(
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('navbar-menu is-active')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('navbar-start')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('navbar-item')
										]),
									A2($author$project$Helper$Common$isQNbrNavigationValid, model, 1) ? _List_fromArray(
										[
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('button' + isLoadingString),
													$elm$html$Html$Events$onClick(
													A2(
														$author$project$Msgs$LinkTo,
														$author$project$Routing$withHash($author$project$Routing$smPaths.it),
														$elm$core$Maybe$Just(
															A2($author$project$View$View$getQuestion, qNbr, 1))))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Previous')
												]))
										]) : _List_Nil)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('navbar-end')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('navbar-item')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('button' + isLoadingString),
													$elm$html$Html$Events$onClick(
													$author$project$Msgs$ToggleQuestionFlag(qNbr))
												]),
											_List_fromArray(
												[
													isQFlagged ? A2(
													$elm$html$Html$div,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('UnFlag '),
															A2(
															$elm$html$Html$span,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('icon'),
																	A2($elm$html$Html$Attributes$style, 'color', 'red')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$i,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('fa fa-flag')
																		]),
																	_List_Nil)
																]))
														])) : $elm$html$Html$text('Flag')
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('navbar-item')
										]),
									A2($author$project$Helper$Common$isQNbrNavigationValid, model, 0) ? _List_fromArray(
										[
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$id('sm-btn-next-qstn'),
													$elm$html$Html$Attributes$class('button' + isLoadingString),
													$elm$html$Html$Events$onClick($author$project$Msgs$ShowNextQuestion)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Next')
												]))
										]) : _List_fromArray(
										[
											A2(
											$elm$html$Html$a,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('button is-danger'),
													$elm$html$Html$Events$onClick($author$project$Msgs$FinishExam)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Finish Exam')
												]))
										]))
								]))
						]))));
	});
var $author$project$View$View$viewStandardQuestionPage = F3(
	function (model, qNbr, primaryContent) {
		var calcToolElement = function () {
			var isShowCalculator = A2(
				$elm$core$Maybe$withDefault,
				false,
				A2(
					$elm$core$Maybe$map,
					function (el) {
						return ($author$project$Type$Cohort$syllabusTitleToInt(el) >= 6) && (qNbr > 12);
					},
					model.mX.jJ));
			return isShowCalculator ? A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$paddingEach(
						{er: 10, e9: 0, fz: 0, fY: 0}),
						$mdgriffith$elm_ui$Element$Border$widthEach(
						{er: 1, e9: 0, fz: 0, fY: 0}),
						$mdgriffith$elm_ui$Element$Border$dashed
					]),
				A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$alignRight, $mdgriffith$elm_ui$Element$Font$bold]),
					A2(
						$mdgriffith$elm_ui$Element$map,
						$author$project$Msgs$MsgToolCalc,
						$author$project$UITool$Calculator$view(model.je)))) : $mdgriffith$elm_ui$Element$none;
		}();
		var pageMeat = _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				$mdgriffith$elm_ui$Element$html(
					$author$project$View$View$viewPartHeroHead(model))),
				A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$Font$size(16),
						$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$white),
						$mdgriffith$elm_ui$Element$Border$rounded(6),
						$mdgriffith$elm_ui$Element$padding(20)
					]),
				_List_fromArray(
					[
						calcToolElement,
						$mdgriffith$elm_ui$Element$html(primaryContent)
					])),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
					]),
				A2($author$project$View$View$viewPartHeroFoot, model, qNbr))
			]);
		return A4(
			$author$project$Page$PagesCommon$makePage,
			$elm$core$Maybe$Nothing,
			$author$project$View$ViewHelper$topBarAsElement(model),
			$elm$core$Maybe$Nothing,
			pageMeat);
	});
var $author$project$View$View$viewQuestion = F2(
	function (model, qNbr) {
		var _v0 = model.gL;
		switch (_v0.$) {
			case 0:
				return A3(
					$author$project$View$View$viewStandardQuestionPage,
					model,
					qNbr,
					$elm$html$Html$text('No question has yet been requested'));
			case 1:
				return A3(
					$author$project$View$View$viewStandardQuestionPage,
					model,
					qNbr,
					A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$p,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('subtitle'),
										A2($elm$html$Html$Attributes$style, 'margin-top', '.3em'),
										A2($elm$html$Html$Attributes$style, 'margin-bottom', '.4em')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										'Loading question number ' + ($elm$core$String$fromInt(qNbr) + '...'))
									])),
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('button is-loading'),
										A2($elm$html$Html$Attributes$style, 'font-size', '6em'),
										A2($elm$html$Html$Attributes$style, 'width', '100%')
									]),
								_List_Nil)
							])));
			case 2:
				var err = _v0.a;
				return (err === 'NetworkError') ? A3($author$project$View$ViewHelper$viewFatalErrorPage, model, 'Your connection to the internet dropped out.  Please DO NOT navigate away from this page, but instead fix the network connection.  THEN you can refresh this browser tab and continue with your exam.', err) : A3($author$project$View$ViewHelper$viewFatalErrorPage, model, 'There was a problem retrieving the question from the server.', err);
			default:
				var currPost = _v0.a;
				var addQuestionState = A2($author$project$View$View$filterQuestionHtmlToAddSelectionAnswer, model, qNbr - 1);
				var questionContent = A2(
					$author$project$View$View$parseContent,
					currPost.lW,
					addQuestionState(
						A2($author$project$Helper$Common$extractContent, currPost, 0)));
				return A3(
					$author$project$View$View$viewStandardQuestionPage,
					model,
					qNbr,
					A3(
						$elm$html$Html$Keyed$node,
						'div',
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('sm-post-content'),
								A2($elm$html$Html$Attributes$style, 'margin-bottom', '.8em')
							]),
						A2(
							$elm$core$List$map,
							function (el) {
								return _Utils_Tuple2(
									$elm$core$String$fromInt(currPost.lW),
									el);
							},
							questionContent)));
		}
	});
var $author$project$Msgs$StartQuestionGroup = F3(
	function (a, b, c) {
		return {$: 16, a: a, b: b, c: c};
	});
var $author$project$Type$AppCfg$examsForLevel = F2(
	function (yearLvlMaybe, appCfg) {
		var isIncludeNonLegacyQGroupForLevel = F2(
			function (lvl, qGroup) {
				return (!$author$project$Type$Testlet$isLegacyQGroupId(qGroup.lW)) ? _Utils_eq(
					qGroup.hB,
					$author$project$Type$AppCfg$lvlForNapStyleExams(lvl)) : _Utils_eq(qGroup.hB, lvl);
			});
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				function (lvl) {
					return A2(
						$elm$core$List$sortBy,
						function ($) {
							return $.nj;
						},
						A2(
							$elm$core$List$filter,
							isIncludeNonLegacyQGroupForLevel(lvl),
							appCfg.nH));
				},
				A2($elm$core$Maybe$map, $author$project$Type$Cohort$toInt, yearLvlMaybe)));
	});
var $author$project$Type$HiddenExamGroup$filterOutHiddenExams = F4(
	function (hiddenExamGroupsMaybe, yearLvlMaybe, groupTag, qGroups) {
		var hidsForCohortAndGroupMaybe = A4(
			$elm$core$Maybe$map3,
			$author$project$Type$HiddenExamGroup$getActivityIdsForCohortAndGroupTag,
			hiddenExamGroupsMaybe,
			yearLvlMaybe,
			$elm$core$Maybe$Just(groupTag));
		if (hidsForCohortAndGroupMaybe.$ === 1) {
			return qGroups;
		} else {
			var hidsForCohortAndGroup = hidsForCohortAndGroupMaybe.a;
			return A2(
				$elm$core$List$filter,
				function (el) {
					return !A2(
						$elm$core$List$member,
						$elm$core$String$fromInt(el.lW),
						hidsForCohortAndGroup);
				},
				qGroups);
		}
	});
var $author$project$View$View$getQGroupsFilteredByLvlAndHiddenExams = F3(
	function (model, appCfg, assignee) {
		var lvlMaybe = assignee.dc.hB;
		var allExamGroupsForLvl = A2($author$project$Type$AppCfg$examsForLevel, lvlMaybe, appCfg);
		var _v0 = model.hi;
		if (_v0.$ === 3) {
			var hiddenExamGroupsMaybe = _v0.a;
			var _v1 = model.f7;
			if (_v1.$ === 2) {
				var group = A2($elm$core$Maybe$withDefault, $author$project$Type$HiddenExamGroup$noGrp, assignee.dc.eV);
				return A4($author$project$Type$HiddenExamGroup$filterOutHiddenExams, hiddenExamGroupsMaybe, lvlMaybe, group, allExamGroupsForLvl);
			} else {
				return allExamGroupsForLvl;
			}
		} else {
			return allExamGroupsForLvl;
		}
	});
var $author$project$Msgs$BrowserBack = {$: 1};
var $author$project$View$ViewHelper$viewBackButton = A2(
	$elm$html$Html$a,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('button'),
			$elm$html$Html$Events$onClick($author$project$Msgs$BrowserBack)
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('Back')
		]));
var $author$project$View$View$viewQuestionGroupInstructions = F4(
	function (model, appCfg, qGroupId, userType) {
		var qGroups = _Utils_eq(userType, $author$project$Type$User$Student) ? A2(
			$elm$core$Maybe$withDefault,
			appCfg.nH,
			A2(
				$elm$core$Maybe$map,
				function (assignee) {
					return A3($author$project$View$View$getQGroupsFilteredByLvlAndHiddenExams, model, appCfg, assignee);
				},
				A2(
					$elm$core$Maybe$map,
					$author$project$Type$User$transformStudentETypeToAssignee,
					A2(
						$elm$core$Maybe$andThen,
						$author$project$Type$User$getStudentETypeMe,
						$krisajenkins$remotedata$RemoteData$toMaybe(model.fc))))) : appCfg.nH;
		var qGroupMaybe = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				function (el) {
					return _Utils_eq(el.lW, qGroupId);
				},
				qGroups));
		if (!qGroupMaybe.$) {
			var qGroup = qGroupMaybe.a;
			var instructions = qGroup.iv.l9;
			var pageMeat = _List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$size(18),
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
						]),
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$html(
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('box content')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$ul,
										_List_Nil,
										A2(
											$elm$core$List$map,
											function (el) {
												return A2(
													$elm$html$Html$li,
													_List_Nil,
													A2(
														$elm$core$Result$withDefault,
														_List_fromArray(
															[
																$elm$html$Html$text('Could not display.  Please report to SmarterMaths')
															]),
														A2(
															$elm$core$Result$map,
															$author$project$Html$Parser$Util$toVirtualDom,
															$author$project$Html$Parser$run(el))));
											},
											instructions))
									])))
						])),
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$spacing(10)
						]),
					_List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_Nil,
							$mdgriffith$elm_ui$Element$html($author$project$View$ViewHelper$viewBackButton)),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_Nil,
							$mdgriffith$elm_ui$Element$html(
								A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('button is-primary'),
											$elm$html$Html$Events$onClick(
											A3($author$project$Msgs$StartQuestionGroup, qGroup.lW, qGroup.oE, qGroup.ps))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Start Exam')
										]))))
						]))
				]);
			return A4(
				$author$project$Page$PagesCommon$makePage,
				$elm$core$Maybe$Nothing,
				$author$project$View$ViewHelper$topBarAsElement(model),
				$elm$core$Maybe$Just(
					$mdgriffith$elm_ui$Element$text('Instructions')),
				pageMeat);
		} else {
			return A3(
				$author$project$View$ViewHelper$viewFatalErrorPage,
				model,
				'We apologise ... We can\'t find the instructions for the exam you\'re trying to load.',
				'Could not find qGroupId in route : ' + ($author$project$Routing$routeToString(model.n_) + (', qGroupId: ' + $elm$core$String$fromInt(qGroupId))));
		}
	});
var $author$project$UISnippet$Misc$GIF = 1;
var $author$project$Type$ExamResult$ActivityResult = function (a) {
	return {$: 1, a: a};
};
var $author$project$Type$NodeCfg$getVariantAnswerSetForVIdx = F2(
	function (answerSets, idx) {
		return A2(
			$elm$core$Array$get,
			idx,
			$elm$core$Array$fromList(answerSets));
	});
var $author$project$Type$NodeCfg$getCategoriesDescsForVariantId = F2(
	function (nodeCfg, variantId) {
		var cats = $author$project$Type$NodeCfg$getCategoryLeavesForCurrentSyllabus(nodeCfg);
		var getCatForId = $author$project$Type$NodeCfg$getCategoryForId(cats);
		var catIdsForSyllabus = A2(
			$elm$core$List$map,
			function ($) {
				return $.lW;
			},
			$author$project$Type$NodeCfg$getCategoryLeavesForCurrentSyllabus(nodeCfg));
		return A2(
			$elm$core$Maybe$withDefault,
			_List_fromArray(
				['Topic description not available']),
			A2(
				$elm$core$Maybe$map,
				function (els) {
					return A2(
						$elm$core$List$map,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.k8;
							},
							A2(
								$elm$core$Basics$composeR,
								A2($elm$core$String$replace, '<p>', ''),
								A2($elm$core$String$replace, '</p>', ''))),
						els);
				},
				A2(
					$elm$core$Maybe$map,
					function (els) {
						return A2($elm$core$List$filterMap, getCatForId, els);
					},
					A2(
						$elm$core$Maybe$map,
						function (els) {
							return A2(
								$elm$core$List$filter,
								function (el) {
									return A2($elm$core$List$member, el, catIdsForSyllabus);
								},
								els);
						},
						A2(
							$elm$core$Maybe$andThen,
							function (el) {
								return A2(
									$elm$core$Maybe$map,
									function ($) {
										return $.kr;
									},
									A2($author$project$Type$NodeCfg$getVariantAnswerSetForVIdx, el.jZ, variantId.c1));
							},
							A2(
								$author$project$Type$NodeCfg$getItemData,
								$author$project$Type$NodeCfg$getItems(nodeCfg.fs),
								variantId))))));
	});
var $author$project$View$View$makeCurrentSessionResultsTable = F3(
	function (isLegacyTable, isShowInstrumentation, rows) {
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table is-striped is-hoverable is-fullwidth')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							$elm$core$List$concat(
								_List_fromArray(
									[
										_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Question #')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Correct?')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Topic')
												]))
										]),
										isLegacyTable ? _List_Nil : _List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$style, 'min-width', '192px')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Question band')
												]))
										]),
										_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Link')
												]))
										]),
										isShowInstrumentation ? _List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('VariantId')
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Raw Difficulty')
												]))
										]) : _List_Nil
									])))
						])),
					A2($elm$html$Html$tbody, _List_Nil, rows)
				]));
	});
var $author$project$Msgs$ViewQuestionAndAnswer = function (a) {
	return {$: 15, a: a};
};
var $author$project$UISnippet$Misc$makeResultHtml = function (urlMaybe) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$html$Html$text('-'),
		A2(
			$elm$core$Maybe$map,
			function (img) {
				return A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'height', '30px'),
							$elm$html$Html$Attributes$src(img)
						]),
					_List_Nil);
			},
			urlMaybe));
};
var $author$project$View$View$toCurrentSessionResultsTableRow = F5(
	function (cohortMaybe, isShowInstrumentation, itemDatas, _v0, topics) {
		var qIdx = _v0.a;
		var qAnswerDetails = _v0.b;
		var qNbrText = $elm$core$String$fromInt(qIdx + 1);
		var makeIsCorrectHtml = function (qAnswerStatus) {
			switch (qAnswerStatus) {
				case 2:
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('icon'),
									A2($elm$html$Html$Attributes$style, 'color', 'green')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fa fa-check')
										]),
									_List_Nil)
								]))
						]);
				case 0:
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('icon'),
									A2($elm$html$Html$Attributes$style, 'color', 'red')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fa fa-minus')
										]),
									_List_Nil)
								]))
						]);
				default:
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('icon'),
									A2($elm$html$Html$Attributes$style, 'color', 'red')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fa fa-times')
										]),
									_List_Nil)
								]))
						]);
			}
		};
		var diffToString = function (intMaybe) {
			return A2(
				$elm$core$Maybe$withDefault,
				'n/a',
				A2($elm$core$Maybe$map, $elm$core$String$fromInt, intMaybe));
		};
		var _v1 = function () {
			if (!qAnswerDetails.$) {
				var qResultItem = qAnswerDetails.a;
				var isCorrectHtml = makeIsCorrectHtml(qResultItem.dW);
				return _Utils_Tuple3(
					isCorrectHtml,
					$author$project$Msgs$ViewQuestionAndAnswer(qAnswerDetails),
					_Utils_Tuple2('n/a', $elm$core$Maybe$Nothing));
			} else {
				var historyItem = qAnswerDetails.a;
				var isCorrectHtml = makeIsCorrectHtml(
					$author$project$Type$ExamResult$toQAnswerStatus(historyItem.dW));
				var diffIntMaybe_ = A2($author$project$Type$ExamResult$getDifficultyForVariantId, itemDatas, historyItem.pK);
				return _Utils_Tuple3(
					isCorrectHtml,
					$author$project$Msgs$ViewQuestionAndAnswer(qAnswerDetails),
					_Utils_Tuple2(
						$author$project$Type$Variant$toShortStringVariantId(historyItem.pK),
						diffIntMaybe_));
			}
		}();
		var aCorrectOrNot = _v1.a;
		var action = _v1.b;
		var _v2 = _v1.c;
		var variantId = _v2.a;
		var diffIntMaybe = _v2.b;
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			$elm$core$List$concat(
				_List_fromArray(
					[
						_List_fromArray(
						[
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'vertical-align', 'middle')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Question ' + qNbrText)
								])),
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'vertical-align', 'middle')
								]),
							aCorrectOrNot),
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'vertical-align', 'middle'),
									A2($elm$html$Html$Attributes$style, 'white-space', 'pre-wrap')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									A3(
										$elm$core$List$foldr,
										$elm$core$Basics$append,
										'',
										A2($elm$core$List$intersperse, ', ', topics)))
								]))
						]),
						function () {
						if (!cohortMaybe.$) {
							var cohort = cohortMaybe.a;
							return _List_fromArray(
								[
									A2(
									$elm$html$Html$td,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$style, 'vertical-align', 'middle')
										]),
									_List_fromArray(
										[
											$author$project$UISnippet$Misc$makeResultHtml(
											A2(
												$elm$core$Maybe$map,
												$author$project$Type$ExamResult$difficultyToImageUrlSmall(cohort),
												diffIntMaybe))
										]))
								]);
						} else {
							return _List_Nil;
						}
					}(),
						_List_fromArray(
						[
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'vertical-align', 'middle')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('button'),
											$elm$html$Html$Events$onClick(action)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('View Q&A')
										]))
								]))
						]),
						isShowInstrumentation ? _List_fromArray(
						[
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'vertical-align', 'middle')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(variantId)
								])),
							A2(
							$elm$html$Html$td,
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$style, 'vertical-align', 'middle')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									diffToString(diffIntMaybe))
								]))
						]) : _List_Nil
					])));
	});
var $author$project$View$View$currentSessionResultsTable_HistoryItems = F3(
	function (nodeCfg, isShowInstrumentation, qs) {
		var topics = A2(
			$elm$core$List$map,
			function (el) {
				return A2($author$project$Type$NodeCfg$getCategoriesDescsForVariantId, nodeCfg, el.pK);
			},
			qs);
		var sessionStats = A2(
			$elm$core$List$indexedMap,
			F2(
				function (idx, el) {
					return _Utils_Tuple2(
						idx,
						$author$project$Type$ExamResult$ActivityResult(el));
				}),
			qs);
		var itemDatas = $author$project$Type$NodeCfg$getItems(nodeCfg.fs);
		return A3(
			$author$project$View$View$makeCurrentSessionResultsTable,
			false,
			isShowInstrumentation,
			A3(
				$elm$core$List$map2,
				A3(
					$author$project$View$View$toCurrentSessionResultsTableRow,
					$elm$core$Maybe$Just(nodeCfg.cF),
					isShowInstrumentation,
					itemDatas),
				sessionStats,
				topics));
	});
var $author$project$Type$ExamResult$QuestionResult = function (a) {
	return {$: 0, a: a};
};
var $author$project$Helper$Common$categoryForQuestion = F3(
	function (categories, qsMetas, qId) {
		var toNonTestletCategory = function (catId) {
			return $elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (el) {
						return _Utils_eq(el.lW, catId) && (!A2($elm$core$String$startsWith, 'TESTLET', el.mO));
					},
					categories));
		};
		var filterFirstCategoryWithoutTestletInName = function (catIds) {
			return $elm$core$List$head(
				A2($elm$core$List$filterMap, toNonTestletCategory, catIds));
		};
		return A2(
			$elm$core$Maybe$andThen,
			filterFirstCategoryWithoutTestletInName,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.kq;
				},
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (el) {
							return _Utils_eq(el.lW, qId);
						},
						qsMetas))));
	});
var $author$project$Helper$Common$topicsForQuestionList = F2(
	function (appCfg, questionResultItems) {
		return A2(
			$elm$core$List$map,
			function (el) {
				return A2(
					$elm$core$Maybe$withDefault,
					'Unknown category',
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.mO;
						},
						A3($author$project$Helper$Common$categoryForQuestion, appCfg.kr, appCfg.iw, el.dS)));
			},
			questionResultItems);
	});
var $author$project$View$View$currentSessionResultsTable_QuestionResultItems = F2(
	function (appCfg, qs) {
		var topics = A2(
			$elm$core$List$map,
			$elm$core$List$singleton,
			A2($author$project$Helper$Common$topicsForQuestionList, appCfg, qs));
		var sessionStats = A2(
			$elm$core$List$indexedMap,
			F2(
				function (idx, el) {
					return _Utils_Tuple2(
						idx,
						$author$project$Type$ExamResult$QuestionResult(el));
				}),
			qs);
		return A3(
			$author$project$View$View$makeCurrentSessionResultsTable,
			true,
			false,
			A3(
				$elm$core$List$map2,
				A3($author$project$View$View$toCurrentSessionResultsTableRow, $elm$core$Maybe$Nothing, false, _List_Nil),
				sessionStats,
				topics));
	});
var $krisajenkins$remotedata$RemoteData$fromMaybe = F2(
	function (error, maybe) {
		if (maybe.$ === 1) {
			return $krisajenkins$remotedata$RemoteData$Failure(error);
		} else {
			var x = maybe.a;
			return $krisajenkins$remotedata$RemoteData$Success(x);
		}
	});
var $author$project$Type$ExamResult$difficultyToImageUrlLarge = F2(
	function (cohort, diff) {
		return A2($author$project$Type$ExamResult$difficultyToImageUrlBase, cohort, diff) + '.gif';
	});
var $author$project$Type$ExamResult$getActivityResultUrlLarge = F3(
	function (cohort, nodeItems, hItems) {
		return A4($author$project$Type$ExamResult$getActivityResult, $author$project$Type$ExamResult$difficultyToImageUrlLarge, cohort, nodeItems, hItems);
	});
var $author$project$Routing$link = F3(
	function (href1, attrs, children) {
		return A2(
			$elm$html$Html$a,
			A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$Events$preventDefaultOn,
					'click',
					$elm$json$Json$Decode$succeed(
						_Utils_Tuple2(href1, true))),
				attrs),
			children);
	});
var $author$project$View$View$makeResultImage = F2(
	function (resultImageType, _v0) {
		var lvlMaybe = _v0.a;
		var bandStringMaybe = _v0.b;
		var _v1 = _Utils_Tuple2(lvlMaybe, bandStringMaybe);
		if (!_v1.a.$) {
			if (!_v1.b.$) {
				var lvl = _v1.a.a;
				var bandString = _v1.b.a;
				var baseUrl = 'https://teacher.smartermaths.com.au/wp-content/uploads/2021/06/nap' + (lvl + ('_res_' + bandString));
				if (!resultImageType) {
					return A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'height', '30px'),
								$elm$html$Html$Attributes$src(baseUrl + '.svg')
							]),
						_List_Nil);
				} else {
					return A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$src(baseUrl + '.gif')
							]),
						_List_Nil);
				}
			} else {
				var lvl = _v1.a.a;
				var _v3 = _v1.b;
				var nbrTxt = function () {
					switch (lvl) {
						case '3':
							return '25 or ';
						case '5':
							return '29 or ';
						case '7':
							return '33 or ';
						case '9':
							return '33 or ';
						default:
							return '';
					}
				}();
				var msgTxt = 'Result not available: This exam requires ' + (nbrTxt + 'more answers for an accurate result');
				if (!resultImageType) {
					return A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('icon has-tooltip-info'),
								A2($elm$html$Html$Attributes$attribute, 'data-tooltip', msgTxt)
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('fa fa-info-circle'),
										A2($elm$html$Html$Attributes$style, 'font-size', '22px'),
										A2($elm$html$Html$Attributes$style, 'color', 'cornflowerblue')
									]),
								_List_Nil)
							]));
				} else {
					return A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$style, 'color', 'white')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(msgTxt)
							]));
				}
			}
		} else {
			return A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Error: Lvl not provided')
					]));
		}
	});
var $author$project$Type$ExamResult$nbrCorrectIncorrect = function (serr) {
	if (!serr.$) {
		var tgrr = serr.a;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (el, _v1) {
					var correct = _v1.a;
					var incorrect = _v1.b;
					var _v2 = el.dW;
					if (_v2 === 2) {
						return _Utils_Tuple2(correct + 1, incorrect);
					} else {
						return _Utils_Tuple2(correct, incorrect + 1);
					}
				}),
			_Utils_Tuple2(0, 0),
			tgrr.ci);
	} else {
		var actrr = serr.a;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (el, _v3) {
					var correct = _v3.a;
					var incorrect = _v3.b;
					var _v4 = el.dW;
					if (!_v4.$) {
						switch (_v4.a.$) {
							case 0:
								var _v5 = _v4.a.a;
								var isCorrect = _v5.b;
								return isCorrect ? _Utils_Tuple2(correct + 1, incorrect) : _Utils_Tuple2(correct, incorrect + 1);
							case 1:
								var _v6 = _v4.a.a;
								var isCorrect = _v6.b;
								return isCorrect ? _Utils_Tuple2(correct + 1, incorrect) : _Utils_Tuple2(correct, incorrect + 1);
							default:
								var _v7 = _v4.a.a;
								var isCorrect = _v7.b;
								return isCorrect ? _Utils_Tuple2(correct + 1, incorrect) : _Utils_Tuple2(correct, incorrect + 1);
						}
					} else {
						return _Utils_Tuple2(correct, incorrect);
					}
				}),
			_Utils_Tuple2(0, 0),
			actrr.ci);
	}
};
var $author$project$View$View$viewSessionResults = F6(
	function (model, appCfg, serr, isShowBackButton, studentFName, assigneeMaybe) {
		var personalizationText = function () {
			if (studentFName.$ === 1) {
				return 'You';
			} else {
				var fNam = studentFName.a;
				return fNam;
			}
		}();
		var navButtons = A2(
			$elm$core$List$append,
			isShowBackButton ? _List_fromArray(
				[
					$author$project$View$ViewHelper$viewBackButton,
					$elm$html$Html$text(' ')
				]) : _List_Nil,
			_List_fromArray(
				[
					A3(
					$author$project$Routing$link,
					$author$project$Msgs$LinkClicked(
						$author$project$Routing$withHash('')),
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('button')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Go to home')
						]))
				]));
		var isNapAdmin = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2($elm$core$Maybe$map, $author$project$JsInterop$Types$isNapAdminFromUserDetails, model.pC));
		var resultTuple = function () {
			if (serr.$ === 1) {
				var actrr = serr.a;
				var itemDatasMaybe = $krisajenkins$remotedata$RemoteData$toMaybe(
					A2($krisajenkins$remotedata$RemoteData$map, $author$project$Type$NodeCfg$getItems, model.mX.fs));
				var currentCohortMaybe = function () {
					var assigneeCohortMaybe = A2(
						$elm$core$Maybe$andThen,
						function ($) {
							return $.hB;
						},
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.dc;
							},
							assigneeMaybe));
					var activeCohortMaybe = A2($elm$core$Maybe$andThen, $author$project$Type$Cohort$fromSyllabusTitleToCohortMaybe, model.mX.jJ);
					return _Utils_eq(assigneeCohortMaybe, $elm$core$Maybe$Nothing) ? activeCohortMaybe : assigneeCohortMaybe;
				}();
				var resultMaybe = A2(
					$elm$core$Maybe$andThen,
					function (itemDatas) {
						return A2(
							$elm$core$Maybe$andThen,
							function (currentCohort) {
								return A3($author$project$Type$ExamResult$getActivityResultUrlLarge, currentCohort, itemDatas, actrr.ci);
							},
							currentCohortMaybe);
					},
					itemDatasMaybe);
				var tempNodeCfg = A3(
					$krisajenkins$remotedata$RemoteData$map2,
					F2(
						function (currentCohort, el) {
							return _Utils_update(
								el,
								{cF: currentCohort});
						}),
					A2($krisajenkins$remotedata$RemoteData$fromMaybe, 'noCohortSpecified', currentCohortMaybe),
					$author$project$Type$NodeCfg$makeNodeCfgResolved(model.mX));
				var tempTable = A2(
					$krisajenkins$remotedata$RemoteData$withDefault,
					$elm$html$Html$text('Node configurations not available.  Please check your network connection, or contact support@smartermaths.com.au'),
					A2(
						$krisajenkins$remotedata$RemoteData$map,
						function (el) {
							return A3($author$project$View$View$currentSessionResultsTable_HistoryItems, el, isNapAdmin, actrr.ci);
						},
						tempNodeCfg));
				if (!resultMaybe.$) {
					var result = resultMaybe.a;
					return _Utils_Tuple2(
						A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(result)
								]),
							_List_Nil),
						tempTable);
				} else {
					return _Utils_Tuple2(
						$elm$html$Html$text('Result not available'),
						tempTable);
				}
			} else {
				var tgrr = serr.a;
				var resultAchieved_ = $author$project$Type$Testlet$isLegacyQGroupId(tgrr.nF) ? A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('tag is-info  is-rounded is-size-2')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							'Band ' + $elm$core$String$fromInt(
								A2($author$project$Helper$Band$singleExamRunBandResult, appCfg, tgrr)))
						])) : A2(
					$author$project$View$View$makeResultImage,
					1,
					A2($author$project$Helper$Band$singleExamRunBandString, appCfg, tgrr));
				return _Utils_Tuple2(
					resultAchieved_,
					A2($author$project$View$View$currentSessionResultsTable_QuestionResultItems, appCfg, tgrr.ci));
			}
		}();
		var heading = personalizationText + ' achieved a Band ...';
		var errorAndRetryMessage = function () {
			var _v2 = model.ll;
			if (_v2.$ === 1) {
				return $mdgriffith$elm_ui$Element$none;
			} else {
				var err = _v2.a;
				return A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$padding(20),
							$mdgriffith$elm_ui$Element$Border$rounded(10),
							$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.eC),
							$mdgriffith$elm_ui$Element$centerX,
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(20)
						]),
					_List_fromArray(
						[
							A2(
							$elm$core$Maybe$withDefault,
							$mdgriffith$elm_ui$Element$text(err.lj),
							A2(
								$elm$core$Maybe$map,
								function (el) {
									return A2($elm$core$String$contains, 'Network Error', el) ? A2(
										$mdgriffith$elm_ui$Element$column,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$spacing(10)
											]),
										_List_fromArray(
											[
												A2(
												$mdgriffith$elm_ui$Element$paragraph,
												_List_fromArray(
													[$mdgriffith$elm_ui$Element$Font$bold]),
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$text('Error:  No internet / Disconnected.')
													])),
												A2(
												$mdgriffith$elm_ui$Element$paragraph,
												_List_Nil,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$text('If you leave this page your results will not be saved.')
													])),
												A2(
												$mdgriffith$elm_ui$Element$paragraph,
												_List_Nil,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$text('To continue:')
													])),
												A2(
												$mdgriffith$elm_ui$Element$column,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$spacing(4),
														A2($mdgriffith$elm_ui$Element$paddingXY, 20, 0)
													]),
												_List_fromArray(
													[
														A2(
														$mdgriffith$elm_ui$Element$paragraph,
														_List_Nil,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$text(' 1. Do not leave or refresh this page.')
															])),
														A2(
														$mdgriffith$elm_ui$Element$paragraph,
														_List_Nil,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$text(' 2. Fix your internet.')
															])),
														A2(
														$mdgriffith$elm_ui$Element$paragraph,
														_List_Nil,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$text(' 3. Click the \'Save results\' button below, to try again.')
															])),
														A2(
														$mdgriffith$elm_ui$Element$paragraph,
														_List_Nil,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$text(' 4. If your results are sucessfully saved, then this message will disappear.  You can repeat steps 1 through 4 if needed.')
															]))
													])),
												A2(
												$author$project$Page$PagesCommon$regButton,
												true,
												{
													bD: $mdgriffith$elm_ui$Element$text('Save results'),
													ai: $elm$core$Maybe$Just($author$project$Msgs$FinishExam)
												})
											])) : A2(
										$mdgriffith$elm_ui$Element$column,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$spacing(10)
											]),
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$text(err.lj),
												A2(
												$mdgriffith$elm_ui$Element$paragraph,
												_List_fromArray(
													[$mdgriffith$elm_ui$Element$Font$italic]),
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$text('The fine print technical details are: ')
													])),
												A2(
												$mdgriffith$elm_ui$Element$paragraph,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$Font$size(14)
													]),
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$text(el)
													]))
											]));
								},
								err.lk))
						]));
			}
		}();
		var _v0 = resultTuple;
		var resultAchieved = _v0.a;
		var resultsTable = _v0.b;
		var pageMeat = _List_fromArray(
			[
				errorAndRetryMessage,
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$centerX,
						$mdgriffith$elm_ui$Element$Font$size(26),
						$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$white),
						$mdgriffith$elm_ui$Element$Font$bold
					]),
				$mdgriffith$elm_ui$Element$text(heading)),
				A2(
				$mdgriffith$elm_ui$Element$column,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$html(resultAchieved),
						A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$centerX,
								$mdgriffith$elm_ui$Element$Font$size(16),
								$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$white)
							]),
						$mdgriffith$elm_ui$Element$text(
							'on ' + $author$project$Type$DateTime$toDateString(
								$author$project$Type$ExamResult$extractTsFinish(serr))))
					])),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				$mdgriffith$elm_ui$Element$html(
					A2($elm$html$Html$section, _List_Nil, navButtons))),
				function () {
				if (isNapAdmin) {
					var _v1 = A3(
						$elm$core$Tuple$mapBoth,
						$elm$core$String$fromInt,
						$elm$core$String$fromInt,
						$author$project$Type$ExamResult$nbrCorrectIncorrect(serr));
					var correct = _v1.a;
					var incorrect = _v1.b;
					return A2(
						$mdgriffith$elm_ui$Element$el,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$size(18),
								$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$white)
							]),
						$mdgriffith$elm_ui$Element$text('Correct/Incorrect: ' + (correct + ('/' + incorrect))));
				} else {
					return $mdgriffith$elm_ui$Element$none;
				}
			}(),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$Font$size(16)
					]),
				$mdgriffith$elm_ui$Element$html(
					A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('box')
							]),
						_List_fromArray(
							[resultsTable])))),
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				$mdgriffith$elm_ui$Element$html(
					A2($elm$html$Html$section, _List_Nil, navButtons)))
			]);
		return A4(
			$author$project$Page$PagesCommon$makePage,
			$elm$core$Maybe$Nothing,
			$author$project$View$ViewHelper$topBarAsElement(model),
			$elm$core$Maybe$Nothing,
			pageMeat);
	});
var $author$project$Type$Configuration$ConfigMode = function (a) {
	return {$: 0, a: a};
};
var $author$project$Msgs$UpdateActivitiesViewMode = function (a) {
	return {$: 5, a: a};
};
var $author$project$Type$ExamResult$getLegacyResult = $elm$core$List$filter(
	A2(
		$elm$core$Basics$composeL,
		$author$project$Type$Testlet$isLegacyQGroupId,
		function ($) {
			return $.nF;
		}));
var $author$project$Type$ExamResult$getTestletGroupResultRecords = function (serrs) {
	return A2(
		$elm$core$List$filterMap,
		function (el) {
			if (el.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var tgrr = el.a;
				return $elm$core$Maybe$Just(tgrr);
			}
		},
		serrs);
};
var $mdgriffith$elm_ui$Element$Input$OnLeft = 1;
var $mdgriffith$elm_ui$Element$Input$labelLeft = $mdgriffith$elm_ui$Element$Input$Label(1);
var $author$project$UISnippet$Misc$SVG = 0;
var $author$project$Helper$Common$getMostRecentBandStringAchievedForQGroup = F3(
	function (appCfg, studentData, qGroup) {
		return A2(
			$elm$core$Maybe$map,
			$author$project$Helper$Band$singleExamRunBandString(appCfg),
			$elm$core$List$head(
				$elm$core$List$reverse(
					A2(
						$elm$core$List$sortBy,
						function ($) {
							return $.pr;
						},
						A2(
							$elm$core$List$filter,
							A2(
								$elm$core$Basics$composeL,
								$elm$core$Basics$eq(qGroup),
								function ($) {
									return $.nF;
								}),
							A2($elm$core$List$filterMap, $author$project$Type$ExamResult$filterTestletGroupResultRecords, studentData.eZ))))));
	});
var $author$project$Msgs$DoNothing = {$: 0};
var $author$project$UISnippet$HideShow$btnHideShowHtml = F2(
	function (isHidden, msgMaybe) {
		var buttonStyle = 'button is-small is-rounded';
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('field has-addons')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('control')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							function () {
								var baseAttribs = _List_fromArray(
									[
										$elm$html$Html$Attributes$class(
										_Utils_ap(
											buttonStyle,
											isHidden ? ' is-danger' : ''))
									]);
								if (!isHidden) {
									if (msgMaybe.$ === 1) {
										return baseAttribs;
									} else {
										var msg = msgMaybe.a;
										return A2(
											$elm$core$List$cons,
											$elm$html$Html$Events$onClick(msg),
											baseAttribs);
									}
								} else {
									return baseAttribs;
								}
							}(),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('icon is-small')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('fa fa-eye-slash')
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$span,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Hide')
										]))
								]))
						])),
					A2(
					$elm$html$Html$p,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('control')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							function () {
								var baseAttribs = _List_fromArray(
									[
										$elm$html$Html$Attributes$class(
										_Utils_ap(
											buttonStyle,
											(!isHidden) ? ' is-success' : ''))
									]);
								if (isHidden) {
									if (msgMaybe.$ === 1) {
										return baseAttribs;
									} else {
										var msg = msgMaybe.a;
										return A2(
											$elm$core$List$cons,
											$elm$html$Html$Events$onClick(msg),
											baseAttribs);
									}
								} else {
									return baseAttribs;
								}
							}(),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('icon is-small')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('fa fa-eye')
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$span,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Show')
										]))
								]))
						]))
				]));
	});
var $author$project$View$ViewHelper$makeExamTypBadge = function (typ) {
	return (!typ) ? A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('tag is-rounded is-small is-warning')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Trial Exam')
			])) : A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('tag is-rounded is-small is-info')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Revision')
			]));
};
var $author$project$View$ViewHelper$makeTimeString = function (tstTime) {
	return A2(
		$elm$core$Maybe$withDefault,
		'? min',
		A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$String$fromInt,
				function (el) {
					return el + ' min';
				}),
			A2(
				$elm$core$Maybe$map,
				function (el) {
					return (el / 60) | 0;
				},
				tstTime)));
};
var $author$project$View$View$toQGroupTableRow = F7(
	function (activitiesViewMode, hiddenExamGroupsMaybe, isStudentRequestor, selfOrAssignee, questionGroup, timesAttempted, bestBandLevel) {
		var timeString = $author$project$View$ViewHelper$makeTimeString(questionGroup.ps);
		var textSizeClass = $elm$html$Html$Attributes$class('is-size-6');
		var tdStyle = A2($elm$html$Html$Attributes$style, 'vertical-align', 'middle');
		var qGroupTag = $author$project$View$ViewHelper$makeExamTypBadge(questionGroup.iv.pt);
		var qGroupId = $elm$core$String$fromInt(questionGroup.lW);
		var groupMaybe = function () {
			switch (activitiesViewMode.$) {
				case 0:
					var groupMaybe_ = activitiesViewMode.a;
					return groupMaybe_;
				case 1:
					return $elm$core$Maybe$Nothing;
				default:
					return selfOrAssignee.dc.eV;
			}
		}();
		var isHidden = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				function (cohort) {
					return A4($author$project$Type$HiddenExamGroup$isHiddenActivity, hiddenExamGroupsMaybe, cohort, groupMaybe, qGroupId);
				},
				selfOrAssignee.dc.hB));
		var msg = function () {
			var questionGroupState = isHidden ? 1 : 0;
			return A2(
				$elm$core$Maybe$withDefault,
				$author$project$Msgs$DoNothing,
				A2(
					$elm$core$Maybe$map,
					function (cohort) {
						return A4($author$project$Msgs$UpdateHiddenExamGroups, questionGroupState, cohort, groupMaybe, qGroupId);
					},
					selfOrAssignee.dc.hB));
		}();
		return A2(
			$elm$html$Html$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[textSizeClass, tdStyle]),
					_List_fromArray(
						[qGroupTag])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[textSizeClass, tdStyle]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							isStudentRequestor ? $author$project$Page$PagesCommon$stripYearLevel(questionGroup.iv.mO) : questionGroup.iv.mO)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[textSizeClass, tdStyle]),
					_List_fromArray(
						[
							$elm$html$Html$text(timeString)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[textSizeClass, tdStyle]),
					_List_fromArray(
						[
							$elm$html$Html$text(questionGroup.iv.k8)
						])),
					A2(
					$elm$html$Html$td,
					_List_fromArray(
						[
							textSizeClass,
							tdStyle,
							A2($elm$html$Html$Attributes$style, 'text-align', 'center')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$elm$core$String$fromInt(timesAttempted))
						])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[bestBandLevel])),
					A2(
					$elm$html$Html$td,
					_List_Nil,
					_List_fromArray(
						[
							function () {
							if (!timesAttempted) {
								return $elm$html$Html$text('');
							} else {
								if (timesAttempted === 1) {
									var tsFinish = A2(
										$elm$core$Maybe$map,
										function ($) {
											return $.pr;
										},
										$elm$core$List$head(
											A2(
												$elm$core$List$filter,
												function (el) {
													return _Utils_eq(el.nF, questionGroup.lW);
												},
												A2($elm$core$List$filterMap, $author$project$Type$ExamResult$filterTestletGroupResultRecords, selfOrAssignee.lU))));
									return A3(
										$author$project$Routing$link,
										$author$project$Msgs$LinkClicked(
											$author$project$Routing$withHash($author$project$Routing$smPaths.of) + ('/' + (selfOrAssignee.dc.pH + ('/' + (qGroupId + ('/' + A2($elm$core$Maybe$withDefault, 'unknown', tsFinish))))))),
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('button is-primary is-small')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Detail')
											]));
								} else {
									return A3(
										$author$project$Routing$link,
										$author$project$Msgs$LinkClicked(
											$author$project$Routing$withHash($author$project$Routing$smPaths.og) + ('/' + (selfOrAssignee.dc.pH + ('/' + qGroupId)))),
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('button is-primary is-small')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Detail')
											]));
								}
							}
						}()
						])),
					function () {
					if (!activitiesViewMode.$) {
						return A2(
							$elm$html$Html$td,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$author$project$UISnippet$HideShow$btnHideShowHtml,
									isHidden,
									$elm$core$Maybe$Just(msg))
								]));
					} else {
						return A2(
							$elm$html$Html$td,
							_List_Nil,
							_List_fromArray(
								[
									isStudentRequestor ? ($elm$core$List$isEmpty(questionGroup.iv.l9) ? A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('button is-danger is-small'),
											$elm$html$Html$Events$onClick(
											A3($author$project$Msgs$StartQuestionGroup, questionGroup.lW, questionGroup.oE, questionGroup.ps))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Start Now')
										])) : A3(
									$author$project$Routing$link,
									$author$project$Msgs$LinkClicked(
										$author$project$Routing$withHash($author$project$Routing$smPaths.g$) + ('/' + qGroupId)),
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('button is-danger is-small')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Start Now')
										]))) : $elm$html$Html$text('')
								]));
					}
				}()
				]));
	});
var $author$project$Helper$Common$uniqueBandsAchievedForQGroup = F3(
	function (appCfg, studentData, qGroup) {
		return $elm$core$Set$toList(
			$elm$core$Set$fromList(
				A2(
					$elm$core$List$map,
					$author$project$Helper$Band$singleExamRunBandResult(appCfg),
					A2(
						$elm$core$List$filter,
						A2(
							$elm$core$Basics$composeL,
							$elm$core$Basics$eq(qGroup),
							function ($) {
								return $.nF;
							}),
						A2($elm$core$List$filterMap, $author$project$Type$ExamResult$filterTestletGroupResultRecords, studentData.eZ)))));
	});
var $author$project$View$Bands$makeBandTag = F2(
	function (colorMaybe, band) {
		var color = function () {
			if (colorMaybe.$ === 1) {
				switch (band) {
					case 2:
						return 'is-success';
					case 3:
						return 'is-warning';
					case 4:
						return 'is-dark';
					case 5:
						return 'is-primary';
					case 6:
						return 'is-link';
					case 7:
						return 'is-info';
					case 8:
						return 'is-success';
					case 9:
						return 'is-warning';
					case 10:
						return 'is-danger';
					default:
						return 'is-light';
				}
			} else {
				var colorRequested = colorMaybe.a;
				return colorRequested;
			}
		}();
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tag is-rounded ' + (color + ' is-small'))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$elm$core$String$fromInt(band))
				]));
	});
var $author$project$View$Bands$viewBandBalls = F2(
	function (allBandsAchieved, lvl) {
		var belowBandTag = function (el) {
			return A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('tag is-rounded is-light is-small')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$elm$core$String$fromInt(el))
					]));
		};
		var aboveBandTag = function (el) {
			return A2($elm$html$Html$span, _List_Nil, _List_Nil);
		};
		var _v0 = function () {
			switch (lvl) {
				case 3:
					return _Utils_Tuple2(2, 7);
				case 5:
					return _Utils_Tuple2(3, 8);
				case 7:
					return _Utils_Tuple2(4, 9);
				case 9:
					return _Utils_Tuple2(5, 10);
				default:
					return _Utils_Tuple2(1, 10);
			}
		}();
		var minBand = _v0.a;
		var maxBand = _v0.b;
		var coloredTagList = A2(
			$elm$core$List$map,
			function (el) {
				return (_Utils_cmp(
					el,
					A2(
						$elm$core$Maybe$withDefault,
						0,
						$elm$core$List$maximum(allBandsAchieved))) > 0) ? aboveBandTag(el) : (A2($elm$core$List$member, el, allBandsAchieved) ? A2($author$project$View$Bands$makeBandTag, $elm$core$Maybe$Nothing, el) : belowBandTag(el));
			},
			A2($elm$core$List$range, minBand, maxBand));
		return A2($elm$html$Html$span, _List_Nil, coloredTagList);
	});
var $author$project$View$View$qGroupsTable = F6(
	function (activitiesViewMode, hiddenExamGroupsMaybe, qGroups, appCfg, isStudentRequestor, selfOrAssignee) {
		var timesAttempteds = function () {
			var timesAttempted = function (qGId) {
				return $elm$core$List$length(
					A2(
						$elm$core$List$filter,
						function (qGroupHistory) {
							return _Utils_eq(qGroupHistory.nF, qGId);
						},
						A2($elm$core$List$filterMap, $author$project$Type$ExamResult$filterTestletGroupResultRecords, selfOrAssignee.lU)));
			};
			return A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.lW;
					},
					timesAttempted),
				qGroups);
		}();
		var lvl = A2(
			$elm$core$Maybe$withDefault,
			7,
			A2($elm$core$Maybe$map, $author$project$Type$Cohort$toInt, selfOrAssignee.dc.hB));
		var allBandLevelsForGroup = A2(
			$elm$core$List$map,
			function (qGroup) {
				return $author$project$Type$Testlet$isLegacyQGroupId(qGroup.lW) ? A2(
					$author$project$View$Bands$viewBandBalls,
					A3(
						$author$project$Helper$Common$uniqueBandsAchievedForQGroup,
						appCfg,
						{eZ: selfOrAssignee.lU},
						qGroup.lW),
					lvl) : A2(
					$elm$core$Maybe$withDefault,
					$elm$html$Html$text(''),
					A2(
						$elm$core$Maybe$map,
						$author$project$View$View$makeResultImage(0),
						A3(
							$author$project$Helper$Common$getMostRecentBandStringAchievedForQGroup,
							appCfg,
							{eZ: selfOrAssignee.lU},
							qGroup.lW)));
			},
			qGroups);
		return $elm$core$List$isEmpty(qGroups) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('notification is-warning')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('No NAPLAN Style exams have been made available by your teacher.')
				])) : A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table is-striped is-hoverable is-fullwidth is-size-6')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$th,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('has-text-weight-normal')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Type')
										])),
									A2(
									$elm$html$Html$th,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('has-text-weight-normal')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Name')
										])),
									A2(
									$elm$html$Html$th,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('has-text-weight-normal')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Time')
										])),
									A2(
									$elm$html$Html$th,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('has-text-weight-normal')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Description')
										])),
									A2(
									$elm$html$Html$th,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('has-text-weight-normal'),
											A2($elm$html$Html$Attributes$style, 'text-align', 'center')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Attempts')
										])),
									A2(
									$elm$html$Html$th,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('has-text-weight-normal'),
											A2($elm$html$Html$Attributes$style, 'min-width', '12rem')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Recent Result')
										])),
									A2(
									$elm$html$Html$th,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('has-text-weight-normal')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('History')
										])),
									function () {
									if (!activitiesViewMode.$) {
										return A2(
											$elm$html$Html$th,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('has-text-weight-normal')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Show to students?')
												]));
									} else {
										return A2(
											$elm$html$Html$th,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('has-text-weight-normal')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('')
												]));
									}
								}()
								]))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					A4(
						$elm$core$List$map3,
						A4($author$project$View$View$toQGroupTableRow, activitiesViewMode, hiddenExamGroupsMaybe, isStudentRequestor, selfOrAssignee),
						qGroups,
						timesAttempteds,
						allBandLevelsForGroup))
				]));
	});
var $author$project$View$View$viewStudentHome = F4(
	function (model, appCfg, isStudentRequestor, selfOrAssignee) {
		var hiddenExamGroupsMaybe = A2($krisajenkins$remotedata$RemoteData$withDefault, $elm$core$Maybe$Nothing, model.hi);
		var _v0 = selfOrAssignee.dc.hB;
		if ((!_v0.$) && (_v0.a.$ === 1)) {
			var _v1 = _v0.a;
			return A2(
				$Punie$elm_reader$Reader$map,
				$elm$html$Html$map($author$project$Msgs$MsgPageStudentHome),
				A5($author$project$Page$StudentHome$view, model.ie, $author$project$Page$PagesCommon$topBar, hiddenExamGroupsMaybe, isStudentRequestor, selfOrAssignee));
		} else {
			var uidAndIsShowLegacyTests = function () {
				var _v9 = model.n_;
				if (_v9.$ === 29) {
					var uid_ = _v9.a;
					var queryMaybe = _v9.b;
					return $elm$core$Maybe$Just(
						_Utils_Tuple2(
							uid_,
							!_Utils_eq(queryMaybe, $elm$core$Maybe$Nothing)));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}();
			var qGroupsLegacyAndNew = function () {
				var qGroups_ = isStudentRequestor ? A3($author$project$View$View$getQGroupsFilteredByLvlAndHiddenExams, model, appCfg, selfOrAssignee) : A2($author$project$Type$AppCfg$examsForLevel, selfOrAssignee.dc.hB, appCfg);
				return A2(
					$elm$core$List$partition,
					A2(
						$elm$core$Basics$composeL,
						$author$project$Type$Testlet$isLegacyQGroupId,
						function ($) {
							return $.lW;
						}),
					qGroups_);
			}();
			var possibleBackButton = isStudentRequestor ? $elm$html$Html$text('') : $author$project$View$ViewHelper$viewBackButton;
			var isHasLegacyResults = !$elm$core$List$isEmpty(
				$author$project$Type$ExamResult$getLegacyResult(
					$author$project$Type$ExamResult$getTestletGroupResultRecords(selfOrAssignee.lU)));
			var headerLineString = function () {
				if (isStudentRequestor) {
					var _v8 = model.n_;
					if (_v8.$ === 6) {
						return selfOrAssignee.dc.lt;
					} else {
						return 'Hi ' + (selfOrAssignee.dc.lt + '!');
					}
				} else {
					return 'Results for ' + (selfOrAssignee.dc.lt + (' ' + selfOrAssignee.dc.mq));
				}
			}();
			var configPreviewElement = function () {
				var groupsForCohortMaybe = A2(
					$elm$core$Maybe$map,
					$elm$core$List$sort,
					A2(
						$elm$core$Maybe$andThen,
						function (els) {
							return $elm$core$List$isEmpty(els) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(els);
						},
						A2(
							$elm$core$Maybe$map,
							$author$project$Helper$Util$unique,
							A2(
								$elm$core$Maybe$map,
								$elm$core$List$filterMap(
									function (el) {
										return el.dc.eV;
									}),
								A2(
									$elm$core$Maybe$map,
									$elm$core$List$filter(
										function (el) {
											return _Utils_eq(
												$elm$core$Maybe$Just(el.n2),
												$author$project$Type$User$getMeId(model.fc));
										}),
									A2(
										$elm$core$Maybe$map,
										$author$project$Page$ManageUsers$toFilteredAssignees(
											{cK: '', b6: '', bX: selfOrAssignee.dc.hB}),
										$krisajenkins$remotedata$RemoteData$toMaybe(model.h5.S)))))));
				var configModeRadios = A2(
					$mdgriffith$elm_ui$Element$Input$radioRow,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$paddingEach(
							{er: 0, e9: 0, fz: 0, fY: 0}),
							$mdgriffith$elm_ui$Element$spacing(30)
						]),
					{
						bD: A2($mdgriffith$elm_ui$Element$Input$labelLeft, _List_Nil, $mdgriffith$elm_ui$Element$none),
						cS: $author$project$Msgs$UpdateActivitiesViewMode,
						ni: _List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$Input$option,
								$author$project$Type$Configuration$TeacherPreviewMode,
								$mdgriffith$elm_ui$Element$text('Preview Mode')),
								A2(
								$mdgriffith$elm_ui$Element$Input$option,
								function () {
									var _v7 = model.f7;
									if (!_v7.$) {
										var group = _v7.a;
										return $author$project$Type$Configuration$ConfigMode(group);
									} else {
										return $author$project$Type$Configuration$ConfigMode($elm$core$Maybe$Nothing);
									}
								}(),
								$mdgriffith$elm_ui$Element$text('Configuration Mode'))
							]),
						ob: $elm$core$Maybe$Just(model.f7)
					});
				var configModeGroups = function () {
					if (groupsForCohortMaybe.$ === 1) {
						return $mdgriffith$elm_ui$Element$none;
					} else {
						var groupsForCohort = groupsForCohortMaybe.a;
						return A2(
							$mdgriffith$elm_ui$Element$column,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$spacing(10)
								]),
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$text('Apply hide/show settings for students by group:'),
									A2(
									$mdgriffith$elm_ui$Element$Input$radioRow,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$paddingEach(
											{er: 0, e9: 0, fz: 0, fY: 0}),
											$mdgriffith$elm_ui$Element$spacing(30)
										]),
									{
										bD: A2($mdgriffith$elm_ui$Element$Input$labelLeft, _List_Nil, $mdgriffith$elm_ui$Element$none),
										cS: $author$project$Msgs$UpdateActivitiesViewMode,
										ni: A2(
											$elm$core$List$cons,
											A2(
												$mdgriffith$elm_ui$Element$Input$option,
												$author$project$Type$Configuration$ConfigMode($elm$core$Maybe$Nothing),
												$mdgriffith$elm_ui$Element$text('No group allocated')),
											A2(
												$elm$core$List$map,
												function (el) {
													return A2(
														$mdgriffith$elm_ui$Element$Input$option,
														$author$project$Type$Configuration$ConfigMode(
															$elm$core$Maybe$Just(el)),
														$mdgriffith$elm_ui$Element$text(el));
												},
												groupsForCohort)),
										ob: $elm$core$Maybe$Just(model.f7)
									})
								]));
					}
				}();
				return A2(
					$mdgriffith$elm_ui$Element$column,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
							$mdgriffith$elm_ui$Element$spacing(10)
						]),
					_List_fromArray(
						[
							configModeRadios,
							A2(
							$mdgriffith$elm_ui$Element$column,
							_List_Nil,
							_List_fromArray(
								[
									function () {
									var _v5 = model.f7;
									switch (_v5.$) {
										case 1:
											return A2(
												$mdgriffith$elm_ui$Element$column,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$spacing(10)
													]),
												_List_fromArray(
													[
														A2(
														$mdgriffith$elm_ui$Element$paragraph,
														_List_Nil,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$text('Preview mode: Activities can be attempted but will not be saved.')
															])),
														A2(
														$mdgriffith$elm_ui$Element$paragraph,
														_List_Nil,
														_List_fromArray(
															[
																$mdgriffith$elm_ui$Element$text('Configuration mode: Hide/show the activities that your students will see.')
															]))
													]));
										case 0:
											return A2(
												$mdgriffith$elm_ui$Element$column,
												_List_fromArray(
													[
														$mdgriffith$elm_ui$Element$spacing(10)
													]),
												_List_fromArray(
													[configModeGroups]));
										default:
											return $mdgriffith$elm_ui$Element$none;
									}
								}()
								]))
						]));
			}();
			var buildTestletsTable = F4(
				function (qGroups, heading, elementMsg, isShowTable) {
					return A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$row,
								$author$project$Page$PagesCommon$contentTitleRowAttrs,
								_List_fromArray(
									[
										$author$project$Page$PagesCommon$makeContentTitleElement(heading),
										A2(
										$mdgriffith$elm_ui$Element$el,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$alignRight,
												$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.oL),
												$mdgriffith$elm_ui$Element$Font$size(16)
											]),
										elementMsg)
									])),
								isShowTable ? A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
									]),
								$mdgriffith$elm_ui$Element$html(
									A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('box')
											]),
										_List_fromArray(
											[
												A6($author$project$View$View$qGroupsTable, model.f7, hiddenExamGroupsMaybe, qGroups, appCfg, isStudentRequestor, selfOrAssignee)
											])))) : $mdgriffith$elm_ui$Element$none
							]));
				});
			var _v2 = qGroupsLegacyAndNew;
			var qGroupsLegacy = _v2.a;
			var qGroupsNew = _v2.b;
			var makePageMeat = function (rState) {
				return _List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$spacing(20),
								$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$white),
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						$elm$core$List$concat(
							_List_fromArray(
								[
									_List_fromArray(
									[
										function () {
										var _v3 = model.n_;
										if (_v3.$ === 6) {
											return configPreviewElement;
										} else {
											return $mdgriffith$elm_ui$Element$none;
										}
									}()
									]),
									_List_fromArray(
									[
										A2(
										$mdgriffith$elm_ui$Element$column,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$spacing(20),
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
												$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$black),
												$mdgriffith$elm_ui$Element$Font$size(16)
											]),
										A2(
											$elm$core$List$map,
											$mdgriffith$elm_ui$Element$map($author$project$Msgs$MsgPageStudentHome),
											_List_fromArray(
												[
													A2(
													$Punie$elm_reader$Reader$run,
													A6($author$project$Page$StudentHome$activitiesSection, model.ie, hiddenExamGroupsMaybe, 0, isStudentRequestor, selfOrAssignee, model.f7),
													rState),
													A2(
													$Punie$elm_reader$Reader$run,
													A6($author$project$Page$StudentHome$activitiesSection, model.ie, hiddenExamGroupsMaybe, 1, isStudentRequestor, selfOrAssignee, model.f7),
													rState)
												]))),
										(!_Utils_eq(
										selfOrAssignee.dc.hB,
										$elm$core$Maybe$Just(
											$author$project$Type$Cohort$Aus(
												$author$project$Type$Cohort$Stage5(1))))) ? A4(buildTestletsTable, qGroupsNew, 'NAPLAN Style Exams', $mdgriffith$elm_ui$Element$none, true) : $mdgriffith$elm_ui$Element$none,
										(($elm$core$List$length(qGroupsLegacy) > 0) && isHasLegacyResults) ? A2(
										$elm$core$Maybe$withDefault,
										$mdgriffith$elm_ui$Element$none,
										A2(
											$elm$core$Maybe$map,
											function (_v4) {
												var uid = _v4.a;
												var isShowLegacyTests = _v4.b;
												return A2(
													$mdgriffith$elm_ui$Element$column,
													_List_fromArray(
														[
															$mdgriffith$elm_ui$Element$spacing(15),
															$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
														]),
													_List_fromArray(
														[
															function () {
															var hideShowBtn = A2(
																$author$project$Page$PagesCommon$thinButton,
																true,
																{
																	bD: $mdgriffith$elm_ui$Element$text(
																		isShowLegacyTests ? 'Hide' : 'Show'),
																	ai: $elm$core$Maybe$Just(
																		A2(
																			$author$project$Msgs$LinkTo,
																			$author$project$Routing$withHash($author$project$Routing$smPaths.oF) + ('/' + (uid + (isShowLegacyTests ? '' : '?show=legacytests'))),
																			$elm$core$Maybe$Nothing))
																});
															return A4(buildTestletsTable, qGroupsLegacy, 'Legacy Tests', hideShowBtn, isShowLegacyTests);
														}()
														]));
											},
											uidAndIsShowLegacyTests)) : $mdgriffith$elm_ui$Element$none,
										A2(
										$mdgriffith$elm_ui$Element$row,
										_List_fromArray(
											[
												$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
											]),
										_List_fromArray(
											[
												A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[$mdgriffith$elm_ui$Element$alignLeft]),
												$mdgriffith$elm_ui$Element$html(possibleBackButton)),
												A2(
												$mdgriffith$elm_ui$Element$el,
												_List_fromArray(
													[$mdgriffith$elm_ui$Element$alignRight]),
												$mdgriffith$elm_ui$Element$html(
													A2(
														$elm$html$Html$p,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('is-size-7 has-text-right')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('v' + model.lx.pL)
															]))))
											]))
									])
								])))
					]);
			};
			return $Punie$elm_reader$Reader$asks(
				function (rState) {
					return A4(
						$author$project$Page$PagesCommon$makePage,
						$elm$core$Maybe$Nothing,
						$author$project$View$ViewHelper$topBarAsElement(model),
						$elm$core$Maybe$Just(
							$mdgriffith$elm_ui$Element$text(headerLineString)),
						makePageMeat(rState));
				});
		}
	});
var $author$project$View$View$handleCommonRoutes = F5(
	function (model, appCfg, me, userType, assignee) {
		var topBarFnPartial = $author$project$Page$PagesCommon$topBar;
		var _v0 = model.n_;
		switch (_v0.$) {
			case 13:
				return A2(
					$Punie$elm_reader$Reader$map,
					$elm$html$Html$map($author$project$Msgs$MsgPageMjd5),
					A2($author$project$Page$Mjd5$view, model.h6, topBarFnPartial));
			case 14:
				var hiddenExamGroupsMaybe = A2($krisajenkins$remotedata$RemoteData$withDefault, $elm$core$Maybe$Nothing, model.hi);
				return A2(
					$Punie$elm_reader$Reader$map,
					$elm$html$Html$map($author$project$Msgs$MsgPageStudentHome),
					A5(
						$author$project$Page$StudentHome$view,
						model.ie,
						topBarFnPartial,
						hiddenExamGroupsMaybe,
						_Utils_eq(userType, $author$project$Type$User$Student) ? true : false,
						assignee));
			case 15:
				return A2(
					$Punie$elm_reader$Reader$map,
					$elm$html$Html$map($author$project$Msgs$MsgPageAdminPage),
					A2($author$project$Page$AdminPage$view, model.h0, topBarFnPartial));
			case 16:
				return A2(
					$Punie$elm_reader$Reader$map,
					$elm$html$Html$map($author$project$Msgs$MsgPageShowVariant),
					A2($author$project$Page$ShowVariant$view, model.no, topBarFnPartial));
			case 17:
				return A2(
					$Punie$elm_reader$Reader$map,
					$elm$html$Html$map($author$project$Msgs$MsgPageProfile),
					A2($author$project$Page$Profile$view, model.h8, topBarFnPartial));
			case 12:
				return A2(
					$Punie$elm_reader$Reader$map,
					$elm$html$Html$map($author$project$Msgs$MsgPageReportCard),
					A2($author$project$Page$ReportCard$view, model.h9, topBarFnPartial));
			case 18:
				return $Punie$elm_reader$Reader$reader(
					A2(
						$elm$html$Html$map,
						$author$project$Msgs$MsgPageSignIn,
						A2($author$project$Page$SignIn$view, model.lx, model.id)));
			case 21:
				var createUserRouteType = _v0.a;
				return A2(
					$Punie$elm_reader$Reader$map,
					$elm$html$Html$map($author$project$Msgs$MsgPageCreateUsers),
					A3($author$project$Page$CreateUsers$view, model.h1, topBarFnPartial, createUserRouteType));
			case 22:
				var manageUserRouteType = _v0.a;
				return A2(
					$Punie$elm_reader$Reader$map,
					$elm$html$Html$map($author$project$Msgs$MsgPageManageUsers),
					A4($author$project$Page$ManageUsers$view, appCfg, model.h5, topBarFnPartial, manageUserRouteType));
			case 23:
				return A2(
					$Punie$elm_reader$Reader$map,
					$elm$html$Html$map($author$project$Msgs$MsgPageManageUsers),
					A4($author$project$Page$ManageUsers$view, appCfg, model.h5, topBarFnPartial, 0));
			case 24:
				var deleteUserRouteType = _v0.a;
				return A2(
					$Punie$elm_reader$Reader$map,
					$elm$html$Html$map($author$project$Msgs$MsgPageMutateUsers),
					A3($author$project$Page$MutateUsers$view, model.h7, topBarFnPartial, deleteUserRouteType));
			case 25:
				var updateUserRouteType = _v0.a;
				return A2(
					$Punie$elm_reader$Reader$map,
					$elm$html$Html$map($author$project$Msgs$MsgPageMutateUsers),
					A3($author$project$Page$MutateUsers$view, model.h7, topBarFnPartial, updateUserRouteType));
			case 26:
				return A2(
					$Punie$elm_reader$Reader$map,
					$elm$html$Html$map($author$project$Msgs$MsgPageManageSchools),
					A2($author$project$Page$ManageSchools$view, model.h4, topBarFnPartial));
			case 27:
				return A2(
					$Punie$elm_reader$Reader$map,
					$elm$html$Html$map($author$project$Msgs$MsgPageAdaptiveAnalytics),
					A2($author$project$Page$AdaptiveAnalytics$view, model.h$, topBarFnPartial));
			case 28:
				return $Punie$elm_reader$Reader$reader(
					A2(
						$elm$html$Html$map,
						$author$project$Msgs$MsgPageSample,
						$author$project$Page$Sample$view(model.ia)));
			case 3:
				var qGroupId = _v0.a;
				return $Punie$elm_reader$Reader$reader(
					A4($author$project$View$View$viewQuestionGroupInstructions, model, appCfg, qGroupId, userType));
			case 1:
				var qNbr = _v0.a;
				return (model.ln === 1) ? $Punie$elm_reader$Reader$reader(
					function () {
						var isLocked = function () {
							var _v1 = A2($elm$core$Array$get, qNbr - 1, model.kR);
							if (_v1.$ === 1) {
								return false;
							} else {
								var qGroupItem = _v1.a;
								return qGroupItem.mj;
							}
						}();
						return isLocked ? $author$project$View$View$viewExamStats(model) : A2($author$project$View$View$viewQuestion, model, qNbr);
					}()) : A4($author$project$View$View$viewStudentHome, model, appCfg, true, assignee);
			case 4:
				return $Punie$elm_reader$Reader$reader(
					$author$project$View$View$viewExamStats(model));
			case 5:
				return $Punie$elm_reader$Reader$reader(
					$author$project$View$View$viewConfirmSection(model));
			case 31:
				var studentSub = _v0.a;
				var tsFinish = _v0.c;
				var isShowBackButton = !model.ln;
				var assigneeMaybe = A2($author$project$View$View$getAssigneeMaybe, model, studentSub);
				var fNameForResultDisplay = function () {
					if (!userType.$) {
						return $elm$core$Maybe$Nothing;
					} else {
						return A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.dc;
								},
								function ($) {
									return $.lt;
								}),
							assigneeMaybe);
					}
				}();
				var historyItems = function () {
					var hstry = A2(
						$krisajenkins$remotedata$RemoteData$withDefault,
						_List_Nil,
						A2(
							$krisajenkins$remotedata$RemoteData$map,
							function ($) {
								return $.eZ;
							},
							model.fQ));
					if (!userType.$) {
						return hstry;
					} else {
						return _Utils_eq(studentSub, $author$project$Type$User$sampleStudentUserSub) ? hstry : A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							A2(
								$elm$core$Maybe$map,
								function ($) {
									return $.lU;
								},
								assigneeMaybe));
					}
				}();
				var historyItemMaybe = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (el) {
							return _Utils_eq(
								$author$project$Type$ExamResult$extractTsFinish(el),
								tsFinish);
						},
						historyItems));
				return $Punie$elm_reader$Reader$reader(
					function () {
						if (!historyItemMaybe.$) {
							var historyItem = historyItemMaybe.a;
							return A6($author$project$View$View$viewSessionResults, model, appCfg, historyItem, isShowBackButton, fNameForResultDisplay, assigneeMaybe);
						} else {
							if (!userType.$) {
								return A3(
									$author$project$View$ViewHelper$viewFatalErrorPage,
									model,
									'Student: Hmm - either you did not answer any questions, or the results requested are no longer available.',
									'The requested exam result set is no longer available for the user.  User details are:' + ($author$project$Type$User$extractPersonEDetails(me).lW + (', ' + $author$project$Type$User$extractPersonEDetails(me).li)));
							} else {
								var errorPage = A3(
									$author$project$View$ViewHelper$viewFatalErrorPage,
									model,
									'Teacher: Hmm - the results for the requested exam are no longer available.',
									'Could not obtain the requested exam result set or single item for the user.  It seems the requested element of studentData.history could not be obtained.  User details are:' + ($author$project$Type$User$extractPersonEDetails(me).lW + (', ' + ($author$project$Type$User$extractPersonEDetails(me).li + (', and possible assignee is: ' + studentSub)))));
								return errorPage;
							}
						}
					}());
			case 32:
				return A2(
					$Punie$elm_reader$Reader$map,
					$elm$html$Html$map($author$project$Msgs$MsgPageShowWorksheet),
					A2($author$project$Page$ShowWorksheet$view, model.ib, topBarFnPartial));
			case 33:
				return A2(
					$Punie$elm_reader$Reader$map,
					$elm$html$Html$map($author$project$Msgs$MsgPageShowWorksheets),
					A2($author$project$Page$ShowWorksheets$view, model.ic, topBarFnPartial));
			case 34:
				return $Punie$elm_reader$Reader$reader(
					$author$project$View$ViewHelper$viewFatalError(model));
			default:
				return $Punie$elm_reader$Reader$reader(
					A3(
						$author$project$View$ViewHelper$viewFatalErrorPage,
						model,
						'We apologise ... We can\'t find the page you\'re trying to load.',
						'Could not find route for : ' + $author$project$Routing$routeToString(model.n_)));
		}
	});
var $author$project$Msgs$SetIsShowModalResultTallyByQId = function (a) {
	return {$: 44, a: a};
};
var $author$project$Msgs$SetQueryForByQuestion = function (a) {
	return {$: 41, a: a};
};
var $NoRedInk$elm_sortable_table$Table$Config = $elm$core$Basics$identity;
var $NoRedInk$elm_sortable_table$Table$customConfig = function (_v0) {
	var toId = _v0.jb;
	var toMsg = _v0.jc;
	var columns = _v0.kC;
	var customizations = _v0.gM;
	return {
		kC: A2(
			$elm$core$List$map,
			function (_v1) {
				var cData = _v1;
				return cData;
			},
			columns),
		gM: customizations,
		jb: toId,
		jc: toMsg
	};
};
var $NoRedInk$elm_sortable_table$Table$simpleRowAttrs = function (_v0) {
	return _List_Nil;
};
var $NoRedInk$elm_sortable_table$Table$HtmlDetails = F2(
	function (attributes, children) {
		return {em: attributes, eA: children};
	});
var $NoRedInk$elm_sortable_table$Table$darkGrey = function (symbol) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'color', '#555')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(' ' + symbol)
			]));
};
var $NoRedInk$elm_sortable_table$Table$lightGrey = function (symbol) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'color', '#ccc')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(' ' + symbol)
			]));
};
var $NoRedInk$elm_sortable_table$Table$simpleTheadHelp = function (_v0) {
	var name = _v0.a;
	var status = _v0.b;
	var onClick_ = _v0.c;
	var content = function () {
		switch (status.$) {
			case 0:
				return _List_fromArray(
					[
						$elm$html$Html$text(name)
					]);
			case 1:
				var selected = status.a;
				return _List_fromArray(
					[
						$elm$html$Html$text(name),
						selected ? $NoRedInk$elm_sortable_table$Table$darkGrey('↓') : $NoRedInk$elm_sortable_table$Table$lightGrey('↓')
					]);
			default:
				if (status.a.$ === 1) {
					var _v2 = status.a;
					return _List_fromArray(
						[
							$elm$html$Html$text(name),
							$NoRedInk$elm_sortable_table$Table$lightGrey('↕')
						]);
				} else {
					var isReversed = status.a.a;
					return _List_fromArray(
						[
							$elm$html$Html$text(name),
							$NoRedInk$elm_sortable_table$Table$darkGrey(
							isReversed ? '↑' : '↓')
						]);
				}
		}
	}();
	return A2(
		$elm$html$Html$th,
		_List_fromArray(
			[onClick_]),
		content);
};
var $NoRedInk$elm_sortable_table$Table$simpleThead = function (headers) {
	return A2(
		$NoRedInk$elm_sortable_table$Table$HtmlDetails,
		_List_Nil,
		A2($elm$core$List$map, $NoRedInk$elm_sortable_table$Table$simpleTheadHelp, headers));
};
var $NoRedInk$elm_sortable_table$Table$defaultCustomizations = {ey: $elm$core$Maybe$Nothing, iH: $NoRedInk$elm_sortable_table$Table$simpleRowAttrs, i5: _List_Nil, fV: _List_Nil, fW: $elm$core$Maybe$Nothing, fX: $NoRedInk$elm_sortable_table$Table$simpleThead};
var $NoRedInk$elm_sortable_table$Table$Column = $elm$core$Basics$identity;
var $NoRedInk$elm_sortable_table$Table$ColumnData = F3(
	function (name, viewData, sorter) {
		return {mO: name, be: sorter, bk: viewData};
	});
var $NoRedInk$elm_sortable_table$Table$textDetails = function (str) {
	return A2(
		$NoRedInk$elm_sortable_table$Table$HtmlDetails,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $NoRedInk$elm_sortable_table$Table$customColumn = function (_v0) {
	var name = _v0.mO;
	var viewData = _v0.bk;
	var sorter = _v0.be;
	return A3(
		$NoRedInk$elm_sortable_table$Table$ColumnData,
		name,
		A2($elm$core$Basics$composeL, $NoRedInk$elm_sortable_table$Table$textDetails, viewData),
		sorter);
};
var $NoRedInk$elm_sortable_table$Table$None = {$: 0};
var $NoRedInk$elm_sortable_table$Table$unsortable = $NoRedInk$elm_sortable_table$Table$None;
var $author$project$View$ViewAnalyticsByQuestion$examNameColumn = F2(
	function (name, toExamName) {
		return $NoRedInk$elm_sortable_table$Table$customColumn(
			{mO: name, be: $NoRedInk$elm_sortable_table$Table$unsortable, bk: toExamName});
	});
var $author$project$Msgs$AnalysisByQuestionChange = function (a) {
	return {$: 45, a: a};
};
var $author$project$Msgs$ToggleSelected = $elm$core$Basics$identity;
var $author$project$View$ViewAnalyticsByQuestion$toExamListRowAttrs = function (qGroupExtended) {
	var baseRetVal = _List_fromArray(
		[
			$elm$html$Html$Events$onClick(
			$author$project$Msgs$AnalysisByQuestionChange(qGroupExtended.ch.lW))
		]);
	var retVal = qGroupExtended.ob ? A2(
		$elm$core$List$cons,
		$elm$html$Html$Attributes$class('is-selected'),
		baseRetVal) : baseRetVal;
	return retVal;
};
var $NoRedInk$elm_sortable_table$Table$veryCustomColumn = $elm$core$Basics$identity;
var $author$project$View$ViewAnalyticsByQuestion$viewTyp = function (typ) {
	return A2(
		$NoRedInk$elm_sortable_table$Table$HtmlDetails,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$View$ViewHelper$makeExamTypBadge(typ)
			]));
};
var $author$project$View$ViewAnalyticsByQuestion$typColumn = F2(
	function (name, toTyp) {
		return $NoRedInk$elm_sortable_table$Table$veryCustomColumn(
			{
				mO: name,
				be: $NoRedInk$elm_sortable_table$Table$unsortable,
				bk: function (el) {
					return $author$project$View$ViewAnalyticsByQuestion$viewTyp(
						toTyp(el));
				}
			});
	});
var $author$project$View$ViewAnalyticsByQuestion$configExamList = $NoRedInk$elm_sortable_table$Table$customConfig(
	{
		kC: _List_fromArray(
			[
				A2(
				$author$project$View$ViewAnalyticsByQuestion$typColumn,
				'Type',
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.ch;
					},
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.iv;
						},
						function ($) {
							return $.pt;
						}))),
				A2(
				$author$project$View$ViewAnalyticsByQuestion$examNameColumn,
				'Name',
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.ch;
					},
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.iv;
						},
						function ($) {
							return $.mO;
						})))
			]),
		gM: _Utils_update(
			$NoRedInk$elm_sortable_table$Table$defaultCustomizations,
			{
				iH: $author$project$View$ViewAnalyticsByQuestion$toExamListRowAttrs,
				i5: _List_fromArray(
					[
						$elm$html$Html$Attributes$class('table is-hoverable is-striped is-fullwidth')
					])
			}),
		jb: A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.ch;
			},
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.lW;
				},
				$elm$core$String$fromInt)),
		jc: function (el) {
			return $author$project$Msgs$DoNothing;
		}
	});
var $author$project$Msgs$SetTableStateForByQuestion = function (a) {
	return {$: 43, a: a};
};
var $author$project$View$ViewAnalyticsByQuestion$viewActionQuestionButton = function (qId) {
	return A2(
		$NoRedInk$elm_sortable_table$Table$HtmlDetails,
		_List_Nil,
		_List_fromArray(
			[
				A3(
				$author$project$Routing$link,
				$author$project$Msgs$LinkClicked(
					$author$project$Routing$withHash($author$project$Routing$smPaths.dS) + ('/' + ($elm$core$String$fromInt(qId) + '/-/-/-'))),
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('button is-primary is-small')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('View Q&A')
					]))
			]));
};
var $author$project$View$ViewAnalyticsByQuestion$actionColumn = F2(
	function (name, toQuestionId) {
		return $NoRedInk$elm_sortable_table$Table$veryCustomColumn(
			{
				mO: name,
				be: $NoRedInk$elm_sortable_table$Table$unsortable,
				bk: A2($elm$core$Basics$composeR, toQuestionId, $author$project$View$ViewAnalyticsByQuestion$viewActionQuestionButton)
			});
	});
var $NoRedInk$elm_sortable_table$Table$DecOrInc = function (a) {
	return {$: 4, a: a};
};
var $NoRedInk$elm_sortable_table$Table$decreasingOrIncreasingBy = function (toComparable) {
	return $NoRedInk$elm_sortable_table$Table$DecOrInc(
		$elm$core$List$sortBy(toComparable));
};
var $author$project$Helper$AnalysisByQuestion$nbrAttemptsForQuestion = function (crctIncrctTuple) {
	var len = $elm$core$List$length;
	var _v0 = crctIncrctTuple;
	var crct = _v0.a;
	var incrct = _v0.b;
	return len(crct) + len(incrct);
};
var $author$project$View$ViewAnalyticsByQuestion$viewAttempts = function (resultTallyByQId) {
	var nbrAttempts = $author$project$Helper$AnalysisByQuestion$nbrAttemptsForQuestion(resultTallyByQId.gF);
	var attemptTxt = (nbrAttempts > 1) ? 'Attempts' : 'Attempt';
	return A2(
		$NoRedInk$elm_sortable_table$Table$HtmlDetails,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(
				$elm$core$String$fromInt(nbrAttempts) + ' '),
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('button is-text is-small'),
						$elm$html$Html$Events$onClick(
						$author$project$Msgs$SetIsShowModalResultTallyByQId(
							$elm$core$Maybe$Just(resultTallyByQId)))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('View ' + attemptTxt)
					]))
			]));
};
var $author$project$View$ViewAnalyticsByQuestion$attemptsColumn = F2(
	function (name, toResultTallyByQId) {
		return $NoRedInk$elm_sortable_table$Table$veryCustomColumn(
			{
				mO: name,
				be: $NoRedInk$elm_sortable_table$Table$decreasingOrIncreasingBy(
					A2(
						$elm$core$Basics$composeR,
						toResultTallyByQId,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.gF;
							},
							$author$project$Helper$AnalysisByQuestion$nbrAttemptsForQuestion))),
				bk: A2($elm$core$Basics$composeR, toResultTallyByQId, $author$project$View$ViewAnalyticsByQuestion$viewAttempts)
			});
	});
var $NoRedInk$elm_sortable_table$Table$IncOrDec = function (a) {
	return {$: 3, a: a};
};
var $NoRedInk$elm_sortable_table$Table$increasingOrDecreasingBy = function (toComparable) {
	return $NoRedInk$elm_sortable_table$Table$IncOrDec(
		$elm$core$List$sortBy(toComparable));
};
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $author$project$View$ViewAnalyticsByQuestion$categoryColumn = F2(
	function (name, _v0) {
		var toPc = _v0.a;
		var toStrand = _v0.b;
		var toCategory = _v0.c;
		var makeSortString = function (el) {
			var sortPcString = A3(
				$elm$core$String$padLeft,
				3,
				'0',
				$elm$core$String$fromInt(
					toPc(el)));
			var categoryString = toCategory(el);
			return _Utils_ap(categoryString, sortPcString);
		};
		return $NoRedInk$elm_sortable_table$Table$customColumn(
			{
				mO: name,
				be: $NoRedInk$elm_sortable_table$Table$increasingOrDecreasingBy(makeSortString),
				bk: toCategory
			});
	});
var $author$project$Helper$Category$getParent = F2(
	function (categories, categoryIdMaybe) {
		var getCategoryMaybe = function (idMaybe) {
			return $elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (el) {
						return _Utils_eq(
							$elm$core$Maybe$Just(el.lW),
							idMaybe);
					},
					categories));
		};
		return A2(
			$elm$core$Maybe$andThen,
			getCategoryMaybe,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.nq;
					},
					$elm$core$Maybe$Just),
				getCategoryMaybe(categoryIdMaybe)));
	});
var $NoRedInk$elm_sortable_table$Table$intColumn = F2(
	function (name, toInt) {
		return {
			mO: name,
			be: $NoRedInk$elm_sortable_table$Table$increasingOrDecreasingBy(toInt),
			bk: A2(
				$elm$core$Basics$composeL,
				A2($elm$core$Basics$composeL, $NoRedInk$elm_sortable_table$Table$textDetails, $elm$core$String$fromInt),
				toInt)
		};
	});
var $author$project$View$ViewAnalyticsByQuestion$makeBandColumn = F2(
	function (name, toBandInt) {
		return $NoRedInk$elm_sortable_table$Table$veryCustomColumn(
			{
				mO: name,
				be: $NoRedInk$elm_sortable_table$Table$decreasingOrIncreasingBy(toBandInt),
				bk: A2(
					$elm$core$Basics$composeR,
					toBandInt,
					function (band) {
						return A2(
							$NoRedInk$elm_sortable_table$Table$HtmlDetails,
							_List_Nil,
							_List_fromArray(
								[
									A2($author$project$View$Bands$makeBandTag, $elm$core$Maybe$Nothing, band)
								]));
					})
			});
	});
var $author$project$View$ViewAnalyticsByQuestion$pcCorrectColumn = F2(
	function (name, _v0) {
		var toPc = _v0.a;
		var toStrand = _v0.b;
		var toCategory = _v0.c;
		var makeSortString = function (el) {
			var strandString = toStrand(el);
			var sortPcString = A3(
				$elm$core$String$padLeft,
				3,
				'0',
				$elm$core$String$fromInt(
					toPc(el)));
			var categoryString = toCategory(el);
			return _Utils_ap(
				sortPcString,
				_Utils_ap(strandString, categoryString));
		};
		return $NoRedInk$elm_sortable_table$Table$customColumn(
			{
				mO: name,
				be: $NoRedInk$elm_sortable_table$Table$increasingOrDecreasingBy(makeSortString),
				bk: A2(
					$elm$core$Basics$composeR,
					toPc,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$String$fromInt,
						function (el) {
							return el + ' %';
						}))
			});
	});
var $author$project$Helper$AnalysisByQuestion$pcCorrectForQuestion = function (crctIncrctTuple) {
	var tot = $author$project$Helper$AnalysisByQuestion$nbrAttemptsForQuestion(crctIncrctTuple);
	return $elm$core$Basics$round(
		100 * ($elm$core$List$length(crctIncrctTuple.a) / tot));
};
var $author$project$View$ViewAnalyticsByQuestion$strandColumn = F2(
	function (name, _v0) {
		var toPc = _v0.a;
		var toStrand = _v0.b;
		var toCategory = _v0.c;
		var makeSortString = function (el) {
			var strandString = toStrand(el);
			var sortPcString = A3(
				$elm$core$String$padLeft,
				3,
				'0',
				$elm$core$String$fromInt(
					toPc(el)));
			var categoryString = toCategory(el);
			return _Utils_ap(
				strandString,
				_Utils_ap(sortPcString, categoryString));
		};
		return $NoRedInk$elm_sortable_table$Table$customColumn(
			{
				mO: name,
				be: $NoRedInk$elm_sortable_table$Table$increasingOrDecreasingBy(makeSortString),
				bk: toStrand
			});
	});
var $author$project$View$ViewAnalyticsByQuestion$toQuestionListRowAttrs = function (resultTallyByQId) {
	var pc = 1.0 - ($author$project$Helper$AnalysisByQuestion$pcCorrectForQuestion(resultTallyByQId.gF) / 100);
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$Attributes$style,
			'background-color',
			'rgba(228, 139, 0, ' + ($elm$core$String$fromFloat(pc) + ')'))
		]);
};
var $author$project$View$ViewAnalyticsByQuestion$configQuestionList = F3(
	function (categories, qsMetas, yearLvl) {
		var toPc = function (el) {
			return $author$project$Helper$AnalysisByQuestion$pcCorrectForQuestion(el.gF);
		};
		var catForQstn = function (qId) {
			return A3($author$project$Helper$Common$categoryForQuestion, categories, qsMetas, qId);
		};
		var toCategory = function (el) {
			return A2(
				$elm$core$Maybe$withDefault,
				'Unknown category',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.mO;
					},
					catForQstn(el.dS)));
		};
		var toStrand = function (el) {
			return A2(
				$elm$core$Maybe$withDefault,
				'Unknown strand',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.mO;
					},
					A2(
						$author$project$Helper$Category$getParent,
						categories,
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.lW;
							},
							catForQstn(el.dS)))));
		};
		return $NoRedInk$elm_sortable_table$Table$customConfig(
			{
				kC: _List_fromArray(
					[
						A2(
						$NoRedInk$elm_sortable_table$Table$intColumn,
						'Question Id',
						function ($) {
							return $.dS;
						}),
						A2(
						$author$project$View$ViewAnalyticsByQuestion$strandColumn,
						'Strand',
						_Utils_Tuple3(toPc, toStrand, toCategory)),
						A2(
						$author$project$View$ViewAnalyticsByQuestion$categoryColumn,
						'Category',
						_Utils_Tuple3(toPc, toStrand, toCategory)),
						A2(
						$author$project$View$ViewAnalyticsByQuestion$pcCorrectColumn,
						'% Correct',
						_Utils_Tuple3(toPc, toStrand, toCategory)),
						A2($author$project$View$ViewAnalyticsByQuestion$attemptsColumn, 'Attempts', $elm$core$Basics$identity),
						A2(
						$author$project$View$ViewAnalyticsByQuestion$makeBandColumn,
						'Band',
						function (el) {
							return A3(
								$author$project$Helper$Band$getBandForQId,
								qsMetas,
								$elm$core$Maybe$Just(
									$author$project$Type$Cohort$toInt(yearLvl)),
								el.dS);
						}),
						A2(
						$author$project$View$ViewAnalyticsByQuestion$actionColumn,
						'Action',
						function ($) {
							return $.dS;
						})
					]),
				gM: _Utils_update(
					$NoRedInk$elm_sortable_table$Table$defaultCustomizations,
					{
						iH: $author$project$View$ViewAnalyticsByQuestion$toQuestionListRowAttrs,
						i5: _List_fromArray(
							[
								$elm$html$Html$Attributes$class('table is-hoverable is-fullwidth')
							])
					}),
				jb: A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.dS;
					},
					$elm$core$String$fromInt),
				jc: $author$project$Msgs$SetTableStateForByQuestion
			});
	});
var $author$project$Helper$AnalysisByQuestion$extractBandsFromQsMetas = F2(
	function (yearLvl, qsMeta) {
		var bnds = A2(
			$elm$core$List$map,
			function (el) {
				return A2(
					$author$project$Helper$Band$getBandForYearLvl,
					el,
					$author$project$Type$Cohort$toInt(yearLvl));
			},
			qsMeta);
		return $author$project$Helper$Util$unique(bnds);
	});
var $author$project$Helper$AnalysisByQuestion$extractQGroupIdsListUsedByAssignees = F2(
	function (qGroups, assignees) {
		var qGroupIds = $elm$core$Set$empty;
		var extractQGroupIds = function (serrs) {
			return A2(
				$elm$core$List$map,
				function ($) {
					return $.nF;
				},
				A2($elm$core$List$filterMap, $author$project$Type$ExamResult$filterTestletGroupResultRecords, serrs));
		};
		return $elm$core$Set$fromList(
			A2(
				$elm$core$List$concatMap,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.lU;
					},
					extractQGroupIds),
				assignees));
	});
var $elm$core$Set$filter = F2(
	function (isGood, _v0) {
		var dict = _v0;
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (key, _v1) {
					return isGood(key);
				}),
			dict);
	});
var $author$project$Helper$Common$filterByGrpNameContainsString = F2(
	function (str, assignees) {
		return A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeL,
				A2(
					$elm$core$Basics$composeL,
					A2(
						$elm$core$Basics$composeL,
						A2(
							$elm$core$Basics$composeL,
							$elm$core$String$contains(
								$elm$core$String$toLower(str)),
							$elm$core$String$toLower),
						$elm$core$Maybe$withDefault('')),
					function ($) {
						return $.eV;
					}),
				function ($) {
					return $.dc;
				}),
			assignees);
	});
var $author$project$Helper$Common$filterByUserType = F2(
	function (userType, assignees) {
		return A2(
			$elm$core$List$filter,
			function (el) {
				return _Utils_eq(el.dc.pt, userType);
			},
			assignees);
	});
var $author$project$Helper$AnalysisByQuestion$filterQGroupsByQGroupIdList = F2(
	function (qGroups, qGroupIds) {
		return A2(
			$elm$core$List$filter,
			function (el) {
				return A2($elm$core$Set$member, el.lW, qGroupIds);
			},
			qGroups);
	});
var $author$project$Helper$AnalysisByQuestion$filterQsMetaByUsedInQGroupIds = F3(
	function (qsMeta, assignees, qGroupIds) {
		var qIds = $author$project$Helper$Util$unique(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.dS;
				},
				A2(
					$elm$core$List$filter,
					function (el) {
						return !(!el.dW);
					},
					A2(
						$elm$core$List$concatMap,
						function ($) {
							return $.ci;
						},
						A2(
							$elm$core$List$filter,
							function (el) {
								return A2($elm$core$Set$member, el.nF, qGroupIds);
							},
							A2(
								$elm$core$List$filterMap,
								$author$project$Type$ExamResult$filterTestletGroupResultRecords,
								A2(
									$elm$core$List$concatMap,
									function ($) {
										return $.lU;
									},
									assignees)))))));
		return A2(
			$elm$core$List$filter,
			function (el) {
				return A2($elm$core$List$member, el.lW, qIds);
			},
			qsMeta);
	});
var $elm$html$Html$footer = _VirtualDom_node('footer');
var $elm$html$Html$header = _VirtualDom_node('header');
var $author$project$Helper$AnalysisByQuestion$reduceToYearList = function (assignees) {
	var levels = A3(
		$elm$core$List$foldl,
		F2(
			function (el, acc) {
				if (!el.$) {
					var lvl = el.a;
					return A2($elm$core$List$member, lvl, acc) ? acc : A2($elm$core$List$cons, lvl, acc);
				} else {
					return acc;
				}
			}),
		_List_Nil,
		A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.dc;
				},
				function ($) {
					return $.hB;
				}),
			A2($author$project$Helper$Common$filterByUserType, $author$project$Type$User$Student, assignees)));
	return levels;
};
var $author$project$Helper$AnalysisByQuestion$getAllQuestionResultsForAssigneeFlattened = function (assignee) {
	return A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.ci;
		},
		A2($elm$core$List$filterMap, $author$project$Type$ExamResult$filterTestletGroupResultRecords, assignee.lU));
};
var $author$project$Helper$AnalysisByQuestion$answerResultForAssigneeForQId = F2(
	function (qId, assignee) {
		return $elm$core$List$head(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.dW;
				},
				A2(
					$elm$core$List$filter,
					function (el) {
						return _Utils_eq(el.dS, qId);
					},
					$author$project$Helper$AnalysisByQuestion$getAllQuestionResultsForAssigneeFlattened(assignee))));
	});
var $author$project$Helper$AnalysisByQuestion$tabulateAnswerResultsByQId = F2(
	function (assignees, qsMeta) {
		var partitionAssigneesOnAnswerToQId = F2(
			function (assignees1, qId) {
				var qIdsForAssignee = function (assignee) {
					return A2(
						$elm$core$List$map,
						function ($) {
							return $.dS;
						},
						$author$project$Helper$AnalysisByQuestion$getAllQuestionResultsForAssigneeFlattened(assignee));
				};
				var ansRes = $author$project$Helper$AnalysisByQuestion$answerResultForAssigneeForQId(qId);
				var retVal = A2(
					$elm$core$List$partition,
					function (el) {
						return _Utils_eq(
							ansRes(el),
							$elm$core$Maybe$Just(2));
					},
					A2(
						$elm$core$List$filter,
						function (el) {
							return A2(
								$elm$core$List$member,
								qId,
								qIdsForAssignee(el));
						},
						assignees1));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$map,
						function ($) {
							return $.dc;
						},
						retVal.a),
					A2(
						$elm$core$List$map,
						function ($) {
							return $.dc;
						},
						retVal.b));
			});
		var filterHistoryByOnlyAnsweredQuestions = function (hstry) {
			var filterForOnlyAnsweredQuestions = function (serrWithAllQs) {
				if (!serrWithAllQs.$) {
					var rec = serrWithAllQs.a;
					return $author$project$Type$ExamResult$TestletGroupRunResult(
						_Utils_update(
							rec,
							{
								ci: A2(
									$elm$core$List$filter,
									function (el) {
										return !(!el.dW);
									},
									rec.ci)
							}));
				} else {
					var rec = serrWithAllQs.a;
					return $author$project$Type$ExamResult$ActivityRunResult(
						_Utils_update(
							rec,
							{
								ci: A2(
									$elm$core$List$filter,
									function (el) {
										return !_Utils_eq(el.dW, $elm$core$Maybe$Nothing);
									},
									rec.ci)
							}));
				}
			};
			return A2($elm$core$List$map, filterForOnlyAnsweredQuestions, hstry);
		};
		var filterByOnlyAnsweredQuestionsForAssignees = function (assignees1) {
			return A2(
				$elm$core$List$map,
				function (el) {
					return _Utils_update(
						el,
						{
							lU: filterHistoryByOnlyAnsweredQuestions(el.lU)
						});
				},
				assignees1);
		};
		var filteredAssignees = filterByOnlyAnsweredQuestionsForAssignees(assignees);
		return A2(
			$elm$core$List$filter,
			function (el) {
				return !_Utils_eq(
					el.gF,
					_Utils_Tuple2(_List_Nil, _List_Nil));
			},
			A2(
				$elm$core$List$map,
				function (el) {
					return {
						gF: A2(partitionAssigneesOnAnswerToQId, filteredAssignees, el),
						dS: el
					};
				},
				A2(
					$elm$core$List$map,
					function ($) {
						return $.lW;
					},
					qsMeta)));
	});
var $elm$html$Html$caption = _VirtualDom_node('caption');
var $NoRedInk$elm_sortable_table$Table$applySorter = F3(
	function (isReversed, sorter, data) {
		switch (sorter.$) {
			case 0:
				return data;
			case 1:
				var sort_ = sorter.a;
				return sort_(data);
			case 2:
				var sort_ = sorter.a;
				return $elm$core$List$reverse(
					sort_(data));
			case 3:
				var sort_ = sorter.a;
				return isReversed ? $elm$core$List$reverse(
					sort_(data)) : sort_(data);
			default:
				var sort_ = sorter.a;
				return isReversed ? sort_(data) : $elm$core$List$reverse(
					sort_(data));
		}
	});
var $NoRedInk$elm_sortable_table$Table$findSorter = F2(
	function (selectedColumn, columnData) {
		findSorter:
		while (true) {
			if (!columnData.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var name = columnData.a.mO;
				var sorter = columnData.a.be;
				var remainingColumnData = columnData.b;
				if (_Utils_eq(name, selectedColumn)) {
					return $elm$core$Maybe$Just(sorter);
				} else {
					var $temp$selectedColumn = selectedColumn,
						$temp$columnData = remainingColumnData;
					selectedColumn = $temp$selectedColumn;
					columnData = $temp$columnData;
					continue findSorter;
				}
			}
		}
	});
var $NoRedInk$elm_sortable_table$Table$sort = F3(
	function (_v0, columnData, data) {
		var selectedColumn = _v0.a;
		var isReversed = _v0.b;
		var _v1 = A2($NoRedInk$elm_sortable_table$Table$findSorter, selectedColumn, columnData);
		if (_v1.$ === 1) {
			return data;
		} else {
			var sorter = _v1.a;
			return A3($NoRedInk$elm_sortable_table$Table$applySorter, isReversed, sorter, data);
		}
	});
var $elm$html$Html$tfoot = _VirtualDom_node('tfoot');
var $NoRedInk$elm_sortable_table$Table$Reversible = function (a) {
	return {$: 2, a: a};
};
var $NoRedInk$elm_sortable_table$Table$Sortable = function (a) {
	return {$: 1, a: a};
};
var $NoRedInk$elm_sortable_table$Table$Unsortable = {$: 0};
var $NoRedInk$elm_sortable_table$Table$onClick = F3(
	function (name, isReversed, toMsg) {
		return A2(
			$elm$html$Html$Events$on,
			'click',
			A2(
				$elm$json$Json$Decode$map,
				toMsg,
				A3(
					$elm$json$Json$Decode$map2,
					$NoRedInk$elm_sortable_table$Table$State,
					$elm$json$Json$Decode$succeed(name),
					$elm$json$Json$Decode$succeed(isReversed))));
	});
var $NoRedInk$elm_sortable_table$Table$toHeaderInfo = F3(
	function (_v0, toMsg, _v1) {
		var sortName = _v0.a;
		var isReversed = _v0.b;
		var name = _v1.mO;
		var sorter = _v1.be;
		switch (sorter.$) {
			case 0:
				return _Utils_Tuple3(
					name,
					$NoRedInk$elm_sortable_table$Table$Unsortable,
					A3($NoRedInk$elm_sortable_table$Table$onClick, sortName, isReversed, toMsg));
			case 1:
				return _Utils_Tuple3(
					name,
					$NoRedInk$elm_sortable_table$Table$Sortable(
						_Utils_eq(name, sortName)),
					A3($NoRedInk$elm_sortable_table$Table$onClick, name, false, toMsg));
			case 2:
				return _Utils_Tuple3(
					name,
					$NoRedInk$elm_sortable_table$Table$Sortable(
						_Utils_eq(name, sortName)),
					A3($NoRedInk$elm_sortable_table$Table$onClick, name, false, toMsg));
			case 3:
				return _Utils_eq(name, sortName) ? _Utils_Tuple3(
					name,
					$NoRedInk$elm_sortable_table$Table$Reversible(
						$elm$core$Maybe$Just(isReversed)),
					A3($NoRedInk$elm_sortable_table$Table$onClick, name, !isReversed, toMsg)) : _Utils_Tuple3(
					name,
					$NoRedInk$elm_sortable_table$Table$Reversible($elm$core$Maybe$Nothing),
					A3($NoRedInk$elm_sortable_table$Table$onClick, name, false, toMsg));
			default:
				return _Utils_eq(name, sortName) ? _Utils_Tuple3(
					name,
					$NoRedInk$elm_sortable_table$Table$Reversible(
						$elm$core$Maybe$Just(isReversed)),
					A3($NoRedInk$elm_sortable_table$Table$onClick, name, !isReversed, toMsg)) : _Utils_Tuple3(
					name,
					$NoRedInk$elm_sortable_table$Table$Reversible($elm$core$Maybe$Nothing),
					A3($NoRedInk$elm_sortable_table$Table$onClick, name, false, toMsg));
		}
	});
var $elm$virtual_dom$VirtualDom$lazy3 = _VirtualDom_lazy3;
var $elm$html$Html$Lazy$lazy3 = $elm$virtual_dom$VirtualDom$lazy3;
var $NoRedInk$elm_sortable_table$Table$viewCell = F2(
	function (data, _v0) {
		var viewData = _v0.bk;
		var details = viewData(data);
		return A2($elm$html$Html$td, details.em, details.eA);
	});
var $NoRedInk$elm_sortable_table$Table$viewRowHelp = F3(
	function (columns, toRowAttrs, data) {
		return A2(
			$elm$html$Html$tr,
			toRowAttrs(data),
			A2(
				$elm$core$List$map,
				$NoRedInk$elm_sortable_table$Table$viewCell(data),
				columns));
	});
var $NoRedInk$elm_sortable_table$Table$viewRow = F4(
	function (toId, columns, toRowAttrs, data) {
		return _Utils_Tuple2(
			toId(data),
			A4($elm$html$Html$Lazy$lazy3, $NoRedInk$elm_sortable_table$Table$viewRowHelp, columns, toRowAttrs, data));
	});
var $NoRedInk$elm_sortable_table$Table$view = F3(
	function (_v0, state, data) {
		var toId = _v0.jb;
		var toMsg = _v0.jc;
		var columns = _v0.kC;
		var customizations = _v0.gM;
		var theadDetails = customizations.fX(
			A2(
				$elm$core$List$map,
				A2($NoRedInk$elm_sortable_table$Table$toHeaderInfo, state, toMsg),
				columns));
		var thead = A2($elm$html$Html$thead, theadDetails.em, theadDetails.eA);
		var sortedData = A3($NoRedInk$elm_sortable_table$Table$sort, state, columns, data);
		var tbody = A3(
			$elm$html$Html$Keyed$node,
			'tbody',
			customizations.fV,
			A2(
				$elm$core$List$map,
				A3($NoRedInk$elm_sortable_table$Table$viewRow, toId, columns, customizations.iH),
				sortedData));
		var withFoot = function () {
			var _v2 = customizations.fW;
			if (_v2.$ === 1) {
				return A2($elm$core$List$cons, tbody, _List_Nil);
			} else {
				var attributes = _v2.a.em;
				var children = _v2.a.eA;
				return A2(
					$elm$core$List$cons,
					A2($elm$html$Html$tfoot, attributes, children),
					A2($elm$core$List$cons, tbody, _List_Nil));
			}
		}();
		return A2(
			$elm$html$Html$table,
			customizations.i5,
			function () {
				var _v1 = customizations.ey;
				if (_v1.$ === 1) {
					return A2($elm$core$List$cons, thead, withFoot);
				} else {
					var attributes = _v1.a.em;
					var children = _v1.a.eA;
					return A2(
						$elm$core$List$cons,
						A2($elm$html$Html$caption, attributes, children),
						A2($elm$core$List$cons, thead, withFoot));
				}
			}());
	});
var $author$project$View$ViewAnalyticsByQuestion$viewAnalyticsByQuestion = F6(
	function (appCfg, questionGroupVersion, analysisByQuestionState, assignees, filterString, qGroupIds) {
		var students = A2(
			$elm$core$List$map,
			$author$project$Helper$Common$reduceHistoryToFirstAttempts,
			A2(
				$author$project$Helper$Common$filterByGrpNameContainsString,
				filterString,
				A2($author$project$Helper$Common$filterByUserType, $author$project$Type$User$Student, assignees)));
		var yearLvl = A2(
			$elm$core$Maybe$withDefault,
			$author$project$Type$Cohort$placeholder,
			A2(
				$elm$core$Maybe$andThen,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Type$Cohort$toInt,
					A2($elm$core$Basics$composeR, $author$project$Type$AppCfg$lvlForNapStyleExams, $author$project$Type$Cohort$fromInt)),
				$elm$core$List$head(
					$author$project$Helper$AnalysisByQuestion$reduceToYearList(students))));
		var qGroupsPossible = function () {
			var qGroupIdsForYearLvl = A2(
				$elm$core$List$filter,
				function () {
					if (!questionGroupVersion) {
						return $author$project$Type$Testlet$isLegacyQGroupId;
					} else {
						return A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Type$Testlet$isLegacyQGroupId);
					}
				}(),
				A2(
					$elm$core$List$map,
					function ($) {
						return $.lW;
					},
					A2(
						$elm$core$List$filter,
						function (el) {
							return _Utils_eq(
								el.hB,
								$author$project$Type$Cohort$toInt(yearLvl));
						},
						appCfg.nH)));
			var qGroupsUsedByAssigneesForYearLvl = A2(
				$elm$core$Set$filter,
				function (el) {
					return A2($elm$core$List$member, el, qGroupIdsForYearLvl);
				},
				A2($author$project$Helper$AnalysisByQuestion$extractQGroupIdsListUsedByAssignees, appCfg.nH, students));
			return A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.nj;
				},
				A2($author$project$Helper$AnalysisByQuestion$filterQGroupsByQGroupIdList, appCfg.nH, qGroupsUsedByAssigneesForYearLvl));
		}();
		var qGroupsPossibleExtended = A2(
			$elm$core$List$map,
			function (el) {
				return {
					ch: el,
					ob: A2($elm$core$Set$member, el.lW, analysisByQuestionState.nG)
				};
			},
			qGroupsPossible);
		var qGroupsSelected = A2($author$project$Helper$AnalysisByQuestion$filterQGroupsByQGroupIdList, qGroupsPossible, qGroupIds);
		var possQsToShow = A3(
			$author$project$Helper$AnalysisByQuestion$filterQsMetaByUsedInQGroupIds,
			appCfg.iw,
			students,
			$elm$core$Set$fromList(
				A2(
					$elm$core$List$map,
					function ($) {
						return $.lW;
					},
					qGroupsSelected)));
		var bandsToHide = analysisByQuestionState.nJ;
		var resultTallyByQId = A2(
			$author$project$Helper$AnalysisByQuestion$tabulateAnswerResultsByQId,
			students,
			A2(
				$elm$core$List$filter,
				function (el) {
					return !A2(
						$elm$core$Set$member,
						A2(
							$author$project$Helper$Band$getBandForYearLvl,
							el,
							$author$project$Type$Cohort$toInt(yearLvl)),
						bandsToHide);
				},
				possQsToShow));
		var bandChoicesToShow = A2($author$project$Helper$AnalysisByQuestion$extractBandsFromQsMetas, yearLvl, possQsToShow);
		return _Utils_Tuple2(
			A2(
				$mdgriffith$elm_ui$Element$row,
				_List_Nil,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('Results by question'),
						A2($author$project$Page$PagesCommon$helpAnchorWithColor, 'smHaPageHeading', $author$project$Theme$Style$t.lN)
					])),
			A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('box')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h2,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('subtitle has-text-dark')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Click one or more exams to add/remove its questions to the analysis (only exams that have results for your cohort are listed.)')
									])),
								A3(
								$NoRedInk$elm_sortable_table$Table$view,
								$author$project$View$ViewAnalyticsByQuestion$configExamList,
								$NoRedInk$elm_sortable_table$Table$initialSort(''),
								qGroupsPossibleExtended)
							])),
						A2(
						$elm$html$Html$div,
						$elm$core$List$isEmpty(qGroupsSelected) ? _List_fromArray(
							[
								$elm$html$Html$Attributes$class('box'),
								A2($elm$html$Html$Attributes$style, 'display', 'none')
							]) : _List_fromArray(
							[
								$elm$html$Html$Attributes$class('box')
							]),
						_List_fromArray(
							[
								function () {
								if ($elm$core$List$isEmpty(bandChoicesToShow)) {
									return A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('notification is-warning')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('No questions were answered by your cohort for your selected exam(s)')
											]));
								} else {
									var questionsTable = $elm$core$List$isEmpty(resultTallyByQId) ? A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('notification')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('There are no questions to show for your current selections')
											])) : A3(
										$NoRedInk$elm_sortable_table$Table$view,
										A3($author$project$View$ViewAnalyticsByQuestion$configQuestionList, appCfg.kr, appCfg.iw, yearLvl),
										analysisByQuestionState.fT,
										resultTallyByQId);
									var bandsFilter = A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('notification')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$span,
												_List_fromArray(
													[
														A2($elm$html$Html$Attributes$style, 'vertical-align', 'middle')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Filter Questions by Band: ')
													])),
												A2(
												$elm$html$Html$span,
												_List_Nil,
												A2(
													$elm$core$List$map,
													function (el) {
														return A2(
															$elm$html$Html$span,
															_List_fromArray(
																[
																	A2($elm$html$Html$Attributes$style, 'margin-left', '5px'),
																	$elm$html$Html$Events$onClick(
																	$author$project$Msgs$SetQueryForByQuestion(el))
																]),
															_List_fromArray(
																[
																	A2(
																	$author$project$View$Bands$makeBandTag,
																	A2($elm$core$Set$member, el, bandsToHide) ? $elm$core$Maybe$Just('is-white') : $elm$core$Maybe$Nothing,
																	el)
																]));
													},
													bandChoicesToShow))
											]));
									return A2(
										$elm$html$Html$div,
										_List_Nil,
										A2(
											$elm$core$List$cons,
											A2(
												$elm$html$Html$h2,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('subtitle has-text-dark')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('The results below are tallied from each student\'s first attempt of the given question.')
													])),
											A2(
												$elm$core$List$cons,
												bandsFilter,
												_List_fromArray(
													[questionsTable]))));
								}
							}()
							])),
						function () {
						var _v0 = analysisByQuestionState.mK;
						if (_v0.$ === 1) {
							return A2($elm$html$Html$p, _List_Nil, _List_Nil);
						} else {
							var resultTallyByQId1 = _v0.a;
							var getStudentListsAsStringTuple = function (_v2) {
								var crct = _v2.a;
								var incrct = _v2.b;
								var toStringList = function (assigneeBases) {
									return $elm$core$List$isEmpty(assigneeBases) ? 'None' : A3(
										$elm$core$List$foldl,
										$elm$core$Basics$append,
										'',
										A2(
											$elm$core$List$intersperse,
											', ',
											A2(
												$elm$core$List$map,
												function (el) {
													return el.lt + (' ' + el.mq);
												},
												assigneeBases)));
								};
								return _Utils_Tuple2(
									toStringList(crct),
									toStringList(incrct));
							};
							var _v1 = getStudentListsAsStringTuple(resultTallyByQId1.gF);
							var crctListAsString = _v1.a;
							var incrctListAsString = _v1.b;
							return A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('modal is-active')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-background')
											]),
										_List_Nil),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-card')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$header,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('modal-card-head')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$p,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('modal-card-title')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(
																'Results for Question Id ' + $elm$core$String$fromInt(resultTallyByQId1.dS))
															])),
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('delete'),
																$elm$html$Html$Events$onClick(
																$author$project$Msgs$SetIsShowModalResultTallyByQId($elm$core$Maybe$Nothing))
															]),
														_List_Nil)
													])),
												A2(
												$elm$html$Html$section,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('modal-card-body')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$article,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('message is-success')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('message-header')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$p,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$elm$html$Html$text('Correctly answered by:')
																			]))
																	])),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('message-body')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(crctListAsString)
																	]))
															])),
														A2(
														$elm$html$Html$article,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('message is-danger')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('message-header')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$p,
																		_List_Nil,
																		_List_fromArray(
																			[
																				$elm$html$Html$text('Incorrectly answered by:')
																			]))
																	])),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('message-body')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(incrctListAsString)
																	]))
															]))
													])),
												A2(
												$elm$html$Html$footer,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('modal-card-foot')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('button is-info'),
																$elm$html$Html$Events$onClick(
																$author$project$Msgs$SetIsShowModalResultTallyByQId($elm$core$Maybe$Nothing))
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Close')
															]))
													]))
											]))
									]));
						}
					}()
					])));
	});
var $author$project$Msgs$Login = {$: 19};
var $author$project$Helper$Common$isValidTeacherAdminOrNonAdmin = function (model) {
	return A3($krisajenkins$remotedata$RemoteData$unwrap, false, $author$project$Type$User$isTeacherE, model.fc);
};
var $elm$html$Html$nav = _VirtualDom_node('nav');
var $author$project$View$ViewHelper$topBarForJSBelow = function (model) {
	var isShowHome = $author$project$Helper$Common$isValidTeacherAdminOrNonAdmin(model) && (!_Utils_eq(model.n_, $author$project$Routing$HomeRoute));
	var _v0 = function () {
		var _v1 = model.fc;
		if (_v1.$ === 3) {
			var me = _v1.a;
			return _Utils_Tuple2('Logout', $author$project$Msgs$Logout);
		} else {
			return _Utils_Tuple2('Login', $author$project$Msgs$Login);
		}
	}();
	var loginButtonText = _v0.a;
	var loginButtonAction = _v0.b;
	return A2(
		$elm$html$Html$nav,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('navbar is-dark'),
				A2($elm$html$Html$Attributes$style, 'background', '#3c3c3c')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('navbar-menu is-active')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('navbar-start')
							]),
						_List_fromArray(
							[
								isShowHome ? A3(
								$author$project$Routing$link,
								$author$project$Msgs$LinkClicked(
									$author$project$Routing$withHash($author$project$Routing$smPaths.pN)),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('navbar-item')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Home')
									])) : $elm$html$Html$text('')
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('navbar-end')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('navbar-item'),
										$elm$html$Html$Events$onClick(loginButtonAction)
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(loginButtonText)
									]))
							]))
					]))
			]));
};
var $author$project$View$View$viewAnalyticsItem = function (model) {
	return $author$project$View$ViewHelper$topBarForJSBelow(model);
};
var $author$project$Msgs$ShowChart = function (a) {
	return {$: 18, a: a};
};
var $elm$html$Html$figure = _VirtualDom_node('figure');
var $author$project$View$ViewAnalytics$viewAnalyticsList = F2(
	function (appCfg, model) {
		var content = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('box')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('columns')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('column is-one-quarter')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											$author$project$Msgs$ShowChart(0))
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('card')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('card-image')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$figure,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('image is-5by3')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$img,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$src('./assets/ByBand.png')
																		]),
																	_List_Nil)
																]))
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('card-content')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('content')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$p,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Results by band')
																		]))
																]))
														]))
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('column is-one-quarter')
								]),
							_List_fromArray(
								[
									A3(
									$author$project$Routing$link,
									$author$project$Msgs$LinkClicked(
										$author$project$Routing$createPath(1)),
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('card')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('card-image')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$figure,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('image is-5by3')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$img,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$src('./assets/ByQuestion.png')
																		]),
																	_List_Nil)
																]))
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('card-content')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('content')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$p,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Results by question')
																		]))
																]))
														]))
												]))
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('column is-one-quarter')
								]),
							_List_fromArray(
								[
									A3(
									$author$project$Routing$link,
									$author$project$Msgs$LinkClicked(
										$author$project$Routing$withHash($author$project$Routing$smPaths.nK)),
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('card')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('card-image')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$figure,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('image is-5by3')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$img,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$src('./assets/Download.png')
																		]),
																	_List_Nil)
																]))
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('card-content')
														]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('content')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$p,
																	_List_Nil,
																	_List_fromArray(
																		[
																			$elm$html$Html$text('Raw data download')
																		]))
																]))
														]))
												]))
										]))
								]))
						]))
				]));
		return _Utils_Tuple2(
			A2(
				$mdgriffith$elm_ui$Element$row,
				_List_Nil,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$text('Analytics'),
						A2($author$project$Page$PagesCommon$helpAnchorWithColor, 'smHaPageHeading', $author$project$Theme$Style$t.lN)
					])),
			content);
	});
var $author$project$Type$AppCfg$extractUniqueYearLvls = function (qGroups) {
	return $author$project$Helper$Util$unique(
		A2(
			$elm$core$List$map,
			function ($) {
				return $.hB;
			},
			qGroups));
};
var $author$project$Helper$Common$isCurrentUserDemoAccount = function (model) {
	return $author$project$Type$ReaderConfig$isUserRestricted(model);
};
var $author$project$Type$HiddenExamGroup$isHiddenExam = F4(
	function (hiddenExamGroupsMaybe, yearLvlMaybe, groupTag, questionGroupId) {
		return _Utils_eq(
			$elm$core$Maybe$Just(true),
			A2(
				$elm$core$Maybe$map,
				$elm$core$List$member(
					$elm$core$String$fromInt(questionGroupId)),
				A2(
					$elm$core$Maybe$map,
					function (fn) {
						return fn(groupTag);
					},
					A3($elm$core$Maybe$map2, $author$project$Type$HiddenExamGroup$getActivityIdsForCohortAndGroupTag, hiddenExamGroupsMaybe, yearLvlMaybe))));
	});
var $author$project$View$ViewConfiguration$viewConfigureHiddenExams = F2(
	function (appCfg, model) {
		var title = $author$project$Helper$Common$isCurrentUserDemoAccount(model) ? A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$spacing(10)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$row,
					_List_Nil,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$text('Configure the exams viewable to your students'),
							A2($author$project$Page$PagesCommon$helpAnchorWithColor, 'smHaPageHeading', $author$project$Theme$Style$t.lN)
						])),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$Font$italic]),
					$mdgriffith$elm_ui$Element$text('* Demo Account: Changes are disabled'))
				])) : A2(
			$mdgriffith$elm_ui$Element$row,
			_List_Nil,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$text('Configure the exams viewable to your students'),
					A2($author$project$Page$PagesCommon$helpAnchorWithColor, 'smHaPageHeading', $author$project$Theme$Style$t.lN)
				]));
		var makeYearLvlRow = function (yearLvl) {
			var qGroupsForYearLvl = A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.nj;
				},
				A2(
					$elm$core$List$filter,
					function (el) {
						return _Utils_eq(el.hB, yearLvl);
					},
					appCfg.nH));
			var isHidden = A3(
				$author$project$Type$HiddenExamGroup$isHiddenExam,
				A2($krisajenkins$remotedata$RemoteData$withDefault, $elm$core$Maybe$Nothing, model.hi),
				$author$project$Type$Cohort$fromInt(yearLvl),
				'nogrp');
			var buttonStyle = 'button is-small is-rounded';
			return A2(
				$elm$core$List$map,
				function (el) {
					return A2(
						$elm$html$Html$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$author$project$View$ViewHelper$makeExamTypBadge(el.iv.pt)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(el.iv.mO)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$View$ViewHelper$makeTimeString(el.ps))
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(el.iv.k8)
									])),
								A2(
								$elm$html$Html$td,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field has-addons')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('control')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														function () {
															var baseAttribs = _List_fromArray(
																[
																	$elm$html$Html$Attributes$class(
																	_Utils_ap(
																		buttonStyle,
																		_Utils_ap(
																			isHidden(el.lW) ? ' is-danger' : '',
																			_Utils_eq(model.hi, $krisajenkins$remotedata$RemoteData$Loading) ? ' is-loading' : ''))),
																	$elm$html$Html$Attributes$disabled(
																	$author$project$Helper$Common$isCurrentUserDemoAccount(model))
																]);
															return (!isHidden(el.lW)) ? A2(
																$elm$core$List$cons,
																$elm$html$Html$Events$onClick(
																	A4(
																		$author$project$Msgs$UpdateHiddenExamGroups,
																		0,
																		A2(
																			$elm$core$Maybe$withDefault,
																			$author$project$Type$Cohort$placeholder,
																			$author$project$Type$Cohort$fromInt(yearLvl)),
																		$elm$core$Maybe$Nothing,
																		$elm$core$String$fromInt(el.lW))),
																baseAttribs) : baseAttribs;
														}(),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$span,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('icon is-small')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$i,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('fa fa-eye-slash')
																			]),
																		_List_Nil)
																	])),
																A2(
																$elm$html$Html$span,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$text('Hide')
																	]))
															]))
													])),
												A2(
												$elm$html$Html$p,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('control')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$button,
														function () {
															var baseAttribs = _List_fromArray(
																[
																	$elm$html$Html$Attributes$class(
																	_Utils_ap(
																		buttonStyle,
																		_Utils_ap(
																			(!isHidden(el.lW)) ? ' is-success' : '',
																			_Utils_eq(model.hi, $krisajenkins$remotedata$RemoteData$Loading) ? ' is-loading' : ''))),
																	$elm$html$Html$Attributes$disabled(
																	$author$project$Helper$Common$isCurrentUserDemoAccount(model))
																]);
															return isHidden(el.lW) ? A2(
																$elm$core$List$cons,
																$elm$html$Html$Events$onClick(
																	A4(
																		$author$project$Msgs$UpdateHiddenExamGroups,
																		1,
																		A2(
																			$elm$core$Maybe$withDefault,
																			$author$project$Type$Cohort$placeholder,
																			$author$project$Type$Cohort$fromInt(yearLvl)),
																		$elm$core$Maybe$Nothing,
																		$elm$core$String$fromInt(el.lW))),
																baseAttribs) : baseAttribs;
														}(),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$span,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('icon is-small')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$i,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('fa fa-eye')
																			]),
																		_List_Nil)
																	])),
																A2(
																$elm$html$Html$span,
																_List_Nil,
																_List_fromArray(
																	[
																		$elm$html$Html$text('Show')
																	]))
															]))
													]))
											]))
									]))
							]));
				},
				qGroupsForYearLvl);
		};
		var makeYearLvlBox = function (yearLvl) {
			var yearLvlString = $elm$core$String$fromInt(yearLvl);
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('box')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h2,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('subtitle has-text-dark')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Year ' + (yearLvlString + (' configurations (ignore if you have no year ' + (yearLvlString + ' students)'))))
							])),
						A2(
						$elm$html$Html$table,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('table is-striped is-fullwidth is-hoverable')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$thead,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Type')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Name')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Time')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Description')
											])),
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Show to students?')
											]))
									])),
								A2(
								$elm$html$Html$tbody,
								_List_Nil,
								makeYearLvlRow(yearLvl))
							]))
					]));
		};
		var _v0 = model.hi;
		switch (_v0.$) {
			case 0:
				return _Utils_Tuple2(
					title,
					A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('notification is-warning')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('An error has occurred.  Please check your internet or refresh your browser as we were unable to connect to the server that handles exam show/hide configurations')
							])));
			case 2:
				var err = _v0.a;
				return _Utils_Tuple2(
					title,
					A3($author$project$View$ViewHelper$viewFatalErrorPage, model, 'There was a problem retrieving your hidden exams data from the server.', 'RemoteData.Failure'));
			default:
				return _Utils_Tuple2(
					title,
					A2(
						$elm$html$Html$div,
						_List_Nil,
						A2(
							$elm$core$List$map,
							makeYearLvlBox,
							$author$project$Type$AppCfg$extractUniqueYearLvls(appCfg.nH))));
		}
	});
var $author$project$View$ViewHelper$topBar = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$html$Html$text(''),
		A2(
			$elm$core$Maybe$map,
			$mdgriffith$elm_ui$Element$layout(_List_Nil),
			$author$project$View$ViewHelper$topBarInternal(model)));
};
var $author$project$View$View$viewLoadingApp = F2(
	function (model, loadingMsg) {
		return A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('hero'),
					$elm$html$Html$Attributes$class('is-fullheight'),
					A2(
					$elm$html$Html$Attributes$style,
					'background-color',
					$author$project$Theme$Style$toRgbString($author$project$Theme$Style$t.nl))
				]),
			_List_fromArray(
				[
					$author$project$View$ViewHelper$topBar(model),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('hero-body')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('container')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h1,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('title'),
											A2($elm$html$Html$Attributes$style, 'color', 'white')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('SmarterMaths Numeracy Online')
										])),
									A2(
									$elm$html$Html$h2,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('subtitle'),
											A2($elm$html$Html$Attributes$style, 'color', 'white')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(loadingMsg)
										]))
								]))
						]))
				]));
	});
var $author$project$View$View$HistoryResultFlavor = 1;
var $author$project$Models$MrWS = 2;
var $author$project$Msgs$OnToggleQuestionAccordion = {$: 2};
var $author$project$View$View$QuestionResultFlavor = 0;
var $krisajenkins$remotedata$RemoteData$mapBoth = F2(
	function (successFn, errorFn) {
		return A2(
			$elm$core$Basics$composeL,
			$krisajenkins$remotedata$RemoteData$mapError(errorFn),
			$krisajenkins$remotedata$RemoteData$map(successFn));
	});
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $author$project$Helper$HtmlUtil$convertHtmlStringToHtmlMsg = function (str) {
	var _v0 = $author$project$Helper$HtmlUtil$convertHtmlStringToResultListHtml(str);
	if (!_v0.$) {
		var listHtmlMsg = _v0.a;
		return A2($elm$html$Html$div, _List_Nil, listHtmlMsg);
	} else {
		var msg = _v0.a;
		return $elm$html$Html$text(msg);
	}
};
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$label = _VirtualDom_node('label');
var $author$project$UISnippet$Answer$viewAnswersHtml = F2(
	function (aSet, vAnswerMaybe) {
		var makeAnswersMCContent = F2(
			function (aSetMC, answerChoiceMaybe) {
				return A2(
					$elm$core$List$indexedMap,
					F2(
						function (idx, el) {
							var modifier = A2(
								$elm$core$Maybe$withDefault,
								$elm$html$Html$Attributes$disabled(true),
								A2(
									$elm$core$Maybe$map,
									function (el1) {
										return _Utils_eq(el1, idx) ? $elm$html$Html$Attributes$checked(true) : $elm$html$Html$Attributes$disabled(true);
									},
									answerChoiceMaybe));
							return A2(
								$elm$html$Html$label,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('radio')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$type_('radio'),
												$elm$html$Html$Attributes$name('answeroption'),
												A2($elm$html$Html$Attributes$style, 'margin-right', '1em'),
												modifier
											]),
										_List_Nil),
										el
									]));
						}),
					A2(
						$elm$core$List$map,
						function (el) {
							return A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										A2($elm$html$Html$Attributes$style, 'display', 'inline-block'),
										A2($elm$html$Html$Attributes$style, 'vertical-align', 'middle')
									]),
								$author$project$Helper$HtmlUtil$nodesToHtmls(el.bY));
						},
						aSetMC));
			});
		var extractChosenMCAnswer = $elm$core$Maybe$andThen(
			function (el) {
				if (!el.$) {
					var _v6 = el.a;
					var idx = _v6.a;
					return $elm$core$Maybe$Just(idx);
				} else {
					return $elm$core$Maybe$Nothing;
				}
			});
		var makeAnswers = F2(
			function (aSet_, vAnswerMaybe_) {
				switch (aSet_.$) {
					case 0:
						var aSetMCSA = aSet_.a;
						return A2(
							$elm$core$List$intersperse,
							A2($elm$html$Html$br, _List_Nil, _List_Nil),
							A2(
								makeAnswersMCContent,
								aSetMCSA,
								extractChosenMCAnswer(vAnswerMaybe_)));
					case 1:
						var mcRecs = aSet_.a;
						var selecteds = function () {
							if ((!vAnswerMaybe_.$) && (vAnswerMaybe_.a.$ === 1)) {
								var _v2 = vAnswerMaybe_.a.a;
								var idxs = _v2.a;
								return idxs;
							} else {
								return _List_Nil;
							}
						}();
						var wasSelectedHalfPairs = A2(
							$elm$core$List$map,
							function (el) {
								return A2($elm$core$List$member, el, selecteds);
							},
							A2(
								$elm$core$List$range,
								0,
								$elm$core$List$length(mcRecs) - 1));
						var makeCheckbox = F2(
							function (htmlEl, isChecked) {
								return A2(
									$elm$html$Html$span,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$type_('checkbox'),
													$elm$html$Html$Attributes$checked(isChecked),
													A2($elm$html$Html$Attributes$style, 'margin-right', '1em'),
													A2($elm$html$Html$Attributes$style, 'margin-bottom', '1em')
												]),
											_List_Nil),
											htmlEl
										]));
							});
						var answerSetHalfPairs = A2(
							$elm$core$List$map,
							A2(
								$elm$core$Basics$composeL,
								A2(
									$elm$core$Basics$composeL,
									$elm$html$Html$span(
										_List_fromArray(
											[
												A2($elm$html$Html$Attributes$style, 'display', 'inline-block')
											])),
									$author$project$Helper$HtmlUtil$nodesToHtmls),
								function ($) {
									return $.bY;
								}),
							mcRecs);
						return A2(
							$elm$core$List$intersperse,
							A2($elm$html$Html$br, _List_Nil, _List_Nil),
							A3($elm$core$List$map2, makeCheckbox, answerSetHalfPairs, wasSelectedHalfPairs));
					default:
						var vAnsBoxRecs = aSet_.a;
						var makeAnswerBox = F2(
							function (vAnsBoxRec, answerMaybe) {
								return A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$style, 'display', 'flex'),
											A2($elm$html$Html$Attributes$style, 'margin-left', '12px')
										]),
									_List_fromArray(
										[
											$author$project$Helper$HtmlUtil$convertHtmlStringToHtmlMsg(vAnsBoxRec.nx),
											$elm$html$Html$text(
											A2(
												$elm$core$Maybe$withDefault,
												'',
												A2(
													$elm$core$Maybe$map,
													function (el) {
														return ' ' + el;
													},
													answerMaybe))),
											$elm$html$Html$text(' '),
											$author$project$Helper$HtmlUtil$convertHtmlStringToHtmlMsg(vAnsBoxRec.oI)
										]));
							});
						var answers = function () {
							if ((!vAnswerMaybe_.$) && (vAnswerMaybe_.a.$ === 2)) {
								var _v4 = vAnswerMaybe_.a.a;
								var strs = _v4.a;
								return strs;
							} else {
								return $elm$core$Array$empty;
							}
						}();
						return A2(
							$elm$core$List$cons,
							$elm$html$Html$text('This question was answered as follows:'),
							A2(
								$elm$core$List$cons,
								A2($elm$html$Html$br, _List_Nil, _List_Nil),
								A2(
									$elm$core$List$cons,
									A2($elm$html$Html$br, _List_Nil, _List_Nil),
									A3(
										$elm$core$List$map2,
										makeAnswerBox,
										vAnsBoxRecs,
										$elm$core$Array$toList(answers)))));
				}
			});
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('field')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('control')
							]),
						A2(makeAnswers, aSet, vAnswerMaybe))
					]))
			]);
	});
var $author$project$View$View$viewStandardQuestionAndAnswerPage = F3(
	function (model, _v0, primaryContent) {
		var pageMeat = _List_fromArray(
			[
				A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[
						$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
						$mdgriffith$elm_ui$Element$Font$size(16)
					]),
				$mdgriffith$elm_ui$Element$html(
					A2($elm$html$Html$div, _List_Nil, primaryContent)))
			]);
		return A4(
			$author$project$Page$PagesCommon$makePage,
			$elm$core$Maybe$Nothing,
			$author$project$View$ViewHelper$topBarAsElement(model),
			$elm$core$Maybe$Just(
				$mdgriffith$elm_ui$Element$text('Question Review')),
			pageMeat);
	});
var $author$project$View$View$viewQuestionAndAnswer = F4(
	function (model, qId, qAnswerStatusString, qaSubRoute) {
		var makeUnit = $elm$core$Basics$always(0);
		var examResultFlavor = A2($elm$core$String$contains, 'v', qId) ? 1 : 0;
		var qRemoteDataStatus = function () {
			if (!examResultFlavor) {
				return A3($krisajenkins$remotedata$RemoteData$mapBoth, makeUnit, makeUnit, model.gL);
			} else {
				return A3($krisajenkins$remotedata$RemoteData$mapBoth, makeUnit, makeUnit, model.no.dv);
			}
		}();
		if ($krisajenkins$remotedata$RemoteData$isNotAsked(qRemoteDataStatus)) {
			return A3(
				$author$project$View$View$viewStandardQuestionAndAnswerPage,
				model,
				qId,
				_List_fromArray(
					[
						$elm$html$Html$text('Preparing to retrieve the question details...')
					]));
		} else {
			if ($krisajenkins$remotedata$RemoteData$isLoading(qRemoteDataStatus)) {
				return A3(
					$author$project$View$View$viewStandardQuestionAndAnswerPage,
					model,
					qId,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$p,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('subtitle'),
									A2($elm$html$Html$Attributes$style, 'margin-top', '.3em'),
									A2($elm$html$Html$Attributes$style, 'margin-bottom', '.4em')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Loading question and answer ...')
								])),
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('button is-loading'),
									A2($elm$html$Html$Attributes$style, 'font-size', '6em'),
									A2($elm$html$Html$Attributes$style, 'width', '100%')
								]),
							_List_Nil)
						]));
			} else {
				var isStudent = A3($krisajenkins$remotedata$RemoteData$unwrap, false, $author$project$Type$User$isStudentE, model.fc);
				var _v0 = function () {
					switch (qAnswerStatusString) {
						case 'c':
							return isStudent ? _Utils_Tuple2('You answered this question correctly!', 'is-success') : _Utils_Tuple2('This question was answered correctly', 'is-success');
						case 'n':
							return isStudent ? _Utils_Tuple2('You did not answer this question', 'is-warning') : _Utils_Tuple2('This question was not answered', 'is-warning');
						case 'i':
							var mColor = 'is-danger';
							switch (qaSubRoute.$) {
								case 0:
									return isStudent ? _Utils_Tuple2('The answer you selected (shown above) was incorrect', mColor) : _Utils_Tuple2('The answer selected (shown above) was incorrect', mColor);
								case 1:
									return isStudent ? _Utils_Tuple2('The combination of answers you selected (shown above) were incorrect', mColor) : _Utils_Tuple2('The combination of answers selected (shown above) were incorrect', mColor);
								case 2:
									return isStudent ? _Utils_Tuple2('Your answer(s) shown above was incorrect', mColor) : _Utils_Tuple2('The answer(s) shown above was incorrect', mColor);
								case 3:
									var answerAsString = qaSubRoute.a;
									return isStudent ? _Utils_Tuple2('Your answer of ' + (answerAsString + ' was incorrect'), mColor) : _Utils_Tuple2('The answer of ' + (answerAsString + ' was incorrect'), mColor);
								default:
									return _Utils_Tuple2('The answer appears incorrect, but there was a software issue ... please let SmarterMaths know - thanks!', mColor);
							}
						default:
							return _Utils_Tuple2('', '');
					}
				}();
				var resString = _v0.a;
				var messageColor = _v0.b;
				var showResultBox = resString !== '';
				var makePrimaryContent = F2(
					function (questionContent, solutionContent) {
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$section,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('accordions'),
										A2($elm$html$Html$Attributes$style, 'margin-bottom', '.8em')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$article,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('accordion is-active')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('accordion-header'),
														$elm$html$Html$Events$onClick($author$project$Msgs$OnToggleQuestionAccordion)
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$p,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text('Question')
															]))
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('accordion-body')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('accordion-content')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('box sm-post-content'),
																		A2($elm$html$Html$Attributes$style, 'margin-bottom', '.8em')
																	]),
																questionContent),
																showResultBox ? A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('notification ' + messageColor)
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text(resString)
																	])) : $elm$html$Html$text('')
															]))
													]))
											])),
										A2(
										$elm$html$Html$article,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class(
												'accordion' + (model.hs ? ' is-active' : ''))
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('accordion-header'),
														$elm$html$Html$Events$onClick($author$project$Msgs$OnToggleQuestionAccordion)
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$p,
														_List_Nil,
														_List_fromArray(
															[
																$elm$html$Html$text(
																'Answer (Click to ' + (model.hs ? 'hide)' : 'show)'))
															])),
														A2(
														$elm$html$Html$a,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('button is-small')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text(
																model.hs ? 'Hide Answer' : 'Show Answer')
															]))
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('accordion-body')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('accordion-content')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('box sm-post-content'),
																		A2($elm$html$Html$Attributes$style, 'margin-bottom', '.8em')
																	]),
																solutionContent)
															]))
													]))
											]))
									])),
								$author$project$View$ViewHelper$viewBackButton
							]);
					});
				if (!examResultFlavor) {
					var _v4 = model.gL;
					if (_v4.$ === 3) {
						var currPost = _v4.a;
						var solutionContent = A2(
							$author$project$View$View$parseContent,
							currPost.lW,
							A2($author$project$Helper$Common$extractContent, currPost, 2));
						var questionContent = function () {
							var extractedQuestion = A2($author$project$Helper$Common$extractContent, currPost, 0);
							var qContent = function () {
								if ((qAnswerStatusString === 'i') || (qAnswerStatusString === 'c')) {
									if (!qaSubRoute.$) {
										var chosenOption = qaSubRoute.a;
										return A2($author$project$View$View$insertMultiChoiceIndexValue, extractedQuestion, chosenOption);
									} else {
										return extractedQuestion;
									}
								} else {
									return extractedQuestion;
								}
							}();
							return A2($author$project$View$View$parseContent, currPost.lW, qContent);
						}();
						return A3(
							$author$project$View$View$viewStandardQuestionAndAnswerPage,
							model,
							qId,
							A2(makePrimaryContent, questionContent, solutionContent));
					} else {
						return A3($author$project$View$ViewHelper$viewFatalErrorPage, model, 'There was a problem retrieving the question from the server.', 'QuestionResultFlavor branch');
					}
				} else {
					var _v6 = model.no.aL;
					if (_v6.$ === 3) {
						var variantContent = _v6.a;
						var solutionContent = $author$project$Helper$HtmlUtil$nodesToHtmls(variantContent.pQ);
						var selectedAnswerMaybe = function () {
							switch (qaSubRoute.$) {
								case 0:
									var chosenOption = qaSubRoute.a;
									return $elm$core$Maybe$Just(
										$author$project$Type$History$MCSA(
											_Utils_Tuple2(chosenOption, qAnswerStatusString === 'c')));
								case 1:
									var chosenOptions = qaSubRoute.a;
									return $elm$core$Maybe$Just(
										$author$project$Type$History$MCMA(
											_Utils_Tuple2(chosenOptions, qAnswerStatusString === 'c')));
								case 2:
									var strs = qaSubRoute.a;
									return $elm$core$Maybe$Just(
										$author$project$Type$History$ANSBOX(
											_Utils_Tuple2(strs, qAnswerStatusString === 'c')));
								case 3:
									return $elm$core$Maybe$Nothing;
								default:
									return $elm$core$Maybe$Nothing;
							}
						}();
						var answers = A2($author$project$UISnippet$Answer$viewAnswersHtml, variantContent.jB, selectedAnswerMaybe);
						var questionContent = A2(
							$elm$core$List$append,
							$author$project$Helper$HtmlUtil$nodesToHtmls(variantContent.nE),
							answers);
						return A3(
							$author$project$View$View$viewStandardQuestionAndAnswerPage,
							model,
							qId,
							A2(makePrimaryContent, questionContent, solutionContent));
					} else {
						var _v8 = model.no.dv;
						if (_v8.$ === 2) {
							var err = _v8.a;
							return A2($elm$core$String$startsWith, 'Network Error', err) ? A2(
								$author$project$View$ViewHelper$decoratePageAllElm,
								model,
								_Utils_Tuple2(
									$mdgriffith$elm_ui$Element$text('There was a problem retrieving the question'),
									A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												A2($elm$html$Html$Attributes$style, 'color', 'white')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('The question could not be retrieved either because of a network error, or it could have been removed from the system.'),
												A2($elm$html$Html$br, _List_Nil, _List_Nil),
												$elm$html$Html$text('If the problem persists, please let us know at support@smartermaths.com.au with the following details:'),
												A2($elm$html$Html$br, _List_Nil, _List_Nil),
												$elm$html$Html$text('Question Id: ' + qId),
												A2($elm$html$Html$br, _List_Nil, _List_Nil),
												A2($elm$html$Html$br, _List_Nil, _List_Nil),
												$author$project$View$ViewHelper$viewBackButton
											])))) : A3($author$project$View$ViewHelper$viewFatalErrorPage, model, 'There was a problem retrieving the question from the server.', 'HistoryResultFlavor branch - ' + err);
						} else {
							return A3(
								$author$project$View$View$viewStandardQuestionAndAnswerPage,
								model,
								qId,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$p,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('subtitle'),
												A2($elm$html$Html$Attributes$style, 'margin-top', '.3em'),
												A2($elm$html$Html$Attributes$style, 'margin-bottom', '.4em')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Loading question and answer ...')
											])),
										A2(
										$elm$html$Html$a,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('button is-loading'),
												A2($elm$html$Html$Attributes$style, 'font-size', '6em'),
												A2($elm$html$Html$Attributes$style, 'width', '100%')
											]),
										_List_Nil)
									]));
						}
					}
				}
			}
		}
	});
var $author$project$Msgs$SetqIdForQuestionLookup = function (a) {
	return {$: 9, a: a};
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $author$project$View$ViewExport$viewRawDataInternal = function (model) {
	return _Utils_Tuple2(
		'All student NAPLAN Exams results raw data download',
		A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('box')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('content')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h3,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('This rarely needed advanced feature can support custom analytics (e.g. Excel analysis) beyond what is provided by the system')
								])),
							A2(
							$elm$html$Html$ul,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$li,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Click the button below to download raw NAPLAN Exam results for all your students, in csv format. (Note: If you\'re the administrator teacher, if you click the \'Get Students\' button on your dashboard next to any or all class teachers at your school, then you\'ll also get the data for their students in your download file.)')
										])),
									A2(
									$elm$html$Html$li,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('You can open/import this data into Microsoft Excel, Google Sheets, Apple Numbers, or other tools.')
										])),
									A2(
									$elm$html$Html$li,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('You will then be able to use those tools, to perform results analysis beyond what is currently provided in the online screens (e.g. Question level analysis, cohort analysis, band distribution, etc..)')
										])),
									A2(
									$elm$html$Html$li,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Notes on csv format / columns'),
											A2(
											$elm$html$Html$ul,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$li,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Each row of the data represents a single exam attempt for a student.')
														])),
													A2(
													$elm$html$Html$li,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('If a band is scored as 0, it is because the student did not complete enough of the test to perform the calculation (current scoring rules require that the student begin the last testlet before the exam time limit is reached.)')
														])),
													A2(
													$elm$html$Html$li,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Question Id key (n_qId column): This identifier may be entered on this page to view the question itself.')
														])),
													A2(
													$elm$html$Html$li,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Question response key (n_resp column): If multiple choice, then a, b, c, d, etc.. indicates the first, second, etc answer boxes; If single answer, then the response typed by the student is provided.')
														])),
													A2(
													$elm$html$Html$li,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Question result key (n_rslt column) : 0=incorrect, 1=correct, n=not answered/skipped, or blank if the student did not reach/view this question before the exam time limit was reached.')
														]))
												]))
										]))
								])),
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('button is-primary'),
									$elm$html$Html$Events$onClick(
									A2(
										$author$project$Msgs$LinkTo,
										$author$project$Routing$withHash($author$project$Routing$smPaths.iz),
										$elm$core$Maybe$Nothing))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('icon')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('fa fa-pencil-square-o')
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$span,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Prepare student results for download')
										]))
								])),
							A2($elm$html$Html$hr, _List_Nil, _List_Nil),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('content')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h3,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Question Lookup')
										])),
									A2(
									$elm$html$Html$ul,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$li,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Type or copy/paste the question id (qId) into the input box, and click \'View Question\'.')
												])),
											A2(
											$elm$html$Html$li,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('If you mistype it and get an error, simply click the back button in your browser to return to this page.')
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('field has-addons')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('control')
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('input'),
															$elm$html$Html$Attributes$type_('text'),
															$elm$html$Html$Attributes$placeholder('The question id (qId)'),
															$elm$html$Html$Events$onInput(
															function (el) {
																var qIdTrim = $elm$core$String$trim(el);
																return ($elm$core$String$isEmpty(qIdTrim) || (!A2($elm$core$String$all, $elm$core$Char$isDigit, qIdTrim))) ? $author$project$Msgs$SetqIdForQuestionLookup($elm$core$Maybe$Nothing) : $author$project$Msgs$SetqIdForQuestionLookup(
																	$elm$core$Maybe$Just(
																		$elm$core$String$trim(el)));
															})
														]),
													_List_Nil)
												])),
											A2(
											$elm$html$Html$p,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('control')
												]),
											_List_fromArray(
												[
													function () {
													var _v0 = model.is;
													if (_v0.$ === 1) {
														return A2(
															$elm$html$Html$a,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('button is-info is-static'),
																	$elm$html$Html$Attributes$disabled(true)
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('View Question')
																]));
													} else {
														var qIdStr = _v0.a;
														return A3(
															$author$project$Routing$link,
															$author$project$Msgs$LinkClicked(
																$author$project$Routing$withHash($author$project$Routing$smPaths.dS + ('/' + (qIdStr + '/-/-/-')))),
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('button is-info')
																]),
															_List_fromArray(
																[
																	$elm$html$Html$text('View Question')
																]));
													}
												}()
												]))
										]))
								]))
						]))
				])));
};
var $author$project$View$ViewExport$viewRawData = function (model) {
	var _v0 = $author$project$View$ViewExport$viewRawDataInternal(model);
	var heading = _v0.a;
	var bodyContent = _v0.b;
	var pageMeat = _List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Element$column,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$white),
					A2($mdgriffith$elm_ui$Element$paddingXY, 30, 30),
					$mdgriffith$elm_ui$Element$Border$rounded(10),
					$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
					$mdgriffith$elm_ui$Element$Font$size(16)
				]),
			_List_fromArray(
				[
					A2(
					$mdgriffith$elm_ui$Element$paragraph,
					_List_Nil,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$html(bodyContent)
						]))
				]))
		]);
	return A4(
		$author$project$Page$PagesCommon$makePage,
		$elm$core$Maybe$Nothing,
		$author$project$View$ViewHelper$topBarAsElement(model),
		$elm$core$Maybe$Just(
			$mdgriffith$elm_ui$Element$text(heading)),
		pageMeat);
};
var $elm$html$Html$Attributes$download = function (fileName) {
	return A2($elm$html$Html$Attributes$stringProperty, 'download', fileName);
};
var $author$project$Helper$Common$countTestletChainQuestions = F2(
	function (appCfg, testletIdMaybe) {
		var testletDetailMaybe = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.lW;
					},
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$Just,
						$elm$core$Basics$eq(testletIdMaybe))),
				appCfg.i9));
		var nbrQstns = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.ci;
					},
					$elm$core$Array$length),
				testletDetailMaybe));
		var anArbitraryNxtTestletId = A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Maybe$Nothing,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$head,
					$elm$core$Maybe$map(
						function ($) {
							return $.ix;
						})),
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.fn;
					},
					testletDetailMaybe)));
		return _Utils_eq(testletIdMaybe, $elm$core$Maybe$Nothing) ? nbrQstns : (nbrQstns + A2($author$project$Helper$Common$countTestletChainQuestions, appCfg, anArbitraryNxtTestletId));
	});
var $author$project$Helper$Common$getNbrQuestionsInQGroup = F2(
	function (appCfg, qGroupId) {
		var strtTstltIdMaybe = A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.oE;
			},
			A2($author$project$Type$AppCfg$getQGroupMaybe, appCfg, qGroupId));
		return A2($author$project$Helper$Common$countTestletChainQuestions, appCfg, strtTstltIdMaybe);
	});
var $author$project$Helper$Common$getQGroupName = F2(
	function (appCfg, qGroupId) {
		return A2(
			$elm$core$Maybe$withDefault,
			'Test is retired from system or not available.  Please disregard this result item',
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.iv;
					},
					function ($) {
						return $.mO;
					}),
				A2($author$project$Type$AppCfg$getQGroupMaybe, appCfg, qGroupId)));
	});
var $author$project$Helper$Common$getAllStudentHistoryAsEncodedCSV = F2(
	function (appCfg, students) {
		var createMetaDataRow = function (qGroupId) {
			var qGroupNbrQuestions = A2($author$project$Helper$Common$getNbrQuestionsInQGroup, appCfg, qGroupId);
			var qGroupQstnHeadings = A2(
				$elm$core$List$concatMap,
				function (el) {
					var it = $elm$core$String$fromInt(el);
					return _List_fromArray(
						[it + '_qId', it + '_resp', it + '_rslt']);
				},
				A2($elm$core$List$range, 1, qGroupNbrQuestions));
			return qGroupQstnHeadings;
		};
		var consStudRow_TestletGroupResultRecordBase = function (singleExamRunResult) {
			var runBase = _List_fromArray(
				[
					$elm$core$String$fromInt(singleExamRunResult.nF),
					A2($author$project$Helper$Common$getQGroupName, appCfg, singleExamRunResult.nF),
					singleExamRunResult.pr,
					$author$project$Type$DateTime$toDateString(singleExamRunResult.pr)
				]);
			var rawCorrect = $author$project$Helper$Common$nbrCorrect(
				A2(
					$elm$core$List$map,
					function ($) {
						return $.dW;
					},
					singleExamRunResult.ci));
			var pcResult = $elm$core$String$fromInt(
				A2(
					$author$project$Helper$Common$percentScore,
					rawCorrect,
					A2($author$project$Helper$Common$getNbrQuestionsInQGroup, appCfg, singleExamRunResult.nF)));
			var makeOneZeroOrN = function (ch) {
				switch (ch) {
					case 'c':
						return '1';
					case 'i':
						return '0';
					default:
						return ch;
				}
			};
			var makeQstnItems = function (questionResultItem) {
				var resp = function () {
					var _v0 = questionResultItem.iu;
					if (!_v0.$) {
						if (!_v0.a.$) {
							var intVal = _v0.a.a;
							return $elm$core$String$fromChar(
								$elm$core$Char$fromCode(intVal + 97));
						} else {
							var strVal = _v0.a.a;
							return strVal;
						}
					} else {
						return 'n/a';
					}
				}();
				return _List_fromArray(
					[
						$elm$core$String$fromInt(questionResultItem.dS),
						resp,
						makeOneZeroOrN(
						$author$project$Type$ExamResult$questionResultEncodeToString(questionResultItem.dW))
					]);
			};
			var questions = A2($elm$core$List$concatMap, makeQstnItems, singleExamRunResult.ci);
			var bandResult = $author$project$Type$Testlet$isLegacyQGroupId(singleExamRunResult.nF) ? $elm$core$String$fromInt(
				A2($author$project$Helper$Band$singleExamRunBandResult, appCfg, singleExamRunResult)) : A2(
				$elm$core$Maybe$withDefault,
				'0',
				A2($author$project$Helper$Band$singleExamRunBandString, appCfg, singleExamRunResult).b);
			return $elm$core$List$concat(
				_List_fromArray(
					[
						runBase,
						_List_fromArray(
						[
							bandResult,
							pcResult,
							$elm$core$String$fromInt(rawCorrect)
						]),
						questions
					]));
		};
		var allCurrentValidQGroupIds = A2(
			$elm$core$List$map,
			function ($) {
				return $.lW;
			},
			appCfg.nH);
		var allUniqueQGroupsInStudentsData = A2(
			$elm$core$List$filter,
			function (gId) {
				return A2($elm$core$List$member, gId, allCurrentValidQGroupIds);
			},
			$elm$core$Set$toList(
				$elm$core$Set$fromList(
					A2(
						$elm$core$List$map,
						function ($) {
							return $.nF;
						},
						A2(
							$elm$core$List$filterMap,
							$author$project$Type$ExamResult$filterTestletGroupResultRecords,
							A2(
								$elm$core$List$concatMap,
								function ($) {
									return $.lU;
								},
								students))))));
		var qGroupMetadataHeaderRows = A2($elm$core$List$map, createMetaDataRow, allUniqueQGroupsInStudentsData);
		var qGroupMetadataLongestSingleHeaderRow = $elm$core$List$head(
			$elm$core$List$reverse(
				A2(
					$elm$core$List$sortWith,
					F2(
						function (l1, l2) {
							return (_Utils_cmp(
								$elm$core$List$length(l1),
								$elm$core$List$length(l2)) < 1) ? 0 : 2;
						}),
					qGroupMetadataHeaderRows)));
		var consRunResultsForAStudent = function (student) {
			var studentRunResults = A2(
				$elm$core$List$map,
				consStudRow_TestletGroupResultRecordBase,
				A2(
					$elm$core$List$filter,
					function (el) {
						return A2($elm$core$List$member, el.nF, allCurrentValidQGroupIds);
					},
					A2($elm$core$List$filterMap, $author$project$Type$ExamResult$filterTestletGroupResultRecords, student.lU)));
			var studentDetailBase = _List_fromArray(
				[
					'data',
					student.dc.li,
					student.dc.lt,
					student.dc.mq,
					A2(
					$elm$core$Maybe$withDefault,
					'UnknownCohort',
					A2($elm$core$Maybe$map, $author$project$Type$Cohort$toString, student.dc.hB)),
					A3(
					$elm$core$String$replace,
					',',
					':',
					A2($elm$core$Maybe$withDefault, '', student.dc.eV))
				]);
			return $elm$core$List$isEmpty(studentRunResults) ? _List_fromArray(
				[studentDetailBase]) : A2(
				$elm$core$List$map,
				function (runRow) {
					return $elm$core$List$concat(
						_List_fromArray(
							[studentDetailBase, runRow]));
				},
				studentRunResults);
		};
		var csvString = function () {
			if ($elm$core$List$isEmpty(students)) {
				return 'No student results data was found.';
			} else {
				var listOfQuestionHeaders = A2($elm$core$Maybe$withDefault, _List_Nil, qGroupMetadataLongestSingleHeaderRow);
				var headerRowListOfStrings = A2(
					$elm$core$List$intersperse,
					',',
					_Utils_ap(
						_List_fromArray(
							['header', 'email', 'first_name', 'last_name', 'yr_lvl', 'group', 'test_id', 'test_name', 'attempt_time_raw', 'attempt_time_text', 'band_result', 'pc_result', 'raw_correct']),
						_Utils_ap(
							listOfQuestionHeaders,
							_List_fromArray(
								['\n']))));
				return $elm$core$String$concat(
					A2(
						$elm$core$List$append,
						headerRowListOfStrings,
						$elm$core$List$concat(
							A2(
								$elm$core$List$map,
								function (el) {
									return _Utils_ap(
										el,
										_List_fromArray(
											['\n']));
								},
								A2(
									$elm$core$List$map,
									$elm$core$List$intersperse(','),
									A2($elm$core$List$concatMap, consRunResultsForAStudent, students))))));
			}
		}();
		return $elm$url$Url$percentEncode(csvString);
	});
var $author$project$View$ViewExport$viewRawDataDownload = F2(
	function (model, appCfg) {
		var students = A2(
			$elm$core$List$map,
			$author$project$Type$User$transformStudentETypeToAssignee,
			A2(
				$elm$core$List$filterMap,
				A2($elm$core$Basics$composeR, $author$project$Page$ManageUsers$extractPersonEFromUser, $author$project$Type$User$extractStudentEFromPersonE),
				A2($krisajenkins$remotedata$RemoteData$withDefault, _List_Nil, model.h5.S)));
		return _Utils_Tuple2(
			$mdgriffith$elm_ui$Element$text('Export all student raw data to CSV'),
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('box')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('content')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h3,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Student results csv')
									])),
								A2(
								$elm$html$Html$p,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('Please click to download the csv file to your local computer.')
									])),
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('button is-primary'),
										$elm$html$Html$Attributes$download('SMNaplanOnline.csv'),
										$elm$html$Html$Attributes$href(
										'data:application/octet-stream,' + A2($author$project$Helper$Common$getAllStudentHistoryAsEncodedCSV, appCfg, students))
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('icon')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$i,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('fa fa-cloud-download')
													]),
												_List_Nil)
											])),
										A2(
										$elm$html$Html$span,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text('Download student results')
											]))
									]))
							]))
					])));
	});
var $author$project$View$View$makeSessionsResultsMainContentBox = F2(
	function (resultColumnTitle, tBodyContent) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('box')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$table,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('table is-striped is-hoverable is-fullwidth')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$thead,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tr,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Attempt')
												])),
											A2(
											$elm$html$Html$th,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$style, 'min-width', '12rem')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(resultColumnTitle)
												])),
											A2(
											$elm$html$Html$th,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Results')
												]))
										]))
								])),
							A2($elm$html$Html$tbody, _List_Nil, tBodyContent)
						]))
				]));
	});
var $author$project$View$Bands$viewHighestBandBalls = F2(
	function (maybeHighestBand, lvl) {
		var highestOnlyBandBallList = function () {
			if (maybeHighestBand.$ === 1) {
				return _List_Nil;
			} else {
				var highestBandAchievedNumber = maybeHighestBand.a;
				return _List_fromArray(
					[highestBandAchievedNumber]);
			}
		}();
		return A2($author$project$View$Bands$viewBandBalls, highestOnlyBandBallList, lvl);
	});
var $author$project$View$View$viewSessionsResults = F4(
	function (model, appCfg, selfOrAssignee, resultSetId) {
		var tgrrs = $elm$core$List$reverse(
			A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.pr;
				},
				A2(
					$elm$core$List$filter,
					function (el) {
						return _Utils_eq(
							$elm$core$String$fromInt(el.nF),
							resultSetId);
					},
					A2($elm$core$List$filterMap, $author$project$Type$ExamResult$filterTestletGroupResultRecords, selfOrAssignee.lU))));
		var headerText = 'Results for ' + (selfOrAssignee.dc.lt + (' ' + selfOrAssignee.dc.mq));
		var makeErrorPage = function (contentEl) {
			return A4(
				$author$project$Page$PagesCommon$makePage,
				$elm$core$Maybe$Nothing,
				$author$project$View$ViewHelper$topBarAsElement(model),
				$elm$core$Maybe$Just(
					$mdgriffith$elm_ui$Element$text(headerText)),
				_List_fromArray(
					[
						A2(
						$mdgriffith$elm_ui$Element$column,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$white),
								A2($mdgriffith$elm_ui$Element$spacingXY, 0, 20)
							]),
						_List_fromArray(
							[
								contentEl,
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_Nil,
								$mdgriffith$elm_ui$Element$html($author$project$View$ViewHelper$viewBackButton))
							]))
					]));
		};
		var actrrs = $elm$core$List$reverse(
			A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.pr;
				},
				A2(
					$elm$core$List$filter,
					function (el) {
						return _Utils_eq(el.jz, resultSetId) && $author$project$Type$ExamResult$isOnlineActivityResultRecord(el);
					},
					$author$project$Type$ExamResult$getActivityResultRecords(selfOrAssignee.lU))));
		var _v0 = _Utils_Tuple2(
			$elm$core$List$isEmpty(tgrrs),
			$elm$core$List$isEmpty(actrrs));
		_v0$2:
		while (true) {
			if (!_v0.a) {
				if (_v0.b) {
					var qGroupMeta = $elm$core$List$head(
						A2(
							$elm$core$List$filter,
							function (el) {
								return _Utils_eq(
									$elm$core$String$fromInt(el.lW),
									resultSetId);
							},
							appCfg.nH));
					var _v1 = _Utils_Tuple2(tgrrs, qGroupMeta);
					if (!_v1.b.$) {
						if (_v1.a.b) {
							var _v2 = _v1.a;
							var attempt1 = _v2.a;
							var qGroupMeta1 = _v1.b.a;
							var tBodyContent = A2(
								$elm$core$List$map,
								function (el) {
									return A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$td,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text(
														$author$project$Type$DateTime$toDateString(el.pr))
													])),
												A2(
												$elm$html$Html$td,
												_List_Nil,
												_List_fromArray(
													[
														$author$project$Type$Testlet$isLegacyQGroupId(el.nF) ? A2(
														$author$project$View$Bands$viewHighestBandBalls,
														$elm$core$Maybe$Just(
															A2($author$project$Helper$Band$singleExamRunBandResult, appCfg, el)),
														A2(
															$elm$core$Maybe$withDefault,
															0,
															A2($elm$core$Maybe$map, $author$project$Type$Cohort$toInt, selfOrAssignee.dc.hB))) : A2(
														$author$project$View$View$makeResultImage,
														0,
														A2($author$project$Helper$Band$singleExamRunBandString, appCfg, el))
													])),
												A2(
												$elm$html$Html$td,
												_List_Nil,
												_List_fromArray(
													[
														A3(
														$author$project$Routing$link,
														$author$project$Msgs$LinkClicked(
															$author$project$Routing$withHash($author$project$Routing$smPaths.of) + ('/' + (selfOrAssignee.dc.pH + ('/' + ($elm$core$String$fromInt(el.nF) + ('/' + el.pr)))))),
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('button is-primary is-small')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Detail')
															]))
													]))
											]));
								},
								tgrrs);
							var mainContentBox = A2($author$project$View$View$makeSessionsResultsMainContentBox, 'Band Achieved', tBodyContent);
							var pageMeat = _List_fromArray(
								[
									A2(
									$mdgriffith$elm_ui$Element$column,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$white)
										]),
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text(qGroupMeta1.iv.mO),
											$mdgriffith$elm_ui$Element$text(qGroupMeta1.iv.k8)
										])),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
											$mdgriffith$elm_ui$Element$Font$size(16)
										]),
									$mdgriffith$elm_ui$Element$html(mainContentBox)),
									A2(
									$mdgriffith$elm_ui$Element$el,
									_List_Nil,
									$mdgriffith$elm_ui$Element$html($author$project$View$ViewHelper$viewBackButton))
								]);
							return A4(
								$author$project$Page$PagesCommon$makePage,
								$elm$core$Maybe$Nothing,
								$author$project$View$ViewHelper$topBarAsElement(model),
								$elm$core$Maybe$Just(
									$mdgriffith$elm_ui$Element$text(headerText)),
								pageMeat);
						} else {
							return makeErrorPage(
								A2(
									$mdgriffith$elm_ui$Element$paragraph,
									_List_Nil,
									_List_fromArray(
										[
											$mdgriffith$elm_ui$Element$text('There was a problem extracting the set of results for this exam.  (Technical details: Could not get qGroup from student history for studentSub, resultSetId: ' + (selfOrAssignee.dc.pH + (', ' + (resultSetId + ')'))))
										])));
						}
					} else {
						var _v3 = _v1.b;
						return makeErrorPage(
							A2(
								$mdgriffith$elm_ui$Element$paragraph,
								_List_Nil,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$text('There was a problem extracting the exam definitions for this exam. (Techical details: Could not get qGroup from appCfg for resultSetId: ' + (resultSetId + ')'))
									])));
					}
				} else {
					break _v0$2;
				}
			} else {
				if (!_v0.b) {
					var itemDatasMaybe = $krisajenkins$remotedata$RemoteData$toMaybe(
						A2($krisajenkins$remotedata$RemoteData$map, $author$project$Type$NodeCfg$getItems, model.mX.fs));
					var currentCohort = A2($elm$core$Maybe$withDefault, $author$project$Type$Cohort$placeholder, selfOrAssignee.dc.hB);
					var makeResult = function (qs) {
						return A2(
							$elm$core$Maybe$withDefault,
							$elm$html$Html$text('Not available'),
							A2(
								$elm$core$Maybe$map,
								function (el) {
									return A2(
										$elm$html$Html$img,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$src(el)
											]),
										_List_Nil);
								},
								A2(
									$elm$core$Maybe$andThen,
									function (el) {
										return A3($author$project$Type$ExamResult$getActivityResultUrlSmall, currentCohort, el, qs);
									},
									itemDatasMaybe)));
					};
					var tBodyContent = function () {
						var actrrsOldestIdx = $elm$core$List$length(actrrs) - 1;
						return A2(
							$elm$core$List$indexedMap,
							F2(
								function (idx, el) {
									return A2(
										$elm$html$Html$tr,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$td,
												_List_Nil,
												_List_fromArray(
													[
														$elm$html$Html$text(
														_Utils_ap(
															$author$project$Type$DateTime$toDateString(el.pr),
															_Utils_eq(idx, actrrsOldestIdx) ? ' (PRE-ASSESSMENT)' : ''))
													])),
												A2(
												$elm$html$Html$td,
												_List_Nil,
												_List_fromArray(
													[
														makeResult(el.ci)
													])),
												A2(
												$elm$html$Html$td,
												_List_Nil,
												_List_fromArray(
													[
														A3(
														$author$project$Routing$link,
														$author$project$Msgs$LinkClicked(
															$author$project$Routing$withHash($author$project$Routing$smPaths.of) + ('/' + (selfOrAssignee.dc.pH + ('/' + (el.jz + ('/' + el.pr)))))),
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('button is-primary is-small')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Detail')
															]))
													]))
											]));
								}),
							actrrs);
					}();
					var mainContentBox = A2($author$project$View$View$makeSessionsResultsMainContentBox, 'Level achieved', tBodyContent);
					var pageMeat = _List_fromArray(
						[
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_fromArray(
								[
									$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
									$mdgriffith$elm_ui$Element$Font$size(16)
								]),
							$mdgriffith$elm_ui$Element$html(mainContentBox)),
							A2(
							$mdgriffith$elm_ui$Element$el,
							_List_Nil,
							$mdgriffith$elm_ui$Element$html($author$project$View$ViewHelper$viewBackButton))
						]);
					return A4(
						$author$project$Page$PagesCommon$makePage,
						$elm$core$Maybe$Nothing,
						$author$project$View$ViewHelper$topBarAsElement(model),
						$elm$core$Maybe$Just(
							$mdgriffith$elm_ui$Element$text(headerText)),
						pageMeat);
				} else {
					break _v0$2;
				}
			}
		}
		return makeErrorPage(
			$mdgriffith$elm_ui$Element$text('Sorry but we are unable to retrieve the results for this exam.  There was an error for resultSetId: ' + resultSetId));
	});
var $author$project$View$View$makeViewsForTeacher = F4(
	function (appCfg, model, me, teacherType) {
		var examResults = A2(
			$krisajenkins$remotedata$RemoteData$withDefault,
			{eZ: _List_Nil},
			model.fQ);
		var assigneeRecordBase = A7($author$project$Type$User$AssigneeBase, $author$project$Type$User$sampleStudentUserSub, $author$project$Type$User$Student, $elm$core$Maybe$Nothing, 'Student View', '', '', $elm$core$Maybe$Nothing);
		var assigneeRecord = A4($author$project$Type$User$Assignee, assigneeRecordBase, '', '', examResults.eZ);
		var _v0 = model.n_;
		switch (_v0.$) {
			case 0:
				return A2(
					$Punie$elm_reader$Reader$map,
					$elm$html$Html$map($author$project$Msgs$MsgPageManageUsers),
					A4($author$project$Page$ManageUsers$view, appCfg, model.h5, $author$project$Page$PagesCommon$topBar, 0));
			case 7:
				return $Punie$elm_reader$Reader$reader(
					$author$project$View$ViewExport$viewRawData(model));
			case 8:
				return $Punie$elm_reader$Reader$reader(
					A2(
						$author$project$View$ViewHelper$decoratePageAllElm,
						model,
						A2($author$project$View$ViewConfiguration$viewConfigureHiddenExams, appCfg, model)));
			case 9:
				return $Punie$elm_reader$Reader$reader(
					A2(
						$author$project$View$ViewHelper$decoratePageAllElm,
						model,
						A2($author$project$View$ViewAnalytics$viewAnalyticsList, appCfg, model)));
			case 10:
				var item = _v0.a;
				return $Punie$elm_reader$Reader$reader(
					function () {
						if (!item) {
							return $author$project$View$View$viewAnalyticsItem(model);
						} else {
							var mdl = model.h5;
							var assignees = A2(
								$author$project$Page$ManageUsers$toFilteredAssignees,
								mdl,
								A2($krisajenkins$remotedata$RemoteData$withDefault, _List_Nil, mdl.S));
							return A2(
								$author$project$View$ViewHelper$decoratePageAllElm,
								model,
								A6($author$project$View$ViewAnalyticsByQuestion$viewAnalyticsByQuestion, appCfg, model.gj, model.gi, assignees, model.t, model.gi.nG));
						}
					}());
			case 11:
				return $Punie$elm_reader$Reader$reader(
					A2(
						$author$project$View$ViewHelper$decoratePageAllElm,
						model,
						A2($author$project$View$ViewExport$viewRawDataDownload, model, appCfg)));
			case 6:
				var cohortStr = _v0.a;
				var cohortMaybe = $author$project$Type$Cohort$fromStringToMaybeCohort(cohortStr);
				if (cohortMaybe.$ === 1) {
					return $Punie$elm_reader$Reader$reader(
						A3($author$project$View$ViewHelper$viewFatalErrorPage, model, 'Apologies, but there are no sample exams for the selected year level', 'The year level selected was: ' + cohortStr));
				} else {
					return A4(
						$author$project$View$View$viewStudentHome,
						model,
						appCfg,
						true,
						A4(
							$author$project$Type$User$Assignee,
							_Utils_update(
								assigneeRecordBase,
								{hB: cohortMaybe}),
							'',
							'',
							examResults.eZ));
				}
			case 29:
				var studentSub = _v0.a;
				var assignee = A2($author$project$View$View$getAssigneeMaybe, model, studentSub);
				if (assignee.$ === 1) {
					return $Punie$elm_reader$Reader$reader(
						($krisajenkins$remotedata$RemoteData$isLoading(model.el) || $krisajenkins$remotedata$RemoteData$isNotAsked(model.el)) ? A2($author$project$View$View$viewLoadingApp, model, 'Loading your assignees data...') : A3(
							$author$project$View$ViewHelper$viewFatalErrorPage,
							model,
							'We apologise ... We can\'t find the information for the page you\'re trying to load.',
							'Could not find assignee in route : ' + $author$project$Routing$routeToString(model.n_)));
				} else {
					var assignee1 = assignee.a;
					return A4($author$project$View$View$viewStudentHome, model, appCfg, false, assignee1);
				}
			case 30:
				var studentSub = _v0.a;
				var qGroupId = _v0.b;
				return $Punie$elm_reader$Reader$asks(
					function (rState) {
						var assignee = _Utils_eq(studentSub, $author$project$Type$User$sampleStudentUserSub) ? $elm$core$Maybe$Just(
							A4(
								$author$project$Type$User$Assignee,
								_Utils_update(
									assigneeRecordBase,
									{
										hB: $elm$core$Maybe$Just(rState.mX.cF)
									}),
								'',
								'',
								examResults.eZ)) : A2($author$project$View$View$getAssigneeMaybe, model, studentSub);
						if (assignee.$ === 1) {
							return ($krisajenkins$remotedata$RemoteData$isLoading(model.el) || $krisajenkins$remotedata$RemoteData$isNotAsked(model.el)) ? A2($author$project$View$View$viewLoadingApp, model, 'Loading your assignees data...') : A3(
								$author$project$View$ViewHelper$viewFatalErrorPage,
								model,
								'We apologise ... We can\'t find the information for the page you\'re trying to load.',
								'Could not find assignee in route : ' + $author$project$Routing$routeToString(model.n_));
						} else {
							var assignee1 = assignee.a;
							return A4($author$project$View$View$viewSessionsResults, model, appCfg, assignee1, qGroupId);
						}
					});
			case 2:
				var qId = _v0.a;
				var qAnswerStatusString = _v0.b;
				var qaSubRoute = _v0.c;
				return $Punie$elm_reader$Reader$reader(
					A4($author$project$View$View$viewQuestionAndAnswer, model, qId, qAnswerStatusString, qaSubRoute));
			default:
				return A5(
					$author$project$View$View$handleCommonRoutes,
					model,
					appCfg,
					me,
					$author$project$Type$User$Teacher(teacherType),
					assigneeRecord);
		}
	});
var $author$project$View$View$notFoundView = A2(
	$elm$html$Html$div,
	_List_Nil,
	_List_fromArray(
		[
			$elm$html$Html$text('Not found')
		]));
var $author$project$View$View$viewPreConfirmationSection = function (model) {
	var messages = _List_fromArray(
		['You\'re doing great! Keep going!', 'Like a boss! ', 'Great job! Victory is near!', 'Excellent! You can do it!', 'Awesome work!']);
	var messageNbr = A2(
		$elm$core$Basics$modBy,
		5,
		A2($elm$core$Maybe$withDefault, 0, model.lo));
	var message = A2(
		$elm$core$Maybe$withDefault,
		'You\'re doing great!  Keep going.',
		A2(
			$elm$core$Array$get,
			messageNbr,
			$elm$core$Array$fromList(messages)));
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('hero'),
				$elm$html$Html$Attributes$class('is-fullheight'),
				$elm$html$Html$Attributes$class('is-primary')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('hero-head')
					]),
				_List_fromArray(
					[
						$author$project$View$ViewHelper$topBar(model),
						$author$project$View$View$viewPartHeroHead(model),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('section container')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('box')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$a,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('button is-white is-loading'),
												A2($elm$html$Html$Attributes$style, 'font-size', '6em'),
												A2($elm$html$Html$Attributes$style, 'width', '100%')
											]),
										_List_Nil),
										A2(
										$elm$html$Html$p,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('has-text-centered')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(message)
											])),
										A2(
										$elm$html$Html$p,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('has-text-centered')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('(You\'ll be back to your page in just a sec!)')
											]))
									]))
							]))
					]))
			]));
};
var $author$project$View$View$pageAfterLogin = function (model) {
	var nodeCfgResolvedRemoteData = $author$project$Type$NodeCfg$makeNodeCfgResolved(model.mX);
	var _v0 = _Utils_Tuple2(model.j$, nodeCfgResolvedRemoteData);
	_v0$2:
	while (true) {
		_v0$3:
		while (true) {
			_v0$4:
			while (true) {
				_v0$5:
				while (true) {
					switch (_v0.a.$) {
						case 3:
							switch (_v0.b.$) {
								case 3:
									var appCfg = _v0.a.a;
									var nodeCfgResolved = _v0.b.a;
									var viewsForTeacher = A2($author$project$View$View$makeViewsForTeacher, appCfg, model);
									var _v1 = model.fc;
									switch (_v1.$) {
										case 3:
											var me = _v1.a;
											var rState = A4($author$project$Models$makeReaderStateWith, model, appCfg, me, nodeCfgResolved);
											if (!me.$) {
												var studentEType = me.a;
												var _v3 = model.fQ;
												switch (_v3.$) {
													case 0:
														var _v4 = model.n_;
														if (_v4.$ === 34) {
															return $author$project$View$ViewHelper$viewFatalError(model);
														} else {
															return A2($author$project$View$View$viewLoadingApp, model, 'Obtaining your access credentials...');
														}
													case 1:
														return (model.ln === 1) ? $author$project$View$View$viewPreConfirmationSection(model) : A2($author$project$View$View$viewLoadingApp, model, 'Loading your account details...');
													case 2:
														var err = _v3.a;
														return A3($author$project$View$ViewHelper$viewFatalErrorPage, model, 'There was a problem retrieving your user data from the server.', err);
													default:
														var studentRecord2 = $author$project$Type$User$transformStudentETypeToAssignee(studentEType);
														var _v5 = model.n_;
														switch (_v5.$) {
															case 0:
																return A2(
																	$Punie$elm_reader$Reader$run,
																	A4($author$project$View$View$viewStudentHome, model, appCfg, true, studentRecord2),
																	rState);
															case 2:
																var qId = _v5.a;
																var qAnswerStatusString = _v5.b;
																var qaSubRoute = _v5.c;
																return A4($author$project$View$View$viewQuestionAndAnswer, model, qId, qAnswerStatusString, qaSubRoute);
															case 30:
																var qGroupId = _v5.b;
																return A4($author$project$View$View$viewSessionsResults, model, appCfg, studentRecord2, qGroupId);
															case 34:
																return $author$project$View$ViewHelper$viewFatalError(model);
															case 35:
																return $author$project$View$View$notFoundView;
															default:
																return A2(
																	$Punie$elm_reader$Reader$run,
																	A5(
																		$author$project$View$View$handleCommonRoutes,
																		model,
																		appCfg,
																		$author$project$Type$User$StudentE(studentEType),
																		$author$project$Type$User$Student,
																		studentRecord2),
																	rState);
														}
												}
											} else {
												if (me.a.$ === 1) {
													return A2(
														$Punie$elm_reader$Reader$run,
														A2(viewsForTeacher, me, 1),
														rState);
												} else {
													return A2(
														$Punie$elm_reader$Reader$run,
														A2(viewsForTeacher, me, 0),
														rState);
												}
											}
										case 1:
											return A2($author$project$View$View$viewLoadingApp, model, 'Loading your user details');
										default:
											return A3($author$project$View$ViewHelper$viewFatalErrorPage, model, 'We apologise ... There seems to be a problem with the application.', 'We could not identify your status as either a student or a teacher.');
									}
								case 2:
									break _v0$2;
								case 0:
									break _v0$4;
								default:
									var _v10 = _v0.b;
									return A2($author$project$View$View$viewLoadingApp, model, 'Loading the application (node) configuration data...');
							}
						case 2:
							var err = _v0.a.a;
							switch (err.$) {
								case 1:
									return A3($author$project$View$ViewHelper$viewFatalErrorPage, model, 'There was a problem retrieving the application configuration data from the server.', 'A Timeout error has occurred.');
								case 2:
									return A3($author$project$View$ViewHelper$viewFatalErrorPage, model, 'There was a problem retrieving the application configuration data from the server.', 'A NetworkError has occurred. Please check your network settings.');
								case 0:
									var errStr = err.a;
									return A3($author$project$View$ViewHelper$viewFatalErrorPage, model, 'There was a problem retrieving the application configuration data from the server.', 'Problem with appCfg.json. Http.BadUrl with error details: ' + errStr);
								case 4:
									var errStr = err.a;
									return A3($author$project$View$ViewHelper$viewFatalErrorPage, model, 'There was a problem retrieving the application configuration data from the server.', 'Problem with appCfg.json. Http.BadBody with error details: ' + errStr);
								default:
									var errInt = err.a;
									return A3(
										$author$project$View$ViewHelper$viewFatalErrorPage,
										model,
										'There was a problem retrieving the application configuration data from the server.',
										'Problem with appCfg.json. Http.BadStatus with error code: ' + $elm$core$String$fromInt(errInt));
							}
						case 0:
							switch (_v0.b.$) {
								case 2:
									break _v0$2;
								case 0:
									break _v0$3;
								case 1:
									break _v0$3;
								default:
									break _v0$3;
							}
						default:
							switch (_v0.b.$) {
								case 2:
									break _v0$2;
								case 0:
									break _v0$4;
								case 1:
									break _v0$5;
								default:
									break _v0$5;
							}
					}
				}
				var _v9 = _v0.a;
				return A2($author$project$View$View$viewLoadingApp, model, 'Loading the application configurations...');
			}
			var _v8 = _v0.b;
			return A2($author$project$View$View$viewLoadingApp, model, 'Preparing to request configuration data (NodeCfg)');
		}
		var _v7 = _v0.a;
		return A2($author$project$View$View$viewLoadingApp, model, 'Preparing to request configuration data (AppCfg)');
	}
	var err = _v0.b.a;
	return A3($author$project$View$ViewHelper$viewFatalErrorPage, model, 'There was a problem retrieving the node configuration data from the server.', 'Error details: ' + err);
};
var $author$project$Page$ForgotPassword$LinkClicked = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$ForgotPassword$goBackToSignInLink = A2(
	$mdgriffith$elm_ui$Element$row,
	_List_fromArray(
		[
			$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
		]),
	_List_fromArray(
		[
			A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$alignLeft]),
			A2(
				$author$project$Page$SignInPagesCommon$makeLink,
				'Never mind, go back',
				$author$project$Page$ForgotPassword$LinkClicked(
					$author$project$Routing$withHash($author$project$Routing$smPaths.om)))),
			A2(
			$mdgriffith$elm_ui$Element$el,
			_List_fromArray(
				[$mdgriffith$elm_ui$Element$alignRight]),
			$author$project$Page$SignInPagesCommon$makeLinkText('Show me help with this'))
		]));
var $author$project$Page$ForgotPassword$SetEmail = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$ForgotPassword$emailField = function (mdl) {
	var decorateAttrs = $author$project$Page$SignInPagesCommon$getDecorateAttrsFn(mdl.b3);
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10)
			]),
		A2(
			$mdgriffith$elm_ui$Element$Input$username,
			decorateAttrs(
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$Input$focusedOnLoad])),
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_Nil,
					A2(
						$mdgriffith$elm_ui$Element$el,
						decorateAttrs(_List_Nil),
						$mdgriffith$elm_ui$Element$text('Email'))),
				cS: $author$project$Page$ForgotPassword$SetEmail,
				cV: mdl.ds,
				cY: mdl.li
			}));
};
var $author$project$Page$ForgotPassword$ForgotPassword = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$PagesCommon$actionButton = function (btnConfig) {
	return A2($author$project$Page$PagesCommon$regButton, true, btnConfig);
};
var $author$project$Page$ForgotPassword$getResetCodeButton = function (_v0) {
	var email = _v0.li;
	return $author$project$Page$PagesCommon$actionButton(
		{
			bD: A2(
				$mdgriffith$elm_ui$Element$el,
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$centerX]),
				$mdgriffith$elm_ui$Element$text('Email me a password change verification code')),
			ai: $elm$core$Maybe$Just(
				$author$project$Page$ForgotPassword$ForgotPassword(email))
		});
};
var $author$project$Page$ForgotPassword$signInBox = function (mdl) {
	return A2(
		$author$project$Page$SignInPagesCommon$signInPagesBox,
		'Forgot your password?',
		_List_fromArray(
			[
				$author$project$Page$SignInPagesCommon$msgArea(mdl.fF),
				$author$project$Page$ForgotPassword$emailField(mdl),
				$author$project$Page$SignInPagesCommon$fieldErrorMsgElement(mdl.b3),
				$author$project$Page$ForgotPassword$getResetCodeButton(mdl)
			]));
};
var $author$project$Page$ForgotPassword$signInPagesArea = function (mdl) {
	return $author$project$Page$SignInPagesCommon$signInArea(
		_List_fromArray(
			[
				$author$project$Page$SignInPagesCommon$smarterMathsLogo,
				$author$project$Page$ForgotPassword$signInBox(mdl),
				$author$project$Page$ForgotPassword$goBackToSignInLink
			]));
};
var $author$project$Page$ForgotPassword$view = function (model) {
	return A2(
		$mdgriffith$elm_ui$Element$layout,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.nl)
			]),
		$author$project$Page$ForgotPassword$signInPagesArea(model));
};
var $author$project$Page$ForgotPasswordSubmit$LinkClicked = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$ForgotPasswordSubmit$goBackToSignInLink = A2(
	$author$project$Page$SignInPagesCommon$makeLink,
	'Never mind, go back',
	$author$project$Page$ForgotPasswordSubmit$LinkClicked(
		$author$project$Routing$withHash($author$project$Routing$smPaths.om)));
var $author$project$Page$ForgotPasswordSubmit$SetCode = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$ForgotPasswordSubmit$codeField = function (mdl) {
	var decorateAttrs = $author$project$Page$SignInPagesCommon$getDecorateAttrsFn(mdl.b$);
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10)
			]),
		A2(
			$mdgriffith$elm_ui$Element$Input$text,
			decorateAttrs(
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$Input$focusedOnLoad])),
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_Nil,
					A2(
						$mdgriffith$elm_ui$Element$el,
						decorateAttrs(_List_Nil),
						$mdgriffith$elm_ui$Element$text('Code'))),
				cS: $author$project$Page$ForgotPasswordSubmit$SetCode,
				cV: mdl.dj,
				cY: mdl.gt
			}));
};
var $author$project$Page$ForgotPasswordSubmit$emailMsgField = function (email) {
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10)
			]),
		A2(
			$mdgriffith$elm_ui$Element$paragraph,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$padding(20),
					$mdgriffith$elm_ui$Element$Border$rounded(10),
					$mdgriffith$elm_ui$Element$Border$solid,
					$mdgriffith$elm_ui$Element$Border$width(1),
					$mdgriffith$elm_ui$Element$Font$size(16)
				]),
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$text('We have sent a verification code by email to: '),
					A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[
							$mdgriffith$elm_ui$Element$Font$bold,
							$mdgriffith$elm_ui$Element$Font$underline,
							$mdgriffith$elm_ui$Element$Font$size(20)
						]),
					$mdgriffith$elm_ui$Element$text(email)),
					$mdgriffith$elm_ui$Element$text('. If you do not see it in your inbox, please check your spam filter.')
				])));
};
var $author$project$Page$ForgotPasswordSubmit$ForgotPasswordSubmit = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $author$project$Page$ForgotPasswordSubmit$forgotPasswordSubmitButton = F2(
	function (_v0, email) {
		var code = _v0.gt;
		var newPassword = _v0.cf;
		return $author$project$Page$PagesCommon$actionButton(
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$el,
					_List_fromArray(
						[$mdgriffith$elm_ui$Element$centerX]),
					$mdgriffith$elm_ui$Element$text('Change password')),
				ai: $elm$core$Maybe$Just(
					A3($author$project$Page$ForgotPasswordSubmit$ForgotPasswordSubmit, email, code, newPassword))
			});
	});
var $author$project$Page$ForgotPasswordSubmit$SetNewPassword = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$ForgotPasswordSubmit$ToggleIsShowPassword = {$: 6};
var $author$project$Page$ForgotPasswordSubmit$newPasswordField = function (mdl) {
	var showOrHideText = mdl.cN ? 'Hide' : 'Show';
	var decorateAttrs = $author$project$Page$SignInPagesCommon$getDecorateAttrsFn(mdl.cQ);
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10)
			]),
		A2(
			$mdgriffith$elm_ui$Element$Input$newPassword,
			decorateAttrs(
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$Input$focusedOnLoad])),
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_Nil,
					A2(
						$mdgriffith$elm_ui$Element$row,
						_List_fromArray(
							[
								$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill)
							]),
						_List_fromArray(
							[
								A2(
								$mdgriffith$elm_ui$Element$el,
								decorateAttrs(_List_Nil),
								$mdgriffith$elm_ui$Element$text('New Password')),
								A2(
								$mdgriffith$elm_ui$Element$el,
								_List_fromArray(
									[
										$mdgriffith$elm_ui$Element$Font$color($author$project$Theme$Style$t.eB),
										$mdgriffith$elm_ui$Element$Font$underline,
										$mdgriffith$elm_ui$Element$Font$size(16),
										$mdgriffith$elm_ui$Element$alignRight,
										$mdgriffith$elm_ui$Element$Events$onClick($author$project$Page$ForgotPasswordSubmit$ToggleIsShowPassword)
									]),
								$mdgriffith$elm_ui$Element$text(showOrHideText))
							]))),
				cS: $author$project$Page$ForgotPasswordSubmit$SetNewPassword,
				cV: mdl.fk,
				iR: mdl.cN,
				cY: mdl.cf
			}));
};
var $author$project$Page$ForgotPasswordSubmit$SetRepeatPassword = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$ForgotPasswordSubmit$repeatPasswordField = function (mdl) {
	var decorateAttrs = $author$project$Page$SignInPagesCommon$getDecorateAttrsFn(mdl.cW);
	return A2(
		$mdgriffith$elm_ui$Element$el,
		_List_fromArray(
			[
				$mdgriffith$elm_ui$Element$width($mdgriffith$elm_ui$Element$fill),
				A2($mdgriffith$elm_ui$Element$paddingXY, 0, 10)
			]),
		A2(
			$mdgriffith$elm_ui$Element$Input$newPassword,
			decorateAttrs(
				_List_fromArray(
					[$mdgriffith$elm_ui$Element$Input$focusedOnLoad])),
			{
				bD: A2(
					$mdgriffith$elm_ui$Element$Input$labelAbove,
					_List_Nil,
					A2(
						$mdgriffith$elm_ui$Element$el,
						decorateAttrs(_List_Nil),
						$mdgriffith$elm_ui$Element$text('Enter New Password Again'))),
				cS: $author$project$Page$ForgotPasswordSubmit$SetRepeatPassword,
				cV: mdl.fy,
				iR: mdl.cN,
				cY: mdl.dU
			}));
};
var $author$project$Page$ForgotPasswordSubmit$signInBox = F2(
	function (mdl, email) {
		return A2(
			$author$project$Page$SignInPagesCommon$signInPagesBox,
			'Reset your password',
			_List_fromArray(
				[
					$author$project$Page$SignInPagesCommon$msgArea(mdl.fF),
					$author$project$Page$ForgotPasswordSubmit$emailMsgField(email),
					$author$project$Page$ForgotPasswordSubmit$codeField(mdl),
					$author$project$Page$SignInPagesCommon$fieldErrorMsgElement(mdl.b$),
					$author$project$Page$ForgotPasswordSubmit$newPasswordField(mdl),
					$author$project$Page$SignInPagesCommon$fieldErrorMsgElement(mdl.cQ),
					$author$project$Page$ForgotPasswordSubmit$repeatPasswordField(mdl),
					$author$project$Page$SignInPagesCommon$fieldErrorMsgElement(mdl.cW),
					A2($author$project$Page$ForgotPasswordSubmit$forgotPasswordSubmitButton, mdl, email)
				]));
	});
var $author$project$Page$ForgotPasswordSubmit$signInPagesArea = F2(
	function (mdl, email) {
		return $author$project$Page$SignInPagesCommon$signInArea(
			_List_fromArray(
				[
					$author$project$Page$SignInPagesCommon$smarterMathsLogo,
					A2($author$project$Page$ForgotPasswordSubmit$signInBox, mdl, email),
					$author$project$Page$ForgotPasswordSubmit$goBackToSignInLink
				]));
	});
var $author$project$Page$ForgotPasswordSubmit$view = F2(
	function (model, email) {
		return A2(
			$mdgriffith$elm_ui$Element$layout,
			_List_fromArray(
				[
					$mdgriffith$elm_ui$Element$Background$color($author$project$Theme$Style$t.nl)
				]),
			A2($author$project$Page$ForgotPasswordSubmit$signInPagesArea, model, email));
	});
var $author$project$View$View$page = function (model) {
	var _v0 = model.fc;
	switch (_v0.$) {
		case 3:
			return $author$project$View$View$pageAfterLogin(model);
		case 2:
			return A3($author$project$View$ViewHelper$viewFatalErrorPage, model, 'We could not log you in.', 'LoginError');
		case 0:
			var _v1 = model.n_;
			switch (_v1.$) {
				case 19:
					return A2(
						$elm$html$Html$map,
						$author$project$Msgs$MsgPageForgotPassword,
						$author$project$Page$ForgotPassword$view(model.h2));
				case 20:
					var email = _v1.a;
					return A2(
						$elm$html$Html$map,
						$author$project$Msgs$MsgPageForgotPasswordSubmit,
						A2($author$project$Page$ForgotPasswordSubmit$view, model.h3, email));
				default:
					return A2(
						$elm$html$Html$map,
						$author$project$Msgs$MsgPageSignIn,
						A2($author$project$Page$SignIn$view, model.lx, model.id));
			}
		default:
			return A2($author$project$View$View$viewLoadingApp, model, 'Retrieving your user details ...');
	}
};
var $author$project$View$View$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'display', 'initial')
			]),
		_List_fromArray(
			[
				function () {
				var _v0 = model.ll;
				if (_v0.$ === 1) {
					return $author$project$View$View$page(model);
				} else {
					var _v1 = model.n_;
					if (_v1.$ === 31) {
						return $author$project$View$View$page(model);
					} else {
						return $author$project$View$ViewHelper$viewFatalError(model);
					}
				}
			}()
			]));
};
var $author$project$Main$main = $elm$browser$Browser$element(
	{l2: $author$project$Main$init, oH: $author$project$Subscriptions$subscriptions, px: $author$project$Update$update, pM: $author$project$View$View$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (version) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (urlPrefixClientResources) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (urlPrefixAppCfg) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (urlPosts) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (serverEnv) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (isDebug) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (interopTags) {
															return A2(
																$elm$json$Json$Decode$andThen,
																function (initUrl) {
																	return A2(
																		$elm$json$Json$Decode$andThen,
																		function (graphQLOps) {
																			return $elm$json$Json$Decode$succeed(
																				{lF: graphQLOps, hm: initUrl, hr: interopTags, md: isDebug, od: serverEnv, pz: urlPosts, pA: urlPrefixAppCfg, pB: urlPrefixClientResources, pL: version});
																		},
																		A2(
																			$elm$json$Json$Decode$field,
																			'graphQLOps',
																			A2(
																				$elm$json$Json$Decode$andThen,
																				function (updateTeacherGQL) {
																					return A2(
																						$elm$json$Json$Decode$andThen,
																						function (updateStudentGQL) {
																							return A2(
																								$elm$json$Json$Decode$andThen,
																								function (updateLoginUserGQL) {
																									return A2(
																										$elm$json$Json$Decode$andThen,
																										function (teachersGQL) {
																											return A2(
																												$elm$json$Json$Decode$andThen,
																												function (studentsWithHstryGQL) {
																													return A2(
																														$elm$json$Json$Decode$andThen,
																														function (studentsGQL) {
																															return A2(
																																$elm$json$Json$Decode$andThen,
																																function (studentsByTchIdWithHstryGQL) {
																																	return A2(
																																		$elm$json$Json$Decode$andThen,
																																		function (studentsByTchIdGQL) {
																																			return A2(
																																				$elm$json$Json$Decode$andThen,
																																				function (nbrUsersForSchoolIdGQL) {
																																					return A2(
																																						$elm$json$Json$Decode$andThen,
																																						function (meGQL) {
																																							return A2(
																																								$elm$json$Json$Decode$andThen,
																																								function (deleteStudentHistoryGQL) {
																																									return A2(
																																										$elm$json$Json$Decode$andThen,
																																										function (deleteSchoolGQL) {
																																											return A2(
																																												$elm$json$Json$Decode$andThen,
																																												function (deleteLoginUserGQL) {
																																													return A2(
																																														$elm$json$Json$Decode$andThen,
																																														function (createTeacherGQL) {
																																															return A2(
																																																$elm$json$Json$Decode$andThen,
																																																function (createStudentGQL) {
																																																	return A2(
																																																		$elm$json$Json$Decode$andThen,
																																																		function (createQuestionGroupGQL) {
																																																			return A2(
																																																				$elm$json$Json$Decode$andThen,
																																																				function (createEntityGQL) {
																																																					return A2(
																																																						$elm$json$Json$Decode$andThen,
																																																						function (bySIdGQL) {
																																																							return $elm$json$Json$Decode$succeed(
																																																								{go: bySIdGQL, gG: createEntityGQL, gH: createQuestionGroupGQL, gI: createStudentGQL, gJ: createTeacherGQL, gQ: deleteLoginUserGQL, gR: deleteSchoolGQL, gS: deleteStudentHistoryGQL, hE: meGQL, hP: nbrUsersForSchoolIdGQL, iZ: studentsByTchIdGQL, i_: studentsByTchIdWithHstryGQL, i$: studentsGQL, i0: studentsWithHstryGQL, i8: teachersGQL, ji: updateLoginUserGQL, jj: updateStudentGQL, jk: updateTeacherGQL});
																																																						},
																																																						A2($elm$json$Json$Decode$field, 'bySIdGQL', $elm$json$Json$Decode$string));
																																																				},
																																																				A2($elm$json$Json$Decode$field, 'createEntityGQL', $elm$json$Json$Decode$string));
																																																		},
																																																		A2($elm$json$Json$Decode$field, 'createQuestionGroupGQL', $elm$json$Json$Decode$string));
																																																},
																																																A2($elm$json$Json$Decode$field, 'createStudentGQL', $elm$json$Json$Decode$string));
																																														},
																																														A2($elm$json$Json$Decode$field, 'createTeacherGQL', $elm$json$Json$Decode$string));
																																												},
																																												A2($elm$json$Json$Decode$field, 'deleteLoginUserGQL', $elm$json$Json$Decode$string));
																																										},
																																										A2($elm$json$Json$Decode$field, 'deleteSchoolGQL', $elm$json$Json$Decode$string));
																																								},
																																								A2($elm$json$Json$Decode$field, 'deleteStudentHistoryGQL', $elm$json$Json$Decode$string));
																																						},
																																						A2($elm$json$Json$Decode$field, 'meGQL', $elm$json$Json$Decode$string));
																																				},
																																				A2($elm$json$Json$Decode$field, 'nbrUsersForSchoolIdGQL', $elm$json$Json$Decode$string));
																																		},
																																		A2($elm$json$Json$Decode$field, 'studentsByTchIdGQL', $elm$json$Json$Decode$string));
																																},
																																A2($elm$json$Json$Decode$field, 'studentsByTchIdWithHstryGQL', $elm$json$Json$Decode$string));
																														},
																														A2($elm$json$Json$Decode$field, 'studentsGQL', $elm$json$Json$Decode$string));
																												},
																												A2($elm$json$Json$Decode$field, 'studentsWithHstryGQL', $elm$json$Json$Decode$string));
																										},
																										A2($elm$json$Json$Decode$field, 'teachersGQL', $elm$json$Json$Decode$string));
																								},
																								A2($elm$json$Json$Decode$field, 'updateLoginUserGQL', $elm$json$Json$Decode$string));
																						},
																						A2($elm$json$Json$Decode$field, 'updateStudentGQL', $elm$json$Json$Decode$string));
																				},
																				A2($elm$json$Json$Decode$field, 'updateTeacherGQL', $elm$json$Json$Decode$string))));
																},
																A2($elm$json$Json$Decode$field, 'initUrl', $elm$json$Json$Decode$string));
														},
														A2($elm$json$Json$Decode$field, 'interopTags', $elm$json$Json$Decode$value));
												},
												A2($elm$json$Json$Decode$field, 'isDebug', $elm$json$Json$Decode$bool));
										},
										A2($elm$json$Json$Decode$field, 'serverEnv', $elm$json$Json$Decode$string));
								},
								A2($elm$json$Json$Decode$field, 'urlPosts', $elm$json$Json$Decode$string));
						},
						A2($elm$json$Json$Decode$field, 'urlPrefixAppCfg', $elm$json$Json$Decode$string));
				},
				A2($elm$json$Json$Decode$field, 'urlPrefixClientResources', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'version', $elm$json$Json$Decode$string)))(0)}});}(this));